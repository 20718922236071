import React, { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import LevelCardWrapper from '../components/roadmap/LevelCardWrapper';
import LevelDescriptionCardWrapper from '../components/roadmap/LevelDescriptionCardWrapper';
import VideoWrapper from '../components/roadmap/VideoWrapper';
import { useLanguage } from '../context/LanguageContext';
import ErrorFallback from './errors/ErrorFallback';
import memberService from '../services/memberService';
import { useAuth } from '../context/AuthContext';

const videoSource: string = 'https://www.youtube.com/embed/wCIbSKdxiC4';
// Before you change this respect the biggest!

const RoadMap = () => {
  const { session } = useAuth();
  const { t } = useLanguage();
  const [isTabActive, setIsTabActive] = React.useState<boolean>(true);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabActive(document.visibilityState === 'visible');
    };

    // Add event listener for visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup function to remove the event listener when the component is unmounted
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    let intervalId: any;
    if (session && isTabActive) {
      intervalId = setInterval(async () => {
        await memberService.userTrackReq(`roadmap`, session?.id!);
      }, 60000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [session, isTabActive]);
  return (
    <div className="flex w-full  flex-shrink-0 flex-col">
      {/* hangi aşamadasın */}
      <section className="flex flex-col mt-5">
        <h2 className="subHeading font-bold">{t?.('roadmap.page.stage')}</h2>
        <p className="mb-10 text-sm md:text-base">
          {t?.('roadmap.page.description')}
        </p>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <LevelCardWrapper />
        </ErrorBoundary>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <VideoWrapper videoSource={videoSource} />
        </ErrorBoundary>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <LevelDescriptionCardWrapper />
        </ErrorBoundary>
      </section>
    </div>
  );
};

export default RoadMap;
