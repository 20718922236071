import React from 'react'
import { Conditions, ConditionsFull } from '../DummyDatasAndTypes'
import Details from './Detailts'
import TotalTurnoverBarChart from './TotalTurnoverBarChart'

interface TotalTurnoverProps {
    conditions: Conditions[]
    conditionsFull: ConditionsFull[]
    isLoading: boolean
}
const TotalTurnover: React.FC<TotalTurnoverProps> = (
    {
        conditions,
        conditionsFull,
        isLoading,
    }
) => {
    return (
        <div className='grid gap-2 grid-cols-10 mt-10 '>
            <div className='col-span-10 lg:col-span-7 xl:col-span-8 rounded-md shadow-md lg:max-h-[470px]'>
                {/* Barchart */}
                <TotalTurnoverBarChart />
            </div>
            <div className='col-span-10 lg:col-span-3 xl:col-span-2 lg:z-10 lg:max-h-[470px]'>
                <p className='font-bold my-3'>
                    Detaylar
                </p>
                <Details
                    isLoading={isLoading}
                    card={{
                        conditions: conditions,
                        conditionsFull: conditionsFull
                    }} />
            </div>
        </div>
    )
}

export default TotalTurnover