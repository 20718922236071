import { Navigate, Route, Routes } from 'react-router-dom';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { AuthProvider } from './context/AuthContext';
import { LanguageProvider } from './context/LanguageContext';
import AliExpressCalculator from './pages/AliExpressCalculator';
import AliExpressProducts from './pages/AliExpressProducts';
import AliExpressTopKeywords from './pages/AliExpressTopKeywords';
import AliExpressTopProducts from './pages/AliExpressTopProducts';
import AllProducts from './pages/AllProducts';
import AmazonArbitrage from './pages/AmazonArbitrage';
import AmazonForecast from './pages/AmazonForecast';
import AmazonKeywordTool from './pages/AmazonKeywordTool';
import AmazonProductFinder from './pages/AmazonProductFinder';
import AmazonProfitCalculator from './pages/AmazonProfitCalculator';
import AmazonReviewAnalysis from './pages/AmazonReviewAnalysis';
import ForgetPassword from './pages/auth/ForgetPassword';
import Login from './pages/auth/Login';
import Logout from './pages/auth/Logout';
import Register from './pages/auth/Register';
import ResetInfo from './pages/auth/ResetInfo';
import ResetPassword from './pages/auth/ResetPassword';
import BillingInfo from './pages/BillingInfo';
import Books from './pages/Books';
import Chat from './pages/Chat';
import Consultancy from './pages/Consultancy';
import CourseDetail from './pages/CourseDetail';
import Courses from './pages/Courses';
import Dashboard from './pages/Dashboard';
import Entegration from './pages/Entegration';
import Error404 from './pages/errors/Error404';
import EtsyHotProducts from './pages/EtsyHotProducts';
import EtsyHotSellers from './pages/EtsyHotSellers';
import EtsyProfitCalculator from './pages/EtsyProfitCalculator';
import EtsyStoreAnalayze from './pages/EtsyStoreAnalayze';
import EtsyTopProducts from './pages/EtsyTopProducts';
import EtsyTopSellers from './pages/EtsyTopSellers';
import Exclusive from './pages/Exclusive';
import Faqs from './pages/Faqs';
import Manufacturer from './pages/Manufacturer';
import Marketplace from './pages/Marketplace';
import MarketplaceInventory from './pages/MarketplaceInventory';
import MarketplaceOrders from './pages/MarketplaceOrders';
import MarketplaceProductDetails from './pages/MarketplaceProductDetails';
import MarketplaceProducts from './pages/MarketplaceProducts';
import MarketplaceSupplierOrders from './pages/MarketplaceSupplierOrders';
import MarketplaceSuppliersProducts from './pages/MarketplaceSuppliersProducts';
import Messages from './pages/Messages';
import Payment from './pages/Payment';
import PricingPage from './pages/PricingPage';
import KVKK from './pages/privacy/KVKK';
import PrivacyPolicy from './pages/privacy/PrivacyPolicy';
import RexvenUsage from './pages/privacy/RexvenUsage';
import SalesPolicy from './pages/privacy/SalesPolicy';
import SubscriptionPolicy from './pages/privacy/SubscriptionPolicy';
// import ProductIntegration from './pages/ProductIntegration';
import ProductsAndWholeSalers from './pages/ProductsAndWholeSalers';
import Profile from './pages/Profile';
// import ServiceProviders from './pages/ServiceProviders';
import Return from './pages/Return';
import RexvenAI from './pages/RexvenAI';
import RoadMap from './pages/RoadMap';
import ServiceProviderDetails from './pages/ServiceProviderDetails';
import ServiceProviderList from './pages/ServiceProviderList';
// import ServiceProviders from './pages/ServiceProviders';
import ShipEntegraCreateOrder from './pages/ShipEntegraCreateOrder';
import ShipEntegraMyOrders from './pages/ShipEntegraMyOrders';
import Shipments from './pages/Shipments';
import ShippingCalculator from './pages/ShippingCalculator';
import SupplierProducts from './pages/SupplierProducts';
import Suppliers from './pages/Suppliers';
import ThanksPage from './pages/ThanksPage';
import TrendyolMyProducts from './pages/TrendyolMyProducts';
import TrendyolOrderPage from './pages/TrendyolOrderPage';
import TrendyolProfitCalculator from './pages/TrendyolProfitCalculator';
import TrendyolSellerDashboard from './pages/TrendyolSellerDashboard';
import AuthRouter from './routes/AuthRouter';
// import Loader from './components/loader/Loader';
import PublicRoute from './routes/PublicRoute';
import UserRouter from './routes/UserRoutes';

const queryClient = new QueryClient();

function App() {
  return (
    <LanguageProvider>
      <AuthProvider>
        {/* <Layout> */}
        <QueryClientProvider client={queryClient}>
          <Routes>
            <Route path={'/gizlilik-politikasi'} element={<PrivacyPolicy />} />
            <Route path={'/aydinlatma-metni'} element={<KVKK />} />
            <Route path={'/kullanim-kosullari'} element={<RexvenUsage />} />

            <Route
              path={'/uyelik-sozlesmesi'}
              element={<SubscriptionPolicy />}
            />
            <Route
              path={'/mesafeli-satis-sozlesmesi'}
              element={<SalesPolicy />}
            />
            <Route
              path={'/return/:userId/:packagePlanId'}
              element={<Return />}
            />
            <Route element={<PublicRoute />}>
              <Route path={'/payment/:plan'} element={<Payment />} />
              <Route path={'/thanks'} element={<ThanksPage />} />
              <Route path={'/pricing'} element={<PricingPage />} />
            </Route>
            <Route element={<AuthRouter />}>
              <Route path={'/login'} element={<Login />} />
              <Route path={'/register'} element={<Register />} />
              <Route path={'/forget-password'} element={<ForgetPassword />} />

              <Route
                path={'/reset-password/:token'}
                element={<ResetPassword />}
              />
              <Route path={'/reset-info/:token'} element={<ResetInfo />} />
            </Route>
            <Route element={<UserRouter />}>
              <Route path={'/dashboard'} element={<Dashboard />} />
              <Route path={'/roadmap'} element={<RoadMap />} />
              <Route
                path={'service-providers'}
                element={<ServiceProviderList />}
              />
              <Route path={'suppliers'} element={<Suppliers />} />
              <Route path={'suppliers/:id'} element={<SupplierProducts />} />
              <Route path={'products'} element={<AllProducts />} />
              <Route path={'marketplace-seller-products'} element={<MarketplaceSuppliersProducts />} />
              <Route
                path={'service-providers/:id'}
                element={<ServiceProviderDetails />}
              />
              <Route path={'/courses'} element={<Courses />} />
              <Route
                path={'/courses/:id/:courseName'}
                element={<CourseDetail />}
              />
              <Route path={'/books'} element={<Books />} />
              <Route path={'/logout'} element={<Logout />} />
              <Route path={'/profile'} element={<Profile />} />
              {/* <Route path={'/billing-info'} element={<BillingInfo />} /> */}
              <Route path={'/integration'} element={<Entegration />} />
              <Route path={'/sss'} element={<Faqs />} />
              <Route
                path={'/manufacturers-and-wholesalers'}
                element={<ProductsAndWholeSalers />}
              />
              <Route path={'/manufacturer/:id'} element={<Manufacturer />} />
              <Route
                path={'/marketplace/products/:id'}
                element={<MarketplaceProductDetails />}
              />
              <Route
                path={'/marketplace-orders'}
                element={<MarketplaceOrders />}
              />
              <Route
                path={'/marketplace-supplier-orders'}
                element={<MarketplaceSupplierOrders />}
              />
              <Route path={'/marketplace'} index element={<Marketplace />} />
              {/* <Route path={'/marketplace/:id'} element={<MarketplaceProducts />} /> */}
              <Route
                path={'/marketplace-inventory'}
                element={<MarketplaceInventory />}
              />
              <Route path={'/chat'} element={<Chat />} />
              <Route path={'/messages'} element={<Messages />} />
              <Route
                path={'/etsy-profit-calculator'}
                element={<EtsyProfitCalculator />}
              />
              <Route path={'/etsy-hot-sellers'} element={<EtsyHotSellers />} />
              <Route path={'/etsy-top-sellers'} element={<EtsyTopSellers />} />
              <Route
                path={'/etsy-hot-products'}
                element={<EtsyHotProducts />}
              />
              <Route
                path={'/etsy-top-products'}
                element={<EtsyTopProducts />}
              />
              <Route
                path={'/etsy-store-analyze'}
                element={<EtsyStoreAnalayze />}
              />
              <Route
                path={'/trendyol-profit-calculator'}
                element={<TrendyolProfitCalculator />}
              />
              <Route
                path={'/trendyol-seller-dashboard'}
                element={<TrendyolSellerDashboard />}
              />
              <Route
                path={'/trendyol-my-products'}
                element={<TrendyolMyProducts />}
              />
              {/*<Route
                path={'/trendyol-product-integration/:id'}
                element={<ProductIntegration />}
              /> */}
              <Route
                path={'/trendyol-orders'}
                element={<TrendyolOrderPage />}
              />
              <Route
                path={'/amazon-profit-calculator'}
                element={<AmazonProfitCalculator />}
              />
              <Route
                path={'/amazon-review-analysis'}
                element={<AmazonReviewAnalysis />}
              />
              <Route
                path={'/amazon-online-arbitrage'}
                element={<AmazonArbitrage />}
              />
              <Route path={'/amazon-forecast'} element={<AmazonForecast />} />
              <Route
                path={'/amazon-product-finder'}
                element={<AmazonProductFinder />}
              />
              <Route
                path={'/amazon-keyword-tool'}
                element={<AmazonKeywordTool />}
              />
              <Route path={'/consultancy'} element={<Consultancy />} />
              <Route path={'/rexven-ai'} element={<RexvenAI />} />

              <Route path={'/exclusive'} element={<Exclusive />} />
              <Route path={'/roadmap'} element={<RoadMap />} />
              <Route
                path={'/aliexpress-products'}
                element={<AliExpressProducts />}
              />
              <Route
                path={'/aliexpress-profit-calculator'}
                element={<AliExpressCalculator />}
              />
              <Route
                path={'/aliexpress-top-products'}
                element={<AliExpressTopProducts />}
              />
              <Route
                path={'/aliexpress-top-keywords'}
                element={<AliExpressTopKeywords />}
              />
              <Route
                path={'/shipping-calculator'}
                element={<ShippingCalculator />}
              />
              <Route path={'/shipments'} element={<Shipments />} />
              {/* <Route path={'/payment/:plan'} element={<Payment />} />
              <Route path={'/thanks'} element={<ThanksPage />} />
              <Route path={'/pricing'} element={<PricingPage />} /> */}

              <Route
                path={'/create-shipment'}
                element={<ShipEntegraCreateOrder />}
              />
              <Route path={'/orders'} element={<ShipEntegraMyOrders />} />
              <Route index element={<Navigate to="/marketplace" />} />
            </Route>
            <Route path={'*'} element={<Error404 />} />
          </Routes>
          {/* </Layout> */}
        </QueryClientProvider>
      </AuthProvider>
    </LanguageProvider>
  );
}

export default App;
