import React, { lazy, Suspense, useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';

// Components
import GPTProductLink from '../components/amazonReviewAnalysis/GPTProductLink';
import GPTReviewBox from '../components/amazonReviewAnalysis/GPTReviewBox';
import ProductFirstReview from '../components/amazonReviewAnalysis/ProductFirstReview';
import ProductReviews from '../components/amazonReviewAnalysis/ProductReviews';
// import SixDigitModal from '../components/numberVerifyModals/SixDigitModal';
import NumVerifyModal from '../components/numberVerifyModals/NumVerifyModal';
import VerifySuccessModal from '../components/numberVerifyModals/VerifySuccessModal';
import SurveyModal from '../components/survey/SurveyModal';
import BannerLoading from '../components/tools/common/BannerLoading';
import BuyModal from '../components/tools/etsy/BuyModal';
import { IuserSession, useAuth } from '../context/AuthContext';
import { useLanguage } from '../context/LanguageContext';
import { useNumberVerification } from '../context/NumberVerificationContext';
import { IProductInfoForReviewTool, IReview } from '../interfaces/amazonToolsInterfaces';
// Others
import amazonToolsService from '../services/amazonToolsService';
import memberService from '../services/memberService';
// MODALS
import alertNotification from '../utils/alertNotification';

const NewBanner = lazy(() => import('../components/tools/common/NewBanner'));

// ------------ FUNCTION THAT CHECKS FOR PRODUCT LINK REGEX -----------
function checkAmazonLink(url: string): boolean {
  const pattern = /^https?:\/\/(?:www\.)?amazon\.(com|com\.tr|com\.mx|com\.au|com\.br|co\.uk|co\.jp|sg|de|es|fr|it|ca|in|cn|ae|nl|sa|se|pl)\/[^\s/$.?#].[^\s]*$/;
  return pattern.test(url);
}

const AmazonReviewAnalysis: React.FC = () => {
  const { session, setSession } = useAuth();
  // const [session, setSession] = useState({
  //   id: 9485,
  //   firstName: "Gayri Meşru Danışmanı",
  //   lastName: "Özgür",
  //   role: "pt04n1dT",
  //   email: "klcomer92@gmail.com",
  //   inCommunity: true,
  //   isSeller: false,
  //   profileImage: "419ff1ee6c9deb1c9bc90e61faa05a7a28a08752a7fb34c114ccc56ede0da660",
  //   level: 5,
  //   levelName: "10.000$ Satışa Ulaştım",
  //   lifeTimeCourse: false,
  //   hasPackage: false,
  //   isGsmVerified: true,
  //   netPromoterScoreDisplay: false,
  //   reviewToolCounters: {
  //     amazonReviewToolCounter: 3
  //   },
  //   discordInviteLink: null,
  //   isDiscordCheck: null,
  //   discordUserId: null,
  //   isInitialSurveyDone: true,
  //   isTrialEnd: false,
  //   rexvenSurveyDisplay: false,
  //   isTrendyolIntegrated: false,
  //   isAliExpressIntegrated: false,
  //   location: "abroad",
  //   isServiceProvider: false,
  //   gsmNumber: "+905303397059",
  //   hasShipEntegraInt: false,
  //   isSupplier: false,
  //   packageName: "Rexven Plus"
  // });

  const { t } = useLanguage();
  const {
    setOpenSurvey,
    setNumVerifyModal,
    setSixDigitVerifyModal,
    isCodeSend,
  } = useNumberVerification();
  const [isTabActive, setIsTabActive] = React.useState<boolean>(true);
  const [open, setOpen] = useState(false);
  const [productLink, setProductLink] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [productReviews, setProductReviews] =
    useState<IReview[]>(dummyProductReviews);

  const [gptAnalysis, setGptAnalysis] = useState<string[]>(dummyGptAnalysis);
  const [productInfo, setProductInfo] =
    useState<IProductInfoForReviewTool>(dummyProductInfo);

  const [productAsin, setProductAsin] = useState('');

  const startGptTool = (event: any) => {
    // console.log('productLink', productLink);

    // checking session for handleActiveModal
    if (
      (session?.hasPackage || session?.lifeTimeCourse) &&
      !session?.isInitialSurveyDone
    ) {
      event.preventDefault();
      setOpenSurvey?.(true);
    } else if (
      !session?.hasPackage &&
      !session?.lifeTimeCourse &&
      !session?.isGsmVerified
    ) {
      event.preventDefault();
      if (!isCodeSend) {
        setNumVerifyModal?.(true);
      } else if (isCodeSend) {
        setSixDigitVerifyModal?.(true);
      }
    } else {
      // Check if there is productLink
      if (!productLink) {
        alertNotification('error', 'Lütfen ürün linki giriniz');
      } else if (checkAmazonLink(productLink)) {
        // Define package usage limits
        const packageUsageLimits: { [key: string]: number } = {
          Free: 3,
          'Rexven Plus': 10,
          'Rexven Premium': 20,
          Elit: 30,
        };

        const userPackage = session?.packageName || 'Free';
        const usageLimit = packageUsageLimits[userPackage] || 3;

        // Check if client has access to tool
        if (session?.reviewToolCounters.amazonReviewToolCounter >= usageLimit) {
          alertNotification('error', `Aylık kullanım hakkınızı aştınız! (${usageLimit} adet)`);
        } else {
          // If Everything is okay, make a request
          setIsLoading(true);
          amazonToolsService
            .getReviewAnalysis({ productUrl: productLink })
            .then((res) => {
              // console.log(res.data);
              setProductAsin(res.data.asin);
              setGptAnalysis(res.data.analysis);
              setProductReviews(res.data.reviews);
              setProductInfo(res.data.productInfo);
              setSession!(res?.data?.session!);
            })
            .catch((err) => {
              alertNotification('error', err.response.data.message);
            })
            .finally(() => {
              setIsLoading(false);
            });
        }
      } else {
        alertNotification(
          'error',
          'Lütfen geçerli bir amazın ürün linki giriniz'
        );
      }
    }
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabActive(document.visibilityState === 'visible');
    };

    // Add event listener for visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup function to remove the event listener when the component is unmounted
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
  useEffect(() => {
    let intervalId: any;
    if (session && isTabActive) {
      intervalId = setInterval(async () => {
        await memberService.userTrackReq(
          'amazon-review-analysis',
          session?.id!
        );
      }, 60000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [session, isTabActive]);
  return (
    <>
      {/* NECCESSARY MODALS */}
      {/* <SixDigitModal /> */}
      <NumVerifyModal />
      <VerifySuccessModal />
      <SurveyModal />
      <ToastContainer />
      <BuyModal open={open} setOpen={setOpen} />
      {/* NECCESSARY MODALS */}

      <Suspense fallback={<BannerLoading />}>
        <NewBanner
          bgUrl={'/images/gpt-yorum-analizi-bg.webp'}
          toolImageUrl="gpt-yorum-analizi"
        />
      </Suspense>

      <div className="bg-white p-2 md:p-6 rounded-xl">
        <GPTProductLink setProductLink={setProductLink} />

        {/* ProductInfo And First Review Section */}
        <ProductFirstReview
          firstReview={productReviews[0]}
          productInfo={productInfo}
          isLoading={isLoading}
        />

        {/* Started Review Analysis BTN */}
        <div className="flex w-full justify-center mt-8">
          <button
            id="gptBox"
            disabled={isLoading ? true : false}
            onClick={(e) => startGptTool(e)}
            className="text-lg font-medium bg-headerPrimary text-white rounded-lg border border-headerPrimary px-10 py-3"
          >
            {t?.('amazon-review-analysis.startAnalyze')}
          </button>
        </div>

        {/* GPT BOX */}
        <GPTReviewBox isLoading={isLoading} gptAnalysis={gptAnalysis!} />

        {/* All Reviews Section */}
        <ProductReviews isLoading={isLoading} productReviews={productReviews} />
      </div>
    </>
  );
};

export default AmazonReviewAnalysis;

//----------- Dummy Data ------------
// To Show Client What is that doing.....
const dummyGptAnalysis = [
  `Kalitesiz Malzeme ve İşçilik: Müşteriler, ürünün ucuz ve kalitesiz olduğunu belirtmişler. Ürünün daha kaliteli malzemelerle üretilmesi ve daha özenli işçilikle montajının yapılması gerekiyor.`,
  `Gitarların Güvenliği: Gitarların raf üzerinde güvenli bir şekilde durmadığından bahsedilmiş. Bu, ürünün en önemli fonksiyonlarından birini yerine getiremediği anlamına geliyor. Daha sağlam ve gitarları güvenle tutabilecek bir tasarım düşünülebilir.`,
  ` Montaj Sorunları: Ürünün montajının zor olduğu ve bazı parçaların (özellikle vidaların) düzgün şekilde çalışmadığı belirtilmiş. Bu da müşteri memnuniyetini düşürüyor. Ürünün montajını kolaylaştıracak şekilde tasarlanması ve tüm parçaların düzgün çalışır durumda olması gerekiyor.`,
  `Eksik Parçalar: Bazı müşteriler ürünle birlikte gerekli parçaların gelmediğini belirtmiş. Kalite kontrol süreçlerini gözden geçirerek bu tür hataları önleyebilirsiniz.`,
  `Dayanıklılık Sorunu: Müşteri yorumlarına göre, ürünün uzun vadede dayanıklı olmadığı ve bazı parçalarının (örneğin kauçukların) zamanla bozulduğu belirtilmiş. Daha dayanıklı malzemeler kullanarak bu sorunu çözebilirsiniz.
Bu geri bildirimleri dikkate alarak, ürününüzü daha kaliteli malzemelerle, daha sağlam ve kullanıcı dostu bir tasarımla ve daha iyi bir montaj kılavuzu ile üretmeyi düşünebilirsiniz. Ayrıca, ürününüzün gitarları güvenli bir şekilde tuttuğunu ve uzun süre dayandığını vurgulayarak pazarlamasını yapabilirsiniz.`,
];

const dummyProductInfo: IProductInfoForReviewTool = {
  productTitle:
    'Kuyal 5 Holder Guitar Stand, Multi-Guitar Display Rack Folding Stand Band Stage Bass Acoustic Guitar, Black',
  productImageUrl:
    'https://m.media-amazon.com/images/I/71C4VyMAkaL._AC_SX679_.jpg',
};

const dummyProductReviews: IReview[] = [
  {
    id: 1,
    clientImageUrl: undefined,
    rating: 1,
    clientName: 'Blade Bloodchild',
    reviewMetaInfo: 'Reviewed in United States on April 28,2016',
    reviewSummary: 'Recommended For No One',
    reviewText: `1: Cheaply made, like more cheaply than i paid kind of cheap. You could literally build one out of scrap for the same price and have equal quality. If i wanted to build guitar stands that is what i would be doing instead of going to school and writing music; but i do not want to do that, hence this misguided purchase. 2: Guitars are NOT safe or secure in this rack. Will even attempt to put my Peavey Vortex on this, but in fairness V-shape bodies are always difficult to place on a stand/rack. Product description claims "Width between peg holders 2.75''." Centerline to centerline they are actually 5'' apart, leaving roughly 4.25'' with the padding. This leaves WAY too much room for the neck to slide around in and cause the bodies of the guitars to hit each other. If you like your guitars without scratches and dings, this rack is worthless.3: Assembly was basically "Here's your parts. Good luck." Had to remove padding from pegs and use pliers to screw forcibly into tubing as the holes were apparently drilled but not threaded...`,
  },
  {
    id: 2,
    clientImageUrl: undefined,
    rating: 1,
    clientName: 'Robet Fox',
    reviewMetaInfo: 'Reviewed in United States on February 02, 2023',
    reviewSummary: 'I wanted a cheap one and I got a cheap one...',
    reviewText: `Don't believe any of the positive reviews here. The photos that show more than one guitar sitting on the stand, one next to the other in harmony? That's BS. You have to be an extremely cautious and super talented human being to be able to put more than one guitar on this stand without banging the guitars together and scratching them over time.Do not get this if you care at all about the exterior of your guitars. Trust me - if you decide to go with the majority of positive reviews like I did, please just keep this review as a reminder that sometimes, the minority is actually in the right.All that without even mentioning the low quality of this product. The instructions were made by a DIY specialist: it's a list of the package contents and then a sentence that goes like: "install in the order of the parts in the list"... that's it!Luckily for me, I have built some IKEA stuff in the past (LOL), so I was able to just build it without instructions, but seriously? You make a product, how difficult is it to create a proper set of instructions that go along with it?But the worst part of the installation was that one of the brackets that suppose to be used as a guide to one of the bars was WELDED not in a 90 degree position, so there was no option to fit the bar in without using brute force. Ironically, it was a good thing that the material is so thin and cheap that I was able to literally bend the bracket to let the bar go through it.I can go more about it, but I thing you get the idea. Don't get it, or... good luck.`,
  },
  {
    id: 3,
    clientImageUrl: undefined,
    rating: 0.5,
    clientName: 'Brooklyn Simmons',
    reviewMetaInfo: 'Reviewed in United States on December 12, 2022',
    reviewSummary: 'Horrible and cheap',
    reviewText: `A screw was not threaded properly - literally the threads just go around and around in a circle, they do not spiral down. Will not thread. Pointless. Returning. Too expensive for a cheaply made item from China. Nope.`,
  },
  {
    id: 4,
    clientImageUrl: undefined,
    rating: 2,
    clientName: 'Jenny Wilson',
    reviewMetaInfo: 'Reviewed in United States on August 18, 2022',
    reviewSummary: 'Disappointing, even for the price',
    reviewText: `Yeah, this thing is even worse than what you would expect from China. Assembly was straight forward, but it doesn’t fit together with anything resembling that of CNC precision. It took some convincing to get this heap of recycled tuna cans into one piece.The design is utterly flawed as any style guitar from my Strats to Les Paul shaped don’t make a solid and confident contact with any of the padded surfaces, leaving you to having to get creative with the guitar placement 7 different ways - only to give it the double take upon walking away to ensure nothing is falling over.I would not trust this piece of recycled garbage with ANY of your valued instruments. In fact, I fully intend of selling this thing for $20 on Craigslist as soon as I’m finished writing this review.Do yourself a favor and just spend a little extra to purchase something trusted from a reputable guitar shop or website. My 15 year old stand from guitar center absolutely blows this thing away in terms of quality, design, and confidence.Do not recommend unless you wanna buy mine for half the price... and I would still feel bad selling it to you.`,
  },
  {
    id: 5,
    clientImageUrl: undefined,
    rating: 1.5,
    clientName: 'Ralph Edwards',
    reviewMetaInfo: 'Reviewed in United States on May 19, 2022',
    reviewSummary: 'Missing all hardware',
    reviewText: `All the hardware was missing. Was a waste of time. On the second I ordered the pegs would not screw in as nothing was tapped or poorly sped so nothing would thread. Go with another brand save the hassle`,
  },
  {
    id: 6,
    clientImageUrl: undefined,
    rating: 1,
    clientName: 'Wade Warren',
    reviewMetaInfo: 'Reviewed in United States on May 16, 2022',
    reviewSummary: 'DO NOT TRUST YOUR GUITARS WITH THIS STAND!!!!!',
    reviewText: `NOT A QUALITY PRODUCT! This guitar stand will not hold your guitars securely. The holes on the upper crossbar that hold the neck pegs were not drilled to hold screws. Even if a peg does fully screw in to the crossbar, it doesn't fit securely and will pry out of the hole when a guitar rests on it. The two bottom crossbars do not hold the guitar bodies securely; guitars slide and pivot unless perfectly placed on the stand. This problem is compounded if the neck peg is loose. I could hardly get one guitar to rest on this rack without fear of having it shift and fall, I wasn't about to try all of my guitars on it. I waited too long for this stand and it wasn't worth it. If you try to return it they will ask you to pay for shipping. Thankfully Amazon is willing to refund the shipping.`,
  },
  {
    id: 7,
    clientImageUrl: undefined,
    rating: 2,
    clientName: 'Jerome Bell',
    reviewMetaInfo: 'Reviewed in United States on May 12, 2022',
    reviewSummary: 'Unable to use',
    reviewText: `The product did not contain the necessary hardware to assemble it. I returned it.`,
  },
  {
    id: 8,
    clientImageUrl: undefined,
    rating: 1.5,
    clientName: 'Bessie Cooper',
    reviewMetaInfo: 'Reviewed in United States on May 02, 2022',
    reviewSummary: 'Not great',
    reviewText: `You get what you pay for. This was difficult to put together. The parts that need to be screwed in do not thread together easily. Once assembled the guitars are not sturdy and move too much and knock against each other. I requested to have the item returned however I must pay to ship it back because I did not assemble it correctly? So confused. I should not have to pay to ship back a product that does not work as it is supposed to. I am unhappy with this product and should not pay to have it shipped back to the seller!`,
  },
  {
    id: 9,
    clientImageUrl: undefined,
    rating: 0.5,
    clientName: 'Jacob Jones',
    reviewMetaInfo: 'Reviewed in United States on April 16, 2022',
    reviewSummary: 'Not worth the money',
    reviewText: `You can tell this thing was made as cheap as possible. The holes are not drilled correctly along the top where the pegs screw in, so it’s not a tight fit. Genuinely afraid it might not hold my guitars in place and allow them to fall into each other. There was also a sticky residue on one of the foam pieces along the bottom. Tried alcohol wipes and Clorox wipes and it still wouldn’t come off. Ended up turning it so it faced the floor, but it could ruin your guitars finish if it touches it. Just stay away from this one and spent the extra couple bucks to get a nice one.`,
  },
];

//----------- Dummy Data ------------
