import { Calendar, Category, Convertshape, Location } from 'iconsax-react';
import React from 'react';
import { IShop } from '../../pages/EtsyStoreAnalayze';
import { convertDate } from '../../utils/dateFormatter';
import LoadingComponent from '../common/LoadingComponent';
import { useLanguage } from '../../context/LanguageContext';
import { classNames } from '../../utils/conditionalClasses';

interface StoreInfoProps {
  shop: IShop;
  isLoading: boolean;
}
const StoreInfo: React.FC<StoreInfoProps> = ({ shop, isLoading }) => {
  const { language } = useLanguage();
  console.log(language);
  return (
    <div className="rounded-lg shadow-md w-full py-4 px-2 md:px-4 bg-white">
      <div className="flex justify-start items-center ">
        {isLoading ? (
          <div className="w-12 h-12 bg-lightGray animate-pulse"></div>
        ) : (
          <img
            src={shop.icon}
            className="object-contain object-center w-12 h-12"
          />
        )}

        <p className="ml-2 w-full uppercase font-extrabold text-lg text-description">
          {isLoading ? <LoadingComponent /> : shop.shopName}
        </p>
      </div>
      <div className="mt-4 text-[13px] lg:text-[15px]  grid grid-cols-2 gap-5  sm:grid-cols-2 sm:gap-6 xl:grid-cols-4">
        <div className="col-span-1 ">
          <div className="flex items-center xl:justify-start ">
            <Calendar
              size="32"
              color="#0F1224"
              className="bg-lightGray rounded-full p-2 w-12 h-12 mr-1"
            />
            <span
              className={classNames(
                language === 'tr' ? '' : 'text-xs',
                'text-right'
              )}
            >
              {language === 'tr' ? 'Mağaza Yaşı: ' : 'Store Age: '}
              {isLoading ? (
                <LoadingComponent />
              ) : (
                <span className="text-xs">
                  {convertDate(shop.createDate, language)}
                </span>
              )}
            </span>
          </div>
        </div>
        <div className="col-span-1">
          <div className="flex items-center xl:justify-center ">
            <Location
              size="32"
              color="#0F1224"
              className="bg-lightGray rounded-full p-2 w-12 h-12 mr-1"
            />
            <span>
              {language === 'tr' ? 'Konum: ' : 'Location: '}
              {isLoading ? <LoadingComponent /> : shop.etsyCountry.name}
            </span>
          </div>
        </div>
        <div className="col-span-1 ">
          <div className="flex items-center xl:justify-center">
            <Category
              size="32"
              color="#0F1224"
              className="bg-lightGray rounded-full p-2 w-12 h-12 mr-1"
            />
            <span>
              {language === 'tr' ? 'Kategori: ' : 'Category: '}
              {isLoading ? (
                <LoadingComponent />
              ) : shop.etsyTaxonomy ? (
                shop.etsyTaxonomy.taxonomyName
              ) : (
                '-'
              )}
            </span>
          </div>
        </div>
        <div className="col-span-1 ">
          <div className="flex items-center xl:justify-center">
            <Convertshape
              size="32"
              color="#0F1224"
              className="bg-lightGray rounded-full p-2 w-12 h-12 mr-1"
            />
            <span>
              {language === 'tr' ? 'Ürün Tipi: ' : 'Product Type: '}
              {isLoading ? <LoadingComponent /> : shop.etsyProductType.type}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreInfo;
