import { Disclosure } from '@headlessui/react';
import React from 'react';
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline';
import { Question } from '../../pages/RexvenAI';

type Props = {
  questions: Question[];
};

const OldQuestions = ({ questions }: Props) => {
  return (
    <div className="mx-auto w-[95%] p-x-0 mt-6 pb-3 lg:px-4">
      <div className="mx-auto w-full grid grid-cols-6 gap-y-4 xl:gap-x-10">
        {questions?.map((question) => (
          <QuestionItem key={question.id} {...question} />
        ))}
      </div>
    </div>
  );
};

export default OldQuestions;

const QuestionItem: React.FC<Question> = ({
  id,
  question: questionText,
  answer,
}) => {
  return (
    <Disclosure
      as="div"
      key={id}
      className="col-span-12 p-4 border-b-2 border-lightGray"
    >
      {({ open }) => (
        <>
          <dt>
            <Disclosure.Button className="flex w-full items-center justify-between text-left text-gray-900">
              <span className="text-xs font-medium leading-7 lg:text-base">
                {questionText}
              </span>
              <span className="ml-6 flex items-center ">
                {open ? (
                  <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                ) : (
                  <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                )}
              </span>
            </Disclosure.Button>
          </dt>
          <Disclosure.Panel as="dd" className="mt-2 pr-12">
            <p className="text-xs lg:text-base leading-7 text-gray-600">
              {answer}
            </p>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};
