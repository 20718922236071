import { Formik } from 'formik';
import React, { useState } from 'react';
import * as Yup from 'yup';

import { useAuth } from '../../context/AuthContext';
import { useLanguage } from '../../context/LanguageContext';
import { useNumberVerification } from '../../context/NumberVerificationContext';
import { classNames } from '../../utils/conditionalClasses';
import { errorElement } from '../../utils/errorElement';

interface EntegrationFormProps {
  onSubmit: (values: any) => Promise<void>;
  loading: boolean;
  integratedInfo: boolean;
}

export interface EntegrationFormGet {
  platform: string;
  keys: Key[];
  producerId: number;
  producer: Producer;
  isAccepted: boolean
}

export interface Key {
  apiKey: string;
  sellerId: string;
  apiSecret: string;
  isAccepted: boolean
}

export interface Producer {
  producerName: string;
  phoneNumber: any;
  status: 'active' | 'passive' | string;
  logo: string;
}

const EntegrationForm: React.FC<EntegrationFormProps> = ({
  onSubmit,
  loading,
  integratedInfo,
}) => {
  const { t } = useLanguage();
  const { setNumVerifyModal } = useNumberVerification()
  const { session } = useAuth();
  // const [isAccepted, setIsAccepted] = useState(true); // Checkbox durumu için state

  let btnContent: any;
  if (loading) {
    btnContent = (
      <div className="text-center flex items-center">
        <div
          className="inline-block h-5 w-5 animate-spin rounded-full border-4 border-solid border-current border-r-transparent motion-reduce:animate-[spin_1.5s_linear_infinite]"
          role="status"
        ></div>
        <span className="text-white text-sm ml-3">Değerlendiriliyor...</span>
      </div>
    );
  } else if (integratedInfo) {
    btnContent = 'Tamamlandı';
  } else {
    btnContent = t?.('common.buttons.register');
  }

  // console.log(integratedInfo);

  return (
    <div className="flex flex-col flex-1 flex-shrink  ">
      <div className="py-4 px-6">
        <Formik
          initialValues={{
            platform: 'trendyol',
            sellerId: '',
            apiKey: '',
            apiSecret: '',
            discountRate: 25,
            isAccepted: false
          }}
          onSubmit={onSubmit}
          validationSchema={Yup.object({
            sellerId: Yup.string().required(t?.('integration.errors.sellerId')),
            apiKey: Yup.string().required(t?.('integration.errors.apiKey')),
            apiSecret: Yup.string().required(
              t?.('integration.errors.apiSecret')
            ),
          })}
        >
          {({ handleSubmit, values, handleChange, errors }) => (
            <form className="flex  flex-col gap-y-6" onSubmit={handleSubmit}>
              {/* {errors.platform && errorElement(errors.platform)} */}

              <div className="flex justify-between items-center gap-x-3 sm:gap-x-1.5">
                <label
                  htmlFor="sellerId"
                  className="font-medium text-xs w-2/12 lg:w-3/12 xl:text-sm"
                >
                  {t?.('integration.page.seller-id')}
                </label>
                <div className="flex flex-1 justify-between gap-x-2">
                  <input
                    value={values?.sellerId}
                    // placeholder={integratedInfo?.keys?.[0].sellerId!}
                    onChange={handleChange}
                    data-testid="sellerId-input"
                    type="text"
                    name="sellerId"
                    id="sellerId"
                    className="flex w-full text-xs rounded-md bg-lightGray border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-headingPrimary sm:text-sm sm:leading-6"
                    readOnly={integratedInfo}
                  />
                </div>
              </div>

              {errors.sellerId && errorElement(errors.sellerId)}

              <div className="flex justify-between items-center gap-x-3 sm:gap-x-1.5">
                <label
                  htmlFor="apiKey"
                  className="font-medium text-xs w-2/12 lg:w-3/12 xl:text-sm"
                >
                  API Key
                </label>
                <div className="flex flex-1 justify-between gap-x-2">
                  <input
                    value={values.apiKey}
                    readOnly={integratedInfo}
                    // placeholder={
                    //   integratedInfo?.keys?.[0].apiKey ? '***********' : ''
                    // }
                    onChange={handleChange}
                    data-testid="api-key-input"
                    type="text"
                    name="apiKey"
                    id="apiKey"
                    className="flex w-full text-xs rounded-md bg-lightGray border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-headingPrimary sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              {errors.apiKey && errorElement(errors.apiKey)}

              <div className="flex justify-between items-center gap-x-3 sm:gap-x-1.5">
                <label
                  htmlFor="apiSecret"
                  className="font-medium text-xs w-2/12 lg:w-3/12 xl:text-sm"
                >
                  API Secret
                </label>
                <div className="flex flex-1 justify-between gap-x-2">
                  <input
                    value={values.apiSecret}
                    readOnly={integratedInfo}
                    // placeholder={
                    //   integratedInfo?.keys?.[0].apiSecret ? '***********' : ''
                    // }
                    onChange={handleChange}
                    data-testid="api-secret-input"
                    type="text"
                    name="apiSecret"
                    id="apiSecret"
                    className="flex w-full rounded-md text-xs bg-lightGray border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-headingPrimary sm:text-sm sm:leading-6 "
                  />
                </div>
              </div>

              {errors.apiSecret && errorElement(errors.apiSecret)}
              <div className="flex justify-between items-center gap-x-3 sm:gap-x-1.5">
                <label
                  htmlFor="isAccepted"
                  className="font-medium text-xs w-2/12 lg:w-3/12 xl:text-sm"
                >
                  Tedarikçi Olmak İstemiyorum
                </label>
                <div className="flex flex-1 justify-between gap-x-2">
                  <input
                    checked={values.isAccepted} // State'ten gelen duruma göre tersini al
                    onChange={(e) => {
                      handleChange(e);
                      // setIsAccepted(!isAccepted); // State'in durumunu tersine çevir
                      if (values.isAccepted) {
                        handleChange({
                          target: { name: 'discountRate', value: 0 } // discountRate değerini 0 olarak ayarla
                        });
                      }
                    }}
                    id="isAccepted"
                    aria-describedby="comments-description"
                    name="isAccepted"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-headingPrimary focus:ring-headingPrimary"
                  />
                </div>
              </div>
              {/* <div className="flex justify-between items-center gap-x-3 sm:gap-x-1.5"> */}
              <div className="text-sm">
                {/* <label
                  htmlFor="apiSecret"
                  className="font-medium text-xs w-2/12 lg:w-3/12 xl:text-sm"
                >
                  İndirim Oranı
                </label>
                <div className="flex flex-1 justify-between gap-x-2">
                  <input
                    value={!values.isAccepted ? values.discountRate : 0}
                    readOnly={values.isAccepted || integratedInfo}
                    placeholder="%"
                    onChange={handleChange}
                    data-testid="discount-rate-input"
                    type="number"
                    min={0}
                    name="discountRate"
                    id="discountRate"
                    className={classNames(values.isAccepted ? 'bg-[#f2f2f2]' : "", "flex w-full rounded-md text-xs bg-lightGray border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-headingPrimary sm:text-sm sm:leading-6")}
                  />
                </div> */}
                Ürünlerin fiyatları Trendyol fiyatlarınızın
                <span className='text-rexRed font-semibold italic'> %50 indirimli halinde temsilen </span>
                gösterilecek. Satıcı ile alacağı ürün miktarına göre özel fiyat anlaşması yapabilirsiniz.
              </div>
              {errors.apiSecret && errorElement(errors.apiSecret)}
              {!session?.isGsmVerified ?
                <div className="flex items-center border-t-2 border-lightGray w-full justify-between gap-x-3 ">
                  <p className='text-rexRed text-sm'>
                    Entegre olabilmek için lütfen telefon numaranızı doğrulayın.
                  </p>
                  <div
                    onClick={() => setNumVerifyModal?.(true)}
                    className="mt-4 rounded-md bg-headerPrimary py-2.5 px-8 text-xs font-semibold text-white shadow-sm hover:bg-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 md:text-sm cursor-pointer"
                  >
                    Doğrulama Kodu Al
                  </div>

                </div> :
                <div className="flex items-center border-t-2 border-lightGray w-full justify-end gap-x-3 ">
                  {integratedInfo && (
                    <p className="text-xs sm:text-sm text-rexRed ">
                      Bir platforma yalnızca bir mağaza entegre edebilirsiniz
                    </p>
                  )}
                  {session?.isGsmVerified &&
                    <button
                      disabled={loading || integratedInfo}
                      type="submit"
                      className="mt-4 rounded-md bg-headerPrimary py-2.5 px-8 text-xs font-semibold text-white shadow-sm hover:bg-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 md:text-sm"
                    >
                      {btnContent}
                    </button>
                  }
                </div>
              }
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EntegrationForm;
