import { Fragment, useEffect, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import TempChart from './TempChart';
import amazonToolsService from '../../../../services/amazonToolsService';
import { classNames } from '../../../../utils/conditionalClasses';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  keyword: string;
}
const options = {
  chart: {
    toolbar: {
      show: false,
    },
    events: {
      mounted: function (chartContext: any, config: any) {
        window.dispatchEvent(new Event('resize'));
      },
    },
    redrawOnParentResize: true,
    redrawOnWindowResize: true,
    zoom: {
      enabled: false,
    },
  },

  grid: {
    row: {
      colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
      opacity: 0.5,
    },
  },
  xaxis: {
    categories: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],

    labels: {
      style: {
        fontSize: '11px',
      },
    },
  },

  colors: ['#3C468F', '#B7BCE1'],
};
const SearchVolumeChartModal: React.FC<Props> = ({
  open,
  setOpen,
  keyword,
}) => {
  const series = [
    {
      name: 'Hacim',
      data: [2000, 3000, 4000, 5000, 4500, 7000, 7400, 9000, 10000],
    },
  ];
  const [chartOptions, setChartOptions] = useState(options);
  const [seriesData, setSeriesData] = useState(series);
  const [selectedDate, setSelectedDate] = useState('all');
  const [filteredData, setFilteredData] = useState<any>(seriesData);
  const [response, setResponse] = useState<any>();

  const getHistoryData = async () => {
    try {
      const res: any = await amazonToolsService.getKeywordHistory(keyword);
      setResponse(res.data);
      const tempOptions = res.data.map((item: any) => {
        return item.referenceDate;
      });
      setChartOptions({
        ...chartOptions,
        xaxis: {
          categories: tempOptions,
          labels: {
            style: {
              fontSize: '11px',
            },
          },
        },
      });
      const tempSeries = res.data.map((item: any) => {
        return item.volume;
      });
      setSeriesData([
        {
          name: 'Hacim',
          data: tempSeries,
        },
      ]);
      setFilteredData([
        {
          name: 'Hacim',
          data: tempSeries,
        },
      ]);
    } catch (error) {}
  };

  const handleFilter = (date: string) => {
    let filterDate: Date | null = new Date();

    switch (date) {
      case 'last30':
        filterDate.setDate(filterDate.getDate() - 30);
        break;
      case 'last90':
        filterDate.setDate(filterDate.getDate() - 90);
        break;
      case 'last180':
        filterDate.setDate(filterDate.getDate() - 180);
        break;
      case 'lastYear':
        filterDate.setFullYear(filterDate.getFullYear() - 1);
        break;
      default:
        filterDate = null;
        break;
    }
    const temp = response.filter((item: any) => {
      return new Date(item.referenceDate) >= filterDate!;
    });
    const tempOptions = temp.map((item: any) => {
      return item.referenceDate;
    });
    setChartOptions({
      ...chartOptions,
      xaxis: {
        categories: tempOptions,
        labels: {
          style: {
            fontSize: '11px',
          },
        },
      },
    });
    const tempSeries = temp.map((item: any) => {
      return item.volume;
    });
    setSeriesData([
      {
        name: 'Hacim',
        data: tempSeries,
      },
    ]);
    setFilteredData([
      {
        name: 'Hacim',
        data: tempSeries,
      },
    ]);
  };

  useEffect(() => {
    if (keyword) getHistoryData();
  }, [keyword]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-x-auto rounded-lg bg-white  pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 w-[370px] sm:w-[600px]">
                {/* <div className="w-[550px]"> */}
                <div className="flex px-4 space-x-2">
                  <button
                    className={classNames(
                      selectedDate === 'last30'
                        ? 'bg-headerPrimary text-white'
                        : 'border-2 border-headerPrimary text-headerPrimary',
                      'px-4 py-1 rounded-md border-2 border-headerPrimary text-headerPrimary text-sm'
                    )}
                    onClick={() => {
                      setSelectedDate('last30');
                      handleFilter('last30');
                    }}
                  >
                    30 Gün
                  </button>
                  <button
                    className={classNames(
                      selectedDate === 'last90'
                        ? 'bg-headerPrimary text-white'
                        : 'border-2 border-headerPrimary text-headerPrimary',
                      'px-4 py-1 rounded-md border-2 border-headerPrimary text-headerPrimary text-sm'
                    )}
                    onClick={() => {
                      setSelectedDate('last90');
                      handleFilter('last90');
                    }}
                  >
                    90 Gün
                  </button>
                  <button
                    className={classNames(
                      selectedDate === 'last180'
                        ? 'bg-headerPrimary text-white'
                        : 'border-2 border-headerPrimary text-headerPrimary',
                      'px-4 py-1 rounded-md border-2 border-headerPrimary text-headerPrimary text-sm'
                    )}
                    onClick={() => {
                      setSelectedDate('last180');
                      handleFilter('last180');
                    }}
                  >
                    180 Gün
                  </button>
                  <button
                    className={classNames(
                      selectedDate === 'lastYear'
                        ? 'bg-headerPrimary text-white'
                        : 'border-2 border-headerPrimary text-headerPrimary',
                      'px-4 py-1 rounded-md border-2 border-headerPrimary text-headerPrimary text-sm'
                    )}
                    onClick={() => {
                      setSelectedDate('lastYear');
                      handleFilter('lastYear');
                    }}
                  >
                    1 Yıl
                  </button>
                  <button
                    onClick={() => {
                      setSelectedDate('all');
                      handleFilter('all');
                    }}
                    className={classNames(
                      selectedDate === 'all'
                        ? 'bg-headerPrimary text-white'
                        : 'border-2 border-headerPrimary text-headerPrimary',
                      'px-4 py-1 rounded-md border-2 border-headerPrimary text-headerPrimary text-sm'
                    )}
                  >
                    Tüm Zamanlar
                  </button>
                </div>
                <TempChart options={chartOptions} series={seriesData} />
                {/* </div> */}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SearchVolumeChartModal;
