import React, { lazy, Suspense, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import LoadingComponent from '../components/common/LoadingComponent';
import ProductNotFound from '../components/common/ProductNotFound';
import EtsyHotProductTable from '../components/etsyHotProducts/EtsyHotProductTable';
import FilteringEtsyHotProducts from '../components/etsyHotProducts/FilteringEtsyHotProducts';
import BannerLoading from '../components/tools/common/BannerLoading';
import BuyModal from '../components/tools/etsy/BuyModal';
import { useAuth } from '../context/AuthContext';
import {
    ICategoryRoot, ICountryRoot, IEtsyHotProductsRoot, IHotProductsQuery, IProductTypeRoot
} from '../interfaces/etsyToolsInterfaces';
import etsyToolsService from '../services/etsyToolsService';
import memberService from '../services/memberService';
import ErrorFallback from './errors/ErrorFallback';

const NewBanner = lazy(() => import('../components/tools/common/NewBanner'));

const EtsyHotProducts = () => {
  // FILTERING STATES
  const [open, setOpen] = useState(false);
  const { session } = useAuth();
  const [page, setPage] = useState<number>(1);
  const [queryParams, setQueryParams] = useState<IHotProductsQuery>({
    etsyCountryId: '',
    createDate: '',
    productCreateDate: '',
    taxonomyId: '',
    etsyProductTypeId: '',
    saleCountMin: '',
    saleCountMax: '',
    priceMin: '',
    priceMax: '',
    revenueMin: '',
    revenueMax: '',
    reviewCountMin: '',
    reviewCountMax: '',
    viewCountMin: '',
    viewCountMax: '',
    title: ''
  });
  const [sortQuery, setSortQuery] = useState<{
    orderBy: string;
    order: string;
  }>({
    orderBy: 'dailySale',
    order: 'DESC',
  });
  const [inputPage, setInputPage] = useState<number | string>(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isTabActive, setIsTabActive] = React.useState<boolean>(true);

  const [queryString, setQueryString] = useState('?page=1');
  const location = useLocation();
  const navigate = useNavigate();

  const filterSubmit = async (values: any) => {
    if (!session?.hasPackage) {
      setOpen(true);
    } else {
      setQueryParams(values);
      navigate(
        `?page=1&countryId=${values?.etsyCountryId}&createDate=${values?.createDate}&taxonomyId=${values?.taxonomyId}&etsyProductTypeId=${values?.etsyProductTypeId}&productCreateDate=${values?.productCreateDate}&saleCountMin=${values?.saleCountMin}&saleCountMax=${values?.saleCountMax}&priceMin=${values?.priceMin}&priceMax=${values?.priceMax}&revenueMin=${values?.revenueMin}&revenueMax=${values?.revenueMax}&reviewCountMin=${values?.reviewCountMin}&reviewCountMax=${values?.reviewCountMax}&viewCountMin=${values?.viewCountMin}&viewCountMax=${values?.viewCountMax}&title=${values.title}`
      );
      setQueryString(
        `countryId=${values?.etsyCountryId}&createDate=${values?.createDate}&taxonomyId=${values?.taxonomyId}&etsyProductTypeId=${values?.etsyProductTypeId}&productCreateDate=${values?.productCreateDate}&saleCountMin=${values?.saleCountMin}&saleCountMax=${values?.saleCountMax}&priceMin=${values?.priceMin}&priceMax=${values?.priceMax}&revenueMin=${values?.revenueMin}&revenueMax=${values?.revenueMax}&reviewCountMin=${values?.reviewCountMin}&reviewCountMax=${values?.reviewCountMax}&viewCountMin=${values?.viewCountMin}&viewCountMax=${values?.viewCountMax}&title=${values.title}`
      );
      setPage(1);
      // refetch();
    }
  };

  const categories = useQuery<ICategoryRoot, Error>(['etsyCategories'], {
    queryFn: () => etsyToolsService.getCategories(),
    staleTime: Infinity,
  });

  //GET PRODUCT TYPES
  const productTypes = useQuery<IProductTypeRoot, Error>(['etsyProductTypes'], {
    queryFn: () => etsyToolsService.getProductTypes(),
    staleTime: Infinity,
  });

  //GET COUNTRIES
  const countries = useQuery<ICountryRoot, Error>(['etsyCountries'], {
    queryFn: () => etsyToolsService.getCountries(),
    staleTime: Infinity,
  });

  const { isLoading, error, data, refetch } = useQuery<
    IEtsyHotProductsRoot,
    Error
  >(['hotProducts', { page, sortQuery, queryParams }], {
    queryFn: () =>
      etsyToolsService.getHotProducts(page, sortQuery, queryParams!),

    staleTime: Infinity,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    let temp = Object.fromEntries([...searchParams]);
    let tempPage = temp.page;

    if (searchParams.has('countryId')) {
      setPage(Number(tempPage));
      setInputPage(Number(tempPage));
      setQueryParams({ ...temp, etsyCountryId: temp.countryId });
      setQueryString(
        `countryId=${temp?.countryId}&createDate=${temp?.createDate}&taxonomyId=${temp?.taxonomyId}&etsyProductTypeId=${temp?.etsyProductTypeId}&productCreateDate=${temp?.productCreateDate}&saleCountMin=${temp?.saleCountMin}&saleCountMax=${temp?.saleCountMax}&priceMin=${temp?.priceMin}&priceMax=${temp?.priceMax}&revenueMin=${temp?.revenueMin}&revenueMax=${temp?.revenueMax}&reviewCountMin=${temp?.reviewCountMin}&reviewCountMax=${temp?.reviewCountMax}&viewCountMin=${temp?.viewCountMin}&viewCountMax=${temp?.viewCountMax}&title=${temp.title}`
      );
    } else if (!searchParams.has('countryId') && searchParams.has('page')) {
      setPage(Number(tempPage));
      setInputPage(Number(tempPage));
      setQueryParams({
        etsyCountryId: '',
        createDate: '',
        productCreateDate: '',
        taxonomyId: '',
        etsyProductTypeId: '',
        saleCountMin: '',
        saleCountMax: '',
        priceMin: '',
        priceMax: '',
        revenueMin: '',
        revenueMax: '',
        reviewCountMin: '',
        reviewCountMax: '',
        viewCountMin: '',
        viewCountMax: '',
        title: ''
      });
    } else {
      setPage(1);
      setInputPage(1);
      setQueryParams({
        etsyCountryId: '',
        createDate: '',
        productCreateDate: '',
        taxonomyId: '',
        etsyProductTypeId: '',
        saleCountMin: '',
        saleCountMax: '',
        priceMin: '',
        priceMax: '',
        revenueMin: '',
        revenueMax: '',
        reviewCountMin: '',
        reviewCountMax: '',
        viewCountMin: '',
        viewCountMax: '',
        title: ''
      });
    }
  }, [page, location.search]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabActive(document.visibilityState === 'visible');
    };

    // Add event listener for visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup function to remove the event listener when the component is unmounted
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    let intervalId: any;
    if (session && isTabActive) {
      intervalId = setInterval(async () => {
        await memberService.userTrackReq('etsy-hot-products', session?.id!);
      }, 60000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [session, isTabActive]);
  return (
    <>
      <BuyModal open={open} setOpen={setOpen} />
      <Suspense fallback={<BannerLoading />}>
        <NewBanner
          bgUrl={'/images/etsy-hot-products-bg.webp'}
          toolImageUrl="etsy-hot-products"
        />
      </Suspense>

      <div className="rounded-xl bg-white p-4 my-8 ">
        {/* FILTERING DATA */}
        <FilteringEtsyHotProducts
          categories={categories?.data?.data!}
          productTypes={productTypes?.data?.data!}
          countries={countries?.data?.data!}
          filterSubmit={filterSubmit}
        />
        {/* FILTERING DATA */}
      </div>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {isLoading ? (
          <LoadingComponent />
        ) : data?.data.products.length! > 0 ? (
          <EtsyHotProductTable
            totalPageCount={data?.data?.totalPageCount!}
            data={data?.data?.products!}
            setOpen={setOpen}
            setPage={setPage}
            page={page}
            setSortQuery={setSortQuery}
            isLoading={isLoading}
            categories={categories?.data?.data!}
            countries={countries?.data?.data!}
            productTypes={productTypes?.data?.data!}
            inputPage={inputPage}
            setInputPage={setInputPage}
            queryString={queryString}
          />
        ) : (
          <ProductNotFound />
        )}
      </ErrorBoundary>
    </>
  );
};

export default EtsyHotProducts;
