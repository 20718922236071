import { Box } from 'iconsax-react';
import React, { Dispatch, SetStateAction } from 'react';

import { useLanguage } from '../../context/LanguageContext';
import { errorElement } from '../../utils/errorElement';
import AddressPatch from './elements/AddressPatch';
import ProductTransactionsTable from './elements/ProductTransactionsTable';
import ShipEntegraCustomSelect from './elements/ShipEntegraCustomSelect';

interface ProductTransactionsProps {
    selectedCurrencies: {
        name: string;
        value: string;
        error: boolean
    }
    setSelectedCurrencies: Dispatch<SetStateAction<{
        name: string;
        value: string;
        error: boolean
    }>>
    products: {
        id: number;
        quantity: number | null;
        unitPrice: number | null;
        name: string | null;
        sku: string | null;
        gtip: string | null;
        quantityErr: boolean, // required
        unitPriceErr: boolean, // required
        nameErr: boolean,
    }[],
    setProducts: Dispatch<SetStateAction<{
        id: number;
        quantity: number | null;
        unitPrice: number | null;
        name: string | null;
        sku: string | null;
        gtip: string | null;
        quantityErr: boolean, // required
        unitPriceErr: boolean, // required
        nameErr: boolean,
    }[]>>
    description: {
        text: string,
        error: boolean
    }
    setDescription: Dispatch<SetStateAction<{
        text: string,
        error: boolean
    }>>
}
const ProductTransactions: React.FC<ProductTransactionsProps> = ({
    selectedCurrencies,
    setSelectedCurrencies,
    products,
    setProducts,
    description,
    setDescription
}) => {
    const { t } = useLanguage();
    const currencies = [
        { name: 'USD', value: 'USD' },
        { name: 'EUR', value: 'EUR' },
        { name: 'GBP', value: 'GBP' },
        { name: 'SAR', value: 'SAR' },
        { name: 'AUD', value: 'AUD' },
        { name: 'CAD', value: 'CAD' },
        { name: 'KWD', value: 'KWD' }
    ];
    return (
        <div className='mt-20'>
            <AddressPatch title={t?.('create-shipment.patchs.product-transactions')} icon={Box} />
            <div className="px-4">
                <div className='grid grid-cols-2 gap-4 grid-row items-end justify-start mt-4'>
                    <div className='col-span-2 md:col-span-1'>
                        <label htmlFor="currency" className="block text-sm font-medium text-gray-700">
                            {t?.('create-shipment.product-transactions.currency')}
                            <span className='text-rexRed'>*</span>
                        </label>
                        <ShipEntegraCustomSelect
                            id="currency"
                            options={currencies}
                            onSelect={(selectedCountry: any) => {
                                setSelectedCurrencies({
                                    name: selectedCountry.name,
                                    value: selectedCountry.value,
                                    error: false
                                })
                            }}
                        />
                        {selectedCurrencies.error === true && errorElement('Para Birimi Girilmesi Zorunludur.')}
                    </div>
                </div>
                <ProductTransactionsTable
                    products={products}
                    setProducts={setProducts}
                    description={description}
                    setDescription={setDescription}
                />
            </div>
        </div>
    )
}

export default ProductTransactions