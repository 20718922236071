import React, { useEffect, useState } from 'react';
import { ArrowSwapVertical } from 'iconsax-react';
import { useQuery } from '@tanstack/react-query';
import LoadingComponent from '../common/LoadingComponent';
import alertNotification from '../../utils/alertNotification';
import Pagination from '../common/Pagination';
import memberService from '../../services/memberService';
import { useAuth } from '../../context/AuthContext';
import { seperateNumberWithComma } from '../../utils/numberFormatter';
import { useLocation } from 'react-router-dom';
import { useLanguage } from '../../context/LanguageContext';

export interface AliexpressProducts {
  status: string;
  data: {
    products: {
      image: string;
      title: string;
      price: number;
      stock: number;
      id: number;
    }[];
    totalPageCount: number;
  };
}

interface Fields {
  [key: string]: string;
}
function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}
const AliexpressProductTable = () => {
  const [query, setQuery] = useState<{ sortField: string; sortOrder: string }>({
    sortField: 'profitMargin',
    sortOrder: 'DESC',
  });
  const { session } = useAuth();
  const { language } = useLanguage();
  const tableHeader =
    language === 'tr'
      ? [
          { name: 'Ürün Resmi', column: 'image', isButton: false },
          { name: 'Ürün Başlığı', column: 'title', isButton: false },
          { name: 'Ürün Fiyatı', column: 'price', isButton: false },
          { name: 'Stok Sayısı', column: 'stock', isButton: false },
          { name: '', column: 'id', isButton: false },
        ]
      : [
          { name: 'Product Image', column: 'image', isButton: false },
          { name: 'Product Title', column: 'title', isButton: false },
          { name: 'Sale Price', column: 'price', isButton: false },
          { name: 'Stock Count', column: 'stock', isButton: false },
          { name: '', column: 'id', isButton: false },
        ];

  const [fields, setFields] = useState<Fields>({
    price: 'DESC',
  });
  const [page, setPage] = useState(1);
  const [inputPage, setInputPage] = useState<string | number>(1);
  const location = useLocation();
  const urlPage = location.search;

  const { isLoading, error, data } = useQuery<AliexpressProducts, Error>(
    ['aeProducts', page, query],
    {
      queryFn: () => memberService.getAliexpressProducts(page),
      staleTime: 10000,
    }
  );

  if (error) {
    alertNotification('error', 'Ürünler getirilirken bir hata oluştu!');
  }
  useEffect(() => {
    let temp;
    if (urlPage !== '') {
      temp = urlPage.split('=')[1];
      setPage(Number(temp));
      setInputPage(Number(temp));
    } else {
      setPage(1);
      setInputPage(1);
    }
  }, [location.search]);

  return (
    <div className="flex flex-col flex-shrink-0 bg-white rounded-xl p-5 shadow-lg sm:p-4">
      <div className="flow-root">
        <div className="relative -mx-4 -my-2 overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300 table-auto">
              <thead>
                <tr>
                  {tableHeader.map((item) => {
                    return (
                      <th
                        key={item.name}
                        className="border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter "
                        scope="col"
                      >
                        {' '}
                        {item.isButton ? (
                          <button
                            className="text-xs md:text-sm flex justify-between items-center"
                            onClick={() => {
                              let order =
                                fields.hasOwnProperty(item.column) &&
                                fields[item.column] === 'DESC'
                                  ? 'ASC'
                                  : 'DESC';
                              setQuery({
                                sortField: item.column,
                                sortOrder: order,
                              });
                              setFields({ ...fields, [item.column]: order });
                            }}
                          >
                            {item.name} <ArrowSwapVertical className="" />
                          </button>
                        ) : (
                          <p className="text-xs md:text-sm">{item.name}</p>
                        )}
                      </th>
                    );
                  })}
                </tr>
              </thead>

              {isLoading ? (
                <div className="w-full flex justify-center">
                  <LoadingComponent />
                </div>
              ) : (
                <tbody className="divide-y divide-gray-200 bg-white">
                  {data?.data.products.map((product: any, idx) => {
                    return (
                      <tr key={product.id}>
                        <td className="py-4 pl-4 flex w-full items-center justify-center text-xs md:text-sm sm:pl-0">
                          <img
                            className="h-10 object-contain object-center"
                            src={`${product.media[0].url}`}
                          />
                        </td>
                        <td
                          className={classNames(
                            'blur-none',
                            'py-4 pl-4 text-xs md:text-sm font-medium text-center text-gray-900 sm:pl-0'
                          )}
                        >
                          {' '}
                          {product.subject[0].name.substring(0, 30)}...
                        </td>
                        <td className="px-4 py-4 text-xs md:text-sm text-center text-gray-500  ">
                          {product.aeVariations[0].price} ₽
                        </td>
                        <td className="px-4 py-4 text-xs md:text-sm text-center text-gray-500 ">
                          30
                        </td>
                        {/* <td className="px-4 py-4 text-xs md:text-smtext-center text-gray-500 ">
                          <button className="text-xs md:text-sm bg-white hover:bg-blue-700 text-blue font-bold py-1 px-2 rounded ">
                            Güncelle
                          </button>
                        </td>
                        <td className="px-4 py-4 text-xs md:text-smtext-center text-gray-500 ">
                          <button className="text-xs md:text-sm bg-blue-700  hover:bg-red-700 text-white font-bold py-1 px-2 rounded ">
                            Detay
                          </button>
                        </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
      <Pagination
        setPage={setPage}
        page={page}
        inputPage={inputPage}
        setInputPage={setInputPage}
        totalPageCount={data?.data.totalPageCount!}
      />
    </div>
  );
};
export default AliexpressProductTable;
