import React, { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import AuthServices from '../services/authService';
import memberService from '../services/memberService';
import alertNotification from '../utils/alertNotification';

export interface LoginDataInterface {
  email: string;
  password: string;
}

export interface RegisterDataInterface {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  passwordConfirm: string;
  isKvkkAccepted: string;
  from: string;
}

export interface IuserSession {
  isInitialSurveyDone?: boolean;
  firstName?: string;
  lastName?: string;
  email?: string;
  role?: string;
  id?: number;
  inComunity?: boolean;
  isSeller?: boolean;
  profileImage?: string | null;
  level: number;
  lifeTimeCourse?: boolean;
  producerId?: number;
  producerStatus?: string;
  hasPackage?: boolean;
  isGsmVerified?: boolean;
  giftPackageName: string;
  netPromoterScoreDisplay?: boolean;
  reviewToolCounters: {
    amazonReviewToolCounter: number;
  };
  discordInviteLink?: string;
  discordUserId?: string;
  isDiscordCheck?: boolean;
  isTrialEnd: boolean;
  isSupplier: boolean;
  rexvenSurveyDisplay: boolean;
  isTrendyolIntegrated: boolean;
  isAliExpressIntegrated: boolean;
  isServiceProvider: boolean;
  location: string;
  gsmNumber: string;
  hasShipEntegraInt: boolean;
  packageName: string;
  isMarketplaceSeller: boolean;
}

export interface AuthContextInterface {
  children?: React.ReactNode;
  session?: IuserSession | undefined | null;
  setSession?: React.Dispatch<
    React.SetStateAction<IuserSession | undefined | null>
  >;
  error?: boolean | string | undefined;
  isLoading?: boolean;
  setUserContext?: (redirectPage: any) => void;
  login?: (userData: LoginDataInterface, redirectPage: any) => void;
  register?: (userRegisterData: RegisterDataInterface) => void;
  logout?: () => void;
  checkSessionStatus?: () => void;
  loginLoading?: boolean;
  updateUserInfo?: (values: { firstName: string; lastName: string }) => void;
  updateUserPassword?: (values: {
    oldPassword: string;
    newPassword: string;
    newPasswordConfirm: string;
  }) => void;
  levelUp?: () => void;
  handleNetPromoterScore?: (score: number) => void;
  handleEmotionSurvey?: (answer: number) => void;
  promoterLoader?: boolean;
  emotionLoader?: boolean;
  isTrialEnd?: boolean;
  isMarketplaceSeller?: boolean;
}

const contextDefaultValues: AuthContextInterface = {};

const AuthContext = createContext<AuthContextInterface>(contextDefaultValues);
const useAuth = () => {
  return useContext(AuthContext);
};
export const AuthProvider: React.FC<AuthContextInterface> = (props) => {
  const [session, setSession] = useState<IuserSession | null | undefined>(
    undefined
  );
  const [isTabActive, setIsTabActive] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [promoterLoader, setPromoterLoader] = useState<boolean>(false);
  const [emotionLoader, setEmotionLoader] = useState<boolean>(false);
  const navigate = useNavigate();

  // login user
  const login = (userLoginData: LoginDataInterface, redirectPage: string) => {
    setIsLoading(true);
    AuthServices.login(userLoginData)
      .then((res) => {
        setUserContext(redirectPage);
        setError(false);
      })
      .catch((err) => {
        console.log(err);
        setError(err?.response?.data?.message);
        alertNotification('error', err?.response?.data?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  //register user
  const register = (userRegisterData: RegisterDataInterface) => {
    setIsLoading(true);
    AuthServices.register(userRegisterData)
      .then((res) => {
        alertNotification(
          'success',
          'Lütfen E-postanıza gelen bağlantıyla hesabınızı onaylayın'
        );

        // navigate('/login');
      })
      .catch((err) => {
        setError(err?.response?.data?.message);
        alertNotification('error', err?.response?.data?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const setUserContext = (redirectPage: string) => {
    AuthServices.getSession()
      .then((res) => {
        setSession(res.data);
        navigate(redirectPage ? redirectPage : '/');
      })
      .catch((err) => {
        setError(true);
        // alertNotification('error', err.message);
        navigate('/login');
      });
  };

  const updateUserInfo = (values: { firstName: string; lastName: string }) => {
    memberService
      .updateUserInfo(values)
      .then((res) => {
        checkSessionStatus();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const levelUp = () => {
    const operation = 'increment';

    memberService
      .levelUp(operation, 1)
      .then((res) => {
        checkSessionStatus();
      })
      .catch((err) => {
        console.log('err', err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const updateUserPassword = (values: {
    oldPassword: string;
    newPassword: string;
    newPasswordConfirm: string;
  }) => {
    memberService
      .updateMemberPassword(values)
      .then((res) => {
        // alertNotification(
        //   'success',
        //   'Parolanız Değiştirildi,tekrar giriş yapınız'
        // );
        AuthServices.logoutUser()
          .then((res) => {
            setSession(null);
          })
          .catch((err) => {
            setError(true);

            // alertNotification('error', err.message);
          });
      })
      .catch((err) => {
        // alertNotification('error', err.response.data.message);
      });
  };

  //check if user has auth
  const checkSessionStatus = () => {
    setIsLoading(true);
    AuthServices.getSession()
      .then((res) => {
        setSession(res.data);
        // console.log(res.data);
      })
      .catch((err) => {
        setError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // logout user
  const logout = () => {
    AuthServices.logoutUser()
      .then((res) => {
        setSession(null);
        // navigate('/login');
        // alertNotification('success', 'Başarıyla çıkış yapıldı');
      })
      .catch((err) => {
        setError(true);
        // alertNotification('error', err.message);
      });
  };

  const handleNetPromoterScore = (score?: number) => {
    setPromoterLoader(true);
    memberService
      .updateNetProScore({ score })
      .then((res) => {
        setSession(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setPromoterLoader(false);
      });
  };

  const handleEmotionSurvey = (answer: number) => {
    setEmotionLoader(true);
    memberService
      .updateEmotionSurvey({ answer })
      .then((res) => {
        setSession(res.data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setEmotionLoader(false);
      });
  };
  useEffect(() => {
    checkSessionStatus();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabActive(document.visibilityState === 'visible');
    };

    // Add event listener for visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup function to remove the event listener when the component is unmounted
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    let intervalId: any;

    if (session && isTabActive) {
      intervalId = setInterval(async () => {
        await memberService.userTrackReq('general', session?.id!);
      }, 60000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [session, isTabActive]);

  return (
    <AuthContext.Provider
      value={{
        session,
        setSession,
        error,
        isLoading,
        login,
        register,
        logout,
        checkSessionStatus,
        setUserContext,
        updateUserInfo,
        updateUserPassword,
        levelUp,
        handleNetPromoterScore,
        promoterLoader,
        emotionLoader,
        handleEmotionSurvey,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export { AuthContext, useAuth };
