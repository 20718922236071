import React, { useEffect, useState } from 'react';
import Pagination from '../components/common/Pagination';
import FaqWrapper from '../components/faqs/FaqWrapper';
import SearchField from '../components/faqs/SearchField';
import memberService from '../services/memberService';
// import SixDigitModal from '../components/numberVerifyModals/SixDigitModal';
import NumVerifyModal from '../components/numberVerifyModals/NumVerifyModal';
import VerifySuccessModal from '../components/numberVerifyModals/VerifySuccessModal';
import SurveyModal from '../components/survey/SurveyModal';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

export interface Faq {
  id: number;
  answer: string;
  question: string;
  faqCategoryId: number;
}
export interface FaqCategory {
  id: string;
  name: string;
}
const Faqs = () => {
  const { session } = useAuth();
  const [faqs, setFaqs] = useState<Faq[]>();

  const [faqCategories, setFaqcategories] = useState<FaqCategory[] | undefined>(
    [
      {
        id: '',
        name: 'Tüm Kategoriler',
      },
    ]
  );
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [keyword, setKeyword] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<FaqCategory>({
    id: '',
    name: 'Tüm Kategoriler',
  });
  const [inputPage, setInputPage] = useState<number | string>(1);
  const [isTabActive, setIsTabActive] = React.useState<boolean>(true);

  const location = useLocation();
  const urlPage = location.search;

  const getFaqs = async () => {
    try {
      const res = await memberService.getFaqs(
        keyword,
        String(selectedCategory?.id),
        urlPage === '' || urlPage === null ? '?page=1' : urlPage
      );
      setFaqs(res.data.faq);
      if (selectedCategory.id) {
        setPage(1);
      }

      setTotalPage(res.data.totalPageCount);
    } catch (error) {
      console.log('Hata oluştu!');
    }
  };

  const getFaqCategories = async () => {
    try {
      const res = await memberService.getFaqCategories();
      setFaqcategories(res.data);
    } catch (error) {}
  };

  useEffect(() => {
    getFaqs();
    getFaqCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyword, page, selectedCategory?.id]);

  useEffect(() => {
    let temp;
    if (urlPage !== '') {
      temp = urlPage.split('=')[1];
      setPage(Number(temp));
      setInputPage(Number(temp));
    } else {
      setPage(1);
      setInputPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabActive(document.visibilityState === 'visible');
    };

    // Add event listener for visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup function to remove the event listener when the component is unmounted
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    let intervalId: any;
    if (session && isTabActive) {
      intervalId = setInterval(async () => {
        await memberService.userTrackReq(`faq`, session?.id!);
      }, 60000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [session, isTabActive]);

  return (
    <>
      {/* <SixDigitModal /> */}
      <NumVerifyModal />
      <VerifySuccessModal />
      <SurveyModal />
      <div className="rounded-xl bg-white shadow-xl p-4 ">
        <SearchField
          setPage={setPage}
          faqCategories={faqCategories}
          setSelectedCategory={setSelectedCategory}
          selectedCategory={selectedCategory}
          setKeyword={setKeyword}
        />
        <FaqWrapper faqs={faqs!} />
        <Pagination
          page={page}
          setPage={setPage}
          totalPageCount={totalPage}
          inputPage={inputPage}
          setInputPage={setInputPage}
        />
      </div>
    </>
  );
};

export default Faqs;
