import React from 'react';
import AddresInfoCard from './AddresInfoCard';
import PackageInfo from './PackageInfo';

const PackageSection = () => {
  return (
    <div className="grid grid-cols-6 gap-2 min-h-[200px]">
      <PackageInfo />
      <AddresInfoCard />
    </div>
  );
};

export default PackageSection;
