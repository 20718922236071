import React from 'react';
import { Information } from 'iconsax-react';
import { Tooltip } from 'react-tooltip';
import { useLanguage } from '../../context/LanguageContext';

interface AliexpressCalculateResultProps {
  aliexpressCalculateResult: AliexpressCalculateResult;
}

export interface AliexpressCalculateResult {
  cargo: number;
  commission: number;
}

const AliexpressCalculateResultForm: React.FC<
  AliexpressCalculateResultProps
> = ({ aliexpressCalculateResult }) => {
  const { language } = useLanguage();

  return (
    <div className="mt-1 mb-4 flex flex-col gap-y-[2px] ">
      <h1 className="w-full border border-[#F27B1A] text-xl font-bold text-white text-center rounded-sm bg-[#F27B1A] p-1 mb-2 -mt-3">
        {language === 'tr' ? 'KESİNTİ' : 'FEES'}
      </h1>

      {/* Komisyon Ücreti */}
      <div className="flex items-center">
        <div className="w-[90%] rounded-sm border-[#FCE3CF] border-[1px] px-2 py-2 shadow-sm text-xs md:text-sm bg-[#FCE3CF] mr-1">
          <div className="flex justify-between">
            <span>
              {language === 'tr' ? 'Komisyon Ücreti' : 'Commission Fee'}{' '}
            </span>
            <Information
              size={20}
              className="cursor-pointer"
              id="commissionResultToolTip"
            />
            <Tooltip
              anchorSelect="#commissionResultToolTip"
              content={
                language === 'tr'
                  ? "Aliexpress'in satış yapılan kategoriden yapmış olduğu kesinti"
                  : "Aliexpress's deduction from the sales category"
              }
            />
          </div>
        </div>
        <p className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 placeholder-gray-400 shadow-sm text-xs md:text-sm">
          {aliexpressCalculateResult.commission + ' '}₺
        </p>
      </div>

      {/* Kargo Cost */}
      <div className="flex items-center">
        <div className="w-[90%] rounded-sm border-[#FCE3CF] border-[1px] px-2 py-2 shadow-sm text-xs md:text-sm bg-[#FCE3CF] mr-1">
          <div className="flex justify-between">
            <span>
              {' '}
              {language === 'tr' ? 'Kargo Tutarı' : 'Shipping Amount'}{' '}
            </span>
            <Information
              size={20}
              className="cursor-pointer"
              id="vatTotalToolTip"
            />
            {language === 'tr' ? (
              <Tooltip anchorSelect="#vatTotalToolTip">
                Ürün kargo fiyatları Aliexpress FBS kargo fiyatları baz <br />
                alınarak hesaplanmaktadır.
              </Tooltip>
            ) : (
              <Tooltip anchorSelect="#vatTotalToolTip">
                Product shipping prices are based on Aliexpress FBS shipping
                <br /> prices is calculated.
              </Tooltip>
            )}
          </div>
        </div>
        <p className="w-full  rounded-sm border border-[#FCE3CF] px-2 py-2 placeholder-gray-400 shadow-sm text-xs md:text-sm">
          {aliexpressCalculateResult.cargo + ' '}₺
        </p>
      </div>
    </div>
  );
};

export default AliexpressCalculateResultForm;
