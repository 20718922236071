import React, { useEffect, useState } from 'react';
import NewBanner from '../components/tools/common/NewBanner';
import alertNotification from '../utils/alertNotification';
import amazonToolsService from '../services/amazonToolsService';
import { useQuery } from '@tanstack/react-query';
import ForecastForm from '../components/tools/amazon/forecast/ForecastForm';
import ResultSection, {
  IResult,
} from '../components/tools/amazon/forecast/ResultSection';
import ForecastTable from '../components/tools/amazon/forecast/ForecastTable';

import AsinForm from '../components/tools/amazon/forecast/AsinForm';

import FormLoader from '../components/tools/amazon/forecast/FormLoader';
import { useSearchParams } from 'react-router-dom';

export interface IAsinData {
  status: string;
  data: {
    image: string;
    title: string;
    price: number;
    amazonFee: number;
    storageFee: number;
    fbaFee: number;
  };
}
const AmazonForecast = () => {
  const [searchParams] = useSearchParams();
  const [asin, setAsin] = useState('B010RWDHAK');
  const [result, setResult] = useState<IResult[]>([
    {
      revenue: 0,
      profit: 0,
      profitMargin: 0,
      investment: 0,
      netInvestment: 0,
      brandValue: 0,
    },
    {
      revenue: 0,
      profit: 0,
      profitMargin: 0,
      investment: 0,
      netInvestment: 0,
      brandValue: 0,
    },
  ]);
  const [brandCost, setBrandCost] = useState(1000);
  const [companyCost, setcompanyCost] = useState(1000);
  const [imageCost, setImageCost] = useState(250);

  const [tableResult, setTableResult] = useState<any>([]);

  const { isLoading, error, data } = useQuery<IAsinData, Error>(
    ['amazonForecast', asin],
    {
      queryFn: () => amazonToolsService.getForecastData(asin),
      staleTime: Infinity,
    }
  );

  if (error) {
    alertNotification('error', error.message);
  }

  useEffect(() => {
    if (searchParams.has('asin')) {
      setAsin(searchParams.get('asin')!);
    }
  }, [searchParams]);
  return (
    <div>
      <NewBanner
        bgUrl="/images/amazon-product-finder-bg.webp"
        toolImageUrl="amazon-forecast"
      />

      <AsinForm
        asin={asin}
        setAsin={setAsin}
        data={data!}
        isLoading={isLoading}
        setResult={setResult}
        setTableResult={setTableResult}
      />

      <div className="flex flex-col lg:flex-row space-x-0 lg:space-x-2 space-y-2 lg:space-y-0">
        {isLoading ? (
          <FormLoader />
        ) : (
          data?.status === 'succcess' && (
            <ForecastForm
              setBrandCost={setBrandCost}
              setCompanyCost={setcompanyCost}
              setImageCost={setImageCost}
              imageCost={imageCost}
              brandCost={brandCost}
              companyCost={companyCost}
              data={data!}
              setResult={setResult}
              setTableResult={setTableResult}
            />
          )
        )}
        <ResultSection result={result} />
      </div>
      {tableResult.length > 0 && (
        <ForecastTable
          data={tableResult}
          brandCost={brandCost}
          companyCost={companyCost}
          imageCost={imageCost}
        />
      )}
    </div>
  );
};

export default AmazonForecast;
