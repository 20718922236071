import httpClient from '../utils/httpClient';

class MarketplaceService {
  public async getEtsyOrders(page?: string | number, queryParams?: any) {
    const response = await httpClient.get(
      `/api/v1/marketplace/seller/orders?page=${page}&shop=${queryParams?.shop}&label=${queryParams?.label}&country=${queryParams?.country}&isGift=${queryParams?.isGift}&customer=${queryParams?.customer}&startDate=${queryParams?.startDate}&endDate=${queryParams?.endDate}`
    );

    return response.data;
  }
  public async getSupplierOrders(page: number, queryParams?: any) {
    const response = await httpClient.get(
      `/api/v1/marketplace/supplier/orders?page=${page}&shop=${queryParams?.shop}&label=${queryParams?.label}&country=${queryParams?.country}&isGift=${queryParams?.isGift}&customer=${queryParams?.customer}&startDate=${queryParams?.startDate}&endDate=${queryParams?.endDate}`
    );

    return response.data;
  }
  public async getAllSupplierOrders(queryParams?: any) {
    const response = await httpClient.get(
      `/api/v1/marketplace/supplier/orders?shop=${queryParams?.shop}&label=${queryParams?.label}&country=${queryParams?.country}&isGift=${queryParams?.isGift}&customer=${queryParams?.customer}&startDate=${queryParams?.startDate}&endDate=${queryParams?.endDate}`
    );

    return response.data;
  }

  //get producers
  public async getMarketplaceProducts(
    page: number | string,
    keyword?: string,
    categoryId?: number | null,
    sku?: string
  ) {
    const { data } = await httpClient.get(
      `/api/v1/marketplace/products?keyword=${keyword}&page=${page}&categoryId=${categoryId ? categoryId : ''
      }&sku=${sku}`
    );
    return data;
  }

  public async getMarketplaceProduct(id: number) {
    const response = await httpClient.get(
      `/api/v1/marketplace/supplier/product/${id}`
    );
    return response.data;
  }

  public async addNoteToOrder(orderItemId: number, note: string) {
    const response = await httpClient.patch(`/api/v1/marketplace/seller/note`, {
      orderItemId,
      note,
    });
    return response.data;
  }
  public async getMarketplaceSupplierInventory(page: number | string) {
    const response = await httpClient.get(
      `/api/v1/marketplace/supplier/inventory?page=${page}`
    );
    return response.data;
  }
  public async updateOrderLabel(payload: {
    orderItemIds: any[];
    label: string;
  }) {
    const response = await httpClient.patch(
      `/api/v1/marketplace/seller/order-label`,

      payload
    );
    return response.data;
  }
  public async updateSellerOrder(payload: {
    isGift: boolean;
    isGiftWrap: boolean;
    note: string;
    giftmessage: string;
    orderId: number;
    attributes?: any;
  }) {
    const response = await httpClient.patch(
      `/api/v1/marketplace/seller/order/${payload.orderId}`,

      payload
    );
    return response.data;
  }

  public async getWalletBalance() {
    const response = await httpClient.get(`/api/v1/marketplace/wallet/balance`);
    return response.data;
  }

  public async buySupplierProduct(orderIds: number[]) {
    const response = await httpClient.post(`/api/v1/marketplace/seller/buy`, {
      orderIds: orderIds,
    });
    return response.data;
  }
  public async createWalletDeposit(payload: { amount: number; note: string }) {
    const response = await httpClient.post(
      `/api/v1/marketplace/wallet/deposit-request`,
      payload
    );
    return response.data;
  }
  public async resendOrderItems(payload: { orderIds: number[] }) {
    console.log(payload);
    const response = await httpClient.post(
      `/api/v1/marketplace/seller/send-again`,
      payload
    );
    return response.data;
  }
  public async resendPayment(payload: { orderIds: number[] }) {
    console.log(payload);
    const response = await httpClient.post(
      `/api/v1/marketplace/seller/send-again`,
      payload
    );
    return response.data;
  }
  public async getCargoPrice(payload: {
    country: string;
    carrier: string;
    weight: number;
    productId: number;
    productVariantId: number;
  }) {
    const response = await httpClient.get(
      `/api/v1/marketplace/cargo-predict?country=${payload.country}&carrier=${payload.carrier}&weight=${payload.weight}&productId=${payload.productId}&productVariantId=${payload.productVariantId}`
    );
    return response.data;
  }
  public async getMarketplaceSellerProducts(
    page: number | string,
    keyword?: string,
    storeId?: number | null,
  ) {
    const { data } = await httpClient.get(
      `/api/v1/marketplace/seller/products-list?keyword=${keyword}&page=${page}&storeId=${storeId ? storeId : ''}`
    );
    return data;
  }
  public async getMarketplaceSellerShops() {
    const { data } = await httpClient.get(
      `/api/v1/marketplace/seller/shops`
    );
    return data;
  }
}

export default new MarketplaceService();
