import React from 'react';

const NoSuchData = () => {
  return (
    <div className="flex flex-col space-y-3 w-full shadow-lg rounded-lg bg-white p-4 border border-[#0EA5E9] text-sm">
      <p>Henüz siparişiniz bulunmamakdatır.</p>
    </div>
  );
};

export default NoSuchData;
