import React, { useEffect } from 'react';
import { useAuth } from '../../context/AuthContext';
const Logout = () => {
  const { logout } = useAuth();
  useEffect(() => {
    logout?.();
  }, []);
  return <div>Logout</div>;
};

export default Logout;
