import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export default function usePaginate() {
  const location = useLocation();
  const navigate = useNavigate();

  return {
    location,
    navigate,
  };
}
