import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../errors/ErrorFallback';
import ForgetPasswordForm from '../../components/authForms/ForgetPasswordForm';


export default function Login() {
    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <ForgetPasswordForm />
        </ErrorBoundary>
    )
}
