export function convertDate(dateString: Date, lang: string = 'tr') {
  const currentDate = new Date();
  const givenDate = new Date(dateString);

  const yearDiff = currentDate.getFullYear() - givenDate.getFullYear();
  const monthDiff = currentDate.getMonth() - givenDate.getMonth();
  const dayDiff = currentDate.getDate() - givenDate.getDate();

  let years = yearDiff >= 0 ? yearDiff : 0;
  let months;

  if (monthDiff > 0) {
    if (dayDiff < 0) {
      months = monthDiff - 1;
      months = months < 0 ? 0 : months;
    } else {
      months = monthDiff;
    }
  } else if (monthDiff === 0) {
    months = 11;
  } else {
    months = 11 - givenDate.getMonth() + currentDate.getMonth();
  }

  let days =
    dayDiff >= 0
      ? dayDiff
      : getLastDayOfMonth(givenDate) -
        givenDate.getDate() +
        currentDate.getDate();

  if (monthDiff < 0 && years > 0) {
    years = years - 1;
  } else if (monthDiff <= 0 && years <= 0) {
    years = 0;
  } else if (years === 1 && months === 11) {
    years = 0;
  }

  let dayString, monthString, yearString;

  if (lang === 'en') {
    dayString = days !== 0 ? `${days} days` : '';
    monthString = months !== 0 ? `${months} months` : '';
    yearString = years !== 0 ? `${years} years` : '';
  } else {
    dayString = days !== 0 ? `${days} gün` : '';
    monthString = months !== 0 ? `${months} ay` : '';
    yearString = years !== 0 ? `${years} yıl` : '';
  }

  const output = `${yearString} ${monthString} ${dayString}`.trim();

  return output;
}

export function getLastDayOfMonth(date: Date) {
  const tempDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return tempDate.getDate();
}
