import React from 'react';
import { DocumentIcon } from '@heroicons/react/24/outline';
import { useLanguage } from '../../context/LanguageContext';
import { useAuth } from '../../context/AuthContext';
import { useNumberVerification } from '../../context/NumberVerificationContext';
interface BooksProps {
  id: number;
  description: string;
  title: string;
  imageLink: string;
  downloadLink: string;
}

function classNames(...classes: String[]) {
  return classes.filter(Boolean).join(' ');
}

const BookCard: React.FC<BooksProps> = ({
  description,
  title,
  imageLink,
  downloadLink,
}) => {
  const { t } = useLanguage();
  const { session } = useAuth();
  const { handleActiveModal } = useNumberVerification();
  return (
    <div className="mt-5 mb-5 bg-white" data-testid="book-card">
      <div className="mx-auto max-w-7xl">
        <div className="">
          <img
            className={classNames(
              'aspect-[3/2] w-full rounded-2xl object-fill'
            )}
            src={imageLink}
            alt="BookImage"
          />
        </div>
        <h3 className="mt-6 h-[40px] max-h-[40px] font-semibold text-left text-headerPrimary cardHeading">
          {title}
        </h3>
        <p className="mt-3 h-[84px] max-h-[84px] text-left text-sm text-description">
          {description}
        </p>
        <div className="mt-3 text-right">
          <a
            target="_blank"
            href={downloadLink}
            data-testid="download-book-link"
            rel="noreferrer"
            onClick={(event) => handleActiveModal?.(event)}
          >
            <button className="bg-[#1F254B] text-white  text-sm px-[34px] py-[10px] rounded-lg">
              <span className="flex">
                <DocumentIcon className="h-5 w-5 mr-3" />{' '}
                {t?.('books.page.download')}
              </span>
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default BookCard;
