import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Formik } from 'formik';
import * as Yup from 'yup';
import { errorElement } from '../../utils/errorElement'
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useLanguage } from '../../context/LanguageContext'
import { Link } from 'react-router-dom';
import LoadingComponent from '../common/LoadingComponent';
import { ToastContainer } from 'react-toastify';

interface EntegrationModalProps {
    openEntegrationModal: boolean,
    setOpenEntegrationModal: React.Dispatch<React.SetStateAction<boolean>>
    handleEntegrationOnSubmit: Function
    loading: boolean
}
const EntegrationModal: React.FC<EntegrationModalProps> = ({
    openEntegrationModal,
    setOpenEntegrationModal,
    handleEntegrationOnSubmit,
    loading }) => {
    const { t } = useLanguage();
    const onSubmit = (values: any) => {
        handleEntegrationOnSubmit(values)
    }
    return (
        <Transition.Root show={openEntegrationModal} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setOpenEntegrationModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative  transform overflow-hidden rounded-lg bg-white  pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl ">
                                <div className="absolute top-0  right-0  pt-4 pr-4 block">
                                    <ToastContainer />
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        onClick={() => setOpenEntegrationModal(false)}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className="p-2 border-b-2 w-full">
                                    <div className="my-6  ml-2 sm:ml-4 flex">
                                        <img
                                            className="object-contain"
                                            src="/assets/logos/rexven-logo-3.png"
                                            alt="Rexven Logo"
                                        />
                                        <div className="text-sm ml-8">
                                            <p className="text-3xl font-thin">
                                                Aracı Kullanmak için Trendyol
                                                Mağazanızı Entegre Ediniz.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="w-full mt-4 px-4">
                                    {loading === true ? (
                                        <LoadingComponent />
                                    ) :
                                        (
                                            <div>
                                                {/* Entegration Form */}
                                                <Formik
                                                    initialValues={{
                                                        sellerId: '',
                                                        apiKey: '',
                                                        apiSecret: '',
                                                        // isKvkkAccepted: '',
                                                    }}
                                                    onSubmit={onSubmit}
                                                    validationSchema={Yup.object({
                                                        sellerId: Yup.string().required(
                                                            t?.('integration.errors.sellerId')
                                                        ),
                                                        apiKey: Yup.string().required(
                                                            t?.('integration.errors.apiKey')
                                                        ),
                                                        apiSecret: Yup.string().required(
                                                            t?.('integration.errors.apiSecret')
                                                        ),
                                                        // isKvkkAccepted: Yup.boolean()
                                                        //     .test(
                                                        //         'is-accepted',
                                                        //         t?.('validation.contract'),
                                                        //         (value) => value === true
                                                        //     )
                                                        //     .required(t?.('validation.contract')),
                                                    })}
                                                >
                                                    {({ handleSubmit, values, handleChange, errors }) => (
                                                        <form
                                                            className="flex  flex-col gap-y-6 mt-4"
                                                            onSubmit={handleSubmit}
                                                        >
                                                            <div className="flex justify-between items-center gap-x-3 sm:gap-x-1.5">
                                                                <label
                                                                    htmlFor="discountRate"
                                                                    className="font-medium  text-center py-2 rounded-lg text-xs w-4/12 lg:w-5/12 xl:text-sm"
                                                                >
                                                                    Trendyol Satıcı ID
                                                                </label>
                                                                <div className="flex flex-1 justify-between gap-x-2">
                                                                    <input
                                                                        placeholder="443276454935"
                                                                        onChange={handleChange}
                                                                        type="text"
                                                                        name="sellerId"
                                                                        id="sellerId"
                                                                        className="flex w-[100%] text-xs rounded-md bg-white border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-headingPrimary sm:text-sm sm:leading-6"
                                                                    />
                                                                </div>
                                                            </div>
                                                            {errors.sellerId && errorElement(errors.sellerId)}

                                                            <div className="flex justify-between items-center gap-x-3 sm:gap-x-1.5">
                                                                <label
                                                                    htmlFor="discountRate"
                                                                    className="font-medium text-center py-2 rounded-lg text-xs w-4/12 lg:w-5/12 xl:text-sm"
                                                                >
                                                                    Trendyol API Key
                                                                </label>
                                                                <div className="flex flex-1 justify-between gap-x-2">
                                                                    <input
                                                                        placeholder="********************"
                                                                        onChange={handleChange}
                                                                        type="text"
                                                                        name="apiKey"
                                                                        id="apiKey"
                                                                        className="flex w-[100%] text-xs rounded-md bg-white border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-headingPrimary sm:text-sm sm:leading-6"
                                                                    />
                                                                </div>
                                                            </div>
                                                            {errors.apiKey && errorElement(errors.apiKey)}

                                                            <div className="flex justify-between items-center gap-x-3 sm:gap-x-1.5">
                                                                <label
                                                                    htmlFor="discountRate"
                                                                    className="font-medium  text-center py-2 rounded-lg text-xs w-4/12 lg:w-5/12 xl:text-sm"
                                                                >
                                                                    Trendyol Secret Key
                                                                </label>
                                                                <div className="flex flex-1 justify-between gap-x-2">
                                                                    <input
                                                                        placeholder="********************"
                                                                        onChange={handleChange}
                                                                        type="text"
                                                                        name="apiSecret"
                                                                        id="apiSecret"
                                                                        className="flex w-[100%] text-xs rounded-md bg-white border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-headingPrimary sm:text-sm sm:leading-6"
                                                                    />
                                                                </div>
                                                            </div>
                                                            {errors.apiSecret && errorElement(errors.apiSecret)}
                                                            {/* <div className="flex items-center justify-between mt-6">
                                                        <div className="flex items-start">
                                                            <input
                                                                id="isKvkkAccepted"
                                                                name="isKvkkAccepted"
                                                                type="checkbox"
                                                                value={values.isKvkkAccepted}
                                                                onChange={handleChange}
                                                                className="h-4 w-4 rounded border-gray-300 text-secondary focus:ring-secondary"
                                                            />
                                                            <label
                                                                htmlFor="isKvkkAccepted"
                                                                className="ml-2 block text-sm text-gray-900"
                                                            >
                                                                Kişisel verileriniz KVKK kapsamında
                                                                işlenmektedir. Bir hesap oluşturduğunuzda
                                                                Üyelik Sözleşmesini, Kullanım Koşullarını,
                                                                Aydınlatma Metnini, Mesafeli Satış
                                                                Sözleşmesini ve Gizlilik Politikasını kabul
                                                                etmiş olursunuz. Daha fazla bilgi için{' '}
                                                                <a
                                                                    href="/uyelik-sozlesmesi"
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                    className="font-medium text-secondary"
                                                                >
                                                                    Üyelik Sözleşmesini
                                                                </a>
                                                                ,{' '}
                                                                <a
                                                                    href="/kullanim-kosullari"
                                                                    className="font-medium text-secondary"
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    Kullanım Koşullarını
                                                                </a>
                                                                ,{' '}
                                                                <a
                                                                    className="font-medium text-secondary"
                                                                    href="/aydinlatma-metni"
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    Aydınlatma Metnini
                                                                </a>
                                                                ,{' '}
                                                                <a
                                                                    className="font-medium text-secondary"
                                                                    href="/mesafeli-satis-sozlesmesi"
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    Mesafeli Satış Sözleşmesini
                                                                </a>{' '}
                                                                ve{' '}
                                                                <a
                                                                    className="font-medium text-secondary"
                                                                    href="/gizlilik-politikasi"
                                                                    target="_blank"
                                                                    rel="noreferrer"
                                                                >
                                                                    Gizlilik Politikasını
                                                                </a>{' '}
                                                                inceleyebilirsiniz.
                                                                {errors.isKvkkAccepted &&
                                                                    errorElement(errors.isKvkkAccepted)}
                                                            </label>
                                                        </div>
                                                    </div> */}
                                                            <div className="flex w-full justify-end gap-x-2 ">
                                                                <div
                                                                    // type="submit"
                                                                    onClick={() => setOpenEntegrationModal(false)}
                                                                    className="mt-1 rounded-md text-headerPrimary py-2.5 px-8 text-xs border border-headerPrimary hover:border-secondary font-semibold shadow-sm hover:bg-secondary hover:text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 md:text-sm"
                                                                >
                                                                    Demoyu İncele
                                                                </div>
                                                                <button
                                                                    type="submit"
                                                                    className="mt-1 rounded-md bg-headerPrimary py-2.5 px-8 text-xs font-semibold text-white shadow-sm hover:bg-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 md:text-sm"
                                                                >
                                                                    Entegre Et
                                                                </button>
                                                            </div>
                                                        </form>
                                                    )}
                                                </Formik>
                                            </div>
                                        )}
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default EntegrationModal