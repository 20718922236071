import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import PackageInfo from '../components/payment/PackageInfo';
import UserInfoWrapper from '../components/payment/UserInfoWrapper';
import { useAuth } from '../context/AuthContext';
// import AddresInfoCard from '../components/billingInfo/AddresInfoCard';
// import PaymentActions from '../components/payment/PaymnetActions';
// import PaymentInfo from '../components/payment/PaymnetInfo';
import memberService from '../services/memberService';
import { triggerPurchaseEvent } from '../utils/gtagManager';
import ErrorFallback from './errors/ErrorFallback';

interface PlanData {
  id: number;
  platform: string;
  duration: number;
  price: string;
  currency: string;
  courses: number[];
  discount: string;
  packageId: number;
  package: {
    name: string;
  };
}
export enum ComponentType {
  'profile',
  'billing',
  'payment',
}

const Payment = () => {
  const { session } = useAuth();

  const [planInfo, setPlanInfo] = useState<PlanData>({
    id: 1,
    platform: '',
    duration: 1,
    price: '',
    currency: 'TRY',
    courses: [],
    discount: '',
    packageId: 1,
    package: {
      name: 'Rexven Premium',
    },
  } as any);
  const [isTabActive, setIsTabActive] = React.useState<boolean>(true);

  const [searchParams] = useSearchParams();
  const params = useParams();
  const navigate = useNavigate();
  const [disablePayment, setDisablePayment] = useState(true);
  const [currentComponent, setCurrentComponent] = useState(
    !session ? ComponentType.profile : ComponentType.billing
  );
  const [country, setCountry] = useState('');
  const getPlanInfo = async (id: number) => {
    const res = await memberService.getPlanInfo(Number(id));
    setPlanInfo(res.data);
  };

  const fetchLocation = async () => {
    try {
      if (!session?.location) {
        const response = await axios.get('https://api.country.is');
        setCountry(response.data.country);
      } else {
        setCountry(session?.location);
      }
    } catch (error) {
      console.error('Hata:', error);
    }
  };
  useEffect(() => {
    if (!session) return;
    // for plus package
    //  stripe  106 aylık,         107 yıllık
    //  iyzico  108 aylık(600 TL), 114 yıllık(4000 TL)
    if (session?.location !== 'TR' && params.plan === '114') {
      navigate('/payment/107');
    } else if (session?.location !== 'TR' && params.plan === '108') {
      navigate('/payment/106');
    } else if (session?.location === 'TR' && params.plan === '107') {
      navigate('/payment/114');
    } else if (session?.location === 'TR' && params.plan === '106') {
      navigate('/payment/108');
    }

    // for plus package
    //  stripe  122 aylık,         123 yıllık
    //  iyzico  120 aylık(600 TL), 121 yıllık(4000 TL)
    if (session?.location !== 'TR' && params.plan === '120') {
      navigate('/payment/122');
    } else if (session?.location !== 'TR' && params.plan === '121') {
      navigate('/payment/123');
    } else if (session?.location === 'TR' && params.plan === '122') {
      navigate('/payment/120');
    } else if (session?.location === 'TR' && params.plan === '123') {
      navigate('/payment/121');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session?.location, params.plan]);

  useEffect(() => {
    fetchLocation();
    if (searchParams.get('status')) {
      setCurrentComponent(ComponentType.payment);
    }
    console.log();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getPlanInfo(Number(params.plan));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disablePayment]);

  useEffect(() => {
    if (planInfo.price.length) {
      triggerPurchaseEvent(
        'add_to_cart',
        '',
        params.plan,
        planInfo.package.name,
        0,
        planInfo.price,
        'add_to_cart_transaction'
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planInfo.price]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabActive(document.visibilityState === 'visible');
    };

    // Add event listener for visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup function to remove the event listener when the component is unmounted
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    let intervalId: any;
    if (session && isTabActive) {
      intervalId = setInterval(async () => {
        await memberService.userTrackReq('payment', session?.id!);
      }, 60000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [session, isTabActive]);
  return (
    <div className="grid grid-cols-12 gap-10 ">
      <div className="col-span-12 xl:col-span-6">
        <div className="flex w-full h-full flex-col px-10">
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <UserInfoWrapper
              currentComponent={currentComponent}
              setCurrentComponent={setCurrentComponent}
              setPlanInfo={setPlanInfo}
              disablePayment={disablePayment}
              country={country}
              planInfo={planInfo}
              setDisablePayment={setDisablePayment}
            />
          </ErrorBoundary>
        </div>
      </div>

      <PackageInfo planInfo={planInfo} country={country} />
    </div>
  );
};

export default Payment;

