import { AxiosResponse } from 'axios';

import httpClient from '../utils/httpClient';

const producers = '/api/v1/producers';
class ProducerService {
  responseBody = (response: AxiosResponse) => response.data;
  //get producers
  public async getProducers(page: number, queryParams: any) {
    const { data } = await httpClient.get(
      `${producers}?page=${page}&keyword=${queryParams.keyword}&location=${queryParams.location}&minProductCount=${queryParams.minProductCount}&maxProductCount=${queryParams.maxProductCount}&categoryId=${queryParams.categoryId}&minRating=${queryParams.minRating}&maxRating=${queryParams.maxRating}&minReviewCount=${queryParams.minReviewCount}&maxReviewCount=${queryParams.maxReviewCount}`
    );
    return data;
  }
  //get producer by id
  public async getProducerById(id: number) {
    const { data } = await httpClient.get(`${producers}/${id}`);
    return data;
  }
  public async updateIntegrationtoActive(payload: {
    // phoneNumber: string;
    discountRate: number;
    isKvkkAccepted: boolean;
  }) {
    const { data } = await httpClient.patch(`/api/v1/integration`, payload);
    return data;
  }

  public async getProducer(id: number) {
    const { data } = await httpClient.get(`${producers}/${id}`);
    return data;
  }

  public async updateProducerInfo(
    producerId: number,
    payload: {
      discountRate: number | string;
    }
  ) {
    const { data } = await httpClient.patch(
      `/api/v1/members/producers/${producerId} `,
      payload
    );
    return data;
  }

  public async deleteProducer(producerId: number, platform: string) {
    const { data } = await httpClient.delete(
      `/api/v1/members/producers/${platform}/${producerId}`
    );
    return data;
  }

  // Contact btn clicked stats request-->
  public async contactProducerStat(producerId: number) {
    const { data } = await httpClient.patch(
      `/api/v1/producers/statistics/${producerId}`
    );
    return data;
  }
  public async updateProviderStat(producerId: number) {
    const { data } = await httpClient.patch(
      `api/v1/service-providers/${producerId}/stats`
    );
    return data;
  }
  public async productStatistics(payload: { productId: number }) {
    const { data } = await httpClient.post(
      `/api/v1/members/producer-product-statistics`,
      payload
    );
    return data;
  }
  public async producerStatistics(payload: {
    producerId: number;
    incrementType: 'view' | 'communication';
  }) {
    const { data } = await httpClient.post(
      `/api/v1/members/producer-product-statistics`,
      payload
    );
    return data;
  }
  public async getAnnouncements(page: number = 1) {
    const { data } = await httpClient.get(
      `/api/v1/rexven/announcements?page=${page}`
    );
    return data;
  }
}

export default new ProducerService();

export interface ProducerInfo {
  discountRate: string | number;
  id: number;
  images: [url: {}];
  location: string;
  logo: string;
  phoneNumber: string;
  producerName: string;
  producerRating: number | string;
  producerSince: string;
  productCount: string | number;
  productRatingCount: number | string;
  productReviewCount: number | string;
  status: 'active' | 'passive' | string;
  userId: number;
}
