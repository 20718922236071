import React, { Fragment, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { Dialog, Transition } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { XMarkIcon } from '@heroicons/react/24/outline';

import { useAuth } from '../../context/AuthContext';
import { useNumberVerification } from '../../context/NumberVerificationContext';

const VerifySuccessModal = () => {
    const { verifySuccessModal, setVerifySuccessModal, setOpenSurvey } = useNumberVerification()
    const { checkSessionStatus } = useAuth()
    const { pathname } = useLocation();

    const showSurvey = () => {
        if (pathname === '/integration' || '/manufacturers-and-wholesalers') {
            checkSessionStatus?.()
        } else {
            setVerifySuccessModal?.(false)
            setOpenSurvey?.(true)
        }
    }
    return (
        <>

            <Transition.Root show={verifySuccessModal} as={Fragment}>
                <Dialog as="div" className="relative z-10" onClose={showSurvey!}>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    <div className="fixed inset-0 z-10 overflow-y-auto">
                        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                                enterTo="opacity-100 translate-y-0 sm:scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            >
                                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                                    {/* <div className="relative bg-white px-6 pt-10 pb-9 shadow-xl mx-auto w-full max-w-lg rounded-2xl"> */}
                                    <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                        <button
                                            type="button"
                                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                            onClick={showSurvey}
                                        >
                                            <span className="sr-only">Close</span>
                                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                        </button>
                                    </div>
                                    <div className="mx-auto flex w-full max-w-md flex-col space-y-4">
                                        <div className="flex flex-col items-center justify-center text-center space-y-2">
                                            <img src="/images/verifySuccess.webp" alt="number_verify" className='h-36 w-36' />
                                            <div className="font-semibold text-3xl my-4">
                                                <p className="my-4">ONAY</p>
                                            </div>
                                            <div className="flex flex-row text-sm font-medium text-gray-400">
                                                <p>Tebrikler! Telefon numaranız başarı ile kaydedildi.</p>
                                            </div>
                                            <CheckIcon className='h-12 w-12 text-greenMid' />
                                        </div>
                                        <div>
                                            <div onClick={showSurvey}
                                                className="flex flex-row items-center justify-center text-center w-full border rounded-xl outline-none py-3 bg-headerPrimary border-none text-white text-sm shadow-sm cursor-pointer">
                                                Kapat
                                            </div>
                                        </div>
                                    </div>
                                    {/* </div> */}
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition.Root>

        </>

    )
}

export default VerifySuccessModal