import { AxiosResponse } from 'axios';

import { RegisterDataInterface } from '../context/AuthContext';
import { IPersonalService } from '../interfaces/serviceProviders';
// import { PAYMENT_URL } from '../config';
import httpClient from '../utils/httpClient';
import paymentClient from '../utils/paymentClient';

// import { IServicePost } from '../interfaces/serviceProviders';

const member = '/api/v1/members';
const withoutMember = '/api/v1';
interface IBillingAddress {
  userId?: number;
  title?: string;
  firstName?: string;
  lastName?: string;
  gsmNumber?: string;
  identityNumber?: string;
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  country?: string;
  zipCode?: string;
  isCorporate?: boolean;
  vatNumber?: string;
  companyName?: string;
  taxOffice?: string;
}
class MemberService {
  responseBody = (response: AxiosResponse) => response.data;
  //return user detail ınfo
  public async getMemberInfo() {
    const { data } = await httpClient.get(`${member}`);
    return data;
  }
  //updates user name,lastname
  public async updateUserInfo(updateUserInfoData: {
    firstName: string;
    lastName: string;
  }) {
    const { data } = await httpClient.patch(`${member}`, updateUserInfoData);
    return data;
  }

  //returns members available courses
  public async getMemberCourses() {
    const { data } = await httpClient.get(`${member}/courses`);
    return data;
  }

  //returns members available courses
  public async getBillingAddress() {
    const { data } = await httpClient.get(`${member}/billing-address`);
    return data;
  }
  //returns members available courses
  public async updateBillingAddress(
    updateBillingAddressData: IBillingAddress,
    forPayment?: boolean
  ) {
    const { data } = await httpClient.patch(`${member}/bills`, {
      ...updateBillingAddressData,
      forPayment: forPayment ? forPayment : false,
    });
    return data;
  }

  public async updateMemberPassword(updateMemberPasswordData: {
    oldPassword: string;
    newPassword: string;
    newPasswordConfirm: string;
  }) {
    const { data } = await httpClient.patch(
      `${member}/update-password`,
      updateMemberPasswordData
    );
    return data;
  }

  public async getUserPackages() {
    const { data } = await httpClient.get(`${member}/packages`);
    return data;
  }
  public async getCancelUserPackageReasons() {
    const { data } = await httpClient.get(
      `${member}/subscription-cancel-reasons`
    );
    return data;
  }
  public async cancelUserPackages(reasons: {
    cancelReasons: number[];
    userFeedback?: string;
  }) {
    const { data } = await httpClient.post(
      `${member}/cancel-packages`,
      reasons
    );
    return data;
  }

  // user level up
  public async levelUp(operation: string, level: number) {
    const { data } = await httpClient.patch(`${member}/levels`, {
      operation: operation,
      level: level,
    });
    return data;
  }

  //SERVICE PROVIDERS MAIN PAGE REQ
  public async getServiceProviders(catId?: number | string) {
    const { data } = await httpClient.get(
      // `${withoutMember}/service-providers/services?catId=${catId}`
      `${withoutMember}/service-providers/services?catId=${catId}`
    );
    return data;
  }
  public async getExServiceProviders() {
    const { data } = await httpClient.get(`${member}/service-providers/`);
    return data;
  }

  //SERVICE DESCRIPTON REQ
  public async getServiceDescripton(catId?: number | string) {
    const { data } = await httpClient.get(
      `${withoutMember}/service-providers/services/${catId}`
    );
    return data;
  }

  // SERVICE PROVIDERS IN DETAIL PAGE
  public async getServiceMembers(catId?: number | string) {
    const { data } = await httpClient.get(
      `${withoutMember}/service-providers?serviceId=${catId}`
    );
    return data;
  }

  //SIMILAR SERVICES REQ
  public async getSimilarServiceProviders(
    limit: number,
    catId?: number | string
  ) {
    const { data } = await httpClient.get(
      `${withoutMember}/service-providers/services?catId=${catId}&limit=${limit}`
    );
    return data;
  }
  //UPDATE SERVICE STATISTICS
  public async updateStatistics(id: number | string) {
    const { data } = await httpClient.patch(
      `${withoutMember}/service-providers/services/${id}/stats`
    );
    return data;
  }
  public async updateExStatistics(id: number | string) {
    const { data } = await httpClient.patch(
      `${member}/service-providers/${id}`
    );
    return data;
  }
  //---PERSONAL SERVICE REQ IN PROFILE PAGE
  public async getPersonalService(id: number) {
    const { data } = await httpClient.get(
      `${withoutMember}/service-providers/${id}`
    );
    return data;
  }
  //--------------
  public async getServiceCategories() {
    const { data } = await httpClient.get(
      `${withoutMember}/service-providers/categories`
    );
    return data;
  }
  //UPDATE PERSONAL SERVICES
  public async updatePersonalServices(
    id: number,
    payload: {
      description: string;
      services: { serviceId: number; serviceFee: number }[];
    }
  ) {
    const { data } = await httpClient.patch(
      `${withoutMember}/service-providers/${id}`,
      payload
    );
    return data;
  }

  //DELETE PERSONAL SERVICE
  public async deletePersonalService(spId: number, serviceId: number) {
    const { data } = await httpClient.delete(
      `${withoutMember}/service-providers/${spId}/services/${serviceId}`
    );
    return data;
  }
  //SERVICE SUGGESTION REQ
  public async suggestService(keyword: { serviceName: string }) {
    const { data } = await httpClient.post(
      `${withoutMember}/service-providers/services`,
      keyword
    );
  }

  //SERVICE PROVIDER CREATE MODAL REQ
  public async getAllServices() {
    const { data } = await httpClient.get(
      `${withoutMember}/service-providers/all-services`
    );
    return data;
  }
  public async createServiceProvider(payload: any) {
    const { data } = await httpClient.post(
      `${withoutMember}/service-providers/`,
      payload
    );
    return data;
  }

  public async getFaqs(keyword: string, categoryId: string, page: string) {
    const { data } = await httpClient.get(
      `${member}/frequently-asked-questions${page}&keyword=${keyword}&categoryId=${categoryId}`
    );
    return data;
  }
  public async getFaqCategories() {
    const { data } = await httpClient.get(`${member}/faq-category`);
    return data;
  }

  // Integration Request
  public async registerAsTrendyolSeller(trendyolSellerRegisterData: {
    platform: string;
    sellerId: string;
    apiKey: string;
    apiSecret: string;
    discountRate?: number;
    isAccepted?: boolean;
  }) {
    const { data } = await httpClient.post(
      `/api/v1/integration`,
      trendyolSellerRegisterData
    );
    return data;
  }

  // Integration Get Request
  public async getIntegratedInfo() {
    const { data } = await httpClient.get(`/api/v1/integration`);
    return data;
  }

  // Survey Get Request
  public async getSurveyQuestions() {
    const { data } = await httpClient.get(`${member}/form/questions`);
    return data;
  }

  // Survey Answers Post Request
  public async sendSurveyAnswers(surveyAnswers: {
    [key: number]:
    | string
    | undefined
    | string[]
    | { country: string; state: string };
  }) {
    const { data } = await httpClient.post(
      `${member}/form/answers`,
      surveyAnswers
    );
    return data;
  }

  public async sendUserSuggestion(suggestion: string) {
    const { data } = await httpClient.post(`${member}/user-suggestion`, {
      suggestion,
    });
    return data;
  }

  public async updateNetProScore(updateNetProScoreData: { score?: number }) {
    const { data } = await httpClient.patch(
      `${member}/net-promoter-score`,
      updateNetProScoreData
    );
    return data;
  }
  public async updateEmotionSurvey(updateEmotionSurveyData: {
    answer: number;
  }) {
    const { data } = await httpClient.patch(
      `${member}/rexven-survey`,
      updateEmotionSurveyData
    );
    return data;
  }

  //Get dashboard sparkline stats
  public async getSparklineStats() {
    const { data } = await httpClient.get(`/api/v1/members/dashboard`);
    return data;
  }

  // Get dashboard Bar chart stats
  public async getDashboardBarStats() {
    const { data } = await httpClient.get(`/api/v1/members/dashboard/graphic`);
    return data;
  }

  public async getExclusiveProducts(
    page: string,
    payload: { sortField: string; sortOrder: string }
  ) {
    const { data } = await httpClient.get(
      `/api/v1/exclusive/products${page}&sortField=${payload.sortField}&sortOrder=${payload.sortOrder}`
    );

    return data;
  }

  public async getEtsyHotProducts() {
    const { data } = await httpClient.get(`/api/v1/exclusive/products`);
    return data;
  }

  // Aliexpress Services
  public async getAliexpressProducts(page: number) {
    const { data } = await httpClient.get(
      `/api/v1/aliexpress/products?page=${page}`
    );
    return data;
  }

  public async integrateAliexpress(values: any) {
    const { data } = await httpClient.post(
      `/api/v1/aliexpress/integrate`,
      values
    );
    return data;
  }

  // send ProviderId when user get discount
  public async sendServiceProviderId(serviceProviderId: number) {
    const { data } = await httpClient.patch(`${member}/service-providers`, {
      serviceProviderId,
    });
    return data;
  }

  // discord refresh
  public async discordRefresh() {
    const { data } = await httpClient.get(`api/v1/discord/refresh`);
    return data;
  }
  public async getPlanInfo(id: number) {
    const { data } = await httpClient.get(`api/v1/payment/package-plans/${id}`);
    return data;
  }
  public async getPlanByCoupon(coupon: string, location: string) {
    const { data } = await httpClient.get(
      `api/v1/payment/package-plans/coupon/${coupon}?location=${location}`
    );
    return data;
  }
  public async getPaymentForm(payload: any) {
    const { data } = await paymentClient.post(
      `api/v1/payments/subs/membership`,
      payload
    );
    return data;
  }
  public async getBillingInfo(payload: any) {
    const { data } = await httpClient.get(
      `api/v1/members/invoices?period=${payload}`
    );
    return data;
  }
  public async getInvoiceForUser(payload: any) {
    const { data } = await httpClient.get(
      `api/v1/members/invoices/request?invoiceId=${payload}`
    );
    return data;
  }
  public async getInvoices() {
    const { data } = await httpClient.get(`api/v1/members/invoices`);

    return data.data;
  }
  public async getOldQuestions(page: number) {
    const { data } = await httpClient.get(
      `api/v1/ai/questionHistory?page=${page}`
    );
    return data.data;
  }
  //USER TRACKING
  public async userTrackReq(page: string, userId: number) {
    const { data } = await httpClient.post(`api/v1/members/user-track`, {
      page,
      userId,
    });
    return data.data;
  }
  //PUBLIC BILLING UPDATE

  public async updatePublicBillingInfo(payload: IBillingAddress) {
    const { data } = await httpClient.patch(`/api/v1/rexven/billing`, payload);
    return data.data;
  }
  //PUBLIC REGISTER
  public async registerPublic(registerData: RegisterDataInterface) {
    const { data } = await httpClient.post(
      `/api/v1/rexven/register`,
      registerData
    );
    return data;
  }
  //SEND VERIFICATION CODE FOR MAIL
  public async submitVerificationCode(payload: {
    userId: number;
    verificationCode: string;
  }) {
    const { data } = await httpClient.post(`/api/v1/rexven/verify`, payload);
    return data;
  }
  //RESEND VERIFICATION CODE FOR MAIL
  public async resendVerificationCode(userId: number) {
    const { data } = await httpClient.post(
      `/api/v1/rexven/resend-verification-code`,
      { userId: userId }
    );
    return data;
  }
  public async sendMessage(payload: {
    message: string;
    producerId?: number;
    productId?: number;
  }) {
    const { data } = await httpClient.post(
      '/api/v1/members/user-message-to-producer',
      payload
    );
    return data;
  }
  public async getPackagePrices(location: string) {
    const { data } = await httpClient.get(
      `/api/v1/payment/package-plan-prices?location=${location}`
    );
    return data;
  }
  public async getUserById(id: number) {
    const { data } = await httpClient.get(`/api/v1/admin/users/${id}`);
    return data;
  }
  public async sendUserMessage(receiverId: number, payload: FormData) {
    const { data } = await httpClient.post(
      `/api/v1/members/messages/${receiverId}`,
      payload,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
    return data;
  }


  public async getMessages(contactId: number) {
    const { data } = await httpClient.get(`/api/v1/members/messages/${contactId}`);
    return data;
  }

  public async getContacts() {
    const { data } = await httpClient.get(`/api/v1/members/message-contacts`);
    return data;
  }

  public async markMessagesAsRead(receiverId: number) {
    const { data } = await httpClient.patch(`/api/v1/members/mark-messages-as-read`, { receiverId }); return data;
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new MemberService();
