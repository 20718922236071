import { InfoCircle } from 'iconsax-react';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { IMAGE_URL } from '../../config';
import { IServiceMember } from '../../interfaces/serviceProviders';
import producerService from '../../services/producerService';
import alertNotification from '../../utils/alertNotification';
import { classNames } from '../../utils/conditionalClasses';
import { seperateNumberWithComma } from '../../utils/numberFormatter';
import ServiceBiographyModal from '../common/ServiceBiographyModal';

// import RatingStars from '../common/RatingStars';

interface Props {
  provider: IServiceMember;
}

const ServiceMemberCard: React.FC<Props> = ({ provider }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [showBiography, setShowBiography] = useState(false);
  const params = useParams();
  const navigate = useNavigate()
  const { id } = params;
  const updateProviderStat = async (id: number) => {
    try {
      await producerService.updateProviderStat(id);
    } catch (error: any) {
      alertNotification('error', error.response.data.message);
    }
  };
  const handleContact = () => {
    updateProviderStat(provider.id)
    navigate(`/messages?to=${provider.userId}&name=${`${provider.first_name} ${provider.last_name}`}&profile=${provider.profile_image}`)
  }
  return (
    <div className="grid grid-cols-12 w-full gap-x-6 border-2 border-cardBorder rounded-[20px] min-[400px]:border-none py-4 min-[400px]:pb-0 items-center">
      <div className="col-span-12 min-[400px]:col-span-3 rounded-xl w-full relative p-4 min-[400px]:p-0">
        <img
          src={IMAGE_URL + provider.imgPath}
          className="rounded-[18px] object-cover object-center aspect-square mx-auto"
          alt={provider.name}
        />
        {provider.isexpert || provider.ispremium ? (
          <img
            className="absolute -top-4 -right-4 h-16 w-16"
            src={`/images/${provider.isexpert ? 'rexvenBadge.png' : 'premiumBadge.png'
              }`}
            alt="badge"
          />
        ) : null}
        {provider.isexpert || provider.ispremium ? (
          <div
            className={classNames(
              provider.isexpert ? 'bg-rexRed/[.50]' : 'bg-[#3C4790]/[.50]',
              'absolute bottom-0 w-full rounded-b-[18px] h-12 flex items-center justify-center'
            )}
          >
            <p className="text-white font-medium text-center">
              {provider.isexpert
                ? 'Rexven Sertifikalı'
                : 'Premium Hizmet Sağlayıcı'}
            </p>
          </div>
        ) : null}
      </div>
      <div className="col-span-12 min-[400px]:col-span-6 h-full mt-4 min-[400px]:mt-0">
        <div className="flex flex-col items-stretch justify-between h-full">
          <div className="flex flex-col justify-start items-start">
            <div className="flex justify-start items-center">
              <p className="font-medium text-center text-sm sm:text-base min-[400px]:text-left my-2 min-[400px]:my-0 mr-3">
                {provider.name.toUpperCase()}
              </p>
              <div className="relative w-32">
                <InfoCircle
                  size="24"
                  color="#5502D7"
                  className="cursor-pointer"
                  onMouseEnter={() => setShowTooltip(true)}
                  onMouseLeave={() => setShowTooltip(false)}
                  onClick={() => setShowBiography(true)}
                />
                <p
                  className={classNames(
                    showTooltip ? 'visible' : 'hidden',
                    'absolute -top-2 -right-[100px] text-xs w-[200px] bg-secondary rounded-md text-white p-2'
                  )}
                >
                  Biyografiyi incelemek için tıklayınız
                </p>
              </div>
            </div>
            <p className='text-xs text-[#4B5563] mt-2 min-h-[20px]'>
              {provider.communicationCount !== '0' &&
                provider.communicationCount !== undefined &&
                provider.communicationCount !== null &&
                <>
                  Bu hizmet sağlayıcımız ile
                  <span className='font-medium'>{` ${provider.communicationCount} `}</span>
                  girişimci iletişim kurdu
                </>
              }
            </p>
          </div>
          <div className="px-2 border-0 min-[400px]:border-2 border-cardBorder mt-3 rounded-xl flex flex-1 items-center text-sm justify-center overflow-auto">
            <p className="h-[180px] whitespace-pre-line">
              {provider.serviceDescription}
            </p>
          </div>
        </div>
      </div>
      <div className="col-span-12 min-[400px]:col-span-3">
        <div className="flex flex-col justify-center space-y-4 items-center h-full w-full">
          <p className="text-[#3C4790] text-xl font-bold">
            {seperateNumberWithComma(provider.serviceFee)}₺
          </p>
          <button
            onClick={handleContact}
            // href={`https://wa.me/${provider.gsmNumber?.replace(
            //   / /g,
            //   ''
            // )}?text=Merhaba,%20ben%20size%20Rexven%20arac%C4%B1l%C4%B1%C4%9F%C4%B1yla%20ula%C5%9Ft%C4%B1m`}
            // target="_blank"
            // rel="noreferrer"
            className="bg-headerPrimary text-white text-xs sm:text-sm px-8 lg:px-4 py-2 text-center rounded-lg border-headerPrimary flex items-center"
          >
            İletişim Kur
          </button>
        </div>
      </div>
      <ServiceBiographyModal
        open={showBiography}
        setOpen={setShowBiography}
        description={provider.description}
      />
    </div>
  );
};

export default ServiceMemberCard;
