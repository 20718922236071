import { AxiosResponse } from 'axios';
import axios from 'axios';

const rexvenAIHttpClient = axios.create({
  headers: {
    'Content-type':'application/x-www-form-urlencoded',
  },
  baseURL: 'https://ai-api.rexven.com',
  withCredentials: false,
});

class RexvenAIService {
  responseBody = (response: AxiosResponse) => response.data;

  // Post Question
  public async postQuestion(payload: { question: string,userId: string|undefined }) {
    const { data } = await rexvenAIHttpClient.post(
      `/api/unstructured/`,
      payload
    );
    return data;
  }
}

export const rexvenAIService = new RexvenAIService();
