import axios from 'axios';
import React, { Suspense, useEffect, useState } from 'react';

import BannerLoading from '../components/tools/common/BannerLoading';
import NewBanner from '../components/tools/common/NewBanner';
import { useAuth } from '../context/AuthContext';
import memberService from '../services/memberService';

interface IPackage {
  duration: number;
  price: string;
  id: number;
  currency: string;
  package: { id: number };
}
const PricingPage = () => {
  const { session } = useAuth();
  const [packages, setPackages] = useState<IPackage[]>();
  const [country, setCountry] = useState<string>();
  const [isTabActive, setIsTabActive] = React.useState<boolean>(true);

  const getPackages = async () => {
    let res;

    try {
      if (!session?.location) {
        const response = await axios.get('https://api.country.is');
        setCountry(response.data.country);
        res = await memberService.getPackagePrices(response.data.country);
      } else {
        setCountry(session?.location!);
        res = await memberService.getPackagePrices(session?.location!);
      }
      setPackages(res.data.sort((a: any, b: any) => a.duration - b.duration));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPackages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabActive(document.visibilityState === 'visible');
    };

    // Add event listener for visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup function to remove the event listener when the component is unmounted
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    let intervalId: any;
    if (session && isTabActive) {
      intervalId = setInterval(async () => {
        await memberService.userTrackReq('pricing', session?.id!);
      }, 60000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [session, isTabActive]);

  return (
    <div className="pt-10">
      <Suspense fallback={<BannerLoading />}>
        <NewBanner
          bgUrl={'/images/rexven-exclusive-bg.webp'}
          toolImageUrl="pricing-page"
        />
      </Suspense>
      <h1 className="text-center text-xl text-[#1F2937] font-semibold">
        Size Uygun Rexven Ekosistem Paketini Seçmek İçin İçerikleri İnceleyin
      </h1>
      <div className="flex flex-col md:flex-row  space-y-8 md:space-y-0  md:space-x-2 lg:space-x-4 xl:space-x-6 justify-center items-start mt-14  ">
        {/* MONTHLY PLAN CARD */}
        <div className="w-[90%] mx-auto md:w-full border-[1px] border-gray-300 rounded-lg shadow-lg pb-14 h-[1000px] md:h-[1200px] lg:h-[1100px] relative">
          <div className="w-full h-28"></div>
          <h1 className="text-center mt-4 text-2xl md:text-xl font-semibold text-headerPrimary">
            Aylık Premium Üyelik
          </h1>
          <div className="h-32 md:h-48 flex items-center justify-center ">
            <h1 className="text-5xl md:text-4xl  xl:text-5xl font-extrabold text-headerPrimary">
              {packages &&
                packages?.length! > 0 &&
                `${Number(packages![0]?.price).toFixed(0)} ${
                  country !== 'TR' ? '$/Monthly' : '₺/Ay'
                }`}
            </h1>
          </div>
          <div className="w-[80%] mx-auto  ">
            <ul className="custom-checkmark space-y-2 md:space-y-1 xl:space-y-2">
              {monthlyPlanCardDescriptions.map((description: string) => (
                <li>
                  <span className="ml-1 text-[#111827] md:text-sm xl:text-base">
                    {description}{' '}
                  </span>
                </li>
              ))}
            </ul>
          </div>
          <a href={`/payment/${packages && packages![0].id}`}>
            <button className="bg-headerPrimary px-6 py-2 text-white w-[80%] mx-auto block absolute bottom-10 left-0 right-0 ml-auto mr-auto rounded-lg shadow-md">
              Ekosisteme Katıl
            </button>
          </a>
        </div>

        {/* PREMIUM PLAN CARD */}
        <div className="w-[90%] mx-auto md:w-full border-[2px] border-headerPrimary rounded-lg shadow-lg pb-14 h-[900px] md:h-[1200px] lg:h-[1100px] relative">
          <div className="w-full bg-headerPrimary h-28">
            <div className="flex flex-col w-full h-full justify-center items-center space-y-1">
              <div className="text-white text-xl font-semibold">
                Yıllık Üyeliğe Özel İndirim
              </div>
              <p className="text-white text-2xl font-extrabold">%44</p>
            </div>
          </div>
          <h1 className="text-center mt-4 text-2xl md:text-xl font-semibold text-headerPrimary">
            Yıllık Premium Üyelik
          </h1>
          <div className="h-32 md:h-48 flex items-center justify-center ">
            <h1 className="text-5xl md:text-4xl  xl:text-5xl font-extrabold text-headerPrimary">
              {packages &&
                packages?.length! > 0 &&
                `${Number(packages![1]?.price).toFixed(0)} ${
                  country !== 'TR' ? '$/Year' : '₺/Yıl'
                }`}
            </h1>
          </div>
          <div className="w-[80%] mx-auto  ">
            <ul className="custom-checkmark space-y-2 md:space-y-1 xl:space-y-2">
              {monthlyPlanCardDescriptions.map((description: string) => (
                <li>
                  <span className="ml-1 text-[#111827] md:text-sm xl:text-base ">
                    {description}{' '}
                  </span>
                </li>
              ))}
            </ul>
          </div>
          <a href={`/payment/${packages && packages![1].id}`}>
            <button className="bg-headerPrimary px-6 py-2 text-white w-[80%] mx-auto block absolute bottom-10 left-0 right-0 ml-auto mr-auto rounded-lg shadow-md">
              Ekosisteme Katıl
            </button>
          </a>
        </div>

        {/* KURUMSAL PLAN CARD */}
        <div className="w-[90%] mx-auto md:w-full border-[1px] border-gray-300 rounded-lg shadow-lg pb-14 h-[900px] md:h-[1200px] lg:h-[1100px] relative">
          <div className="w-full h-28"></div>
          <h1 className="text-center mt-4 text-2xl md:text-xl font-semibold text-headerPrimary">
            Kurumsal Üyelik
          </h1>
          <div className="h-32 md:h-48 flex items-center justify-center ">
            <span className="bg-headerPrimary block py-[5px] px-6 rounded-lg"></span>
          </div>
          <div className="w-[80%] mx-auto  ">
            <ul className="custom-checkmark space-y-2 md:space-y-1 xl:space-y-2">
              {corparatePlanCardDescription.map((description: string) => (
                <li>
                  <span className="ml-1 text-[#111827] md:text-sm xl:text-base">
                    {description}{' '}
                  </span>
                </li>
              ))}
            </ul>
          </div>
          <a
            href="https://community.rexven.com/iletisim/"
            target="_blank"
            rel="noreferrer"
          >
            <button className="bg-headerPrimary px-6 py-2 text-white w-[80%] mx-auto block absolute bottom-10 left-0 right-0 ml-auto mr-auto rounded-lg shadow-md">
              İletişime Geçin
            </button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default PricingPage;

// CARD DESCRIPTION DATAS -->
const corparatePlanCardDescription = [
  'Topluluğa hizmet sağlayıcı ya da şirket olarak katılarak verdiğiniz hizmetleri sunabilir ve topluluğun gelişimine katkı sağlayabilirsiniz.',
  'Kargo ve Lojistik',
  'Şirket ve Muhasebe',
  'Ara Depo',
  'E-ticaret Alt Yapıları',
  'Ödeme Alt Yapıları',
  'Entegrasyon ve Fatura',
  'Dijital Pazarlama Danışmanlığı',
  'E-ticaret Danışmanlığı',
  'Video-Fotoğrafçılık Hizmetleri',
  'Belgelendirme Hizmetleri',
  'Pazar Yerleri',
];

const monthlyPlanCardDescriptions = [
  'Ücretsiz Üyeliktekilerin Hepsi',
  'Özel Discord Grubuna Erişim',
  'Topluluğa Özel Kargo, Ara depo, Muhasebe, Şirket Kurulumu vb. Hizmetlerde İndirimler',
  'Amazon Master Eğitimi (110+ Saat)',
  'Amazon Uzmanı Video Serisi ',
  'Etsy Master Eğitimi',
  'Trendyol Master Eğitimi',
  'Haftalık Canlı Eğitimler',
  'Anlık Soru-Cevap Desteği',
  'Özel Network ve Yardımlaşma',
  'Amazon Araçları',
  'Etsy Araçları',
  'Trendyol Araçları',
  'Aliexpress Araçları',
];
