import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import LoadingComponent from '../components/common/LoadingComponent';
import ProductNotFound from '../components/common/ProductNotFound';
import ArbitrageFilter from '../components/tools/amazon/ArbitrageFilter';
import ArbitrageTable from '../components/tools/amazon/ArbitrageTable';
import NewBanner from '../components/tools/common/NewBanner';
import { useAuth } from '../context/AuthContext';
import { RootArbitrageProduct } from '../interfaces/amazonToolsInterfaces';
import amazonToolsService from '../services/amazonToolsService';
import memberService from '../services/memberService';
import ErrorFallback from './errors/ErrorFallback';

interface IArbitrageQuery {
  from: string;
  to: string;
  minSaleCount?: number | string;
  maxSaleCount?: number | string;
  minPrice?: number | string;
  maxPrice?: number | string;
  minRevenue?: number | string;
  maxRevenue?: number | string;
  minNetProfit?: number | string;
  maxNetProfit?: number | string;
  minProfitMargin?: number | string;
  maxProfitMargin?: number | string;
  minProfitRate?: number | string;
  maxProfitRate?: number | string;
  minRivalCount: number | string;
  maxRivalCount: number | string;
  minVariationCount: number | string;
  maxVariationCount: number | string;
}

const AmazonArbitrage = () => {
  const { session } = useAuth();
  const [page, setPage] = useState(1);
  const [isTabActive, setIsTabActive] = React.useState<boolean>(true);
  const [inputPage, setInputPage] = useState<string | number>(1);
  const [searchParams, setSearchParams] = useSearchParams();
  let temp = Object.fromEntries([...searchParams]);
  const [queryParams, setQueryParams] = useState<IArbitrageQuery>({
    to: temp.to,
    from: temp.from,
    minSaleCount: temp.minSaleCount,
    maxSaleCount: temp.maxSaleCount,
    minPrice: temp.minPrice,
    maxPrice: temp.maxPrice,
    minRevenue: temp.minRevenue,
    maxRevenue: temp.maxRevenue,
    minNetProfit: temp.minNetProfit,
    maxNetProfit: temp.maxNetProfit,
    minProfitMargin: temp.minProfitMargin
      ? Number(temp.minProfitMargin) / 100
      : '',
    maxProfitMargin: temp.maxProfitMargin
      ? Number(temp.maxProfitMargin) / 100
      : '',
    minProfitRate: temp.minProfitRate ? Number(temp.minProfitRate) / 100 : '',
    maxProfitRate: temp.maxProfitRate ? Number(temp.maxProfitRate) / 100 : '',
    minRivalCount: temp.minRivalCount,
    maxRivalCount: temp.maxRivalCount,
    minVariationCount: temp.minVariationCount,
    maxVariationCount: temp.maxVariationCount,
  });
  const [queryString, setQueryString] = useState('?page=1');

  const navigate = useNavigate();

  const { isLoading, error, data } = useQuery<RootArbitrageProduct, Error>(
    ['amazonArbitrage', { page, queryParams }],
    {
      queryFn: () =>
        amazonToolsService.getArbitrageProducts(page, queryParams!),

      staleTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );
  const handleSubmit = (values: any) => {
    setPage(1);
    // console.log(values);
    // setQueryParams(values);
    setQueryString(
      `to=ca&from=usa&minSaleCount=${values.minSaleCount}&maxSaleCount=${values.maxSaleCount}&minPrice=${values.minPrice}&maxPrice=${values.maxPrice}&minRevenue=${values.minRevenue}&maxRevenue=${values.maxRevenue}&minNetProfit=${values.minNetProfit}&maxNetProfit=${values.maxNetProfit}&minProfitMargin=${values.minProfitMargin}&maxProfitMargin=${values.maxProfitMargin}&minProfitRate=${values.minProfitRate}&maxProfitRate=${values.maxProfitRate}&minRivalCount=${values.minRivalCount}&maxRivalCount=${values.maxRivalCount}&minVariationCount=${values.minVariationCount}&maxVariationCount=${values.maxVariationCount}`
    );
    navigate(
      `?page=1&to=ca&from=usa&minSaleCount=${values.minSaleCount}&maxSaleCount=${values.maxSaleCount}&minPrice=${values.minPrice}&maxPrice=${values.maxPrice}&minRevenue=${values.minRevenue}&maxRevenue=${values.maxRevenue}&minNetProfit=${values.minNetProfit}&maxNetProfit=${values.maxNetProfit}&minProfitMargin=${values.minProfitMargin}&maxProfitMargin=${values.maxProfitMargin}&minProfitRate=${values.minProfitRate}&maxProfitRate=${values.maxProfitRate}&minRivalCount=${values.minRivalCount}&maxRivalCount=${values.maxRivalCount}&minVariationCount=${values.minVariationCount}&maxVariationCount=${values.maxVariationCount}`
    );
  };

  useEffect(() => {
    if (searchParams.has('to')) {
      setPage(Number(temp.page));
      setInputPage(Number(temp.page));
      setQueryParams({
        to: temp.to,
        from: temp.from,
        minSaleCount: temp.minSaleCount,
        maxSaleCount: temp.maxSaleCount,
        minPrice: temp.minPrice,
        maxPrice: temp.maxPrice,
        minRevenue: temp.minRevenue,
        maxRevenue: temp.maxRevenue,
        minNetProfit: temp.minNetProfit,
        maxNetProfit: temp.maxNetProfit,
        minProfitMargin: temp.minProfitMargin
          ? Number(temp.minProfitMargin) / 100
          : '',
        maxProfitMargin: temp.maxProfitMargin
          ? Number(temp.maxProfitMargin) / 100
          : '',
        minProfitRate: temp.minProfitRate
          ? Number(temp.minProfitRate) / 100
          : '',
        maxProfitRate: temp.maxProfitRate
          ? Number(temp.maxProfitRate) / 100
          : '',
        minRivalCount: temp.minRivalCount,
        maxRivalCount: temp.maxRivalCount,
        minVariationCount: temp.minVariationCount,
        maxVariationCount: temp.maxVariationCount,
      });
      setQueryString(
        `to=ca&from=usa&minSaleCount=${temp.minSaleCount}&maxSaleCount=${temp.maxSaleCount}&minPrice=${temp.minPrice}&maxPrice=${temp.maxPrice}&minRevenue=${temp.minRevenue}&maxRevenue=${temp.maxRevenue}&minNetProfit=${temp.minNetProfit}&maxNetProfit=${temp.maxNetProfit}&minProfitMargin=${temp.minProfitMargin}&maxProfitMargin=${temp.maxProfitMargin}&minProfitRate=${temp.minProfitRate}&maxProfitRate=${temp.maxProfitRate}&minRivalCount=${temp.minRivalCount}&maxRivalCount=${temp.maxRivalCount}&minVariationCount=${temp.minVariationCount}&maxVariationCount=${temp.maxVariationCount}`
      );
    } else if (!searchParams.has('to') && searchParams.has('page')) {
      setPage(Number(temp.page));
      setInputPage(Number(temp.page));
      setQueryParams({
        to: '',
        from: '',
        minSaleCount: '',
        maxSaleCount: '',
        minPrice: '',
        maxPrice: '',
        minRevenue: '',
        maxRevenue: '',
        minNetProfit: '',
        maxNetProfit: '',
        minProfitMargin: '',
        maxProfitMargin: '',
        minProfitRate: '',
        maxProfitRate: '',
        minRivalCount: '',
        maxRivalCount: '',
        minVariationCount: '',
        maxVariationCount: '',
      });
    } else {
      setPage(1);
      setInputPage(1);
      setQueryParams({
        to: '',
        from: '',
        minSaleCount: '',
        maxSaleCount: '',
        minPrice: '',
        maxPrice: '',
        minRevenue: '',
        maxRevenue: '',
        minNetProfit: '',
        maxNetProfit: '',
        minProfitMargin: '',
        maxProfitMargin: '',
        minProfitRate: '',
        maxProfitRate: '',
        minRivalCount: '',
        maxRivalCount: '',
        minVariationCount: '',
        maxVariationCount: '',
      });
    }
  }, [page, searchParams]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabActive(document.visibilityState === 'visible');
    };

    // Add event listener for visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup function to remove the event listener when the component is unmounted
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    let intervalId: any;
    if (session && isTabActive) {
      intervalId = setInterval(async () => {
        await memberService.userTrackReq(
          'amazon-online-arbitrage',
          session?.id!
        );
      }, 60000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [session, isTabActive]);
  return (
    <div>
      <NewBanner
        bgUrl="/images/amazon-product-finder-bg.webp"
        toolImageUrl="amazon-arbitraj"
      />
      <ArbitrageFilter handleSubmit={handleSubmit} />
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {isLoading ? (
          <LoadingComponent />
        ) : data?.data.data.length! > 0 ? (
          <ArbitrageTable
            data={data?.data.data!}
            isLoading={isLoading!}
            totalPageCount={data?.data?.totalPageCount!}
            page={page}
            inputPage={inputPage}
            setInputPage={setInputPage}
            setPage={setPage}
            queryString={queryString}
          />
        ) : (
          <ProductNotFound />
        )}
      </ErrorBoundary>
    </div>
  );
};

export default AmazonArbitrage;
