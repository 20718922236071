import React, { useState, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { StarIcon } from '@heroicons/react/20/solid';
import httpClient from '../../utils/httpClient';
import alertNotification from '../../utils/alertNotification';
import API_URL from '../../config';
import { useDebounce } from 'use-debounce';
import { ToastContainer } from 'react-toastify';

interface OrderMatchingModalProps {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedOrder: any;
  getOrders: () => void;
  withoutCalculate?: boolean;
  // refetchData: () => void;
}
interface Product {
  id: number;
  title: string;
  images: string[];
}
const supplierGifts = [
  { supplierId: 3, productId: 2756 },
  { supplierId: 6, productId: 2757 },
  { supplierId: 5, productId: 2758 },
];

const OrderMatchingModal: React.FC<OrderMatchingModalProps> = ({
  isModalOpen,
  setIsModalOpen,
  selectedOrder,
  getOrders,
  withoutCalculate,
  // refetchData,
}) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [value] = useDebounce(searchTerm, 600);
  const [selectedProduct, setSelectedProduct] = useState<{
    [key: number]: any | null;
  } | null>(null);

  const handleProductSelect = (index: number, product: Product) => {
    setSelectedProduct((prevSelectedProducts) => ({
      ...prevSelectedProducts,
      [index]: product,
    }));
  };

  const handleMatch = async () => {
    if (!selectedProduct) {
      alertNotification('error', 'Lütfen tüm ürünleri eşleştirin');
      return;
    }
    if (
      selectedOrder.seller_order_items.length !==
      Object.keys(selectedProduct).length
    ) {
      alertNotification('error', 'Lütfen tüm ürünleri eşleştirin');
      return;
    }
    setLoading(true);
    const temp = selectedOrder.seller_order_items.map(
      (item: any, index: number) => {
        return {
          sellerProductId: item.seller_product.id,
          supplierProductVariantId:
            item.seller_product.title === 'Gift Wrap'
              ? supplierGifts.find(
                  (prod: any) =>
                    Number(prod.id) === Number(item.supplier_order.supplierId)
                )?.productId
              : selectedProduct[index]?.id,
          sellerOrderItemId: item.id,
        };
      }
    );

    try {
      await httpClient.post(
        !withoutCalculate
          ? `/api/v1/marketplace/seller/product-match`
          : `/api/v1/marketplace/seller/product-detail-match`,
        !withoutCalculate
          ? { matchingData: temp } // İlk koşulda matchingData ile birlikte gönder
          : {
              sellerProductId: Number(temp[0].sellerProductId),
              supplierProductVariantId: Number(
                temp[0].supplierProductVariantId
              ), // Düz obje olarak gönder
            }
      );
      console.log('temp', {
        sellerProductId: temp[0].sellerProductId,
        supplierProductVariantId: temp[0].supplierProductVariantId,
      });
      alertNotification('success', 'Sipariş eşleştirme başarılı');

      getOrders();
      setIsModalOpen(false);
      setSelectedProduct(null);
    } catch (error) {
      console.error('Failed to match order with product:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchProducts = async () => {
    try {
      const response = await httpClient.get(
        `/api/v1/marketplace/all-products?keyword=${value}`
      );
      const arr: any = [];
      response.data.data.data.map((item: any) => {
        return item.supplier_product_variants.map((variant: any) => {
          arr.push({ ...variant, images: item.images });
        });
      });

      setProducts(arr);
    } catch (error: any) {
      alertNotification('error', error.response.data.message);
    } finally {
      setLoadingProducts(false);
    }
  };
  useEffect(() => {
    if (!isModalOpen) return;
    setLoadingProducts(true);

    fetchProducts();
  }, [isModalOpen, value]);
  return (
    <Transition show={isModalOpen} as={React.Fragment}>
      <Dialog
        as="div"
        className="relative z-10 "
        onClose={() => {
          setSelectedProduct(null);
          setIsModalOpen(false);
        }}
      >
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75" />
        </Transition.Child>
        <ToastContainer />
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-3xl h-[60vh] 3xl:max-w-5xl transform overflow-auto  rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg mb-8 font-medium text-center leading-6 text-gray-900"
                >
                  Sipariş Eşleştirme
                </Dialog.Title>
                <div className="mt-2 flex flex-col overflow-y-auto">
                  {selectedOrder?.seller_order_items.map(
                    (order: any, index: number) => {
                      if (order.seller_product.title === 'Gift Wrap') {
                        return null;
                      }
                      return (
                        <div className="flex">
                          <div className="flex flex-col flex-1">
                            <div className="flex flex-row items-center">
                              <img
                                src={
                                  order.seller_product.images[0]
                                    ? order.seller_product.images[0]
                                    : '/images/image-not-found.png'
                                }
                                alt="Order item"
                                className="w-16 h-16 3xl:w-20 3xl:h-20 rounded-lg"
                              />
                              <p className="px-4 text-sm">
                                {order?.seller_product.title.substring(0, 70)}
                                ...
                              </p>
                            </div>
                            <div>
                              {order?.seller_product.attributes.map(
                                (item: any) => {
                                  return Object.entries(item).map(
                                    ([key, value], index) => (
                                      <div key={index} className="flex">
                                        <p className="font-bold mr-2">{key}:</p>
                                        <p>{value as string}</p>
                                      </div>
                                    )
                                  );
                                }
                              )}
                            </div>
                            {selectedProduct && (
                              <div className="mt-8 mr-4 flex items-center space-x-2 mb-4">
                                {selectedProduct[index]?.images[0] && (
                                  <img
                                    src={
                                      API_URL +
                                      'images/marketplace/supplier-images/' +
                                      selectedProduct[index]?.images[0]
                                    }
                                    alt="Product"
                                    className="w-16 h-16 3xl:w-20 3xl:h-20 object-cover mr-2 rounded-lg"
                                  />
                                )}
                                <p className="text-sm">
                                  {selectedProduct[index] &&
                                    selectedProduct[index]?.name}
                                </p>
                              </div>
                            )}
                          </div>

                          <div className="flex-1">
                            <div className="mb-4 flex items-center">
                              <StarIcon className="h-5 w-5 text-gray-500" />
                              <input
                                type="text"
                                placeholder="Ürün Ara..."
                                className="w-full border border-gray-300 rounded-md p-2 ml-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                onChange={(e) => setSearchTerm(e.target.value)}
                              />
                            </div>
                            {loadingProducts ? (
                              <div className="flex justify-center items-center h-60">
                                Ürünler yükleniyor...
                              </div>
                            ) : (
                              <ul className="max-h-60 overflow-y-auto">
                                {products
                                  // .filter((product: any) =>
                                  //  {
                                  //   console.log("girdim")
                                  //   return product?.name
                                  //     .toLowerCase()
                                  //     .includes(value?.toLowerCase())}
                                  // )
                                  .map((product: any) => (
                                    <li
                                      key={product.id}
                                      className="flex text-sm items-center cursor-pointer p-2 hover:bg-gray-100"
                                      onClick={() =>
                                        handleProductSelect(index, product)
                                      }
                                    >
                                      {product?.images[0] && (
                                        <img
                                          src={
                                            API_URL +
                                            'images/marketplace/supplier-images/' +
                                            product?.images[0]
                                          }
                                          alt="Product"
                                          className="w-16 h-16 object-cover mr-2"
                                        />
                                      )}
                                      {product?.name}
                                    </li>
                                  ))}
                              </ul>
                            )}
                          </div>
                        </div>
                      );
                    }
                  )}
                </div>
                <div className="mt-4 flex justify-end">
                  <button
                    disabled={loading}
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-[#2563EB] px-4 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={handleMatch}
                  >
                    {loading ? 'Ürünler Eşleştiriliyor...' : 'Eşleştir'}
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default OrderMatchingModal;
