import React from 'react';

const FormLoader = () => {
  return (
    <div className="rounded-xl shadow-lg bg-white p-4 w-full lg:w-1/2 mb-4 lg:mb-0">
      <div className="grid grid-cols-12 py-4 gap-3">
        <div className="col-span-6">
          <div className="flex flex-col space-y-3">
            <div className="animate-pulse bg-lightGray w-full h-12 rounded-md"></div>
            <div className="animate-pulse bg-lightGray w-full h-12 rounded-md"></div>
            <div className="animate-pulse bg-lightGray w-full h-12 rounded-md"></div>
            <div className="animate-pulse bg-lightGray w-full h-12 rounded-md"></div>
            <div className="animate-pulse bg-lightGray w-full h-12 rounded-md"></div>
            <div className="animate-pulse bg-lightGray w-full h-12 rounded-md"></div>
            <div className="animate-pulse bg-lightGray w-full h-12 rounded-md"></div>
          </div>
        </div>
        <div className="col-span-6">
          <div className="flex flex-col space-y-3">
            <div className="animate-pulse bg-lightGray w-full h-12 rounded-md"></div>
            <div className="animate-pulse bg-lightGray w-full h-12 rounded-md"></div>
            <div className="animate-pulse bg-lightGray w-full h-12 rounded-md"></div>
            <div className="animate-pulse bg-lightGray w-full h-12 rounded-md"></div>
            <div className="animate-pulse bg-lightGray w-full h-12 rounded-md"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormLoader;
