import React, { useState } from 'react';
import ReactApexChart from 'react-apexcharts';

interface TrendyolStackedChartProps {
  dates: string[];
  series: any[];
  options: {
    chart: {
      height: number;
      stacked: boolean;
      toolbar: {
        show: boolean;
      };
      zoom: {
        enabled: boolean;
      };
    };
    responsive: {
      breakpoint: number;
      options: {
        legend: {
          position: string;
          offsetX: number;
          offsetY: number;
        };
      };
    }[];
    plotOptions: {
      bar: {
        horizontal: boolean;
        borderRadius: number;
        dataLabels: {
          total: {
            enabled: boolean;
            style: {
              fontSize: string;
              fontWeight: number;
            };
          };
        };
      };
    };
    xaxis: {
      categories: string[];
      labels: {
        style: {
          fontSize: string;
        };
      };
    };
    legend: {
      show: boolean;
      offsetY: number;
    };
    fill: {
      opacity: number;
    };
    colors: string[];
  };
}

const TrendyolStackedChart: React.FC<TrendyolStackedChartProps> = ({
  dates,
  series,
  options,
}) => {
  //   const series = { data: chartValues };

  return (
    <ReactApexChart options={options} series={series} type="bar" height={350} />
  );
};

export default TrendyolStackedChart;
