import React from 'react';
import { Image } from 'iconsax-react';
const BlurredProductFirstReview = () => {
  return (
    <>
      <div className="grid gap-8 grid-cols-12 mt-10 animate-pulse">
        {/* PRODUCT IMAGE */}
        <div className="col-span-12 lg:col-span-5">
          <div className="border-cardBorder p-4 rounded-lg border-2 h-[450px] bg-gray-300 flex justify-center items-center ">
            <Image size="100" color="#FFF" />
          </div>
        </div>

        {/* Product Info */}
        <div className="col-span-12 lg:col-span-7">
          {/* Product Name */}
          <div className="text-description font-bold text-2xl mb-3">
            <div className="w-full bg-gray-300 h-2.5 rounded-full mb-2"></div>
            <div className="w-full bg-gray-300 h-2.5 rounded-full mb-2"></div>
            <div className="w-48 bg-gray-300 h-2.5 rounded-full mb-2"></div>
          </div>

          {/* Customer Photo  & Customer Name */}
          <div className="flex items-center text-description mb-2">
            <div className="w-[20px] h-[20px] rounded-full bg-gray-300 "></div>
            <span className="text-lg ml-2 font-normal mt-2">
              <div className="w-48 bg-gray-300 h-2.5 rounded-full mb-2"></div>
            </span>
          </div>

          {/* Review Star */}
          <div className="flex items-center mb-1">
            <div className="w-24 bg-gray-300 h-2.5 rounded-full mb-2"></div>
            <span className="ml-2 text-description font-medium">
              <div className="w-48 bg-gray-300 h-2.5 rounded-full mb-2"></div>
            </span>
          </div>

          {/* Review Info */}
          <div className="text-description font-light mb-1">
            {/* Reviewed in {firstReview.reviewLocation} on {firstReview.reviewDate} */}
            <div className="w-[70%] bg-gray-300 h-2.5 rounded-full mb-2"></div>
          </div>

          {/* First Review */}
          <div className="mb-3 text-sm leading-6 mt-5">
            <div className="w-full bg-gray-300 h-2.5 rounded-full mb-2"></div>
            <div className="w-full bg-gray-300 h-2.5 rounded-full mb-2"></div>
            <div className="w-full bg-gray-300 h-2.5 rounded-full mb-2"></div>
            <div className="w-full bg-gray-300 h-2.5 rounded-full mb-2"></div>
            <div className="w-full bg-gray-300 h-2.5 rounded-full mb-2"></div>
            <div className="w-full bg-gray-300 h-2.5 rounded-full mb-2"></div>
            <div className="w-full bg-gray-300 h-2.5 rounded-full mb-2"></div>
            <div className="w-full bg-gray-300 h-2.5 rounded-full mb-2"></div>
            <div className="w-full bg-gray-300 h-2.5 rounded-full mb-2"></div>
            <div className="w-full bg-gray-300 h-2.5 rounded-full mb-2"></div>
            <div className="w-full bg-gray-300 h-2.5 rounded-full mb-2"></div>
            <div className="w-full bg-gray-300 h-2.5 rounded-full mb-2"></div>
            <div className="w-full bg-gray-300 h-2.5 rounded-full mb-2"></div>
            <div className="w-48 bg-gray-300 h-2.5 rounded-full mb-2"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlurredProductFirstReview;
