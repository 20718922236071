import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import CoursesCardWrapper from '../components/courses/CoursesCardWrapper';
import NumVerifyModal from '../components/numberVerifyModals/NumVerifyModal';
// import SixDigitModal from '../components/numberVerifyModals/SixDigitModal';
import VerifySuccessModal from '../components/numberVerifyModals/VerifySuccessModal';
import SurveyModal from '../components/survey/SurveyModal';
import { useAuth } from '../context/AuthContext';
import courseService from '../services/courseService';
import memberService from '../services/memberService';
import ErrorFallback from './errors/ErrorFallback';

const Courses = () => {
  const { session } = useAuth();
  const [isTabActive, setIsTabActive] = React.useState<boolean>(true);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabActive(document.visibilityState === 'visible');
    };

    // Add event listener for visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup function to remove the event listener when the component is unmounted
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    let intervalId: any;
    if (session && isTabActive) {
      intervalId = setInterval(async () => {
        await memberService.userTrackReq(`courses`, session?.id!);
      }, 60000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [session, isTabActive]);



  return (
    <div className="flex w-full flex-shrink-0 flex-col">
      <section className="flex flex-col">
        {/* <SixDigitModal /> */}
        <NumVerifyModal />
        <VerifySuccessModal />
        <SurveyModal />
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <CoursesCardWrapper />
        </ErrorBoundary>
      </section>
    </div>
  );
};

export default Courses;
