import React, { Suspense, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import AmazonProductFinderFilter from '../components/amazonProductFinder/AmazonProductFinderFilter';
import AmazonProductFinderTable from '../components/amazonProductFinder/AmazonProductFinderTable';
import DotLoaderSpinner from '../components/common/DotLoaderSpinner';
import ProductNotFound from '../components/common/ProductNotFound';
import BannerLoading from '../components/tools/common/BannerLoading';
import NewBanner from '../components/tools/common/NewBanner';
import { useLanguage } from '../context/LanguageContext';
import amazonToolsService from '../services/amazonToolsService';
import ErrorFallback from './errors/ErrorFallback';

export interface InputLabel {
    name: string;
    minValue: number | string;
    maxValue: number | string;
}
export interface AmazonProduct {
    asin: string;
    title: string;
    images: string[];
    price: string;
    saleCount: string | null;
    revenue: string | null;
    amazonCuts: {
        fbaFee: string | null;
        storageFee: string | null;
        referralFee: string | null;
    };
    totalAmazonCut: string | null;
    rivalCount: number;
    isAmazonOnList: boolean;
    reviewCount: number | null;
    rating: string | null;
    category: string;
    variationCount: number;
    sizeTier: string | null;
    sizeTierCode: string | null;
    listedSince: string;
    keepaCategoryId: string;
    dimensions: {
        packageWidth: number;
        packageHeight: number;
        packageLength: number;
        packageWeight: number;
    };
    description: string | null;
    avgBsr: string;
    imageCount: string;
}
export type orderByTypes = 'price' | 'saleCount' | 'revenue' | 'totalAmazonCut' | 'rivalCount' | 'reviewCount' | 'rating' | 'variationCount' | 'avgBsr' | 'imageCount'
const AmazonProductFinder = () => {
    const { language } = useLanguage();
    const countryOptions = language === 'tr' ?
        ['Hepsi', 'Amerika', 'Kanada', 'Almanya', 'Fransa', 'İspanya', 'Italya', 'Avusturalya', 'Meksika'] :
        ['All', 'America', 'Canada', 'Germany', 'France', 'Spain', 'Italy', 'Australia', 'Mexico']
    const sizeOptions = language === 'tr' ?
        ['Hepsi', 'Small Standart Size', 'Large Standart Size', 'Small Oversize', 'Medium Oversize', 'Large Oversize', 'Special Oversize'] :
        ['All', 'Small Standart Size', 'Large Standart Size', 'Small Oversize', 'Medium Oversize', 'Large Oversize', 'Special Oversize'];
    const [selectedCountry, setSelectedCountry] = useState(countryOptions[0]);
    const [selectedSize, setSelectedSize] = useState(sizeOptions[0]);
    const [selectFilter, setSelectFilter] = useState([
        {
            name: 'Home & Kitchen',
            value: false,
            id: 1055398,
            disabled: false
        },
        {
            name: 'Office Products',
            value: false,
            id: 1064954,
            disabled: false
        },
        {
            name: 'Pet Supplies',
            value: false,
            id: 2619533011,
            disabled: false
        },
        {
            name: 'Patio, Lawn & Garden',
            value: false,
            id: 2972638011,
            disabled: false
        },
        {
            name: 'Sports & Outdoors',
            value: false,
            id: 3375251,
            disabled: false
        },
        {
            name: 'Toys & Games',
            value: false,
            id: 165793011,
            disabled: false
        },
        {
            name: 'Tools & Home Improvement',
            value: false,
            id: 228013,
            disabled: false
        },
        {
            name: 'Appliances',
            value: false,
            id: 2619525011,
            disabled: false
        },
        {
            name: 'Automotive',
            value: false,
            id: 15684181,
            disabled: false
        },
        {
            name: 'Arts Crafts & Sewing',
            value: false,
            id: 2617941011,
            disabled: false
        },
        {
            name: 'Baby Products',
            value: false,
            id: 165796011,
            disabled: false
        },
        {
            name: 'Beauty & Personal Care',
            value: false,
            id: 0,
            disabled: true
        },
        {
            name: 'Cell Phones & Accessories',
            value: false,
            id: 0,
            disabled: true
        },
        {
            name: 'Computer & Accessories',
            value: false,
            id: 0,
            disabled: true
        },
        {
            name: 'Grocery & Gourmet Food',
            value: false,
            id: 0,
            disabled: true
        },

        {
            name: 'Kitchen & Dining',
            value: false,
            id: 0,
            disabled: true
        },
        {
            name: 'Camera & Photo',
            value: false,
            id: 0,
            disabled: true
        },
        {
            name: 'Clothing Shoes & Jewelry',
            value: false,
            id: 0,
            disabled: true
        },
        {
            name: 'Electronics',
            value: false,
            id: 0,
            disabled: true
        },
        {
            name: 'Health & Household',
            value: false,
            id: 0,
            disabled: true
        },
        {
            name: 'Industrial & Scientific',
            value: false,
            id: 0,
            disabled: true
        },
        {
            name: 'Musical Instruments',
            value: false,
            id: 0,
            disabled: true
        },
    ]);
    const [selectAll, setSelectAll] = useState(false);
    const [inputLabels, setInputLabels] = useState<InputLabel[]>([
        {
            name: language === 'tr' ? 'Aylık Satış Sayısı' : 'Monthly Sales Count',
            minValue: '',
            maxValue: ''
        },
        {
            name: language === 'tr' ? 'Satış Fiyatı' : 'Sales Price',
            minValue: '',
            maxValue: ''
        },
        {
            name: language === 'tr' ? 'Aylık Ciro' : 'Monthly Revenue',
            minValue: '',
            maxValue: ''
        },
        {
            name: language === 'tr' ? 'Rakip Sayısı' : 'Number of Competitors',
            minValue: '',
            maxValue: ''
        },
        {
            name: language === 'tr' ? 'BSR' : 'Best Sellers Rank (BSR)',
            minValue: '',
            maxValue: ''
        },
        {
            name: language === 'tr' ? 'Resim Sayısı' : 'Number of Images',
            minValue: '',
            maxValue: ''
        },
        {
            name: language === 'tr' ? 'Yorum Sayısı' : 'Number of Reviews',
            minValue: '',
            maxValue: ''
        },
        {
            name: language === 'tr' ? 'Yorum Puanı' : 'Review Score',
            minValue: '',
            maxValue: ''
        },
        {
            name: language === 'tr' ? 'Ürün Listeleme Tarihi' : 'Product Age',
            minValue: '',
            maxValue: 2025
        },
        {
            name: language === 'tr' ? 'Varyasyon Sayısı' : 'Number of Variations',
            minValue: '',
            maxValue: ''
        },
    ]);
    const [isAmazonAdded, setIsAmazonAdded] = useState<boolean | null>(null);
    const [keyword, setKeyword] = useState('')
    const [products, setProducts] = useState<AmazonProduct[]>([])
    const location = useLocation();
    let urlPage = location.search;
    const [loading, setIsLoading] = useState(false)
    const [error, setError] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    let temp = Object.fromEntries([...searchParams]);
    const [queryParams, setQueryParams] = useState({
        minSaleCount: temp.minSaleCount || '',
        maxSaleCount: temp.maxSaleCount || '',
        minPrice: temp.minPrice || '',
        maxPrice: temp.maxPrice || '',
        minRevenue: temp.minRevenue || '',
        maxRevenue: temp.maxRevenue || '',
        minRivalCount: temp.minRivalCount || '',
        maxRivalCount: temp.maxRivalCount || '',
        minVariationCount: temp.minVariationCount || '',
        maxVariationCount: temp.maxVariationCount || '',
        minBsr: temp.minBsr || '',
        maxBsr: temp.maxBsr || '',
        minImageCount: temp.minImageCount || '',
        maxImageCount: temp.maxImageCount || '',
        minReviewCount: temp.minReviewCount || '',
        maxReviewCount: temp.maxReviewCount || '',
        minRating: temp.minRating || '',
        maxRating: temp.maxRating || '',
        minAge: temp.minAge || '',
        maxAge: temp.maxAge || '',
        categoryIds: temp.categoryIds || '',
        isAmazonOnList: temp.isAmazonOnList || '',
        sizeTierCode: temp.sizeTierCode || '',
        keyword: temp.keyword || '',
        orderType: temp.orderType || '',
        orderBy: temp.orderBy || ''
    });
    const [queryString, setQueryString] = useState('?page=1');
    const [page, setPage] = useState(1)
    const [orderType, setOrderType] = useState('')
    const [orderBy, setOrderBy] = useState('')
    const [inputPage, setInputPage] = useState<number | string>(1)
    const [totalPageCount, setTotalPageCount] = useState(1)
    const navigate = useNavigate();

    useEffect(() => {
        // "Select All" seçildiğinde tüm kategorileri işaretle veya işareti kaldır
        if (selectAll) {
            const updatedSelectFilter = selectFilter.map((item) => ({
                ...item,
                value: true,
            }));
            setSelectFilter(updatedSelectFilter);
        } else {
            const updatedSelectFilter = selectFilter.map((item) => ({
                ...item,
                value: false,
            }));
            setSelectFilter(updatedSelectFilter);
        }
    }, [selectAll]);

    useEffect(() => {
        // Kategorilerin tümünün seçilip seçilmediğini kontrol et
        const allSelected = selectFilter.every((item) => item.value);
        setSelectAll(allSelected);
    }, [selectFilter]);

    const handleSubmit = () => {
        setPage(1)
        setInputPage(1)
        let sizeTierCode;
        if (selectedSize !== 'Hepsi' && selectedSize !== 'All') {
            sizeTierCode = (sizeOptions.indexOf(selectedSize))
        } else {
            sizeTierCode = ''
        }
        const selectedCategories = selectFilter
            .filter((item) => item.value)
            .map((item) => item.id).filter(id => id > 0);
        setQueryString(
            `minSaleCount=${inputLabels[0].minValue}&maxSaleCount=${inputLabels[0].maxValue}&minPrice=${inputLabels[1].minValue}&maxPrice=${inputLabels[1].maxValue}&minRevenue=${inputLabels[2].minValue}&maxRevenue=${inputLabels[2].maxValue}&minRivalCount=${inputLabels[3].minValue}&maxRivalCount=${inputLabels[3].maxValue}&minBsr=${inputLabels[4].minValue}&maxBsr=${inputLabels[4].maxValue}&minImageCount=${inputLabels[5].minValue}&maxImageCount=${inputLabels[5].maxValue}&minReviewCount=${inputLabels[6].minValue}&maxReviewCount=${inputLabels[6].maxValue}&minRating=${inputLabels[7].minValue}&maxRating=${inputLabels[7].maxValue}&minAge=${inputLabels[8].minValue}&maxAge=${inputLabels[8].maxValue}&minVariationCount=${inputLabels[9].minValue}&maxVariationCount=${inputLabels[9].maxValue}&categoryIds=${selectedCategories}&isAmazonOnList=${isAmazonAdded !== null ? isAmazonAdded : ''}&sizeTierCode=${sizeTierCode}&keyword=${keyword}&orderType=${orderType}&orderBy=${orderBy}`
        );
        navigate(
            `?page=1&minSaleCount=${inputLabels[0].minValue}&maxSaleCount=${inputLabels[0].maxValue}&minPrice=${inputLabels[1].minValue}&maxPrice=${inputLabels[1].maxValue}&minRevenue=${inputLabels[2].minValue}&maxRevenue=${inputLabels[2].maxValue}&minRivalCount=${inputLabels[3].minValue}&maxRivalCount=${inputLabels[3].maxValue}&minBsr=${inputLabels[4].minValue}&maxBsr=${inputLabels[4].maxValue}&minImageCount=${inputLabels[5].minValue}&maxImageCount=${inputLabels[5].maxValue}&minReviewCount=${inputLabels[6].minValue}&maxReviewCount=${inputLabels[6].maxValue}&minRating=${inputLabels[7].minValue}&maxRating=${inputLabels[7].maxValue}&minAge=${inputLabels[8].minValue}&maxAge=${inputLabels[8].maxValue}&minVariationCount=${inputLabels[9].minValue}&maxVariationCount=${inputLabels[9].maxValue}&categoryIds=${selectedCategories}&isAmazonOnList=${isAmazonAdded !== null ? isAmazonAdded : ''}&sizeTierCode=${sizeTierCode}&keyword=${keyword}&orderType=${orderType}&orderBy=${orderBy}`
        );
    };

    const getProductSearch = () => {
        setIsLoading(true)
        amazonToolsService.getProductSearch(page, queryParams)
            .then((res) => {
                setProducts(res.data.data)
                setTotalPageCount(res.data.totalPageCount)
            })
            .catch((err) => {
                setError(true)
            })
            .finally(() => {
                setIsLoading(false)
            })
    }



    const resetFilters = () => {
        // Reset selectedCountry and selectedSize to their initial values
        setSelectedCountry(countryOptions[0]);
        setSelectedSize(sizeOptions[0]);

        // Reset selectFilter to have all values as false
        const resetSelectFilter = selectFilter.map((item) => ({
            ...item,
            value: false,
        }));
        setSelectFilter(resetSelectFilter);

        // Reset selectAll to false
        setSelectAll(false);
        setKeyword('')
        setIsAmazonAdded(null)
        // Reset inputLabels to have minValue and maxValue as 0 for all items
        const resetInputLabels = inputLabels.map((item) => ({
            ...item,
            minValue: '',
            maxValue: '',
        }));
        setInputLabels(resetInputLabels);
        setQueryString('')
        navigate('')
        setPage(1)
        setInputPage(1)
        setOrderBy('')
        setOrderType('')
        setQueryString(
            `minSaleCount=&maxSaleCount=&minPrice=&maxPrice=&minRevenue=&maxRevenue=&minRivalCount=&maxRivalCount=&minBsr=&maxBsr=&minImageCount=&maxImageCount=&minReviewCount=&maxReviewCount=&minRating=&maxRating=&minAge=&maxAge=&minVariationCount=&maxVariationCount=&categoryIds=&isAmazonOnList=&sizeTierCode=&keyword=&orderType=&orderBy=`
        );
        navigate(
            `?page=1&minSaleCount=&maxSaleCount=&minPrice=&maxPrice=&minRevenue=&maxRevenue=&minRivalCount=&maxRivalCount=&minBsr=&maxBsr=&minImageCount=&maxImageCount=&minReviewCount=&maxReviewCount=&minRating=&maxRating=&minAge=&maxAge=&minVariationCount=&maxVariationCount=&categoryIds=&isAmazonOnList=&sizeTierCode=&keyword=&orderType=&orderBy=`
        );
    };
    // Use this function where you need to reset the filters, like in a "Clear" button click handler.

    const handleOrderTypeBy = (name: string, orderType: boolean) => {
        setOrderType(orderType === true ? 'DESC' : 'ASC')
        setOrderBy(name)
        let sizeTierCode;
        if (selectedSize !== 'Hepsi' && selectedSize !== 'All') {
            sizeTierCode = (sizeOptions.indexOf(selectedSize))
        } else {
            sizeTierCode = ''
        }
        const selectedCategories = selectFilter
            .filter((item) => item.value)
            .map((item) => item.id).filter(id => id > 0);
        setQueryString(
            `minSaleCount=${inputLabels[0].minValue}&maxSaleCount=${inputLabels[0].maxValue}&minPrice=${inputLabels[1].minValue}&maxPrice=${inputLabels[1].maxValue}&minRevenue=${inputLabels[2].minValue}&maxRevenue=${inputLabels[2].maxValue}&minRivalCount=${inputLabels[3].minValue}&maxRivalCount=${inputLabels[3].maxValue}&minBsr=${inputLabels[4].minValue}&maxBsr=${inputLabels[4].maxValue}&minImageCount=${inputLabels[5].minValue}&maxImageCount=${inputLabels[5].maxValue}&minReviewCount=${inputLabels[6].minValue}&maxReviewCount=${inputLabels[6].maxValue}&minRating=${inputLabels[7].minValue}&maxRating=${inputLabels[7].maxValue}&minAge=${inputLabels[8].minValue}&maxAge=${inputLabels[8].maxValue}&minVariationCount=${inputLabels[9].minValue}&maxVariationCount=${inputLabels[9].maxValue}&categoryIds=${selectedCategories}&isAmazonOnList=${isAmazonAdded !== null ? isAmazonAdded : ''}&sizeTierCode=${sizeTierCode}&keyword=${keyword}&orderType=${orderType === true ? 'DESC' : 'ASC'}&orderBy=${name}`
        );
        navigate(
            `?page=${page}&minSaleCount=${inputLabels[0].minValue}&maxSaleCount=${inputLabels[0].maxValue}&minPrice=${inputLabels[1].minValue}&maxPrice=${inputLabels[1].maxValue}&minRevenue=${inputLabels[2].minValue}&maxRevenue=${inputLabels[2].maxValue}&minRivalCount=${inputLabels[3].minValue}&maxRivalCount=${inputLabels[3].maxValue}&minBsr=${inputLabels[4].minValue}&maxBsr=${inputLabels[4].maxValue}&minImageCount=${inputLabels[5].minValue}&maxImageCount=${inputLabels[5].maxValue}&minReviewCount=${inputLabels[6].minValue}&maxReviewCount=${inputLabels[6].maxValue}&minRating=${inputLabels[7].minValue}&maxRating=${inputLabels[7].maxValue}&minAge=${inputLabels[8].minValue}&maxAge=${inputLabels[8].maxValue}&minVariationCount=${inputLabels[9].minValue}&maxVariationCount=${inputLabels[9].maxValue}&categoryIds=${selectedCategories}&isAmazonOnList=${isAmazonAdded !== null ? isAmazonAdded : ''}&sizeTierCode=${sizeTierCode}&keyword=${keyword}&orderType=${orderType === true ? 'DESC' : 'ASC'}&orderBy=${name}`
        );
    };
    useEffect(() => {
        getProductSearch()
    }, [queryParams])

    useEffect(() => {
        if (searchParams.has('minSaleCount')) {
            setPage(Number(temp.page));
            setInputPage(Number(temp.page));
            console.log('setQueryParams 1')
            setQueryParams({
                minSaleCount: temp.minSaleCount,
                maxSaleCount: temp.maxSaleCount,
                minPrice: temp.minPrice,
                maxPrice: temp.maxPrice,
                minRevenue: temp.minRevenue,
                maxRevenue: temp.maxRevenue,
                minRivalCount: temp.minRivalCount,
                maxRivalCount: temp.maxRivalCount,
                minVariationCount: temp.minVariationCount,
                maxVariationCount: temp.maxVariationCount,
                minBsr: temp.minBsr,
                maxBsr: temp.maxBsr,
                minImageCount: temp.minImageCount,
                maxImageCount: temp.maxImageCount,
                minReviewCount: temp.minReviewCount,
                maxReviewCount: temp.maxReviewCount,
                minRating: temp.minRating,
                maxRating: temp.maxRating,
                minAge: temp.minAge,
                maxAge: temp.maxAge,
                categoryIds: temp.categoryIds,
                isAmazonOnList: temp.isAmazonOnList,
                sizeTierCode: temp.sizeTierCode,
                keyword: temp.keyword,
                orderType: temp.orderType,
                orderBy: temp.orderBy
            });
            setQueryString(
                `minSaleCount=${temp.minSaleCount}&maxSaleCount=${temp.maxSaleCount}&minPrice=${temp.minPrice}&maxPrice=${temp.maxPrice}&minRevenue=${temp.minRevenue}&maxRevenue=${temp.maxRevenue}&minRivalCount=${temp.minRivalCount}&maxRivalCount=${temp.maxRivalCount}&minBsr=${temp.minBsr}&maxBsr=${temp.maxBsr}&minImageCount=${temp.minImageCount}&maxImageCount=${temp.maxImageCount}&minReviewCount=${temp.minReviewCount}&maxReviewCount=${temp.maxReviewCount}&minRating=${temp.minRating}&maxRating=${temp.maxRating}&minAge=${temp.minAge}&maxAge=${temp.maxAge}&minVariationCount=${temp.minVariationCount}&maxVariationCount=${temp.maxVariationCount}&categoryIds=${temp.categoryIds}&isAmazonOnList=${temp.isAmazonOnList !== null ? temp.isAmazonOnList : ''}&sizeTierCode=${temp.sizeTierCode}&keyword=${temp.keyword}&orderType=${temp.orderType}&orderBy=${temp.orderBy}`
            );
        } else if (!searchParams.has('minSaleCount') && searchParams.has('page')) {
            setPage(Number(temp.page));
            setInputPage(Number(temp.page));
            console.log('setQueryParams 2')
            setQueryParams({
                minSaleCount: '',
                maxSaleCount: '',
                minPrice: '',
                maxPrice: '',
                minRevenue: '',
                maxRevenue: '',
                minRivalCount: '',
                maxRivalCount: '',
                minVariationCount: '',
                maxVariationCount: '',
                minBsr: '',
                maxBsr: '',
                minImageCount: '',
                maxImageCount: '',
                minReviewCount: '',
                maxReviewCount: '',
                minRating: '',
                maxRating: '',
                minAge: '',
                maxAge: '',
                categoryIds: '',
                isAmazonOnList: '',
                sizeTierCode: '',
                keyword: '',
                orderType: '',
                orderBy: ''
            });
        }
    }, [searchParams]);
    return (
        <div>
            <Suspense fallback={<BannerLoading />}>
                <NewBanner
                    bgUrl="/images/danismanlik-bg.webp"
                    toolImageUrl="amazon-product-finder"
                />
                <AmazonProductFinderFilter
                    sizeOptions={sizeOptions}
                    selectedSize={selectedSize}
                    setSelectedSize={setSelectedSize}
                    selectFilter={selectFilter}
                    setSelectFilter={setSelectFilter}
                    inputLabels={inputLabels}
                    setInputLabels={setInputLabels}
                    selectAll={selectAll}
                    setSelectAll={setSelectAll}
                    isAmazonAdded={isAmazonAdded}
                    setIsAmazonAdded={setIsAmazonAdded}
                    keyword={keyword}
                    setKeyword={setKeyword}
                    resetFilters={resetFilters}
                    handleSubmit={handleSubmit}
                />
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                    {loading ? (
                        <DotLoaderSpinner loading={loading} />
                    ) : (products.length! > 0 ?
                        (
                            <AmazonProductFinderTable
                                products={products}
                                isLoading={loading}
                                setPage={setPage}
                                page={page}
                                inputPage={inputPage}
                                setInputPage={setInputPage}
                                queryString={queryString}
                                totalPageCount={totalPageCount}
                                handleOrderTypeBy={handleOrderTypeBy}
                            />
                        )
                        : (
                            <ProductNotFound />
                        ))}
                </ErrorBoundary>
            </Suspense>
        </div>
    )
}

export default AmazonProductFinder