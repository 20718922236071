import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { useAuth } from '../../context/AuthContext';
import { useLanguage } from '../../context/LanguageContext';

interface GPTProductLinkProps {
  setProductLink: React.Dispatch<React.SetStateAction<string>>;
}

const GPTProductLink: React.FC<GPTProductLinkProps> = ({ setProductLink }) => {
  const { session } = useAuth();
  //   id: 9485,
  //   firstName: "Gayri Meşru Danışmanı",
  //   lastName: "Özgür",
  //   role: "pt04n1dT",
  //   email: "klcomer92@gmail.com",
  //   inCommunity: true,
  //   isSeller: false,
  //   profileImage: "419ff1ee6c9deb1c9bc90e61faa05a7a28a08752a7fb34c114ccc56ede0da660",
  //   level: 5,
  //   levelName: "10.000$ Satışa Ulaştım",
  //   lifeTimeCourse: false,
  //   hasPackage: false,
  //   isGsmVerified: true,
  //   netPromoterScoreDisplay: false,
  //   reviewToolCounters: {
  //     amazonReviewToolCounter: 3
  //   },
  //   discordInviteLink: null,
  //   isDiscordCheck: null,
  //   discordUserId: null,
  //   isInitialSurveyDone: true,
  //   isTrialEnd: false,
  //   rexvenSurveyDisplay: false,
  //   isTrendyolIntegrated: false,
  //   isAliExpressIntegrated: false,
  //   location: "abroad",
  //   isServiceProvider: false,
  //   gsmNumber: "+905303397059",
  //   hasShipEntegraInt: false,
  //   isSupplier: false,
  //   packageName: "Elit"
  // });
  const { t } = useLanguage();
  const [userToolCount, setUserToolCount] = useState('');
  const [buyString, setBuyString] = useState(false);
  const formik = useFormik({
    initialValues: {
      productLink: '',
    },
    onSubmit: () => {
      // console.log(formik.values);
    },
  });

  //TODO: Hataları localization yap
  // useEffect(() => {
  //   if (
  //     !session?.hasPackage &&
  //     session?.reviewToolCounters.amazonReviewToolCounter! < 3
  //   ) {
  //     // Ücretsiz üye ve daha kullanmamış
  //     setUserToolCount(
  //       'Rexven ücretsiz versiyonu kullandığınız için aracı 3 kere kullanabilirsiniz.'
  //     );
  //   } else if (
  //     !session?.hasPackage &&
  //     session?.reviewToolCounters.amazonReviewToolCounter! > 2
  //   ) {
  //     // Ücretsiz üye ve bir kere kullanmış.
  //     setBuyString(true);
  //   } else if (
  //     session?.hasPackage &&
  //     session?.reviewToolCounters.amazonReviewToolCounter! === 20
  //   ) {
  //     // Ücretli üye hiç kullanmamış
  //     setUserToolCount(t?.('amazon-review-analysis.max20token'));
  //   } else if (
  //     session?.hasPackage &&
  //     session.reviewToolCounters.amazonReviewToolCounter > 0
  //   ) {
  //     setUserToolCount(
  //       // `Yorum analiz aracımızı bu ay ${
  //       //   session.reviewToolCounters.amazonReviewToolCounter
  //       // } defa kullandınız, ${
  //       //   20 - Number(session.reviewToolCounters.amazonReviewToolCounter)
  //       // } hakkınız kaldı`
  //       `${t?.('amazon-review-analysis.reviewUsed')}: ${session.reviewToolCounters.amazonReviewToolCounter
  //       } - ${t?.('amazon-review-analysis.reviewRemain')}: ${20 - Number(session.reviewToolCounters.amazonReviewToolCounter)
  //       }  `
  //     );
  //   }
  // }, [session, t]);
  useEffect(() => {
    const packageUsageLimits: Record<string, number> = {
      Free: 3,
      'Rexven Plus': 10,
      'Rexven Premium': 20,
      Elit: 30,
    };

    const userPackage = session?.packageName || 'Free';
    const usageLimit = packageUsageLimits[userPackage] || 3;
    const usageCount = session?.reviewToolCounters.amazonReviewToolCounter || 0;

    if (userPackage === 'Free' && usageCount < usageLimit) {
      setUserToolCount(
        `Rexven ücretsiz versiyonu kullandığınız için aracı ${usageLimit} kere kullanabilirsiniz.`
      );
    } else if (userPackage === 'Free' && usageCount >= usageLimit) {
      setBuyString(true);
    } else if (usageCount >= usageLimit) {
      setUserToolCount(t?.(`amazon-review-analysis.max${usageLimit}token`));
    } else {
      setUserToolCount(
        `${t?.('amazon-review-analysis.reviewUsed')}: ${usageCount} - ${t?.(
          'amazon-review-analysis.reviewRemain'
        )}: ${usageLimit - usageCount}`
      );
    }
  }, [session, t]);

  return (
    <div>
      <form className="grid gap-8 grid-cols-12" onSubmit={formik.handleSubmit}>
        <div className="col-span-12 text-center lg:col-span-5 lg:pt-[1px] xl:pt-1 lg:text-left">
          <label htmlFor="productLink" className="text-sm text-headerPrimary">
            {/* Yorumlarını Analiz Etmek İstediğiniz Ürünün Linkini Yapıştırınız: */}
          </label>
          <p className="p-1 text-xs lg:-ml-1 text-cardBorder">
            {buyString ? (
              <p>
                {t?.('amazon-review-analysis.usageExceed')}
                <Link
                  className=" underline"
                  target={'_blank'}
                  to="https://rexven.com/topluluk-paketleri "
                >
                  {t?.('amazon-review-analysis.subscribe')}
                </Link>
              </p>
            ) : (
              userToolCount
            )}
          </p>
        </div>
        <div className="col-span-12 px-2 lg:col-span-7 relative">
          <div className="relative">
            <input
              id="productLink"
              name="productLink"
              value={formik.values.productLink}
              onChange={(e) => {
                formik.handleChange(e);
                setProductLink(e.target.value);
              }}
              className="block w-full px-2 text-xs py-2  placeholder:italic placeholder:text-slate-400 border border-cardBorder  text-headerPrimary focus:outline-none focus:border-cardBorder rounded-lg bg-gray-50"
              placeholder="https://www.amazon.com/Kuyal-Multi-Guitar-Display-Folding-Acoustic..."
            />
          </div>
          <p className="p-1 text-xs text-cardBorder">
            {t?.('amazon-review-analysis.toolCountries')}
          </p>
        </div>
      </form>
    </div>
  );
};

export default GPTProductLink;
