import React from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../../../context/LanguageContext';

const CoursesCard = () => {
  const { language } = useLanguage();
  return (
    <div className="bg-white px-2 pt-4 pb-6 shadow-lg rounded-xl col-span-2 md:col-span-1 ">
      <p className="px-4 font-semibold text-headerPrimary hover:font-bold">
        <Link to="/courses" className="cursor-pointer">
          {language === 'tr' ? 'Eğitimler' : 'Courses'}
        </Link>
      </p>
      <div className="flex flex-row gap-4 justify-between items-center px-2 h-full">
        <Link
          className="flex flex-col gap-4 justify-center items-center  transition delay-150 hover:scale-110 h-[151px]"
          to="/courses"
        >
          <img
            className="py-2 px-2 object-contain"
            src="/images/dashboardAmazon.svg"
            alt="Rexven Logo"
          />
          <span className=" text-xs sm:text-sm font-medium text-headerPrimary text-center">
            Amazon
          </span>
        </Link>
        <Link
          className="flex flex-col gap-4 justify-center items-center  transition delay-150 hover:scale-110 h-[151px]"
          to="/courses"
        >
          <img
            className="py-2 px-2 object-contain"
            src="/images/dashboardEtsy.svg"
            alt="Rexven Logo"
          />
          <span className=" text-xs sm:text-sm font-medium text-headerPrimary text-center">
            Etsy
          </span>
        </Link>
        <Link
          className="flex flex-col gap-4 justify-center items-center  transition delay-150 hover:scale-110 h-[151px]"
          to="/courses"
        >
          <img
            className="py-2 px-2 object-contain"
            src="/images/dashboardShopify.svg"
            alt="Rexven Logo"
          />
          <span className=" text-xs sm:text-sm font-medium text-headerPrimary text-center">
            Shopify
          </span>
        </Link>
      </div>
    </div>
  );
};

export default CoursesCard;
