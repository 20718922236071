import React from 'react';
import { useLanguage } from '../../context/LanguageContext';
import { Link } from 'react-router-dom';
import { InfoCircle } from 'iconsax-react';

const ShippingInfoCard = () => {
  const { t } = useLanguage();

  return (
    <div className="flex flex-col space-y-3 w-full shadow-lg rounded-lg bg-white p-4 mb-8 text-sm">
      <h3 className="text-headerPrimary font-medium text-lg">Önemli Not</h3>
      <p>
        Çevre uzunluğu belirli bir ölçüden büyük gönderiler için taşıyıcı
        firmalar ek ücret almaktadır. Ek ücret kapsamına giren hesaplama ve ek
        ücretleri aşağıdaki gibidir.
      </p>
      <p>
        Uluslararası hava taşımacılığında çevre uzunluk hesaplama; [(iki kısa
        kenarın toplamı) x 2] + Uzun kenar şeklindedir.
      </p>
      <p>
        <strong>FedEx</strong> ile çıkan gönderileriniz için;
      </p>
      <p>
        Bir kenarı <strong>270 cm’</strong> den büyükse <strong>68</strong> kg
        üzerinden faturalanmaktadır. Çevre uzunluğu <strong>370 cm’</strong> den
        büyükse <strong>70$</strong> ek ücret alınıp faturalanmaktadır.
      </p>
      <p>UPS ile çıkan gönderileriniz için;</p>
      <p>
        Çevre uzunluğu <strong>295 cm</strong> ile <strong>400 cm</strong>{' '}
        arasında ise <strong>48 kg</strong> üzerinden faturalandırılır ve{' '}
        <strong>125$</strong> ek ücret alınır. <br />
        Gönderinizin bir kenarının uzunluğu 98 cm’ den büyükse ek olarak 16$
        alınır. <br />
        Gönderinizin iki kenar uzunluğu 75 cm’ den büyükse ek olarak 16$ alınır.{' '}
        <br />
        Gönderinizin gerçek ağırlığı 30 kg’ ın üstündeyse ek olarak 16$ alınır.{' '}
        <br />
        Bir paketin ölçüleri 70 desiyi aşıyorsa ek olarak 270$ alınır. <br />
        Gönderinizin bir kenar uzunluğu 270 cm’ den büyükse veya çevre uzunluğu
        400 cm’ den büyükse 270$ ek ücret alınır. <br />
      </p>
      <p>
        <strong>TNT</strong> ile çıkan gönderileriniz için;
      </p>
      <p>
        Gönderinizin bir kenar uzunluğu 120 cm ile 150 cm arasında ise 36$ ek
        ücret alınır. Gönderinizin bir kenar uzunluğu 150 cm’ den büyükse 54$ ek
        ücret alınır.
      </p>
      <p>
        *Fedex ve TNT ile çıkacak olan gönderilerde posta koduna göre uzak bölge
        ücreti yansıyabilir.
      </p>
      <Link
        to={'/etsy-profit-calculator'}
        className="text-[#0EA5E9] flex items-center space-x-2 justify-start"
      >
        <InfoCircle size="24" color="#0EA5E9" />
        <p>
          Bir gönderinin başlıca pazar yerlerinde karlılık oranını buradan
          hesaplayabilirsiniz.
        </p>
      </Link>
    </div>
  );
};

export default ShippingInfoCard;
