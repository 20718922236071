import { Box, Calendar, DiscountShape } from 'iconsax-react';
import React from 'react';

import {
    BuildingStorefrontIcon, GlobeAltIcon, MapPinIcon, RectangleStackIcon
} from '@heroicons/react/24/outline';

import { useLanguage } from '../../context/LanguageContext';
import { Producer } from '../../pages/Manufacturer';
import RatingStars from '../common/RatingStars';

interface ManufacturerInfoProps {
  producer: Producer;
  handleChange: (e: any) => void;
  productCount: number | null;
}

const ManufacturerInfo: React.FC<ManufacturerInfoProps> = ({
  producer,
  handleChange,
  productCount,
}) => {
  const { t } = useLanguage();
  return (
    <>
      <div className="mt-4 text-[13px] lg:text-[15px]  grid grid-cols-2 gap-5  sm:grid-cols-2 sm:gap-6 xl:grid-cols-4">
        <div className="col-span-1 ">
          <div className="flex items-center xl:justify-start text-[#374151]">
            {/* <BuildingStorefrontIcon className="h-10 w-10 bg-lightGray p-2 rounded-full mr-4 " /> */}
            <Calendar className="h-10 w-10 bg-lightGray p-2 rounded-full mr-4 " />
            <span className="text-right">
              {t?.('manufacturer.page.storeAge')}: {producer?.producerSince}{' '}
            </span>
          </div>
        </div>
        <div className="col-span-1">
          <div className="flex items-center xl:justify-center text-[#374151]">
            <MapPinIcon className="h-10 w-10 bg-lightGray p-2 rounded-full mr-4 " />
            <span>
              {t?.('manufacturer.page.location')}: {producer?.location}{' '}
            </span>
          </div>
        </div>
        <div className="col-span-1 ">
          <div className="flex items-center xl:justify-center text-[#374151]">
            {/* <RectangleStackIcon className="h-10 w-10 bg-lightGray p-2 rounded-full mr-4 " /> */}
            <Box className="h-10 w-10 bg-lightGray p-2 rounded-full mr-4 " />
            <span>
              {t?.('manufacturer.page.productCount')}: {productCount}
            </span>
          </div>
        </div>
        <div className="col-span-1">
          <div className="flex items-center xl:justify-end text-[#374151]">
            {/* <GlobeAltIcon className="h-10 w-10 bg-lightGray p-2 rounded-full mr-4 " /> */}
            <DiscountShape className="h-10 w-10 bg-lightGray p-2 rounded-full mr-4 " />
            <span>
              {t?.('manufacturer.page.discount')}: %{producer?.discountRate < 50 ? 50 : producer?.discountRate}{' '}
            </span>
          </div>
        </div>
      </div>

      <div className="mt-6 grid grid-cols-3 gap-5">
        <div className="col-span-1">
          <div className="flex items-center">
            <RatingStars
              rating={Number(producer?.producerRating)}
              shop={true}
            />
          </div>
        </div>
        <div className="col-span-2 xl:col-span-1 mt-1 text-center  text-xs text-[#6B7280]">
          <p>
            {producer?.productRatingCount} {t?.('manufacturer.page.rating')} |{' '}
            {producer?.productReviewCount} {t?.('manufacturer.page.comment')}{' '}
          </p>
        </div>
        {/* <div className=" col-span-3  xl:col-span-1  xl:text-right items-center text-center">
          <select
            onChange={handleChange}
            className=" xl:w-52 w-full  text-sm border-2 border-lightGray rounded-lg"
          >
            <option value="higherComments">
              {t?.('manufacturer.page.higherComments')}
            </option>
            <option value="lowestComments">
              {t?.('manufacturer.page.leastComment')}
            </option>
            <option value="higherPrices">
              {t?.('manufacturer.page.highestPrice')}
            </option>
            <option value="lowestPrices">
              {t?.('manufacturer.page.lowestPrice')}
            </option>
          </select>
        </div> */}
      </div>
    </>
  );
};

export default ManufacturerInfo;
