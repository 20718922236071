import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { seperateNumberWithComma } from '../../../../utils/numberFormatter';
import { DaysDataType, FinancialData } from '../../DummyDatasAndTypes';
import { CardTitle } from './SellerDashboardCardGroups';

interface ExpenseCardProps {
    cardTitles: CardTitle[]
    allDayData: DaysDataType
}
const ExpenseCard: React.FC<ExpenseCardProps> = ({ cardTitles, allDayData }) => {
    const [cardData, setCardData] = useState({} as { [title: string]: FinancialData });

    const getDataForCard = (
        title: string,
        todayData: FinancialData,
        sevenDayData: FinancialData,
        thirtyDayData: FinancialData,
        ninetyDayData: FinancialData
    ): FinancialData => {
        switch (title) {
            case 'Bugün':
                return todayData;
            case 'Son 7 Gün':
                return sevenDayData;
            case 'Son 30 Gün':
                return thirtyDayData;
            case 'Son 90 Gün':
                return ninetyDayData;
            default:
                return {
                    totalRevenue: {
                        revenue: 0,
                        profitOrLoss: 0,
                        orderCount: 0,
                        costs: 0,
                        profitMargin: 0,
                        returnCount: 0,
                        saleCount: 0
                    },
                    totalCost: 0,
                    profitOrLoss: 0,
                    profitMargin: 0
                };
        }
    };

    useEffect(() => {
        let newCardData: { [title: string]: FinancialData } = {};
        cardTitles.forEach((card) => {
            const data = getDataForCard(
                card.title,
                allDayData.data.day,
                allDayData.data.week,
                allDayData.data.month,
                allDayData.data.quarter
            );
            newCardData[card.title] = data;
        });
        setCardData(newCardData);
    }, [allDayData, cardTitles]);

    const chartOptions = {
        options: {
            plotOptions: {
                pie: {
                    startAngle: -90,
                    endAngle: 90,
                    offsetY: 10
                }
            },
            dataLabels: {
                enabled: false
            },
            tooltip: {
                enabled: false,
            },
            states: {
                active: {
                    filter: {
                        type: 'none',
                    }
                }
            },
            colors:
                ["#E3211E", `${'#E3211E'}33`]
            ,
            responsive: [{
                breakpoint: 480,
                options: {
                    chart: {
                        width: 250
                    },
                    legend: {
                        show: false
                    }
                }
            }],
            legend: {
                show: false
            }
        },
    }

    return (
        <div className='grid grid-cols-12 gap-4 my-8 my-2'>
            {cardTitles.map((card) => {
                let data = cardData[card.title];
                let totalCost = data?.totalCost ?? 0;
                let profitMargin = data?.profitMargin ?? 0;
                let revenue = data?.totalRevenue?.revenue ?? 0;
                let chartvalue = [100 - profitMargin, profitMargin]
                return (
                    <div className='col-span-12 lg:col-span-6 xl:col-span-3 bg-lightGray border rounded-md shadow-md p-2 w-full lg:w-auto min-h-[450px] flex flex-col justify-between items-center'>
                        <div className='flex flex-col justify-start w-full'>
                            <p className='font-semibold text-sm'>{card.title}</p>
                            <p className='text-xs font-thin text-cardBorder mb-2'>{card.date}</p>
                        </div>
                        <div className="flex justify-center items-center relative">
                            <ReactApexChart
                                options={chartOptions?.options}
                                series={chartvalue}
                                type="donut"
                                height={225}
                                width={225}
                            />
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '45%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    fontSize: '1rem',
                                    zIndex: 1,
                                    color: "#E3211E"
                                }}
                            >
                                <p className='text-center'> Gider</p>
                                <p className='text-center font-semibold'>
                                    {seperateNumberWithComma(totalCost)}₺</p>
                            </div>
                        </div>
                        <div className='min-h-[150px] -mt-16 px-4 text-center'>
                            <p>{`${card.title} yapılan satışlardan `}
                                <span className='font-semibold'>
                                    {seperateNumberWithComma(revenue)} ₺ {""}
                                </span>
                                ciro elde etmiş ve {""}
                                <span className='font-semibold'>{seperateNumberWithComma(totalCost)} ₺</span> gideriniz olmuştur.
                            </p>
                            <p>{`Giderlerinizin toplam satışlarınıza oranı = `}
                                <span className='font-semibold'>
                                    % {(100 - profitMargin).toFixed(2)}
                                </span>
                                {` (${seperateNumberWithComma(totalCost)} ₺ / ${seperateNumberWithComma(revenue)} ₺)`}
                                {/* <span className='font-semibold'>{revenue} = % {profitMargin}</span> */}
                            </p>
                        </div>
                    </div >
                )
            })}
        </div>
    )
}

export default ExpenseCard