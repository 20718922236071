import React, { Fragment } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

import PackageCancelModalQuestions from './PackageCancelModalQuestions';
import PackageCancelSuccessModal from './PackageCancelSuccessModal';
import { CancelReasons } from './PackageInfo';

interface PackageCancelModalProps {
    openPackageCancelModal: boolean,
    setOpenPackageCancelModal: React.Dispatch<React.SetStateAction<boolean>>;
    cancelReasons: CancelReasons[]
    cancelModalLoader: boolean
    openPackageCancelSuccessModal: boolean,
    openPackageCancelQuestionModal: boolean,
    handleCancelPackage: (cancelReasons: number[], userFeedback?: string) => void
    error: string
    setError: React.Dispatch<React.SetStateAction<string>>;
}

const PackageCancelModal: React.FC<PackageCancelModalProps> = ({
    openPackageCancelModal,
    setOpenPackageCancelModal,
    cancelReasons,
    openPackageCancelQuestionModal,
    openPackageCancelSuccessModal,
    handleCancelPackage,
    cancelModalLoader,
    error,
    setError }) => {

    let modalContent;
    if (openPackageCancelQuestionModal) {
        modalContent =
            <PackageCancelModalQuestions error={error!} setError={setError} cancelModalLoader={cancelModalLoader!} handleCancelPackage={handleCancelPackage} setOpenPackageCancelModal={setOpenPackageCancelModal} cancelReasons={cancelReasons!} />
    } else if (openPackageCancelSuccessModal) {
        modalContent =
            <PackageCancelSuccessModal />
    }
    return (
        <Transition.Root show={openPackageCancelModal} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={setOpenPackageCancelModal!}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>
                <div className="fixed inset-0 z-10 overflow-y-auto mt-10">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
                                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        onClick={() => setOpenPackageCancelModal(false)}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                {modalContent}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>)
}

export default PackageCancelModal