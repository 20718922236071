import React, { Suspense, lazy, useEffect } from 'react';
import ProductTable from '../components/exclusive/ProductTable';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './errors/ErrorFallback';
import BannerLoading from '../components/tools/common/BannerLoading';
import memberService from '../services/memberService';
import { useAuth } from '../context/AuthContext';
import { useLanguage } from '../context/LanguageContext';
const NewBanner = lazy(() => import('../components/tools/common/NewBanner'));

const Exclusive = () => {
  const { language } = useLanguage();
  const { session } = useAuth();
  const [isTabActive, setIsTabActive] = React.useState<boolean>(true);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabActive(document.visibilityState === 'visible');
    };

    // Add event listener for visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup function to remove the event listener when the component is unmounted
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    let intervalId: any;
    if (session && isTabActive) {
      intervalId = setInterval(async () => {
        await memberService.userTrackReq(`exclusive`, session?.id!);
      }, 60000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [session, isTabActive]);
  return (
    <div>
      <Suspense fallback={<BannerLoading />}>
        <NewBanner
          bgUrl={'/images/rexven-exclusive-bg.webp'}
          toolImageUrl="rexven-exclusive"
        />
      </Suspense>
      <div className="rounded-xl bg-white p-4 my-8 flex flex-col md:flex-row justify-between items-center">
        <h2 className="text-sm md:text-lg w-full md:w-5/6 space-x-1 mb-2 md:mb-0">
          {language === 'tr'
            ? 'Rexven Exclusive üyesi olmak ve bu ürünlerden satın almak için iletişim kurun'
            : 'Contact us to become a Rexven Exclusive member and purchase these products'}
        </h2>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://form.jotform.com/230192653920958"
          className="bg-[#1F254B] text-white text-xs sm:text-sm lg:text-base text-center px-4 py-[10px] w-full rounded-lg md:w-1/5"
        >
          {language === 'tr' ? 'Randevu Al' : 'Book an Appointment'}
        </a>
      </div>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <ProductTable />
      </ErrorBoundary>
    </div>
  );
};

export default Exclusive;
