import React from 'react';

const ProductNotFound = () => {
  return (
    <div className="flex w-full justify-center items-center flex-col">
      <img className="w-1/2" src="/images/notFound.png" alt="not-found" />
      <h1 className="font-bold text-lg text-rexRed">
        Aradığınız kriterlere uygun sonuç bulunamadı...
      </h1>
    </div>
  );
};

export default ProductNotFound;
