import React from 'react';
import { Icon } from 'iconsax-react';
import LoadingComponent from '../../common/LoadingComponent';

interface EtsyInfoCardProps {
  infos: {
    icon: Icon;
    title: string;
    value: string | number;
    iconColor: string;
    titleColor: string;
    valueColor: string;
  }[];
  resultLoading: boolean;
}
const EtsyInfoCards: React.FC<EtsyInfoCardProps> = ({
  resultLoading,
  infos,
}) => {
  return (
    <div className="flex space-x-1 mt-4">
      {infos.map((infoCard, index) => (
        <div
          key={index}
          className={`flex-1 flex-wrap bg-white flex rounded-xl shadow-md px-1`}
        >
          <div className="flex flex-col items-center text-center mx-auto py-3">
            <infoCard.icon
              className="mb-1"
              size="24"
              color={infoCard.iconColor}
            />
            <p
              style={{ color: infoCard.titleColor }}
              className="mb-1 text-xs xl:text-sm"
            >
              {infoCard.title}{' '}
            </p>
            <p
              style={{ color: infoCard.valueColor }}
              className="mb-1 font-bold text-xs xl:text-sm"
            >
              {resultLoading ? <LoadingComponent /> : infoCard.value}{' '}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default EtsyInfoCards;
