import React from 'react';
import { Outlet, Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import Loading from '../components/loader/Loader';
import Layout from '../layout/Layout';
import { NumberVerificationProvider } from '../context/NumberVerificationContext';

const UserRouter: React.FC = (props) => {
  const { session, isLoading } = useAuth();
  let location = useLocation();
  function extractTitle(a: string) {
    const firstSlashIndex = a.indexOf('/');

    if (firstSlashIndex === -1) {
      // Slash karakteri yoksa tüm string'i geri gönderiyoruz
      return a;
    } else {
      const secondSlashIndex = a.indexOf('/', firstSlashIndex + 1);

      if (secondSlashIndex === -1) {
        // İkinci slash yoksa ilk slash'tan sonrasını geri gönderiyoruz
        return a.substring(firstSlashIndex + 1);
      } else {
        // İki slash varsa ilk iki slash arasındakini geri gönderiyoruz
        return a.substring(firstSlashIndex + 1, secondSlashIndex);
      }
    }
  }
  let pageTitle = extractTitle(location.pathname);

  if (isLoading || session === undefined) {
    return (
      <div className="h-screen w-full flex items-center justify-center">
        <Loading />
      </div>
    );
  }
  if (session === null) {
    return <Navigate to={'/login'} state={{ from: location }} replace />;
  }

  return (
    <NumberVerificationProvider>
      <Layout title={`${pageTitle}.title`}>
        <Outlet />
      </Layout>
    </NumberVerificationProvider>
  );
};
export default UserRouter;
