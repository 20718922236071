import React from 'react';

const LoadingComponent = () => {
  return (
    <div className="flex w-full text-center items-center ">
      <div
        className="h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
        role="status"
      >
        <div className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
          Loading...
        </div>
      </div>
    </div>
  );
};

export default LoadingComponent;
