import React, { useEffect } from 'react';
import memberService from '../services/memberService';
import { IUserkey } from '../interfaces/aliexpressInterfaces';
import AliexpressProductTable from '../components/aliexpress/AliexpressProductTable';
import NeedtoBeIntegrated from '../components/common/NeedtoBeIntegrated';
import { useAuth } from '../context/AuthContext';

interface Store {
  logo?: string;
  producerName?: string;
  phoneNumber?: string;
  status?: string;
}

const AliExpressProducts = () => {
  const [aliexpressProducts, setAliexpressProducts] = React.useState<any[]>([]);
  const [isTabActive, setIsTabActive] = React.useState<boolean>(true);
  const [hasIntegration, setHasIntegration] = React.useState<boolean>(true);
  const { session } = useAuth();
  const [store, setStore] = React.useState<Store>({} as Store);

  const getAliexpressProducts = async () => {
    let result = await memberService.getAliexpressProducts(1);
  };
  // const integrateStore = async (values: any) => {
  //   await memberService.integrateAliexpress(values);
  //   checkAliexpressIntegration();
  // };
  const checkAliexpressIntegration = async () => {
    let result = await memberService.getIntegratedInfo();
    console.log(result);
    // check if user has aliexpress integration
    let aliexpressKeyCount = result.data.filter(
      (i: IUserkey) => i.platform === 'aliexpress'
    ).length;
    if (aliexpressKeyCount > 0) {
      getAliexpressProducts();
      setHasIntegration(true);
    } else {
      setHasIntegration(false);
    }
    // check User has producer values
    let producerCount = result.data.filter(
      (i: IUserkey) => i.producer !== null
    ).length;
    if (producerCount > 0) {
      let storeValues = result.data.filter(
        (i: IUserkey) => i.producer !== null
      )[0].producer;
      setStore(storeValues);
    }
  };

  useEffect(() => {
    checkAliexpressIntegration();
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabActive(document.visibilityState === 'visible');
    };

    // Add event listener for visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup function to remove the event listener when the component is unmounted
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    let intervalId: any;
    if (session && isTabActive) {
      intervalId = setInterval(async () => {
        await memberService.userTrackReq('aliexpress-products', session?.id!);
      }, 60000);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [session, isTabActive]);

  return (
    <>
      {hasIntegration ? (
        <AliexpressProductTable />
      ) : (
        // <IntegrationForm integrateStore={integrateStore} integratedInfo={store} />
        <NeedtoBeIntegrated />
      )}
    </>
  );
};

export default AliExpressProducts;
