import React, { Fragment, useState } from 'react';
import { Formik } from 'formik';
import { useLanguage } from '../../context/LanguageContext';
import * as Yup from 'yup';
import { errorElement } from '../../utils/errorElement';
import { EntegrationFormGet } from '../entegration/EntegrationForm';

interface Props {
  integrateStore: (values: any) => Promise<void>;
  integratedInfo: boolean;
  loading: boolean;
}

// export interface Store {
//   logo?: string;
//   producerName?: string;
//   phoneNumber?: string;
//   status?: string;
// }

const IntegrationForm: React.FC<Props> = ({
  integrateStore,
  integratedInfo,
  loading,
}) => {
  const { t } = useLanguage();
  let btnContent: any;
  if (loading) {
    btnContent = (
      <div className="text-center flex items-center">
        <div
          className="inline-block h-5 w-5 animate-spin rounded-full border-4 border-solid border-current border-r-transparent motion-reduce:animate-[spin_1.5s_linear_infinite]"
          role="status"
        ></div>
        <span className="text-white text-sm ml-3">Değerlendiriliyor...</span>
      </div>
    );
  } else if (integratedInfo) {
    btnContent = 'Tamamlandı';
  } else {
    btnContent = t?.('common.buttons.register');
  }
  return (
    <div
      className="bg-transparent h-[300px] col-span-5 lg:col-span-3 rounded-xl shadow-lg"
      data-testid="entegration-form"
    >
      <div className="flex flex-col flex-1 flex-shrink">
        <div className="py-4 px-6">
          <Formik
            initialValues={{
              apiKey: '',
            }}
            onSubmit={integrateStore}
            validationSchema={Yup.object({
              apiKey: Yup.string().required(
                t?.('aliexpress-integration.errors.apiKey')
              ),
            })}
          >
            {({ handleSubmit, values, handleChange, errors }) => (
              <form className="flex  flex-col gap-y-6" onSubmit={handleSubmit}>
                {/* <div className="flex justify-between items-center gap-x-3 sm:gap-x-1.5"> */}
                {/* <label
                    htmlFor="storeName"
                    className="font-medium text-xs w-2/12 lg:w-3/12 xl:text-sm"
                  >
                    {t?.('aliexpress-integration.page.storeName')}
                  </label> */}
                {/* <div className="flex flex-1 justify-between gap-x-2">
                    <input
                      // disabled={
                      //   integratedInfo?.producer.phoneNumber ? true : false
                      // }
                      value={values?.storeName}
                      // placeholder={integratedInfo?.producer.producerName}
                      onChange={handleChange}
                      data-testid="storeName-input"
                      type="text"
                      name="storeName"
                      id="storeName"
                      className="flex w-full text-xs rounded-md bg-lightGray border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-headingPrimary sm:text-sm sm:leading-6"
                      readOnly={
                        integratedInfo?.producer.producerName ? true : false
                      }
                    />
                  </div>
                </div> */}

                {/* {!integratedInfo?.producer.producerName &&
                  errors.storeName &&
                  errorElement(errors.storeName)} */}

                {/* <div className="flex justify-between items-center gap-x-3 sm:gap-x-1.5">
                  <label
                    htmlFor="phoneNumber"
                    className="font-medium text-xs w-2/12 lg:w-3/12 xl:text-sm"
                  >
                    {t?.('aliexpress-integration.page.phoneNumber')}
                  </label>
                  <div className="flex flex-1 justify-between gap-x-2">
                    <input
                      // disabled={
                      //   integratedInfo?.producer.phoneNumber ? true : false
                      // }
                      value={values.phoneNumber}
                      // placeholder={integratedInfo?.producer.phoneNumber}
                      onChange={handleChange}
                      data-testid="api-secret-input"
                      type="text"
                      name="phoneNumber"
                      id="phoneNumber"
                      className="flex w-full rounded-md text-xs bg-lightGray border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-headingPrimary sm:text-sm sm:leading-6 "
                    />
                  </div> */}
                {/* </div> */}

                {/* {!integratedInfo?.producer.phoneNumber &&
                  errors.phoneNumber &&
                  errorElement(errors.phoneNumber)} */}

                <div className="flex justify-between items-center gap-x-3 sm:gap-x-1.5">
                  <label
                    htmlFor="apiKey"
                    className="font-medium text-xs w-2/12 lg:w-3/12 xl:text-sm"
                  >
                    {t?.('aliexpress-integration.page.apiKey')}
                  </label>
                  <div className="flex flex-1 justify-between gap-x-2">
                    <input
                      value={values.apiKey}
                      placeholder="****************"
                      onChange={handleChange}
                      data-testid="api-key-input"
                      type="text"
                      name="apiKey"
                      id="apiKey"
                      readOnly={integratedInfo}
                      className="flex w-full text-xs rounded-md bg-lightGray border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-headingPrimary sm:text-sm sm:leading-6"
                    />
                  </div>
                </div>

                {errors.apiKey && errorElement(errors.apiKey)}

                <div className="flex  border-t-2 border-lightGray w-full justify-end gap-x-3 ">
                  {
                    <button
                      disabled={integratedInfo}
                      type="submit"
                      className="mt-4 rounded-md bg-headerPrimary py-2.5 px-8 text-xs font-semibold text-white shadow-sm hover:bg-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 md:text-sm"
                    >
                      {btnContent}
                    </button>
                  }
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};
export default IntegrationForm;
