import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useQuery } from '@tanstack/react-query';
import amazonToolsService from '../../../../services/amazonToolsService';
import LoadingComponent from '../../../common/LoadingComponent';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  keyword: string;
}

const GiveawayModal: React.FC<Props> = ({ open, setOpen, keyword }) => {
  const { data, isLoading } = useQuery<any, Error>(['giveaway', keyword], {
    queryFn: () => amazonToolsService.getGiveaway(keyword),
    staleTime: Infinity,
    enabled: !!keyword,
  });

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-auto rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-[600px] sm:p-6">
                <div className="w-[768px] h-[40vh] overflow-y-auto">
                  {/* <div className="w-full flex justify-end mb-4 min-w-[1280px]"> */}
                  {/* <button
                    type="button"
                    className="rounded-md absolute top-0 right-0 bg-white text-headerPrimary hover:text-gray-500 "
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button> */}
                  {/* </div> */}
                  <div className="grid grid-cols-11 min-w-[1280px]">
                    <div className="col-span-11">
                      <div className="grid grid-cols-11">
                        <p className="text-headerPrimary font-medium col-span-1">
                          Kelime
                        </p>
                        <p className="text-headerPrimary font-medium col-span-1">
                          Hacim
                        </p>
                        <p className="text-headerPrimary font-medium col-span-1">
                          1.Gün
                        </p>
                        <p className="text-headerPrimary font-medium col-span-1">
                          2.Gün
                        </p>
                        <p className="text-headerPrimary font-medium col-span-1">
                          3.Gün
                        </p>
                        <p className="text-headerPrimary font-medium col-span-1">
                          4.Gün
                        </p>
                        <p className="text-headerPrimary font-medium col-span-1">
                          5.Gün
                        </p>
                        <p className="text-headerPrimary font-medium col-span-1">
                          6.Gün
                        </p>
                        <p className="text-headerPrimary font-medium col-span-1">
                          7.Gün
                        </p>
                        <p className="text-headerPrimary font-medium col-span-1">
                          8.Gün
                        </p>
                        <p className="text-headerPrimary font-medium col-span-1">
                          Toplam
                        </p>
                      </div>
                    </div>
                    {isLoading ? (
                      <LoadingComponent />
                    ) : (
                      data?.data?.map((item: any) => {
                        return (
                          <div className="col-span-11">
                            <div className="grid grid-cols-11 border-b border-lightGray">
                              <p className="w-full col-span-1 mt-2 text-sm flex items-center">
                                {item.keyword}
                              </p>
                              <p className="w-full col-span-1 mt-2 text-sm flex items-center">
                                {item.searchVolume}
                              </p>
                              <p className="w-full col-span-1 mt-2 text-sm flex items-center">
                                {item.giveawayTotal >= 128
                                  ? item.giveawayTotal / 8 -
                                    2 * Math.floor(item.giveawayTotal / 64)
                                  : item.giveawayTotal / 8}
                              </p>
                              <p className="w-full col-span-1 mt-2 text-sm flex items-center">
                                {item.giveawayTotal >= 128
                                  ? item.giveawayTotal / 8 -
                                    2 * Math.floor(item.giveawayTotal / 64)
                                  : item.giveawayTotal / 8}
                              </p>
                              <p className="w-full col-span-1 mt-2 text-sm flex items-center">
                                {item.giveawayTotal >= 128
                                  ? item.giveawayTotal / 8 -
                                    1 * Math.floor(item.giveawayTotal / 64)
                                  : item.giveawayTotal / 8}
                              </p>
                              <p className="w-full col-span-1 mt-2 text-sm flex items-center">
                                {item.giveawayTotal >= 128
                                  ? item.giveawayTotal / 8 -
                                    0 * Math.floor(item.giveawayTotal / 64)
                                  : item.giveawayTotal / 8}
                              </p>
                              <p className="w-full col-span-1 mt-2 text-sm flex items-center">
                                {item.giveawayTotal >= 128
                                  ? item.giveawayTotal / 8 +
                                    1 * Math.floor(item.giveawayTotal / 64)
                                  : item.giveawayTotal / 8}
                              </p>
                              <p className="w-full col-span-1 mt-2 text-sm flex items-center">
                                {item.giveawayTotal >= 128
                                  ? item.giveawayTotal / 8 +
                                    2 * Math.floor(item.giveawayTotal / 64)
                                  : item.giveawayTotal / 8}
                              </p>
                              <p className="w-full col-span-1 mt-2 text-sm flex items-center">
                                {item.giveawayTotal >= 128
                                  ? item.giveawayTotal / 8 +
                                    1 * Math.floor(item.giveawayTotal / 64)
                                  : item.giveawayTotal / 8}
                              </p>
                              <p className="w-full col-span-1 mt-2 text-sm flex items-center">
                                {item.giveawayTotal >= 128
                                  ? item.giveawayTotal / 8 +
                                    1 * Math.floor(item.giveawayTotal / 64)
                                  : item.giveawayTotal / 8}
                              </p>
                              <p className="w-full col-span-1 mt-2 text-sm flex items-center">
                                {item.giveawayTotal}
                              </p>
                              {/* <p className="w-full col-span-1 mt-2 text-sm">
                              {item.first}
                            </p>
                            <p className="w-full col-span-1 mt-2">
                              {item.second}
                            </p>
                            <p className="w-full col-span-1 mt-2">
                              {item.third}
                            </p>
                            <p className="w-full col-span-1 mt-2">
                              {item.fourth}
                            </p>
                            <p className="w-full col-span-1 mt-2">
                              {item.fifth}
                            </p>
                            <p className="w-full col-span-1 mt-2">
                              {item.sixth}
                            </p>
                            <p className="w-full col-span-1 mt-2">
                              {item.seventh}
                            </p>
                            <p className="w-full col-span-1 mt-2">
                              {item.eight}
                            </p>
                            <p className="w-full col-span-1 mt-2">
                              {item.total}
                            </p> */}
                            </div>
                          </div>
                        );
                      })
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default GiveawayModal;
