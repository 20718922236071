import React from 'react';

const ErrorFallback = () => {
    return (
        <>
            <main className="grid place-items-center px-6 sm:py-32 lg:px-8 xl:py-1 ">
                <div className="text-center">
                    <div>
                        <img
                            src="/assets/images/500-error.png"
                            className="max-h-[400px] w-full"
                            alt='500 page'
                        />
                    </div>
                    <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                        Beklenmeyen bir hata oluştu 😕
                    </h1>
                    <p className="mt-6 text-base leading-7 text-gray-600">
                        Üzgünüz,lütfen daha sonra tekrar deneyin
                    </p>
                </div>
            </main>
        </>
    );
};

export default ErrorFallback;
