import React from 'react';

interface VideoWrapperProps {
  videoSource: string;
}

const VideoWrapper: React.FC<VideoWrapperProps> = ({ videoSource }) => {
  return (
    <div className="grid gap-4 grid-cols-12">
      <div className=" col-span-12 xl:col-span-8 relative ">
        <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
          <iframe
            className="rounded-lg shadow-xl"
            src="https://player.vimeo.com/video/865058706?h=c73d7e1871&title=0&byline=0&portrait=0"
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
            }}
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
      {/* <div className="col-span-12 xl:col-span-8 flex items-center relative">
         <img src="/images/roadMapHero.webp" /> 
      </div> */}
      <div className="hidden xl:block bg-white  xl:col-span-4 p-4 rounded-xl shadow-sm">
        <img
          className="w-full h-full rounded-2xl object-contain"
          width={'100'}
          src="/images/roadMapTek.png"
        />
      </div>
    </div>
  );
};

export default VideoWrapper;
