import React, { useEffect, useState } from 'react';
import NewBanner from '../components/tools/common/NewBanner';
import DatePicker from '../components/trendyolSellerDashboard/DatePicker';
import EntegrationModal from '../components/trendyolSellerDashboard/EntegrationModal';
import SummaryCards from '../components/trendyolSellerDashboard/SummaryCards';
import { useAuth } from '../context/AuthContext';
import TotalTurnover from '../components/trendyolSellerDashboard/totalTurnover/TotalTurnover';
import SellerDashboardCardGroups from '../components/trendyolSellerDashboard/totalTurnover/sellerDahboardCardGroup/SellerDashboardCardGroups';
import memberService from '../services/memberService';
import alertNotification from '../utils/alertNotification';
import trendyolToolsService from '../services/trendyolToolsService';
import { seperateNumberWithComma } from '../utils/numberFormatter';
import {
  DailyDummyConditions,
  DailyDummyConditionsFull,
  DailyDummySummaryDatas,
  DaysDataType,
  DaysType,
  DummyConditions,
  DummyConditionsFull,
  DummySummaryDatas,
  QuarterDummyConditions,
  QuarterDummyConditionsFull,
  QuarterDummySummaryDatas,
  SummaryItem,
  WeeklyDummyConditions,
  WeeklyDummyConditionsFull,
  WeeklyDummySummaryDatas,
} from '../components/trendyolSellerDashboard/DummyDatasAndTypes';

const TrendyolSellerDashboard = () => {
  const { checkSessionStatus, session } = useAuth();
  //TODO: add here original session
  //set's select list and datepickers apprearence
  const [selected, setSelected] = useState({
    title: 'Son 30 Gün',
    current: false,
  });
  const [showDatePicker, setShowDatePicker] = useState(false);
  //modal states

  const [openEntegrationModal, setOpenEntegrationModal] =
    useState<boolean>(false);
  const [entegrationLoading, setEntegrationLoading] = useState(false);
  const [isTabActive, setIsTabActive] = React.useState<boolean>(true);

  //in use effect we define dates to use in every fetch
  const [days, setDays] = useState<DaysType>({
    today: '',
    sevenDaysAgo: '',
    thirtyDaysAgo: '',
    ninetyDaysAgo: '',
  });
  //dates to use user selects from select list
  const [selectedDate, setSelectedDate] = useState({
    startDate: days.thirtyDaysAgo,
    endDate: days.today,
  });
  //we will use these dates to fetch data after user choose date from date picker
  const [datePickerStartDate, setDatePickerStartDate] = useState(new Date());
  const [datePickerEndDate, setDatePickerEndDate] = useState<Date | null>(null);
  const [datDiff, setDayDiff] = useState(90);
  //data to use in summary cars at top of the page
  const [summary, setSummary] = useState<SummaryItem[]>(DummySummaryDatas);

  //states to pass detail card
  const [conditions, setConditions] = useState(DummyConditions);
  const [conditionsFull, setConditionsFull] = useState(DummyConditionsFull);

  //data to pass card groups
  const [allDayData, setAllDayData] = useState<DaysDataType>({
    loading: false,
    data: {
      day: {
        totalRevenue: {
          revenue: 1755,
          profitOrLoss: 487,
          orderCount: 7,
          costs: 1268,
          profitMargin: 24,
          returnCount: 1,
          saleCount: 10,
        },
        totalCost: 1268,
        profitOrLoss: 487,
        profitMargin: 24,
      },
      week: {
        totalRevenue: {
          revenue: 9505,
          profitOrLoss: 2656,
          orderCount: 23,
          costs: 1594,
          profitMargin: 26,
          returnCount: 4,
          saleCount: 33,
        },
        totalCost: 1594,
        profitOrLoss: 2656,
        profitMargin: 26,
      },
      month: {
        totalRevenue: {
          revenue: 29467,
          profitOrLoss: 8235,
          orderCount: 72,
          costs: 4944,
          profitMargin: 51,
          returnCount: 9,
          saleCount: 96,
        },
        totalCost: 4944,
        profitOrLoss: 8235,
        profitMargin: 51,
      },
      quarter: {
        totalRevenue: {
          revenue: 70720,
          profitOrLoss: 19764,
          orderCount: 163,
          costs: 11865,
          profitMargin: 76,
          returnCount: 25,
          saleCount: 196,
        },
        totalCost: 11865,
        profitOrLoss: 19764,
        profitMargin: 76,
      },
    },
    error: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(false);

  const getSellerDashboardDetails = () => {
    if (
      session?.producerId &&
      (selectedDate.startDate || selectedDate.endDate) &&
      !session?.isTrialEnd
    ) {
      setIsLoading(true);
      trendyolToolsService
        .getSellerDashboardDetails(
          session?.producerId,
          selectedDate?.startDate,
          selectedDate?.endDate
        )
        .then((res) => {
          setDayDiff(res.dayDiff);
          if (res?.data) {
            setSummary([
              {
                ...summary[0],
                amountText:
                  res?.data.totalRevenue !== undefined &&
                  res?.data.totalRevenue !== null
                    ? seperateNumberWithComma(res?.data.totalRevenue)
                    : 0,
              },
              {
                ...summary[1],
                amountText:
                  res?.data.profitOrLoss !== undefined &&
                  res?.data.profitOrLoss !== null
                    ? seperateNumberWithComma(res?.data.profitOrLoss)
                    : 0,
              },
              {
                ...summary[2],
                amountText:
                  res?.data.adCost !== undefined && res?.data.adCost !== null
                    ? seperateNumberWithComma(res?.data.adCost)
                    : 0,
              },
              {
                ...summary[3],
                amountText:
                  res?.data.productCost !== undefined &&
                  res?.data.productCost !== null
                    ? seperateNumberWithComma(res?.data.productCost)
                    : 0,
              },
              {
                ...summary[4],
                amountText:
                  res?.data.returnCost !== undefined &&
                  res?.data.returnCost !== null
                    ? seperateNumberWithComma(res?.data.returnCost)
                    : 0,
              },
              {
                ...summary[5],
                amountText:
                  res?.data.trCut !== undefined && res?.data.trCut !== null
                    ? seperateNumberWithComma(res?.data.trCut)
                    : 0,
              },
              {
                ...summary[6],
                amountText:
                  res?.data.shipCost !== undefined &&
                  res?.data.shipCost !== null
                    ? seperateNumberWithComma(res?.data.shipCost)
                    : 0,
              },
              {
                ...summary[7],
                amountText:
                  res?.data.totalVat !== undefined &&
                  res?.data.totalVat !== null
                    ? seperateNumberWithComma(res?.data.totalVat)
                    : 0,
              },
              {
                ...summary[8],
                amountText:
                  res?.data.profitMargin !== undefined &&
                  res?.data.profitMargin !== null
                    ? seperateNumberWithComma(res?.data.profitMargin)
                    : 0,
              },
              {
                ...summary[9],
                amountText:
                  res?.data.profitRate !== undefined &&
                  res?.data.profitRate !== null
                    ? seperateNumberWithComma(res?.data.profitRate)
                    : 0,
              },
            ]);

            let newConditions = [...conditions]; // create a copy of the summary state
            let firstElevenItems = [...conditionsFull.slice(0, 12)]; // get the first 11 items

            // Update the value properties in the newConditions object
            newConditions[0].value =
              res?.data.totalRevenue !== undefined &&
              res?.data.totalRevenue !== null
                ? seperateNumberWithComma(res?.data.totalRevenue)
                : 0;
            newConditions[1].value =
              res?.data.trCut !== undefined && res?.data.trCut !== null
                ? seperateNumberWithComma(res?.data.trCut)
                : 0;
            newConditions[2].value =
              res?.data.trCommission !== undefined &&
              res?.data.trCommission !== null
                ? seperateNumberWithComma(res?.data.trCommission)
                : 0;
            newConditions[3].value =
              res?.data.serviceCost !== undefined &&
              res?.data.serviceCost !== null
                ? seperateNumberWithComma(res?.data.serviceCost)
                : 0;
            newConditions[4].value =
              res?.data.shipCost !== undefined && res?.data.shipCost !== null
                ? seperateNumberWithComma(res?.data.shipCost)
                : 0;
            newConditions[5].value =
              res?.data.productCost !== undefined &&
              res?.data.productCost !== null
                ? seperateNumberWithComma(res?.data.productCost)
                : 0;
            newConditions[6].value =
              res?.data.adCost !== undefined && res?.data.adCost !== null
                ? seperateNumberWithComma(res?.data.adCost)
                : 0;
            newConditions[7].value =
              res?.data.returnCost !== undefined &&
              res?.data.returnCost !== null
                ? seperateNumberWithComma(res?.data.returnCost)
                : 0;
            newConditions[8].value =
              res?.data.totalVat !== undefined && res?.data.totalVat !== null
                ? seperateNumberWithComma(res?.data.totalVat)
                : 0;
            newConditions[9].value =
              res?.data.profitOrLoss !== undefined &&
              res?.data.profitOrLoss !== null
                ? seperateNumberWithComma(res?.data.profitOrLoss)
                : 0;
            newConditions[10].value =
              res?.data.profitMargin !== undefined &&
              res?.data.profitMargin !== null
                ? seperateNumberWithComma(res?.data.profitMargin)
                : 0;
            newConditions[11].value =
              res?.data.profitRate !== undefined &&
              res?.data.profitRate !== null
                ? seperateNumberWithComma(res?.data.profitRate)
                : 0;

            // Update the value properties in the firstElevenItems object
            firstElevenItems[0].value =
              res?.data.totalRevenue !== undefined &&
              res?.data.totalRevenue !== null
                ? seperateNumberWithComma(res?.data.totalRevenue)
                : 0;
            firstElevenItems[1].value =
              res?.data.trCut !== undefined && res?.data.trCut !== null
                ? seperateNumberWithComma(res?.data.trCut)
                : 0;
            firstElevenItems[2].value =
              res?.data.trCommission !== undefined &&
              res?.data.trCommission !== null
                ? seperateNumberWithComma(res?.data.trCommission)
                : 0;
            firstElevenItems[3].value =
              res?.data.serviceCost !== undefined &&
              res?.data.serviceCost !== null
                ? seperateNumberWithComma(res?.data.serviceCost)
                : 0;
            firstElevenItems[4].value =
              res?.data.shipCost !== undefined && res?.data.shipCost !== null
                ? seperateNumberWithComma(res?.data.shipCost)
                : 0;
            firstElevenItems[5].value =
              res?.data.productCost !== undefined &&
              res?.data.productCost !== null
                ? seperateNumberWithComma(res?.data.productCost)
                : 0;
            firstElevenItems[6].value =
              res?.data.adCost !== undefined && res?.data.adCost !== null
                ? seperateNumberWithComma(res?.data.adCost)
                : 0;
            firstElevenItems[7].value =
              res?.data.returnCost !== undefined &&
              res?.data.returnCost !== null
                ? seperateNumberWithComma(res?.data.returnCost)
                : 0;
            firstElevenItems[8].value =
              res?.data.totalVat !== undefined && res?.data.totalVat !== null
                ? seperateNumberWithComma(res?.data.totalVat)
                : 0;
            firstElevenItems[9].value =
              res?.data.profitOrLoss !== undefined &&
              res?.data.profitOrLoss !== null
                ? seperateNumberWithComma(res?.data.profitOrLoss)
                : 0;
            firstElevenItems[10].value =
              res?.data.profitMargin !== undefined &&
              res?.data.profitMargin !== null
                ? seperateNumberWithComma(res?.data.profitMargin)
                : 0;
            firstElevenItems[11].value =
              res?.data.profitRate !== undefined &&
              res?.data.profitRate !== null
                ? seperateNumberWithComma(res?.data.profitRate)
                : 0;

            setConditions(newConditions); // Update the state
            // Combine the updated first 11 items with the remaining items
            let newConditionsFull = [
              ...firstElevenItems,
              ...conditionsFull.slice(12),
            ];
            setConditionsFull(newConditionsFull);
          }
        })
        .catch(() => {
          setError(true);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else if (
      (!session?.isTrendyolIntegrated ||
        (session?.isTrendyolIntegrated && session?.isTrialEnd)) &&
      selected.title === 'Bugün'
    ) {
      setSummary(DailyDummySummaryDatas);
      setConditions(DailyDummyConditions);
      setConditionsFull(DailyDummyConditionsFull);
    } else if (
      (!session?.isTrendyolIntegrated ||
        (session?.isTrendyolIntegrated && session?.isTrialEnd)) &&
      selected.title === 'Son 30 Gün'
    ) {
      setSummary(DummySummaryDatas);
      setConditions(DummyConditions);
      setConditionsFull(DummyConditionsFull);
    } else if (
      (!session?.isTrendyolIntegrated ||
        (session?.isTrendyolIntegrated && session?.isTrialEnd)) &&
      selected.title === 'Son 7 Gün'
    ) {
      setSummary(WeeklyDummySummaryDatas);
      setConditions(WeeklyDummyConditions);
      setConditionsFull(WeeklyDummyConditionsFull);
    } else if (
      (!session?.isTrendyolIntegrated ||
        (session?.isTrendyolIntegrated && session?.isTrialEnd)) &&
      selected.title === 'Son 90 Gün'
    ) {
      setSummary(QuarterDummySummaryDatas);
      setConditions(QuarterDummyConditions);
      setConditionsFull(QuarterDummyConditionsFull);
    }
  };

  const getSellerDashboardCardGroupsData = () => {
    if (session?.producerId && !session?.isTrialEnd) {
      setAllDayData({ loading: true, data: allDayData.data, error: false });
      trendyolToolsService
        .getSellerDashboardCardGroupsData(session.producerId)
        .then((res) => {
          setAllDayData({ loading: false, data: res.data, error: false });
        })
        .catch((err) => {
          setAllDayData({ loading: false, data: allDayData.data, error: true });
        });
    }
  };

  useEffect(() => {
    getSellerDashboardCardGroupsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // entegration functions
  const handleBannerOnClick = () => setOpenEntegrationModal(true);
  const handleEntegrationOnSubmit = async (values: any) => {
    let { apiSecret, apiKey, sellerId } = values;
    setEntegrationLoading(true);
    memberService
      .registerAsTrendyolSeller({
        platform: 'trendyol',
        apiKey,
        apiSecret,
        sellerId,
      })
      .then((res) => {
        alertNotification('succes', 'Mağazanız Başarıyla Eklendi');
        checkSessionStatus!();
      })
      .catch((err) => {
        alertNotification('error', err.response.data.message);
      })
      .finally(() => {
        setEntegrationLoading(false);
      });
  };

  // changes selected date after date select option & date picker date changes
  const handleOptionSelect = (option: any) => {
    switch (option.title) {
      case 'Tarih Aralığı Seç':
        setShowDatePicker(true);
        setSelected(option);
        setSelectedDate({
          startDate: datePickerStartDate.toISOString(),
          endDate: datePickerEndDate!.toISOString(),
        });
        break;
      case 'Bugün':
        setShowDatePicker(false);
        setSelected(option);
        setSelectedDate({
          startDate: days.today,
          endDate: '',
        });
        break;
      case 'Son 7 Gün':
        setShowDatePicker(false);
        setSelected(option);
        setSelectedDate({
          startDate: days.sevenDaysAgo,
          endDate: days.today,
        });
        break;
      case 'Son 30 Gün':
        setShowDatePicker(false);
        setSelected(option);
        setSelectedDate({
          startDate: days.thirtyDaysAgo,
          endDate: days.today,
        });
        break;
      case 'Son 90 Gün':
        setShowDatePicker(false);
        setSelected(option);
        setSelectedDate({
          startDate: days.ninetyDaysAgo,
          endDate: days.today,
        });
        break;
    }
  };

  useEffect(() => {
    getSellerDashboardDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate, selected]);

  // handles which modal will be shown or not
  useEffect(() => {
    if (!session?.isTrendyolIntegrated) {
      setOpenEntegrationModal(true);
    }
  }, [session?.isTrendyolIntegrated]);

  //sets today 30 days ago 12 months ago or and selectedDate 30 days ago as default
  useEffect(() => {
    const today = new Date();
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(today.getDate() - 7);

    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(today.getDate() - 30);

    const ninetyDaysAgo = new Date();
    ninetyDaysAgo.setDate(today.getDate() - 90);

    setDays({
      today: today.toISOString(),
      sevenDaysAgo: sevenDaysAgo.toISOString(),
      thirtyDaysAgo: thirtyDaysAgo.toISOString(),
      ninetyDaysAgo: ninetyDaysAgo.toISOString(),
    });
    setSelectedDate({
      startDate: thirtyDaysAgo.toISOString(),
      endDate: today.toISOString(),
    });
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabActive(document.visibilityState === 'visible');
    };

    // Add event listener for visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup function to remove the event listener when the component is unmounted
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    let intervalId: any;
    if (session && isTabActive) {
      intervalId = setInterval(async () => {
        await memberService.userTrackReq(
          `trendyol-seller-dashboard`,
          session?.id!
        );
      }, 60000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [session, isTabActive]);

  return (
    <>
      {!session?.isTrendyolIntegrated && (
        <NewBanner
          bgUrl={'/images/trendyol-seller-dashboard-banner.webp'}
          toolWithoutImg={'Mağazamı Entegre Et'}
          bannerOnClick={handleBannerOnClick}
        />
      )}
      <div className="text-right">
        <DatePicker
          selected={selected}
          setSelected={setSelected}
          showDatePicker={showDatePicker}
          setShowDatePicker={setShowDatePicker}
          setSelectedDate={setSelectedDate}
          handleOptionSelect={handleOptionSelect}
          startDate={datePickerStartDate}
          setStartDate={setDatePickerStartDate}
          endDate={datePickerEndDate}
          setEndDate={setDatePickerEndDate}
          dayDiff={datDiff}
        />
      </div>
      {/* TODO: loadinglere bak */}
      <SummaryCards isLoading={isLoading} summary={summary} />
      <TotalTurnover
        isLoading={isLoading}
        conditions={conditions}
        conditionsFull={conditionsFull}
      />
      {
        <EntegrationModal
          loading={entegrationLoading}
          openEntegrationModal={openEntegrationModal}
          setOpenEntegrationModal={setOpenEntegrationModal}
          handleEntegrationOnSubmit={handleEntegrationOnSubmit}
        />
      }
      <SellerDashboardCardGroups allDayData={allDayData} />
    </>
  );
};

export default TrendyolSellerDashboard;
