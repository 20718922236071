import React from 'react';
import { Disclosure } from '@headlessui/react';
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline';
import { Faq } from '../../pages/Faqs';
import { useNumberVerification } from '../../context/NumberVerificationContext';

export interface FaqItemProps {
  faq: Faq;
}
const FaqItem: React.FC<FaqItemProps> = ({ faq }) => {
  const { handleActiveModal } = useNumberVerification();
  return (
    <>
      <Disclosure
        as="div"
        key={faq?.id}
        className="col-span-6 py-3 xl:p-6 xl:col-span-3 border-b-2 border-lightGray"
      >
        {({ open, close }) => (
          <>
            <dt
              onClick={(event) => handleActiveModal?.(event)}
            >
              <Disclosure.Button className="flex w-full items-center justify-between text-left text-gray-900">
                <span className="text-xs font-medium leading-7 lg:text-base">
                  {faq?.question}
                </span>
                <span className="ml-6 flex items-center ">
                  {open ? (
                    <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                  ) : (
                    <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                  )}
                </span>
              </Disclosure.Button>
            </dt>
            <Disclosure.Panel as="dd" className="mt-2 pr-12">
              <p className="text-xs lg:text-base leading-7 text-gray-600">
                {faq?.answer}
              </p>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </>
  );
};

export default FaqItem;
