import { ArrowDown2, ArrowUp2 } from 'iconsax-react';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../../context/LanguageContext';

interface ConsultancyCardProps {
  idx: number;
  card: {
    platform: string;
    conditions: string[];
    imgUrl?: string;
    price?: string;
    conditionsFull: string[];
  };
}
function classNames(...classes: String[]) {
  return classes.filter(Boolean).join(' ');
}
const ConsultancyCard: React.FC<ConsultancyCardProps> = ({ idx, card }) => {
  const { language } = useLanguage();
  const [open, setOpen] = useState(false);
  let isEven: boolean;

  const handleClick = () => {
    setOpen(!open);
  };
  console.log(card);
  if (idx % 2 === 0) {
    isEven = true;
  } else {
    isEven = false;
  }
  return (
    <div
      className={classNames(
        isEven ? 'bg-headerPrimary' : 'bg-white',
        'w-full mb-2 md:mb-0 shadow-md rounded-xl p-0'
      )}
    >
      <div
        className={classNames(
          isEven ? 'bg-white' : 'bg-headerPrimary',
          'md:h-[20rem] h-[16rem] font-thin p-4 xl:p-5 2xl:p-8 rounded-t-xl deneme flex  flex-col items-center'
        )}
      >
        {isEven && (
          <>
            <h1 className="text-headerPrimary text-[1.5rem] text-center hidden">
              Fiyat
            </h1>
            <h1 className="text-[1.5rem] lg:text-[2rem] text-rexRed text-center font-bold">
              REXVEN ELITE
            </h1>
            <h1 className="text-headerPrimary leading-10 mt-4 font-extrabold text-[2.5rem] lg:text-[2.7rem] text-center">
              {card.price}
            </h1>
          </>
        )}

        <h1 className="text-[2rem] lg:text-[2.25rem] text-rexRed text-center font-bold">
          {card.platform}
        </h1>
        {card.platform === 'REXVEN' && (
          <>
            <h1 className="text-[2rem] lg:text-[2.25rem] text-rexRed text-center font-bold">
              EXCLUSIVE
            </h1>
            <p className="text-white text-xs xl:text-sm text-center p-0 mb-2">
              {language === 'tr'
                ? '(Bu paket Amazon Wholesale paketidir. Rexven olarak markaların ürünlerini sizlerin satmasını sağlıyoruz.)'
                : '(This package is the Amazon Wholesale package. As Rexven, we enable sellers to sell branded products.)'}
            </p>
          </>
        )}
        <div className="w-16">
          <img
            className="w-full object-contain"
            src={card.imgUrl}
            alt="rexven"
          />
        </div>
      </div>
      <div
        className={classNames(
          !isEven ? 'bg-white' : 'bg-headerPrimary',
          'font-thin p-4 md:p-2 xl:p-6 2xl:p-12 rounded-b-xl flex flex-col  items-center'
        )}
      >
        <ul className="flex flex-col justify-center overflow-hidden list-disc px-0 min-[1037px]:px-4 pt-2 min-h-44">
          {open
            ? card.conditionsFull.map((item) => {
                return (
                  <li
                    className={classNames(
                      isEven ? 'text-white' : 'text-description',
                      'text-xs sm:text-sm md:text-xs max-[640px]:text-sm xl:text-sm 2xl:text-base leading-6 tracking-normal'
                    )}
                  >
                    {item}
                  </li>
                );
              })
            : card.conditions.map((item) => {
                return (
                  <li
                    className={classNames(
                      isEven ? 'text-white' : 'text-description',
                      'text-xs sm:text-sm md:text-xs max-[640px]:text-sm xl:text-sm 2xl:text-base leading-6 xl:tracking-normal'
                    )}
                  >
                    {item}
                  </li>
                );
              })}
        </ul>
        <button
          onClick={handleClick}
          className={classNames(
            isEven ? 'text-white' : 'text-description',
            'font-medium flex items-center w-full justify-center text-sm 2xl:text-base my-2 mb-4'
          )}
        >
          {language === 'tr' ? 'Avantajları Görüntüle' : 'View Benefits'}{' '}
          {open ? <ArrowUp2 /> : <ArrowDown2 />}
        </button>
        <a
          target="_blank"
          // href="https://form.jotform.com/230192653920958"
          href={
            card.platform === 'ETSY'
              ? 'https://rexven.com/etsy-danismanlik'
              : 'https://rexven.com/amazon-danismanlik'
          }
          rel="noreferrer"
          className={classNames(
            isEven ? 'bg-rexRed' : 'bg-headingPrimary',
            'text-white rounded-lg block w-full text-center font-medium py-2'
          )}
        >
          {language === 'tr' ? 'İletişim Kur' : 'Get Contact'}
        </a>
      </div>
    </div>
  );
};

export default ConsultancyCard;
