import React from 'react';
import Pagination from '../../common/Pagination';
import { classNames } from '../../../utils/conditionalClasses';
import { IAliexpressTopProduct } from '../../../interfaces/aliexpressInterfaces';
import LoadingComponent from '../../common/LoadingComponent';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../../context/LanguageContext';

interface IAliexpressTopProductsQuery {
  orderBy: 'gmv' | 'orders' | string;
}
type Props = {
  queryParams: IAliexpressTopProductsQuery;
  setQueryParams: React.Dispatch<
    React.SetStateAction<IAliexpressTopProductsQuery>
  >;
  totalPageCount: number;
  page: number;
  isLoading: boolean;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  data: IAliexpressTopProduct[];
  inputPage: string | number;
  setInputPage: React.Dispatch<React.SetStateAction<string | number>>;
  queryString: string;
  setQueryString: React.Dispatch<React.SetStateAction<string>>;
};

const AliexpressTopProductsTable = ({
  queryParams,
  setQueryParams,
  setPage,
  page,
  totalPageCount,
  isLoading,
  data,
  inputPage,
  setInputPage,
  queryString,
  setQueryString,
}: Props) => {
  // const navigate = useNavigate();
  const { language } = useLanguage();

  const tableHeader =
    language === 'tr'
      ? [
          { name: 'Sıra', column: 'index' },
          // { name: 'Ürün Resmi', column: 'title' },
          { name: 'Başlık', column: 'title' },
          { name: 'Kategori-1', column: 'categoryFirstLevel' },
          { name: 'Kategori-2', column: 'categorySecondLevel' },
          { name: 'Kategori-3', column: 'categoryThirdLevel' },
          { name: 'Kategori-4', column: 'categoryForthLevel' },
          { name: 'Marka', column: 'brandValue' },
          { name: 'Ciro (Son 30 Gün)', column: 'revenue' },
          { name: 'Görüntülenme', column: 'display' },
          { name: 'Dönüşüm Oranı', column: 'conversion' },
        ]
      : [
          { name: 'No', column: 'index' },
          // { name: 'Ürün Resmi', column: 'title' },
          { name: 'Title', column: 'title' },
          { name: 'Category-1', column: 'categoryFirstLevel' },
          { name: 'Category-2', column: 'categorySecondLevel' },
          { name: 'Category-3', column: 'categoryThirdLevel' },
          { name: 'Category-4', column: 'categoryForthLevel' },
          { name: 'Brand', column: 'brandValue' },
          { name: 'Revenue (Last 30 Days)', column: 'revenue' },
          { name: 'Views', column: 'display' },
          { name: 'Conversion Rate', column: 'conversion' },
        ];
  return (
    <>
      {/* FILTER SECTION */}
      {/* <div className="bg-white rounded-xl mt-12 p-4 flex justify-center items-center">
        <div className="flex justify-center md:mt-4 lg:mt-8 xl:mt-10">
          <button
            className={classNames(
              queryParams.orderBy === 'gmv'
                ? 'bg-headerPrimary text-white'
                : '',
              'border-[1px] border-headerPrimary font-medium px-4 py-3 rounded-tl-lg rounded-bl-lg'
            )}
            onClick={() => {
              setPage(1);
              setInputPage(1);
              //   setQueryString('?page=1');
              setQueryString(`orderBy=gmv`);
              navigate(`?page=1&orderBy=gmv`);
              setQueryParams({ orderBy: 'gmv' });
            }}
          >
            Toplam Ciro
          </button>
          <button
            className={classNames(
              queryParams.orderBy === 'orders'
                ? 'bg-headerPrimary text-white'
                : '',
              'border-[1px] border-headerPrimary font-medium px-4 py-3 rounded-tr-lg rounded-br-lg'
            )}
            onClick={() => {
              setPage(1);
              setInputPage(1);

              setQueryString(`orderBy=orders`);
              navigate(`?page=1&orderBy=orders`);
              setQueryParams({ orderBy: 'orders' });
            }}
          >
            Sipariş Sayısı
          </button>
        </div>
      </div> */}
      {/* / FILTER SECTION */}
      <div className="flex flex-col flex-shrink-0 bg-white rounded-xl p-5 shadow-lg mt-2 sm:p-4 relative">
        <div className="flow-root">
          <div className="relative -mx-4 -my-2 overflow-x-auto overflow-y-auto h-[60vh] scrollbar-hide">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300 table-fixed">
                <thead className="rounded-xl border-none text-white">
                  <tr>
                    {tableHeader.map((item, index) => {
                      return (
                        <th
                          key={index}
                          className="sticky first:rounded-l-2xl last:rounded-r-2xl top-0 z-10 border-b border-gray-300 text-center   bg-headerPrimary py-3.5 pl-4 pr-3 text-sm font-semibold text-white backdrop-blur backdrop-filter "
                        >
                          <p className="text-xs md:text-sm">{item.name}</p>
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                {isLoading ? (
                  <div className="w-full flex justify-center">
                    <LoadingComponent />
                  </div>
                ) : (
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {data?.map((prod: IAliexpressTopProduct, idx) => {
                      return (
                        <tr
                          key={idx}
                          className={classNames(
                            'even:bg-lightGray border-none'
                          )}
                        >
                          <td className="  text-xs md:text-sm sm:pl-0 border-none">
                            <p className="text-center text-gray">
                              {idx + 50 * (page - 1) + 1}
                            </p>
                          </td>

                          <td className="py-4 text-xs whitespace-nowrap  text-center text-gray-500 border-none">
                            <p className="text-center">
                              {prod.title.substring(0, 40)}..
                            </p>
                          </td>

                          <td className=" px-3 py-4 text-center text-xs md:text-sm min-w-52 text-gray-500 border-none">
                            <p className="px-6">{prod.categoryFirstLevel}</p>
                          </td>

                          <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm w-52 text-gray-500 border-none">
                            <p>{prod.categorySecondLevel} </p>
                          </td>

                          <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 border-none">
                            <p>{prod.categoryThirdLevel} </p>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 border-none">
                            <p>{prod.categoryForthLevel} </p>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm min-w-52 text-gray-500 border-none">
                            {prod.brandValue ? (
                              <p className="px-6">{prod.brandValue}</p>
                            ) : (
                              <p className="px-6">-</p>
                            )}
                          </td>

                          <td className="whitespace-nowrap px-6 py-4 text-center text-xs md:text-sm text-gray-500 border-none">
                            <p>${prod.revenue} </p>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 border-none">
                            <p>{prod.display} </p>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 border-none">
                            <p>%{prod.conversion} </p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>

        <Pagination
          setPage={setPage}
          page={page}
          inputPage={inputPage}
          setInputPage={setInputPage}
          totalPageCount={totalPageCount!}
          queryString={queryString}
        />
      </div>
    </>
  );
};

export default AliexpressTopProductsTable;
