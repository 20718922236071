/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';

import IntegrationForm from '../components/aliexpress/IntegrationForm';
import EntegrationForm, { EntegrationFormGet } from '../components/entegration/EntegrationForm';
import EntegrationVideo from '../components/entegration/EntegrationVideo';
// import IntegrationNumVerifyModal from '../components/integrationNumVerifyModals/IntegrationNumVerifyModal';
// import IntegrationSixDigitModal from '../components/integrationNumVerifyModals/IntegrationSixDigitModal';
// import IntegrationVerifySuccessModal from '../components/integrationNumVerifyModals/IntegrationVerifySuccessModal';
// import ShipEntegraIntegrationCard from '../components/entegration/ShipEntegraIntegrationCard';
import NumVerifyModal from '../components/numberVerifyModals/NumVerifyModal';
// import SixDigitModal from '../components/numberVerifyModals/SixDigitModal';
import VerifySuccessModal from '../components/numberVerifyModals/VerifySuccessModal';
import SaleRegister from '../components/productsAndWholeSalers/SaleRegister';
import { useAuth } from '../context/AuthContext';
import { useLanguage } from '../context/LanguageContext';
import { useNumberVerification } from '../context/NumberVerificationContext';
// import authService from '../services/authService';
import memberService from '../services/memberService';
import producerService from '../services/producerService';
import alertNotification from '../utils/alertNotification';

const Entegration = () => {
  const { session } = useAuth();
  const [loading, setLoading] = useState(false);
  const [platform, setPlatform] = useState('trendyol');
  const [isAliexpress, setIsAliExpress] = useState(false);
  const [isTrendyol, setIsTrendyol] = useState(false);
  const [isTabActive, setIsTabActive] = React.useState<boolean>(true);

  const { checkSessionStatus } = useAuth();
  const { t } = useLanguage();

  const [integratedInfo, setIntegratedInfo] = useState<EntegrationFormGet[]>();
  const { handleActiveModal } = useNumberVerification();
  const onSubmit = async (values: any) => {
    let temp = { ...values, discountRate: 50, isAccepted: !values.isAccepted };
    setLoading(true);
    try {
      const res: any = await memberService.registerAsTrendyolSeller(temp);
      alertNotification('success', res.message);
      getIntegratedInfo();
      checkSessionStatus!();
    } catch (error: any) {
      alertNotification('error', error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setPlatform(e.currentTarget.value);
  };

  const getIntegratedInfo = () => {
    //TODO burada trendyolu ayrı aliexpressi ayrı yapmalıyız
    memberService
      .getIntegratedInfo()
      .then((res) => {
        if (res.data.length > 0) {
          // eslint-disable-next-line array-callback-return
          res.data.map((item: { platform: string }) => {
            if (item.platform === 'trendyol') {
              setIsTrendyol(true);
            } else {
              setIsAliExpress(true);
            }
          });
        }
        setIntegratedInfo(res.data);
      })
      .catch((err: any) => alertNotification('error', err.response.data));
  };
  const integrateStore = async (values: any) => {
    setLoading(true);
    try {
      if (isAliexpress) {
        alertNotification('error', 'Bu mağaza tekrar entegre edilemez');
      } else {
        await memberService.integrateAliexpress(values);
        getIntegratedInfo();
      }
    } catch (error: any) {
      alertNotification('error', error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const deleteProducer = (producerId: number, platform: string) => {
    producerService.deleteProducer(producerId, platform).then((res) => {
      checkSessionStatus!();
    });
  };

  useEffect(() => {
    if (session?.isAliExpressIntegrated || session?.isTrendyolIntegrated) {
      getIntegratedInfo();
    }
  }, []);

  useEffect(() => {
    handleActiveModal?.(undefined, true);
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabActive(document.visibilityState === 'visible');
    };

    // Add event listener for visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup function to remove the event listener when the component is unmounted
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    let intervalId: any;
    if (session && isTabActive) {
      intervalId = setInterval(async () => {
        await memberService.userTrackReq(`inregration`, session?.id!);
      }, 60000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [session, isTabActive]);
  return (
    <>
      {/* <SixDigitModal /> */}
      <NumVerifyModal />
      <VerifySuccessModal />
      <div className="flex w-full flex-shrink-0 flex-col">
        <ToastContainer />
        <div className="grid gap-10 rounded-xl mb-5 text-center grid-cols-5">
          <div className="bg-white col-span-5 lg:col-span-3 rounded-xl shadow-lg"
            data-testid="entegration-form"
          >
            <div className="text-left border-b-2 border-lightGray py-3 px-6">
              <h3 className="font-bold text-headingPrimary text-xl">
                {t?.('integration.page.form-title')}
              </h3>
            </div>
            <div className="flex justify-between items-center pl-4 pt-6 pb-2 gap-x-3 sm:gap-x-1.5">
              <label
                htmlFor="platform"
                className="font-medium text-xs w-2/12 lg:w-3/12 xl:text-sm"
              >
                {t?.('integration.page.market-place')}
              </label>
              <div className="flex flex-1 justify-between gap-x-2">
                <select
                  onChange={handleChange}
                  name="platform"
                  id="platform"
                  className="flex w-[50%] text-xs rounded-md bg-lightGray border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-headingPrimary sm:text-sm sm:leading-6"
                >
                  <option value="trendyol">Trendyol</option>
                  <option value="aliexpress">Aliexpress</option>
                </select>
              </div>
            </div>
            {platform === 'trendyol' ? (
              <EntegrationForm
                onSubmit={onSubmit}
                loading={loading}
                integratedInfo={isTrendyol}
              />
            ) : (
              <IntegrationForm
                integratedInfo={isAliexpress}
                integrateStore={integrateStore}
                loading={loading}
              />
            )}
          </div>
          {/* IMAGE FIELD */}
          <div className="hidden lg:block bg-white col-span-5 lg:col-span-2 p-4 rounded-xl shadow-lg"
            data-testid="entegration-image"
          >
            <img
              style={{ height: '350px' }}
              src="/images/entegrationTek.webp"
              alt="entegration"
              className="w-full h-full rounded-2xl object-contain"
              width={'100'}
            />
          </div>
          {/* <div
            className=" bg-white col-span-5 2xl:col-span-2 p-4 rounded-xl shadow-lg flex flex-col items-start justify-between gap-6"
            data-testid="entegration-image"
          >
            <ShipEntegraIntegrationCard logo={"./images/shipEntegraIntegration.svg"} title={'ShipEntegra'} description={'En iyi kullanıcı deneyimi ile yurt dışı kargo süreçlerini yönetebilir, zamandan ve paradan maksimum tasarruf edebilirsiniz!'} />
            <ShipEntegraIntegrationCard logo={"./images/amazonIntegration.svg"} title={'Amazon'} description={'Amazon, 1994 yılında Jeff Bezos tarafından Cadabra adıyla kuruldu ve 1995 yılında Amazon.com olarak çevrimiçi hale geldi.'} />
            <ShipEntegraIntegrationCard logo={"./images/etsyIntegration.svg"} title={'Etsy'} description={'Etsy, kullanıcılara el emeği ürünler sunan bir satış yeridir. Satılan ürünlerin çoğunluğu sanat, el işi gibi alanlardadır.'} />
          </div> */}
        </div>

        {/* Producer Info  */}
        {integratedInfo && integratedInfo.length > 0 && (
          <div className="bg-white  py-3 px-6 rounded-xl mt-10 mb-3 ">
            <h3 className="font-bold text-headingPrimary text-xl">
              Entegre Olan Mağazalarınız
            </h3>

            {/* Producer Infos Container */}
            {integratedInfo.map((store, index) => {
              return (
                <>
                  <div className="flex flex-row justify-between items-center px-2 mt-4">
                    <div className="font-bold text-description flex flex-row justify-start items-center">
                      <h1 className="mr-4">{index + 1}</h1>
                      <img
                        src={
                          store?.platform === 'aliexpress'
                            ? '/assets/logos/aliexpress.png'
                            : store?.producer?.logo!
                        }
                        className="w-10 h-10 hidden sm:block border-2 mr-4 rounded-md"
                        alt="producer_logo"
                      />
                      <p className="text-xs md:text-sm mr-2">
                        {store?.platform === 'aliexpress'
                          ? ''
                          : store?.producer?.producerName}{' '}
                      </p>
                      {store?.platform === 'aliexpress' ? null : <span>|</span>}

                      <span className="ml-2 text-xs md:text-sm">
                        {store.platform === 'trendyol'
                          ? 'Trendyol'
                          : 'AliExpress'}
                      </span>

                      {isTrendyol && store?.producer?.status === 'active' ? (
                        <p className="ml-4 text-sm font-bold">
                          Durum:
                          <span className="ml-2 text-rexRed"> Aktif</span>
                        </p>
                      ) : store.platform === 'trendyol' ? (
                        <div className="flex items-center ml-4">
                          <SaleRegister />
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                    <div>
                      <button
                        className="bg-rexRed border-2 ml-2 border-rexRed px-4 py-[4px] text-xs sm:text-sm lg:px-8 lg:py-[5px] rounded-md text-white font-medium cursor-pointer hover:bg-lightGray hover:text-rexRed "
                        onClick={() => {
                          deleteProducer(store?.producerId!, store?.platform!);
                        }}
                      >
                        Sil
                      </button>
                    </div>
                  </div>
                </>
              );
            })}

            {/* Delete Btn Container */}
          </div>
        )}
        {/* /Producer Info */}

        <div className="mt-10">
          <EntegrationVideo />
        </div>
      </div>
    </>
  );
};

export default Entegration;
