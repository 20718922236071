import React, { useEffect, useState } from 'react'
import TotalTurnoverCard from './TotalTurnoverCard'
import ProfitMarginCard from './ProfitMarginCard';
import ExpenseCard from './ExpenseCard';
import ProfitOrLossCard from './ProfitOrLossCard';
import { DaysDataType } from '../../DummyDatasAndTypes';

interface SellerDashboardCardGroupsProps {
    allDayData: DaysDataType
}


export interface CardTitle {
    title: string;
    date: string;
    key?: string
}

const SellerDashboardCardGroups: React.FC<SellerDashboardCardGroupsProps> = ({
    allDayData
}) => {

    const [cardTitles, setCardTitles] = useState<CardTitle[]>([]);

    useEffect(() => {
        const today = new Date();
        const sevenDaysAgo = new Date();
        sevenDaysAgo.setDate(today.getDate() - 7);

        const thirtyDaysAgo = new Date();
        thirtyDaysAgo.setDate(today.getDate() - 30);

        const ninetyDaysAgo = new Date();
        ninetyDaysAgo.setDate(today.getDate() - 90);

        setCardTitles([
            { title: "Bugün", date: today.toLocaleDateString() },
            { title: "Son 7 Gün", date: `${sevenDaysAgo.toLocaleDateString()} - ${today.toLocaleDateString()}` },
            { title: "Son 30 Gün", date: `${thirtyDaysAgo.toLocaleDateString()} - ${today.toLocaleDateString()}` },
            { title: "Son 90 Gün", date: `${ninetyDaysAgo.toLocaleDateString()} - ${today.toLocaleDateString()}`, },
        ]);
    }, []);


    return (
        <div className='shadow-md mt-10 py-5 px-2'>
            <p className='mb-2 font-black text-lg'> Toplam Ciro</p>
            <TotalTurnoverCard
                allDayData={allDayData}
                cardTitles={cardTitles}
                colors={{ color: '#939BD2', textColor: '#3C4790', valueColor: '#1F254B' }}
            />
            <p className='mt-6 mb-2 font-black text-lg'> Kar Marjı </p>
            <ProfitMarginCard
                allDayData={allDayData}
                cardTitles={cardTitles} />
            <p className='mt-6 mb-2 font-black text-lg'> Kar / Zarar </p>
            <ProfitOrLossCard
                allDayData={allDayData}
                cardTitles={cardTitles}
                label={['Kar', "Zarar"]}
            />
            <p className='mt-6 mb-2 font-black text-lg'> Giderler </p>
            <ExpenseCard
                allDayData={allDayData}
                cardTitles={cardTitles} />
        </div >
    )
}

export default SellerDashboardCardGroups