import React, { useEffect, useState } from 'react';

import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';

import { CourseData } from '../../pages/CourseDetail';
import ContentLine from './ContentLine';

interface Props {
  course: CourseData;
  selectedContent: {
    sectionLocation: any;
    path: any;
    id: any;
    contentName: any;
    notes: string
  };
  endedSectionContentCount:
  | { sectionId: number; contentId: number }[]
  | undefined;
  handleSelectedContent: Function;
  onChange: (e: any, courseId: number, contentId: number) => {};
  display: string;
}

const CourseSectionsSideBar: React.FC<Props> = ({
  course,
  selectedContent,
  endedSectionContentCount,
  handleSelectedContent,
  onChange,
  display,
}) => {
  return (
    <div
      className={`${display} xl:col-span-4 2xl:col-span-3  w-full  bg-white rounded-xl shadow-lg  overflow-auto scrollbar-hide h-screen`}
    >
      <div className="text-rexRed sticky top-0 z-30 uppercase text-center p-4 bg-lightGray rounded-t-xl font-medium">
        {course.courseName}
      </div>
      {course.sections.map((section, index) => {
        return (
          <dl key={section.id} className="relative">
            <Disclosure
              defaultOpen={
                section.contents.find(
                  (content) => content.id === selectedContent.id
                )?.id === selectedContent.id
              }
              as="div"
              key={section.id}
              className="pt-3"
            >
              {({ open }) => (
                <>
                  <dt className="flex relative w-full cursor-pointer  bg-white py-1.5 pl-3 pr-3 text-left text-gray-900 sm:text-sm sm:leading-6 border-b border-ligth-gary">
                    <Disclosure.Button className="flex-col w-full items-between text-left text-gray-900">
                      <div className="flex items-between w-full ">
                        <span className="truncate uppercase font-medium text-headingPrimary">
                          {index + 1}. {section.sectionName}
                        </span>
                        <span className="pointer-events-none  inset-y-0 right-0 ml-auto flex">
                          {' '}
                          {open ? (
                            <ChevronUpIcon
                              className="h-6 w-6 text-gray-900"
                              aria-hidden="true"
                            />
                          ) : (
                            <ChevronDownIcon
                              className="h-6 w-6 text-gray-900"
                              aria-hidden="true"
                            />
                          )}
                        </span>
                      </div>
                      <div className="flex items-center mt-1">
                        <span className="text-sm font-medium text-gray-900">{`${endedSectionContentCount?.filter(
                          (item) => item.sectionId === section.id
                        ).length
                          }/${section?.contents?.length}`}</span>
                      </div>
                    </Disclosure.Button>
                  </dt>
                  {section.contents.map((content) => {
                    return (
                      <Disclosure.Panel key={content.id} as="dd">
                        <ContentLine
                          course={course}
                          selectedContent={selectedContent}
                          handleSelectedContent={handleSelectedContent}
                          onChange={onChange}
                          content={content}
                          section={section}
                        />
                      </Disclosure.Panel>
                    );
                  })}
                </>
              )}
            </Disclosure>
          </dl>
        );
      })}
    </div>
  );
};

export default CourseSectionsSideBar;
