import React from 'react';
import StoreStatisticsCard from './StoreStatisticsCard';

interface Props {
  infoCards: any;
  isLoading: boolean;
}
const StoreStatisticsWrapper: React.FC<Props> = ({ infoCards, isLoading }) => {
  return (
    <div className="grid gap-4 grid-cols-10 justify-between items-center my-10 ">
      {infoCards.map(
        (
          info: {
            value: number;
            title: string;
            titleColor: string;
            valueColor: string;
            icon: any;
          },
          index: number
        ) => (
          <StoreStatisticsCard
            key={index}
            title={info.title}
            value={info.value}
            titleColor={info.titleColor}
            valueColor={info.valueColor}
            icon={info.icon}
            isLoading={isLoading}
          />
        )
      )}
    </div>
  );
};

export default StoreStatisticsWrapper;
