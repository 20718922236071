import { Dialog, Transition } from '@headlessui/react';
import React, { useState } from 'react';
import marketplaceService from '../../services/marketplaceService';
import alertNotification from '../../utils/alertNotification';

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  orderItems: any[];
}
const ResendModal: React.FC<Props> = ({
  isModalOpen,
  setIsModalOpen,
  orderItems,
}) => {
  const [isPayment, setIsPayment] = useState(false);
  const [selectedItems, setSelectedItems] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [cost, setCost] = useState(null);
  const handleCheckboxChange = (id: number) => {
    setSelectedItems(
      (prevSelected: any) =>
        prevSelected.includes(id)
          ? prevSelected.filter((itemId: number) => itemId !== id) // Eğer id seçiliyse çıkar
          : [...prevSelected, Number(id)] // Eğer id seçili değilse ekle
    );
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const res = await marketplaceService.resendOrderItems({
        orderIds: selectedItems,
      });
      if (res.data.shipmentCost) {
        setCost(res.data.shipmentCost);
        setSelectedItems(res.data.orderItemIds);
        setIsPayment(true);
      }
    } catch (error: any) {
      alertNotification('error', error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handlePayment = async () => {
    setLoading(true);
    const orderIds = selectedItems;
    try {
      await marketplaceService.buySupplierProduct(orderIds);
    } catch (error: any) {
      alertNotification('error', error.response.data.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Transition show={isModalOpen} as={React.Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setIsModalOpen(false)}
      >
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium text-center py-4 leading-6 text-gray-900"
                >
                  Siparişi tekrar gönderme talebi
                </Dialog.Title>
                <div className="mt-2">
                  <p className="text-xs text-gray-500">
                    {isPayment
                      ? 'Ürünlerinize ait kargo ücreti tarafınızdan karşılanacaktır.'
                      : ' Siparişinizde hata olması durumunda iligli ürünü tekrar göndermek için talep oluşturabilirsiniz. Aşağıdaki ürünlerden tekrar göndermek istediklerinizi işaretleyerek devam edebilirsiniz.'}
                  </p>
                  {!isPayment ? (
                    <>
                      <div className="flex flex-col">
                        {orderItems?.map((item: any, index) => (
                          <div
                            key={index}
                            className="flex items-center justify-start space-x-4 my-2"
                          >
                            <div className="flex items-center">
                              <input
                                type="checkbox"
                                checked={selectedItems?.includes(
                                  Number(item.id)
                                )}
                                onChange={() =>
                                  handleCheckboxChange(Number(item.id))
                                }
                                className="mr-2"
                              />
                              <img
                                src={item?.seller_product.images[0]}
                                alt=""
                                className="w-12 h-12 object-cover"
                              />
                              <div className="ml-2">
                                <h3 className="text-sm font-semibold">
                                  {Object.keys(
                                    item?.seller_product?.attributes[0]
                                  ).map((key) => {
                                    return (
                                      <p>
                                        {key +
                                          ':' +
                                          item?.seller_product?.attributes[0][
                                            key
                                          ]}
                                      </p>
                                    );
                                  })}
                                </h3>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="w-full flex justify-end space-x-2">
                        <button
                          onClick={() => setIsModalOpen(false)}
                          className="bg-blue-500 text-white rounded-lg px-2 py-2 text-sm w-32"
                        >
                          Vazgeç
                        </button>
                        <button
                          disabled={selectedItems.length === 0}
                          className="bg-headingPrimary text-white rounded-lg text-sm px-2 py-2 w-32"
                          onClick={handleSubmit}
                        >
                          Talep oluştur
                        </button>
                      </div>
                    </>
                  ) : (
                    <div>
                      <p className="text-sm text-headingPrimary">
                        Kargo ücreti:{' '}
                        <span className="font-medium">
                          {Number(cost).toFixed(2)}
                        </span>
                      </p>
                      <div className="w-full flex justify-end space-x-2">
                        <button
                          onClick={() => setIsModalOpen(false)}
                          className="bg-blue-500 text-white rounded-lg px-2 py-2 text-sm w-32"
                        >
                          Vazgeç
                        </button>
                        <button
                          disabled={loading}
                          className="bg-headingPrimary text-white rounded-lg text-sm px-2 py-2 w-32"
                          onClick={handlePayment}
                        >
                          {loading ? 'Ödeme Alınıyor...' : 'Ödeme yap'}
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ResendModal;
