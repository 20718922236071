import { CloseCircle } from 'iconsax-react'; // Import CloseCircle from iconsax-react or appropriate library
import React, { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import Pagination from '../components/common/Pagination';
import ProductNotFound from '../components/common/ProductNotFound';
import CategoryCard from '../components/marketplace/CategoryCard';
import LoadingSkeleton from '../components/marketplace/LoadingSkeleton';
import MarketplaceProductsCart from '../components/marketplace/ProductsCardMarketplace';
import TopCategoryLine from '../components/marketplace/TopCategoryLine';
import { useLanguage } from '../context/LanguageContext';
import { ProductData } from '../interfaces/marketplaceInterfaces';
import marketplaceService from '../services/marketplaceService';
import alertNotification from '../utils/alertNotification';
import { classNames } from '../utils/conditionalClasses';

const Marketplace = () => {
    const { t } = useLanguage();
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [page, setPage] = useState(Number(searchParams.get('page')) || 1);
    const [totalPage, setTotalPage] = useState<number>();
    const [inputPage, setInputPage] = useState<number | string>(Number(searchParams.get('page')) || 1);
    const [products, setProducts] = useState<ProductData[]>([]);
    const [loading, setLoading] = useState(false);
    const [keyword, setKeyword] = useState('');
    const [sku, setSku] = useState('');
    const [open, setOpen] = React.useState(false);
    const [queryString, setQueryString] = useState(location.search);
    const [productId, setProductId] = React.useState<number | null>(null);
    const [categoryId, setCategoryId] = useState<number | null>();
    const [categoryName, setCategoryName] = useState<string>();
    const inputRef = useRef<HTMLInputElement | null>(null);
    const skuInputRef = useRef<HTMLInputElement | null>(null);
    const [isInitialLoad, setIsInitialLoad] = useState(true);

    const getMarketplaceProducts = () => {
        setLoading(true);
        marketplaceService.getMarketplaceProducts(page, keyword, categoryId ? categoryId : null, sku)
            .then(res => {
                setTotalPage(res.data.totalPageCount);
                setProducts(res.data.data);
            })
            .catch(error => {
                alertNotification('error', error.response.data.message);
            })
            .finally(() => {
                setLoading(false);
            })
    };

    useEffect(() => {
        if (isInitialLoad) {
            setIsInitialLoad(false);
            return;
        }
        getMarketplaceProducts();
    }, [keyword, categoryId, page, categoryName, sku]);

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setCategoryId(null)
        const newKeyword = inputRef.current?.value || '';
        const newSku = skuInputRef.current?.value || '';
        setKeyword(newKeyword);
        setSku(newSku)
        setPage(1);
        setInputPage(1);
        setQueryString(`keyword=${newKeyword}&sku=${newSku}`);
        navigate(`?page=1&keyword=${newKeyword}&sku=${newSku}`);
    };

    const handleCategoryId = (id: number, name: string) => {
        setKeyword('')
        setSku('')
        if (inputRef.current) {
            inputRef.current.value = '';
        }
        if (skuInputRef.current) {
            skuInputRef.current.value = '';
        }
        setCategoryId(id);
        setCategoryName(name);
        setPage(1);
        setInputPage(1);
        setProducts([]);
    }


    let content;
    if (isInitialLoad || (!keyword && !categoryId && !products.length && !loading)) {
        content = <CategoryCard handleCategoryId={handleCategoryId} />;
    } else if (!products.length && !loading) {
        content = <>
            <TopCategoryLine categoryName={categoryName} handleCategoryId={handleCategoryId} />
            <ProductNotFound />
        </>
    } else if (loading) {
        content = <LoadingSkeleton />;
    } else if (products?.length && !loading) {
        content = <>
            <TopCategoryLine categoryName={categoryName} handleCategoryId={handleCategoryId} />
            <div className="grid grid-cols-12 2xl:grid-cols-10 justify-center bg-white items-center gap-6 md:gap-4 col-span-12 md:col-span-10 border-t-4 border-[#F9FAFB] pt-4 ">
                {products.map((product) => (
                    <div className="col-span-12 bg-[#f9fafb] rounded-[24px] sm:col-span-6 lg:col-span-4 xl:col-span-3 2xl:col-span-2 items-center justify-center p-[30px]" key={uuidv4()}>
                        <MarketplaceProductsCart
                            setOpen={setOpen}
                            setProductId={setProductId}
                            product={product}
                        />
                    </div>
                ))}
            </div>
            <Pagination
                page={page}
                setPage={setPage}
                totalPageCount={totalPage ? totalPage : 1}
                setInputPage={setInputPage}
                inputPage={inputPage}
                queryString={queryString}
            />
        </>
    }

    return (
        <div className="flex w-full flex-shrink-0 flex-col">
            <div className="bg-white rounded-2xl p-2 md:p-6">
                <form className="" onSubmit={handleSubmit}>
                    <div className="grid grid-cols-12 gap-x-4 gap-y-2 justify-center items-center mx-auto">
                        <div className="col-span-12 md:col-span-5">
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                    <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                                    </svg>
                                </div>
                                <input ref={skuInputRef} id="default-search" className="block w-full p-[0.58rem] pl-10 text-xs md:text-sm text-gray-900 border border-headerPrimary rounded-lg bg-gray-50" placeholder={'SKU ile ara...'} />
                                {/* {sku && (
                                    <div className="absolute inset-y-0 right-20 flex items-center">
                                        <CloseCircle className='w-4 h-4 cursor-pointer' onClick={clearSearch} />
                                    </div>
                                )} */}
                            </div>
                        </div>
                        <div className="col-span-12 md:col-span-5">
                            <label htmlFor="default-search" className="mb-2 text-lg lg:text-sm font-medium text-gray-900 sr-only dark:text-white">
                                Search
                            </label>
                            <div className="relative">
                                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                    <svg aria-hidden="true" className="w-5 h-5 text-gray-500 dark:text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
                                    </svg>
                                </div>
                                <input ref={inputRef} id="default-search" className="block w-full p-[0.58rem] pl-10 text-xs md:text-sm text-gray-900 border border-headerPrimary rounded-lg bg-gray-50" placeholder={'Ürün Başlığı ile Ara...'} />
                                {/* {keyword && (
                                    <div className="absolute inset-y-0 right-20 flex items-center">
                                        <CloseCircle className='w-4 h-4 cursor-pointer' onClick={clearSearch} />
                                    </div>
                                )} */}

                            </div>
                        </div>
                        <button
                            type="submit"
                            className="col-span-12 md:col-span-2 text-white bg-headerPrimary rounded-md font-medium text-sm text-center py-2 md:py-2.5 w-full">
                            {/* {t?.('common.buttons.search')} */}
                            Filtrele
                        </button>
                    </div>
                </form>
                {content}
            </div>
        </div>
    );
};

export default Marketplace;
