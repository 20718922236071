import { Google } from 'iconsax-react';
import React, { useState } from 'react';
import { getGoogleUrl } from '../../utils/getGoogleUrl';
import { Link, useLocation } from 'react-router-dom';
import { RegisterDataInterface } from '../../context/AuthContext';
import memberService from '../../services/memberService';
import { Formik } from 'formik';
import * as Yup from 'yup';
import alertNotification from '../../utils/alertNotification';
import { useLanguage } from '../../context/LanguageContext';
import { errorElement } from '../../utils/errorElement';
import { ComponentType } from '../../pages/Payment';
import MailConfirmModal from './MailConfirmModal';
import { ToastContainer } from 'react-toastify';
import LoadingComponent from '../common/LoadingComponent';

interface Props {
  setCurrentComponent: React.Dispatch<React.SetStateAction<ComponentType>>;
  currentComponent: ComponentType;
}

type FormValues = Omit<RegisterDataInterface, 'from'>;
const PaymentRegister: React.FC<Props> = ({
  setCurrentComponent,
  currentComponent,
}) => {
  const location = useLocation();
  const { t } = useLanguage();
  const [isKvkk, setIsKvkk] = useState(false);
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState('');
  const [loading, setLoading] = useState(false);
  const [disable, setDisable] = useState(false);
  const handleSubmit = async (values: any) => {
    setLoading(true);
    setDisable(true);
    try {
      const res = await memberService.registerPublic(values);
      setUserId(res.data);

      setOpen(true);
    } catch (error: any) {
      alertNotification('error', error.response.data.message);
      setDisable(false);
    } finally {
      setLoading(false);
    }
  };

  let from = location.pathname;
  console.log('from------->', from);
  return (
    <div className="p-4 sm:min-h-[73vh] xl:px-[100px]">
      <ToastContainer />
      <MailConfirmModal
        open={open}
        setOpen={setOpen}
        setCurrentComponent={setCurrentComponent}
        userId={userId}
        currentComponent={currentComponent}
      />
      {/* <p className="font-bold text-base sm:text-xl text-primary mb-2">
        {t?.('payment.register.title')}
      </p> */}

      <a
        href={getGoogleUrl(from, from)}
        className="flex justify-center items-center w-full mb-4 py-2 px-4 text-sm font-medium border border-transparent text-white shadow-sm bg-googleRed rounded-md mt-2 hover:shadow-lg"
      >
        <Google size="20" className="text-white mr-2" variant="Bold" />{' '}
        {t?.('payment.register.google')}
      </a>
      <Formik
        initialValues={{
          firstName: '',
          lastName: '',
          email: '',
          password: '',
          passwordConfirm: '',
          isKvkkAccepted: '',
        }}
        onSubmit={(values: FormValues, actions) => {
          handleSubmit(values);
          actions.setSubmitting(false);
          actions.resetForm();
        }}
        validateOnChange={false}
        validationSchema={Yup.object({
          firstName: Yup.string().required(t?.('validation.required')),
          lastName: Yup.string().required(t?.('validation.required')),
          email: Yup.string()
            .email('lütfen geçerli bir email adresi giriniz')
            .required('Bu alan boş bırakılamaz'),
          password: Yup.string().required(t?.('validation.required')),
          passwordConfirm: Yup.string()
            .oneOf([Yup.ref('password')], 'Şifreler eşleşmiyor')
            .required(t?.('validation.required')),
          // isKvkkAccepted: Yup.boolean().required(t?.('validation.contract')),
          isKvkkAccepted: Yup.boolean()
            .test(
              'is-accepted',
              t?.('validation.contract'),
              (value) => value === true
            )
            .required(t?.('validation.contract')),
        })}
      >
        {({ handleSubmit, handleChange, setFieldValue, values, errors }) => (
          <form className="flex flex-col space-y-3" onSubmit={handleSubmit}>
            <label
              className="font-medium text-sm sm:text-base"
              htmlFor="firstName"
            >
              İsim{' '}
            </label>
            <input
              type="text"
              name="firstName"
              id="firstName"
              value={values.firstName}
              onChange={handleChange}
              className="border-cardBorder border-2 rounded-md py-1 px-2"
            />
            {errors.firstName && errorElement(errors.firstName)}
            <label
              className="font-medium text-sm sm:text-base"
              htmlFor="lastName"
            >
              Soyisim{' '}
            </label>
            <input
              type="text"
              name="lastName"
              id="lastName"
              value={values.lastName}
              onChange={handleChange}
              className="border-cardBorder border-2 rounded-md py-1 px-2"
            />
            {errors.lastName && errorElement(errors.lastName)}
            <label className="font-medium text-sm sm:text-base" htmlFor="email">
              Email{' '}
            </label>
            <input
              type="text"
              name="email"
              id="email"
              value={values.email}
              onChange={handleChange}
              className="border-cardBorder border-2 rounded-md py-1 px-2"
            />
            {errors.email && errorElement(errors.email)}
            <label
              className="font-medium text-sm sm:text-base"
              htmlFor="password"
            >
              Şifre{' '}
            </label>
            <input
              type="password"
              name="password"
              id="password"
              value={values.password}
              onChange={handleChange}
              className="border-cardBorder border-2 rounded-md py-1 px-2"
            />
            <label
              className="font-medium text-sm sm:text-base"
              htmlFor="passwordConfirm"
            >
              Şifre Tekrar{' '}
            </label>
            <input
              type="password"
              name="passwordConfirm"
              id="passwordConfirm"
              value={values.passwordConfirm}
              onChange={handleChange}
              className="border-cardBorder py-1 px-2 border-2 rounded-md"
            />
            {errors.passwordConfirm && errorElement(errors.passwordConfirm)}
            <p className="text-sm">
              Hesabınız var mı?{' '}
              <Link
                to="/login"
                state={{ from: location }}
                className="text-blue-600"
              >
                Giriş Yapın
              </Link>
            </p>
            <div className="flex items-center">
              <input
                type="checkbox"
                name="isKvkkAccepted"
                id="isKvkkAccepted"
                value={values.isKvkkAccepted}
                onChange={(e) => {
                  handleChange(e);
                  setIsKvkk(!isKvkk);
                }}
                className="rounded-sm"
              />
              <label
                htmlFor="isKvkkAccepted"
                className="ml-2 block text-sm text-gray-900"
              >
                <a
                  href="/aydinlatma-metni"
                  target="_blank"
                  className="text-blue-600"
                >
                  KVKK
                </a>{' '}
                ve{' '}
                <a
                  href="/gizlilik-politikasi"
                  target="_blank"
                  className="text-blue-600"
                >
                  Gizlilik Politikasını
                </a>{' '}
                okudum ve kabul ediyorum.
              </label>
            </div>
            {errors.isKvkkAccepted && errorElement(errors.isKvkkAccepted)}
            <div className="flex justify-end">
              <button
                disabled={disable}
                className="bg-headerPrimary p-2 rounded-lg text-white text-sm font-medium min-w-[100px] flex justify-center"
              >
                {loading ? (
                  <div>
                    <LoadingComponent />
                  </div>
                ) : (
                  'Kayıt Ol ve Devam Et'
                )}
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default PaymentRegister;
