import React, { useState, useEffect } from 'react';
import { seperateNumberWithComma } from '../../../../utils/numberFormatter';
import { DaysDataType, FinancialData } from '../../DummyDatasAndTypes';
import CustomCardChart from './CustomCardChart';
import { CardTitle } from './SellerDashboardCardGroups';

interface ProfitOrLossCardProps {
    cardTitles: CardTitle[];
    label: string[];
    allDayData: DaysDataType;
}

const ProfitOrLossCard: React.FC<ProfitOrLossCardProps> = ({
    cardTitles,
    label,
    allDayData,
}) => {
    const [cardData, setCardData] = useState({} as { [title: string]: FinancialData });

    const getDataForCard = (
        title: string,
        todayData: FinancialData,
        sevenDayData: FinancialData,
        thirtyDayData: FinancialData,
        ninetyDayData: FinancialData
    ): FinancialData => {
        switch (title) {
            case 'Bugün':
                return todayData;
            case 'Son 7 Gün':
                return sevenDayData;
            case 'Son 30 Gün':
                return thirtyDayData;
            case 'Son 90 Gün':
                return ninetyDayData;
            default:
                return {
                    totalRevenue: {
                        revenue: 0,
                        profitOrLoss: 0,
                        orderCount: 0,
                        costs: 0,
                        profitMargin: 0,
                        returnCount: 0,
                        saleCount: 0
                    },
                    totalCost: 0,
                    profitOrLoss: 0,
                    profitMargin: 0
                };
        }
    };




    useEffect(() => {
        let newCardData: { [title: string]: FinancialData } = {};
        cardTitles.forEach((card) => {
            const data = getDataForCard(
                card.title,
                allDayData.data.day,
                allDayData.data.week,
                allDayData.data.month,
                allDayData.data.quarter
            );
            newCardData[card.title] = data;
        });
        setCardData(newCardData);
    }, [allDayData, cardTitles]);

    return (
        <div className='grid grid-cols-12 gap-4 my-8'>
            {cardTitles.map((card) => {
                let data = cardData[card.title];
                let profitOrLoss = data?.profitOrLoss ?? 0;
                let revenue = data?.totalRevenue?.revenue ?? 0;
                return (
                    <div
                        className='col-span-12 lg:col-span-6 xl:col-span-3 bg-lightGray border rounded-md shadow-md p-2 w-full lg:w-auto min-h-[450px] flex flex-col justify-between items-center'
                        key={card.title}
                    >
                        <div className='flex flex-col justify-start w-full'>
                            <p className='font-semibold text-sm'>{card.title}</p>
                            <p className='text-xs font-thin text-cardBorder mb-2'>{card.date}</p>
                        </div>
                        <div className='flex justify-center items-center'>
                            <CustomCardChart
                                value={profitOrLoss}
                                label={profitOrLoss >= 0 ? label[0] : label[1]}
                                color={profitOrLoss >= 0 ? '#939BD2' : '#E84C4A'}
                                textColor={profitOrLoss >= 0 ? '#3C4790' : '#B51917'}
                                valueColor={profitOrLoss >= 0 ? '#1F254B' : '#E3211E'}
                                fontWeight={'font-semibold'}
                            />
                        </div>
                        {profitOrLoss !== undefined && profitOrLoss !== null && (

                            <div className='py-4 px-4 -mt-2 min-h-[110px] text-center'>
                                <p>{`${card.title} yapılan satışlardan `}
                                    <span className='font-semibold'>
                                        {seperateNumberWithComma(revenue)} ₺ {""}
                                    </span>
                                    ciro ve {""}
                                    <span className='font-semibold'>{seperateNumberWithComma(profitOrLoss)} ₺</span>
                                    {""} {profitOrLoss! >= 0 ? "kar" : "zarar"} elde edilmiştir.

                                </p>
                            </div>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

export default ProfitOrLossCard;
