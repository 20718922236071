import React, { Fragment, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useLanguage } from '../../context/LanguageContext';
import { classNames } from '../../utils/conditionalClasses';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { Listbox, Transition } from '@headlessui/react';
import alertNotification from '../../utils/alertNotification';
import shipentegraService from '../../services/shipentegraService';
import { ToastContainer } from 'react-toastify';

const COUNTRIES = [
  { code: 'US', name: 'Amerika Birleşik Devletleri' },
  { code: 'CA', name: 'Kanada' },
  { code: 'AU', name: 'Avustralya' },
  { code: 'DE', name: 'Almanya' },
  { code: 'FR', name: 'Fransa' },
  { code: 'IT', name: 'İtalya' },
  { code: 'ES', name: 'İspanya' },
  { code: 'NL', name: 'Hollanda' },
  { code: 'AF', name: 'Afganistan' },
  { code: 'AD', name: 'Andora' },
  { code: 'AI', name: 'Angila' },
  { code: 'AO', name: 'Angola' },
  { code: 'AG', name: 'Antigua ve Barbuda' },
  { code: 'AR', name: 'Arjantin' },
  { code: 'AL', name: 'Arnavutluk' },
  { code: 'AW', name: 'Aruba' },
  { code: 'AT', name: 'Avusturya' },
  { code: 'AZ', name: 'Azerbaycan' },
  { code: 'BS', name: 'Bahamalar' },
  { code: 'BH', name: 'Bahreyn' },
  { code: 'BD', name: 'Bangladeş' },
  { code: 'BB', name: 'Barbados' },
  { code: 'BE', name: 'Belçika' },
  { code: 'BZ', name: 'Belize' },
  { code: 'BJ', name: 'Benin' },
  { code: 'BM', name: 'Bermuda' },
  { code: 'BT', name: 'Bhutan' },
  { code: 'AE', name: 'Birleşik Arap Emirlikleri' },
  { code: 'BO', name: 'Bolivya' },
  { code: 'AN', name: 'Bonaire' },
  { code: 'BA', name: 'Bosna-Hersek' },
  { code: 'BW', name: 'Botswana' },
  { code: 'BR', name: 'Brezilya' },
  { code: 'BN', name: 'Brunei' },
  { code: 'BG', name: 'Bulgaristan' },
  { code: 'BF', name: 'Burkina Faso' },
  { code: 'BI', name: 'Burundi' },
  { code: 'CV', name: 'Cape Verde Adaları' },
  { code: 'KY', name: 'Cayman Adaları' },
  { code: 'GI', name: 'Cebelitarık' },
  { code: 'DZ', name: 'Cezayir' },
  { code: 'DJ', name: 'Cibuti' },
  { code: 'CK', name: 'Cook Adaları' },
  { code: 'TD', name: 'Çad' },
  { code: 'CZ', name: 'Çek Cumhuriyeti' },
  { code: 'CN', name: 'Çin Halk Cumhuriyeti' },
  { code: 'DK', name: 'Danimarka' },
  { code: 'TL', name: 'Doğu Timor' },
  { code: 'DO', name: 'Dominik Cumhuriyeti' },
  { code: 'DM', name: 'Dominika' },
  { code: 'EC', name: 'Ekvator' },
  { code: 'GQ', name: 'Ekvator Ginesi' },
  { code: 'SV', name: 'El Salvador' },
  { code: 'ID', name: 'Endonezya' },
  { code: 'ER', name: 'Eritre' },
  { code: 'AM', name: 'Ermenistan' },
  { code: 'EE', name: 'Estonya' },
  { code: 'ET', name: 'Etiyopya' },
  { code: 'FO', name: 'Faroe Adaları' },
  { code: 'MA', name: 'Fas' },
  { code: 'FJ', name: 'Fiji Adası' },
  { code: 'PH', name: 'Filipinler' },
  { code: 'FI', name: 'Finlandiya' },
  { code: 'GF', name: 'Fransız Guyanası' },
  { code: 'PF', name: 'Fransız Polinezyası' },
  { code: 'GA', name: 'Gabon' },
  { code: 'GM', name: 'Gambiya' },
  { code: 'GH', name: 'Gana' },
  { code: 'PS', name: 'Gaza (Batı Şeria)' },
  { code: 'GN', name: 'Gine' },
  { code: 'GW', name: 'Gine-Bissau' },
  { code: 'GD', name: 'Grenada' },
  { code: 'GL', name: 'Grönland' },
  { code: 'GP', name: 'Guadelup' },
  { code: 'GU', name: 'Guam' },
  { code: 'GT', name: 'Guatemala' },
  { code: 'GY', name: 'Guyana' },
  { code: 'ZA', name: 'Güney Afrika' },
  { code: 'GE', name: 'Gürcistan' },
  { code: 'HT', name: 'Haiti' },
  { code: 'HR', name: 'Hırvatistan' },
  { code: 'IN', name: 'Hindistan' },
  { code: 'HN', name: 'Honduras' },
  { code: 'HK', name: 'Hong Kong' },
  { code: 'IQ', name: 'Irak' },
  { code: 'VG', name: 'İngiliz Virgin Adaları' },
  { code: 'GB', name: 'İngiltere' },
  { code: 'IE', name: 'İrlanda Cumhuriyeti' },
  { code: 'IL', name: 'İsrail' },
  { code: 'SE', name: 'İsveç' },
  { code: 'CH', name: 'İsviçre' },
  { code: 'IS', name: 'İzlanda' },
  { code: 'JM', name: 'Jamaika' },
  { code: 'JP', name: 'Japonya' },
  { code: 'KH', name: 'Kamboçya' },
  { code: 'CM', name: 'Kamerun' },
  { code: 'ME', name: 'Karadağ' },
  { code: 'QA', name: 'Katar' },
  { code: 'KZ', name: 'Kazakistan' },
  { code: 'KE', name: 'Kenya' },
  { code: 'CY', name: 'Kıbrıs' },
  { code: 'KG', name: 'Kırgızistan' },
  { code: 'KI', name: 'Kiribati' },
  { code: 'CO', name: 'Kolombiya' },
  { code: 'CG', name: 'Kongo' },
  { code: 'CD', name: 'Kongo Halk Cumhuriyeti' },
  { code: 'KR', name: 'Kore, Güney' },
  { code: 'CR', name: 'Kosta Rika' },
  { code: 'KW', name: 'Kuveyt' },
  { code: 'MP', name: 'Kuzey Mariana Adaları' },
  { code: 'LA', name: 'Laos' },
  { code: 'LS', name: 'Lesotho' },
  { code: 'LV', name: 'Letonya' },
  { code: 'LR', name: 'Liberya' },
  { code: 'LY', name: 'Libya' },
  { code: 'LI', name: 'Liechtenstein' },
  { code: 'LT', name: 'Litvanya' },
  { code: 'LB', name: 'Lübnan' },
  { code: 'LU', name: 'Lüksemburg' },
  { code: 'HU', name: 'Macaristan' },
  { code: 'MO', name: 'Macau' },
  { code: 'MG', name: 'Madagaskar' },
  { code: 'MK', name: 'Makedonya (FYROM)' },
  { code: 'MW', name: 'Malavi' },
  { code: 'MV', name: 'Maldiv Adaları' },
  { code: 'MY', name: 'Malezya' },
  { code: 'ML', name: 'Mali' },
  { code: 'MT', name: 'Malta' },
  { code: 'MH', name: 'Marshall Adaları' },
  { code: 'MQ', name: 'Martinik' },
  { code: 'MU', name: 'Mauritius' },
  { code: 'YT', name: 'Mayotte' },
  { code: 'MX', name: 'Meksika' },
  { code: 'EG', name: 'Mısır' },
  { code: 'FM', name: 'Mikronezya (Mikronezya, Federe Devletleri)' },
  { code: 'MN', name: 'Moğolistan' },
  { code: 'MD', name: 'Moldova' },
  { code: 'MC', name: 'Monako' },
  { code: 'MS', name: 'Montserrat' },
  { code: 'MR', name: 'Moritanya' },
  { code: 'MZ', name: 'Mozambik' },
  { code: 'MM', name: 'Myanmar' },
  { code: 'NA', name: 'Namibya' },
  { code: 'NP', name: 'Nepal' },
  { code: 'KN', name: 'Nevis (St. Kitts)' },
  { code: 'NE', name: 'Nijer' },
  { code: 'NG', name: 'Nijerya' },
  { code: 'NI', name: 'Nikaragua' },
  { code: 'NO', name: 'Norveç' },
  { code: 'CF', name: 'Orta Afrika Cumhuriyeti' },
  { code: 'UZ', name: 'Özbekistan' },
  { code: 'PK', name: 'Pakistan' },
  { code: 'PW', name: 'Palau' },
  { code: 'PA', name: 'Panama' },
  { code: 'PG', name: 'Papua Yeni Gine' },
  { code: 'PY', name: 'Paraguay' },
  { code: 'PE', name: 'Peru' },
  { code: 'PL', name: 'Polonya' },
  { code: 'PT', name: 'Portekiz (Azor ve Madeira hariç)' },
  { code: 'PR', name: 'Porto Riko' },
  { code: 'RE', name: 'Reunion' },
  { code: 'RO', name: 'Romanya' },
  { code: 'RW', name: 'Ruanda' },
  { code: 'WS', name: 'Samoa' },
  { code: 'SM', name: 'San Marino' },
  { code: 'SN', name: 'Senegal' },
  { code: 'SC', name: 'Seysel Adaları' },
  { code: 'RS', name: 'Sırbistan' },
  { code: 'SL', name: 'Sierra Leone' },
  { code: 'SG', name: 'Singapur' },
  { code: 'SK', name: 'Slovak Cumhuriyeti' },
  { code: 'SI', name: 'Slovenya' },
  { code: 'SB', name: 'Solomon Adaları' },
  { code: 'LK', name: 'Sri Lanka' },
  { code: 'BL', name: 'St. Barthelemy' },
  { code: 'LC', name: 'St. Lucia' },
  { code: 'VC', name: 'St. Vincent ve Grenadines' },
  { code: 'SR', name: 'Surinam' },
  { code: 'SA', name: 'Suudi Arabistan' },
  { code: 'SZ', name: 'Swaziland' },
  { code: 'CL', name: 'Şili' },
  { code: 'TJ', name: 'Tacikistan' },
  { code: 'TZ', name: 'Tanzanya' },
  { code: 'TH', name: 'Tayland' },
  { code: 'TW', name: 'Tayvan' },
  { code: 'TG', name: 'Togo' },
  { code: 'TO', name: 'Tonga' },
  { code: 'TT', name: 'Trinidad ve Tobago' },
  { code: 'TN', name: 'Tunus' },
  { code: 'TC', name: 'Turks ve Caicos Adaları' },
  { code: 'TV', name: 'Tuvalu' },
  { code: 'TM', name: 'Türkmenistan' },
  { code: 'UG', name: 'Uganda' },
  { code: 'UA', name: 'Ukrayna' },
  { code: 'OM', name: 'Umman' },
  { code: 'UY', name: 'Uruguay' },
  { code: 'JO', name: 'Ürdün' },
  { code: 'VU', name: 'Vanuatu' },
  { code: 'VE', name: 'Venezuela' },
  { code: 'VN', name: 'Vietnam' },
  { code: 'VI', name: 'Virgin Adaları ABD' },
  { code: 'WF', name: 'Wallis ve Futuna Adaları' },
  { code: 'YE', name: 'Yemen Cumhuriyeti' },
  { code: 'NC', name: 'Yeni Kaledonya' },
  { code: 'NZ', name: 'Yeni Zelanda' },
  { code: 'GR', name: 'Yunanistan' },
  { code: 'ZM', name: 'Zambiya' },
  { code: 'ZW', name: 'Zimbabve' },
];

interface FormValues {
  country: string;
  width: number | string;
  height: number | string;
  length: number | string;
  weight: number | string;
  kgDesi: number | string;
}
interface Props {
  setData: React.Dispatch<React.SetStateAction<undefined>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}
const ShippingCalculatorForm: React.FC<Props> = ({ setData, setLoading }) => {
  const { t } = useLanguage();
  const [countryName, setCountryName] = useState('Ülke seçiniz');

  const submit = async (values: FormValues) => {
    setLoading(true);
    let payload = {
      country: values.country,
      kgDesi: Number(values.kgDesi),
    };
    try {
      const res = await shipentegraService.calculateShipping(payload);
      setData(res.data.prices);
    } catch (error: any) {
      console.log(error);
      alertNotification('error', error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      country: '',
      width: '',
      height: '',
      length: '',
      weight: '',
      kgDesi: '',
    },
    onSubmit: submit,
    validationSchema: Yup.object().shape({
      country: Yup.string().required(t?.('formFilter.cantEmpty')),
      height: Yup.number().min(0, t?.('formFilter.noZero')),
      width: Yup.number().min(0, t?.('formFilter.noZero')),
      length: Yup.number().min(0, t?.('formFilter.neZero')),
      weight: Yup.number().min(0, t?.('formFilter.noZero')),
      kgDesi: Yup.number()
        .min(0, t?.('formFilter.noZero'))
        .required(t?.('formFilter.cantEmpty')),
    }),
  });
  return (
    <div className="flex flex-col space-y-3 w-full shadow-lg rounded-xl bg-white p-4 mb-8 text-sm">
      <ToastContainer />
      <form onSubmit={formik.handleSubmit}>
        <div className="grid grid-cols-12 gap-4">
          {/* COUNTRY SELECTION */}
          <div className="col-span-6 md:col-span-4">
            <label
              className="w-full py-2 font-medium text-xs min-[1335px]:text-sm"
              htmlFor="country"
            >
              {t?.('etsy-top-products.filter.country')}
            </label>

            <Listbox
              value={formik.values.country}
              onChange={(country) => {
                formik.values.country = country;

                let countryTitle = COUNTRIES?.find(
                  (item) => item.code === formik.values.country
                )?.name;

                countryTitle = countryTitle ? countryTitle : 'Hepsi';

                setCountryName(countryTitle!);
              }}
              //   onChange={formik.handleChange}
            >
              {({ open }) => (
                <>
                  <div className="relative w-full">
                    <Listbox.Button className="relative w-full cursor-default rounded-md bg-white pl-3 pr-10  pt-2.5 pb-2 text-xs min-[1335px]:text-sm text-left text-gray-900 shadow-sm ring-1 ring-inset ring-cardBorder focus:outline-none ">
                      <span className="block truncate">{countryName}</span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronDownIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-20 border-2 border-cardBorder mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-xs shadow-lg ring-1 ring-black ring-opacity-5 focus:ring-secondary focus:outline-none sm:text-sm">
                        <Listbox.Option
                          key={98989898}
                          className={({ active }) =>
                            classNames(
                              active
                                ? 'bg-secondary text-white'
                                : 'text-gray-900',
                              'relative cursor-default select-none py-2 pl-3 pr-9'
                            )
                          }
                          value={''}
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={classNames(
                                  selected ? 'font-semibold' : 'font-normal',
                                  'block truncate'
                                )}
                              >
                                {'Hepsi'}
                              </span>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? 'text-white' : 'text-indigo-600',
                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                  )}
                                >
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                        {COUNTRIES &&
                          COUNTRIES?.map((country: any) => (
                            <Listbox.Option
                              key={country.code}
                              className={({ active }) =>
                                classNames(
                                  active
                                    ? 'bg-secondary text-white'
                                    : 'text-gray-900',
                                  'relative cursor-default select-none py-2 pl-3 pr-9'
                                )
                              }
                              value={country.code}
                            >
                              {({ selected, active }) => (
                                <>
                                  <span
                                    className={classNames(
                                      selected
                                        ? 'font-semibold'
                                        : 'font-normal',
                                      'block truncate'
                                    )}
                                  >
                                    {country.name}
                                  </span>

                                  {selected ? (
                                    <span
                                      className={classNames(
                                        active
                                          ? 'text-white'
                                          : 'text-indigo-600',
                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                      )}
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
          </div>

          <div className="col-span-6 md:col-span-4">
            <label
              className="w-full font-medium text-xs min-[1335px]:text-sm"
              htmlFor="width"
            >
              En (cm)
            </label>
            <input
              value={formik.values.width}
              onChange={(e) => {
                formik.setFieldValue('width', e.currentTarget.value);
                if (formik.values.weight !== '')
                  formik.setFieldValue('weight', '');
                if (formik.values.height !== '' && formik.values.length) {
                  formik.setFieldValue(
                    'kgDesi',
                    (Number(formik.values.height) *
                      Number(formik.values.length) *
                      Number(e.currentTarget.value)) /
                      5000
                  );
                }
              }}
              id="width"
              name="width"
              type="number"
              className="w-full rounded-md border border-cardBorder shadow-sm text-xs min-[1335px]:text-sm bg-white"
              placeholder="Lütfen cm olarak giriniz..."
            />
          </div>
          <div className="col-span-6 md:col-span-4">
            <label
              className="w-full font-medium text-xs min-[1335px]:text-sm"
              htmlFor="length"
            >
              Boy (cm)
            </label>
            <input
              value={formik.values.length}
              onChange={(e) => {
                formik.setFieldValue('length', e.currentTarget.value);
                if (formik.values.weight !== '')
                  formik.setFieldValue('weight', '');
                if (formik.values.height !== '' && formik.values.width) {
                  formik.setFieldValue(
                    'kgDesi',
                    (Number(formik.values.height) *
                      Number(formik.values.width) *
                      Number(e.currentTarget.value)) /
                      5000
                  );
                }
              }}
              id="length"
              name="length"
              type="number"
              className="w-full rounded-md border border-cardBorder shadow-sm text-xs min-[1335px]:text-sm bg-white"
              placeholder="Lütfen cm olarak giriniz..."
            />
          </div>
          <div className="col-span-6 md:col-span-4">
            <label
              className="w-full font-medium text-xs min-[1335px]:text-sm"
              htmlFor="height"
            >
              Yükseklik (cm)
            </label>
            <input
              value={formik.values.height}
              onChange={(e) => {
                formik.setFieldValue('height', e.currentTarget.value);
                if (formik.values.weight !== '')
                  formik.setFieldValue('weight', '');
                if (formik.values.width !== '' && formik.values.length) {
                  formik.setFieldValue(
                    'kgDesi',
                    (Number(formik.values.width) *
                      Number(formik.values.length) *
                      Number(e.currentTarget.value)) /
                      5000
                  );
                }
              }}
              id="height"
              name="height"
              type="number"
              className="w-full rounded-md border border-cardBorder shadow-sm text-xs min-[1335px]:text-sm bg-white"
              placeholder="Lütfen cm olarak giriniz..."
            />
          </div>
          {/* WEIGHT */}
          <div className="col-span-6 md:col-span-4">
            <label
              className="w-full font-medium text-xs min-[1335px]:text-sm"
              htmlFor="weight"
            >
              Ağırlık (kg)
            </label>
            <input
              value={formik.values.weight}
              onChange={(e) => {
                formik.setFieldValue('weight', e.currentTarget.value);
                if (
                  formik.values.height !== '' &&
                  formik.values.width !== '' &&
                  formik.values.length !== ''
                ) {
                  if (
                    (Number(formik.values.height) *
                      Number(formik.values.width) *
                      Number(formik.values.length)) /
                      5000 >
                    Number(e.currentTarget.value)
                  ) {
                    formik.setFieldValue(
                      'kgDesi',
                      (Number(formik.values.height) *
                        Number(formik.values.width) *
                        Number(formik.values.length)) /
                        5000
                    );
                  } else {
                    formik.setFieldValue('kgDesi', e.currentTarget.value);
                    formik.setFieldValue('width', '');
                    formik.setFieldValue('height', '');
                    formik.setFieldValue('length', '');
                  }
                } else {
                  formik.setFieldValue('kgDesi', e.currentTarget.value);
                }
                // if (
                //   Number(e.currentTarget.value) > Number(formik.values.kgDesi)
                // ) {
                //   formik.setFieldValue('kgDesi', e.currentTarget.value);
                // }
              }}
              id="weight"
              name="weight"
              type="number"
              className="w-full rounded-md border border-cardBorder shadow-sm text-xs min-[1335px]:text-sm bg-white"
              placeholder="Lütfen kg cinsinden giriniz..."
            />
          </div>
          <div className="col-span-6 md:col-span-4">
            <label
              className="w-full font-medium text-xs min-[1335px]:text-sm"
              htmlFor="weight"
            >
              Kg/Desi
            </label>
            <input
              value={formik.values.kgDesi}
              onChange={formik.handleChange}
              id="kgDesi"
              name="kgDesi"
              type="number"
              className="w-full rounded-md border border-cardBorder shadow-sm text-xs min-[1335px]:text-sm bg-white"
              placeholder="Lütfen desi değerini giriniz..."
            />
          </div>
          <div className="col-span-12 ">
            <div className="flex items-end justify-end h-full">
              <button
                type="submit"
                className="bg-headerPrimary px-4 py-2 md:py-2.5 md:px-12 rounded-lg text-white"
              >
                Hesapla
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default ShippingCalculatorForm;
