import React from 'react';

interface LevelDescripCardProps {
  title: string;
  content: string[];
  bGroundCol: string;
}

const LevelDescripCard: React.FC<LevelDescripCardProps> = ({
  title,
  content,
  bGroundCol,
}) => {
  return (
    <div
      style={{ backgroundColor: bGroundCol }}
      className="flex flex-col  shadow-lg shadow-secondary/50 rounded-md m-4"
    >
      <div className="px-4 py-8 xl:pt-6 xl:pb-16 sm:py-6 md:py-3 lg:py-4  text-headerPrimary text-center text-sm font-bold border-b-2 border-primary sm:h-auto md:h-24 lg:h-24 xl:h-28">
        {title}
      </div>
      <div className="text-left">
        <ol className="px-8 py-4 list-decimal">
          {content.map((content, index) => (
            <li key={index} className="text-sm m-1">
              {content}
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
};

export default LevelDescripCard;
