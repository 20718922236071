import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../errors/ErrorFallback';
import RegisterForm from '../../components/authForms/RegisterForm';


export default function Login() {
    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <RegisterForm />
        </ErrorBoundary>
    )
}
