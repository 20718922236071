import React, { useEffect } from 'react';
import { classNames } from '../../utils/conditionalClasses';

type Props = {
  isLoading: boolean;
  answerString: string;
  questionString: string;
  inputRef: React.MutableRefObject<HTMLInputElement | null>;
  postQuestion: (question: string) => void;
  setQuestionString: React.Dispatch<React.SetStateAction<string | undefined>>;
};

const ExampleField = ({
  isLoading,
  answerString,
  questionString,
  inputRef,
  postQuestion,
  setQuestionString,
}: Props) => {
  if (isLoading) {
    return <BlurredResponse />;
  }

  return (
    <div className="mx-auto border-2 border-[#6F7AC3] rounded-xl w-[90%] md:w-[80%] xl:w-[60%] my-10 h-full">
      <div
        className={classNames(
          !questionString ? 'border-b-2' : '',
          'p-4 border-[#6F7AC3]'
        )}
      >
        {!answerString ? (
          <>
            {' '}
            <h1 className="font-semibold text-2xl text-headerPrimary mb-4">
              E-ticareti Rexven'e Sor
            </h1>
            <p className="text-[#070912] ">
              Yapay zekayı Amazon ve Etsy’de satış konusunda eğitiyoruz. Bu
              konuda istediğiniz soruları sorabilirsiniz. Şu an test
              aşamasındayız, ne kadar çok soru sorarsanız o kadar hızlı
              öğrenecektir.
            </p>
            <p>
              Birkaç hafta içinde Amazon ve Etsy’de satış konusunda kişisel
              danışmanınız olacaktır.
            </p>
          </>
        ) : (
          <p className="text-[#070912]">{answerString}</p>
        )}
      </div>
      {/* EXAMPLE QUESTIONS */}
      {!answerString ? (
        <div className=" bg-[#EDEEF8] rounded-xl h-full px-5 p-4">
          <div className="flex flex-col space-y-3">
            {exampleQuestions.map((question, index) => (
              <button
                key={index}
                onClick={() => {
                  if (inputRef.current) {
                    inputRef.current.value = question;
                    setQuestionString(question);
                    postQuestion(question);
                  }
                }}
                className="bg-white border-2 border-[#6F7AC3] rounded-[6px] py-2 text-left pl-8 text-base"
              >
                {question}
              </button>
            ))}
          </div>
        </div>
      ) : (
        ''
      )}
      {/* / EXAMPLE QUESTIONS */}
    </div>
  );
};

export default ExampleField;

const BlurredResponse = () => {
  return (
    <>
      <div className="mx-auto border-2 border-[#6F7AC3] rounded-xl w-[90%] md:w-[80%] xl:w-[60%] my-10 h-full">
        <div className={classNames('p-4 border-[#6F7AC3]  animate-pulse')}>
          <div className="mb-3 text-sm leading-6 mt-5">
            <div className="w-full bg-gray-300 h-2.5 rounded-full mb-2"></div>
            <div className="w-full bg-gray-300 h-2.5 rounded-full mb-2"></div>
            <div className="w-full bg-gray-300 h-2.5 rounded-full mb-2"></div>
            <div className="w-full bg-gray-300 h-2.5 rounded-full mb-2"></div>
            <div className="w-full bg-gray-300 h-2.5 rounded-full mb-2"></div>
            <div className="w-full bg-gray-300 h-2.5 rounded-full mb-2"></div>
            <div className="w-full bg-gray-300 h-2.5 rounded-full mb-2"></div>
            <div className="w-full bg-gray-300 h-2.5 rounded-full mb-2"></div>
            <div className="w-full bg-gray-300 h-2.5 rounded-full mb-2"></div>
            <div className="w-full bg-gray-300 h-2.5 rounded-full mb-2"></div>
            <div className="w-full bg-gray-300 h-2.5 rounded-full mb-2"></div>
            <div className="w-full bg-gray-300 h-2.5 rounded-full mb-2"></div>
            <div className="w-full bg-gray-300 h-2.5 rounded-full mb-2"></div>
            <div className="w-48 bg-gray-300 h-2.5 rounded-full mb-2"></div>
          </div>
        </div>
      </div>
    </>
  );
};

const exampleQuestions = [
  'Rexven nedir?',
  'E-ticarete nasıl başlarım?',
  "Amazon'da satışa nasıl başlarım?",
  "Etsy'de satışa nasıl başlarım?",
];
