import React from 'react';

import MobileMessageWrapper from '../components/messagesPage/MobileMessageWrapper';

const Messages = () => {
    return (
        <div>
            <MobileMessageWrapper />
        </div>
    )
}

export default Messages