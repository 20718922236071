import React, {
    useEffect,
    useState
} from 'react'

interface QuestionProps {
    handleForward: (question: string, answer?: string) => void
}

const Question1: React.FC<QuestionProps> = ({
    handleForward,
}) => {
    const [answer, setAnswer] = useState(() => {
        const answer1 = sessionStorage.getItem("answer1");
        return answer1 || '';
    });
    const QA = [
        { id: '18', title: '18 - 25' },
        { id: '25', title: '25 - 35' },
        { id: '35', title: '35 - 45' },
        { id: '45', title: '45 - 55' },
        { id: '55', title: '55+' },
    ]
    useEffect(() => {
        sessionStorage.setItem("answer1", answer);
    }, [answer]);
    return (
        <>
            <div>
                <div className="flex flex-row text-sm font-small text-rexRed">
                    <p>Sizleri daha iyi tanıyarak ürün ve hizmetlerimizi geliştirmemiz için aşağıdaki bilgileri doldurabilir misiniz?</p>
                </div>
                <div className="flex flex-col space-y-4">
                    <div className='mt-2'>
                        <label className="text-base font-semibold text-gray-900">Kaç Yaşındasınız?</label>
                        <fieldset className="mt-4">
                            <legend className="sr-only">Notification method</legend>
                            <div className="space-y-4">
                                {QA.map((option) => (
                                    <div key={option.id} className="flex items-center">
                                        <input
                                            id={option?.id}
                                            name="notification-method"
                                            type="radio"
                                            value={option?.title}
                                            defaultChecked={option?.title === answer}
                                            className="h-4 w-4 border-gray-300 text-cardBorder focus:ring-cardBorder"
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAnswer(e.target.value)}
                                        />
                                        <label htmlFor={option.id} className="ml-3 block text-sm font-small leading-6 text-gray-900">
                                            {option?.title}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </fieldset>
                    </div>
                    <div className="flex items-center text-[16px] bg justify-between mt-2">
                        <div className="text-center text-[13px] px-3 h-[50px] max-h-[50px] font-bold  lg:py-1  w-[49%] flex items-center">
                            <button className="w-full"></button>
                        </div>
                        <div onClick={() =>
                            handleForward("question1", answer)
                        } className="hover:cursor-pointer text-center text-[12px] bg-headerPrimary font-bold text-white h-[50px] max-h-[50px]  px-3 py-1  border-2 rounded-lg border-headerPrimary w-[49%] flex items-center justify-center">
                            İleri
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Question1
