import React from 'react';
import { seperateNumberWithComma } from '../../../../utils/numberFormatter';
import { Tooltip } from 'react-tooltip';
import { Information } from 'iconsax-react';

export interface IResult {
  revenue: number;
  profit: number;
  profitMargin: number;
  investment: number;
  netInvestment: number;
  brandValue: number;
}
interface Props {
  result: IResult[];
}
const ResultSection: React.FC<Props> = ({ result }) => {
  return (
    <div className="grid grid-cols-12 bg-white shadow-lg rounded-xl w-full lg:w-1/2">
      {result.length > 0 &&
        result.map((item: IResult, index: number) => {
          return (
            <div
              className="col-span-6 border-b-2 border-[#3C4790] p-6"
              key={Math.random() * 100000}
            >
              <p className="underline">{index + 1}.Yıl Verileri</p>
              <div className="flex justify-between items-center  my-2">
                <p className="text-xs xl:text-sm">Ciro</p>
                <p className="text-xs xl:text-sm font-medium text-[#3C4790]">
                  ${seperateNumberWithComma(Number(item.revenue.toFixed(0)))}
                </p>
              </div>
              <div className="flex justify-between items-center mb-3">
                <p className="text-xs xl:text-sm">Kar</p>
                <p className="text-xs xl:text-sm font-medium text-[#3C4790]">
                  ${seperateNumberWithComma(Number(item.profit.toFixed(0)))}
                </p>
              </div>
              <div className="flex justify-between items-center mb-3">
                <p className="text-xs xl:text-sm">Kar Marjı</p>
                <p className="text-xs xl:text-sm font-medium text-[#3C4790]">
                  %
                  {seperateNumberWithComma(
                    Number(item.profitMargin.toFixed(0))
                  )}
                </p>
              </div>
              <div className="flex justify-between items-center mb-3">
                <p
                  className="text-xs xl:text-sm flex items-center"
                  id="investment"
                >
                  Yatırım Tutarı{' '}
                  <Information color="#6F7AC3" size={16} className="ml-1" />
                </p>
                <p className="text-xs xl:text-sm font-medium text-[#3C4790]">
                  ${seperateNumberWithComma(Number(item.investment.toFixed(0)))}
                </p>
                <Tooltip
                  content="Ürün + Kargo + Marka Tescili + Şirket Kurulumu + Ürün Fotoğrafı"
                  anchorSelect="#investment"
                />
              </div>
              <div className="flex justify-between items-center mb-3">
                <p
                  className="text-xs xl:text-sm flex items-center"
                  id="netInvestment"
                >
                  Net Yatırım{' '}
                  <Information color="#6F7AC3" size={16} className="ml-1" />
                </p>
                <p className="text-xs xl:text-sm font-medium text-[#3C4790]">
                  $
                  {seperateNumberWithComma(
                    Number(item.netInvestment.toFixed(0))
                  )}
                </p>
                <Tooltip
                  anchorSelect="#netInvestment"
                  content="Yatırım Tutarı - Net Kar"
                />
              </div>
              <div className="flex justify-between items-center mb-3">
                <p className="text-xs xl:text-sm">Yıl Sonu Marka Değeri</p>
                <p className="text-xs xl:text-sm font-medium text-[#3C4790]">
                  ${seperateNumberWithComma(Number(item.brandValue.toFixed(0)))}
                </p>
              </div>
            </div>
          );
        })}
      {result.length > 0 && (
        <div className="col-span-6 p-6">
          <div className="flex flex-col lg:flex-row justify-center lg:justify-between items-center mb-2">
            <p className="text-xs xl:text-sm text-center lg:text-start">
              Toplam Kar (2 Yıl)
            </p>
            <p className="text-xs xl:text-sm font-medium text-[#3C4790]">
              $
              {seperateNumberWithComma(
                Number((result[0].profit + result[1].profit).toFixed(0))
              )}
            </p>
          </div>
          <div className="flex flex-col lg:flex-row justify-center lg:justify-between items-center mb-2">
            <p className="text-xs xl:text-sm text-center lg:text-start">
              Toplam Ciro (2 Yıl)
            </p>
            <p className="text-xs xl:text-sm font-medium text-[#3C4790]">
              ${(result[0].revenue + result[1].revenue).toFixed(0)}
            </p>
          </div>
        </div>
      )}
      {result.length > 0 && (
        <div className="col-span-6 p-6">
          <div className="flex flex-col lg:flex-row justify-center lg:justify-between items-center mb-2">
            <p className="text-xs xl:text-sm text-center lg:text-start">
              Net Yatırım (2 Yıl)
            </p>
            <p className="text-xs xl:text-sm font-medium text-[#3C4790]">
              $
              {seperateNumberWithComma(
                Number(
                  (result[0].netInvestment + result[1].netInvestment).toFixed(0)
                )
              )}
            </p>
          </div>
          <div className="flex flex-col lg:flex-row justify-center lg:justify-between items-center mb-2">
            <p className="text-xs xl:text-sm text-center lg:text-start">
              Kar Marjı (2 Yıl)
            </p>
            <p className="text-xs xl:text-sm font-medium text-[#3C4790]">
              %
              {(
                ((result[0].profit + result[1].profit) /
                  (result[0].revenue + result[1].revenue)) *
                100
              ).toFixed(0)}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default ResultSection;
