import React from 'react';
import { Information } from 'iconsax-react';
import { Tooltip } from 'react-tooltip';

interface TrendyolCalculateResultProps {
  trendyolCalculateResult: TrendyolCalculateResult;
}

export interface TrendyolCalculateResult {
  income: number;
  totalCost: number;
  grossingUp: number;
  profit: number;
  profitMargin: number;
  commission: number;
  serviceCharge: number;
  vatTotal: number;
  vatSale: number;
  vatProduct: number;
  vatPacking: number;
  vatShipping: number;
  vatAdvertising: number;
  vatCommission: number;
  vatServiceCharge: number;
}

function classNames(...classes: String[]) {
  return classes.filter(Boolean).join(' ');
}

const TrendyolCalculateResultForm: React.FC<TrendyolCalculateResultProps> = ({
  trendyolCalculateResult,
}) => {
  return (
    <div className="mt-1 mb-4 flex flex-col gap-y-[2px] ">
      <h1 className="w-full border border-[#F27B1A] text-xl font-bold text-white text-center rounded-sm bg-[#F27B1A] p-1 mb-2 -mt-3">
        KESİNTİ
      </h1>

      {/* Komisyon Ücreti */}
      <div className="flex items-center">
        <div className="w-[90%] rounded-sm border-[#FCE3CF] border-[1px] px-2 py-2 shadow-sm text-xs md:text-sm bg-[#FCE3CF] mr-1">
          <div className="flex justify-between">
            <span>Komisyon Ücreti</span>
            <Information
              size={20}
              className="cursor-pointer"
              id="commissionResultToolTip"
            />
            <Tooltip
              anchorSelect="#commissionResultToolTip"
              content="Trendyol‘un satış yapılan kategoriden yapmış olduğu kesinti"
            />
          </div>
        </div>
        <p className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 placeholder-gray-400 shadow-sm text-xs md:text-sm">
          {trendyolCalculateResult.commission + ' '}₺
        </p>
      </div>

      {/* Hizmet Bedeli Ücreti */}
      <div className="flex items-center">
        <div className="w-[90%] rounded-sm border-[#FCE3CF] border-[1px] px-2 py-2 shadow-sm text-xs md:text-sm bg-[#FCE3CF] mr-1">
          <div className="flex justify-between">
            <span>Hizmet Bedeli Ücreti</span>
            <Information
              size={20}
              className="cursor-pointer"
              id="serviceChargeToolTip"
            />
            <Tooltip anchorSelect="#serviceChargeToolTip">
              Müşteri ve satıcı memnuniyeti açısından kritik olan entegrasyon,
              izlenebilirlik ve ulaşılabilirlik sağlayan sistemlerin <br />{' '}
              teknolojik ve insan kaynağı altyapı giderlerine istinaden Trendyol
              tarafından yapılan kesinti
            </Tooltip>
          </div>
        </div>
        <p
          className={classNames(
            // trendyolCalculateResult.serviceCharge === 0 ? '' : 'text-[#E3211E]',
            'w-full rounded-sm border border-[#FCE3CF] px-2 py-2 placeholder-gray-400 shadow-sm text-xs md:text-sm'
          )}
        >
          {/* {trendyolCalculateResult.serviceCharge === 0
            ? `${trendyolCalculateResult.serviceCharge + ' ₺'}`
            : `-${trendyolCalculateResult.serviceCharge + ' ₺'}`} */}
          {trendyolCalculateResult.serviceCharge + ' ₺'}
        </p>
      </div>

      {/* Ödenecek KDV */}
      <div className="flex items-center">
        <div className="w-[90%] rounded-sm border-[#FCE3CF] border-[1px] px-2 py-2 shadow-sm text-xs md:text-sm bg-[#FCE3CF] mr-1">
          <div className="flex justify-between">
            <span>Ödenecek KDV </span>
            <Information
              size={20}
              className="cursor-pointer"
              id="vatTotalToolTip"
            />
            <Tooltip anchorSelect="#vatTotalToolTip">
              "Ürün Maliyeti KDV", "Paketleme Maliyeti KDV", "Kargo Maliyeti
              KDV", "Reklam Maliyeti KDV", "Komisyon Ücreti KDV" ve <br />{' '}
              "Hizmet Bedeli KDV" toplamının "Satış Fiyatı KDV" değerinden
              çıkarılması neticesinde elde edilen devlete <br /> ödenecek net
              Katma Değer Vergisi (KDV) tutarı
            </Tooltip>
          </div>
        </div>
        <p className="w-full  rounded-sm border border-[#FCE3CF] px-2 py-2 placeholder-gray-400 shadow-sm text-xs md:text-sm">
          {trendyolCalculateResult.vatTotal + ' '}₺
        </p>
      </div>

      {/* Satış Fiyatı KDV */}
      <div className="flex items-center">
        <div className="w-[90%] rounded-sm border-[#FCE3CF] border-[1px] px-2 py-2 shadow-sm text-xs md:text-sm bg-[#FEF1E7] mr-1">
          <div className="flex justify-between">
            <span>Satış Fiyatı KDV </span>
            <Information
              size={20}
              className="cursor-pointer"
              id="vatSaleToolTip"
            />
            <Tooltip anchorSelect="#vatSaleToolTip">
              Ürünün satışından oluşan KDV tutarı
            </Tooltip>
          </div>
        </div>
        <p
          className={classNames(
            // trendyolCalculateResult.vatSale === 0 ? '' : 'text-[#E3211E]',
            'w-full rounded-sm border border-[#FCE3CF] px-2 py-2 placeholder-gray-400 shadow-sm text-xs md:text-sm'
          )}
        >
          {/* {trendyolCalculateResult.vatSale === 0
            ? `${trendyolCalculateResult.vatSale + ' ₺'}`
            : `-${trendyolCalculateResult.vatSale + ' ₺'}`} */}
          {trendyolCalculateResult.vatSale + ' ₺'}
        </p>
      </div>

      {/* Ürün Maliyeti KDV */}
      <div className="flex items-center">
        <div className="w-[90%] rounded-sm border-[#FCE3CF] border-[1px] px-2 py-2 shadow-sm text-xs md:text-sm bg-[#FEF1E7] mr-1">
          <div className="flex justify-between">
            <span>Ürün Maliyeti KDV</span>
            <Information
              size={20}
              className="cursor-pointer"
              id="vatProductToolTip"
            />
            <Tooltip anchorSelect="#vatProductToolTip">
              Ürünün alışından oluşan KDV tutarı
            </Tooltip>
          </div>
        </div>
        <p className="w-full  rounded-sm border border-[#FCE3CF] px-2 py-2 placeholder-gray-400 shadow-sm text-xs md:text-sm">
          {trendyolCalculateResult.vatProduct + ' '}₺
        </p>
      </div>

      {/* Paketleme KDV */}
      <div className="flex items-center">
        <div className="w-[90%] rounded-sm border-[#FCE3CF] border-[1px] px-2 py-2 shadow-sm text-xs md:text-sm bg-[#FEF1E7] mr-1">
          <div className="flex justify-between">
            <span>Paketleme KDV</span>
            <Information
              size={20}
              className="cursor-pointer"
              id="vatPackingToolTip"
            />
            <Tooltip anchorSelect="#vatPackingToolTip">
              Ambalaj veya paketleme alışından oluşan KDV tutarı
            </Tooltip>
          </div>
        </div>
        <p
          className={classNames(
            'w-full rounded-sm border border-[#FCE3CF] px-2 py-2 placeholder-gray-400 shadow-sm text-xs md:text-sm'
          )}
        >
          {trendyolCalculateResult.vatPacking + ' ₺'}
          {/* {trendyolCalculateResult.vatPacking !== 0
            ? `-${trendyolCalculateResult.vatPacking + ' ₺'}`
            : trendyolCalculateResult.vatPacking + ' ₺'} */}
        </p>
      </div>

      {/* Kargo KDV */}
      <div className="flex items-center">
        <div className="w-[90%] rounded-sm border-[#FCE3CF] border-[1px] px-2 py-2 shadow-sm text-xs md:text-sm bg-[#FEF1E7] mr-1">
          <div className="flex justify-between">
            <span>Kargo KDV</span>
            <Information
              size={20}
              className="cursor-pointer"
              id="vatShippingToolTip"
            />
            <Tooltip anchorSelect="#vatShippingToolTip">
              Kargo firmasına yapılan ödemeden oluşan KDV tutarı
            </Tooltip>
          </div>
        </div>
        <p
          className={classNames(
            // trendyolCalculateResult.vatShipping > 0 ? 'text-[#E3211E]' : '',
            'w-full rounded-sm border border-[#FCE3CF] px-2 py-2 placeholder-gray-400 shadow-sm text-xs md:text-sm'
          )}
        >
          {/* {trendyolCalculateResult.vatShipping > 0
            ? `-${trendyolCalculateResult.vatShipping}₺`
            : `${trendyolCalculateResult.vatShipping * -1}`} */}
          {trendyolCalculateResult.vatShipping + ' ₺'}
        </p>
      </div>

      {/* Reklam KDV */}
      <div className="flex items-center">
        <div className="w-[90%] rounded-sm border-[#FCE3CF] border-[1px] px-2 py-2 shadow-sm text-xs md:text-sm bg-[#FEF1E7] mr-1">
          <div className="flex justify-between">
            <span>Reklam KDV</span>
            <Information
              size={20}
              className="cursor-pointer"
              id="vatAdToolTip"
            />
            <Tooltip anchorSelect="#vatAdToolTip">
              Ürünün tanıtımı için Trendyol'a yapılan ödemeden oluşan KDV tutarı
            </Tooltip>
          </div>
        </div>
        <p className="w-full  rounded-sm border border-[#FCE3CF] px-2 py-2 placeholder-gray-400 shadow-sm text-xs md:text-sm">
          {trendyolCalculateResult.vatAdvertising + ' '}₺
        </p>
      </div>

      {/* Komisyon KDV */}
      <div className="flex items-center">
        <div className="w-[90%] rounded-sm border-[#FCE3CF] border-[1px] px-2 py-2 shadow-sm text-xs md:text-sm bg-[#FEF1E7] mr-1">
          <div className="flex justify-between">
            <span>Komisyon KDV</span>
            <Information
              size={20}
              className="cursor-pointer"
              id="vatCommToolTip"
            />
            <Tooltip anchorSelect="#vatCommToolTip">
              Trendyol‘un satış yapılan kategoriye göre yapmış olduğu kesintiden
              oluşan KDV tutarı
            </Tooltip>
          </div>
        </div>
        <p className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2  placeholder-gray-400 shadow-sm text-xs md:text-sm">
          {trendyolCalculateResult.vatCommission + ' '}₺
        </p>
      </div>

      {/* Hizmet Bedeli KDV */}
      <div className="flex items-center">
        <div className="w-[90%] rounded-sm border-[#FCE3CF] border-[1px] px-2 py-2 shadow-sm text-xs md:text-sm bg-[#FEF1E7] mr-1">
          <div className="flex justify-between">
            <span>Hizmet Bedeli KDV</span>
            <Information
              size={20}
              className="cursor-pointer"
              id="vatServiceChargeToolTip"
            />
            <Tooltip anchorSelect="#vatServiceChargeToolTip">
              Müşteri ve satıcı memnuniyeti açısından kritik olan entegrasyon,{' '}
              izlenebilirlik ve ulaşılabilirlik sağlayan <br /> sistemlerin
              teknolojik ve insan kaynağı altyapı giderlerine istinaden Trendyol
              tarafından <br /> yapılan kesintiden oluşan KDV tutarı
            </Tooltip>
          </div>
        </div>
        <p className="w-full rounded-sm border border-[#FCE3CF]  px-2 py-2 placeholder-gray-400 shadow-sm text-xs md:text-sm">
          {trendyolCalculateResult.vatServiceCharge + ' '}₺
        </p>
      </div>
    </div>
  );
};

export default TrendyolCalculateResultForm;
