import React from 'react';

const BlurredProductReview = () => {
  return (
    <div className="px-2 py-4 shadow-md bg-white rounded-lg my-2">
      {/* ClientImage - ClientName - Rating */}
      <div className="flex justify-start">
        <div className="flex items-center text-description mb-1">
          <div className="w-[20px] h-[20px] rounded-full bg-gray-300"></div>
          <span className="text-lg ml-2 font-normal mr-2">
            <div className="w-44 bg-gray-300 h-2.5 rounded-full mt-2 mb-2"></div>
          </span>
          <div className="w-32 bg-gray-300 h-2.5 rounded-full mt-2 mb-2"></div>
        </div>
      </div>
      {/* reviewInfo */}
      <div className="flex justify-start w-full">
        <div className="w-[30%] bg-gray-300 h-2.5 rounded-full mt-2 mb-2"></div>
      </div>
      {/* Review Location And Date */}
      <div className="flex justify-start">
        <div className="w-[25%] bg-gray-300 h-2.5 rounded-full mt-2 mb-2"></div>
      </div>

      {/* Review Himself */}
      <div className="">
        <div className="w-full bg-gray-300 h-2.5 rounded-full mt-2 mb-2"></div>
        <div className="w-full bg-gray-300 h-2.5 rounded-full mt-2 mb-2"></div>
        <div className="w-full bg-gray-300 h-2.5 rounded-full mt-2 mb-2"></div>
        <div className="w-full bg-gray-300 h-2.5 rounded-full mt-2 mb-2"></div>
        <div className="w-[60%] bg-gray-300 h-2.5 rounded-full mt-2 mb-2"></div>
      </div>
    </div>
  );
};

export default BlurredProductReview;
