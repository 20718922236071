import {
  Box1,
  CloseSquare,
  DocumentDownload,
  EyeSlash,
  Link2,
  Messages1,
  TickCircle,
  TicketStar,
  TruckFast,
  Wallet1,
} from 'iconsax-react';
import React from 'react';
import { Link } from 'react-router-dom';

import API_URL from '../../../config';
import { classNames } from '../../../utils/conditionalClasses';
import { formatDateTR } from '../../../utils/date';
import LoadingComponent from '../../common/LoadingComponent';
import { Tooltip } from 'react-tooltip';

export type LabelKey =
  | 'waitingMatch'
  | 'waitingPayment'
  | 'producing'
  | 'ready'
  | 'waitingLabel'
  | 'shipped'
  | 'delivered';

export const labels = {
  waitingMatch: (
    <div className="flex space-x-2 justify-center items-center rounded-full border border-[#4F46E5] px-2 p-1 w-full">
      <Link2 size="18" color="#4F46E5" variant="Bold" />
      <p className="text-[#4F46E5] text-sm">Eşleşme Bekliyor</p>
    </div>
  ),

  waitingPayment: (
    <div className="flex space-x-2 justify-center items-center rounded-full border border-[#0D9488] px-2 p-1 w-full">
      <Wallet1 size="18" color="#0D9488" />
      <p className="text-[#0D9488] text-sm">Ödeme Bekliyor</p>
    </div>
  ),
  waitingProducing: (
    <div className="flex space-x-2 justify-center items-center rounded-full border border-[#EC4899] px-2 p-1 w-[200px]">
      <Box1 size="18" color="#EC4899" />
      <p className="text-[#EC4899] text-sm">Üretim Bekliyor</p>
    </div>
  ),
  producing: (
    <div className="flex space-x-2 justify-center items-center rounded-full border border-[#7C3AED] px-2 p-1 w-full">
      <Box1 size="18" color="#7C3AED" />
      <p className="text-[#7C3AED] text-sm">Üretiliyor</p>
    </div>
  ),
  ready: (
    <div className="flex space-x-2 justify-center items-center rounded-full border border-[#06B6D4] px-2 p-1 w-full">
      <Box1 size="18" color="#06B6D4" />
      <p className="text-[#06B6D4] text-sm">Hazır</p>
    </div>
  ),
  waitingLabel: (
    <div className="flex space-x-2 justify-center items-center rounded-full border border-[#F59E0B] px-2 p-1 w-full">
      <TicketStar size="18" color="#F59E0B" />
      <p className="text-[#F59E0B] text-sm">Etiket Bekliyor</p>
    </div>
  ),
  shipped: (
    <div className="flex space-x-2 justify-center items-center rounded-full border border-[#0EA5E9] px-2 p-1 w-full">
      <TruckFast size="18" color="#0EA5E9" />
      <p className="text-[#0EA5E9] text-sm">Kargoya Verildi</p>
    </div>
  ),
  delivered: (
    <div className="flex space-x-2 justify-center items-center rounded-full border border-[#059669] px-2 p-1 w-full">
      <TickCircle size="18" color="#059669" />
      <p className="text-[#059669] text-sm">Teslim Edildi</p>
    </div>
  ),
  hidden: (
    <div className="flex space-x-2 justify-center items-center rounded-full border border-[#4F46E5] px-2 p-1 w-full">
      <EyeSlash size="18" color="#4F46E5" variant="Bold" />
      <p className="text-[#4F46E5] text-sm">Gizli</p>
    </div>
  ),
  cancelled: (
    <div className="flex space-x-2 justify-center items-center rounded-full border border-[#4F46E5] px-2 p-1 w-full">
      <CloseSquare size="18" color="red" variant="Bold" />
      <p className="text-red-500 text-sm">Gizli</p>
    </div>
  ),
};
const tableHeader = [
  { name: 'Seç' },
  { name: 'Sıra' },
  { name: 'Sipariş' },
  { name: 'Fotoğraf' },
  { name: 'Detay' },
  {
    name: 'Alıcı',
  },
  { name: 'Fiyat' },
  { name: 'Hediye' },

  {
    name: 'Satıcı Notu',
  },
  {
    name: 'İşlemler',
  },
  { name: 'Tasarım' },
];
interface Props {
  isLoading: boolean;
  orders: any[];
  page: number;
  setSelectedOrders: React.Dispatch<React.SetStateAction<any>>;
  selectedOrders: any;
  ref: any;
}

const SupplierOrdersTable = React.forwardRef<HTMLTableElement, Props>(
  ({ setSelectedOrders, page, selectedOrders, orders, isLoading }, ref) => {
    const toggleOrderSelection = (selectedItem: any) => {
      setSelectedOrders((prevSelectedOrders: any) => {
        const isAlreadySelected = prevSelectedOrders.some(
          (orderItem: any) =>
            orderItem.seller_order_items[0].id ===
            selectedItem.seller_order_items[0].id
        );
        if (isAlreadySelected) {
          return prevSelectedOrders.filter(
            (orderItem: any) =>
              orderItem.seller_order_items[0].id !==
              selectedItem.seller_order_items[0].id
          );
        } else {
          return [...prevSelectedOrders, selectedItem];
        }
      });
    };
    const downloadLabel = async (url: string) => {
      console.log('first');
      console.log(url);
      const response = await fetch(url);
      console.log(response);
      const blob = await response.blob();
      const filename = 'label.pdf';
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    };
    return (
      <div className="flow-root">
        <div className="relative -mx-4 -my-2 overflow-x-auto overflow-y-auto scrollbar-hide">
          <div className="inline-block min-w-full py-2 align-middle  sm:px-6 lg:px-8">
            <div className=" flex flex-col flex-shrink-0 bg-white rounded-xl mt-8">
              <div className="relative overflow-y-auto overflow-x-auto scrollbar-hide">
                <div className="inline-block min-w-full align-middle h-[100vh]">
                  <table className="min-w-[1280px] table-fixed" ref={ref}>
                    <thead>
                      <tr>
                        {tableHeader.map((item, index) => {
                          return (
                            <th
                              key={index}
                              className="sticky top-0 z-10 bg-headerPrimary py-3.5 pl-4 pr-3 text-sm text-center font-semibold text-white first:rounded-tl-lg last:rounded-tr-lg first:rounded-bl-lg last:rounded-br-lg"
                              // scope="col"
                            >
                              {' '}
                              <p className="text-xs md:text-sm">{item.name}</p>
                            </th>
                          );
                        })}
                      </tr>
                    </thead>

                    {isLoading ? (
                      <div className="w-full flex justify-center">
                        <LoadingComponent />
                      </div>
                    ) : (
                      <tbody className="divide-y divide-gray-200 bg-white">
                        <tr className="h-10"></tr>
                        {orders?.length > 0 ? (
                          orders?.map((order: any, idx) => {
                            return (
                              <tr
                                key={idx}
                                className={classNames(
                                  // !session?.hasPackage && idx > 2
                                  //   ? 'blur-sm'
                                  //   : 'blur-none',
                                  'shadow-md rounded-xl border-none'
                                )}
                              >
                                <td className="py-4 pl-4 text-xs md:text-sm sm:pl-0">
                                  <div className="flex justify-center">
                                    {
                                      <input
                                        type="checkbox"
                                        className="rounded-sm border-blue-500"
                                        checked={selectedOrders?.some(
                                          (orderItem: any) =>
                                            orderItem.seller_order_items[0]
                                              .sellerOrderId ===
                                            order.seller_order_items[0]
                                              .sellerOrderId
                                        )}
                                        onChange={() =>
                                          toggleOrderSelection(order)
                                        }
                                      />
                                    }
                                  </div>
                                </td>
                                <td className="py-4 pl-4 text-xs md:text-sm sm:pl-0">
                                  <p className="text-center text-gray">
                                    {idx + 20 * (page - 1) + 1}
                                  </p>
                                </td>
                                <td className="py-4 text-xs sm:pl-0 whitespace-nowrap text-gray-500">
                                  <div className="flex flex-col items-start space-y-1">
                                    <p>
                                      <span className="text-headerPrimary">
                                        Tarih:
                                      </span>{' '}
                                      {formatDateTR(order.orderDate)}
                                    </p>

                                    <p>
                                      <span className="text-headerPrimary">
                                        Sipariş no:{' '}
                                      </span>
                                      {
                                        order?.seller_order_items[0]
                                          ?.seller_order?.platformOrderId
                                      }
                                    </p>
                                    <p>
                                      <span className="text-headerPrimary">
                                        Sipariş adedi:
                                      </span>{' '}
                                      {order.seller_order_items?.length}
                                    </p>

                                    {labels[
                                      order?.seller_order_items[0]
                                        ?.label as LabelKey
                                    ] || (
                                      <div className="flex space-x-2 justify-center items-center rounded-full border border-rexRed px-2 py-1 w-full">
                                        <p className="text-sm text-rexRed">
                                          Geçersiz durum
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                </td>
                                <td>
                                  <div
                                    className={classNames(
                                      order?.seller_order_items.length > 1
                                        ? 'space-y-2'
                                        : 'space-y-0',
                                      'px-2 py-2 flex flex-col items-center justify-center w-[272px]'
                                    )}
                                  >
                                    {order?.seller_order_items.map(
                                      (item: any) => {
                                        return (
                                          <img
                                            onLoad={() => {}}
                                            className="h-[200px] w-[200px] aspect-square object-cover object-center rounded-lg"
                                            src={
                                              item.seller_product.images[0]
                                                ? item.seller_product.images[0]
                                                : '/images/image-not-found.png'
                                            }
                                            alt="Product"
                                          />
                                        );
                                      }
                                    )}
                                  </div>
                                </td>
                                <td className="py-4 text-xs md:text-sm text-left overflow-hidden text-gray-500 px-3 min-w-[230px]">
                                  <div className="flex flex-col items-start space-y-4">
                                    {order?.seller_order_items.map(
                                      (item: any, index: any) => (
                                        <div
                                          key={index}
                                          className="flex items-center space-x-4 px-2 pb-2"
                                        >
                                          {/* <input
                                            type="checkbox"
                                            className="rounded-sm border-blue-500"
                                            checked={selectedOrders?.some(
                                              (orderItem: any) =>
                                                orderItem.id === item.id
                                            )}
                                            onChange={() =>
                                              toggleOrderSelection(item)
                                            }
                                          /> */}

                                          <div className="flex flex-col space-y-2">
                                            <p className="text-xs text-left text-[#1F2937] overflow-hidden h-16 font-medium">
                                              {item.seller_product.title}
                                            </p>

                                            <p className="text-xs text-[#1F2937]">
                                              Adet:{' '}
                                              <span className="text-gray-500">
                                                {item.quantity}
                                              </span>
                                            </p>

                                            <div className="text-xs text-left text-gray-500">
                                              {Object.entries(
                                                item.seller_product
                                                  .attributes[0]
                                              ).map(([key, value]) => (
                                                <div
                                                  className="flex space-x-1"
                                                  key={`${index}-${key}`}
                                                >
                                                  <p className="text-[#1F2937]">
                                                    {key}:
                                                  </p>
                                                  <p>{value as string}</p>
                                                </div>
                                              ))}
                                              <p className="font-medium text-[#1F2937]">
                                                Kişiselleştirme:{' '}
                                                <span className="font-normal">
                                                  {item.personalization || '-'}
                                                </span>
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </td>

                                <td className="py-4 text-xs text-left text-gray-500">
                                  <div className="flex flex-col items-start space-y-1 w-24">
                                    <p>
                                      <span className="text-[#1F2937]">
                                        İsim:{' '}
                                      </span>
                                      {
                                        order?.seller_order_items[0]
                                          ?.seller_order?.fullName
                                      }
                                    </p>
                                    {/* <div>
                                      <span className="text-[#1F2937]">
                                        Email:
                                      </span>{' '}
                                      {
                                        order.seller_order_items[0]
                                          ?.seller_order.email
                                      }{' '}
                                    </div> */}
                                    <div>
                                      <span className="text-[#1F2937]">
                                        Adres:{' '}
                                      </span>
                                      {
                                        order.seller_order_items[0]
                                          ?.seller_order.address
                                      }{' '}
                                    </div>

                                    <div>
                                      <span className="text-[#1F2937]">
                                        Ülke:
                                      </span>
                                      {
                                        order?.seller_order_items[0]
                                          ?.seller_order?.seller_etsy_orders
                                          ?.customerCountry
                                      }
                                    </div>
                                  </div>
                                </td>
                                <td className="py-4 text-left text-xs md:text-xs text-gray-500">
                                  <div className="flex flex-col items-start space-y-1">
                                    {/* {order?.seller_order_items?.map(
                                    (item: any) => {
                                      return ( */}

                                    {/* ); */}
                                    {/* }
                                  )} */}

                                    <div className="text-md font-bold whitespace-nowrap">
                                      <span className="text-[#1F2937] text-lg">
                                        ${' '}
                                        {Number(
                                          order.seller_order_items.reduce(
                                            (acc: any, item: any) =>
                                              acc +
                                              Number(
                                                item.seller_product
                                                  .supplierProductVariantPrice
                                              ) *
                                                item.quantity,
                                            0
                                          )
                                        ).toFixed(2)}
                                      </span>
                                    </div>
                                  </div>
                                </td>

                                <td className="py-4 px-2 text-xs text-left w-40 text-gray-500">
                                  {order.seller_order_items.map((item: any) => {
                                    return (
                                      <div className="flex flex-col items-start space-y-1 min-w-[140px] pb-2">
                                        <div>
                                          Ürün hediye mi?:{' '}
                                          {item.seller_order.seller_etsy_orders
                                            .isGift === true
                                            ? 'Evet'
                                            : 'Hayır'}{' '}
                                        </div>
                                        {/* <div>
                                          Gift wrap:{' '}
                                          {item.seller_order.seller_etsy_orders
                                            .needsGiftWrap === true
                                            ? 'Evet'
                                            : 'Hayır'}{' '}
                                        </div> */}
                                        <div>
                                          Hediye mesajı:{' '}
                                          {/* {order.seller_etsy_order.giftMessage
                                        ? order.giftMessage
                                        : '-'}{' '} */}
                                        </div>
                                        <div>
                                          Alıcı mesajı:{' '}
                                          {/* {order.seller_etsy_order.buyerMessage
                                        ? order.buyerMessage
                                        : '-'} */}
                                        </div>
                                      </div>
                                    );
                                  })}
                                </td>

                                <td className="py-4 px-4">
                                  {
                                    <div className="flex flex-col space-y-2">
                                      {order?.seller_order_items?.map(
                                        (item: any) => {
                                          return (
                                            <p className="border-none rounded-lg bg-lightGray focus:outline-none text-xs placeholder:text-xs min-w-[150px] p-2">
                                              {item.note}
                                            </p>
                                          );
                                        }
                                      )}
                                      {/* <div className="flex space-x-2">
                                    <button
                                      className="bg-[#2563EB] text-white text-xs px-3 py-1 rounded-lg whitespace-nowrap inline-block focus:outline-none flex-1"
                                      onClick={() =>
                                        handleAddNote(
                                          order.seller_order_items[0].id
                                        )
                                      }
                                    >
                                      Not Ekle
                                    </button>
                                  </div> */}
                                    </div>
                                  }
                                </td>

                                <td>
                                  <div className="mt-3 flex items-center space-x-2 px-2">
                                    <Tooltip
                                      anchorSelect="#message"
                                      content="Mesaj"
                                    />
                                    <Link
                                      id="message"
                                      target="_blank"
                                      to={`/messages?to=${order?.seller_order_items[0]?.seller_order.userId}&name=${order?.seller_order_items[0]?.seller_order.user.firstName} ${order?.seller_order_items[0]?.seller_order.user.lastName}`}
                                      className="px-2 py-1 rounded-full whitespace-nowrap focus:outline-none focus:ring"
                                    >
                                      <Messages1
                                        size="32"
                                        color="#2563EB"
                                        variant="Bold"
                                      />
                                    </Link>
                                    <Tooltip
                                      anchorSelect="#download"
                                      content="Label İndir"
                                    />
                                    {order?.seller_order_items[0].seller_order
                                      .labelPdf && (
                                      <a
                                        id="download"
                                        href={
                                          order?.seller_order_items[0].seller_order.labelPdf.includes(
                                            'https'
                                          )
                                            ? order?.seller_order_items[0]
                                                .seller_order.labelPdf
                                            : `${API_URL}${order?.seller_order_items[0].seller_order.labelPdf}`
                                        }
                                        target="_blank"
                                        download
                                        rel="noreferrer"
                                      >
                                        <DocumentDownload
                                          size="32"
                                          color="#2563EB"
                                          variant="Bold"
                                        />{' '}
                                      </a>
                                    )}
                                  </div>
                                </td>
                                <td>
                                  {order?.seller_order_items[0]?.designFiles
                                    ?.length < 1 ? (
                                    <div className="w-72 flex">
                                      {order?.seller_order_items?.map(
                                        (item: any) => {
                                          return item?.seller_product?.mockupImages?.map(
                                            (mockup: any, index: number) => {
                                              return (
                                                <img
                                                  onLoad={() => {}}
                                                  className="w-24 aspect-square object-contain object-center rounded-lg"
                                                  src={`${API_URL}${mockup}`}
                                                  alt="Mockup"
                                                />
                                              );
                                            }
                                          );
                                        }
                                      )}
                                    </div>
                                  ) : (
                                    <div className="w-32">
                                      {order?.seller_order_items?.map(
                                        (item: any) => {
                                          return item?.designFiles?.map(
                                            (design: any, index: number) => {
                                              return (
                                                <>
                                                  <img
                                                    onLoad={() => {}}
                                                    className="w-[200px] aspect-square object-contain object-center rounded-lg"
                                                    src={`${API_URL}${design}`}
                                                    alt="Design"
                                                  />
                                                  <a
                                                    download
                                                    target="_blank"
                                                    className="bg-purpleDark py-1 px-2 block text-center text-sm text-white mt-1"
                                                    href={`${API_URL}${design}`}
                                                    rel="noreferrer"
                                                  >
                                                    İndir
                                                  </a>
                                                </>
                                              );
                                            }
                                          );
                                        }
                                      )}
                                    </div>
                                  )}

                                  {order?.seller_order_items[0]?.designFiles
                                    ?.length < 1 ? (
                                    <div className="flex justify-between space-x-1 items-center">
                                      {order?.seller_order_items?.map(
                                        (item: any) => {
                                          return item?.seller_product?.designImages?.map(
                                            (design: any, index: number) => {
                                              return (
                                                <a
                                                  download
                                                  target="_blank"
                                                  className="bg-purpleDark w-full rounded-lg py-1 px-2 block text-center text-xs text-white mt-1"
                                                  href={`${API_URL}${design}`}
                                                  rel="noreferrer"
                                                >
                                                  İndir
                                                </a>
                                              );
                                            }
                                          );
                                        }
                                      )}
                                    </div>
                                  ) : (
                                    <div className="flex w-full justify-between">
                                      {order?.seller_order_items?.map(
                                        (item: any) => {
                                          return item?.designFiles?.map(
                                            (design: any, index: number) => {
                                              return (
                                                <a
                                                  download
                                                  target="_blank"
                                                  className="bg-purpleDark py-1 px-2 block text-center text-sm text-white mt-1"
                                                  href={`${API_URL}${design}`}
                                                  rel="noreferrer"
                                                >
                                                  İndir
                                                </a>
                                              );
                                            }
                                          );
                                        }
                                      )}
                                    </div>
                                  )}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <p>Sipariş bulunamadı</p>
                        )}
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default SupplierOrdersTable;
