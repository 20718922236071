import React, { useEffect, useState } from 'react';
import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import stripeService from '../services/stripeService';
import alertNotification from '../utils/alertNotification';

import Loader from '../components/loader/Loader';

const Return = () => {
  const [status, setStatus] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const params = useParams();
  const [query] = useSearchParams();
  const completeCheckout = async (
    userId: number,
    packagePlanId: number,
    sessionId: string
  ) => {
    setLoading(true);
    try {
      const res = await stripeService.completeSubscription(
        userId,
        packagePlanId,
        sessionId
      );
      setStatus(res.status);
      console.log(res);
      navigate(
        `/thanks?coupon=${
          res.data.couponCode ? res.data.couponCode : ''
        }&basePrice=${res.data.basePrice}&discountedPrice=${
          res.data.discountedPrice
        }&itemId=${res.data.packageId}&itemName=${
          res.data.packageName
        }&isAnnual=${res.data.isAnnual}`
      );
    } catch (error: any) {
      alertNotification(
        'error',
        'Ödeme esnasında bir hata oluştu. Lütfen tekrar deneyiniz'
      );
      setStatus('error');
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const sessionId = query.get('session_id');

    completeCheckout(
      Number(params?.userId),
      Number(params?.packagePlanId),
      sessionId!
    );
  }, [params?.packagePlanId, query, params?.userId]);

  if (status === 'error') {
    return <Navigate to={`/payment/${params.packagePlanId}`} />;
  }

  return (
    <section
      id="success"
      className="h-screen w-full flex flex-col justify-center items-center"
    >
      {loading && (
        <>
          <Loader />
          <h1 className="text-primary font-medium mt-8 text-xl">
            Ödeme gerçekleştiriliyor...
          </h1>
        </>
      )}
    </section>
  );
};
export default Return;
