import { Formik } from 'formik';
import { Google } from 'iconsax-react';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import * as Yup from 'yup';

import { LoginDataInterface, useAuth } from '../../context/AuthContext';
import { useLanguage } from '../../context/LanguageContext';
import shipentegraService from '../../services/shipentegraService';
import alertNotification from '../../utils/alertNotification';
import { errorElement } from '../../utils/errorElement';
import { getGoogleUrl } from '../../utils/getGoogleUrl';

export default function LoginForm() {
  const { login } = useAuth();
  const { t } = useLanguage();
  const location = useLocation();
  let from = ((location.state as any)?.from?.pathname as string) || '/';

  return (
    <>
      <div className="flex min-h-screen flex-col justify-center py-12 sm:px-6 lg:px-8 bg-lightGray">
        <ToastContainer />
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-7 w-auto"
            src="/assets/logos/rexven-logo.svg"
            alt="Your Company"
          />
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
            {t?.('login.header')}
          </h2>
        </div>
        <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-md p-4">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <div className="mb-3 flex justify-end">
              {/* TODO:if you decide to use translation just comment out these lines */}
              {/* <div className="w-28">
                <LanguageSelect />
              </div> */}
            </div>
            <Formik
              initialValues={{
                email: '',
                password: '',
              }}
              onSubmit={(values: LoginDataInterface, actions) => {
                login?.(values, location.state?.from);
                actions.setSubmitting(false);
              }}
              validateOnChange={false}
              validationSchema={Yup.object({
                email: Yup.string()
                  .email('lütfen geçerli bir email adresi giriniz')
                  .required('Bu alan boş bırakılamaz'),
                password: Yup.string().required('Bu alan boş bırakılamaz'),
              })}
            >
              {({ handleSubmit, values, handleChange, errors }) => (
                <form onSubmit={handleSubmit} className="space-y-6">
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t?.('login.email')}
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="email"
                        placeholder={t?.('login.placeholders.email')}
                        value={values.email}
                        onChange={handleChange}
                        autoComplete="email"
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-secondary focus:outline-none focus:ring-secondary sm:text-sm"
                      />
                      {errors.email && errorElement(errors.email)}
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t?.('login.password')}
                    </label>
                    <div className="mt-1">
                      <input
                        id="password"
                        name="password"
                        placeholder={t?.('login.placeholders.password')}
                        type="password"
                        value={values.password}
                        onChange={handleChange}
                        autoComplete="current-password"
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-secondary focus:outline-none focus:ring-secondary sm:text-sm"
                      />
                      {errors.password && errorElement(errors.password)}
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <input
                        id="remember-me"
                        name="remember-me"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-secondary focus:ring-secondary"
                      />
                      <label
                        htmlFor="remember-me"
                        className="ml-2 block text-sm text-gray-900"
                      >
                        {t?.('login.remember-me')}
                      </label>
                    </div>

                    <div className="text-sm">
                      <Link
                        to="/forget-password"
                        className="font-medium text-secondary hover:text-secondary"
                      >
                        {t?.('login.forget-password')}
                      </Link>
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="flex w-full justify-center rounded-md border border-transparent bg-secondary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2"
                    >
                      {t?.('common.buttons.login')}
                    </button>
                  </div>
                </form>
              )}
            </Formik>
            <a
              href={getGoogleUrl(from)}
              className="flex justify-center items-center w-full py-2 px-4 text-sm font-medium border border-transparent text-white shadow-sm bg-googleRed rounded-md mt-2 hover:shadow-md"
            >
              <Google size="20" className="text-white mr-2" variant="Bold" />{' '}
              Google ile Giriş Yap
            </a>
            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="bg-white px-2 text-gray-500">
                    {t?.('login.not-member')}
                  </span>
                </div>
              </div>
              <div className="mt-6">
                <Link
                  className="flex w-full justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2"
                  to={'/register'}
                >
                  {' '}
                  {t?.('common.buttons.register')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
