import React, { useEffect, useRef, useState } from 'react';

interface QuestionProps {
    handleForward: (question: string, answer: string | string[]) => void
    handleBackward: (question: string, answer: string | string[]) => void
}
const Question5: React.FC<QuestionProps> = ({
    handleForward,
    handleBackward
}) => {
    const [inputEnabled, setInputEnabled] = useState(false)
    const [otherInputValue, setOtherInputValue] = useState('');
    const [answers, setAnswers] = useState(() => {
        const storedAnswers = JSON.parse(
            sessionStorage.getItem('answer5') || '[]'
        );
        return storedAnswers.length > 0 ? storedAnswers : [''];
    });
    const inputRef = useRef<HTMLInputElement>(null);

    const handleInputChange = () => {
        const inputValue = inputRef.current?.value || '';
        setOtherInputValue(inputValue);
    };

    const handleCheckboxChange = (answer: string) => {
        const updatedAnswers = [...answers];
        const answerIndex = updatedAnswers.indexOf(answer);

        if (answerIndex > -1) {
            updatedAnswers.splice(answerIndex, 1);
        } else {
            updatedAnswers.push(answer);
        }

        setAnswers(updatedAnswers);
    };

    const handleForwardClick = () => {
        const updatedAnswers = [...answers];
        if (inputEnabled && otherInputValue) {
            updatedAnswers.push(otherInputValue);
        }
        setAnswers(updatedAnswers);
        sessionStorage.setItem('otherInputValue5', otherInputValue);
        handleForward('question5', updatedAnswers);
    };

    const handleBackwardClick = () => {
        const updatedAnswers = [...answers];
        if (inputEnabled && otherInputValue) {
            updatedAnswers.push(otherInputValue);
        }
        handleBackward('question5', updatedAnswers);
    };

    useEffect(() => {
        sessionStorage.setItem('answer5', JSON.stringify(answers));
    }, [answers]);

    useEffect(() => {
        const storedOtherInputValue = sessionStorage.getItem('otherInputValue5') || '';
        setOtherInputValue(storedOtherInputValue);
        setInputEnabled(!!storedOtherInputValue);

        const isOtherInputChecked = !!storedOtherInputValue;
        const otherInputCheckbox = document.getElementById('other') as HTMLInputElement;
        if (otherInputCheckbox) {
            otherInputCheckbox.checked = isOtherInputChecked;
        }
    }, []);


    const isAnswerChecked = (answer: string) => answers.includes(answer);

    const notificationMethods = [
        { id: '1', title: 'Eğitimler' },
        { id: '2', title: 'Soru-cevap desteği' },
        { id: '3', title: 'Network ve yardımlaşma' },
        { id: '4', title: 'Tedarikçiler' },
        { id: '5', title: 'Hizmet sağlayıcı indirimleri' },
        { id: '6', title: 'Araçlar (Etsy, Trendyol ve Amazon)' },
    ]
    return (
        <>
            <div>
                <div className="flex flex-col space-y-4 min-h-[400px] justify-between">
                    <div className='mt-2'>
                        <label className="text-base font-semibold text-gray-900">Rexven’e Katılmanızı Etkileyen
                            En Önemli Özellik Neydi ?</label>
                        <fieldset className="mt-4">
                            <legend className="sr-only">Notification method</legend>
                            <div className="space-y-4">
                                {notificationMethods.map((notificationMethod) => (
                                    <div key={notificationMethod.id} className="flex items-center">
                                        <input
                                            id={notificationMethod.id}
                                            name="notification-method"
                                            type="checkbox"
                                            checked={isAnswerChecked(notificationMethod.title)}
                                            className="h-4 w-4 border-gray-300 text-cardBorder focus:ring-cardBorder"
                                            onChange={() => handleCheckboxChange(notificationMethod.title)}
                                        />
                                        <label
                                            htmlFor={notificationMethod.id}
                                            className="ml-3 block text-sm font-small leading-6 text-gray-900"
                                        >
                                            {notificationMethod.title}
                                        </label>
                                    </div>
                                ))}
                                <div className="flex items-center">
                                    <input
                                        id="other"
                                        name="notification-method"
                                        defaultChecked={inputEnabled}
                                        type="checkbox"
                                        className="h-4 w-4 border-gray-300 text-cardBorder focus:ring-cardBorder"
                                        onChange={() => {
                                            inputRef.current!.value = '';
                                            setInputEnabled(!inputEnabled);
                                        }}
                                    />
                                    <input
                                        ref={inputRef}
                                        value={inputEnabled ? otherInputValue : ''}
                                        onChange={handleInputChange}
                                        type="text"
                                        name="otherInput"
                                        id="otherInput"
                                        placeholder="Diğer"
                                        className={`block ml-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 ${!inputEnabled && 'bg-gray-100 cursor-not-allowed'}`}
                                        disabled={!inputEnabled}
                                    />
                                </div>
                            </div>
                        </fieldset>
                    </div>
                    <div className="flex items-center text-[16px] bg justify-between mt-2">
                        <div
                            onClick={handleBackwardClick}
                            className=" text-center text-[13px] px-3 h-[50px] max-h-[50px] font-bold  lg:py-1  w-[49%] flex items-center w-full cursor-pointer">Geri</div>                        <div
                                onClick={handleForwardClick}
                                className="hover:cursor-pointer text-center text-[12px] bg-headerPrimary font-bold text-white h-[50px] max-h-[50px]  px-1 py-1  border-2 rounded-lg border-headerPrimary w-[49%] flex items-center justify-center"
                            >
                            İleri
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Question5