import { Icon, Logout } from 'iconsax-react';
import { Link, useLocation } from 'react-router-dom';

import { Menu } from '@headlessui/react';
import {
    ArchiveBoxIcon, ArrowRightCircleIcon, ChevronDownIcon, DocumentDuplicateIcon, HeartIcon,
    PencilSquareIcon, TrashIcon, UserPlusIcon
} from '@heroicons/react/20/solid';

import { IMAGE_URL } from '../config';
import { useAuth } from '../context/AuthContext';
import { useLanguage } from '../context/LanguageContext';
import { classNames } from '../utils/conditionalClasses';
import { Navs } from './Header';

interface PropsState {
    profileNav: Navs[];
}
const HeaderDropDown: React.FC<PropsState> = ({
    profileNav
}) => {
    const { session } = useAuth()
    const { t } = useLanguage();
    const { logout } = useAuth();
    const location = useLocation();
    const logoutSec = {
        name: t?.('sideBar.logout'),
        href: '/logout',
        icon: Logout,
        current: false,
    };
    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button className="flex items-center justify-end">
                    {/* Options
                    <ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5 text-gray-400" /> */}
                    <img
                        className="h-8 w-8 rounded-full"
                        src={
                            session?.profileImage
                                ? `${IMAGE_URL}${session?.profileImage}`
                                : `https://api.dicebear.com/5.x/bottts/svg?seed=${session?.firstName + ' ' + session?.lastName
                                }`
                        }
                        alt=""
                    />
                    <div>
                        <p className="hidden text-sm sm:text-lg ml-3 md:block">
                            {session?.firstName} {session?.lastName}
                        </p>
                        {/* <p className="hidden text-xs sm:text-md ml-3 md:block">
                            {t?.('layout.level')} {session?.level}
                        </p> */}
                    </div>
                </Menu.Button>
            </div>

            <Menu.Items
                as='div'
                className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
            >
                <div className="py-1">
                    {profileNav.map((nav) => {
                        return (
                            <Menu.Item key={nav.href}>
                                <Link
                                    to={nav.href}
                                    target={nav.target}
                                    className="group flex items-center px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                                >
                                    <nav.icon aria-hidden="true" className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" />
                                    {nav.name}
                                </Link>
                            </Menu.Item>
                        )
                    })}
                </div>
                <div className="py-1">
                    <Menu.Item>
                        <button
                            key={logoutSec.name}
                            onClick={logout}
                            className="group flex items-center px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                        >
                            <logoutSec.icon
                                aria-hidden="true" className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                            />

                            {logoutSec.name}
                        </button>
                    </Menu.Item>
                </div>
            </Menu.Items>
        </Menu>
    )
}
export default HeaderDropDown