import React, { CSSProperties } from 'react';
import { BarLoader } from 'react-spinners';

interface Props {
  loading: boolean;
}
const override: CSSProperties = {
  display: 'block',
  margin: '0 auto',
  borderColor: 'red',
};
const PaymentLoading: React.FC<Props> = ({ loading }) => {
  return <BarLoader color="#1F254B" cssOverride={override} />;
};

export default PaymentLoading;
