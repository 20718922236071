import React from 'react';
import { Faq } from '../../pages/Faqs';
import FaqItem from './FaqItem';

export interface Props {
  faqs: Faq[];
}
const FaqWrapper: React.FC<Props> = ({ faqs }) => {
  return (
    <div
      data-testid="fqWrapper"
      className="mx-auto w-full p-x-0 mt-6 pb-3 lg:px-4 "
    >
      <div className="mx-auto w-full grid grid-cols-6 gap-y-4 xl:gap-x-10">
        {/* <dl className="mt-10 space-y-4 divide-y divide-gray-900/10 grid grid-cols-6 gap-y-4 xl:gap-x-10"> */}
        {faqs?.map((faq) => (
          <FaqItem key={faq?.id} faq={faq} />
        ))}
        {/* </dl> */}
      </div>
    </div>
  );
};

export default FaqWrapper;
