import React, { useEffect, useRef, useState } from 'react';
import { useLanguage } from '../../context/LanguageContext';
import API_URL from '../../config';

const EntegrationVideo = () => {
  const { t } = useLanguage();
  // this ref is defined here to trigger the video element src change
  const videoRef = useRef<HTMLVideoElement>(null);

  const [video, setVideo] = useState('')
  const descriptionArray = [
    'Trendyol mağaza hesabınıza gidin',
    'Sağ üst tarafta mağaza isminize tıklayın',
    'Hesap bilgilerim sekmesini açın',
    'Hesap bilgileri sayfanızda sağ üstten entegrasyon bilgileri sekmesini açın',
    'Açılans sekmeden "Satıcı ID, "API key", "API Secret" verilerini kopyalayın',
    'Bu verileri Rexven paneline yapıştırın',
  ];
  useEffect(() => {
    const isIphone = navigator.userAgent.match(/iPhone/i) !== null;
    if (isIphone) {
      const video = videoRef.current!;
      video.src = `${API_URL}${"courses/shared/entegrasyon.mp4"}`;
      videoRef.current?.play();
      setVideo(`${API_URL}${"courses/shared/entegrasyon.mp4"}`);
    }
  }, [video]);


  return (
    <div
      className="bg-white rounded-2xl px-6 py-10 pb-24 mb-12"
      data-testid="entegration-video"
    >
      <h1 className="font-bold text-headingPrimary text-2xl mb-4">
        {t?.('integration.page.video-title')}
      </h1>
      <div className="grid gap-8 xl:gap-10 grid-cols-5">
        <div className="col-span-5 lg:col-span-3 ">
          <video
            className="rounded-xl w-full h-auto"
            autoPlay
            muted
            playsInline
            ref={videoRef}
            controls
            controlsList="nodownload"
          >
            <source
              src={video || `${API_URL}${"courses/shared/entegrasyon.mp4"}`}
              type="video/mp4"
            />
          </video>
        </div>
        <div className="col-span-5 lg:col-span-2">
          <ol className="list-decimal ml-2">
            {descriptionArray.map((content, index) => (
              <li
                key={index}
                className="text-description font-semibold my-2 xl:my-6"
              >
                {content}
              </li>
            ))}
          </ol>
        </div>
      </div>
    </div>
  );
};

export default EntegrationVideo;
