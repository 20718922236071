import React from 'react';
import { useLanguage } from '../../context/LanguageContext';
import { InfoCircle } from 'iconsax-react';
import { Tooltip } from 'react-tooltip';

interface Props {
  data: any;
}
// let temp = {
//   generalInfo: '',
//   bestCarrier: 'shipentegra-worldwide-standard',
//   bestCarrierServiceType: 'ECO',
//   uniqueCode: 'PR085',
//   prices: [
//     {
//       serviceType: 'ECO',
//       clearServiceName: 'ShipEntegra Worldwide Standard',
//       serviceName: 'shipentegra-worldwide-standard',
//       cargoPrice: 9.95,
//       fuelCost: 0.5,
//       pricing: '17-template-17 PR041 0.01',
//       fuelMultiplier: 5,
//       additionalDescription: 'Tahmini Teslim Süresi 8-12 iş günü<br>',
//       totalPrice: 10.45,
//       tooltip: '5% yakıt çarpanı uygulanmıştır',
//       discount: 0,
//     },
//     {
//       serviceType: 'EXPRESS',
//       clearServiceName: 'ShipEntegra Express',
//       serviceName: 'Shipentegra',
//       cargoPrice: 13.04,
//       fuelCost: 1.63,
//       pricing: 'shipentegra-template-21 PR021',
//       fuelMultiplier: 12.5,
//       additionalDescription:
//         'Tahmini Teslim Süresi 2-7 iş günü<br>Geri iade navlun ücreti: 29.34 $<br>',
//       totalPrice: 14.67,
//       tooltip: '12.5% yakıt çarpanı uygulanmıştır',
//       discount: 0,
//     },
//     {
//       serviceType: 'EXPRESS',
//       clearServiceName: 'ShipEntegra Peak Express',
//       serviceName: 'shipentegra-peak-express',
//       cargoPrice: 12.61,
//       fuelCost: 2.19,
//       pricing: 'peak-express PR041',
//       fuelMultiplier: 17.4,
//       additionalDescription: 'Geri iade ücretsizdir.',
//       totalPrice: 14.8,
//       tooltip: '17.4% yakıt çarpanı uygulanmıştır',
//       discount: 0.25,
//     },
//   ],
// };
const renderDescription = (desc: string) => {
  return { __html: desc };
};
const ShippingOffersTable: React.FC<Props> = ({ data }) => {
  const { language } = useLanguage();
  const tableHeader =
    language === 'tr'
      ? [
          { name: 'Servis', column: 'clearServiceName' },
          { name: 'Fiyat', column: 'cargoPrice' },
          { name: 'Yakıt Farkı', column: 'fuelCost' },
          { name: 'İndirim', column: 'stock' },
          { name: 'Toplam Fiyat', column: 'totalPrice' },
          { name: 'Açıklama', column: 'additionalDescription' },
        ]
      : [
          { name: 'Service', column: 'clearServiceName' },
          { name: 'Price', column: 'cargoPrice' },
          { name: 'Fuel Cost', column: 'fuelCost' },
          { name: 'Discount', column: 'stock' },
          { name: 'Total Price', column: 'totalPrice' },
          { name: 'Description', column: 'additionalDescription' },
        ];

  return (
    <div className="flex flex-col flex-shrink-0 bg-white rounded-xl shadow-lg mb-8">
      {/* <div className="flow-root"> */}
      <div className="relative overflow-x-auto">
        <div className="inline-block min-w-full align-middle">
          <div className="border-none rounded-lg overflow-hidden">
            <table className="min-w-full table-auto ">
              <thead className="bg-headerPrimary">
                {tableHeader.map((item) => {
                  return (
                    <th
                      key={item.name}
                      className="py-6 pl-4 pr-3 text-center text-sm font-semibold text-white"
                      scope="col"
                    >
                      <p className="text-xs md:text-sm">{item.name}</p>
                    </th>
                  );
                })}
              </thead>

              <tbody className="divide-y divide-gray-200 bg-white">
                {data?.map((offer: any, index: number) => {
                  return (
                    <tr key={offer.serviceName} className="even:bg-[#EDEEF8]">
                      <td className="px-4 py-4 text-xs md:text-sm text-center text-gray-500">
                        <div className=" flex justify-center items-center space-x-2 h-full">
                          <p className="w-56">{offer.clearServiceName} </p>
                          {offer.clearServiceName.includes('Peak') && (
                            <div className="flex justify-center items-center rounded-full bg-[#7DD3FC] p-1 px-2">
                              <p className="text-center text-xs text-[#0369A1]">
                                En Hızlı
                              </p>
                            </div>
                          )}
                          {offer.serviceType === 'ECO' && (
                            <div className="flex justify-center items-center rounded-full bg-[#6EE7B7] p-1 px-2">
                              <p className="text-center text-xs text-[#047857] w-20">
                                En Uygun
                              </p>
                            </div>
                          )}
                        </div>
                      </td>
                      <td className="px-4 py-4 text-xs md:text-sm text-center text-gray-500 ">
                        $ {offer.cargoPrice}
                      </td>
                      <td className="px-4 py-4 text-xs md:text-sm text-center text-gray-500 ">
                        <div className="flex justify-center items-center space-x-2">
                          <p>$ {offer.fuelCost}</p>
                          <InfoCircle
                            size={20}
                            color="#FDBA74"
                            id={`priceToolTip${index}`}
                          />
                          {language === 'tr' && (
                            <Tooltip
                              anchorSelect={`#priceToolTip${index}`}
                              content={offer.tooltip}
                            />
                          )}
                        </div>
                      </td>
                      <td className="px-4 py-4 text-xs md:text-sm text-center text-gray-500 ">
                        $ {offer.discount}
                      </td>
                      <td className="px-4 py-4 text-xs md:text-sm text-center text-gray-500 ">
                        $ {offer.totalPrice}
                      </td>
                      <td className="px-4 py-4 text-xs md:text-sm text-center text-gray-500 ">
                        <div className="flex justify-center items-center w-full">
                          <p
                            className="w-56"
                            dangerouslySetInnerHTML={renderDescription(
                              offer.additionalDescription
                            )}
                          ></p>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default ShippingOffersTable;
