import React from 'react';

interface Props {
  percent: number;
}
const ProggressBar: React.FC<Props> = ({ percent }) => {
  return (
    <div className="w-full bg-[#FCE9E8] rounded-full h-2.5">
      <div
        className="bg-rexRed h-2.5 rounded-full"
        style={{ width: `${percent ? percent : 0}%` }}
      ></div>
    </div>
  );
};

export default ProggressBar;
