import axios from 'axios';
import React, { Fragment } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

import API_URL from '../../config';
import alertNotification from '../../utils/alertNotification';
import DropImage from '../tools/common/DropImage';

interface Props {
  open: boolean;
  setOpen: (open: boolean) => void;
}

const ProfileImageModal: React.FC<Props> = ({ open, setOpen }) => {
  const [image, setImage] = React.useState<any>();
  //CREATE HTTP CLIENT
  let http = axios.create({
    headers: {
      'Content-type': 'multipart/form-data',
    },
    baseURL: API_URL,
    withCredentials: true,
  });
  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const payload = new FormData();
    payload.append('image', image);
    try {
      await http.patch('/api/v1/members/photo', payload);
      alertNotification('success', 'Başırılı bir şekilde güncellendi');
    } catch (error: any) {
      alertNotification('error', error.response.data);
    } finally {
      setOpen(false);
    }
  };
  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                  <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div>
                    <div className="mt-3 text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-base font-semibold leading-6 text-gray-900"
                      >
                        Profil Resmi Güncelleme
                      </Dialog.Title>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6">
                    <div className="flex justify-center space-x-4">
                      <DropImage setImage={setImage} />
                      {image && (
                        <img
                          src={image?.preview}
                          className="w-24 h-24 rounded-md object-cover object-center"
                          onLoad={() => {
                            URL.revokeObjectURL(image.preview);
                          }}
                          alt="preview"
                        />
                      )}
                    </div>
                    <button
                      type="button"
                      className="inline-flex w-full mt-4 justify-center rounded-md bg-headerPrimary px-3 py-2 text-sm font-semibold text-white shadow-sm "
                      onClick={handleSubmit}
                    >
                      Onayla
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default ProfileImageModal;
