/* eslint-disable jsx-a11y/no-redundant-roles */

import React, { useState } from 'react';
import LevelCard from './LevelCard';
import LevelConfirmModal from '../shared/LevelConfirmModal';
import { useLanguage } from '../../context/LanguageContext';
import { useAuth } from '../../context/AuthContext';

export default function LevelCardWrapper() {
  const { t } = useLanguage();
  const { session } = useAuth();

  let steps = [
    { id: 1, name: t?.('roadmap.level-card.first'), href: '#' },
    {
      id: 2,
      name: t?.('roadmap.level-card.second'),
      href: '#',
    },
    { id: 3, name: t?.('roadmap.level-card.third'), href: '#' },
    { id: 4, name: t?.('roadmap.level-card.fourth'), href: '#' },
    { id: 5, name: t?.('roadmap.level-card.fifth'), href: '#' },
  ];
  const [open, setOpen] = useState<boolean>(false);

  return (
    <nav className="w-100 mb-10 shadow-lg lg:rounded-xl" aria-label="Progress">
      <ol
        role="list"
        className="overflow-hidden rounded-md  lg:flex  lg:justify-between lg:items-center lg:bg-lightGray "
      >
        {steps.map((step, stepIdx) => (
          <LevelCard
            key={step.id}
            step={step}
            stepIdx={stepIdx}
            steps={steps}
            level={session?.level}
            setOpen={setOpen}
          />
        ))}
      </ol>
      <LevelConfirmModal open={open} setOpen={setOpen} />
    </nav>
  );
}
