import React, { useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useFormik } from 'formik';
import marketplaceService from '../../services/marketplaceService';
import alertNotification from '../../utils/alertNotification';
import { Edit2 } from 'iconsax-react';
import { DocumentPlusIcon } from '@heroicons/react/20/solid';
import API_URL from '../../config';

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedOrder: any;
  setSelectedOrder: React.Dispatch<React.SetStateAction<any>>;
  setIsDesignModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  getOrders: () => void;
  setIsUpdate: React.Dispatch<React.SetStateAction<boolean>>;
  setIsCustom: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedProductId: React.Dispatch<any>;
}
const UpdateOrderForm: React.FC<Props> = ({
  isModalOpen,
  setIsModalOpen,
  selectedOrder,
  setSelectedOrder,
  getOrders,
  setIsDesignModalOpen,
  setIsCustom,
  setIsUpdate,
  setSelectedProductId,
}) => {
  const dynamicInitialValues =
    selectedOrder.attributes.length > 0 &&
    selectedOrder.attributes.reduce((acc: any, attr: any) => {
      Object.keys(attr).forEach((key) => {
        acc[key] = attr[key];
      });
      return acc;
    }, {});

  const updateSellerOrder = async (payload: any) => {
    try {
      await marketplaceService.updateSellerOrder(payload);
      getOrders();
    } catch (error: any) {
      alertNotification('error', error.response.data.message);
    }
  };
  console.log({ selectedOrder });
  const formik = useFormik({
    initialValues: {
      isGift: selectedOrder?.isGift,
      // isGiftWrap: selectedOrder?.isGiftWrap,
      note: selectedOrder?.buyerMessage,
      giftMessage: selectedOrder?.giftMessage,
      personalization: selectedOrder?.personalization,
      ...dynamicInitialValues,
    },
    onSubmit: (values) => {
      const {
        isGift,
        // isGiftWrap,
        note,
        giftMessage,
        personalization,
        ...rest
      } = values;
      const payload = {
        attributes: [{ ...rest }],
        orderId: Number(selectedOrder.id),
        isGift: isGift ? true : false,
        // isGiftWrap: isGiftWrap ? true : false,
        note: note ? note : '',
        giftMessage: giftMessage ? giftMessage : '',
        personalization: personalization ? personalization : '',
      };

      updateSellerOrder(payload);
      setIsModalOpen(false);
    },
  });

  return (
    <Transition show={isModalOpen} as={React.Fragment}>
      <Dialog
        as="div"
        className="relative z-10 "
        onClose={() => {
          setSelectedOrder(null);
          setIsModalOpen(false);
        }}
      >
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-xl transform overflow-auto rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg mb-8 font-medium text-center leading-6 text-gray-900"
                >
                  Sipariş Güncelle
                </Dialog.Title>
                <div className="mt-2 flex w-full">
                  <form
                    className="flex flex-col justify-center items-start space-y-1 w-full"
                    onSubmit={formik.handleSubmit}
                  >
                    {selectedOrder.attributes &&
                      selectedOrder.attributes.map((attr: any, index: number) =>
                        Object.keys(attr).map((key) => (
                          <div
                            key={`${key}-${index}`}
                            className="flex flex-col justify-center items-start space-y-1 w-full mb-2"
                          >
                            <label className="text-sm text-headerPrimary font-medium">
                              {key}
                            </label>
                            <input
                              type="text"
                              name={key}
                              className="w-full rounded-lg border border-gray-300 p-2.5 text-xs text-gray-900 focus:ring-blue-500 focus:border-blue-500"
                              value={formik.values[key]}
                              onChange={formik.handleChange}
                            />
                          </div>
                        ))
                      )}
                    <div className="flex flex-col justify-center items-start space-y-1 w-full">
                      <label
                        className="text-sm text-headerPrimary font-medium"
                        htmlFor="personalization"
                      >
                        Kişiselleştirme
                      </label>
                      <input
                        value={formik.values.personalization}
                        onChange={formik.handleChange}
                        className="rounded-lg border border-gray-300 p-2.5 focus:outline-none text-xs placeholder:text-xs w-full"
                        name="personalization"
                        id="personalization"
                        type="text"
                      />
                    </div>
                    <div className="flex justify-center items-start space-x-2 py-2">
                      <input
                        type="checkbox"
                        name="isGift"
                        checked={formik.values.isGift}
                        id="isGift"
                        value={formik.values.isGift}
                        onChange={formik.handleChange}
                        className="rounded-md"
                      />
                      <label
                        className="text-sm text-headerPrimary font-medium"
                        htmlFor="isGift"
                      >
                        Hediye mi ?
                      </label>
                    </div>
                    {/* <div className="flex justify-center items-start space-x-2 py-2">
                      <input
                        onChange={formik.handleChange}
                        checked={formik.values.isGiftWrap}
                        value={formik.values.isGiftWrap}
                        type="checkbox"
                        name="isGiftWrap"
                        id="isGiftWrap"
                        className="rounded-md"
                      />
                      <label
                        className="text-sm text-headerPrimary font-medium"
                        htmlFor="isGiftWrapped"
                      >
                        Hediye paketi olacak mı ?
                      </label>
                    </div> */}
                    <div className="flex flex-col justify-center items-start space-y-1 w-full">
                      <label
                        className="text-sm text-headerPrimary font-medium"
                        htmlFor="giftMessage"
                      >
                        Hediye Mesajı
                      </label>
                      <textarea
                        value={formik.values.giftMessage}
                        onChange={formik.handleChange}
                        className="border-none rounded-lg bg-lightGray focus:outline-none text-xs placeholder:text-xs w-full"
                        name="giftMessage"
                        id="giftMessage"
                        rows={5}
                        placeholder="Hediye mesajınızı ekleyin..."
                      />
                    </div>
                    <div className="flex flex-col justify-center items-start space-y-1 w-full">
                      <label
                        className="text-sm text-headerPrimary font-medium"
                        htmlFor="note"
                      >
                        Sipariş Notu
                      </label>
                      <textarea
                        value={formik.values.note}
                        onChange={formik.handleChange}
                        className="border-none rounded-lg bg-lightGray focus:outline-none text-xs placeholder:text-xs w-full"
                        name="note"
                        id="note"
                        rows={5}
                        placeholder="Müşteri notunuzu ekleyin..."
                      />
                    </div>
                    <div className="mt-4 w-full flex justify-end py-2">
                      <button
                        type="submit"
                        className="inline-flex justify-center rounded-md border border-transparent bg-[#2563EB] px-4 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      >
                        Güncelle
                      </button>
                    </div>
                  </form>
                </div>
                {selectedOrder.needsDesign && (
                  <>
                    <div>
                      {selectedOrder?.designFiles &&
                        selectedOrder?.designFiles.map((file: any) => (
                          <img
                            key={file}
                            className="w-32 aspect-square object-contain object-center rounded-lg"
                            src={`${API_URL}${file}`}
                            alt="Mockup"
                          />
                        ))}
                    </div>
                    {/* <div className="flex space-x-2">
                      <button
                        className="bg-[#FB923C] rounded-full w-full mx-auto py-1 px-2 flex justify-center items-center space-x-1 mt-2"
                        onClick={() => {
                          setSelectedProductId(selectedOrder.id);
                          setIsDesignModalOpen(true);
                          setIsUpdate(true);
                          setIsCustom(true);
                        }}
                      >
                        <Edit2 color="white" size={18} />
                        <p className="text-white text-sm">Düzenle</p>
                      </button>
                      <button
                        className="bg-[#FB923C] rounded-full w-full mx-auto py-1 px-2 flex justify-center items-center space-x-1 mt-2"
                        onClick={() => {
                          setSelectedProductId(selectedOrder.id);
                          setIsDesignModalOpen(true);
                          setIsUpdate(false);
                          setIsCustom(true);
                        }}
                      >
                        <DocumentPlusIcon color="white" width={24} />
                        <p className="text-white text-sm">Ekle</p>
                      </button>
                    </div> */}
                  </>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default UpdateOrderForm;
