import React from 'react';
import ToolsAbsoluteBuyBox from '../common/ToolsAbsoluteBuyBox';
import { useAuth } from '../../context/AuthContext';
import LoadingComponent from '../common/LoadingComponent';
import { classNames } from '../../utils/conditionalClasses';
import { convertDate } from '../../utils/dateFormatter';
import { seperateNumberWithComma } from '../../utils/numberFormatter';
import { useQuery } from '@tanstack/react-query';
import {
  ICategoryRoot,
  ICountryRoot,
  IEtsyShopProduct,
  IProductTypeRoot,
} from '../../interfaces/etsyToolsInterfaces';
import etsyToolsService from '../../services/etsyToolsService';
import ToolSearchBar from '../common/ToolSearchBar';
import SearchField from './SearchField';
import { useLanguage } from '../../context/LanguageContext';

interface StoreProductsTableProps {
  isLoading: boolean;
  data: IEtsyShopProduct[];
  setQuery: React.Dispatch<React.SetStateAction<string>>;
}
const StoreProductsTable: React.FC<StoreProductsTableProps> = ({
  isLoading,
  data,
  setQuery,
}) => {
  const { session } = useAuth();
  const { language } = useLanguage();

  const tableHeader =
    language === 'tr'
      ? [
          { name: 'Sıra', column: 'id' },
          { name: 'Ürün', column: 'imgUrl' },
          { name: 'Ürün Başlığı', column: 'title' },
          { name: 'Ürün Yaşı', column: 'originalCreationTimestamp' },
          { name: 'Kategori', column: 'etsyTaxonomyId' },
          { name: 'Ürün Tipi', column: 'etsyProductTypeId' },
          { name: 'Ürün Fiyatı', column: 'price' },
          { name: 'Günlük Satış', column: 'dailySale' },
          { name: 'Son 30 Gün Satış', column: 'last30DaysSale' },
          { name: 'Son 30 Gün Ciro', column: 'last30DaysRevenue' },
          { name: 'Toplam Satış', column: 'totalSale' },
          { name: 'Yorum', column: 'totalReviewCount' },
          {
            name: 'Son 30 Gün Yorum',
            column: 'lastMonthReviewCount',
          },
          { name: 'Ürün Görüntülenme', column: 'view' },
          { name: 'Ürün Favori', column: 'numFavorers' },
          { name: 'Etiketler', column: 'tags' },
        ]
      : [
          { name: 'No', column: 'id' },
          { name: 'Product', column: 'imgUrl' },
          { name: 'Title', column: 'title' },
          { name: 'Product Age', column: 'originalCreationTimestamp' },
          { name: 'Category', column: 'etsyTaxonomyId' },
          { name: 'Product Type', column: 'etsyProductTypeId' },
          { name: 'Price', column: 'price' },
          { name: 'Daily Sale', column: 'dailySale' },
          { name: 'Last 30 Days Sales', column: 'last30DaysSale' },
          { name: 'Last 30 Days Revenue', column: 'last30DaysRevenue' },
          { name: 'Total Sale', column: 'totalSale' },
          { name: 'Review', column: 'totalReviewCount' },
          {
            name: 'Last 30 Days Review',
            column: 'lastMonthReviewCount',
          },
          { name: 'View', column: 'view' },
          { name: 'Favorite', column: 'numFavorers' },
          { name: 'Tags', column: 'tags' },
        ];
  const categories = useQuery<ICategoryRoot, Error>(['etsyCategories'], {
    queryFn: () => etsyToolsService.getCategories(),
  });

  //GET PRODUCT TYPES
  const productTypes = useQuery<IProductTypeRoot, Error>(['etsyProductTypes'], {
    queryFn: () => etsyToolsService.getProductTypes(),
  });

  function renderTags(tags: any) {
    if (tags.length > 0) {
      return tags.map((tag: any, index: any) => {
        if (index % 5 === 0 && index !== 0) {
          return (
            <React.Fragment key={index}>
              <br />
              <span>{tag.toLowerCase()}, </span>
            </React.Fragment>
          );
        } else {
          return (
            <span
              key={index}
              style={{ color: index % 2 === 0 ? '#FF9E66' : '' }}
            >
              {tag.toLowerCase()},
            </span>
          );
        }
      });
    } else {
      return '-';
    }
  }
  let content;

  if (isLoading) {
    content = <LoadingComponent />;
  } else if (isLoading === false && data?.length > 0) {
    content = (
      <div className="flow-root">
        <div className="relative -mx-4 -my-2 overflow-x-auto overflow-y-auto h-[60vh] scrollbar-hide">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300 table-fixed">
              <thead>
                <tr>
                  {tableHeader.map((item, index) => {
                    return (
                      <th
                        key={index}
                        className="sticky top-0 z-10 border-b border-gray-300 text-center bg-lightGray py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter "
                        // scope="col"
                      >
                        {' '}
                        <p className="text-xs md:text-sm">{item.name}</p>
                      </th>
                    );
                  })}
                </tr>
              </thead>

              {isLoading ? (
                <div className="w-full flex justify-center">
                  <LoadingComponent />
                </div>
              ) : (
                <tbody className="divide-y divide-gray-200 bg-white">
                  {data?.map((prod: any, idx) => {
                    return (
                      <tr
                        key={idx}
                        className={classNames(
                          !session?.hasPackage && idx > 2
                            ? 'blur-sm'
                            : 'blur-none',
                          'even:bg-lightGray'
                        )}
                      >
                        <td className="py-4 pl-4 text-xs md:text-sm sm:pl-0">
                          <p className="text-center text-gray">{idx + 1}</p>
                        </td>
                        <td className="py-4 pl-4 text-xs md:text-sm font-medium text-center text-gray-900 sm:pl-0">
                          <a
                            href={`https://www.etsy.com/listing/${prod.id}`}
                            target="_blank"
                          >
                            <img
                              className="h-10 object-contain w-full"
                              src={prod.imgUrl}
                            />
                          </a>
                        </td>
                        <td className="py-4 text-xs text-center text-gray-500">
                          <a
                            href={`https://www.etsy.com/listing/${prod.id}`}
                            target="_blank"
                            className="text-center"
                          >
                            {prod.title.substring(0, 40)}..
                          </a>
                        </td>

                        <td className="whitespace-nowrap text-center px-3 py-4 text-xs  text-gray-500">
                          {convertDate(
                            prod?.originalCreationTimestamp,
                            language
                          )}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs  text-gray-500 ">
                          {prod?.etsyTaxonomyId &&
                          Number(
                            categories?.data?.data.find(
                              (item: { id: any }) =>
                                item.id === prod.etsyTaxonomyId
                            )?.taxonomyName.length
                          ) > 0
                            ? categories?.data?.data.find(
                                (item: { id: any }) =>
                                  item.id === prod.etsyTaxonomyId
                              )?.taxonomyName
                            : '-'}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          {prod.etsyProductTypeId &&
                          Number(
                            productTypes?.data?.data.find(
                              (item: { id: any }) =>
                                item.id === prod.etsyProductTypeId
                            )?.type.length
                          ) > 0
                            ? productTypes?.data?.data.find(
                                (item: { id: any }) =>
                                  item.id === prod.etsyProductTypeId
                              )?.type
                            : '-'}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          ${Number(prod.price).toFixed(2)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          {prod.dailySale
                            ? Number(prod.dailySale).toFixed(0)
                            : '-'}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm min-w-52 text-gray-500">
                          <p className="px-6">
                            {prod.last30DaysSale
                              ? seperateNumberWithComma(prod.last30DaysSale)
                              : '-'}
                          </p>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm w-52 text-gray-500 ">
                          {prod.last30DaysRevenue
                            ? `$ ${seperateNumberWithComma(
                                prod.last30DaysRevenue
                              )}`
                            : '-'}
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500">
                          {prod.totalSale
                            ? seperateNumberWithComma(prod.totalSale)
                            : '-'}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          {prod.totalReviewCount
                            ? seperateNumberWithComma(
                                Number(prod.totalReviewCount)
                              )
                            : '-'}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center min-w-[100px] text-xs md:text-sm text-gray-500">
                          {prod.lastMonthReviewCount
                            ? seperateNumberWithComma(prod.lastMonthReviewCount)
                            : '-'}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500">
                          {seperateNumberWithComma(prod.view)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500">
                          {seperateNumberWithComma(prod.numFavorers)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500">
                          {/* {prod.tags[0]} */}
                          {renderTags(prod.tags)}
                        </td>
                      </tr>
                    );
                  })}
                  {!session?.hasPackage &&
                    Array.from({ length: 97 }).map((_, idx) => (
                      <tr
                        key={idx}
                        className={classNames('blur-sm even:bg-lightGray')}
                      >
                        <td className="py-4 pl-4 text-xs md:text-sm sm:pl-0 w-16">
                          <p className="text-center text-gray">{idx + 4}</p>
                        </td>
                        <td className="py-4 pl-4 text-xs md:text-sm font-medium text-center text-gray-900 sm:pl-0 w-16">
                          <img
                            className="h-10 object-contain w-full"
                            src={`https://picsum.photos/${idx}`}
                          />
                        </td>
                        <td className="px-4 py-4 text-xs md:text-sm text-center text-gray-500  ">
                          Heollaaae
                        </td>
                        <td className="whitespace-nowrap text-center px-3 py-4 text-xs md:text-sm text-gray-500 ">
                          Tıasdoanskdo
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs  text-gray-500 ">
                          Jewelry
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          Fiziksel
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          167253178
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          12313
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          2323
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          4434343
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          333444
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          333444
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          333444
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          333444
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500">
                          {renderTags([
                            'Bridesmaid',
                            'Hangers',
                            'Personalized',
                            'Hanger',
                            'Wedding',
                            'Bridesmaid',
                            'Hangers',
                            'Personalized',
                            'Hanger',
                            'Wedding',
                            'Bridesmaid',
                            'Hangers',
                            'Personalized',
                            'Hanger',
                            'Wedding',
                          ])}
                        </td>
                      </tr>
                    ))}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
    );
  } else if (data.length === 0 && isLoading === false) {
    content = (
      <div>
        <div className="flex flex-col justify-center items-center p-10">
          <h2 className="text-rexRed text-lg font-bold">Oops!</h2>
          <h2 className="text-base font-bold p-4">
            {language === 'tr'
              ? 'Aradığınız kriterlere uygun mağaza bulunamadı.'
              : 'No store was found matching your search criteria.'}
          </h2>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col flex-shrink-0 bg-white rounded-xl p-5 shadow-lg sm:p-4 relative">
      <SearchField
        placeholder="https://www.etsy.com/shop/CaitlynMinimalist"
        setProductLink={setQuery}
      />
      {!session?.hasPackage && <ToolsAbsoluteBuyBox />}
      {content}
    </div>
  );
};

export default StoreProductsTable;

// {isLoading ? (
//   <LoadingComponent />
// ) : data.length > 0 && isLoading === false ? (
//   <div className="flow-root">
//     <div className="relative -mx-4 -my-2 overflow-x-auto overflow-y-auto h-screen scrollbar-hide">
//       <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
//         <table className="min-w-full divide-y divide-gray-300 table-fixed">
//           <thead>
//             <tr>
//               {tableHeader.map((item, index) => {
//                 return (
//                   <th
//                     key={index}
//                     className="sticky top-0 z-10 border-b border-gray-300 text-center w-52 bg-lightGray py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter "
//                     scope="col"
//                   >
//                     {' '}
//                     <p className="text-xs md:text-sm">{item.name}</p>
//                   </th>
//                 );
//               })}
//             </tr>
//           </thead>

//           {isLoading ? (
//             <div className="w-full flex justify-center">
//               <LoadingComponent />
//             </div>
//           ) : (
//             <tbody className="divide-y divide-gray-200 bg-white">
//               {data?.map((prod: any, idx) => {
//                 return (
//                   <tr
//                     key={idx}
//                     className={classNames(
//                       !session?.hasPackage && idx > 2
//                         ? 'blur-sm'
//                         : 'blur-none',
//                       'even:bg-lightGray'
//                     )}
//                   >
//                     <td className="py-4 pl-4 text-xs md:text-sm sm:pl-0 w-16">
//                       <p className="text-center text-gray">{idx + 1}</p>
//                     </td>
//                     <td className="py-4 pl-4 text-xs md:text-sm font-medium text-center text-gray-900 sm:pl-0 w-16">
//                       <a
//                         href={`https://www.etsy.com/listing/${prod.id}`}
//                         target="_blank"
//                       >
//                         <img
//                           className="h-10 object-contain w-full"
//                           src={prod.imgUrl}
//                         />
//                       </a>
//                     </td>
//                     <td className="px-4 py-4 text-xs text-center text-gray-500  ">
//                       <a
//                         href={`https://www.etsy.com/listing/${prod.id}`}
//                         target="_blank"
//                       >
//                         {prod.title.substring(0, 60)}
//                       </a>
//                     </td>

//                     <td className="whitespace-nowrap text-center px-3 py-4 text-xs  text-gray-500 ">
//                       {convertDate(prod?.originalCreationTimestamp)}
//                     </td>
//                     <td className="whitespace-nowrap px-3 py-4 text-center text-xs  text-gray-500 ">
//                       {Number(
//                         categories?.data?.data.find(
//                           (item: { id: any }) =>
//                             item.id === prod.etsyTaxonomyId
//                         )?.taxonomyName.length
//                       ) > 0
//                         ? categories?.data?.data.find(
//                             (item: { id: any }) =>
//                               item.id === prod.etsyTaxonomyId
//                           )?.taxonomyName
//                         : '-'}
//                     </td>
//                     <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
//                       {Number(
//                         productTypes?.data?.data.find(
//                           (item: { id: any }) =>
//                             item.id === prod.etsyProductTypeId
//                         )?.type.length
//                       ) > 0
//                         ? productTypes?.data?.data.find(
//                             (item: { id: any }) =>
//                               item.id === prod.etsyProductTypeId
//                           )?.type
//                         : '-'}
//                     </td>
//                     <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
//                       ${Number(prod.price).toFixed(2)}
//                     </td>
//                     <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
//                       {prod.last30DaysSale
//                         ? seperateNumberWithComma(prod.last30DaysSale)
//                         : '-'}
//                     </td>
//                     <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
//                       {prod.last30DaysRevenue
//                         ? `$ ${seperateNumberWithComma(
//                             prod.last30DaysRevenue
//                           )}`
//                         : '-'}
//                     </td>

//                     <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500">
//                       {prod.totalSale
//                         ? seperateNumberWithComma(prod.totalSale)
//                         : '-'}
//                     </td>
//                     <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
//                       {seperateNumberWithComma(
//                         Number(prod.totalReviewCount)
//                       )}
//                     </td>
//                     <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500">
//                       {seperateNumberWithComma(prod.lastMonthReviewCount)}
//                     </td>
//                     <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500">
//                       {seperateNumberWithComma(prod.view)}
//                     </td>
//                     <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500">
//                       {seperateNumberWithComma(prod.numFavorers)}
//                     </td>
//                     <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500">
//                       {/* {prod.tags[0]} */}
//                       {renderTags(prod.tags)}
//                     </td>
//                   </tr>
//                 );
//               })}
//               {!session?.hasPackage &&
//                 Array.from({ length: 97 }).map((_, idx) => (
//                   <tr
//                     key={idx}
//                     className={classNames('blur-sm even:bg-lightGray')}
//                   >
//                     <td className="py-4 pl-4 text-xs md:text-sm sm:pl-0 w-16">
//                       <p className="text-center text-gray">{idx + 4}</p>
//                     </td>
//                     <td className="py-4 pl-4 text-xs md:text-sm font-medium text-center text-gray-900 sm:pl-0 w-16">
//                       <img
//                         className="h-10 object-contain w-full"
//                         src={`https://picsum.photos/${idx}`}
//                       />
//                     </td>
//                     <td className="px-4 py-4 text-xs md:text-sm text-center text-gray-500  ">
//                       Heollaaae
//                     </td>
//                     <td className="whitespace-nowrap text-center px-3 py-4 text-xs md:text-sm text-gray-500 ">
//                       Tıasdoanskdo
//                     </td>
//                     <td className="whitespace-nowrap px-3 py-4 text-center text-xs  text-gray-500 ">
//                       Jewelry
//                     </td>
//                     <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
//                       Fiziksel
//                     </td>
//                     <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
//                       167253178
//                     </td>
//                     <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
//                       12313
//                     </td>
//                     <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
//                       2323
//                     </td>

//                     <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
//                       4434343
//                     </td>

//                     <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
//                       333444
//                     </td>
//                     <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
//                       333444
//                     </td>
//                     <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
//                       333444
//                     </td>
//                     <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
//                       333444
//                     </td>

//                     <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500">
//                       {renderTags([
//                         'Bridesmaid',
//                         'Hangers',
//                         'Personalized',
//                         'Hanger',
//                         'Wedding',
//                         'Bridesmaid',
//                         'Hangers',
//                         'Personalized',
//                         'Hanger',
//                         'Wedding',
//                         'Bridesmaid',
//                         'Hangers',
//                         'Personalized',
//                         'Hanger',
//                         'Wedding',
//                       ])}
//                     </td>
//                   </tr>
//                 ))}
//             </tbody>
//           )}
//         </table>
//       </div>
//     </div>
//   </div>
// ) : (
//   <div>
//     <div className="flex flex-col justify-center items-center p-10">
//       <h2 className="text-rexRed text-lg font-bold">Oops!</h2>
//       <h2 className="text-base font-bold p-4">
//         Görünüşe göre analiz etmek için seçtiğiniz mağazanın ürünleri
//         arasında 1000 yorumu geçen ürünü bulunmuyor.
//       </h2>
//     </div>
//   </div>
// )}
