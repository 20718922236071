import React from 'react';
import { Link } from 'react-router-dom';

const NeedtoBeIntegrated = () => {
  return (
    <div className="rounded-lg bg-white shadow-md flex flex-col justify-center items-center p-10">
      <h2 className="text-rexRed text-lg font-bold">
        Oops! Henüz entegre değilsiniz!
      </h2>
      <h2 className="text-base font-bold p-4">
        Ürünlerinizi görüntülemek için mağazanızı entegre etmeniz gerekmektedir.
        Aşağıdaki butona tıklayarak entegrasyon sayfasına gidebilirsiniz.
      </h2>
      <Link
        className="py-2 px-8 bg-headerPrimary text-white rounded-md"
        to={'/integration'}
      >
        Entegrasyon'a Git
      </Link>
    </div>
  );
};

export default NeedtoBeIntegrated;
