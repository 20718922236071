import React from 'react';
// import Pagination from '../../../common/Pagination';
// import { classNames } from '../../../../utils/conditionalClasses';
// import LoadingComponent from '../../../common/LoadingComponent';
import { Activity, Diagram } from 'iconsax-react';
import { seperateNumberWithComma } from '../../../../utils/numberFormatter';
import { IKeywordObject } from '../../../../interfaces/amazonToolsInterfaces';
import LoadingTable from './LoadingTable';
import { convertDate } from '../../../../utils/dateFormatter';

const tableHeader = [
  { name: 'Anahtar Kelime', id: Math.round(Math.random() * 10000) },
  { name: 'Aranma Hacmi', id: Math.round(Math.random() * 10000) },
  { name: 'Top. Satış Hacmi', id: Math.round(Math.random() * 10000) },
  { name: 'Ort. Aylık Ciro', id: Math.round(Math.random() * 10000) },
  { name: 'Ort. Aylık Satış', id: Math.round(Math.random() * 10000) },
  { name: 'Rakip Sayısı', id: Math.round(Math.random() * 10000) },
  { name: 'Top. Satış Sayısı', id: Math.round(Math.random() * 10000) },
  { name: 'Ort. Satış Fiyatı', id: Math.round(Math.random() * 10000) },
  { name: 'Benzer Kelimeler', id: Math.round(Math.random() * 10000) },
];

const tempData = [
  {
    keyword: 'Dune',
    searchVolume: 33300000,
    salesVolume: 1965,
    avgMonthlyRevenue: 2133333,
    avgMonthlySales: 30000,
    rivalCount: 12,
    relatedKeywords: 'science fiction, space opera',
    isFavorite: true,
    topThreeClicks: 0.4,
    topThreeCpc: 25,
    avgReview: 3000,
    salesReview: 200,
    totalSales: 1200000,
    avgProductAge: 3,
    avgPrice: 40,
    giveaway: 120,
  },
  {
    keyword: 'adidas',
    searchVolume: 40000,
    salesVolume: 1951,
    avgMonthlyRevenue: 1200020,
    avgMonthlySales: 12000,
    rivalCount: 10,
    relatedKeywords: 'science fiction, space opera',
    isFavorite: true,
    topThreeClicks: 0.4,
    topThreeCpc: 25,
    avgReview: 3000,
    salesReview: 200,
    totalSales: 1200000,
    avgProductAge: 3,
    avgPrice: 40,
    giveaway: 120,
  },
  {
    keyword: "Ender's Game",
    searchVolume: 500000,
    salesVolume: 1985,
    avgMonthlyRevenue: 3000000,
    avgMonthlySales: 10000,
    rivalCount: 3,
    relatedKeywords: 'science fiction, military science fiction',
    isFavorite: true,
    topThreeClicks: 0.4,
    topThreeCpc: 25,
    avgReview: 3000,
    salesReview: 200,
    totalSales: 1200000,
    avgProductAge: 3,
    avgPrice: 40,
    giveaway: 120,
  },
  {
    keyword: 'nike shoes',
    searchVolume: 5677777777,
    salesVolume: 90000,
    avgMonthlyRevenue: 400000,
    avgMonthlySales: 400,
    rivalCount: 4,
    relatedKeywords: 'science fiction, dystopian',
    isFavorite: true,
    topThreeClicks: 0.4,
    topThreeCpc: 25,
    avgReview: 3000,
    salesReview: 200,
    totalSales: 1200000,
    avgProductAge: 3,
    avgPrice: 40,
    giveaway: 120,
  },
  {
    keyword: 'Brave New World',
    searchVolume: 800000,
    salesVolume: 4999999,
    avgMonthlyRevenue: 122000,
    avgMonthlySales: 1230,
    rivalCount: 5,
    relatedKeywords: 'science fiction, dystopian',
    isFavorite: false,
    topThreeClicks: 0.4,
    topThreeCpc: 25,
    avgReview: 3000,
    salesReview: 200,
    totalSales: 1200000,
    avgProductAge: 3,
    avgPrice: 40,
    giveaway: 120,
  },
  {
    keyword: "The Hitchhiker's Guide to the Galaxy",
    searchVolume: 500055,
    salesVolume: 4000,
    avgMonthlyRevenue: 909990,
    avgMonthlySales: 3000,
    rivalCount: 10,
    relatedKeywords: 'science fiction, comedy',
    isFavorite: true,
    topThreeClicks: 0.4,
    topThreeCpc: 25,
    avgReview: 3000,
    salesReview: 200,
    totalSales: 1200000,
    avgProductAge: 3,
    avgPrice: 40,
    giveaway: 120,
  },
  {
    keyword: 'Neuromancer',
    searchVolume: 60000,
    salesVolume: 3000,
    avgMonthlyRevenue: 3000,
    avgMonthlySales: 3000,
    rivalCount: 10,
    relatedKeywords: 'science fiction, cyberpunk',
    isFavorite: false,
    topThreeClicks: 0.4,
    topThreeCpc: 25,
    avgReview: 3000,
    salesReview: 200,
    totalSales: 1200000,
    avgProductAge: 3,
    avgPrice: 40,
    giveaway: 120,
  },
];

interface Props {
  setGiveaway: React.Dispatch<React.SetStateAction<boolean>>;
  setSearchVolume: React.Dispatch<React.SetStateAction<boolean>>;
  setRelated: React.Dispatch<React.SetStateAction<boolean>>;
  data: IKeywordObject[];
  isLoading: boolean;
  setKeyword: React.Dispatch<React.SetStateAction<string>>;
}
const KeywordTable: React.FC<Props> = ({
  setGiveaway,
  setSearchVolume,
  setRelated,
  data,
  isLoading,
  setKeyword,
}) => {
  //REACT QUERY API REQUEST

  return (
    <div className=" flex flex-col flex-shrink-0 bg-white rounded-xl shadow-lg mt-8  overflow-y-auto overflow-x-auto scrollbar-hide">
      <div className="flex flex-col">
        <div className="relative min-w-[1024px] align-middle h-[60vh]">
          {/* HEAD */}
          <div className="sticky top-0 bg-headerPrimary text-white rounded-t-xl py-6 z-10">
            <div className="grid grid-cols-9 ">
              {tableHeader.map((item) => {
                return (
                  <div className="col-span-1 flex justify-center" key={item.id}>
                    <p className="text-xs md:text-sm">{item.name}</p>
                  </div>
                );
              })}
            </div>
          </div>
          <div>
            {isLoading ? (
              <LoadingTable />
            ) : (
              data?.map((item) => {
                return (
                  <div
                    className="grid grid-cols-9 w-full justify-around items-center text-primary even:bg-lightGray"
                    key={item.term}
                  >
                    <div className="col-span-1 border-r-2 border-lightGray h-full flex items-center justify-center px-2">
                      <p className="text-xs md:text-sm text-center text-[#2563EB] font-medium">
                        {item.term}
                      </p>
                    </div>
                    <div className="col-span-8 flex flex-col py-3">
                      <div className="w-full flex">
                        <div className="w-full flex justify-center items-center">
                          <p className="text-xs md:text-sm text-center mr-2">
                            {seperateNumberWithComma(item.volume)}
                          </p>
                          <Diagram
                            onClick={() => {
                              setSearchVolume(true);
                              setKeyword(item.term);
                            }}
                            className="h-5 w-5 cursor-pointer"
                            color="#2563EB"
                          />
                        </div>
                        <div className="w-full flex justify-center items-center">
                          <p className="text-xs md:text-sm text-center">
                            ${seperateNumberWithComma(item.saleVolume)}
                          </p>
                        </div>
                        <div className="w-full flex justify-center items-center">
                          <p className="text-xs md:text-sm text-center">
                            ${seperateNumberWithComma(item.monthlyRevenue)}
                          </p>
                        </div>
                        <div className="w-full flex justify-center items-center">
                          <p className="text-xs md:text-sm text-center">
                            {seperateNumberWithComma(item.monthlySale)}
                          </p>
                        </div>
                        <div className="w-full flex justify-center items-center">
                          <p className="text-xs md:text-sm text-center">
                            {item.rivalCount}
                          </p>
                        </div>

                        <div className="w-full flex justify-center items-center">
                          <p className="text-xs md:text-sm text-center">
                            {seperateNumberWithComma(item.totalSale!)}
                          </p>
                          {/* <Heart
                          onClick={() => {
                            console.log('beğendim');
                          }}
                          color="#E3211E"
                          className="cursor-pointer"
                          variant={item.isFavorite ? 'Bold' : 'Linear'}
                        /> */}
                        </div>
                        <div className="w-full flex justify-center items-center">
                          <p className="text-xs md:text-sm text-center">
                            ${item.price}
                          </p>
                        </div>
                        <div className="w-full flex justify-center">
                          <button
                            className="text-xs font-medium border-2 border-headerPrimary text-headerPrimary text-center rounded-lg px-4 py-2"
                            onClick={() => {
                              setRelated(true);
                              setKeyword(item.term);
                            }}
                          >
                            İncele
                          </button>
                        </div>
                      </div>
                      {/* INFO SECTION */}
                      <div className="w-full grid grid-cols-8 justify-start items-center py-2">
                        <div className="col-span-6">
                          <div className="grid grid-cols-6">
                            <div className="w-full col-span-1 flex items-center justify-center">
                              <p className="text-xs  font-thin text-center">
                                Top 3 Ürün Tıklama: {item.click}
                              </p>
                            </div>
                            <div className="w-full col-span-1 flex items-center justify-center">
                              <p className="text-xs font-thin text-center">
                                Top 3 Ürün Dönüşüm: {item.conversion}
                              </p>
                            </div>
                            <div className="w-full col-span-1 flex items-center justify-center">
                              <p className="text-xs font-thin text-center">
                                Ort. Yorum Sayısı: {item.reviewCount}
                              </p>
                            </div>

                            <div className="w-full col-span-1 flex items-center justify-center">
                              <p className="text-xs font-thin text-center ">
                                Satış / Yorum: {item.saleReviewRate}
                              </p>
                            </div>
                            <div className="w-full col-span-1 flex flex-col items-center justify-center">
                              <p className="text-xs font-thin text-center">
                                Ort. Ürünlerin Yaşı:{' '}
                              </p>
                              <p className="text-xs font-thin text-center">
                                {item.productAge === null
                                  ? '-'
                                  : convertDate(new Date(item.productAge))}
                              </p>
                            </div>
                            <div className="w-full col-span-1">
                              <div className="flex justify-start items-center space-x-2">
                                <p className="text-xs pl-8 font-thin text-center">
                                  Giveaway: {item.giveawayCount}
                                </p>
                                <Activity
                                  onClick={() => {
                                    setGiveaway(true);
                                    setKeyword(item.term);
                                  }}
                                  color="#2563EB"
                                  className="cursor-pointer"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default KeywordTable;
