import React from 'react';
import ReactApexChart from 'react-apexcharts';

interface PieChartProps {
  series: number[];
  options: any;
}

const PieChart: React.FC<PieChartProps> = ({ series, options }) => {
  // console.log(series);
  return (
    <div id="chart" className="py-4">
      <ReactApexChart
        width={'100%'}
        height={'270px'}
        options={options}
        series={series}
        type="donut"
      />
    </div>
  );
};

export default PieChart;
