import React from 'react';
import { Link } from 'react-router-dom';

import { useLanguage } from '../../../context/LanguageContext';

const ProducersCard = () => {
  const { language } = useLanguage();
  const cards = [
    '/images/dashboardItem1.svg',
    '/images/dashboardItem2.svg',
    '/images/dashboardItem3.svg',
  ];
  return (
    <div className="bg-white px-2 pt-4 pb-6 shadow-xl rounded-md col-span-2 md:col-span-1 ">
      <p className="px-4 font-semibold text-headerPrimary hover:font-bold">
        <Link to="/manufacturers-and-wholesalers" className="cursor-pointer">
          {language === 'tr'
            ? 'Tedarikçiler'
            : 'Manufacturers Wholesalers'}
        </Link>
      </p>
      <div className="flex flex-row gap-4 justify-between items-center p-2 h-full">
        {cards.map((card) => {
          return (
            <Link
              className="transition delay-150 hover:scale-110 flex items-center justify-center"
              to="/manufacturers-and-wholesalers"
            >
              <img className=" w-32 h-full" src={card} alt="Rexven Logo" />
            </Link>
          );
        })}
        {/* <Link className='col-span-3 sm:col-span-1 flex flex-col gap-2 justify-center items-center transition delay-150 hover:scale-110' to='/manufacturers-and-wholesalers'>
                    <div className='bg-white pt-6 w-[70%] lg:w-[85%] px-2 h-64 shadow-xl rounded-xl flex flex-col items-center justify-center'>
                        <img
                            className="w-24"
                            src="/images/dashboardGuitar.svg"
                            alt="Rexven Logo"
                        />
                        <div className='flex flex-col items-start w-[70%] mt-2'>
                            <div className="rounded-full border-[5px] w-full  border-[#6F7AC3] my-1"></div>
                            <div className='flex flex-row items-center w-[50%]'>
                                <div className="rounded-full border-[5px]  border-[#6F7AC3] mr-1"></div>
                                <div className="border-y-[2px] rounded-md w-full  border-[#6F7AC3]"></div>
                            </div>
                            <div className='flex flex-row items-center w-full justify-between gap-1'>
                                <div className="mt-1 rounded-md bg-[#DBDEF0] lg:px-3 py-2 px-6 2xl:px-4 shadow-sm"></div>
                                <div className="mt-1 rounded-md bg-headerPrimary lg:px-3 py-2 px-6 2xl:px-4 shadow-sm"></div>
                            </div>
                        </div>
                    </div>
                </Link>
                <Link className='col-span-3 sm:col-span-1 flex flex-col justify-center items-center transition delay-150 hover:scale-110' to='/manufacturers-and-wholesalers'>
                    <div className='bg-white pt-6 w-[70%] lg:w-[85%] px-2 h-64 shadow-xl rounded-xl flex flex-col items-center justify-center'>
                        <img
                            className="w-24"
                            src="/images/dashboardShoes.svg"
                            alt="Rexven Logo"
                        />
                        <div className='flex flex-col items-start w-[70%] mt-2'>
                            <div className="rounded-full border-[5px] w-full  border-[#6F7AC3] my-1"></div>
                            <div className='flex flex-row items-center w-[50%]'>
                                <div className="rounded-full border-[5px]  border-[#6F7AC3] mr-1"></div>
                                <div className="border-y-[2px] rounded-md w-full  border-[#6F7AC3]"></div>
                            </div>
                            <div className='flex flex-row items-center w-full justify-between gap-1'>
                                <div className="mt-1 rounded-md bg-[#DBDEF0] lg:px-3 py-2 px-6 2xl:px-4 shadow-sm"></div>
                                <div className="mt-1 rounded-md bg-headerPrimary lg:px-3 py-2 px-6 2xl:px-4 shadow-sm"></div>
                            </div>
                        </div>
                    </div>
                </Link>
                <Link className='col-span-3 sm:col-span-1 flex flex-col justify-center items-center transition delay-150 hover:scale-110' to='/manufacturers-and-wholesalers'>
                    <div className='bg-white pt-6 w-[70%] lg:w-[85%] px-2 h-64 shadow-xl rounded-xl flex flex-col items-center justify-center'>
                        <img
                            className="w-24"
                            src="/images/dashboardWatch.svg"
                            alt="Rexven Logo"
                        />
                        <div className='flex flex-col items-start w-[70%] mt-2'>
                            <div className="rounded-full border-[5px] w-full  border-[#6F7AC3] my-1"></div>
                            <div className='flex flex-row items-center w-[50%]'>
                                <div className="rounded-full border-[5px]  border-[#6F7AC3] mr-1"></div>
                                <div className="border-y-[2px] rounded-md w-full  border-[#6F7AC3]"></div>
                            </div>
                            <div className='flex flex-row items-center w-full justify-between gap-1'>
                                <div className="mt-1 rounded-md bg-[#DBDEF0] lg:px-3 py-2 px-6 2xl:px-4 shadow-sm"></div>
                                <div className="mt-1 rounded-md bg-headerPrimary lg:px-3 py-2 px-6 2xl:px-4 shadow-sm"></div>
                            </div>
                        </div>
                    </div>
                </Link> */}
      </div>
    </div>
  );
};

export default ProducersCard;
