import React from 'react'
import { CheckIcon } from '@heroicons/react/20/solid'
import { useNumberVerification } from '../../../context/NumberVerificationContext'
import { useAuth } from '../../../context/AuthContext'


const SurveySuccessModal = () => {
    const { setOpenSurvey } = useNumberVerification()
    const { checkSessionStatus } = useAuth()
    return (
        <>
            <div className="mx-auto flex w-full max-w-md flex-col space-y-4">
                <div className="flex flex-col items-center justify-center text-center space-y-2">
                    <img src="/images/surveySuccess.webp" alt="number_verify" className='h-36 w-36' />
                    <div className="font-semibold text-3xl my-4">
                        <p className="my-4">TEŞEKKÜRLER</p>
                    </div>
                    <div className="flex flex-row text-sm font-medium text-gray-400">
                        <p>Ankete katıldığınız için teşekkür ederiz.</p>
                    </div>
                    <CheckIcon className='h-12 w-12 text-greenMid' />
                    <div onClick={() => {
                        setOpenSurvey?.(false)
                        checkSessionStatus?.()
                    }} className="flex flex-row items-center justify-center text-center w-[49%] border rounded-xl outline-none py-2 border-headerPrimary text-headerPrimary text-md font-md shadow-sm cursor-pointer">
                        Kapat
                    </div>
                </div>
            </div>
        </>

    )
}

export default SurveySuccessModal