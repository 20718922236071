import { useFormik } from 'formik';
import { Edit, I3DCubeScan, InfoCircle, Location } from 'iconsax-react';
import { Dispatch, SetStateAction } from 'react';
import { Tooltip } from 'react-tooltip';
import * as Yup from 'yup';

import { Switch } from '@headlessui/react';

import { useLanguage } from '../../context/LanguageContext';
import { USState } from '../../interfaces/shipEntegraInterfaces';
import { classNames } from '../../utils/conditionalClasses';
import { errorElement } from '../../utils/errorElement';
import AddressPatch from './elements/AddressPatch';
import ShipEntegraCustomSelect from './elements/ShipEntegraCustomSelect';
import ProductTransactions from './ProductTransactions';

interface NewPostAddressProps {
    states: USState[]
    selectedState: USState
    setSelectedState: Dispatch<SetStateAction<USState>>
    countries: never[]
    selectedCountry: {
        id: number;
        name: string;
        error: boolean
    }
    setSelectedCountry: Dispatch<SetStateAction<{
        id: number;
        name: string;
        error: boolean
    }>>
    selectedCurrencies: {
        name: string;
        value: string;
        error: boolean
    }
    setSelectedCurrencies: Dispatch<SetStateAction<{
        name: string;
        value: string;
        error: boolean
    }>>
    products: {
        id: number;
        quantity: number | null;
        unitPrice: number | null;
        name: string | null;
        sku: string | null;
        gtip: string | null;
        quantityErr: boolean, // required
        unitPriceErr: boolean, // required
        nameErr: boolean,
    }[],
    setProducts: Dispatch<SetStateAction<{
        id: number;
        quantity: number | null;
        unitPrice: number | null;
        name: string | null;
        sku: string | null;
        gtip: string | null;
        quantityErr: boolean, // required
        unitPriceErr: boolean, // required
        nameErr: boolean,
    }[]>>
    description: {
        text: string,
        error: boolean
    }
    setDescription: Dispatch<SetStateAction<{
        text: string,
        error: boolean
    }>>
    count: number
    setCount: Dispatch<SetStateAction<number>>
    loading: boolean
    enabled: boolean
    setEnabled: Dispatch<SetStateAction<boolean>>
    onSubmit: (values: any) => void
}
const NewPostAddress: React.FC<NewPostAddressProps> = ({
    states,
    selectedState,
    setSelectedState,
    countries,
    selectedCountry,
    setSelectedCountry,
    selectedCurrencies,
    setSelectedCurrencies,
    products,
    setProducts,
    description,
    setDescription,
    count,
    setCount,
    loading,
    enabled,
    setEnabled,
    onSubmit
}) => {
    const { t } = useLanguage();
    // const [enabled2, setEnabled2] = useState(false)

    const handleIncrement = () => {
        setCount(count + 1);
    };

    const handleDecrement = () => {
        if (count > 1) {
            setCount(count - 1);
        }
    };
    const formik = useFormik({
        initialValues: {
            name: '',
            address: '',
            city: '',
            country: '',
            state: '', // Varsayılan değeri belirtmek gerekir
            postalCode: '',
            phone: '',
            email: '',
            reference1: '',
            weight: '',
            width: '',
            length: '',
            height: ''
        },
        onSubmit: onSubmit,
        validationSchema: Yup.object().shape({
            name: Yup.string().required(t?.('create-shipment.shipment-address.error')),
            address: Yup.string().required(t?.('create-shipment.shipment-address.error')),
            state: selectedCountry.name === 'US' && selectedState.name === '' ? Yup.string().required(t?.('create-shipment.shipment-address.error')) : Yup.string(),
            city: Yup.string().required(t?.('create-shipment.shipment-address.error')),
            postalCode: Yup.string().required(t?.('create-shipment.shipment-address.error')),
        }),
        validateOnChange: false,
    });
    return (
        <form
            onSubmit={formik.handleSubmit}
        >
            {/* NOTE: adress section */}
            <>
                <AddressPatch title={t?.('create-shipment.patchs.shipment-address')} icon={Location} />
                <div className='px-4'>
                    <div className='grid grid-cols-2 gap-4 grid-row items-end justify-start mt-4'>
                        <div className='col-span-2 md:col-span-1'>
                            <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                {t?.('create-shipment.shipment-address.name')}
                                <span className='text-rexRed'>*</span>
                            </label>
                            <input
                                type="text"
                                name="name"
                                id="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                className="block w-full rounded-lg border-sm border-cardBorder px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-cardBorder placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cardBorder sm:text-sm sm:leading-6"
                                placeholder={t?.('create-shipment.shipment-address.name')}
                            />
                            {formik.errors.name && errorElement(formik.errors.name)}
                        </div>
                        <div className='col-span-2 md:col-span-1'>
                            <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                                {t?.('create-shipment.shipment-address.address')}
                                <span className='text-rexRed'>*</span>
                            </label>
                            <input
                                type="text"
                                name="address"
                                id="address"
                                value={formik.values.address}
                                onChange={formik.handleChange}
                                className="block w-full rounded-lg border-sm border-cardBorder px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-cardBorder placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cardBorder sm:text-sm sm:leading-6"
                                placeholder={t?.('create-shipment.shipment-address.address')}
                            />
                            {formik.errors.address && errorElement(formik.errors.address)}
                        </div>
                    </div>
                    <div className='grid grid-cols-2 gap-4 grid-row items-end justify-start mt-4'>
                        <div className='col-span-2 md:col-span-1'>
                            <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                                {t?.('create-shipment.shipment-address.country')}
                                <span className='text-rexRed'>*</span>
                            </label>
                            {countries && countries.length > 0 && (
                                <>
                                    <ShipEntegraCustomSelect
                                        id="country"
                                        options={countries}
                                        onSelect={(selectedCountry: { countryId: number, countryCode: string }) => {
                                            setSelectedCountry({
                                                id: selectedCountry.countryId,
                                                name: selectedCountry.countryCode,
                                                error: false
                                            })
                                        }}
                                    />
                                    {selectedCountry.error === true && errorElement('Alıcı Ülke Girilmesi Zorunludur.')}
                                </>
                            )}
                        </div>
                        <div className='col-span-2 md:col-span-1'>
                            <label htmlFor="state" className="block text-sm font-medium text-gray-700">
                                {t?.('create-shipment.shipment-address.state')}
                                {selectedCountry.name === 'US' && <span className='text-rexRed'>*</span>}
                            </label>
                            {selectedCountry.name === 'US' ?
                                <>
                                    <ShipEntegraCustomSelect
                                        id="state"
                                        options={states ? states : []}
                                        onSelect={(selectedState: any) => {
                                            setSelectedState(selectedState)
                                        }}
                                    />
                                    {formik.errors.state && errorElement(formik.errors.state)}</>
                                :
                                <>
                                    <input
                                        type="text"
                                        name="state"
                                        id="state"
                                        value={formik.values.state}
                                        onChange={formik.handleChange}
                                        className="block w-full rounded-lg border-sm border-cardBorder px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-cardBorder placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cardBorder sm:text-sm sm:leading-6"
                                        placeholder={t?.('create-shipment.shipment-address.state')}
                                    />
                                </>
                            }
                        </div>
                    </div>
                    <div className='grid grid-cols-2 gap-4 grid-row items-end justify-start mt-4'>
                        <div className='col-span-2 md:col-span-1'>
                            <label htmlFor="zipcode" className="block text-sm font-medium text-gray-700">
                                {t?.('create-shipment.shipment-address.zipCode')}
                                <span className='text-rexRed'>*</span>
                            </label>
                            <input
                                type="number"
                                name="postalCode"
                                id="postalCode"
                                value={formik.values.postalCode}
                                onChange={formik.handleChange}
                                className="block w-full rounded-lg border-sm border-cardBorder px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-cardBorder placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cardBorder sm:text-sm sm:leading-6"
                                placeholder="94105"
                            />
                            {formik.errors.postalCode && errorElement(formik.errors.postalCode)}
                        </div>
                        <div className='col-span-2 md:col-span-1'>
                            <label htmlFor="city" className="block text-sm font-medium text-gray-700">
                                {t?.('create-shipment.shipment-address.city')}
                                <span className='text-rexRed'>*</span>
                            </label>
                            <input
                                type="text"
                                name="city"
                                id="city"
                                value={formik.values.city}
                                onChange={formik.handleChange}
                                className="block w-full rounded-lg border-sm border-cardBorder px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-cardBorder placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cardBorder sm:text-sm sm:leading-6"
                                placeholder={t?.('create-shipment.shipment-address.city')}
                            />
                            {formik.errors.city && errorElement(formik.errors.city)}
                        </div>
                    </div>
                    <div className='grid grid-cols-2 gap-4 grid-row items-end justify-start mt-4'>
                        <div className='col-span-2 md:col-span-1'>
                            <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                                {t?.('create-shipment.shipment-address.phone')}
                            </label>
                            <input
                                type="text"
                                name="phone"
                                id="phone"
                                value={formik.values.phone}
                                onChange={formik.handleChange}
                                className="block w-full rounded-lg border-sm border-cardBorder px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-cardBorder placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cardBorder sm:text-sm sm:leading-6"
                                placeholder="+1415-736-0000"
                            />
                            {formik.errors.phone && errorElement(formik.errors.phone)}
                        </div>
                        <div className='col-span-2 md:col-span-1'>
                            <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                {t?.('create-shipment.shipment-address.e-mail')}
                            </label>
                            <input
                                type="email"
                                name="email"
                                id="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                className="block w-full rounded-lg border-sm border-cardBorder px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-cardBorder placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cardBorder sm:text-sm sm:leading-6"
                                placeholder={t?.('create-shipment.shipment-address.e-mail')}
                            />
                            {formik.errors.email && errorElement(formik.errors.email)}
                        </div>
                    </div>
                </div>
            </>
            {/* NOTE: product section */}
            <ProductTransactions
                selectedCurrencies={selectedCurrencies}
                setSelectedCurrencies={setSelectedCurrencies}
                products={products}
                setProducts={setProducts}
                description={description}
                setDescription={setDescription}
            />
            {/* NOTE: package section */}
            <div className='mt-20'>
                <AddressPatch title={t?.('create-shipment.patchs.package-transactions')} icon={I3DCubeScan} />
                <div className='px-4 pb-4' >
                    <div className='w-full sm:w-[50%] lg:w-[40%] xl:w-[25%] 2xl:w-[20%] h-[60px] flex items-center justify-start rounded-br-lg rounded-tr-lg mt-16 gap-2 '>
                        <p className='font-medium text-headerPrimary'>
                            {t?.('create-shipment.package-transactions.order-description')}
                        </p>
                        <Edit className='mr-2 text-headerPrimary' />
                    </div>
                    <div className='grid grid-cols-2 gap-4 grid-row items-end justify-start'>
                        <div className='col-span-2 xl:col-span-1'>
                            <label htmlFor="reference1" className="block text-sm font-medium text-gray-700">
                                {t?.('create-shipment.package-transactions.referenceNo')}
                                <InfoCircle id='info' className='h-4 w-4 rounded-full ml-1 bg-headerPrimary text-white inline-block' />
                                <Tooltip
                                    className='z-60'
                                    anchorSelect="#info"
                                    content={'Gönderinizi kendi bünyenizde tanımlamak için referans numarası girebilirsiniz.'}
                                />
                            </label>
                            <input
                                type="text"
                                name="reference1"
                                id="reference1"
                                value={formik.values.reference1}
                                onChange={formik.handleChange}
                                className="block w-full rounded-lg border-sm border-cardBorder px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-cardBorder placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cardBorder sm:text-sm sm:leading-6"
                                placeholder="0"
                            />
                        </div>
                        <div className='col-span-2 xl:col-span-1 grid grid-cols-4 gap-1 grid-row items-end justify-start'>
                            <div className='col-span-4 md:col-span-2 lg:col-span-1'>
                                <label htmlFor="weight" className="block text-sm font-medium text-gray-700">
                                    {t?.('create-shipment.package-transactions.weight')}
                                </label>
                                <input
                                    type="number"
                                    name="weight"
                                    id="weight"
                                    value={formik.values.weight}
                                    onChange={formik.handleChange}
                                    className="block w-full rounded-lg border-sm border-cardBorder px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-cardBorder placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cardBorder sm:text-sm sm:leading-6"
                                    placeholder="0"
                                />
                            </div>
                            <div className='col-span-4 md:col-span-2 lg:col-span-1'>
                                <label htmlFor="width" className="block text-sm font-medium text-gray-700">
                                    {t?.('create-shipment.package-transactions.width')}
                                </label>
                                <input
                                    type="number"
                                    name="width"
                                    id="width"
                                    value={formik.values.width}
                                    onChange={formik.handleChange}
                                    className="block w-full rounded-lg border-sm border-cardBorder px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-cardBorder placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cardBorder sm:text-sm sm:leading-6"
                                    placeholder="0"
                                />
                            </div>
                            <div className='col-span-4 md:col-span-2 lg:col-span-1'>
                                <label htmlFor="size" className="block text-sm font-medium text-gray-700">
                                    {t?.('create-shipment.package-transactions.size')}
                                </label>
                                <input
                                    type="number"
                                    name="length"
                                    id="length"
                                    value={formik.values.length}
                                    onChange={formik.handleChange}
                                    className="block w-full rounded-lg border-sm border-cardBorder px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-cardBorder placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cardBorder sm:text-sm sm:leading-6"
                                    placeholder="0"
                                />
                            </div>
                            <div className='col-span-4 md:col-span-2 lg:col-span-1'>
                                <label htmlFor="height" className="block text-sm font-medium text-gray-700">
                                    {t?.('create-shipment.package-transactions.height')}
                                </label>
                                <input
                                    type="number"
                                    name="height"
                                    id="height"
                                    value={formik.values.height}
                                    onChange={formik.handleChange}
                                    className="block w-full rounded-lg border-sm border-cardBorder px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-cardBorder placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cardBorder sm:text-sm sm:leading-6"
                                    placeholder="0"
                                />
                            </div>
                        </div>
                    </div>
                    <label htmlFor="NumberOfBoxes" className="block text-sm font-medium text-gray-700 mt-4">
                        {t?.('create-shipment.package-transactions.number-of-boxes')}
                        <span className='text-rexRed'>*</span>
                    </label>
                    <div id='NumberOfBoxes' className="flex items-center ">
                        <div
                            onClick={handleDecrement}
                            className="px-2 py-1 bg-[#EDEEF8]  focus:outline-none rounded-l-md h-full w-12 cursor-pointer text-center"
                        >
                            <span className="text-xl text-headerPrimary">-</span>
                        </div>
                        <input
                            type="number"
                            value={count}
                            min={1}
                            className="w-12 h-full text-center border border-cardBorder focus:outline-none"
                            onChange={(e) => setCount(parseInt(e.target.value))}
                        />
                        <div
                            onClick={handleIncrement}
                            className="px-2 py-1 bg-[#3C4790] 0 focus:outline-none h-full  rounded-r-md w-12 cursor-pointer text-center"
                        >
                            <span className="text-xl text-white">+</span>
                        </div>
                    </div>
                    <div className="mt-4 mb-2 flex items-center">
                        <Switch
                            id="switch1"
                            checked={enabled}
                            onChange={setEnabled}
                            className={classNames(
                                enabled
                                    ? 'bg-[#22D3EE]'
                                    : 'bg-[#E5E5E6]',
                                'relative inline-flex h-5 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none'
                            )}
                        >
                            <span className="sr-only">Use setting</span>
                            <span
                                aria-hidden="true"
                                className={classNames(
                                    enabled
                                        ? 'translate-x-5 bg-white'
                                        : 'translate-x-0 bg-[#818183]',
                                    'pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                                )}
                            />
                        </Switch>
                        <label htmlFor="switch1" className="text-gray-600 font-sm text-xs ml-1">
                            {t?.('create-shipment.package-transactions.reset')}
                        </label>
                    </div>

                </div>
            </div>
            <div className='flex items-center justify-center '>
                <button disabled={loading} type='submit' className="text-sm lg:text-sm border-2 py-2 px-4 text-white bg-headerPrimary border-headerPrimary rounded-md flex flex-row items-center justify-center gap-1 w-[250px]">
                    {loading && <svg className="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <circle className="text-white" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                    </svg>}
                    Sipariş Oluştur
                </button>
            </div>

        </form>
    )
}

export default NewPostAddress