import { Lock1 } from 'iconsax-react';
import React from 'react';
import { Link } from 'react-router-dom';

import { useLanguage } from '../../context/LanguageContext';

// Must be wrapped before relative div before table......
const ToolsAbsoluteBuyBox: React.FC = () => {
  const { language } = useLanguage();
  return (
    <div
      className={`bg-white shadow-xl rounded-lg border-2 absolute top-1/3 h-[370px] z-40 w-[60%] left-[20%] md:w-[45%] md:left-[30%] lg:w-[40%] lg:left-[35%] xl:w-[30%] xl:left-[35%]   min-[2000px]:w-[25%] min-[2000px]:left-[35%] `}
    >
      <div className="flex justify-center mt-12 mb-8">
        <Lock1 size="80" color="#3C4790" variant="Bold" />
      </div>
      <p className="text-headerPrimary text-sm md:text-lg lg:text-lg px-4 mb-8 font-medium text-center">
        {language === 'tr'
          ? 'Tüm Ürünleri Görmek İçin Premium Üye Ol.'
          : 'Become a Premium Member to See All Products.'}
      </p>
      <div className="flex justify-center">
        <Link
          target={'_blank'}
          to="https://rexven.com/topluluk-paketleri"
          type="button"
          className="inline-flex justify-center rounded-xl bg-headerPrimary px-12 py-3 text-sm xl:text-xl font-semibold text-white shadow-sm hover:bg-secondary"
        >
          {language === 'tr' ? 'Premium Üye Ol' : 'Rexven Premium'}
        </Link>
      </div>
    </div>
  );
};

export default ToolsAbsoluteBuyBox;
