import { useFormik } from 'formik';
import { Location, NoteRemove } from 'iconsax-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import * as Yup from 'yup';

import { ChevronDoubleUpIcon } from '@heroicons/react/20/solid';
import { useQuery } from '@tanstack/react-query';

import DotLoaderSpinner from '../components/common/DotLoaderSpinner';
import ProductNotFound from '../components/common/ProductNotFound';
import RatingStars from '../components/common/RatingStars';
import ManufacturerInfo from '../components/manufacturer/ManufacturerInfo';
import ManufacturerProductCard from '../components/manufacturer/ManufacturerProductCard';
import MessageModal from '../components/productsAndWholeSalers/MessageModal';
import ProductCart from '../components/productsAndWholeSalers/ProductCart';
import ProductsWrapper, {
  Products,
} from '../components/productsAndWholeSalers/ProductsWrapper';
import SaleRegister from '../components/productsAndWholeSalers/SaleRegister';
import { TurkishCities } from '../components/shipentegraCreatePost/elements/states';
import { useLanguage } from '../context/LanguageContext';
import producerService from '../services/producerService';
import productService from '../services/productService';
import alertNotification from '../utils/alertNotification';
import { classNames } from '../utils/conditionalClasses';
import ErrorFallback from './errors/ErrorFallback';
import { Producer, ProducerProducts } from './Manufacturer';
import NumVerifyModal from '../components/numberVerifyModals/NumVerifyModal';

interface FormValues {
  minPrice: number | string;
  maxPrice: number | string;
  minRating: number | string;
  maxRating: number | string;
  minReviewCount: number | string;
  maxReviewCount: number | string;
}

const validationSchema = Yup.object().shape({
  category: Yup.string(),
  minPrice: Yup.number(),
  maxPrice: Yup.number(),
  minRating: Yup.number(),
  maxRating: Yup.number(),
  minReviewCount: Yup.number(),
  maxReviewCount: Yup.number(),
});

const SupplierProducts = () => {
  const { t } = useLanguage();
  const { id } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedCategory, setSelectedCategory] = useState<number | string>(
    Number(searchParams.get('categoryId')) || ''
  );
  const categories = [
    {
      id: 1,
      categoryName: 'Tüm Kategoriler',
    },
    {
      id: 368,
      categoryName: 'Aksesuar',
    },
    {
      id: 403,
      categoryName: 'Ayakkabı',
    },
    {
      id: 522,
      categoryName: 'Giyim',
    },
    {
      id: 685,
      categoryName: 'Hobi & Eğlence',
    },
    // {
    //   id: 687,
    //   categoryName: 'Kitap',
    // },
    {
      id: 758,
      categoryName: 'Ev & Mobilya',
    },
    {
      id: 790,
      categoryName: 'Otomobil & Motosiklet',
    },
    {
      id: 1070,
      categoryName: 'Kozmetik & Kişisel Bakım',
    },
    {
      id: 1071,
      categoryName: 'Elektronik',
    },
    {
      id: 1216,
      categoryName: 'Kırtasiye & Ofis Malzemeleri',
    },
    {
      id: 1219,
      categoryName: 'Süpermarket',
    },
    {
      id: 2862,
      categoryName: 'Anne & Bebek & Çocuk',
    },
    {
      id: 3186,
      categoryName: 'Spor & Outdoor',
    },
    // {
    //   id: 3981,
    //   categoryName: 'Ek Hizmetler',
    // },
    {
      id: 5558,
      categoryName: 'Bahçe & Elektrikli El Aletleri',
    },
    {
      id: 5559,
      categoryName: 'Banyo Yapı & Hırdavat',
    },
  ];
  const [producerProducts, setProducerProducts] = useState<ProducerProducts[]>(
    []
  );
  const inputRef = useRef<HTMLInputElement | null>(null);
  const navigate = useNavigate();
  const [producer, setProducer] = useState<Producer>();
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [pageNo, setPageNo] = useState(Number(searchParams.get('page')) || 1);
  const [search, setSearch] = useState(searchParams.get('keyword') || '');
  const [queryString, setQueryString] = useState('');
  const [manuTotalPage, setManuTotalPage] = useState(1);
  const [productCount, setProductCount] = useState(null);
  const [orderBy, setOrderBy] = useState('reviewCount');
  const [order, setOrder] = useState('DESC');
  const [productId, setProductId] = React.useState<number | null>(null);
  let temp = Object.fromEntries([...searchParams]);
  const [queryParams, setQueryParams] = useState({
    page: temp.page || '1',
    minPrice: Number(temp.minPrice) || '',
    maxPrice: Number(temp.maxPrice) || '',
    categoryId: Number(temp.categoryId) || '',
    minRating: Number(temp.minRating) || '',
    maxRating: Number(temp.maxRating) || '',
    keyword: temp.keyword || '',
    minReviewCount: Number(temp.minReviewCount) || '',
    maxReviewCount: Number(temp.maxReviewCount) || '',
  });
  const initialValues = {
    page: searchParams.get('page') || '1',
    minPrice: searchParams.get('minPrice') || '',
    maxPrice: searchParams.get('maxPrice') || '',
    categoryId: searchParams.get('categoryId') || '',
    minRating: searchParams.get('minRating') || '',
    maxRating: searchParams.get('maxRating') || '',
    minReviewCount: searchParams.get('minReviewCount') || '',
    maxReviewCount: searchParams.get('maxReviewCount') || '',
  };
  const [open, setOpen] = useState(false);
  const observer = useRef<IntersectionObserver | null>(null);
  const lastProductRef = useCallback(
    (node: HTMLElement | null) => {
      if (loading) return;
      if (observer.current) observer?.current?.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && pageNo < manuTotalPage) {
          setPageNo((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, manuTotalPage]
  );
  const getProducer = (id: any) => {
    producerService
      .getProducer(id)
      .then((res) => {
        setProducer(res.data);
      })
      .catch((err) => {
        alertNotification('error', err.response.data.message);
      });
  };

  const { isLoading, error, data } = useQuery<any, Error>(
    ['suppliersProducts', { id, pageNo, queryParams }],
    {
      queryFn: () =>
        productService.getProducerProducts(+id!, pageNo, queryParams),

      staleTime: Infinity,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );

  // Producer Contact Btn Clicked Stats-->
  const updateProducerStats = (productId: number) => {
    producerService
      .productStatistics({ productId })
      .then((res) => {})
      .catch((res) => {
        console.log(res);
      });
  };

  const formik = useFormik<FormValues>({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setProducerProducts([]);
      const params = {
        page: '1',
        keyword: search,
        categoryId: selectedCategory.toString(),
        minPrice: values.minPrice ? values.minPrice.toString() : '',
        maxPrice: values.maxPrice ? values.maxPrice.toString() : '',
        minReviewCount: values.minReviewCount
          ? values.minReviewCount.toString()
          : '',
        maxReviewCount: values.maxReviewCount
          ? values.maxReviewCount.toString()
          : '',
        minRating: values.minRating ? values.minRating.toString() : '',
        maxRating: values.maxRating ? values.maxRating.toString() : '',
      };
      // setSearchParams(params);
      // const { page, keyword, ...rest } = params;
      // setPageNo(1);
      // setQueryString(`${new URLSearchParams(rest).toString()}`);
      setSearchParams(params);

      const { page, ...rest } = params;
      setPageNo(1);
      setQueryParams(params);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
  });
  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    // setSearch(String(inputRef.current?.value));
    setQueryParams({
      ...queryParams,
      keyword: String(inputRef.current?.value),
    });
    setProducerProducts([]);
    navigate(`?page=1&keyword=${inputRef.current?.value}`);
    setPageNo(1);
  };

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    getProducer(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setLoading(false);
    if (data) {
      setProducerProducts((prev) => [...prev, ...data.data.products]);
      setManuTotalPage(data.data.totalPageCount);
      setProductCount(data.data.totalProductCount);
    }
  }, [data]);

  useEffect(() => {
    setQueryParams({
      page: temp.minPrice || '1',
      minPrice: temp.minPrice || '',
      maxPrice: temp.maxPrice || '',
      categoryId: temp.categoryId || '',
      minRating: temp.minRating || '',
      maxRating: temp.maxRating || '',
      keyword: temp.keyword || '',
      minReviewCount: temp.minReviewCount || '',
      maxReviewCount: temp.maxReviewCount || '',
    });
  }, [searchParams]);

  return (
    <div className="flex w-full flex-shrink-0 flex-col">
      <div className="bg-white rounded-2xl p-2 md:p-6">
        {/* NOTE: search Bar*/}
        <div className="grid grid-cols-12 gap-x-4 gap-y-2 lg:gap-y-0 justify-center items-center">
          {/* Logo */}
          <div className="col-span-12 lg:col-span-2">
            <Link
              to={'/'}
              className={classNames(
                'flex h-16 flex-shrink-0 items-center px-4 justify-between'
              )}
            >
              <img
                className="h-5 w-auto"
                src="/assets/logos/rexven-logo.svg"
                alt="Rexven Company"
              />
            </Link>
          </div>

          {/* Search Bar Field */}
          <div className="col-span-12 lg:col-span-8">
            {/* <SearchBar
                            placeholder={t?.('manufacturers-and-wholesalers.placeholder')}
                        /> */}
            <form className="" onSubmit={handleSubmit}>
              <label
                htmlFor="default-search"
                className="mb-2 text-lg lg:text-sm font-medium text-gray-900 sr-only dark:text-white"
              >
                Search
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5 text-gray-500 dark:text-gray-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    ></path>
                  </svg>
                </div>
                <input
                  ref={inputRef}
                  id="default-search"
                  className="block w-full p-[0.58rem] pl-10 text-xs md:text-sm text-gray-900 border border-headerPrimary rounded-lg bg-gray-50 "
                  placeholder={'Ürün Bul'}
                />
                <button
                  type="submit"
                  className="text-white absolute right-0 bottom-0 bg-headerPrimary rounded-r-md font-medium text-sm px-4 py-2 md:py-2.5"
                >
                  {t?.('common.buttons.search')}
                </button>
              </div>
            </form>
          </div>

          {/* Register Button Field */}
          <div className="col-span-12 lg:col-span-2">
            <SaleRegister />
          </div>
          <NumVerifyModal />
        </div>
        {/* NOTE: categories*/}
        <div className="flow-root">
          <div className="relative -mx-2 -my-2 overflow-x-auto overflow-y-auto scrollbar-hide">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="w-full my-10 overflow-hidden">
                <div className="flex items-center justify-between overflow-auto touch-pan-x py-3">
                  {categories?.map((category: any) => (
                    <p
                      onClick={() => {
                        setProducerProducts([]);
                        setLoading(true);
                        setPageNo(1);
                        setSelectedCategory(
                          category.id === 1 ? '' : category.id.toString()
                        );
                        setQueryParams({
                          ...queryParams,
                          categoryId:
                            category.id === 1 ? '' : category.id.toString(),
                        });
                        const params = Object.fromEntries(
                          searchParams.entries()
                        );
                        params.categoryId =
                          category.id === 1 ? '' : String(category.id);
                        params.page = '1';
                        setSearchParams(params);
                      }}
                      className={classNames(
                        selectedCategory == category.id
                          ? 'border-b-2 font-bold text-md border-headerPrimary text-[#1F2937]'
                          : 'text-[#374151] text-sm xl:text-sm',
                        'whitespace-nowrap font-medium  min-[1306px]:font-medium text-center  px-3 first:pl-0 last:pr-0  cursor-pointer inline-block hover:text-headerPrimary'
                      )}
                      key={category.id}
                    >
                      {category.categoryName}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* side categories and product cards */}
        <div className="grid grid-cols-12 gap-x-4 gap-y-2 lg:gap-y-0 justify-center items-start ">
          {/* side categories min-[450px]:sticky */}
          <div className="col-span-12  lg:col-span-2 pt-4 lg:sticky  lg:top-[64px]">
            {' '}
            {/* Örnek: Header yüksekliğine göre ayarlama yapın */}
            <form onSubmit={formik.handleSubmit} className="space-y-4">
              {/* Ürün Sayısı */}
              <div className="flex flex-col gap-2">
                <label
                  htmlFor="minPrice"
                  className="block text-xs font-medium text-gray-700"
                >
                  Ürün Fiyatı
                </label>
                <div className="flex">
                  <input
                    id="minPrice"
                    name="minPrice"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.minPrice}
                    placeholder="Min"
                    className="mt-1 block w-full py-0 placeholder:text-xs  rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                  <span className="flex items-center mx-2">-</span>
                  <input
                    id="maxPrice"
                    name="maxPrice"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.maxPrice}
                    placeholder="Max"
                    className="mt-1 block w-full py-0 placeholder:text-xs  rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                </div>
              </div>
              {/* YORUM SAYISI */}
              <div className="flex flex-col gap-2">
                <label
                  htmlFor="minReviewCount"
                  className="block text-xs font-medium text-gray-700"
                >
                  Yorum Sayısı
                </label>
                <div className="flex">
                  <input
                    id="minReviewCount"
                    name="minReviewCount"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.minReviewCount}
                    placeholder="Min"
                    className="mt-1 block w-full py-0 placeholder:text-xs  rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                  <span className="flex items-center mx-2">-</span>
                  <input
                    id="maxReviewCount"
                    name="maxReviewCount"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.maxReviewCount}
                    placeholder="Max"
                    className="mt-1 block w-full py-0 placeholder:text-xs  rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                </div>
              </div>
              {/* RATING  */}
              <div className="flex flex-col gap-2">
                <label
                  htmlFor="minRating"
                  className="block text-xs font-medium text-gray-700"
                >
                  Değerlendirme
                </label>
                <div className="flex">
                  <input
                    id="minRating"
                    name="minRating"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.minRating}
                    placeholder="Min"
                    className="mt-1 block w-full py-0 placeholder:text-xs  rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                  <span className="flex items-center mx-2">-</span>
                  <input
                    id="maxRating"
                    name="maxRating"
                    type="text"
                    onChange={formik.handleChange}
                    value={formik.values.maxRating}
                    placeholder="Max"
                    className="mt-1 block w-full py-0 placeholder:text-xs  rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="hover:cursor-pointer text-center text-[12px] bg-headerPrimary font-bold text-white h-[40px] max-h-[50px] px-3 py-1  border-2 rounded-lg border-headerPrimary w-full flex items-center justify-center"
                >
                  Uygula
                </button>
                <button
                  onClick={() => {
                    formik.resetForm();
                    setSearchParams({
                      page: searchParams.get('page')!,
                      keyword: searchParams.get('keyword')!,
                      category: '',
                      minPrice: '',
                      maxPrice: '',
                      minReviewCount: '',
                      maxReviewCount: '',
                      minRating: '',
                      maxRating: '',
                    });
                  }}
                  className="hover:cursor-pointer text-center text-[12px] bg-white font-bold text-headerPrimary h-[40px] max-h-[50px] w-full px-3 py-1  border-2 rounded-lg border-headerPrimary w-[49%] flex items-center justify-center mt-3"
                >
                  Temizle
                </button>
              </div>
            </form>
          </div>
          {/* product cards */}
          <div className="col-span-12 lg:col-span-10 border-t-4 border-[#F9FAFB] pt-4">
            <div className="bg-white rounded-xl p-2 md:p-6 border border-[#E5E7EB] mb-4">
              <div className="flex items-center">
                <img
                  src={producer?.logo}
                  className="h-10 w-10 object-contain"
                  alt="producer-logo"
                />
                <h1 className="ml-2 font-bold uppercase">
                  {producer?.producerName}{' '}
                </h1>
              </div>
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <ManufacturerInfo
                  productCount={productCount}
                  producer={producer!}
                  handleChange={() => {}}
                />
              </ErrorBoundary>
            </div>
            <div className="grid grid-cols-12 justify-center items-center gap-6">
              {loading && <DotLoaderSpinner loading={loading} />}
              {producerProducts?.length > 0 &&
                producerProducts?.map((product: any, index: number) => (
                  <div
                    ref={
                      index === producerProducts.length - 1
                        ? lastProductRef
                        : undefined
                    }
                    className="col-span-12 md:col-span-6 xl:col-span-4 2xl:col-span-3"
                  >
                    <ManufacturerProductCard
                      key={product.id}
                      product={product}
                      setOpen={setOpen}
                      setProductId={setProductId}
                      updateProducerStats={updateProducerStats}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <MessageModal
        open={open}
        setOpen={setOpen}
        productId={productId!}
        updateProducerStats={updateProducerStats}
      />
      {pageNo > 1 && (
        <div
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          }}
          className="fixed right-20 bottom-5 mr-4 mb-4 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer"
        >
          <ChevronDoubleUpIcon className="h-6 w-6" />
        </div>
      )}
    </div>
  );
};

export default SupplierProducts;
