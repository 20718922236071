/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';

import ProvidersCarousel from '../components/dashboard/ProvidersCarousel';
import SparklinesWrapper from '../components/dashboard/SparklinesWrapper';
import ToolsAndAnnouncementsWrapper from '../components/dashboard/ToolsAndAnnouncementsWrapper';
import { useAuth } from '../context/AuthContext';
import memberService from '../services/memberService';

const Dashboard = () => {
  const { session } = useAuth();
  const [isTabActive, setIsTabActive] = React.useState<boolean>(true);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabActive(document.visibilityState === 'visible');
    };

    // Add event listener for visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup function to remove the event listener when the component is unmounted
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    let intervalId: any;
    if (session && isTabActive) {
      intervalId = setInterval(async () => {
        await memberService.userTrackReq(`dashboard`, session?.id!);
      }, 60000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [session, isTabActive]);
  return (
    <div>
      <SparklinesWrapper />
      <ToolsAndAnnouncementsWrapper />
      <ProvidersCarousel />
      {/* <BarChartAndProfileWrapper /> */}
      {/* <WholeSalersCarousel />
      <AcademyCarousel />
      <ToolsNavDashboard /> */}
    </div>
  );
};

export default Dashboard;
