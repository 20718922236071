import { ErrorMessage, Field, FieldArray, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import { AddCircle, Calculator, DocumentText, ShieldCross, TickCircle } from 'iconsax-react';
import { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';
import { DotLoader } from 'react-spinners';
import * as Yup from 'yup';

import { Dialog, Transition } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import { XMarkIcon } from '@heroicons/react/24/outline';

import { useLanguage } from '../../context/LanguageContext';
import { OrderItemData } from '../../interfaces/shipEntegraInterfaces';
import shipentegraService from '../../services/shipentegraService';
import alertNotification from '../../utils/alertNotification';
import { classNames } from '../../utils/conditionalClasses';
import { errorElement } from '../../utils/errorElement';
import DotLoaderSpinner, { override } from '../common/DotLoaderSpinner';
import carrierServicesByCountry from '../shipEntegraShared/carriersByCountries.json';
import { isEuCountry } from '../shipEntegraShared/IsEuropeCountry';
import maxWeights from '../shipEntegraShared/MaxWeights';
import FormikSwitch from './elements/FormikSwitch';

interface CreateTicketModalMultipleProps {
    openCreateTicket: boolean
    setOpenCreateTicket: Dispatch<SetStateAction<boolean>>
    selectedOrderIds: number[] | undefined
    getOrders: () => void
}
interface CalculationResult {
    success: boolean;
    price: number;
    fuel: number;
    pricing: string;
    uniqueCode: string;
}
interface CountryServices {
    [key: string]: {
        services: string[];
    };
}
interface Carrier {
    title: string;
    value: string;
    info: {
        url: string;
        specialService: string;
    };
}
interface OrderCarriers {
    [index: number]: Carrier[];
}
type SetFieldErrorType = FormikHelpers<any>['setFieldError'];
const CreateTicketModalMultiple: React.FC<CreateTicketModalMultipleProps> = ({ openCreateTicket, setOpenCreateTicket, selectedOrderIds, getOrders }) => {
    const { t } = useLanguage();
    const [detailsVisible, setDetailsVisible] = useState<{ [key: number]: boolean }>({});
    const [isLoading, setIsLoading] = useState(false)
    const [calIsLoading, setCalIsLoading] = useState(false)
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [urls, setUrls] = useState<{ [key: number]: string }>({});
    const [items, setItems] = useState<OrderItemData[]>([])
    const [calculationResults, setCalculationResults] = useState<{ [key: number]: CalculationResult }>({});
    const [calculated, setCalculated] = useState(false)
    const [orderCarriers, setOrderCarriers] = useState<OrderCarriers>({});
    const countryServices: CountryServices = carrierServicesByCountry;

    const [requestStates, setRequestStates] = useState([{ status: 'idle', errorMessage: '' }]);

    const getOrderItems = () => {
        // selectedOrderIds'in undefined olma ihtimaline karşı koruma
        if (!selectedOrderIds || selectedOrderIds.length === 0) {
            // Eğer selectedOrderIds boş veya undefined ise, işlemi durdur
            return;
        }
        setIsDataLoaded(false)
        // selectedOrderIds boş değilse, her bir ID için getOrderItems çağrısını yap
        const promises = selectedOrderIds.map(id => shipentegraService.getOrderItems(id));

        Promise.all(promises).then(results => {
            // Tüm istekler tamamlandığında burası çalışır
            const allItems = results.map(res => res.data); // Her bir sonucun data'sını al
            setItems(allItems); // Elde edilen tüm sonuçları items state'ine at
            const newRequestStates = allItems.map(() => ({ status: 'idle', errorMessage: '' }));
            setRequestStates(newRequestStates);
        }).catch(error => {
            console.error("İstekler sırasında bir hata oluştu:", error);
        }).finally(() => {
            setIsDataLoaded(true)
        })
    };

    useEffect(() => {
        if (openCreateTicket) {
            getOrderItems()
        }
    }, [openCreateTicket])

    useEffect(() => {
        // items listesi değiştiğinde, her bir item için detailsVisible state'ini güncelle.
        const initialVisibilityState = items.reduce((acc, _, index) => {
            acc[index] = false; // Başlangıçta her satırın detayı kapalıdır.
            return acc;
        }, {} as { [key: number]: boolean });
        setDetailsVisible(initialVisibilityState);
    }, [items]);

    // Belirli bir satırın detay görünürlüğünü toggle eden fonksiyon
    const toggleDetailVisibility = (index: number) => {
        setDetailsVisible((currentDetailsVisible) => ({
            ...currentDetailsVisible,
            [index]: !currentDetailsVisible[index],
        }));
    };

    const validationSchema = Yup.object().shape({
        orders: Yup.array()
            .of(
                Yup.object().shape({
                    specialService: Yup.string().required('Taşıyıcı alanı zorunludur.'),
                    currency: Yup.string().required('Para birimi alanı zorunludur.'),
                    weight: Yup.number()
                        .required('Ağırlık alanı zorunludur.')
                        .positive('Ağırlık pozitif bir sayı olmalı.')
                        .test(
                            'max-weight-check',
                            'Belirlenen maksimum ağırlığı aşıyor.',
                            function (value) {
                                const { specialService, country } = this.parent;
                                const serviceMaxWeights = maxWeights[specialService] || {};
                                const maxWeight = serviceMaxWeights[country];
                                if (maxWeight && value > maxWeight) {
                                    return this.createError({
                                        message: `Bu taşıyıcı için ${country} ülkesine maksimum ağırlık sınırı (${maxWeight}kg)'dır.`
                                    });
                                }
                                return true;
                            }
                        ),

                    insurance: Yup.boolean().required('Sigorta alanı zorunludur.'),
                    content: Yup.string().required('Ürün adı alanı zorunludur.'),
                    items: Yup.array()
                        .of(
                            Yup.object().shape({
                                declaredQuantity: Yup.number().required('Adet alanı zorunludur.').positive('Adet pozitif bir sayı olmalı.'),
                                declaredPrice: Yup.number().required('Birim fiyatı alanı zorunludur.').positive('Birim fiyatı pozitif bir sayı olmalı.'),
                                gtip: Yup.string().required('GTIP No alanı zorunludur.'),
                            })
                        )
                        .required('Sipariş öğeleri alanı zorunludur.')
                        .min(1, 'En az bir sipariş öğesi olmalıdır.'),
                })
            )
    });

    const initialValues = {
        orders: items.map(item => ({
            orderId: item.generalInfo.shipEntegraOrderId,
            content: item.generalInfo.content || '',
            currency: item.generalInfo.currency || 'USD',
            weight: item.generalInfo.weight || '',
            specialService: item.generalInfo.shipTo.country === 'ES' ? 'shipentegra-eco' : 'shipentegra-express',
            iossNumber: '',
            recipientName: item.generalInfo.shipTo.name || '',
            insurance: true || false,
            country: item.generalInfo.shipTo.country || '',
            declaredPrice: item.generalInfo.items[0]?.declaredPrice || '',
            gtip: item.generalInfo.items[0].gtip || '',
            items: item.generalInfo.items.map(item => ({
                declaredQuantity: item.declaredQuantity || '',
                declaredPrice: item.declaredPrice || '',
                gtip: item.gtip || '',
                itemId: item.itemId
            })),
        }))
    };

    const onSubmit = async (values: any) => {
        setIsLoading(true)
        setCalculated(false)
        values.orders.forEach((order: any, index: number) => {
            setRequestStates(prevStates => {
                const newStates = [...prevStates];
                newStates[index] = { status: 'loading', errorMessage: '' };
                return newStates;
            });
            const orderUrl = urls[index];
            const payload = {
                url: orderUrl || "https://newfront.shipentegra.com/v1/logistics/labels/shipentegra",
                specialService: order.specialService,
                insurance: order.insurance,
                content: order.content,
                weight: order.weight,
                iossNumber: order.iossNumber,
                currency: order.currency,
                items: order.items.map((item: any) => ({
                    itemId: item.itemId,
                    declaredPrice: item.declaredPrice,
                    declaredQuantity: item.declaredQuantity,
                    gtip: item.gtip,
                })),
            };
            shipentegraService.updateTicket(order.orderId, payload).then((response: any) => {
                setRequestStates(prevStates => {
                    const newStates = [...prevStates];
                    newStates[index] = { status: 'success', errorMessage: '' };
                    return newStates;
                });
                alertNotification('success', `Sipariş ${order.orderId} güncellendi`);
            }).catch((error: any) => {
                setRequestStates(prevStates => {
                    const newStates = [...prevStates];
                    newStates[index] = { status: 'error', errorMessage: error.response.data.message }; // error.message veya error.response.data.message kullanabilirsiniz.
                    return newStates;
                });
                console.error(`Sipariş ${order.orderId} güncellenirken hata oluştu`, error);
                alertNotification('error', `Sipariş ${order.orderId} güncellenirken hata oluştu: ${error.response.data.message}`);
            }).finally(() => {
                setIsLoading(false)
            })
        });
    };

    const handleCalculateClick = (index: any, order: any, setFieldError: SetFieldErrorType) => {
        if (!order.weight) {
            // Set a Formik field error for the specific weight field
            setFieldError(`orders[${index}].weight`, 'Ağırlık alanı zorunludur.');
            return;
        }

        const payload = {
            country: order.country,
            kgDesi: order.weight,
            seCarrier: order.specialService,
            isAmazonShipment: 0
        };
        setCalculationResults({})
        setCalIsLoading(true)
        shipentegraService.calculate(payload)
            .then((res) => {
                setCalculationResults(prevResults => ({
                    ...prevResults,
                    [index]: res.data, // index ile ilişkilendirilmiş hesaplama sonucu
                }));
                setCalculated(true)
            }).catch((err) => {
                console.log(err);
                setCalculated(false)
                alertNotification('err', err.response.data.message)
            }).finally(() => {
                setCalIsLoading(false)
            })
    };
    const handleBatchCalculate = (orders: any, setFieldError: SetFieldErrorType) => {
        orders.forEach((order: any, index: any) => {
            if (!order.weight) {
                setFieldError(`orders[${index}].weight`, 'Ağırlık alanı zorunludur.');
            } else {
                const payload = {
                    country: order.country,
                    kgDesi: order.weight,
                    seCarrier: order.specialService,
                    isAmazonShipment: 0
                };
                setCalIsLoading(true)
                shipentegraService.calculate(payload)
                    .then((res) => {
                        setCalculated(true)
                        setCalculationResults(prevResults => ({
                            ...prevResults,
                            [index]: res.data,
                        }));
                    }).catch((err) => {
                        console.log(err);
                        setCalculated(false)
                        alertNotification('err', err.response.data.message)
                    }).finally(() => {
                        setCalIsLoading(false)
                    })
            }
        });
    };
    const resetCalculateAndClose = () => {
        const hasSuccess = requestStates.some(state => state.status === 'success');
        if (hasSuccess) {
            getOrders();
        }
        setCalculated(false)
        setOpenCreateTicket(false)
    }
    useEffect(() => {
        const newOrderCarriers: OrderCarriers = {};
        items.forEach((item, index) => {
            const countryCode = item.generalInfo.shipTo.country;
            const availableServices = countryServices[countryCode]?.services || [];
            const carriersForCountry: Carrier[] = availableServices.map(service => ({
                value: service,
                title: service.replace(/-/g, ' ').replace(/\b\w/g, letter => letter.toUpperCase()),
                info: {
                    url: "https://newfront.shipentegra.com/v1/logistics/labels/shipentegra",
                    specialService: service
                }
            }));
            newOrderCarriers[index] = carriersForCountry;
        });
        setOrderCarriers(newOrderCarriers);
    }, [items]);

    // Render fonksiyonu
    const renderCarrierOptions = (index: number) => {
        const carriers: Carrier[] = orderCarriers[index] || [];
        return carriers.map((carrier, carrierIndex) => (
            <option key={carrierIndex} value={carrier.value}>{carrier.title}</option>
        ));
    };

    return (
        <Transition.Root show={openCreateTicket} as={Fragment}>
            <Dialog as="div" className="relative z-60" onClose={resetCalculateAndClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>
                <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-4">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-full p-4 sm:p-6">
                                <div className="absolute right-0 top-0 pr-4 pt-4">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        onClick={resetCalculateAndClose}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className="">
                                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                        <Dialog.Title as="h3" className="flex flex-row items-center justify-left gap-1 text-base font-semibold leading-6 text-headerPrimary">
                                            <DocumentText color="#1F254B"
                                                variant="Bold"
                                                className="h-5 w-5" />
                                            Sipariş Detayları
                                        </Dialog.Title>
                                        <div className="mt-2 min-h-[400px]">
                                            {isDataLoaded ? <Formik
                                                validationSchema={validationSchema}
                                                initialValues={initialValues} onSubmit={onSubmit}>
                                                {({ values, setFieldError }) => (
                                                    <Form>
                                                        <FieldArray name="orders">
                                                            {({ insert, remove, push }) => (
                                                                <div className="overflow-x-auto">
                                                                    <table>
                                                                        <thead>
                                                                            <tr>
                                                                                <th className="px-2 py-3 text-sm font-semibold text-center">Taşıyıcı</th>
                                                                                <th className="px-2 py-3 text-sm font-semibold text-center">Ülke</th>
                                                                                <th className="px-2 py-3 text-sm font-semibold text-center">Sigorta</th>
                                                                                <th className="px-2 py-3 text-sm font-semibold text-center">Gümrük Değeri</th>
                                                                                <th className="px-2 py-3 text-sm font-semibold text-center">Para Birimi</th>
                                                                                <th className="px-2 py-3 text-sm font-semibold text-center">Ürün</th>
                                                                                <th className="px-2 py-3 text-sm font-semibold text-center">Alıcı</th>
                                                                                <th className="px-2 py-3 text-sm font-semibold text-center">GTIP No</th>
                                                                                <th className="px-2 py-3 text-sm font-semibold text-center">Ağırlık</th>
                                                                                <th className="px-2 py-3 text-sm font-semibold text-center">IOSS/HMRC</th>
                                                                                <th className="px-2 py-3 text-sm font-semibold text-center"></th>
                                                                                <th className="px-2 py-3 text-sm font-semibold text-center">*</th>
                                                                            </tr>
                                                                        </thead>

                                                                        <tbody>
                                                                            {values?.orders?.map((order, index) => (
                                                                                <>
                                                                                    <tr className={classNames(requestStates[index]?.status === 'error' ? 'rounded-md bg-red-50' : '', 'my-1')} key={index}>
                                                                                        <td className="px-2 py-4 whitespace-nowrap min-w-[250px]">
                                                                                            <Field
                                                                                                placeholder="seçiniz" name={`orders[${index}].specialService`} as="select">
                                                                                                {({ field, form }: FieldProps) => (
                                                                                                    <select
                                                                                                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                                                                                            const { value } = e.target;
                                                                                                            const selectedCarrier = orderCarriers[index]?.find(carrier => carrier.value === value);
                                                                                                            if (selectedCarrier) {
                                                                                                                form.setFieldValue(`orders[${index}].specialService`, value);
                                                                                                                // İsteğe bağlı olarak diğer işlemler...
                                                                                                            }
                                                                                                        }}
                                                                                                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                                                    >
                                                                                                        {renderCarrierOptions(index)}
                                                                                                    </select>
                                                                                                )}
                                                                                            </Field>
                                                                                            <ErrorMessage name={`orders[${index}].specialService`} component="div" className=" rounded-md bg-red-50 p-1 my-1 text-xs text-rexRed" />
                                                                                        </td>
                                                                                        <td className="px-2 py-4 whitespace-nowrap min-w-[65px]">
                                                                                            <span className="flex items-center">
                                                                                                <img className="h-5 w-5 flex-shrink-0 rounded-full" alt="" src={`https://flagsapi.com/${order.country}/flat/64.png`}></img>
                                                                                                <span className="ml-1">{order.country}</span>
                                                                                            </span>
                                                                                        </td>
                                                                                        <td className="px-2 py-4 whitespace-nowrap min-w-[75px]">
                                                                                            <FormikSwitch name={`orders[${index}].insurance`} />
                                                                                            <ErrorMessage name={`orders[${index}].insurance`} component="div" className=" rounded-md bg-red-50 p-1 my-1 text-xs text-rexRed" />
                                                                                        </td>
                                                                                        <td className="px-2 py-4 whitespace-nowrap min-w-[140px]">
                                                                                            <Field name={`orders[${index}].declaredPrice`} type="number" className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                                                                        </td>
                                                                                        <td className="px-2 py-4 whitespace-nowrap min-w-[120px]">
                                                                                            <Field as="select" name={`orders[${index}].currency`} className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" >
                                                                                                {({ field }: FieldProps) => (
                                                                                                    <select
                                                                                                        className="mt-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                                                        {...field}>
                                                                                                        <option value="USD">USD</option>
                                                                                                        <option value="EUR">EUR</option>
                                                                                                        <option value="GBP">GBP</option>
                                                                                                        <option value="SAR">SAR</option>
                                                                                                        <option value="AUD">AUD</option>
                                                                                                        <option value="CAD">CAD</option>
                                                                                                        <option value="KWD">KWD</option>
                                                                                                    </select>
                                                                                                )}
                                                                                            </Field>
                                                                                            <ErrorMessage name={`orders[${index}].currency`} component="div" className=" rounded-md bg-red-50 p-1 my-1 text-xs text-rexRed" />
                                                                                        </td>
                                                                                        <td className="px-2 py-4 whitespace-nowrap min-w-[160px]">
                                                                                            <Field name={`orders[${index}].content`} type='text' className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm" />
                                                                                            <ErrorMessage name={`orders[${index}].content`} component="div" className=" rounded-md bg-red-50 p-1 my-1 text-xs text-rexRed" />
                                                                                        </td>
                                                                                        <td className="px-2 py-4 whitespace-nowrap min-w-[140px]">
                                                                                            <span className="mt-1 block w-full rounded-md p-2 sm:text-sm">{order.recipientName}</span>
                                                                                        </td>
                                                                                        <td className="px-2 py-4 whitespace-nowrap min-w-[140px]">
                                                                                            <Field name={`orders[${index}].gtip`} type='text' className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                                                                        </td>
                                                                                        <td className="px-2 py-4 whitespace-nowrap min-w-[140px]">
                                                                                            <div className="relative">
                                                                                                <Field
                                                                                                    name={`orders[${index}].weight`}
                                                                                                    type="number"
                                                                                                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm pr-10" // Adjust right padding to leave space for the icon
                                                                                                />
                                                                                                <Calculator
                                                                                                    onClick={() => handleCalculateClick(index, values.orders[index], setFieldError)}
                                                                                                    variant="Bold"
                                                                                                    className="cursor-pointer text-headerPrimary w-4 h-4 absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400" // Adjust positioning if needed
                                                                                                />
                                                                                            </div>
                                                                                            <ErrorMessage
                                                                                                name={`orders[${index}].weight`}
                                                                                                component="div"
                                                                                                className="rounded-md bg-red-50 p-1 my-1 text-xs text-rexRed"
                                                                                            />
                                                                                        </td>


                                                                                        <td className="px-2 py-4 whitespace-nowrap">
                                                                                            {isEuCountry(values.orders[index].country) && <>
                                                                                                <Field name={`orders[${index}].iossNumber`} type='text' className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                                                                            </>}
                                                                                            {/* <ErrorMessage name={`orders[${index}].iossNumber`} component="div" className=" rounded-md bg-red-50 p-1 my-1 text-xs text-rexRed" /> */}
                                                                                        </td>
                                                                                        <td className="px-2 py-4 whitespace-nowrap flex flex-col items-start justify-start w-full gap-2">
                                                                                            <div className="px-2 py-4 whitespace-nowrap flex flex-row items-center justify-start w-full gap-2">
                                                                                                <p
                                                                                                    onClick={() => toggleDetailVisibility(index)}
                                                                                                    className='text-headerPrimary font-semibold cursor-pointer'>
                                                                                                    Detay
                                                                                                </p>
                                                                                                <div>
                                                                                                    {detailsVisible[index] ? <ChevronDownIcon
                                                                                                        onClick={() => toggleDetailVisibility(index)}
                                                                                                        className="h-5 w-5 text-[#1F254B] cursor-pointer bg-[#EDEEF8] rounded-md"
                                                                                                        aria-hidden="true"
                                                                                                    />
                                                                                                        :
                                                                                                        <ChevronUpIcon
                                                                                                            onClick={() => toggleDetailVisibility(index)}
                                                                                                            className="h-5 w-5 text-[#1F254B] cursor-pointer bg-[#EDEEF8] rounded-md"
                                                                                                            aria-hidden="true"
                                                                                                        />
                                                                                                    }
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td className="px-2 py-4 whitespace-nowrap">
                                                                                            {requestStates[index]?.status && requestStates[index]?.status !== undefined ? (
                                                                                                <>
                                                                                                    {requestStates[index]?.status === 'loading' &&
                                                                                                        <div className="flex items-center justify-center">
                                                                                                            <DotLoader
                                                                                                                color={'#1F254B'}
                                                                                                                // loading={loading}
                                                                                                                cssOverride={override}
                                                                                                                size={25}
                                                                                                                aria-label="Loading Spinner"
                                                                                                                data-testid="loader"
                                                                                                            />
                                                                                                        </div>
                                                                                                    }
                                                                                                    {requestStates[index]?.status === 'success' &&
                                                                                                        <div className="flex items-center justify-center">
                                                                                                            <TickCircle
                                                                                                                className='text-greenMid'
                                                                                                                size="32"
                                                                                                            />
                                                                                                        </div>
                                                                                                    }
                                                                                                    {requestStates[index]?.status === 'error' &&
                                                                                                        <div className="flex items-center justify-center">
                                                                                                            <ShieldCross
                                                                                                                className='text-rexRed'
                                                                                                                size="32"
                                                                                                            />
                                                                                                        </div>
                                                                                                    }
                                                                                                </>
                                                                                            ) : (
                                                                                                <div>...</div>
                                                                                            )}
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        {calculated && calculationResults[index] && !isNaN(calculationResults[index].price + calculationResults[index].fuel) && (
                                                                                            <div className='my-2 text-[#007599] text-sm font-semibold'>
                                                                                                Ağırlığı girilen ve fiyatı hesaplanan 1 gönderiniz için ödenmesi gereken toplam tahmini tutar
                                                                                                <span className='font-bold text-md text-headerPrimary'>
                                                                                                    {` ${(calculationResults[index].price + calculationResults[index].fuel).toFixed(2)} `}
                                                                                                </span>
                                                                                                USD dir. Gerçek fiyat son tartımdan sonra hesaplanacaktır.
                                                                                            </div>
                                                                                        )}

                                                                                    </tr>
                                                                                    <tr>{requestStates[index].status === 'error' && (
                                                                                        errorElement(requestStates[index].errorMessage)
                                                                                    )}</tr>
                                                                                    {detailsVisible[index] && (
                                                                                        <tr>
                                                                                            <td className='col-span-6'>
                                                                                                <div className="float-right p-4 rounded-lg">
                                                                                                    <div className={classNames(
                                                                                                        detailsVisible[index] ? 'block' : 'hidden',
                                                                                                        ''
                                                                                                    )}>
                                                                                                        <FieldArray name={`orders[${index}].items`}>
                                                                                                            {({ insert: insertItem, remove: removeItem, push: pushItem }) => (
                                                                                                                <>
                                                                                                                    {order.items.map((item, itemIndex) => (
                                                                                                                        <>
                                                                                                                            <div className='md:hidden text-sm mt-4 text-left'>Kalem-{itemIndex + 1}</div>

                                                                                                                            <div className='grid grid-cols-12 gap-4 mt-2' key={itemIndex}>
                                                                                                                                <div className='flex flex-col items-start justify-center col-span-12 2xl:col-span-4'>
                                                                                                                                    <label htmlFor={`orders[${index}].items[${itemIndex}].declaredQuantity`} className="block text-sm font-medium text-gray-700">Adet</label>
                                                                                                                                    <Field className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" name={`orders[${index}].items[${itemIndex}].declaredQuantity`} type="number" placeholder="Adet" />
                                                                                                                                    <ErrorMessage name={`orders[${index}].items[${itemIndex}].declaredQuantity`} component="div" className="rounded-md bg-red-50 p-1 my-1 text-xs text-rexRed" />
                                                                                                                                </div>
                                                                                                                                <div className='flex flex-col items-start justify-center col-span-12 2xl:col-span-4'>
                                                                                                                                    <label htmlFor={`orders[${index}].items[${itemIndex}].declaredPrice`} className="block text-sm font-medium text-gray-700">Birim Fiyat</label>
                                                                                                                                    <Field className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" name={`orders[${index}].items[${itemIndex}].declaredPrice`} type="number" placeholder="Birim Fiyatı" />
                                                                                                                                    <ErrorMessage name={`orders[${index}].items[${itemIndex}].declaredPrice`} component="div" className="rounded-md bg-red-50 p-1 my-1 text-xs text-rexRed" />
                                                                                                                                </div>
                                                                                                                                <div className='flex flex-col items-start justify-center col-span-12 2xl:col-span-4'>
                                                                                                                                    <label htmlFor={`orders[${index}].items[${itemIndex}].gtip`} className="block text-sm font-medium text-gray-700">Gtip</label>
                                                                                                                                    <Field className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" name={`orders[${index}].items[${itemIndex}].gtip`} type="text" placeholder="GTIP No" />
                                                                                                                                    <ErrorMessage name={`orders[${index}].items[${itemIndex}].gtip`} component="div" className="rounded-md bg-red-50 p-1 my-1 text-xs text-rexRed" />
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </>

                                                                                                                    ))}
                                                                                                                </>
                                                                                                            )}
                                                                                                        </FieldArray>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </td>
                                                                                        </tr>
                                                                                    )}
                                                                                </>
                                                                            ))}
                                                                        </tbody>
                                                                    </table>
                                                                </div>
                                                            )}
                                                        </FieldArray>
                                                        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                                            <button
                                                                type="submit"
                                                                className="inline-flex w-full justify-center rounded-md bg-headerPrimary px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                                                            >
                                                                {isLoading ? <>
                                                                    <DotLoader
                                                                        color={'#FFFFFF'}
                                                                        className='w-full'
                                                                        // loading={loading}
                                                                        cssOverride={override}
                                                                        size={20}
                                                                        aria-label="Loading Spinner"
                                                                        data-testid="loader"
                                                                    />
                                                                    {t?.('orders.filters.createTicket')}
                                                                </>

                                                                    :
                                                                    <>
                                                                        <AddCircle className='w-4 h-4 inline-block mr-2' />
                                                                        {t?.('orders.filters.createTicket')}
                                                                    </>}
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                                                onClick={() => handleBatchCalculate(values.orders, setFieldError)}
                                                            >
                                                                {calIsLoading ? <>
                                                                    <DotLoader
                                                                        color={'#1F254B'}
                                                                        className='w-full'
                                                                        // loading={loading}
                                                                        cssOverride={override}
                                                                        size={20}
                                                                        aria-label="Loading Spinner"
                                                                        data-testid="loader"
                                                                    />
                                                                    Fiyat Hesapla
                                                                </>
                                                                    :
                                                                    <>
                                                                        <Calculator className='w-4 h-4 inline-block mr-2' />
                                                                        Fiyat Hesapla
                                                                    </>}
                                                            </button>
                                                        </div>
                                                    </Form>
                                                )}
                                            </Formik> : <DotLoaderSpinner loading={isDataLoaded} />}
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
export default CreateTicketModalMultiple

