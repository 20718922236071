import React, { Fragment, useState } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

import { useAuth } from '../../context/AuthContext';
import { ComponentType } from '../../pages/Payment';
import memberService from '../../services/memberService';
import LoadingComponent from '../common/LoadingComponent';
import alertNotification from '../../utils/alertNotification';
import { useLanguage } from '../../context/LanguageContext';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentComponent: React.Dispatch<React.SetStateAction<ComponentType>>;
  userId: string | number;
  currentComponent: ComponentType;
}
const MailConfirmModal: React.FC<Props> = ({
  open,
  setOpen,
  setCurrentComponent,
  userId,
  currentComponent,
}) => {
  const { checkSessionStatus } = useAuth();
  const { t } = useLanguage();
  const [code, setCode] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    setCode(e.currentTarget.value);
  };
  const confirmCode = async () => {
    setLoading(true);
    try {
      await memberService.submitVerificationCode({
        userId: Number(userId),
        verificationCode: code,
      });
      setDisabled(true);
      setOpen(false);
      alertNotification('success', t?.('payment.mailConfirm.successMsg'));
      if (currentComponent === ComponentType.billing) {
        setCurrentComponent(ComponentType.payment);
      } else {
        setCurrentComponent(ComponentType.billing);
      }
      checkSessionStatus?.();
    } catch (error) {
      setDisabled(false);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    confirmCode();
  };

  const resendCode = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setDisabled(true);
    try {
      await memberService.resendVerificationCode(Number(userId));
    } catch (error) {
      setDisabled(false);
    }
  };
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-2 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div className="mt-1 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      Lütfen mail adresinize gelen kodu aşağıdaki alana giriniz.
                    </Dialog.Title>
                    <div className="mt-2"></div>
                  </div>
                </div>
                <div className="mt-5 flex flex-col gap-4 w-full">
                  <form
                    onSubmit={handleSubmit}
                    className="flex justify-between w-full space-x-4"
                  >
                    <input
                      type="text"
                      name="code"
                      id="code"
                      value={code}
                      onChange={handleChange}
                      className="w-full h-full flex flex-col items-center justify-center text-center px-2 outline-none rounded-xl border border-gray-200 text-lg bg-white focus:bg-gray-50 focus:ring-1 focus:ring-headerPrimary"
                    />
                    <button
                      type="submit"
                      className="inline-flex w-full justify-center rounded-md bg-headingPrimary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                    >
                      {loading ? (
                        <div>
                          <LoadingComponent />
                        </div>
                      ) : (
                        'Onayla'
                      )}
                    </button>
                  </form>
                  <button
                    onClick={resendCode}
                    disabled={disabled}
                    type="submit"
                    className="inline-flex w-full justify-center rounded-md bg-headingPrimary px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                  >
                    Tekrar Kod Gönder
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default MailConfirmModal;
