import React, { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query';
import { Edit2, Link2 } from 'iconsax-react';
import { useAuth } from '../../context/AuthContext';
import { useLanguage } from '../../context/LanguageContext';
import { Store } from '../../pages/MarketplaceSuppliersProducts';
import { classNames } from '../../utils/conditionalClasses';
import { seperateNumberWithComma } from '../../utils/numberFormatter';
import LoadingComponent from '../common/LoadingComponent';
import Pagination from '../common/Pagination';
import OrderMatchingModal from '../marketplaceOrders/OrderMatchingModal';
import marketplaceService from '../../services/marketplaceService';
import httpClient from '../../utils/httpClient';
export interface ProductVariant {
    id: string;
    price: string;
    name: string;
    imageList: string[];
}

export interface Product {
    id: string;
    title: string;
    images: string[];
    totalSales: string;
    totalRevenue: string;
    averagePrice: string;
    isImageSaved: boolean;
    supplier_product_variant: ProductVariant | null;
}
export interface SellerProductsTable {
    totalPageCount: number;
    page: number;
    isLoading: boolean;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    inputPage: number | string;
    setInputPage: React.Dispatch<React.SetStateAction<number | string>>;
    data: Product[];
    queryString: string;
}
const ProductsTable: React.FC<SellerProductsTable> = ({
    totalPageCount,
    page,
    setPage,
    isLoading,
    data,
    inputPage,
    setInputPage,
    queryString,
}) => {
    const { t } = useLanguage();
    const { session } = useAuth();
    const tableHeader = [
        { name: 'Resim', column: 'images' },
        { name: 'Başlık', column: 'title' },
        { name: 'Satış adedi', column: 'totalSales' },
        { name: 'Ciro', column: 'totalRevenue' },
        {
            name: 'Ortalama Satış Fiyatı',
            column: 'averagePrice',
        },
        {
            name: 'Ürün Maliyeti',
            column: 'supplier_product_variant',
        },
        {
            name: 'Eşleşen Ürün',
            column: 'matchingProduct',
        },
        {
            name: 'Tasarım Kaydedilsin',
            column: 'saveDesign',
        },
        // {
        //     name: 'Güncelle',
        //     column: 'update',
        // },
    ];
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState<any>(null);

    const [localData, setLocalData] = useState<Product[]>(data)

    const handleCheckboxChange = async (event: React.ChangeEvent<HTMLInputElement>, sellerProductId: number) => {
        const isChecked = event.target.checked;

        // UI anında güncellensin
        setLocalData((prevData) =>
            prevData.map((prod) =>
                +prod.id === sellerProductId ? { ...prod, isImageSaved: isChecked } : prod
            )
        );

        try {
            // Sunucuya isteği gönder
            await httpClient.patch('/api/v1/marketplace/seller/isImageSaved', {
                sellerProductId,
                isImageSaved: isChecked,
            });
        } catch (error) {
            console.error('Error updating isImageSaved:', error);

            // Hata durumunda UI'yı eski haline getir
            setLocalData((prevData) =>
                prevData.map((prod) =>
                    +prod.id === sellerProductId ? { ...prod, isImageSaved: !isChecked } : prod
                )
            );
        }
    };
    useEffect(() => {
        setLocalData(data)
    }, [data])
    const getOrders = () => {
        if (data) {
            return data; // or perform any other action with the data
        }
    };
    let x = <td className="whitespace-nowrap px-3 py-4 text-center min-w-[100px] text-[18px] text-[#374151] lg:min-w-[150px]">
        {true ?
            <div className='w-full bg-[#faf9fb] border-2 border-[#1D4ED8] text-[#1D4ED8] py-[12px] px-[10px] rounded-[6px] h-[40px] flex items-center justify-center gap-[10px]'>
                <Edit2 color='#1D4ED8' variant='Bold' />
                {/* <span className='text-[14px] font-medium'>Güncelle</span> */}
            </div>
            :
            <span />}
    </td>
    // Modal açıldığında ilgili ürünü seçme işlemi
    const handleProductMatch = (prod: Product) => {
        setSelectedOrder({
            id: prod.id,
            seller_order_items: [
                {
                    id: prod.id,
                    seller_product: {
                        id: prod.id,
                        title: prod.title,
                        images: prod.images,
                        attributes: [{ Price: Number(prod.averagePrice).toFixed(2) }],
                    },
                },
            ],
        });
        setIsModalOpen(true);
    };

    const calculateWithCommissionPrice = (price: number, packageName: string) => {
        let withCommissionPrice = price;

        if (packageName === 'Elit') {
            withCommissionPrice = withCommissionPrice * 1.05;
        } else if (packageName === 'Rexven Premium') {
            withCommissionPrice = withCommissionPrice * 1.1;
        } else if (packageName === 'Rexven Plus') {
            withCommissionPrice = withCommissionPrice * 1.15;
        } else if (packageName === 'Free') {
            withCommissionPrice = withCommissionPrice * 1.2;
        }

        return withCommissionPrice.toFixed(2);
    };
    return (
        <div className="hidden lg:flex flex-col flex-shrink-0 mt-2 relative">
            <div className="flow-root">
                <div className="relative -mx-4 -my-2 overflow-x-auto overflow-y-auto h-[60vh] scrollbar-hide">
                    <div className="inline-block min-w-full py-2 align-middle  sm:px-6 lg:px-8">
                        <table className="min-w-full divide-y divide-gray-300  table-fixed">
                            <thead>
                                <tr>
                                    {tableHeader.map((item, index) => {
                                        return (
                                            <th
                                                key={index}
                                                className="sticky top-0 z-10 text-center bg-[#111827] py-3.5 pl-4 pr-3 text-sm font-semibold text-white backdrop-blur backdrop-filter "
                                            // scope="col"
                                            >
                                                {' '}
                                                <p className="text-xs md:text-sm">{item.name}</p>
                                            </th>
                                        );
                                    })}
                                </tr>
                            </thead>

                            {isLoading ? (
                                <div className="w-full flex justify-center">
                                    <LoadingComponent />
                                </div>
                            ) : (
                                <tbody className="divide-y divide-gray-200 bg-white">
                                    {data && data?.map((prod: Product, idx) => {
                                        const localProduct = localData && localData?.find((item) => item.id === prod.id);
                                        return (
                                            <tr
                                                key={idx}
                                                className={classNames(
                                                    'bg-[#F9FAFB]'
                                                )}
                                            >
                                                <td className="py-4 pl-4 text-xs md:text-sm font-medium text-center text-gray-900 sm:pl-0 lg:min-w-[275px]">
                                                    <img
                                                        className="border border-[#D1D5DB] rounded-[24px] h-[150px] w-[150px] object-fit object-center mx-auto"
                                                        src={`${prod.images[0]}`}
                                                        alt="imagenotfound"
                                                    />
                                                </td>
                                                <td className="text-[#374151] text-[18px] font-medium text-center lg:min-w-[350px]">

                                                    {prod.title}
                                                </td>

                                                <td className="whitespace-nowrap px-3 py-4 text-center text-[18px] text-[#374151] lg:min-w-[150px]">

                                                    {prod.totalSales}
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-center text-[18px] min-w-52 text-[#374151] lg:min-w-[150px]">
                                                    <p className="px-6">
                                                        ${' '}
                                                        {prod.totalRevenue ? (
                                                            <span className="">{`${Number(
                                                                prod.totalRevenue
                                                            ).toFixed(2)}`}</span>
                                                        ) : (
                                                            '-'
                                                        )}
                                                    </p>
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-center text-[18px] w-52 text-[#374151] lg:min-w-[150px] ">
                                                    ${' '}
                                                    {prod.averagePrice ? (
                                                        <span className="">{`${Number(
                                                            prod.averagePrice
                                                        ).toFixed(2)}`}</span>
                                                    ) : (
                                                        '-'
                                                    )}
                                                </td>

                                                <td className="whitespace-nowrap px-3 py-4 text-center text-[18px] text-[#374151] lg:min-w-[150px]">
                                                    {/* <span className="">
                                                        {prod.supplier_product_variant
                                                            ? seperateNumberWithComma(Number(prod.supplier_product_variant.price))
                                                            : '-'}
                                                    </span> */}
                                                    <span className="">
                                                        {prod.supplier_product_variant ? (
                                                            seperateNumberWithComma(
                                                                Number(calculateWithCommissionPrice(Number(prod.supplier_product_variant.price), session?.packageName!))
                                                            )
                                                        ) : (
                                                            '-'
                                                        )}
                                                    </span>
                                                </td>
                                                <td className="px-3 py-4 text-center text-[18px] text-[#374151] lg:min-w-[320px]">
                                                    {prod.supplier_product_variant ? (
                                                        <div className='flex flex-row items-center justify-between gap-[8px] max-w-[320px]'>
                                                            <img
                                                                className='h-[100px] w-[100px] rounded-[24px]'
                                                                src={`https://api.rexven.com/images/marketplace/supplier-images/${prod?.supplier_product_variant.imageList[0]}`}
                                                                alt="productImg"
                                                            />
                                                            <span className='text-[#374151] text-[18px] max-w-[200px] overflow-hidden text-ellipsis'>
                                                                {prod.supplier_product_variant.name}
                                                            </span>
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className='cursor-pointer bg-[#faf9fb] border-2 border-[#4F46E5] text-[#4F46E5] py-[12px] px-[10px] rounded-[6px] h-[40px] flex items-center justify-center gap-[10px]'
                                                            onClick={() =>
                                                                session?.isMarketplaceSeller && handleProductMatch(prod)
                                                            }
                                                        >
                                                            <Link2 color='#4F46E5' variant='Bold' />
                                                            <span className='text-[14px] font-medium'>Ürün Eşleştir</span>
                                                        </div>
                                                    )}
                                                </td>

                                                <td className="whitespace-nowrap px-3 py-4 text-center text-[18px] text-[#374151] lg:min-w-[150px]">
                                                    <div className="flex h-6 items-center justify-center">
                                                        <input
                                                            id="comments"
                                                            name="comments"
                                                            type="checkbox"
                                                            aria-describedby="comments-description"
                                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                                            // checked={prod.isImageSaved}
                                                            checked={localProduct ? localProduct.isImageSaved : prod.isImageSaved}
                                                            onChange={(e) => session?.isMarketplaceSeller && handleCheckboxChange(e, +prod.id)}
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    {/* {!session?.isMarketplaceSeller &&
                                        Array.from({ length: 97 }).map((_, idx) => (
                                            <tr
                                                key={idx}
                                                className={classNames('blur-sm even:bg-lightGray')}
                                            >
                                                <td className="py-4 pl-4 text-xs md:text-sm font-medium text-center text-gray-900 sm:pl-0 w-16">
                                                    <img
                                                        className="h-10 object-contain w-full"
                                                        src={`https://picsum.photos/${idx}`}
                                                    />
                                                </td>
                                                <td className="px-4 py-4 text-xs md:text-sm text-center text-[#374151]  ">
                                                    Heollaaae
                                                </td>
                                                <td className="whitespace-nowrap text-center px-3 py-4 text-xs md:text-sm text-[#374151] ">
                                                    Tıasdoanskdo
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-center text-xs  text-[#374151] ">
                                                    Jewelry
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-[#374151] ">
                                                    Fiziksel
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-[#374151] ">
                                                    167253178
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-[#374151] ">
                                                    12313
                                                </td>
                                                <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-[#374151] ">
                                                    2323
                                                </td>
                                            </tr>
                                        ))} */}
                                </tbody>
                            )}
                        </table>
                    </div>
                </div>
            </div>
            <Pagination
                setPage={setPage}
                page={page}
                inputPage={inputPage}
                setInputPage={setInputPage}
                totalPageCount={totalPageCount!}
                queryString={queryString}
            />
            <OrderMatchingModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                selectedOrder={selectedOrder}
                getOrders={getOrders}
                withoutCalculate={true}
            />
        </div>
    )
}

export default ProductsTable

