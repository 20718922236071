import React, { useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import alertNotification from '../../utils/alertNotification';
import marketplaceService from '../../services/marketplaceService';
import { useAuth } from '../../context/AuthContext';

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  balance: number;
}
const WalletModal: React.FC<Props> = ({
  isModalOpen,
  setIsModalOpen,
  balance,
}) => {
  const { session } = useAuth();
  const [note, setNote] = useState('');
  const [depositAmount, setDepositAmount] = useState(0);
  const inputRef = useRef<HTMLInputElement>(null);

  const sendDepositRequest = async () => {
    try {
      marketplaceService.createWalletDeposit({
        amount: depositAmount,
        note: note ? note : '',
      });
      alertNotification('success', 'Para yükleme talebi başarılı');
      setIsModalOpen(false);
      setDepositAmount(0);
      setNote('');
    } catch (error: any) {
      alertNotification('error', error.response.data.message);
    }
  };
  useEffect(() => {
    if (isModalOpen && inputRef.current) {
      inputRef?.current.focus();
    }
    if (!isModalOpen) {
      return;
    }
  }, [isModalOpen]);
  return (
    <Transition show={isModalOpen} as={React.Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setIsModalOpen(false)}
      >
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75" />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-3xl 3xl:max-w-5xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-medium text-start py-4 leading-6"
                >
                  <p className="text-xl font-bold text-headerPrimary">
                    Cüzdan:{' '}
                    <span className="text-4xl font-bold text-[#059669]">
                      ${Number(balance).toFixed(2)}
                    </span>
                  </p>
                </Dialog.Title>

                <div className="rounded-xl flex items-center space-x-3 bg-[#F9FAFB] text-headerPrimary p-4">
                  <div className="flex-1">
                    Amerika Hesabı (Amerika'dan gönderim yapacaksanız bu hesaba
                    gönderim yapınız)
                    <p className="font-bold mb-1">
                      Hesap Sahibi:{' '}
                      <span className="font-thin ml-1">ANVENTURE LLC</span>{' '}
                    </p>
                    <p className="font-bold">
                      ACH ve havale yönlendirme numarası:
                      <span className="font-thin ml-1">026073150</span>
                    </p>
                    <p className="font-bold">
                      Hesap numarası:
                      <span className="font-thin ml-1">822000589258</span>
                    </p>
                    <p className="font-bold">
                      Hesap türü:
                      <span className="font-thin ml-1">Checking</span>
                    </p>
                    <p className="font-bold">
                      Wise'ın adresi:
                      <span className="font-thin ml-1">
                        30 W. 26th Street, Sixth Floor New York NY 10010 United
                        States
                      </span>
                    </p>
                  </div>

                  <div className="flex-1">
                    Diğer Hesap (Amerika dışındaki ülkelerden gönderim
                    yapacaksanız bu hesaba gönderim yapınız)
                    <p className="font-bold mb-1">
                      Hesap Sahibi:{' '}
                      <span className="font-thin ml-1">ANVENTURE LLC</span>{' '}
                    </p>
                    <p className="font-bold">
                      Yönlendirme numarası:
                      <span className="font-thin ml-1">026073150</span>
                    </p>
                    <p className="font-bold">
                      Swift/BIC:
                      <span className="font-thin ml-1">CMFGUS33</span>
                    </p>
                    <p className="font-bold">
                      Hesap numarası:
                      <span className="font-thin ml-1">822000589258</span>
                    </p>
                    <p className="font-bold">
                      Wise'ın adresi:
                      <span className="font-thin ml-1">
                        30 W. 26th Street, Sixth Floor New York NY 10010 United
                        States
                      </span>
                    </p>
                  </div>
                </div>
                <div className="mt-8">
                  <p className="font-bold text-headerPrimary">
                    Yükleme İstediğiniz Miktar
                  </p>
                  <input
                    type="text"
                    ref={inputRef}
                    onChange={(e) => setDepositAmount(+e.target.value)}
                    name="balance"
                    id="balance"
                    className="border-[#6B7280] rounded-md block mb-4 py-1"
                    placeholder="$0"
                  />
                  <p className="font-bold text-rexRed">ÖNEMLİ: </p>
                  <p>
                    Para gönderirken{' '}
                    <span className="text-[#2563EB] font-bold">
                      {session?.id}
                    </span>{' '}
                    olan müşteri numaranızı açıklama bölümüne yazınız
                  </p>
                  <p className="font-thin ml-1 mb-1">
                    Anventure LLC, Rexven'in Amerika'daki şirketidir
                  </p>
                  {/* <textarea
                    name="note"
                    id=""
                    onChange={(e) => setNote(e.target.value)}
                    rows={4}
                    placeholder="Açıklama giriniz."
                    className="border-none rounded-lg bg-lightGray focus:outline-none text-xs placeholder:text-xs w-full"
                  /> */}
                  <div className="flex justify-end">
                    <button
                      onClick={() => sendDepositRequest()}
                      className="bg-[#2563EB]  hover:bg-blue-700 text-white py-2 px-2 rounded-lg"
                    >
                      Onaya Gönder
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default WalletModal;
