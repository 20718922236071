import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import alertNotification from '../../utils/alertNotification';
import memberService from '../../services/memberService';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  producerId?: number;
  productId?: number;
  updateProducerStats: (
    productId: number,
    incrementType: 'view' | 'communication'
  ) => void;
}

const MessageModal: React.FC<Props> = ({
  open,
  setOpen,
  producerId,
  productId,
  updateProducerStats,
}) => {
  const [message, setMessage] = useState('');

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.currentTarget.value);
  };

  const sendMessage = async () => {
    try {
      await memberService.sendMessage({ message, producerId, productId });
      alertNotification('success', 'Mesajınız iletilmiştir');
      await updateProducerStats(
        productId ? productId : producerId!,
        'communication'
      );

      setOpen(false);
    } catch (error: any) {
      alertNotification('error', error.response.data.message);
    }
  };
  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    sendMessage();
    setMessage('');
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-md sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:my-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      Ürün ile ilgili talebinizi yazınız
                    </Dialog.Title>
                  </div>
                  <form
                    className="flex flex-col justify-center items-center"
                    onSubmit={handleSubmit}
                  >
                    <textarea
                      rows={5}
                      name="message"
                      id="message"
                      onChange={handleChange}
                      value={message}
                      placeholder="Kedi yatağı yaptırmak istiyorum. Boyutları 30 cm * 45 cm * 10 cm olsun. Ağırlığı en fazla 1 kilogram olsun. Ürünlerin Amerika'daki depoma gönderilmesini istiyorum."
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    <button
                      disabled={message.length < 1}
                      type="submit"
                      className="text-white bg-headerPrimary rounded-md font-medium w-[200px] text-sm px-4 py-2 md:py-2.5 mt-8"
                    >
                      Mesaj Gönder
                    </button>
                  </form>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default MessageModal;
