import React, { useState } from 'react';

import { PlusIcon } from '@heroicons/react/24/outline';

import { IMAGE_URL } from '../../config';
import { useAuth } from '../../context/AuthContext';
import { useLanguage } from '../../context/LanguageContext';
import memberService from '../../services/memberService';
import ProfileImageModal from '../common/ProfileImageModal';
import ProggressBar from '../common/ProggressBar';

const ProfileHeader = () => {
  const { session } = useAuth();
  const [open, setOpen] = useState(false);
  const { t } = useLanguage();

  const discordLogin = async () => {
    let response = await memberService.discordRefresh();
    //redirect to discord

    window.location.href = response.data;
    //window.location.reload();
  };
  let percent = session!.level * 20;

  return (
    <div className="flex flex-col flex-shrink-0 bg-white rounded-xl p-5 shadow-lg sm:p-7 sm:flex-row">
      <div className="flex flex-1 pb-2 sm:p-0">
        <div className="relative w-20 sm:w-40">
          <img
            className="rounded-xl w-20 sm:w-40 aspect-square"
            src={
              session?.profileImage
                ? `${IMAGE_URL}${session?.profileImage}`
                : `https://api.dicebear.com/5.x/bottts/svg?seed=${session?.firstName + ' ' + session?.lastName
                }`
            }
            alt=""
          />

          <div
            className="bg-[#50CD89] h-6 w-6 sm:h-10 sm:w-10 absolute top-5 -right-3 sm:-right-4 flex justify-center cursor-pointer items-center z-10 rounded-full"
            onClick={() => setOpen(true)}
          >
            {<PlusIcon color="white" height={48} width={48} />}
          </div>
        </div>

        <div className="flex flex-col px-5 sm:px-7">
          <h3 className="font-medium text-headingPrimary text-md sm:text-xl">
            {session?.firstName} {session?.lastName}
          </h3>
          <p className="text-headingSecondary text-xs sm:text-sm">
            {session?.email}
          </p>
        </div>
      </div>

      <div className="flex flex-1 flex-col justify-end">
        <div className="flex justify-end mb-10">
          {session?.discordUserId === null ? (
            <div className="justify-end">
              <p className="mb-5 text-xs lg:text-sm text-red-500">
                {t?.('profile.discordWarning')}
              </p>
              <p className="flex justify-end">
                <button
                  onClick={discordLogin}
                  className="hover:cursor-pointer rounded-md bg-headerPrimary py-2.5 px-8 text-xs font-semibold text-white shadow-sm hover:bg-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 md:text-sm"
                >
                  {t?.('profile.discordBtn')}
                </button>
              </p>
            </div>
          ) : (
            <div className="justify-end">
              <p className="mb-5">{session?.discordUserId}</p>
              <p className="flex justify-end">
                <button
                  onClick={discordLogin}
                  className="hover:cursor-pointer rounded-md bg-headerPrimary py-2.5 px-8 text-xs font-semibold text-white shadow-sm hover:bg-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 md:text-sm"
                >
                  {t?.('profile.refreshDiscord')}
                </button>
              </p>
            </div>
          )}
        </div>

        <div className="flex justify-between">
          <p className="text-headingSecondary text-xs pb-2 sm:text-sm ">
            {t?.('profile.progress')}
          </p>
          <p className="font-medium">{percent}%</p>
        </div>
        <ProggressBar percent={percent} />
      </div>
      <ProfileImageModal open={open} setOpen={setOpen} />
    </div>
  );
};

export default ProfileHeader;
