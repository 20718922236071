import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useLanguage } from '../../../../context/LanguageContext';

import { IAsinData } from '../../../../pages/AmazonForecast';
import { IResult } from './ResultSection';
import { errorElement } from '../../../../utils/errorElement';
import { Tooltip } from 'react-tooltip';
import { Information } from 'iconsax-react';

interface Props {
  data: IAsinData;
  setResult: React.Dispatch<React.SetStateAction<IResult[]>>;
  setBrandCost: React.Dispatch<React.SetStateAction<number>>;
  setCompanyCost: React.Dispatch<React.SetStateAction<number>>;
  setImageCost: React.Dispatch<React.SetStateAction<number>>;
  setTableResult: React.Dispatch<any>;
  imageCost: number;
  companyCost: number;
  brandCost: number;
}
const ForecastForm: React.FC<Props> = ({
  data,
  setBrandCost,
  setCompanyCost,
  setImageCost,
  setResult,
  setTableResult,
  imageCost,
  companyCost,
  brandCost,
}) => {
  const { t } = useLanguage();
  const [profitResult, setProfitResult] = useState({
    netProfit: 0,
    profitMargin: 0,
    roi: 0,
  });
  const calculateProfit = (
    price: number,
    shippingFee: number,
    productCost: number,
    amazonFee: number,
    fbaFee: number,
    storageFee: number
  ) => {
    const netProfit =
      price - (shippingFee + productCost + amazonFee + fbaFee + storageFee);
    const profitMargin = (netProfit / price) * 100;
    const roi = (netProfit / (shippingFee + productCost)) * 100;
    setProfitResult({ netProfit, profitMargin, roi });
  };

  const calculateStorage = (
    index: number,
    sales: number,
    increaseRate: number,
    storageFee: number
  ) => {
    switch (index) {
      case 1:
      case 4:
      case 7:
      case 10:
        return Number(
          (
            (sales +
              sales * (increaseRate + 1) +
              sales * (increaseRate + 1) * (increaseRate + 1)) *
            storageFee
          ).toFixed(2)
        );

      case 2:
      case 5:
      case 8:
      case 11:
        return Number(
          ((sales + sales * (increaseRate + 1)) * storageFee).toFixed(2)
        );

      case 3:
      case 6:
      case 9:
      case 12:
        return Number((sales * storageFee).toFixed(2));

      default:
        break;
    }
  };
  const calculateTwoYears = (
    price: number,
    estimatedSales: number,
    returnRate: number,
    increaseRateOne: number,
    increaseRateTwo: number,
    amazonFee: number,
    shippingCost: number,
    fbaFee: number,
    productCost: number,
    storageFee: number,
    tacos: number,
    tacos2: number,
    tacos3: number,
    tacos4: number,
    brandCost: number,
    companyCost: number,
    productImageCost: number
  ) => {
    const arrayOne: any = [];
    const arrayTwo: any = [];
    const tableResultOne = [];
    const tableResultTwo = [];

    //FIRST YEAR CALCULATION
    for (let i = 1; i < 13; i++) {
      let sales =
        arrayOne.length > 0
          ? Math.round(arrayOne[i - 2].sales * (1 + increaseRateOne / 100))
          : estimatedSales;
      let actualTacos = i < 7 ? tacos : tacos2;

      arrayOne.push({
        sales: Number(sales),
        revenue: Number(sales * price).toFixed(2),
        return: Number(((sales * returnRate) / 100) * fbaFee).toFixed(2),
        productCost: Number(productCost * sales).toFixed(2),
        shippingCost: Number(sales * shippingCost).toFixed(2),
        fbaFee: Number(fbaFee * sales).toFixed(2),
        amazonFee: Number(amazonFee * sales).toFixed(2),
        storageFee: calculateStorage(
          i,
          sales,
          increaseRateOne / 100,
          storageFee
        ),
        ppcFee: ((sales * price * actualTacos) / 100).toFixed(2),
        giveaway: i < 4 ? (sales * 0.15 * 5).toFixed(2) : 0,
        review: i < 4 ? (sales * 0.1 * 8).toFixed(2) : 0,
        brandCost: i === 1 ? brandCost : 0,
        companyCost: i === 1 ? companyCost : 0,
        imageCost: i === 1 ? productImageCost : 0,
      });
    }

    //SECOND YEAR CALCULATION
    for (let i = 1; i < 13; i++) {
      let sales =
        arrayTwo.length > 0
          ? Math.round(arrayTwo[i - 2].sales * (1 + increaseRateTwo / 100))
          : Math.round(
              arrayOne[arrayOne.length - 1].sales * (1 + increaseRateTwo / 100)
            );

      let actualTacos = i < 7 ? tacos3 : tacos4;

      arrayTwo.push({
        sales: Number(sales),
        revenue: Number((sales * price).toFixed(2)),
        return: Number((((sales * returnRate) / 100) * fbaFee).toFixed(2)),
        productCost: Number((productCost * sales).toFixed(2)),
        shippingCost: Number((sales * shippingCost).toFixed(2)),
        fbaFee: Number((fbaFee * sales).toFixed(2)),
        amazonFee: Number((amazonFee * sales).toFixed(2)),
        storageFee: calculateStorage(
          i,
          sales,
          increaseRateTwo / 100,
          storageFee
        ),
        ppcFee: Number(((sales * price * actualTacos) / 100).toFixed(2)),
        giveaway: 0,
        review: 0,
        brandCost: 0,
        companyCost: 0,
        imageCost: 0,
      });
    }

    const firstYearRevenue = arrayOne.reduce(
      (a: number, b: any) => a + Number(b.revenue),
      0
    );

    const firstYearProfitArray = arrayOne.map((item: any) => {
      let res =
        Number(item.revenue) -
        Number(item.return) -
        Number(item.productCost) -
        Number(item.shippingCost) -
        Number(item.fbaFee) -
        Number(item.amazonFee) -
        Number(item.storageFee) -
        Number(item.ppcFee) -
        Number(item.giveaway) -
        Number(item.review) -
        Number(item.brandCost) -
        Number(item.companyCost) -
        Number(item.imageCost);
      return Number(res.toFixed(2));
    });
    const firstYearProfit = firstYearProfitArray.reduce(
      (a: number, b: any) => a + Number(b),
      0
    );
    firstYearProfitArray.push(Number(firstYearProfit.toFixed(2)));

    const firstYearCostArray = arrayOne.map((item: any) => {
      let res =
        Number(item.return) +
        Number(item.productCost) +
        Number(item.shippingCost) +
        Number(item.fbaFee) +
        Number(item.amazonFee) +
        Number(item.storageFee) +
        Number(item.ppcFee) +
        Number(item.giveaway) +
        Number(item.review) +
        Number(item.brandCost) +
        Number(item.companyCost) +
        Number(item.imageCost);
      return Number(res.toFixed(2));
    });

    const firstYearCost = firstYearCostArray.reduce(
      (a: number, b: any) => a + Number(b),
      0
    );
    firstYearCostArray.push(firstYearCost);

    const secondYearRevenue = arrayTwo.reduce(
      (a: number, b: any) => a + Number(b.revenue),
      0
    );

    //FIRST YEAR TOTAL CALCULATION
    const salesArrayOne = arrayOne.map((item: any) => item.sales);
    const salesTotalOne = salesArrayOne.reduce(
      (a: number, b: any) => a + Number(b),
      0
    );
    salesArrayOne.push(Number(salesTotalOne.toFixed(2)));
    salesArrayOne.unshift('Satış Sayısı');

    const productCostOneArray = arrayOne.map((item: any) =>
      Number(item.productCost)
    );
    const productCostOne = productCostOneArray.reduce(
      (a: number, b: any) => a + Number(b),
      0
    );
    productCostOneArray.push(Number(productCostOne.toFixed(2)));
    productCostOneArray.unshift('Üretim Maliyeti');

    const shippingCostOneArray = arrayOne.map((item: any) =>
      Number(item.shippingCost)
    );
    const shippingCostOne = shippingCostOneArray.reduce(
      (a: number, b: any) => a + Number(b),
      0
    );
    shippingCostOneArray.push(Number(shippingCostOne.toFixed(2)));
    shippingCostOneArray.unshift('Kargo');
    const giveawayOneArray = arrayOne.map((item: any) => Number(item.giveaway));
    const giveawayOne = giveawayOneArray.reduce(
      (a: number, b: any) => a + Number(b),
      0
    );
    giveawayOneArray.push(Number(giveawayOne.toFixed(2)));
    giveawayOneArray.unshift('Giveaway');
    const reviewOneArray = arrayOne.map((item: any) => Number(item.review));
    const reviewOne = reviewOneArray.reduce(
      (a: number, b: any) => a + Number(b),
      0
    );
    reviewOneArray.push(Number(reviewOne.toFixed(2)));
    reviewOneArray.unshift('Yorum');

    const revenueOneArray = arrayOne.map((item: any) => Number(item.revenue));
    const revenueOne = revenueOneArray.reduce(
      (a: number, b: any) => a + Number(b),
      0
    );
    revenueOneArray.push(Number(revenueOne.toFixed(2)));
    revenueOneArray.unshift('Satış Geliri');
    const storageCostOneArray = arrayOne.map((item: any) =>
      Number(item.storageFee).toFixed(2)
    );
    const storageCostOne = storageCostOneArray.reduce(
      (a: number, b: any) => a + Number(b),
      0
    );
    storageCostOneArray.push(Number(storageCostOne.toFixed(2)));
    storageCostOneArray.unshift('Depolama Maliyeti');

    const returnFeeOneArray = arrayOne.map((item: any) => Number(item.return));
    const returnFeeOne = returnFeeOneArray.reduce(
      (a: number, b: any) => a + Number(b),
      0
    );
    returnFeeOneArray.push(Number(returnFeeOne.toFixed(2)));
    returnFeeOneArray.unshift('İadeler');
    const amazonFeeOneArray = arrayOne.map((item: any) =>
      Number(item.amazonFee)
    );
    const amazonFeeOne = amazonFeeOneArray.reduce(
      (a: number, b: any) => a + Number(b),
      0
    );
    amazonFeeOneArray.push(Number(amazonFeeOne.toFixed(2)));
    amazonFeeOneArray.unshift('Amazon Komisyonu');

    const fbaFeeOneArray = arrayOne.map((item: any) => Number(item.fbaFee));
    const fbaFeeOne = fbaFeeOneArray.reduce(
      (a: number, b: any) => a + Number(b),
      0
    );
    fbaFeeOneArray.push(Number(fbaFeeOne.toFixed(2)));
    fbaFeeOneArray.unshift('FBA Fee');

    const ppcFeeOneArray = arrayOne.map((item: any) => Number(item.ppcFee));
    const ppcFeeOne = ppcFeeOneArray.reduce(
      (a: number, b: any) => a + Number(b),
      0
    );
    ppcFeeOneArray.push(Number(ppcFeeOne.toFixed(2)));
    ppcFeeOneArray.unshift('Reklam - PPC');

    const firstYearInvestment =
      productCostOne +
      shippingCostOne +
      giveawayOne +
      reviewOne +
      companyCost +
      brandCost +
      productImageCost;

    const totalCostofFirstYear =
      firstYearInvestment +
      returnFeeOne +
      amazonFeeOne +
      fbaFeeOne +
      storageCostOne +
      ppcFeeOne;

    const profitOfFirstYear = firstYearRevenue - totalCostofFirstYear;

    const firstYearNetInvestment = firstYearInvestment - profitOfFirstYear;

    tableResultOne.push(
      salesArrayOne,
      revenueOneArray,
      returnFeeOneArray,
      productCostOneArray,
      shippingCostOneArray,
      fbaFeeOneArray,
      amazonFeeOneArray,
      storageCostOneArray,
      ppcFeeOneArray,
      giveawayOneArray,
      reviewOneArray,
      firstYearCostArray,
      firstYearProfitArray
    );

    const firstYearResult = {
      revenue: revenueOne,
      profit: profitOfFirstYear,
      profitMargin: (profitOfFirstYear / revenueOne) * 100,
      investment: firstYearInvestment,
      netInvestment: firstYearNetInvestment,
      brandValue: profitOfFirstYear * 5,
    };

    //SECOND YEAR TOTAL CALCULATION
    const salesArrayTwo = arrayTwo.map((item: any) => item.sales);
    const salesTwo = salesArrayTwo.reduce(
      (a: number, b: any) => a + Number(b),
      0
    );
    salesArrayTwo.push(Number(salesTwo.toFixed(2)));

    salesArrayTwo.unshift('Satış Sayısı');

    const secondYearProfitArray = arrayTwo.map((item: any) => {
      let res =
        Number(item.revenue) -
        Number(item.return) -
        Number(item.productCost) -
        Number(item.shippingCost) -
        Number(item.fbaFee) -
        Number(item.amazonFee) -
        Number(item.storageFee) -
        Number(item.ppcFee) -
        Number(item.giveaway) -
        Number(item.review) -
        Number(item.brandCost) -
        Number(item.companyCost) -
        Number(item.imageCost);

      return Number(res.toFixed(2));
    });

    const secondYearProfit = secondYearProfitArray.reduce(
      (a: number, b: any) => a + Number(b),
      0
    );

    secondYearProfitArray.push(Number(secondYearProfit.toFixed(2)));

    const secondYearCostArray = arrayTwo.map((item: any) => {
      let res =
        item.return +
        item.productCost +
        item.shippingCost +
        item.fbaFee +
        item.amazonFee +
        item.storageFee +
        item.ppcFee +
        item.giveaway +
        item.review +
        item.brandCost +
        item.companyCost +
        item.imageCost;

      return Number(res.toFixed(2));
    });
    const secondYearCost = secondYearCostArray.reduce(
      (a: number, b: any) => a + Number(b),
      0
    );

    secondYearCostArray.push(Number(secondYearCost.toFixed(2)));

    const productCostTwoArray = arrayTwo.map((item: any) =>
      Number(item.productCost)
    );
    const productCostTwo = productCostTwoArray.reduce(
      (a: number, b: any) => a + Number(b),
      0
    );
    productCostTwoArray.push(productCostTwo);
    productCostTwoArray.unshift('Üretim Maliyeti');
    const shippingCostTwoArray = arrayTwo.map((item: any) =>
      Number(item.shippingCost)
    );
    const shippingCostTwo = shippingCostTwoArray.reduce(
      (a: number, b: any) => a + Number(b),
      0
    );

    shippingCostTwoArray.push(shippingCostTwo);
    shippingCostTwoArray.unshift('Kargo');

    const giveawayTwoArray = arrayTwo.map((item: any) => Number(item.giveaway));
    const giveawayTwo = giveawayTwoArray.reduce(
      (a: number, b: any) => a + Number(b),
      0
    );

    giveawayTwoArray.push(giveawayTwo);
    giveawayTwoArray.unshift('Giveaway');
    const reviewTwoArray = arrayTwo.map((item: any) => Number(item.review));
    const reviewTwo = reviewTwoArray.reduce(
      (a: number, b: any) => a + Number(b),
      0
    );

    reviewTwoArray.push(reviewTwo);
    reviewTwoArray.unshift('Yorum');
    const revenueTwoArray = arrayTwo.map((item: any) => Number(item.revenue));
    const revenueTwo = revenueTwoArray.reduce(
      (a: number, b: any) => a + Number(b),
      0
    );

    revenueTwoArray.push(revenueTwo);
    revenueTwoArray.unshift('Satış Geliri');
    const storageCostTwoArray = arrayTwo.map((item: any) =>
      Number(item.storageFee).toFixed(2)
    );
    const storageCostTwo = storageCostTwoArray.reduce(
      (a: number, b: any) => a + Number(b),
      0
    );

    storageCostTwoArray.push(storageCostTwo);
    storageCostTwoArray.unshift('Depolama Maliyeti');

    const returnFeeTwoArray = arrayTwo.map((item: any) => Number(item.return));
    const returnFeeTwo = returnFeeTwoArray.reduce(
      (a: number, b: any) => a + Number(b),
      0
    );

    returnFeeTwoArray.push(returnFeeTwo);
    returnFeeTwoArray.unshift('İadeler');
    const amazonFeeTwoArray = arrayTwo.map((item: any) =>
      Number(item.amazonFee)
    );
    const amazonFeeTwo = amazonFeeTwoArray.reduce(
      (a: number, b: any) => a + Number(b),
      0
    );

    amazonFeeTwoArray.push(amazonFeeTwo);
    amazonFeeTwoArray.unshift('Amazon Komisyonu');

    const fbaFeeTwoArray = arrayTwo.map((item: any) => Number(item.fbaFee));
    const fbaFeeTwo = fbaFeeTwoArray.reduce(
      (a: number, b: any) => a + Number(b),
      0
    );

    fbaFeeTwoArray.push(fbaFeeTwo);
    fbaFeeTwoArray.unshift('FBA Fee');
    const ppcFeeTwoArray = arrayTwo.map((item: any) => Number(item.ppcFee));
    const ppcFeeTwo = ppcFeeTwoArray.reduce(
      (a: number, b: any) => a + Number(b),
      0
    );

    ppcFeeTwoArray.push(ppcFeeTwo);
    ppcFeeTwoArray.unshift('Reklam - PPC');

    const secondYearInvestment =
      shippingCostTwo + giveawayTwo + reviewTwo + fbaFeeTwo;

    const totalCostofSecondYear =
      productCostTwo +
      secondYearInvestment +
      returnFeeTwo +
      amazonFeeTwo +
      storageCostTwo +
      ppcFeeTwo;

    const profitOfSecondYear = secondYearRevenue - totalCostofSecondYear;

    const secondYearNetInvestment = secondYearInvestment - profitOfSecondYear;

    tableResultTwo.push(
      salesArrayTwo,
      revenueTwoArray,
      returnFeeTwoArray,
      productCostTwoArray,
      shippingCostTwoArray,
      fbaFeeTwoArray,
      amazonFeeTwoArray,
      storageCostTwoArray,
      ppcFeeTwoArray,
      giveawayTwoArray,
      reviewTwoArray,
      secondYearCostArray,
      secondYearProfitArray
    );

    setTableResult([tableResultOne, tableResultTwo]);
    const secondYearResult = {
      revenue: revenueTwo,
      profit: profitOfSecondYear,
      profitMargin: (profitOfSecondYear / revenueTwo) * 100,
      investment: secondYearInvestment,
      netInvestment: secondYearNetInvestment,
      brandValue: profitOfSecondYear * 5,
    };
    setResult([firstYearResult, secondYearResult]);
  };

  const submit = (values: any) => {
    const {
      price,
      shippingFee,
      productCost,
      amazonFee,
      fbaFee,
      storageFee,
      estimatedSales,
      returnRate,
      increaseRateOne,
      increaseRateTwo,
      tacosSixMonths,
      tacosTwelveMonths,
      tacosEighteenMonths,
      tacosTwentyFourMonths,
      productImageCost,
      companyCost,
      brandCost,
    } = values;

    calculateProfit(
      Number(price),
      Number(shippingFee),
      Number(productCost),
      Number(amazonFee),
      Number(fbaFee),
      Number(storageFee)
    );
    calculateTwoYears(
      Number(price),
      Number(estimatedSales),
      Number(returnRate),
      Number(increaseRateOne),
      Number(increaseRateTwo),
      Number(amazonFee),
      Number(shippingFee),
      Number(fbaFee),
      Number(productCost),
      Number(storageFee),
      Number(tacosSixMonths),
      Number(tacosTwelveMonths),
      Number(tacosEighteenMonths),
      Number(tacosTwentyFourMonths),
      Number(brandCost || 0),
      Number(companyCost || 0),
      Number(productImageCost || 0)
    );
  };

  const formik = useFormik({
    initialValues: {
      price: data?.data?.price.toFixed(2) || '',
      storageFee: data?.data?.storageFee || '',
      shippingFee: '',
      estimatedSales: 50,
      productCost: '',
      amazonFee: data?.data?.amazonFee.toFixed(2) || '',
      fbaFee: data?.data?.fbaFee.toFixed(2) || '',
      tacosSixMonths: '25',
      tacosTwelveMonths: '20',
      tacosEighteenMonths: '15',
      tacosTwentyFourMonths: '10',
      returnRate: '5',
      increaseRateOne: '25',
      increaseRateTwo: '10',
      productImageCost: '250',
      companyCost: '1000',
      brandCost: '1000',
    },
    onSubmit: submit,
    validationSchema: Yup.object().shape({
      price: Yup.number()
        .required(t?.('validation.required'))
        .min(1, t?.('formFilter.aliFormPrice')),
      shippingFee: Yup.number().required(t?.('validation.required')),
      productCost: Yup.number().required(t?.('validation.required')),
    }),
  });

  return (
    <div className="rounded-xl shadow-lg bg-white p-4 w-full lg:w-1/2 mb-4 md:mb-0">
      <form
        className="grid grid-cols-12 py-4 gap-3"
        onSubmit={formik.handleSubmit}
      >
        <div className="col-span-12 md:col-span-6">
          <div className="flex justify-between items-center mb-2">
            <label htmlFor="estimatedSales" className="text-xs xl:text-sm">
              İlk Ay Tahmini Satış
            </label>
            <input
              type="text"
              name="estimatedSales"
              value={formik.values.estimatedSales}
              onChange={formik.handleChange}
              className="border border-[#6F7AC3] text-xs xl:text-sm rounded-lg w-16 py-1 placeholder:text-lightGray"
              placeholder="$5"
            />
          </div>
          <div className="flex justify-between items-center mb-2">
            <label htmlFor="companyCost" className="text-xs xl:text-sm">
              Şirket Kurulumu
            </label>
            <div className="flex items-center">
              <p className="mr-1 text-sm">$</p>
              <input
                type="text"
                name="companyCost"
                value={companyCost}
                onChange={(e) => {
                  formik.setFieldValue(
                    'companyCost',
                    Number(e.currentTarget.value)
                  );
                  setCompanyCost(Number(e.currentTarget.value));
                }}
                className="border border-[#6F7AC3] text-xs xl:text-sm rounded-lg w-16 py-1 placeholder:text-lightGray"
                placeholder="$5"
              />
            </div>
          </div>
          <div className="flex justify-between items-center mb-2">
            <label htmlFor="brandCost" className="text-xs xl:text-sm">
              Marka Tescili
            </label>
            <div className="flex items-center">
              <p className="mr-1 text-sm">$</p>
              <input
                type="text"
                name="brandCost"
                value={brandCost}
                onChange={(e) => {
                  formik.setFieldValue('brandCost', e.currentTarget.value);
                  setBrandCost(Number(e.currentTarget.value));
                }}
                className="border border-[#6F7AC3] text-xs xl:text-sm rounded-lg w-16 py-1 placeholder:text-lightGray"
                placeholder="$5"
              />
            </div>
          </div>
          <div className="flex justify-between items-center mb-2">
            <label htmlFor="productImageCost" className="text-xs xl:text-sm">
              Ürün Foto Maliyet
            </label>
            <div className="flex items-center">
              <p className="mr-1 text-sm">$</p>
              <input
                type="text"
                name="productImageCost"
                value={imageCost}
                onChange={(e) => {
                  formik.setFieldValue(
                    'productImageCost',
                    Number(e.currentTarget.value)
                  );
                  setImageCost(Number(e.currentTarget.value));
                }}
                className="border border-[#6F7AC3] text-xs xl:text-sm rounded-lg w-16 py-1 placeholder:text-lightGray"
                placeholder="$5"
              />
            </div>
          </div>
          <div className="flex justify-between items-center mb-2">
            <label
              htmlFor="tacosSixMonths"
              className="text-xs xl:text-sm flex items-center"
              id="tacos1"
            >
              TACOS (0-6 Ay) <Information color="#6F7AC3" size={16} />
            </label>
            <div className="flex items-center">
              <p className="mr-1 text-sm">%</p>
              <input
                type="text"
                name="tacosSixMonths"
                value={formik.values.tacosSixMonths}
                onChange={formik.handleChange}
                className="border border-[#6F7AC3] text-xs xl:text-sm rounded-lg w-16 py-1"
                placeholder="$5"
              />
            </div>
            <Tooltip
              place="right"
              anchorSelect="#tacos1"
              content="Toplam reklamın toplam satışa oranı"
            />
          </div>
          <div className="flex justify-between items-center mb-2">
            <label
              htmlFor="tacosTwelveMonths"
              className="text-xs xl:text-sm flex items-center"
              id="tacos2"
            >
              TACOS (6-12 Ay) <Information color="#6F7AC3" size={16} />
            </label>
            <div className="flex items-center">
              <p className="mr-1 text-sm">%</p>
              <input
                type="text"
                name="tacosTwelveMonths"
                value={formik.values.tacosTwelveMonths}
                onChange={formik.handleChange}
                className="border border-[#6F7AC3] text-xs xl:text-sm rounded-lg w-16 py-1"
                placeholder="$5"
              />
            </div>
            <Tooltip
              place="right"
              anchorSelect="#tacos2"
              content="Toplam reklamın toplam satışa oranı"
            />
          </div>
          <div className="flex justify-between items-center mb-2">
            <label
              htmlFor="tacosEighteenMonths"
              className="text-xs xl:text-sm flex items-center"
              id="tacos3"
            >
              TACOS (12-18 Ay) <Information color="#6F7AC3" size={16} />
            </label>
            <div className="flex items-center">
              <p className="mr-1 text-sm">%</p>
              <input
                type="text"
                name="tacosEighteenMonths"
                value={formik.values.tacosEighteenMonths}
                onChange={formik.handleChange}
                className="border border-[#6F7AC3] text-xs xl:text-sm rounded-lg w-16 py-1"
                placeholder="$5"
              />
            </div>
            <Tooltip
              place="right"
              anchorSelect="#tacos3"
              content="Toplam reklamın toplam satışa oranı"
            />
          </div>
          <div className="flex justify-between items-center mb-2">
            <label
              htmlFor="tacosTwentyFourMonths"
              className="text-xs xl:text-sm flex items-center"
              id="tacos4"
            >
              TACOS (18-24 Ay) <Information color="#6F7AC3" size={16} />
            </label>
            <div className="flex items-center">
              <p className="mr-1 text-sm">%</p>
              <input
                type="text"
                name="tacosTwentyFourMonths"
                value={formik.values.tacosTwentyFourMonths}
                onChange={formik.handleChange}
                className="border border-[#6F7AC3] text-xs xl:text-sm rounded-lg w-16 py-1"
                placeholder="$5"
              />
            </div>
            <Tooltip
              place="right"
              anchorSelect="#tacos4"
              content="Toplam reklamın toplam satışa oranı"
            />
          </div>
          <div className="flex justify-between items-center mb-2">
            <label htmlFor="returnRate" className="text-xs xl:text-sm">
              İade Oranı
            </label>
            <div className="flex items-center">
              <p className="mr-1 text-sm">%</p>
              <input
                type="text"
                name="returnRate"
                value={formik.values.returnRate}
                onChange={formik.handleChange}
                className="border border-[#6F7AC3] text-xs xl:text-sm rounded-lg w-16 py-1"
                placeholder="$5"
              />
            </div>
          </div>
          <div className="flex justify-between items-center mb-2">
            <label htmlFor="increaseRateOne" className="text-xs xl:text-sm">
              Büyüme Oranı 1.Yıl
            </label>
            <div className="flex items-center">
              <p className="mr-1 text-sm">%</p>
              <input
                type="text"
                name="increaseRateOne"
                value={formik.values.increaseRateOne}
                onChange={formik.handleChange}
                className="border border-[#6F7AC3] text-xs xl:text-sm rounded-lg w-16 py-1"
              />
            </div>
          </div>
          <div className="flex justify-between items-center mb-2">
            <label htmlFor="increaseRateTwo" className="text-xs xl:text-sm">
              Büyüme Oranı 2.Yıl
            </label>
            <div className="flex items-center">
              <p className="mr-1 text-sm">%</p>
              <input
                type="text"
                name="increaseRateTwo"
                value={formik.values.increaseRateTwo}
                onChange={formik.handleChange}
                className="border border-[#6F7AC3] text-xs xl:text-sm rounded-lg w-16 py-1"
                placeholder="$5"
              />
            </div>
          </div>
        </div>

        <div className="col-span-12 md:col-span-6">
          <div className="flex justify-between items-center mb-2">
            <label htmlFor="price" className="text-xs xl:text-sm">
              Satış Fiyatı
            </label>
            <div className="flex items-center">
              <p className="mr-1 text-sm">$</p>
              <input
                type="number"
                name="price"
                value={formik.values.price}
                onChange={formik.handleChange}
                className="border border-[#6F7AC3] text-xs xl:text-sm rounded-lg w-16 py-1 "
                placeholder="$350"
              />
            </div>
          </div>
          {formik.errors.price && errorElement(formik.errors.price)}
          <div className="flex justify-between items-center mb-2">
            <label htmlFor="shippingFee" className="text-xs xl:text-sm">
              Kargo Maliyeti
            </label>
            <div className="flex items-center">
              <p className="mr-1 text-sm">$</p>
              <input
                type="text"
                name="shippingFee"
                value={formik.values.shippingFee}
                onChange={formik.handleChange}
                className="border border-[#6F7AC3] text-xs xl:text-sm placeholder:text-lightGray rounded-lg w-16 py-1  "
                placeholder="$5"
              />
            </div>
          </div>
          {formik.errors.shippingFee && errorElement(formik.errors.shippingFee)}
          <div className="flex justify-between items-center mb-2">
            <label htmlFor="productCost" className="text-xs xl:text-sm">
              Üretim Maliyeti
            </label>
            <div className="flex items-center">
              <p className="mr-1 text-sm">$</p>
              <input
                type="text"
                name="productCost"
                value={formik.values.productCost}
                onChange={formik.handleChange}
                className="border border-[#6F7AC3] text-xs xl:text-sm rounded-lg placeholder:text-lightGray w-16 py-1 "
                placeholder="$100"
              />
            </div>
          </div>
          {formik.errors.productCost && errorElement(formik.errors.productCost)}
          <div className="flex justify-between items-center mb-2">
            <label htmlFor="productCost" className="text-xs xl:text-sm">
              Depolama Ücreti
            </label>
            <div className="flex items-center">
              <p className="mr-1 text-sm">$</p>
              <input
                type="text"
                name="storageFee"
                value={formik.values.storageFee}
                onChange={formik.handleChange}
                className="border border-[#6F7AC3] text-xs xl:text-sm rounded-lg w-16 py-1 "
                placeholder="$5"
              />
            </div>
          </div>
          <div className="flex justify-between items-center mb-2">
            <label htmlFor="amazonFee" className="text-xs xl:text-sm">
              Amazon Komisyonu
            </label>
            <div className="flex items-center">
              <p className="mr-1 text-sm">$</p>
              <input
                type="text"
                name="amazonFee"
                value={formik.values.amazonFee}
                onChange={formik.handleChange}
                className="border border-[#6F7AC3] text-xs xl:text-sm rounded-lg w-16 py-1 "
                placeholder="$5"
              />
            </div>
          </div>
          <div className="flex justify-between items-center mb-3">
            <label htmlFor="fbaFee" className="text-xs xl:text-sm">
              FBA Fee
            </label>
            <div className="flex items-center">
              <p className="mr-1 text-sm">$</p>
              <input
                type="text"
                name="fbaFee"
                value={formik.values.fbaFee}
                onChange={formik.handleChange}
                className="border border-[#6F7AC3] text-xs xl:text-sm rounded-lg w-16 py-1 "
                placeholder="$5"
              />
            </div>
          </div>
          <div className="flex justify-between items-center mb-3">
            <p className="text-xs xl:text-sm">Net Kar/Zarar </p>
            <p className="text-xs xl:text-sm mr-3">
              ${profitResult.netProfit.toFixed(2)}
            </p>
          </div>
          <div className="flex justify-between items-center mb-3">
            <p className="text-xs xl:text-sm">Kar Marjı</p>
            <p className="text-xs xl:text-sm mr-3">
              %{profitResult.profitMargin.toFixed(2)}
            </p>
          </div>
          <div className="flex justify-between items-center mb-3">
            <p className="text-xs xl:text-sm">ROI (Kar Oranı)</p>
            <p className="text-xs xl:text-sm mr-3">
              %{profitResult.roi.toFixed(2)}
            </p>
          </div>
          <div className="flex w-full justify-end">
            <button
              type="submit"
              className="rounded-lg bg-headerPrimary py-2.5 px-8 lg:px-12 text-xs font-semibold mt-10 text-white shadow-sm md:text-sm "
            >
              Hesapla
            </button>
          </div>
        </div>

        {/* <div className="col-span-12">
          <div className="flex w-full justify-end">
            <button
              type="submit"
              className="rounded-lg bg-headerPrimary py-2.5 px-8 text-xs font-semibold text-white shadow-sm  md:text-sm "
            >
              Hesapla
            </button>
          </div>
        </div> */}
      </form>
    </div>
  );
};

export default ForecastForm;
