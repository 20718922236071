import React from 'react';
import RatingStars from '../../components/common/RatingStars';
import { IReview } from '../../interfaces/amazonToolsInterfaces';
import BlurredProductReview from './BlurredProductReview';

interface ProductReviewsProps {
  productReviews: IReview[];
  isLoading: boolean;
}

const ProductReviews: React.FC<ProductReviewsProps> = ({
  productReviews,
  isLoading,
}) => {
  if (isLoading) {
    return (
      <div id="productReviewsAll" className="w-full mt-6 animate-pulse">
        {productReviews.slice(1).map((review: IReview, index) => (
          <BlurredProductReview key={index} />
        ))}
      </div>
    );
  }

  return (
    <div id="productReviewsAll" className="w-full mt-6">
      {productReviews.slice(1).map((review: IReview) => (
        <div
          key={review.id}
          className="px-2 py-4 shadow-md bg-white rounded-lg my-2"
        >
          {/* ClientImage - ClientName - Rating */}
          <div className="flex justify-start">
            <h3 className="flex items-center text-description mb-1">
              <img
                alt="shop-logo"
                src={
                  review.clientImageUrl
                    ? review.clientImageUrl
                    : `https://api.dicebear.com/6.x/avataaars/svg?seed=${review.clientName} `
                }
                className="w-[20px] h-[20px] rounded-full"
              />
              <span className="text-lg ml-2 font-normal mr-2">
                {review.clientName}
              </span>
              <RatingStars rating={1} shop={false} />
            </h3>
          </div>
          {/* reviewInfo */}
          <div className="flex justify-start">
            <p className="text-description font-medium">
              {review.reviewSummary}
            </p>
          </div>
          {/* Review Location And Date */}
          <div className="flex justify-start">
            <p className="text-description font-light my-1 text-xs">
              {/* Reviewed in {review.reviewLocation} on {review.reviewDate} */}
              {review.reviewMetaInfo}
            </p>
          </div>

          {/* Review Himself */}
          <div className="flex justify-start my-2">
            <p className="text-sm text-description leading-6">
              {review.reviewText}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProductReviews;
