import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import ResetInfoForm from '../../components/authForms/ResetInfoForm';
import ErrorFallback from '../errors/ErrorFallback';


export default function ResetInfo() {
    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <ResetInfoForm />
        </ErrorBoundary>
    )
}
