import React from 'react';

const ShippingCalculatorFooter = () => {
  return (
    <div className="flex flex-col space-y-3 w-full shadow-lg rounded-lg bg-white p-4 border border-[#0EA5E9] text-sm">
      <ul>
        <li className="mb-2">
          * Gönderilerin ağırlığı hesaplanırken tartı üzerindeki ağırlığı ve
          hacimsel ağırlığı(desi) baz alınır.Hacimsel ağırlık hesaplamalarında
          gönderinizin en boy ve yükseklik değerlerinin birbiri ile
          çarpılmasının 5000 ile bölümü ile bulunmaktadır. (Uzunluk * Genişlik *
          Yükseklik) / 5000 = Ağırlık
        </li>
        <li className="mb-2">
          * Paketin geçerli ağırlığını hacimsel ağırlığıyla karşılaştırın. İki
          ağırlıktan daha ağır olanı, faturalandırılabilir ağırlıktır ve fiyatı
          hesaplamak için bunun kullanılması gerekir.
        </li>
        <li className="mb-2">
          * Gönderimleriniz gümrükleme gerektirmeyebilir. Gümrük ücretleri ve
          vergileri, öğelerinizin içeriğine ve değerine ve ülkenizin
          düzenlemelerine göre hesaplanır.
        </li>
        <li className="mb-2">
          {' '}
          * Eko servis yalnızca ABD için 0.5 kg/desi altı gönderilerde
          geçerlidir.
        </li>
        <li className="mb-2">
          * Eko kapsamına girmeyip eko seçilen gönderiler default olarak
          fiyatlandırılacaktır.Fiyat revizesi gerçekleştirilmeyecektir.
        </li>
      </ul>
    </div>
  );
};

export default ShippingCalculatorFooter;
