import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';

const videoData = [
    {
        course: "6-etsy-master-egitimi",
        mainTitle: "1-giris",
        videoTitle: "1-amazon-ve-etsy-yol-haritasi-belirleme",
        embed: {
            src: "https://player.vimeo.com/video/927117491?badge=0&autopause=0&player_id=0&app_id=58479",
            title: "1-amazon-ve-etsy-yol-haritasi-belirleme"
        }
    },
    {
        course: "6-etsy-master-egitimi",
        mainTitle: "1-giris",
        videoTitle: "2-amazon-ve-etsy-is-modelleri",
        embed: {
            src: "https://player.vimeo.com/video/927135829?badge=0&autopause=0&player_id=0&app_id=58479",
            title: "2-amazon-ve-etsy-is-modelleri"
        }
    },
    {
        course: "6-etsy-master-egitimi",
        mainTitle: "1-giris",
        videoTitle: "3-etsy-urun-bulma-ve-analiz-egitimi",
        embed: {
            src: "https://player.vimeo.com/video/927135644?badge=0&autopause=0&player_id=0&app_id=58479",
            title: "3-etsy-urun-bulma-ve-analiz-egitimi"
        }
    },
    {
        course: "6-etsy-master-egitimi",
        mainTitle: "1-giris",
        videoTitle: "4-uygulamali-etsy-urun-arastirma",
        embed: {
            src: "https://player.vimeo.com/video/927176939?badge=0&autopause=0&player_id=0&app_id=58479",
            title: "4-uygulamali-etsy-urun-arastirma"
        }
    },
    {
        course: "6-etsy-master-egitimi",
        mainTitle: "1-giris",
        videoTitle: "5-etsy-urun-listeleme-egitimi",
        embed: {
            src: "https://player.vimeo.com/video/927179429?badge=0&autopause=0&player_id=0&app_id=58479",
            title: "5-etsy-urun-listeleme-egitimi"
        }
    },
    {
        course: "6-etsy-master-egitimi",
        mainTitle: "1-giris",
        videoTitle: "6-uygulamali-etsy-urun-listeleme-egitimi",
        embed: {
            src: "https://player.vimeo.com/video/927201532?badge=0&autopause=0&player_id=0&app_id=58479",
            title: "6-uygulamali-etsy-urun-listeleme-egitimi"
        }
    },
    {
        course: "6-etsy-master-egitimi",
        mainTitle: "1-giris",
        videoTitle: "7-etsy-satis-taktik-ve-strratejileri-egitimi",
        embed: {
            src: "https://player.vimeo.com/video/927201698?badge=0&autopause=0&player_id=0&app_id=58479",
            title: "7-etsy-satis-taktik-ve-strratejileri-egitimi"
        }
    },
    {
        course: "6-etsy-master-egitimi",
        mainTitle: "2-canli-egitimler",
        videoTitle: "1-etsye-giris-egitimi",
        embed: {
            src: "https://player.vimeo.com/video/927214771?badge=0&autopause=0&player_id=0&app_id=58479",
            title: "1-etsye-giris-egitimi"
        }
    },
    {
        course: "6-etsy-master-egitimi",
        mainTitle: "2-canli-egitimler",
        videoTitle: "2-etsy-maliyet-kar-analizi",
        embed: {
            src: "https://player.vimeo.com/video/927492907?badge=0&autopause=0&player_id=0&app_id=58479",
            title: "2-etsy-maliyet-kar-analizi"
        }
    },
    {
        course: "6-etsy-master-egitimi",
        mainTitle: "2-canli-egitimler",
        videoTitle: "3-etsy-analiz-yazilimlari",
        embed: {
            src: "https://player.vimeo.com/video/927527315?badge=0&autopause=0&player_id=0&app_id=58479",
            title: "3-etsy-analiz-yazilimlari"
        }
    },
    {
        course: "6-etsy-master-egitimi",
        mainTitle: "2-canli-egitimler",
        videoTitle: "4-etsy-dogru-urun-secimi",
        embed: {
            src: "https://player.vimeo.com/video/927532299?badge=0&autopause=0&player_id=0&app_id=58479",
            title: "4-etsy-dogru-urun-secimi"
        }
    },
    {
        course: "6-etsy-master-egitimi",
        mainTitle: "2-canli-egitimler",
        videoTitle: "5-etsy-seo",
        embed: {
            src: "https://player.vimeo.com/video/927916451?badge=0&autopause=0&player_id=0&app_id=58479",
            title: "5-etsy-seo"
        }
    },
    {
        course: "6-etsy-master-egitimi",
        mainTitle: "2-canli-egitimler",
        videoTitle: "6-etsy-urun-fotograf-cekimi",
        embed: {
            src: "https://player.vimeo.com/video/928685813?badge=0&autopause=0&player_id=0&app_id=58479",
            title: "6-etsy-urun-fotograf-cekimi"
        }
    },
    {
        course: "6-etsy-master-egitimi",
        mainTitle: "2-canli-egitimler",
        videoTitle: "7-etsy-hesap-acilisi",
        embed: {
            src: "https://player.vimeo.com/video/928689950?badge=0&autopause=0&player_id=0&app_id=58479",
            title: "7-etsy-hesap-acilisi"
        }
    },
    {
        course: "6-etsy-master-egitimi",
        mainTitle: "2-canli-egitimler",
        videoTitle: "8-etsy-urun-listeleme",
        embed: {
            src: "https://player.vimeo.com/video/928702242?badge=0&autopause=0&player_id=0&app_id=58479",
            title: "8-etsy-urun-listeleme"
        }
    },
    {
        course: "6-etsy-master-egitimi",
        mainTitle: "2-canli-egitimler",
        videoTitle: "9-magaza-ve-satici-paneli-tanitimi",
        embed: {
            src: "https://player.vimeo.com/video/928708717?badge=0&autopause=0&player_id=0&app_id=58479",
            title: "9-magaza-ve-satici-paneli-tanitimi"
        }
    },
    {
        course: "6-etsy-master-egitimi",
        mainTitle: "2-canli-egitimler",
        videoTitle: "10-etsy-istatistikler",
        embed: {
            src: "https://player.vimeo.com/video/928711914?badge=0&autopause=0&player_id=0&app_id=58479",
            title: "10-etsy-istatistikler"
        }
    },
    {
        course: "6-etsy-master-egitimi",
        mainTitle: "2-canli-egitimler",
        videoTitle: "11-etsy-pazarlama",
        embed: {
            src: "https://player.vimeo.com/video/928758833?badge=0&autopause=0&player_id=0&app_id=58479",
            title: "11-etsy-pazarlama"
        }
    },
    {
        course: "6-etsy-master-egitimi",
        mainTitle: "2-canli-egitimler",
        videoTitle: "12-etsy-sosyal-medya",
        embed: {
            src: "https://player.vimeo.com/video/928764317?badge=0&autopause=0&player_id=0&app_id=58479",
            title: "12-etsy-sosyal-medya"
        }
    },
    {
        course: "6-etsy-master-egitimi",
        mainTitle: "2-canli-egitimler",
        videoTitle: "13-printify-giris-egitimi",
        embed: {
            src: "https://player.vimeo.com/video/928768432?badge=0&autopause=0&player_id=0&app_id=58479",
            title: "13-printify-giris-egitimi"
        }
    },
    {
        course: "6-etsy-master-egitimi",
        mainTitle: "2-canli-egitimler",
        videoTitle: "14-printify-egitimi-2",
        embed: {
            src: "https://player.vimeo.com/video/928776827?badge=0&autopause=0&player_id=0&app_id=58479",
            title: "14-printify-egitimi-2"
        }
    },
    {
        course: "6-etsy-master-egitimi",
        mainTitle: "2-canli-egitimler",
        videoTitle: "15-pinterest-egitimi",
        embed: {
            src: "https://player.vimeo.com/video/928784467?badge=0&autopause=0&player_id=0&app_id=58479",
            title: "15-pinterest-egitimi"
        }
    },
    {
        course: "6-etsy-master-egitimi",
        mainTitle: "2-canli-egitimler",
        videoTitle: "16-etsy-dijital-urun-dugun-davetiyesi",
        embed: {
            src: "https://player.vimeo.com/video/928788191?badge=0&autopause=0&player_id=0&app_id=58479",
            title: "16-etsy-dijital-urun-dugun-davetiyesi"
        }
    },
    {
        course: "6-etsy-master-egitimi",
        mainTitle: "2-canli-egitimler",
        videoTitle: "17-etsyde-dijital-urun-yapay-zeka-ile-tasarim",
        embed: {
            src: "https://player.vimeo.com/video/930292351?badge=0&autopause=0&player_id=0&app_id=58479",
            title: "17-etsyde-dijital-urun-yapay-zeka-ile-tasarim"
        }
    },
    {
        course: "6-etsy-master-egitimi",
        mainTitle: "2-canli-egitimler",
        videoTitle: "18-etsy-soru-cevap-yayini",
        embed: {
            src: "https://player.vimeo.com/video/930303643?badge=0&autopause=0&player_id=0&app_id=58479",
            title: "18-etsy-soru-cevap-yayini"
        }
    },
    {
        course: "6-etsy-master-egitimi",
        mainTitle: "2-canli-egitimler",
        videoTitle: "19-etsy-soru-cevap-yayini-2",
        embed: {
            src: "https://player.vimeo.com/video/930307862?badge=0&autopause=0&player_id=0&app_id=58479",
            title: "19-etsy-soru-cevap-yayini-2"
        }
    },
    {
        course: "6-etsy-master-egitimi",
        mainTitle: "2-canli-egitimler",
        videoTitle: "20-pinterest-egitimi-1",
        embed: {
            src: "https://player.vimeo.com/video/930394483?badge=0&autopause=0&player_id=0&app_id=58479",
            title: "20-pinterest-egitimi-1"
        }
    },
    {
        course: "6-etsy-master-egitimi",
        mainTitle: "2-etsy-kampi",
        videoTitle: "1-etsye-giris-egitimi",
        embed: {
            src: "https://player.vimeo.com/video/930556184?badge=0&autopause=0&player_id=0&app_id=58479",
            title: "1-etsye-giris-egitimi"
        }
    },
    {
        course: "6-etsy-master-egitimi",
        mainTitle: "2-etsy-kampi",
        videoTitle: "2-maliyet-ve-kar-analizi",
        embed: {
            src: "https://player.vimeo.com/video/930559695?badge=0&autopause=0&player_id=0&app_id=58479",
            title: "2-maliyet-ve-kar-analizi"
        }
    },
    {
        course: "6-etsy-master-egitimi",
        mainTitle: "2-etsy-kampi",
        videoTitle: "3-etsy-analiz-yazilimlari",
        embed: {
            src: "https://player.vimeo.com/video/930565066?badge=0&autopause=0&player_id=0&app_id=58479",
            title: "3-etsy-analiz-yazilimlari"
        }
    },
    {
        course: "6-etsy-master-egitimi",
        mainTitle: "2-etsy-kampi",
        videoTitle: "4-dogru-urun-secimi",
        embed: {
            src: "https://player.vimeo.com/video/930588006?badge=0&autopause=0&player_id=0&app_id=58479",
            title: "4-dogru-urun-secimi"
        }
    },
    {
        course: "6-etsy-master-egitimi",
        mainTitle: "2-etsy-kampi",
        videoTitle: "5-etsy-urun-fotograf-cekimi",
        embed: {
            src: "https://player.vimeo.com/video/930591880?badge=0&autopause=0&player_id=0&app_id=58479",
            title: "5-etsy-urun-fotograf-cekimi"
        }
    },
    {
        course: "6-etsy-master-egitimi",
        mainTitle: "2-etsy-kampi",
        videoTitle: "6-etsy-seo",
        embed: {
            src: "https://player.vimeo.com/video/930606733?badge=0&autopause=0&player_id=0&app_id=58479",
            title: "6-etsy-seo"
        }
    },
    {
        course: "6-etsy-master-egitimi",
        mainTitle: "2-etsy-kampi",
        videoTitle: "7-etsy-urun-listeleme",
        embed: {
            src: "https://player.vimeo.com/video/930617126?badge=0&autopause=0&player_id=0&app_id=58479",
            title: "7-etsy-urun-listeleme"
        }
    },
    {
        course: "6-etsy-master-egitimi",
        mainTitle: "2-etsy-kampi",
        videoTitle: "8-etsy-hesap-acilisi",
        embed: {
            src: "https://player.vimeo.com/video/930621288?badge=0&autopause=0&player_id=0&app_id=58479",
            title: "8-etsy-hesap-acilisi"
        }
    },
    {
        course: "6-etsy-master-egitimi",
        mainTitle: "2-etsy-kampi",
        videoTitle: "9-magaza-ve-satici-paneli",
        embed: {
            src: "https://player.vimeo.com/video/930650292?badge=0&autopause=0&player_id=0&app_id=58479",
            title: "9-magaza-ve-satici-paneli"
        }
    },
    {
        course: "6-etsy-master-egitimi",
        mainTitle: "2-etsy-kampi",
        videoTitle: "10-etsy-istatistikler-ve-pazarlama",
        embed: {
            src: "https://player.vimeo.com/video/930680043?badge=0&autopause=0&player_id=0&app_id=58479",
            title: "10-etsy-istatistikler-ve-pazarlama"
        }
    },
    {
        course: "6-etsy-master-egitimi",
        mainTitle: "2-etsy-kampi",
        videoTitle: "11-e-pinterest-egitimi",
        embed: {
            src: "https://player.vimeo.com/video/930717908?badge=0&autopause=0&player_id=0&app_id=58479",
            title: "11-e-pinterest-egitimi"
        }
    },
    {
        course: "6-etsy-master-egitimi",
        mainTitle: "2-etsy-kampi",
        videoTitle: "12-siparis-sonrasi-dikkat-edilmesi-gerekenler-ve-dijital-urunler",
        embed: {
            src: "https://player.vimeo.com/video/930725193?badge=0&autopause=0&player_id=0&app_id=58479",
            title: "12-siparis-sonrasi-dikkat-edilmesi-gerekenler-ve-dijital-urunler"
        }
    },
    {
        course: "12-eticaret-baslangic-kampi",
        mainTitle: "1-genel",
        videoTitle: "1-hedef-belirleme",
        embed: {
            src: "https://player.vimeo.com/video/930959039?badge=0&autopause=0&player_id=0&app_id=58479",
            title: "1-hedef-belirleme"
        }
    },
    {
        course: "12-eticaret-baslangic-kampi",
        mainTitle: "1-genel",
        videoTitle: "2-kafa-yapisi",
        embed: {
            src: "https://player.vimeo.com/video/930961656?badge=0&autopause=0&player_id=0&app_id=58479",
            title: "2-kafa-yapisi"
        }
    },
    {
        course: "12-eticaret-baslangic-kampi",
        mainTitle: "1-genel",
        videoTitle: "3-is-modeli-secimi",
        embed: {
            src: "https://player.vimeo.com/video/930964503?badge=0&autopause=0&player_id=0&app_id=58479",
            title: "3-is-modeli-secimi"
        }
    },
    {
        course: "12-eticaret-baslangic-kampi",
        mainTitle: "1-genel",
        videoTitle: "4-hangi-pazar-yeri",
        embed: {
            src: "https://player.vimeo.com/video/930967798?badge=0&autopause=0&player_id=0&app_id=58479",
            title: "4-hangi-pazar-yeri"
        }
    },
    {
        course: "12-eticaret-baslangic-kampi",
        mainTitle: "1-genel",
        videoTitle: "5-etsyde-ilk-adim",
        embed: {
            src: "https://player.vimeo.com/video/930980531?badge=0&autopause=0&player_id=0&app_id=58479",
            title: "5-etsyde-ilk-adim"
        }
    },
    {
        course: "12-eticaret-baslangic-kampi",
        mainTitle: "1-genel",
        videoTitle: "6-onbes-asamada-pl",
        embed: {
            src: "https://player.vimeo.com/video/932010535?badge=0&autopause=0&player_id=0&app_id=58479",
            title: "6-onbes-asamada-pl"
        }
    },
    {
        course: "12-eticaret-baslangic-kampi",
        mainTitle: "1-genel",
        videoTitle: "7-arbitraj-wholesale-yol-haritasi",
        embed: {
            src: "https://player.vimeo.com/video/932012734?badge=0&autopause=0&player_id=0&app_id=58479",
            title: "7-arbitraj-wholesale-yol-haritasi"
        }
    }
];
interface VimeoPlayerProps {
    title: string
    courseId: number
    selectedContentId: any
    update: (courseId: number, contentId: number) => void
}
const VimeoPlayer: React.FC<VimeoPlayerProps> = ({ title, update, courseId, selectedContentId }) => {
    const [videoSrc, setVideoSrc] = useState<string>('');

    const handleTitleClick = () => {
        const video = videoData.find(video => `/${video.embed.title}.mp4` === title);
        if (video) {
            setVideoSrc(video.embed.src);
        }
    }

    useEffect(() => {
        handleTitleClick()
    }, [title]);

    return (
        <div style={{ padding: '56.21% 0 0 0', position: 'relative' }}>
            <ReactPlayer
                url={videoSrc}
                playing={true}
                controls={true}
                width="100%"
                height="100%"
                onEnded={() => update(courseId, selectedContentId)}
                style={{ position: 'absolute', top: 0, left: 0 }}
            />
        </div>
    );
};

export default VimeoPlayer;
