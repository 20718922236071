import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/react/24/solid';

import { useLanguage } from '../../context/LanguageContext';
import { useNumberVerification } from '../../context/NumberVerificationContext';
import { ProducerProducts } from '../../pages/Manufacturer';
import RatingStars from '../common/RatingStars';

interface ManufacturerProductCardProps {
  product: ProducerProducts;
  updateProducerStats: (productId: number) => void;
  setProductId: React.Dispatch<React.SetStateAction<number | null>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const ManufacturerProductCard: React.FC<ManufacturerProductCardProps> = ({
  product,
  updateProducerStats,
  setProductId,
  setOpen,
}) => {
  const { t } = useLanguage();
  const navigate = useNavigate()
  const [currentIndex, setCurrentIndex] = useState(0);
  const { handleActiveModal } = useNumberVerification();

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide
      ? product.images.length - 1
      : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === product.images.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };


  return (
    <div className="group relative border-[1px]  rounded-lg border-[#E5E7EB] p-4 sm:p-2">
      <div className="w-[200px] h-[300px] aspect-w-1 aspect-h-1 overflow-hidden rounded-lg bg-gray-200 group-hover:opacity-75 mx-auto">
        <img
          onError={(e) => {
            (e.target as HTMLImageElement).src = '/images/notFound.png';
          }}
          src={
            product?.images && product?.images.length > 0
              ? product?.images[currentIndex]?.url
              : '/images/notFound.png'
          }
          alt={`${product.title}`}
          className="h-full w-full object-contain bg-white object-center"
        />
      </div>
      {product?.images && product.images.length > 1 && (
        <>
          <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
            <ChevronDoubleLeftIcon className="h-6 w-6" onClick={prevSlide} />
          </div>
          <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
            <ChevronDoubleRightIcon className="h-6 w-6" onClick={nextSlide} />
          </div>
        </>
      )}
      <div className=" pt-2 pb-2 text-left">
        <div className="h-[35px] max-h-[35px] -ml-0.5 mt-4 flex items-center text-sm  ">
          <p className="pl-1">
            <span className="font-bold  mr-1">{product?.brand} </span>{' '}
            {product.title}
          </p>
        </div>

        <div className="h-[35px] text-primary  max-h-[35px] -ml-0.5 mt-4 justify-end flex items-center text-sm  ">
          <span className="text-[10px] line-through text-[#939BD2] text-sm">
            {Number(product?.salePrice).toFixed(2)}₺
          </span>
          <span className="ml-2 mr-1 text-headerPrimary  text-lg sm:text-xl font-medium">
            {Number(product?.discountedPrice).toFixed(2)}₺
          </span>
        </div>

        <div className="mt-3 flex flex-col items-left">
          <p className="sr-only">{product?.rating} out of 5 stars</p>
          <div className="flex items-center">
            <RatingStars rating={Number(product.rating)} shop={false} />
            <span className="text-sm ml-1">
              {' '}
              ({product?.reviewCount
                ? product?.reviewCount
                : 0} Değerlendirme){' '}
            </span>
          </div>
        </div>
        <div className="flex items-center justify-between mt-2 px-1 ">
          <button
            onClick={(e) => {
              // e.preventDefault();
              // if (product?.id) {
              //   setProductId?.(product.product_id);
              // }
              // setOpen(true);
              navigate(`/messages?to=${product.producerUserId}&name=${`${product.first_name} ${product.last_name}`}&profile=${product.profile_image}`)
            }}
            className="hover:cursor-pointer text-center text-[12px] bg-headerPrimary font-bold text-white h-[50px] max-h-[50px] w-full px-3 py-1  border-2 rounded-lg border-headerPrimary w-[49%] flex items-center justify-center"
          >
            İletişim Kur
          </button>
          {/* {product.phoneNumber ? (
            <a
              onClick={(event) => {
                updateProducerStats(product.product_id);
                handleActiveModal?.(event);
              }}
              href={`https://wa.me/${product.phoneNumber.replace(
                / /g,
                ''
              )}?text=Merhaba,%20ben%20size%20Rexven%20arac%C4%B1l%C4%B1%C4%9F%C4%B1yla%20ula%C5%9Ft%C4%B1m`}
              target="_blank"
              rel="noreferrer"
              className="bg-headerPrimary  h-[40px] max-h-[40px] text-white text-sm px-3 py-1  border-2 rounded-lg border-headerPrimary w-[100%] flex items-center"
            >
              <button className="w-full">
                {t?.('manufacturers-and-wholesalers.contact')}
              </button>
            </a>
          ) : (
            <div className="bg-headerPrimary  h-[40px] max-h-[40px] text-white text-sm px-3 py-1  border-2 rounded-lg border-headerPrimary w-[100%] flex items-center">
              <button className="w-full">İletişim Adresi Bulunamadı</button>
            </div>
          )} */}
        </div>
        <p className="text-xs text-[#4B5563] mt-2 min-h-[20px]">
          {product.communicationCount !== '0' &&
            product.communicationCount !== undefined &&
            product.communicationCount !== null && (
              <>
                Bu ürün için
                <span className="font-medium">{` ${product.communicationCount} `}</span>
                girişimci iletişim kurdu
              </>
            )}
        </p>
      </div>
    </div>
  );
};

export default ManufacturerProductCard;
