export const citiesTurkey = [
  { value: 'ADANA', label: 'ADANA' },
  { value: 'ADIYAMAN', label: 'ADIYAMAN' },
  { value: 'AFYONKARAHİSAR', label: 'AFYONKARAHİSAR' },
  { value: 'AĞRI', label: 'AĞRI' },
  { value: 'AMASYA', label: 'AMASYA' },
  { value: 'ANKARA', label: 'ANKARA' },
  { value: 'ANTALYA', label: 'ANTALYA' },
  { value: 'ARTVİN', label: 'ARTVİN' },
  { value: 'AYDIN', label: 'AYDIN' },
  { value: 'BALIKESİR', label: 'BALIKESİR' },
  { value: 'BİLECİKK', label: 'BİLECİKK' },
  { value: 'BİNGÖL', label: 'BİNGÖL' },
  { value: 'BİTLİS', label: 'BİTLİS' },
  { value: 'BOLU', label: 'BOLU' },
  { value: 'BURDUR', label: 'BURDUR' },
  { value: 'BURSA', label: 'BURSA' },
  { value: 'ÇANAKKALE', label: 'ÇANAKKALE' },
  { value: 'ÇANKIRI', label: 'ÇANKIRI' },
  { value: 'ÇORUM', label: 'ÇORUM' },
  { value: 'DENİZLİ', label: 'DENİZLİ' },
  { value: 'DİYARBAKIR', label: 'DİYARBAKIR' },
  { value: 'EDİRNE', label: 'EDİRNE' },
  { value: 'ELAZIĞ', label: 'ELAZIĞ' },
  { value: 'ERZİNCAN', label: 'ERZİNCAN' },
  { value: 'ERZURUM', label: 'ERZURUM' },
  { value: 'ESKİŞEHİR', label: 'ESKİŞEHİR' },
  { value: 'GAZİANTEP', label: 'GAZİANTEP' },
  { value: 'GİRESUN', label: 'GİRESUN' },
  { value: 'GÜMÜŞHANE', label: 'GÜMÜŞHANE' },
  { value: 'HAKKARİ', label: 'HAKKARİ' },
  { value: 'HATAY', label: 'HATAY' },
  { value: 'ISPARTA', label: 'ISPARTA' },
  { value: 'MERSİN', label: 'MERSİN' },
  { value: 'İSTANBUL', label: 'İSTANBUL' },
  { value: 'İZMİR', label: 'İZMİR' },
  { value: 'KARS', label: 'KARS' },
  { value: 'KASTAMONU', label: 'KASTAMONU' },
  { value: 'KAYSERİ', label: 'KAYSERİ' },
  { value: 'KIRKLARELİ', label: 'KIRKLARELİ' },
  { value: 'KIRŞEHİR', label: 'KIRŞEHİR' },
  { value: 'KOCAELİ', label: 'KOCAELİ' },
  { value: 'KONYA', label: 'KONYA' },
  { value: 'KÜTAHYA', label: 'KÜTAHYA' },
  { value: 'MALATYA', label: 'MALATYA' },
  { value: 'MANİSA', label: 'MANİSA' },
  { value: 'KAHRAMANMARAŞ', label: 'KAHRAMANMARAŞ' },
  { value: 'MARDİN', label: 'MARDİN' },
  { value: 'MUĞLA', label: 'MUĞLA' },
  { value: 'MUŞ', label: 'MUŞ' },
  { value: 'NEVŞEHİR', label: 'NEVŞEHİR' },
  { value: 'NİĞDE', label: 'NİĞDE' },
  { value: 'ORDU', label: 'ORDU' },
  { value: 'RİZE', label: 'RİZE' },
  { value: 'SAKARYA', label: 'SAKARYA' },
  { value: 'SAMSUN', label: 'SAMSUN' },
  { value: 'SİİRT', label: 'SİİRT' },
  { value: 'SİNOP', label: 'SİNOP' },
  { value: 'SİVAS', label: 'SİVAS' },
  { value: 'TEKİRDAĞ', label: 'TEKİRDAĞ' },
  { value: 'TOKAT', label: 'TOKAT' },
  { value: 'TRABZON', label: 'TRABZON' },
  { value: 'TUNCELİ', label: 'TUNCELİ' },
  { value: 'ŞANLIURFA', label: 'ŞANLIURFA' },
  { value: 'UŞAK', label: 'UŞAK' },
  { value: 'VAN', label: 'VAN' },
  { value: 'YOZGAT', label: 'YOZGAT' },
  { value: 'ZONGULDAK', label: 'ZONGULDAK' },
  { value: 'AKSARAY', label: 'AKSARAY' },
  { value: 'BAYBURT', label: 'BAYBURT' },
  { value: 'KARAMAN', label: 'KARAMAN' },
  { value: 'KIRIKKALE', label: 'KIRIKKALE' },
  { value: 'BATMAN', label: 'BATMAN' },
  { value: 'ŞIRNAK', label: 'ŞIRNAK' },
  { value: 'BARTIN', label: 'BARTIN' },
  { value: 'ARDAHAN', label: 'ARDAHAN' },
  { value: 'IĞDIR', label: 'IĞDIR' },
  { value: 'YALOVA', label: 'YALOVA' },
  { value: 'KARABÜK', label: 'KARABÜK' },
  { value: 'KİLİS', label: 'KİLİS' },
  { value: 'OSMANİYE', label: 'OSMANİYE' },
  { value: 'DÜZCE', label: 'DÜZCE' },
];

let temp = [
  {
    id: 1,
    il_id: 'Adana',
    ilce: 'Merkez',
    say_kodu: 1250,
    vergi_dairesi: 'Adana İhtisas',
  },
  {
    id: 2,
    il_id: 'Adana',
    ilce: 'Merkez',
    say_kodu: 1251,
    vergi_dairesi: '5 Ocak',
  },
  {
    id: 3,
    il_id: 'Adana',
    ilce: 'Merkez',
    say_kodu: 1252,
    vergi_dairesi: 'Yüreğir',
  },
  {
    id: 4,
    il_id: 'Adana',
    ilce: 'Merkez',
    say_kodu: 1253,
    vergi_dairesi: 'Seyhan',
  },
  {
    id: 5,
    il_id: 'Adana',
    ilce: 'Merkez',
    say_kodu: 1254,
    vergi_dairesi: 'Ziyapaşa',
  },
  {
    id: 6,
    il_id: 'Adana',
    ilce: 'Merkez',
    say_kodu: 1255,
    vergi_dairesi: 'Çukurova',
  },
  {
    id: 7,
    il_id: 'Adana',
    ilce: 'Ceyhan',
    say_kodu: 1201,
    vergi_dairesi: 'Ceyhan',
  },
  {
    id: 8,
    il_id: 'Adana',
    ilce: 'Kozan',
    say_kodu: 1203,
    vergi_dairesi: 'Kozan',
  },
  {
    id: 9,
    il_id: 'Adana',
    ilce: 'Karataş',
    say_kodu: 1205,
    vergi_dairesi: 'Karataş',
  },
  {
    id: 10,
    il_id: 'Adana',
    ilce: 'Feke',
    say_kodu: 1103,
    vergi_dairesi: 'Feke',
  },
  {
    id: 11,
    il_id: 'Adana',
    ilce: 'Karaisalı',
    say_kodu: 1105,
    vergi_dairesi: 'Karaisalı',
  },
  {
    id: 12,
    il_id: 'Adana',
    ilce: 'Pozantı',
    say_kodu: 1109,
    vergi_dairesi: 'Pozantı',
  },
  {
    id: 13,
    il_id: 'Adana',
    ilce: 'Saimbeyli',
    say_kodu: 1110,
    vergi_dairesi: 'Saimbeyli',
  },
  {
    id: 14,
    il_id: 'Adana',
    ilce: 'Tufanbeyli',
    say_kodu: 1111,
    vergi_dairesi: 'Tufanbeyli',
  },
  {
    id: 15,
    il_id: 'Adana',
    ilce: 'Yumurtalık',
    say_kodu: 1112,
    vergi_dairesi: 'Yumurtalık',
  },
  {
    id: 16,
    il_id: 'Adana',
    ilce: 'Aladağ',
    say_kodu: 1114,
    vergi_dairesi: 'Aladağ',
  },
  {
    id: 17,
    il_id: 'Adana',
    ilce: 'İmamoğlu',
    say_kodu: 1115,
    vergi_dairesi: 'İmamoğlu',
  },
  {
    id: 18,
    il_id: 'Adıyaman',
    ilce: 'Merkez',
    say_kodu: 2260,
    vergi_dairesi: 'Adıyaman',
  },
  {
    id: 19,
    il_id: 'Adıyaman',
    ilce: 'Kahta',
    say_kodu: 2261,
    vergi_dairesi: 'Kahta',
  },
  {
    id: 20,
    il_id: 'Adıyaman',
    ilce: 'Besni',
    say_kodu: 2101,
    vergi_dairesi: 'Besni',
  },
  {
    id: 21,
    il_id: 'Adıyaman',
    ilce: 'Çelikhan',
    say_kodu: 2102,
    vergi_dairesi: 'Çelikhan',
  },
  {
    id: 22,
    il_id: 'Adıyaman',
    ilce: 'Gerger',
    say_kodu: 2103,
    vergi_dairesi: 'Gerger',
  },
  {
    id: 23,
    il_id: 'Adıyaman',
    ilce: 'Gölbaşı',
    say_kodu: 2104,
    vergi_dairesi: 'Gölbaşı',
  },
  {
    id: 24,
    il_id: 'Adıyaman',
    ilce: 'Samsat',
    say_kodu: 2106,
    vergi_dairesi: 'Samsat',
  },
  {
    id: 25,
    il_id: 'Adıyaman',
    ilce: 'Sincik',
    say_kodu: 2107,
    vergi_dairesi: 'Sincik',
  },
  {
    id: 26,
    il_id: 'Adıyaman',
    ilce: 'Tut',
    say_kodu: 2108,
    vergi_dairesi: 'Tut',
  },
  {
    id: 27,
    il_id: 'Afyon',
    ilce: 'Merkez',
    say_kodu: 3201,
    vergi_dairesi: 'Tınaztepe',
  },
  {
    id: 28,
    il_id: 'Afyon',
    ilce: 'Merkez',
    say_kodu: 3280,
    vergi_dairesi: 'Kocatepe',
  },
  {
    id: 29,
    il_id: 'Afyon',
    ilce: 'Dinar',
    say_kodu: 3260,
    vergi_dairesi: 'Dinar',
  },
  {
    id: 30,
    il_id: 'Afyon',
    ilce: 'Bolvadin',
    say_kodu: 3261,
    vergi_dairesi: 'Bolvadin',
  },
  {
    id: 31,
    il_id: 'Afyon',
    ilce: 'Emirdağ',
    say_kodu: 3262,
    vergi_dairesi: 'Emirdağ',
  },
  {
    id: 32,
    il_id: 'Afyon',
    ilce: 'Sandıklı',
    say_kodu: 3263,
    vergi_dairesi: 'Sandıklı',
  },
  {
    id: 33,
    il_id: 'Afyon',
    ilce: 'Çay',
    say_kodu: 3202,
    vergi_dairesi: 'Çay',
  },
  {
    id: 34,
    il_id: 'Afyon',
    ilce: 'Dazkırı',
    say_kodu: 3103,
    vergi_dairesi: 'Dazkırı',
  },
  {
    id: 35,
    il_id: 'Afyon',
    ilce: 'İhsaniye',
    say_kodu: 3105,
    vergi_dairesi: 'İhsaniye',
  },
  {
    id: 36,
    il_id: 'Afyon',
    ilce: 'Sinanpaşa',
    say_kodu: 3107,
    vergi_dairesi: 'Sinanpaşa',
  },
  {
    id: 37,
    il_id: 'Afyon',
    ilce: 'Sultandağı',
    say_kodu: 3108,
    vergi_dairesi: 'Sultandağı',
  },
  {
    id: 38,
    il_id: 'Afyon',
    ilce: 'Şuhut',
    say_kodu: 3109,
    vergi_dairesi: 'Şuhut',
  },
  {
    id: 39,
    il_id: 'Afyon',
    ilce: 'Başmakçı',
    say_kodu: 3111,
    vergi_dairesi: 'Başmakçı',
  },
  {
    id: 40,
    il_id: 'Afyon',
    ilce: 'Bayat',
    say_kodu: 3112,
    vergi_dairesi: 'Bayat',
  },
  {
    id: 41,
    il_id: 'Afyon',
    ilce: 'İscehisar',
    say_kodu: 3264,
    vergi_dairesi: 'İscehisar',
  },
  {
    id: 42,
    il_id: 'Afyon',
    ilce: 'Çobanlar',
    say_kodu: 3114,
    vergi_dairesi: 'Çobanlar',
  },
  {
    id: 43,
    il_id: 'Afyon',
    ilce: 'Evciler',
    say_kodu: 3115,
    vergi_dairesi: 'Evciler',
  },
  {
    id: 44,
    il_id: 'Afyon',
    ilce: 'Hocalar',
    say_kodu: 3116,
    vergi_dairesi: 'Hocalar',
  },
  {
    id: 45,
    il_id: 'Afyon',
    ilce: 'Kızılören',
    say_kodu: 3117,
    vergi_dairesi: 'Kızılören',
  },
  {
    id: 46,
    il_id: 'Ağrı',
    ilce: 'Merkez',
    say_kodu: 4260,
    vergi_dairesi: 'Ağrı',
  },
  {
    id: 47,
    il_id: 'Ağrı',
    ilce: 'Doğubeyazıt',
    say_kodu: 4261,
    vergi_dairesi: 'Doğubeyazıt',
  },
  {
    id: 48,
    il_id: 'Ağrı',
    ilce: 'Patnos',
    say_kodu: 4262,
    vergi_dairesi: 'Patnos',
  },
  {
    id: 49,
    il_id: 'Ağrı',
    ilce: 'Diyadin',
    say_kodu: 4101,
    vergi_dairesi: 'Diyadin',
  },
  {
    id: 50,
    il_id: 'Ağrı',
    ilce: 'Eleşkirt',
    say_kodu: 4103,
    vergi_dairesi: 'Eleşkirt',
  },
  {
    id: 51,
    il_id: 'Ağrı',
    ilce: 'Hamur',
    say_kodu: 4104,
    vergi_dairesi: 'Hamur',
  },
  {
    id: 52,
    il_id: 'Ağrı',
    ilce: 'Taşlıçay',
    say_kodu: 4106,
    vergi_dairesi: 'Taşlıçay',
  },
  {
    id: 53,
    il_id: 'Ağrı',
    ilce: 'Tutak',
    say_kodu: 4107,
    vergi_dairesi: 'Tutak',
  },
  {
    id: 54,
    il_id: 'Amasya',
    ilce: 'Merkez',
    say_kodu: 5260,
    vergi_dairesi: 'Amasya',
  },
  {
    id: 55,
    il_id: 'Amasya',
    ilce: 'Merzifon',
    say_kodu: 5261,
    vergi_dairesi: 'Merzifon',
  },
  {
    id: 56,
    il_id: 'Amasya',
    ilce: 'Gümüşhacıköy',
    say_kodu: 5262,
    vergi_dairesi: 'Gümüşhacıköy',
  },
  {
    id: 57,
    il_id: 'Amasya',
    ilce: 'Taşova',
    say_kodu: 5263,
    vergi_dairesi: 'Taşova',
  },
  {
    id: 58,
    il_id: 'Amasya',
    ilce: 'Suluova',
    say_kodu: 5264,
    vergi_dairesi: 'Suluova',
  },
  {
    id: 59,
    il_id: 'Amasya',
    ilce: 'Göynücek',
    say_kodu: 5101,
    vergi_dairesi: 'Göynücek',
  },
  {
    id: 60,
    il_id: 'Amasya',
    ilce: 'Hamamözü',
    say_kodu: 5106,
    vergi_dairesi: 'Hamamözü',
  },
  {
    id: 61,
    il_id: 'Ankara',
    ilce: 'Merkez',
    say_kodu: 6280,
    vergi_dairesi: 'Anadolu İhtisas',
  },
  {
    id: 62,
    il_id: 'Ankara',
    ilce: 'Merkez',
    say_kodu: 6281,
    vergi_dairesi: 'Ankara İhtisas',
  },
  {
    id: 63,
    il_id: 'Ankara',
    ilce: 'Merkez',
    say_kodu: 6253,
    vergi_dairesi: 'Kavaklıdere',
  },
  {
    id: 64,
    il_id: 'Ankara',
    ilce: 'Merkez',
    say_kodu: 6268,
    vergi_dairesi: 'Hitit',
  },
  {
    id: 65,
    il_id: 'Ankara',
    ilce: 'Merkez',
    say_kodu: 6271,
    vergi_dairesi: 'Ostim',
  },
  {
    id: 66,
    il_id: 'Ankara',
    ilce: 'Merkez',
    say_kodu: 6252,
    vergi_dairesi: 'Veraset ve Harçlar',
  },
  {
    id: 67,
    il_id: 'Ankara',
    ilce: 'Merkez',
    say_kodu: 6254,
    vergi_dairesi: 'Maltepe',
  },
  {
    id: 68,
    il_id: 'Ankara',
    ilce: 'Merkez',
    say_kodu: 6255,
    vergi_dairesi: 'Yenimahalle',
  },
  {
    id: 69,
    il_id: 'Ankara',
    ilce: 'Merkez',
    say_kodu: 6257,
    vergi_dairesi: 'Çankaya',
  },
  {
    id: 70,
    il_id: 'Ankara',
    ilce: 'Merkez',
    say_kodu: 6258,
    vergi_dairesi: 'Kızılbey',
  },
  {
    id: 71,
    il_id: 'Ankara',
    ilce: 'Merkez',
    say_kodu: 6259,
    vergi_dairesi: 'Mithatpaşa',
  },
  {
    id: 72,
    il_id: 'Ankara',
    ilce: 'Merkez',
    say_kodu: 6260,
    vergi_dairesi: 'Ulus',
  },
  {
    id: 73,
    il_id: 'Ankara',
    ilce: 'Merkez',
    say_kodu: 6261,
    vergi_dairesi: 'Yıldırım Beyazıt',
  },
  {
    id: 74,
    il_id: 'Ankara',
    ilce: 'Merkez',
    say_kodu: 6262,
    vergi_dairesi: 'Seğmenler',
  },
  {
    id: 75,
    il_id: 'Ankara',
    ilce: 'Merkez',
    say_kodu: 6264,
    vergi_dairesi: 'Dikimevi',
  },
  {
    id: 76,
    il_id: 'Ankara',
    ilce: 'Merkez',
    say_kodu: 6265,
    vergi_dairesi: 'Doğanbey',
  },
  {
    id: 77,
    il_id: 'Ankara',
    ilce: 'Merkez',
    say_kodu: 6266,
    vergi_dairesi: 'Yeğenbey',
  },
  {
    id: 78,
    il_id: 'Ankara',
    ilce: 'Merkez',
    say_kodu: 6269,
    vergi_dairesi: 'Yahya Galip',
  },
  {
    id: 79,
    il_id: 'Ankara',
    ilce: 'Merkez',
    say_kodu: 6270,
    vergi_dairesi: 'Muhammet Karagüzel',
  },
  {
    id: 80,
    il_id: 'Ankara',
    ilce: 'Merkez',
    say_kodu: 6272,
    vergi_dairesi: 'Gölbaşı',
  },
  {
    id: 81,
    il_id: 'Ankara',
    ilce: 'Merkez',
    say_kodu: 6273,
    vergi_dairesi: 'Sincan',
  },
  {
    id: 82,
    il_id: 'Ankara',
    ilce: 'Merkez',
    say_kodu: 6274,
    vergi_dairesi: 'Dışkapı',
  },
  {
    id: 83,
    il_id: 'Ankara',
    ilce: 'Merkez',
    say_kodu: 6275,
    vergi_dairesi: 'Etimesgut',
  },
  {
    id: 84,
    il_id: 'Ankara',
    ilce: 'Merkez',
    say_kodu: 6276,
    vergi_dairesi: 'Başkent',
  },
  {
    id: 85,
    il_id: 'Ankara',
    ilce: 'Merkez',
    say_kodu: 6277,
    vergi_dairesi: 'Cumhuriyet',
  },
  {
    id: 86,
    il_id: 'Ankara',
    ilce: 'Merkez',
    say_kodu: 6278,
    vergi_dairesi: 'Keçiören',
  },
  {
    id: 87,
    il_id: 'Ankara',
    ilce: 'Merkez',
    say_kodu: 6279,
    vergi_dairesi: 'Kahramankazan',
  },
  {
    id: 88,
    il_id: 'Ankara',
    ilce: 'Polatlı',
    say_kodu: 6205,
    vergi_dairesi: 'Polatlı',
  },
  {
    id: 89,
    il_id: 'Ankara',
    ilce: 'Şereflikoçhisar',
    say_kodu: 6207,
    vergi_dairesi: 'Şereflikoçhisar',
  },
  {
    id: 90,
    il_id: 'Ankara',
    ilce: 'Beypazarı',
    say_kodu: 6209,
    vergi_dairesi: 'Beypazarı',
  },
  {
    id: 91,
    il_id: 'Ankara',
    ilce: 'Merkez',
    say_kodu: 6211,
    vergi_dairesi: 'Çubuk',
  },
  {
    id: 92,
    il_id: 'Ankara',
    ilce: 'Haymana',
    say_kodu: 6213,
    vergi_dairesi: 'Haymana',
  },
  {
    id: 93,
    il_id: 'Ankara',
    ilce: 'Merkez',
    say_kodu: 6215,
    vergi_dairesi: 'Elmadağ',
  },
  {
    id: 94,
    il_id: 'Ankara',
    ilce: 'Merkez',
    say_kodu: 6102,
    vergi_dairesi: 'Ayaş',
  },
  {
    id: 95,
    il_id: 'Ankara',
    ilce: 'Merkez',
    say_kodu: 6103,
    vergi_dairesi: 'Balâ',
  },
  {
    id: 96,
    il_id: 'Ankara',
    ilce: 'Çamlıdere',
    say_kodu: 6106,
    vergi_dairesi: 'Çamlıdere',
  },
  {
    id: 97,
    il_id: 'Ankara',
    ilce: 'Güdül',
    say_kodu: 6110,
    vergi_dairesi: 'Güdül',
  },
  {
    id: 98,
    il_id: 'Ankara',
    ilce: 'Merkez',
    say_kodu: 6112,
    vergi_dairesi: 'Kalecik',
  },
  {
    id: 99,
    il_id: 'Ankara',
    ilce: 'Kızılcahamam',
    say_kodu: 6115,
    vergi_dairesi: 'Kızılcahamam',
  },
  {
    id: 100,
    il_id: 'Ankara',
    ilce: 'Nallıhan',
    say_kodu: 6117,
    vergi_dairesi: 'Nallıhan',
  },
  {
    id: 101,
    il_id: 'Ankara',
    ilce: 'Merkez',
    say_kodu: 6125,
    vergi_dairesi: 'Akyurt',
  },
  {
    id: 102,
    il_id: 'Ankara',
    ilce: 'Evren',
    say_kodu: 6127,
    vergi_dairesi: 'Evren',
  },
  {
    id: 103,
    il_id: 'Antalya',
    ilce: 'Merkez',
    say_kodu: 7255,
    vergi_dairesi: 'Antalya Kurumlar',
  },
  {
    id: 104,
    il_id: 'Antalya',
    ilce: 'Merkez',
    say_kodu: 7256,
    vergi_dairesi: 'Antalya İhtisas',
  },
  {
    id: 105,
    il_id: 'Antalya',
    ilce: 'Merkez',
    say_kodu: 7251,
    vergi_dairesi: 'Üçkapılar',
  },
  {
    id: 106,
    il_id: 'Antalya',
    ilce: 'Merkez',
    say_kodu: 7252,
    vergi_dairesi: 'Kalekapı',
  },
  {
    id: 107,
    il_id: 'Antalya',
    ilce: 'Merkez',
    say_kodu: 7253,
    vergi_dairesi: 'Muratpaşa',
  },
  {
    id: 108,
    il_id: 'Antalya',
    ilce: 'Merkez',
    say_kodu: 7254,
    vergi_dairesi: 'Düden',
  },
  {
    id: 109,
    il_id: 'Antalya',
    ilce: 'Alanya',
    say_kodu: 7201,
    vergi_dairesi: 'Alanya',
  },
  {
    id: 110,
    il_id: 'Antalya',
    ilce: 'Serik',
    say_kodu: 7202,
    vergi_dairesi: 'Serik',
  },
  {
    id: 111,
    il_id: 'Antalya',
    ilce: 'Manavgat',
    say_kodu: 7203,
    vergi_dairesi: 'Manavgat',
  },
  {
    id: 112,
    il_id: 'Antalya',
    ilce: 'Elmalı',
    say_kodu: 7204,
    vergi_dairesi: 'Elmalı',
  },
  {
    id: 113,
    il_id: 'Antalya',
    ilce: 'Kemer',
    say_kodu: 7205,
    vergi_dairesi: 'Kemer',
  },
  {
    id: 114,
    il_id: 'Antalya',
    ilce: 'Kumluca',
    say_kodu: 7206,
    vergi_dairesi: 'Kumluca',
  },
  {
    id: 115,
    il_id: 'Antalya',
    ilce: 'Finike',
    say_kodu: 7104,
    vergi_dairesi: 'Finike',
  },
  {
    id: 116,
    il_id: 'Antalya',
    ilce: 'Akseki',
    say_kodu: 7101,
    vergi_dairesi: 'Akseki',
  },
  {
    id: 117,
    il_id: 'Antalya',
    ilce: 'Gazipaşa',
    say_kodu: 7105,
    vergi_dairesi: 'Gazipaşa',
  },
  {
    id: 118,
    il_id: 'Antalya',
    ilce: 'Gündoğmuş',
    say_kodu: 7106,
    vergi_dairesi: 'Gündoğmuş',
  },
  {
    id: 119,
    il_id: 'Antalya',
    ilce: 'Kaş',
    say_kodu: 7107,
    vergi_dairesi: 'Kaş',
  },
  {
    id: 120,
    il_id: 'Antalya',
    ilce: 'Korkuteli',
    say_kodu: 7108,
    vergi_dairesi: 'Korkuteli',
  },
  {
    id: 121,
    il_id: 'Antalya',
    ilce: 'Demre',
    say_kodu: 7112,
    vergi_dairesi: 'Demre',
  },
  {
    id: 122,
    il_id: 'Antalya',
    ilce: 'İbradı',
    say_kodu: 7113,
    vergi_dairesi: 'İbradı',
  },
  {
    id: 123,
    il_id: 'Artvin',
    ilce: 'Merkez',
    say_kodu: 8260,
    vergi_dairesi: 'Artvin ',
  },
  {
    id: 124,
    il_id: 'Artvin',
    ilce: 'Hopa',
    say_kodu: 8261,
    vergi_dairesi: 'Hopa',
  },
  {
    id: 125,
    il_id: 'Artvin',
    ilce: 'Arhavi',
    say_kodu: 8262,
    vergi_dairesi: 'Arhavi',
  },
  {
    id: 126,
    il_id: 'Artvin',
    ilce: 'Ardanuç',
    say_kodu: 8101,
    vergi_dairesi: 'Ardanuç',
  },
  {
    id: 127,
    il_id: 'Artvin',
    ilce: 'Borçka',
    say_kodu: 8103,
    vergi_dairesi: 'Borçka',
  },
  {
    id: 128,
    il_id: 'Artvin',
    ilce: 'Şavşat',
    say_kodu: 8105,
    vergi_dairesi: 'Şavşat',
  },
  {
    id: 129,
    il_id: 'Artvin',
    ilce: 'Yusufeli',
    say_kodu: 8106,
    vergi_dairesi: 'Yusufeli',
  },
  {
    id: 130,
    il_id: 'Artvin',
    ilce: 'Murgul',
    say_kodu: 8107,
    vergi_dairesi: 'Murgul',
  },
  {
    id: 131,
    il_id: 'Aydın',
    ilce: 'Merkez',
    say_kodu: 9201,
    vergi_dairesi: 'Efeler',
  },
  {
    id: 132,
    il_id: 'Aydın',
    ilce: 'Merkez',
    say_kodu: 9280,
    vergi_dairesi: 'Güzelhisar',
  },
  {
    id: 133,
    il_id: 'Aydın',
    ilce: 'Nazilli',
    say_kodu: 9260,
    vergi_dairesi: 'Nazilli',
  },
  {
    id: 134,
    il_id: 'Aydın',
    ilce: 'Söke',
    say_kodu: 9261,
    vergi_dairesi: 'Söke',
  },
  {
    id: 135,
    il_id: 'Aydın',
    ilce: 'Çine',
    say_kodu: 9262,
    vergi_dairesi: 'Çine',
  },
  {
    id: 136,
    il_id: 'Aydın',
    ilce: 'Germencik',
    say_kodu: 9263,
    vergi_dairesi: 'Germencik',
  },
  {
    id: 137,
    il_id: 'Aydın',
    ilce: 'Kuşadası',
    say_kodu: 9265,
    vergi_dairesi: 'Kuşadası',
  },
  {
    id: 138,
    il_id: 'Aydın',
    ilce: 'Didim',
    say_kodu: 9281,
    vergi_dairesi: 'Didim',
  },
  {
    id: 139,
    il_id: 'Aydın',
    ilce: 'Bozdoğan',
    say_kodu: 9101,
    vergi_dairesi: 'Bozdoğan',
  },
  {
    id: 140,
    il_id: 'Aydın',
    ilce: 'Karacasu',
    say_kodu: 9104,
    vergi_dairesi: 'Karacasu',
  },
  {
    id: 141,
    il_id: 'Aydın',
    ilce: 'Koçarlı',
    say_kodu: 9105,
    vergi_dairesi: 'Koçarlı',
  },
  {
    id: 142,
    il_id: 'Aydın',
    ilce: 'Kuyucak',
    say_kodu: 9107,
    vergi_dairesi: 'Kuyucak',
  },
  {
    id: 143,
    il_id: 'Aydın',
    ilce: 'Sultanhisar',
    say_kodu: 9110,
    vergi_dairesi: 'Sultanhisar',
  },
  {
    id: 144,
    il_id: 'Aydın',
    ilce: 'Yenipazar',
    say_kodu: 9111,
    vergi_dairesi: 'Yenipazar',
  },
  {
    id: 145,
    il_id: 'Aydın',
    ilce: 'Buharkent',
    say_kodu: 9112,
    vergi_dairesi: 'Buharkent',
  },
  {
    id: 146,
    il_id: 'Aydın',
    ilce: 'İncirliova',
    say_kodu: 9113,
    vergi_dairesi: 'İncirliova',
  },
  {
    id: 147,
    il_id: 'Aydın',
    ilce: 'Karpuzlu',
    say_kodu: 9114,
    vergi_dairesi: 'Karpuzlu',
  },
  {
    id: 148,
    il_id: 'Aydın',
    ilce: 'Köşk',
    say_kodu: 9115,
    vergi_dairesi: 'Köşk',
  },
  {
    id: 149,
    il_id: 'Balıkesir',
    ilce: 'Merkez',
    say_kodu: 10201,
    vergi_dairesi: 'Karesi',
  },
  {
    id: 150,
    il_id: 'Balıkesir',
    ilce: 'Merkez',
    say_kodu: 10280,
    vergi_dairesi: 'Kurtdereli',
  },
  {
    id: 151,
    il_id: 'Balıkesir',
    ilce: 'Ayvalık',
    say_kodu: 10260,
    vergi_dairesi: 'Ayvalık',
  },
  {
    id: 152,
    il_id: 'Balıkesir',
    ilce: 'Bandırma',
    say_kodu: 10261,
    vergi_dairesi: 'Bandırma',
  },
  {
    id: 153,
    il_id: 'Balıkesir',
    ilce: 'Burhaniye',
    say_kodu: 10262,
    vergi_dairesi: 'Burhaniye',
  },
  {
    id: 154,
    il_id: 'Balıkesir',
    ilce: 'Edremit',
    say_kodu: 10263,
    vergi_dairesi: 'Edremit',
  },
  {
    id: 155,
    il_id: 'Balıkesir',
    ilce: 'Gönen',
    say_kodu: 10264,
    vergi_dairesi: 'Gönen',
  },
  {
    id: 156,
    il_id: 'Balıkesir',
    ilce: 'Susurluk',
    say_kodu: 10265,
    vergi_dairesi: 'Susurluk',
  },
  {
    id: 157,
    il_id: 'Balıkesir',
    ilce: 'Erdek',
    say_kodu: 10266,
    vergi_dairesi: 'Erdek',
  },
  {
    id: 158,
    il_id: 'Balıkesir',
    ilce: 'Bigadiç',
    say_kodu: 10267,
    vergi_dairesi: 'Bigadiç',
  },
  {
    id: 159,
    il_id: 'Balıkesir',
    ilce: 'Sındırgı',
    say_kodu: 10268,
    vergi_dairesi: 'Sındırgı',
  },
  {
    id: 160,
    il_id: 'Balıkesir',
    ilce: 'Dursunbey',
    say_kodu: 10269,
    vergi_dairesi: 'Dursunbey',
  },
  {
    id: 161,
    il_id: 'Balıkesir',
    ilce: 'Balya',
    say_kodu: 10102,
    vergi_dairesi: 'Balya',
  },
  {
    id: 162,
    il_id: 'Balıkesir',
    ilce: 'Havran',
    say_kodu: 10110,
    vergi_dairesi: 'Havran',
  },
  {
    id: 163,
    il_id: 'Balıkesir',
    ilce: 'İvrindi',
    say_kodu: 10111,
    vergi_dairesi: 'İvrindi',
  },
  {
    id: 164,
    il_id: 'Balıkesir',
    ilce: 'Kepsut',
    say_kodu: 10112,
    vergi_dairesi: 'Kepsut',
  },
  {
    id: 165,
    il_id: 'Balıkesir',
    ilce: 'Manyas',
    say_kodu: 10113,
    vergi_dairesi: 'Manyas',
  },
  {
    id: 166,
    il_id: 'Balıkesir',
    ilce: 'Savaştepe',
    say_kodu: 10114,
    vergi_dairesi: 'Savaştepe',
  },
  {
    id: 167,
    il_id: 'Balıkesir',
    ilce: 'Marmara',
    say_kodu: 10117,
    vergi_dairesi: 'Marmara',
  },
  {
    id: 168,
    il_id: 'Balıkesir',
    ilce: 'Gömeç',
    say_kodu: 10118,
    vergi_dairesi: 'Gömeç',
  },
  {
    id: 169,
    il_id: 'Bilecik',
    ilce: 'Merkez',
    say_kodu: 11260,
    vergi_dairesi: 'Bilecik',
  },
  {
    id: 170,
    il_id: 'Bilecik',
    ilce: 'Bozüyük',
    say_kodu: 11261,
    vergi_dairesi: 'Bozüyük',
  },
  {
    id: 171,
    il_id: 'Bilecik',
    ilce: 'Gölpazarı',
    say_kodu: 11102,
    vergi_dairesi: 'Gölpazarı',
  },
  {
    id: 172,
    il_id: 'Bilecik',
    ilce: 'Osmaneli',
    say_kodu: 11103,
    vergi_dairesi: 'Osmaneli',
  },
  {
    id: 173,
    il_id: 'Bilecik',
    ilce: 'Pazaryeri',
    say_kodu: 11104,
    vergi_dairesi: 'Pazaryeri',
  },
  {
    id: 174,
    il_id: 'Bilecik',
    ilce: 'Söğüt',
    say_kodu: 11105,
    vergi_dairesi: 'Söğüt',
  },
  {
    id: 175,
    il_id: 'Bilecik',
    ilce: 'Yenipazar',
    say_kodu: 11106,
    vergi_dairesi: 'Yenipazar',
  },
  {
    id: 176,
    il_id: 'Bilecik',
    ilce: 'İnhisar',
    say_kodu: 11107,
    vergi_dairesi: 'İnhisar',
  },
  {
    id: 177,
    il_id: 'Bingöl',
    ilce: 'Merkez',
    say_kodu: 12260,
    vergi_dairesi: 'Bingöl',
  },
  {
    id: 178,
    il_id: 'Bingöl',
    ilce: 'Genç',
    say_kodu: 12101,
    vergi_dairesi: 'Genç',
  },
  {
    id: 179,
    il_id: 'Bingöl',
    ilce: 'Karlıova',
    say_kodu: 12102,
    vergi_dairesi: 'Karlıova',
  },
  {
    id: 180,
    il_id: 'Bingöl',
    ilce: 'Kiğı',
    say_kodu: 12103,
    vergi_dairesi: 'Kiğı',
  },
  {
    id: 181,
    il_id: 'Bingöl',
    ilce: 'Solhan',
    say_kodu: 12104,
    vergi_dairesi: 'Solhan',
  },
  {
    id: 182,
    il_id: 'Bingöl',
    ilce: 'Adaklı',
    say_kodu: 12105,
    vergi_dairesi: 'Adaklı',
  },
  {
    id: 183,
    il_id: 'Bingöl',
    ilce: 'Yayladere',
    say_kodu: 12106,
    vergi_dairesi: 'Yayladere',
  },
  {
    id: 184,
    il_id: 'Bingöl',
    ilce: 'Yedisu',
    say_kodu: 12107,
    vergi_dairesi: 'Yedisu',
  },
  {
    id: 185,
    il_id: 'Bitlis',
    ilce: 'Merkez',
    say_kodu: 13260,
    vergi_dairesi: 'Bitlis',
  },
  {
    id: 186,
    il_id: 'Bitlis',
    ilce: 'Tatvan',
    say_kodu: 13261,
    vergi_dairesi: 'Tatvan ',
  },
  {
    id: 187,
    il_id: 'Bitlis',
    ilce: 'Adilcevaz',
    say_kodu: 13101,
    vergi_dairesi: 'Adilcevaz',
  },
  {
    id: 188,
    il_id: 'Bitlis',
    ilce: 'Ahlat',
    say_kodu: 13102,
    vergi_dairesi: 'Ahlat',
  },
  {
    id: 189,
    il_id: 'Bitlis',
    ilce: 'Hizan',
    say_kodu: 13103,
    vergi_dairesi: 'Hizan',
  },
  {
    id: 190,
    il_id: 'Bitlis',
    ilce: 'Mutki',
    say_kodu: 13104,
    vergi_dairesi: 'Mutki',
  },
  {
    id: 191,
    il_id: 'Bitlis',
    ilce: 'Göroymak',
    say_kodu: 13106,
    vergi_dairesi: 'Güroymak',
  },
  {
    id: 192,
    il_id: 'Bolu',
    ilce: 'Merkez',
    say_kodu: 14260,
    vergi_dairesi: 'Bolu',
  },
  {
    id: 193,
    il_id: 'Bolu',
    ilce: 'Gerede',
    say_kodu: 14262,
    vergi_dairesi: 'Gerede',
  },
  {
    id: 194,
    il_id: 'Bolu',
    ilce: 'Göynük',
    say_kodu: 14104,
    vergi_dairesi: 'Göynük',
  },
  {
    id: 195,
    il_id: 'Bolu',
    ilce: 'Kıbrıscık',
    say_kodu: 14105,
    vergi_dairesi: 'Kıbrıscık',
  },
  {
    id: 196,
    il_id: 'Bolu',
    ilce: 'Mengen',
    say_kodu: 14106,
    vergi_dairesi: 'Mengen',
  },
  {
    id: 197,
    il_id: 'Bolu',
    ilce: 'Mudurnu',
    say_kodu: 14107,
    vergi_dairesi: 'Mudurnu',
  },
  {
    id: 198,
    il_id: 'Bolu',
    ilce: 'Seben',
    say_kodu: 14108,
    vergi_dairesi: 'Seben',
  },
  {
    id: 199,
    il_id: 'Bolu',
    ilce: 'Dörtdivan',
    say_kodu: 14113,
    vergi_dairesi: 'Dörtdivan',
  },
  {
    id: 200,
    il_id: 'Bolu',
    ilce: 'Yeniçağa',
    say_kodu: 14114,
    vergi_dairesi: 'Yeniçağa',
  },
  {
    id: 201,
    il_id: 'Burdur',
    ilce: 'Merkez',
    say_kodu: 15260,
    vergi_dairesi: 'Burdur',
  },
  {
    id: 202,
    il_id: 'Burdur',
    ilce: 'Bucak',
    say_kodu: 15261,
    vergi_dairesi: 'Bucak',
  },
  {
    id: 203,
    il_id: 'Burdur',
    ilce: 'Ağlasun',
    say_kodu: 15101,
    vergi_dairesi: 'Ağlasun',
  },
  {
    id: 204,
    il_id: 'Burdur',
    ilce: 'Gölhisar',
    say_kodu: 15103,
    vergi_dairesi: 'Gölhisar',
  },
  {
    id: 205,
    il_id: 'Burdur',
    ilce: 'Tefenni',
    say_kodu: 15104,
    vergi_dairesi: 'Tefenni',
  },
  {
    id: 206,
    il_id: 'Burdur',
    ilce: 'Yeşilova',
    say_kodu: 15105,
    vergi_dairesi: 'Yeşilova',
  },
  {
    id: 207,
    il_id: 'Burdur',
    ilce: 'Karamanlı',
    say_kodu: 15106,
    vergi_dairesi: 'Karamanlı',
  },
  {
    id: 208,
    il_id: 'Burdur',
    ilce: 'Kemer',
    say_kodu: 15107,
    vergi_dairesi: 'Kemer',
  },
  {
    id: 209,
    il_id: 'Burdur',
    ilce: 'Altınyayla',
    say_kodu: 15108,
    vergi_dairesi: 'Altınyayla',
  },
  {
    id: 210,
    il_id: 'Burdur',
    ilce: 'Çavdır',
    say_kodu: 15109,
    vergi_dairesi: 'Çavdır',
  },
  {
    id: 211,
    il_id: 'Burdur',
    ilce: 'Çeltikçi',
    say_kodu: 15110,
    vergi_dairesi: 'Çeltikçi',
  },
  {
    id: 212,
    il_id: 'Bursa',
    ilce: 'Merkez',
    say_kodu: 16250,
    vergi_dairesi: 'Bursa İhtisas',
  },
  {
    id: 213,
    il_id: 'Bursa',
    ilce: 'Merkez',
    say_kodu: 16251,
    vergi_dairesi: 'Osmangazi',
  },
  {
    id: 214,
    il_id: 'Bursa',
    ilce: 'Merkez',
    say_kodu: 16252,
    vergi_dairesi: 'Yıldırım',
  },
  {
    id: 215,
    il_id: 'Bursa',
    ilce: 'Merkez',
    say_kodu: 16253,
    vergi_dairesi: 'Çekirge',
  },
  {
    id: 216,
    il_id: 'Bursa',
    ilce: 'Merkez',
    say_kodu: 16254,
    vergi_dairesi: 'Setbaşı',
  },
  {
    id: 217,
    il_id: 'Bursa',
    ilce: 'Merkez',
    say_kodu: 16255,
    vergi_dairesi: 'Uludağ',
  },
  {
    id: 218,
    il_id: 'Bursa',
    ilce: 'Merkez',
    say_kodu: 16256,
    vergi_dairesi: 'Yeşil',
  },
  {
    id: 219,
    il_id: 'Bursa',
    ilce: 'Merkez',
    say_kodu: 16257,
    vergi_dairesi: 'Nilüfer',
  },
  {
    id: 220,
    il_id: 'Bursa',
    ilce: 'Merkez',
    say_kodu: 16258,
    vergi_dairesi: 'Ertuğrulgazi',
  },
  {
    id: 221,
    il_id: 'Bursa',
    ilce: 'Merkez',
    say_kodu: 16259,
    vergi_dairesi: 'Gökdere',
  },
  {
    id: 222,
    il_id: 'Bursa',
    ilce: 'Merkez',
    say_kodu: 16201,
    vergi_dairesi: 'Gemlik',
  },
  {
    id: 223,
    il_id: 'Bursa',
    ilce: 'İnegöl',
    say_kodu: 16202,
    vergi_dairesi: 'İnegöl',
  },
  {
    id: 224,
    il_id: 'Bursa',
    ilce: 'Karacabey',
    say_kodu: 16203,
    vergi_dairesi: 'Karacabey',
  },
  {
    id: 225,
    il_id: 'Bursa',
    ilce: 'Mustafakemalpaşa',
    say_kodu: 16204,
    vergi_dairesi: 'Mustafakemalpaşa',
  },
  {
    id: 226,
    il_id: 'Bursa',
    ilce: 'Merkez',
    say_kodu: 16205,
    vergi_dairesi: 'Mudanya',
  },
  {
    id: 227,
    il_id: 'Bursa',
    ilce: 'Orhangazi',
    say_kodu: 16206,
    vergi_dairesi: 'Orhangazi',
  },
  {
    id: 228,
    il_id: 'Bursa',
    ilce: 'İznik',
    say_kodu: 16207,
    vergi_dairesi: 'İznik',
  },
  {
    id: 229,
    il_id: 'Bursa',
    ilce: 'Yenişehir',
    say_kodu: 16208,
    vergi_dairesi: 'Yenişehir',
  },
  {
    id: 230,
    il_id: 'Bursa',
    ilce: 'Keles',
    say_kodu: 16105,
    vergi_dairesi: 'Keles',
  },
  {
    id: 231,
    il_id: 'Bursa',
    ilce: 'Orhaneli',
    say_kodu: 16108,
    vergi_dairesi: 'Orhaneli',
  },
  {
    id: 232,
    il_id: 'Bursa',
    ilce: 'Harmancık',
    say_kodu: 16111,
    vergi_dairesi: 'Harmancık',
  },
  {
    id: 233,
    il_id: 'Bursa',
    ilce: 'Büyükorhan',
    say_kodu: 16112,
    vergi_dairesi: 'Büyükorhan',
  },
  {
    id: 234,
    il_id: 'Çanakkale',
    ilce: 'Merkez',
    say_kodu: 17260,
    vergi_dairesi: 'Çanakkale',
  },
  {
    id: 235,
    il_id: 'Çanakkale',
    ilce: 'Biga',
    say_kodu: 17261,
    vergi_dairesi: 'Biga',
  },
  {
    id: 236,
    il_id: 'Çanakkale',
    ilce: 'Çan',
    say_kodu: 17262,
    vergi_dairesi: 'Çan',
  },
  {
    id: 237,
    il_id: 'Çanakkale',
    ilce: 'Gelibolu',
    say_kodu: 17263,
    vergi_dairesi: 'Gelibolu',
  },
  {
    id: 238,
    il_id: 'Çanakkale',
    ilce: 'Ayvacık',
    say_kodu: 17101,
    vergi_dairesi: 'Ayvacık',
  },
  {
    id: 239,
    il_id: 'Çanakkale',
    ilce: 'Bayramiç',
    say_kodu: 17102,
    vergi_dairesi: 'Bayramiç',
  },
  {
    id: 240,
    il_id: 'Çanakkale',
    ilce: 'Bozcaada',
    say_kodu: 17104,
    vergi_dairesi: 'Bozcaada',
  },
  {
    id: 241,
    il_id: 'Çanakkale',
    ilce: 'Eceabat',
    say_kodu: 17106,
    vergi_dairesi: 'Eceabat',
  },
  {
    id: 242,
    il_id: 'Çanakkale',
    ilce: 'Ezine',
    say_kodu: 17107,
    vergi_dairesi: 'Ezine',
  },
  {
    id: 243,
    il_id: 'Çanakkale',
    ilce: 'Gökçeada',
    say_kodu: 17109,
    vergi_dairesi: 'Gökçeada',
  },
  {
    id: 244,
    il_id: 'Çanakkale',
    ilce: 'Lapseki',
    say_kodu: 17110,
    vergi_dairesi: 'Lapseki',
  },
  {
    id: 245,
    il_id: 'Çanakkale',
    ilce: 'Yenice',
    say_kodu: 17111,
    vergi_dairesi: 'Yenice',
  },
  {
    id: 246,
    il_id: 'Çankırı',
    ilce: 'Merkez',
    say_kodu: 18260,
    vergi_dairesi: 'Çankırı',
  },
  {
    id: 247,
    il_id: 'Çankırı',
    ilce: 'Çerkeş',
    say_kodu: 18101,
    vergi_dairesi: 'Çerkeş',
  },
  {
    id: 248,
    il_id: 'Çankırı',
    ilce: 'Eldivan',
    say_kodu: 18102,
    vergi_dairesi: 'Eldivan',
  },
  {
    id: 249,
    il_id: 'Çankırı',
    ilce: 'Ilgaz',
    say_kodu: 18104,
    vergi_dairesi: 'Ilgaz ',
  },
  {
    id: 250,
    il_id: 'Çankırı',
    ilce: 'Kurşunlu',
    say_kodu: 18105,
    vergi_dairesi: 'Kurşunlu',
  },
  {
    id: 251,
    il_id: 'Çankırı',
    ilce: 'Orta',
    say_kodu: 18106,
    vergi_dairesi: 'Orta',
  },
  {
    id: 252,
    il_id: 'Çankırı',
    ilce: 'Şabanözü',
    say_kodu: 18108,
    vergi_dairesi: 'Şabanözü',
  },
  {
    id: 253,
    il_id: 'Çankırı',
    ilce: 'Yapraklı',
    say_kodu: 18109,
    vergi_dairesi: 'Yapraklı',
  },
  {
    id: 254,
    il_id: 'Çankırı',
    ilce: 'Atkaracalar',
    say_kodu: 18110,
    vergi_dairesi: 'Atkaracalar',
  },
  {
    id: 255,
    il_id: 'Çankırı',
    ilce: 'Kızılırmak',
    say_kodu: 18111,
    vergi_dairesi: 'Kızılırmak',
  },
  {
    id: 256,
    il_id: 'Çankırı',
    ilce: 'Bayramören',
    say_kodu: 18112,
    vergi_dairesi: 'Bayramören',
  },
  {
    id: 257,
    il_id: 'Çankırı',
    ilce: 'Korgun',
    say_kodu: 18113,
    vergi_dairesi: 'Korgun',
  },
  {
    id: 258,
    il_id: 'Çorum',
    ilce: 'Merkez',
    say_kodu: 19260,
    vergi_dairesi: 'Çorum',
  },
  {
    id: 259,
    il_id: 'Çorum',
    ilce: 'Sungurlu',
    say_kodu: 19261,
    vergi_dairesi: 'Sungurlu',
  },
  {
    id: 260,
    il_id: 'Çorum',
    ilce: 'Alaca',
    say_kodu: 19101,
    vergi_dairesi: 'Alaca',
  },
  {
    id: 261,
    il_id: 'Çorum',
    ilce: 'Bayat',
    say_kodu: 19102,
    vergi_dairesi: 'Bayat',
  },
  {
    id: 262,
    il_id: 'Çorum',
    ilce: 'İskilip',
    say_kodu: 19103,
    vergi_dairesi: 'İskilip',
  },
  {
    id: 263,
    il_id: 'Çorum',
    ilce: 'Kargı',
    say_kodu: 19104,
    vergi_dairesi: 'Kargı',
  },
  {
    id: 264,
    il_id: 'Çorum',
    ilce: 'Mecitözü',
    say_kodu: 19105,
    vergi_dairesi: 'Mecitözü',
  },
  {
    id: 265,
    il_id: 'Çorum',
    ilce: 'Ortaköy',
    say_kodu: 19106,
    vergi_dairesi: 'Ortaköy',
  },
  {
    id: 266,
    il_id: 'Çorum',
    ilce: 'Osmancık',
    say_kodu: 19107,
    vergi_dairesi: 'Osmancık',
  },
  {
    id: 267,
    il_id: 'Çorum',
    ilce: 'Boğazkale',
    say_kodu: 19109,
    vergi_dairesi: 'Boğazkale',
  },
  {
    id: 268,
    il_id: 'Çorum',
    ilce: 'Uğurludağ',
    say_kodu: 19110,
    vergi_dairesi: 'Uğurludağ',
  },
  {
    id: 269,
    il_id: 'Çorum',
    ilce: 'Dodurga',
    say_kodu: 19111,
    vergi_dairesi: 'Dodurga',
  },
  {
    id: 270,
    il_id: 'Çorum',
    ilce: 'Oğuzlar',
    say_kodu: 19112,
    vergi_dairesi: 'Oğuzlar',
  },
  {
    id: 271,
    il_id: 'Çorum',
    ilce: 'Laçin',
    say_kodu: 19113,
    vergi_dairesi: 'Laçin',
  },
  {
    id: 272,
    il_id: 'Denizli',
    ilce: 'Merkez',
    say_kodu: 20202,
    vergi_dairesi: 'Çınar',
  },
  {
    id: 273,
    il_id: 'Denizli',
    ilce: 'Merkez',
    say_kodu: 20203,
    vergi_dairesi: 'Gökpınar',
  },
  {
    id: 274,
    il_id: 'Denizli',
    ilce: 'Merkez',
    say_kodu: 20201,
    vergi_dairesi: 'Saraylar',
  },
  {
    id: 275,
    il_id: 'Denizli',
    ilce: 'Merkez',
    say_kodu: 20250,
    vergi_dairesi: 'Denizli İhtisas',
  },
  {
    id: 276,
    il_id: 'Denizli',
    ilce: 'Merkez',
    say_kodu: 20280,
    vergi_dairesi: 'Pamukkale',
  },
  {
    id: 277,
    il_id: 'Denizli',
    ilce: 'Sarayköy',
    say_kodu: 20260,
    vergi_dairesi: 'Sarayköy',
  },
  {
    id: 278,
    il_id: 'Denizli',
    ilce: 'Acıpayam',
    say_kodu: 20261,
    vergi_dairesi: 'Acıpayam',
  },
  {
    id: 279,
    il_id: 'Denizli',
    ilce: 'Tavas',
    say_kodu: 20262,
    vergi_dairesi: 'Tavas',
  },
  {
    id: 280,
    il_id: 'Denizli',
    ilce: 'Buldan',
    say_kodu: 20263,
    vergi_dairesi: 'Buldan',
  },
  {
    id: 281,
    il_id: 'Denizli',
    ilce: 'Çal',
    say_kodu: 20264,
    vergi_dairesi: 'Çal',
  },
  {
    id: 282,
    il_id: 'Denizli',
    ilce: 'Çivril',
    say_kodu: 20265,
    vergi_dairesi: 'Çivril',
  },
  {
    id: 283,
    il_id: 'Denizli',
    ilce: 'Çameli',
    say_kodu: 20104,
    vergi_dairesi: 'Çameli',
  },
  {
    id: 284,
    il_id: 'Denizli',
    ilce: 'Çardak',
    say_kodu: 20105,
    vergi_dairesi: 'Çardak',
  },
  {
    id: 285,
    il_id: 'Denizli',
    ilce: 'Güney',
    say_kodu: 20107,
    vergi_dairesi: 'Güney',
  },
  {
    id: 286,
    il_id: 'Denizli',
    ilce: 'Kale',
    say_kodu: 20108,
    vergi_dairesi: 'Kale',
  },
  {
    id: 287,
    il_id: 'Denizli',
    ilce: 'Babadağ',
    say_kodu: 20111,
    vergi_dairesi: 'Babadağ',
  },
  {
    id: 288,
    il_id: 'Denizli',
    ilce: 'Bekilli',
    say_kodu: 20112,
    vergi_dairesi: 'Bekilli',
  },
  {
    id: 289,
    il_id: 'Denizli',
    ilce: 'Honaz',
    say_kodu: 20113,
    vergi_dairesi: 'Honaz',
  },
  {
    id: 290,
    il_id: 'Denizli',
    ilce: 'Serinhisar',
    say_kodu: 20114,
    vergi_dairesi: 'Serinhisar',
  },
  {
    id: 291,
    il_id: 'Denizli',
    ilce: 'Akköy',
    say_kodu: 20115,
    vergi_dairesi: 'Akköy',
  },
  {
    id: 292,
    il_id: 'Denizli',
    ilce: 'Baklan',
    say_kodu: 20116,
    vergi_dairesi: 'Baklan',
  },
  {
    id: 293,
    il_id: 'Denizli',
    ilce: 'Beyağaç',
    say_kodu: 20117,
    vergi_dairesi: 'Beyağaç',
  },
  {
    id: 294,
    il_id: 'Denizli',
    ilce: 'Bozkurt',
    say_kodu: 20118,
    vergi_dairesi: 'Bozkurt',
  },
  {
    id: 295,
    il_id: 'Diyarbakır',
    ilce: 'Merkez',
    say_kodu: 21251,
    vergi_dairesi: 'Gökalp',
  },
  {
    id: 296,
    il_id: 'Diyarbakır',
    ilce: 'Merkez',
    say_kodu: 21281,
    vergi_dairesi: 'Süleyman Nazif',
  },
  {
    id: 297,
    il_id: 'Diyarbakır',
    ilce: 'Merkez',
    say_kodu: 21282,
    vergi_dairesi: 'Cahit Sıtkı Tarancı',
  },
  {
    id: 298,
    il_id: 'Diyarbakır',
    ilce: 'Bismil',
    say_kodu: 21101,
    vergi_dairesi: 'Bismil',
  },
  {
    id: 299,
    il_id: 'Diyarbakır',
    ilce: 'Çermik',
    say_kodu: 21102,
    vergi_dairesi: 'Çermik',
  },
  {
    id: 300,
    il_id: 'Diyarbakır',
    ilce: 'Çınar',
    say_kodu: 21103,
    vergi_dairesi: 'Çınar',
  },
  {
    id: 301,
    il_id: 'Diyarbakır',
    ilce: 'Çüngüş',
    say_kodu: 21104,
    vergi_dairesi: 'Çüngüş',
  },
  {
    id: 302,
    il_id: 'Diyarbakır',
    ilce: 'Dicle',
    say_kodu: 21105,
    vergi_dairesi: 'Dicle',
  },
  {
    id: 303,
    il_id: 'Diyarbakır',
    ilce: 'Ergani',
    say_kodu: 21106,
    vergi_dairesi: 'Ergani',
  },
  {
    id: 304,
    il_id: 'Diyarbakır',
    ilce: 'Hani',
    say_kodu: 21107,
    vergi_dairesi: 'Hani',
  },
  {
    id: 305,
    il_id: 'Diyarbakır',
    ilce: 'Hazro',
    say_kodu: 21108,
    vergi_dairesi: 'Hazro',
  },
  {
    id: 306,
    il_id: 'Diyarbakır',
    ilce: 'Kulp',
    say_kodu: 21109,
    vergi_dairesi: 'Kulp',
  },
  {
    id: 307,
    il_id: 'Diyarbakır',
    ilce: 'Lice',
    say_kodu: 21110,
    vergi_dairesi: 'Lice',
  },
  {
    id: 308,
    il_id: 'Diyarbakır',
    ilce: 'Silvan',
    say_kodu: 21111,
    vergi_dairesi: 'Silvan',
  },
  {
    id: 309,
    il_id: 'Diyarbakır',
    ilce: 'Eğil',
    say_kodu: 21112,
    vergi_dairesi: 'Eğil',
  },
  {
    id: 310,
    il_id: 'Diyarbakır',
    ilce: 'Kocaköy',
    say_kodu: 21113,
    vergi_dairesi: 'Kocaköy',
  },
  {
    id: 311,
    il_id: 'Edirne',
    ilce: 'Merkez',
    say_kodu: 22201,
    vergi_dairesi: 'Arda',
  },
  {
    id: 312,
    il_id: 'Edirne',
    ilce: 'Merkez',
    say_kodu: 22260,
    vergi_dairesi: 'Kırkpınar ',
  },
  {
    id: 313,
    il_id: 'Edirne',
    ilce: 'Keşan',
    say_kodu: 22261,
    vergi_dairesi: 'Keşan',
  },
  {
    id: 314,
    il_id: 'Edirne',
    ilce: 'Uzunköprü',
    say_kodu: 22262,
    vergi_dairesi: 'Uzunköprü',
  },
  {
    id: 315,
    il_id: 'Edirne',
    ilce: 'Havsa',
    say_kodu: 22263,
    vergi_dairesi: 'Havsa',
  },
  {
    id: 316,
    il_id: 'Edirne',
    ilce: 'İpsala',
    say_kodu: 22264,
    vergi_dairesi: 'İpsala',
  },
  {
    id: 317,
    il_id: 'Edirne',
    ilce: 'Enez',
    say_kodu: 22101,
    vergi_dairesi: 'Enez',
  },
  {
    id: 318,
    il_id: 'Edirne',
    ilce: 'Lalapaşa',
    say_kodu: 22105,
    vergi_dairesi: 'Lalapaşa',
  },
  {
    id: 319,
    il_id: 'Edirne',
    ilce: 'Meriç',
    say_kodu: 22106,
    vergi_dairesi: 'Meriç',
  },
  {
    id: 320,
    il_id: 'Edirne',
    ilce: 'Süloğlu',
    say_kodu: 22108,
    vergi_dairesi: 'Süloğlu',
  },
  {
    id: 321,
    il_id: 'Elazığ',
    ilce: 'Merkez',
    say_kodu: 23201,
    vergi_dairesi: 'Harput',
  },
  {
    id: 322,
    il_id: 'Elazığ',
    ilce: 'Merkez',
    say_kodu: 23280,
    vergi_dairesi: 'Hazar',
  },
  {
    id: 323,
    il_id: 'Elazığ',
    ilce: 'Ağın',
    say_kodu: 23101,
    vergi_dairesi: 'Ağın',
  },
  {
    id: 324,
    il_id: 'Elazığ',
    ilce: 'Baskil',
    say_kodu: 23102,
    vergi_dairesi: 'Baskil',
  },
  {
    id: 325,
    il_id: 'Elazığ',
    ilce: 'Karakoçan',
    say_kodu: 23103,
    vergi_dairesi: 'Karakoçan',
  },
  {
    id: 326,
    il_id: 'Elazığ',
    ilce: 'Keban',
    say_kodu: 23104,
    vergi_dairesi: 'Keban',
  },
  {
    id: 327,
    il_id: 'Elazığ',
    ilce: 'Maden',
    say_kodu: 23105,
    vergi_dairesi: 'Maden',
  },
  {
    id: 328,
    il_id: 'Elazığ',
    ilce: 'Palu',
    say_kodu: 23106,
    vergi_dairesi: 'Palu',
  },
  {
    id: 329,
    il_id: 'Elazığ',
    ilce: 'Sivrice',
    say_kodu: 23107,
    vergi_dairesi: 'Sivrice',
  },
  {
    id: 330,
    il_id: 'Elazığ',
    ilce: 'Arıcak',
    say_kodu: 23108,
    vergi_dairesi: 'Arıcak',
  },
  {
    id: 331,
    il_id: 'Elazığ',
    ilce: 'Kovancılar',
    say_kodu: 23109,
    vergi_dairesi: 'Kovancılar',
  },
  {
    id: 332,
    il_id: 'Elazığ',
    ilce: 'Alacakaya',
    say_kodu: 23110,
    vergi_dairesi: 'Alacakaya',
  },
  {
    id: 333,
    il_id: 'Erzincan',
    ilce: 'Merkez',
    say_kodu: 24260,
    vergi_dairesi: 'Fevzipaşa',
  },
  {
    id: 334,
    il_id: 'Erzincan',
    ilce: 'Çayırlı',
    say_kodu: 24101,
    vergi_dairesi: 'Çayırlı',
  },
  {
    id: 335,
    il_id: 'Erzincan',
    ilce: 'İliç',
    say_kodu: 24102,
    vergi_dairesi: 'İliç',
  },
  {
    id: 336,
    il_id: 'Erzincan',
    ilce: 'Kemah',
    say_kodu: 24103,
    vergi_dairesi: 'Kemah',
  },
  {
    id: 337,
    il_id: 'Erzincan',
    ilce: 'Kemaliye',
    say_kodu: 24104,
    vergi_dairesi: 'Kemaliye',
  },
  {
    id: 338,
    il_id: 'Erzincan',
    ilce: 'Refahiye',
    say_kodu: 24105,
    vergi_dairesi: 'Refahiye',
  },
  {
    id: 339,
    il_id: 'Erzincan',
    ilce: 'Tercan',
    say_kodu: 24106,
    vergi_dairesi: 'Tercan',
  },
  {
    id: 340,
    il_id: 'Erzincan',
    ilce: 'Üzümlü',
    say_kodu: 24107,
    vergi_dairesi: 'Üzümlü',
  },
  {
    id: 341,
    il_id: 'Erzincan',
    ilce: 'Otlukbeli',
    say_kodu: 24108,
    vergi_dairesi: 'Otlukbeli',
  },
  {
    id: 342,
    il_id: 'Erzurum',
    ilce: 'Merkez',
    say_kodu: 25251,
    vergi_dairesi: 'Aziziye',
  },
  {
    id: 343,
    il_id: 'Erzurum',
    ilce: 'Merkez',
    say_kodu: 25280,
    vergi_dairesi: 'Kazımkarabekir',
  },
  {
    id: 344,
    il_id: 'Erzurum',
    ilce: 'Aşkale',
    say_kodu: 25101,
    vergi_dairesi: 'Aşkale',
  },
  {
    id: 345,
    il_id: 'Erzurum',
    ilce: 'Çat',
    say_kodu: 25102,
    vergi_dairesi: 'Çat',
  },
  {
    id: 346,
    il_id: 'Erzurum',
    ilce: 'Hınıs',
    say_kodu: 25103,
    vergi_dairesi: 'Hınıs',
  },
  {
    id: 347,
    il_id: 'Erzurum',
    ilce: 'Horasan',
    say_kodu: 25104,
    vergi_dairesi: 'Horasan',
  },
  {
    id: 348,
    il_id: 'Erzurum',
    ilce: 'İspir',
    say_kodu: 25105,
    vergi_dairesi: 'İspir',
  },
  {
    id: 349,
    il_id: 'Erzurum',
    ilce: 'Karayazı',
    say_kodu: 25106,
    vergi_dairesi: 'Karayazı',
  },
  {
    id: 350,
    il_id: 'Erzurum',
    ilce: 'Narman',
    say_kodu: 25107,
    vergi_dairesi: 'Narman',
  },
  {
    id: 351,
    il_id: 'Erzurum',
    ilce: 'Oltu',
    say_kodu: 25108,
    vergi_dairesi: 'Oltu',
  },
  {
    id: 352,
    il_id: 'Erzurum',
    ilce: 'Olur',
    say_kodu: 25109,
    vergi_dairesi: 'Olur',
  },
  {
    id: 353,
    il_id: 'Erzurum',
    ilce: 'Pasinler',
    say_kodu: 25110,
    vergi_dairesi: 'Pasinler',
  },
  {
    id: 354,
    il_id: 'Erzurum',
    ilce: 'Şenkaya',
    say_kodu: 25111,
    vergi_dairesi: 'Şenkaya',
  },
  {
    id: 355,
    il_id: 'Erzurum',
    ilce: 'Tekman',
    say_kodu: 25112,
    vergi_dairesi: 'Tekman',
  },
  {
    id: 356,
    il_id: 'Erzurum',
    ilce: 'Tortum',
    say_kodu: 25113,
    vergi_dairesi: 'Tortum',
  },
  {
    id: 357,
    il_id: 'Erzurum',
    ilce: 'Karaçoban',
    say_kodu: 25114,
    vergi_dairesi: 'Karaçoban',
  },
  {
    id: 358,
    il_id: 'Erzurum',
    ilce: 'Uzundere',
    say_kodu: 25115,
    vergi_dairesi: 'Uzundere',
  },
  {
    id: 359,
    il_id: 'Erzurum',
    ilce: 'Pazaryolu',
    say_kodu: 25116,
    vergi_dairesi: 'Pazaryolu',
  },
  {
    id: 360,
    il_id: 'Erzurum',
    ilce: 'Merkez',
    say_kodu: 25117,
    vergi_dairesi: 'Aziziye (Ilıca)',
  },
  {
    id: 361,
    il_id: 'Erzurum',
    ilce: 'Köprüköy',
    say_kodu: 25118,
    vergi_dairesi: 'Köprüköy',
  },
  {
    id: 362,
    il_id: 'Eskişehir',
    ilce: 'Merkez Başkanlık',
    say_kodu: 26250,
    vergi_dairesi: 'Eskişehir',
  },
  {
    id: 363,
    il_id: 'Eskişehir',
    ilce: 'Mahmudiye',
    say_kodu: 26102,
    vergi_dairesi: 'Mahmudiye',
  },
  {
    id: 364,
    il_id: 'Eskişehir',
    ilce: 'Mihalıççık',
    say_kodu: 26103,
    vergi_dairesi: 'Mihalıççık',
  },
  {
    id: 365,
    il_id: 'Eskişehir',
    ilce: 'Sarıcakaya',
    say_kodu: 26104,
    vergi_dairesi: 'Sarıcakaya',
  },
  {
    id: 366,
    il_id: 'Eskişehir',
    ilce: 'Seyitgazi',
    say_kodu: 26105,
    vergi_dairesi: 'Seyitgazi',
  },
  {
    id: 367,
    il_id: 'Eskişehir',
    ilce: 'Alpu',
    say_kodu: 26107,
    vergi_dairesi: 'Alpu',
  },
  {
    id: 368,
    il_id: 'Eskişehir',
    ilce: 'Beylikova',
    say_kodu: 26108,
    vergi_dairesi: 'Beylikova',
  },
  {
    id: 369,
    il_id: 'Eskişehir',
    ilce: 'İnönü',
    say_kodu: 26109,
    vergi_dairesi: 'İnönü',
  },
  {
    id: 370,
    il_id: 'Eskişehir',
    ilce: 'Günyüzü',
    say_kodu: 26110,
    vergi_dairesi: 'Günyüzü',
  },
  {
    id: 371,
    il_id: 'Eskişehir',
    ilce: 'Han',
    say_kodu: 26111,
    vergi_dairesi: 'Han',
  },
  {
    id: 372,
    il_id: 'Eskişehir',
    ilce: 'Mihalgazi',
    say_kodu: 26112,
    vergi_dairesi: 'Mihalgazi',
  },
  {
    id: 373,
    il_id: 'Gaziantep',
    ilce: 'Merkez',
    say_kodu: 27250,
    vergi_dairesi: 'Gaziantep İhtisas',
  },
  {
    id: 374,
    il_id: 'Gaziantep',
    ilce: 'Merkez',
    say_kodu: 27251,
    vergi_dairesi: 'Suburcu',
  },
  {
    id: 375,
    il_id: 'Gaziantep',
    ilce: 'Merkez',
    say_kodu: 27252,
    vergi_dairesi: 'Şehitkâmil ',
  },
  {
    id: 376,
    il_id: 'Gaziantep',
    ilce: 'Merkez',
    say_kodu: 27253,
    vergi_dairesi: 'Şahinbey',
  },
  {
    id: 377,
    il_id: 'Gaziantep',
    ilce: 'Merkez',
    say_kodu: 27254,
    vergi_dairesi: 'Gazikent',
  },
  {
    id: 378,
    il_id: 'Gaziantep',
    ilce: 'Merkez',
    say_kodu: 27255,
    vergi_dairesi: 'Kozanlı',
  },
  {
    id: 379,
    il_id: 'Gaziantep',
    ilce: 'Nizip',
    say_kodu: 27202,
    vergi_dairesi: 'Nizip',
  },
  {
    id: 380,
    il_id: 'Gaziantep',
    ilce: 'İslahiye',
    say_kodu: 27203,
    vergi_dairesi: 'İslahiye',
  },
  {
    id: 381,
    il_id: 'Gaziantep',
    ilce: 'Araban',
    say_kodu: 27101,
    vergi_dairesi: 'Araban',
  },
  {
    id: 382,
    il_id: 'Gaziantep',
    ilce: 'Merkez',
    say_kodu: 27105,
    vergi_dairesi: 'Oğuzeli',
  },
  {
    id: 383,
    il_id: 'Gaziantep',
    ilce: 'Yavuzeli',
    say_kodu: 27106,
    vergi_dairesi: 'Yavuzeli',
  },
  {
    id: 384,
    il_id: 'Gaziantep',
    ilce: 'Karkamış',
    say_kodu: 27107,
    vergi_dairesi: 'Karkamış',
  },
  {
    id: 385,
    il_id: 'Gaziantep',
    ilce: 'Nurdağı',
    say_kodu: 27108,
    vergi_dairesi: 'Nurdağı',
  },
  {
    id: 386,
    il_id: 'Giresun',
    ilce: 'Merkez',
    say_kodu: 28260,
    vergi_dairesi: 'Giresun',
  },
  {
    id: 387,
    il_id: 'Giresun',
    ilce: 'Bulancak',
    say_kodu: 28261,
    vergi_dairesi: 'Bulancak',
  },
  {
    id: 388,
    il_id: 'Giresun',
    ilce: 'Alucra',
    say_kodu: 28101,
    vergi_dairesi: 'Alucra',
  },
  {
    id: 389,
    il_id: 'Giresun',
    ilce: 'Dereli',
    say_kodu: 28103,
    vergi_dairesi: 'Dereli',
  },
  {
    id: 390,
    il_id: 'Giresun',
    ilce: 'Espiye',
    say_kodu: 28104,
    vergi_dairesi: 'Espiye',
  },
  {
    id: 391,
    il_id: 'Giresun',
    ilce: 'Eynesil',
    say_kodu: 28105,
    vergi_dairesi: 'Eynesil',
  },
  {
    id: 392,
    il_id: 'Giresun',
    ilce: 'Görele',
    say_kodu: 28106,
    vergi_dairesi: 'Görele',
  },
  {
    id: 393,
    il_id: 'Giresun',
    ilce: 'Keşap',
    say_kodu: 28107,
    vergi_dairesi: 'Keşap',
  },
  {
    id: 394,
    il_id: 'Giresun',
    ilce: 'Şebinkarahisar',
    say_kodu: 28108,
    vergi_dairesi: 'Şebinkarahisar',
  },
  {
    id: 395,
    il_id: 'Giresun',
    ilce: 'Tirebolu',
    say_kodu: 28109,
    vergi_dairesi: 'Tirebolu',
  },
  {
    id: 396,
    il_id: 'Giresun',
    ilce: 'Piraziz',
    say_kodu: 28110,
    vergi_dairesi: 'Piraziz',
  },
  {
    id: 397,
    il_id: 'Giresun',
    ilce: 'Yağlıdere',
    say_kodu: 28111,
    vergi_dairesi: 'Yağlıdere',
  },
  {
    id: 398,
    il_id: 'Giresun',
    ilce: 'Çanakçı',
    say_kodu: 28112,
    vergi_dairesi: 'Çanakçı',
  },
  {
    id: 399,
    il_id: 'Giresun',
    ilce: 'Güce',
    say_kodu: 28113,
    vergi_dairesi: 'Güce',
  },
  {
    id: 400,
    il_id: 'Giresun',
    ilce: 'Çamoluk',
    say_kodu: 28114,
    vergi_dairesi: 'Çamoluk',
  },
  {
    id: 401,
    il_id: 'Giresun',
    ilce: 'Doğankent',
    say_kodu: 28115,
    vergi_dairesi: 'Doğankent',
  },
  {
    id: 402,
    il_id: 'Gümüşhane',
    ilce: 'Merkez',
    say_kodu: 29260,
    vergi_dairesi: 'Gümüşhane',
  },
  {
    id: 403,
    il_id: 'Gümüşhane',
    ilce: 'Kelkit',
    say_kodu: 29102,
    vergi_dairesi: 'Kelkit',
  },
  {
    id: 404,
    il_id: 'Gümüşhane',
    ilce: 'Şiran',
    say_kodu: 29103,
    vergi_dairesi: 'Şiran',
  },
  {
    id: 405,
    il_id: 'Gümüşhane',
    ilce: 'Torul',
    say_kodu: 29104,
    vergi_dairesi: 'Torul',
  },
  {
    id: 406,
    il_id: 'Gümüşhane',
    ilce: 'Köse',
    say_kodu: 29107,
    vergi_dairesi: 'Köse',
  },
  {
    id: 407,
    il_id: 'Gümüşhane',
    ilce: 'Kürtün',
    say_kodu: 29108,
    vergi_dairesi: 'Kürtün',
  },
  {
    id: 408,
    il_id: 'Hakkari',
    ilce: 'Merkez',
    say_kodu: 30260,
    vergi_dairesi: 'Hakkari',
  },
  {
    id: 409,
    il_id: 'Hakkari',
    ilce: 'Yüksekova',
    say_kodu: 30261,
    vergi_dairesi: 'Yüksekova',
  },
  {
    id: 410,
    il_id: 'Hakkari',
    ilce: 'Çukurca',
    say_kodu: 30102,
    vergi_dairesi: 'Çukurca',
  },
  {
    id: 411,
    il_id: 'Hakkari',
    ilce: 'Şemdinli',
    say_kodu: 30103,
    vergi_dairesi: 'Şemdinli',
  },
  {
    id: 412,
    il_id: 'Hatay',
    ilce: 'Merkez',
    say_kodu: 31201,
    vergi_dairesi: '23 Temmuz',
  },
  {
    id: 413,
    il_id: 'Hatay',
    ilce: 'Merkez',
    say_kodu: 31203,
    vergi_dairesi: 'Antakya',
  },
  {
    id: 414,
    il_id: 'Hatay',
    ilce: 'Merkez',
    say_kodu: 31280,
    vergi_dairesi: 'Şükrükanatlı',
  },
  {
    id: 415,
    il_id: 'Hatay',
    ilce: 'İskenderun',
    say_kodu: 31202,
    vergi_dairesi: 'Sahil',
  },
  {
    id: 416,
    il_id: 'Hatay',
    ilce: 'İskenderun',
    say_kodu: 31281,
    vergi_dairesi: 'Akdeniz',
  },
  {
    id: 417,
    il_id: 'Hatay',
    ilce: 'İskenderun',
    say_kodu: 31290,
    vergi_dairesi: 'Asım Gündüz',
  },
  {
    id: 418,
    il_id: 'Hatay',
    ilce: 'Dörtyol',
    say_kodu: 31260,
    vergi_dairesi: 'Dörtyol',
  },
  {
    id: 419,
    il_id: 'Hatay',
    ilce: 'Kırıkhan',
    say_kodu: 31261,
    vergi_dairesi: 'Kırıkhan',
  },
  {
    id: 420,
    il_id: 'Hatay',
    ilce: 'Reyhanlı',
    say_kodu: 31262,
    vergi_dairesi: 'Reyhanlı',
  },
  {
    id: 421,
    il_id: 'Hatay',
    ilce: 'Samandağ',
    say_kodu: 31263,
    vergi_dairesi: 'Samandağ',
  },
  {
    id: 422,
    il_id: 'Hatay',
    ilce: 'Altınözü',
    say_kodu: 31101,
    vergi_dairesi: 'Altınözü',
  },
  {
    id: 423,
    il_id: 'Hatay',
    ilce: 'Hassa',
    say_kodu: 31103,
    vergi_dairesi: 'Hassa',
  },
  {
    id: 424,
    il_id: 'Hatay',
    ilce: 'Yayladağı',
    say_kodu: 31108,
    vergi_dairesi: 'Yayladağı',
  },
  {
    id: 425,
    il_id: 'Hatay',
    ilce: 'Erzin',
    say_kodu: 31109,
    vergi_dairesi: 'Erzin',
  },
  {
    id: 426,
    il_id: 'Hatay',
    ilce: 'Belen',
    say_kodu: 31110,
    vergi_dairesi: 'Belen',
  },
  {
    id: 427,
    il_id: 'Hatay',
    ilce: 'Kumlu',
    say_kodu: 31111,
    vergi_dairesi: 'Kumlu',
  },
  {
    id: 428,
    il_id: 'Isparta',
    ilce: 'Merkez',
    say_kodu: 32201,
    vergi_dairesi: 'Davraz',
  },
  {
    id: 429,
    il_id: 'Isparta',
    ilce: 'Merkez',
    say_kodu: 32260,
    vergi_dairesi: 'Kaymakkapı',
  },
  {
    id: 430,
    il_id: 'Isparta',
    ilce: 'Eğirdir',
    say_kodu: 32261,
    vergi_dairesi: 'Eğirdir',
  },
  {
    id: 431,
    il_id: 'Isparta',
    ilce: 'Yalvaç',
    say_kodu: 32262,
    vergi_dairesi: 'Yalvaç',
  },
  {
    id: 432,
    il_id: 'Isparta',
    ilce: 'Atabey',
    say_kodu: 32101,
    vergi_dairesi: 'Atabey',
  },
  {
    id: 433,
    il_id: 'Isparta',
    ilce: 'Gelendost',
    say_kodu: 32103,
    vergi_dairesi: 'Gelendost',
  },
  {
    id: 434,
    il_id: 'Isparta',
    ilce: 'Keçiborlu',
    say_kodu: 32104,
    vergi_dairesi: 'Keçiborlu',
  },
  {
    id: 435,
    il_id: 'Isparta',
    ilce: 'Senirkent',
    say_kodu: 32105,
    vergi_dairesi: 'Senirkent',
  },
  {
    id: 436,
    il_id: 'Isparta',
    ilce: 'Sütçüler',
    say_kodu: 32106,
    vergi_dairesi: 'Sütçüler',
  },
  {
    id: 437,
    il_id: 'Isparta',
    ilce: 'Şarkikaraağaç',
    say_kodu: 32107,
    vergi_dairesi: 'Şarkikaraağaç',
  },
  {
    id: 438,
    il_id: 'Isparta',
    ilce: 'Uluborlu',
    say_kodu: 32108,
    vergi_dairesi: 'Uluborlu ',
  },
  {
    id: 439,
    il_id: 'Isparta',
    ilce: 'Aksu',
    say_kodu: 32110,
    vergi_dairesi: 'Aksu',
  },
  {
    id: 440,
    il_id: 'Isparta',
    ilce: 'Gönen',
    say_kodu: 32111,
    vergi_dairesi: 'Gönen',
  },
  {
    id: 441,
    il_id: 'Isparta',
    ilce: 'Yenişarbademli',
    say_kodu: 32112,
    vergi_dairesi: 'Yenişarbademli',
  },
  {
    id: 442,
    il_id: 'Mersin',
    ilce: 'Merkez',
    say_kodu: 33250,
    vergi_dairesi: 'İstiklâl',
  },
  {
    id: 443,
    il_id: 'Mersin',
    ilce: 'Merkez',
    say_kodu: 33252,
    vergi_dairesi: 'Uray',
  },
  {
    id: 444,
    il_id: 'Mersin',
    ilce: 'Merkez',
    say_kodu: 33254,
    vergi_dairesi: 'Liman',
  },
  {
    id: 445,
    il_id: 'Mersin',
    ilce: 'Merkez',
    say_kodu: 33255,
    vergi_dairesi: 'Toros',
  },
  {
    id: 446,
    il_id: 'Mersin',
    ilce: 'Merkez',
    say_kodu: 33256,
    vergi_dairesi: 'Mersin İhtisas',
  },
  {
    id: 447,
    il_id: 'Mersin',
    ilce: 'Erdemli',
    say_kodu: 33201,
    vergi_dairesi: 'Erdemli',
  },
  {
    id: 448,
    il_id: 'Mersin',
    ilce: 'Silifke',
    say_kodu: 33202,
    vergi_dairesi: 'Silifke',
  },
  {
    id: 449,
    il_id: 'Mersin',
    ilce: 'Anamur',
    say_kodu: 33203,
    vergi_dairesi: 'Anamur',
  },
  {
    id: 450,
    il_id: 'Mersin',
    ilce: 'Tarsus',
    say_kodu: 33204,
    vergi_dairesi: 'Kızılmurat',
  },
  {
    id: 451,
    il_id: 'Mersin',
    ilce: 'Tarsus',
    say_kodu: 33205,
    vergi_dairesi: 'Şehitkerim',
  },
  {
    id: 452,
    il_id: 'Mersin',
    ilce: 'Gülnar',
    say_kodu: 33103,
    vergi_dairesi: 'Gülnar',
  },
  {
    id: 453,
    il_id: 'Mersin',
    ilce: 'Mut',
    say_kodu: 33104,
    vergi_dairesi: 'Mut',
  },
  {
    id: 454,
    il_id: 'Mersin',
    ilce: 'Aydıncık',
    say_kodu: 33107,
    vergi_dairesi: 'Aydıncık',
  },
  {
    id: 455,
    il_id: 'Mersin',
    ilce: 'Bozyazı',
    say_kodu: 33108,
    vergi_dairesi: 'Bozyazı',
  },
  {
    id: 456,
    il_id: 'Mersin',
    ilce: 'Çamlıyayla',
    say_kodu: 33109,
    vergi_dairesi: 'Çamlıyayla',
  },
  {
    id: 457,
    il_id: 'İstanbul',
    ilce: 'Merkez Başkanlık',
    say_kodu: 34230,
    vergi_dairesi: 'Büyük Mükellefler',
  },
  {
    id: 458,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34231,
    vergi_dairesi: 'Boğaziçi Kurumlar',
  },
  {
    id: 459,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34244,
    vergi_dairesi: 'Anadolu Kurumlar',
  },
  {
    id: 460,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34232,
    vergi_dairesi: 'Marmara Kurumlar',
  },
  {
    id: 461,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34220,
    vergi_dairesi: 'Haliç İhtisas',
  },
  {
    id: 462,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34221,
    vergi_dairesi: 'Vatan İhtisas',
  },
  {
    id: 463,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34222,
    vergi_dairesi: 'Çamlıca İhtisas',
  },
  {
    id: 464,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34223,
    vergi_dairesi: 'Alemdağ',
  },
  {
    id: 465,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34266,
    vergi_dairesi: 'Beyoğlu',
  },
  {
    id: 466,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34278,
    vergi_dairesi: 'Halkalı',
  },
  {
    id: 467,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34234,
    vergi_dairesi: 'Davutpaşa',
  },
  {
    id: 468,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34235,
    vergi_dairesi: 'Esenler',
  },
  {
    id: 469,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34236,
    vergi_dairesi: 'Fatih',
  },
  {
    id: 470,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34237,
    vergi_dairesi: 'Küçükköy',
  },
  {
    id: 471,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34239,
    vergi_dairesi: 'Merter',
  },
  {
    id: 472,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34242,
    vergi_dairesi: 'Sultanbeyli',
  },
  {
    id: 473,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34245,
    vergi_dairesi: 'Tuzla',
  },
  {
    id: 474,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34246,
    vergi_dairesi: 'Kozyatağı',
  },
  {
    id: 475,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34247,
    vergi_dairesi: 'Maslak',
  },
  {
    id: 476,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34248,
    vergi_dairesi: 'Zincirlikuyu',
  },
  {
    id: 477,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34249,
    vergi_dairesi: 'İkitelli',
  },
  {
    id: 478,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34251,
    vergi_dairesi: 'Beşiktaş',
  },
  {
    id: 479,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34252,
    vergi_dairesi: 'Ümraniye',
  },
  {
    id: 480,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34253,
    vergi_dairesi: 'Yeditepe',
  },
  {
    id: 481,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34254,
    vergi_dairesi: 'Kasımpaşa',
  },
  {
    id: 482,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34255,
    vergi_dairesi: 'Erenköy',
  },
  {
    id: 483,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34256,
    vergi_dairesi: 'Hisar',
  },
  {
    id: 484,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34257,
    vergi_dairesi: 'Tuna',
  },
  {
    id: 485,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34258,
    vergi_dairesi: 'Rıhtım',
  },
  {
    id: 486,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34259,
    vergi_dairesi: 'Güngören',
  },
  {
    id: 487,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34260,
    vergi_dairesi: 'Kocasinan',
  },
  {
    id: 488,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34261,
    vergi_dairesi: 'Güneşli',
  },
  {
    id: 489,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34262,
    vergi_dairesi: 'Küçükyalı',
  },
  {
    id: 490,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34263,
    vergi_dairesi: 'Pendik',
  },
  {
    id: 491,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34264,
    vergi_dairesi: 'Bayrampaşa',
  },
  {
    id: 492,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34265,
    vergi_dairesi: 'Beyazıt',
  },
  {
    id: 493,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34269,
    vergi_dairesi: 'Gaziosmanpaşa',
  },
  {
    id: 494,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34270,
    vergi_dairesi: 'Göztepe',
  },
  {
    id: 495,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34271,
    vergi_dairesi: 'Hocapaşa',
  },
  {
    id: 496,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34272,
    vergi_dairesi: 'Kadıköy',
  },
  {
    id: 497,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34273,
    vergi_dairesi: 'Kocamustafapaşa',
  },
  {
    id: 498,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34274,
    vergi_dairesi: 'Mecidiyeköy',
  },
  {
    id: 499,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34276,
    vergi_dairesi: 'Şişli',
  },
  {
    id: 500,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34277,
    vergi_dairesi: 'Üsküdar',
  },
  {
    id: 501,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34279,
    vergi_dairesi: 'Kağıthane ',
  },
  {
    id: 502,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34280,
    vergi_dairesi: 'Zeytinburnu',
  },
  {
    id: 503,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34281,
    vergi_dairesi: 'Beykoz',
  },
  {
    id: 504,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34283,
    vergi_dairesi: 'Sarıyer',
  },
  {
    id: 505,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34284,
    vergi_dairesi: 'Bakırköy',
  },
  {
    id: 506,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34285,
    vergi_dairesi: 'Kartal',
  },
  {
    id: 507,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34287,
    vergi_dairesi: 'Nakil Vasıtaları',
  },
  {
    id: 508,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34288,
    vergi_dairesi: 'Sarıgazi',
  },
  {
    id: 509,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34291,
    vergi_dairesi: 'Atışalanı',
  },
  {
    id: 510,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34292,
    vergi_dairesi: 'Yakacık',
  },
  {
    id: 511,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34293,
    vergi_dairesi: 'Yenibosna',
  },
  {
    id: 512,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34294,
    vergi_dairesi: 'Avcılar',
  },
  {
    id: 513,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34296,
    vergi_dairesi: 'Küçükçekmece',
  },
  {
    id: 514,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34297,
    vergi_dairesi: 'Beylikdüzü',
  },
  {
    id: 515,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34295,
    vergi_dairesi: 'Adalar',
  },
  {
    id: 516,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34203,
    vergi_dairesi: 'Silivri',
  },
  {
    id: 517,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34204,
    vergi_dairesi: 'Büyükçekmece',
  },
  {
    id: 518,
    il_id: 'İstanbul',
    ilce: 'Merkez',
    say_kodu: 34205,
    vergi_dairesi: 'Şile',
  },
  {
    id: 519,
    il_id: 'İzmir',
    ilce: 'Merkez',
    say_kodu: 35264,
    vergi_dairesi: 'Bornova',
  },
  {
    id: 520,
    il_id: 'İzmir',
    ilce: 'Merkez',
    say_kodu: 35265,
    vergi_dairesi: 'Çakabey',
  },
  {
    id: 521,
    il_id: 'İzmir',
    ilce: 'Merkez',
    say_kodu: 35259,
    vergi_dairesi: 'Kordon',
  },
  {
    id: 522,
    il_id: 'İzmir',
    ilce: 'Merkez',
    say_kodu: 35263,
    vergi_dairesi: 'Hasan Tahsin',
  },
  {
    id: 523,
    il_id: 'İzmir',
    ilce: 'Merkez',
    say_kodu: 35250,
    vergi_dairesi: 'İzmir İhtisas',
  },
  {
    id: 524,
    il_id: 'İzmir',
    ilce: 'Merkez',
    say_kodu: 35251,
    vergi_dairesi: '9 Eylül',
  },
  {
    id: 525,
    il_id: 'İzmir',
    ilce: 'Merkez',
    say_kodu: 35252,
    vergi_dairesi: 'Yamanlar',
  },
  {
    id: 526,
    il_id: 'İzmir',
    ilce: 'Merkez',
    say_kodu: 35256,
    vergi_dairesi: 'Karşıyaka',
  },
  {
    id: 527,
    il_id: 'İzmir',
    ilce: 'Merkez',
    say_kodu: 35257,
    vergi_dairesi: 'Kemeraltı',
  },
  {
    id: 528,
    il_id: 'İzmir',
    ilce: 'Merkez',
    say_kodu: 35258,
    vergi_dairesi: 'Konak',
  },
  {
    id: 529,
    il_id: 'İzmir',
    ilce: 'Merkez',
    say_kodu: 35260,
    vergi_dairesi: 'Şirinyer',
  },
  {
    id: 530,
    il_id: 'İzmir',
    ilce: 'Merkez',
    say_kodu: 35261,
    vergi_dairesi: 'Kadifekale',
  },
  {
    id: 531,
    il_id: 'İzmir',
    ilce: 'Merkez',
    say_kodu: 35262,
    vergi_dairesi: 'Taşıtlar',
  },
  {
    id: 532,
    il_id: 'İzmir',
    ilce: 'Merkez',
    say_kodu: 35254,
    vergi_dairesi: 'Belkahve',
  },
  {
    id: 533,
    il_id: 'İzmir',
    ilce: 'Merkez',
    say_kodu: 35266,
    vergi_dairesi: 'Balçova',
  },
  {
    id: 534,
    il_id: 'İzmir',
    ilce: 'Merkez',
    say_kodu: 35267,
    vergi_dairesi: 'Gaziemir',
  },
  {
    id: 535,
    il_id: 'İzmir',
    ilce: 'Merkez',
    say_kodu: 35268,
    vergi_dairesi: 'Ege',
  },
  {
    id: 536,
    il_id: 'İzmir',
    ilce: 'Merkez',
    say_kodu: 35269,
    vergi_dairesi: 'Çiğli',
  },
  {
    id: 537,
    il_id: 'İzmir',
    ilce: 'Bayındır',
    say_kodu: 35201,
    vergi_dairesi: 'Bayındır',
  },
  {
    id: 538,
    il_id: 'İzmir',
    ilce: 'Bergama',
    say_kodu: 35202,
    vergi_dairesi: 'Bergama',
  },
  {
    id: 539,
    il_id: 'İzmir',
    ilce: 'Merkez',
    say_kodu: 35203,
    vergi_dairesi: 'Menemen',
  },
  {
    id: 540,
    il_id: 'İzmir',
    ilce: '\u00d6demiş',
    say_kodu: 35204,
    vergi_dairesi: '\u00d6demiş',
  },
  {
    id: 541,
    il_id: 'İzmir',
    ilce: 'Tire',
    say_kodu: 35205,
    vergi_dairesi: 'Tire',
  },
  {
    id: 542,
    il_id: 'İzmir',
    ilce: 'Merkez',
    say_kodu: 35206,
    vergi_dairesi: 'Torbalı',
  },
  {
    id: 543,
    il_id: 'İzmir',
    ilce: 'Merkez',
    say_kodu: 35207,
    vergi_dairesi: 'Kemalpaşa',
  },
  {
    id: 544,
    il_id: 'İzmir',
    ilce: 'Merkez',
    say_kodu: 35208,
    vergi_dairesi: 'Urla',
  },
  {
    id: 545,
    il_id: 'İzmir',
    ilce: 'Selçuk',
    say_kodu: 35209,
    vergi_dairesi: 'Selçuk',
  },
  {
    id: 546,
    il_id: 'İzmir',
    ilce: 'Kınık',
    say_kodu: 35210,
    vergi_dairesi: 'Kınık',
  },
  {
    id: 547,
    il_id: 'İzmir',
    ilce: 'Kiraz',
    say_kodu: 35211,
    vergi_dairesi: 'Kiraz',
  },
  {
    id: 548,
    il_id: 'İzmir',
    ilce: 'Çeşme',
    say_kodu: 35212,
    vergi_dairesi: 'Çeşme',
  },
  {
    id: 549,
    il_id: 'İzmir',
    ilce: 'Merkez',
    say_kodu: 35213,
    vergi_dairesi: 'Aliağa',
  },
  {
    id: 550,
    il_id: 'İzmir',
    ilce: 'Merkez',
    say_kodu: 35215,
    vergi_dairesi: 'Menderes',
  },
  {
    id: 551,
    il_id: 'İzmir',
    ilce: 'Dikili',
    say_kodu: 35105,
    vergi_dairesi: 'Dikili',
  },
  {
    id: 552,
    il_id: 'İzmir',
    ilce: 'Merkez',
    say_kodu: 35106,
    vergi_dairesi: 'Foça',
  },
  {
    id: 553,
    il_id: 'İzmir',
    ilce: 'Karaburun',
    say_kodu: 35107,
    vergi_dairesi: 'Karaburun',
  },
  {
    id: 554,
    il_id: 'İzmir',
    ilce: 'Merkez',
    say_kodu: 35114,
    vergi_dairesi: 'Seferihisar',
  },
  {
    id: 555,
    il_id: 'İzmir',
    ilce: 'Beydağ',
    say_kodu: 35120,
    vergi_dairesi: 'Beydağ',
  },
  {
    id: 556,
    il_id: 'Kars',
    ilce: 'Merkez',
    say_kodu: 36260,
    vergi_dairesi: 'Kars',
  },
  {
    id: 557,
    il_id: 'Kars',
    ilce: 'Arpaçay',
    say_kodu: 36103,
    vergi_dairesi: 'Arpaçay',
  },
  {
    id: 558,
    il_id: 'Kars',
    ilce: 'Digor',
    say_kodu: 36105,
    vergi_dairesi: 'Digor',
  },
  {
    id: 559,
    il_id: 'Kars',
    ilce: 'Kağızman',
    say_kodu: 36109,
    vergi_dairesi: 'Kağızman',
  },
  {
    id: 560,
    il_id: 'Kars',
    ilce: 'Sarıkamış',
    say_kodu: 36111,
    vergi_dairesi: 'Sarıkamış',
  },
  {
    id: 561,
    il_id: 'Kars',
    ilce: 'Selim',
    say_kodu: 36112,
    vergi_dairesi: 'Selim',
  },
  {
    id: 562,
    il_id: 'Kars',
    ilce: 'Susuz',
    say_kodu: 36113,
    vergi_dairesi: 'Susuz',
  },
  {
    id: 563,
    il_id: 'Kars',
    ilce: 'Akyaka',
    say_kodu: 36115,
    vergi_dairesi: 'Akyaka',
  },
  {
    id: 564,
    il_id: 'Kastamonu',
    ilce: 'Merkez',
    say_kodu: 37260,
    vergi_dairesi: 'Kastamonu ',
  },
  {
    id: 565,
    il_id: 'Kastamonu',
    ilce: 'Tosya',
    say_kodu: 37261,
    vergi_dairesi: 'Tosya',
  },
  {
    id: 566,
    il_id: 'Kastamonu',
    ilce: 'Taşköprü',
    say_kodu: 37262,
    vergi_dairesi: 'Taşköprü',
  },
  {
    id: 567,
    il_id: 'Kastamonu',
    ilce: 'Araç',
    say_kodu: 37101,
    vergi_dairesi: 'Araç',
  },
  {
    id: 568,
    il_id: 'Kastamonu',
    ilce: 'Azdavay',
    say_kodu: 37102,
    vergi_dairesi: 'Azdavay',
  },
  {
    id: 569,
    il_id: 'Kastamonu',
    ilce: 'Bozkurt',
    say_kodu: 37103,
    vergi_dairesi: 'Bozkurt',
  },
  {
    id: 570,
    il_id: 'Kastamonu',
    ilce: 'Cide',
    say_kodu: 37104,
    vergi_dairesi: 'Cide',
  },
  {
    id: 571,
    il_id: 'Kastamonu',
    ilce: 'Çatalzeytin',
    say_kodu: 37105,
    vergi_dairesi: 'Çatalzeytin',
  },
  {
    id: 572,
    il_id: 'Kastamonu',
    ilce: 'Daday',
    say_kodu: 37106,
    vergi_dairesi: 'Daday',
  },
  {
    id: 573,
    il_id: 'Kastamonu',
    ilce: 'Devrekani',
    say_kodu: 37107,
    vergi_dairesi: 'Devrekani',
  },
  {
    id: 574,
    il_id: 'Kastamonu',
    ilce: 'İnebolu',
    say_kodu: 37108,
    vergi_dairesi: 'İnebolu',
  },
  {
    id: 575,
    il_id: 'Kastamonu',
    ilce: 'Küre',
    say_kodu: 37109,
    vergi_dairesi: 'Küre',
  },
  {
    id: 576,
    il_id: 'Kastamonu',
    ilce: 'Abana',
    say_kodu: 37112,
    vergi_dairesi: 'Abana',
  },
  {
    id: 577,
    il_id: 'Kastamonu',
    ilce: 'İhsangazi',
    say_kodu: 37113,
    vergi_dairesi: 'İhsangazi',
  },
  {
    id: 578,
    il_id: 'Kastamonu',
    ilce: 'Pınarbaşı',
    say_kodu: 37114,
    vergi_dairesi: 'Pınarbaşı',
  },
  {
    id: 579,
    il_id: 'Kastamonu',
    ilce: 'Şenpazar',
    say_kodu: 37115,
    vergi_dairesi: 'Şenpazar',
  },
  {
    id: 580,
    il_id: 'Kastamonu',
    ilce: 'Ağlı',
    say_kodu: 37116,
    vergi_dairesi: 'Ağlı',
  },
  {
    id: 581,
    il_id: 'Kastamonu',
    ilce: 'Doğanyurt',
    say_kodu: 37117,
    vergi_dairesi: 'Doğanyurt',
  },
  {
    id: 582,
    il_id: 'Kastamonu',
    ilce: 'Hanönü',
    say_kodu: 37118,
    vergi_dairesi: 'Hanönü',
  },
  {
    id: 583,
    il_id: 'Kastamonu',
    ilce: 'Seydiler',
    say_kodu: 37119,
    vergi_dairesi: 'Seydiler',
  },
  {
    id: 584,
    il_id: 'Kayseri',
    ilce: 'Merkez',
    say_kodu: 38250,
    vergi_dairesi: 'Kayseri İhtisas',
  },
  {
    id: 585,
    il_id: 'Kayseri',
    ilce: 'Merkez',
    say_kodu: 38251,
    vergi_dairesi: 'Mimar Sinan',
  },
  {
    id: 586,
    il_id: 'Kayseri',
    ilce: 'Merkez',
    say_kodu: 38252,
    vergi_dairesi: 'Erciyes',
  },
  {
    id: 587,
    il_id: 'Kayseri',
    ilce: 'Merkez',
    say_kodu: 38253,
    vergi_dairesi: 'Kaleönü',
  },
  {
    id: 588,
    il_id: 'Kayseri',
    ilce: 'Merkez',
    say_kodu: 38254,
    vergi_dairesi: 'Gevher Nesibe',
  },
  {
    id: 589,
    il_id: 'Kayseri',
    ilce: 'Develi',
    say_kodu: 38201,
    vergi_dairesi: 'Develi',
  },
  {
    id: 590,
    il_id: 'Kayseri',
    ilce: 'Pınarbaşı',
    say_kodu: 38202,
    vergi_dairesi: 'Pınarbaşı',
  },
  {
    id: 591,
    il_id: 'Kayseri',
    ilce: 'Bünyan',
    say_kodu: 38203,
    vergi_dairesi: 'Bünyan',
  },
  {
    id: 592,
    il_id: 'Kayseri',
    ilce: 'Felahiye',
    say_kodu: 38103,
    vergi_dairesi: 'Felahiye',
  },
  {
    id: 593,
    il_id: 'Kayseri',
    ilce: 'İncesu',
    say_kodu: 38104,
    vergi_dairesi: 'İncesu',
  },
  {
    id: 594,
    il_id: 'Kayseri',
    ilce: 'Sarıoğlan',
    say_kodu: 38106,
    vergi_dairesi: 'Sarıoğlan',
  },
  {
    id: 595,
    il_id: 'Kayseri',
    ilce: 'Sarız',
    say_kodu: 38107,
    vergi_dairesi: 'Sarız',
  },
  {
    id: 596,
    il_id: 'Kayseri',
    ilce: 'Tomarza',
    say_kodu: 38108,
    vergi_dairesi: 'Tomarza',
  },
  {
    id: 597,
    il_id: 'Kayseri',
    ilce: 'Yahyalı',
    say_kodu: 38109,
    vergi_dairesi: 'Yahyalı',
  },
  {
    id: 598,
    il_id: 'Kayseri',
    ilce: 'Yeşilhisar',
    say_kodu: 38110,
    vergi_dairesi: 'Yeşilhisar',
  },
  {
    id: 599,
    il_id: 'Kayseri',
    ilce: 'Akkışla',
    say_kodu: 38111,
    vergi_dairesi: 'Akkışla',
  },
  {
    id: 600,
    il_id: 'Kayseri',
    ilce: 'Merkez- (5216)',
    say_kodu: 38113,
    vergi_dairesi: 'Hacılar',
  },
  {
    id: 601,
    il_id: 'Kayseri',
    ilce: '\u00d6zvatan',
    say_kodu: 38114,
    vergi_dairesi: '\u00d6zvatan',
  },
  {
    id: 602,
    il_id: 'Kırklareli',
    ilce: 'Merkez',
    say_kodu: 39260,
    vergi_dairesi: 'Kırklareli',
  },
  {
    id: 603,
    il_id: 'Kırklareli',
    ilce: 'Lüleburgaz',
    say_kodu: 39261,
    vergi_dairesi: 'Lüleburgaz',
  },
  {
    id: 604,
    il_id: 'Kırklareli',
    ilce: 'Babaeski',
    say_kodu: 39262,
    vergi_dairesi: 'Babaeski',
  },
  {
    id: 605,
    il_id: 'Kırklareli',
    ilce: 'Demirköy',
    say_kodu: 39102,
    vergi_dairesi: 'Demirköy',
  },
  {
    id: 606,
    il_id: 'Kırklareli',
    ilce: 'Kofçaz',
    say_kodu: 39103,
    vergi_dairesi: 'Kofçaz',
  },
  {
    id: 607,
    il_id: 'Kırklareli',
    ilce: 'Pehlivanköy',
    say_kodu: 39105,
    vergi_dairesi: 'Pehlivanköy',
  },
  {
    id: 608,
    il_id: 'Kırklareli',
    ilce: 'Pınarhisar',
    say_kodu: 39106,
    vergi_dairesi: 'Pınarhisar',
  },
  {
    id: 609,
    il_id: 'Kırklareli',
    ilce: 'Vize',
    say_kodu: 39107,
    vergi_dairesi: 'Vize',
  },
  {
    id: 610,
    il_id: 'Kırşehir',
    ilce: 'Merkez',
    say_kodu: 40260,
    vergi_dairesi: 'Kırşehir',
  },
  {
    id: 611,
    il_id: 'Kırşehir',
    ilce: 'Kaman',
    say_kodu: 40261,
    vergi_dairesi: 'Kaman',
  },
  {
    id: 612,
    il_id: 'Kırşehir',
    ilce: 'Çiçekdağı',
    say_kodu: 40101,
    vergi_dairesi: 'Çiçekdağı',
  },
  {
    id: 613,
    il_id: 'Kırşehir',
    ilce: 'Mucur',
    say_kodu: 40103,
    vergi_dairesi: 'Mucur',
  },
  {
    id: 614,
    il_id: 'Kırşehir',
    ilce: 'Akpınar',
    say_kodu: 40104,
    vergi_dairesi: 'Akpınar',
  },
  {
    id: 615,
    il_id: 'Kırşehir',
    ilce: 'Akçakent',
    say_kodu: 40105,
    vergi_dairesi: 'Akçakent',
  },
  {
    id: 616,
    il_id: 'Kırşehir',
    ilce: 'Boztepe',
    say_kodu: 40106,
    vergi_dairesi: 'Boztepe',
  },
  {
    id: 617,
    il_id: 'Kocaeli',
    ilce: 'Merkez',
    say_kodu: 41250,
    vergi_dairesi: 'Kocaeli İhtisas',
  },
  {
    id: 618,
    il_id: 'Kocaeli',
    ilce: 'Merkez',
    say_kodu: 41252,
    vergi_dairesi: 'Tepecik',
  },
  {
    id: 619,
    il_id: 'Kocaeli',
    ilce: 'Merkez',
    say_kodu: 41253,
    vergi_dairesi: 'Alemdar',
  },
  {
    id: 620,
    il_id: 'Kocaeli',
    ilce: 'Merkez Gebze',
    say_kodu: 41254,
    vergi_dairesi: 'Gebze İhtisas',
  },
  {
    id: 621,
    il_id: 'Kocaeli',
    ilce: 'Merkez',
    say_kodu: 41290,
    vergi_dairesi: 'Acısu',
  },
  {
    id: 622,
    il_id: 'Kocaeli',
    ilce: 'Merkez- (5216)',
    say_kodu: 41202,
    vergi_dairesi: 'Uluçınar',
  },
  {
    id: 623,
    il_id: 'Kocaeli',
    ilce: 'Merkez- (5216)',
    say_kodu: 41203,
    vergi_dairesi: 'İlyasbey',
  },
  {
    id: 624,
    il_id: 'Kocaeli',
    ilce: 'Merkez- (5216)',
    say_kodu: 41204,
    vergi_dairesi: 'Gölcük',
  },
  {
    id: 625,
    il_id: 'Kocaeli',
    ilce: 'Merkez- (5216)',
    say_kodu: 41205,
    vergi_dairesi: 'Karamürsel',
  },
  {
    id: 626,
    il_id: 'Kocaeli',
    ilce: 'Merkez- (5216)',
    say_kodu: 41206,
    vergi_dairesi: 'Körfez',
  },
  {
    id: 627,
    il_id: 'Kocaeli',
    ilce: 'Merkez',
    say_kodu: 41207,
    vergi_dairesi: 'Derince ',
  },
  {
    id: 628,
    il_id: 'Kocaeli',
    ilce: 'Merkez',
    say_kodu: 41103,
    vergi_dairesi: 'Kandıra',
  },
  {
    id: 629,
    il_id: 'Konya',
    ilce: 'Merkez',
    say_kodu: 42250,
    vergi_dairesi: 'Konya İhtisas',
  },
  {
    id: 630,
    il_id: 'Konya',
    ilce: 'Merkez',
    say_kodu: 42251,
    vergi_dairesi: 'Selçuk',
  },
  {
    id: 631,
    il_id: 'Konya',
    ilce: 'Merkez',
    say_kodu: 42252,
    vergi_dairesi: 'Mevlana',
  },
  {
    id: 632,
    il_id: 'Konya',
    ilce: 'Merkez',
    say_kodu: 42253,
    vergi_dairesi: 'Meram',
  },
  {
    id: 633,
    il_id: 'Konya',
    ilce: 'Merkez',
    say_kodu: 42254,
    vergi_dairesi: 'Alaaddin',
  },
  {
    id: 634,
    il_id: 'Konya',
    ilce: 'Akşehir',
    say_kodu: 42201,
    vergi_dairesi: 'Akşehir',
  },
  {
    id: 635,
    il_id: 'Konya',
    ilce: 'Ereğli',
    say_kodu: 42202,
    vergi_dairesi: 'Ereğli',
  },
  {
    id: 636,
    il_id: 'Konya',
    ilce: 'Beyşehir',
    say_kodu: 42204,
    vergi_dairesi: 'Beyşehir',
  },
  {
    id: 637,
    il_id: 'Konya',
    ilce: 'Cihanbeyli',
    say_kodu: 42205,
    vergi_dairesi: 'Cihanbeyli',
  },
  {
    id: 638,
    il_id: 'Konya',
    ilce: 'Çumra',
    say_kodu: 42206,
    vergi_dairesi: 'Çumra',
  },
  {
    id: 639,
    il_id: 'Konya',
    ilce: 'Seydişehir',
    say_kodu: 42207,
    vergi_dairesi: 'Seydişehir',
  },
  {
    id: 640,
    il_id: 'Konya',
    ilce: 'Ilgın',
    say_kodu: 42208,
    vergi_dairesi: 'Ilgın',
  },
  {
    id: 641,
    il_id: 'Konya',
    ilce: 'Kulu',
    say_kodu: 42209,
    vergi_dairesi: 'Kulu',
  },
  {
    id: 642,
    il_id: 'Konya',
    ilce: 'Karapınar',
    say_kodu: 42210,
    vergi_dairesi: 'Karapınar',
  },
  {
    id: 643,
    il_id: 'Konya',
    ilce: 'Bozkır',
    say_kodu: 42103,
    vergi_dairesi: 'Bozkır',
  },
  {
    id: 644,
    il_id: 'Konya',
    ilce: 'Doğanhisar',
    say_kodu: 42106,
    vergi_dairesi: 'Doğanhisar',
  },
  {
    id: 645,
    il_id: 'Konya',
    ilce: 'Hadim',
    say_kodu: 42109,
    vergi_dairesi: 'Hadim',
  },
  {
    id: 646,
    il_id: 'Konya',
    ilce: 'Kadınhanı',
    say_kodu: 42111,
    vergi_dairesi: 'Kadınhanı',
  },
  {
    id: 647,
    il_id: 'Konya',
    ilce: 'Sarayönü',
    say_kodu: 42115,
    vergi_dairesi: 'Sarayönü',
  },
  {
    id: 648,
    il_id: 'Konya',
    ilce: 'Yunak',
    say_kodu: 42117,
    vergi_dairesi: 'Yunak',
  },
  {
    id: 649,
    il_id: 'Konya',
    ilce: 'Akören',
    say_kodu: 42118,
    vergi_dairesi: 'Akören',
  },
  {
    id: 650,
    il_id: 'Konya',
    ilce: 'Altınekin',
    say_kodu: 42119,
    vergi_dairesi: 'Altınekin',
  },
  {
    id: 651,
    il_id: 'Konya',
    ilce: 'Derebucak',
    say_kodu: 42121,
    vergi_dairesi: 'Derebucak',
  },
  {
    id: 652,
    il_id: 'Konya',
    ilce: 'Hüyük',
    say_kodu: 42122,
    vergi_dairesi: 'Hüyük',
  },
  {
    id: 653,
    il_id: 'Konya',
    ilce: 'Taşkent',
    say_kodu: 42123,
    vergi_dairesi: 'Taşkent',
  },
  {
    id: 654,
    il_id: 'Konya',
    ilce: 'Emirgazi',
    say_kodu: 42127,
    vergi_dairesi: 'Emirgazi',
  },
  {
    id: 655,
    il_id: 'Konya',
    ilce: 'Güneysınır',
    say_kodu: 42128,
    vergi_dairesi: 'Güneysınır',
  },
  {
    id: 656,
    il_id: 'Konya',
    ilce: 'Halkapınar',
    say_kodu: 42129,
    vergi_dairesi: 'Halkapınar',
  },
  {
    id: 657,
    il_id: 'Konya',
    ilce: 'Tuzlukçu',
    say_kodu: 42130,
    vergi_dairesi: 'Tuzlukçu',
  },
  {
    id: 658,
    il_id: 'Konya',
    ilce: 'Ahırlı',
    say_kodu: 42124,
    vergi_dairesi: 'Ahırlı',
  },
  {
    id: 659,
    il_id: 'Konya',
    ilce: 'Çeltik',
    say_kodu: 42125,
    vergi_dairesi: 'Çeltik',
  },
  {
    id: 660,
    il_id: 'Konya',
    ilce: 'Derbent',
    say_kodu: 42126,
    vergi_dairesi: 'Derbent',
  },
  {
    id: 661,
    il_id: 'Konya',
    ilce: 'Yalıhüyük',
    say_kodu: 42131,
    vergi_dairesi: 'Yalıhüyük',
  },
  {
    id: 662,
    il_id: 'Kütahya',
    ilce: 'Merkez',
    say_kodu: 43201,
    vergi_dairesi: '30 Ağustos',
  },
  {
    id: 663,
    il_id: 'Kütahya',
    ilce: 'Merkez',
    say_kodu: 43280,
    vergi_dairesi: 'Çinili',
  },
  {
    id: 664,
    il_id: 'Kütahya',
    ilce: 'Gediz',
    say_kodu: 43260,
    vergi_dairesi: 'Gediz',
  },
  {
    id: 665,
    il_id: 'Kütahya',
    ilce: 'Simav',
    say_kodu: 43261,
    vergi_dairesi: 'Simav',
  },
  {
    id: 666,
    il_id: 'Kütahya',
    ilce: 'Tavşanlı',
    say_kodu: 43262,
    vergi_dairesi: 'Tavşanlı',
  },
  {
    id: 667,
    il_id: 'Kütahya',
    ilce: 'Emet',
    say_kodu: 43263,
    vergi_dairesi: 'Emet',
  },
  {
    id: 668,
    il_id: 'Kütahya',
    ilce: 'Altıntaş',
    say_kodu: 43101,
    vergi_dairesi: 'Altıntaş',
  },
  {
    id: 669,
    il_id: 'Kütahya',
    ilce: 'Domaniç',
    say_kodu: 43102,
    vergi_dairesi: 'Domaniç',
  },
  {
    id: 670,
    il_id: 'Kütahya',
    ilce: 'Aslanapa',
    say_kodu: 43107,
    vergi_dairesi: 'Aslanapa',
  },
  {
    id: 671,
    il_id: 'Kütahya',
    ilce: 'Dumlupınar',
    say_kodu: 43108,
    vergi_dairesi: 'Dumlupınar',
  },
  {
    id: 672,
    il_id: 'Kütahya',
    ilce: 'Hisarcık',
    say_kodu: 43109,
    vergi_dairesi: 'Hisarcık',
  },
  {
    id: 673,
    il_id: 'Kütahya',
    ilce: 'Şaphane',
    say_kodu: 43110,
    vergi_dairesi: 'Şaphane',
  },
  {
    id: 674,
    il_id: 'Kütahya',
    ilce: 'Çavdarhisar',
    say_kodu: 43111,
    vergi_dairesi: 'Çavdarhisar',
  },
  {
    id: 675,
    il_id: 'Kütahya',
    ilce: 'Pazarlar',
    say_kodu: 43112,
    vergi_dairesi: 'Pazarlar',
  },
  {
    id: 676,
    il_id: 'Malatya',
    ilce: 'Merkez',
    say_kodu: 44251,
    vergi_dairesi: 'Fırat',
  },
  {
    id: 677,
    il_id: 'Malatya',
    ilce: 'Merkez',
    say_kodu: 44252,
    vergi_dairesi: 'Beydağı',
  },
  {
    id: 678,
    il_id: 'Malatya',
    ilce: 'Akçadağ',
    say_kodu: 44101,
    vergi_dairesi: 'Akçadağ',
  },
  {
    id: 679,
    il_id: 'Malatya',
    ilce: 'Arapgir',
    say_kodu: 44102,
    vergi_dairesi: 'Arapgir',
  },
  {
    id: 680,
    il_id: 'Malatya',
    ilce: 'Arguvan',
    say_kodu: 44103,
    vergi_dairesi: 'Arguvan',
  },
  {
    id: 681,
    il_id: 'Malatya',
    ilce: 'Darende',
    say_kodu: 44104,
    vergi_dairesi: 'Darende',
  },
  {
    id: 682,
    il_id: 'Malatya',
    ilce: 'Doğanşehir',
    say_kodu: 44105,
    vergi_dairesi: 'Doğanşehir',
  },
  {
    id: 683,
    il_id: 'Malatya',
    ilce: 'Hekimhan',
    say_kodu: 44106,
    vergi_dairesi: 'Hekimhan',
  },
  {
    id: 684,
    il_id: 'Malatya',
    ilce: 'Pütürge',
    say_kodu: 44107,
    vergi_dairesi: 'Pütürge',
  },
  {
    id: 685,
    il_id: 'Malatya',
    ilce: 'Yeşilyurt',
    say_kodu: 44108,
    vergi_dairesi: 'Yeşilyurt',
  },
  {
    id: 686,
    il_id: 'Malatya',
    ilce: 'Battalgazi',
    say_kodu: 44109,
    vergi_dairesi: 'Battalgazi',
  },
  {
    id: 687,
    il_id: 'Malatya',
    ilce: 'Doğanyol',
    say_kodu: 44110,
    vergi_dairesi: 'Doğanyol',
  },
  {
    id: 688,
    il_id: 'Malatya',
    ilce: 'Kale',
    say_kodu: 44111,
    vergi_dairesi: 'Kale',
  },
  {
    id: 689,
    il_id: 'Malatya',
    ilce: 'Kuluncak',
    say_kodu: 44112,
    vergi_dairesi: 'Kuluncak',
  },
  {
    id: 690,
    il_id: 'Malatya',
    ilce: 'Yazıhan',
    say_kodu: 44113,
    vergi_dairesi: 'Yazıhan ',
  },
  {
    id: 691,
    il_id: 'Manisa',
    ilce: 'Merkez',
    say_kodu: 45250,
    vergi_dairesi: 'Manisa İhtisas',
  },
  {
    id: 692,
    il_id: 'Manisa',
    ilce: 'Merkez',
    say_kodu: 45251,
    vergi_dairesi: 'Alaybey',
  },
  {
    id: 693,
    il_id: 'Manisa',
    ilce: 'Merkez',
    say_kodu: 45252,
    vergi_dairesi: 'Mesir',
  },
  {
    id: 694,
    il_id: 'Manisa',
    ilce: 'Akhisar',
    say_kodu: 45201,
    vergi_dairesi: 'Akhisar',
  },
  {
    id: 695,
    il_id: 'Manisa',
    ilce: 'Alaşehir',
    say_kodu: 45202,
    vergi_dairesi: 'Alaşehir',
  },
  {
    id: 696,
    il_id: 'Manisa',
    ilce: 'Demirci',
    say_kodu: 45203,
    vergi_dairesi: 'Demirci',
  },
  {
    id: 697,
    il_id: 'Manisa',
    ilce: 'Kırkağaç',
    say_kodu: 45204,
    vergi_dairesi: 'Kırkağaç',
  },
  {
    id: 698,
    il_id: 'Manisa',
    ilce: 'Salihli',
    say_kodu: 45205,
    vergi_dairesi: 'Salihli Adil Oral',
  },
  {
    id: 699,
    il_id: 'Manisa',
    ilce: 'Sarıgöl',
    say_kodu: 45206,
    vergi_dairesi: 'Sarıgöl',
  },
  {
    id: 700,
    il_id: 'Manisa',
    ilce: 'Saruhanlı',
    say_kodu: 45207,
    vergi_dairesi: 'Saruhanlı',
  },
  {
    id: 701,
    il_id: 'Manisa',
    ilce: 'Soma',
    say_kodu: 45208,
    vergi_dairesi: 'Soma',
  },
  {
    id: 702,
    il_id: 'Manisa',
    ilce: 'Turgutlu',
    say_kodu: 45209,
    vergi_dairesi: 'Turgutlu',
  },
  {
    id: 703,
    il_id: 'Manisa',
    ilce: 'Gördes',
    say_kodu: 45210,
    vergi_dairesi: 'Gördes',
  },
  {
    id: 704,
    il_id: 'Manisa',
    ilce: 'Kula',
    say_kodu: 45211,
    vergi_dairesi: 'Kula',
  },
  {
    id: 705,
    il_id: 'Manisa',
    ilce: 'Selendi',
    say_kodu: 45110,
    vergi_dairesi: 'Selendi',
  },
  {
    id: 706,
    il_id: 'Manisa',
    ilce: 'Ahmetli',
    say_kodu: 45113,
    vergi_dairesi: 'Ahmetli',
  },
  {
    id: 707,
    il_id: 'Manisa',
    ilce: 'Gölmarmara',
    say_kodu: 45114,
    vergi_dairesi: 'Gölmarmara',
  },
  {
    id: 708,
    il_id: 'Manisa',
    ilce: 'Köprübaşı',
    say_kodu: 45115,
    vergi_dairesi: 'Köprübaşı',
  },
  {
    id: 709,
    il_id: 'Kahramanmaraş',
    ilce: 'Merkez',
    say_kodu: 46201,
    vergi_dairesi: 'Aslanbey',
  },
  {
    id: 710,
    il_id: 'Kahramanmaraş',
    ilce: 'Merkez',
    say_kodu: 46280,
    vergi_dairesi: 'Aksu',
  },
  {
    id: 711,
    il_id: 'Kahramanmaraş',
    ilce: 'Elbistan',
    say_kodu: 46260,
    vergi_dairesi: 'Elbistan',
  },
  {
    id: 712,
    il_id: 'Kahramanmaraş',
    ilce: 'Afşin',
    say_kodu: 46261,
    vergi_dairesi: 'Afşin',
  },
  {
    id: 713,
    il_id: 'Kahramanmaraş',
    ilce: 'Pazarcık',
    say_kodu: 46262,
    vergi_dairesi: 'Pazarcık',
  },
  {
    id: 714,
    il_id: 'Kahramanmaraş',
    ilce: 'Andırın',
    say_kodu: 46102,
    vergi_dairesi: 'Andırın',
  },
  {
    id: 715,
    il_id: 'Kahramanmaraş',
    ilce: 'Göksun',
    say_kodu: 46104,
    vergi_dairesi: 'Göksun',
  },
  {
    id: 716,
    il_id: 'Kahramanmaraş',
    ilce: 'Türkoğlu',
    say_kodu: 46106,
    vergi_dairesi: 'Türkoğlu',
  },
  {
    id: 717,
    il_id: 'Kahramanmaraş',
    ilce: 'Çağlayancerit',
    say_kodu: 46107,
    vergi_dairesi: 'Çağlayancerit',
  },
  {
    id: 718,
    il_id: 'Kahramanmaraş',
    ilce: 'Ekinözü',
    say_kodu: 46108,
    vergi_dairesi: 'Ekinözü',
  },
  {
    id: 719,
    il_id: 'Kahramanmaraş',
    ilce: 'Nurhak',
    say_kodu: 46109,
    vergi_dairesi: 'Nurhak',
  },
  {
    id: 720,
    il_id: 'Mardin',
    ilce: 'Merkez',
    say_kodu: 47260,
    vergi_dairesi: 'Mardin',
  },
  {
    id: 721,
    il_id: 'Mardin',
    ilce: 'Kızıltepe',
    say_kodu: 47261,
    vergi_dairesi: 'Kızıltepe',
  },
  {
    id: 722,
    il_id: 'Mardin',
    ilce: 'Nusaybin',
    say_kodu: 47262,
    vergi_dairesi: 'Nusaybin',
  },
  {
    id: 723,
    il_id: 'Mardin',
    ilce: 'Derik',
    say_kodu: 47102,
    vergi_dairesi: 'Derik',
  },
  {
    id: 724,
    il_id: 'Mardin',
    ilce: 'Mazıdağı',
    say_kodu: 47106,
    vergi_dairesi: 'Mazıdağı',
  },
  {
    id: 725,
    il_id: 'Mardin',
    ilce: 'Midyat',
    say_kodu: 47107,
    vergi_dairesi: 'Midyat',
  },
  {
    id: 726,
    il_id: 'Mardin',
    ilce: '\u00d6merli',
    say_kodu: 47109,
    vergi_dairesi: '\u00d6merli',
  },
  {
    id: 727,
    il_id: 'Mardin',
    ilce: 'Savur',
    say_kodu: 47110,
    vergi_dairesi: 'Savur',
  },
  {
    id: 728,
    il_id: 'Mardin',
    ilce: 'Dargeçit',
    say_kodu: 47112,
    vergi_dairesi: 'Dargeçit',
  },
  {
    id: 729,
    il_id: 'Mardin',
    ilce: 'Yeşilli',
    say_kodu: 47113,
    vergi_dairesi: 'Yeşilli',
  },
  {
    id: 730,
    il_id: 'Muğla',
    ilce: 'Merkez',
    say_kodu: 48260,
    vergi_dairesi: 'Muğla',
  },
  {
    id: 731,
    il_id: 'Muğla',
    ilce: 'Bodrum',
    say_kodu: 48261,
    vergi_dairesi: 'Bodrum',
  },
  {
    id: 732,
    il_id: 'Muğla',
    ilce: 'Fethiye',
    say_kodu: 48262,
    vergi_dairesi: 'Fethiye',
  },
  {
    id: 733,
    il_id: 'Muğla',
    ilce: 'Köyceğiz',
    say_kodu: 48263,
    vergi_dairesi: 'Köyceğiz',
  },
  {
    id: 734,
    il_id: 'Muğla',
    ilce: 'Milas',
    say_kodu: 48264,
    vergi_dairesi: 'Milas',
  },
  {
    id: 735,
    il_id: 'Muğla',
    ilce: 'Marmaris',
    say_kodu: 48265,
    vergi_dairesi: 'Marmaris',
  },
  {
    id: 736,
    il_id: 'Muğla',
    ilce: 'Yatağan',
    say_kodu: 48266,
    vergi_dairesi: 'Yatağan',
  },
  {
    id: 737,
    il_id: 'Muğla',
    ilce: 'Datça',
    say_kodu: 48102,
    vergi_dairesi: 'Datça',
  },
  {
    id: 738,
    il_id: 'Muğla',
    ilce: 'Ula',
    say_kodu: 48108,
    vergi_dairesi: 'Ula',
  },
  {
    id: 739,
    il_id: 'Muğla',
    ilce: 'Dalaman',
    say_kodu: 48109,
    vergi_dairesi: 'Dalaman',
  },
  {
    id: 740,
    il_id: 'Muğla',
    ilce: 'Ortaca',
    say_kodu: 48110,
    vergi_dairesi: 'Ortaca',
  },
  {
    id: 741,
    il_id: 'Muğla',
    ilce: 'Kavaklıdere',
    say_kodu: 48111,
    vergi_dairesi: 'Kavaklıdere',
  },
  {
    id: 742,
    il_id: 'Muğla',
    ilce: 'Seydiemer',
    say_kodu: 48113,
    vergi_dairesi: 'Seydikemer',
  },
  {
    id: 743,
    il_id: 'Muş',
    ilce: 'Merkez',
    say_kodu: 49260,
    vergi_dairesi: 'Muş',
  },
  {
    id: 744,
    il_id: 'Muş',
    ilce: 'Bulanık',
    say_kodu: 49101,
    vergi_dairesi: 'Bulanık',
  },
  {
    id: 745,
    il_id: 'Muş',
    ilce: 'Malazgirt',
    say_kodu: 49102,
    vergi_dairesi: 'Malazgirt',
  },
  {
    id: 746,
    il_id: 'Muş',
    ilce: 'Varto',
    say_kodu: 49103,
    vergi_dairesi: 'Varto',
  },
  {
    id: 747,
    il_id: 'Muş',
    ilce: 'Hasköy',
    say_kodu: 49104,
    vergi_dairesi: 'Hasköy',
  },
  {
    id: 748,
    il_id: 'Muş',
    ilce: 'Korkut',
    say_kodu: 49105,
    vergi_dairesi: 'Korkut',
  },
  {
    id: 749,
    il_id: 'Nevşehir',
    ilce: 'Merkez',
    say_kodu: 50260,
    vergi_dairesi: 'Nevşehir',
  },
  {
    id: 750,
    il_id: 'Nevşehir',
    ilce: 'Avanos',
    say_kodu: 50101,
    vergi_dairesi: 'Avanos',
  },
  {
    id: 751,
    il_id: 'Nevşehir',
    ilce: 'Derinkuyu',
    say_kodu: 50102,
    vergi_dairesi: 'Derinkuyu',
  },
  {
    id: 752,
    il_id: 'Nevşehir',
    ilce: 'Gülşehir',
    say_kodu: 50103,
    vergi_dairesi: 'Gülşehir',
  },
  {
    id: 753,
    il_id: 'Nevşehir',
    ilce: 'Hacıbektaş',
    say_kodu: 50104,
    vergi_dairesi: 'Hacıbektaş ',
  },
  {
    id: 754,
    il_id: 'Nevşehir',
    ilce: 'Kozaklı',
    say_kodu: 50105,
    vergi_dairesi: 'Kozaklı',
  },
  {
    id: 755,
    il_id: 'Nevşehir',
    ilce: 'Ürgüp',
    say_kodu: 50106,
    vergi_dairesi: 'Ürgüp',
  },
  {
    id: 756,
    il_id: 'Nevşehir',
    ilce: 'Acıgöl',
    say_kodu: 50107,
    vergi_dairesi: 'Acıgöl',
  },
  {
    id: 757,
    il_id: 'Niğde',
    ilce: 'Merkez',
    say_kodu: 51260,
    vergi_dairesi: 'Niğde',
  },
  {
    id: 758,
    il_id: 'Niğde',
    ilce: 'Bor',
    say_kodu: 51262,
    vergi_dairesi: 'Bor',
  },
  {
    id: 759,
    il_id: 'Niğde',
    ilce: 'Çamardı',
    say_kodu: 51103,
    vergi_dairesi: 'Çamardı',
  },
  {
    id: 760,
    il_id: 'Niğde',
    ilce: 'Ulukışla',
    say_kodu: 51105,
    vergi_dairesi: 'Ulukışla',
  },
  {
    id: 761,
    il_id: 'Niğde',
    ilce: 'Altunhisar',
    say_kodu: 51106,
    vergi_dairesi: 'Altunhisar',
  },
  {
    id: 762,
    il_id: 'Niğde',
    ilce: 'Çiftlik',
    say_kodu: 51107,
    vergi_dairesi: 'Çiftlik',
  },
  {
    id: 763,
    il_id: 'Ordu',
    ilce: 'Merkez',
    say_kodu: 52201,
    vergi_dairesi: 'Boztepe',
  },
  {
    id: 764,
    il_id: 'Ordu',
    ilce: 'Merkez',
    say_kodu: 52260,
    vergi_dairesi: 'Köprübaşı',
  },
  {
    id: 765,
    il_id: 'Ordu',
    ilce: 'Fatsa',
    say_kodu: 52261,
    vergi_dairesi: 'Fatsa',
  },
  {
    id: 766,
    il_id: 'Ordu',
    ilce: 'Ünye',
    say_kodu: 52262,
    vergi_dairesi: 'Ünye',
  },
  {
    id: 767,
    il_id: 'Ordu',
    ilce: 'Akkuş',
    say_kodu: 52101,
    vergi_dairesi: 'Akkuş',
  },
  {
    id: 768,
    il_id: 'Ordu',
    ilce: 'Aybastı',
    say_kodu: 52102,
    vergi_dairesi: 'Aybastı',
  },
  {
    id: 769,
    il_id: 'Ordu',
    ilce: 'Gölköy',
    say_kodu: 52104,
    vergi_dairesi: 'Gölköy',
  },
  {
    id: 770,
    il_id: 'Ordu',
    ilce: 'Korgan',
    say_kodu: 52105,
    vergi_dairesi: 'Korgan',
  },
  {
    id: 771,
    il_id: 'Ordu',
    ilce: 'Kumru',
    say_kodu: 52106,
    vergi_dairesi: 'Kumru',
  },
  {
    id: 772,
    il_id: 'Ordu',
    ilce: 'Mesudiye',
    say_kodu: 52107,
    vergi_dairesi: 'Mesudiye',
  },
  {
    id: 773,
    il_id: 'Ordu',
    ilce: 'Perşembe',
    say_kodu: 52108,
    vergi_dairesi: 'Perşembe',
  },
  {
    id: 774,
    il_id: 'Ordu',
    ilce: 'Ulubey',
    say_kodu: 52109,
    vergi_dairesi: 'Ulubey',
  },
  {
    id: 775,
    il_id: 'Ordu',
    ilce: 'Gülyalı',
    say_kodu: 52111,
    vergi_dairesi: 'Gülyalı',
  },
  {
    id: 776,
    il_id: 'Ordu',
    ilce: 'Gürgentepe',
    say_kodu: 52112,
    vergi_dairesi: 'Gürgentepe',
  },
  {
    id: 777,
    il_id: 'Ordu',
    ilce: 'Çamaş',
    say_kodu: 52113,
    vergi_dairesi: 'Çamaş',
  },
  {
    id: 778,
    il_id: 'Ordu',
    ilce: 'Çatalpınar',
    say_kodu: 52114,
    vergi_dairesi: 'Çatalpınar',
  },
  {
    id: 779,
    il_id: 'Ordu',
    ilce: 'Çaybaşı',
    say_kodu: 52115,
    vergi_dairesi: 'Çaybaşı',
  },
  {
    id: 780,
    il_id: 'Ordu',
    ilce: 'İkizce',
    say_kodu: 52116,
    vergi_dairesi: 'İkizce',
  },
  {
    id: 781,
    il_id: 'Ordu',
    ilce: 'Kabadüz',
    say_kodu: 52117,
    vergi_dairesi: 'Kabadüz',
  },
  {
    id: 782,
    il_id: 'Ordu',
    ilce: 'Kabataş',
    say_kodu: 52118,
    vergi_dairesi: 'Kabataş',
  },
  {
    id: 783,
    il_id: 'Rize',
    ilce: 'Merkez',
    say_kodu: 53201,
    vergi_dairesi: 'Kaçkar',
  },
  {
    id: 784,
    il_id: 'Rize',
    ilce: 'Merkez',
    say_kodu: 53260,
    vergi_dairesi: 'Yeşilçay',
  },
  {
    id: 785,
    il_id: 'Rize',
    ilce: 'Çayeli',
    say_kodu: 53261,
    vergi_dairesi: 'Çayeli',
  },
  {
    id: 786,
    il_id: 'Rize',
    ilce: 'Pazar',
    say_kodu: 53262,
    vergi_dairesi: 'Pazar',
  },
  {
    id: 787,
    il_id: 'Rize',
    ilce: 'Ardeşen',
    say_kodu: 53263,
    vergi_dairesi: 'Ardeşen',
  },
  {
    id: 788,
    il_id: 'Rize',
    ilce: 'Çamlıhemşin',
    say_kodu: 53102,
    vergi_dairesi: 'Çamlıhemşin',
  },
  {
    id: 789,
    il_id: 'Rize',
    ilce: 'Fındıklı',
    say_kodu: 53104,
    vergi_dairesi: 'Fındıklı',
  },
  {
    id: 790,
    il_id: 'Rize',
    ilce: 'İkizdere',
    say_kodu: 53105,
    vergi_dairesi: 'İkizdere',
  },
  {
    id: 791,
    il_id: 'Rize',
    ilce: 'Kalkandere',
    say_kodu: 53106,
    vergi_dairesi: 'Kalkandere',
  },
  {
    id: 792,
    il_id: 'Rize',
    ilce: 'Güneysu',
    say_kodu: 53108,
    vergi_dairesi: 'Güneysu',
  },
  {
    id: 793,
    il_id: 'Rize',
    ilce: 'Derepazarı',
    say_kodu: 53109,
    vergi_dairesi: 'Derepazarı',
  },
  {
    id: 794,
    il_id: 'Rize',
    ilce: 'Hemşin',
    say_kodu: 53110,
    vergi_dairesi: 'Hemşin',
  },
  {
    id: 795,
    il_id: 'Rize',
    ilce: 'İyidere',
    say_kodu: 53111,
    vergi_dairesi: 'İyidere',
  },
  {
    id: 796,
    il_id: 'Sakarya',
    ilce: 'Merkez',
    say_kodu: 54251,
    vergi_dairesi: 'Gümrükönü',
  },
  {
    id: 797,
    il_id: 'Sakarya',
    ilce: 'Merkez',
    say_kodu: 54252,
    vergi_dairesi: 'Ali Fuat Cebesoy',
  },
  {
    id: 798,
    il_id: 'Sakarya',
    ilce: 'Merkez',
    say_kodu: 54253,
    vergi_dairesi: 'Sapanca',
  },
  {
    id: 799,
    il_id: 'Sakarya',
    ilce: 'Merkez',
    say_kodu: 54201,
    vergi_dairesi: 'Akyazı',
  },
  {
    id: 800,
    il_id: 'Sakarya',
    ilce: 'Geyve',
    say_kodu: 54202,
    vergi_dairesi: 'Geyve',
  },
  {
    id: 801,
    il_id: 'Sakarya',
    ilce: 'Merkez',
    say_kodu: 54203,
    vergi_dairesi: 'Hendek',
  },
  {
    id: 802,
    il_id: 'Sakarya',
    ilce: 'Karasu',
    say_kodu: 54204,
    vergi_dairesi: 'Karasu',
  },
  {
    id: 803,
    il_id: 'Sakarya',
    ilce: 'Kaynarca',
    say_kodu: 54105,
    vergi_dairesi: 'Kaynarca',
  },
  {
    id: 804,
    il_id: 'Sakarya',
    ilce: 'Kocaali',
    say_kodu: 54107,
    vergi_dairesi: 'Kocaali',
  },
  {
    id: 805,
    il_id: 'Sakarya',
    ilce: 'Pamukova',
    say_kodu: 54108,
    vergi_dairesi: 'Pamukova',
  },
  {
    id: 806,
    il_id: 'Sakarya',
    ilce: 'Taraklı',
    say_kodu: 54109,
    vergi_dairesi: 'Taraklı',
  },
  {
    id: 807,
    il_id: 'Sakarya',
    ilce: 'Merkez',
    say_kodu: 54111,
    vergi_dairesi: 'Karapürçek',
  },
  {
    id: 808,
    il_id: 'Samsun',
    ilce: 'Merkez',
    say_kodu: 55251,
    vergi_dairesi: '19 Mayıs',
  },
  {
    id: 809,
    il_id: 'Samsun',
    ilce: 'Merkez',
    say_kodu: 55252,
    vergi_dairesi: 'Gaziler',
  },
  {
    id: 810,
    il_id: 'Samsun',
    ilce: 'Merkez',
    say_kodu: 55290,
    vergi_dairesi: 'Zafer',
  },
  {
    id: 811,
    il_id: 'Samsun',
    ilce: 'Bafra',
    say_kodu: 55202,
    vergi_dairesi: 'Bafra',
  },
  {
    id: 812,
    il_id: 'Samsun',
    ilce: 'Çarşamba',
    say_kodu: 55203,
    vergi_dairesi: 'Çarşamba',
  },
  {
    id: 813,
    il_id: 'Samsun',
    ilce: 'Terme',
    say_kodu: 55204,
    vergi_dairesi: 'Terme',
  },
  {
    id: 814,
    il_id: 'Samsun',
    ilce: 'Havza',
    say_kodu: 55205,
    vergi_dairesi: 'Havza',
  },
  {
    id: 815,
    il_id: 'Samsun',
    ilce: 'Alaçam',
    say_kodu: 55101,
    vergi_dairesi: 'Alaçam',
  },
  {
    id: 816,
    il_id: 'Samsun',
    ilce: 'Kavak',
    say_kodu: 55105,
    vergi_dairesi: 'Kavak ',
  },
  {
    id: 817,
    il_id: 'Samsun',
    ilce: 'Ladik',
    say_kodu: 55106,
    vergi_dairesi: 'Ladik',
  },
  {
    id: 818,
    il_id: 'Samsun',
    ilce: 'Vezirköprü',
    say_kodu: 55108,
    vergi_dairesi: 'Vezirköprü',
  },
  {
    id: 819,
    il_id: 'Samsun',
    ilce: 'Asarcık',
    say_kodu: 55109,
    vergi_dairesi: 'Asarcık',
  },
  {
    id: 820,
    il_id: 'Samsun',
    ilce: 'Ondokuz Mayıs',
    say_kodu: 55110,
    vergi_dairesi: 'Ondokuz Mayıs',
  },
  {
    id: 821,
    il_id: 'Samsun',
    ilce: 'Salıpazarı',
    say_kodu: 55111,
    vergi_dairesi: 'Salıpazarı',
  },
  {
    id: 822,
    il_id: 'Samsun',
    ilce: 'Merkez',
    say_kodu: 55112,
    vergi_dairesi: 'Tekkeköy',
  },
  {
    id: 823,
    il_id: 'Samsun',
    ilce: 'Ayvacık',
    say_kodu: 55113,
    vergi_dairesi: 'Ayvacık',
  },
  {
    id: 824,
    il_id: 'Samsun',
    ilce: 'Yakakent',
    say_kodu: 55114,
    vergi_dairesi: 'Yakakent',
  },
  {
    id: 825,
    il_id: 'Siirt',
    ilce: 'Merkez',
    say_kodu: 56260,
    vergi_dairesi: 'Siirt',
  },
  {
    id: 826,
    il_id: 'Siirt',
    ilce: 'Baykan',
    say_kodu: 56102,
    vergi_dairesi: 'Baykan',
  },
  {
    id: 827,
    il_id: 'Siirt',
    ilce: 'Eruh',
    say_kodu: 56104,
    vergi_dairesi: 'Eruh',
  },
  {
    id: 828,
    il_id: 'Siirt',
    ilce: 'Kurtalan',
    say_kodu: 56106,
    vergi_dairesi: 'Kurtalan',
  },
  {
    id: 829,
    il_id: 'Siirt',
    ilce: 'Pervari',
    say_kodu: 56107,
    vergi_dairesi: 'Pervari',
  },
  {
    id: 830,
    il_id: 'Siirt',
    ilce: 'Şirvan',
    say_kodu: 56110,
    vergi_dairesi: 'Şirvan',
  },
  {
    id: 831,
    il_id: 'Sinop',
    ilce: 'Merkez',
    say_kodu: 57260,
    vergi_dairesi: 'Sinop',
  },
  {
    id: 832,
    il_id: 'Sinop',
    ilce: 'Boyabat',
    say_kodu: 57261,
    vergi_dairesi: 'Boyabat',
  },
  {
    id: 833,
    il_id: 'Sinop',
    ilce: 'Ayancık',
    say_kodu: 57101,
    vergi_dairesi: 'Ayancık',
  },
  {
    id: 834,
    il_id: 'Sinop',
    ilce: 'Durağan',
    say_kodu: 57103,
    vergi_dairesi: 'Durağan',
  },
  {
    id: 835,
    il_id: 'Sinop',
    ilce: 'Erfelek',
    say_kodu: 57104,
    vergi_dairesi: 'Erfelek',
  },
  {
    id: 836,
    il_id: 'Sinop',
    ilce: 'Gerze',
    say_kodu: 57105,
    vergi_dairesi: 'Gerze',
  },
  {
    id: 837,
    il_id: 'Sinop',
    ilce: 'Türkeli',
    say_kodu: 57106,
    vergi_dairesi: 'Türkeli',
  },
  {
    id: 838,
    il_id: 'Sinop',
    ilce: 'Dikmen',
    say_kodu: 57107,
    vergi_dairesi: 'Dikmen',
  },
  {
    id: 839,
    il_id: 'Sinop',
    ilce: 'Saraydüzü',
    say_kodu: 57108,
    vergi_dairesi: 'Saraydüzü',
  },
  {
    id: 840,
    il_id: 'Sivas',
    ilce: 'Merkez',
    say_kodu: 58201,
    vergi_dairesi: 'Kale',
  },
  {
    id: 841,
    il_id: 'Sivas',
    ilce: 'Merkez',
    say_kodu: 58280,
    vergi_dairesi: 'Site',
  },
  {
    id: 842,
    il_id: 'Sivas',
    ilce: 'Şarkışla',
    say_kodu: 58260,
    vergi_dairesi: 'Şarkışla',
  },
  {
    id: 843,
    il_id: 'Sivas',
    ilce: 'Divriği',
    say_kodu: 58101,
    vergi_dairesi: 'Divriği',
  },
  {
    id: 844,
    il_id: 'Sivas',
    ilce: 'Gemerek',
    say_kodu: 58102,
    vergi_dairesi: 'Gemerek',
  },
  {
    id: 845,
    il_id: 'Sivas',
    ilce: 'Gürün',
    say_kodu: 58103,
    vergi_dairesi: 'Gürün',
  },
  {
    id: 846,
    il_id: 'Sivas',
    ilce: 'Hafik',
    say_kodu: 58104,
    vergi_dairesi: 'Hafik',
  },
  {
    id: 847,
    il_id: 'Sivas',
    ilce: 'İmranlı',
    say_kodu: 58105,
    vergi_dairesi: 'İmranlı',
  },
  {
    id: 848,
    il_id: 'Sivas',
    ilce: 'Kangal',
    say_kodu: 58106,
    vergi_dairesi: 'Kangal',
  },
  {
    id: 849,
    il_id: 'Sivas',
    ilce: 'Koyulhisar',
    say_kodu: 58107,
    vergi_dairesi: 'Koyulhisar',
  },
  {
    id: 850,
    il_id: 'Sivas',
    ilce: 'Suşehri',
    say_kodu: 58109,
    vergi_dairesi: 'Suşehri',
  },
  {
    id: 851,
    il_id: 'Sivas',
    ilce: 'Yıldızeli',
    say_kodu: 58110,
    vergi_dairesi: 'Yıldızeli',
  },
  {
    id: 852,
    il_id: 'Sivas',
    ilce: 'Zara',
    say_kodu: 58111,
    vergi_dairesi: 'Zara',
  },
  {
    id: 853,
    il_id: 'Sivas',
    ilce: 'Akıncılar',
    say_kodu: 58112,
    vergi_dairesi: 'Akıncılar',
  },
  {
    id: 854,
    il_id: 'Sivas',
    ilce: 'Altınyayla',
    say_kodu: 58113,
    vergi_dairesi: 'Altınyayla',
  },
  {
    id: 855,
    il_id: 'Sivas',
    ilce: 'Doğanşar',
    say_kodu: 58114,
    vergi_dairesi: 'Doğanşar',
  },
  {
    id: 856,
    il_id: 'Sivas',
    ilce: 'Gölova',
    say_kodu: 58115,
    vergi_dairesi: 'Gölova',
  },
  {
    id: 857,
    il_id: 'Sivas',
    ilce: 'Ulaş',
    say_kodu: 58116,
    vergi_dairesi: 'Ulaş',
  },
  {
    id: 858,
    il_id: 'Tekirdağ',
    ilce: 'Merkez',
    say_kodu: 59201,
    vergi_dairesi: 'Süleymanpaşa',
  },
  {
    id: 859,
    il_id: 'Tekirdağ',
    ilce: 'Merkez',
    say_kodu: 59260,
    vergi_dairesi: 'Namık Kemal',
  },
  {
    id: 860,
    il_id: 'Tekirdağ',
    ilce: 'Çerkezköy',
    say_kodu: 59261,
    vergi_dairesi: 'Çerkezköy',
  },
  {
    id: 861,
    il_id: 'Tekirdağ',
    ilce: 'Çorlu',
    say_kodu: 59262,
    vergi_dairesi: 'Çorlu',
  },
  {
    id: 862,
    il_id: 'Tekirdağ',
    ilce: 'Hayrabolu',
    say_kodu: 59263,
    vergi_dairesi: 'Hayrabolu',
  },
  {
    id: 863,
    il_id: 'Tekirdağ',
    ilce: 'Malkara',
    say_kodu: 59264,
    vergi_dairesi: 'Malkara',
  },
  {
    id: 864,
    il_id: 'Tekirdağ',
    ilce: 'Muratlı',
    say_kodu: 59265,
    vergi_dairesi: 'Muratlı',
  },
  {
    id: 865,
    il_id: 'Tekirdağ',
    ilce: 'Saray',
    say_kodu: 59106,
    vergi_dairesi: 'Saray',
  },
  {
    id: 866,
    il_id: 'Tekirdağ',
    ilce: 'Şarköy',
    say_kodu: 59107,
    vergi_dairesi: 'Şarköy',
  },
  {
    id: 867,
    il_id: 'Tekirdağ',
    ilce: 'Marmara Ereğlisi',
    say_kodu: 59108,
    vergi_dairesi: 'Marmara Ereğlisi',
  },
  {
    id: 868,
    il_id: 'Tokat',
    ilce: 'Merkez',
    say_kodu: 60260,
    vergi_dairesi: 'Tokat',
  },
  {
    id: 869,
    il_id: 'Tokat',
    ilce: 'Erbaa',
    say_kodu: 60261,
    vergi_dairesi: 'Erbaa',
  },
  {
    id: 870,
    il_id: 'Tokat',
    ilce: 'Niksar',
    say_kodu: 60262,
    vergi_dairesi: 'Niksar',
  },
  {
    id: 871,
    il_id: 'Tokat',
    ilce: 'Turhal',
    say_kodu: 60263,
    vergi_dairesi: 'Turhal',
  },
  {
    id: 872,
    il_id: 'Tokat',
    ilce: 'Zile',
    say_kodu: 60264,
    vergi_dairesi: 'Zile',
  },
  {
    id: 873,
    il_id: 'Tokat',
    ilce: 'Almus',
    say_kodu: 60101,
    vergi_dairesi: 'Almus',
  },
  {
    id: 874,
    il_id: 'Tokat',
    ilce: 'Artova',
    say_kodu: 60102,
    vergi_dairesi: 'Artova',
  },
  {
    id: 875,
    il_id: 'Tokat',
    ilce: 'Reşadiye',
    say_kodu: 60105,
    vergi_dairesi: 'Reşadiye',
  },
  {
    id: 876,
    il_id: 'Tokat',
    ilce: 'Pazar',
    say_kodu: 60108,
    vergi_dairesi: 'Pazar',
  },
  {
    id: 877,
    il_id: 'Tokat',
    ilce: 'Yeşilyurt',
    say_kodu: 60109,
    vergi_dairesi: 'Yeşilyurt',
  },
  {
    id: 878,
    il_id: 'Tokat',
    ilce: 'Başçiftlik',
    say_kodu: 60110,
    vergi_dairesi: 'Başçiftlik',
  },
  {
    id: 879,
    il_id: 'Tokat',
    ilce: 'Sulusaray',
    say_kodu: 60111,
    vergi_dairesi: 'Sulusaray ',
  },
  {
    id: 880,
    il_id: 'Trabzon',
    ilce: 'Merkez',
    say_kodu: 61201,
    vergi_dairesi: 'Hızırbey',
  },
  {
    id: 881,
    il_id: 'Trabzon',
    ilce: 'Merkez',
    say_kodu: 61280,
    vergi_dairesi: 'Karadeniz',
  },
  {
    id: 882,
    il_id: 'Trabzon',
    ilce: 'Akçaabat',
    say_kodu: 61260,
    vergi_dairesi: 'Akçaabat',
  },
  {
    id: 883,
    il_id: 'Trabzon',
    ilce: 'Of',
    say_kodu: 61261,
    vergi_dairesi: 'Of',
  },
  {
    id: 884,
    il_id: 'Trabzon',
    ilce: 'Vakfıkebir',
    say_kodu: 61262,
    vergi_dairesi: 'Vakfıkebir',
  },
  {
    id: 885,
    il_id: 'Trabzon',
    ilce: 'Araklı',
    say_kodu: 61102,
    vergi_dairesi: 'Araklı',
  },
  {
    id: 886,
    il_id: 'Trabzon',
    ilce: 'Arsin',
    say_kodu: 61103,
    vergi_dairesi: 'Arsin',
  },
  {
    id: 887,
    il_id: 'Trabzon',
    ilce: 'Çaykara',
    say_kodu: 61104,
    vergi_dairesi: 'Çaykara',
  },
  {
    id: 888,
    il_id: 'Trabzon',
    ilce: 'Maçka',
    say_kodu: 61105,
    vergi_dairesi: 'Maçka',
  },
  {
    id: 889,
    il_id: 'Trabzon',
    ilce: 'Sürmene',
    say_kodu: 61107,
    vergi_dairesi: 'Sürmene',
  },
  {
    id: 890,
    il_id: 'Trabzon',
    ilce: 'Tonya',
    say_kodu: 61108,
    vergi_dairesi: 'Tonya',
  },
  {
    id: 891,
    il_id: 'Trabzon',
    ilce: 'Yomra',
    say_kodu: 61110,
    vergi_dairesi: 'Yomra',
  },
  {
    id: 892,
    il_id: 'Trabzon',
    ilce: 'Beşikdüzü',
    say_kodu: 61111,
    vergi_dairesi: 'Beşikdüzü',
  },
  {
    id: 893,
    il_id: 'Trabzon',
    ilce: 'Şalpazarı',
    say_kodu: 61112,
    vergi_dairesi: 'Şalpazarı',
  },
  {
    id: 894,
    il_id: 'Trabzon',
    ilce: 'Çarşıbaşı',
    say_kodu: 61113,
    vergi_dairesi: 'Çarşıbaşı',
  },
  {
    id: 895,
    il_id: 'Trabzon',
    ilce: 'Dernekpazarı',
    say_kodu: 61114,
    vergi_dairesi: 'Dernekpazarı',
  },
  {
    id: 896,
    il_id: 'Trabzon',
    ilce: 'Düzköy',
    say_kodu: 61115,
    vergi_dairesi: 'Düzköy',
  },
  {
    id: 897,
    il_id: 'Trabzon',
    ilce: 'Hayrat',
    say_kodu: 61116,
    vergi_dairesi: 'Hayrat',
  },
  {
    id: 898,
    il_id: 'Trabzon',
    ilce: 'Köprübaşı',
    say_kodu: 61117,
    vergi_dairesi: 'Köprübaşı',
  },
  {
    id: 899,
    il_id: 'Tunceli',
    ilce: 'Merkez',
    say_kodu: 62260,
    vergi_dairesi: 'Tunceli',
  },
  {
    id: 900,
    il_id: 'Tunceli',
    ilce: 'Çemişgezek',
    say_kodu: 62101,
    vergi_dairesi: 'Çemişgezek',
  },
  {
    id: 901,
    il_id: 'Tunceli',
    ilce: 'Hozat',
    say_kodu: 62102,
    vergi_dairesi: 'Hozat',
  },
  {
    id: 902,
    il_id: 'Tunceli',
    ilce: 'Mazgirt',
    say_kodu: 62103,
    vergi_dairesi: 'Mazgirt',
  },
  {
    id: 903,
    il_id: 'Tunceli',
    ilce: 'Nazimiye',
    say_kodu: 62104,
    vergi_dairesi: 'Nazimiye',
  },
  {
    id: 904,
    il_id: 'Tunceli',
    ilce: 'Ovacık',
    say_kodu: 62105,
    vergi_dairesi: 'Ovacık',
  },
  {
    id: 905,
    il_id: 'Tunceli',
    ilce: 'Pertek',
    say_kodu: 62106,
    vergi_dairesi: 'Pertek',
  },
  {
    id: 906,
    il_id: 'Tunceli',
    ilce: 'Pülümür',
    say_kodu: 62107,
    vergi_dairesi: 'Pülümür',
  },
  {
    id: 907,
    il_id: 'Şanlıurfa',
    ilce: 'Merkez',
    say_kodu: 63201,
    vergi_dairesi: 'Şehitlik',
  },
  {
    id: 908,
    il_id: 'Şanlıurfa',
    ilce: 'Merkez',
    say_kodu: 63280,
    vergi_dairesi: 'Topçu Meydanı',
  },
  {
    id: 909,
    il_id: 'Şanlıurfa',
    ilce: 'Siverek',
    say_kodu: 63260,
    vergi_dairesi: 'Siverek',
  },
  {
    id: 910,
    il_id: 'Şanlıurfa',
    ilce: 'Viranşehir',
    say_kodu: 63261,
    vergi_dairesi: 'Viranşehir',
  },
  {
    id: 911,
    il_id: 'Şanlıurfa',
    ilce: 'Birecik',
    say_kodu: 63262,
    vergi_dairesi: 'Birecik',
  },
  {
    id: 912,
    il_id: 'Şanlıurfa',
    ilce: 'Akçakale',
    say_kodu: 63101,
    vergi_dairesi: 'Akçakale',
  },
  {
    id: 913,
    il_id: 'Şanlıurfa',
    ilce: 'Bozova',
    say_kodu: 63103,
    vergi_dairesi: 'Bozova',
  },
  {
    id: 914,
    il_id: 'Şanlıurfa',
    ilce: 'Halfeti',
    say_kodu: 63104,
    vergi_dairesi: 'Halfeti',
  },
  {
    id: 915,
    il_id: 'Şanlıurfa',
    ilce: 'Hilvan',
    say_kodu: 63105,
    vergi_dairesi: 'Hilvan',
  },
  {
    id: 916,
    il_id: 'Şanlıurfa',
    ilce: 'Suruç',
    say_kodu: 63107,
    vergi_dairesi: 'Suruç',
  },
  {
    id: 917,
    il_id: 'Şanlıurfa',
    ilce: 'Ceylanpınar',
    say_kodu: 63109,
    vergi_dairesi: 'Ceylanpınar',
  },
  {
    id: 918,
    il_id: 'Şanlıurfa',
    ilce: 'Harran',
    say_kodu: 63110,
    vergi_dairesi: 'Harran',
  },
  {
    id: 919,
    il_id: 'Uşak',
    ilce: 'Merkez',
    say_kodu: 64260,
    vergi_dairesi: 'Uşak',
  },
  {
    id: 920,
    il_id: 'Uşak',
    ilce: 'Banaz',
    say_kodu: 64261,
    vergi_dairesi: 'Banaz',
  },
  {
    id: 921,
    il_id: 'Uşak',
    ilce: 'Eşme',
    say_kodu: 64262,
    vergi_dairesi: 'Eşme',
  },
  {
    id: 922,
    il_id: 'Uşak',
    ilce: 'Karahallı',
    say_kodu: 64103,
    vergi_dairesi: 'Karahallı',
  },
  {
    id: 923,
    il_id: 'Uşak',
    ilce: 'Ulubey',
    say_kodu: 64104,
    vergi_dairesi: 'Ulubey',
  },
  {
    id: 924,
    il_id: 'Uşak',
    ilce: 'Sivaslı',
    say_kodu: 64105,
    vergi_dairesi: 'Sivaslı',
  },
  {
    id: 925,
    il_id: 'Van',
    ilce: 'Merkez',
    say_kodu: 65260,
    vergi_dairesi: 'Van',
  },
  {
    id: 926,
    il_id: 'Van',
    ilce: 'Erciş',
    say_kodu: 65201,
    vergi_dairesi: 'Erciş',
  },
  {
    id: 927,
    il_id: 'Van',
    ilce: 'Başkale',
    say_kodu: 65101,
    vergi_dairesi: 'Başkale',
  },
  {
    id: 928,
    il_id: 'Van',
    ilce: 'Çatak',
    say_kodu: 65102,
    vergi_dairesi: 'Çatak',
  },
  {
    id: 929,
    il_id: 'Van',
    ilce: 'Gevaş',
    say_kodu: 65104,
    vergi_dairesi: 'Gevaş',
  },
  {
    id: 930,
    il_id: 'Van',
    ilce: 'Gürpınar',
    say_kodu: 65105,
    vergi_dairesi: 'Gürpınar',
  },
  {
    id: 931,
    il_id: 'Van',
    ilce: 'Muradiye',
    say_kodu: 65106,
    vergi_dairesi: 'Muradiye',
  },
  {
    id: 932,
    il_id: 'Van',
    ilce: '\u00d6zalp',
    say_kodu: 65107,
    vergi_dairesi: '\u00d6zalp',
  },
  {
    id: 933,
    il_id: 'Van',
    ilce: 'Bahçesaray',
    say_kodu: 65108,
    vergi_dairesi: 'Bahçesaray',
  },
  {
    id: 934,
    il_id: 'Van',
    ilce: 'Çaldıran',
    say_kodu: 65109,
    vergi_dairesi: 'Çaldıran',
  },
  {
    id: 935,
    il_id: 'Van',
    ilce: 'Edremit',
    say_kodu: 65110,
    vergi_dairesi: 'Edremit',
  },
  {
    id: 936,
    il_id: 'Van',
    ilce: 'Saray',
    say_kodu: 65111,
    vergi_dairesi: 'Saray',
  },
  {
    id: 937,
    il_id: 'Yozgat',
    ilce: 'Merkez',
    say_kodu: 66260,
    vergi_dairesi: 'Yozgat',
  },
  {
    id: 938,
    il_id: 'Yozgat',
    ilce: 'Boğazlıyan',
    say_kodu: 66261,
    vergi_dairesi: 'Boğazlıyan',
  },
  {
    id: 939,
    il_id: 'Yozgat',
    ilce: 'Sorgun',
    say_kodu: 66262,
    vergi_dairesi: 'Sorgun',
  },
  {
    id: 940,
    il_id: 'Yozgat',
    ilce: 'Yerköy',
    say_kodu: 66263,
    vergi_dairesi: 'Yerköy',
  },
  {
    id: 941,
    il_id: 'Yozgat',
    ilce: 'Akdağmadeni',
    say_kodu: 66101,
    vergi_dairesi: 'Akdağmadeni',
  },
  {
    id: 942,
    il_id: 'Yozgat',
    ilce: 'Çayıralan',
    say_kodu: 66103,
    vergi_dairesi: 'Çayıralan ',
  },
  {
    id: 943,
    il_id: 'Yozgat',
    ilce: 'Çekerek',
    say_kodu: 66104,
    vergi_dairesi: 'Çekerek',
  },
  {
    id: 944,
    il_id: 'Yozgat',
    ilce: 'Sarıkaya',
    say_kodu: 66105,
    vergi_dairesi: 'Sarıkaya',
  },
  {
    id: 945,
    il_id: 'Yozgat',
    ilce: 'Şefaatli',
    say_kodu: 66106,
    vergi_dairesi: 'Şefaatli',
  },
  {
    id: 946,
    il_id: 'Yozgat',
    ilce: 'Aydıncık',
    say_kodu: 66109,
    vergi_dairesi: 'Aydıncık',
  },
  {
    id: 947,
    il_id: 'Yozgat',
    ilce: 'Çandır',
    say_kodu: 66110,
    vergi_dairesi: 'Çandır',
  },
  {
    id: 948,
    il_id: 'Yozgat',
    ilce: 'Kadışehri',
    say_kodu: 66111,
    vergi_dairesi: 'Kadışehri',
  },
  {
    id: 949,
    il_id: 'Yozgat',
    ilce: 'Saraykent',
    say_kodu: 66112,
    vergi_dairesi: 'Saraykent',
  },
  {
    id: 950,
    il_id: 'Yozgat',
    ilce: 'Yenifakılı',
    say_kodu: 66113,
    vergi_dairesi: 'Yenifakılı',
  },
  {
    id: 951,
    il_id: 'Zonguldak',
    ilce: 'Merkez',
    say_kodu: 67201,
    vergi_dairesi: 'Uzunmehmet',
  },
  {
    id: 952,
    il_id: 'Zonguldak',
    ilce: 'Merkez',
    say_kodu: 67280,
    vergi_dairesi: 'Kara Elmas',
  },
  {
    id: 953,
    il_id: 'Zonguldak',
    ilce: 'Ereğli',
    say_kodu: 67261,
    vergi_dairesi: 'Ereğli',
  },
  {
    id: 954,
    il_id: 'Zonguldak',
    ilce: 'Çaycuma',
    say_kodu: 67263,
    vergi_dairesi: 'Çaycuma',
  },
  {
    id: 955,
    il_id: 'Zonguldak',
    ilce: 'Devrek',
    say_kodu: 67264,
    vergi_dairesi: 'Devrek',
  },
  {
    id: 956,
    il_id: 'Zonguldak',
    ilce: 'Alaplı',
    say_kodu: 67110,
    vergi_dairesi: 'Alaplı',
  },
  {
    id: 957,
    il_id: 'Zonguldak',
    ilce: 'Gökçebey',
    say_kodu: 67113,
    vergi_dairesi: 'Gökçebey',
  },
  {
    id: 958,
    il_id: 'Aksaray',
    ilce: 'Merkez',
    say_kodu: 68201,
    vergi_dairesi: 'Aksaray',
  },
  {
    id: 959,
    il_id: 'Aksaray',
    ilce: 'Ağaçören',
    say_kodu: 68101,
    vergi_dairesi: 'Ağaçören',
  },
  {
    id: 960,
    il_id: 'Aksaray',
    ilce: 'Güzelyurt',
    say_kodu: 68102,
    vergi_dairesi: 'Güzelyurt',
  },
  {
    id: 961,
    il_id: 'Aksaray',
    ilce: 'Ortaköy',
    say_kodu: 68103,
    vergi_dairesi: 'Ortaköy',
  },
  {
    id: 962,
    il_id: 'Aksaray',
    ilce: 'Sarıyahşi',
    say_kodu: 68104,
    vergi_dairesi: 'Sarıyahşi',
  },
  {
    id: 963,
    il_id: 'Aksaray',
    ilce: 'Eskil',
    say_kodu: 68105,
    vergi_dairesi: 'Eskil',
  },
  {
    id: 964,
    il_id: 'Aksaray',
    ilce: 'Gülağaç',
    say_kodu: 68106,
    vergi_dairesi: 'Gülağaç',
  },
  {
    id: 965,
    il_id: 'Bayburt',
    ilce: 'Merkez',
    say_kodu: 69201,
    vergi_dairesi: 'Bayburt',
  },
  {
    id: 966,
    il_id: 'Bayburt',
    ilce: 'Aydıntepe',
    say_kodu: 69101,
    vergi_dairesi: 'Aydıntepe',
  },
  {
    id: 967,
    il_id: 'Bayburt',
    ilce: 'Demirözü',
    say_kodu: 69102,
    vergi_dairesi: 'Demirözü',
  },
  {
    id: 968,
    il_id: 'Karaman',
    ilce: 'Merkez',
    say_kodu: 70201,
    vergi_dairesi: 'Karaman',
  },
  {
    id: 969,
    il_id: 'Karaman',
    ilce: 'Ayrancı',
    say_kodu: 70101,
    vergi_dairesi: 'Ayrancı',
  },
  {
    id: 970,
    il_id: 'Karaman',
    ilce: 'Ermenek',
    say_kodu: 70102,
    vergi_dairesi: 'Ermenek',
  },
  {
    id: 971,
    il_id: 'Karaman',
    ilce: 'Kazım Karabekir',
    say_kodu: 70103,
    vergi_dairesi: 'Kazım Karabekir',
  },
  {
    id: 972,
    il_id: 'Karaman',
    ilce: 'Başyayla',
    say_kodu: 70104,
    vergi_dairesi: 'Başyayla',
  },
  {
    id: 973,
    il_id: 'Karaman',
    ilce: 'Sarıveliler',
    say_kodu: 70105,
    vergi_dairesi: 'Sarıveliler',
  },
  {
    id: 974,
    il_id: 'Kırıkkale',
    ilce: 'Merkez',
    say_kodu: 71201,
    vergi_dairesi: 'Irmak',
  },
  {
    id: 975,
    il_id: 'Kırıkkale',
    ilce: 'Merkez',
    say_kodu: 71202,
    vergi_dairesi: 'Kaletepe',
  },
  {
    id: 976,
    il_id: 'Kırıkkale',
    ilce: 'Delice',
    say_kodu: 71101,
    vergi_dairesi: 'Delice',
  },
  {
    id: 977,
    il_id: 'Kırıkkale',
    ilce: 'Keskin',
    say_kodu: 71102,
    vergi_dairesi: 'Keskin',
  },
  {
    id: 978,
    il_id: 'Kırıkkale',
    ilce: 'Sulakyurt',
    say_kodu: 71103,
    vergi_dairesi: 'Sulakyurt',
  },
  {
    id: 979,
    il_id: 'Kırıkkale',
    ilce: 'Balışeyh',
    say_kodu: 71105,
    vergi_dairesi: 'Balışeyh',
  },
  {
    id: 980,
    il_id: 'Kırıkkale',
    ilce: 'Çelebi',
    say_kodu: 71106,
    vergi_dairesi: 'Çelebi',
  },
  {
    id: 981,
    il_id: 'Kırıkkale',
    ilce: 'Karakeçili',
    say_kodu: 71107,
    vergi_dairesi: 'Karakeçili',
  },
  {
    id: 982,
    il_id: 'Batman',
    ilce: 'Merkez',
    say_kodu: 72260,
    vergi_dairesi: 'Batman',
  },
  {
    id: 983,
    il_id: 'Batman',
    ilce: 'Beşiri',
    say_kodu: 72101,
    vergi_dairesi: 'Beşiri',
  },
  {
    id: 984,
    il_id: 'Batman',
    ilce: 'Gercüş',
    say_kodu: 72102,
    vergi_dairesi: 'Gercüş',
  },
  {
    id: 985,
    il_id: 'Batman',
    ilce: 'Hasankeyf',
    say_kodu: 72103,
    vergi_dairesi: 'Hasankeyf',
  },
  {
    id: 986,
    il_id: 'Batman',
    ilce: 'Kozluk',
    say_kodu: 72104,
    vergi_dairesi: 'Kozluk',
  },
  {
    id: 987,
    il_id: 'Batman',
    ilce: 'Sason',
    say_kodu: 72105,
    vergi_dairesi: 'Sason',
  },
  {
    id: 988,
    il_id: 'Şırnak',
    ilce: 'Merkez',
    say_kodu: 73260,
    vergi_dairesi: 'Şırnak',
  },
  {
    id: 989,
    il_id: 'Şırnak',
    ilce: 'Cizre',
    say_kodu: 73261,
    vergi_dairesi: 'Cizre',
  },
  {
    id: 990,
    il_id: 'Şırnak',
    ilce: 'Silopi',
    say_kodu: 73262,
    vergi_dairesi: 'Silopi',
  },
  {
    id: 991,
    il_id: 'Şırnak',
    ilce: 'Beytüşşebap',
    say_kodu: 73101,
    vergi_dairesi: 'Beytüşşebap',
  },
  {
    id: 992,
    il_id: 'Şırnak',
    ilce: 'Güçlükonak',
    say_kodu: 73103,
    vergi_dairesi: 'Güçlükonak',
  },
  {
    id: 993,
    il_id: 'Şırnak',
    ilce: 'İdil',
    say_kodu: 73104,
    vergi_dairesi: 'İdil',
  },
  {
    id: 994,
    il_id: 'Şırnak',
    ilce: 'Uludere',
    say_kodu: 73106,
    vergi_dairesi: 'Uludere',
  },
  {
    id: 995,
    il_id: 'Bartın',
    ilce: 'Merkez',
    say_kodu: 74260,
    vergi_dairesi: 'Bartın',
  },
  {
    id: 996,
    il_id: 'Bartın',
    ilce: 'Amasra',
    say_kodu: 74101,
    vergi_dairesi: 'Amasra',
  },
  {
    id: 997,
    il_id: 'Bartın',
    ilce: 'Kurucaşile',
    say_kodu: 74102,
    vergi_dairesi: 'Kurucaşile',
  },
  {
    id: 998,
    il_id: 'Bartın',
    ilce: 'Ulus',
    say_kodu: 74103,
    vergi_dairesi: 'Ulus',
  },
  {
    id: 999,
    il_id: 'Ardahan',
    ilce: 'Merkez',
    say_kodu: 75201,
    vergi_dairesi: 'Ardahan',
  },
  {
    id: 1000,
    il_id: 'Ardahan',
    ilce: 'Çıldır',
    say_kodu: 75101,
    vergi_dairesi: 'Çıldır',
  },
  {
    id: 1001,
    il_id: 'Ardahan',
    ilce: 'Damal',
    say_kodu: 75102,
    vergi_dairesi: 'Damal',
  },
  {
    id: 1002,
    il_id: 'Ardahan',
    ilce: 'Göle',
    say_kodu: 75103,
    vergi_dairesi: 'Göle',
  },
  {
    id: 1003,
    il_id: 'Ardahan',
    ilce: 'Hanak',
    say_kodu: 75104,
    vergi_dairesi: 'Hanak',
  },
  {
    id: 1004,
    il_id: 'Ardahan',
    ilce: 'Posof',
    say_kodu: 75105,
    vergi_dairesi: 'Posof',
  },
  {
    id: 1005,
    il_id: 'Iğdır',
    ilce: 'Merkez',
    say_kodu: 76201,
    vergi_dairesi: 'Iğdır ',
  },
  {
    id: 1006,
    il_id: 'Iğdır',
    ilce: 'Aralık',
    say_kodu: 76101,
    vergi_dairesi: 'Aralık',
  },
  {
    id: 1007,
    il_id: 'Iğdır',
    ilce: 'Karakoyunlu',
    say_kodu: 76102,
    vergi_dairesi: 'Karakoyunlu',
  },
  {
    id: 1008,
    il_id: 'Iğdır',
    ilce: 'Tuzluca',
    say_kodu: 76103,
    vergi_dairesi: 'Tuzluca',
  },
  {
    id: 1009,
    il_id: 'Yalova',
    ilce: 'Merkez',
    say_kodu: 77201,
    vergi_dairesi: 'Yalova',
  },
  {
    id: 1010,
    il_id: 'Yalova',
    ilce: 'Altınova',
    say_kodu: 77101,
    vergi_dairesi: 'Altınova',
  },
  {
    id: 1011,
    il_id: 'Yalova',
    ilce: 'Armutlu',
    say_kodu: 77102,
    vergi_dairesi: 'Armutlu',
  },
  {
    id: 1012,
    il_id: 'Yalova',
    ilce: 'Çınarcık',
    say_kodu: 77103,
    vergi_dairesi: 'Çınarcık',
  },
  {
    id: 1013,
    il_id: 'Karabük',
    ilce: 'Merkez',
    say_kodu: 78201,
    vergi_dairesi: 'Karabük',
  },
  {
    id: 1014,
    il_id: 'Karabük',
    ilce: 'Safranbolu',
    say_kodu: 78260,
    vergi_dairesi: 'Safranbolu',
  },
  {
    id: 1015,
    il_id: 'Karabük',
    ilce: 'Eflani',
    say_kodu: 78101,
    vergi_dairesi: 'Eflani',
  },
  {
    id: 1016,
    il_id: 'Karabük',
    ilce: 'Eskipazar',
    say_kodu: 78102,
    vergi_dairesi: 'Eskipazar',
  },
  {
    id: 1017,
    il_id: 'Karabük',
    ilce: 'Ovacık',
    say_kodu: 78103,
    vergi_dairesi: 'Ovacık',
  },
  {
    id: 1018,
    il_id: 'Karabük',
    ilce: 'Yenice',
    say_kodu: 78105,
    vergi_dairesi: 'Yenice',
  },
  {
    id: 1019,
    il_id: 'Kilis',
    ilce: 'Merkez',
    say_kodu: 79201,
    vergi_dairesi: 'Kilis',
  },
  {
    id: 1020,
    il_id: 'Osmaniye',
    ilce: 'Merkez',
    say_kodu: 80201,
    vergi_dairesi: 'Osmaniye',
  },
  {
    id: 1021,
    il_id: 'Osmaniye',
    ilce: 'Kadirli',
    say_kodu: 80260,
    vergi_dairesi: 'Kadirli',
  },
  {
    id: 1022,
    il_id: 'Osmaniye',
    ilce: 'Bahçe',
    say_kodu: 80101,
    vergi_dairesi: 'Bahçe',
  },
  {
    id: 1023,
    il_id: 'Osmaniye',
    ilce: 'Düziçi',
    say_kodu: 80102,
    vergi_dairesi: 'Düziçi',
  },
  {
    id: 1024,
    il_id: 'Osmaniye',
    ilce: 'Hasanbeyli',
    say_kodu: 80104,
    vergi_dairesi: 'Hasanbeyli',
  },
  {
    id: 1025,
    il_id: 'Osmaniye',
    ilce: 'Sumbas',
    say_kodu: 80105,
    vergi_dairesi: 'Sumbas',
  },
  {
    id: 1026,
    il_id: 'Osmaniye',
    ilce: 'Toprakkale',
    say_kodu: 80106,
    vergi_dairesi: 'Toprakkale',
  },
  {
    id: 1027,
    il_id: 'Düzce',
    ilce: 'Merkez',
    say_kodu: 81260,
    vergi_dairesi: 'Düzce',
  },
  {
    id: 1028,
    il_id: 'Düzce',
    ilce: 'Akçakoca',
    say_kodu: 81261,
    vergi_dairesi: 'Akçakoca',
  },
  {
    id: 1029,
    il_id: 'Düzce',
    ilce: 'Yığılca',
    say_kodu: 81107,
    vergi_dairesi: 'Yığılca',
  },
  {
    id: 1030,
    il_id: 'Düzce',
    ilce: 'Cumayeri',
    say_kodu: 81102,
    vergi_dairesi: 'Cumayeri',
  },
  {
    id: 1031,
    il_id: 'Düzce',
    ilce: 'Gölyaka',
    say_kodu: 81104,
    vergi_dairesi: 'Gölyaka',
  },
  {
    id: 1032,
    il_id: 'Düzce',
    ilce: 'Çilimli',
    say_kodu: 81103,
    vergi_dairesi: 'Çilimli',
  },
  {
    id: 1033,
    il_id: 'Düzce',
    ilce: 'Gümüşova',
    say_kodu: 81105,
    vergi_dairesi: 'Gümüşova',
  },
  {
    id: 1034,
    il_id: 'Düzce',
    ilce: 'Kaynaşlı',
    say_kodu: 81106,
    vergi_dairesi: 'Kaynaşlı ',
  },
];

export const vatCities = temp.map((item) => {
  return {
    label: `${item.vergi_dairesi.toLocaleUpperCase(
      'tr'
    )} VERGİ DAİRESİ MÜDÜRLÜĞÜ - ${item.say_kodu}`,
    value: `${item.il_id.toLocaleUpperCase(
      'tr'
    )} ${item.vergi_dairesi.toLocaleUpperCase(
      'tr'
    )} VERGİ DAİRESİ MÜDÜRLÜĞÜ - (${item.say_kodu})`,
  };
});
