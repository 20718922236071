import { useEffect, useState } from 'react';

import { USStates } from '../components/shipentegraCreatePost/elements/states';
import NewPostAddress from '../components/shipentegraCreatePost/NewPostAddress';
import ShipEntegraLoginRegister from '../components/shipEntegraShared/ShipEntegraLoginRegister';
import NewBanner from '../components/tools/common/NewBanner';
import { useAuth } from '../context/AuthContext';
import { USState } from '../interfaces/shipEntegraInterfaces';
import etsyToolsService from '../services/etsyToolsService';
import shipentegraService from '../services/shipentegraService';
import alertNotification from '../utils/alertNotification';

interface Product {
    id: number;
    quantity: number | null;
    unitPrice: number | null;
    name: string | null;
    sku: string | null;
    gtip: string | null;
    quantityErr: boolean;
    unitPriceErr: boolean;
    nameErr: boolean;
}
const ShipEntegraCreateOrder = () => {
    const { session } = useAuth()
    const states: USState[] = (USStates)
    const [loading, setLoading] = useState(false)
    const [enabled, setEnabled] = useState(true)
    const [selectedState, setSelectedState] = useState<USState>({ name: '', value: '' })
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({
        id: 0,
        name: '',
        error: false
    })
    const [selectedCurrencies, setSelectedCurrencies] = useState({
        name: '',
        value: '',
        error: false
    });
    const [products, setProducts] = useState<Product[]>([
        {
            id: 1,
            quantity: null, // required
            unitPrice: null, // required
            name: null, // required
            sku: null,
            gtip: null,
            quantityErr: false, // required
            unitPriceErr: false, // required
            nameErr: false,
        }
    ]);
    const [description, setDescription] = useState({
        text: '',
        error: false
    });
    const [packageQuantity, setPackageQuantity] = useState(1);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars

    const getCountries = () => {
        etsyToolsService.getCountries().then((res) => {
            setCountries(res.data);
        });
    };

    useEffect(() => {
        getCountries();
    }, []);

    const resetFieldValues = () => {
        setProducts([
            {
                id: 1,
                quantity: null, // required
                unitPrice: null, // required
                name: null, // required
                sku: null,
                gtip: null,
                quantityErr: false, // required
                unitPriceErr: false, // required
                nameErr: false,
            }
        ])
        setSelectedCountry({
            id: 0,
            name: '',
            error: false
        })
        setSelectedCurrencies({
            name: '',
            value: '',
            error: false
        });
        setDescription({
            text: '',
            error: false
        });
        setSelectedState({ name: '', value: '' })
        setPackageQuantity(1);
    }
    const onSubmit = (values: any) => {
        const emptyNameErr = products.some(product => !product.name);
        const emptyQuantityErr = products.some(product => !product.quantity);
        const emptyUnitPriceErr = products.some(product => !product.unitPrice);

        if (emptyNameErr || emptyQuantityErr || emptyUnitPriceErr) {
            setProducts(prevProducts => prevProducts.map(product => ({
                ...product,
                nameErr: !product.name,
                quantityErr: !product.quantity,
                unitPriceErr: !product.unitPrice
            })));
        }
        if (selectedCountry.name === '') {
            setSelectedCountry({
                id: 0,
                name: '',
                error: true
            })
        }
        if (selectedCurrencies.name === '') {
            setSelectedCurrencies({
                name: '',
                value: '',
                error: true
            })
        }
        if (description.text === '') {
            setDescription({
                text: '',
                error: true
            })
        }

        const orderData = {
            shippingAddress: {
                name: values.name,
                address: values.address,
                city: values.city,
                country: selectedCountry.name,
                postalCode: String(values.postalCode),
                ...(selectedState.name && { state: selectedState.name }),
                ...(values.phone && { phone: String(values.phone) }),
                ...(values.email && { email: values.email }),
            },
            currency: selectedCurrencies.name,
            description: description.text,
            items: products.map(product => ({
                quantity: Number(product.quantity),
                unitPrice: Number(product.unitPrice),
                name: product.name,
                ...(product.sku && { sku: product.sku }),
                ...(product.gtip && { gtip: product.gtip }),
            })),
            packageQuantity: packageQuantity,
            reference1: values.reference1 === '' ? '' : values.reference1,
            weight: values.weight === '' ? 0 : values.weight,
            width: values.width === '' ? 0 : values.width,
            height: values.height === '' ? 0 : values.height,
            length: values.length === '' ? 0 : values.length,
        };
        if (!selectedCountry.error && !selectedCurrencies.error && !emptyNameErr && !emptyQuantityErr && !emptyUnitPriceErr && !description.error) {
            setLoading(true)
            shipentegraService.createOrder(orderData)
                .then(response => {
                    if (enabled) {
                        // Sayfa yeniden yüklendiğinde bildirimi göstermek üzere bir bayrak ayarlayın
                        localStorage.setItem('reloadNotification', 'success');
                        // Sayfayı yenileyin
                        window.location.reload();
                    } else {
                        // Eğer enabled değilse, bildirimi hemen göster
                        alertNotification('success', "Sipariş oluşturuldu");
                    }
                }).catch((err: any) => {
                    alertNotification('error', err.response.data.message);
                }).finally(() => {
                    setLoading(false)
                })
        }
    };

    useEffect(() => {
        // Component mount edildiğinde çalışacak kod:
        const reloadNotification = localStorage.getItem('reloadNotification');
        if (reloadNotification) {
            // LocalStorage'dan alınan bildirim tipine göre alertNotification çağrısı yapın
            alertNotification(reloadNotification, "Sipariş oluşturuldu");
            // Kullanıldıktan sonra bayrağı temizleyin
            localStorage.removeItem('reloadNotification');
        }
    }, []); // Bağımlılık dizisi boş olduğu için bu useEffect sadece component mount edildiğinde çalışır

    let content =
        <div className='bg-white rounded-xl shadow-lg py-4'>
            {/* //TODO:if amazon fba wants to be added cancel comment and delete the others */}
            {/* <TabSelection/> */}
            <NewPostAddress
                loading={loading}
                enabled={enabled}
                setEnabled={setEnabled}
                states={states}
                selectedState={selectedState}
                setSelectedState={setSelectedState}
                countries={countries}
                selectedCountry={selectedCountry}
                setSelectedCountry={setSelectedCountry}
                selectedCurrencies={selectedCurrencies}
                setSelectedCurrencies={setSelectedCurrencies}
                products={products}
                setProducts={setProducts}
                description={description}
                setDescription={setDescription}
                count={packageQuantity}
                setCount={setPackageQuantity}
                onSubmit={onSubmit} />
        </div>
    return (
        <>
            <NewBanner
                bgUrl={'/images/shipentegra-banner-bg.webp'}
                toolImageUrl="shipentegra-create-post"
            />
            <ShipEntegraLoginRegister />
            {session?.hasShipEntegraInt ?
                <>
                    {content}
                </>
                :
                <div className='blur-sm h-full w-full'>
                    {content}
                </div>
            }
        </>
    )
}
export default ShipEntegraCreateOrder