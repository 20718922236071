import React, { useEffect, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useLocation, useParams } from 'react-router-dom';

import ProggressBar from '../components/common/ProggressBar';
import CourseDescriptionCard from '../components/courseDetail/CourseDescriptionCard';
import CourseSectionsSideBar from '../components/courseDetail/CourseSectionsSideBar';
import VimeoPlayer from '../components/courseDetail/VimeoPlayer';
import API_URL from '../config';
import { useAuth } from '../context/AuthContext';
import CourseService from '../services/courseService';
import memberService from '../services/memberService';
import ErrorFallback from './errors/ErrorFallback';

export interface Content {
  id: number;
  contentName: string;
  contentOrder: number;
  type: string;
  path: string;
  notes: string;
  createdAt: Date;
  updatedAt: Date;
  sectionId: number;
  duration: number;
}

export interface Section {
  id: number;
  sectionName: string;
  sectionOrder: number;
  sectionLocation: string;
  createdAt: Date;
  updatedAt: Date;
  newCourseId: number;
  contents: Content[];
}

export interface CourseData {
  id: number;
  courseName: string;
  courseLocation: string;
  description: string;
  isFree: boolean;
  createdAt: string;
  updatedAt: string;
  sections: Section[];
  completed?: number[];
}

const CourseDetail = () => {
  const { session } = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  let isUpdate = false;
  const [isTabActive, setIsTabActive] = React.useState<boolean>(true);
  const [course, setCourse] = useState<CourseData>({
    id: 1,
    courseName: '',
    courseLocation: '',
    description: '',
    isFree: true,
    createdAt: '',
    updatedAt: '',
    sections: [],
    completed: [],
  });
  const [selectedContent, setSelectedContent] = useState<{
    sectionLocation: any;
    path: any;
    id: any;
    contentName: any;
    notes: string;
  }>();
  const [contentCount, setContentCount] = useState<number>(0);
  const [endedSectionContentCount, setEndedSectionContentCount] = useState<
    { sectionId: number; contentId: number }[] | undefined
  >();
  const [percent, setPercent] = useState<number>();
  const [internetQualityLow, setInternetQualityLow] = useState<number>(3);
  const [qualitySelected, setQualitySelected] = useState<boolean>(false);
  const [userPaused, setUserPaused] = useState<boolean>(true);
  //get courseId from url
  const { id, courseName } = useParams();
  const query = useQuery();
  const sectionUrl = query.get('section');
  const pathUrl = query.get('path');
  const courseLocationUrl = query.get('course')
  const nameUrl = query.get('name')
  const notesUrl = query.get('notes')
  const contentIdUrl = query.get('id')
  function classNames(...classes: String[]) {
    return classes.filter(Boolean).join(' ');
  }

  // this ref is defined here to trigger the video element src change
  const videoRef = useRef<HTMLVideoElement>(null);

  const handleSelectedContent = (
    sectionLocation: string,
    path: string,
    id: number,
    contentName: string,
    notes: string
  ) => {
    // if (sectionUrl || pathUrl || courseLocationUrl || nameUrl || notesUrl || contentIdUrl) {
    //   setSelectedContent({
    //     sectionLocation: sectionUrl,
    //     path: pathUrl,
    //     id: contentIdUrl,
    //     contentName: nameUrl,
    //     notes: notesUrl ? notesUrl : ''
    //   })
    // } else {
    // }
    setSelectedContent({ sectionLocation, path, id, contentName, notes });
    videoRef.current?.load(); // reload the video every time the user changes the video
  };
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }

  const getLatestVideo = (course: CourseData, id: number) => {
    let section;
    let loc;
    let nextSection: Section | undefined;
    let nextContent: Content | undefined;
    for (let i = 0; i < course?.sections?.length; i++) {
      if (
        course!.sections[i].contents.filter((el) => el.id === id).length > 0
      ) {
        for (let j = 0; j < course?.sections[i].contents.length; j++) {
          if (course!.sections[i].contents[j].id === id) {
            section = course!.sections[i];
            loc = section.sectionLocation;
            // Check if there is a next content in the same section
            if (j < section.contents.length - 1) {
              nextContent = section.contents[j + 1];
              break;
            }
          }
        }
        // Check if there is a next section and get its first content
        if (!nextContent && i < course!.sections.length - 1) {
          nextSection = course!.sections[i + 1];
          nextContent = nextSection.contents[0];
          break;
        }
      }
    }

    const ifLatestVideo = section?.contents?.filter(
      (content) => content?.id === id
    )[0].path;
    const ifLatestVideoId = section?.contents?.filter(
      (content) => content?.id === id
    )[0]?.id;
    const ifLatestContentName = section?.contents?.filter(
      (content) => content?.id === id
    )[0].contentName;
    const ifLatestContentNotes = section?.contents?.filter(
      (content) => content?.id === id
    )[0].notes;

    let latestVideo;
    let latestVideoId;
    let latestContentName;
    let latestContentNotes;

    if (ifLatestVideo === undefined || ifLatestVideo === null) {
      latestVideo = course?.sections[0]?.contents[0]!.path!;
      latestVideoId = course?.sections[0]?.contents[0]!.id!;
      latestContentName = course?.sections[0]?.contents[0]!.contentName!;
      latestContentNotes = course?.sections[0]?.contents[0]!.notes;
    } else {
      latestVideo = ifLatestVideo;
      latestVideoId = ifLatestVideoId;
      latestContentName = ifLatestContentName;
      latestContentNotes = ifLatestContentNotes;
    }

    let nextSectionLocation = nextSection?.sectionLocation;
    let nextContentPath = nextContent?.path;
    let nextContentId = nextContent?.id;
    let nextContentName = nextContent?.contentName;
    let nextContentNotes = nextContent?.notes;

    // If there is no next section, get the section of the next content
    if (!nextSectionLocation && nextContent) {
      nextSectionLocation = course.sections.find(
        (section) => section.id === nextContent?.sectionId
      )?.sectionLocation;
    }
    return [
      loc ? loc : course?.sections[0]?.sectionLocation!,
      latestVideo,
      latestVideoId,
      latestContentName,
      latestContentNotes,
      nextSectionLocation,
      nextContentPath,
      nextContentId,
      nextContentName,
      nextContentNotes,
    ];
  };

  const getTotalContentCount = () => {
    let count = 0;
    // eslint-disable-next-line array-callback-return
    course.sections.map((section) => {
      // eslint-disable-next-line array-callback-return
      section.contents.map((content) => {
        count = count + 1;
      });
    });
    setContentCount(count);
  };

  const getEndedSectionContentCount = () => {
    const newContent: { sectionId: number; contentId: number }[] = [];
    course?.sections?.forEach((section) => {
      section.contents.forEach((content) => {
        if (course.completed?.includes(content.id)) {
          // const contentIndex = endedSectionContentCount?.findIndex(item => item.contentId === content.id);
          newContent.push({ sectionId: section.id, contentId: content.id });
        }
      });
    });
    return newContent;
  };

  const getCourseDetails = async () => {
    setLoading(true);
    try {
      const data = await Promise.all([
        CourseService.getCourse(id!),
        CourseService.getUserProgress(id!),
      ]);
      // Find the content with the highest contentId in the completed array
      const completedContentIds = data[1]?.data?.completed
        ? data[1]?.data?.completed
        : [];
      const maxContentId = completedContentIds[completedContentIds.length - 1];
      // const maxContentId = Math.max(...completedContentIds);
      const maxCompletedContent = data[0]?.data?.sections
        .flatMap((section: Section) => section.contents)
        .find((content: Content) => content.id === maxContentId);
      setCourse({
        ...data[0]?.data,
        completed: data[1]?.data?.completed ? data[1]?.data?.completed : [],
      });
      const values = getLatestVideo(
        { ...data[0]?.data, completed: data[1]?.data?.completed },
        data[1]?.data?.completed &&
          selectedContent?.id < maxCompletedContent?.id &&
          isUpdate
          ? selectedContent?.id
          : maxCompletedContent?.id
      );


      if (isUpdate) {
        setSelectedContent({
          sectionLocation: values[5]!,
          path: values[6]!,
          id: values[7]!,
          contentName: values[8]!,
          notes: values[9]!.toString(),
        });

      } else if (sectionUrl || pathUrl || courseLocationUrl || nameUrl || notesUrl || contentIdUrl) {
        setSelectedContent({
          sectionLocation: sectionUrl,
          path: pathUrl,
          id: Number(contentIdUrl),
          contentName: nameUrl,
          notes: notesUrl ? notesUrl : ''
        })
      } else {
        setSelectedContent({
          sectionLocation: values[0]!,
          path: values[1]!,
          id: values[2]!,
          contentName: values[3]!,
          notes: values[4]!.toString(),
        });
      }
      videoRef.current?.load(); // reload the video every time the user changes the video
      setEndedSectionContentCount(getEndedSectionContentCount());
      getTotalContentCount();
      setLoading(false);
      return data;
    } catch (error) {
      console.log(error);
    }
  };

  const onChange = async (e: any, courseId: number, contentId: number) => {
    if (e.target.checked === true) {
      await CourseService.updateUserProgress({ courseId, contentId });
      getCourseDetails();
    } else if (e.target.checked === false) {
      await CourseService.discardUserProgress({ courseId, contentId });
      getCourseDetails();
    }
  };

  const update = (courseId: number, contentId: number) => {
    isUpdate = true;
    CourseService.updateUserProgress({ courseId, contentId }).then(() => {
      getCourseDetails();
      setEndedSectionContentCount(getEndedSectionContentCount());
      videoRef.current?.load();
    });
  };

  useEffect(() => {
    getCourseDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log('selectedContent', selectedContent)
    setEndedSectionContentCount(getEndedSectionContentCount());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContent]);

  useEffect(() => {
    setPercent((course?.completed?.length! / contentCount!) * 100);
  }, [course?.completed?.length, contentCount]);

  useEffect(() => {
    getTotalContentCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [course?.completed?.length]);

  useEffect(() => {
    const isIphone = navigator.userAgent.match(/iPhone/i) !== null;
    if (isIphone) {
      const video = videoRef.current!;
      if (video) {
        video.src = `${API_URL}courses${course?.courseLocation}${selectedContent?.sectionLocation}${selectedContent?.path}`;
      }
    }
  }, [course, selectedContent]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabActive(document.visibilityState === 'visible');
    };

    // Add event listener for visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup function to remove the event listener when the component is unmounted
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    let intervalId: any;
    if (session && isTabActive) {
      intervalId = setInterval(async () => {
        await memberService.userTrackReq(
          `courses-detail-${courseName}`,
          session?.id!
        );
      }, 60000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [session, isTabActive, courseName]);
  const checkInternetQuality = () => {
    if (navigator.connection && course !== undefined) {
      const { downlink, effectiveType, rtt } = navigator.connection;
      // Bağlantı hızını değerlendirme
      if (effectiveType === '3g' || downlink < 2.5 || rtt > 1000) {
        setInternetQualityLow(2);
      } else {
        setInternetQualityLow(1);
      }
    }
  };

  useEffect(() => {
    // Başlangıçta ve sayfa yeniden yüklendiğinde bağlantı hızını kontrol et
    checkInternetQuality();
  }, []);

  useEffect(() => {
    // Belirli aralıklarla checkInternetQuality fonksiyonunu çağırmak için setInterval kullanılıyor
    if (qualitySelected === false || userPaused) {
      const intervalId = setInterval(() => {
        checkInternetQuality();
      }, 10000); // 10 saniyede bir çalışacak şekilde ayarlandı
      // Komponent temizlendiğinde setInterval'i temizlemek için clearInterval kullanılıyor
      return () => {
        clearInterval(intervalId);
      };
    }
  }, [internetQualityLow]);

  const setVideoAfterQualityChange = () => {
    if (internetQualityLow !== 3) {
      const video1 = videoRef.current!;
      if (video1) {
        const currentTime1 = video1!.currentTime;
        if (internetQualityLow === 2) {
          // Daha düşük kalitede bir video SRC'si ayarla
          const video = videoRef.current!;
          video.src = `${API_URL}courses${course?.courseLocation}${selectedContent?.sectionLocation
            }${selectedContent?.path.replace('.mp4', '_version_480P.mp4')}`;
          video.currentTime = currentTime1;
          video.load();
          if (!userPaused) {
            video.play();
          }
          // setCurrentSrc();
        } else if (internetQualityLow === 1) {
          // Yüksek kalitede video SRC'si ayarla
          const video = videoRef.current!;
          video.src = `${API_URL}courses${course?.courseLocation}${selectedContent?.sectionLocation}${selectedContent?.path}`;
          video.currentTime = currentTime1;
          video.load();
          if (!userPaused) {
            video.play();
          }
        }
      }
    }
  };

  useEffect(() => {
    setVideoAfterQualityChange();
    // // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [internetQualityLow, selectedContent]);

  let content;
  content = (
    <>
      <h3 className="font-medium text-headingPrimary text-md sm:text-xl uppercase">
        {courseName}
      </h3>

      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 xl:col-span-8 2xl:col-span-9">
          <div className="my-2 grid grid-cols-12 gap-2 items-center justify-between w-full">
            <div className='w-full col-span-12 lg:col-span-8'>
              <span className="font-medium text-rexRed text-sm sm:text-md ">
                {selectedContent?.contentName}
              </span>
              {course.courseLocation !== '/6-etsy-master-egitimi' &&
                <span
                  onClick={() => {
                    setQualitySelected(true);
                    setInternetQualityLow(2);
                  }}
                  className={classNames(
                    internetQualityLow === 2 ? 'bg-lightGray' : '',
                    'ml-2 border rounded-md text-headingPrimary border-lightGray text-sm cursor-pointer p-1'
                  )}
                >
                  480P
                </span>}
              {course.courseLocation !== '/6-etsy-master-egitimi' &&
                <span
                  onClick={() => {
                    setQualitySelected(true);
                    setInternetQualityLow(1);
                  }}
                  // style={{ backgroundColor: "headingPrimary" }}
                  className={classNames(
                    internetQualityLow === 1 ? 'bg-lightGray' : '',
                    'ml-2 border rounded-md text-headingPrimary border-lightGray text-sm cursor-pointer p-1'
                  )}
                >
                  720P
                </span>}

            </div>
            <div className="col-span-12 lg:col-span-4 flex flex-row   items-center justify-start lg:justify-end  w-full pb-2">
              <div className="w-[50%] lg:w-[75%] text-headerPrimary text-sm">
                Kurs İlerlemesi{' '}
                {course?.completed?.length && contentCount && Math.ceil(percent!)}{' '}
                %
                <ProggressBar percent={percent!} />
              </div>
            </div>
          </div>
          <div className="relative bg-white rounded-xl shadow-lg">
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              {course !== undefined && selectedContent !== undefined && course.courseLocation !== '/6-etsy-master-egitimi' && (
                <video
                  className="rounded-xl w-full h-auto"
                  playsInline
                  ref={videoRef}
                  controls
                  controlsList="nodownload"
                  onEnded={() => update(course.id, selectedContent?.id)}
                  onClick={() => setUserPaused((prev) => !prev)}
                  id={'videoPlayer'}
                >
                  <source
                    src={`${API_URL}courses${course?.courseLocation}${selectedContent?.sectionLocation}${selectedContent?.path}`}
                    type={'video/mp4'}
                  />
                  <source
                    src={`${API_URL}courses${course?.courseLocation}${selectedContent?.sectionLocation
                      }${selectedContent?.path.replace(
                        '.mp4',
                        '_version_480P.mp4'
                      )}`}
                    type={'video/mp4'}
                  />
                </video>
              )}
              {course !== undefined && selectedContent !== undefined && course.courseLocation === '/6-etsy-master-egitimi' && (
                <VimeoPlayer title={selectedContent?.path} update={update} courseId={course.id} selectedContentId={selectedContent.id} />
              )}
            </ErrorBoundary>
          </div>

          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <CourseDescriptionCard
              endedSectionContentCount={endedSectionContentCount}
              selectedContent={selectedContent!}
              handleSelectedContent={handleSelectedContent}
              onChange={onChange}
              percent={percent!}
              course={course}
              contentCount={contentCount}
            />
          </ErrorBoundary>
        </div>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <CourseSectionsSideBar
            course={course}
            endedSectionContentCount={endedSectionContentCount}
            selectedContent={selectedContent!}
            handleSelectedContent={handleSelectedContent}
            onChange={onChange}
            display={'hidden xl:block'}
          />
        </ErrorBoundary>
      </div>
    </>
  );

  return <div className="flex w-full flex-shrink-0 flex-col">{content}</div>;
};

export default CourseDetail;
