import { useField } from 'formik';
import React from 'react';

import { Switch } from '@headlessui/react';

import { classNames } from '../../../utils/conditionalClasses';

interface FormikSwitchProps {
    name: string;
}

const FormikSwitch: React.FC<FormikSwitchProps> = ({ name }) => {
    const [field, , helpers] = useField({ name });
    const { setValue } = helpers;

    return (
        <Switch
            checked={!!field.value}
            onChange={(value) => setValue(value)}
            className={classNames(
                field.value
                    ? 'bg-[#22D3EE]'
                    : 'bg-[#E5E5E6]',
                'relative inline-flex h-5 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none'
            )}
        >
            <span className="sr-only">Use setting</span>
            <span
                aria-hidden="true"
                className={classNames(
                    field.value
                        ? 'translate-x-5 bg-white'
                        : 'translate-x-0 bg-[#818183]',
                    'pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                )}
            />
        </Switch>
    );
};

export default FormikSwitch;
