export function durationConverter(saniye: number): string {
    let mins = Math.ceil(saniye / 60);
    const h = Math.floor(mins / 60);
    mins %= 60;

    if (h > 0) {
        return `${h} sa ${mins} dk`;
    } else {
        return `${mins} dk`;
    }
}