import React from 'react';
import Select from 'react-select';
import { CommissionCategories } from './TrendyolCalculateProfitForm';

interface CustomSelectProps {
  options: any;
  onSelect: any;
  page?: string
}

const CustomSelect: React.FC<CustomSelectProps> = ({ options, onSelect, page }) => {
  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      backgroundColor: 'white',
      border: page === 'survey' ? `1px solid` : `1px solid #FCE3CF`,
      borderRadius: '3px',
      //   paddingLeft: '2px',
      minHeight: '2.3rem',
      fontSize: '12px',
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#FCE3CF' : 'white',
      fontSize: '12px',
      color: state.isSelected ? 'white' : 'black',
      '&:hover': {
        backgroundColor: '#FCE3CF',
        color: '#000',
      },
    }),
  };

  const handleChange = (selectedOption: any) => {
    onSelect(selectedOption.value);
  };

  return (
    <Select
      placeholder="Seçiniz..."
      styles={customStyles}
      options={options.map((option: CommissionCategories) => ({
        value: option,
        label: option.name,
      }))}
      onChange={handleChange}
    />
  );
};

export default CustomSelect;
