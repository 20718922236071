import { PlusIcon } from '@heroicons/react/24/outline';
import { DocumentText } from 'iconsax-react';
import React, { useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

interface Props {
  setImage: React.Dispatch<React.SetStateAction<any>>;
}

const DropDocuments: React.FC<Props> = ({ setImage }) => {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    console.log(acceptedFiles);
    setImage(
      Object.assign(acceptedFiles[0], {
        preview: URL.createObjectURL(acceptedFiles[0]),
      })
    );
  }, []);
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    maxFiles: 4,
    onDrop,
  });
  const files = acceptedFiles.map((file) => (
    <li key={file.name} className="flex space-x-2 items-center">
      <p>{file.name}</p>

      <DocumentText size="24" color="#3c468f" />
    </li>
  ));

  useEffect(() => {}, []);

  return (
    <div className="flex space-x-4">
      <div
        {...getRootProps()}
        className="w-24 h-24 border-2 border-cardBorder flex justify-center items-center rounded-xl cursor-pointer"
      >
        <input {...getInputProps()} />
        <div className="w-12 h-12 bg-lightGray rounded-full">
          {<PlusIcon color="#3c468f" height={48} width={48} />}
        </div>
      </div>
      <ul>{files}</ul>
    </div>
  );
};

export default DropDocuments;
