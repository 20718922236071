import React from 'react';
import ReactApexChart from 'react-apexcharts';

interface Props {
  options: any;
  series: any;
}
const HistoryChart: React.FC<Props> = ({ options, series }) => {
  console.log({ series });
  return (
    <div>
      <ReactApexChart
        options={options}
        type="line"
        height={'400px'}
        width={'550px'}
        series={series}
      />
    </div>
  );
};

export default HistoryChart;
