import React, { Suspense, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import { useQuery } from '@tanstack/react-query';

import DotLoaderSpinner from '../components/common/DotLoaderSpinner';
import ProductNotFound from '../components/common/ProductNotFound';
import ShipEntegraCreateTicket from '../components/ShipEntegraMyOrders/ShipEntegraCreateTicket';
import ShipEntegraOrdersForm from '../components/ShipEntegraMyOrders/ShipEntegraOrdersForm';
import ShipEntegraOrdersTable from '../components/ShipEntegraMyOrders/ShipEntegraOrdersTable';
import ShipEntegraLoginRegister from '../components/shipEntegraShared/ShipEntegraLoginRegister';
import BannerLoading from '../components/tools/common/BannerLoading';
import NewBanner from '../components/tools/common/NewBanner';
import { useAuth } from '../context/AuthContext';
import { useLanguage } from '../context/LanguageContext';
import { ICountryRoot } from '../interfaces/etsyToolsInterfaces';
import { IOrdersQuery, Order } from '../interfaces/shipEntegraInterfaces';
import etsyToolsService from '../services/etsyToolsService';
import shipentegraService from '../services/shipentegraService';
import alertNotification from '../utils/alertNotification';
import ErrorFallback from './errors/ErrorFallback';

const ShipEntegraMyOrders = () => {
    const { session } = useAuth()
    const [orders, setOrders] = useState<Order[]>([])
    const [filteredData, setFilteredData] = useState<Order[]>();
    const [query, setQuery] = useState('');
    const [value] = useDebounce(query, 500);
    const countries = useQuery<ICountryRoot, Error>(['shipEntegraCountries'], {
        queryFn: () => etsyToolsService.getCountries(),
        staleTime: Infinity,
    });
    const [isLoading, setIsLoading] = useState(false)
    const [openCreateTicket, setOpenCreateTicket] = useState(false)
    const [openArrangeOrder, setOpenArrangeOrder] = useState(false)
    const [page, setPage] = useState(1);
    const [totalPageCount, setTotalPageCount] = useState(1)
    const [totalOrderCount, setTotalOrderCount] = useState(1)
    const [inputPage, setInputPage] = useState<number | string>(1);
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();
    const navigate = useNavigate();
    const { t } = useLanguage();
    const urlPage = location.search;
    const [queryString, setQueryString] = useState('');
    const [selectedOrders, setSelectedOrders] = useState<Order[]>([]);
    const [error, setError] = useState<boolean | string>('')
    const [selectedOrderIds, setSelectedOrderIds] = React.useState<number[]>([]);
    const [selectedOrderDetails, setSelectedOrderDetails] = React.useState<
        { url: string; orderId: number }[]
    >([]);

    const tableHeader = [
        {
            name: t?.('orders.table.choose'),
            column: 'choose'
        },
        {
            name: t?.('orders.table.ticket'),
            column: 'label_unique'
        },
        {
            name: t?.('orders.table.product'),
            column: 'av'
        },
        {
            name: t?.('orders.table.id'),
            column: 'store_id'
        },
        {
            name: t?.('orders.table.buyer'),
            column: 'createDate'
        },
        {
            name: t?.('orders.table.trackingNu'),
            column: 'store_id'
        },
        {
            name: t?.('orders.table.seTagNu'),
            column: 'store_id'
        },
        {
            name: t?.('orders.table.carrier'),
            column: 'carrier'
        },
        {
            name: t?.('orders.table.country'),
            column: 'avgSales'
        },
        {
            name: t?.('orders.table.piece'),
            column: 'quantity'
        },
        {
            name: t?.('orders.table.customs-value'),
            column: 'unit_price'
        },
        {
            name: t?.('orders.table.expected-shipment-date'),
            column: 'totalListingCount'
        },
        // {
        //     name: t?.('orders.table.store-name'),
        //     column: 'sso_id'
        // },
        {
            name: t?.('orders.table.order-date'),
            column: 'orderId'
        },
        {
            name: t?.('orders.table.SKU'),
            column: 'sku'
        },
        {
            name: t?.('orders.table.own-note'),
            column: 'my_note'
        },
        {
            name: t?.('orders.table.shipping-method'),
            column: 'shipment_type'
        },
        // {
        //     name: t?.('orders.table.transactions'),
        //     column: 'transactions'
        // },
        // {
        //     name: t?.('orders.table.tags'),
        //     column: 'tags'
        // },
        {
            name: t?.('orders.table.img'),
            column: 'imageUrl'
        }
    ];

    const orderFormSubmit = (values: IOrdersQuery) => {
        setPage(1)
        setInputPage(1)
        const data = {
            ...(values?.countryId && { country: values?.countryId }),
            ...(values?.status && { status: values?.status }),
            ...(values?.startDate && { startDate: values?.startDate }),
            ...(values?.endDate && { endDate: values?.endDate }),
        }

        const query = Object.entries(data)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
        // Yönlendirme yap
        const pageRegex = /(\?|&)page=\d+/g;
        const matchPage = urlPage.match(pageRegex);
        if (query) {
            if (matchPage) {
                const newUrlPage = urlPage.replace(`${matchPage[0]}&`, '');
                setQueryString(`${newUrlPage}`)
                navigate(`?page=1&${query}`)
            } else {
                setQueryString(`${query}`)
                navigate(`?page=1&${query}`);
            }
        }
    };

    const getOrders = () => {
        setIsLoading(true)
        shipentegraService.getOrdersPageData(urlPage)
            .then((res) => {
                setOrders(res.data.orders);
                setFilteredData(res.data.orders);
                setError(false)
            }).catch((err) => {
                setError(true)
                if (err.response.data.message === 'Kullanıcıya ait entegrasyon bulunmamaktadır.') {
                    alertNotification('error', 'Kullanıcıya ait entegrasyon bulunmamaktadır.')
                    setError(err.response.data.message)
                }
            }).finally(() => {
                setIsLoading(false)
            })
    }
    const calculateTotalPageCount = (totalDataCount: number) => {
        setTotalPageCount(Math.ceil(totalDataCount / 150))
    }
    const getTotalOrderCount = () => {
        setIsLoading(true)
        shipentegraService.getTotalOrderCount(urlPage)
            .then((res) => {
                setTotalOrderCount(res.data);
                calculateTotalPageCount(res.data)
            }).catch((err) => {
                console.log(err)
            }).finally(() => {
                setIsLoading(false)
            })
    }


    useEffect(() => {
        getTotalOrderCount()
        getOrders()
    }, [urlPage, page])

    useEffect(() => {
        const temp = orders?.filter((item: any) => {
            return item.name.toLowerCase().includes(value.toLowerCase());
        });
        setFilteredData(temp);
    }, [value, orders]);

    let content = <>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <ShipEntegraOrdersForm
                countries={countries?.data?.data!}
                orderFormSubmit={orderFormSubmit}
                setQueryString={setQueryString}
            />
        </ErrorBoundary>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <ShipEntegraCreateTicket
                query={query}
                setQuery={setQuery}
                setOpenCreateTicket={setOpenCreateTicket}
                selectedOrderIds={selectedOrderIds}
                selectedOrderDetails={selectedOrderDetails}
            />
        </ErrorBoundary>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            {isLoading ? (
                <DotLoaderSpinner loading={isLoading} />
            ) : error === 'Kullanıcıya ait entegrasyon bulunmamaktadır.' ?
                <div className="flex flex-col space-y-3 w-full shadow-lg rounded-lg bg-white p-4 border border-[#0EA5E9] text-sm">
                    <p>Kullanıcıya ait entegrasyon bulunmamaktadır.</p>
                </div>
                : orders.length > 0 ? (
                    <>

                        <ShipEntegraOrdersTable
                            selectedOrders={selectedOrders}
                            setSelectedOrders={setSelectedOrders}
                            selectedOrderIds={selectedOrderIds}
                            setSelectedOrderIds={setSelectedOrderIds}
                            selectedOrderDetails={selectedOrderDetails}
                            setSelectedOrderDetails={setSelectedOrderDetails}
                            page={page}
                            setPage={setPage}
                            data={filteredData!}
                            totalPageCount={totalPageCount}
                            tableHeader={tableHeader}
                            isLoading={isLoading}
                            inputPage={inputPage}
                            setInputPage={setInputPage}
                            queryString={queryString}
                            openCreateTicket={openCreateTicket}
                            setOpenCreateTicket={setOpenCreateTicket}
                            setOpenArrangeOrder={setOpenArrangeOrder}
                            openArrangeOrder={openArrangeOrder}
                            getOrders={getOrders}
                        />
                    </>

                ) : (
                    <ProductNotFound />
                )}
        </ErrorBoundary>
    </>
    return (
        <div>
            <Suspense fallback={<BannerLoading />}>
                <NewBanner
                    bgUrl={'/images/shipentegra-banner-bg.webp'}
                    toolImageUrl="shipentegra-orders"
                />
                <ShipEntegraLoginRegister />
                {session?.hasShipEntegraInt ?
                    <>
                        {content}
                    </>
                    :
                    <div className='blur-sm h-full w-full'>
                        {content}
                    </div>
                }
            </Suspense>
        </div>
    )
}

export default ShipEntegraMyOrders