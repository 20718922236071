import { Formik, useFormikContext, useFormik } from 'formik';
import * as Yup from 'yup';
import { errorElement } from '../../utils/errorElement';
import React, { useEffect, useState } from 'react';
import { Calculator } from 'iconsax-react';
import { Information } from 'iconsax-react';
import { Tooltip } from 'react-tooltip';
import { useLanguage } from '../../context/LanguageContext';

interface TrendyolProfitFormProps {
  onSubmit: (values: any) => Promise<void>;
}

const AliexpressCalculateProfitForm: React.FC<TrendyolProfitFormProps> = ({
  onSubmit,
}) => {
  const { language, t } = useLanguage();
  const formik = useFormik({
    initialValues: {
      price: '',
      productCost: 0,
      adsCost: 0,
      discount: 0,
      laborCost: 0,
      packageCost: 0,
      shippingWeight: 0,
      type: 'percent',
    },
    onSubmit: onSubmit,
    validationSchema: Yup.object().shape({
      price: Yup.number()
        .required(t?.('formFilter.aliFormPrice'))
        .min(1, t?.('formFilter.aliFormPrice')),
      productCost: Yup.number().required(t?.('formFilter.aliFormProductCost')),
      adsCost: Yup.number().required(t?.('formFilter.aliFormAdsCost')),
      discount: Yup.number().min(0, t?.('formFilter.noZero')),
    }),
  });

  return (
    <>
      <form
        className="flex flex-col gap-y-[2px] py-4"
        onSubmit={formik.handleSubmit}
      >
        <h1 className="w-full border border-[059669] text-xl font-bold text-white text-center rounded-sm bg-[#059669] p-1 my-2">
          {language === 'tr' ? 'GELİR' : 'REVENUE'}
        </h1>

        {/* SELLING PRICE */}
        <div className="flex items-center">
          <div className="w-[80%] rounded-sm border-[#FCE3CF] border-[1px] px-2 py-2 shadow-sm text-xs md:text-sm bg-[#FCE3CF] mr-1 ">
            <div className="flex justify-between">
              <label htmlFor="price">
                {language === 'tr' ? 'Satış Fiyatı' : 'Sale Price'}{' '}
                <span className="text-xs">(₺)</span>{' '}
              </label>
              <Information
                size={20}
                className="cursor-pointer"
                id="priceToolTip"
              />
              <Tooltip
                anchorSelect="#priceToolTip"
                content={
                  language === 'tr'
                    ? 'Ürünün KDV dahil indirimsiz satış fiyatı'
                    : "The product's non-discounted sales price, including VAT"
                }
              />
            </div>
          </div>
          <input
            value={formik.values.price}
            onChange={formik.handleChange}
            name="price"
            id="price"
            className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 placeholder-gray-400 shadow-sm text-xs md:text-sm"
            type="number"
            placeholder="250₺"
            onWheel={(e) => {
              e.currentTarget.blur();
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 38 || e.keyCode === 40) {
                // 38 for 'Up' and 40 for 'Down'
                e.preventDefault();
              }
            }}
          />
        </div>
        {formik.errors.price && errorElement(formik.errors.price)}

        {/* SHIPPING INCOME */}
        <div className="flex items-center">
          <div className="w-[80%] rounded-sm border-[#FCE3CF] border-[1px] px-2 py-2 shadow-sm text-xs md:text-sm bg-[#FCE3CF] mr-1 ">
            <div className="flex justify-between">
              <label htmlFor="shippingWeight">
                {language === 'tr' ? 'Kargo Ağırlığı' : 'Shipping Weight'}{' '}
                <span className="text-xs">(kg)</span>
              </label>
              <Information
                size={20}
                className="cursor-pointer"
                id="shippingWeightToolTip"
              />
              <Tooltip
                anchorSelect="#shippingWeightToolTip"
                content={
                  language === 'tr'
                    ? 'Kargoya verilecek ürünün ağırlığı'
                    : 'Weight of the product to be shipped'
                }
              />
            </div>
          </div>
          <input
            value={formik.values.shippingWeight}
            onChange={formik.handleChange}
            name="shippingWeight"
            id="shippingWeight"
            className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 placeholder-gray-400 shadow-sm text-xs md:text-sm"
            type="number"
            placeholder="25₺"
            onWheel={(e) => {
              e.currentTarget.blur();
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 38 || e.keyCode === 40) {
                // 38 for 'Up' and 40 for 'Down'
                e.preventDefault();
              }
            }}
          />
        </div>

        {/* DISCOUNT PLACE */}
        <div className="flex items-center mb-2">
          <div className="w-[78%] rounded-sm border-[#FCE3CF] border-[1px] px-2 py-2 shadow-sm text-xs md:text-sm bg-[#FCE3CF] mr-1 ">
            <div className="flex justify-between text-xs">
              <label htmlFor="discount">
                {language === 'tr' ? 'İndirim / Kupon' : 'Discount / Coupon'}{' '}
                <span className="text-xs">(%/₺)</span>
              </label>
              <Information
                size={20}
                className="cursor-pointer"
                id="discountToolTip"
              />
              {language === 'tr' ? (
                <Tooltip anchorSelect="#discountToolTip">
                  Satış fiyatı üzerinden uygulanacak sabit ya da yüzdesel <br />{' '}
                  indirim / kupon miktarı
                </Tooltip>
              ) : (
                <Tooltip anchorSelect="#discountToolTip">
                  Fixed or percentage to be applied over the sales price <br />{' '}
                  discount/coupon amount
                </Tooltip>
              )}
            </div>
          </div>
          <div className="flex w-full items-center pr-2">
            <input
              onWheel={(e) => {
                e.currentTarget.blur();
              }}
              onKeyDown={(e) => {
                if (e.keyCode === 38 || e.keyCode === 40) {
                  // 38 for 'Up' and 40 for 'Down'
                  e.preventDefault();
                }
              }}
              min={0}
              type="number"
              name="discount"
              id="discount"
              max={formik.values.type === 'percent' ? 100 : undefined}
              onChange={formik.handleChange}
              value={formik.values.discount}
              placeholder="25"
              className="rounded-sm border border-[#FCE3CF] px-2 py-2 shadow-sm text-xs min-[1335px]:text-sm mr-1 bg-white w-4/12"
            />
            <div
              className="flex justify-center items-center w-4/12 ml-2"
              role="group"
            >
              <input
                name="type"
                type="radio"
                defaultChecked={true}
                onChange={formik.handleChange}
                value="percent"
                className="h-4 w-4 border-gray-300 text-cardBorder focus:ring-cardBorder"
              />
              <label
                htmlFor={'type'}
                className="ml-1 block text-xs min-[1335px]:text-[11px] font-normal min-[1335px]:font-medium  leading-6 text-gray-900 mr-3"
              >
                {language === 'tr' ? ' Yüzde' : 'Percantage'}
              </label>
            </div>
            <div className="flex justify-center items-center w-4/12">
              <input
                name="type"
                type="radio"
                value="amount"
                onChange={formik.handleChange}
                className="h-4 w-4 border-gray-300 text-cardBorder focus:ring-cardBorder"
              />
              <label
                htmlFor={'type'}
                className="ml-1 block text-xs min-[1335px]:text-[11px] font-normal min-[1335px]:font-medium  leading-6 text-gray-900"
              >
                {language === 'tr' ? 'Sabit' : 'Fixed'}
              </label>
            </div>
          </div>
        </div>

        <h1 className="w-full border border-[#DB2777] text-xl font-bold text-white text-center rounded-sm bg-[#DB2777] p-1 mb-2">
          {language === 'tr' ? 'MALİYET' : 'COST'}
        </h1>

        {/* PRODUCT COST */}
        <div className="flex items-center">
          <div className="w-[80%] rounded-sm border-[#FCE3CF] border-[1px] px-2 py-2 shadow-sm text-xs md:text-sm bg-[#FCE3CF] mr-1 ">
            <div className="flex justify-between">
              <label htmlFor="productCost">
                {language === 'tr' ? 'Ürün Maliyeti' : 'Product Cost'}{' '}
                <span className="text-xs">(₺)</span>
              </label>
              <Information
                size={20}
                className="cursor-pointer"
                id="productCostToolTip"
              />
              <Tooltip
                anchorSelect="#productCostToolTip"
                content={
                  language === 'tr'
                    ? 'Ürünün KDV dahil alış maliyeti'
                    : 'Purchase cost of the product including VAT'
                }
              />
            </div>
          </div>
          <input
            value={formik.values.productCost}
            onChange={formik.handleChange}
            name="productCost"
            id="productCost"
            className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 placeholder-gray-400 shadow-sm text-xs md:text-sm"
            type="number"
            placeholder="50₺"
            onWheel={(e) => {
              e.currentTarget.blur();
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 38 || e.keyCode === 40) {
                // 38 for 'Up' and 40 for 'Down'
                e.preventDefault();
              }
            }}
          />
        </div>
        {formik.errors.productCost && errorElement(formik.errors.productCost)}

        {/* ISCILIK MALIYETI */}
        <div className="flex items-center">
          <div className="w-[80%] rounded-sm border-[#FCE3CF] border-[1px] px-2 py-2 shadow-sm text-xs md:text-sm bg-[#FCE3CF] mr-1 ">
            <div className="flex justify-between">
              <label htmlFor="laborCost">
                {language === 'tr' ? 'İşçilik Maliyeti' : 'Labor Cost'}{' '}
                <span className="text-xs">(₺)</span>
              </label>
              <Information
                size={20}
                className="cursor-pointer"
                id="laborCostToolTip"
              />
              <Tooltip
                anchorSelect="#laborCostToolTip"
                content={
                  language === 'tr'
                    ? 'Ürünün üretiminde kullanılan işgücü maliyeti'
                    : 'Labor cost used in the production of the product'
                }
              />
            </div>
          </div>
          <input
            value={formik.values.laborCost}
            onChange={formik.handleChange}
            name="laborCost"
            id="laborCost"
            className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 placeholder-gray-400 shadow-sm text-xs md:text-sm"
            type="number"
            placeholder="50₺"
            onWheel={(e) => {
              e.currentTarget.blur();
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 38 || e.keyCode === 40) {
                // 38 for 'Up' and 40 for 'Down'
                e.preventDefault();
              }
            }}
          />
        </div>

        {/* PAKETLEME MALIYETI */}
        <div className="flex items-center">
          <div className="w-[80%] rounded-sm border-[#FCE3CF] border-[1px] px-2 py-2 shadow-sm text-xs md:text-sm bg-[#FCE3CF] mr-1 ">
            <div className="flex justify-between">
              <label htmlFor="packageCost">
                {language === 'tr' ? 'Paketleme Maliyeti' : 'Packaging Cost'}{' '}
                <span className="text-xs">(₺)</span>
              </label>
              <Information
                size={20}
                className="cursor-pointer"
                id="packageCostToolTip"
              />
              {language === 'tr' ? (
                <Tooltip anchorSelect="#packageCostToolTip">
                  Ürünün ambalajlama veya paketlenmesi için harcanan <br /> KDV
                  dahil maliyet
                </Tooltip>
              ) : (
                <Tooltip anchorSelect="#packageCostToolTip">
                  VAT included for packaging or wrapping of the product cost
                </Tooltip>
              )}
            </div>
          </div>
          <input
            value={formik.values.packageCost}
            onChange={formik.handleChange}
            name="packageCost"
            id="packageCost"
            className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 placeholder-gray-400 shadow-sm text-xs md:text-sm"
            type="number"
            placeholder="50₺"
            onWheel={(e) => {
              e.currentTarget.blur();
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 38 || e.keyCode === 40) {
                // 38 for 'Up' and 40 for 'Down'
                e.preventDefault();
              }
            }}
          />
        </div>

        {/* AD COST */}
        <div className="flex items-center">
          <div className="w-[80%] rounded-sm border-[#FCE3CF] border-[1px] px-2 py-2 shadow-sm text-xs md:text-sm bg-[#FCE3CF] mr-1 ">
            <div className="flex justify-between">
              <label htmlFor="adsCost">
                {language === 'tr' ? 'Reklam Maliyeti' : 'Advertising Cost'}{' '}
                <span className="text-xs">(₺)</span>
              </label>
              <Information
                size={20}
                className="cursor-pointer"
                id="adCostToolTip"
              />
              <Tooltip
                anchorSelect="#adCostToolTip"
                content={
                  language === 'tr'
                    ? "Ürünün tanıtımı için Aliexpress'e ödenen maliyet"
                    : 'The cost paid to Aliexpress for the promotion of the product'
                }
              />
            </div>
          </div>
          <input
            value={formik.values.adsCost}
            onChange={formik.handleChange}
            name="adsCost"
            id="adsCost"
            className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 placeholder-gray-400 shadow-sm text-xs md:text-sm"
            type="number"
            placeholder="0₺"
            onWheel={(e) => {
              e.currentTarget.blur();
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 38 || e.keyCode === 40) {
                // 38 for 'Up' and 40 for 'Down'
                e.preventDefault();
              }
            }}
          />
        </div>
        {formik.errors.adsCost && errorElement(formik.errors.adsCost)}

        <button
          className="mt-2 mb-2 text-white font-bold rounded-md  bg-headerPrimary w-full py-2.5 px-2 flex items-center justify-center"
          type="submit"
        >
          <span className="mr-2">
            {language === 'tr' ? 'Hesapla' : 'Calculate'}{' '}
          </span>
          <Calculator size="24" color="#fff" />
        </button>
      </form>
    </>
  );
};

export default AliexpressCalculateProfitForm;
