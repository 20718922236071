import React, { useEffect, useState } from 'react'
import {
    WalletAdd,
    PercentageSquare,
    WalletMinus,
    Wallet,
    ReceiptAdd,
    ReceiptMinus,
} from 'iconsax-react';
import CustomCardChart from './CustomCardChart';
import { CardTitle } from './SellerDashboardCardGroups';
import { DaysDataType, FinancialData, RevenueData } from '../../DummyDatasAndTypes';
import { seperateNumberWithComma } from '../../../../utils/numberFormatter';

type DataKey = keyof RevenueData;
interface TotalTurnoverCardProps {
    cardTitles: CardTitle[]
    colors: {
        color: string,
        textColor: string,
        valueColor: string

    }
    allDayData: DaysDataType
}
const TotalTurnoverCard: React.FC<TotalTurnoverCardProps> = ({ cardTitles, colors, allDayData,
}) => {
    const [cardData, setCardData] = useState({} as { [title: string]: FinancialData });
    const [totalTurnoverCardInfo, setTotalTurnoverCardInfo] = useState([
        {
            name: WalletAdd,
            iconColor: "#60A5FA",
            backgroundColor: "#DBEAFE",
            amountType: "Gelir",
            key: 'revenue' as DataKey
        },
        {
            name: WalletMinus,
            iconColor: "#F87171",
            backgroundColor: "#FEE2E2",
            amountType: "Gider",
            key: 'costs' as DataKey
        },
        {
            name: Wallet,
            iconColor: "#34D399",
            backgroundColor: "#D1FAE5",
            amountType: "Kar/Zarar",
            key: 'profitOrLoss' as DataKey
        },
        {
            name: PercentageSquare,
            iconColor: "#A78BFA",
            backgroundColor: "#EDE9FE",
            amountType: "Kar Marjı",
            key: 'profitMargin' as DataKey
        },
        {
            name: ReceiptAdd,
            iconColor: "#22D3EE",
            backgroundColor: "#CFFAFE",
            amountType: "Sipariş Sayısı",
            key: 'orderCount' as DataKey
        },
        {
            name: ReceiptMinus,
            iconColor: "#F472B6",
            backgroundColor: "#FCE7F3",
            amountType: "İade Sayısı",
            key: 'returnCount' as DataKey
        }
    ])

    const getDataForCard = (
        title: string,
        todayData: FinancialData,
        sevenDayData: FinancialData,
        thirtyDayData: FinancialData,
        ninetyDayData: FinancialData
    ): FinancialData => {
        switch (title) {
            case 'Bugün':
                return todayData;
            case 'Son 7 Gün':
                return sevenDayData;
            case 'Son 30 Gün':
                return thirtyDayData;
            case 'Son 90 Gün':
                return ninetyDayData;
            default:
                return {
                    totalRevenue: {
                        revenue: 0,
                        profitOrLoss: 0,
                        orderCount: 0,
                        costs: 0,
                        profitMargin: 0,
                        returnCount: 0,
                        saleCount: 0
                    },
                    totalCost: 0,
                    profitOrLoss: 0,
                    profitMargin: 0
                };
        }
    };
    useEffect(() => {
        let newCardData = { ...cardData };
        cardTitles.forEach(card => {
            const data = getDataForCard(card.title, allDayData.data.day, allDayData.data.week, allDayData.data.month, allDayData.data.quarter);
            newCardData[card.title] = data;
        });
        setCardData(newCardData);
    }, [allDayData, cardTitles]);


    return (
        <div className='grid grid-cols-12 gap-4 my-8'>
            {cardTitles.map((card) => {
                return (
                    <div className='col-span-12 lg:col-span-6 xl:col-span-3 bg-lightGray border rounded-md shadow-md p-2 w-full lg:w-auto min-h-[450px] flex flex-col justify-between items-center'>
                        <div className='flex flex-col justify-start w-full'>
                            <p className='font-semibold text-sm'>{card.title}</p>
                            <p className='text-xs font-thin text-cardBorder mb-2'>{card.date}</p>
                        </div>
                        <div className="flex justify-center items-center">
                            <CustomCardChart color={colors.color} textColor={colors.textColor} valueColor={colors.valueColor} fontWeight={'font-semibold'} value={cardData[card.title]?.totalRevenue?.saleCount} label={'Satılan Ürün'} />
                        </div>
                        <div className='grid grid-cols-2 gap-4 mt-4 items-center justify-center'>
                            {totalTurnoverCardInfo?.map((icon) => {
                                let amount: number | RevenueData = cardData[card.title]?.totalRevenue?.[icon.key];
                                return (
                                    <div>
                                        <div className='grid grid-cols-12 items-center justify-start'>
                                            <div className='col-span-3 p-1 flex items-center justify-center border rounded-md' style={{ backgroundColor: `${icon.backgroundColor}` }}>
                                                <icon.name className='w-7 h-7 font-black text-4xl col-span-3' style={{ color: `${icon.iconColor}` }} />
                                            </div>
                                            <div className='col-span-9 ml-2'>
                                                {allDayData?.loading ?
                                                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                                        <path className="opacity-75 text-[#2D356C]" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                    </svg>
                                                    :
                                                    <span className={`text-sm text-[#2D356C] font-medium`}>{seperateNumberWithComma(amount)}{icon.key === 'profitMargin' ? ' %' :
                                                        icon.key === 'returnCount' ? '' :
                                                            icon.key === 'orderCount' ? '' :
                                                                ' ₺'}</span>
                                                }
                                                <p className='text-xs font-medium text-cardBorder'>{icon.amountType}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div >
                )
            })}
        </div>

    )
}


export default TotalTurnoverCard