import React, { lazy, Suspense, useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import LoadingComponent from '../components/common/LoadingComponent';
import ProductNotFound from '../components/common/ProductNotFound';
import BannerLoading from '../components/tools/common/BannerLoading';
import BuyModal from '../components/tools/etsy/BuyModal';
import HotFilterForm from '../components/tools/etsy/HotFilterForm';
import SellerTable from '../components/tools/etsy/SellerTable';
import { useAuth } from '../context/AuthContext';
import { useLanguage } from '../context/LanguageContext';
import {
    ICategoryRoot, ICountryRoot, IHotSellerRoot, IHotSellersQuery, IProductTypeRoot
} from '../interfaces/etsyToolsInterfaces';
import etsyToolsService from '../services/etsyToolsService';
import memberService from '../services/memberService';
import ErrorFallback from './errors/ErrorFallback';

const NewBanner = lazy(() => import('../components/tools/common/NewBanner'));

const EtsyHotSellers = () => {
  const { t } = useLanguage();
  const [page, setPage] = useState(1);
  const [sortQuery, setSortQuery] = useState<{
    orderBy: string;
    order: string;
  }>({
    order: 'DESC',
    orderBy: 'avgSales',
  });
  const [queryParams, setQueryParams] = useState<IHotSellersQuery>({
    etsyCountryId: '',
    createDate: '',
    taxonomyId: '',
    etsyProductTypeId: '',
    totalSaleCountMin: '',
    totalSaleCountMax: '',
    // dailySalesMin: '',
    // dailySalesMax: '',
    avgSalesMin: '',
    avgSalesMax: '',
    reviewCountMin: '',
    reviewCountMax: '',
    totalListingCountMin: '',
    totalListingCountMax: '',
    shopName: ''
  });
  const [inputPage, setInputPage] = useState<number | string>(1);
  const [queryString, setQueryString] = useState('?page=1');
  const [isTabActive, setIsTabActive] = React.useState<boolean>(true);

  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  // const urlPage = location.search;
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const { session } = useAuth();

  const tableHeader = [
    { name: t?.('etsy-top-sellers.table.no'), column: 'id', isButton: false },
    {
      name: t?.('etsy-top-sellers.table.logo'),
      column: 'icon',
      isButton: false,
    },
    {
      name: t?.('etsy-top-sellers.table.storeName'),
      column: 'shopName',
      isButton: false,
    },
    {
      name: t?.('etsy-top-sellers.table.country'),
      column: 'countryId',
      isButton: false,
    },
    {
      name: t?.('etsy-top-sellers.table.storeAge'),
      column: 'createDate',
      isButton: true,
    },
    {
      name: t?.('etsy-top-sellers.table.category'),
      column: 'categoryId',
      isButton: false,
    },
    {
      name: t?.('etsy-top-sellers.table.productType'),
      column: 'productTypeId',
      isButton: false,
    },
    {
      name: t?.('etsy-top-sellers.table.totalSales'),
      column: 'totalSaleCount',
      isButton: true,
    },
    // { name: 'Satış/Gün', column: 'dailySales', isButton: true },
    {
      name: t?.('etsy-top-sellers.table.avgDailySales'),
      column: 'avgSales',
      isButton: true,
    },
    {
      name: t?.('etsy-top-sellers.table.totalReview'),
      column: 'reviewCount',
      isButton: true,
    },
    {
      name: t?.('etsy-top-sellers.table.reviewPoint'),
      column: 'reviewAverage',
      isButton: false,
    },
    {
      name: t?.('etsy-top-sellers.table.totalListing'),
      column: 'totalListingCount',
      isButton: true,
    },
    {
      name: t?.('etsy-top-sellers.table.storeFallower'),
      column: 'numFavorers',
      isButton: false,
    },
  ];

  const onSubmit = async (values: any) => {
    if (!session?.hasPackage) {
      setOpen(true);
    } else {
      // setQueryParams(values);
      navigate(
        `?page=1&countryId=${values?.etsyCountryId}&createDate=${values?.createDate}&taxonomyId=${values?.taxonomyId}&etsyProductTypeId=${values?.etsyProductTypeId}&totalSaleCountMin=${values?.totalSaleCountMin}&totalSaleCountMax=${values?.totalSaleCountMax}&avgSalesMin=${values?.avgSalesMin}&avgSalesMax=${values?.avgSalesMax}&reviewCountMin=${values?.reviewCountMin}&reviewCountMax=${values?.reviewCountMax}&totalListingCountMin=${values?.totalListingCountMin}&totalListingCountMax=${values?.totalListingCountMax}&orderBy=${sortQuery.orderBy}&order=${sortQuery.order}&shopName=${values?.shopName}`
      );
      // setQueryString(
      //   `countryId=${values?.etsyCountryId}&createDate=${values?.createDate}&taxonomyId=${values?.taxonomyId}&etsyProductTypeId=${values?.etsyProductTypeId}&totalSaleCountMin=${values?.totalSaleCountMin}&totalSaleCountMax=${values?.totalSaleCountMax}&avgSalesMin=${values?.avgSalesMin}&avgSalesMax=${values?.avgSalesMax}&reviewCountMin=${values?.reviewCountMin}&reviewCountMax=${values?.reviewCountMax}&totalListingCountMin=${values?.totalListingCountMin}&totalListingCountMax=${values?.totalListingCountMax}&orderBy=${sortQuery.orderBy}&order=${sortQuery.order}`
      // );
      setPage(1);
      // refetch();
    }
  };

  const { isLoading, error, data, refetch } = useQuery<IHotSellerRoot, Error>(
    ['hotSellers', { page, sortQuery, queryParams }],
    {
      queryFn: () =>
        etsyToolsService.getHotSellers(page, sortQuery, queryParams!),

      cacheTime: 6000000,
      refetchOnWindowFocus: false,
    }
  );

  // GET CATEGORIES
  const categories = useQuery<ICategoryRoot, Error>(['etsyCategories'], {
    queryFn: () => etsyToolsService.getCategories(),
    staleTime: Infinity,
  });

  //GET PRODUCT TYPES
  const productTypes = useQuery<IProductTypeRoot, Error>(['etsyProductTypes'], {
    queryFn: () => etsyToolsService.getProductTypes(),
    staleTime: Infinity,
  });

  //GET COUNTRIES
  const countries = useQuery<ICountryRoot, Error>(['etsyCountries'], {
    queryFn: () => etsyToolsService.getCountries(),
    staleTime: Infinity,
  });

  useEffect(() => {
    let temp = Object.fromEntries([...searchParams]);
    let tempPage = temp.page;
    if (searchParams.has('countryId')) {
      setPage(Number(tempPage));
      setInputPage(Number(tempPage));
      setQueryParams({ ...temp, etsyCountryId: temp.countryId });
      setQueryString(
        `countryId=${temp?.countryId}&createDate=${temp?.createDate}&taxonomyId=${temp?.taxonomyId}&etsyProductTypeId=${temp?.etsyProductTypeId}&totalSaleCountMin=${temp?.totalSaleCountMin}&totalSaleCountMax=${temp?.totalSaleCountMax}&avgSalesMin=${temp?.avgSalesMin}&avgSalesMax=${temp?.avgSalesMax}&reviewCountMin=${temp?.reviewCountMin}&reviewCountMax=${temp?.reviewCountMax}&totalListingCountMin=${temp?.totalListingCountMin}&totalListingCountMax=${temp?.totalListingCountMax}&orderBy=${sortQuery.orderBy}&order=${sortQuery.order}&shopName=${temp?.shopName}`
      );
    } else if (!searchParams.has('countryId') && searchParams.has('page')) {
      setPage(Number(tempPage));
      setInputPage(Number(tempPage));
      setQueryParams({
        etsyCountryId: '',
        createDate: '',
        taxonomyId: '',
        etsyProductTypeId: '',
        totalSaleCountMin: '',
        totalSaleCountMax: '',
        avgSalesMax: '',
        avgSalesMin: '',
        reviewCountMin: '',
        reviewCountMax: '',
        totalListingCountMin: '',
        totalListingCountMax: '',
        shopName: ''
      });
    } else {
      //hiçb,şey yoksa
      setPage(1);
      setInputPage(1);
      setQueryParams({
        etsyCountryId: '',
        createDate: '',
        taxonomyId: '',
        etsyProductTypeId: '',
        totalSaleCountMin: '',
        totalSaleCountMax: '',
        avgSalesMax: '',
        avgSalesMin: '',
        reviewCountMin: '',
        reviewCountMax: '',
        totalListingCountMin: '',
        totalListingCountMax: '',
        shopName: ''
      });
    }
  }, [page, location.search]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabActive(document.visibilityState === 'visible');
    };

    // Add event listener for visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup function to remove the event listener when the component is unmounted
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    let intervalId: any;
    if (session && isTabActive) {
      intervalId = setInterval(async () => {
        await memberService.userTrackReq(`etsy-hot-sellers`, session?.id!);
      }, 60000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [session, isTabActive]);
  return (
    <div>
      <BuyModal open={open} setOpen={setOpen} />
      <Suspense fallback={<BannerLoading />}>
        <NewBanner
          bgUrl={'/images/etsy-hot-sellers-bg.webp'}
          toolImageUrl="etsy-hot-sellers"
        />
      </Suspense>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <HotFilterForm
          onSubmit={onSubmit}
          categories={categories?.data?.data!}
          productTypes={productTypes?.data?.data!}
          countries={countries?.data?.data!}
        />
        {isLoading ? (
          <LoadingComponent />
        ) : data?.data.sellers.length! > 0 ? (
          <SellerTable
            countries={countries.data?.data!}
            categories={categories.data?.data!}
            productTypes={productTypes.data?.data!}
            page={page}
            setPage={setPage}
            data={data?.data.sellers!}
            totalPageCount={data?.data.totalPageCount!}
            tableHeader={tableHeader}
            isLoading={isLoading}
            setSortQuery={setSortQuery}
            setOpen={setOpen}
            inputPage={inputPage}
            setInputPage={setInputPage}
            queryString={queryString}
          />
        ) : (
          <ProductNotFound />
        )}
      </ErrorBoundary>
    </div>
  );
};

export default EtsyHotSellers;
