import React, { useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';

import { useAuth } from '../../context/AuthContext';
import { useLanguage } from '../../context/LanguageContext';
import { useNumberVerification } from '../../context/NumberVerificationContext';
import memberService from '../../services/memberService';
import producerService from '../../services/producerService';
import alertNotification from '../../utils/alertNotification';
import FullRegisterAsSeller from './FullRegisterAsSeller';
import RegisterAsSellerModal from './RegisterAsSellerModal';

const SaleRegister = () => {
  const { t } = useLanguage();
  const [open, setOpen] = useState(false);
  const [producerName, setProducerName] = useState('');
  const [loading, setLoading] = useState(false);

  const { session, checkSessionStatus } = useAuth();
  const { setNumVerifyModal } = useNumberVerification();
  const handleClick = () => {
    if (!session?.isGsmVerified) {
      setNumVerifyModal?.(true);
    } else {
      setOpen(true);
    }
  };

  // Seller null form submit
  const fullRegisterSubmit = async (values: any) => {
    setLoading(true);
    let { discountRate, apiSecret, apiKey, sellerId } = values;

    memberService
      .registerAsTrendyolSeller({
        platform: 'trendyol',
        apiKey,
        apiSecret,
        sellerId,
        discountRate: 50,
        isAccepted: true,
      })
      .then((res) => {
        alertNotification('succes', 'Mağazanız Başarıyla Eklendi');
        checkSessionStatus!();
        // });
      })
      .catch((err) => {
        alertNotification('error', err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Seller Phone Number and DiscountRate Forum -- Registiration (status->passive)
  const onSubmit = async (values: any) => {
    let { discountRate, isKvkkAccepted } = values;

    const data = {
      discountRate: 50,
      isKvkkAccepted: isKvkkAccepted,
    };

    producerService
      .updateIntegrationtoActive(data)
      .then((res) => {
        alertNotification('success', res.message);
        setTimeout(() => {
          checkSessionStatus!();
        }, 1500);
      })
      .catch((err) => {
        console.log(err);
        alertNotification('error', err.response.message);
      });
  };

  const getProducerById = async () => {
    // If session has producerId, with this request set producerName to use in
    try {
      if (session?.producerId) {
        const res = await producerService.getProducerById(session?.producerId!);
        setProducerName(res.data.producerName);
      }
    } catch (error: any) {
      // alertNotification('error', error.response.data.message);
    }
  };

  useEffect(() => {
    getProducerById();
  }, []);

  return (
    <div className="">
      <ToastContainer />
      {session?.producerStatus === 'active' ? (
        <button className="text-sm lg:text-sm w-full border-2 py-2 text-white bg-headerPrimary border-headerPrimary rounded-md">
          {producerName}
        </button>
      ) : (
        <button
          onClick={handleClick}
          className="text-xs lg:text-sm w-full border-2 py-2  xl:px-4 text-white bg-headerPrimary border-headerPrimary rounded-md"
        >
          {t?.('manufacturers-and-wholesalers.registerWholeSaler')}
        </button>
      )}

      {!session?.isSeller && (
        // {session?.producerStatus === 'passive' ?

        <FullRegisterAsSeller
          loading={loading}
          open={open}
          setOpen={setOpen}
          onSubmit={fullRegisterSubmit}
        />
      )}
    </div>
  );
};

export default SaleRegister;
