import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../errors/ErrorFallback';
import LoginForm from '../../components/authForms/LoginForm';
import ResetPasswordForm from '../../components/authForms/ResetPasswordForm';


export default function ResetPassword() {
    return (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
            <ResetPasswordForm />
        </ErrorBoundary>
    )
}
