import React, { Suspense, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import BannerLoading from '../components/tools/common/BannerLoading';
import NewBanner from '../components/tools/common/NewBanner';
import { useAuth } from '../context/AuthContext';
import { triggerPurchaseEvent } from '../utils/gtagManager';

const ThanksPage = () => {
  const { session } = useAuth();
  const location = useLocation();
  const [discount, setDiscount] = useState<number>(0);
  const searchParams = new URLSearchParams(location.search);
  const coupon = searchParams.get('coupon');
  const value = parseFloat(searchParams.get('basePrice') || '');
  const discountedPrice = parseFloat(searchParams.get('discountedPrice') || '');
  const itemId = searchParams.get('itemId');
  const itemName = searchParams.get('itemName');
  const currentTimeInMilliseconds = Date.now();
  const transactionId = `${currentTimeInMilliseconds}-${session?.id}`;
  useEffect(() => {
    if (!isNaN(value) && !isNaN(discountedPrice) && value !== 0) {
      const calculatedDiscount = (value - discountedPrice) / value;
      setDiscount(calculatedDiscount * 100);
    } else {
      setDiscount(0);
    }
  }, [value, discountedPrice]);
  useEffect(() => {
    triggerPurchaseEvent(
      'purchase',
      coupon,
      itemId,
      itemName,
      discount,
      value,
      transactionId
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Suspense fallback={<BannerLoading />}>
        <NewBanner
          bgUrl={'/images/rexven-exclusive-bg.webp'}
          toolImageUrl="thanks-page"
        />
      </Suspense>

      <h1 className="my-20 text-center text-lg font-medium text-secondary">
        Başlamadan önce videoyu izleyiniz...
      </h1>
      <div className="my-20 w-[90%] md:w-[80%]   mx-auto ">
        <div
          style={{
            width: '100%',
            padding: '56.25% 0 0 0',
            position: 'relative',
          }}
        >
          <iframe
            title="Roadmap"
            className="rounded-lg shadow-xl"
            src="https://player.vimeo.com/video/865058706?h=c73d7e1871&title=0&byline=0&portrait=0"
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              width: '100%',
              height: '100%',
            }}
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default ThanksPage;
