import { Location } from 'iconsax-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useDebounce } from 'use-debounce';

import { ChevronDoubleUpIcon } from '@heroicons/react/20/solid';

import DotLoaderSpinner from '../components/common/DotLoaderSpinner';
import ProductNotFound from '../components/common/ProductNotFound';
import RatingStars from '../components/common/RatingStars';
import MessageModal from '../components/productsAndWholeSalers/MessageModal';
import ProductCart from '../components/productsAndWholeSalers/ProductCart';
import ProductsWrapper, {
  Products,
} from '../components/productsAndWholeSalers/ProductsWrapper';
import SaleRegister from '../components/productsAndWholeSalers/SaleRegister';
import { TurkishCitiesForSupliers } from '../components/shipentegraCreatePost/elements/states';
import { useLanguage } from '../context/LanguageContext';
import producerService from '../services/producerService';
import productService from '../services/productService';
import alertNotification from '../utils/alertNotification';
import { classNames } from '../utils/conditionalClasses';
import ErrorFallback from './errors/ErrorFallback';

interface City {
  value: string;
}
type PriceRange = {
  key: string;
  label: string;
};
const turkishCities: City[] = TurkishCitiesForSupliers;
const priceRanges: PriceRange[] = [
  { key: '0-1000', label: '0 - 1.000 ₺' },
  { key: '1000-5000', label: '1.000 ₺ - 5.000 ₺' },
  { key: '5000-10000', label: '5.000 ₺ - 10.000 ₺' },
  { key: '10000-50000', label: '10.000 ₺ - 50.000 ₺' },
  { key: '50000-', label: '50.000 ₺ ve üzeri' },
];

const SupplierProducts = () => {
  const { t } = useLanguage();
  const [searchParams, setSearchParams] = useSearchParams();
  const categories = [
    {
      id: 1,
      categoryName: 'Tüm Kategoriler',
    },
    {
      id: 368,
      categoryName: 'Aksesuar',
    },
    {
      id: 403,
      categoryName: 'Ayakkabı',
    },
    {
      id: 522,
      categoryName: 'Giyim',
    },
    {
      id: 685,
      categoryName: 'Hobi & Eğlence',
    },
    // {
    //     "id": 687,
    //     "categoryName": "Kitap"
    // },
    {
      id: 758,
      categoryName: 'Ev & Mobilya',
    },
    {
      id: 790,
      categoryName: 'Otomobil & Motosiklet',
    },
    {
      id: 1070,
      categoryName: 'Kozmetik & Kişisel Bakım',
    },
    {
      id: 1071,
      categoryName: 'Elektronik',
    },
    {
      id: 1216,
      categoryName: 'Kırtasiye & Ofis Malzemeleri',
    },
    {
      id: 1219,
      categoryName: 'Süpermarket',
    },
    {
      id: 2862,
      categoryName: 'Anne & Bebek & Çocuk',
    },
    {
      id: 3186,
      categoryName: 'Spor & Outdoor',
    },
    // {
    //     "id": 3981,
    //     "categoryName": "Ek Hizmetler"
    // },
    {
      id: 5558,
      categoryName: 'Bahçe & Elektrikli El Aletleri',
    },
    {
      id: 5559,
      categoryName: 'Banyo Yapı & Hırdavat',
    },
  ];
  const [selectedCategory, setSelectedCategory] = useState<number>(
    parseInt(searchParams.get('selectedCategory') || '1')
  );
  const [selectedLocations, setSelectedLocations] = useState<string[]>(() => {
    const locationParam = searchParams.get('location');
    return locationParam ? locationParam.split(',') : [];
  });

  const [page, setPage] = useState(parseInt(searchParams.get('page') || '1'));
  const [keyword, setKeyword] = useState(searchParams.get('keyword') || '');
  const [minPrice, setMinPrice] = useState<string>(
    searchParams.get('minPrice') || '0'
  );
  const [maxPrice, setMaxPrice] = useState<string>(
    searchParams.get('maxPrice') || '0'
  );
  const [minReviewCount, setMinReviewCount] = useState<number>(
    parseInt(searchParams.get('minReviewCount') || '')
  );
  const [maxReviewCount, setMaxReviewCount] = useState<number>(
    parseInt(searchParams.get('maxReviewCount') || '')
  );
  const [minRating, setMinRating] = useState<string>(
    searchParams.get('minRating') || ''
  );
  const [maxRating, setMaxRating] = useState<string>(
    searchParams.get('maxRating') || ''
  );
  const [orderBy, setOrderBy] = useState('reviewCount');
  const [order, setOrder] = useState('DESC');
  const [selectedRanges, setSelectedRanges] = useState<Record<string, boolean>>(
    {}
  );

  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [products, setProducts] = useState<Products[]>([]);
  const [prTotalPage, setPrTotalPage] = useState(4);

  const observer = useRef<IntersectionObserver | null>(null);
  const lastProductRef = useCallback(
    (node: HTMLElement | null) => {
      if (loading) return;
      if (observer.current) observer?.current?.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          setPage((prevPage) => prevPage + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, hasMore]
  );
  const [open, setOpen] = React.useState(false);
  const [productId, setProductId] = React.useState<number | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const navigate = useNavigate();

  const handleKeywordSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setKeyword(String(inputRef.current?.value));
    setPage(1);
    setProducts([]);
  };

  const handleFilterSubmit = () => {
    setPage(1);
    setProducts([]);
    getProducts();
  };

  const getProducts = async () => {
    setLoading(true);
    setError(false);
    try {
      const res = await productService.getProducts(
        page,
        keyword,
        orderBy,
        order,
        selectedCategory,
        selectedLocations,
        +minPrice,
        +maxPrice,
        +minRating,
        +maxRating,
        +minReviewCount,
        +maxReviewCount
      );

      setProducts((prevProducts: Products[]) => {
        return [...prevProducts, ...res.data.products];
      });

      setHasMore(page < res.data.totalPageCount);
      setPrTotalPage(res.data.totalPageCount);
      setLoading(false);
    } catch (error: any) {
      setError(true);
      alertNotification('error', error.response.data.message);
    }
  };

  useEffect(() => {
    getProducts();
  }, [selectedCategory, keyword, page]);

  const updateProducerStats = (productId: number, incrementType?: string) => {
    producerService
      .productStatistics({ productId })
      .then((res) => {
        // console.log(res);
      })
      .catch((res) => {
        console.log(res);
      });
  };

  const updateSearchParams = useCallback(() => {
    const params = {
      page: page.toString(),
      keyword: keyword,
      selectedCategory: selectedCategory.toString(),
      location: selectedLocations.join(','),
      minPrice: minPrice,
      maxPrice: maxPrice,
      minReviewCount: minReviewCount.toString(),
      maxReviewCount: maxReviewCount.toString(),
      minRating: minRating,
      maxRating: maxRating,
      orderBy: orderBy,
      order: order,
    };
    setSearchParams(params);
  }, [
    keyword,
    selectedCategory,
    selectedLocations,
    minPrice,
    maxPrice,
    minReviewCount,
    maxReviewCount,
    minRating,
    maxRating,
    orderBy,
    order,
    page,
    setSearchParams,
  ]);

  const handleMinPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMinPrice(event.target.value);
    setSelectedRanges({});
  };

  const handleMaxPriceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMaxPrice(event.target.value);
    setSelectedRanges({}); // Clear checkbox selections
  };

  const handleCheckboxChange = (key: string, isChecked: boolean) => {
    const newSelectedRanges = { ...selectedRanges, [key]: isChecked };
    setSelectedRanges(newSelectedRanges);

    const selectedKeys = Object.keys(newSelectedRanges).filter(
      (k) => newSelectedRanges[k]
    );
    if (selectedKeys?.length > 0) {
      // Calculate the new min and max price from selected ranges
      let newMinPrice = Infinity;
      let newMaxPrice = 0;
      selectedKeys?.forEach((k) => {
        const bounds = k.split('-').map(Number);
        if (bounds[0] < newMinPrice) newMinPrice = bounds[0];
        if (bounds[1] && bounds[1] > newMaxPrice) newMaxPrice = bounds[1];
      });
      setMinPrice(newMinPrice.toString());
      setMaxPrice(newMaxPrice ? newMaxPrice.toString() : '');
    } else {
      setMinPrice('');
      setMaxPrice('');
    }
  };

  const handleLocationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked, name } = event.target;
    const cityName = name.split('.')[1]; // Extract city value from name

    setSelectedLocations((prevLocations) => {
      if (checked) {
        // Add city to locations if checked
        return [...prevLocations, cityName];
      } else {
        // Remove city from locations if unchecked
        return prevLocations.filter((location) => location !== cityName);
      }
    });
  };

  const resetFilters = () => {
    // State'leri başlangıç durumlarına ayarla
    setPage(1);
    setKeyword('');
    setSelectedCategory(1); // Varsayılan olarak 'Tüm Kategoriler' gibi bir kategoriye dönmek isteyebilirsiniz
    setSelectedLocations([]);
    setMinPrice('');
    setMaxPrice('');
    setMinReviewCount(parseInt(''));
    setMaxReviewCount(parseInt(''));
    setMinRating('');
    setMaxRating('');
    setOrderBy('reviewCount');
    setOrder('DESC');
    setSelectedRanges({});
    // URL parametrelerini güncelle
    setSearchParams({});
  };

  useEffect(() => {
    updateSearchParams();
  }, [
    keyword,
    selectedCategory,
    selectedLocations,
    minPrice,
    maxPrice,
    minReviewCount,
    maxReviewCount,
    minRating,
    maxRating,
    orderBy,
    order,
    page,
  ]);

  return (
    <div className="flex w-full flex-shrink-0 flex-col p-2">
      <div className="bg-white rounded-2xl p-2 md:p-6">
        {/* NOTE: search Bar*/}
        <div className="grid grid-cols-12 gap-x-4 gap-y-2 lg:gap-y-0 justify-center items-center">
          {/* Logo */}
          <div className="col-span-12 lg:col-span-2">
            <Link
              to={'/'}
              className={classNames(
                'flex h-16 flex-shrink-0 items-center px-4 justify-between'
              )}
            >
              <img
                className="h-5 w-auto"
                src="/assets/logos/rexven-logo.svg"
                alt="Rexven Company"
              />
            </Link>
          </div>

          {/* Search Bar Field */}
          <div className="col-span-12 lg:col-span-8">
            <form className="" onSubmit={handleKeywordSubmit}>
              <label
                htmlFor="default-search"
                className="mb-2 text-lg lg:text-sm font-medium text-gray-900 sr-only dark:text-white"
              >
                Search
              </label>
              <div className="relative">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5 text-gray-500 dark:text-gray-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                    ></path>
                  </svg>
                </div>
                <input
                  ref={inputRef}
                  id="default-search"
                  className="block w-full p-[0.58rem] pl-10 text-xs md:text-sm text-gray-900 border border-headerPrimary rounded-lg bg-gray-50 "
                  placeholder={'Ürün Bul'}
                />
                <button
                  type="submit"
                  className="text-white absolute right-0 bottom-0 bg-headerPrimary rounded-r-md font-medium text-sm px-4 py-2 md:py-2.5"
                >
                  {t?.('common.buttons.search')}
                </button>
              </div>
            </form>
          </div>

          {/* Register Button Field */}
          <div className="col-span-12 lg:col-span-2">
            <SaleRegister />
          </div>
        </div>
        {/* NOTE: categories*/}
        <div className="flow-root">
          <div className="relative -mx-2 -my-4 overflow-x-auto overflow-y-auto scrollbar-hide">
            <div className="inline-block min-w-full align-middle sm:px-6 lg:px-8">
              <div className="w-full my-10 overflow-hidden">
                <div className="flex items-center justify-between overflow-auto touch-pan-x py-3">
                  {categories?.map((category: any) => (
                    <p
                      onClick={() => {
                        setProducts([]);
                        setPage(1);
                        setSelectedCategory(category.id);
                      }}
                      className={classNames(
                        selectedCategory === category.id
                          ? 'border-b-2 font-bold text-sm md:text-md border-headerPrimary text-[#1F2937]'
                          : 'text-[#374151] text-xs  md:text-sm xl:text-sm',
                        'whitespace-nowrap font-medium  min-[1306px]:font-medium text-center  px-3 first:pl-0 last:pr-0  cursor-pointer inline-block hover:text-headerPrimary'
                      )}
                      key={category.id}
                    >
                      {category.categoryName}
                    </p>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* side categories and product cards */}
        <div className="grid grid-cols-12 gap-x-2 md:gap-x-4 gap-y-2 lg:gap-y-0 justify-center items-start ">
          {/* side categories*/}
          <div className="col-span-5 sm:col-span-4 md:col-span-2  pt-4 lg:sticky top-[64px] max-w-[100px] min-[400px]:max-w-[150px] min-[500px]:max-w-none">
            <div className="space-y-4 overflow-y-auto max-h-full">
              {/* Konum */}
              <div className="">
                <label
                  htmlFor="location"
                  className="block text-xs md:text-sm font-medium text-gray-700 border-b-2"
                >
                  Konum
                </label>
              </div>
              <div className="relative mb-8">
                <div className="max-h-40 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300">
                  <div className="flex flex-col gap-1">
                    {turkishCities.map((city) => (
                      <label
                        key={city.value}
                        className="flex items-center space-x-1 md:space-x-2"
                      >
                        <input
                          type="checkbox"
                          name={`cities.${city.value}`}
                          onChange={handleLocationChange}
                          checked={selectedLocations.includes(city.value)}
                          className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        />
                        <span className="text-xs md:text-sm font-medium text-gray-700">
                          {city.value}
                        </span>
                      </label>
                    ))}
                  </div>
                </div>
              </div>
              {/* Fiyat */}
              <div className="grid grid-cols-9 gap-2 items-end justify-center">
                <div className="col-span-9 min-[500px]:col-span-4">
                  <label
                    htmlFor="priceMin"
                    className="block text-xs md:text-sm font-medium text-gray-700"
                  >
                    Min Fiyat
                  </label>
                  <input
                    id="priceMin"
                    name="priceMin"
                    type="text"
                    placeholder="Min"
                    value={minPrice}
                    onChange={handleMinPriceChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                </div>
                <div className="hidden min-[500px]:col-span-1 min-[500px]:flex justify-center items-center">
                  <span>-</span>
                </div>
                <div className="col-span-9 min-[500px]:col-span-4">
                  <label
                    htmlFor="priceMax"
                    className="block text-xs md:text-sm font-medium text-gray-700"
                  >
                    Max Fiyat
                  </label>
                  <input
                    id="priceMax"
                    name="priceMax"
                    type="text"
                    placeholder="Max"
                    value={maxPrice}
                    onChange={handleMaxPriceChange}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                </div>
              </div>
              <div className="relative">
                <div className="">
                  <div className="flex flex-col gap-1">
                    {priceRanges.map((range) => (
                      <label
                        key={range.key}
                        className="flex items-center space-x-2"
                      >
                        <input
                          type="checkbox"
                          name={`selectedPriceRange.${range.key}`}
                          onChange={(e) =>
                            handleCheckboxChange(range.key, e.target.checked)
                          }
                          checked={selectedRanges[range.key] || false}
                          className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        />
                        <span className="text-xs md:text-sm font-medium text-gray-700">
                          {range.label}
                        </span>
                      </label>
                    ))}
                  </div>
                </div>
              </div>
              {/* Review Count */}
              <div className="grid grid-cols-9 gap-2 items-end justify-center">
                <div className="col-span-9 min-[500px]:col-span-4">
                  <label
                    htmlFor="priceMin"
                    className="block text-xs md:text-sm font-medium text-gray-700"
                  >
                    Min Yorum
                  </label>
                  <input
                    id="minReviewCount"
                    name="minReviewCount"
                    type="number"
                    placeholder="Min"
                    min={0}
                    value={minReviewCount}
                    onChange={(e) => setMinReviewCount(+e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                </div>
                <div className="hidden min-[500px]:col-span-1 min-[500px]:flex justify-center items-center">
                  <span>-</span>
                </div>
                <div className="col-span-9 min-[500px]:col-span-4">
                  <label
                    htmlFor="priceMax"
                    className="block text-xs md:text-sm font-medium text-gray-700"
                  >
                    Max Yorum
                  </label>
                  <input
                    id="maxReviewCount"
                    name="maxReviewCount"
                    type="number"
                    placeholder="Max"
                    min={0}
                    value={maxReviewCount}
                    onChange={(e) => setMaxReviewCount(+e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                </div>
              </div>
              {/* Rating */}
              <div className="grid grid-cols-9 gap-2 items-end justify-center">
                <div className="col-span-9 min-[500px]:col-span-4">
                  <label
                    htmlFor="minRating"
                    className="block text-xs md:text-sm font-medium text-gray-700"
                  >
                    Min Puan
                  </label>
                  <input
                    id="minRating"
                    name="minRating"
                    type="number"
                    placeholder="Min"
                    value={minRating}
                    onChange={(e) => {
                      // Check if the input is empty and handle accordingly
                      const inputValue = e.target.value;
                      if (inputValue === '') {
                        setMinRating('');
                      } else {
                        // Only apply min/max constraints if the input is not empty
                        const numericValue = Math.max(
                          0,
                          Math.min(5, +inputValue)
                        );
                        setMinRating(numericValue.toString()); // Ensure you set a string value back to state
                      }
                    }}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                </div>
                <div className="hidden min-[500px]:col-span-1 min-[500px]:flex justify-center items-center">
                  <span>-</span>
                </div>
                <div className="col-span-9 min-[500px]:col-span-4">
                  <label
                    htmlFor="maxRating"
                    className="block text-xs md:text-sm font-medium text-gray-700"
                  >
                    Max Puan
                  </label>
                  <input
                    id="maxRating"
                    name="maxRating"
                    type="number"
                    placeholder="Max"
                    value={maxRating}
                    onChange={(e) => {
                      // Check if the input is empty and handle accordingly
                      const inputValue = e.target.value;
                      if (inputValue === '') {
                        setMaxRating('');
                      } else {
                        // Only apply min/max constraints if the input is not empty
                        const numericValue = Math.max(
                          0,
                          Math.min(5, +inputValue)
                        );
                        setMaxRating(numericValue.toString()); // Ensure you set a string value back to state
                      }
                    }}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  />
                </div>
              </div>
              <div>
                <button
                  type="submit"
                  onClick={handleFilterSubmit}
                  className="hover:cursor-pointer text-center text-[12px] bg-headerPrimary font-bold text-white h-[40px] max-h-[50px] w-full px-3 py-1  border-2 rounded-lg border-headerPrimary w-[49%] flex items-center justify-center"
                >
                  Uygula
                </button>
                <button
                  onClick={resetFilters}
                  className="hover:cursor-pointer text-center text-[12px] bg-white font-bold text-headerPrimary h-[40px] max-h-[50px] w-full px-3 py-1  border-2 rounded-lg border-headerPrimary w-[49%] flex items-center justify-center mt-3"
                >
                  Temizle
                </button>
              </div>
            </div>
          </div>
          {/* product cards */}
          <div className="col-span-7 sm:col-span-8 md:col-span-10  grid grid-cols-12 gap-2 justify-right min-[470px]:justify-center items-center  pt-4">
            {products.map((product, index) => (
              <div
                key={product.id}
                ref={index === products.length - 1 ? lastProductRef : undefined}
                className="col-span-12 sm:col-span-6 lg:col-span-6 xl:col-span-4 2xl:col-span-3"
              >
                <ProductCart
                  setOpen={setOpen}
                  setProductId={setProductId}
                  product={product}
                  updateProducerStats={updateProducerStats}
                />
              </div>
            ))}
            {loading && (
              <div className="w-full h-full flex items-center justify-center">
                <DotLoaderSpinner loading={loading} />
              </div>
            )}
          </div>
        </div>
      </div>
      {page > 1 && (
        <div
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          }}
          className="fixed right-5 bottom-5 mr-4 mb-4 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer"
        >
          <ChevronDoubleUpIcon className="h-6 w-6" />
        </div>
      )}
      <MessageModal
        open={open}
        setOpen={setOpen}
        productId={productId!}
        updateProducerStats={updateProducerStats}
      />
    </div>
  );
};

export default SupplierProducts;
