import tr from 'date-fns/locale/tr';
import { useFormik } from 'formik';
import React, { Fragment, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/outline';

import { useLanguage } from '../../../context/LanguageContext';
import { classNames } from '../../../utils/conditionalClasses';

registerLocale('tr', tr);

interface Props {
  // setQuery: React.Dispatch<React.SetStateAction<string>>;
  // query: string;
  setQueryParams: React.Dispatch<
    React.SetStateAction<{
      startDate: undefined;
      endDate: undefined;
      stores: string;
      status: number;
    }>
  >;
}
const status = [
  { name: 'confirmed', status: 3, display: 'Hepsi' },
  { name: 'notBilled', status: 2, display: 'Faturalanmayanlar' },
  { name: 'refunded', status: 4, display: 'İadeler' },
];
const FilterShipmentForm: React.FC<Props> = ({ setQueryParams }) => {
  const [statusName, setStatusName] = useState(status[1].display);
  const navigate = useNavigate();
  const formatDate = (date: Date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Ay indeksi 0'dan başlar, bu yüzden +1 ekliyoruz
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  const { t } = useLanguage();
  const submitForm = (values: {
    startDate: Date | undefined;
    endDate: Date | undefined;
    stores: string;
    status: number;
  }) => {
    navigate(
      `?startDate=${values.startDate === undefined ? '' : formatDate(values.startDate)
      }&endDate=${values.endDate === undefined ? '' : formatDate(values.endDate)
      }&stores=${values.stores === '' ? '' : values.stores}&status=${values.status
      }`
    );
  };
  const formik = useFormik({
    initialValues: {
      status: 3,
      startDate: undefined,
      endDate: undefined,
      trackingNumber: '',
      stores: '',
    },
    onSubmit: submitForm,
    validationSchema: Yup.object().shape({
      status: Yup.string().required(t?.('formFilter.cantEmpty')),
    }),
  });

  return (
    <div className="flex flex-col space-y-3 w-full shadow-lg rounded-xl bg-white p-4 mb-12 mt-8 text-sm">
      <form onSubmit={formik.handleSubmit}>
        <div className="grid grid-cols-12 gap-2">
          <div className="col-span-6 md:col-span-3">
            <label
              className="w-full font-medium text-xs min-[1335px]:text-sm"
              htmlFor="status"
            >
              Durum
            </label>
            <Listbox
              value={formik.values.status}
              onChange={(statusItem) => {
                formik.values.status = statusItem;
                let statName = status?.find(
                  (item) => item.status === formik.values.status
                )?.display;

                // statName =
                //   statusName && statusName !== 'Hepsi' ? statusName : 'Hepsi';

                setStatusName(statName!);
              }}
            >
              {({ open }) => (
                <>
                  <div className="relative w-full">
                    <Listbox.Button className="relative w-full cursor-default rounded-md bg-white pl-3 pr-10  pt-2.5 pb-2 text-xs min-[1335px]:text-sm text-left text-gray-900 shadow-sm ring-1 ring-inset ring-cardBorder focus:outline-none ">
                      <span className="block truncate">{statusName}</span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronDownIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-20 border-2 border-cardBorder mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-xs shadow-lg ring-1 ring-black ring-opacity-5 focus:ring-secondary focus:outline-none sm:text-sm">
                        {/* <Listbox.Option
                          key={98989898}
                          className={({ active }) =>
                            classNames(
                              active
                                ? 'bg-secondary text-white'
                                : 'text-gray-900',
                              'relative cursor-default select-none py-2 pl-3 pr-9'
                            )
                          }
                          value={''}
                        > */}
                        {/* {({ selected, active }) => (
                            <>
                              {/* <span
                                className={classNames(
                                  selected ? 'font-semibold' : 'font-normal',
                                  'block truncate'
                                )}
                              >
                                {'Hepsi'}
                              </span> */}

                        {/* {selected ? (
                                <span
                                  className={classNames(
                                    active ? 'text-white' : 'text-indigo-600',
                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                  )}
                                >
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null} */}
                        {/* </>
                          )} */}
                        {/* </Listbox.Option> */}
                        {status &&
                          status?.map((item: any) => (
                            <Listbox.Option
                              key={item.name}
                              className={({ active }) =>
                                classNames(
                                  active
                                    ? 'bg-secondary text-white'
                                    : 'text-gray-900',
                                  'relative cursor-default select-none py-2 pl-3 pr-9'
                                )
                              }
                              value={item.status}
                            >
                              {({ selected, active }) => (
                                <>
                                  <span
                                    className={classNames(
                                      selected
                                        ? 'font-semibold'
                                        : 'font-normal',
                                      'block truncate'
                                    )}
                                  >
                                    {item.display}
                                  </span>

                                  {selected ? (
                                    <span
                                      className={classNames(
                                        active
                                          ? 'text-white'
                                          : 'text-indigo-600',
                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                      )}
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
          </div>
          <div className="col-span-6 md:col-span-3">
            <label
              className="w-full font-medium text-xs min-[1335px]:text-sm"
              htmlFor="startDate"
            >
              Başlangıç Tarihi
            </label>

            <DatePicker
              name="startDate"
              className="w-full rounded-md border z-30 border-cardBorder shadow-sm text-xs min-[1335px]:text-sm bg-white"
              selected={formik.values.startDate}
              locale={tr}
              placeholderText="Başlangıç tarihi seçiniz"
              onChange={(date) => {
                formik.setFieldValue('startDate', date);
              }}
            />
          </div>
          <div className="col-span-6 md:col-span-3">
            <label
              className="w-full font-medium text-xs min-[1335px]:text-sm"
              htmlFor="endDate"
            >
              Bitiş Tarihi
            </label>
            <DatePicker
              name="endDate"
              className="w-full rounded-md border border-cardBorder shadow-sm text-xs min-[1335px]:text-sm bg-white"
              selected={formik.values.endDate}
              locale={tr}
              placeholderText="Bitiş tarihi seçiniz"
              onChange={(date) => {
                formik.setFieldValue('endDate', date);
              }}
            />
          </div>
          {/* <div className="col-span-6 md:col-span-3">
            <label
              className="w-full font-medium text-xs min-[1335px]:text-sm"
              htmlFor="trackingNumber"
            >
              Sipariş Numarası
            </label>
            <input
              value={formik.values.trackingNumber}
              onChange={formik.handleChange}
              id="trackingNumber"
              name="trackingNumber"
              type="number"
              className="w-full rounded-md border border-cardBorder shadow-sm text-xs min-[1335px]:text-sm bg-white"
              placeholder="Sipariş numarasını giriniz..."
            />
          </div> */}
          {/* WEIGHT */}
          <div className="col-span-6 md:col-span-3">
            <label
              className="w-full font-medium text-xs min-[1335px]:text-sm"
              htmlFor="stores"
            >
              Mağaza
            </label>
            <input
              value={formik.values.stores}
              onChange={formik.handleChange}
              id="stores"
              name="stores"
              type="text"
              className="w-full rounded-md border border-cardBorder shadow-sm text-xs min-[1335px]:text-sm bg-white"
              placeholder="Mağaza adını giriniz..."
            />
          </div>

          <div className="col-span-12 mt-2">
            <div className="flex items-end justify-end space-x-4 h-full">
              <button
                type="submit"
                className="bg-headerPrimary px-4 py-2 md:py-2.5 md:px-6 rounded-lg text-white"
              >
                Filtrele
              </button>
              <button
                className="bg-white border-2 border-headerPrimary px-4 py-2 md:px-6 rounded-lg text-headerPrimary"
                onClick={() => formik.resetForm()}
              >
                Temizle
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default FilterShipmentForm;
