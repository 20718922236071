import React from 'react'
import { PlayCircleIcon } from '@heroicons/react/20/solid';
import { durationConverter } from '../../utils/durationConverter';
import { CourseData } from '../../pages/CourseDetail';

interface Props {
    course: CourseData;
    selectedContent: {
        sectionLocation: any;
        path: any;
        id: any;
        contentName: any;
        notes: string
    };
    handleSelectedContent: Function;
    onChange: (e: any, courseId: number, contentId: number) => {};
    content: any;
    section: any
}
function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
}
const ContentLine: React.FC<Props> = ({ content, selectedContent, course, onChange, handleSelectedContent, section }) => {
    return (
        <>
            <div
                onClick={handleSelectedContent.bind(
                    null,
                    section.sectionLocation,
                    content.path,
                    content.id,
                    content.contentName,
                    content.notes
                )}
                key={content.id}
                className={
                    content.id === selectedContent?.id
                        ? 'border-b border-light-gray relative cursor-pointer select-none py-1 pl-3 pr-4 bg-secondary text-white cursor'
                        : 'border-b border-light-gray relative cursor-pointer select-none py-2 pl-3 pr-4 text-gray-900 bg-backgroundDefault'
                }
            >
                <div className={'flex items-center'}>
                    <div className="flex h-6 items-center">
                        <input
                            checked={course.completed?.includes(
                                content.id
                            )}
                            onChange={(e: any) =>
                                onChange(e, course.id, content.id)
                            }
                            id="comments"
                            aria-describedby="comments-description"
                            name="comments"
                            type="checkbox"
                            className="h-4 w-4 rounded border-gray-300 text-headingPrimary focus:ring-headingPrimary cursor-pointer"
                        />
                    </div>
                    <span

                        className={classNames(
                            selectedContent?.id === content.id
                                ? 'text-sm'
                                : 'text-sm',
                            'ml-2 block truncate'
                        )}
                    >
                        { } {content.contentName}
                    </span>
                </div>
                <div className={'flex items-center'}>
                    <PlayCircleIcon
                        className="h-4 w-4"
                        aria-hidden="true"
                    />
                    <span className="text-sm pl-2">
                        {durationConverter(content.duration)}
                    </span>
                </div>
            </div>
        </>
    )
}

export default ContentLine