import React, { useEffect, useState } from 'react';
import ChartsWrapper from '../components/trendyolOrders/ChartsWrapper';
import OrdersTable from '../components/trendyolOrders/OrdersTable';
import { useAuth } from '../context/AuthContext';
import NewBanner from '../components/tools/common/NewBanner';
import EntegrationModal from '../components/trendyolSellerDashboard/EntegrationModal';
import memberService from '../services/memberService';
import alertNotification from '../utils/alertNotification';
import trendyolToolsService from '../services/trendyolToolsService';
import LoadingComponent from '../components/common/LoadingComponent';
import moment from 'moment';
import CustomDatePicker from '../components/trendyolSellerDashboard/DatePicker';
import { DaysType } from '../components/trendyolSellerDashboard/DummyDatasAndTypes';

export interface ITrendyolOrders {
  id: number;
  images: {
    url: string;
  }[];
  title: string;
  saleCount: number;
  returnCount: number;
  totalRevenue: number;
  productCost: null | number;
  shipCost: number;
  returnCost: number;
  trCut: number;
  adCost: null | number;
  totalVat: number;
  profitOrLoss: number;
  profitMargin: number;
  profitRate: number;
  barcode: number;
  producerId: number;
}

const data = [
  {
    id: 1233,
    images: [
      {
        url: 'https://cdn.dsmcdn.com/ty28/product/media/images/20201126/14/32745776/66606322/1/1_org.jpg',
      },
    ],
    title: 'Takı Reçine Kolye Tasarımı Rose Gümüş',
    saleCount: 35,
    returnCount: 5,
    totalRevenue: 3344,
    productCost: null,
    shipCost: 697,
    returnCost: 194,
    trCut: 526,
    adCost: null,
    totalVat: 447,
    profitOrLoss: 1478,
    profitMargin: 44,
    profitRate: 0,
    barcode: 2312312,
    producerId: 12312,
  },
  {
    id: 33233,
    images: [
      {
        url: 'https://cdn.dsmcdn.com/ty39/product/media/images/20201223/11/41091431/57886728/1/1_org.jpg',
      },
    ],
    title: 'Orijinal Kadın 5 Li Altın',
    saleCount: 11,
    returnCount: 2,
    totalRevenue: 1375,
    productCost: 40,
    shipCost: 486,
    returnCost: 192,
    trCut: 335,
    adCost: null,
    totalVat: 216,
    profitOrLoss: 144,
    profitMargin: 10,
    profitRate: 32,
    barcode: 23123212,
    producerId: 12312,
  },
  {
    id: 41783,
    images: [
      {
        url: 'https://cdn.dsmcdn.com/ty448/product/media/images/20220607/9/122409795/495844502/1/1_org.jpg',
      },
    ],
    title: 'Küp Detaylı Dörtlü',
    saleCount: 8,
    returnCount: 0,
    totalRevenue: 2625,
    productCost: null,
    shipCost: 292,
    returnCost: 0,
    trCut: 417,
    adCost: null,
    totalVat: 376,
    profitOrLoss: 1538,
    profitMargin: 58,
    profitRate: 0,
    barcode: 2312312,
    producerId: 12312,
  },
  {
    id: 23334,
    images: [
      {
        url: 'https://cdn.dsmcdn.com/ty448/product/media/images/20220607/17/122629047/496138210/1/1_org.jpg',
      },
    ],
    title: 'Turquoise Stone Dörtlü',
    saleCount: 6,
    returnCount: 1,
    totalRevenue: 670,
    productCost: null,
    shipCost: 118,
    returnCost: 48,
    trCut: 84,
    adCost: null,
    totalVat: 84,
    profitOrLoss: 335,
    profitMargin: 50,
    profitRate: 0,
    barcode: 2312312,
    producerId: 12312,
  },
  {
    id: 76559,
    images: [
      {
        url: 'https://cdn.dsmcdn.com/mnresize/-/-//ty39/product/media/images/20201223/11/41089537/57886721/1/1_org_thumb.jpg',
      },
    ],
    title: 'Sertifikalı Çakra Bilekliği',
    saleCount: 4,
    returnCount: 0,
    totalRevenue: 600,
    productCost: null,
    shipCost: 30,
    returnCost: 0,
    trCut: 162,
    adCost: 15,
    totalVat: 254,
    profitOrLoss: 222,
    profitMargin: 14,
    profitRate: 0,
    barcode: 2312312,
    producerId: 12312,
  },
  {
    id: 76553,
    images: [
      {
        url: 'https://cdn.dsmcdn.com/mnresize/-/-//ty39/product/media/images/20201223/11/41089537/57886721/1/1_org_thumb.jpg',
      },
    ],
    title: 'Bereket Bilekliği',
    saleCount: 4,
    returnCount: 0,
    totalRevenue: 1600,
    productCost: null,
    shipCost: 960,
    returnCost: 0,
    trCut: 162,
    adCost: 15,
    totalVat: 254,
    profitOrLoss: 222,
    profitMargin: 14,
    profitRate: 0,
    barcode: 2312312,
    producerId: 12312,
  },
];
const TrendyolOrderPage = () => {
  let now = moment();

  const { checkSessionStatus, session } = useAuth();
  //orders table states
  const [selected, setSelected] = useState({
    title: 'Son 30 Gün',
    current: false,
  });
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [page, setPage] = useState(1);
  const [inputPage, setInputPage] = useState<number | string>(1);
  let temp = session?.isTrendyolIntegrated ? [] : data;
  const [orders, setOrders] = useState(temp);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPageCount, setTotalPageCount] = useState(1);
  const [openEntegrationModal, setOpenEntegrationModal] =
    useState<boolean>(false);
  const [entegrationLoading, setEntegrationLoading] = useState(false);
  const [isTabActive, setIsTabActive] = React.useState<boolean>(true);

  const [datePickerStartDate, setDatePickerStartDate] = useState(new Date());
  const [datePickerEndDate, setDatePickerEndDate] = useState<Date | null>(
    now.subtract(30, 'days').toDate()
  );
  const [datDiff, setDayDiff] = useState(90);
  const [days, setDays] = useState<DaysType>({
    today: '',
    sevenDaysAgo: '',
    thirtyDaysAgo: '',
    ninetyDaysAgo: '',
  });
  const [selectedDate, setSelectedDate] = useState({
    startDate: days.thirtyDaysAgo,
    endDate: days.today,
  });
  const handleBannerOnClick = () => setOpenEntegrationModal(true);

  const handleEntegrationOnSubmit = async (values: any) => {
    let { apiSecret, apiKey, sellerId } = values;
    setEntegrationLoading(true);
    memberService
      .registerAsTrendyolSeller({
        platform: 'trendyol',
        apiKey,
        apiSecret,
        sellerId,
      })
      .then((res) => {
        alertNotification('succes', 'Mağazanız Başarıyla Eklendi');
        checkSessionStatus!();
      })
      .catch((err) => {
        alertNotification('error', err.response.data.message);
      })
      .finally(() => {
        setEntegrationLoading(false);
      });
  };

  const getTrendyolOrders = async () => {
    setIsLoading(true);

    try {
      const res = await trendyolToolsService.getTrendyolOrders(
        session?.producerId!,
        selectedDate
      );
      setOrders(res.data);
      setTotalPageCount(res.data.pageCount);
    } catch (error: any) {
      alertNotification('error', error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleOptionSelect = (option: any) => {
    console.log(option);
    switch (option.title) {
      case 'Tarih Aralığı Seç':
        setShowDatePicker(true);
        setSelected(option);
        setSelectedDate({
          startDate: datePickerStartDate.toISOString(),
          endDate: datePickerEndDate!.toISOString(),
        });
        break;
      case 'Bugün':
        setShowDatePicker(false);
        setSelected(option);
        setSelectedDate({
          startDate: days.today,
          endDate: '',
        });
        break;
      case 'Son 7 Gün':
        setShowDatePicker(false);
        setSelected(option);
        setSelectedDate({
          startDate: days.sevenDaysAgo,
          endDate: days.today,
        });
        break;
      case 'Son 30 Gün':
        setShowDatePicker(false);
        setSelected(option);
        setSelectedDate({
          startDate: days.thirtyDaysAgo,
          endDate: days.today,
        });
        break;
      case 'Son 90 Gün':
        setShowDatePicker(false);
        setSelected(option);
        setSelectedDate({
          startDate: days.ninetyDaysAgo,
          endDate: days.today,
        });
        break;
    }
  };

  useEffect(() => {
    const today = new Date();
    const sevenDaysAgo = new Date();
    sevenDaysAgo.setDate(today.getDate() - 7);

    const thirtyDaysAgo = new Date();
    thirtyDaysAgo.setDate(today.getDate() - 30);

    const ninetyDaysAgo = new Date();
    ninetyDaysAgo.setDate(today.getDate() - 90);

    setDays({
      today: today.toISOString(),
      sevenDaysAgo: sevenDaysAgo.toISOString(),
      thirtyDaysAgo: thirtyDaysAgo.toISOString(),
      ninetyDaysAgo: ninetyDaysAgo.toISOString(),
    });
    setSelectedDate({
      startDate: thirtyDaysAgo.toISOString(),
      endDate: today.toISOString(),
    });
  }, []);

  useEffect(() => {
    if (session?.isTrendyolIntegrated && selectedDate.startDate) {
      getTrendyolOrders();
      console.log('get trendyol orders');
    }
  }, [page, selectedDate]);

  useEffect(() => {
    if (!session?.isTrendyolIntegrated) {
      setOpenEntegrationModal(true);
    }
  }, [session?.isTrendyolIntegrated]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabActive(document.visibilityState === 'visible');
    };

    // Add event listener for visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup function to remove the event listener when the component is unmounted
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    let intervalId: any;
    if (session && isTabActive) {
      intervalId = setInterval(async () => {
        await memberService.userTrackReq(`trendyol-orders-page`, session?.id!);
      }, 60000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [session, isTabActive]);

  return (
    <div>
      {!session?.isTrendyolIntegrated && (
        <NewBanner
          bgUrl={'/images/trendyol-kar-hesaplama-bg.webp'}
          toolWithoutImg={'Mağazamı Entegre Et'}
          bannerOnClick={handleBannerOnClick}
        />
      )}
      {isLoading ? (
        <LoadingComponent />
      ) : orders.length > 0 ? (
        <>
          <div className="text-right mb-2">
            <CustomDatePicker
              selected={selected}
              setSelected={setSelected}
              showDatePicker={showDatePicker}
              setShowDatePicker={setShowDatePicker}
              setSelectedDate={setSelectedDate}
              handleOptionSelect={handleOptionSelect}
              startDate={datePickerStartDate}
              setStartDate={setDatePickerStartDate}
              endDate={datePickerEndDate}
              setEndDate={setDatePickerEndDate}
              dayDiff={datDiff}
            />
          </div>
          <ChartsWrapper dateQuery={selectedDate} />
          <OrdersTable
            orders={orders}
            getTrendyolOrders={getTrendyolOrders}
            totalPageCount={totalPageCount}
            inputPage={inputPage}
            setInputPage={setInputPage}
            page={page}
            setPage={setPage}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
          />
        </>
      ) : (
        <div className="w-full shadow-lg rounded-md bg-white p-6">
          <p className="text-center font-medium text-lg">
            Henüz siparişiniz bulunmamaktadır
          </p>
        </div>
      )}
      {
        <EntegrationModal
          loading={entegrationLoading}
          openEntegrationModal={openEntegrationModal}
          setOpenEntegrationModal={setOpenEntegrationModal}
          handleEntegrationOnSubmit={handleEntegrationOnSubmit}
        />
      }
    </div>
  );
};

export default TrendyolOrderPage;
