import { ErrorMessage, Field, FieldArray, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import { AddCircle, Calculator, DocumentText } from 'iconsax-react';
import { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';
import { DotLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

import { Dialog, Transition } from '@headlessui/react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';
import { XMarkIcon } from '@heroicons/react/24/outline';

import { useLanguage } from '../../context/LanguageContext';
import { OrderItemData } from '../../interfaces/shipEntegraInterfaces';
import shipentegraService from '../../services/shipentegraService';
import alertNotification from '../../utils/alertNotification';
import { classNames } from '../../utils/conditionalClasses';
import { errorElement } from '../../utils/errorElement';
import DotLoaderSpinner, { override } from '../common/DotLoaderSpinner';
import carrierServicesByCountry from '../shipEntegraShared/carriersByCountries.json';
import { isEuCountry } from '../shipEntegraShared/IsEuropeCountry';
import FormikSwitch from './elements/FormikSwitch';

interface CreateTicketModalProps {
    openCreateTicket: boolean
    setOpenCreateTicket: Dispatch<SetStateAction<boolean>>
    selectedOrderIds: number[] | undefined
    getOrders: () => void
}
type SetFieldErrorType = FormikHelpers<OrderItemData>['setFieldError'];
interface CountryServices {
    [key: string]: {
        services: string[];
    };
}
interface Carrier {
    title: string;
    value: string;
    info: {
        url: string;
        specialService: string;
    };
}
const CreateTicketModal: React.FC<CreateTicketModalProps> = ({ openCreateTicket, setOpenCreateTicket, selectedOrderIds, getOrders }) => {
    const { t } = useLanguage();
    const [isLoading, setIsLoading] = useState(false)
    const [calIsLoading, setCalIsLoading] = useState(false)
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [isDetailVisible, setIsDetailVisible] = useState(true);
    const [url, setUrl] = useState('');
    const [carriers, setCarriers] = useState<Carrier[]>([{ title: '', value: '', info: { url: '', specialService: '' } }])
    const [items, setItems] = useState<OrderItemData[]>([])
    const [calculation, setCalculation] = useState({
        success: true,
        price: 0,
        fuel: 0,
        pricing: "",
        uniqueCode: ""
    })
    const [calculated, setCalculated] = useState(false)
    const [showIossNumber, setShowIossNumber] = useState(false)
    const countryServices: CountryServices = carrierServicesByCountry;

    const getOrderItems = () => {
        if (!selectedOrderIds || selectedOrderIds.length === 0) {
            return;
        }
        setIsDataLoaded(false);

        shipentegraService.getOrderItems(selectedOrderIds[0])
            .then((res) => { setItems([res.data]); })
            .catch(error => {
                console.error("İstekler sırasında bir hata oluştu:", error);
            }).finally(() => {
                setIsDataLoaded(true);
            })
    };



    useEffect(() => {
        if (openCreateTicket && selectedOrderIds) {
            getOrderItems()
        }
    }, [openCreateTicket, selectedOrderIds]);

    useEffect(() => {
        const countryCode = items[0]?.generalInfo.shipTo.country;
        const availableServices = countryServices[countryCode]?.services || [];

        shipentegraService.getCarriers().then(res => {
            const filteredCarriers = res.data.filter((carrier: Carrier) => availableServices.includes(carrier.value));
            const missingServices = availableServices.filter(service => !res.data.some((carrier: Carrier) => carrier.value === service))
                .map(service => ({
                    value: service,
                    title: service.split('-').map(word => word.charAt(0).toUpperCase() + word.substring(1)).join(' '),
                    url: "https://newfront.shipentegra.com/v1/logistics/labels/shipentegra" // Varsayılan URL
                }));
            setCarriers([...filteredCarriers, ...missingServices]);
        }).catch(err => console.error(err));
    }, [items]);

    const validationSchema = Yup.object({
        specialService: Yup.string().required('Taşıyıcı alanı zorunludur.'),
        currency: Yup.string().required('Para birimi alanı zorunludur.'),
        weight: Yup.number().required('Ağırlık alanı zorunludur.').positive('Ağırlık pozitif bir sayı olmalı.'),
        insurance: Yup.boolean().required('Sigorta alanı zorunludur.'),
        content: Yup.string().required('Ürün adı alanı zorunludur.'),
        items: Yup.array()
            .of(
                Yup.object({
                    declaredQuantity: Yup.number().required('Adet alanı zorunludur.').positive('Adet pozitif bir sayı olmalı.'),
                    declaredPrice: Yup.number().required('Birim fiyatı alanı zorunludur.').positive('Birim fiyatı pozitif bir sayı olmalı.'),
                    gtip: Yup.string().required('GTIP No alanı zorunludur.'),
                })
            )
            .required('Sipariş öğeleri alanı zorunludur.')
            .min(1, 'En az bir sipariş öğesi olmalıdır.'),
    });


    const initialValues = {
        content: items[0]?.generalInfo.content || '',
        currency: items[0]?.generalInfo.currency || 'USD',
        weight: items[0]?.generalInfo.weight || '',
        specialService: carriers.length > 0 ? carriers[0].value : '', // carriers doluysa ilk değeri kullan, değilse boş string ata
        iossNumber: '',
        recipientName: items[0]?.generalInfo.shipTo.name || '',
        insurance: true || false,
        country: items[0]?.generalInfo.shipTo.country || '',
        declaredPrice: items[0]?.generalInfo.items[0]?.declaredPrice || '',
        gtip: items[0]?.generalInfo.items[0].gtip || '',
        items: items[0]?.generalInfo.items.map(item => ({
            declaredQuantity: item.declaredQuantity || '',
            declaredPrice: item.declaredPrice || '',
            gtip: item.gtip || '',
            itemId: item.itemId
        })),
    };



    const onSubmit = (values: any) => {
        setIsLoading(true)
        setCalculated(false)
        const payload = {
            url: url || carriers[0].info.url,
            specialService: values.specialService,
            insurance: values.insurance,
            content: values.content,
            weight: values.weight,
            iossNumber: values.iossNumber,
            currency: values.currency,
            items: values.items.map((item: any) => ({
                itemId: item.itemId,
                declaredPrice: item.declaredPrice,
                declaredQuantity: item.declaredQuantity,
                gtip: item.gtip,
            })),
        };
        shipentegraService.updateTicket(selectedOrderIds?.[0], payload)
            .then(res => {
                alertNotification('success', 'Siparişiniz Güncellendi')
                setOpenCreateTicket(false)
                getOrders()
            })
            .catch(err => {
                toast.error(err.response.data.message, {
                    position: 'top-right',
                    autoClose: 7000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }).finally(() => {
                setIsLoading(false)
            })
    }

    const calculate = (values: any, setFieldError: SetFieldErrorType) => {
        let isValid = true;

        if (!values.specialService) {
            setFieldError('specialService', 'Taşıyıcı alanı zorunludur.');
            isValid = false;
        }
        if (!values.weight) {
            setFieldError('weight', 'Ağırlık alanı zorunludur.');
            isValid = false;
        }
        if (!values.country) {
            setFieldError('country', 'Ülke alanı zorunludur.');
            isValid = false;
        }

        if (isValid) {
            const payload = {
                country: values.country,
                kgDesi: values.weight,
                seCarrier: values.specialService,
                isAmazonShipment: 0
            }
            setCalIsLoading(true)
            shipentegraService.calculate(payload)
                .then((res) => {
                    setCalculation(res.data)
                    setCalculated(true)
                }).catch((err) => {
                    console.log(err);
                    setCalculated(false)
                    alertNotification('err', err.response.data.message)
                }).finally(() => {
                    setCalIsLoading(false)
                })
        } else {
        }
    };
    const resetCalculateAndClose = () => {
        setCalculated(false)
        setOpenCreateTicket(false)
    }
    useEffect(() => {
        const countryIsEu = isEuCountry(items[0]?.generalInfo.shipTo.country);
        if (countryIsEu) {
            // Ülke AB üyesi ise IOSS numarası alanını göster
            setShowIossNumber(true);
        } else {
            // Ülke AB üyesi değilse IOSS numarası alanını gizle ve varsa değerini temizle
            setShowIossNumber(false);
        }
    }, [items[0]?.generalInfo.shipTo.country]);

    return (
        <Transition.Root show={openCreateTicket} as={Fragment}>
            <Dialog as="div" className="relative z-60" onClose={resetCalculateAndClose}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-50 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-4">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 w-full p-4 sm:p-6">
                                <div className="absolute right-0 top-0 pr-4 pt-4">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        onClick={resetCalculateAndClose}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className="">
                                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                        <Dialog.Title as="h3" className="flex flex-row items-center justify-left gap-1 text-base font-semibold leading-6 text-headerPrimary">
                                            <DocumentText color="#1F254B"
                                                variant="Bold"
                                                className="h-5 w-5" />
                                            Sipariş Detayları
                                        </Dialog.Title>
                                        <div className="mt-2 min-h-[400px]">
                                            {isDataLoaded ? <Formik
                                                initialValues={initialValues}
                                                validationSchema={validationSchema}
                                                onSubmit={onSubmit}
                                            >
                                                {formikProps => {
                                                    return (
                                                        <Form>
                                                            <div className='grid grid-cols-12 2xl:flex 2xl:flex-row gap-6 lg:gap-2 items-start justify-start 2xl:item-center 2xl:justify-center'>
                                                                <div className='flex flex-col items-start justify-center col-span-12 md:col-span-6'>
                                                                    <label htmlFor={`specialService`} className="block text-sm 2xl:min-h-[50px] font-medium text-description">Taşıyıcı</label>
                                                                    <Field as="select" name={`specialService`}>
                                                                        {({ field, form }: FieldProps) => (
                                                                            <select {...field} onChange={(e: any) => {
                                                                                const { value } = e.target
                                                                                const selectedCarrier = carriers.find(carrier => carrier.value === value);
                                                                                if (selectedCarrier) {
                                                                                    form.setFieldValue("specialService", value);
                                                                                    setUrl(selectedCarrier.info.url); // URL state'ini güncelle
                                                                                }
                                                                            }} className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                                                                                {carriers.map((carrier, index) => {
                                                                                    return (
                                                                                        <option key={index} value={carrier.value}>{carrier.title}</option>
                                                                                    )
                                                                                })}
                                                                            </select>
                                                                        )}
                                                                    </Field>
                                                                    {formikProps.errors.specialService && errorElement(formikProps.errors.specialService)}
                                                                </div>
                                                                <div className='flex flex-col items-start justify-center col-span-12 md:col-span-3 min-h-full'>
                                                                    <label className="block text-sm 2xl:min-h-[50px] font-medium text-description">Ülke</label>
                                                                    <div className="block mt-1">
                                                                        <div className="flex items-start mt-1">
                                                                            <img className="h-5 w-5 flex-shrink-0 rounded-full" alt="" src={`https://flagsapi.com/${items[0]?.generalInfo.shipTo.country}/flat/64.png`}></img>
                                                                            <span className="ml-1">{items[0]?.generalInfo?.shipTo?.country}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='flex flex-col items-start justify-center col-span-12 md:col-span-3'>
                                                                    <label className="block text-sm 2xl:min-h-[50px] font-medium text-description">Sigorta</label>
                                                                    <div className="block mt-2">
                                                                        <FormikSwitch name="insurance" />
                                                                        {formikProps.errors.insurance && errorElement(formikProps.errors.insurance)}
                                                                    </div>
                                                                </div>
                                                                <div className='flex flex-col items-start justify-center col-span-12 md:col-span-6'>
                                                                    <label htmlFor={`declaredPrice`} className="block text-sm 2xl:min-h-[50px] font-medium text-description">Gümrük Değeri</label>
                                                                    <Field type="number" name={`declaredPrice`} className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                                                    {formikProps.errors.declaredPrice && errorElement(formikProps.errors.declaredPrice)}
                                                                </div>
                                                                <div className='flex flex-col items-start justify-center col-span-12 md:col-span-6'>
                                                                    <label htmlFor={`currency`} className="block text-sm 2xl:min-h-[50px] font-medium text-description">Para Birimi</label>
                                                                    <div className="block mt-1">
                                                                        <Field as="select" name={`currency`} className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm">
                                                                            {({ field }: FieldProps) => (
                                                                                <select
                                                                                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                                                    {...field}>
                                                                                    <option value="USD">USD</option>
                                                                                    <option value="EUR">EUR</option>
                                                                                    <option value="GBP">GBP</option>
                                                                                    <option value="SAR">SAR</option>
                                                                                    <option value="AUD">AUD</option>
                                                                                    <option value="CAD">CAD</option>
                                                                                    <option value="KWD">KWD</option>
                                                                                </select>
                                                                            )}
                                                                        </Field>
                                                                    </div>
                                                                    {formikProps.errors.currency && errorElement(formikProps.errors.currency)}
                                                                </div>
                                                                <div className='flex flex-col items-start justify-center col-span-12 md:col-span-6'>
                                                                    <label htmlFor={`content`} className="block text-sm 2xl:min-h-[50px] font-medium text-description">Ürün</label>
                                                                    <Field name={`content`} type='text' className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                                                    {formikProps.errors.content && errorElement(formikProps.errors.content)}
                                                                </div>
                                                                <div className='flex flex-col items-start justify-center col-span-12 md:col-span-6'>
                                                                    <label className="block text-sm 2xl:min-h-[50px] font-medium text-description">Alıcı</label>
                                                                    <span className="mt-1 block rounded-md p-2 sm:text-sm">{items[0]?.generalInfo.shipTo.name}</span>
                                                                </div>
                                                                <div className='flex flex-col items-start justify-center col-span-12 md:col-span-6'>
                                                                    <label htmlFor={`gtip`} className="block text-sm 2xl:min-h-[50px] font-medium text-description">GTIP No</label>
                                                                    <Field name={`gtip`} type='text' className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                                                    {formikProps.errors.gtip && errorElement(formikProps.errors.gtip)}
                                                                </div>
                                                                <div className='flex flex-col items-start justify-center col-span-12 md:col-span-6'>
                                                                    <label htmlFor="weight" className="block text-sm 2xl:min-h-[50px] font-medium text-description">Ağırlık</label>
                                                                    <div className="relative w-full">
                                                                        <Field
                                                                            type="number"
                                                                            name="weight"
                                                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm pr-10"
                                                                        />
                                                                        <Calculator
                                                                            onClick={() => calculate(formikProps.values, formikProps.setFieldError)}
                                                                            variant="Bold"
                                                                            className='cursor-pointer text-headerPrimary mr-2 w-4 h-4 absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400'
                                                                        />
                                                                    </div>
                                                                    {formikProps.errors.weight && errorElement(formikProps.errors.weight)}
                                                                </div>
                                                                <div className='flex flex-col items-start justify-center col-span-12 md:col-span-6'>
                                                                    {showIossNumber && <>
                                                                        <label htmlFor={`iossNumber`} className="block text-sm 2xl:min-h-[50px] font-medium text-description">IOSS / HMRC</label>
                                                                        <Field type='text' name={`iossNumber`} className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" />
                                                                    </>}
                                                                    {/* {formikProps.errors.iossNumber && errorElement(formikProps.errors.iossNumber)} */}
                                                                </div>
                                                            </div>
                                                            {calculated &&
                                                                <div className='my-2 text-[#007599] text-sm font-semibold'>
                                                                    Ağırlığı girilen ve fiyatı hesaplanan 1 gönderiniz için ödenmesi gereken toplam tahmini tutar
                                                                    <span className='font-bold text-md text-headerPrimary'>{` ${(calculation.price + calculation.fuel).toFixed(2)} `}</span>
                                                                    USD dir. Gerçek fiyat son tartımdan sonra hesaplanacaktır.
                                                                </div>}
                                                            <div className='flex flex-row items-center justify-start w-full gap-2 mt-6 mb-2'>
                                                                <p
                                                                    onClick={() => setIsDetailVisible(!isDetailVisible)}
                                                                    className='text-headerPrimary font-semibold cursor-pointer'>
                                                                    Sipariş Detayı
                                                                </p>
                                                                <div>
                                                                    {isDetailVisible ? <ChevronDownIcon
                                                                        onClick={() => setIsDetailVisible(false)}
                                                                        className="h-5 w-5 text-[#1F254B] cursor-pointer bg-[#EDEEF8] rounded-md"
                                                                        aria-hidden="true"
                                                                    />
                                                                        :
                                                                        <ChevronUpIcon
                                                                            onClick={() => setIsDetailVisible(true)}
                                                                            className="h-5 w-5 text-[#1F254B] cursor-pointer bg-[#EDEEF8] rounded-md"
                                                                            aria-hidden="true"
                                                                        />
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className={classNames(
                                                                isDetailVisible ? 'block' : 'hidden',
                                                                ''
                                                            )}>
                                                                <FieldArray name="items">
                                                                    {({ push, remove }) => (
                                                                        formikProps?.values?.items?.map((item: any, index: any) => (
                                                                            <>
                                                                                <div className='md:hidden text-sm mt-4 text-left'>Kalem-{index + 1}</div>
                                                                                <div className='grid grid-cols-12 gap-4 mt-2' key={index}>
                                                                                    <div className='flex flex-col items-start justify-center col-span-4'>
                                                                                        <label htmlFor={`items[${index}].declaredQuantity`} className="block text-sm font-medium text-description">Adet</label>
                                                                                        <Field className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" name={`items[${index}].declaredQuantity`} type="number" placeholder="Adet" />
                                                                                        <ErrorMessage name={`items[${index}].declaredQuantity`} component="div" className="rounded-md bg-red-50 p-1 my-1 text-xs text-rexRed" />
                                                                                    </div>
                                                                                    <div className='flex flex-col items-start justify-center col-span-4'>
                                                                                        <label htmlFor={`items[${index}].declaredPrice`} className="block text-sm font-medium text-description">Birim Fiyat</label>
                                                                                        <Field className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" name={`items[${index}].declaredPrice`} type="number" placeholder="Birim Fiyatı" />
                                                                                        <ErrorMessage name={`items[${index}].declaredPrice`} component="div" className="rounded-md bg-red-50 p-1 my-1 text-xs text-rexRed" />
                                                                                    </div>
                                                                                    <div className='flex flex-col items-start justify-center  col-span-4'>
                                                                                        <label htmlFor={`items[${index}].gtip`} className="block text-sm font-medium text-description">Gtip</label>
                                                                                        <Field className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" name={`items[${index}].gtip`} type="text" placeholder="GTIP No" />
                                                                                        <ErrorMessage name={`items[${index}].gtip`} component="div" className="rounded-md bg-red-50 p-1 my-1 text-xs text-rexRed" />
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        ))
                                                                    )}
                                                                </FieldArray>
                                                            </div>
                                                            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                                                <button
                                                                    type="submit"
                                                                    className="inline-flex w-full justify-center rounded-md bg-headerPrimary px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                                                                // onClick={() => setOpenCreateTicket(false)}
                                                                >
                                                                    {isLoading ? <>
                                                                        <DotLoader
                                                                            color={'#FFFFFF'}
                                                                            className='w-full'
                                                                            // loading={loading}
                                                                            cssOverride={override}
                                                                            size={20}
                                                                            aria-label="Loading Spinner"
                                                                            data-testid="loader"
                                                                        />
                                                                        {t?.('orders.filters.createTicket')}
                                                                    </>

                                                                        :
                                                                        <>
                                                                            <AddCircle className='w-4 h-4 inline-block mr-2' />
                                                                            {t?.('orders.filters.createTicket')}
                                                                        </>}
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                                                    onClick={() => calculate(formikProps.values, formikProps.setFieldError)}
                                                                >
                                                                    {calIsLoading ? <>
                                                                        <DotLoader
                                                                            color={'#1F254B'}
                                                                            className='w-full'
                                                                            // loading={loading}
                                                                            cssOverride={override}
                                                                            size={20}
                                                                            aria-label="Loading Spinner"
                                                                            data-testid="loader"
                                                                        />
                                                                        Fiyat Hesapla                                                                    </>

                                                                        :
                                                                        <>
                                                                            <Calculator className='w-4 h-4 inline-block mr-2' />
                                                                            Fiyat Hesapla
                                                                        </>}
                                                                </button>
                                                            </div>
                                                        </Form>
                                                    )
                                                }}
                                            </Formik> : <DotLoaderSpinner loading={isDataLoaded} />}

                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
export default CreateTicketModal

