import React from 'react';

import PasswordChange from './PasswordChange';
import ProfileUpdate from './ProfileUpdate';

const EditProfileWrapper = () => {
  return (
    <div className=" flex flex-1 flex-col gap-y-2 flex-shrink-0 mt-2">
      <ProfileUpdate />
      <PasswordChange />
    </div>
  );
};

export default EditProfileWrapper;
