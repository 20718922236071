/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Medal, Star, UserOctagon } from 'iconsax-react';
import React, { useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';

import { useLanguage } from '../../context/LanguageContext';
import memberService from '../../services/memberService';

const SparklinesWrapper = () => {
  const { t } = useLanguage();
  const sparklinesQuery = useQuery({
    queryKey: ['dashboard'],
    queryFn: () => memberService.getSparklineStats(),
    staleTime: 600000, // Veriler 1 dakika boyunca önbellekte tutulacak
    initialData: {
      status: '',
      data: {
        userCount: '',
        producerCount: '',
        productCount: '',
        serviceProviderCount: '',
      },
    },
  });

  useEffect(() => {
    sparklinesQuery.refetch(); // Sayfa yeniden yüklendiğinde isteği yeniden çağırma
  }, []);

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-4">
      {/* GRAP -1 E-Ticaret Girişimcisi */}
      <div className="bg-white pt-2 px-2 shadow-lg rounded-md  grid grid-cols-4">
        <div className="col-span-3 ">
          <p className="px-4 pt-2 font-semibold text-[#DB2777]">
            {t?.('dashboard.headerCard.ecommerce')}
          </p>
          <p className="h-16 font-bold px-4 py-2 text-4xl text-[#EC4899]">
            {sparklinesQuery.data?.data.userCount}
          </p>
        </div>
        <div className="flex justify-end col-span-1 pt-2">
          <UserOctagon size={80} color="#DB2777" />
        </div>
      </div>

      {/* GRAP -2 Tedarikçi */}
      <div className="bg-white pt-2 px-2 shadow-lg rounded-md  grid grid-cols-4">
        <div className="col-span-3">
          <p className="px-4 py-2 font-semibold text-[#2563EB]">
            {t?.('dashboard.headerCard.producer')}
          </p>
          <p className="h-16 font-bold px-6 py-2 text-4xl text-[#3B82F6]">
            {sparklinesQuery.data?.data.producerCount}
          </p>
        </div>
        <div className="flex justify-end col-span-1 pt-2">
          <Medal size={80} color="#2563EB" />
        </div>
      </div>

      {/* GRAP -3 Hizmet Sağlayıcı */}
      <div className="bg-white pt-2 px-2 shadow-lg rounded-md  grid grid-cols-4">
        <div className="col-span-3">
          <p className="px-4 py-2 font-semibold text-[#059669]">
            {t?.('dashboard.headerCard.service')}
          </p>
          <p className="h-16 font-bold px-6 py-2 text-4xl text-[#10B981]">
            {sparklinesQuery.data?.data.serviceProviderCount}
          </p>
        </div>
        <div className="flex justify-end col-span-1 pt-2">
          <Star size={80} color="#059669" />
        </div>
      </div>

      {/* GRAP -4 Ürün Sayısı */}
      <div className="bg-white pt-2 px-2 shadow-lg rounded-md  grid grid-cols-4">
        <div className="col-span-3">
          <p className="px-4 py-2 font-semibold text-[#EA580C]">
            {t?.('dashboard.headerCard.product')}
          </p>
          <p className="h-16 font-bold px-6 py-2 text-4xl text-[#F97316]">
            {sparklinesQuery.data?.data.productCount}
          </p>
        </div>
        <div className="flex justify-end col-span-1 pt-2">
          <Box size={80} color="#EA580C" />
        </div>
      </div>
    </div>
  );
};

export default SparklinesWrapper;
