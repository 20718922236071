import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import trendyolToolsService from '../../../services/trendyolToolsService';
import { DummyWeeklyValues } from '../DummyDatasAndTypes';
import { useAuth } from '../../../context/AuthContext';
import { seperateNumberWithComma } from '../../../utils/numberFormatter';



interface ApiResponse {
    status: string;
    data: { date: string; value: number }[];
}
interface BarChartProps {
}
interface Stats {
    week: string;
    total_revenue: number;
}
interface ChartData {
    dates: string[];
    values: number[];
}

const TotalTurnoverBarChart: React.FC<BarChartProps> = () => {
    const [chartData, setChartData] = useState<ChartData>({ dates: [], values: [] });
    const { session } = useAuth()
    const [showBarChartAlert, setShowBarChartAlert] = useState(false)
    const [stats, setStats] = useState<Stats[]>([]);


    useEffect(() => {
        const fetchData = async () => {
            if (session?.producerId && !session?.isTrialEnd) {
                try {
                    const res: ApiResponse = await trendyolToolsService.getSellerDashboardChartsData(session.producerId, true);
                    const apiStats = res.data.map(({ date, value }) => ({
                        week: date,
                        total_revenue: value,
                    }));
                    if (apiStats.length) {
                        setStats(apiStats);
                    } else {
                        setShowBarChartAlert(true)
                    }
                } catch (err) {
                    console.log(err);
                }
            } else if (!session?.isTrendyolIntegrated) {
                const demoStats = DummyWeeklyValues.map(({ week, total_revenue }) => ({
                    week,
                    total_revenue: Number(total_revenue), // Convert the string to a number
                }));
                setStats(demoStats);
            }
        };
        fetchData();
    }, []);



    useEffect(() => {
        let dateArr: string[] = [];
        let valuesArr: number[] = [];

        stats.map((el: Stats) => {
            dateArr.push(el.week);
            valuesArr.push(Number(el.total_revenue));
        });

        setChartData({ dates: dateArr.reverse(), values: valuesArr.reverse() });
    }, [stats]);


    const chartOptions = {
        options: {
            chart: {
                height: 350,
                toolbar: {
                    show: false,
                },
            },
            plotOptions: {
                bar: {
                    columnWidth: '65%',
                    distributed: true,
                    borderRadius: 5,
                },
            },
            dataLabels: {
                enabled: false,
            },
            legend: {
                show: false,
            },
            xaxis: {
                categories: chartData.dates,
                labels: {
                    style: {
                        fontSize: '12px',
                    },
                },
            },
            toolbar: {
                show: false,
            },
            tooltip: {
                custom: ({
                    seriesIndex,
                    dataPointIndex,
                    w,
                }: {
                    seriesIndex: number;
                    dataPointIndex: number;
                    w: any;
                }) => {
                    const data = seperateNumberWithComma(w.globals.series[seriesIndex][dataPointIndex]);
                    const date = chartData.dates[dataPointIndex];
                    const tooltipText = `<span className="block bg-primary text-white p-2">Toplam Ciro: ${data} ₺</span>`;
                    return tooltipText;
                },
            },
            colors: ['#6F7AC3'], // Renkleri burada değiştirebilirsiniz.
        },
    };

    const series = [{ data: chartData.values }]; // Chart'ta kullanılacak değerler





    return (
        <div className='relative'>
            <div className='flex items-center justify-end m-2'>
                <div className="hidden">
                    <label htmlFor="tabs" className="sr-only">
                        Select a tab
                    </label>
                    <select
                        id="tabs"
                        name="tabs"
                        className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                    >
                    </select>
                </div>
                <div className="hidden sm:block">
                    <nav className="flex space-x-4" aria-label="Tabs">
                        <span
                            className='text-gray-500 hover:text-gray-700 rounded-md px-3 py-2 text-sm font-medium cursor-pointer'
                        >
                        </span>
                    </nav>
                </div>
            </div>
            {/* Bar Chart */}
            <ReactApexChart
                options={chartOptions.options}
                series={series}
                type="bar"
                height={350}
            />
            {showBarChartAlert &&
                <div className='absolute top-[40%] md:left-[40%] left-[10%] inline-flex items-center gap-x-1.5 rounded-md bg-[#6F7AC3] px-3 py-2 text-white shadow-sm min-w-[260px]'>
                    Henüz Satışınız Bulunmamaktadır
                </div>
            }
        </div>
    );
};

export default TotalTurnoverBarChart;
