import { TickCircle } from 'iconsax-react';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { StarIcon } from '@heroicons/react/24/solid';

import { useAuth } from '../../context/AuthContext';
// import { useAuth } from '../../context/AuthContext';
import { useLanguage } from '../../context/LanguageContext';

const premiumPackageInfoTr = [
  { id: uuidv4(), info: 'Tedarik Sistemine Katılma Hakkı' },
  { id: uuidv4(), info: 'Ürün ve Kargoda %10 İndirim' },
  { id: uuidv4(), info: 'Tedarik Sistemi Eğitimi' },
  { id: uuidv4(), info: 'Amazon Master Eğitimi' },
  { id: uuidv4(), info: 'Etsy Master Eğitimi' },
  { id: uuidv4(), info: 'Trendyol Master Eğitimi' },
  { id: uuidv4(), info: 'E-ticaret Danışmanlığı Yapma Eğitimi' },
  { id: uuidv4(), info: 'Amazon Arbitrage Eğitimi' },
  { id: uuidv4(), info: 'Canlı Eğitim Kaynakları' },
  { id: uuidv4(), info: 'Tedarik Sistemi Eğitimi' },
  { id: uuidv4(), info: 'Amazon Ürün Bulma  Aracı' },
  { id: uuidv4(), info: 'Etsy Çok Satan Satıcı ve Ürünler Aracı' },
  { id: uuidv4(), info: 'Etsy Popüler Satıcı ve Ürünler Aracı' },
  { id: uuidv4(), info: 'Etsy Rakip Analizi Aracı' },
  { id: uuidv4(), info: 'Özel Whatsapp Grubu' },
  { id: uuidv4(), info: 'Anlık Soru/Cevap Desteği' },
  { id: uuidv4(), info: 'Grup İçi Yardımlaşma' },
  { id: uuidv4(), info: 'Network İmkanı' },
  { id: uuidv4(), info: 'Uluslararası Sertifika Kazanma İmkanı' },
];
const premiumPackageInfoEn = [
  { id: uuidv4(), info: 'Amazon Master Training' },
  { id: uuidv4(), info: 'Etsy Master Training' },
  { id: uuidv4(), info: 'Trendyol Master Training' },
  { id: uuidv4(), info: 'E-commerce Consultancy Training' },
  { id: uuidv4(), info: 'Amazon Arbitrage Training' },
  { id: uuidv4(), info: 'Live Training Resources' },
  { id: uuidv4(), info: 'Amazon Arbitrage Tool' },
  { id: uuidv4(), info: 'AI Review Analysis Tool' },
  { id: uuidv4(), info: 'Etsy Best Sellers and Products Tool' },
  { id: uuidv4(), info: 'Etsy Popular Sellers and Products Tool' },
  { id: uuidv4(), info: 'Etsy Competitor Analysis Tool' },
  { id: uuidv4(), info: 'Special Discord Group' },
  { id: uuidv4(), info: 'Instant Q&A Support' },
  { id: uuidv4(), info: 'Group Collaboration' },
  { id: uuidv4(), info: 'Networking Opportunity' },
  { id: uuidv4(), info: 'Service Provider Opportunity' },
  { id: uuidv4(), info: 'International Certification Opportunity' },
];

const plusPackageInfoTr = [
  { id: uuidv4(), info: 'Tedarik Sistemine Katılma Hakkı' },
  { id: uuidv4(), info: 'Ürün ve Kargoda %5 İndirim' },
  { id: uuidv4(), info: 'Tedarik Sistemi Eğitimi' },
  { id: uuidv4(), info: 'Özel Whatsapp Grubu' },
  { id: uuidv4(), info: 'Anlık Soru/Cevap Desteği' },
  { id: uuidv4(), info: 'Grup içi Yardımlaşma' },
  { id: uuidv4(), info: 'Amazon Ürün Araştırma Aracı' },
  { id: uuidv4(), info: 'Etsy Çok Satan Satıcı ve Ürünler Aracı' },
  { id: uuidv4(), info: 'Etsy Popüler Satıcı ve Ürünler Aracı' },
  { id: uuidv4(), info: 'Etsy Rakip Analizi Aracı' },
];
const plusPackageInfoEn = [
  { id: uuidv4(), info: 'Special Discord Group' },
  { id: uuidv4(), info: 'Instant Q&A Support' },
  { id: uuidv4(), info: 'Group Collaboration' },
  { id: uuidv4(), info: 'Service Provider Opportunity' },
  { id: uuidv4(), info: 'International Certification Right' },
  { id: uuidv4(), info: 'Amazon Product Research Tool' },
  { id: uuidv4(), info: 'Amazon Arbitrage Tool (US-Canada)' },
  { id: uuidv4(), info: 'Amazon Forecast Tool' },
  { id: uuidv4(), info: 'AI Review Analysis Tool' },
  { id: uuidv4(), info: 'Etsy Best Sellers and Products Tool' },
  { id: uuidv4(), info: 'Etsy Popular Sellers and Products Tool' },
  { id: uuidv4(), info: 'Etsy Competitor Analysis Tool' },
  // { id: uuidv4(), info: 'Special Shipping Discount Rate (10%)' },
  // { id: uuidv4(), info: 'Amazon Keyword Tool (Coming Soon)' },
  // { id: uuidv4(), info: 'Amazon Plugin (Coming Soon)' },
  // { id: uuidv4(), info: 'Etsy Plugin (Coming Soon)' },
  // { id: uuidv4(), info: 'Competitor Tracking Tool for Amazon and Etsy (Coming Soon)' },
  // { id: uuidv4(), info: 'Amazon Product Listing Tool (Coming Soon)' },
  // { id: uuidv4(), info: 'Etsy Product Listing Tool (Coming Soon)' }
];

interface Props {
  planInfo: any;
  country: string;
}
const PackageInfo: React.FC<Props> = ({ planInfo, country }) => {
  const { session } = useAuth();
  const { t } = useLanguage();
  const params = useParams();
  const [plan, setPlan] = React.useState<any>([]);

  useEffect(() => {
    if (session) {
      if (
        session?.location !== 'TR' &&
        (params.plan === '122' || params.plan === '123')
      ) {
        setPlan(plusPackageInfoEn);
      } else if (
        session?.location === 'TR' &&
        (params.plan === '120' || params.plan === '121')
      ) {
        setPlan(plusPackageInfoTr);
      } else if (
        session?.location !== 'TR' &&
        (params.plan === '107' || params.plan === '106')
      ) {
        setPlan(premiumPackageInfoEn);
      } else if (
        session?.location === 'TR' &&
        (params.plan === '114' || params.plan === '108')
      ) {
        setPlan(premiumPackageInfoTr);
      }
    } else {
      if (
        // country !== 'TR' &&
        params.plan === '122' ||
        params.plan === '123'
      ) {
        setPlan(plusPackageInfoEn);
      } else if (
        // country === 'TR' &&
        params.plan === '120' ||
        params.plan === '121'
      ) {
        setPlan(plusPackageInfoTr);
      } else if (
        // country !== 'TR' &&
        params.plan === '107' ||
        params.plan === '106'
      ) {
        setPlan(premiumPackageInfoEn);
      } else if (
        // country === 'TR' &&
        params.plan === '114' ||
        params.plan === '108'
      ) {
        setPlan(premiumPackageInfoTr);
      }
    }
  }, [country]);
  return (
    <div className="col-span-12 xl:col-span-6 bg-lightGray rounded-xl shadow-lg relative mx-10">
      <img
        src="/images/badgereturn.png"
        alt="badge"
        className="absolute -top-6 -right-4 hidden sm:block"
      />
      <div className="flex flex-col justify-between h-full">
        <p className="text-2xl text-white bg-secondary text-center py-6 rounded-t-xl font-bold">
          {/* {t?.('payment.packagePlan')} */}
          {planInfo.package.name}{' '}
          {session?.location === 'TR' ? 'Paketi' : 'Package'}
        </p>
        <ul className="pl-8 mt-8">
          {plan.map((item: any) => {
            return (
              <li
                key={item.id}
                className="text-primary pt-2 flex items-center space-x-2 w-full px-2"
              >
                <div className="w-1/10">
                  <TickCircle className="text-secondary" />
                </div>
                <p className="text-sm font-medium w-9/10">{item.info}</p>
              </li>
            );
          })}
        </ul>
        <div className="my-8">
          {/* {planInfo.id === 53 && (
            <p className="text-rexRed font-medium text-4xl line-through text-center mb-4">
              1500₺
            </p>
          )} */}
          <p className="text-secondary text-center text-2xl text-bold">
            {' '}
            <span className="text-7xl font-extrabold">
              {Number(planInfo.price).toFixed(0)}
              {planInfo.currency === 'USD' ? '$' : '₺'}
            </span>{' '}
            {planInfo.duration === 12 ? '/Yıllık' : '/Aylık'}
            {/* {planInfo.duration === 12 && country === 'TR'
              ? '/Yıllık'
              : planInfo.duration === 12 && country !== 'TR'
              ? '/Yearly'
              : planInfo.duration === 1 && country === 'TR'
              ? '/Aylık'
              : planInfo.duration === 1 && country !== 'TR'
              ? '/Monthly'
              : null} */}
          </p>
        </div>

        {/* GOOGLE REVİEW SECTION */}

        <div className="bg-white py-8 rounded-b-xl w-full">
          <div className="flex justify-center">
            <img src="/images/googleIcon.png" alt="google" />
            <div className="flex flex-col items-start justify-center ml-2">
              <p className="text-primary text-xl font-bold tracking-wide">
                Google Reviews
              </p>
              <div className="flex">
                <p className="text-xl font-bold mr-2">5.0</p>

                {Array.from({ length: 5 }, (_, index) => {
                  return <StarIcon width={24} height={24} color="#FFC700" />;
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackageInfo;
