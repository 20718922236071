interface AddressPatchProps {
    title: string
    icon: React.ElementType;
}
const AddressPatch: React.FC<AddressPatchProps> = ({
    title,
    icon: IconComponent
}) => {
    return (
        <div className='w-full sm:w-[50%] lg:w-[40%] xl:w-[25%] 2xl:w-[20%] bg-[#A5F3FC] h-[60px] flex items-center justify-start px-4 rounded-br-lg rounded-tr-lg my-16 '>
            <IconComponent className='mr-2 text-headerPrimary' />
            <p className='font-semibold text-headerPrimary'>
                {title}
            </p>
        </div>
    )
}

export default AddressPatch