import React, { useEffect, useState } from 'react';
import { useAuth } from '../../context/AuthContext';
import memberService from '../../services/memberService';
import appendIyzicoScript from '../../utils/appendIyzicoForm';
import { useLocation, useParams } from 'react-router-dom';
import StripeCheckoutForm from './StripeCheckoutForm';
import { ToastContainer } from 'react-toastify';
import alertNotification from '../../utils/alertNotification';
import { classNames } from '../../utils/conditionalClasses';
import { useLanguage } from '../../context/LanguageContext';

interface PaymentInfoProps {
  setPlanInfo: (paymentInfo: any) => void;
  disablePayment: boolean;
  planInfo: any;
  country: string;
}

const PaymentActions = ({
  setPlanInfo,
  disablePayment,
  planInfo,
  country,
}: PaymentInfoProps) => {
  const { session } = useAuth();
  const { t } = useLanguage();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const couponValue = searchParams.get('coupon');

  const [error, setError] = useState({ message: '' });
  const [couponCode, setCouponCode] = useState('');

  const params = useParams();
  const handleCouponChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCouponCode(e.target.value.toUpperCase());
  };

  const handleCouponApply = async () => {
    try {
      if (session?.location === 'TR') {
        let res = await memberService.getPlanByCoupon(
          couponCode,
          session?.location
        );
        window.location.href = `/payment/${res.data.plan.id}?coupon=${couponCode}&status=payment`;
      } else {
        window.location.href = `/payment/${params.plan}?coupon=${couponCode}&status=payment`;
      }
    } catch (error: any) {
      alertNotification('error', error.response.data.message);
    }
  };
  const handlePayment = async (plan: any) => {
    try {
      let coupon = '';
      if (couponValue !== undefined && couponValue !== null) {
        coupon = couponValue.toUpperCase();
        setCouponCode(coupon);
      }

      let res = await memberService.getPaymentForm({
        couponCode: coupon,
        userId: session?.id,
        productId: plan.id,
      });
      if (!disablePayment) {
        appendIyzicoScript(
          'subs',
          res.data,
          'begin_checkout',
          couponCode,
          plan.id,
          plan.package.name,
          plan.discount,
          plan.price
        );
      }
    } catch (error: any) {
      setError(error.response.data);
    }
  };

  useEffect(() => {
    if (session?.location === 'TR') {
      if (
        planInfo !== undefined &&
        session !== undefined &&
        typeof couponCode === 'string' &&
        planInfo.id !== 1
      ) {
        // get coupon code from query
        handlePayment(planInfo);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [planInfo, disablePayment]);

  return (
    <div className=" p-4 sm:p-7 sm:min-h-[73vh]">
      <div className="pb-2 sm:p-0">
        <div className="flex flex-col">
          <h3 className="text-headerPrimary font-medium cardHeading">
            {t?.('payment.checkout.title')}
          </h3>
          <div className="flex items-center mt-4">
            <input
              type="text"
              className="py-2 px-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary"
              placeholder={t?.('payment.checkout.coupon')}
              value={couponCode}
              onChange={handleCouponChange}
            />
            <button
              className={classNames(
                !couponCode
                  ? ' bg-cardBorder'
                  : 'bg-headerPrimary cursor-pointer',
                'ml-2 py-1 sm:py-2 px-4 text-xs sm:text-base font-semibold text-white rounded-lg'
              )}
              onClick={handleCouponApply}
              disabled={!couponCode ? true : false}
            >
              {t?.('payment.checkout.submit')}
            </button>
          </div>
          <div id="iyzico-container">
            <div
              id="iyzipay-checkout-form"
              className="responsive"
              style={{ marginTop: '20px' }}
            ></div>
          </div>
          {disablePayment ? (
            <>
              <div className="h-full flex flex-col items-center justify-center">
                <p className="text-center">
                  Lütfen Fatura bilgilerinizi güncelleyiniz
                </p>
              </div>
            </>
          ) : (
            <div className="h-full flex flex-col items-center justify-center">
              <p className="text-center text-red-500">
                {country === 'TR' && error.message}
              </p>
            </div>
          )}
          {session?.location !== 'TR' && (
            <StripeCheckoutForm country={country} />
          )}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default PaymentActions;
