import { AxiosResponse } from 'axios';
import paymentClient from '../utils/paymentClient';

class StripeService {
  responseBody = (response: AxiosResponse) => response.data;

  // Get main categories
  public async initializeCheckout(payload: {
    userId: number;
    packagePlanId: number;
    couponCode?: string;
  }) {
    const { data } = await paymentClient.post(
      `/api/v1/payments/stripe/subscription`,
      payload
    );
    return data;
  }
  public async completeSubscription(
    userId: number,
    packagePlanId: number,
    sessionId: string
  ) {
    const { data } = await paymentClient.get(
      `/api/v1/payments/stripe/subscription/${userId}/${packagePlanId}?session_id=${sessionId}`
    );
    return data;
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new StripeService();
