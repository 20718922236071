// might be also configured according to client ip
export default function decideInitialLang(setLang: string) {
  // declare allowed languages
  //TODO:if you decide to use translation just add in this array 'en'
  // var allowedLangs = ['tr', 'en'];
  var allowedLangs = ['tr'];

  // check localstorage for current lang
  const initialLang = localStorage.getItem('currentLang');
  if (!initialLang) {
    // assign if there is a valid default language
    if (setLang && allowedLangs.indexOf(setLang) > -1) {
      localStorage.setItem('currentLang', setLang);
      return setLang;
    }
    // detect browser language
    // @ts-ignore
    // let browserLanguage =
    //   window.navigator.language || window.navigator['language'];
    // assign if there is a valid browser language
    // if (browserLanguage && allowedLangs.indexOf(browserLanguage) > -1) {
    //   localStorage.setItem('currentLang', browserLanguage);
    //   //TODO:browserLanguage
    //   return 'tr';
    // }
    // assign a default language(tr)
    localStorage.setItem('currentLang', 'tr');
    return 'tr';
  } else {
    // set setInitialLang from localStorage if not malformed
    // console.log(allowedLangs.indexOf(initialLang));
    if (allowedLangs.indexOf(initialLang) > -1) {
      //initialLang olacak
      return initialLang;
    } else {
      return 'tr';
    }
  }
}
