import { ArrowDown2, ArrowUp2, SearchNormal } from 'iconsax-react';
import React, { useState } from 'react';
import { Listbox } from '@headlessui/react';
import { CheckIcon } from '@heroicons/react/20/solid';
import { Store } from '../../pages/MarketplaceSuppliersProducts';
import { useAuth } from '../../context/AuthContext';

interface FilterProps {
    shops: Store[];
    selected: Store;
    setSelected: React.Dispatch<React.SetStateAction<Store>>;
    handleSubmit: (selectedShop: Store, searchValue: string) => void;
}

const Filters: React.FC<FilterProps> = ({
    shops,
    selected,
    setSelected,
    handleSubmit
}) => {
    // Input değeri için state
    const [searchValue, setSearchValue] = useState('');
    const { session } = useAuth()
    return (
        <div className='grid grid-cols-10 gap-4 md:gap-6 items-center justify-center mb-[64px]'>
            {/* Mağazalar Seçimi */}
            <div className='col-span-10 lg:col-span-4 xl:col-span-2 flex flex-col'>
                <Listbox value={selected} onChange={setSelected}>
                    {({ open }) => (
                        <>
                            <Listbox.Label className="block font-medium text-[#1F2937] mb-1">
                                Mağazalar
                            </Listbox.Label>
                            <div className="relative">
                                <Listbox.Button
                                    style={{ boxShadow: "0px 4px 12px rgba(17, 24, 39, 0.15)" }}
                                    className="relative w-full rounded-[6px] cursor-default shadow-lg bg-[#f9fafb] py-[8px] px-[16px] text-left focus:outline-none sm:text-sm sm:leading-6">
                                    <span className="text-[#9CA3AF] text-base font-normal leading-6 tracking-wide [font-feature-settings:'liga'_off]">
                                        {selected ? selected.name : 'Mağaza seçiniz'}
                                    </span>
                                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                        {open ? (
                                            <ArrowUp2 variant='Bold' aria-hidden="true" className="h-5 w-5 text-[#374151]" />
                                        ) : (
                                            <ArrowDown2 variant='Bold' aria-hidden="true" className="h-5 w-5 text-[#374151]" />
                                        )}
                                    </span>
                                </Listbox.Button>
                                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    {shops.map((shop) => (
                                        <Listbox.Option
                                            key={shop.id}
                                            value={shop}
                                            className="group relative cursor-default select-none py-2 pl-3 pr-9 text-gray-900 data-[focus]:bg-indigo-600 data-[focus]:text-white"
                                        >
                                            <span className="block truncate font-normal group-data-[selected]:font-semibold">
                                                {shop.name}
                                            </span>
                                            <span className="absolute inset-y-0 right-0 flex items-center pr-4 text-indigo-600 group-data-[focus]:text-white [.group:not([data-selected])_&]:hidden">
                                                <CheckIcon aria-hidden="true" className="h-5 w-5" />
                                            </span>
                                        </Listbox.Option>
                                    ))}
                                </Listbox.Options>
                            </div>
                        </>
                    )}
                </Listbox>
            </div>

            {/* Müşteri İsmi Ara */}
            <div className="col-span-10 lg:col-span-4 xl:col-span-2 relative lg:flex lg:items-end mt-[16px] lg:mt-0 shadow-lg bg-[#f9fafb] rounded-[6px]"
                style={{ boxShadow: "0px 4px 12px rgba(17, 24, 39, 0.15)" }}
            >
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <SearchNormal aria-hidden="true" className="h-5 w-5 text-gray-400" />
                </div>
                <input
                    placeholder="Müşteri ismi ile sipariş ara"
                    value={searchValue} // Input değerini state'den alıyoruz
                    onChange={(e) => setSearchValue(e.target.value)} // Input değişikliğini yakalıyoruz
                    className="block w-full border-0 py-[8px] bg-[#f9fafb] rounded-[6px] px-10 text-[#9CA3AF] placeholder:text-gray-400 sm:text-sm sm:leading-6"
                />
            </div>

            {/* Filtrele Butonu */}
            <div className='col-span-10 lg:col-span-2 xl:col-span-1 w-full lg:flex lg:justify-end mt-[16px] lg:mt-0'>
                <button
                    onClick={() => session?.isMarketplaceSeller && handleSubmit(selected, searchValue)}
                    className='w-full lg:w-auto bg-[#1D4ED8] text-[#f9fafb] text-center py-[12px] px-[10px] rounded-[6px] font-semibold'>
                    Filtrele
                </button>
            </div>
        </div>
    );
};

export default Filters;
