import { format, isSameDay, parseISO } from 'date-fns';
import { tr } from 'date-fns/locale';
import { Camera, CloseSquare, Paperclip, Profile2User, ProfileRemove, Send2 } from 'iconsax-react';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { IMAGE_URL } from '../../config';
import { useAuth } from '../../context/AuthContext';
import { useLanguage } from '../../context/LanguageContext';
import ErrorFallback from '../../pages/errors/ErrorFallback';
import memberService from '../../services/memberService';
import { classNames } from '../../utils/conditionalClasses';
import DotLoaderSpinner from '../common/DotLoaderSpinner';
import MessageImageModal from './MessageImageModal';

type MessagesResponse = {
    id: number | null;
    firstName: string;
    lastName: string;
    receiverId: number;
    messages: Message[];
};

type Message = {
    id: number;
    messageContent: string;
    messageType: string;
    isRead: boolean;
    createdAt: string;
    receiverId: number;
    sender: {
        firstName: string;
        lastName: string;
        id: number;
    };
};
interface ContactDetail {
    id: number;
    firstName: string;
    lastName: string;
    lastMessageDate: string
    recieverId: number;
    profileImage: string
    unreadCount: number;
    lastMessage?: string;
    lastMessageType?: string
}
const MobileMessageWrapper = () => {
    const { session } = useAuth()
    const { language } = useLanguage();
    // const [loading, setLoading] = useState(false)
    const [initialLoading, setInitialLoading] = useState(false);
    const [backgroundLoading, setBackgroundLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false)
    const [noMessage, setNoMessage] = useState(false)
    const [contactInfo, setContactInfo] = useState<{
        name: string,
        id: number,
        profileImg: string
    }>()
    const [contacts, setContacts] = useState<ContactDetail[]>([])
    const [messages, setMessages] = useState<MessagesResponse | null>()
    const [messageContent, setMessageContent] = useState('');
    const [image, setImageFile] = useState<File | null>(null);
    const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>(null);
    // let fileInputRef = useRef<HTMLInputElement>(null);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);

    const toValue = searchParams.get('to');
    const nameValue = searchParams.get('name');
    // const imgValue = searchParams.get('image')
    const profileValue = searchParams.get('profile')


    const lastMessageRef = useRef<HTMLDivElement | null>(null);

    const handleContactClick = (contact: ContactDetail) => {
        setContactInfo({ name: contact.firstName + ' ' + contact.lastName, id: contact.id, profileImg: contact.profileImage });
        // Okunmamış mesaj sayısını sıfırlayın
        const updatedContacts = contacts.map(c => c.id === contact.id ? { ...c, unreadCount: 0 } : c);
        setContacts(updatedContacts);
    };

    const handleMessageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setMessageContent(e.target.value);
    };


    const handleFirstMessage = async (contacts: ContactDetail[]) => {
        if (contacts.length === 0 && !toValue) {
            setNoMessage(true)
            return
        }
        setContacts(contacts)
        if (toValue) {
            const existingContact = contacts.find(c => c.recieverId === parseInt(toValue));
            if (existingContact) {
                setContactInfo({
                    name: `${existingContact.firstName} ${existingContact.lastName}`,
                    id: existingContact.recieverId,
                    profileImg: (existingContact.profileImage !== 'null' && existingContact.profileImage !== undefined && existingContact.profileImage !== null) ? existingContact.profileImage : ''
                });
            } else {
                const newContact: ContactDetail = {
                    id: parseInt(toValue),
                    firstName: nameValue || '',
                    lastName: '',
                    recieverId: parseInt(toValue),
                    profileImage: (profileValue !== 'null' && profileValue !== undefined && profileValue !== null) ? profileValue : '',
                    unreadCount: 0,
                    lastMessageType: '',
                    lastMessageDate: ''
                };
                setContactInfo({
                    name: nameValue || '',
                    id: parseInt(toValue),
                    profileImg: (profileValue !== 'null' && profileValue !== undefined && profileValue !== null) ? profileValue : ''
                });
                setContacts([...contacts, newContact]);
            }
        } else {
            if (!contactInfo)
                setContactInfo({
                    name: `${contacts[0]?.firstName} ${contacts[0]?.lastName}`,
                    id: contacts[0]?.id,
                    profileImg: contacts[0]?.profileImage
                })
        }
    }

    const getContacts = async () => {
        // setInitialLoading(true);
        try {
            const res = await memberService.getContacts();
            handleFirstMessage(res.data);
        } catch (err) {
            setError(true);
        } finally {
            setInitialLoading(false);
        }
    };



    const getMessages = async () => {
        setBackgroundLoading(true);
        try {
            if (contactInfo?.id) {
                const res = await memberService.getMessages(contactInfo.id);
                setMessages(res.data);
            }
        } catch (err) {
            setError(true);
        } finally {
            setBackgroundLoading(false);
        }
    };


    const sendMessage = async () => {
        if (!messageContent.trim()) return;
        setBackgroundLoading(true);
        const formData = new FormData();
        formData.append('messageContent', messageContent);
        if (image) {
            formData.append('image', image);
        }
        try {
            if (contactInfo?.id) {
                await memberService.sendUserMessage(contactInfo?.id, formData);
                setMessageContent('');
                await getMessages();
            }
        } catch (err) {
            setError(true);
            console.error('Error sending message:', err);
        } finally {
            setBackgroundLoading(false);
        }
    };



    useEffect(() => {
        getMessages()
    }, [contactInfo])
    useEffect(() => {
        getContacts();
    }, []);

    useEffect(() => {
        const intervalId = setInterval(() => {
            getMessages();
            getContacts()
        }, 10000);

        return () => clearInterval(intervalId);
    }, [contactInfo]);

    // useEffect(() => {
    //     if (messages?.messages) {
    //         scrollToBottom();
    //     }
    // }, [messages])

    // Saat formatlama fonksiyonu
    const formatTime = (timeStr: string) => format(parseISO(timeStr), 'HH:mm');

    // Tarih formatlama fonksiyonu
    const formatDate = (dateStr: string) => format(parseISO(dateStr), 'd MMMM yyyy', { locale: tr });

    return (
        < >
            {noMessage ?
                <div className="flex flex-col justify-center items-center p-10">
                    <h1 className="text-rexRed text-2xl">
                        😕
                    </h1>
                    <h2 className="text-base font-bold p-4">
                        {language === 'tr'
                            ? 'Henüz bir mesajınız bulunmamaktadır.'
                            : "You don't have any messages yet."}
                    </h2>
                </div> :
                <div className='rounded-lg bg-white grid grid-cols-6 items-center justify-center shadow-lg' >
                    {/* contacts list */}
                    <div className='col-span-1 border-r-2 h-full py-2 flex flex-col items-center md:items-start justify-start gap-4 overflow-auto'>
                        <Profile2User
                            variant="Bold"
                            className='text-[#2563EB] mb-4 md:hidden'
                        />
                        <p className='font-bold text-[#1F2937] hidden md:block pl-2'>
                            Sohbetler
                        </p>
                        {contacts?.map((contact) => (
                            <div onClick={() => {
                                handleContactClick(contact)
                                // setContactInfo({ name: contact.firstName + ' ' + contact.lastName, id: contact.id, profileImg: contact.profileImage });
                            }}
                                className={classNames(contact.recieverId === contactInfo?.id ? 'bg-[#DBEAFE]' : '', 'min-h-[50px] w-full flex flex-row items-center justify-center xl:justify-start pl-4 pr-2 gap-2 cursor-pointer relative')}>

                                <div className="relative">
                                    <img className="h-10 w-10 rounded-full"
                                        src={contact?.profileImage ? `${IMAGE_URL}${contact?.profileImage}` : `https://api.dicebear.com/5.x/bottts/svg?seed=${contact?.firstName}`}
                                        alt=""
                                        onError={(e) => {
                                            e.currentTarget.src = `https://api.dicebear.com/5.x/bottts/svg?seed=${contact?.firstName}`;
                                        }} />
                                    {contact?.unreadCount > 0 && (
                                        <div className="hidden min-[475px]:block absolute top-0 right-1 transform translate-x-1/4 -translate-y-1/4 bg-rexRed text-white text-xs px-1.5 py-0.5 rounded-full z-20">
                                            {contact.unreadCount}
                                        </div>
                                    )}
                                </div>

                                <div className='hidden xl:flex flex-col justify-center flex-grow'>
                                    <div className='text-xs xl:block text-[#2563EB] font-semibold'>
                                        {`${contact.firstName} ${contact.lastName}`.length > 20 ?
                                            `${`${contact.firstName} ${contact.lastName}`.slice(0, 17)}...` :
                                            `${contact.firstName} ${contact.lastName}`
                                        }
                                    </div>
                                    <div className='text-gray-500 text-xs'>
                                        {contact && contact.lastMessage ? (
                                            contact.lastMessageType === 'image' ?
                                                <div className='flex items-center justify-start gap-2'>
                                                    <Camera className='w-4 h-4' variant="Bold" />
                                                    <span>Fotoğraf</span>
                                                </div>
                                                :
                                                `${contact.lastMessage.slice(0, 17)}${contact.lastMessage.length > 20 ? '...' : ''}`
                                        ) : ''}

                                        {/* {contact.lastMessage ?  : ''} */}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>



                    {/* message */}
                    <div className='col-span-5 min-h-screen flex flex-col items-between justify-between'>
                        {/* username */}
                        <div className='flex items-center justify-start border-b-2 p-2 gap-4 sticky top-16 z-50 bg-white'>
                            <div>
                                <img
                                    className="h-12 w-auto rounded-full"
                                    src={contactInfo?.profileImg ? `${IMAGE_URL}${contactInfo?.profileImg}` : `https://api.dicebear.com/5.x/bottts/svg?seed=${contactInfo?.name}`}
                                    alt="user"
                                    onError={(e) => {
                                        e.currentTarget.src = `https://api.dicebear.com/5.x/bottts/svg?seed=${contactInfo?.name}`;
                                    }}
                                />

                            </div>
                            <div className='text-[#1F2937] text-xs font-semibold'>
                                {contactInfo?.name}
                            </div>
                        </div>
                        {initialLoading ? (
                            <DotLoaderSpinner loading={initialLoading} />
                        ) : (
                            <div className=' p-2  -my-12 overflow-x-auto overflow-y-auto h-[70vh] scrollbar-hide'>
                                {messages?.messages?.reduce((acc: any[], msg, idx, arr) => {
                                    const currentDate = parseISO(msg.createdAt);
                                    if (idx === 0 || !isSameDay(currentDate, parseISO(arr[idx - 1].createdAt))) {
                                        acc.push(
                                            <div key={msg.createdAt} className="text-center text-xs text-[#374151] font-semibold py-2">
                                                {formatDate(msg.createdAt)}
                                            </div>
                                        );
                                    }
                                    acc.push(
                                        <div
                                            ref={idx === arr.length - 1 ? lastMessageRef : null}
                                            key={msg.id}
                                            className={`${msg.sender.id === session?.id ? 'bg-[#BFDBFE] ml-auto' : 'bg-[#F9FAFB]'} text-[#374151] text-xs sm:text-md mb-2 py-2 px-2 rounded-md max-w-[80%]`}
                                        >
                                            {msg.messageType === 'image' ?
                                                <img className='max-h-[225px] max-w-[225px]' src={`${msg.messageContent}`} alt='sended' /> :
                                                <p>
                                                    {msg.messageContent}
                                                </p>}
                                            <div className="flex items-center justify-end w-full gap-2">
                                                <div className='text-[#374151] text-[9px]'>{formatTime(msg.createdAt)}</div>
                                                <div>{msg.isRead ?
                                                    <img src='./images/tick2.png' alt='read' className='w-4 h-2' />
                                                    :
                                                    <img src='./images/tick.png' alt='read' className='w-4 h-2' />
                                                }
                                                </div>
                                            </div>
                                        </div>
                                    );
                                    return acc;
                                }, [])}
                            </div>
                        )}
                        {/* input */}
                        <form onSubmit={(e) => { e.preventDefault(); sendMessage(); }} className="flex items-center space-x-2 sticky bottom-0 bg-white p-2">
                            <div className="relative flex-grow">
                                <input
                                    type="text"
                                    placeholder="Mesaj yaz..."
                                    value={messageContent}
                                    onChange={handleMessageChange}
                                    className={classNames(imagePreviewUrl ? 'h-20' : '', "w-full pl-4 pr-10 py-2 border border-gray-300 rounded-md focus:outline-none focus:border-blue-500")}
                                />
                                <Paperclip
                                    className="text-gray-500 absolute right-0 top-1/2 pr-3 inset-y-0 cursor-pointer h-8 w-8 transform -translate-y-1/2"
                                    onClick={() => setOpen(true)}
                                />
                            </div>
                            <button type="submit" className="bg-[#2563EB] text-white p-2 rounded-md">
                                <Send2 className="w-6 h-6" />
                            </button>
                        </form>


                    </div>
                    <MessageImageModal
                        getMessages={getMessages}
                        getContacts={getContacts}
                        id={contactInfo?.id}
                        open={open}
                        setOpen={setOpen} />
                </div>}

        </>
    )
}

export default MobileMessageWrapper
