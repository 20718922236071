import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import React, { Fragment, useState } from 'react';
import { useLanguage } from '../../context/LanguageContext';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { errorElement } from '../../utils/errorElement';
import LoadingComponent from '../common/LoadingComponent';

interface ModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  //   onSubmit: (values: any) => Promise<void>;
  onSubmit: (values: any) => void;
}

const AliExpressIntegrationModal: React.FC<ModalProps> = ({
  open,
  setOpen,
  onSubmit,
}) => {
  const { t } = useLanguage();

  return (
    <div>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative  transform overflow-hidden rounded-lg bg-white  pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-2xl ">
                  <div className="absolute top-0  right-0  pt-4 pr-4 block">
                    <button
                      type="button"
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      onClick={() => setOpen(false)}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="flex justify-between items-center min-h-[150px] border-b-2 w-full p-4">
                    <img
                      className="object-contain"
                      src="/assets/logos/rexven-logo-3.png"
                      alt="Rexven Logo"
                    />

                    <p className="ml-4 text-sm">
                      Ürünlerinizi Aliexpress'e entegre etmek için Aliexpress
                      Mağazanızı Rexven'e tanımlamanız gerekmektedir. Aşağıdaki
                      alana Api Key girerek entegrasyonu tamamlayabilirsiniz.
                    </p>
                  </div>
                  <div className="w-full mt-4 px-4">
                    {
                      <div>
                        {/* Entegration Form */}
                        <Formik
                          initialValues={{
                            apiKey: '',
                          }}
                          onSubmit={onSubmit}
                          validationSchema={Yup.object({
                            apiKey: Yup.string().required(
                              t?.('integration.errors.apiKey')
                            ),
                          })}
                        >
                          {({ handleSubmit, values, handleChange, errors }) => (
                            <form
                              className="flex  flex-col gap-y-6 mt-4"
                              onSubmit={handleSubmit}
                            >
                              <div className="flex justify-between items-center gap-x-3 sm:gap-x-1.5">
                                <label
                                  htmlFor="discountRate"
                                  className="font-medium text-center py-2 rounded-lg text-xs w-4/12 lg:w-5/12 xl:text-sm"
                                >
                                  AliExpress API Key
                                </label>
                                <div className="flex flex-1 justify-between gap-x-2">
                                  <input
                                    placeholder="********************"
                                    onChange={handleChange}
                                    type="text"
                                    name="apiKey"
                                    id="apiKey"
                                    className="flex w-[100%] text-xs rounded-md bg-white border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-headingPrimary sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                              {/* <div className="flex justify-between items-center gap-x-3 sm:gap-x-1.5">
                                <label
                                  htmlFor="phoneNumber"
                                  className="font-medium text-center py-2 rounded-lg text-xs w-4/12 lg:w-5/12 xl:text-sm"
                                >
                                  Telefon Numarası
                                </label>
                                <div className="flex flex-1 justify-between gap-x-2">
                                  <input
                                    placeholder="********************"
                                    onChange={handleChange}
                                    type="text"
                                    name="phoneNumber"
                                    id="phoneNumber"
                                    className="flex w-[100%] text-xs rounded-md bg-white border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-headingPrimary sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div>
                              <div className="flex justify-between items-center gap-x-3 sm:gap-x-1.5">
                                <label
                                  htmlFor="storeName"
                                  className="font-medium text-center py-2 rounded-lg text-xs w-4/12 lg:w-5/12 xl:text-sm"
                                >
                                  Mağaza İsmi
                                </label>
                                <div className="flex flex-1 justify-between gap-x-2">
                                  <input
                                    placeholder="********************"
                                    onChange={handleChange}
                                    type="text"
                                    name="storeName"
                                    id="storeName"
                                    className="flex w-[100%] text-xs rounded-md bg-white border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-headingPrimary sm:text-sm sm:leading-6"
                                  />
                                </div>
                              </div> */}
                              {errors.apiKey && errorElement(errors.apiKey)}

                              <div className="flex w-full justify-end gap-x-2 ">
                                <button
                                  type="submit"
                                  className="mt-1 rounded-md bg-headerPrimary py-2.5 px-8 text-xs font-semibold text-white shadow-sm hover:bg-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 md:text-sm"
                                >
                                  {t?.('common.buttons.register')}
                                </button>
                              </div>
                            </form>
                          )}
                        </Formik>
                      </div>
                    }
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default AliExpressIntegrationModal;
