import { ArrowSwapVertical, Lock1 } from 'iconsax-react';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import { useAuth } from '../../context/AuthContext';
// import ToolsAbsoluteBuyBox from '../common/ToolsAbsoluteBuyBox';
import { useLanguage } from '../../context/LanguageContext';
import memberService from '../../services/memberService';
import alertNotification from '../../utils/alertNotification';
import { seperateNumberWithComma } from '../../utils/numberFormatter';
import LoadingComponent from '../common/LoadingComponent';
import Pagination from '../common/Pagination';

export interface ExclusiveProduct {
  status: string;
  data: {
    products: {
      id: number;
      asin: string;
      productCost: string;
      shippingCost: string;
      leadTime: number;
      maxOrderQuantity: number;
      image: string;
      avgBsr: number;
      saleCount: number;
      monthlyRevenue: number;
      salePrice: number;
      amazonCost: number;
      fbaFee: number;
      profit: number;
      profitMargin: number;
      profitRate: number;
      profitHundred: number;
      investment: number;
      rivalCount: number;
      isAmazon: boolean;
      reviewCount: number;
    }[];
    totalPageCount: number;
  };
}

interface Fields {
  [key: string]: string;
}
function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}
const ProductTable = () => {
  const [query, setQuery] = useState<{ sortField: string; sortOrder: string }>({
    sortField: 'profitMargin',
    sortOrder: 'DESC',
  });
  const { session } = useAuth();
  const { language } = useLanguage();

  const [fields, setFields] = useState<Fields>({
    asin: 'DESC',
    salePrice: 'DESC',
    productCost: 'DESC',
    monthlyRevenue: 'DESC',
    saleCount: 'DESC',
    amazonCost: 'DESC',
    shippingCost: 'DESC',
    profit: 'DESC',
    profitMargin: 'DESC',
    profitRate: 'DESC',
    investment: 'DESC',
    profitHundred: 'DESC',
    rivalCount: 'DESC',
    reviewCount: 'DESC',
    leadTime: 'DESC',
    maxOrderQuantity: 'DESC',
  });
  const [page, setPage] = useState(1);
  const [inputPage, setInputPage] = useState<string | number>(1);
  const location = useLocation();
  const urlPage = location.search;

  const { isLoading, error, data } = useQuery<ExclusiveProduct, Error>(
    ['excProducts', page, query],
    {
      queryFn: () =>
        memberService.getExclusiveProducts(
          urlPage === '' || urlPage === null ? '?page=1' : urlPage,
          query
        ),
      staleTime: 10000,
    }
  );

  if (error) {
    alertNotification('error', 'Ürünler getirilirken bir hata oluştu!');
  }
  useEffect(() => {
    let temp;
    if (urlPage !== '') {
      temp = urlPage.split('=')[1];
      setPage(Number(temp));
      setInputPage(Number(temp));
    } else {
      setPage(1);
      setInputPage(1);
    }
  }, [location.search]);

  const tableHeader =
    language === 'tr'
      ? [
        { name: 'Sıra', column: 'id', isButton: false },
        { name: 'Ürün', column: 'image', isButton: false },
        { name: 'ASIN', column: 'asin', isButton: false },
        { name: 'Aylık Satış Adedi', column: 'saleCount', isButton: true },
        { name: 'Aylık Ciro', column: 'monthlyRevenue', isButton: true },
        { name: 'Satış Fiyatı', column: 'salePrice', isButton: true },
        { name: 'Ürün Maliyeti', column: 'productCost', isButton: true },
        { name: 'Amazon Kesintileri', column: 'amazonCost', isButton: true },
        { name: 'Kargo Maliyeti', column: 'shippingCost', isButton: true },
        { name: 'Net Kar', column: 'profit', isButton: true },
        { name: 'Kar Marjı', column: 'profitMargin', isButton: true },
        { name: 'Kar Oranı', column: 'profitRate', isButton: true },
        { name: 'Rakip Satıcı Sayısı', column: 'rivalCount', isButton: true },
        {
          name: 'Listede Amazon Var mı',
          column: 'isAmazon',
          isButton: false,
        },
        { name: 'Yorum Sayısı', column: 'reviewCount', isButton: true },
        { name: 'Ürün Tedarik Süresi', column: 'leadTime', isButton: true },
        {
          name: 'Maksimum Sipariş Miktarı',
          column: 'maxOrderQuantity',
          isButton: true,
        },
      ]
      : [
        { name: 'No', column: 'id', isButton: false },
        { name: 'Product', column: 'image', isButton: false },
        { name: 'ASIN', column: 'asin', isButton: false },
        { name: 'Monthly Sales', column: 'saleCount', isButton: true },
        { name: 'Monthly Revenue', column: 'monthlyRevenue', isButton: true },
        { name: 'Sale Price', column: 'salePrice', isButton: true },
        { name: 'Product Cost', column: 'productCost', isButton: true },
        { name: 'Amazon Fees', column: 'amazonCost', isButton: true },
        { name: 'Shipping Cost', column: 'shippingCost', isButton: true },
        { name: 'Net Profit', column: 'profit', isButton: true },
        { name: 'Profit Margin', column: 'profitMargin', isButton: true },
        { name: 'Profit Rate (ROI)', column: 'profitRate', isButton: true },
        { name: 'Competitor Sellers', column: 'rivalCount', isButton: true },
        {
          name: 'Amazon is Seller?',
          column: 'isAmazon',
          isButton: false,
        },
        { name: 'Reviews', column: 'reviewCount', isButton: true },
        { name: 'Supply Time', column: 'leadTime', isButton: true },
        {
          name: 'Max Order Quantity',
          column: 'maxOrderQuantity',
          isButton: true,
        },
      ];

  return (
    <div className="flex flex-col flex-shrink-0 bg-white rounded-xl p-5 shadow-lg sm:p-4 relative">
      {/* {!session?.hasPackage && <ToolsAbsoluteBuyBox />} */}
      {!session?.hasPackage && (
        <div
          className={`bg-white shadow-xl rounded-lg border-2 absolute top-[150px] h-[350px] z-40 w-[60%] left-[20%] md:w-[45%] md:left-[30%] lg:w-[40%] lg:left-[35%] xl:w-[30%] xl:left-[35%]   min-[2000px]:w-[25%] min-[2000px]:left-[35%] `}
        >
          <div className="flex justify-center mt-12 mb-8">
            <Lock1 size="80" color="#3C4790" variant="Bold" />
          </div>
          <p className="text-headerPrimary text-sm md:text-lg lg:text-lg px-4 mb-8 font-medium text-center">
            {language === 'tr'
              ? 'Tüm Ürünleri Görmek İçin Üye Ol.'
              : 'Become a Premium Member to See All Products.'}
          </p>
          <div className="flex justify-center">
            <Link
              target={'_blank'}
              to={language === 'tr' ? "https://rexven.com/topluluk-paketleri" : "https://rexven.com/pricing"}
              type="button"
              className="inline-flex justify-center rounded-xl bg-headerPrimary px-12 py-3 text-sm xl:text-xl font-semibold text-white shadow-sm hover:bg-secondary"
            >
              {language === 'tr' ? 'Üye Ol' : 'Rexven Premium'}
            </Link>
          </div>
        </div>
      )}
      <div className="flow-root">
        <div className="relative -mx-4 -my-2 overflow-x-auto overflow-y-auto h-[70vh] scrollbar-hide">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300 table-auto">
              <thead>
                <tr>
                  {tableHeader.map((item) => {
                    return (
                      <th
                        key={item.name}
                        className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter "
                        scope="col"
                      >
                        {' '}
                        {item.isButton ? (
                          <button
                            className="text-xs md:text-sm flex justify-between items-center"
                            onClick={() => {
                              let order =
                                fields.hasOwnProperty(item.column) &&
                                  fields[item.column] === 'DESC'
                                  ? 'ASC'
                                  : 'DESC';
                              setQuery({
                                sortField: item.column,
                                sortOrder: order,
                              });
                              setFields({ ...fields, [item.column]: order });
                            }}
                          >
                            {item.name} <ArrowSwapVertical className="" />
                          </button>
                        ) : (
                          <p className="text-xs md:text-sm">{item.name}</p>
                        )}
                      </th>
                    );
                  })}
                </tr>
              </thead>

              {isLoading ? (
                <div className="w-full flex justify-center">
                  <LoadingComponent />
                </div>
              ) : (
                <tbody className="divide-y divide-gray-200 bg-white">
                  {data?.data.products.map((product: any, idx) => {
                    let productAsin = '**********';
                    let isBlur = true;
                    if (
                      session?.role === 'g5k7e3p2' &&
                      product.profitRate <= 30
                    ) {
                      productAsin = product.asin;
                      isBlur = false;
                    }
                    if (
                      session?.role === 't9u2h6m8' &&
                      product.profitRate <= 40
                    ) {
                      productAsin = product.asin;
                      isBlur = false;
                    }
                    if (
                      session?.role === 'd4x8s7n2' ||
                      session?.role === 'po6coorox' ||
                      session?.role === 'pt04n1dT' ||
                      session?.role === 'msvwerv02' ||
                      session?.role === 'mıbB043'
                    ) {
                      productAsin = product.asin;
                      isBlur = false;
                    }
                    return (
                      <tr key={product.id}>
                        <td className="py-4 pl-4 text-xs md:text-sm sm:pl-0 w-16">
                          <p className="text-center text-gray">
                            {idx + 20 * (page - 1) + 1}
                          </p>
                        </td>
                        <td
                          className={classNames(
                            isBlur ? 'blur-sm' : 'blur-none',
                            'py-4 pl-4 text-xs md:text-sm font-medium text-center text-gray-900 sm:pl-0 w-16'
                          )}
                        >
                          <a
                            href={`https://www.amazon.com/dp/${productAsin}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              className="h-10 object-contain"
                              src={`https://m.media-amazon.com/images/I/${product.image}`}
                            />
                          </a>
                        </td>
                        <td className="px-4 py-4 text-xs md:text-smtext-center text-gray-500  ">
                          <a
                            href={`https://www.amazon.com/dp/${productAsin}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {' '}
                            {productAsin}
                          </a>
                        </td>
                        <td className="whitespace-nowrap text-center px-3 py-4 text-xs md:text-sm text-gray-500 ">
                          {product.saleCount}
                        </td>
                        <td className="whitespace-nowrap text-center px-3 py-4 text-xs md:text-sm text-gray-500 ">
                          ${' '}
                          {seperateNumberWithComma(
                            product.monthlyRevenue.toFixed(0)
                          )}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          $ {product.salePrice}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          $ {product.productCost}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          $ {product.amazonCost}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          $ {product.shippingCost}
                        </td>
                        <td
                          className="whitespace-nowrap px-3 py-4 text-center text-xs bg-lightGray font-medium md:text-sm text-gray-500"
                        // style={{ backgroundColor: 'rgba(18, 185, 128, 0.4)' }}
                        >
                          $ {product.profit}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          %{(Number(product.profitMargin) * 100).toFixed(0)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm bg-lightGray font-medium text-gray-500 ">
                          %{(Number(product.profitRate) * 100).toFixed(0)}
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          {product.rivalCount}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          {product.isAmazon ? 'Evet' : 'Hayır'}
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500">
                          {seperateNumberWithComma(product.reviewCount)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500">
                          {product.leadTime} hafta
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500">
                          {product.maxOrderQuantity}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
      <Pagination
        setPage={setPage}
        page={page}
        inputPage={inputPage}
        setInputPage={setInputPage}
        totalPageCount={data?.data.totalPageCount!}
      />
    </div>
  );
};
export default ProductTable;

//   asin: string;
//   salePrice: string;
//   productCost: string;
//   monthlyRevenue: string;
//   amazonCost: string;
//   shippingCost: string;
//   netProfit: string;
//   profitMargin: string;
//   profitRate: string;
//   investment: string;
//   profitHundred: string;
//   rivalCount: string;
//   reviewCount: string;
//   leadTime: string;
//   maxOrderQuantity: string;
