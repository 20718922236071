import React from 'react';

const Footer = () => {
  return (
    <div className="w-full px-5 py-5 flex flex-col sm:flex-row justify-between items-center bg-lightGray border-t-2 border-headingSecondary">
      <div className="flex justify-start items-center">
        <a
          href="/aydinlatma-metni"
          target="_blank"
          rel="noreferrer"
          className="text-headingPrimary  text-center md:text-left  xl:text-xs text-[9px]"
        >
          Aydınlatma Metni
        </a>
        <p className="xl:text-xs text-[9px] text-headingPrimary px-3">|</p>
        <a
          href="/uyelik-sozlesmesi"
          target="_blank"
          rel="noreferrer"
          className="text-headingPrimary  text-center md:text-left xl:text-xs text-[9px]"
        >
          Üyelik Sözleşmesi
        </a>
        <p className="xl:text-xs text-[9px] text-headingPrimary px-3">|</p>
        <a
          href="/mesafeli-satis-sozlesmesi"
          target="_blank"
          rel="noreferrer"
          className="text-headingPrimary text-center md:text-left  xl:text-xs text-[9px]"
        >
          Mesafeli Satış Sözleşmesi
        </a>
        <p className="xl:text-xs text-[9px] text-headingPrimary px-3">|</p>
        <a
          href="/gizlilik-politikasi"
          target="_blank"
          rel="noreferrer"
          className="text-headingPrimary xl:text-xs  text-center md:text-left text-[9px]"
        >
          Gizlilik Politikası
        </a>
        <p className="xl:text-xs text-[9px] text-headingPrimary px-3">|</p>
        <a
          href="/kullanim-kosullari"
          target="_blank"
          rel="noreferrer"
          className="text-headingPrimary xl:text-xs  text-center md:text-left text-[9px]"
        >
          Kullanım Koşulları
        </a>
      </div>
      <p className="text-headingPrimary xl:text-xs text-[9px] mt-4 sm:mt-0">
        Rexven - Copyright 2024 - Rexven Teknoloji A.Ş.
      </p>
    </div>
  );
};

export default Footer;
