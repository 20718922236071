import React from 'react';

import producerService from '../../services/producerService';
import MessageModal from './MessageModal';
import ProductCart from './ProductCart';

export interface Products {
  id: number;
  brand: string;
  salePrice: number;
  reviewCount: number;
  rating: number;
  images: Image[];
  title: string;
  discountedPrice: number;
  producerName: string;
  discountRate: number;
  phoneNumber: string;
  producerId: number;
  producerUserId: number,
  profile_image: string,
  first_name: string,
  last_name: string,
  communicationCount: string;
  product_id: number;
}

export interface Image {
  url: string;
}

interface ProductsWrapperProps {
  products: Products[];
}

// Producer Contact Btn Clicked Stats-->
const updateProducerStats = (productId: number, incrementType?: string) => {
  producerService
    .productStatistics({ productId })
    .then((res) => {
      console.log(res);
    })
    .catch((res) => {
      console.log(res);
    });
};

const ProductsWrapper: React.FC<ProductsWrapperProps> = ({ products }) => {
  const [open, setOpen] = React.useState(false);
  const [productId, setProductId] = React.useState<number | null>(null);
  return (
    <div className="bg-white mt-4 ">
      <div className="mx-auto overflow-hidden ">
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 min-[1200px]:grid-cols-3 sm:mx-0 min-[1350px]:grid-cols-4">
          {products.map((product) => (
            <ProductCart
              setOpen={setOpen}
              setProductId={setProductId}
              key={product.id}
              product={product}
              updateProducerStats={updateProducerStats}
            />
          ))}
        </div>
      </div>
      <MessageModal
        open={open}
        setOpen={setOpen}
        productId={productId!}
        updateProducerStats={updateProducerStats}
      />
    </div>
  );
};

export default ProductsWrapper;
