import React, { useState, useEffect } from 'react';
// import ToolSearchBar from '../components/common/ToolSearchBar';
import ProfitProductInfo from '../components/tools/common/ProfitProductInfo';
// import VideoTools from '../components/tools/common/VideoTools';
import SmallInfoCards from '../components/tools/common/SmallInfoCards';
import PieChart from '../components/tools/common/PieChart';

import alertNotification from '../utils/alertNotification';

import {
  WalletAdd1,
  Box1,
  PercentageSquare,
  ReceiptDisscount,
  Box2,
} from 'iconsax-react';
// import SixDigitModal from '../components/numberVerifyModals/SixDigitModal';
import NumVerifyModal from '../components/numberVerifyModals/NumVerifyModal';
import VerifySuccessModal from '../components/numberVerifyModals/VerifySuccessModal';
import SurveyModal from '../components/survey/SurveyModal';
import { useNumberVerification } from '../context/NumberVerificationContext';
import { useAuth } from '../context/AuthContext';
import NewBanner from '../components/tools/common/NewBanner';
import AliexpressCalculateProfitForm from '../components/aliexpress/AliexpressCalculateProfitForm';
import aliExpressService from '../services/aliExpressService';
import AliexpressCalculateResultForm, {
  AliexpressCalculateResult,
} from '../components/aliexpress/AliexpressCalculateResultForm';
import memberService from '../services/memberService';
import { useLanguage } from '../context/LanguageContext';

interface ProductInfo {
  productImg: string;
  productTitle: string;
  brand: string;
  sellerName: string;
  rating: number;
  sellingPrice: number;
  commissionRate: number;
}

const AliExpressCalculator = () => {
  const { language } = useLanguage();
  const [isTabActive, setIsTabActive] = useState<boolean>(true);
  const [productLink, setProductLink] = useState('');
  const [productInfo, setProductInfo] = useState<ProductInfo>();
  const [resultLoading, setResultLoading] = useState(false);
  const { session } = useAuth();
  const { handleActiveModal } = useNumberVerification();
  const [commissionRate, setCommissionRate] = useState<number>();

  const [infoCardsArr, setInfoCardsArr] = useState(
    language === 'tr'
      ? [
          {
            icon: WalletAdd1,
            title: 'Gelir',
            value: '-',
            iconColor: '#0F766E',
            titleColor: '#0D9488',
            valueColor: '#0F766E',
          },
          {
            icon: Box1,
            title: 'Maliyet',
            value: '-',
            iconColor: '#BE185D',
            titleColor: '#DB2777',
            valueColor: '#BE185D',
          },
          {
            icon: Box2,
            title: 'Kesinti',
            value: '-',
            iconColor: '#F27B1A',
            titleColor: '#C15D0B',
            valueColor: '#F27B1A',
          },
          {
            icon: ReceiptDisscount,
            title: 'Kar',
            value: '-',
            iconColor: '#0369A1',
            titleColor: '#0284C7',
            valueColor: '#0369A1',
          },

          {
            icon: PercentageSquare,
            title: 'Kar Marjı',
            value: '-',
            iconColor: '#4338CA',
            titleColor: '#6366F1',
            valueColor: '#4338CA',
          },
        ]
      : [
          {
            icon: WalletAdd1,
            title: 'Revenue',
            value: '-',
            iconColor: '#0F766E',
            titleColor: '#0D9488',
            valueColor: '#0F766E',
          },
          {
            icon: Box1,
            title: 'Cost',
            value: '-',
            iconColor: '#BE185D',
            titleColor: '#DB2777',
            valueColor: '#BE185D',
          },
          {
            icon: Box2,
            title: 'Fees',
            value: '-',
            iconColor: '#F27B1A',
            titleColor: '#C15D0B',
            valueColor: '#F27B1A',
          },
          {
            icon: ReceiptDisscount,
            title: 'Profit',
            value: '-',
            iconColor: '#0369A1',
            titleColor: '#0284C7',
            valueColor: '#0369A1',
          },

          {
            icon: PercentageSquare,
            title: 'Profit Margin',
            value: '-',
            iconColor: '#4338CA',
            titleColor: '#6366F1',
            valueColor: '#4338CA',
          },
        ]
  );

  const [chartValues, setChartValues] = useState([
    //['Kar', 'Kesinti', 'Maliyet'],
    33.3, 33.3, 33.3,
  ]);

  const chartOptions = {
    labels:
      language === 'tr'
        ? ['Kar', 'Kesinti', 'Maliyet']
        : ['Profit', 'Fees', 'Cost'], // Value Titles
    colors: ['#0284C7', '#F27B1A', '#DB2777'], // Value Colors
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          size: '50px',
          labels: {
            show: true,
          },
        },
      },
    },
  };

  const [calculateResult, setCalculateResult] =
    useState<AliexpressCalculateResult>();

  const onSubmit = async (values: any) => {
    console.log('FormValues', values);

    const payload = {
      price: Number(values.price),
      type: values.type,
      discount: Number(values.discount),
      weight: Number(values.shippingWeight),
      productCost: Number(values.productCost),
      laborCost: Number(values.laborCost),
      packageCost: Number(values.packageCost),
      adsCost: Number(values.adsCost),
    };

    if (
      session?.isGsmVerified ||
      session?.hasPackage ||
      session?.lifeTimeCourse
    ) {
      setResultLoading(true);

      aliExpressService
        .getProfitForAliexpress(payload)
        .then((res) => {
          setInfoCardsArr(
            language === 'tr'
              ? [
                  {
                    icon: WalletAdd1,
                    title: 'Gelir',
                    value: res.data.income + ' ₺',
                    iconColor: '#0F766E',
                    titleColor: '#0D9488',
                    valueColor: '#0F766E',
                  },
                  {
                    icon: Box1,
                    title: 'Maliyet',
                    value: res.data.totalCost + ' ₺',
                    iconColor: '#BE185D',
                    titleColor: '#DB2777',
                    valueColor: '#BE185D',
                  },
                  {
                    icon: Box2,
                    title: 'Kesinti',
                    value: res.data.grossingUp + ' ₺',
                    iconColor: '#F27B1A',
                    titleColor: '#C15D0B',
                    valueColor: '#F27B1A',
                  },
                  {
                    icon: ReceiptDisscount,
                    title: 'Kar',
                    value: res.data.profit + ' ₺',
                    iconColor: '#0369A1',
                    titleColor: '#0284C7',
                    valueColor: '#0369A1',
                  },

                  {
                    icon: PercentageSquare,
                    title: 'Kar Marjı',
                    value: res.data.profitMargin + ' %',
                    iconColor: '#4338CA',
                    titleColor: '#6366F1',
                    valueColor: '#4338CA',
                  },
                ]
              : [
                  {
                    icon: WalletAdd1,
                    title: 'Revenue',
                    value: res.data.income + ' ₺',
                    iconColor: '#0F766E',
                    titleColor: '#0D9488',
                    valueColor: '#0F766E',
                  },
                  {
                    icon: Box1,
                    title: 'Cost',
                    value: res.data.totalCost + ' ₺',
                    iconColor: '#BE185D',
                    titleColor: '#DB2777',
                    valueColor: '#BE185D',
                  },
                  {
                    icon: Box2,
                    title: 'Fees',
                    value: res.data.grossingUp + ' ₺',
                    iconColor: '#F27B1A',
                    titleColor: '#C15D0B',
                    valueColor: '#F27B1A',
                  },
                  {
                    icon: ReceiptDisscount,
                    title: 'Profit',
                    value: res.data.profit + ' ₺',
                    iconColor: '#0369A1',
                    titleColor: '#0284C7',
                    valueColor: '#0369A1',
                  },

                  {
                    icon: PercentageSquare,
                    title: 'Profit Margin',
                    value: res.data.profitMargin + ' %',
                    iconColor: '#4338CA',
                    titleColor: '#6366F1',
                    valueColor: '#4338CA',
                  },
                ]
          );

          setChartValues([
            res.data.profit,
            res.data.grossingUp,
            res.data.totalCost,
          ]);

          setCalculateResult(res.data);
        })
        .catch((err) => {
          // console.log(err);
          alertNotification('error', err.response.data.message);
        })
        .finally(() => {
          setResultLoading(false);
        });
    } else {
      handleActiveModal?.();
    }
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabActive(document.visibilityState === 'visible');
    };

    // Add event listener for visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup function to remove the event listener when the component is unmounted
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    let intervalId: any;
    if (session && isTabActive) {
      intervalId = setInterval(async () => {
        await memberService.userTrackReq(
          'aliexpress-profit-calculator',
          session?.id!
        );
      }, 60000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [session, isTabActive]);
  return (
    <>
      {/* <SixDigitModal /> */}
      <NumVerifyModal />
      <VerifySuccessModal />
      <SurveyModal />

      <div className="flex w-full flex-shrink-0 flex-col">
        <NewBanner
          bgUrl={'/images/trendyol-kar-hesaplama-bg.webp'}
          toolImageUrl="aliexpress-kar-hesaplama"
        />

        <div className="grid grid-cols-12 gap-4 mt-4">
          <div className="col-span-12 lg:col-span-6 xl:col-span-5 px-2 lg:px-4 bg-white rounded-xl shadow-xl">
            {productInfo && <ProfitProductInfo product={productInfo} />}

            <AliexpressCalculateProfitForm onSubmit={onSubmit} />

            {calculateResult && (
              <AliexpressCalculateResultForm
                aliexpressCalculateResult={calculateResult}
              />
            )}
          </div>
          <div className="col-span-12 lg:col-span-6 xl:col-span-7 ">
            {/* Video Content */}
            {/* <VideoTools videoSrc="courses/shared/trendyol-kar-hesaplama.mp4" /> */}

            {/* Small Info Cards */}
            <SmallInfoCards
              spanCount={1}
              infos={infoCardsArr}
              resultLoading={resultLoading}
            />

            {/* Apex Section */}
            <div className="mt-4 py-4  bg-white rounded-xl shadow-xl ">
              <PieChart series={chartValues} options={chartOptions} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AliExpressCalculator;
