import { useFormik } from 'formik';
import React, { Fragment, useState } from 'react';
import * as Yup from 'yup';

import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';

import { useLanguage } from '../../context/LanguageContext';
import { ICategory, ICountry, IProductType } from '../../interfaces/etsyToolsInterfaces';
import { errorElement } from '../../utils/errorElement';

interface FilteringEtsyHotProductsProps {
  filterSubmit: (values: any) => Promise<void>;
  categories: ICategory[];
  productTypes: IProductType[];
  countries: ICountry[];
}
function classNames(...classes: String[]) {
  return classes.filter(Boolean).join(' ');
}

const FilteringEtsyHotProducts: React.FC<FilteringEtsyHotProductsProps> = ({
  filterSubmit,
  categories,
  productTypes,
  countries,
}) => {
  const { t } = useLanguage();
  const [countryName, setCountryName] = useState(t?.('common.select'));
  const [date, setDate] = useState(t?.('common.select'));
  const [category, setCategory] = useState(t?.('common.select'));
  const [productType, setProductType] = useState(t?.('common.select'));
  const [productDate, setProductDate] = useState(t?.('common.select'));

  const formik = useFormik({
    initialValues: {
      etsyCountryId: '',
      createDate: '',
      productCreateDate: '',
      taxonomyId: '',
      etsyProductTypeId: '',
      saleCountMin: '',
      saleCountMax: '',
      priceMin: '',
      priceMax: '',
      revenueMin: '',
      revenueMax: '',
      reviewCountMin: '',
      reviewCountMax: '',
      viewCountMin: '',
      viewCountMax: '',
      title: ''
    },
    onSubmit: filterSubmit,
    validationSchema: Yup.object().shape({
      saleCountMin: Yup.number().min(0, t?.('formFilter.neZero')),
      saleCountMax: Yup.number().when(
        'saleCountMin',
        (min: any, schema) => min && schema.min(min, t?.('formFilter.maxSale'))
      ),
      priceMin: Yup.number().min(0, t?.('formFilter.neZero')),
      priceMax: Yup.number().when(
        'priceMin',
        (min: any, schema) => min && schema.min(min, t?.('formFilter.maxPrice'))
      ),
      revenueMin: Yup.number().min(0, t?.('formFilter.neZero')),
      revenueMax: Yup.number().when(
        'revenueMin',
        (min: any, schema) =>
          min && schema.min(min, t?.('formFilter.maxRevenue'))
      ),
      reviewCountMin: Yup.number().min(0, t?.('formFilter.neZero')),
      reviewCountMax: Yup.number().when(
        'reviewCountMin',
        (min: any, schema) =>
          min && schema.min(min, t?.('formFilter.maxReview'))
      ),
      viewCountMin: Yup.number().min(0, t?.('formFilter.neZero')),
      viewCountMax: Yup.number().when(
        'viewCountMin',
        (min: any, schema) => min && schema.min(min, t?.('formFilter.maxView'))
      ),
    }),
  });

  const SHOPAGES = [
    { id: 129, title: 'Hepsi', value: '' },
    { id: 123, title: '> 2023', value: 2023 },
    { id: 1231, title: '> 2022', value: 2022 },
    { id: 1232, title: '> 2021', value: 2021 },
    { id: 1233, title: '> 2020', value: 2020 },
    { id: 1234, title: '> 2019', value: 2019 },
    { id: 1235, title: '> 2018', value: 2018 },
    { id: 12356, title: '> 2017', value: 2017 },
    { id: 12315, title: '> 2016', value: 2016 },
    { id: 12314, title: '> 2015', value: 2015 },
    { id: 123123, title: '> 2014', value: 2014 },
    { id: 1232342, title: '> 2013', value: 2013 },
    { id: 12388, title: '> 2012', value: 2012 },
    { id: 12389, title: '> 2011', value: 2011 },
    { id: 123100, title: '> 2010', value: 2010 },
    { id: 123101, title: '> 2009', value: 2009 },
    { id: 123102, title: '> 2008', value: 2008 },
    { id: 123103, title: '> 2007', value: 2007 },
    { id: 123104, title: '> 2006', value: 2006 },
    { id: 123105, title: '> 2005', value: 2005 },
  ];
  const PRODUCT_AGES = [
    { id: 123445, title: 'Hepsi', value: '' },
    { id: 123445, title: '< 1 ay', value: 1 },
    { id: 123446, title: '< 2 ay', value: 2 },
    { id: 123447, title: '< 3 ay', value: 3 },
    { id: 123448, title: '< 4 ay', value: 4 },
    { id: 123449, title: '< 5 ay', value: 5 },
    { id: 123441, title: '< 6 ay', value: 6 },
    { id: 123442, title: '< 7 ay', value: 7 },
    { id: 123443, title: '< 8 ay', value: 8 },
    { id: 123444, title: '< 9 ay', value: 9 },
    { id: 1234455, title: '< 10 ay', value: 10 },
    { id: 1232345, title: '< 11 ay', value: 11 },
    { id: 1232346, title: '< 12 ay', value: 12 },
  ];

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="grid grid-cols-12 xl:grid-cols-10 gap-4">
          <div className="col-span-12 xl:col-span-10">
            <p className="text-description font-medium px-1 mb-1 text-xs md:text-sm">
              {t?.('etsy-top-products.filter.keyword')}
            </p>
            <div className="flex justify-between items-center space-x-2">
              <input
                value={formik.values.title}
                onChange={formik.handleChange}
                id="title"
                name="title"
                type="text"
                className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                placeholder="necklace, ring, personalized, dog ..."
              />
            </div>
            {formik.errors.reviewCountMin &&
              errorElement(formik.errors.reviewCountMin!)}
            {formik.errors.reviewCountMax &&
              errorElement(formik.errors.reviewCountMax!)}
          </div>
          {/* Ülke */}
          <div className="col-span-6 md:col-span-3 xl:col-span-2">
            <label
              className="w-full py-2 font-medium text-xs min-[1335px]:text-sm"
              htmlFor="etsyCountryId"
            >
              {t?.('etsy-hot-products.filter.country')}
            </label>

            <Listbox
              value={formik.values.etsyCountryId}
              onChange={(country) => {
                formik.values.etsyCountryId = country;

                let countryTitle = countries?.find(
                  (item) => item.id === Number(formik.values.etsyCountryId)
                )?.name;

                countryTitle = countryTitle ? countryTitle : 'Hepsi';
                setCountryName(countryTitle!);
              }}
            >
              {({ open }) => (
                <>
                  <div className="relative w-full">
                    <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-2 pl-3 pr-10  text-xs min-[1335px]:text-sm text-left text-gray-900 shadow-sm ring-1 ring-inset ring-cardBorder focus:outline-none ">
                      <span className="block truncate">{countryName}</span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronDownIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-20 border-2 border-cardBorder mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-xs shadow-lg ring-1 ring-black ring-opacity-5 focus:ring-secondary focus:outline-none sm:text-sm">
                        <Listbox.Option
                          key={98989898}
                          className={({ active }) =>
                            classNames(
                              active
                                ? 'bg-secondary text-white'
                                : 'text-gray-900',
                              'relative cursor-default select-none py-2 pl-3 pr-9'
                            )
                          }
                          value={''}
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={classNames(
                                  selected ? 'font-semibold' : 'font-normal',
                                  'block truncate'
                                )}
                              >
                                {'Hepsi'}
                              </span>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? 'text-white' : 'text-indigo-600',
                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                  )}
                                >
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                        {countries &&
                          countries?.map((country: any) => (
                            <Listbox.Option
                              key={country.id}
                              className={({ active }) =>
                                classNames(
                                  active
                                    ? 'bg-secondary text-white'
                                    : 'text-gray-900',
                                  'relative cursor-default select-none py-2 pl-3 pr-9'
                                )
                              }
                              value={country.id}
                            >
                              {({ selected, active }) => (
                                <>
                                  <span
                                    className={classNames(
                                      selected
                                        ? 'font-semibold'
                                        : 'font-normal',
                                      'block truncate'
                                    )}
                                  >
                                    {country.name}
                                  </span>

                                  {selected ? (
                                    <span
                                      className={classNames(
                                        active
                                          ? 'text-white'
                                          : 'text-indigo-600',
                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                      )}
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
          </div>
          {/* Mağaza Açılış Yılı */}
          <div className="col-span-6 md:col-span-3 xl:col-span-2">
            <label
              className="w-full   py-2 font-medium text-xs min-[1335px]:text-sm   "
              htmlFor="createDate"
            >
              {t?.('etsy-hot-products.filter.openingYear')}
            </label>
            <Listbox
              value={formik.values.createDate}
              onChange={(date) => {
                formik.values.createDate = date;
                let createDate = SHOPAGES.find(
                  (item) => item.value === Number(formik.values.createDate)
                )?.title;
                createDate = createDate ? createDate : 'Hepsi';
                setDate(createDate!);
              }}
            >
              {({ open }) => (
                <>
                  <div className="relative w-full">
                    <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-2 pl-3 pr-10  text-xs min-[1335px]:text-sm text-left text-gray-900 shadow-sm ring-1 ring-inset ring-cardBorder focus:outline-none ">
                      <span className="block truncate">{date}</span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronDownIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-20 border-2 border-cardBorder mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-xs shadow-lg ring-1 ring-black ring-opacity-5 focus:ring-secondary focus:outline-none sm:text-sm">
                        {SHOPAGES.map((date: any) => (
                          <Listbox.Option
                            key={date.id}
                            className={({ active }) =>
                              classNames(
                                active
                                  ? 'bg-secondary text-white'
                                  : 'text-gray-900',
                                'relative cursor-default select-none py-2 pl-3 pr-9'
                              )
                            }
                            value={date.value}
                          >
                            {({ selected, active }) => (
                              <>
                                <span
                                  className={classNames(
                                    selected ? 'font-semibold' : 'font-normal',
                                    'block truncate'
                                  )}
                                >
                                  {date.title}
                                </span>

                                {selected ? (
                                  <span
                                    className={classNames(
                                      active ? 'text-white' : 'text-indigo-600',
                                      'absolute inset-y-0 right-0 flex items-center pr-4'
                                    )}
                                  >
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
          </div>

          {/* Ürün Yaşı */}
          <div className="col-span-6 md:col-span-3 xl:col-span-2">
            <label
              className="w-full   py-2 font-medium text-xs min-[1335px]:text-sm   "
              htmlFor="productCreateDate"
            >
              {t?.('etsy-hot-products.filter.productAge')}
            </label>
            <Listbox
              value={formik.values.productCreateDate}
              onChange={(date) => {
                formik.values.productCreateDate = date;
                let createDate = PRODUCT_AGES.find(
                  (item) =>
                    item.value === Number(formik.values.productCreateDate)
                )?.title;
                createDate = createDate ? createDate : 'Hepsi';
                setProductDate(createDate!);
              }}
            >
              {({ open }) => (
                <>
                  <div className="relative w-full">
                    <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-2 pl-3 pr-10  text-xs min-[1335px]:text-sm text-left text-gray-900 shadow-sm ring-1 ring-inset ring-cardBorder focus:outline-none ">
                      <span className="block truncate">{productDate}</span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronDownIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-20 border-2 border-cardBorder mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-xs shadow-lg ring-1 ring-black ring-opacity-5 focus:ring-secondary focus:outline-none sm:text-sm">
                        {PRODUCT_AGES.map((date: any) => (
                          <Listbox.Option
                            key={date.id}
                            className={({ active }) =>
                              classNames(
                                active
                                  ? 'bg-secondary text-white'
                                  : 'text-gray-900',
                                'relative cursor-default select-none py-2 pl-3 pr-9'
                              )
                            }
                            value={date.value}
                          >
                            {({ selected, active }) => (
                              <>
                                <span
                                  className={classNames(
                                    selected ? 'font-semibold' : 'font-normal',
                                    'block truncate'
                                  )}
                                >
                                  {date.title}
                                </span>

                                {selected ? (
                                  <span
                                    className={classNames(
                                      active ? 'text-white' : 'text-indigo-600',
                                      'absolute inset-y-0 right-0 flex items-center pr-4'
                                    )}
                                  >
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
          </div>

          {/* Kategoriler */}
          <div className="col-span-6 md:col-span-3 xl:col-span-2">
            <label
              className="w-full   py-2 font-medium text-xs min-[1335px]:text-sm   "
              htmlFor="taxonomyId"
            >
              {t?.('etsy-hot-products.filter.category')}
            </label>
            <Listbox
              value={formik.values.taxonomyId}
              onChange={(category) => {
                formik.values.taxonomyId = category;
                let categoryName =
                  categories &&
                  categories?.find(
                    (item) => item.id === Number(formik.values.taxonomyId)
                  )?.taxonomyName;
                categoryName = categoryName ? categoryName : 'Hepsi';

                setCategory(categoryName!);
              }}
            >
              {({ open }) => (
                <>
                  <div className="relative w-full">
                    <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-2 pl-3 pr-10  text-xs min-[1335px]:text-sm text-left text-gray-900 shadow-sm ring-1 ring-inset ring-cardBorder focus:outline-none ">
                      <span className="block truncate">{category}</span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronDownIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-20 border-2 border-cardBorder mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-xs shadow-lg ring-1 ring-black ring-opacity-5 focus:ring-secondary focus:outline-none sm:text-sm">
                        <Listbox.Option
                          key={7868769}
                          className={({ active }) =>
                            classNames(
                              active
                                ? 'bg-secondary text-white'
                                : 'text-gray-900',
                              'relative cursor-default select-none py-2 pl-3 pr-9'
                            )
                          }
                          value={''}
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={classNames(
                                  selected ? 'font-semibold' : 'font-normal',
                                  'block truncate'
                                )}
                              >
                                {'Hepsi'}
                              </span>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? 'text-white' : 'text-indigo-600',
                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                  )}
                                >
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                        {categories &&
                          categories?.map((category: any) => (
                            <Listbox.Option
                              key={category.id}
                              className={({ active }) =>
                                classNames(
                                  active
                                    ? 'bg-secondary text-white'
                                    : 'text-gray-900',
                                  'relative cursor-default select-none py-2 pl-3 pr-9'
                                )
                              }
                              value={category.id}
                            >
                              {({ selected, active }) => (
                                <>
                                  <span
                                    className={classNames(
                                      selected
                                        ? 'font-semibold'
                                        : 'font-normal',
                                      'block truncate'
                                    )}
                                  >
                                    {category.taxonomyName}
                                  </span>

                                  {selected ? (
                                    <span
                                      className={classNames(
                                        active
                                          ? 'text-white'
                                          : 'text-indigo-600',
                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                      )}
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
          </div>

          {/* Ürün Tipi */}
          <div className="col-span-6 md:col-span-3 xl:col-span-2">
            <label
              className="w-full py-2 font-medium text-xs min-[1335px]:text-sm   "
              htmlFor="etsyProductTypeId"
            >
              {t?.('etsy-hot-products.filter.productType')}
            </label>
            <Listbox
              value={formik.values.etsyProductTypeId}
              onChange={(prodType) => {
                formik.values.etsyProductTypeId = prodType;
                let tempType =
                  productTypes &&
                  productTypes &&
                  productTypes?.find(
                    (item) =>
                      item.id === Number(formik.values.etsyProductTypeId)
                  )?.type;
                tempType = tempType ? tempType : 'Hepsi';
                setProductType(tempType!);
              }}
            >
              {({ open }) => (
                <>
                  <div className="relative w-full">
                    <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-2 pl-3 pr-10  text-xs min-[1335px]:text-sm text-left text-gray-900 shadow-sm ring-1 ring-inset ring-cardBorder focus:outline-none ">
                      <span className="block truncate">{productType}</span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronDownIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>

                    <Transition
                      show={open}
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute z-20 border-2 border-cardBorder mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-xs shadow-lg ring-1 ring-black ring-opacity-5 focus:ring-secondary focus:outline-none sm:text-sm">
                        <Listbox.Option
                          key={746565}
                          className={({ active }) =>
                            classNames(
                              active
                                ? 'bg-secondary text-white'
                                : 'text-gray-900',
                              'relative cursor-default select-none py-2 pl-3 pr-9'
                            )
                          }
                          value={''}
                        >
                          {({ selected, active }) => (
                            <>
                              <span
                                className={classNames(
                                  selected ? 'font-semibold' : 'font-normal',
                                  'block truncate'
                                )}
                              >
                                {'Hepsi'}
                              </span>

                              {selected ? (
                                <span
                                  className={classNames(
                                    active ? 'text-white' : 'text-indigo-600',
                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                  )}
                                >
                                  <CheckIcon
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </span>
                              ) : null}
                            </>
                          )}
                        </Listbox.Option>
                        {productTypes &&
                          productTypes.map((prodType: any) => (
                            <Listbox.Option
                              key={prodType.id}
                              className={({ active }) =>
                                classNames(
                                  active
                                    ? 'bg-secondary text-white'
                                    : 'text-gray-900',
                                  'relative cursor-default select-none py-2 pl-3 pr-9'
                                )
                              }
                              value={prodType.id}
                            >
                              {({ selected, active }) => (
                                <>
                                  <span
                                    className={classNames(
                                      selected
                                        ? 'font-semibold'
                                        : 'font-normal',
                                      'block truncate'
                                    )}
                                  >
                                    {prodType.type}
                                  </span>

                                  {selected ? (
                                    <span
                                      className={classNames(
                                        active
                                          ? 'text-white'
                                          : 'text-indigo-600',
                                        'absolute inset-y-0 right-0 flex items-center pr-4'
                                      )}
                                    >
                                      <CheckIcon
                                        className="h-5 w-5"
                                        aria-hidden="true"
                                      />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Listbox.Option>
                          ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
          </div>

          {/* Son 30 Gün Satış */}
          <div className="col-span-6 md:col-span-3 xl:col-span-2">
            <label
              className="w-full py-2 font-medium text-xs xl:text-sm"
              htmlFor="saleCountMin"
            >
              {t?.('etsy-hot-products.filter.last30Sales')}
            </label>
            <div className="flex justify-between items-center space-x-2">
              <input
                value={formik.values.saleCountMin}
                onChange={formik.handleChange}
                id="saleCountMin"
                name="saleCountMin"
                type="number"
                className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                placeholder="Min"
              />
              <p>-</p>
              <input
                value={formik.values.saleCountMax}
                onChange={formik.handleChange}
                id="saleCountMax"
                name="saleCountMax"
                type="number"
                className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                placeholder="Max"
              />
            </div>
            {formik.errors.saleCountMin &&
              errorElement(formik.errors.saleCountMin!)}
            {formik.errors.saleCountMax &&
              errorElement(formik.errors.saleCountMax!)}
          </div>

          {/* Satış Fiyatı */}
          <div className="col-span-6 md:col-span-3 xl:col-span-2">
            <label
              className="w-full py-2 font-medium text-xs min-[1335px]:text-sm   "
              htmlFor="priceMin"
            >
              {t?.('etsy-hot-products.filter.price')}
            </label>
            <div className="flex justify-between items-center space-x-2">
              <input
                value={formik.values.priceMin}
                onChange={formik.handleChange}
                id="priceMin"
                name="priceMin"
                type="number"
                className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                placeholder="Min"
              />
              <p>-</p>
              <input
                value={formik.values.priceMax}
                onChange={formik.handleChange}
                id="priceMax"
                name="priceMax"
                type="number"
                className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                placeholder="Max"
              />
            </div>
            {formik.errors.priceMin && errorElement(formik.errors.priceMin!)}
            {formik.errors.priceMax && errorElement(formik.errors.priceMax!)}
          </div>

          {/* Son 30 Gün Ciro */}
          <div className="col-span-6 md:col-span-3 xl:col-span-2">
            <label
              className="w-full py-2 font-medium text-xs min-[1335px]:text-sm   "
              htmlFor="revenueMin"
            >
              {t?.('etsy-hot-products.filter.last30Revenue')}
            </label>
            <div className="flex justify-between items-center space-x-2">
              <input
                value={formik.values.revenueMin}
                onChange={formik.handleChange}
                id="revenueMin"
                name="revenueMin"
                type="number"
                className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                placeholder="Min"
              />
              <p>-</p>
              <input
                value={formik.values.revenueMax}
                onChange={formik.handleChange}
                id="revenueMax"
                name="revenueMax"
                type="number"
                className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                placeholder="Max"
              />
            </div>
            {formik.errors.revenueMin &&
              errorElement(formik.errors.revenueMin!)}
            {formik.errors.revenueMax &&
              errorElement(formik.errors.revenueMax!)}
          </div>

          {/* HIDDEN GRID */}
          <div className="hidden md:block md:col-span-3 xl:hidden"></div>

          {/* Ürün Yorum */}
          <div className="col-span-6 md:col-span-3 xl:col-span-2">
            <label
              className="w-full py-2 font-medium text-xs min-[1335px]:text-sm   "
              htmlFor="reviewCountMin"
            >
              {t?.('etsy-hot-products.filter.review')}
            </label>
            <div className="flex justify-between items-center space-x-2">
              <input
                value={formik.values.reviewCountMin}
                onChange={formik.handleChange}
                id="reviewCountMin"
                name="reviewCountMin"
                type="number"
                className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                placeholder="Min"
              />
              <p>-</p>
              <input
                value={formik.values.reviewCountMax}
                onChange={formik.handleChange}
                id="reviewCountMax"
                name="reviewCountMax"
                type="number"
                className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                placeholder="Max"
              />
            </div>
            {formik.errors.reviewCountMin &&
              errorElement(formik.errors.reviewCountMin!)}
            {formik.errors.reviewCountMax &&
              errorElement(formik.errors.reviewCountMax!)}
          </div>

          {/* Ürün Görüntülenme */}
          <div className="col-span-6 md:col-span-3 xl:col-span-2">
            <label
              className="w-full py-2 font-medium text-xs min-[1335px]:text-sm   "
              htmlFor="viewCountMin"
            >
              {t?.('etsy-hot-products.filter.view')}
            </label>
            <div className="flex justify-between items-center space-x-2">
              <input
                value={formik.values.viewCountMin}
                onChange={formik.handleChange}
                id="viewCountMin"
                name="viewCountMin"
                type="number"
                className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                placeholder="Min"
              />
              <p>-</p>
              <input
                value={formik.values.viewCountMax}
                onChange={formik.handleChange}
                id="viewCountMax"
                name="viewCountMax"
                type="number"
                className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                placeholder="Max"
              />
            </div>
            {formik.errors.viewCountMin &&
              errorElement(formik.errors.viewCountMin!)}
            {formik.errors.viewCountMax &&
              errorElement(formik.errors.viewCountMax!)}
          </div>

          {/* HIDDEN GRID */}
          <div className="hidden md:block md:col-span-3 xl:hidden"></div>
        </div>

        {/* SUBMIT BTN */}
        <div className="flex justify-center">
          <button
            type="submit"
            className="bg-headerPrimary px-4 py-2 md:px-12 rounded-lg text-white mt-4"
          >
            {t?.('etsy-hot-products.filter.filter')}
          </button>
        </div>
      </form>
    </>
  );
};

export default FilteringEtsyHotProducts;
