import React, { useEffect, useRef, useState } from 'react';

interface QuestionProps {
    handleForward: (question: string, answer: string | string[]) => void
    handleBackward: (question: string, answer: string | string[]) => void
}
const Question6: React.FC<QuestionProps> = ({
    handleForward,
    handleBackward
}) => {
    const [answers, setAnswers] = useState(() => {
        const storedAnswers = JSON.parse(
            sessionStorage.getItem('answer6') || '[]'
        );
        return storedAnswers.length > 0 ? storedAnswers : [''];
    });

    const handleCheckboxChange = (answer: string) => {
        const updatedAnswers = [...answers];
        const answerIndex = updatedAnswers.indexOf(answer);

        if (answerIndex > -1) {
            updatedAnswers.splice(answerIndex, 1);
        } else {
            updatedAnswers.push(answer);
        }

        setAnswers(updatedAnswers);
    };

    const handleForwardClick = () => {
        const updatedAnswers = [...answers];
        setAnswers(updatedAnswers);
        handleForward('question6', updatedAnswers);
    };

    const handleBackwardClick = () => {
        const updatedAnswers = [...answers];
        handleBackward('question6', updatedAnswers);
    };

    useEffect(() => {
        sessionStorage.setItem('answer6', JSON.stringify(answers));
    }, [answers]);


    const isAnswerChecked = (answer: string) => answers.includes(answer);

    const notificationMethods = [
        { id: '1', title: '0-$1000' },
        { id: '2', title: '$1000 - $5000' },
        { id: '3', title: '$5000 - $10.000' },
        { id: '4', title: '$10.000 - $50.000' },
        { id: '5', title: '$50.000 ve üzeri' },
    ]
    return (
        <>
            <div>
                <div className="flex flex-col space-y-4 min-h-[400px] justify-between">
                    <div className='mt-2'>
                        <label className="text-base font-semibold text-gray-900"> E-ticaret için ayırdığınız bütçe ne kadar?</label>
                        <fieldset className="mt-4">
                            <legend className="sr-only">Notification method</legend>
                            <div className="space-y-4">
                                {notificationMethods.map((notificationMethod) => (
                                    <div key={notificationMethod.id} className="flex items-center">
                                        <input
                                            id={notificationMethod.id}
                                            name="notification-method"
                                            type="checkbox"
                                            checked={isAnswerChecked(notificationMethod.title)}
                                            className="h-4 w-4 border-gray-300 text-cardBorder focus:ring-cardBorder"
                                            onChange={() => handleCheckboxChange(notificationMethod.title)}
                                        />
                                        <label
                                            htmlFor={notificationMethod.id}
                                            className="ml-3 block text-sm font-small leading-6 text-gray-900"
                                        >
                                            {notificationMethod.title}
                                        </label>
                                    </div>
                                ))}
                            </div>
                        </fieldset>
                    </div>
                    <div className="flex items-center text-[16px] bg justify-between mt-2">
                        <div
                            onClick={handleBackwardClick}
                            className=" text-center text-[13px] px-3 h-[50px] max-h-[50px] font-bold  lg:py-1  w-[49%] flex items-center w-full cursor-pointer">Geri</div>                        <div
                                onClick={handleForwardClick}
                                className="hover:cursor-pointer text-center text-[12px] bg-headerPrimary font-bold text-white h-[50px] max-h-[50px]  px-1 py-1  border-2 rounded-lg border-headerPrimary w-[49%] flex items-center justify-center"
                            >
                            Gönder
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Question6