import React, { Fragment, useState, useRef } from 'react';
import { useLanguage } from '../../context/LanguageContext';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { FaqCategory } from '../../pages/Faqs';
import Searchbar from '../common/SearchBar';

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

export interface Props {
  faqCategories: FaqCategory[] | undefined;
  selectedCategory: {
    id: string;
    name: string;
  };
  setSelectedCategory: React.Dispatch<
    React.SetStateAction<{
      id: string;
      name: string;
    }>
  >;
  setKeyword: React.Dispatch<React.SetStateAction<string>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}

const SearchField: React.FC<Props> = ({
  faqCategories,
  selectedCategory,
  setSelectedCategory,
  setKeyword,
  setPage,
}) => {
  const { t } = useLanguage();

  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleClick = () => {
    setKeyword(String(inputRef?.current?.value));
    setPage(1);
  };
  return (
    <div
      data-testid="srchField"
      className="flex flex-col gap-y-2 md:flex-row md:gap-0 md:justify-center md:gap-x-3 md:mt-4 md:mb-6"
    >
      <Listbox value={selectedCategory} onChange={setSelectedCategory}>
        {({ open }) => (
          <>
            <div className="relative md:w-1/3">
              <Listbox.Button className="relative w-full cursor-default rounded-lg bg-white py-2 pl-3 pr-10  text-xs text-left text-gray-900 shadow-sm ring-1 ring-inset ring-headerPrimary focus:outline-none   sm:text-sm sm:leading-6">
                <span className="block truncate">{selectedCategory?.name}</span>
                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                  <ChevronUpDownIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </span>
              </Listbox.Button>

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-xs shadow-lg ring-1 ring-black ring-opacity-5 focus:ring-secondary focus:outline-none sm:text-sm">
                  <Listbox.Option
                    key={'1'}
                    className={({ active }) =>
                      classNames(
                        active ? 'bg-secondary text-white' : 'text-gray-900',
                        'relative cursor-default select-none py-2 pl-3 pr-9'
                      )
                    }
                    value={{ id: '', name: 'Tüm Kategoriler' }}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={classNames(
                            selected ? 'font-semibold' : 'font-normal',
                            'block truncate'
                          )}
                        >
                          {'TÜM KATEGORİLER'}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                  {faqCategories?.map((category) => (
                    <Listbox.Option
                      key={category.id}
                      className={({ active }) =>
                        classNames(
                          active ? 'bg-secondary text-white' : 'text-gray-900',
                          'relative cursor-default select-none py-2 pl-3 pr-9'
                        )
                      }
                      value={category}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={classNames(
                              selected ? 'font-semibold' : 'font-normal',
                              'block truncate'
                            )}
                          >
                            {category.name}
                          </span>

                          {selected ? (
                            <span
                              className={classNames(
                                active ? 'text-white' : 'text-indigo-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4'
                              )}
                            >
                              <CheckIcon
                                className="h-5 w-5"
                                aria-hidden="true"
                              />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </>
        )}
      </Listbox>
      <div className="md:w-1/3">
        <Searchbar
          placeholder="Aramak istediğiniz kelimeyi giriniz"
          setKeyword={setKeyword}
          setPage={setPage}
        />
      </div>
      {/* <div className="md:w-1/3">
        <input
          ref={inputRef!}
          type="text"
          name="keyoword"
          id="keyword"
          className="block w-full text-xs rounded-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-secondary placeholder:text-gray-400  sm:text-sm sm:leading-6"
          placeholder={t?.('sss.search.placeholder')}
        />
      </div>
      <button
        onClick={handleClick}
        className="inline-flex justify-center items-center rounded-md bg-headerPrimary py-1.5 px-8 text-xs font-semibold text-white shadow-sm hover:bg-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 md:text-sm"
      >
        Ara
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.8"
          stroke="white"
          className="w-5 h-5 ml-2"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
          />
        </svg>
      </button> */}
    </div>
  );
};

export default SearchField;
