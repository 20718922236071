import React, { useEffect, useState } from 'react';
import LoadingComponent from '../common/LoadingComponent';
import { ToastContainer } from 'react-toastify';
import { classNames } from '../../utils/conditionalClasses';
import { useAuth } from '../../context/AuthContext';
import { useLanguage } from '../../context/LanguageContext';
import { EtsyOrder, EtsyOrderItem } from '../../pages/MarketplaceOrders';
import { formatDateTR } from '../../utils/date';
import OrderMatchingModal from './OrderMatchingModal';
import AddNoteModal from './AddNoteModal';
import { Link, useSearchParams } from 'react-router-dom';
import PaymentModal from './PaymentModal';
import { Tooltip } from 'react-tooltip';
import ReactCountryFlag from 'react-country-flag';
import {
  Copy,
  DocumentDownload,
  Edit2,
  EmptyWallet,
  Eye,
  EyeSlash,
  InfoCircle,
  Link2,
  Messages1,
  Truck,
} from 'iconsax-react';
import StatusSection from './StatusSection';
import marketplaceService from '../../services/marketplaceService';
import alertNotification from '../../utils/alertNotification';
import WalletModal from './WalletModal';
import DocumentUploadModal from './DocumentUploadModal';
import UpdateOrderForm from './UpdateOrderForm';
import DesignModal from './DesignModal';
import ShipmentRadioGroup from './ShipmentRadioGroup';
import httpClient from '../../utils/httpClient';
import API_URL from '../../config';
import { DocumentPlusIcon } from '@heroicons/react/20/solid';
import LoadingModal from '../common/LoadingModal';
import ResendModal from './ResendModal';
import { copyToClipboard } from '../../utils/copyToClipboard';

interface MarketplaceOrderProps {
  page: number | string;
  isLoading: boolean;
  data: EtsyOrder[];
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setInputPage: React.Dispatch<React.SetStateAction<number | string>>;
  balance: number;
  getWalletBalance: () => void;
  setQueryParams: React.Dispatch<any>;
  getOrders: () => void;
  labelCounts: any;
}

const MarketplaceOrderTable: React.FC<MarketplaceOrderProps> = ({
  page,
  isLoading,
  data,
  setPage,
  setInputPage,
  balance,
  setQueryParams,
  labelCounts,
  getOrders,
  getWalletBalance,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDesignModalOpen, setIsDesignModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [selectedEtsyOrder, setSelectedEtsyOrder] = useState<any>(null);
  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [isDocumentModalOpen, setIsDocumentModalOpen] = useState(false);
  const [isWalletModalOpen, setIsWalletModalOpen] = useState(false);
  const [multiplier, setMultiplier] = useState(1);
  const [selectedProductId, setSelectedProductId] = useState<any>(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [selectedOrders, setSelectedOrders] = useState<EtsyOrderItem[]>([]);
  const [selectedOrderItemId, setSelectedOrderItemId] = useState<any>(null);
  const [resendOrderItems, setResendOrderItems] = useState<any[]>([]);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isCustom, setIsCustom] = useState(false);
  const [resendModal, setResendModal] = useState(false);
  const [loadingLabel, setLoadingLabel] = useState(false);
  const [note, setNote] = useState('');
  const [searchParams] = useSearchParams();

  const { session } = useAuth();
  const { t } = useLanguage();

  const addNoteToOrder = async (orderId: number, note: string) => {
    try {
      await marketplaceService.addNoteToOrder(orderId, note);
      alertNotification('success', 'Notunuz eklendi');
      getOrders();
    } catch (error) {
      alertNotification('error', 'Not eklenirken bir hata oluştu');
    }
  };

  const renderButton = (label: string, order: any) => {
    if (label === 'waitingMatch') {
      return (
        <button
          className={
            'text-white bg-[#4F46E5] text-xs 2xl:text-sm px-4 py-2 rounded-full w-full flex items-center justify-center space-x-2 cursor-pointer'
          }
          // disabled={selectedOrders.length === 0}
          onClick={() => {
            setSelectedOrder(order);
            setIsModalOpen(true);
          }}
        >
          <Link2 color="white" size="24" variant="Bold" /> <p>Eşleştir</p>
        </button>
      );
    } else if (label === 'waitingPayment') {
      return (
        <button
          className={
            'text-white bg-[#0D9488] text-xs 2xl:text-sm px-4 py-2 rounded-full w-full flex justify-center items-center space-x-2 cursor-pointer'
          }
          onClick={() => {
            setSelectedOrders([order]);
            setIsPaymentModalOpen(true);
          }}
        >
          <EmptyWallet size="24" color="white" variant="Bold" />{' '}
          <p>Ödeme Yap</p>
        </button>
      );
    } else if (
      label === 'waitingProducing' ||
      label === 'producing' ||
      label === 'waitingLabel'
    ) {
      return (
        <button
          className="text-white bg-[#F59E0B] text-xs 2xl:text-sm px-4 py-2 rounded-full w-full flex items-center justify-center space-x-2 cursor-pointer"
          disabled={order.labelPdf !== null}
          onClick={() => {
            if (
              order.allShipmentCost.some((item: any) =>
                item.serviceCode.includes('shipentegra')
              )
            ) {
              getShipentegraLabel(order.platformOrderId);
            } else {
              getShipstationLabel(order.platformOrderId);
            }
          }}
        >
          <DocumentDownload size="24" color="white" variant="Bold" />{' '}
          <p>Label Al</p>
        </button>
      );
    } else if (label === 'delivered' || label === 'shipped') {
      return (
        <button
          className="text-[#111827] border-2 border-[#111827] bg-transparent text-xs 2xl:text-sm px-4 py-2 rounded-full w-full"
          disabled={order.labelPdf !== null}
          onClick={() => {
            setResendModal(true);
            setResendOrderItems(order.seller_order_items);
          }}
        >
          Yeniden Gönder
        </button>
      );
    }
  };
  const getShipstationLabel = async (orderId: number) => {
    setLoadingLabel(true);
    try {
      await httpClient.get(
        `/api/v1/marketplace/shipstation-label?platformOrderId=${orderId}`
      );
      alertNotification('success', 'Etiket alma işlemi başarılı');
      getOrders();
    } catch (error: any) {
      alertNotification('error', error.response.data.message);
    } finally {
      setLoadingLabel(false);
    }
  };

  const getShipentegraLabel = async (orderId: number) => {
    setLoadingLabel(true);
    try {
      await httpClient.get(
        `/api/v1/marketplace/shipentegra-label?platformOrderId=${orderId}`
      );
      getOrders();
      alertNotification('success', 'Etiket alma işlemi başarılı');
    } catch (error: any) {
      alertNotification('error', error.response.data.message);
    } finally {
      setLoadingLabel(false);
    }
  };
  const tableHeader = [
    { name: 'Seç', column: 'choose' },
    { name: t?.('marketplace-orders.table.orderId'), column: 'orderId' },
    { name: 'Fotoğraf', column: 'product' },

    { name: 'Detay', column: 'details' },
    // {
    //   name: t?.('marketplace-orders.table.buyer'),
    //   column: 'customerFirstName',
    // },
    { name: t?.('marketplace-orders.table.price'), column: 'totalPrice' },
    { name: 'Kargo', column: 'shipment' },
    // { name: t?.('marketplace-orders.table.gift'), column: 'giftMessage' },
    // {
    //   name: t?.('marketplace-orders.table.addNote'),
    //   column: 'orderDate',
    // },

    {
      name: 'Tasarım',
      column: 'design',
    },
    {
      name: 'İşlemler',
      column: 'edit',
    },
    // {
    //   name: 'Tekrar Gönder',
    //   column: 'resend',
    // },
  ];

  const hideOrder = async (id: number[]) => {
    try {
      const label =
        searchParams.get('label') === 'hidden' ? 'hidden' : 'waitingMatch';
      console.log(id);
      if (label === 'hidden') {
        await marketplaceService.updateOrderLabel({
          orderItemIds: id,
          label: 'waitingMatch',
        });
      } else {
        await marketplaceService.updateOrderLabel({
          orderItemIds: id,
          label: 'hidden',
        });
      }
      getOrders();
    } catch (error: any) {
      alertNotification('error', error.response.data.message);
    }
  };
  const handleNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNote(e.target.value);
  };
  const handleAddNote = (order: any) => {
    addNoteToOrder(Number(order), note);
    setNote('');
  };

  const toggleOrderSelection = (selectedItem: EtsyOrderItem) => {
    setSelectedOrders((prevSelectedOrders) => {
      const isAlreadySelected = prevSelectedOrders.some(
        (orderItem) => orderItem.id === selectedItem.id
      );

      if (isAlreadySelected) {
        return prevSelectedOrders.filter(
          (orderItem) => orderItem.id !== selectedItem.id
        );
      } else {
        return [...prevSelectedOrders, selectedItem];
      }
    });
  };

  const downloadLabel = async (url: string) => {
    console.log('first');
    console.log(url);
    const response = await fetch(url);
    console.log(response);
    const blob = await response.blob();
    const filename = 'label.pdf';
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
  };
  const decideTracking = (serviceCode: string, trackingNumber: string) => {
    if (serviceCode.includes('shipentegra')) {
      return `https://track.shipentegra.com/track?showLastMileCarrier=true&trackno=${trackingNumber}`;
    } else if (serviceCode.includes('usps')) {
      return `https://tools.usps.com/go/TrackConfirmAction?tRef=fullpage&tLc=2&text28777=&tLabels=${trackingNumber}%2C&tABt=true`;
    } else if (serviceCode.includes('ups')) {
      return `https://www.ups.com/track?loc=en_US&tracknum=${trackingNumber}`;
    }
  };

  useEffect(() => {
    if (session?.giftPackageName) {
      if (session?.giftPackageName === 'Rexven Premium') {
        setMultiplier(1.1);
      } else if (session?.giftPackageName === 'Rexven Plus') {
        setMultiplier(1.15);
      } else if (session?.giftPackageName === 'Elit') {
        setMultiplier(1.05);
      }
    } else {
      if (session?.packageName === 'Free') {
        setMultiplier(1.2);
      } else if (session?.packageName === 'Rexven Premium') {
        setMultiplier(1.1);
      } else if (session?.packageName === 'Rexven Plus') {
        setMultiplier(1.15);
      } else if (session?.packageName === 'Elit') {
        setMultiplier(1.05);
      }
    }
  }, [session]);

  return (
    <div className="flex flex-col flex-shrink-0 w-full bg-white rounded-2xl p-5 shadow-lg mt-2 sm:p-4 relative">
      <div className="flex justify-between items-center flex-1 space-x-4 p-4">
        <button
          className={classNames(
            selectedOrders.length === 0
              ? 'text-[#4B5563] bg-[#D1D5DB]'
              : 'text-white bg-[#2563EB]',
            ' text-xs 2xl:text-sm px-4 py-2 rounded-lg '
          )}
          disabled={selectedOrders.length === 0}
          onClick={() => setIsPaymentModalOpen(true)}
        >
          Ödeme Yap
        </button>
        <div className="flex justify-start mb-4 items-center space-x-4">
          <button
            className="bg-headerPrimary text-white font-medium text-xs 2xl:text-sm py-2 rounded-lg w-44 border-none"
            onClick={() => setIsWalletModalOpen(true)}
          >
            Cüzdan: ${Number(balance).toFixed(2)}
          </button>
        </div>
      </div>

      <StatusSection
        labelCounts={labelCounts}
        setQueryParams={setQueryParams}
        setInputPage={setInputPage}
        setPage={setPage}
      />
      {/* TABLE */}
      <div className="flow-root">
        <div className="relative -mx-4 -my-2 overflow-x-auto overflow-y-auto scrollbar-hide">
          <div className="inline-block min-w-full py-2 align-middle  sm:px-6 lg:px-8">
            <div className=" flex flex-col flex-shrink-0 bg-white rounded-xl mt-8">
              <div className="relative overflow-y-auto overflow-x-auto scrollbar-hide">
                <div className="inline-block min-w-full align-middle h-[100vh]">
                  <table className="min-w-[1280px] w-full">
                    <thead>
                      <tr>
                        {tableHeader.map((item, index) => {
                          return (
                            <th
                              key={index}
                              className="sticky top-0 z-10 text-center bg-headerPrimary py-3.5 pl-4 pr-3 text-sm font-semibold text-white first:rounded-tl-lg last:rounded-tr-lg first:rounded-bl-lg last:rounded-br-lg"
                              // scope="col"
                            >
                              {' '}
                              <p className="text-xs md:text-sm">{item.name}</p>
                            </th>
                          );
                        })}
                      </tr>
                    </thead>

                    {isLoading ? (
                      <div className="w-full flex justify-center">
                        <LoadingComponent />
                      </div>
                    ) : (
                      <tbody className="divide-y divide-gray-200 bg-white">
                        <tr className="h-10"></tr>
                        {data?.map((order: any, idx) => {
                          return (
                            <tr key={idx} className="shadow-md border-none">
                              <td className="px-1 text-xs md:text-sm sm:pl-0 ">
                                <div className="flex justify-center">
                                  {order?.seller_order_items[0]?.label ===
                                    'waitingPayment' && (
                                    <input
                                      type="checkbox"
                                      className="rounded-sm border-blue-500"
                                      checked={selectedOrders.some(
                                        (orderItem) => orderItem.id === order.id
                                      )}
                                      onChange={() =>
                                        toggleOrderSelection(order)
                                      }
                                    />
                                  )}
                                </div>
                              </td>
                              {/* <td className="py-4 pl-4 text-xs md:text-sm sm:pl-0">
                                <p className="text-center text-gray">
                                  {idx + 20 * (Number(page) - 1) + 1}
                                </p>
                              </td> */}
                              <td className="py-4 text-xs sm:pl-0 whitespace-nowrap text-gray-500">
                                <div className="flex flex-col items-start space-y-1">
                                  <p>
                                    <span className="text-headerPrimary">
                                      Tarih:
                                    </span>{' '}
                                    {formatDateTR(order.orderDate)}
                                  </p>
                                  <p>
                                    <span className="text-headerPrimary">
                                      Son tarih:{' '}
                                    </span>
                                    {order.seller_etsy_orders?.shipByDate &&
                                      formatDateTR(
                                        order.seller_etsy_orders.shipByDate
                                      )}{' '}
                                  </p>
                                  <p>
                                    <span className="text-headerPrimary">
                                      Sipariş no:{' '}
                                    </span>
                                    {order?.platformOrderId}
                                  </p>
                                  <p>
                                    <span className="text-headerPrimary">
                                      Müşteri:{' '}
                                    </span>
                                    {order?.fullName}
                                  </p>
                                  <div className="flex items-center space-x-1">
                                    <p className="text-headerPrimary">Ülke: </p>
                                    <p>
                                      {
                                        order?.seller_etsy_orders
                                          .customerCountry
                                      }
                                    </p>
                                    <Tooltip
                                      anchorSelect={`#address${idx}`}
                                      content={
                                        order?.seller_etsy_orders
                                          ?.customerAddress1
                                      }
                                      style={{
                                        width: '200px',
                                      }}
                                    />
                                    <ReactCountryFlag
                                      id={`address${idx}`}
                                      className="ml-1 text-base"
                                      svg
                                      countryCode={
                                        order?.seller_etsy_orders
                                          .customerCountry
                                      }
                                    />
                                  </div>

                                  {renderButton(
                                    order?.seller_order_items[0]?.label,
                                    order
                                  )}
                                </div>
                              </td>
                              <td className="px-2">
                                <div
                                  className={classNames(
                                    order?.seller_order_items.length > 1
                                      ? 'space-y-2'
                                      : 'space-y-0',
                                    'px-2 py-2 flex flex-col items-center justify-center'
                                  )}
                                >
                                  {order.seller_order_items.map((item: any) => {
                                    return (
                                      <div className="relative">
                                        {item.seller_product.title ===
                                          'Gift Wrap' ||
                                        item.seller_product.title === '' ? (
                                          <img
                                            className="h-[160px] w-[160px] aspect-square object-cover object-center rounded-lg"
                                            src={'/images/giftwrap1.webp'}
                                            alt="Product"
                                          />
                                        ) : (
                                          <img
                                            className=" h-[160px] w-[160px] aspect-square object-cover object-center rounded-lg mb-2"
                                            src={
                                              item.seller_product.images[0]
                                                ? item.seller_product.images[0]
                                                : '/images/image-not-found.png'
                                            }
                                            alt="Product"
                                          />
                                        )}
                                        {/* {item.seller_product.title && (
                                          <button
                                            className="absolute top-1 right-1 z-1 bg-[#4F46E5] rounded-lg p-1.5"
                                            onClick={() => {
                                              setSelectedOrder(order);
                                              setIsModalOpen(true);
                                            }}
                                          >
                                            <Link2
                                              size="24"
                                              color="white"
                                              variant="Bold"
                                            />
                                          </button>
                                        )} */}
                                        {item.seller_product.title !==
                                          'Gift Wrap' &&
                                          item.seller_product.title !== '' && (
                                            <button
                                              className="py-1 px-4 bg-white text-[#2563EB] border border-[#2563EB] rounded-lg flex items-center justify-center w-[160px]"
                                              onClick={() => {
                                                const etsyOrder =
                                                  order?.seller_etsy_orders;
                                                let temp = {
                                                  id: item.id,
                                                  giftMessage:
                                                    etsyOrder.giftMessage,
                                                  buyerMessage:
                                                    etsyOrder.buyerMessage,
                                                  isGift: etsyOrder.isGift,
                                                  attributes:
                                                    item.seller_product
                                                      ?.attributes,
                                                  personalization:
                                                    item.personalization,
                                                  needsDesign:
                                                    item.supplierOrderNeedsDesign,
                                                  designFiles: item.designFiles,
                                                };

                                                setSelectedEtsyOrder(temp);
                                                setIsUpdateModalOpen(true);
                                              }}
                                            >
                                              <Edit2
                                                color="#2563EB"
                                                size={18}
                                              />
                                              <p className="text-xs">
                                                Güncelle
                                              </p>
                                            </button>
                                          )}
                                      </div>
                                    );
                                  })}
                                </div>
                              </td>

                              <td className="py-4 text-xs md:text-sm text-left overflow-hidden text-gray-500 sm:pl-0">
                                <div className="flex flex-col items-start space-y-4">
                                  {order.seller_order_items.map(
                                    (item: any, index: any) => (
                                      <div
                                        key={index}
                                        className="flex items-center space-x-4 px-2"
                                      >
                                        {/* "text-xs text-left text-[#1F2937] overflow-hidden h-16 font-medium" */}
                                        <div className="flex flex-col space-y-1 h-[200px] py-4 w-48 justify-center">
                                          <p
                                            className={classNames(
                                              item.seller_product.title ===
                                                'Gift Wrap'
                                                ? 'ml-12'
                                                : 'ml-0',
                                              'text-xs text-left text-[#1F2937] font-medium'
                                            )}
                                          >
                                            {item.seller_product.title.substring(
                                              0,
                                              44
                                            )}
                                            ...
                                          </p>

                                          {item.seller_product.title !==
                                            'Gift Wrap' && (
                                            <p className="text-xs text-[#1F2937]">
                                              Adet:{' '}
                                              <span className="text-gray-500">
                                                {item.quantity}
                                              </span>
                                            </p>
                                          )}

                                          {item.seller_product.title !==
                                            'Gift Wrap' && (
                                            <div className="text-xs text-left text-gray-500 min-w-[160px] h-[200px] overflow-y-auto">
                                              {item.seller_product.attributes
                                                .length > 0 &&
                                                Object.entries(
                                                  item.seller_product
                                                    .attributes[0]
                                                ).map(([key, value]) => {
                                                  if (
                                                    !key.includes(
                                                      'CustomizedURL'
                                                    )
                                                  ) {
                                                    return (
                                                      <div
                                                        className="flex space-x-1"
                                                        key={`${index}-${key}`}
                                                      >
                                                        <p className="text-[#1F2937]">
                                                          {key}:
                                                        </p>
                                                        <p>{value as string}</p>
                                                      </div>
                                                    );
                                                  } else {
                                                    return null;
                                                  }
                                                })}
                                              {(item.personalization ||
                                                item.customizationData.length >
                                                  0) && (
                                                <p className="font-medium text-[#1F2937]">
                                                  Kişiselleştirme:{' '}
                                                  <span className="font-normal">
                                                    {item.personalization
                                                      ? item.personalization
                                                      : item.customizationData
                                                      ? item.customizationData.map(
                                                          (
                                                            item: any,
                                                            index: number
                                                          ) => {
                                                            if (index > 8)
                                                              return (
                                                                <p>{item}</p>
                                                              );
                                                          }
                                                        )
                                                      : '-'}
                                                  </span>
                                                </p>
                                              )}
                                            </div>
                                          )}
                                          <p className="font-medium text-[#1F2937] text-xs flex">
                                            Ürün hediye mi?:
                                            <span className="font-normal ml-1">
                                              {order.seller_etsy_orders
                                                .isGift === true
                                                ? 'Evet'
                                                : 'Hayır'}{' '}
                                            </span>
                                            <span
                                              className="ml-1"
                                              id={`isGift${idx}`}
                                            >
                                              <InfoCircle
                                                size={16}
                                                color="#111827"
                                              />
                                            </span>
                                          </p>
                                        </div>
                                      </div>
                                    )
                                  )}
                                  <Tooltip
                                    anchorSelect={`#isGift${idx}`}
                                    content={
                                      `Mesaj:${
                                        order?.seller_etsy_orders.giftMessage
                                          ? order?.seller_etsy_orders
                                              .giftMessage
                                          : '-'
                                      }` +
                                      '/ ' +
                                      `Alıcı Mesajı:${
                                        order?.seller_etsy_orders.buyerMessage
                                          ? order?.seller_etsy_orders
                                              .buyerMessage
                                          : '-'
                                      }`
                                    }
                                  />
                                </div>
                              </td>

                              {/* <td className="py-4 text-xs text-left text-gray-500">
                                <div className="flex flex-col w-32 items-start space-y-1">
                                  <p>
                                    <span className="text-[#1F2937]">
                                      İsim:{' '}
                                    </span>
                                    {order.seller_etsy_orders.customerFirstName}{' '}
                                    {order.seller_etsy_orders.customerLastName}
                                  </p>
                                  {/* <div>
                                    <span className="text-[#1F2937]">
                                      Email:
                                    </span>{' '}
                                    {order.seller_etsy_orders.email}{' '}
                                  </div> */}
                              {/* <div>
                                    <span className="text-[#1F2937]">
                                      Adres:{' '}
                                    </span>
                                    {order.seller_etsy_orders.customerAddress1}
                                    {order.seller_etsy_orders.customerAddress2}
                                  </div>
                                  <p>
                                    <span className="text-[#1F2937]">
                                      Şehir:{' '}
                                    </span>
                                    {order.seller_etsy_orders.customerCity}
                                  </p>
                                  <p>
                                    <span className="text-[#1F2937]">
                                      Eyalet:{' '}
                                    </span>
                                    {order.seller_etsy_orders.customerState}{' '}
                                  </p>
                                  <p>
                                    <span className="text-[#1F2937]">
                                      Posta Kodu:{' '}
                                    </span>
                                    {
                                      order.seller_etsy_orders
                                        .customerPostalCode
                                    }
                                  </p>
                                  <div>
                                    <span className="text-[#1F2937]">
                                      Ülke:{' '}
                                    </span>
                                    {order.seller_etsy_orders.customerCountry}
                                  </div>
                                </div> */}
                              {/* </td> */}
                              <td className="py-4 text-center text-xs md:text-xs text-gray-500">
                                <div className="flex flex-col items-center space-y-1 px-2">
                                  <div>
                                    <span className="text-lg text-center font-medium text-headerPrimary">
                                      {order.currency === 'USD'
                                        ? '$'
                                        : order.currency}{' '}
                                      {Number(order.total).toFixed(2)}
                                    </span>
                                  </div>

                                  {order?.seller_order_items[0]
                                    ?.supplierOrderProductVariantPrice && (
                                    <div className="w-24">
                                      <span className="text-[#1F2937]">
                                        Alış:
                                      </span>
                                      $
                                      {order?.seller_order_items[0]
                                        ?.supplierOrderProductVariantPrice
                                        ? order?.seller_order_items
                                            .reduce(
                                              (acc: number, item: any) =>
                                                acc +
                                                item.quantity *
                                                  item.supplierOrderProductVariantPrice *
                                                  multiplier,
                                              0
                                            )
                                            .toFixed(2)
                                        : ' -'}
                                    </div>
                                  )}
                                </div>
                              </td>
                              <td className="py-4 text-xs md:text-sm text-left overflow-hidden text-gray-500 sm:pl-0">
                                {order?.allShipmentCost.length > 0 ? (
                                  <ShipmentRadioGroup
                                    groupIndex={idx}
                                    getOrders={getOrders}
                                    sellerOrderId={order?.id}
                                    allShipmentCost={order?.allShipmentCost!}
                                    serviceCode={order?.serviceCode}
                                    country={
                                      order?.seller_etsy_orders.customerCountry
                                    }
                                  />
                                ) : (
                                  <div className="w-full px-2 py-4 rounded-xl flex space-x-2 justify-center items-center bg-[#FFFBEB]">
                                    <InfoCircle size={24} color="#F59E0B" />
                                    <p className="text-[#F59E0B] text-xs">
                                      Lütfen ürün eşleştirmesi yapınız.
                                    </p>
                                  </div>
                                )}
                                {order.trackingNumber && (
                                  <div className="rounded-lg px-2 w-44">
                                    <p
                                      className="text-xs mt-2 border-gray-500 border-2 rounded-lg px-2 py-1 text-center mb-2 flex cursor-pointer"
                                      onClick={() =>
                                        copyToClipboard(order?.trackingNumber)
                                      }
                                    >
                                      {order?.trackingNumber.substring(0, 16)}
                                      ...
                                      <Copy
                                        size={18}
                                        color="#374151"
                                        className="ml-1"
                                      />
                                    </p>
                                    <a
                                      className="flex space-x-2 cursor-pointer border-2 border-[#EA580C] rounded-lg px-2 py-1 text-white mx-auto items-center"
                                      target="_blank"
                                      rel="noreferrer"
                                      href={decideTracking(
                                        order?.serviceCode,
                                        order?.trackingNumber
                                      )}
                                    >
                                      <Truck size={24} color="#EA580C" />
                                      <p className="text-[#EA580C] text-xs">
                                        Kargo Takip
                                      </p>
                                    </a>
                                  </div>
                                )}
                              </td>

                              {/* <td className="py-4 px-2 text-xs text-left w-40 text-gray-500">
                                <div className="flex flex-col items-start space-y-1 min-w-[140px]">
                                  <div>
                                    Ürün hediye mi?:{' '}
                                    {order.seller_etsy_orders.isGift === true
                                      ? 'Evet'
                                      : 'Hayır'}{' '}
                                  </div>
                                
                                  <div>
                                    Hediye mesajı:{' '}
                                    {order.seller_etsy_orders.giftMessage
                                      ? order.seller_etsy_orders.giftMessage
                                      : '-'}{' '}
                                  </div>
                                  <div>
                                    Alıcı mesajı:{' '}
                                    {order.seller_etsy_orders.buyerMessage
                                      ? order.seller_etsy_orders.buyerMessage
                                      : '-'}
                                  </div>
                                </div>
                              </td> */}
                              {/* NOT EKLE */}
                              {/* <td className="py-4 px-4">
                                {order.seller_order_items[0].note ? (
                                  <div>
                                    <p className="text-xs text-headerPrimary">
                                      {order.seller_order_items[0].note}
                                    </p>
                                    <button
                                      className="text-sm text-[#FB923C] border-none outline-none flex-1 flex items-center space-x-2"
                                      onClick={() => {
                                        setSelectedOrder(order);
                                        setIsNoteModalOpen(true);
                                      }}
                                    >
                                      <p>Değiştir</p>
                                      <Edit2 color="#FB923C" size={24} />
                                    </button>
                                  </div>
                                ) : (
                                  <>
                                    <textarea
                                      className="border-none rounded-lg bg-lightGray focus:outline-none text-xs placeholder:text-xs w-full"
                                      name="note"
                                      id="note"
                                      onChange={handleNoteChange}
                                      rows={5}
                                      placeholder="Not ekleyin..."
                                    />
                                    <div className="flex space-x-2">
                                      <button
                                        className="bg-[#2563EB] text-white text-xs px-3 py-1 rounded-lg whitespace-nowrap inline-block focus:outline-none flex-1"
                                        onClick={() =>
                                          handleAddNote(
                                            order.seller_order_items[0].id
                                          )
                                        }
                                      >
                                        Not Ekle
                                      </button>
                                    </div>
                                  </>
                                )}
                              </td> */}
                              {/* <td>
                                <div className="mt-3 flex items-center space-x-2 px-2">
                                  <Tooltip
                                    anchorSelect="#message"
                                    content="Mesaj"
                                  />
                                  {order?.seller_order_items[0]?.supplier_order
                                    .supplier.userId && (
                                    <Link
                                      id="message"
                                      target="_blank"
                                      to={`/messages?to=${order?.seller_order_items[0]?.supplier_order.supplier.userId}&name=${order?.seller_order_items[0]?.supplier_order.supplier.name}`}
                                      // &name=${order?.seller_order.user.firstName}
                                      className="px-2 py-1 rounded-full whitespace-nowrap focus:outline-none focus:ring"
                                    >
                                      <Messages1
                                        size="32"
                                        color="#2563EB"
                                        variant="Bold"
                                      />
                                    </Link>
                                  )}
                                  
                                  <Tooltip
                                    anchorSelect="#downloadLabel"
                                    content="Label İndir"
                                  />
                                  {order?.labelPdf && (
                                    <button
                                      id="downloadLabel"
                                      onClick={() =>
                                        downloadLabel(
                                          order?.labelPdf.includes('https')
                                            ? order?.labelPdf
                                            : `${API_URL}${order?.labelPdf}`
                                        )
                                      }
                                      // href={

                                      // }
                                      // target="_blank"
                                      // download
                                      // rel="noreferrer"
                                    >
                                      <DocumentDownload
                                        size="32"
                                        color="#2563EB"
                                        variant="Bold"
                                      />{' '}
                                    </button>
                                  )}

                                  <Tooltip
                                    anchorSelect="#productMatch"
                                    content="Ürün Eşleştir"
                                  />
                                  {order?.seller_order_items[0]?.label ===
                                    'waitingMatch' && (
                                    <button
                                      id="productMatch"
                                      onClick={() => {
                                        setSelectedOrder(order);
                                        setIsModalOpen(true);
                                      }}
                                    >
                                      <Link2
                                        size="32"
                                        color="#4F46E5"
                                        variant="Bold"
                                      />
                                    </button>
                                  )}
                                  <Tooltip
                                    anchorSelect="#hideOrder"
                                    content="Siparişi Gizle"
                                  />
                                  {order?.seller_order_items[0]?.label !==
                                    'hidden' &&
                                    order?.seller_order_items[0]?.label ===
                                      'waitingMatch' && (
                                      <button
                                        id="hideOrder"
                                        onClick={() =>
                                          hideOrder(
                                            order.seller_order_items.map(
                                              (item: any) => Number(item.id)
                                            )
                                          )
                                        }
                                      >
                                        <EyeSlash
                                          size="32"
                                          color="#4F46E5"
                                          variant="Bold"
                                        />
                                      </button>
                                    )}
                                  <Tooltip
                                    anchorSelect="#showOrder"
                                    content="Siparişi Göster"
                                  />
                                  {order?.seller_order_items[0]?.label ===
                                    'hidden' &&
                                    searchParams.get('label') === 'hidden' && (
                                      <button
                                        id="showOrder"
                                        onClick={() =>
                                          hideOrder(
                                            order.seller_order_items.map(
                                              (item: any) => Number(item.id)
                                            )
                                          )
                                        }
                                      >
                                        <Eye
                                          size="32"
                                          color="#4F46E5"
                                          variant="Bold"
                                        />
                                      </button>
                                    )}
                                </div>
                              </td> */}
                              <td>
                                <div className="w-64">
                                  {order?.seller_order_items?.map(
                                    (item: any) => {
                                      return (
                                        <div className="mb-2 p-1 flex flex-col justify-center items-center border-lightGray rounded-md">
                                          <div className="flex">
                                            {item?.seller_product?.mockupImages?.map(
                                              (mockup: any, index: number) => {
                                                return (
                                                  <img
                                                    onLoad={() => {}}
                                                    className="w-32 aspect-square object-contain object-center rounded-lg"
                                                    src={`${API_URL}${mockup}`}
                                                    alt="Mockup"
                                                  />
                                                );
                                              }
                                            )}
                                          </div>
                                          {item.supplierOrderNeedsDesign && (
                                            <div className="flex justify-center space-x-2">
                                              {item.seller_product?.mockupImages
                                                ?.length > 0 && (
                                                <button
                                                  className=" border-2 border-[#FB923C] rounded-full w-24 mx-auto py-1 px-2 flex justify-center items-center space-x-1 mt-2"
                                                  onClick={() => {
                                                    setSelectedProductId(
                                                      item.seller_product.id
                                                    );
                                                    setSelectedOrderItemId(
                                                      Number(item.id)
                                                    );
                                                    setIsDesignModalOpen(true);
                                                    setIsUpdate(true);
                                                    setIsCustom(false);
                                                  }}
                                                >
                                                  <Edit2
                                                    color="#FB923C"
                                                    size={18}
                                                  />
                                                  <p className="text-[#FB923C] text-xs">
                                                    Düzenle
                                                  </p>
                                                </button>
                                              )}
                                              {(item.seller_product
                                                ?.mockupImages?.length < 2 ||
                                                item.seller_product
                                                  ?.mockupImages === null) && (
                                                <button
                                                  className="bg-[#FB923C] rounded-full w-24 mx-auto py-1 px-2 flex justify-center items-center space-x-1 mt-2"
                                                  onClick={() => {
                                                    setSelectedProductId(
                                                      item.seller_product.id
                                                    );
                                                    setSelectedOrderItemId(
                                                      Number(item.id)
                                                    );
                                                    setIsDesignModalOpen(true);
                                                    setIsUpdate(false);
                                                    setIsCustom(false);
                                                  }}
                                                  disabled={
                                                    item.seller_product
                                                      ?.mockupImages?.length >=
                                                    2
                                                  }
                                                >
                                                  <DocumentPlusIcon
                                                    color="white"
                                                    width={24}
                                                  />
                                                  <p className="text-white text-xs">
                                                    Ekle
                                                  </p>
                                                </button>
                                              )}
                                            </div>
                                          )}
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </td>
                              <td>
                                <div className="w-32">
                                  <Link
                                    id="message"
                                    target="_blank"
                                    to={`/messages?to=${order?.seller_order_items[0]?.supplier_order.supplier.userId}&name=${order?.seller_order_items[0]?.supplier_order.supplier.name}`}
                                    // &name=${order?.seller_order.user.firstName}

                                    className="w-full mb-2 block text-center text-xs text-[#1D4ED8] border-2 px-1 py-1 border-[#1D4ED8] rounded-md"
                                  >
                                    Mesaj Gönder
                                  </Link>
                                  <button
                                    onClick={() => {
                                      setSelectedOrder(order);
                                      setIsNoteModalOpen(true);
                                    }}
                                    className="w-full text-center text-xs text-white border-2 px-1 py-1 border-[#1D4ED8] bg-[#1D4ED8] rounded-md"
                                  >
                                    Not Ekle
                                  </button>
                                  <button
                                    onClick={() =>
                                      hideOrder(
                                        order.seller_order_items.map(
                                          (item: any) => Number(item.id)
                                        )
                                      )
                                    }
                                    className="w-full text-center text-xs text-white mt-2 border-2 px-1 py-1 border-[#6B7280] bg-[#6B7280] rounded-md"
                                  >
                                    Gizle
                                  </button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    )}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ResendModal
        isModalOpen={resendModal}
        setIsModalOpen={setResendModal}
        orderItems={resendOrderItems}
      />
      <DesignModal
        isCustom={isCustom}
        open={isDesignModalOpen}
        setOpen={setIsDesignModalOpen}
        selectedProductId={selectedProductId}
        selectedOrderItemId={selectedOrderItemId}
        getOrders={getOrders}
        setIsModalOpen={setIsUpdateModalOpen}
        isUpdate={isUpdate}
      />
      {selectedEtsyOrder && (
        <UpdateOrderForm
          setIsDesignModalOpen={setIsDesignModalOpen}
          isModalOpen={isUpdateModalOpen}
          setSelectedProductId={setSelectedProductId}
          setIsModalOpen={setIsUpdateModalOpen}
          selectedOrder={selectedEtsyOrder}
          getOrders={getOrders}
          setIsUpdate={setIsUpdate}
          setIsCustom={setIsCustom}
          setSelectedOrder={setSelectedEtsyOrder}
        />
      )}
      <PaymentModal
        isModalOpen={isPaymentModalOpen}
        setIsModalOpen={setIsPaymentModalOpen}
        balance={balance}
        multiplier={multiplier}
        getWalletBalance={getWalletBalance}
        selectedOrders={selectedOrders}
        setSelectedOrders={setSelectedOrders}
        getOrders={getOrders}
        setIsWalletModalOpen={setIsWalletModalOpen}
      />
      <OrderMatchingModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        selectedOrder={selectedOrder}
        getOrders={getOrders}
      />
      <WalletModal
        isModalOpen={isWalletModalOpen}
        setIsModalOpen={setIsWalletModalOpen}
        balance={balance}
      />
      <AddNoteModal
        isModalOpen={isNoteModalOpen}
        getOrders={getOrders}
        setIsModalOpen={setIsNoteModalOpen}
        selectedOrder={selectedOrder}
      />
      {selectedOrder && (
        <DocumentUploadModal
          isModalOpen={isDocumentModalOpen}
          setIsModalOpen={setIsDocumentModalOpen}
          orderItemId={selectedOrder}
          getOrders={getOrders}
        />
      )}
      <ToastContainer />
      <LoadingModal
        text="Label alınıyor..."
        isModalOpen={loadingLabel}
        setIsModalOpen={setLoadingLabel}
      />
    </div>
  );
};

export default MarketplaceOrderTable;
