import React from 'react';
import { Provider } from './ProvidersCardsWrapper';
import { Link } from 'react-router-dom';
import API_URL from '../../config';
import { seperateNumberWithComma } from '../../utils/numberFormatter';

interface Props {
  provider: Provider;
}

const ProviderCard: React.FC<Props> = ({ provider }) => {
  return (
    <Link
      to={`/service-providers/${provider.id}`}
      state={{ title: provider.service_name, catId: provider.cat_id }}
      className="w-full"
    >
      <div className="border-headerPrimary border-2 rounded-xl flex flex-col justify-center">
        <div className="w-full h-32 pt-2">
          <img
            src={API_URL + provider.img_path}
            className="w-full h-full rounded-t-xl border-b-2 border-headerPrimary object-center object-contain"
            alt="provider_photo"
          />
        </div>

        <p className="text-primary flex justify-center items-center text-sm py-3 px-2 text-center border-b-2 border-headerPrimary h-16">
          {provider.service_name}
        </p>

        <p className="text-primary text-center font-bold py-3">
          {/* {provider.min_service_fee}₺ - {provider.max_service_fee}₺ */}
          {provider.min_service_fee === provider.max_service_fee
            ? `${seperateNumberWithComma(provider.max_service_fee)}₺`
            : `${seperateNumberWithComma(
                provider.min_service_fee
              )}₺ - ${seperateNumberWithComma(provider.max_service_fee)}₺`}
        </p>
      </div>
    </Link>
  );
};

export default ProviderCard;
