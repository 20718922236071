import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../context/LanguageContext';

interface SearchProps {
  setKeyword: React.Dispatch<React.SetStateAction<string>>;
  placeholder: string;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}
const Searchbar: React.FC<SearchProps> = ({
  setKeyword,

  placeholder,
  setPage,
}) => {
  const { t } = useLanguage();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const navigate = useNavigate();
  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setKeyword(String(inputRef.current?.value));
    navigate(`?page=1&keyword=${inputRef.current?.value}`);
    setPage(1);
  };

  return (
    <form className="" onSubmit={handleSubmit}>
      <label
        htmlFor="default-search"
        className="mb-2 text-lg lg:text-sm font-medium text-gray-900 sr-only dark:text-white"
      >
        Search
      </label>
      <div className="relative">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <svg
            aria-hidden="true"
            className="w-5 h-5 text-gray-500 dark:text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            ></path>
          </svg>
        </div>
        <input
          ref={inputRef}
          id="default-search"
          className="block w-full p-[0.58rem] pl-10 text-xs md:text-sm text-gray-900 border border-headerPrimary rounded-lg bg-gray-50 "
          placeholder={placeholder}
        />
        <button
          type="submit"
          className="text-white absolute right-0 bottom-0 bg-headerPrimary rounded-r-md font-medium text-sm px-4 py-2 md:py-2.5"
        >
          {t?.('common.buttons.search')}
        </button>
      </div>
    </form>
  );
};

export default Searchbar;
