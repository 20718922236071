import React from 'react';
import RatingStars from '../../components/common/RatingStars';
import { ArrowDown2 } from 'iconsax-react';
import BlurredProductFirstReview from './BlurredProductFirstReview';
import {
  IProductInfoForReviewTool,
  IReview,
} from '../../interfaces/amazonToolsInterfaces';
import { useLanguage } from '../../context/LanguageContext';
interface ProductFirstReviewProps {
  productInfo: IProductInfoForReviewTool;
  firstReview: IReview;
  isLoading: boolean;
}

const ProductFirstReview: React.FC<ProductFirstReviewProps> = ({
  firstReview,
  productInfo,
  isLoading,
}) => {
  const { t } = useLanguage();
  if (isLoading) {
    return <BlurredProductFirstReview />;
  }

  return (
    <>
      <div className="grid gap-8 grid-cols-12 mt-10">
        {/* PRODUCT IMAGE */}
        <div className="col-span-12 lg:col-span-5">
          <div className="border-cardBorder p-4 rounded-lg border-2 h-[450px] ">
            <img
              src={productInfo.productImageUrl}
              className="w-full object-contain h-full"
              alt={`${productInfo.productTitle}`}
            />
          </div>
        </div>

        {/* Product Info */}
        <div className="col-span-12 lg:col-span-7">
          {/* Product Name */}
          <h2 className="text-description font-bold text-2xl mb-4">
            {productInfo.productTitle}
          </h2>

          {/* Customer Photo  & Customer Name */}
          <h3 className="flex items-center text-description mb-1">
            <img
              alt="shop-logo"
              src={
                firstReview.clientImageUrl
                  ? firstReview.clientImageUrl
                  : `https://api.dicebear.com/6.x/avataaars/svg?seed=${firstReview.clientName} `
              }
              className="w-[20px] h-[20px] rounded-full"
            />
            <span className="text-lg ml-2 font-normal">
              {firstReview.clientName}
            </span>
          </h3>

          {/* Review Star */}
          <div className="flex items-center mb-1">
            <RatingStars rating={1} shop={false} />
            <span className="ml-2 text-description font-medium">
              {firstReview.reviewSummary}
            </span>
          </div>

          {/* Review Info */}
          <p className="text-description font-light mb-1">
            {/* Reviewed in {firstReview.reviewLocation} on {firstReview.reviewDate} */}
            {firstReview.reviewMetaInfo}
          </p>

          {/* First Review */}
          <p className="mb-3 text-sm leading-6">{firstReview.reviewText}</p>

          {/* Show All Btn */}
          <div className="text-center mt-5">
            <a href="#productReviewsAll" className="text-[#3C4790]">
              {t?.('amazon-review-analysis.seeAllReviews')}
              <p className="flex justify-center animate-bounce mt-1">
                <ArrowDown2 size={24} />
              </p>
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductFirstReview;
