import { UserOctagon } from 'iconsax-react';
import React from 'react';
import { seperateNumberWithComma } from '../../utils/numberFormatter';
import LoadingComponent from '../common/LoadingComponent';

interface StoreStatisticsProps {
  title: string;
  value: number;
  titleColor: string;
  valueColor: string;
  icon: any;
  isLoading: boolean;
}
const StoreStatisticsCard: React.FC<StoreStatisticsProps> = ({
  title,
  value,
  titleColor,
  valueColor,
  icon,
  isLoading,
}) => {
  return (
    <div className="col-span-10 min-[1315px]:col-span-2 md:col-span-5 ">
      <div className="flex flex-col justify-between bg-white pt-2 shadow-lg rounded-lg px-8 py-4 min-[1396px]:px-4 min-[1315px]:px-2">
        <p className="py-2 font-semibold" style={{ color: titleColor }}>
          {title}
        </p>
        <div className="flex justify-between items-end">
          <p className="font-bold text-xl" style={{ color: valueColor }}>
            {isLoading ? (
              <LoadingComponent />
            ) : value ? (
              seperateNumberWithComma(value)
            ) : (
              '-'
            )}
          </p>
          <div>{icon}</div>
        </div>
      </div>
    </div>
  );
};

export default StoreStatisticsCard;
