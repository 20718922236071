import { Formik } from 'formik';
import React, { useState } from 'react';
import { ToastContainer } from 'react-toastify';
import * as Yup from 'yup';

import { useAuth } from '../../context/AuthContext';
import { useLanguage } from '../../context/LanguageContext';
import alertNotification from '../../utils/alertNotification';
import { errorElement } from '../../utils/errorElement';

export interface Password {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
}
const PasswordChange = () => {
  const { t } = useLanguage();
  const { updateUserPassword } = useAuth();
  const [showAlert, setShowAlert] = useState(false);
  // const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/;
  // const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+-=[]{}|';:",.<>?~`]{6,}$/;
  const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+\-=\[\]{}|';:",.<>?~`]{6,}$/;
  // const regex = /^.{6,}$/;


  const onSubmit = async (values: Password) => {
    try {
      await updateUserPassword?.(values);
    } catch (error) {
      setShowAlert(true);
      alertNotification('error', t?.('errors.sthWentWrong'));
    }
  };
  return (
    <div className="flex flex-col w-full flex-shrink-0 bg-white rounded-xl shadow-lg">
      <ToastContainer />
      <div className="border-b-2 border-lightGray p-5 sm:p-7">
        <h3 className="font-medium text-headingPrimary cardHeading">
          {' '}
          {t?.('profile.passwordChange.title')}
        </h3>
      </div>
      <div className="p-5 sm:p-7">
        <Formik
          initialValues={{
            oldPassword: '',
            newPassword: '',
            newPasswordConfirm: '',
          }}
          onSubmit={onSubmit}
          validationSchema={Yup.object({
            oldPassword: Yup.string().required(t?.('validation.required')),
            newPassword: Yup.string()
              .matches(regex, t?.('validation.profile.passwordChange'))
              .required(t?.('validation.required')),
            newPasswordConfirm: Yup.string()
              .matches(regex, t?.('validation.profile.passwordChange'))
              .oneOf(
                [Yup.ref('newPassword')],
                t?.('validation.passwordDoesNotMatch')
              )
              .required(t?.('validation.required')),
          })}
        >
          {({ handleSubmit, values, handleChange, errors }) => (
            <form className="flex  flex-col gap-y-3" onSubmit={handleSubmit}>
              <div className="flex justify-between items-center gap-x-3 sm:gap-x-1.5">
                <label className="font-medium text-xs w-2/12 lg:w-4/12 xl:text-sm">
                  {t?.('profile.passwordChange.form.currentPassword')}
                </label>
                <div className="flex flex-1 justify-between gap-x-2">
                  <input
                    type="password"
                    name="oldPassword"
                    id="oldPassword"
                    onChange={handleChange}
                    className="flex w-full text-xs rounded-md bg-lightGray border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-headingPrimary sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              {errors.oldPassword && errorElement(errors.oldPassword)}
              <div className="flex justify-between items-center gap-x-3 sm:gap-x-1.5">
                <label className="font-medium text-xs w-2/12 xl:text-sm lg:w-4/12">
                  {t?.('profile.passwordChange.form.newPassword')}
                </label>
                <div className="flex flex-1 justify-between gap-x-2">
                  <input
                    type="password"
                    name="newPassword"
                    id="newPassword"
                    onChange={handleChange}
                    className="flex w-full text-xs rounded-md bg-lightGray border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-headingPrimary sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              {errors.newPassword && errorElement(errors.newPassword)}
              <div className="flex justify-between items-center gap-x-3 sm:gap-x-1.5">
                <label className="font-medium text-xs w-2/12 xl:text-sm lg:w-4/12">
                  {t?.('profile.passwordChange.form.confirmPassword')}
                </label>
                <div className="flex flex-1 justify-between gap-x-2">
                  <input
                    type="password"
                    name="newPasswordConfirm"
                    id="newPasswordConfirm"
                    onChange={handleChange}
                    className="flex w-full rounded-md text-xs bg-lightGray border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-headingPrimary sm:text-sm sm:leading-6 "
                  />
                </div>
              </div>
              {errors.newPasswordConfirm &&
                errorElement(errors.newPasswordConfirm)}
              <div className="flex  w-full justify-end gap-x-3 ">
                <button className="text-[#939BD2] hover:text-secondary font-semibold text-sm">
                  {t?.('profile.passwordChange.form.discard')}
                </button>
                <button className="rounded-md bg-headerPrimary py-2.5 px-8 text-xs font-semibold text-white shadow-sm hover:bg-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 md:text-sm">
                  {t?.('profile.passwordChange.form.submit')}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default PasswordChange;
