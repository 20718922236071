import React from 'react';

import LoadingComponent from '../../common/LoadingComponent';

const tableHeader = [
  { name: 'Etiket', column: 'label' },
  { name: 'id', column: 'id' },
  { name: 'Alıcı İsmi', column: 'recipient_name' },
  { name: 'Ülke', column: 'country' },
  { name: 'Resim', column: 'image' },
  { name: 'Tarih', column: 'date' },
  { name: 'SKU ', column: 'sku' },
];
interface Props {
  isLoading: boolean;
  data: any;
}

const LabelledTable: React.FC<Props> = ({ isLoading, data }) => {
  return (
    <div className="flex flex-col flex-shrink-0 bg-white rounded-xl shadow-lg my-8 h-[50vh]">
      {/* <div className="flow-root"> */}
      <div className="relative overflow-x-auto overflow-y-auto">
        <div className="inline-block min-w-full align-middle">
          <div className="border-none rounded-lg">
            <table className="min-w-full table-auto">
              <thead className="bg-headerPrimary">
                {tableHeader.map((item) => {
                  return (
                    <th
                      key={item.name}
                      className="py-6 pl-4 pr-3 bg-headerPrimary sticky top-0 text-center text-sm font-semibold text-white"
                      scope="col"
                    >
                      <p className="text-xs md:text-sm">{item.name}</p>
                    </th>
                  );
                })}
              </thead>

              {isLoading ? (
                <div className="w-full flex justify-center">
                  <LoadingComponent />
                </div>
              ) : (
                <tbody className="divide-y divide-gray-200 bg-white">
                  {data?.data?.orders?.map((order: any) => {
                    return (
                      <tr key={order.sso_id} className="even:bg-[#EDEEF8]">
                        <td className="px-4 py-4 text-xs md:text-sm text-center text-gray-500">
                          <div className=" flex justify-center items-center space-x-2 h-full">
                            <p className="w-56">{order.sso_id}</p>
                          </div>
                        </td>
                        <td className="px-4 py-4 text-xs md:text-sm text-center text-gray-500 ">
                          {order.order_id}
                        </td>
                        <td className="px-4 py-4 text-xs md:text-sm text-center text-gray-500 ">
                          {order.ship_to_name}
                        </td>
                        <td className="px-4 py-4 text-xs md:text-sm text-center text-gray-500 ">
                          <div>
                            <p className="w-20 mx-auto">
                              {order.ship_to_country}
                            </p>
                          </div>
                        </td>
                        <td className="px-4 py-4 text-xs md:text-sm text-center text-gray-500 ">
                          {order.image_url ? <img
                            src={order.image_url}
                            alt="product_image"
                            className="object-cover aspect-square rounded-lg mx-auto"
                          /> : '-'}
                        </td>

                        <td className="px-4 py-4 text-xs md:text-sm text-center text-gray-500 ">
                          {order.date}
                        </td>
                        <td className="px-4 py-4 text-xs md:text-sm text-center text-gray-500 ">
                          {order.sku}
                        </td>
                        {/* <td className="px-4 py-4 text-xs md:text-sm text-center text-gray-500 ">
                          {order.store_name}
                        </td> */}
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default LabelledTable;
