import React, { useEffect } from 'react';
import { StarIcon } from '@heroicons/react/20/solid';

interface RatingStarProps {
  rating: number;
  shop: boolean;
}
const RatingStars: React.FC<RatingStarProps> = ({ rating, shop }) => {
  let fake: number;

  if (rating !== 0) fake = rating;

  if (shop && rating === 0) fake = 9;
  if (!shop && rating === 0) fake = 4.2;

  return (
    <div className="flex">
      <div className="flex w-full justify-start items-center relative z-0">
        <StarIcon className="text-gray-200 min-h-[18px] min-w-[18px]" />
        <StarIcon className="text-gray-200 min-h-[18px] min-w-[18px]" />
        <StarIcon className="text-gray-200 min-h-[18px] min-w-[18px]" />
        <StarIcon className="text-gray-200 min-h-[18px] min-w-[18px]" />
        <StarIcon className="text-gray-200 min-h-[18px] min-w-[18px]" />

        <div
          className="overflow-hidden flex justify-start items-center absolute top-0 left-0 z-2"
          style={{ width: shop ? `${fake! * 10}%` : `${fake! * 20}%` }}
        >
          <StarIcon className="text-[#E84D4A] min-h-[18px] min-w-[18px]" />
          <StarIcon className="text-[#E84D4A] min-h-[18px] min-w-[18px]" />
          <StarIcon className="text-[#E84D4A] min-h-[18px] min-w-[18px]" />
          <StarIcon className="text-[#E84D4A] min-h-[18px] min-w-[18px]" />
          <StarIcon className="text-[#E84D4A] min-h-[18px] min-w-[18px]" />
        </div>
      </div>
    </div>
  );
};

export default RatingStars;
