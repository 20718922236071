/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ToastContainer } from 'react-toastify';

import { useQuery } from '@tanstack/react-query';

import PackageSection from '../components/billingInfo/PackageSection';
import PaymentTable from '../components/billingInfo/PaymentTable';
import EditProfileWrapper from '../components/profile/EditProfileWrapper';
import ProfileHeader from '../components/profile/ProfileHeader';
import ServiceSection from '../components/profile/ServiceSection';
import UpdateCompany from '../components/profile/UpdateCompany';
import { useAuth } from '../context/AuthContext';
import { IRootPersonalService } from '../interfaces/serviceProviders';
import memberService from '../services/memberService';
import producerService from '../services/producerService';
import ErrorFallback from './errors/ErrorFallback';

const Profile = () => {
  const [producerInfo, setProducerInfo] = useState();
  const [isTabActive, setIsTabActive] = React.useState<boolean>(true);
  const { session } = useAuth();

  const getProducerInfo = async () => {
    const res = await producerService.getProducerById(session?.producerId!);
    // console.log(res);
    setProducerInfo(res.data);
  };

  const { data } = useQuery<IRootPersonalService, Error>(['personalServices'], {
    queryFn: () => memberService.getPersonalService(session?.id!),
    staleTime: Infinity,
  });

  useEffect(() => {
    // console.log('session', session);
    if (session?.producerId) {
      getProducerInfo();
    }
  }, [session?.producerId]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabActive(document.visibilityState === 'visible');
    };

    // Add event listener for visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup function to remove the event listener when the component is unmounted
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    let intervalId: any;
    if (session && isTabActive) {
      intervalId = setInterval(async () => {
        await memberService.userTrackReq(`profile`, session?.id!);
      }, 60000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [session, isTabActive]);

  return (
    <>
      <div className="grid grid-cols-12">
        <div className="col-span-12 xl:col-span-7">
          <div className="flex w-full h-full  flex-shrink-0 flex-col ">
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <ProfileHeader />
            </ErrorBoundary>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <EditProfileWrapper />
            </ErrorBoundary>
          </div>
        </div>

        <div className="hidden xl:flex xl:col-span-5 rounded-xl justify-center ">
          <img
            src="/images/updateProfile.webp"
            alt="update_profile_mrTek"
            className=" rounded-2xl object-contain shadow-xs"
          />
        </div>

        {producerInfo && session?.producerStatus === 'active' && (
          <div className="col-span-12 xl:col-span-7">
            <UpdateCompany producer={producerInfo} />
          </div>
        )}
        {/* SERVICE SECTOION */}
        {data?.data && (
          <div className="col-span-12  mt-2">
            <ServiceSection data={data?.data} />
          </div>
        )}
      </div>
      <ToastContainer />
      <div className="flex w-full  flex-shrink-0 flex-col gap-y-2 lg:gap-y-8 mt-10">
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <PackageSection />
        </ErrorBoundary>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <PaymentTable />
        </ErrorBoundary>
      </div>
    </>
  );
};

export default Profile;
