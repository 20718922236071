import React from 'react';

const Loader = () => {
  return (
    <div className="flex items-center justify-center w-full opacity bg-light-blue-600 opacity-50">
      <img
        src="/assets/logos/rexven-logo.gif"
        alt="rexven-logo.gif"
        className="w-48 h-48"
      />
    </div>
  );
};

export default Loader;
