import { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthContext';

const useMultiplier = () => {
  const [multiplier, setMultiplier] = useState<number>(1);
  const { session } = useAuth();

  useEffect(() => {
    if (session?.packageName === 'Free') {
      setMultiplier(1.2);
    } else if (session?.packageName === 'Rexven Premium') {
      setMultiplier(1.1);
    } else if (session?.packageName === 'Rexven Plus') {
      setMultiplier(1.15);
    } else if (session?.packageName === 'Elit') {
      setMultiplier(1.05);
    }
  }, [session]);

  return multiplier;
};

export default useMultiplier;
