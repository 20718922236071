import 'react-datepicker/dist/react-datepicker.css';

import tr from 'date-fns/locale/tr';
import { useFormik } from 'formik';
import React, { Fragment, useState } from 'react';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';

import { useLanguage } from '../../context/LanguageContext';
import { ICountry } from '../../interfaces/etsyToolsInterfaces';
import { IOrdersQuery } from '../../interfaces/shipEntegraInterfaces';
import { classNames } from '../../utils/conditionalClasses';

interface ShipEntegraOrdersFormProps {
    orderFormSubmit: (values: IOrdersQuery) => void
    countries: ICountry[];
    setQueryString: React.Dispatch<React.SetStateAction<string>>;
}

const ShipEntegraOrdersForm: React.FC<ShipEntegraOrdersFormProps> = ({
    orderFormSubmit,
    countries,
    setQueryString,
}) => {
    const { t, language } = useLanguage();
    const [startDate, setStartDate] = useState<Date>();
    const [endDate, setEndDate] = useState<Date>();

    const [countryName, setCountryName] = useState({
        name: 'Seçiniz',
        id: ''
    });
    const [orderStatus, setOrderStatus] = useState({
        status: 'Seçiniz',
        value: 10
    });
    const navigate = useNavigate();
    registerLocale('tr', tr)
    setDefaultLocale('tr');
    const formatDate = (date: Date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Ay indeksi 0'dan başlar, bu yüzden +1 ekliyoruz
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
    const handleFormSubmit = (values: any) => {
        orderFormSubmit({
            countryId: countryName.name === 'Hepsi' || countryName.name === 'All' ? '' : countryName.id,
            // store: values.store,
            // tag: values.tag,
            // searchTerm: values.searchTerm,
            status: orderStatus.value,
            startDate: startDate ? formatDate(startDate) : '',
            endDate: endDate ? formatDate(endDate) : ''
        });
    }
    const formik = useFormik({
        initialValues: {
            countryId: '',
            // store: '',
            // tag: '',
            // searchTerm: ''
        },
        onSubmit: (values) => handleFormSubmit(values),
    });

    const handleClear = () => {
        formik.resetForm();
        setCountryName({
            name: 'Seçiniz',
            id: ''
        })
        setEndDate(undefined)
        setStartDate(undefined)
        setOrderStatus({
            status: 'Seçiniz',
            value: 10
        })
        setQueryString("")
        navigate("")
    }
    const handleStatusChange = (status: string) => {
        const selectedStatus = orderStatusList.find(item => item.status === status);
        if (selectedStatus) {
            setOrderStatus({
                status: status,
                value: selectedStatus.value
            });
        }
    };
    const allOption = <Listbox.Option
        key={Math.random()}
        className={({ active }) =>
            classNames(
                active
                    ? 'bg-secondary text-white'
                    : 'text-gray-900',
                'relative cursor-default select-none py-2 pl-3 pr-9'
            )
        }
        value={language === 'tr' ? "Hepsi" : "All"}
    >
        {({ selected, active }) => (
            <>
                <span
                    className={classNames(
                        selected ? 'font-semibold' : 'font-normal',
                        'block truncate'
                    )}
                >
                    {language === 'tr' ? "Hepsi" : "All"}
                </span>

                {selected ? (
                    <span
                        className={classNames(
                            active ? 'text-white' : 'text-indigo-600',
                            'absolute inset-y-0 right-0 flex items-center pr-4'
                        )}
                    >
                        <CheckIcon
                            className="h-5 w-5"
                            aria-hidden="true"
                        />
                    </span>
                ) : null}
            </>
        )}
    </Listbox.Option>

    const orderStatusList = language === 'tr' ? [
        { status: 'Hepsi', value: 10 },
        { status: 'Ödeme Bekleyen Siparişler', value: 1 },
        { status: 'Bekleyen Siparişler', value: 2 },
        { status: 'Gönderilen Siparişler', value: 3 },
        { status: 'İptal Edilen Siparişler', value: 4 },
        // { status: 'Favori Siparişler', value: 5 }
    ] : [
        { status: 'All orders', value: 10 },
        { status: 'Orders Awaiting Payment', value: 1 },
        { status: 'Pending orders', value: 2 },
        { status: 'Sent Orders', value: 3 },
        { status: 'Canceled Orders', value: 4 },
        // { status: 'Favorite Orders', value: 5 }
    ];
    return (
        <div className="bg-white rounded-xl mt-12">
            <form
                className="grid grid-cols-12 gap-4 p-4"
                onSubmit={formik.handleSubmit}
            >
                {/* Order Status */}
                <div className="col-span-6 md:col-span-3 xl:col-span-3 ">
                    <div className="flex flex-col items-center mb-2">
                        <label
                            className="w-full py-2 font-medium text-xs min-[1335px]:text-sm"
                            htmlFor="countryId"
                        >
                            {t?.('orders.filters.orderStatus')}
                        </label>

                        <Listbox
                            value={orderStatus.status}
                            onChange={handleStatusChange}
                        >
                            {({ open }) => (
                                <>
                                    <div className="relative w-full">
                                        <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-2 pl-3 pr-10  text-xs min-[1335px]:text-sm text-left text-gray-900 shadow-sm ring-1 ring-inset ring-cardBorder focus:outline-none ">
                                            <span className="block truncate">{orderStatus.status}</span>
                                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                <ChevronDownIcon
                                                    className="h-5 w-5 text-gray-400"
                                                    aria-hidden="true"
                                                />
                                            </span>
                                        </Listbox.Button>

                                        <Transition
                                            show={open}
                                            as={Fragment}
                                            leave="transition ease-in duration-100"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                        >
                                            <Listbox.Options className="absolute z-20 border-2 border-cardBorder mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-xs shadow-lg ring-1 ring-black ring-opacity-5 focus:ring-secondary focus:outline-none sm:text-sm">
                                                {/* {allOption} */}
                                                {orderStatusList?.map((status: any) => (
                                                    <Listbox.Option
                                                        key={status.value}
                                                        className={({ active }) =>
                                                            classNames(
                                                                active
                                                                    ? 'bg-secondary text-white'
                                                                    : 'text-gray-900',
                                                                'relative cursor-default select-none py-2 pl-3 pr-9'
                                                            )
                                                        }
                                                        value={status.status}
                                                    >
                                                        {({ selected, active }) => (
                                                            <>
                                                                <span
                                                                    className={classNames(
                                                                        selected ? 'font-semibold' : 'font-normal',
                                                                        'block truncate'
                                                                    )}
                                                                >
                                                                    {status.status}
                                                                </span>

                                                                {selected ? (
                                                                    <span
                                                                        className={classNames(
                                                                            active ? 'text-white' : 'text-indigo-600',
                                                                            'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                        )}
                                                                    >
                                                                        <CheckIcon
                                                                            className="h-5 w-5"
                                                                            aria-hidden="true"
                                                                        />
                                                                    </span>
                                                                ) : null}
                                                            </>
                                                        )}
                                                    </Listbox.Option>
                                                ))}
                                            </Listbox.Options>
                                        </Transition>
                                    </div>
                                </>
                            )}
                        </Listbox>
                    </div>
                </div>

                {/* country*/}
                <div className="col-span-6 md:col-span-3 xl:col-span-3 ">
                    <div className="flex flex-col items-center mb-2">
                        <label
                            className="w-full py-2 font-medium text-xs min-[1335px]:text-sm"
                            htmlFor="countryId"
                        >
                            {t?.('etsy-top-sellers.filter.country')}
                        </label>
                        <Listbox
                            value={formik.values.countryId}
                            onChange={(country) => {
                                formik.values.countryId = country;
                                let countryTitle = countries?.find(
                                    (item) => item.id === Number(formik.values.countryId)
                                )?.countryCode;
                                let countryName = countries?.find(
                                    (item) => item.id === Number(formik.values.countryId)
                                )?.name;
                                countryTitle = countryTitle || (language === 'tr' ? "Hepsi" : "All");
                                countryName = countryName || (language === 'tr' ? "Hepsi" : "All");
                                setCountryName({
                                    name: countryName!,
                                    id: countryTitle
                                });
                            }}
                        >
                            {({ open }) => (
                                <>
                                    <div className="relative w-full">
                                        <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-2 pl-3 pr-10  text-xs min-[1335px]:text-sm text-left text-gray-900 shadow-sm ring-1 ring-inset ring-cardBorder focus:outline-none ">
                                            <span className="block truncate">{countryName.name}</span>
                                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                <ChevronDownIcon
                                                    className="h-5 w-5 text-gray-400"
                                                    aria-hidden="true"
                                                />
                                            </span>
                                        </Listbox.Button>

                                        <Transition
                                            show={open}
                                            as={Fragment}
                                            leave="transition ease-in duration-100"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                        >
                                            <Listbox.Options className="absolute z-20 border-2 border-cardBorder mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-xs shadow-lg ring-1 ring-black ring-opacity-5 focus:ring-secondary focus:outline-none sm:text-sm">
                                                {allOption}
                                                {countries?.map((country: any) => (
                                                    <Listbox.Option
                                                        key={country.id}
                                                        className={({ active }) =>
                                                            classNames(
                                                                active
                                                                    ? 'bg-secondary text-white'
                                                                    : 'text-gray-900',
                                                                'relative cursor-default select-none py-2 pl-3 pr-9'
                                                            )
                                                        }
                                                        value={country.id}
                                                    >
                                                        {({ selected, active }) => (
                                                            <>
                                                                <span
                                                                    className={classNames(
                                                                        selected ? 'font-semibold' : 'font-normal',
                                                                        'block truncate'
                                                                    )}
                                                                >
                                                                    {country.name}
                                                                </span>

                                                                {selected ? (
                                                                    <span
                                                                        className={classNames(
                                                                            active ? 'text-white' : 'text-indigo-600',
                                                                            'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                        )}
                                                                    >
                                                                        <CheckIcon
                                                                            className="h-5 w-5"
                                                                            aria-hidden="true"
                                                                        />
                                                                    </span>
                                                                ) : null}
                                                            </>
                                                        )}
                                                    </Listbox.Option>
                                                ))}
                                            </Listbox.Options>
                                        </Transition>
                                    </div>
                                </>
                            )}
                        </Listbox>
                    </div>
                </div>

                {/* start Date */}
                <div className="col-span-6 md:col-span-3 xl:col-span-3 z-20">
                    <div className="flex flex-col items-center mb-2">
                        <label
                            className="w-full py-2 font-medium text-xs min-[1335px]:text-sm"
                            htmlFor="endDate"
                        >
                            {t?.('orders.filters.start')}
                        </label>
                        {/* start date picker */}
                        <DatePicker
                            id={'startDate'}
                            className="w-full rounded-md border border-cardBorder py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                            selected={startDate}
                            onChange={(date: any) => { setStartDate(date) }}
                            selectsStart
                            startDate={startDate}
                            endDate={endDate}
                            showYearDropdown={true}
                            maxDate={new Date()}
                            placeholderText="Seçiniz"
                            autoComplete="off"
                        />
                    </div>
                </div>
                {/* end Date */}
                <div className="col-span-6 md:col-span-3 xl:col-span-3 z-20">
                    <div className="flex flex-col items-center mb-2">
                        <label
                            className="w-full py-2 font-medium text-xs min-[1335px]:text-sm"
                            htmlFor="endDate"
                        >
                            {t?.('orders.filters.end')}
                        </label>
                        <DatePicker
                            id={'endDate'}
                            className="w-full rounded-md border border-cardBorder py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                            selected={endDate}
                            onChange={(date: any) => { setEndDate(date) }}
                            selectsEnd
                            startDate={startDate}
                            endDate={endDate}
                            maxDate={new Date()}
                            showYearDropdown={true}
                            placeholderText="Seçiniz"
                            autoComplete="off"
                        />
                    </div>
                </div>

                {/* Store */}
                {/* <div className="col-span-6 md:col-span-3 xl:col-span-3 ">
                    <div className="flex flex-col items-center mb-2">
                        <label
                            className="w-full py-2 font-medium text-xs min-[1335px]:text-sm"
                            htmlFor="countryId"
                        >
                            {t?.('etsy-top-sellers.filter.country')}
                        </label>
                        <Listbox
                            value={formik.values.countryId}
                            onChange={(country) => {
                                formik.values.countryId = country;
                                let countryTitle = countries?.find(
                                    (item) => item.id === Number(formik.values.countryId)
                                )?.name;
                                countryTitle = countryTitle || (language === 'tr' ? "Hepsi" : "All");
                                setCountryName(countryTitle!);
                            }}
                        >
                            {({ open }) => (
                                <>
                                    <div className="relative w-full">
                                        <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-2 pl-3 pr-10  text-xs min-[1335px]:text-sm text-left text-gray-900 shadow-sm ring-1 ring-inset ring-cardBorder focus:outline-none ">
                                            <span className="block truncate">{countryName}</span>
                                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                <ChevronDownIcon
                                                    className="h-5 w-5 text-gray-400"
                                                    aria-hidden="true"
                                                />
                                            </span>
                                        </Listbox.Button>

                                        <Transition
                                            show={open}
                                            as={Fragment}
                                            leave="transition ease-in duration-100"
                                            leaveFrom="opacity-100"
                                            leaveTo="opacity-0"
                                        >
                                            <Listbox.Options className="absolute z-20 border-2 border-cardBorder mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-xs shadow-lg ring-1 ring-black ring-opacity-5 focus:ring-secondary focus:outline-none sm:text-sm">
                                                {allOption}
                                                {countries?.map((country: any) => (
                                                    <Listbox.Option
                                                        key={country.id}
                                                        className={({ active }) =>
                                                            classNames(
                                                                active
                                                                    ? 'bg-secondary text-white'
                                                                    : 'text-gray-900',
                                                                'relative cursor-default select-none py-2 pl-3 pr-9'
                                                            )
                                                        }
                                                        value={country.id}
                                                    >
                                                        {({ selected, active }) => (
                                                            <>
                                                                <span
                                                                    className={classNames(
                                                                        selected ? 'font-semibold' : 'font-normal',
                                                                        'block truncate'
                                                                    )}
                                                                >
                                                                    {country.name}
                                                                </span>

                                                                {selected ? (
                                                                    <span
                                                                        className={classNames(
                                                                            active ? 'text-white' : 'text-indigo-600',
                                                                            'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                        )}
                                                                    >
                                                                        <CheckIcon
                                                                            className="h-5 w-5"
                                                                            aria-hidden="true"
                                                                        />
                                                                    </span>
                                                                ) : null}
                                                            </>
                                                        )}
                                                    </Listbox.Option>
                                                ))}
                                            </Listbox.Options>
                                        </Transition>
                                    </div>
                                </>
                            )}
                        </Listbox>
                    </div>
                </div> */}

                {/* search term */}
                {/* <div className="col-span-6 md:col-span-3 xl:col-span-3 ">
                    <div className="flex flex-col items-center mb-2">
                        <label
                            className="w-full py-2 font-medium text-xs min-[1335px]:text-sm"
                            htmlFor="tag"
                        >
                            {t?.('orders.filters.tag')}
                        </label>
                        <input
                            name="tag"
                            id="tag"
                            onChange={formik.handleChange}
                            type="text"
                            value={formik.values.tag}
                            className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                            placeholder={t?.('orders.filters.tag')}
                        />
                    </div>
                </div>
                <div className="col-span-6 md:col-span-3 xl:col-span-3 ">
                    <div className="flex flex-col items-center mb-2">
                        <label
                            className="w-full py-2 font-medium text-xs min-[1335px]:text-sm"
                            htmlFor="searchTerm"
                        >
                            {t?.('orders.filters.search-term')}
                        </label>
                        <input
                            name="searchTerm"
                            id="searchTerm"
                            onChange={formik.handleChange}
                            type="text"
                            value={formik.values.searchTerm}
                            className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                            placeholder={t?.('orders.filters.search-term')}
                        />
                    </div>
                </div> */}
                <div className="col-span-6 md:col-span-3 xl:col-span-3 flex py-2 items-end justify-between gap-2">
                    <div
                        // type="submit"
                        onClick={handleClear}
                        className="text-center cursor-pointer px-4 py-2 text-sm lg:text-base border-2 border-headerPrimary mx-auto w-full text-headerPrimary font-bold rounded-md  bg-white "
                    >
                        {t?.('orders.filters.clear')}
                    </div>
                    <button
                        type="submit"
                        className="px-4 py-2 text-sm lg:text-base border-2 border-headerPrimary mx-auto w-full text-white font-bold rounded-md  bg-headerPrimary "
                    >
                        {t?.('orders.filters.filter')}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default ShipEntegraOrdersForm;
