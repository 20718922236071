import { Fragment, useState } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';

import { IRootService, IServices } from '../../interfaces/serviceProviders';
import memberService from '../../services/memberService';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  services: IServices[];
  setServices: React.Dispatch<React.SetStateAction<IServices[]>>;
  setIsServiceAdd: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddServiceModal: React.FC<Props> = ({
  open,
  setOpen,
  setServices,
  services,
  setIsServiceAdd,
}) => {
  const [service, setService] = useState<IServices | null>(null);

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const { value, name } = e.currentTarget;
    if (name === 'serviceId') {
      setService({
        ...service!,
        serviceId: Number(value),
        serviceName: String(
          data?.data?.find((service) => service.id === Number(value))
            ?.serviceName
        ),
      });
    } else if (name === 'description') {
      setService({ ...service!, description: value });
    } else if (name === 'serviceFee') {
      setService({ ...service!, serviceFee: Number(value) });
    }
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setServices([...services, service!]);
    setOpen(false);
    setIsServiceAdd(true);
    setService(null);
  };
  const { data } = useQuery<IRootService, Error>(['allServices'], {
    queryFn: () => memberService.getAllServices(),
    staleTime: Infinity,
  });

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          setOpen(false);
          setService(null);
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      Eklemek istediğiniz hizmet bilgilerini giriniz
                    </Dialog.Title>
                    <div className="mt-2">
                      <form onSubmit={handleSubmit}>
                        <div className="flex justify-between items-center space-x-2 mb-2">
                          <select
                            className="rounded-md border-2 border-cardBorder text-xs w-2/3 outline-none"
                            placeholder="Hizmet kategorisini seçiniz..."
                            onChange={handleChange}
                            value={Number(service?.serviceId)}
                            name="serviceId"
                          >
                            <option value={0}>
                              Hizmet kategorisi seçiniz...
                            </option>
                            {data?.data
                              .filter(
                                (item) =>
                                  !services.some(
                                    (serviceItem) =>
                                      serviceItem.serviceName ===
                                      item.serviceName
                                  )
                              )
                              .map((res) => {
                                return (
                                  <option
                                    value={Number(res.id)}
                                    key={`service-${res.id}`}
                                  >
                                    {res.serviceName}
                                  </option>
                                );
                              })}
                          </select>

                          <input
                            type="text"
                            name="serviceFee"
                            className="rounded-md border-2 border-cardBorder text-xs w-1/3"
                            onChange={handleChange}
                            value={service?.serviceFee}
                            placeholder="1200₺"
                          />
                        </div>
                        <textarea
                          rows={10}
                          value={service?.description}
                          onChange={handleChange}
                          className="w-full rounded-md border-2  border-cardBorder text-sm md:text-base placeholder:text-sm"
                          name="description"
                          id="description"
                          placeholder="Bu hizmeti nasıl verdiğinizi açıklayınız.."
                        />
                        <div className="mt-5 sm:mt-6">
                          <button
                            type="submit"
                            className="inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm bg-headerPrimary  "
                            onClick={handleSubmit}
                          >
                            Ekle
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default AddServiceModal;
