import React from 'react';

import producerService from '../../services/producerService';
import MessageModal from './MessageModal';
import WholeSalerCart from './WholeSalerCart';

export interface ProducerRoot {
  status: string;
  data: Producer[];
}

export interface Producer {
  id: number;
  user_id: number,
  profile_image: string,
  first_name: string,
  last_name: string,
  producer_name: string;
  producer_rating: string;
  location: string;
  logo: string;
  phone_number: string;
  images: Image[];
  review: any;
  view_count: string;
  communication_count: string;
  categories: string[];
  products: { image: { url: string }; title: string; sale_price: number }[];
}

export interface Image {
  url: string;
}

interface WholeSalersProps {
  wholeSalers: Producer[];
}

// Producer Contact Btn Clicked Stats-->
const updateProducerStats = (
  producerId: number,
  incrementType: 'view' | 'communication'
) => {
  // console.log('producerId', producerId);
  producerService
    .producerStatistics({ producerId, incrementType })
    .then((res) => {
      console.log(res);
    })
    .catch((res) => {
      console.log(res);
    });
};

const WholeSalersWrapper: React.FC<WholeSalersProps> = ({ wholeSalers }) => {
  const [open, setOpen] = React.useState(false);
  const [producerId, setProducerId] = React.useState<number | null>(null);

  return (
    <div className="bg-white mt-4 ">
      <div className="mx-auto overflow-hidden ">
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 min-[1200px]:grid-cols-3 sm:mx-0 min-[1350px]:grid-cols-4">
          {wholeSalers.map((wholeSaler) => (
            <WholeSalerCart
              key={wholeSaler.id}
              setOpen={setOpen}
              wholeSaler={wholeSaler}
              setProducerId={setProducerId}
              updateProducerStats={updateProducerStats}
            />
          ))}
        </div>
      </div>
      <MessageModal
        open={open}
        setOpen={setOpen}
        producerId={producerId!}
        updateProducerStats={updateProducerStats}
      />
    </div>
  );
};

export default WholeSalersWrapper;
