import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/autoplay';

import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Autoplay, Navigation } from 'swiper';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

import { useQuery } from '@tanstack/react-query';

import API_URL, { IMAGE_URL } from '../../config';
import { useLanguage } from '../../context/LanguageContext';
import memberService from '../../services/memberService';

const ProvidersCarousel = () => {
  const { t, language } = useLanguage();

  const providersCarouselQuery = useQuery({
    queryKey: ['providers-carousel'],
    queryFn: () => memberService.getExServiceProviders(),
    staleTime: 600000, // Veriler 1 dakika boyunca önbellekte tutulacak
    // enabled: false,
  });

  return (
    <div className="bg-white mt-4 shadow-md rounded-md p-2 py-10">
      <p className="px-4 font-semibold text-headerPrimary">
        {language === 'tr' ? 'Partnerlerimiz' : 'Partners'}
      </p>
      <Swiper
        modules={[Navigation, Autoplay]}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 50,
          },
          800: {
            slidesPerView: 3,
            spaceBetween: 10,
          },
          1110: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          1280: {
            slidesPerView: 4,
            spaceBetween: 1,
          },
          1550: {
            slidesPerView: 5,
            spaceBetween: 10,
          },
        }}

        loop={true}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        className="mt-4"
      >
        {providersCarouselQuery.data && providersCarouselQuery.data.data
          ? providersCarouselQuery.data.data.map(
            (provider: any, index: any) => (
              <SwiperSlide
                key={index}
                className="px-14 min-[375px]:px-16 sm:px-10 lg:px-4 xl:px-10"
              >
                <div className="h-40 min-w-48 cursor-grab">
                  <img
                    className="aspect-[3/2] w-full h-full rounded object-contain"
                    src={provider.imagePath ? `${API_URL}images${provider.imagePath}` : `${IMAGE_URL}${provider?.user.profileImage}`}
                    alt={'providerimage'}
                  />
                </div>
              </SwiperSlide>
            )
          )
          : ''}
      </Swiper>
      {/* <p className="text-center px-4 md:px-20 lg:px-32 xl:px-40 mt-4 text-description font-normal">
        {t?.('dashboard.provider.content')} */}
      {/* <p className="text-center px-4 md:px-20 lg:px-32 xl:px-40 mt-4 text-description font-normal">
        Rexven olarak sizler için en güvenilir kargo, lojistik, ara depo, şirket
        kuruluşu, vergi, muhasebe, ürün fotoğrafçılığı, belgelendirme ve
        danışmanlık gibi bir çok alandaki hizmet sağlayıcı şirket ile anlaştık
        ve özel indirimler aldık.
      </p>
      <div className="mt-3 text-center items-center">
        <Link to={'/service-providers'}>
          <button className="px-8 bg-headerPrimary py-2 text-white rounded-md shadow-md">
            {t?.('dashboard.provider.btnContent')}
          </button>
        </Link>
      </div> */}
    </div>
  );
};

export default ProvidersCarousel;
