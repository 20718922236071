import { Formik } from 'formik';
import { Google } from 'iconsax-react';
import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import * as Yup from 'yup';

import { RegisterDataInterface, useAuth } from '../../context/AuthContext';
import { useLanguage } from '../../context/LanguageContext';
import shipentegraService from '../../services/shipentegraService';
import alertNotification from '../../utils/alertNotification';
import { classNames } from '../../utils/conditionalClasses';
import { errorElement } from '../../utils/errorElement';
import { getGoogleUrl } from '../../utils/getGoogleUrl';

type FormValues = Omit<RegisterDataInterface, 'from'>;
export default function RegisterForm() {
  // const regex = /^(?=.[A-Za-z])(?=.\d)[A-Za-z\d]{6,}$/;
  const [isKvkk, setIsKvkk] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const { register } = useAuth();
  const { t } = useLanguage();
  const location = useLocation();

  let from = ((location.state as any)?.from?.pathname as string) || '/';
  return (
    <>
      <div className="flex min-h-screen flex-col justify-center py-12 sm:px-6 lg:px-8 bg-lightGray">
        <ToastContainer />
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-7 w-auto"
            src="/assets/logos/rexven-logo.svg"
            alt="Your Company"
          />
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
            {t?.('register.header')}
          </h2>
        </div>
        <div className="mt-2 sm:mx-auto sm:w-full sm:max-w-md p-4">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <button
              // href={getGoogleUrl(from)}
              // disabled={!isKvkk}
              onClick={() => {
                if (isKvkk) {
                  window.location.href = getGoogleUrl(from);
                } else {
                  setShowValidation(true);
                  return;
                }
              }}
              className="flex justify-center items-center w-full py-2 px-4 text-sm font-medium border border-transparent text-white shadow-sm bg-googleRed rounded-md mt-2 hover:shadow-md"
            >
              <Google size="24" className="text-white mr-2" variant="Bold" />{' '}
              Google ile Kayıt Ol
            </button>
            {/* <button
              onClick={registerWithShipEntegra}
              className="flex justify-center items-center w-full py-2 px-4 text-sm font-medium border-2 border-[#52b0f0] text-[#52b0f0] shadow-sm bg-[#f0f9ff] rounded-md mt-2 hover:shadow-md"
            >
              <img src="/images/shipentegra.svg" alt="carrier" className="mr-2" />
              ShipEntegra ile Kayıt Ol
            </button> */}
            {showValidation &&
              errorElement('Lütfen üyelik sözleşmesini kabul ediniz')}
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center text-sm mt-4">
                <span className="bg-white px-2 text-gray-500">VEYA</span>
              </div>
            </div>
            <div className="mb-3 flex justify-end">
              {/* TODO:if you decide to use translation just comment out these lines */}
              {/* <div className="w-28">
                <LanguageSelect />
              </div> */}
            </div>
            <Formik
              initialValues={{
                firstName: '',
                lastName: '',
                email: '',
                password: '',
                passwordConfirm: '',
                isKvkkAccepted: '',
              }}
              onSubmit={(values: FormValues, actions) => {
                register?.({ ...values, from: from });
                actions.setSubmitting(false);
                actions.resetForm();
              }}
              validateOnChange={false}
              validationSchema={Yup.object({
                firstName: Yup.string().required(t?.('validation.required')),
                lastName: Yup.string().required(t?.('validation.required')),
                email: Yup.string()
                  .email('lütfen geçerli bir email adresi giriniz')
                  .required('Bu alan boş bırakılamaz'),
                password: Yup.string().required(t?.('validation.required')),
                passwordConfirm: Yup.string().required(
                  t?.('validation.required')
                ),
                // isKvkkAccepted: Yup.boolean().required(t?.('validation.contract')),
                isKvkkAccepted: Yup.boolean()
                  .test(
                    'is-accepted',
                    t?.('validation.contract'),
                    (value) => value === true
                  )
                  .required(t?.('validation.contract')),
              })}
            >
              {({ handleSubmit, values, handleChange, errors }) => (
                <form onSubmit={handleSubmit} className="space-y-6">
                  <div>
                    <label
                      htmlFor="firstName"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t?.('register.firstName')}
                    </label>
                    <div className="mt-1">
                      <input
                        id="firstName"
                        name="firstName"
                        placeholder={t?.('register.placeholders.firstName')}
                        value={values.firstName}
                        onChange={handleChange}
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-secondary focus:outline-none focus:ring-secondary sm:text-sm"
                      />
                      {errors.firstName && errorElement(errors.firstName)}
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="lastName"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t?.('register.lastName')}
                    </label>
                    <div className="mt-1">
                      <input
                        id="lastName"
                        name="lastName"
                        placeholder={t?.('register.placeholders.lastName')}
                        value={values.lastName}
                        onChange={handleChange}
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-secondary focus:outline-none focus:ring-secondary sm:text-sm"
                      />
                      {errors.lastName && errorElement(errors.lastName)}
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t?.('login.email')}
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="email"
                        placeholder={t?.('login.placeholders.email')}
                        value={values.email}
                        onChange={handleChange}
                        autoComplete="email"
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-secondary focus:outline-none focus:ring-secondary sm:text-sm"
                      />
                      {errors.email && errorElement(errors.email)}
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t?.('register.password')}
                    </label>
                    <div className="mt-1">
                      <input
                        id="password"
                        name="password"
                        placeholder={t?.('register.placeholders.password')}
                        type="password"
                        value={values.password}
                        onChange={handleChange}
                        autoComplete="current-password"
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-secondary focus:outline-none focus:ring-secondary sm:text-sm"
                      />
                      {errors.password && errorElement(errors.password)}
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="passwordConfirm"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t?.('register.passwordAgain')}
                    </label>
                    <div className="mt-1">
                      <input
                        id="passwordConfirm"
                        name="passwordConfirm"
                        placeholder={t?.('register.placeholders.passwordAgain')}
                        type="password"
                        value={values.passwordConfirm}
                        onChange={handleChange}
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-secondary focus:outline-none focus:ring-secondary sm:text-sm"
                      />
                      {errors.passwordConfirm &&
                        errorElement(errors.passwordConfirm)}
                    </div>
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="flex w-full justify-center rounded-md border border-transparent bg-secondary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2"
                    >
                      {t?.('common.buttons.register')}
                    </button>
                  </div>
                  <div
                    className={classNames(
                      'flex items-center justify-between mt-6',
                      showValidation
                        ? 'border rounded-lg border-rexRed p-2 bg-[#FEF2F2]'
                        : ''
                    )}
                  >
                    <div className="flex items-start">
                      <input
                        id="isKvkkAccepted"
                        name="isKvkkAccepted"
                        type="checkbox"
                        value={values.isKvkkAccepted}
                        onChange={(e) => {
                          handleChange(e);
                          setIsKvkk(!isKvkk);
                        }}
                        className="h-4 w-4 rounded border-gray-300 text-secondary focus:ring-secondary"
                      />
                      <label
                        htmlFor="isKvkkAccepted"
                        className="ml-2 block text-xs text-gray-900"
                      >
                        Kişisel verileriniz KVKK kapsamında işlenmektedir. Bir
                        hesap oluşturduğunuzda{' '}
                        <a
                          href="/uyelik-sozlesmesi"
                          target="_blank"
                          rel="noreferrer"
                          className="font-medium text-secondary"
                        >
                          Üyelik Sözleşmesini
                        </a>
                        ,{' '}
                        <a
                          href="/kullanim-kosullari"
                          className="font-medium text-secondary"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Kullanım Koşullarını
                        </a>
                        ,{' '}
                        <a
                          className="font-medium text-secondary"
                          href="/aydinlatma-metni"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Aydınlatma Metnini
                        </a>
                        ,{' '}
                        <a
                          className="font-medium text-secondary"
                          href="/mesafeli-satis-sozlesmesi"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Mesafeli Satış Sözleşmesini
                        </a>{' '}
                        ve{' '}
                        <a
                          className="font-medium text-secondary"
                          href="/gizlilik-politikasi"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Gizlilik Politikasını
                        </a>{' '}
                        kabul etmiş olursunuz.
                        {errors.isKvkkAccepted &&
                          errorElement(errors.isKvkkAccepted)}
                      </label>
                    </div>
                  </div>
                </form>
              )}
            </Formik>

            <div className="mt-6">
              <div className="relative">
                <div className="absolute inset-0 flex items-center">
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="bg-white px-2 text-gray-500">
                    {t?.('register.member')}
                  </span>
                </div>
              </div>
              <div className="mt-6">
                <Link
                  className="flex w-full justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2"
                  to={'/login'}
                >
                  {' '}
                  {t?.('common.buttons.login')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
