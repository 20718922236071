import React from 'react';
// import { Add } from 'iconsax-react';
import LabelledTable from './LabelledTable';
// import CreatePackageModal from './CreatePackageModal';
import { useQuery } from '@tanstack/react-query';
import shipentegraService from '../../../services/shipentegraService';
import NoSuchData from './NoSuchData';
import DotLoaderSpinner from '../../common/DotLoaderSpinner';

const LabelledShipments = () => {
  // const [show, setShow] = useState(false);

  const { isLoading, data, error } = useQuery<any, Error>(['labelledOrders'], {
    queryFn: () => shipentegraService.getConfirmedShipments({ status: 1 }),
    staleTime: 10000,
  });

  return (
    <div>
      <div className="flex flex-col space-y-3 w-full shadow-lg rounded-lg bg-white p-4 border border-[#0EA5E9] text-sm mb-8">
        <p>
          Ürünlerinizi şehrinizde bulunan ShipEntegra deposuna getirebilir veya
          aşağıdaki anlaşma kodları ile ücretsiz gönderim yapabilirsiniz.
        </p>
        <p>Sürat Kargo Anlaşma Kodu : 1232391330</p>
        <p>MNG Kargo Anlaşma Kodu : 390931124</p>
      </div>
      {/* <button
        className="bg-headerPrimary text-white text-sm px-2 py-2 border-headerPrimary rounded-md flex items-center justify-center"
        onClick={() => setShow(true)}
      >
        <Add size="24" color="white" /> Paket Oluştur{' '}
      </button> */}
      {isLoading ? (
        <DotLoaderSpinner loading={isLoading} />
      ) : data ? (
        <LabelledTable isLoading={isLoading} data={data} />
      ) : (
        <NoSuchData />
      )}

      {/* <CreatePackageModal show={show} setShow={setShow} /> */}
    </div>
  );
};

export default LabelledShipments;
