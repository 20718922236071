import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../errors/ErrorFallback';
import LoginForm from '../../components/authForms/LoginForm';


export default function Login() {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <LoginForm />
    </ErrorBoundary>
  )
}
