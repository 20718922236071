import React from 'react';

const BannerLoading = () => {
  return (
    <div className={`w-full bg-gray-300 rounded-xl  h-[200px] mb-8`}>
      <div className="text-center flex justify-center items-center h-full">
        <p className="text-headerPrimary text-xl font-bold">
          Banner Loading....
        </p>
      </div>
    </div>
  );
};

export default BannerLoading;
