import React, { CSSProperties } from 'react';
import { DotLoader } from 'react-spinners';

export const override: CSSProperties = {
  display: 'block',
  margin: '0 auto',
  borderColor: 'red',
};

interface Props {
  loading: boolean;
  size?: number
}
const DotLoaderSpinner: React.FC<Props> = ({ loading, size = 75 }) => {
  return (
    <DotLoader
      color={'#1F254B'}
      loading={loading}
      cssOverride={override}
      size={size}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  );
};

export default DotLoaderSpinner;
