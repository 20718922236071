import React, { useEffect, useState } from 'react';
import FilterShipmentForm from './FilterShipmentForm';
import ShipmentsTable from './ShipmentsTable';
import { useDebounce } from 'use-debounce';
import { SearchNormal1 } from 'iconsax-react';
import shipentegraService from '../../../services/shipentegraService';
import alertNotification from '../../../utils/alertNotification';
import { ToastContainer } from 'react-toastify';
import NoSuchData from './NoSuchData';
import DotLoaderSpinner from '../../common/DotLoaderSpinner';
import { useSearchParams } from 'react-router-dom';

// export interface IConfirmedOrders {
//   id: number;
//   img_url: string;
//   ship_to_name: string;
//   se_tracking_number: string;
//   order_id: number;
//   acceptedAt: string;
//   service: string;
//   ship_to_country: string;
//   sentegra_price: number;
//   sku: string;
//   store_name: string;
//   seo_created_date: string;
// }
const ConfirmedShipments = () => {
  const [searchParams] = useSearchParams();
  const [data, setData] = useState<any>();
  const [filteredData, setFilteredData] = useState<any>();
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [value] = useDebounce(query, 500);
  const [queryParams, setQueryParams] = useState<any>(
    Object.fromEntries([...searchParams])
      ? Object.fromEntries([...searchParams])
      : {
          status: 3,
          startDate: '',
          endDate: '',
          stores: '',
        }
  );

  const getConfirmedOrders = async () => {
    try {
      setLoading(true);
      const response = await shipentegraService.getConfirmedShipments(
        queryParams
      );
      setData(response.data.orders);
      setFilteredData(response.data.orders);
    } catch (error: any) {
      alertNotification('error', error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // searchParams'ın varlığını kontrol et ve queryparams'ı karşılaştır
    if (
      searchParams.has('startDate') ||
      searchParams.has('endDate') ||
      searchParams.has('stores') ||
      searchParams.has('status')
    ) {
      let search = Object.fromEntries([...searchParams]);
      if (
        search.status === queryParams.status &&
        search.startDate === queryParams.startDate &&
        search.endDate === queryParams.endDate &&
        search.stores === queryParams.stores
      )
        return;
      setQueryParams(search);
      // searchParams'ı kullanarak gerekli isteği at
    }
  }, [searchParams]);

  useEffect(() => {
    getConfirmedOrders();
  }, [queryParams]);

  useEffect(() => {
    const temp = data?.filter((item: any) => {
      return item.ship_to_name.toLowerCase().includes(value.toLowerCase());
    });
    setFilteredData(temp);
  }, [value, data]);
  return (
    <>
      <ToastContainer />
      <FilterShipmentForm setQueryParams={setQueryParams} />
      <div className="flex w-full justify-end my-4">
        <div className="flex justify-between items-center border border-cardBorder rounded-md bg-white">
          <input
            value={query}
            onChange={(e) => {
              setQuery(e.currentTarget.value);
            }}
            id="query"
            name="query"
            type="text"
            className="w-full shadow-sm text-xs border-none min-[1335px]:text-sm bg-white  rounded-md outline-none focus:ring-0 focus:outline-none focus:border-none"
            placeholder="Tabloda ara..."
          />
          <SearchNormal1 size="24" color="#1F254B" className="mr-2" />
        </div>
      </div>

      {loading ? (
        <DotLoaderSpinner loading={loading} />
      ) : filteredData?.length > 0 ? (
        <ShipmentsTable loading={loading} data={filteredData!} />
      ) : (
        <NoSuchData />
      )}
    </>
  );
};

export default ConfirmedShipments;
