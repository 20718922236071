import React, { useState } from 'react';
import { useLanguage } from '../../context/LanguageContext';
import { useQuery } from '@tanstack/react-query';
import memberService from '../../services/memberService';
import alertNotification from '../../utils/alertNotification';
import { useAuth } from '../../context/AuthContext';
import InvoiceReqModal from './InvoiceReqModal';
import LoadingComponent from '../common/LoadingComponent';
import { ToastContainer } from 'react-toastify';

interface Invoice {
  id: number;
  billingDate: string;
  paidPrice: string;
  currency: string;
  status: 'success';
  requestedInvoices: any;
}

export default function PaymentTable() {
  const { t, language } = useLanguage();
  const [open, setOpen] = useState(false);
  const { session } = useAuth();

  function convertDateToMonth(tarih: string) {
    const ayListesi =
      language === 'tr'
        ? [
            'Ocak',
            'Şubat',
            'Mart',
            'Nisan',
            'Mayıs',
            'Haziran',
            'Temmuz',
            'Ağustos',
            'Eylül',
            'Ekim',
            'Kasım',
            'Aralık',
          ]
        : [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
          ];

    const tarihObjesi = new Date(tarih);
    const ay = tarihObjesi.getMonth();
    const ayAdi = ayListesi[ay];

    return ayAdi;
  }
  const isBillingDateInSevenDays = (date: string) => {
    const today = new Date();
    const billingDate = new Date(date);
    const diffTime = Math.abs(billingDate.getTime() - today.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays <= 7;
  };
  const requestForInvoice = async (e: React.SyntheticEvent, id: any) => {
    e.preventDefault();
    try {
      await memberService.getInvoiceForUser(id);
      setOpen(true);
    } catch (error: any) {
      alertNotification('error', error.response.data.message);
    }
  };

  const { isLoading, error, data } = useQuery<Invoice[], Error>(['bills'], {
    queryFn: () => memberService.getInvoices(),
    staleTime: 10000,
  });

  return (
    <div className="bg-white rounded-xl p-4 shadow-lg">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="cardHeading font-medium text-headerPrimary">
            {t?.('billingInfo.payment.title')}
          </h1>
        </div>
      </div>
      <div className="mt-8 flow-root">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left tableHeading font-semibold text-headingPrimary sm:pl-6"
                    >
                      {t?.('billingInfo.payment.date')}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left tableHeading font-semibold text-headingPrimary"
                    >
                      {t?.('billingInfo.payment.description')}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left tableHeading font-semibold text-headingPrimary"
                    >
                      {t?.('billingInfo.payment.amount')}
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left tableHeading font-semibold text-headingPrimary"
                    >
                      {t?.('billingInfo.payment.bill')}
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {isLoading ? (
                    <LoadingComponent />
                  ) : data?.length === 0 ? (
                    <p className="w-full p-4">
                      {t?.('billingInfo.payment.noBill')}
                    </p>
                  ) : (
                    data?.map((invoice) => (
                      <tr key={invoice.id}>
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-xs lg:text-smfont-medium text-headingPrimary sm:pl-6">
                          {new Date(invoice.billingDate).toLocaleString()}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-xs lg:text-sm text-gray-500">
                          {convertDateToMonth(invoice.billingDate)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-xs lg:text-sm text-gray-500">
                          {invoice.currency === 'USD' ? '$ ' : '₺ '}
                          {invoice.paidPrice}{' '}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-xs lg:text-sm text-gray-500">
                          <button
                            onClick={(e) => requestForInvoice(e, invoice.id)}
                            disabled={
                              invoice.requestedInvoices === null ||
                              invoice.requestedInvoices?.requestDate ||
                              isBillingDateInSevenDays(invoice.billingDate)
                            }
                            title={
                              isBillingDateInSevenDays(invoice.billingDate)
                                ? 'Fatura isteme tarihi henüz gelmedi'
                                : ''
                            }
                            className="rounded-md bg-lightGray py-2.5 px-4 text-xs font-semibold text-[#939BD2] shadow-sm md:text-sm"
                          >
                            {t?.('billingInfo.payment.request')}
                          </button>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
      <InvoiceReqModal open={open} setOpen={setOpen} email={session?.email!} />
    </div>
  );
}
