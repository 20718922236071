import React from 'react';

// 1    T-shirt
// 2    Sweatshirt
// 3    Hoodie
// 4    Halı
// 5    Kolye
// 6    Küpe
// 7    Bileklik
// 8    Yüzük
// 9    Halhal
// 10    Deri
// 11    Duvar Dekoru
// 12    Ahşap
const categories = [
  { id: 1, name: 'T-shirt', imgPath: '/images/marketplace/t-shirt.png' },
  { id: 2, name: 'Sweatshirt', imgPath: '/images/marketplace/sweatshirt.png' },
  { id: 3, name: 'Hoodie', imgPath: '/images/marketplace/hoodie.png' },
  { id: 4, name: 'Halı', imgPath: '/images/marketplace/hali.png' },
  { id: 5, name: 'Kolye', imgPath: '/images/marketplace/kolye.png' },
  { id: 6, name: 'Küpe', imgPath: '/images/marketplace/kupe.png' },
  { id: 7, name: 'Bileklik', imgPath: '/images/marketplace/bileklik.png' },
  { id: 8, name: 'Yüzük', imgPath: '/images/marketplace/yuzuk.png' },
  { id: 9, name: 'Halhal', imgPath: '/images/marketplace/halhal.webp' },
  { id: 10, name: 'Deri', imgPath: '/images/marketplace/deri.png' },
  {
    id: 11,
    name: 'Metal',
    imgPath: '/images/marketplace/duvarDekoru.png',
  },
  { id: 12, name: 'Ahşap', imgPath: '/images/marketplace/ahsap.png' },
  { id: 13, name: 'Havlu', imgPath: '/images/marketplace/havlu.png' },
  { id: 14, name: 'Kanvas', imgPath: '/images/marketplace/kanvas.png' },
  { id: 15, name: 'Çanta', imgPath: '/images/marketplace/canta.png' },
  { id: 16, name: 'Önlük', imgPath: '/images/marketplace/onluk.png' },
  { id: 17, name: 'Şapka', imgPath: '/images/marketplace/sapka.png' },
  { id: 18, name: 'Akrilik', imgPath: '/images/marketplace/epoksi.png' },
  { id: 19, name: 'Bardak', imgPath: '/images/marketplace/bardak.png' },
  { id: 20, name: 'Terlik', imgPath: '/images/marketplace/terlik.png' },
  { id: 21, name: 'Pijama', imgPath: '/images/marketplace/pijama.png' },
  {
    id: 22,
    name: 'Kedi-Köpek',
    imgPath: '/images/marketplace/evcil-hayvan.png',
  },
  {
    id: 25,
    name: 'Tumbler',
    imgPath: '/images/marketplace/tumbler.webp',
  },
  {
    id: 24,
    name: 'Ornament',
    imgPath: '/images/marketplace/ornament.webp',
  },
];
interface CategoryCardProps {
  handleCategoryId: (id: number, name: string) => void;
}

const CategoryCard: React.FC<CategoryCardProps> = ({ handleCategoryId }) => {
  const handleClick = (id: number, name: string) => {
    handleCategoryId(id, name);
  };
  return (
    <div className="cursor-pointer grid grid-cols-12 justify-center items-center gap-6 col-span-12 border-t-4 border-[#F9FAFB] pt-4 mt-16 bg-white">
      {categories.map((cat, index) => (
        <div
          onClick={() => handleClick(cat.id, cat.name)}
          key={index}
          className="col-span-12 md:col-span-6 lg:col-span-3 p-4 rounded-[24px] hover:shadow-[0_0px_40px_0_rgba(17,24,39,0.14)] overflow-hidden bg-[#F9FAFB] flex flex-col items-center justify-center"
        >
          <img
            src={cat.imgPath}
            alt={cat.name}
            className="w-full h-auto max-w-xs object-contain rounded-[12px] mt-2"
          />
          <div className="px-4 text-center mt-4">
            <span
              onClick={() => handleClick(cat.id, cat.name)}
              className="text-lg font-semibold text-[#374151] w-full text-left"
            >
              {cat.name}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CategoryCard;
