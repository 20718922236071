import React from 'react';
import { Link } from 'react-router-dom';

import { MapPinIcon } from '@heroicons/react/20/solid';

import { useLanguage } from '../../context/LanguageContext';
// import { useNumberVerification } from '../../context/NumberVerificationContext';
import RatingStars from '../common/RatingStars';
import { Producer } from './WholeSalersWrapper';

interface WholeSaleCartProps {
  wholeSaler: Producer;
  updateProducerStats: (
    producerId: number,
    incrementType: 'communication' | 'view'
  ) => void;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setProducerId?: React.Dispatch<React.SetStateAction<number | null>>;
}

const WholeSalerCart: React.FC<WholeSaleCartProps> = ({
  wholeSaler,
  updateProducerStats,
  setOpen,
  setProducerId,
}) => {
  // const { handleActiveModal } = useNumberVerification();
  const { t } = useLanguage();

  return (
    <div className="group relative border-[1px]  rounded-lg border-cardBorder p-2 sm:p-4">
      <div className="h-[300px] aspect-w-1 aspect-h-1 overflow-hidden rounded-lg bg-gray-200 group-hover:opacity-75 mx-auto">
        <img
          onError={(e) => {
            (e.target as HTMLImageElement).src = '/images/notFound.png';
          }}
          src={
            wholeSaler.images && wholeSaler.images.length > 0
              ? wholeSaler.images[0].url
              : '/images/notFound.png'
          }
          alt="product_image"
          className="w-full h-full object-contain bg-white object-center"
        />
      </div>
      <div className=" pt-2 pb-2 text-left">
        <div className="flex items-center h-[20px] max-h-[20px]">
          <h3 className="flex items-center text-sm font-medium text-gray-900">
            {/* <span className="inline-block w-[20px] h-[20px]"> */}
            <img
              src={
                wholeSaler.images && wholeSaler.images.length > 0
                  ? wholeSaler.images[0].url
                  : '/images/notFound.png'
              }
              alt="producer_image"
              className="inline-block w-[20px] h-[20px]"
            />
            {/* </span>{' '} */}
            <span className="ml-1">{wholeSaler.producer_name}</span>
          </h3>
        </div>
        <div className="h-[35px] max-h-[35px] -ml-0.5 mt-3 flex items-center text-sm  text-[red] ">
          <MapPinIcon className="h-5 w-5" />
          <span className="ml-1">{wholeSaler.location}</span>
        </div>

        <div className="mt-3 flex flex-col items-left">
          <p className="sr-only">{wholeSaler.producer_rating}</p>
          <div className="flex items-center">
            <span className="text-xs md:text-sm">Mağaza Puanı:</span>

            <RatingStars
              rating={Number(wholeSaler.producer_rating)}
              shop={true}
            />
            <span className="text-sm ml-1">
              {' '}
              ({wholeSaler.producer_rating}){' '}
            </span>
          </div>
        </div>
        <div className="flex items-center text-[16px] bg justify-between mt-2">
          <Link
            to={`/manufacturer/${wholeSaler.id}`}
            className="text-center text-[13px] px-3 h-[50px] max-h-[50px] font-bold  lg:py-1 border-2 rounded-lg border-headerPrimary w-[49%] flex items-center"
          >
            <button
              onClick={() => {
                updateProducerStats(wholeSaler.id, 'view');
              }}
              className="w-full"
            >
              {t?.('manufacturers-and-wholesalers.showProducts')}
            </button>
          </Link>
          <button
            onClick={(e) => {
              e.preventDefault();
              if (wholeSaler?.id) {
                setProducerId?.(wholeSaler.id);
              }
              setOpen(true);
            }}
            className="hover:cursor-pointer text-center text-[12px] bg-headerPrimary font-bold text-white h-[50px] max-h-[50px]  px-3 py-1  border-2 rounded-lg border-headerPrimary w-[49%] flex items-center justify-center"
          >
            İletişim Kur
          </button>
          {/* <a
            onClick={(event) => {
              updateProducerStats(wholeSaler.id, 'communication');
              handleActiveModal?.(event);
            }}
            href={`https://wa.me/${wholeSaler.phone_number.replace(
              / /g,
              ''
            )}?text=Merhaba,%20ben%20size%20Rexven%20arac%C4%B1l%C4%B1%C4%9F%C4%B1yla%20ula%C5%9Ft%C4%B1m`}
            target="_blank"
            rel="noreferrer"
            className="hover:cursor-pointer text-center text-[12px] bg-headerPrimary font-bold text-white h-[50px] max-h-[50px]  px-3 py-1  border-2 rounded-lg border-headerPrimary w-[49%] flex items-center justify-center"
          >
            {t?.('manufacturers-and-wholesalers.contact')}
          </a> */}
        </div>
        <p className="text-xs text-[#4B5563] mt-2 min-h-[20px]">
          {wholeSaler.view_count !== '0' &&
            wholeSaler.view_count !== undefined &&
            wholeSaler.view_count !== null && (
              <>
                Bu tedarikçinin ürünleri
                <span className="font-medium">{` ${wholeSaler.view_count} `}</span>
                kere incelendi
              </>
            )}
          <br />
          {wholeSaler.view_count !== '0' &&
            wholeSaler.view_count !== undefined &&
            wholeSaler.view_count !== null &&
            wholeSaler.communication_count !== '0' &&
            wholeSaler.communication_count !== undefined &&
            wholeSaler.communication_count !== null && (
              <>
                <span className="font-medium">{`${wholeSaler.communication_count} `}</span>
                girişimci iletişim kurdu.
              </>
            )}
        </p>
      </div>
    </div>
  );
};

export default WholeSalerCart;
