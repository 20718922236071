import React, { useState, useEffect, lazy, Suspense } from 'react';
import VideoTools from '../components/tools/common/VideoTools';
import PieChart from '../components/tools/common/PieChart';
import ProfitProductInfo from '../components/tools/common/ProfitProductInfo';
import {
  WalletAdd1,
  Box1,
  Box2,
  PercentageSquare,
  ReceiptSearch,
} from 'iconsax-react';
import etsyToolsService, { ProfitEtsy } from '../services/etsyToolsService';
import alertNotification from '../utils/alertNotification';
import ProfitForm from '../components/tools/etsy/ProfitForm';
import EtsyCostTable from '../components/tools/etsy/EtsyCostTable';
import LinkForm from '../components/tools/etsy/LinkForm';
import LoadingComponent from '../components/common/LoadingComponent';
import EtsyInfoCards from '../components/tools/etsy/EtsyInfoCards';
// import SixDigitModal from '../components/numberVerifyModals/SixDigitModal';
import NumVerifyModal from '../components/numberVerifyModals/NumVerifyModal';
import SurveyModal from '../components/survey/SurveyModal';
import VerifySuccessModal from '../components/numberVerifyModals/VerifySuccessModal';
import { useNumberVerification } from '../context/NumberVerificationContext';
import { useAuth } from '../context/AuthContext';
import BannerLoading from '../components/tools/common/BannerLoading';
import memberService from '../services/memberService';
import { useLanguage } from '../context/LanguageContext';
const NewBanner = lazy(() => import('../components/tools/common/NewBanner'));

export interface ProductCalc {
  productImage: string;
  productTitle: string;
  brand?: string;
  rating: number;
  sellerName: string;
  price: number;
  discountRate: number;
  originalPrice: number;
  discountedPrice: number;
}
export interface ProfitCalc {
  listingFee: number;
  productFee: number;
  shippingFee: number;
  giftBoxFee: number;
  paymentProcessingFee: number;
  regulatoryOperatingFee: number;
  offsiteAdsFee: number;
  vat: number;
  exchangeFee: number;
  salesTax?: number;
  revenue: number;
  totalCost: number;
  etsyCost: number;
  profit: number;
  profitMargin: number;
  cur: number;
}

const EtsyProfitCalculator = () => {
  const { language } = useLanguage();
  const [productLink, setProductLink] = useState('');
  // const [currency, setCurrency] = useState('USD');
  const [isTabActive, setIsTabActive] = React.useState<boolean>(true);

  const [shopCurrency, setShopCurrency] = useState('USD');

  const [profitCalc, setProfitCalc] = useState<ProfitCalc | undefined>();
  const [loading, setLoading] = useState(false);
  const [bankCur, setBankCur] = useState('₺');
  const [resultLoading, setResultLoading] = useState(false);
  // const [shippingFee, setShippingFee] = useState(0); // After calculation result setInfoCardsArr
  const [infoCardsArr, setInfoCardsArr] = useState(
    language === 'tr'
      ? [
          {
            icon: WalletAdd1,
            title: `Gelir (${bankCur})`,
            value: '-',
            iconColor: '#4338CA',
            titleColor: '#6366F1',
            valueColor: '#4338CA',
          },
          {
            icon: Box1,
            title: `Maliyet (${bankCur})`,
            value: '-',
            iconColor: '#F87171',
            titleColor: '#F87171',
            valueColor: '#B91C1C',
          },
          {
            icon: Box2,
            title: `Etsy Kesinti (${bankCur})`,
            value: '-',
            iconColor: '#F45900',
            titleColor: '#CC4B00',
            valueColor: '#F45900',
          },

          {
            icon: ReceiptSearch,
            title: `Kar (${bankCur})`,
            value: '-',
            iconColor: '#047857',
            titleColor: '#059669',
            valueColor: '#047857',
          },
          {
            icon: PercentageSquare,
            title: `Kar Marjı (%)`,
            value: '-',
            iconColor: '#0F766E',
            titleColor: '#0D9488',
            valueColor: '#0F766E',
          },
        ]
      : [
          {
            icon: WalletAdd1,
            title: `Revenue (${bankCur})`,
            value: '-',
            iconColor: '#4338CA',
            titleColor: '#6366F1',
            valueColor: '#4338CA',
          },
          {
            icon: Box1,
            title: `Cost (${bankCur})`,
            value: '-',
            iconColor: '#F87171',
            titleColor: '#F87171',
            valueColor: '#B91C1C',
          },
          {
            icon: Box2,
            title: `Etsy Fees (${bankCur})`,
            value: '-',
            iconColor: '#F45900',
            titleColor: '#CC4B00',
            valueColor: '#F45900',
          },

          {
            icon: ReceiptSearch,
            title: `Profit (${bankCur})`,
            value: '-',
            iconColor: '#047857',
            titleColor: '#059669',
            valueColor: '#047857',
          },
          {
            icon: PercentageSquare,
            title: `Profit Margin (%)`,
            value: '-',
            iconColor: '#0F766E',
            titleColor: '#0D9488',
            valueColor: '#0F766E',
          },
        ]
  );
  const { session } = useAuth();

  const chartOptions = {
    labels:
      language === 'tr'
        ? ['Maliyet', 'Etsy Kesinti', 'Kar']
        : ['Cost', 'Etsy Fees', 'Profit'], // Value Titles
    colors: ['#F87171', '#FF9E66', '#059669'], // Value Colors
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          size: '50%',
          labels: {
            show: true,
          },
        },
      },
    },
  };

  const {
    setOpenSurvey,
    setNumVerifyModal,
    setSixDigitVerifyModal,
    isCodeSend,
  } = useNumberVerification();
  // After calculation result setChartOptions
  const [chartValues, setChartValues] = useState([25, 25, 25]); // Ürün - Etsy - Kar/zarar - Kargo

  // After Submiting Product Link setProductInfo
  const [productInfo, setProductInfo] = useState<ProductCalc>();

  const getProductInfo = async () => {
    setLoading(true);

    setInfoCardsArr(
      language === 'tr'
        ? [
            {
              ...infoCardsArr[0],
              title: `Gelir (${bankCur})`,
              value: '-',
            },
            {
              ...infoCardsArr[1],
              title: `Maliyet (${bankCur})`,
              value: '-',
            },
            {
              ...infoCardsArr[2],
              title: `Etsy Kesinti (${bankCur})`,
              value: '-',
            },
            {
              ...infoCardsArr[3],
              title: `Kar (${bankCur})`,
              value: '-',
            },
            {
              ...infoCardsArr[4],
              title: `Kar Marjı (%)`,
              value: '-',
            },
          ]
        : [
            {
              ...infoCardsArr[0],
              title: `Revenue (${bankCur})`,
              value: '-',
            },
            {
              ...infoCardsArr[1],
              title: `Cost (${bankCur})`,
              value: '-',
            },
            {
              ...infoCardsArr[2],
              title: `Etsy Fees (${bankCur})`,
              value: '-',
            },
            {
              ...infoCardsArr[3],
              title: `Profit (${bankCur})`,
              value: '-',
            },
            {
              ...infoCardsArr[4],
              title: `Profit Margin (%)`,
              value: '-',
            },
          ]
    );
    setProfitCalc(undefined);
    try {
      const res = await etsyToolsService.getProductwithUrl(
        productLink,
        shopCurrency
      );
      setProductInfo(res.data);
    } catch (error) {
      alertNotification('error', 'Aradığınız kriterde ürün bulunamadı');
    } finally {
      setLoading(false);
    }
  };

  const getEtsyProfitCalculation = async (payload: ProfitEtsy) => {
    setResultLoading(true);
    try {
      const res = await etsyToolsService.getProfitforEtsy(payload);
      setProfitCalc(res.data);

      setChartValues([
        Number(res.data.product),
        Number(res.data.etsyCost),
        Number(res.data.profit),
      ]);
      setBankCur(res.data.symbol);
      setInfoCardsArr(
        language === 'tr'
          ? [
              {
                ...infoCardsArr[0],
                title: `Gelir (${res.data.symbol})`,
                value: Number.parseFloat(res.data.revenue).toFixed(2),
              },
              {
                ...infoCardsArr[1],
                title: `Maliyet (${res.data.symbol})`,
                value: res.data.product.toFixed(2),
              },
              {
                ...infoCardsArr[2],
                title: `Etsy Kesinti (${res.data.symbol})`,
                value: res.data.etsyCost.toFixed(2),
              },
              {
                ...infoCardsArr[3],
                title: `Kar (${res.data.symbol})`,
                value: res.data.profit.toFixed(2),
              },
              {
                ...infoCardsArr[4],
                title: `Kar Marjı (%)`,
                value: Number.parseFloat(res.data.profitMargin).toFixed(2),
              },
            ]
          : [
              {
                ...infoCardsArr[0],
                title: `Revenue (${res.data.symbol})`,
                value: Number.parseFloat(res.data.revenue).toFixed(2),
              },
              {
                ...infoCardsArr[1],
                title: `Cost (${res.data.symbol})`,
                value: res.data.product.toFixed(2),
              },
              {
                ...infoCardsArr[2],
                title: `Etsy Fees (${res.data.symbol})`,
                value: res.data.etsyCost.toFixed(2),
              },
              {
                ...infoCardsArr[3],
                title: `Profit (${res.data.symbol})`,
                value: res.data.profit.toFixed(2),
              },
              {
                ...infoCardsArr[4],
                title: `Profit Margin (%)`,
                value: Number.parseFloat(res.data.profitMargin).toFixed(2),
              },
            ]
      );
      setBankCur(res.data.symbol);
    } catch (error) {
      alertNotification('error', error);
    } finally {
      setResultLoading(false);
    }
  };

  const onSubmit = (event: any, values: any) => {
    let payload = {
      shopLocation: values.shopLocation,
      bankLocation: values.bankLocation,
      shopCurrency: shopCurrency,
      hasTaxNumber: values.hasTaxNumber === 'yok' ? false : true,
      offsiteAds: Number(values.offsiteAds / 100),
      hasFreeListing: values.hasFreeListing === 'priced' ? false : true,
      salePrice: Number(values.salePrice),
      shippingIncome: Number(values.shippingIncome),
      giftBoxIncome: Number(values.giftBoxIncome),
      tax: Number(values.tax),
      discountPercentage:
        values.isPercent === 'Percent' ? Number(values.discount) / 100 : 0,
      discountFixed: values.isPercent === 'Fixed' ? Number(values.discount) : 0,
      productCost: Number(values.productCost),
      laborCost: Number(values.laborCost),
      packageCost: Number(values.packageCost),
      shippingCost: Number(values.shippingCost),
      addCost: Number(values.addCost),
    };
    if (
      (session?.hasPackage || session?.lifeTimeCourse) &&
      !session?.isInitialSurveyDone
    ) {
      event.preventDefault();
      setOpenSurvey?.(true);
    } else if (
      !session?.hasPackage &&
      !session?.lifeTimeCourse &&
      !session?.isGsmVerified
    ) {
      event.preventDefault();
      if (!isCodeSend) {
        setNumVerifyModal?.(true);
      } else if (isCodeSend) {
        setSixDigitVerifyModal?.(true);
      }
    } else {
      getEtsyProfitCalculation(payload);
    }
  };

  useEffect(() => {
    if (productLink && productLink.startsWith('https://www.etsy.com')) {
      getProductInfo();
    }
  }, [productLink, shopCurrency]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabActive(document.visibilityState === 'visible');
    };

    // Add event listener for visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup function to remove the event listener when the component is unmounted
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    let intervalId: any;
    if (session && isTabActive) {
      intervalId = setInterval(async () => {
        await memberService.userTrackReq(
          `etsy-profit-calculator`,
          session?.id!
        );
      }, 60000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [session, isTabActive]);
  const CURRENCIES = [
    { currency: 'Australian Dollar', code: 'AUD' },
    { currency: 'Euro', code: 'EUR' },
    { currency: 'Canadian Dollar', code: 'CAD' },
    { currency: 'Czech Koruna', code: 'CZK' },
    { currency: 'Danish Krone', code: 'DKK' },
    { currency: 'Hong Kong Dollar', code: 'HKD' },
    { currency: 'Hungarian Forint', code: 'HUF' },
    { currency: 'Indian Rupee', code: 'INR' },
    { currency: 'Indonesian Rupiah', code: 'IDR' },
    { currency: 'Israeli New Shekel', code: 'ILS' },
    { currency: 'Japanese Yen', code: 'JPY' },
    { currency: 'Malaysian Ringgit', code: 'MYR' },
    { currency: 'Mexican Peso', code: 'MXN' },
    { currency: 'Moroccan Dirham', code: 'MAD' },
    { currency: 'New Zealand Dollar', code: 'NZD' },
    { currency: 'Norwegian Krone', code: 'NOK' },
    { currency: 'Philippine Peso', code: 'PHP' },
    { currency: 'Polish Zloty', code: 'PLN' },
    { currency: 'Pound Sterling', code: 'GBP' },
    { currency: 'Singapore Dollar', code: 'SGD' },
    { currency: 'South African Rand', code: 'ZAR' },
    { currency: 'Swedish Krona', code: 'SEK' },
    { currency: 'Swiss Franc', code: 'CHF' },
    { currency: 'Turkish Lira', code: 'TRY' },
    { currency: 'US Dollar', code: 'USD' },
    { currency: 'Vietnamese Dong', code: 'VND' },
  ];
  return (
    <>
      {/* <SixDigitModal /> */}
      <NumVerifyModal />
      <VerifySuccessModal />
      <SurveyModal />
      <div className="flex w-full flex-shrink-0 flex-col">
        <Suspense fallback={<BannerLoading />}>
          <NewBanner
            bgUrl={'/images/etsy-kar-hesaplama-bg.webp'}
            toolImageUrl="etsy-kar-hesaplama"
          />
        </Suspense>

        <LinkForm
          placeholder="https://www.etsy.com/listing/1454064011/retro-sunset-rays-wavy-shirt-vintage?ga_order=most_relevant&ga_search_type=a"
          CURRENCIES={CURRENCIES}
          setShopCurrency={setShopCurrency}
          setProductLink={setProductLink}
          shopCurrency={shopCurrency}
        />
        {/* </div> */}
        <div className="grid grid-cols-12 gap-4 mt-4">
          <div className="col-span-12 px-2 lg:px-4  lg:col-span-6 xl:col-span-5 bg-white rounded-xl shadow-xl">
            {loading ? (
              <LoadingComponent />
            ) : productInfo ? (
              <ProfitProductInfo
                product={{
                  productImg: productInfo?.productImage,
                  productTitle: productInfo?.productTitle || '',
                  brand: productInfo?.brand,
                  sellerName: productInfo?.sellerName,
                  rating: productInfo?.rating,
                }}
              />
            ) : (
              ''
            )}

            {/* Form */}
            <ProfitForm
              shopCurrency={shopCurrency}
              onSubmit={onSubmit}
              productInfo={productInfo}
              loading={loading}
            />
            {/* ETSY COSTS */}
            {profitCalc?.etsyCost && (
              <EtsyCostTable profitCalc={profitCalc} currency={bankCur} />
            )}
          </div>

          <div className="col-span-12 lg:col-span-6 xl:col-span-7 ">
            {/* video content */}
            <VideoTools videoSrc="courses/shared/etsy-kar-hesaplama.mp4" />
            {/* <img
              className="w-full rounded-xl"
              src="/images/etsyCalcBanner.png"
              alt="etsy-banner"
            /> */}

            {/* Smal Info cards */}
            <EtsyInfoCards infos={infoCardsArr} resultLoading={resultLoading} />

            {/* Apex Section */}
            <div className="mt-4 py-4  bg-white rounded-xl shadow-xl">
              <PieChart series={chartValues} options={chartOptions} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EtsyProfitCalculator;
