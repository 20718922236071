import React from 'react';
import BlurredGptReviewBox from './BlurredGptReviewBox';
interface GPTReviewBoxProps {
  gptAnalysis: string[];
  isLoading: boolean;
}

// function classNames(...classes: String[]) {
//   return classes.filter(Boolean).join(' ');
// }

const GPTReviewBox: React.FC<GPTReviewBoxProps> = ({
  gptAnalysis,
  isLoading,
}) => {
  if (isLoading) {
    return <BlurredGptReviewBox />;
  }

  return (
    <>
      <div className="mt-6 w-full border-2 border-cardBorder rounded-lg px-2 py-4">
        <ol className="px-4">
          {gptAnalysis.map((analysis, idx) => (
            <li key={idx} className="text-[#3C4790] text-md my-2">
              {' '}
              {analysis}
            </li>
          ))}
        </ol>
      </div>
    </>
  );
};

export default GPTReviewBox;
