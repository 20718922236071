import { BoxSearch, Calculator, EmojiHappy } from 'iconsax-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../../../context/LanguageContext';

const ToolsCard = () => {
  const { language } = useLanguage();
  return (
    <div className="bg-white px-2 pt-4 pb-6 shadow-lg rounded-xl col-span-2 md:col-span-1 ">
      <p className="px-4 font-semibold text-headerPrimary hover:font-bold">
        <Link to="/amazon-review-analysis" className="cursor-pointer">
          {language === 'tr' ? 'Araçlar' : 'Tools'}
        </Link>
      </p>
      <div className="flex flex-row gap-4 justify-between items-center p-2 h-full">
        <Link
          to="/amazon-online-arbitrage"
          className="flex flex-col gap-4 justify-center items-center transition delay-150 hover:scale-110 hover:cursor-pointer"
        >
          <div className="bg-white py-2 px-2 w-20 h-20 sm:w-32 sm:h-32 md:w-20 xl:w-20 2xl:w-28 md:h-20 xl:h-20 2xl:h-28 shadow-xl rounded-full flex items-center justify-center">
            <BoxSearch size={50} color="#3C4790" />
          </div>
          <span className=" text-xs sm:text-sm font-medium text-headerPrimary text-center">
            {language === 'tr' ? 'Ürün' : 'Product'}{' '}
            <br className="2xl:hidden" />{' '}
            {language === 'tr' ? 'Bulma' : 'Finding'}
          </span>
        </Link>
        <Link
          to="/etsy-profit-calculator"
          className="flex flex-col gap-4 justify-center items-center transition delay-150 hover:scale-110 hover:cursor-pointer"
        >
          <div className="bg-white py-2 px-2 w-20 h-20 sm:w-32 sm:h-32 md:w-20 xl:w-20 2xl:w-28 md:h-20 xl:h-20 2xl:h-28 shadow-xl rounded-full flex items-center justify-center">
            <Calculator size={50} color="#3C4790" />
          </div>
          <span className="text-xs sm:text-sm font-medium text-headerPrimary text-center">
            {language === 'tr' ? 'Kar ' : 'Profit '}
            <br className="2xl:hidden" />
            {language === 'tr' ? 'Hesaplama' : 'Calculation'}
          </span>
        </Link>
        <Link
          to="/amazon-review-analysis"
          className="flex flex-col gap-4 justify-center items-center transition delay-150 hover:scale-110 hover:cursor-pointer"
        >
          <div className="bg-white py-2 px-2 w-20 h-20 sm:w-32 sm:h-32 md:w-20 xl:w-20 2xl:w-28 md:h-20 xl:h-20 2xl:h-28 shadow-xl rounded-full flex items-center justify-center">
            <EmojiHappy size={50} color="#3C4790" />
          </div>
          <span className=" text-xs sm:text-sm font-medium text-headerPrimary text-center">
            {language === 'tr' ? 'Yapay ' : 'Artificial '}
            <br className="2xl:hidden" />
            {language === 'tr' ? 'Zeka' : 'Intelligence'}
          </span>
        </Link>
      </div>
    </div>
  );
};

export default ToolsCard;
