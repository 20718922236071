import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { XCircleIcon } from '@heroicons/react/24/outline';

import UserSuggestionModal from '../components/survey/userSuggestion/UserSuggestionModal';
import { useLanguage } from '../context/LanguageContext';
import { useNumberVerification } from '../context/NumberVerificationContext';
import { classNames } from '../utils/conditionalClasses';

const UserSuggestion = () => {
  const location = useLocation();
  const { language } = useLanguage();
  const [open, setOpen] = useState(false);
  const [display, setDisplay] = useState(true);
  const { openSurvey } = useNumberVerification();

  useEffect(() => {
    if (location.pathname.includes('/messages')) {
      setDisplay(false);
    }
    if (openSurvey) {
      setDisplay(false);
    }
  }, [location.pathname, openSurvey]);

  return (
    <>
      <div
        className={classNames(
          display ? 'block' : 'hidden',
          `fixed z-40 right-0 top-1/2 transform -translate-y-1/2`
        )}
      >
        <div className="m-0 p-0">
          <div className="text-white cursor-pointer">
            <div
              className="rounded-br-lg rounded-tr-lg text-white text-sm border-none outline-none whitespace-nowrap bg-[#1D4ED8] py-8 px-1 shadow-lg transition-all duration-800 flex items-center overflow-hidden"
              style={{ writingMode: 'vertical-rl', transform: 'rotate(180deg)' }}
            >
              <div
                onClick={() => setOpen(true)}
                className="flex-shrink-0 leading-1 w-4 h-4 mb-2"
              >
                <img src="./assets/logos/rexven-logo-3.png" alt="" />
              </div>
              <span onClick={() => setOpen(true)}>
                {language === 'tr' ? 'ÖNERİM VAR' : 'I have a Suggestion'}
              </span>
              <span
                onClick={() => setDisplay(false)}
                className="flex-shrink-0 leading-1 w-6 h-6 mt-2"
              >
                <XCircleIcon />
              </span>
            </div>
          </div>
        </div>
      </div>
      <UserSuggestionModal open={open} setOpen={setOpen} />
    </>
  );
};

export default UserSuggestion;
