import React from 'react';
import usePaginate from '../../hooks/usePaginate';
import { useLanguage } from '../../context/LanguageContext';

interface PageRadioButtonsProps {
  isProducer: boolean;
  setIsProducer: React.Dispatch<React.SetStateAction<boolean>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setKeyword: React.Dispatch<React.SetStateAction<string>>;
  setInputPage: React.Dispatch<React.SetStateAction<number | string>>;
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

const PageRadioButtons: React.FC<PageRadioButtonsProps> = ({
  isProducer,
  setIsProducer,
  setPage,
  setKeyword,
  setInputPage,
}) => {
  const { navigate } = usePaginate();
  const { t } = useLanguage();

  return (
    <div className="flex flex-row text-sm">
      <button
        className={classNames(
          isProducer === true ? 'bg-headerPrimary text-white' : '',
          'w-[50%] border-2 py-2 border-headerPrimary rounded-l-md'
        )}
        onClick={() => {
          setIsProducer(true);
          setPage(1);
          setKeyword('');
          setInputPage('');
          navigate('');
        }}
      >
        {t?.('manufacturers-and-wholesalers.manufacturers')}
      </button>
      <button
        className={classNames(
          isProducer === false ? 'bg-headerPrimary text-white' : '',
          'w-[50%] border-2 py-2 border-headerPrimary rounded-r-md'
        )}
        onClick={() => {
          setIsProducer(false);
          setPage(1);
          setKeyword('');
          setInputPage('');
          navigate('');
        }}
      >
        {t?.('manufacturers-and-wholesalers.products')}
      </button>
    </div>
  );
};

export default PageRadioButtons;
