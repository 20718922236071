import React, { useState, useEffect } from 'react';
import NewBanner from '../components/tools/common/NewBanner';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { RootAliexpressTopKeywords } from '../interfaces/aliexpressInterfaces';
import aliExpressService from '../services/aliExpressService';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './errors/ErrorFallback';
import LoadingComponent from '../components/common/LoadingComponent';
import ProductNotFound from '../components/common/ProductNotFound';
import { useQuery } from '@tanstack/react-query';
import AliexpressTopKeywordsTable from '../components/tools/aliexpress/AliexpressTopKeywordsTable';
import memberService from '../services/memberService';
import { useAuth } from '../context/AuthContext';

interface IAliexpressTopKeywordssQuery {
  keyword: string;
}

const AliExpressTopKeywords = () => {
  const { session } = useAuth();
  const [page, setPage] = useState(1);
  const [queryString, setQueryString] = useState('?page=1');
  const [isTabActive, setIsTabActive] = React.useState<boolean>(true);
  const [inputPage, setInputPage] = useState<string | number>(1);
  const [queryParams, setQueryParams] = useState<IAliexpressTopKeywordssQuery>({
    keyword: '',
  });
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const { isLoading, error, data } = useQuery<RootAliexpressTopKeywords, Error>(
    ['aliexpressTopKeywords', { page, queryParams }],
    {
      queryFn: () =>
        aliExpressService.getAliExpressTopKeywords(page, queryParams!),
      staleTime: Infinity,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (!searchParams.has('page') && !searchParams.has('keyword')) {
      setPage(1);
      setInputPage(1);
      setQueryString(`keyword=${queryParams.keyword}`);
      navigate(`?page=1&keyword=${queryParams.keyword}`);
    } else if (searchParams.has('page') && searchParams.has('keyword')) {
      let temp = Object.fromEntries([...searchParams]);
      setPage(Number(temp.page));
      setInputPage(Number(temp.page));
      setQueryString(`keyword=${temp.keyword}`);
      navigate(`?page=${temp.page}&keyword=${temp.keyword}`);
      setQueryParams({
        keyword: temp.keyword,
      });
    }
  }, [page, location.search]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabActive(document.visibilityState === 'visible');
    };

    // Add event listener for visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup function to remove the event listener when the component is unmounted
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
  useEffect(() => {
    let intervalId: any;
    if (session && isTabActive) {
      intervalId = setInterval(async () => {
        await memberService.userTrackReq(
          'aliexpress-top-keywords',
          session?.id!
        );
      }, 60000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [session, isTabActive]);
  return (
    <div>
      <NewBanner
        bgUrl="/images/trendyol-kar-hesaplama-bg.webp"
        toolImageUrl="aliexpress-top-keywords"
      />
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {isLoading ? (
          <LoadingComponent />
        ) : data?.data.data.length! > 0 ? (
          <AliexpressTopKeywordsTable
            setQueryString={setQueryString}
            queryString={queryString}
            setInputPage={setInputPage}
            inputPage={inputPage}
            setPage={setPage}
            page={page}
            data={data?.data.data!}
            isLoading={isLoading}
            queryParams={queryParams}
            setQueryParams={setQueryParams}
            totalPageCount={data?.data.totalPageCount!}
          />
        ) : (
          <ProductNotFound />
        )}
      </ErrorBoundary>
    </div>
  );
};

export default AliExpressTopKeywords;
