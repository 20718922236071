import React from 'react';

interface Props {
  imageUrl: string;
  title: string;
}

const TitleBanner: React.FC<Props> = ({ imageUrl, title }) => {
  return (
    <div
      className={`w-full rounded-xl flex items-center justify-center h-[240px] mb-8`}
      style={{
        backgroundImage: `url(${imageUrl})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'full',
      }}
    >
      <p className="text-4xl xl:text-6xl text-headerPrimary font-black uppercase text-center">
        {' '}
        {title}
      </p>
    </div>
  );
};

export default TitleBanner;
