import React, { useEffect, useState } from 'react';

import CustomSelect from '../../trendyolProfit/CustomSelect';
import { jobs } from './jobs';

interface QuestionProps {
    handleForward: (question: string, answer: string) => void
    handleBackward: (question: string, answer: string) => void
}
const Question2: React.FC<QuestionProps> = ({
    handleForward,
    handleBackward
}) => {
    const [categories, setCategories] = useState(jobs);
    const [answer, setAnswer] = useState(() => {
        const answer2 = sessionStorage.getItem("answer2");
        return answer2 || '';
    });
    useEffect(() => {
        sessionStorage.setItem("answer2", answer);
    }, [answer]);
    return (
        <>
            <div>
                <div className="flex flex-col space-y-4 min-h-[400px] justify-between">
                    <div className='mt-2'>
                        <label htmlFor="profession" className="text-base font-semibold text-gray-900">
                            Mesleğiniz Nedir ?
                        </label>
                        <div className="w-full mt-2 mb-10">
                            {categories && categories.length > 0 && (
                                <CustomSelect
                                    page='survey'
                                    options={categories}
                                    onSelect={(selectedCategory: any) => {
                                        setAnswer(selectedCategory.name);
                                    }}
                                />
                            )}
                        </div>
                    </div>
                    <div className="flex items-center text-[16px] bg justify-between mt-2">
                        <div
                            onClick={() => { handleBackward("question2", answer) }}
                            className=" text-center text-[13px] px-3 h-[50px] max-h-[50px] font-bold  lg:py-1  w-[49%] flex items-center w-full cursor-pointer">Geri</div>
                        <div
                            onClick={() => { handleForward("question2", answer) }}
                            className="hover:cursor-pointer text-center text-[12px] bg-headerPrimary font-bold text-white h-[50px] max-h-[50px]  px-1 py-1  border-2 rounded-lg border-headerPrimary w-[49%] flex items-center justify-center"
                        >
                            İleri
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Question2