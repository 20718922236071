import { Outlet } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import Loading from '../components/loader/Loader';
import PublicLayout from '../layout/PublicLayout';

const PublicRoute: React.FC = (props) => {
  const { session } = useAuth();

  if (session === undefined) {
    return (
      <div className="h-screen w-full flex items-center justify-center">
        <Loading />
      </div>
    );
  }

  return (
    <PublicLayout>
      <Outlet />
    </PublicLayout>
  );
};
export default PublicRoute;
