import React from 'react';

const KVKK = () => {
  return (
    <div className="flex flex-col max-w-[1280px] mx-auto py-10">
      <p className="font-medium">
        KİŞİSEL VERİLERİN KORUNMASINA İLİŞKİN AYDINLATMA METNİ
      </p>
      <p>&nbsp;</p>
      <p>
        Değerli Ziyaret&ccedil;ilerimiz, &Uuml;yelerimiz ve M&uuml;şterilerimiz,
      </p>
      <p>
        6698 sayılı Kişisel Verilerin Korunması Kanunu (KVKK) uyarınca, veri
        sorumlusu sıfatıyla &lsquo;Rexven Teknoloji A.Ş.&rsquo; (Rexven veya
        Şirket)&rsquo;nın aydınlatma y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml; (KVKK
        md. 10) bulunduğundan bu kapsamda işbu metin ile kişisel verilerinizin
        işlenmesi ile veri sahibi olarak haklarınıza ilişkin bilgilendirilmeniz
        ama&ccedil;lanmıştır. Bu metinde;
      </p>
      <ul>
        <li>
          <p>
            Firma&rsquo;nın, www.rexven.com uzantılı internet sitesini
            (&ldquo;site&rdquo;) ziyaret etmeniz (&ldquo;&Ccedil;evrimi&ccedil;i
            Ziyaret&ccedil;i&rdquo;), Site&rsquo;ye &uuml;ye olmanız, Site
            &uuml;zerinden &uuml;r&uuml;n paket satın almanız ve Chrome
            eklentisini kullanmanız,
          </p>
        </li>
        <li>
          <p>
            Ticari iletişim kanalları ile genel ve kişiye &ouml;zel kampanya
            bilgilendirmelerin yapılmasını kabul etmeniz,
          </p>
        </li>
        <li>
          <p>
            İletişim kanallarımız &uuml;zerinden karşılıklı olarak iletişime
            ge&ccedil;memiz,
          </p>
        </li>
        <li>
          <p>
            Firma tarafından d&uuml;zenlenen kampanya ve diğer etkinliklere
            katılmanız
          </p>
        </li>
      </ul>
      <p>
        halinde kişisel veri işleme s&uuml;re&ccedil;lerine ilişkin
        aydınlatılmanız ama&ccedil;lanmıştır.
      </p>
      <p>
        <br />
      </p>
      <p>
        Rexven olarak; Site kullanımlarına ilişkin istatistiksel
        &ccedil;alışmaların yapılması, hedeflenmiş reklam/tanıtımların yapılması
        ve etkin bir m&uuml;şteri deneyimi yaşamanızı sağlamak amacıyla
        Site&rsquo;de &ccedil;erez vb. y&ouml;ntemler kullanmaktayız.
        &Ccedil;erezlerle ilgili ayrıntılı bilgi i&ccedil;in
        &lsquo;www.rexven.com&rsquo; adresinde yer alan &ccedil;erez
        politikasını inceleyebilirsiniz.
      </p>
      <p>
        <br />
      </p>
      <p className="font-medium">A) VERİ SORUMLUSU</p>
      <p>
        KVKK uyarınca, kişisel verileriniz; veri sorumlusu olarak T&uuml;rkiye
        Cumhuriyeti kanunlarına g&ouml;re&nbsp;Mutlukent Mah. 2041 Sok. No :5/1
        &Ccedil;ankaya/Ankara&nbsp;adresinde kurulmuş Rexven
        Teknoloji A. Ş. tarafından aşağıda kısaca a&ccedil;ıklanan kapsamda
        işlenebilecek, saklanabilecek, gerektiğinde &uuml;&ccedil;&uuml;nc&uuml;
        şahıslara a&ccedil;ıklanabilecek, anonim hale getirilebilecek veya
        kimliksizleştirilecek yada imha edilecektir.
      </p>
      <p>
        Rexven Teknoloj A.Ş. gizlilik ilkelerini &ouml;n planda tutmakta olup
        Rexven platformunu kullanarak tarafımıza iletmiş olduğunuz her
        t&uuml;rl&uuml; kişisel veriyi;&nbsp;
      </p>
      <p>
        Başta 6698 sayılı Kişisel Verilerin Korunması Kanunu
        (&lsquo;kanun&rsquo; veya &lsquo;KVKK&rsquo;) olmak &uuml;zere sair
        mevzuat ve T&uuml;rkiye Cumhuriyeti&rsquo;nin taraf olduğu uluslararası
        s&ouml;zleşmeler bağlamında
      </p>
      <p>&nbsp; &nbsp;&nbsp;&bull;&nbsp; &nbsp;&nbsp;Kaydetme</p>
      <p>&nbsp; &nbsp;&nbsp;&bull;&nbsp; &nbsp;&nbsp;İşleme&nbsp;</p>
      <p>&nbsp; &nbsp;&nbsp;&bull;&nbsp; &nbsp;&nbsp;Saklama</p>
      <p>&nbsp; &nbsp;&nbsp;&bull;&nbsp; &nbsp;&nbsp;G&uuml;ncelleme</p>
      <p>&nbsp; &nbsp;&nbsp;&bull;&nbsp; &nbsp;&nbsp;Sınıflandırma</p>
      <p>&nbsp; &nbsp;&nbsp;&bull;&nbsp; &nbsp;&nbsp;Devretme</p>
      <p>
        &nbsp; &nbsp;&nbsp;&bull;&nbsp; &nbsp;&nbsp;Ve gerektiğinde yurtdışı da
        dahil olmak &uuml;zere &uuml;&ccedil;&uuml;nc&uuml; şahıslara aktarma
      </p>
      <p>
        &nbsp; &nbsp;&nbsp;&bull;&nbsp; &nbsp;&nbsp;Ve yine gerekmesi durumunda
        anonim hale getirme veya imha etme
      </p>
      <p>&nbsp; &nbsp;&nbsp;&bull;&nbsp; &nbsp;&nbsp;Kimliksizleştirme</p>
      <p>
        konularında gerekli olabilecek her t&uuml;rl&uuml; prosed&uuml;r&uuml;
        uygulamaktadır. Kişisel veri, kimliği belirli ya da belirlenebilir
        ger&ccedil;ek kişiye ilişkin her t&uuml;rl&uuml; bilgidir. Aşağıda
        kişisel verilerinizin Rexven tarafından ne şekilde toplandığı,
        işlendiği, hangi ama&ccedil;larla ve kimlere aktarılabildiği, hangi
        hallerde imha edileceği ve kişisel veri sahibi olarak sizlerin Kişisel
        Verilerin Korunması Kanunu kapsamında sahip olduğunuz haklar ile bu
        hakları kullanmak i&ccedil;in Rexven&rsquo;e ne şekilde m&uuml;racaat
        edebileceğiniz konularında kısaca bilgilendirileceksiniz. &nbsp;
      </p>
      <p className="font-medium">&nbsp; &nbsp;1- &Uuml;YELERİMİZ</p>
      <p>&nbsp;</p>
      <p>&nbsp; &nbsp; a) &nbsp;Rexven tarafından işlenen kişisel veriler:</p>
      <p>&nbsp;</p>
      <p>
        Rexven platformunda yer alan &uuml;yelik koşullarını kabul ederek web
        sitesinden veya mobil cihazlara kurulan uygulamadan giriş yapan ve
        platformda yer alan &uuml;r&uuml;nleri takip eden ve/veya satın alan
        kişiler &quot;&Uuml;ye&quot; olarak tanımlanmaktadır. Rexven platformuna
        &uuml;ye olmanız halinde aşağıdaki kişisel veriler işlenebilmektedir.
      </p>
      <p>&nbsp;</p>
      <p>
        &bull; Kimlik Bilgileri: ad, soyad, cinsiyet ve yaş bilgisi,
        TCKN&nbsp;(yalnızca belli &uuml;r&uuml;n gruplarının satın alınması
        halinde mevzuat uyarınca işlenmektedir.)
      </p>
      <p>
        &bull; İletişim Bilgileri: cep telefonu numarası, teslimat adresi,
        e-posta adresi,
      </p>
      <p>
        &bull; M&uuml;şteri İşlem Bilgileri: sipariş ve fatura bilgileri,
        teslimat işlem bilgileri, yapılan alışverişlerle ilgili
        ger&ccedil;ekleşen işlem ge&ccedil;mişi bilgisi, talep ve şikayet
        bilgileri, platformda yer alan &uuml;r&uuml;nlere ilişkin yorum
        yapılması ve/veya soru sorulması halinde yorum ve sorular
        i&ccedil;erisinde bizzat paylaşılan bilgiler, Rexven m&uuml;şteri
        hizmetleri aracılığıyla yapılan konuşma i&ccedil;eriklerine y&ouml;nelik
        bilgiler,
      </p>
      <p>
        &bull; İşlem G&uuml;venliği Bilgileri: IP adresi bilgisi, şifre ve
        parola bilgileri, &ccedil;erez bilgileri,
      </p>
      <p>
        &bull; Hukuki İşlem Bilgileri: yetkili kişi, kurum ve kuruluşlarla
        yazışmalardaki bilgiler, dava ve icra dosyalarındaki bilgiler, yasal
        bilgi talebi bilgileri,
      </p>
      <p>
        &bull; İşitsel Kayıt Bilgileri: &ccedil;ağrı merkeziyle
        g&ouml;r&uuml;ş&uuml;lmesi halinde ses kayıtları.
      </p>
      <p>&nbsp;</p>
      <p>
        &nbsp; &nbsp; b) Rexven&rsquo;in kişisel verileri işlemesinin
        ama&ccedil;ları ve toplama y&ouml;ntemleri:
      </p>
      <p>&nbsp;</p>
      <p>
        Şirket; kimlik, iletişim ve m&uuml;şteri işlem bilgileri,
        s&ouml;zleşmesel ilişki kapsamında &uuml;yelerden, rexven.com internet
        sitesinden elektronik ortamda otomatik olarak aşağıda yazdığımız
        ama&ccedil;larla toplamakta ve işlemektedir.
      </p>
      <p>&nbsp;</p>
      <p>
        &bull; S&ouml;zleşmenin kurulması ve ifasına y&ouml;nelik
        s&uuml;re&ccedil;lerin y&uuml;r&uuml;t&uuml;lmesi,
      </p>
      <p>
        &bull; Finans ve muhasebe s&uuml;re&ccedil;lerinin
        y&uuml;r&uuml;t&uuml;lmesi ve denetimi,
      </p>
      <p>
        &bull; Şirketin iş faaliyetlerinin y&uuml;r&uuml;t&uuml;lmesi ve
        denetimi,
      </p>
      <p>
        &bull; Faturalandırma s&uuml;re&ccedil;lerinin
        y&uuml;r&uuml;t&uuml;lmesi,
      </p>
      <p>
        &bull; İnternet sitesi &uuml;zerinden satın alım işlemlerinin
        ger&ccedil;ekleşmesi ve işlem yapanın kimlik bilgilerinin teyit
        edilmesi,
      </p>
      <p>
        &bull; A&ccedil;ık rızanın bulunması halinde, &ccedil;eşitli pazarlama
        ve reklam faaliyetlerinde kullanılabilmesi, tercihlere uygun
        &uuml;r&uuml;nlerin &uuml;yelere sunulması ve memnuniyetin
        arttırılmasına y&ouml;nelik faaliyetlerin y&uuml;r&uuml;t&uuml;lmesi,
      </p>
      <p>
        &bull; M&uuml;şteri memnuniyetini artırmak, platformdan alışveriş yapan
        m&uuml;şterileri tanıyabilmek ve m&uuml;şteri &ccedil;evresi analizinde
        kullanabilmek, Şirketimizin sunduğu &uuml;r&uuml;n ve hizmetlerin
        geliştirilmesi ve iyileştirilmesine y&ouml;nelik faaliyetlerin
        y&uuml;r&uuml;t&uuml;lmesi,
      </p>
      <p>
        &bull; Stratejik analiz &ccedil;alışmalarının
        y&uuml;r&uuml;t&uuml;lmesi,
      </p>
      <p>&bull; İletişim faaliyetlerinin y&uuml;r&uuml;t&uuml;lmesi,</p>
      <p>
        &bull; &Uuml;yelerin ilgi alanlarını dikkate alarak ilgilerine sunulacak
        &uuml;r&uuml;nlerin bulunması,
      </p>
      <p>
        &bull; Mesafeli satış s&ouml;zleşmesi ve T&uuml;keticinin Korunması
        Hakkında Kanun&apos;un ilgili maddeleri gereğince platformumuz
        &uuml;zerinden akdedilen s&ouml;zleşmelerin koşulları, g&uuml;ncel
        durumu ve g&uuml;ncellemeler ile ilgili iletişime ge&ccedil;ilmesi ve
        gerekli bilgilendirmelerin yapılması,
      </p>
      <p>
        &bull; A&ccedil;ık rızanın bulunması halinde, Rexven nezdindeki
        gelişmeler, fırsatlar ve yenilikler hakkında &uuml;yelere bilgi
        verilmesi,
      </p>
      <p>
        &bull; İnternet sitesinden alışveriş yapan m&uuml;şterilerin tanınması,
        m&uuml;şteri &ccedil;evresi analizinde kullanılmasına y&ouml;nelik
        faaliyetlerin y&uuml;r&uuml;t&uuml;lmesi ve bu kapsamda anlaşmalı
        kuruluşlar aracılığıyla elektronik ortamda ve/veya fiziki ortamda anket
        &ccedil;alışmaları yapılması,
      </p>
      <p>
        &bull; &Uuml;r&uuml;n ve hizmetlerimize ilişkin talep, şikayet ve
        &ouml;nerilerin değerlendirilmesi,
      </p>
      <p>
        &bull; &Uuml;r&uuml;nlere y&ouml;nelik bilgi alınmasının sağlanması,
      </p>
      <p>
        &bull; Hizmet satış sonrası destek hizmetlerinin
        y&uuml;r&uuml;t&uuml;lmesi,
      </p>
      <p>
        &bull; Bilgi g&uuml;venliği s&uuml;re&ccedil;lerinin
        y&uuml;r&uuml;t&uuml;lmesi,
      </p>
      <p>
        &bull; Rexven platformuna ilişkin olarak operasyonların
        g&uuml;venliğinin sağlanması,
      </p>
      <p>
        &bull; Faaliyetlerin mevzuata uygun y&uuml;r&uuml;t&uuml;lmesinin
        sağlanması,
      </p>
      <p>&bull; Hukuk işlerinin takibi ve y&uuml;r&uuml;t&uuml;lmesi,</p>
      <p>&bull; Yetkili kişi, kamu kurum ve kuruluşlarına bilgi verilmesi.</p>
      <p>&nbsp;</p>
      <p>
        Şirket; işlem g&uuml;venliği bilgileri, s&ouml;zleşmesel ilişki
        kapsamında bizzat &uuml;yelerden, rexven.com internet sitesinden
        elektronik ortamda otomatik olarak aşağıdaki ama&ccedil;larla toplamakta
        ve işlemektedir.
      </p>
      <p>&nbsp;</p>
      <p>
        &bull; S&ouml;zleşmenin kurulması ve ifasına y&ouml;nelik
        s&uuml;re&ccedil;lerin y&uuml;r&uuml;t&uuml;lmesi,
      </p>
      <p>
        &bull; Şirketin iş faaliyetlerinin y&uuml;r&uuml;t&uuml;lmesi ve
        denetimi,
      </p>
      <p>
        &bull; İnternet sitesi &uuml;zerinden satın alım işlemlerinin
        ger&ccedil;ekleşmesi ve işlem yapanın kimlik bilgilerinin teyit
        edilmesi,
      </p>
      <p>
        &bull; A&ccedil;ık rızanın bulunması halinde, &ccedil;eşitli pazarlama
        ve reklam faaliyetlerinde kullanılabilmesi, &uuml;yelerin tercihlerine
        uygun &uuml;r&uuml;nlerin &uuml;yelere sunulması ve memnuniyetin
        arttırılmasına y&ouml;nelik faaliyetlerin y&uuml;r&uuml;t&uuml;lmesi,
      </p>
      <p>
        &bull; M&uuml;şteri memnuniyetini artırmak, platformdan alışveriş yapan
        m&uuml;şterileri tanıyabilmek ve m&uuml;şteri &ccedil;evresi analizinde
        kullanabilmek, Şirketimizin sunduğu &uuml;r&uuml;n ve hizmetlerin
        geliştirilmesi ve iyileştirilmesine y&ouml;nelik faaliyetlerin
        y&uuml;r&uuml;t&uuml;lmesi,
      </p>
      <p>
        &bull; Bilgi g&uuml;venliği s&uuml;re&ccedil;lerinin
        y&uuml;r&uuml;t&uuml;lmesi,
      </p>
      <p>
        &bull; Rexven platformuna ilişkin olarak operasyonların
        g&uuml;venliğinin sağlanması,
      </p>
      <p>
        &bull; Faaliyetlerin mevzuata uygun y&uuml;r&uuml;t&uuml;lmesinin
        sağlanması,
      </p>
      <p>&bull; Yetkili kişi, kurum ve kuruluşlara bilgi verilmesi.</p>
      <p>&nbsp;</p>
      <p>
        Şirket; hukuki işlem bilgileri, s&ouml;zleşmesel ilişki kapsamında
        &uuml;yelerden, internet sitesinden elektronik ortamda otomatik olarak
        aşağıdaki ama&ccedil;larla toplamakta ve işlemektedir.
      </p>
      <p>&nbsp;</p>
      <p>
        &bull; S&ouml;zleşmenin kurulması ve ifasına y&ouml;nelik
        s&uuml;re&ccedil;lerin y&uuml;r&uuml;t&uuml;lmesi,
      </p>
      <p>
        &bull; Şirketin iş faaliyetlerinin y&uuml;r&uuml;t&uuml;lmesi ve
        denetimi,
      </p>
      <p>
        &bull; &Uuml;r&uuml;n ve hizmetlerimize ilişkin talep, şikayet ve
        &ouml;nerilerin değerlendirilmesi,
      </p>
      <p>
        &bull; Bilgi g&uuml;venliği s&uuml;re&ccedil;lerinin
        y&uuml;r&uuml;t&uuml;lmesi,
      </p>
      <p>
        &bull; Rexven platformuna ilişkin olarak operasyonların
        g&uuml;venliğinin sağlanması,
      </p>
      <p>
        &bull; Faaliyetlerin mevzuata uygun y&uuml;r&uuml;t&uuml;lmesinin
        sağlanması,
      </p>
      <p>&bull; Hukuk işlerinin takibi ve y&uuml;r&uuml;t&uuml;lmesi,</p>
      <p>&bull; Yetkili kişi, kurum ve kuruluşlara bilgi verilmesi.</p>
      <p>&nbsp;</p>
      <p>
        Şirket; işitsel kayıt bilgileri, &ccedil;ağrı merkeziyle iletişime
        ge&ccedil;ilmesi halinde aşağıdaki ama&ccedil;larla toplamakta ve
        işlemektedir.
      </p>
      <p>
        &bull; S&ouml;zleşmenin kurulması ve ifasına y&ouml;nelik
        s&uuml;re&ccedil;lerin y&uuml;r&uuml;t&uuml;lmesi,
      </p>
      <p>&bull; İletişim faaliyetlerinin y&uuml;r&uuml;t&uuml;lmesi,</p>
      <p>
        &bull; Kampanyaların y&uuml;r&uuml;t&uuml;lmesi, tercihlerinize uygun
        &uuml;r&uuml;nlerin tarafınıza sunulması başta olmak &uuml;zere
        &uuml;yelere daha iyi bir alışveriş deneyiminin sağlanması ve
        memnuniyetin arttırılmasına y&ouml;nelik faaliyetlerin
        y&uuml;r&uuml;t&uuml;lmesi,
      </p>
      <p>
        &bull; Şirketimizin sunduğu &uuml;r&uuml;n ve hizmetlerin geliştirilmesi
        ve iyileştirilmesine y&ouml;nelik faaliyetlerin
        y&uuml;r&uuml;t&uuml;lmesi,
      </p>
      <p>
        &bull; Rexven platformuna giriş yapanların ilgi alanlarını dikkate
        alarak ilgilerine sunulmak &uuml;zere &uuml;r&uuml;nlerin bulunması,
      </p>
      <p>
        &bull; Mesafeli satış s&ouml;zleşmesi ve T&uuml;keticinin Korunması
        Hakkında Kanun&apos;un ilgili maddeleri gereğince platformumuz
        &uuml;zerinden akdedilen s&ouml;zleşmelerin koşulları, g&uuml;ncel
        durumu ve g&uuml;ncellemeler ile ilgili iletişime ge&ccedil;ilmesi ve
        gerekli bilgilendirmelerin yapılması,
      </p>
      <p>
        &bull; A&ccedil;ık rızanın bulunması halinde, Rexven nezdindeki
        gelişmeler, fırsatlar ve yenilikler hakkında &uuml;yelere bilgi
        verilmesi,
      </p>
      <p>
        &bull; İnternet sitesinden alışveriş yapan m&uuml;şterilerin tanınması,
        m&uuml;şteri &ccedil;evresi analizinde kullanılmasına y&ouml;nelik
        faaliyetlerin y&uuml;r&uuml;t&uuml;lmesi ve bu kapsamda anlaşmalı
        kuruluşlar aracılığıyla elektronik ortamda ve/veya fiziki ortamda anket
        &ccedil;alışmaları yapılması,
      </p>
      <p>
        &bull; &Uuml;r&uuml;n ve hizmetlerimize ilişkin talep, şikayet ve
        &ouml;nerilerinin değerlendirilmesi,
      </p>
      <p>
        &bull; Rexven platformuna ilişkin olarak operasyonların
        g&uuml;venliğinin sağlanması,
      </p>
      <p>
        &bull; Faaliyetlerin mevzuata uygun y&uuml;r&uuml;t&uuml;lmesinin
        sağlanması,
      </p>
      <p>&bull; Hukuk işlerinin takibi ve y&uuml;r&uuml;t&uuml;lmesi,</p>
      <p>&bull; Yetkili kişi, kurum ve kuruluşlara bilgi verilmesi.</p>
      <p>&nbsp;</p>
      <p>
        &nbsp; &nbsp; c) Kişisel verilerin işlenmesinde Rexven&rsquo;in
        dayandığı hukuki sebepler nelerdir?
      </p>
      <p>&nbsp;</p>
      <p>
        Rexven &quot;Rexven tarafından hangi kişisel veriler
        işlenmektedir?&quot; başlığında yer alan kişisel veriler yukarıda yer
        alan ama&ccedil;larla ve KVKK&rsquo;nın 5., 8. ve 9. maddelerinde
        belirtilen ve aşağıda yer verilen hukuka uygunluk sebeplerine
        dayanılarak işlenmektedir.
      </p>
      <p>&nbsp;</p>
      <p>
        &bull;&nbsp;6563 Elektronik Ticaretin D&uuml;zenlenmesi Hakkında Kanun,
        6102 Sayılı T&uuml;rk Ticaret Kanunu, 5237 Sayılı T&uuml;rk Ceza Kanunu
        ve 6502 sayılı T&uuml;keticinin Korunması Hakkında Kanun başta olmak
        &uuml;zere Şirketimizin tabi olduğu mevzuatta a&ccedil;ık&ccedil;a
        &ouml;ng&ouml;r&uuml;lm&uuml;ş olması hukuki sebebine
        dayanarak;&nbsp;şirket platformunda yer alan operasyonların
        g&uuml;venliğinin sağlanması, bilgi g&uuml;venliği
        s&uuml;re&ccedil;lerinin y&uuml;r&uuml;t&uuml;lmesi, faaliyetlerin
        mevzuata uygun y&uuml;r&uuml;t&uuml;lmesinin sağlanmasına y&ouml;nelik
        faaliyetler başta olmak &uuml;zere mevzuattan kaynaklanan
        y&uuml;k&uuml;ml&uuml;l&uuml;klerin yerine getirilmesi,
      </p>
      <p>
        &bull;&nbsp;S&ouml;zleşmenin kurulması veya ifasıyla doğrudan doğruya
        ilgili olması kaydıyla, kişisel verilerin işlenmesinin gerekli olması
        hukuki sebebine dayanarak;&nbsp;&uuml;yelik s&ouml;zleşmesinin
        kurulması, mesafeli satış s&ouml;zleşmesi gibi T&uuml;keticinin
        Korunması Hakkında Kanun&apos;un ilgili maddeleri gereğince Rexven
        platformu &uuml;zerinden akdedilen s&ouml;zleşmelerin kurulması
        faaliyetlerinin y&uuml;r&uuml;t&uuml;lmesi, akdedilen s&ouml;zleşmelerin
        ifasına y&ouml;nelik faaliyetlerin y&uuml;r&uuml;t&uuml;lmesi, satın
        alım işlemlerinin ger&ccedil;ekleştirilmesine y&ouml;nelik faaliyetlerin
        y&uuml;r&uuml;t&uuml;lmesi, şirketin iş faaliyetlerinin
        y&uuml;r&uuml;t&uuml;lmesi ve denetimi, teslimat
        s&uuml;re&ccedil;lerinin takibinin yapılması, &uuml;r&uuml;n ve
        hizmetlerimize ilişkin talep, şikayet ve &ouml;nerilerin
        değerlendirilmesi, finans ve muhasebe s&uuml;re&ccedil;lerinin
        y&uuml;r&uuml;t&uuml;lmesi ve denetimi, iletişim faaliyetlerinin
        y&uuml;r&uuml;t&uuml;lmesi,
      </p>
      <p>
        &bull;&nbsp;Hukuki y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml;n yerine
        getirilmesinin zorunlu olması hukuki sebebine dayanarak;&nbsp;Mesafeli
        S&ouml;zleşmeler Y&ouml;netmeliği ve Elektronik Ticarette Hizmet
        Sağlayıcı ve Aracı Hizmet Sağlayıcılar Hakkında Y&ouml;netmelik başta
        olmak &uuml;zere Şirketimizin tabi olduğu ikincil mevzuatta yer alan
        ve/veya yetkili merciiler tarafından yayınlanan karar, kılavuz ve
        rehberlerde belirtilen hukuki y&uuml;k&uuml;ml&uuml;l&uuml;klerinin
        yerine getirilmesi, yetkili kişi, kurum ve kuruluşlara bilgi verilmesi,
        faaliyetlerin mevzuata uygun y&uuml;r&uuml;t&uuml;lmesinin sağlanması,
        hukuk işlerinin takibi ve y&uuml;r&uuml;t&uuml;lmesi, finans ve muhasebe
        işlerinin y&uuml;r&uuml;t&uuml;lmesi,
      </p>
      <p>
        &bull;&nbsp;Bir hakkın tesisi, kullanılması veya korunması i&ccedil;in
        veri işlemenin zorunlu olması hukuki sebebine dayanarak;&nbsp;hukuk ve
        dava işlerinin y&uuml;r&uuml;t&uuml;lmesi,
      </p>
      <p>
        &bull;&nbsp;Temel hak ve &ouml;zg&uuml;rl&uuml;klere zarar vermemek
        kaydıyla şirketimizin meşru menfaatleri i&ccedil;in veri işlenmesinin
        zorunlu olması hukuki sebebine dayanarak;şirketimizin sunduğu
        &uuml;r&uuml;n ve hizmetlerin geliştirilmesi ve iyileştirilmesine
        y&ouml;nelik faaliyetlerin y&uuml;r&uuml;t&uuml;lmesi ve &uuml;yelere
        daha iyi bir alışveriş deneyiminin sağlanması,
      </p>
      <p>
        &bull;&nbsp;A&ccedil;ık rızanın bulunması hukuki sebebine
        dayanarak;&nbsp;platformda yer alan &uuml;r&uuml;nlere y&ouml;nelik
        soruların ve/veya yorumların ad-soyad bilgisi ile birlikte yayınlanması,
        &ccedil;eşitli pazarlama ve reklam faaliyetlerinde kullanılabilmesi,
        kullanıcıların tercihlerine uygun &uuml;r&uuml;nlerin kullanıcılara
        sunulması başta olmak &uuml;zere, daha iyi bir alışveriş deneyiminin
        sağlanması ve memnuniyetin arttırılmasına y&ouml;nelik faaliyetlerin
        y&uuml;r&uuml;t&uuml;lmesi, Rexven nezdindeki gelişmeler, fırsatlar ve
        yenilikler hakkında kullanıcılara bilgi verilmesi, ticari elektronik
        iletiler g&ouml;nderilmesi ve yurtdışına kişisel veri aktarımı
        yapılması.
      </p>
      <p>&nbsp;</p>
      <p>
        &nbsp; &nbsp; d) Rexven kişisel verileri kimlere hangi sebeplerle
        aktarıyor?
      </p>
      <p>&nbsp;</p>
      <p>
        Şirketimiz, kişisel verileri &quot;bilme gereği&quot; ve &quot;kullanma
        gereği&quot; ilkelerine uygun olarak, gerekli veri minimizasyonunu
        sağlayarak ve gerekli teknik ve idari g&uuml;venlik tedbirlerini alarak
        işlemeye &ouml;zen g&ouml;stermektedir. İş faaliyetlerinin
        y&uuml;r&uuml;t&uuml;lmesi veya denetimi, iş s&uuml;rekliliğinin
        sağlanması, dijital altyapıların işletilmesi farklı paydaşlarla
        s&uuml;rekli veri akışını zaruri kıldığı i&ccedil;in işlenen kişisel
        veriler belirli ama&ccedil;larla &uuml;&ccedil;&uuml;nc&uuml; kişilere
        aktarılması gerekiyor. Ayrıca, s&ouml;zleşmesel ve kanuni
        y&uuml;k&uuml;ml&uuml;l&uuml;klerin tam ve gereği gibi yerine
        getirilebilmesi i&ccedil;in kişisel verilerin doğru ve g&uuml;ncel
        olması &ccedil;ok &ouml;nemlidir. Bunun i&ccedil;in de muhtelif iş
        ortaklarıyla ve hizmet sağlayıcılarla &ccedil;alışıyoruz.
      </p>
      <p>
        Rexven&rsquo;in tabi olduğu mevzuatta a&ccedil;ık&ccedil;a
        &ouml;ng&ouml;r&uuml;lm&uuml;ş olması ve hukuki
        y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml;n yerine getirilebilmesi i&ccedil;in
        zorunlu olması, s&ouml;zleşmenin kurulması veya ifası i&ccedil;in
        gerekli olması hukuki sebeplerine dayanarak;
      </p>
      <ul>
        <li>
          <p>
            &Uuml;yeliğiniz, satın alma ile ilgili satın alacağınız paketleri
            kullanımınızı sağlamak, ve satış sonrası destek hizmetlerini
            verebilmek, talep ve şikayetlerinizin &ccedil;&ouml;z&uuml;mlenmesi
            ve gerektiğinde sizinle iletişime
            ge&ccedil;ebilmek,&nbsp;&uuml;yelerimizin deneyimini ve
            memnuniyetini arttırmaya y&ouml;nelik faaliyetleri
            y&uuml;r&uuml;tmek, şirketimiz nezdindeki gelişmeler, fırsatlar ve
            yenilikler hakkında &uuml;yelere bilgi vermek, şirketimizin sunduğu
            &uuml;r&uuml;n ve hizmetlerin geliştirilmesi ve iyileştirilmesine
            y&ouml;nelik faaliyetleri y&uuml;r&uuml;tmek,&nbsp;bilişim
            teknolojileri, pazarlama/reklam/analiz faaliyetleri ya da uzmanlık
            gerektiren danışmanlık vb. hizmetlerini almak ama&ccedil;larıyla
            yurti&ccedil;i ve yurtdışında bulunan iş ortakları ve hizmet
            sağlayıcılarıyla (elektronik ileti aracı hizmet sağlayıcıları,
            cihazlar aracılığıyla kişisel veri toplayanlar,
            pazarlama/reklam/analiz hizmeti sağlayıcıları, veri tabanı ve sunucu
            hizmeti sağlayıcıları, altyapı bakım ve destek hizmeti
            sağlayıcıları, hukuki ve mali danışmanlık hizmeti verenler,
            e-fatura/e-arşiv fatura hizmeti sağlayıcıları, bağımsız denetim
            hizmeti sağlayıcıları, g&ouml;nderi/kargo/kurye, arşivleme ve depo
            hizmeti verenler gibi) ile, bu firmalar tarafından Şirket&rsquo;in
            faaliyetlerinin yerine getirilmesi,&nbsp;iş s&uuml;rekliliğinin
            sağlanması, bilgi g&uuml;venliğinin sağlanması, kanuni
            y&uuml;k&uuml;ml&uuml;l&uuml;klerin yerine getirilmesi&nbsp;amacı
            ile verilen hizmetler d&acirc;hilinde kullanılması kaydıyla,
          </p>
        </li>
        <li>
          <p>
            Yetkili ve g&ouml;revli &ouml;zel veya kamu kurum ve kuruluşlar ile
            adli makamlara ve resmi kurum/kuruluşlara karşı olan bilgi, belge
            verme ve ilgili sair y&uuml;k&uuml;ml&uuml;l&uuml;klerimizi yerine
            getirmek ve dava ve cevap hakları gibi yasal haklarımızı
            kullanabilmek amacıyla bizden istenen bilgileri anılan bu kurum,
            kuruluş ve makamlarla,
          </p>
        </li>
        <li>
          <p>
            Ş&uuml;pheli işlemlerin takibi, tespiti ve hukuka aykırı işlemlerin
            &ouml;nlenmesi ama&ccedil;larıyla gerekli durumlarda danışmanlık
            hizmeti aldığımız tedarik&ccedil;ilerimiz ve ilgili işleme ilişkin
            &ouml;deme kuruluşları ile,
          </p>
        </li>
        <li>
          <p>
            Site&rsquo;deki ziyaret trafiğinin artırılmasına ilişkin faaliyetler
            kapsamında tedarik&ccedil;ilerimizle,
          </p>
        </li>
        <li>
          <p>
            &Ouml;demenizi kredi/banka kartı ile yapmanız halinde kredi/banka
            kartı bilgilerinizi, Firma tarafından kaydedilmeksizin ilgili banka,
            elektronik &ouml;deme kuruluşu vb. hizmeti sağlayan
            &uuml;&ccedil;&uuml;nc&uuml; kişilerle,
          </p>
        </li>
        <li>
          <p>
            Finansal ve muhasebe s&uuml;re&ccedil;lerinin y&ouml;netimi,
            risklerin tespiti, değerlendirilmesi, dolandırıcılıkların
            &ouml;nlenmesi i&ccedil;in ilgili iş ortaklarımızla,
            danışmanlarımızla ve hizmet sağlayıcılarımızla, bankalarla, mali
            m&uuml;şavirlerimizle,
          </p>
        </li>
        <li>
          <p>
            M&uuml;şteriye e-faturasının elektronik olarak g&ouml;nderilmesi
            i&ccedil;in e-fatura iş ortağıyla; fiziki s&ouml;zleşme veya fatura
            teslimatı yapılabilmesi amacıyla kargo ve kurye firmalarıyla,
            &ouml;zel entegrat&ouml;r, bağımsız denetim, g&uuml;mr&uuml;k, mali
            m&uuml;şavir/muhasebe hizmeti sunan iş ortaklarımızla,
          </p>
        </li>
        <li>
          <p>
            Vergisel y&uuml;k&uuml;ml&uuml;l&uuml;klerin yerine getirilmesi
            i&ccedil;in vergi daireleriyle, vergi denetimleri sırasında fatura
            ve tahsilat makbuzlarının T.C. Hazine ve Maliye Bakanlığı
            yetkilileriyle,
          </p>
        </li>
        <li>
          <p>
            İletilerin y&ouml;netilmesi i&ccedil;in ileti y&ouml;netim sistemi
            (İYS) kayıt bağlamında Ticaret Bakanlığı ve yetkilendirdiği şirketle
            ve ileti g&ouml;nderimi i&ccedil;in yetkili ticari ileti altyapı
            sağlayıcıyla,
          </p>
        </li>
        <li>
          <p>
            Bilişim altyapımızı sağlayan, işleten veya hizmet sunan iş
            ortaklarımızla ve hizmet sağlayıcılarımızla,
          </p>
        </li>
        <li>
          <p>
            Risk y&ouml;netimi ve finansal raporlama s&uuml;re&ccedil;lerinin
            y&uuml;r&uuml;t&uuml;lmesi alanında hizmet sunan iş ortaklarımızla,
          </p>
        </li>
      </ul>
      <p>A&ccedil;ık rızanın bulunması hukuki sebebine dayanarak;</p>
      <ul>
        <li>
          <p>
            Web sitesi kullanım tercihleri ve gezinti ge&ccedil;mişi,
            segmentasyon yapılması ve m&uuml;şteri ile beğeni ve tercihleri
            doğrultusunda iletişime ge&ccedil;ilmesi amacıyla ilgili hizmet
            sağlayıcı ve iş ortağıyla,
          </p>
        </li>
        <li>
          <p>
            Profilleme, analiz, sunulan hizmetlerin &ouml;zelleştirilmesi,
            pazarlama ve reklam ama&ccedil;larıyla bu alanda hizmet sunan iş
            ortaklarıyla ve hizmet sağlayıcılarla,
          </p>
        </li>
        <li>
          <p>
            Pazarlama s&uuml;re&ccedil;lerinin y&ouml;netilmesi amacıyla ilgili
            iş ortaklarımızla,
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p>
        Bir hakkın tesisi, kullanılması veya korunması i&ccedil;in veri
        işlemenin zorunlu olması, şirketimizin tabi olduğu mevzuatta
        a&ccedil;ık&ccedil;a &ouml;ng&ouml;r&uuml;lm&uuml;ş olması ve hukuki
        y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml;n&uuml;n yerine getirebilmesi
        i&ccedil;in zorunlu olması hukuki sebeplerine dayanarak;
      </p>
      <p>&nbsp;</p>
      <ul>
        <li>
          <p>
            Hukuki y&uuml;k&uuml;ml&uuml;l&uuml;klerin yerine getirilmesi
            kapsamında avukatlar, denet&ccedil;iler, adli bilişim uzmanları,
            siber g&uuml;venlik danışmanları, vergi danışmanları ile danışmanlık
            ve hizmet aldığımız diğer &uuml;&ccedil;&uuml;nc&uuml; kişilerle ve
            iş ortaklarıyla,
          </p>
        </li>
        <li>
          <p>
            D&uuml;zenleyici ve denetleyici kurumlar ile mahkeme ve icra
            m&uuml;d&uuml;rl&uuml;kleri gibi yetkili kamu kurum ve
            kuruluşlarıyla,
          </p>
        </li>
        <li>
          <p>
            Kişisel verilerinizi talep etmeye yetkili olan diğer kamu kurum veya
            kuruluşlarıyla, yurti&ccedil;i ve/veya yurtdışında bulunan
            iştiraklerimizle, tedarik&ccedil;ilerimizle, iş ortaklarımızla,
            anlaşmalı olduğumuz bankalarla ve &uuml;r&uuml;n veya hizmet
            aldığımız &uuml;&ccedil;&uuml;nc&uuml; kişilerle
            paylaşılabilmektedir.
          </p>
        </li>
      </ul>
      <p>&nbsp;</p>
      <p className="font-medium">&nbsp; &nbsp;2- MİSAFİR &Uuml;YELERİMİZ</p>
      <p>&nbsp;</p>
      <p>
        &nbsp; &nbsp; a) Rexven tarafından hangi kişisel verileriniz
        işlenmektedir?
      </p>
      <p>&nbsp;</p>
      <p>
        Rexven platformuna &uuml;ye olmaksızın platformda yer alan
        &uuml;r&uuml;nleri takip eden ve/veya satın alan kişiler &ldquo;Misafir
        &Uuml;ye&rdquo; olarak tanımlanmaktadır. Rexven platformunu misafir
        &uuml;ye olarak kullanmanız ve alışveriş yapmanız sırasında aşağıda yer
        alan kişisel verileriniz işlenmektedir.
      </p>
      <p>&bull; Kimlik Bilgileri: adınız, soyadınız,</p>
      <p>
        &bull; İletişim Bilgileri: cep telefonu numarası, teslimat adresi,
        e-posta adresi,
      </p>
      <p>
        &bull; M&uuml;şteri İşlem Bilgileri: sipariş bilgileri, fatura
        bilgileri, teslimat işlem bilgileri, alışverişlerle ilgili
        ger&ccedil;ekleşen işlem ge&ccedil;mişi bilgisi, talep ve şikayet
        bilgileri,
      </p>
      <p>
        &bull; İşlem G&uuml;venliği Bilgileri: IP adresi bilgileri, &ccedil;erez
        bilgileri,
      </p>
      <p>
        &bull; Hukuki İşlem Bilgileri: yetkili kişi, kurum ve kuruluşlarla
        yazışmalardaki bilgiler, dava ve icra dosyalarındaki bilgiler, yasal
        bilgi talebi bilgileriniz,
      </p>
      <p>
        &bull; İşitsel Kayıt Bilgileri: &ccedil;ağrı merkeziyle
        g&ouml;r&uuml;şme halinde ses kaydı verisi.
      </p>
      <p>&nbsp;</p>
      <p>
        &nbsp; &nbsp; b) Kişisel verilerin işlenmesinin ama&ccedil;ları ve
        toplama y&ouml;ntemleri nelerdir?
      </p>
      <p>&nbsp;</p>
      <p>
        Kimlik, iletişim ve m&uuml;şteri işlem bilgileri;&nbsp;bizzat sizlerden,
        mobil uygulama ya da internet sitesinden elektronik ortamda otomatik
        olarak aşağıdaki ama&ccedil;larla toplanmakta ve işlenmektedir.
      </p>
      <p>
        &bull; S&ouml;zleşmenin kurulması ve ifasına y&ouml;nelik
        s&uuml;re&ccedil;lerin y&uuml;r&uuml;t&uuml;lmesi,
      </p>
      <p>
        &bull; Finans ve muhasebe s&uuml;re&ccedil;lerinin
        y&uuml;r&uuml;t&uuml;lmesi ve denetimi,
      </p>
      <p>
        &bull; Şirketin iş faaliyetlerinin y&uuml;r&uuml;t&uuml;lmesi ve
        denetimi,
      </p>
      <p>
        &bull; İnternet sitesi/ mobil uygulamalar &uuml;zerinden satın alım
        işlemlerinin ger&ccedil;ekleşmesi ve işlem yapanın kimlik bilgilerinin
        teyit edilmesi,
      </p>
      <p>
        &bull; Şirketimizin sunduğu &uuml;r&uuml;n ve hizmetlerin geliştirilmesi
        ve iyileştirilmesine y&ouml;nelik faaliyetlerin
        y&uuml;r&uuml;t&uuml;lmesi,
      </p>
      <p>
        &bull; Stratejik analiz &ccedil;alışmalarının
        y&uuml;r&uuml;t&uuml;lmesi,
      </p>
      <p>&bull; İletişim faaliyetlerinin y&uuml;r&uuml;t&uuml;lmesi,</p>
      <p>
        &bull; Mesafeli satış s&ouml;zleşmesi ve T&uuml;keticinin Korunması
        Hakkında Kanun&apos;un ilgili maddeleri gereğince platformumuz
        &uuml;zerinden akdedilen s&ouml;zleşmelerin koşulları, g&uuml;ncel
        durumu ve g&uuml;ncellemeler ile ilgili iletişime ge&ccedil;ilmesi ve
        gerekli bilgilendirmelerin yapılması,&uuml;yelik s&ouml;zleşmesinin
        kurulması,
      </p>
      <p>
        &bull; &Uuml;r&uuml;n ve hizmetlerimize ilişkin talep, şikayet ve
        &ouml;nerilerinin değerlendirilmesi,
      </p>
      <p>
        &bull; &Uuml;r&uuml;n ve hizmet satış sonrası destek hizmetlerinin
        y&uuml;r&uuml;t&uuml;lmesi,
      </p>
      <p>
        &bull; Bilgi g&uuml;venliği s&uuml;re&ccedil;lerinin
        y&uuml;r&uuml;t&uuml;lmesi,
      </p>
      <p>
        &bull; Rexven platformuna ilişkin olarak operasyonların
        g&uuml;venliğinin sağlanması,
      </p>
      <p>
        &bull; Faaliyetlerin mevzuata uygun y&uuml;r&uuml;t&uuml;lmesinin
        sağlanması,
      </p>
      <p>&bull; Hukuk işlerinin takibi ve y&uuml;r&uuml;t&uuml;lmesi,</p>
      <p>&bull; Yetkili kişi, kurum ve kuruluşlara bilgi verilmesi.</p>
      <p>
        İşlem g&uuml;venliği bilgileri;&nbsp;bizzat sizlerden, mobil uygulama ya
        da internet sitesinden elektronik ortamda otomatik olarak aşağıdaki
        ama&ccedil;larla toplanmakta ve işlenmektedir.
      </p>
      <p>
        &bull; S&ouml;zleşmenin kurulması ve ifasına y&ouml;nelik
        s&uuml;re&ccedil;lerin y&uuml;r&uuml;t&uuml;lmesi,
      </p>
      <p>
        &bull; Şirketin iş faaliyetlerinin y&uuml;r&uuml;t&uuml;lmesi ve
        denetimi,
      </p>
      <p>
        &bull; İnternet sitesi/ mobil uygulamalar &uuml;zerinden satın alım
        işlemlerinin ger&ccedil;ekleşmesi ve işlem yapanın kimlik bilgilerinin
        teyit edilmesi,
      </p>
      <p>
        &bull; Şirketimizin sunduğu &uuml;r&uuml;n ve hizmetlerin geliştirilmesi
        ve iyileştirilmesine y&ouml;nelik faaliyetlerin
        y&uuml;r&uuml;t&uuml;lmesi,
      </p>
      <p>
        &bull; Bilgi g&uuml;venliği s&uuml;re&ccedil;lerinin
        y&uuml;r&uuml;t&uuml;lmesi,
      </p>
      <p>
        &bull; Rexven platformuna ilişkin olarak operasyonların
        g&uuml;venliğinin sağlanması,
      </p>
      <p>
        &bull; Faaliyetlerin mevzuata uygun y&uuml;r&uuml;t&uuml;lmesinin
        sağlanması,
      </p>
      <p>&bull; Yetkili kişi, kurum ve kuruluşlara bilgi verilmesi.</p>
      <p>
        Hukuki işlem bilgileri;&nbsp;bizzat &uuml;yelerden, mobil uygulama,
        internet sitesinden elektronik ortamda otomatik olarak aşağıdaki
        ama&ccedil;larla toplanmakta ve işlenmektedir.
      </p>
      <p>
        &bull; S&ouml;zleşmenin kurulması ve ifasına y&ouml;nelik
        s&uuml;re&ccedil;lerin y&uuml;r&uuml;t&uuml;lmesi,
      </p>
      <p>
        &bull; Şirketin iş faaliyetlerinin y&uuml;r&uuml;t&uuml;lmesi ve
        denetimi,
      </p>
      <p>
        &bull; Şirketimizin sunduğu &uuml;r&uuml;n ve hizmetlerin geliştirilmesi
        ve iyileştirilmesine y&ouml;nelik faaliyetlerin
        y&uuml;r&uuml;t&uuml;lmesi,
      </p>
      <p>
        &bull; &Uuml;r&uuml;n ve hizmetlerimize ilişkin talep, şikayet ve
        &ouml;nerilerin değerlendirilmesi,
      </p>
      <p>
        &bull; Bilgi g&uuml;venliği s&uuml;re&ccedil;lerinin
        y&uuml;r&uuml;t&uuml;lmesi,
      </p>
      <p>
        &bull; Rexven platformuna ilişkin olarak operasyonların
        g&uuml;venliğinin sağlanması,
      </p>
      <p>
        &bull; Faaliyetlerin mevzuata uygun y&uuml;r&uuml;t&uuml;lmesinin
        sağlanması,
      </p>
      <p>&bull; Hukuk işlerinin takibi ve y&uuml;r&uuml;t&uuml;lmesi,</p>
      <p>&bull; Yetkili kişi, kurum ve kuruluşlara bilgi verilmesi.</p>
      <p>
        İşitsel kayıt bilgileri;&nbsp;&ccedil;ağrı merkeziyle iletişime
        ge&ccedil;meniz halinde aşağıdaki ama&ccedil;larla toplanmakta ve
        işlenmektedir.
      </p>
      <p>
        &bull; S&ouml;zleşmenin kurulması ve ifasına y&ouml;nelik
        s&uuml;re&ccedil;lerin y&uuml;r&uuml;t&uuml;lmesi,
      </p>
      <p>&bull; İletişim faaliyetlerinin y&uuml;r&uuml;t&uuml;lmesi,</p>
      <p>
        &bull; Şirketimizin sunduğu &uuml;r&uuml;n ve hizmetlerin geliştirilmesi
        ve iyileştirilmesine y&ouml;nelik faaliyetlerin
        y&uuml;r&uuml;t&uuml;lmesi,
      </p>
      <p>
        &bull; Mesafeli satış s&ouml;zleşmesi ve T&uuml;keticinin Korunması
        Hakkında Kanun&apos;un ilgili maddeleri gereğince platformumuz
        &uuml;zerinden akdedilen s&ouml;zleşmelerin koşulları, g&uuml;ncel
        durumu ve g&uuml;ncellemeler ile ilgili iletişime ge&ccedil;ilmesi ve
        gerekli bilgilendirmelerin yapılması,
      </p>
      <p>
        &bull; &Uuml;r&uuml;n ve hizmetlerimize ilişkin talep, şikayet ve
        &ouml;nerilerin değerlendirilmesi,
      </p>
      <p>
        &bull; Rexven platformuna ilişkin olarak operasyonların
        g&uuml;venliğinin sağlanması,
      </p>
      <p>
        &bull; Faaliyetlerin mevzuata uygun y&uuml;r&uuml;t&uuml;lmesinin
        sağlanması,
      </p>
      <p>&bull; Hukuk işlerinin takibi ve y&uuml;r&uuml;t&uuml;lmesi,</p>
      <p>&bull; Yetkili kişi, kurum ve kuruluşlara bilgi verilmesi.</p>
      <p>&nbsp;</p>
      <p>
        &nbsp; &nbsp; c) Kişisel verilerin işlenmesinin hukuki sebebi nedir ?
      </p>
      <p>&nbsp;</p>
      <p>
        Rexven, &ldquo;Rexven tarafından hangi kişisel veriler
        işlenmektedir?&rdquo; başlığında yer alan kişisel veriler yukarıda yer
        alan ama&ccedil;larla KVKK&rsquo;nın 5, 8. ve 9. maddelerinde belirtilen
        ve aşağıda yer verilen hukuka uygunluk sebeplerine dayanılarak
        işlenmektedir.
      </p>
      <p>
        &bull;&nbsp;6563 Elektronik Ticaretin D&uuml;zenlenmesi Hakkında Kanun,
        6102 Sayılı T&uuml;rk Ticaret Kanunu, 5237 Sayılı T&uuml;rk Ceza Kanunu
        ve 6502 sayılı T&uuml;keticinin Korunması Hakkında Kanun başta olmak
        &uuml;zere Şirketimizin tabi olduğu mevzuatta a&ccedil;ık&ccedil;a
        &ouml;ng&ouml;r&uuml;lm&uuml;ş olması hukuki sebebine
        dayanarak;&nbsp;şirket platformunda yer alan operasyonların
        g&uuml;venliğinin sağlanması, bilgi g&uuml;venliği
        s&uuml;re&ccedil;lerinin y&uuml;r&uuml;t&uuml;lmesi, faaliyetlerin
        mevzuata uygun y&uuml;r&uuml;t&uuml;lmesinin sağlanmasına y&ouml;nelik
        faaliyetler başta olmak &uuml;zere mevzuattan kaynaklanan
        y&uuml;k&uuml;ml&uuml;l&uuml;klerin yerine getirilmesi,,
      </p>
      <p>
        &bull;&nbsp;S&ouml;zleşmenin kurulması veya ifasıyla doğrudan doğruya
        ilgili olması kaydıyla, kişisel verilerin işlenmesinin gerekli olması
        hukuki sebebine dayanarak;&nbsp;mesafeli satış s&ouml;zleşmesi gibi
        T&uuml;keticinin Korunması Hakkında Kanun&apos;un ilgili maddeleri
        kapsamında platformumuz &uuml;zerinden akdedilen s&ouml;zleşmelerin
        kurulması faaliyetlerinin y&uuml;r&uuml;t&uuml;lmesi, satın alım
        işlemlerinizin ger&ccedil;ekleştirilmesine y&ouml;nelik faaliyetlerin
        y&uuml;r&uuml;t&uuml;lmesi, şirketin iş faaliyetlerinin
        y&uuml;r&uuml;t&uuml;lmesi ve denetimi, &uuml;r&uuml;n ve hizmetlerimize
        ilişkin talep, şikayet ve &ouml;nerilerin değerlendirilmesi, finans ve
        muhasebe s&uuml;re&ccedil;lerinin y&uuml;r&uuml;t&uuml;lmesi ve
        denetimi, iletişim faaliyetlerinin y&uuml;r&uuml;t&uuml;lmesi,
      </p>
      <p>
        &bull;&nbsp;Şirketimizin hukuki
        y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml;n&uuml;n yerine getirebilmesi
        i&ccedil;in zorunlu olması hukuki sebebine dayanarak;&nbsp;Mesafeli
        S&ouml;zleşmeler Y&ouml;netmeliği ve Elektronik Ticarette Hizmet
        Sağlayıcı ve Aracı Hizmet Sağlayıcılar Hakkında Y&ouml;netmelik başta
        olmak &uuml;zere Şirketimizin tabii olduğu ilgili t&uuml;m mevzuatta yer
        alan ve/veya yetkili merciiler tarafından yayınlanan karar, kılavuz ve
        rehberlerde belirtilen hukuki y&uuml;k&uuml;ml&uuml;l&uuml;klerinin
        yerine getirilmesi, yetkili kişi, kurum ve kuruluşlara bilgi verilmesi,
        faaliyetlerin mevzuata uygun y&uuml;r&uuml;t&uuml;lmesinin sağlanması,
        hukuk işlerinin takibi ve y&uuml;r&uuml;t&uuml;lmesi, finans ve muhasebe
        işlerinin y&uuml;r&uuml;t&uuml;lmesi,
      </p>
      <p>
        &bull;&nbsp;Bir hakkın tesisi, kullanılması veya korunması i&ccedil;in
        veri işlemenin zorunlu olması hukuki sebebine dayanarak;&nbsp;hukuk ve
        dava işlerinin y&uuml;r&uuml;t&uuml;lmesi,
      </p>
      <p>
        &bull;&nbsp;Temel hak ve &ouml;zg&uuml;rl&uuml;klerine zarar vermemek
        kaydıyla şirketimizin meşru menfaatleri i&ccedil;in veri işlenmesinin
        zorunlu olması hukuki sebebine dayanarak;&nbsp;şirketimizin sunduğu
        &uuml;r&uuml;n ve hizmetlerin geliştirilmesi ve iyileştirilmesine
        y&ouml;nelik faaliyetlerin y&uuml;r&uuml;t&uuml;lmesi,
      </p>
      <p>
        &bull;&nbsp;A&ccedil;ık rızanızın bulunması hukuki sebebine
        dayanarak;&nbsp;yurtdışına kişisel veri aktarımı yapılması.
      </p>
      <p>&nbsp;</p>
      <p>
        &nbsp; &nbsp; d) Rexven kişisel verilerinizi kimlere hangi sebeplerle
        aktarıyor?
      </p>
      <p>&nbsp;</p>
      <p>
        Şirketimiz, kişisel verilerinizi &ldquo;bilme gereği&rdquo; ve
        &ldquo;kullanma gereği&rdquo; ilkelerine uygun olarak, sadece gerekli
        veri bilgisine ulaşmayı ama&ccedil;layarak ve gerekli teknik ve idari
        g&uuml;venlik tedbirlerini alarak işlemeye &ouml;zen
        g&ouml;stermektedir. İş faaliyetlerinin y&uuml;r&uuml;t&uuml;lmesi veya
        denetimi, iş s&uuml;rekliliğinin sağlanması, dijital altyapıların
        işletilmesi farklı paydaşlarla s&uuml;rekli veri akışını zaruri kıldığı
        i&ccedil;in işlediğimiz kişisel verileri belirli ama&ccedil;larla
        &uuml;&ccedil;&uuml;nc&uuml; kişilere aktarmak durumundayız. Ayrıca,
        s&ouml;zleşmesel ve kanuni y&uuml;k&uuml;ml&uuml;l&uuml;klerimizi tam ve
        gereği gibi yerine getirebilmemiz i&ccedil;in kişisel verilerinizin
        doğru ve g&uuml;ncel olması &ccedil;ok &ouml;nemlidir. Bunun i&ccedil;in
        de muhtelif iş ortaklarıyla ve hizmet sağlayıcılarla &ccedil;alışmak
        durumundayız.
      </p>
      <p>
        Kişisel verileriniz, pazaryeri operasyonun y&uuml;r&uuml;t&uuml;lmesi,
        sipariş verilen &uuml;r&uuml;nlerin teslimat s&uuml;re&ccedil;lerinin
        y&uuml;r&uuml;t&uuml;lmesi, t&uuml;m s&uuml;re&ccedil;lere y&ouml;nelik
        yardım ve destek taleplerinin y&ouml;netilmesi, deneyimin ve
        memnuniyetin arttırılmasına y&ouml;nelik faaliyetlerin
        y&uuml;r&uuml;t&uuml;lmesi, şirketimizin sunduğu &uuml;r&uuml;n ve
        hizmetlerin geliştirilmesi ve iyileştirilmesine y&ouml;nelik
        faaliyetlerin y&uuml;r&uuml;t&uuml;lmesi, &uuml;yelere daha iyi bir
        alışveriş deneyiminin sağlanması ve memnuniyetin arttırılmasına
        y&ouml;nelik faaliyetlerin y&uuml;r&uuml;t&uuml;lmesi, iş
        s&uuml;rekliliğinin sağlanması, bilgi g&uuml;venliğinin sağlanması,
        kanuni y&uuml;k&uuml;ml&uuml;l&uuml;klerin yerine getirilmesi
        ama&ccedil;ları başta olmak &uuml;zere ve işbu aydınlatma metninin
        ikinci maddesinde belirtilen ama&ccedil;larla sınırlı olarak gerekli
        olduğu &ouml;l&ccedil;&uuml;de:
      </p>
      <p>
        Şirketimizin tabi olduğu mevzuatta a&ccedil;ık&ccedil;a
        &ouml;ng&ouml;r&uuml;lm&uuml;ş olması ve hukuki
        y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml;n&uuml;n yerine getirebilmesi
        i&ccedil;in zorunlu olması, s&ouml;zleşmenin kurulması veya ifası
        i&ccedil;in gerekli olması hukuki sebeplerine dayanarak;
      </p>
      <p>
        &bull; Satın alım s&uuml;re&ccedil;lerinin y&uuml;r&uuml;t&uuml;lmesi
        amacıyla Rexven&rsquo;in iştirakleriyle ve ilgili yurti&ccedil;i ve
        yurtdışı şirketleri ile,
      </p>
      <p>
        &bull; Faturalandırma s&uuml;re&ccedil;lerinin
        y&uuml;r&uuml;t&uuml;lmesi amacıyla iş ortaklarımız, danışmanlarımız ve
        hizmet sağlayıcılarımızla, bankalarla, mali m&uuml;şavirlerimizle,
      </p>
      <p>
        &bull; &Uuml;r&uuml;n ve hizmet satış ve satış sonrası destek
        hizmetlerinin sunulması i&ccedil;in &ccedil;ağrı merkezi hizmeti sunan
        iş ortakları ve diğer hizmet sağlayıcılarıyla,
      </p>
      <p>
        &bull; Hizmetlerin kalite kontrol, şik&acirc;yet y&ouml;netimi ve risk
        analizi alanında hizmet sunan iş ortakları ve hizmet sağlayıcılarıyla,
      </p>
      <p>
        &bull; Finansal ve muhasebe s&uuml;re&ccedil;lerinin y&ouml;netimi,
        risklerin tespiti, değerlendirilmesi, dolandırıcılıkların &ouml;nlenmesi
        i&ccedil;in ilgili iş ortaklarımız, danışmanlarımız ve hizmet
        sağlayıcılarımızla, bankalarla, mali m&uuml;şavirlerimizle,
      </p>
      <p>
        &bull; M&uuml;şteriye e-faturasının elektronik olarak g&ouml;nderilmesi
        i&ccedil;in e-fatura iş ortağıyla; fiziki s&ouml;zleşme veya fatura
        teslimatı yapılabilmesi amacıyla kargo ve kurye firmalarıyla, &ouml;zel
        entegrat&ouml;r, bağımsız denetim, g&uuml;mr&uuml;k, mali
        m&uuml;şavir/muhasebe hizmeti sunan iş ortaklarımızla,
      </p>
      <p>
        &bull; Vergisel y&uuml;k&uuml;ml&uuml;l&uuml;klerin yerine getirilmesi
        i&ccedil;in vergi daireleriyle, vergi denetimleri sırasında fatura ve
        tahsilat makbuzlarının T.C. Hazine ve Maliye Bakanlığı yetkilileriyle,
      </p>
      <p>
        &bull; Bilişim altyapımızı sağlayan, işleten veya hizmet sunan iş
        ortaklarımızla ve hizmet sağlayıcılarımızla,
      </p>
      <p>
        &bull; Risk y&ouml;netimi ve finansal raporlama s&uuml;re&ccedil;lerinin
        y&uuml;r&uuml;t&uuml;lmesi alanında hizmet sunan iş ortaklarımızla,
      </p>
      <p>
        Bir hakkın tesisi, kullanılması veya korunması i&ccedil;in veri
        işlemenin zorunlu olması, şirketimizin tabi olduğu mevzuatta
        a&ccedil;ık&ccedil;a &ouml;ng&ouml;r&uuml;lm&uuml;ş olması ve hukuki
        y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml;n&uuml;n yerine getirebilmesi
        i&ccedil;in zorunlu olması hukuki sebeplerine dayanarak;
      </p>
      <p>
        &bull; Hukuki y&uuml;k&uuml;ml&uuml;l&uuml;klerin yerine getirilmesi
        kapsamında avukatlar, denet&ccedil;iler, adli bilişim uzmanları, siber
        g&uuml;venlik danışmanları, vergi danışmanları ile danışmanlık ve hizmet
        aldığımız diğer &uuml;&ccedil;&uuml;nc&uuml; kişilerle ve iş
        ortaklarıyla,
      </p>
      <p>
        &bull; D&uuml;zenleyici ve denetleyici kurumlar ile mahkeme ve icra
        m&uuml;d&uuml;rl&uuml;kleri gibi yetkili kamu kurum ve kuruluşlarıyla,
      </p>
      <p>
        &bull; Kişisel verilerinizi talep etmeye yetkili olan diğer kamu kurum
        veya kuruluşlarıyla, yurti&ccedil;i ve/veya yurtdışında bulunan
        iştiraklerimizle, tedarik&ccedil;ilerimizle, iş ortaklarımızla,
        anlaşmalı olduğumuz bankalarla ve &uuml;r&uuml;n veya hizmet aldığımız
        &uuml;&ccedil;&uuml;nc&uuml; kişilerle paylaşılabilmektedir.
      </p>
      <p>&nbsp;</p>
      <p className="font-medium">B) KİŞİSEL VERİLERİNİZİN KORUNMASI&nbsp;</p>
      <p>
        <br />
      </p>
      <p>
        1- Rexven, y&uuml;r&uuml;rl&uuml;kteki ilgili mevzuat
        h&uuml;k&uuml;mleri gereğince verilerin gizliliğini sağlamak,
        &nbsp;b&uuml;t&uuml;nl&uuml;ğ&uuml;n&uuml; korumak ve bu konuda teknik
        &ouml;nlemleri almak konusunda veri sorumlusu sıfatıyla sorumludur.
      </p>
      <p>&nbsp;</p>
      <p>
        &bull; Kişisel verileriniz, veri tabanlarımızda uluslararası
        standartlara uygun olarak şifreli şekilde kayıtlı tutulmaktadır.
      </p>
      <p>
        &bull; Web sitesi ve mobil site aracılığıyla Rexven&rsquo;e ilettiğiniz
        kişisel verileriniz SSL (Secure Sockets Layer) teknolojisi kullanılarak
        korunmaktadır.
      </p>
      <p>
        &bull; Kişisel veri işleme faaliyetlerine ilişkin d&uuml;zenli olarak
        risk analizleri yapılmakta ve risklerin azaltılması i&ccedil;in
        aksiyonlar alınmaktadır.
      </p>
      <p>
        &bull; Kişisel verilere yetkisiz erişimleri engellemek i&ccedil;in
        erişim ve yetki kontrolleri uygulanmaktadır.
      </p>
      <p>
        &bull; Bu kapsamda veri işleme politikalarımızı her zaman
        g&uuml;ncellediğimizi bilginize sunarız.
      </p>
      <p>&nbsp;</p>
      <p>2- &Uuml;yelerimizin Hakları</p>
      <p>&nbsp;</p>
      <p>
        Bu Aydınlatma Metni&rsquo;nin &quot;Hak ve Talepleriniz İ&ccedil;in
        İletişim&quot; b&ouml;l&uuml;m&uuml;nde yer alan y&ouml;ntemlerle
        Şirketimize başvurarak,
      </p>
      <p>&nbsp;</p>
      <p>&bull; Kişisel verilerinizin işlenip işlenmediğini &ouml;ğrenme,</p>
      <p>&bull; İşlenmişse buna ilişkin bilgi talep etme,</p>
      <p>
        &bull; Kişisel verilerinizin işlenme amacını ve bunların amacına uygun
        kullanılıp kullanılmadığını &ouml;ğrenme,
      </p>
      <p>
        &bull; Yurt i&ccedil;inde veya yurt dışında aktarıldığı
        &uuml;&ccedil;&uuml;nc&uuml; kişileri bilme,
      </p>
      <p>
        &bull; Kişisel verilerin eksik veya yanlış işlenmiş olması halinde
        bunların d&uuml;zeltilmesini isteme,
      </p>
      <p>
        &bull; KVKK&rsquo;da &ouml;ng&ouml;r&uuml;len şartlar
        &ccedil;er&ccedil;evesinde kişisel verilerinizin silinmesini veya yok
        edilmesini isteme,
      </p>
      <p>
        &bull; Yukarıda belirtilen d&uuml;zeltme, silinme ve yok edilme
        şeklindeki haklarınız uyarınca yapılan işlemlerin, kişisel verilerin
        aktarıldığı &uuml;&ccedil;&uuml;nc&uuml; kişilere bildirilmesini isteme,
      </p>
      <p>
        &bull; İşlenen kişisel verilerinizin m&uuml;nhasıran otomatik sistemler
        ile analiz edilmesi sureti ile aleyhinize bir sonucun ortaya
        &ccedil;ıkmasına itiraz etme,
      </p>
      <p>
        &bull; Kişisel verilerinizin ilgili mevzuata aykırı olarak işlenmesi
        sebebiyle zarara uğramanız h&acirc;linde zararınızın giderilmesini talep
        etme haklarına sahipsiniz.
      </p>
      <p>&nbsp;</p>
      <p>3- Bizimle Nasıl İletişime Ge&ccedil;ebilirsiniz?</p>
      <p>&nbsp;</p>
      <p>
        Şirket tarafından işlenen kişisel verilerinize ilişkin olarak
        KVKK&rsquo;nın 11. maddesi kapsamındaki taleplerinizi; Veri Sorumlusuna
        Başvuru Usul ve Esasları Hakkında Tebliğ&rsquo;e uygun şekilde yazılı
        olarak ya da kayıtlı elektronik posta (KEP) adresi, g&uuml;venli
        elektronik imza, mobil imza ya da Firma&rsquo;ya daha &ouml;nce
        bildirdiğiniz ve sistemimizde kayıtlı bulunan elektronik posta
        adresinizi kullanarak aşağıdaki adreslere her zaman iletebilirsiniz.
      </p>
      <p>
        <br />
      </p>
      <p>Veri Sorumlusu: Rexven Teknoloji A.Ş.</p>
      <p>
        <br />
      </p>
      <p>E-posta: info@rexven.com</p>
      <p>
        <br />
      </p>
      <p>
        Adres: Mutlukent Mah. 2041 Sok. No :5/1
        &Ccedil;ankaya/Ankara
      </p>
      <p>
        <br />
      </p>
      <p>Tel: 0850 532 35 76</p>
      <p>
        <br />
      </p>
      <p>
        *&quot;Şahsen Yazılı Başvuru&quot; yoluna başvuracak İlgili Kişilerin
        kimliğini tevsik edici belgeleri ibr/az etmesi zorunludur. Bu bağlamda
        Mutlukent Mah. 2041 Sok. No :5/1 &Ccedil;ankaya/Ankara
        adresine yapacağınız şahsen yazılı başvurularda başvuru formuyla
        birlikte kimlik fotokopisinin yalnızca &ouml;n y&uuml;z&uuml;n&uuml;n
        (kan grubu ve din hanesi g&ouml;z&uuml;kmeyecek şekilde) ibr/az
        edilmesini rica ederiz
      </p>
      <p>
        <br />
      </p>
      <p>
        **Başvuru formunun tebligat zarfına veya e-postanın konu kısmına
        &quot;Kişisel Verilerin Korunması Kanunu İlgili Kişi Talebi&quot;
        yazılmasını rica ederiz.
      </p>
      <p>
        Kişisel veri sahipleri olarak, haklarınıza ilişkin taleplerinizi,
        Rexven&rsquo;e iletmeniz durumunda Rexven talebinizi en ge&ccedil; 30
        (otuz) g&uuml;n i&ccedil;inde &uuml;cretsiz olarak
        sonu&ccedil;landıracaktır. Ancak, başvurunuza verilecek yanıtın mevzuat
        uyarınca bir maliyet gerektirmesi halinde Rexven tarafından Kişisel
        Verileri Koruma Kurulu tarafından belirlenen tarifedeki &uuml;cret talep
        edilebilecektir.
      </p>
      <p>&nbsp;</p>
      <p>AYDINLATMA METNİ HAKKINDA</p>
      <p>&nbsp;</p>
      <p>
        Rexven işbu Kişisel Verilerin Korunmasına İlişkin Aydınlatma Metnini
        y&uuml;r&uuml;rl&uuml;kteki mevzuatta yapılabilecek değişiklikler
        &ccedil;er&ccedil;evesinde her zaman g&uuml;ncelleme hakkını saklı
        tutar.
      </p>
      <p>G&uuml;ncellenme Tarihi : 15.05.2023</p>
      <p>
        <br />
      </p>
      <p>
        <br />
      </p>
    </div>
  );
};

export default KVKK;
