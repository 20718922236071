import React, { createContext, useContext, useEffect, useState } from 'react';

import authService from '../services/authService';
import { useAuth } from './AuthContext';

export interface NumberVerificationContextInterface {
  children?: React.ReactNode;
  numVerifyModal?: boolean;
  sixDigitVerifyModal?: boolean;
  verifySuccessModal?: boolean;
  gsmNumber?: number;
  isCodeSend?: boolean;
  minutes?: number;
  seconds?: number;
  openSurvey?: boolean;
  numVerifyModalError?: string;
  sixDigitVerifyModalError?: string;
  loading?: boolean;
  setOpenSurvey?: React.Dispatch<React.SetStateAction<boolean>>;
  setNumVerifyModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setSixDigitVerifyModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setVerifySuccessModal?: React.Dispatch<React.SetStateAction<boolean>>;
  setMinutes?: React.Dispatch<React.SetStateAction<number>>;
  setSeconds?: React.Dispatch<React.SetStateAction<number>>;
  //NOTE: eventten soru işaretini kaldır
  // handleActiveModal?: (event?: any, returnFunction?: (values?: any) => any) => void
  handleActiveModal?: (event?: any, frIntegration?: boolean) => void;
  sendGsmNumber?: (value: any, page: string) => void;
  sendVerificationCode?: (
    code: { a: number; b: number; c: number; d: number; e: number; f: number },
    page: string
  ) => void;
}
const contextDefaultValues: NumberVerificationContextInterface = {
  numVerifyModal: false,
  sixDigitVerifyModal: false,
  verifySuccessModal: false,
  gsmNumber: +905555555555,
  isCodeSend: false,
  minutes: 0,
  seconds: 0,
  openSurvey: false,
  numVerifyModalError: '',
  sixDigitVerifyModalError: '',
  loading: false,
  setOpenSurvey: () => {},
  setNumVerifyModal: () => {},
  setSixDigitVerifyModal: () => {},
  setVerifySuccessModal: () => {},
  setMinutes: () => {},
  setSeconds: () => {},
  handleActiveModal: () => {},
  sendGsmNumber: (value: any) => {},
  sendVerificationCode: (
    code: { a: number; b: number; c: number; d: number; e: number; f: number },
    page: string
  ) => {},
};

const NumberVerificationContext = createContext(contextDefaultValues);
const useNumberVerification = () => {
  return useContext(NumberVerificationContext);
};
export const NumberVerificationProvider: React.FC<
  NumberVerificationContextInterface
> = ({ children }) => {
  const { session } = useAuth();
  const [loading, setLoading] = useState(false);
  const [isCodeSend, setIsCodeSend] = useState<boolean>(false);
  const [numVerifyModal, setNumVerifyModal] = useState(false);
  const [numVerifyModalError, setNumVerifyModalError] = useState('');
  const [sixDigitVerifyModal, setSixDigitVerifyModal] = useState(false);
  const [sixDigitVerifyModalError, setSixDigitVerifyModalError] = useState('');

  const [verifySuccessModal, setVerifySuccessModal] = useState(false);

  const [minutes, setMinutes] = useState(2);
  const [seconds, setSeconds] = useState(59);

  const [gsmNumber, setGsmNumber] = useState();

  const [openSurvey, setOpenSurvey] = useState(false);

  const handleActiveModal = (event: any, frIntegration?: boolean) => {
    if (frIntegration && !session?.isGsmVerified) {
      if (!isCodeSend) {
        setNumVerifyModal?.(true);
      } else if (isCodeSend) {
        setSixDigitVerifyModal?.(true);
      }
    } else {
      if (
        (session?.hasPackage || session?.lifeTimeCourse) &&
        !session?.isInitialSurveyDone
      ) {
        event && event.preventDefault();
        setOpenSurvey?.(true);
      } else if (
        !session?.hasPackage &&
        !session?.lifeTimeCourse &&
        !session?.isGsmVerified
      ) {
        event.preventDefault();
        if (!isCodeSend) {
          setNumVerifyModal?.(true);
        } else if (isCodeSend) {
          setSixDigitVerifyModal?.(true);
        }
      } else {
        return;
      }
    }
  };

  //when survey closed sessipon will be updated to refresh user session

  const sendGsmNumber = (value: any, page: string) => {
    if (!isCodeSend) {
      setLoading(true);
      console.log(value);
      authService
        .sendGsmNumber({ ...value, page })
        .then(() => {
          setIsCodeSend(true);
          setTimeout(() => {
            setIsCodeSend(false);
            setMinutes(2);
            setSeconds(59);
          }, 179000);
          setGsmNumber(value);
          setNumVerifyModal(false);
          // setSixDigitVerifyModal(true);
          setOpenSurvey(true);
          setNumVerifyModalError('');
        })
        .catch((err) => {
          setNumVerifyModalError(err.response.data.message);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const sendVerificationCode = (
    code: { a: number; b: number; c: number; d: number; e: number; f: number },
    page: string
  ) => {
    setLoading(true);
    authService
      .sendVerificationCode({
        code: Number(
          Object.values(code).reduce(
            (acc, cur): string => (acc += cur.toString(10)),
            ''
          )
        ),
        page,
      })
      .then(() => {
        setNumVerifyModal(false);
        setSixDigitVerifyModal(false);
        setVerifySuccessModal(true);
      })
      .catch((err) => {
        setSixDigitVerifyModalError(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (isCodeSend) {
      let myInterval = setInterval(() => {
        if (seconds! > 0) {
          setSeconds?.(seconds! - 1);
        }
        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(myInterval);
          } else {
            setMinutes?.(minutes! - 1);
            setSeconds?.(59);
          }
        }
      }, 1000);
      return () => {
        clearInterval(myInterval);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCodeSend, seconds, minutes]);

  useEffect(() => {
    setNumVerifyModalError('');
    setSixDigitVerifyModalError('');
  }, [numVerifyModal, sixDigitVerifyModal]);
  return (
    <NumberVerificationContext.Provider
      value={{
        numVerifyModal,
        sixDigitVerifyModal,
        verifySuccessModal,
        gsmNumber,
        isCodeSend,
        minutes,
        seconds,
        openSurvey,
        numVerifyModalError,
        sixDigitVerifyModalError,
        loading,
        setNumVerifyModal,
        setSixDigitVerifyModal,
        setVerifySuccessModal,
        setMinutes,
        setSeconds,
        setOpenSurvey,
        handleActiveModal,
        sendGsmNumber,
        sendVerificationCode,
      }}
    >
      {children}
    </NumberVerificationContext.Provider>
  );
};

export { NumberVerificationContext, useNumberVerification };
