import { Lock1 } from 'iconsax-react';
import React, { useState } from 'react';

import { useAuth } from '../../context/AuthContext';
import shipentegraService from '../../services/shipentegraService';
import alertNotification from '../../utils/alertNotification';

const ShipEntegraLoginRegister = () => {
    const { session } = useAuth()
    const [isLoading, setIsLoading] = useState(false)
    const loginRegisterWithShipEntegra = (hasAccount: boolean) => {
        setIsLoading(true);
        shipentegraService
            .loginWithShipEntegra({ hasAccount })
            .then((res: any) => {
                // window.location.href = res.data;
                window.open(res.data, '_blank');
            })
            .catch((err: any) => {
                alertNotification('error', err.response.data.message);
            })
            .finally(() => {
                setIsLoading(false);
            });

    };
    // const loginWithShipEntegra = () => {
    //     shipentegraService
    //         .loginWithShipEntegra()
    //         .then((res: any) => {
    //             // window.location.href = res.data;
    //             window.open(res.data, '_blank');
    //         })
    //         .catch((err: any) => {
    //             alertNotification('error', err.response.data.message);
    //         })
    //         .finally(() => {
    //             setIsLoading(false);
    //         });
    // };
    return (
        <>
            {!session?.hasShipEntegraInt ?
                <div
                    className={`bg-white shadow-xl rounded-lg border-2 absolute top-1/3 h-[480px] z-40 w-[90%] md:w-[60%] md:left-[20%] md:w-[45%] md:left-[30%] lg:w-[40%] lg:left-[35%] xl:w-[30%] xl:left-[35%]   min-[2000px]:w-[25%] min-[2000px]:left-[35%] `}
                >
                    <div className="flex justify-center mt-12 mb-8">
                        <Lock1 size="80" color="#3C4790" variant="Bold" />
                    </div>
                    <p className="text-headerPrimary text-sm md:text-lg lg:text-lg px-4 mb-8 font-medium text-center">
                        Kargo entegrasyonunu yapmak için hali hazırda ShipEntegra hesabınız varsa giriş yapın, eğer hesabınız yoksa yeni bir hesap açın.                    </p>
                    <div className="flex flex-col items-center justify-center ">
                        <span className='text-xs text-rexRed text-center px-8'>
                            Giriş butonuna tıklamadan önce, ShipEntegra’da giriş yaptıysanız çıkış yapmanız gerekmektedir.
                        </span>
                        <button
                            onClick={() => loginRegisterWithShipEntegra(true)}
                            className={"flex justify-center items-center w-3/4 py-2 px-4 text-sm font-medium border-2 border-[#52b0f0] text-[#52b0f0] shadow-sm bg-[#f0f9ff] rounded-md mt-2 hover:shadow-md"}
                        >
                            <img src="/images/shipentegra.svg" alt="carrier" className="mr-2" />
                            Giriş Yap
                        </button>
                        <button
                            onClick={() => loginRegisterWithShipEntegra(false)}
                            className={"flex justify-center items-center w-3/4 py-2 px-4 text-sm font-medium border-2 border-[#52b0f0] text-white shadow-sm bg-[#52b0f0] rounded-md mt-2 hover:shadow-md"}
                        >
                            <img src="/images/shipentegra-white.svg" alt="carrier" className="mr-2" />
                            Kayıt Ol
                        </button>
                    </div>
                </div>
                : ''}
        </>
    )
}

export default ShipEntegraLoginRegister