import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import { BillingInfo } from '../billingInfo/AddresInfoCard';
import memberService from '../../services/memberService';
import alertNotification from '../../utils/alertNotification';
import { citiesTurkey, vatCities } from '../../utils/Cities';
import { useLanguage } from '../../context/LanguageContext';
import * as Yup from 'yup';
import { errorElement } from '../../utils/errorElement';
import Select, { SingleValue } from 'react-select';
import { ComponentType } from '../../pages/Payment';
import { useAuth } from '../../context/AuthContext';
import { ToastContainer } from 'react-toastify';
import { useParams } from 'react-router-dom';
import MailConfirmModal from './MailConfirmModal';

interface Props {
  setCurrentComponent: React.Dispatch<React.SetStateAction<ComponentType>>;
  setDisablePayment: React.Dispatch<React.SetStateAction<boolean>>;
  country: string;
  currentComponent: ComponentType;
}

const PaymentBillingInfo: React.FC<Props> = ({
  setCurrentComponent,
  setDisablePayment,
  country,
  currentComponent,
}) => {
  const { t } = useLanguage();
  const { session } = useAuth();
  const params = useParams();
  const [open, setOpen] = useState(false);

  const [billingInfo, setBillingInfo] = useState<BillingInfo>({
    addressLine1: '',
    addressLine2: undefined,
    city: '',
    companyName: '',
    country: '',
    firstName: '',
    gsmNumber: '',
    identityNumber: '',
    isCorporate: false,
    lastName: '',
    taxOffice: '',
    title: '',
    vatNumber: '',
    zipCode: '',
  });

  const getBillingInfo = async () => {
    try {
      const res = await memberService.getBillingAddress();

      if (res.data.length > 0) {
        if (setDisablePayment !== undefined) {
          setDisablePayment(false);
        }

        setBillingInfo(res.data[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const regexName = /^[a-zA-ZŞşÇçıİÜüĞğÖö\s]*$/;
  const regexIdentity = /^[0-9]{11}$/;
  const regexGsm = /^[1-9][0-9\s]*$/;

  const onSubmit = (values: BillingInfo) => {
    let temp = { ...values, gsmNumber: `+90 ${values.gsmNumber}` };
    if (params.plan === '107' || params.plan === '106') {
      temp = {
        ...temp,
        addressLine1: temp.addressLine1 ? temp.addressLine1 : ' ',
        zipCode: temp.zipCode ? temp.zipCode : ' ',
        city: temp.city ? temp.city : ' ',
        country: temp.country ? temp.country : ' ',
      };
    }

    if (session) {
      memberService
        .updateBillingAddress(temp, true)
        .then((res) => {
          if (res.data.isVerified === true) {
            alertNotification('success', t?.('payment.billing.successMsg'));
            getBillingInfo();
            setCurrentComponent(ComponentType.payment);
          } else {
            alertNotification('error', t?.('payment.billing.errorMsg'));
            setOpen(true);
          }
        })
        .catch((err) => {
          alertNotification('error', err.response.data.message);
        });
    }
  };

  useEffect(() => {
    if (session?.id) {
      getBillingInfo();
    }
  }, [session?.id]);

  return (
    <div className="p-4 max-w-[570px] sm:min-h-[73vh]">
      <ToastContainer />
      <MailConfirmModal
        open={open}
        setOpen={setOpen}
        setCurrentComponent={setCurrentComponent}
        userId={Number(session?.id)}
        currentComponent={currentComponent}
      />
      <p className="font-bold text-xl text-primary mb-4">
        {t?.('payment.billing.title')}
      </p>

      <Formik
        initialValues={{
          title: billingInfo?.title,
          firstName: billingInfo?.firstName,
          lastName: billingInfo?.lastName,
          addressLine1: billingInfo?.addressLine1,
          addressLine2: billingInfo?.addressLine2,
          city: billingInfo?.city,
          country: billingInfo?.country,
          zipCode: billingInfo?.zipCode,
          gsmNumber: String(billingInfo?.gsmNumber).replace(/^(\+90)\s*/, ''),
          identityNumber: billingInfo?.identityNumber,
          companyName: billingInfo?.companyName,
          taxOffice: billingInfo?.taxOffice,
          vatNumber: billingInfo?.vatNumber,
          isCorporate: billingInfo?.isCorporate,
        }}
        enableReinitialize={true}
        onSubmit={onSubmit}
        validationSchema={Yup.object({
          title: Yup.string().required(t?.('validation.required')),
          firstName: Yup.string()
            .matches(regexName, t?.('validation.profile.profileUpdate'))
            .required(t?.('validation.required')),
          lastName: Yup.string()
            .matches(regexName, t?.('validation.profile.profileUpdate'))
            .required(t?.('validation.required')),
          addressLine1:
            country === 'TR'
              ? Yup.string().required(t?.('validation.required'))
              : Yup.string(),
          city:
            country === 'TR'
              ? Yup.string()
                  .matches(regexName, t?.('validation.profile.profileUpdate'))
                  .required(t?.('validation.required'))
              : Yup.string(),
          country:
            country === 'TR'
              ? Yup.string()
                  .matches(regexName, t?.('validation.profile.profileUpdate'))
                  .required(t?.('validation.required'))
              : Yup.string(),
          identityNumber:
            country === 'TR'
              ? Yup.string()
                  .matches(regexIdentity, t?.('validation.profile.identity'))
                  .required(t?.('validation.required'))
              : Yup.string(),
          gsmNumber:
            country === 'TR'
              ? Yup.string()
                  .matches(regexGsm, t?.('validation.profile.gsm'))
                  .required(t?.('validation.required'))
              : Yup.string(),
        })}
      >
        {({ handleSubmit, handleChange, setFieldValue, values, errors }) => (
          <form onSubmit={handleSubmit}>
            <div className="overflow-hidden">
              <div className="bg-white px-4 py-5 sm:p-6">
                <div className="grid grid-cols-6 gap-6">
                  <div className="col-span-6 ">
                    <label
                      htmlFor="title"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      {t?.('billingInfo.billing.billingForm.title')}
                    </label>
                    <input
                      value={values.title}
                      onChange={handleChange}
                      type="text"
                      name="title"
                      id="title"
                      className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  {errors.title && errorElement(errors.title)}
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="firstName"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      {t?.('billingInfo.billing.billingForm.name')}
                    </label>
                    <input
                      type="text"
                      value={values.firstName}
                      onChange={handleChange}
                      name="firstName"
                      id="firstName"
                      className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    {errors.firstName && errorElement(errors.firstName)}
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="lastName"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      {t?.('billingInfo.billing.billingForm.surname')}
                    </label>
                    <input
                      value={values.lastName}
                      onChange={handleChange}
                      type="text"
                      name="lastName"
                      id="lastName"
                      className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    {errors.lastName && errorElement(errors.lastName)}
                  </div>

                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="addressLine1"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      {t?.('billingInfo.billing.billingForm.adres1')}
                    </label>
                    <input
                      value={values.addressLine1}
                      onChange={handleChange}
                      type="text"
                      name="addressLine1"
                      id="addressLine1"
                      className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                    {errors.addressLine1 && errorElement(errors.addressLine1)}
                  </div>
                  <div className="col-span-6 sm:col-span-3">
                    <label
                      htmlFor="addressLine2"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      {t?.('billingInfo.billing.billingForm.adres2')}
                    </label>
                    <input
                      value={values.addressLine2 ? values.addressLine2 : ''}
                      onChange={handleChange}
                      type="text"
                      name="addressLine2"
                      id="addressLine"
                      className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                    <label
                      htmlFor="city"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      {t?.('billingInfo.billing.billingForm.city')}
                    </label>

                    {country === 'TR' ? (
                      <Select
                        options={citiesTurkey}
                        name="city"
                        placeholder="Seçiniz..."
                        onChange={(
                          newValue: SingleValue<{
                            label: string;
                            value: string;
                          }>
                        ) => {
                          setFieldValue('city', newValue?.value);
                        }}
                        className="mt-2 block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    ) : (
                      <input
                        value={values.city ? values.city : ''}
                        onChange={handleChange}
                        type="text"
                        name="city"
                        id="city"
                        className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                    )}
                  </div>

                  <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                    <label
                      htmlFor="country"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      {t?.('billingInfo.billing.billingForm.country')}
                    </label>
                    <input
                      value={values.country}
                      onChange={handleChange}
                      type="text"
                      name="country"
                      id="country"
                      className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>

                  <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                    <label
                      htmlFor="zipCode"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      {t?.('billingInfo.billing.billingForm.zipCode')}
                    </label>
                    <input
                      onChange={handleChange}
                      value={values.zipCode}
                      type="text"
                      name="zipCode"
                      id="zipCode"
                      className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    />
                  </div>
                  {country === 'TR' && (
                    <div className="col-span-6 sm:col-span-2">
                      <label
                        htmlFor="gsmNumber"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        {t?.('billingInfo.billing.billingForm.phone')}
                      </label>
                      <div className="flex justify-center items-center mt-2 py-1 px-2 w-full rounded-md  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 ">
                        <p className="text-sm">+90</p>
                        <input
                          value={values.gsmNumber}
                          onChange={handleChange}
                          type="text"
                          name="gsmNumber"
                          placeholder="555 555 55 55"
                          id="gsmNumber"
                          className="border-none h-7 w-full leading-3 sm:text-sm focus:ring-0"
                        />
                      </div>
                      {errors.gsmNumber && errorElement(errors.gsmNumber)}
                    </div>
                  )}
                  {country === 'TR' && (
                    <div className="col-span-6 sm:col-span-2">
                      <label
                        htmlFor="identityNumber"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        {t?.('billingInfo.billing.billingForm.identity')}
                      </label>
                      <input
                        onChange={handleChange}
                        value={values.identityNumber}
                        type="text"
                        name="identityNumber"
                        id="identityNumber"
                        className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                      />
                      {errors.identityNumber &&
                        errorElement(errors.identityNumber)}
                    </div>
                  )}
                  <div className="col-span-6 sm:col-span-2">
                    <label
                      htmlFor="isCorporate"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      {t?.('payment.billing.commercialInvoice')}
                    </label>
                    <input
                      onChange={handleChange}
                      checked={values.isCorporate}
                      type="checkbox"
                      name="isCorporate"
                      id="isCorporate"
                      className="mt-3 w-6 h-6 block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
                    />
                  </div>
                  {values.isCorporate && session?.location !== 'TR' && (
                    <>
                      <div className="col-span-6 sm:col-span-2">
                        <label
                          htmlFor="companyName"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          {t?.('billingInfo.billing.billingForm.company')}
                        </label>
                        <input
                          value={values.companyName}
                          onChange={handleChange}
                          type="text"
                          name="companyName"
                          id="companyName"
                          className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-2">
                        <label
                          htmlFor="taxOffice"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          {t?.('billingInfo.billing.billingForm.taxOffice')}
                        </label>
                        <Select
                          options={vatCities}
                          name="taxOffice"
                          placeholder="Seçiniz..."
                          onChange={(
                            newValue: SingleValue<{
                              label: string;
                              value: string;
                            }>
                          ) => {
                            setFieldValue('taxOffice', newValue?.value);
                          }}
                          className="mt-2 block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>

                      <div className="col-span-6 sm:col-span-2">
                        <label
                          htmlFor="vatNumber"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          {t?.('billingInfo.billing.billingForm.taxNumber')}
                        </label>
                        <input
                          onChange={handleChange}
                          value={values.vatNumber}
                          type="text"
                          name="vatNumber"
                          id="vatNumber"
                          className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </>
                  )}
                  {values.isCorporate && session?.location === 'TR' && (
                    <>
                      <div className="col-span-6 sm:col-span-2">
                        <label
                          htmlFor="companyName"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          {t?.('billingInfo.billing.billingForm.company')}
                        </label>
                        <input
                          value={values.companyName}
                          onChange={handleChange}
                          type="text"
                          name="companyName"
                          id="companyName"
                          className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-2">
                        <label
                          htmlFor="taxOffice"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          {t?.('billingInfo.billing.billingForm.taxOffice')}
                        </label>
                        <input
                          value={values.taxOffice}
                          onChange={handleChange}
                          type="text"
                          name="taxOffice"
                          id="taxOffice"
                          className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                      <div className="col-span-6 sm:col-span-2">
                        <label
                          htmlFor="vatNumber"
                          className="block text-sm font-medium leading-6 text-gray-900"
                        >
                          {t?.('billingInfo.billing.billingForm.taxNumber')}
                        </label>
                        <input
                          onChange={handleChange}
                          value={values.vatNumber}
                          type="text"
                          name="vatNumber"
                          id="vatNumber"
                          className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="px-4 py-3 text-right sm:px-6">
                <button
                  type="submit"
                  className="rounded-md bg-headerPrimary py-2.5 px-8 text-xs font-semibold text-white shadow-sm hover:bg-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 md:text-sm"
                >
                  Onayla ve Ödemeye Geç
                </button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default PaymentBillingInfo;
