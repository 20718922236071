import React, { useEffect, useState } from 'react';

import API_URL from '../../config';
import { useLanguage } from '../../context/LanguageContext';
import BookCard from './BookCard';

interface IBook {
  id: number;
  description: string;
  title: string;
  image: string;
  downloadLink: string;
}
const BooksCardsWrapper = () => {
  const booksTr = [
    // {
    //   id: 1,
    //   description:
    //     'E-Ticaret ve Amazon hakkında genel bilgileri, iş modellerini ve satışın temel aşamalarını ele alan 100 sayfalık akıcı bir kaynak.',
    //   title: "Amazon'da Yol Haritası E-Kitabı",
    //   image: '/images/3-book-amazon-yol-haritasi.webp',
    //   downloadLink: `${API_URL}books?name=amazon-100sf-ebook.pdf`,
    // },
    {
      id: 2,
      description:
        "Mehmet Tek'in Amazon hikayesi ve Amazon'da satış sürecini detaylara inerek ele aldığımız 218 sayfalık başucu kitabı.",
      title: 'Amazon Eğitimi E-Kitabı',
      image: '/images/1-book-amazon-egitimi.webp',
      downloadLink: `${API_URL}books?name=amazon-ebook2022.pdf`,
    },
    {
      id: 3,
      description:
        "Trendyol'da başarılı satıcı olmak isteyen ve satışlarını arttırmak isteyenlerin adım adım takip edebileceği bir kaynak.",
      title: "A'dan Z'ye Trendyol Eğitimi E-Kitabı",
      image: '/images/4-book-trendyol.webp',
      downloadLink: `${API_URL}books?name=trendyol-egitimi-ekitap.pdf`,
    },
    {
      id: 4,
      description:
        'Sağlıklı bir Etsy hesabı açabilmek adına dikkat etmeniz gereken aşamlar bu e-kitapta.',
      title: 'Etsy Hesap Kuralları Rehberi',
      image: '/images/2-book-etsy-hesap-kurallari.webp',
      downloadLink: `${API_URL}books?name=etsy-kurallari.pdf`,
    },
    {
      id: 5,
      description:
        'Etsy’de Satışın Tüm Detaylarını Konu Alan Bu Kitaba Ücretsiz Sahip Olabilirsin.',
      title: 'Etsy E-Kitabı',
      image: '/images/etsyebook.webp',
      downloadLink: `${API_URL}books?name=etsy-ebook.pdf`,
    },
  ];
  const booksEn = [
    {
      id: 1,
      description:
        'A 100-page comprehensive resource that covers general information, business models, and fundamental stages of selling in e-commerce and Amazon.',
      title: 'Amazon Roadmap E-Book',
      image: '/images/3-book-amazon-yol-haritasi.webp',
      downloadLink: `${API_URL}books?name=amazon-100sf-ebook.pdf`,
    },
    {
      id: 2,
      description:
        "A 218-page essential guide that explores Mehmet Tek's Amazon journey and delves into the details of the selling process on Amazon.",
      title: 'Amazon Training E-Book',
      image: '/images/1-book-amazon-egitimi.webp',
      downloadLink: `${API_URL}books?name=amazon-ebook2022.pdf`,
    },

    {
      id: 3,
      description:
        'Sağlıklı bir Etsy hesabı açabilmek adına dikkat etmeniz gereken aşamlar bu e-kitapta.',
      title: 'Etsy Account Health Guide',
      image: '/images/2-book-etsy-hesap-kurallari.webp',
      downloadLink: `${API_URL}books?name=etsy-kurallari.pdf`,
    },
    {
      id: 4,
      description:
        'The steps to pay attention to in order to open a healthy Etsy account are included in this e-book.',
      title: 'Etsy E-Book',
      image: '/images/etsyebook.webp',
      downloadLink: `${API_URL}books?name=etsy-ebook.pdf`,
    },
  ];
  const { language } = useLanguage();
  const [books, setBooks] = useState<IBook[]>();

  useEffect(() => {
    language === 'tr' ? setBooks(booksTr) : setBooks(booksEn);
  }, [language]);

  return (
    <div className="bg-white p-6 grid  gap-6 overflow-hidden text-center sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 rounded-2xl">
      {books?.map((book) => (
        <BookCard
          data-testid="book-card"
          key={book.id}
          id={book.id}
          description={book.description}
          imageLink={book.image}
          title={book.title}
          downloadLink={book.downloadLink}
        />
      ))}
    </div>
  );
};

export default BooksCardsWrapper;
