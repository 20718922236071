import React, { useState } from 'react';
import * as Yup from 'yup';
import { errorElement } from '../../../utils/errorElement';
import { useFormik } from 'formik';
import { useLanguage } from '../../../context/LanguageContext';

interface FilterProps {
  handleSubmit: (values: any) => void;
}

const ArbitrageFilter: React.FC<FilterProps> = ({ handleSubmit }) => {
  const { t } = useLanguage();
  const formik = useFormik({
    initialValues: {
      minSaleCount: '',
      maxSaleCount: '',
      minPrice: '',
      maxPrice: '',
      minRevenue: '',
      maxRevenue: '',
      minNetProfit: '',
      maxNetProfit: '',
      minProfitMargin: '',
      maxProfitMargin: '',
      minProfitRate: '',
      maxProfitRate: '',
      minRivalCount: '',
      maxRivalCount: '',
      minVariationCount: '',
      maxVariationCount: '',
    },
    onSubmit: handleSubmit,
    validationSchema: Yup.object().shape({
      minSaleCount: Yup.number().min(0, t?.('formFilter.neZero')),
      maxSaleCount: Yup.number().when(
        'minSaleCount',
        (min: any, schema) =>
          min && schema.min(min, t?.('formFilter.maxMinSale'))
      ),
      minPrice: Yup.number().min(0, t?.('formFilter.neZero')),
      maxPrice: Yup.number().when(
        'minPrice',
        (min: any, schema) => min && schema.min(min, t?.('formFilter.maxPrice'))
      ),
      minRevenue: Yup.number().min(0, t?.('formFilter.neZero')),
      maxRevenue: Yup.number().when(
        'minRevenue',
        (min: any, schema) =>
          min && schema.min(min, t?.('formFilter.maxRevenue'))
      ),
      minNetProfit: Yup.number().min(0, t?.('formFilter.neZero')),
      maxNetProfit: Yup.number().when(
        'minNetProfit',
        (min: any, schema) =>
          min && schema.min(min, t?.('formFilter.maxNetProfit'))
      ),
      minProfitMargin: Yup.number().min(0, t?.('formFilter.neZero')),
      maxProfitMargin: Yup.number().when(
        'minNetProfit',
        (min: any, schema) =>
          min && schema.min(min, t?.('formFilter.maxProfitMargin'))
      ),
      minProfitRate: Yup.number().min(0, t?.('formFilter.neZero')),
      maxProfitRate: Yup.number().when(
        'minProfitRate',
        (min: any, schema) =>
          min && schema.min(min, t?.('formFilter.maxProfitRate'))
      ),
      minRivalCount: Yup.number().min(0, t?.('formFilter.neZero')),
      maxRivalCount: Yup.number().when(
        'minRivalCount',
        (min: any, schema) =>
          min && schema.min(min, t?.('formFilter.maxRivalCount'))
      ),
      minVariationCount: Yup.number().min(0, t?.('formFilter.neZero')),
      maxVariationCount: Yup.number().when(
        'minVariationCount',
        (min: any, schema) =>
          min && schema.min(min, t?.('formFilter.maxVariantCount'))
      ),
    }),
  });
  return (
    <div className="bg-white rounded-xl mt-12  p-4 flex justify-center items-center">
      <form className="w-full flex flex-col" onSubmit={formik.handleSubmit}>
        <div className="w-full flex flex-col lg:flex-row items-center justify-evenly">
          <div className="w-full lg:w-[15%]">
            <div className="flex w-full pl-3 mb-3 lg:mb-0 flex-row lg:flex-col items-center justify-center lg:mr-6 lg:space-y-4 lg:border-r-2">
              <div className="flex justify-start items-center mr-2 space-x-3">
                <img
                  src="/images/usa.png"
                  alt="usa_flag"
                  className="border-2 w-[40%] object-contain border-[#1F254B] rounded-xl "
                />
                <p className="text-sm hidden lg:block">
                  {t?.('amazon-online-arbitrage.america')}
                </p>
              </div>
              <div className="flex justify-start items-center space-x-3 mr-2">
                <img
                  src="/images/kanada.png"
                  alt="usa_flag"
                  className="border-2 w-[40%] object-contain border-[#1F254B] rounded-xl "
                />
                <p className="text-sm  hidden lg:block">
                  {t?.('amazon-online-arbitrage.canada')}
                </p>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-[85%] grid grid-cols-12 gap-3 pl-4">
            <div className="col-span-12 md:col-span-3 xl:col-span-3">
              <div className="flex flex-col items-center mb-2">
                <label
                  className="w-full py-2 font-medium text-xs min-[1335px]:text-sm"
                  htmlFor="minSaleCount"
                >
                  {t?.('amazon-online-arbitrage.filter.monthlySales')}
                </label>
                <div className="flex w-full justify-between items-center space-x-2">
                  <input
                    name="minSaleCount"
                    id="minSaleCount"
                    onChange={formik.handleChange}
                    type="number"
                    value={formik.values.minSaleCount}
                    className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                    placeholder="Min"
                  />
                  <p>-</p>
                  <input
                    onChange={formik.handleChange}
                    name="maxSaleCount"
                    id="maxSaleCount"
                    value={formik.values.maxSaleCount}
                    type="number"
                    className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                    placeholder="Max"
                  />
                </div>
                {formik.errors.maxSaleCount &&
                  errorElement(formik.errors.maxSaleCount!)}
                {formik.errors.minSaleCount &&
                  errorElement(formik.errors.minSaleCount!)}
              </div>
            </div>
            <div className="col-span-12 md:col-span-3 xl:col-span-3">
              <div className="flex flex-col items-center mb-2">
                <label
                  className="w-full py-2 font-medium text-xs min-[1335px]:text-sm"
                  htmlFor="minRevenue"
                >
                  {t?.('amazon-online-arbitrage.filter.monthlyRevenue')}
                </label>
                <div className="flex w-full justify-between items-center space-x-2">
                  <input
                    name="minRevenue"
                    id="minRevenue"
                    onChange={formik.handleChange}
                    type="number"
                    value={formik.values.minRevenue}
                    className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                    placeholder="Min"
                  />
                  <p>-</p>
                  <input
                    onChange={formik.handleChange}
                    name="maxRevenue"
                    id="maxRevenue"
                    value={formik.values.maxRevenue}
                    type="number"
                    className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                    placeholder="Max"
                  />
                </div>
                {formik.errors.maxRevenue &&
                  errorElement(formik.errors.maxRevenue!)}
                {formik.errors.minRevenue &&
                  errorElement(formik.errors.minRevenue!)}
              </div>
            </div>
            <div className="col-span-12 md:col-span-3 xl:col-span-3">
              <div className="flex flex-col items-center mb-2">
                <label
                  className="w-full py-2 font-medium text-xs min-[1335px]:text-sm"
                  htmlFor="minPrice"
                >
                  {t?.('amazon-online-arbitrage.filter.price')}{' '}
                  {t?.('amazon-online-arbitrage.canada')}
                </label>
                <div className="flex w-full justify-between items-center space-x-2">
                  <input
                    name="minPrice"
                    id="minPrice"
                    onChange={formik.handleChange}
                    type="number"
                    value={formik.values.minPrice}
                    className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                    placeholder="Min"
                  />
                  <p>-</p>
                  <input
                    onChange={formik.handleChange}
                    name="maxPrice"
                    id="maxPrice"
                    value={formik.values.maxPrice}
                    type="number"
                    className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                    placeholder="Max"
                  />
                </div>
                {formik.errors.maxPrice &&
                  errorElement(formik.errors.maxPrice!)}
                {formik.errors.minPrice &&
                  errorElement(formik.errors.minPrice!)}
              </div>
            </div>
            <div className="col-span-12 md:col-span-3 xl:col-span-3">
              <div className="flex flex-col items-center mb-2">
                <label
                  className="w-full py-2 font-medium text-xs min-[1335px]:text-sm"
                  htmlFor="minNetProfit"
                >
                  {t?.('amazon-online-arbitrage.filter.netProfit')}
                </label>
                <div className="flex w-full justify-between items-center space-x-2">
                  <input
                    name="minNetProfit"
                    id="minNetProfit"
                    onChange={formik.handleChange}
                    type="number"
                    value={formik.values.minNetProfit}
                    className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                    placeholder="Min"
                  />
                  <p>-</p>
                  <input
                    onChange={formik.handleChange}
                    name="maxNetProfit"
                    id="maxNetProfit"
                    value={formik.values.maxNetProfit}
                    type="number"
                    className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                    placeholder="Max"
                  />
                </div>
                {formik.errors.maxNetProfit &&
                  errorElement(formik.errors.maxNetProfit!)}
                {formik.errors.minNetProfit &&
                  errorElement(formik.errors.minNetProfit!)}
              </div>
            </div>
            <div className="col-span-12 md:col-span-3 xl:col-span-3">
              <div className="flex flex-col items-center mb-2">
                <label
                  className="w-full py-2 font-medium text-xs min-[1335px]:text-sm"
                  htmlFor="minProfitMargin"
                >
                  {t?.('amazon-online-arbitrage.filter.profitMargin')}
                </label>
                <div className="flex w-full justify-between items-center space-x-2">
                  <input
                    name="minProfitMargin"
                    id="minProfitMargin"
                    onChange={formik.handleChange}
                    type="number"
                    value={formik.values.minProfitMargin}
                    className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                    placeholder="Min"
                  />
                  <p>-</p>
                  <input
                    onChange={formik.handleChange}
                    name="maxProfitMargin"
                    id="maxProfitMargin"
                    value={formik.values.maxProfitMargin}
                    type="number"
                    className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                    placeholder="Max"
                  />
                </div>
                {formik.errors.maxProfitMargin &&
                  errorElement(formik.errors.maxProfitMargin!)}
                {formik.errors.minProfitMargin &&
                  errorElement(formik.errors.minProfitMargin!)}
              </div>
            </div>
            <div className="col-span-12 md:col-span-3 xl:col-span-3">
              <div className="flex flex-col items-center mb-2">
                <label
                  className="w-full py-2 font-medium text-xs min-[1335px]:text-sm"
                  htmlFor="minProfitRate"
                >
                  {t?.('amazon-online-arbitrage.filter.profitRate')}
                </label>
                <div className="flex w-full justify-between items-center space-x-2">
                  <input
                    name="minProfitRate"
                    id="minProfitRate"
                    onChange={formik.handleChange}
                    type="number"
                    value={formik.values.minProfitRate}
                    className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                    placeholder="Min"
                  />
                  <p>-</p>
                  <input
                    onChange={formik.handleChange}
                    name="maxProfitRate"
                    id="maxProfitRate"
                    value={formik.values.maxProfitRate}
                    type="number"
                    className="w-full rounded-md border border-cardBorder py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                    placeholder="Max"
                  />
                </div>
                {formik.errors.maxProfitRate &&
                  errorElement(formik.errors.maxProfitRate!)}
                {formik.errors.minProfitRate &&
                  errorElement(formik.errors.minProfitRate!)}
              </div>
            </div>
            <div className="col-span-12 md:col-span-3 xl:col-span-3">
              <div className="flex flex-col items-center mb-2">
                <label
                  className="w-full py-2 font-medium text-xs min-[1335px]:text-sm"
                  htmlFor="minProfitRate"
                >
                  {t?.('amazon-online-arbitrage.filter.rivalCount')}
                </label>
                <div className="flex w-full justify-between items-center space-x-2">
                  <input
                    name="minRivalCount"
                    id="minRivalCount"
                    onChange={formik.handleChange}
                    type="number"
                    value={formik.values.minRivalCount}
                    className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                    placeholder="Min"
                  />
                  <p>-</p>
                  <input
                    onChange={formik.handleChange}
                    name="maxRivalCount"
                    id="maxRivalCount"
                    value={formik.values.maxRivalCount}
                    type="number"
                    className="w-full rounded-md border border-cardBorder py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                    placeholder="Max"
                  />
                </div>
                {formik.errors.maxRivalCount &&
                  errorElement(formik.errors.maxRivalCount!)}
                {formik.errors.minRivalCount &&
                  errorElement(formik.errors.minRivalCount!)}
              </div>
            </div>
            <div className="col-span-12 md:col-span-3 xl:col-span-3">
              <div className="flex flex-col items-center mb-2">
                <label
                  className="w-full py-2 font-medium text-xs min-[1335px]:text-sm"
                  htmlFor="minProfitRate"
                >
                  {t?.('amazon-online-arbitrage.filter.variation')}
                </label>
                <div className="flex w-full justify-between items-center space-x-2">
                  <input
                    name="minVariationCount"
                    id="minVariationCount"
                    onChange={formik.handleChange}
                    type="number"
                    value={formik.values.minVariationCount}
                    className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                    placeholder="Min"
                  />
                  <p>-</p>
                  <input
                    onChange={formik.handleChange}
                    name="maxVariationCount"
                    id="maxVariationCount"
                    value={formik.values.maxVariationCount}
                    type="number"
                    className="w-full rounded-md border border-cardBorder py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                    placeholder="Max"
                  />
                </div>
                {formik.errors.maxVariationCount &&
                  errorElement(formik.errors.maxVariationCount!)}
                {formik.errors.minVariationCount &&
                  errorElement(formik.errors.minVariationCount!)}
              </div>
            </div>
          </div>
        </div>
        <button
          type="submit"
          className="col-span-12 w-1-2 px-4 py-2 text-sm lg:w-3/12 lg:text-base my-2 mx-auto text-white font-bold rounded-md  bg-headerPrimary mt-4 flex items-center justify-center"
        >
          {t?.('amazon-online-arbitrage.filter.filter')}
        </button>
      </form>
    </div>
  );
};

export default ArbitrageFilter;
