import React, { Fragment, useEffect, useState } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

import aliexpressService, { AliChildCat, AliMainCat } from '../../services/aliExpressService';
import alertNotification from '../../utils/alertNotification';
import { errorElement } from '../../utils/errorElement';
import LoadingComponent from '../common/LoadingComponent';

interface IntegrationProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  productId: number;
}
interface Select {
  name: string;
  value: number | string;
  categories: AliChildCat[];
}
const IntegrationModal: React.FC<IntegrationProps> = ({
  open,
  setOpen,
  productId,
}) => {
  const [mainCats, setMainCats] = useState<AliMainCat[]>([]);
  const [childCat, setChildCat] = useState<AliChildCat[]>();
  const [selects, setSelects] = useState<Select[]>([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isDeepest, setIsDeepest] = useState(false);
  const [price, setPrice] = useState('');
  const [isValid, setIsValid] = useState(true);
  const [shipment, setShipment] = useState<number>();

  const getMainCats = async () => {
    const data = await aliexpressService.getMainCategories();

    setMainCats(data.data.categories);
  };

  //FIXME GEREKSIZ OLARABILIR
  const getChildCatService = async (categories: string[]) => {
    try {
      const res = await aliexpressService.getChildCategories(categories);
      return res.data.categories;
    } catch (error) {
      return;
    }
  };

  function validatePrice(value: string) {
    var pattern = /^[0-9]+(\.[0-9]+)?$/;
    return pattern.test(value);
  }

  const handlePrice = (e: React.FormEvent<HTMLInputElement>) => {
    if (validatePrice(e.currentTarget.value)) {
      setIsValid(true);
      setPrice(e.currentTarget.value);
    } else {
      setIsValid(false);
    }
  };

  const getChildCats = async (
    categories: string[],
    cat: string,
    selected: number
  ) => {
    setIsLoading(true);

    try {
      const batchNum = 20;
      const divider = Math.ceil(categories.length / 20);
      const dividedCats = [];

      for (let i = 0; i < divider; i++) {
        dividedCats.push(categories.slice(i * batchNum, (i + 1) * batchNum));
      }

      const foo = await Promise.all(
        dividedCats.map((cats) => aliexpressService.getChildCategories(cats))
      );

      const bar = foo.reduce((acc, cur) => {
        acc.push(...cur.data.categories);
        return acc;
      }, []);

      setChildCat(bar);

      if (selected === 0) {
        setSelects([
          {
            name: `child_1`,
            value: cat,
            categories: bar,
          },
        ]);
      } else {
        let temp = selects.slice(0, selected);

        setSelects([
          ...temp,
          {
            name: `child_${selected + 1}`,
            value: cat,
            categories: bar,
          },
        ]);
      }
    } catch (error) {
      return;
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = async (e: React.FormEvent<HTMLSelectElement>) => {
    let cat: any;
    let selected: number;
    if (e.currentTarget.name === 'mainCategory') {
      cat = mainCats.find((item) => item.id === e.currentTarget.value);

      selected = 0;
    } else {
      let hebele = selects.find(
        (item) => item.name === e.currentTarget.name
      )?.categories;

      cat = hebele?.find((item) => item.id === e.currentTarget.value);

      selected = Number(e.currentTarget.name.split('_')[1]);
    }

    if (cat.children_ids.length > 0) {
      setIsDeepest(false);
      getChildCats(cat?.children_ids!, e.currentTarget.value, selected);
    } else {
      //önceden eklediği bir select varsa burada silinmesi lazım
      if (selected < selects.length) {
        let temp = selects.slice(selected - 1, selects.length - 1);
        setSelects(temp);
      }
      setIsDeepest(true);
    }
  };

  const integrateProduct = async () => {
    let id = Number(childCat?.pop()?.id);

    let payload = {
      categoryId: String(id),
      price: Number(price),
      productId: Number(productId),
      shippingTemplateId: Number(shipment!),
    };

    try {
      await aliexpressService.integrateToAliexpress(payload);
      alertNotification('success', 'Ürün entegrasyonu başarılı');
    } catch (error) {
      // alertNotification('error', 'Ürün entegrasyonu başarısız');
      return;
    }
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    integrateProduct();
    setSelects([]); // remove all child selects
    setIsDeepest(false); //hide price field

    setOpen(false);
  };

  const getShipment = async () => {
    try {
      const res = await aliexpressService.getShippingTemplates();

      setShipment(res.data.templates[0].template_id);
    } catch (error) {
      // alertNotification(
      //   'error',
      //   'Kargo taslakları getirilirken bir hata oluştu!'
      // );
    }
  };

  useEffect(() => {
    if (open) {
      getMainCats();
      getShipment();
    }
  }, [open]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          setOpen(false);
          setSelects([]); // remove all child selects
          setIsDeepest(false); //hide price field
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => {
                      setOpen(false);
                      setSelects([]); // remove all child selects
                      setIsDeepest(false); //hide price field
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Ürününüzü Aliexpress'e yüklemek için kategoriyi en alt
                        kategoriye kadar seçtiğinizden emin olun.
                      </p>
                    </div>
                  </div>
                </div>
                <form
                  onSubmit={handleSubmit}
                  className="flex flex-col justify-center items-center"
                >
                  <div className="mt-5 sm:mt-6">
                    <label htmlFor="mainCategory">Kategori Seçiniz</label>
                    <select
                      name="mainCategory"
                      id="mainCategory"
                      className="rounded-md mb-2 border-secondary p-1 w-full mt-1"
                      onChange={handleChange}
                    >
                      <option value="">Seçiniz</option>
                      {mainCats?.map((cat) => {
                        return (
                          <option key={cat.id} value={cat.id}>
                            {cat.name}
                          </option>
                        );
                      })}
                    </select>
                    {selects?.map((sel) => {
                      return (
                        <select
                          key={sel.value}
                          name={sel.name}
                          className="rounded-md mb-2 border-secondary p-1 w-full mt-1"
                          onChange={handleChange}
                        >
                          <option key={sel.name} value="">
                            Seçiniz
                          </option>
                          {sel?.categories?.map((cat) => {
                            return (
                              <option key={cat.id} value={cat.id}>
                                {cat.name}
                              </option>
                            );
                          })}
                        </select>
                      );
                    })}
                    {isLoading && <LoadingComponent />}
                    {isDeepest && (
                      <div>
                        <label htmlFor="price" className="w-1/3">
                          Satış Fiyatı
                        </label>
                        <input
                          onChange={handlePrice}
                          className="rounded-md mb-2 border-secondary p-1 w-full mt-1"
                          type="text"
                          name="price"
                          id="price"
                          placeholder="Ürün satış fiyatını TL olarak giriniz"
                        />
                      </div>
                    )}
                    {isDeepest &&
                      !isValid &&
                      errorElement('Lütfen geçerli bir fiyat giriniz')}
                    <div className="flex justify-center items-center">
                      <button
                        disabled={!isDeepest || !isValid}
                        type="submit"
                        className="border-2 border-primary text-primary rounded-lg py-1 px-2"
                      >
                        AliExpress'e Yükle
                      </button>
                    </div>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default IntegrationModal;
