import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { classNames } from '../../../../utils/conditionalClasses';
import { seperateNumberWithComma } from '../../../../utils/numberFormatter';

const tableHeader = [
  { id: uuidv4(), name: '1. Ay' },
  { id: uuidv4(), name: '2. Ay' },
  { id: uuidv4(), name: '3. Ay' },
  { id: uuidv4(), name: '4. Ay' },
  { id: uuidv4(), name: '5. Ay' },
  { id: uuidv4(), name: '6. Ay' },
  { id: uuidv4(), name: '7. Ay' },
  { id: uuidv4(), name: '8. Ay' },
  { id: uuidv4(), name: '9. Ay' },
  { id: uuidv4(), name: '10. Ay' },
  { id: uuidv4(), name: '11. Ay' },
  { id: uuidv4(), name: '12. Ay' },
  { id: uuidv4(), name: 'Toplam' },
];
interface Props {
  data: any;
  brandCost: number;
  companyCost: number;
  imageCost: number;
}
const ForecastTable: React.FC<Props> = ({
  data,
  brandCost,
  companyCost,
  imageCost,
}) => {
  const [selectedYear, setSelectedYear] = useState(0);

  return (
    <div className="rounded-xl shadow-lg bg-white mt-16 p-4">
      <p className="w-full text-center text-headerPrimary font-bold text-2xl">
        Nakit Akış Tablosu
      </p>
      <div className="flex justify-start items-center">
        <div className="flex space-x-4 ml-4">
          <button
            onClick={() => setSelectedYear(0)}
            className={classNames(
              selectedYear === 0
                ? 'bg-headerPrimary text-white'
                : 'bg-white text-headerPrimary',
              'mt-4 rounded-lg py-2.5 px-8 border border-headerPrimary text-xs font-semibold  shadow-sm  md:text-sm'
            )}
          >
            1.Yıl
          </button>
          <button
            onClick={() => setSelectedYear(1)}
            className={classNames(
              selectedYear === 1
                ? 'bg-headerPrimary text-white'
                : 'bg-white text-headerPrimary',
              'mt-4 rounded-lg py-2.5 px-8 border border-headerPrimary text-xs font-semibold  shadow-sm  md:text-sm'
            )}
          >
            2.Yıl
          </button>
        </div>
      </div>

      <div className="flex flex-col flex-shrink-0 bg-white rounded-xl my-8">
        {/* <div className="flow-root"> */}
        <div className="relative overflow-x-auto overflow-y-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="border-none rounded-lg">
              <table className="min-w-full table-fixed">
                <thead className="bg-headerPrimary rounded-t-lg">
                  <th
                    className="bg-headerPrimary py-6 pl-4 pr-3 text-center text-sm sticky top-0 first:rounded-tl-lg last:rounded-tr-lg font-semibold text-white"
                    scope="col"
                  >
                    {selectedYear + 1}. Yıl
                  </th>
                  {tableHeader.map((item: any) => {
                    return (
                      <th
                        key={item.id}
                        className="bg-headerPrimary py-6 pl-4 pr-3 text-center text-sm sticky top-0 first:rounded-tl-lg last:rounded-tr-lg font-semibold text-white"
                        scope="col"
                      >
                        <p className="text-xs md:text-sm">{item.name}</p>
                      </th>
                    );
                  })}
                </thead>

                <tbody className="divide-y divide-gray-200 bg-white">
                  {data[selectedYear].map((array: any, idx: number) => {
                    if (
                      idx === data[selectedYear].length - 1 ||
                      idx === data[selectedYear].length - 2
                    )
                      return;
                    return (
                      <tr key={uuidv4()} className="even:bg-[#EDEEF8]">
                        {array.map((item: any, index: number) => {
                          return (
                            <td className="px-4 py-4 text-xs md:text-sm text-center text-gray-500 ">
                              <div
                                className={classNames(
                                  index === 0 ? 'w-32' : '',
                                  ' flex justify-center items-center space-x-2 h-full '
                                )}
                              >
                                {item === 0 ? (
                                  '-'
                                ) : (
                                  <p>
                                    {idx > 0 && index !== 0 ? '$' : ''}
                                    {typeof item === 'number' && idx !== 0
                                      ? seperateNumberWithComma(
                                          Number(item.toFixed(2))
                                        )
                                      : item}
                                  </p>
                                )}
                              </div>
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                  <tr className="even:bg-[#EDEEF8]">
                    <td className="px-4 py-4 text-xs md:text-sm  text-gray-500 text-center">
                      Marka Tescili
                    </td>
                    <td className="px-4 py-4 text-xs md:text-sm text-center text-gray-500">
                      <p>${selectedYear === 0 ? brandCost : '-'}</p>
                    </td>
                    <td className="text-center text-xs md:text-sm  text-gray-500">
                      -
                    </td>
                    <td className="text-center text-xs md:text-sm  text-gray-500">
                      -
                    </td>
                    <td className="text-center text-xs md:text-sm  text-gray-500">
                      -
                    </td>
                    <td className="text-center text-xs md:text-sm  text-gray-500">
                      -
                    </td>
                    <td className="text-center text-xs md:text-sm  text-gray-500">
                      -
                    </td>
                    <td className="text-center text-xs md:text-sm  text-gray-500">
                      -
                    </td>
                    <td className="text-center text-xs md:text-sm  text-gray-500">
                      -
                    </td>
                    <td className="text-center text-xs md:text-sm  text-gray-500">
                      -
                    </td>
                    <td className="text-center text-xs md:text-sm  text-gray-500">
                      -
                    </td>
                    <td className="text-center text-xs md:text-sm  text-gray-500">
                      -
                    </td>
                    <td className="text-center text-xs md:text-sm  text-gray-500">
                      -
                    </td>
                    <td className="text-center text-xs md:text-sm  text-gray-500">
                      -
                    </td>
                  </tr>
                  <tr className="even:bg-[#EDEEF8]">
                    <td className="px-4 py-4 text-xs md:text-sm text-center text-gray-500">
                      Şirket Kurulumu
                    </td>
                    <td className="px-4 py-4 text-xs md:text-sm text-center text-gray-500">
                      <p>${selectedYear === 0 ? companyCost : '-'}</p>
                    </td>
                    <td className="text-center text-xs md:text-sm  text-gray-500">
                      -
                    </td>
                    <td className="text-center text-xs md:text-sm  text-gray-500">
                      -
                    </td>
                    <td className="text-center text-xs md:text-sm  text-gray-500">
                      -
                    </td>
                    <td className="text-center text-xs md:text-sm  text-gray-500">
                      -
                    </td>
                    <td className="text-center text-xs md:text-sm  text-gray-500">
                      -
                    </td>
                    <td className="text-center text-xs md:text-sm  text-gray-500">
                      -
                    </td>
                    <td className="text-center text-xs md:text-sm  text-gray-500">
                      -
                    </td>
                    <td className="text-center text-xs md:text-sm  text-gray-500">
                      -
                    </td>
                    <td className="text-center text-xs md:text-sm  text-gray-500">
                      -
                    </td>
                    <td className="text-center text-xs md:text-sm  text-gray-500">
                      -
                    </td>
                    <td className="text-center text-xs md:text-sm  text-gray-500">
                      -
                    </td>
                    <td className="text-center text-xs md:text-sm  text-gray-500">
                      -
                    </td>
                  </tr>
                  <tr className="even:bg-[#EDEEF8]">
                    <td className="px-4 py-4 text-xs md:text-sm text-center text-gray-500">
                      Ürün Fotoğrafı
                    </td>
                    <td className="px-4 py-4 text-xs md:text-sm text-center text-gray-500">
                      <p>${selectedYear === 0 ? imageCost : '-'}</p>
                    </td>
                    <td className="text-center text-xs md:text-sm  text-gray-500">
                      -
                    </td>
                    <td className="text-center text-xs md:text-sm  text-gray-500">
                      -
                    </td>
                    <td className="text-center text-xs md:text-sm  text-gray-500">
                      -
                    </td>
                    <td className="text-center text-xs md:text-sm  text-gray-500">
                      -
                    </td>
                    <td className="text-center text-xs md:text-sm  text-gray-500">
                      -
                    </td>
                    <td className="text-center text-xs md:text-sm  text-gray-500">
                      -
                    </td>
                    <td className="text-center text-xs md:text-sm  text-gray-500">
                      -
                    </td>
                    <td className="text-center text-xs md:text-sm  text-gray-500">
                      -
                    </td>
                    <td className="text-center text-xs md:text-sm  text-gray-500">
                      -
                    </td>
                    <td className="text-center text-xs md:text-sm  text-gray-500">
                      -
                    </td>
                    <td className="text-center text-xs md:text-sm  text-gray-500">
                      -
                    </td>
                    <td className="text-center text-xs md:text-sm  text-gray-500">
                      -
                    </td>
                  </tr>
                  <tr className="bg-[#BFDBFE]">
                    {data[selectedYear][1].map((item: any, index: number) => {
                      return (
                        <td
                          key={uuidv4()}
                          className="px-4 py-4 text-xs md:text-sm text-center text-gray-500"
                        >
                          <p>
                            {index === 0
                              ? 'Toplam Gelir'
                              : `$${seperateNumberWithComma(item.toFixed(0))}`}
                          </p>
                        </td>
                      );
                    })}
                  </tr>
                  <tr className="bg-[#BFDBFE]">
                    <td className="px-4 py-4 text-xs md:text-sm text-center text-gray-500">
                      <p>Toplam Gider</p>
                    </td>
                    {data[selectedYear][data[selectedYear].length - 2].map(
                      (item: any, index: number) => {
                        return (
                          <td
                            key={uuidv4()}
                            className="px-4 py-4 text-xs md:text-sm text-center text-gray-500 "
                          >
                            <p>${seperateNumberWithComma(item.toFixed(0))}</p>
                          </td>
                        );
                      }
                    )}
                  </tr>
                  <tr>
                    <td className="px-4 py-4 text-xs md:text-sm text-center text-gray-500">
                      <p>Kar/Zarar</p>
                    </td>
                    {data[selectedYear][data[selectedYear].length - 1].map(
                      (item: any, index: number) => {
                        return (
                          <td
                            key={uuidv4()}
                            className="px-4 py-4 text-xs md:text-sm text-center text-gray-500"
                          >
                            <p
                              className={classNames(
                                item < 0 ? 'text-red-500' : 'text-green-500'
                              )}
                            >
                              ${seperateNumberWithComma(item.toFixed(0))}
                            </p>
                          </td>
                        );
                      }
                    )}
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default ForecastTable;
