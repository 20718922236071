import React from 'react';
import { Link } from 'react-router-dom';

const Error404 = () => {
  return (
    <>
      <main className="grid place-items-center px-6 sm:py-32 lg:px-8 xl:py-1 ">
        <div className="text-center">
          <div>
            <img
              src="/assets/images/404-error.png"
              className="max-h-[400px] w-full"
              alt='404 page'
            />
          </div>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Sayfa Bulunamadı!
          </h1>
          <p className="mt-6 text-base leading-7 text-gray-600">
            Üzgünüz, görüntülemek istediğiniz sayfa bulunamamıştır
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link
              to="/"
              className="rounded-md bg-primary px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-secondary hover:cursor-pointer"
            >
              Anasayfaya Dön
            </Link>
          </div>
        </div>
      </main>
    </>
  );
};

export default Error404;
