import React from 'react';
import Select from 'react-select';

import { CommissionCategories } from '../../trendyolProfit/TrendyolCalculateProfitForm';

interface ShipEntegraCustomSelectProps {
  options: any;
  onSelect: any;
  id?: string
}

const ShipEntegraCustomSelect: React.FC<ShipEntegraCustomSelectProps> = ({ options, onSelect }) => {
  const customStyles = {
    control: (provided: any) => ({
      ...provided,
      backgroundColor: 'white',
      border: `2px solid #6F7AC3`,
      borderRadius: '8px',
      minHeight: '42.5px',
      fontSize: '12px',
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#939BD2' : 'white',
      fontSize: '12px',
      color: state.isSelected ? 'white' : 'black',
      '&:hover': {
        backgroundColor: '#939BD2',
        color: '#000',
      },
    }),
  };

  const handleChange = (selectedOption: any) => {
    onSelect(selectedOption.value);
  };

  return (
    <Select
      placeholder="Seçiniz..."
      styles={customStyles}
      options={options.map((option: any) => ({
        value: option,
        label: option.name,
      }))}
      onChange={handleChange}
    />
  );
};

export default ShipEntegraCustomSelect;
