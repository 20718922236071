import React, { useEffect, useState } from 'react';

import { CourseData } from '../../pages/CourseDetail';
import ProggressBar from '../common/ProggressBar';
import Afiliates from './Afiliates';
import CourseSectionsSideBar from './CourseSectionsSideBar';

interface Props {
  percent: number;
  course: CourseData;
  contentCount: number;
  selectedContent: {
    sectionLocation: any;
    path: any;
    id: any;
    contentName: any;
    notes: string;
  };
  endedSectionContentCount:
  | { sectionId: number; contentId: number }[]
  | undefined;
  handleSelectedContent: Function;
  onChange: (e: any, courseId: number, contentId: number) => {};
}
const CourseDescriptionCard: React.FC<Props> = ({
  percent,
  course,
  contentCount,
  selectedContent,
  endedSectionContentCount,
  handleSelectedContent,
  onChange,
}) => {
  const [tabs, setTabs] = useState(() => {
    if (selectedContent?.notes?.length) {
      return [
        { name: 'Hizmet Sağlayıcılar', count: '', current: true, display: '' },
        { name: 'Kurs İçeriği', current: false, display: 'xl:hidden' },
        // { name: 'Duyuru', current: true, display: '' },
      ];
    } else {
      return [
        { name: 'Hizmet Sağlayıcılar', count: '', current: true, display: '' },
        { name: 'Kurs İçeriği', current: false, display: 'xl:hidden' },
      ];
    }
  });

  const handleActiveTab = (tabName: string) => {
    const updatedTabs = tabs.map((tab) => {
      // Eğer tabName parametresi ile gelen tabName'e eşitse current'ı true yap, diğerlerini false yap
      if (tab.name === tabName) {
        return { ...tab, current: true };
      } else {
        return { ...tab, current: false };
      }
    });
    // Güncellenmiş tabs array'ini setTabs fonksiyonu ile state'i güncelle
    setTabs(updatedTabs);
  };
  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
  }

  useEffect(() => {
    const handleResize = () => {
      const innerWidth = window.innerWidth;
      // innerWidth 1279'dan küçükse ve 'Kurs İçeriği' tabı seçili ise, tab.current'i true yap
      if (innerWidth <= 1279 && tabs[0].name === 'Hizmet Sağlayıcılar') {
        setTabs((prevTabs) => [
          { ...prevTabs[0], current: false },
          { ...prevTabs[1], current: true },
          { ...prevTabs[2], current: false },
        ]);
      }
      // innerWidth 1279'dan büyükse ve 'Kurs İçeriği' tabı seçili ise, tab.current'i true yap
      else if (innerWidth > 1279 && tabs[1].name === 'Kurs İçeriği') {
        setTabs((prevTabs) => [
          { ...prevTabs[0], current: true },
          { ...prevTabs[1], current: false },
          { ...prevTabs[2], current: false },
        ]);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [tabs]);
  useEffect(() => {
    setTabs((prevTabs) => {
      if (selectedContent?.notes?.length) {
        return [
          { name: 'Hizmet Sağlayıcılar', count: '', current: true, display: '' },
          { name: 'Kurs İçeriği', current: false, display: 'xl:hidden' },
          // { name: 'Duyuru', current: true, display: '' },
        ];
      } else {
        return [
          { name: 'Hizmet Sağlayıcılar', count: '', current: true, display: '' },
          { name: 'Kurs İçeriği', current: false, display: 'xl:hidden' },
        ];
      }
    });
  }, [selectedContent]);

  let activeTabContent;
  tabs.map((tab) => {
    if (tab.name === 'Hizmet Sağlayıcılar' && tab.current === true) {
      activeTabContent = (
        <>
          {/* <div className="md:flex flex-row   items-center justify-between my-5 pb-2">
            <div className="w-[50%] text-headerPrimary text-sm">
              Kurs İlerlemesi{' '}
              {course?.completed?.length && contentCount && Math.ceil(percent!)}{' '}
              %
              <ProggressBar percent={percent!} />
            </div>
          </div>
          <div>{course.description}</div> */}
          <Afiliates />
        </>
      );
    }
    // else if (tab.name === 'Duyuru' && tab.current === true) {
    //   activeTabContent = (
    //     <>
    //       <div className="md:flex flex-row   items-center justify-between my-5 pb-2">
    //         <div className="text-headingPrimary">
    //           <div className="mb-2 text-rexRed font-semibold">
    //             <span className="inline-flex items-center justify-center w-4 h-4 mr-1 text-xs font-semibold text-white bg-rexRed rounded-full">
    //               {'!'}
    //             </span>
    //             Eğitici Notu{' '}
    //           </div>
    //           <div>{selectedContent.notes}</div>
    //         </div>
    //       </div>
    //     </>
    //   );
    // }
    else if (tab.name === 'Kurs İçeriği' && tab.current === true) {
      activeTabContent = (
        <div className="my-5">
          <CourseSectionsSideBar
            display={tab.display}
            course={course}
            selectedContent={selectedContent}
            endedSectionContentCount={endedSectionContentCount}
            handleSelectedContent={handleSelectedContent}
            onChange={onChange}
          />
        </div>
      );
    }
  });
  return (
    <div className="mt-5 bg-white rounded-xl text-sm lg:text-md shadow-lg p-5">
      <div>
        <div className="">
          <div className="border-b border-gray-200">
            <nav
              className="-mb-px flex space-x-1 lg:space-x-4"
              aria-label="Tabs"
            >
              {tabs.map((tab) => (
                <div
                  onClick={() => handleActiveTab(tab.name)}
                  key={tab.name}
                  className={classNames(
                    tab.current
                      ? 'border-headingPrimary text-headingPrimary'
                      : 'border-transparent text-gray-500 hover:border-gray-200 hover:text-gray-700',
                    `${tab.display} flex whitespace-nowrap border-b-2 py-2 px-1 text-sm font-medium cursor-pointer`
                  )}
                  aria-current={tab.current ? 'page' : undefined}
                >
                  {/* {tab.name === 'Duyuru' ? (
                    <span>
                      <span className='text-xs'> {tab.name}</span>
                      <span className="inline-flex items-center justify-center w-4 h-4 ml-1 text-xs font-semibold text-white bg-rexRed rounded-full">
                        {1}
                      </span>
                    </span>
                  ) : ( */}
                  <span className='text-xs'> {tab.name}</span>
                  {/* )} */}
                  {tab.count ? (
                    <span
                      className={classNames(
                        tab.current
                          ? 'bg-headingPrimary-100 text-headingPrimary'
                          : 'bg-gray-100 text-gray-900',
                        'ml-1 hidden rounded-full py-0.5 px-1.5 text-xs font-medium md:inline-block'
                      )}
                    >
                      {tab.count}
                    </span>
                  ) : null}
                </div>
              ))}
            </nav>
          </div>
        </div>
      </div>
      {activeTabContent}
    </div>
  );
};

export default CourseDescriptionCard;
