import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';

import LoadingComponent from '../components/common/LoadingComponent';
import Pagination from '../components/common/Pagination';
import ManufacturerInfo from '../components/manufacturer/ManufacturerInfo';
import ManufacturerProductCard from '../components/manufacturer/ManufacturerProductCard';
import NumVerifyModal from '../components/numberVerifyModals/NumVerifyModal';
// import SixDigitModal from '../components/numberVerifyModals/SixDigitModal';
import VerifySuccessModal from '../components/numberVerifyModals/VerifySuccessModal';
import MessageModal from '../components/productsAndWholeSalers/MessageModal';
import SurveyModal from '../components/survey/SurveyModal';
import { useAuth } from '../context/AuthContext';
import memberService from '../services/memberService';
import producerService from '../services/producerService';
import productService from '../services/productService';
import alertNotification from '../utils/alertNotification';
import ErrorFallback from './errors/ErrorFallback';

export interface Producer {
  id: number;
  producerRating: string;
  producerName: string;
  producerSince: string;
  productCount: number;
  location: string;
  productRatingCount: number;
  productReviewCount: number;
  phoneNumber: string;
  discountRate: number;
  status: 'active' | 'passive';
  logo: string;
  userId: number;
}

export interface ProducerProducts {
  id: number;
  producerUserId: number,
  profile_image: string,
  first_name: string,
  last_name: string,
  brand: string;
  salePrice: number;
  reviewCount: number;
  rating: number;
  images: { url: string }[];
  title: string;
  discountedPrice: number;
  producerId: number;
  phoneNumber: string;
  communicationCount: string;
  product_id: number;
}

const Manufacturer = () => {
  const { session } = useAuth();
  const { id } = useParams();
  const [producer, setProducer] = useState<Producer>();
  const [producerProducts, setProducerProducts] =
    useState<ProducerProducts[]>();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [keyword, setKeyword] = useState('');
  const [orderBy, setOrderBy] = useState('reviewCount');
  const [order, setOrder] = useState('DESC');
  const [manuTotalPage, setManuTotalPage] = useState(1);
  const [isTabActive, setIsTabActive] = React.useState<boolean>(true);

  const [productCount, setProductCount] = useState(null);
  const [inputPage, setInputPage] = useState<number | string>(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const [queryString, setQueryString] = useState('?page=1');
  const [productId, setProductId] = useState<number | null>(null);
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const urlPage = location.search;
  // const navigate = useNavigate();
  let temp = Object.fromEntries([...searchParams]);
  let tempPage = temp.page;

  const handleChange = (e: any) => {
    if (e.target.value === 'higherComments') {
      setOrderBy('reviewCount');
      setOrder('DESC');
    } else if (e.target.value === 'lowestComments') {
      setOrderBy('reviewCount');
      setOrder('ASC');
    } else if (e.target.value === 'higherPrices') {
      setOrderBy('discountedPrice');
      setOrder('DESC');
    } else if (e.target.value === 'lowestPrices') {
      setOrderBy('discountedPrice');
      setOrder('ASC');
    }
    setPage(1);
  };

  const getProducer = (id: any) => {
    producerService
      .getProducer(id)
      .then((res) => {
        setProducer(res.data);
      })
      .catch((err) => {
        alertNotification('error', err.response.data.message);
      });
  };

  const getProducerProducts = async (id: any) => {
    // setLoading(true);
    // try {
    //   const res = await productService.getProducerProducts(
    //     id,
    //     page,
    //     keyword,
    //     orderBy,
    //     order
    //   );
    //   setProducerProducts(res.data.products);
    //   setManuTotalPage(res.data.totalPageCount);
    //   setProductCount(res.data.totalProductCount);
    //   setLoading(false);
    // } catch (error: any) {
    //   alertNotification('error', 'Something went wrong');
    // }
  };

  // Producer Contact Btn Clicked Stats-->
  const updateProducerStats = (productId: number) => {
    console.log(typeof productId);
    producerService
      .productStatistics({ productId })
      .then((res) => { })
      .catch((res) => {
        console.log(res);
      });
  };

  useEffect(() => {
    getProducer(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getProducerProducts(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, keyword, order, orderBy]);

  useEffect(() => {
    if (urlPage.includes('&')) {
      setPage(Number(tempPage));
      setInputPage(Number(tempPage));
      setQueryString(`?page=${page}`);
    } else if (!urlPage.includes('&') && urlPage.length > 0) {
      setPage(Number(tempPage));
      setInputPage(Number(tempPage));
    } else {
      setPage(1);
      setInputPage(1);
    }
  }, [page, queryString, tempPage, urlPage]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabActive(document.visibilityState === 'visible');
    };

    // Add event listener for visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup function to remove the event listener when the component is unmounted
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    let intervalId: any;
    if (session && isTabActive) {
      intervalId = setInterval(async () => {
        await memberService.userTrackReq(`manufacturers`, session?.id!);
      }, 60000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [session, isTabActive]);
  return (
    <>
      {/* <SixDigitModal /> */}
      <NumVerifyModal />
      <VerifySuccessModal />
      <SurveyModal />
      <div className="flex w-full flex-shrink-0 flex-col">
        <div className="bg-white rounded-xl p-2 md:p-6">
          <div className="flex items-center">
            <img
              src={producer?.logo}
              className="h-10 w-10 object-contain"
              alt="producer-logo"
            />
            <h1 className="ml-2 font-bold uppercase">
              {producer?.producerName}{' '}
            </h1>
          </div>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <ManufacturerInfo
              productCount={productCount}
              producer={producer!}
              handleChange={handleChange}
            />
          </ErrorBoundary>
        </div>
        {loading === false && producerProducts?.length! > 0 ? (
          <div className="mt-8 bg-white rounded-xl p-2 md:p-6">
            <div className="grid grid-cols-1 gap-4 mb-4 sm:grid-cols-2 min-[1200px]:grid-cols-3 sm:mx-0 min-[1350px]:grid-cols-4">
              {producerProducts?.map((product) => (
                <ErrorBoundary FallbackComponent={ErrorFallback}>
                  <ManufacturerProductCard
                    key={product.id}
                    product={product}
                    updateProducerStats={updateProducerStats}
                    setOpen={setOpen}
                    setProductId={setProductId}
                  />
                </ErrorBoundary>
              ))}
            </div>
            <>
              <Pagination
                setPage={setPage}
                page={page}
                totalPageCount={manuTotalPage}
                queryString={queryString}
                setInputPage={setInputPage}
                inputPage={inputPage}
              />
            </>
          </div>
        ) : (
          loading === true && <LoadingComponent />
        )}
      </div>
      <MessageModal
        open={open}
        setOpen={setOpen}
        productId={productId!}
        updateProducerStats={updateProducerStats}
      />
    </>
  );
};

export default Manufacturer;
