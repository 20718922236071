import React from 'react';
import { useLocation } from 'react-router-dom';

import { useAuth } from '../../context/AuthContext';
import { useLanguage } from '../../context/LanguageContext';

interface Props {
  setOpen: any;
  setOpenSuggest?: React.Dispatch<React.SetStateAction<boolean>>;
}

const ServiceProviderBtn: React.FC<Props> = ({ setOpen, setOpenSuggest }) => {
  const { t } = useLanguage();
  const location = useLocation();
  const path = location.pathname;
  const { session } = useAuth();
  return (
    <div
      className="flex w-full items-center"
      style={{
        justifyContent:
          path === '/service-providers' ? 'space-between' : 'flex-end',
      }}
    >
      {path === '/service-providers' && (
        <button
          className="text-headerPrimary font-medium text-sm hover:text-secondary md:text-base "
          onClick={() => setOpenSuggest?.(true)}
        >
          {t?.('service-providers.page.suggestion')}
        </button>
      )}
      {!session?.isServiceProvider && (
        <button
          onClick={setOpen}
          className="rounded-md bg-headerPrimary py-2.5 px-8 text-xs font-semibold text-white shadow-sm hover:bg-secondary"
        >
          {t?.('service-providers.page.registerAsServiceProvider')}
        </button>
      )}
    </div>
  );
};

export default ServiceProviderBtn;
