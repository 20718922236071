import React, { useEffect, useRef, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import { useQuery } from '@tanstack/react-query';

import LoadingComponent from '../components/common/LoadingComponent';
import ProductNotFound from '../components/common/ProductNotFound';
import { useAuth } from '../context/AuthContext';
import memberService from '../services/memberService';
import ErrorFallback from './errors/ErrorFallback';
import marketplaceService from '../services/marketplaceService';
import MarketplaceInventoryTable from '../components/marketplaceOrders/InventoryTable';
import { v4 as uuidv4 } from 'uuid';

import MarketplaceProductsCart from '../components/marketplace/ProductsCardMarketplace';
import Pagination from '../components/common/Pagination';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { MarketPlaceProductCard } from '../interfaces/marketplaceInterfaces';
import alertNotification from '../utils/alertNotification';

export interface EtsyInventory {
  name: string;
  etsyListingId: string;
  itemImage: string;
  marketplaceProductTitle: string;
  marketplaceProductPrice: number;
  marketplaceProductImages: string[];
  totalQuantity: number;
  totalPrice: number;
  totalCost: number;
  averagePrice: number;
}

export interface RootMarketplaceInventory {
  status: string;
  data: EtsyInventory[];
}

const MarketplaceInventory = () => {
  const { session } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [products, setProducts] = useState<MarketPlaceProductCard[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const [page, setPage] = useState(Number(searchParams.get('page')) || 1);
  const [keyword, setKeyword] = useState('');
  const [queryString, setQueryString] = useState(location.search);
  const [open, setOpen] = React.useState(false);
  const [productId, setProductId] = React.useState<number | null>(null);

  const [totalPage, setTotalPage] = useState(1);
  const [inputPage, setInputPage] = useState<number | string>(
    Number(searchParams.get('page')) || 1
  );
  const [isTabActive, setIsTabActive] = React.useState<boolean>(true);
  const inputRef = useRef<HTMLInputElement | null>(null);

  // const { isLoading, error, data, refetch } = useQuery<any, Error>(
  //   ['marketplaceInventory', { page }],
  //   {
  //     queryFn: () => marketplaceService.getMarketplaceSupplierInventory(page),

  //     staleTime: Infinity,
  //     refetchOnWindowFocus: false,
  //   }
  // );
  const getMarketplaceProducts = async () => {
    setIsLoading(true);
    try {
      const res = await marketplaceService.getMarketplaceProducts(
        page,
        keyword
      );
      console.log(res.data.data);
      let temp = res.data.data.map((item: any) => {
        return {
          id: item.id,
          title: item.title,
          price: item.supplier_product_variants[0].price,
          imageList: item.images,
        };
      });
      setProducts(temp);
      setTotalPage(res.data.totalPageCount);
      setIsLoading(false);
    } catch (error: any) {
      alertNotification('error', error.response.data.message);
    }
  };
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setKeyword(inputRef.current?.value || '');
    setPage(1);
    setInputPage(1);
    setQueryString(`keyword=${inputRef.current?.value}`);
    navigate(`?page=1&keyword=${inputRef.current?.value}`);
  };

  useEffect(() => {
    getMarketplaceProducts();
  }, [keyword, page]);

  useEffect(() => {
    let intervalId: any;
    if (session && isTabActive) {
      intervalId = setInterval(async () => {
        await memberService.userTrackReq('marketplace-inventory', session?.id!);
      }, 60000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [session, isTabActive]);
  return (
    <div className="bg-white p-4 rounded-xl">
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        {isLoading ? (
          <LoadingComponent />
        ) : products.length! > 0 ? (
          <>
            <div className="col-span-12 lg:col-span-8">
              <form className="" onSubmit={handleSubmit}>
                <label
                  htmlFor="default-search"
                  className="mb-2 text-lg lg:text-sm font-medium text-gray-900 sr-only dark:text-white"
                >
                  Search
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 text-gray-500 dark:text-gray-400"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      ></path>
                    </svg>
                  </div>
                  <input
                    ref={inputRef}
                    id="default-search"
                    className="block w-full p-[0.58rem] pl-10 text-xs md:text-sm text-gray-900 border border-headerPrimary rounded-lg bg-gray-50 "
                    placeholder={'Ürün Ara...'}
                  />
                  <button
                    type="submit"
                    className="text-white absolute right-0 bottom-0 bg-headerPrimary rounded-r-md font-medium text-sm px-4 py-2 md:py-2.5"
                  >
                    Ara
                  </button>
                </div>
              </form>
            </div>
            <div className="grid grid-cols-12 md:gap-2 justify-center items-center gap-6 col-span-12 md:col-span-10 border-t-4 border-[#F9FAFB] pt-4 ">
              {products.map((product: any) => (
                <div className="col-span-12 md:col-span-6 lg:col-span-3">
                  <MarketplaceProductsCart
                    setOpen={setOpen}
                    setProductId={setProductId}
                    key={uuidv4()}
                    product={product}
                  />
                </div>
              ))}
            </div>
            <Pagination
              page={page}
              setPage={setPage}
              totalPageCount={totalPage}
              setInputPage={setInputPage}
              inputPage={inputPage}
              queryString={queryString}
            />
          </>
        ) : (
          <ProductNotFound />
        )}
      </ErrorBoundary>
    </div>
  );
};

export default MarketplaceInventory;
