import React from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from '../../../context/LanguageContext';

const ServicesCard = () => {
  const { language } = useLanguage();
  return (
    <div className="bg-white px-2 pt-4 pb-6 shadow-lg rounded-xl col-span-2 md:col-span-1 ">
      <p className="px-4 font-semibold text-headerPrimary hover:font-bold">
        <Link to="/service-providers" className="cursor-pointer ">
          {language === 'tr' ? 'Hizmetler' : 'Service Providers'}
        </Link>
      </p>
      <div className="flex flex-row justify-between p-2 h-full">
        <Link
          to="/service-providers"
          className="  flex flex-col gap-2 justify-center items-center transition delay-150 hover:scale-110"
        >
          <div className="bg-white py-2 px-2 w-20 h-20 sm:w-32 sm:h-32 md:w-20 xl:w-20 2xl:w-28 md:h-20 xl:h-20 2xl:h-28 shadow-xl rounded-full flex items-center justify-center">
            <img
              className="object-contain"
              src="/images/dashboardServicesTax.svg"
              alt="Rexven Logo"
            />
          </div>
          {language === 'tr' ? (
            <span className="text-xs sm:text-sm lg:text-xs xl:text-sm font-medium text-headerPrimary text-center">
              Şirket Kuruluşu <br />
              Vergi Muhasebe
            </span>
          ) : (
            <span className="text-xs sm:text-sm lg:text-xs xl:text-sm font-medium text-headerPrimary text-center">
              Company Establishment <br />
              Tax Accounting
            </span>
          )}
        </Link>
        <Link
          to="/service-providers"
          className="  flex flex-col gap-2 justify-center items-center transition delay-150 hover:scale-110"
        >
          <div className="bg-white py-2 px-2 w-20 h-20 sm:w-32 sm:h-32 md:w-20 xl:w-20 2xl:w-28 md:h-20 xl:h-20 2xl:h-28 shadow-xl rounded-full flex items-center justify-center">
            <img
              className="object-contain"
              src="/images/dashboardServicesConsultancy.svg"
              alt="Rexven Logo"
            />
          </div>
          {language === 'tr' ? (
            <span className="text-xs sm:text-sm lg:text-xs xl:text-sm font-medium text-headerPrimary text-center">
              Kargo&Lojistik <br />
              Ara Depo
            </span>
          ) : (
            <span className="text-xs sm:text-sm lg:text-xs xl:text-sm font-medium text-headerPrimary text-center">
              Cargo&Logistics <br />
              Intermediate Warehouse
            </span>
          )}
        </Link>
        <Link
          to="/service-providers"
          className="  flex flex-col gap-2 justify-center items-center transition delay-150 hover:scale-110"
        >
          <div className="bg-white py-2 px-2 w-20 h-20 sm:w-32 sm:h-32 md:w-20 xl:w-20 2xl:w-28 md:h-20 xl:h-20 2xl:h-28 shadow-xl rounded-full flex items-center justify-center">
            <img
              className="object-contain"
              src="/images/dashboardServicesLogistics.svg"
              alt="Rexven Logo"
            />
          </div>
          {language === 'tr' ? (
            <span className="text-xs sm:text-sm lg:text-xs xl:text-sm font-medium text-headerPrimary text-center">
              Danışmanlık <br />
              (Amazon,Etsy...)
            </span>
          ) : (
            <span className="text-xs sm:text-sm lg:text-xs xl:text-sm font-medium text-headerPrimary text-center">
              Consultancy <br />
              (Amazon,Etsy...)
            </span>
          )}
        </Link>
      </div>
    </div>
  );
};

export default ServicesCard;
