import axios from 'axios';
import { PAYMENT_URL } from '../config';

export default axios.create({
  headers: {
    'Content-type': 'application/json',
  },
  baseURL: PAYMENT_URL,
  withCredentials: true,
});
