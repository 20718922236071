import { AxiosResponse } from 'axios';
import httpClient from '../utils/httpClient';

export interface ProfitAliexpress {
  price: number;
  type: boolean;
  discount: number;
  weight: number;
  productCost: number;
  laborCost: number;
  packageCost: number;
  adsCost: number;
}
export interface AliMainCat {
  id: string;
  name: string;
  children_ids: string[];
  sku_properties: [];
  properties: [];
}
export interface AliChildCat {
  id: string;
  name: string;
  children_ids: string[];
  sku_properties: [];
  properties: [];
}

export interface IAliProduct {
  categoryId: string;
  price: number;
  productId: number;
  shippingTemplateId: number;
}
class AliExpressService {
  responseBody = (response: AxiosResponse) => response.data;

  // Get main categories
  public async getMainCategories() {
    const { data } = await httpClient.get(`/api/v1/aliexpress/categories`);
    return data;
  }

  // Get children categories
  public async getChildCategories(cats: string[]) {
    const { data } = await httpClient.post(
      `/api/v1/aliexpress/category-names`,
      { categories: cats }
    );
    return data;
  }

  //   Get Calculate Result
  public async getProfitForAliexpress(payload: ProfitAliexpress) {
    const { data } = await httpClient.post(
      `/api/v1/aliexpress/calculate`,
      payload
    );
    return data;
  }

  //Get Shipping Templates
  public async getShippingTemplates() {
    const { data } = await httpClient.get(
      `/api/v1/aliexpress/shipment-template`
    );
    return data;
  }

  //
  public async integrateToAliexpress(payload: IAliProduct) {
    const { data } = await httpClient.post(
      `/api/v1/aliexpress/product`,
      payload
    );
    return data;
  }

  public async getAliExpressTopProducts(page: number, payload: any) {
    const { data } = await httpClient.get(
      `/api/v1/aliexpress/most-sold?page=${page}`
    );
    return data;
  }

  public async getAliExpressTopKeywords(page: number, payload: any) {
    const { data } = await httpClient.get(
      `/api/v1/aliexpress/keywords?page=${page}&keyword=${payload.keyword}`
    );
    return data;
  }

  public async updateProcesses() {
    const { data } = await httpClient.get(
      `/api/v1/aliexpress/update-processes`
    );
    return data;
  }
}

export default new AliExpressService();
