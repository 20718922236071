import React from 'react';
import ProviderCard from './ProviderCard';
import { IRootAllServices } from '../../interfaces/serviceProviders';
import { Link, useParams } from 'react-router-dom';
import memberService from '../../services/memberService';
import { useQuery } from '@tanstack/react-query';
import LoadingComponent from '../common/LoadingComponent';

const providers = [
  {
    service_name: 'Trendyol',
    id: 3,
    img_path: '/images/amazon-arbitraj.webp',
    order: 5,
    min_service_fee: 1000,
    max_service_fee: 1500,
  },
  {
    service_name: 'Amazon Hesap Açma',
    id: 3,
    img_path: '/images/amazon-arbitraj.webp',
    order: 5,
    min_service_fee: 2000,
    max_service_fee: 5500,
  },
  {
    service_name: 'Etsy Hesap Açma',
    id: 3,
    img_path: '/images/amazon-arbitraj.webp',
    order: 5,
    min_service_fee: 2000,
    max_service_fee: 5500,
  },
  {
    service_name: 'Dijital PAzarlama - SEO',
    id: 3,
    img_path: '/images/amazon-arbitraj.webp',
    order: 5,
    min_service_fee: 1000,
    max_service_fee: 2500,
  },
  {
    service_name: 'Ürün Fotoğrafı',
    id: 3,
    img_path: '/images/amazon-arbitraj.webp',
    order: 5,
    min_service_fee: 1000,
    max_service_fee: 2500,
  },
  {
    service_name: 'Shopify',
    id: 3,
    img_path: '/images/amazon-arbitraj.webp',
    order: 5,
    min_service_fee: 1000,
    max_service_fee: 2500,
  },
];
interface Props {
  data: IRootAllServices | undefined;
}

const SimilarServices: React.FC<Props> = ({ data }) => {
  return (
    <div className="flex flex-col justify-start items-center space-y-4 mt-4">
      <p className="font-bold text-headerPrimary text-2xl text-center">
        Hizmetler
      </p>
      {false ? (
        <LoadingComponent />
      ) : (
        data?.data.map((provider, index) => (
          <ProviderCard key={`similar-${provider.id}`} provider={provider} />
        ))
      )}
    </div>
  );
};

export default SimilarServices;
