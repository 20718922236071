import { Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';

import { useAuth } from '../../context/AuthContext';
import { useLanguage } from '../../context/LanguageContext';
import { errorElement } from '../../utils/errorElement';
import GsmUpdate from './GsmUpdate';

export interface Profile {
  firstName: string;
  lastName: string;
  // phone: string;
}
const ProfileUpdate = () => {
  const { t } = useLanguage();
  const { session, updateUserInfo } = useAuth();

  const onSubmit = async (values: Profile) => {
    try {
      await updateUserInfo?.(values);
    } catch (error) {
      alert('there is a huge problem');
    }
  };
  const regexName = /^[a-zA-ZŞşÇçıİÜüĞğÖö\s]*$/;

  return (
    <div className="flex flex-col flex-1 flex-shrink-0 bg-white rounded-xl  shadow-lg">
      <div className="border-b-2 border-lightGray p-5 sm:p-7">
        <h3 className="font-medium text-headingPrimary cardHeading">
          {' '}
          {t?.('profile.profileUpdate.title')}
        </h3>
      </div>
      <div className="p-5 sm:p-7">
        <Formik
          initialValues={{
            firstName: session?.firstName!,
            lastName: session?.lastName!,
          }}
          onSubmit={onSubmit}
          validationSchema={Yup.object({
            firstName: Yup.string().matches(
              regexName,
              t?.('validation.profile.profileUpdate')
            ),
            lastName: Yup.string().matches(
              regexName,
              t?.('validation.profile.profileUpdate')
            ),
          })}
        >
          {({ handleSubmit, values, handleChange, errors }) => (
            <form className="flex flex-col gap-y-3" onSubmit={handleSubmit}>
              <div className="flex justify-start items-center gap-x-1.5">
                <label className="font-medium text-xs w-2/12 xl:text-sm">
                  {t?.('profile.profileUpdate.form.firstName') +
                    ' ' +
                    t?.('profile.profileUpdate.form.lastName')}
                </label>
                <div className="flex w-full sm:w-8/12  justify-between gap-x-2">
                  <input
                    type="text"
                    name="firstName"
                    id="firstName"
                    onChange={handleChange}
                    value={values.firstName}
                    className="flex w-1/3 sm:w-2/5 rounded-md text-xs bg-lightGray border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-headingPrimary sm:text-sm sm:leading-6 "
                  />
                  <input
                    type="text"
                    name="lastName"
                    id="lastName"
                    onChange={handleChange}
                    value={values.lastName}
                    className="flex w-1/3 sm:w-2/5 rounded-md text-xs bg-lightGray border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-headingPrimary sm:text-sm sm:leading-6"
                  />
                  <button
                    type="submit"
                    className="flex w-1/3 justify-center items-center rounded-md sm:w-1/5 bg-headerPrimary py-1 px-4 lg:py-2.5 lg:px-8 text-xs font-semibold text-white shadow-sm hover:bg-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 md:text-sm"
                  >
                    {t?.('profile.passwordChange.form.submit')}
                  </button>
                </div>
              </div>
              {errors.firstName && errorElement(errors.firstName)}
              {errors.lastName && errorElement(errors.lastName)}
              {/* <div className="flex justify-between items-center gap-x-1.5">
                <label className="font-medium text-xs w-2/12 sm:text-sm">
                  {t?.('profile.profileUpdate.form.phone')}
                </label>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  className="flex w-10/12 rounded-md bg-lightGray border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-headingPrimary sm:text-sm sm:leading-6"
                />
              </div> */}
              {/* <div className="flex flex-1 justify-end gap-x-3">
                <button
                  type="submit"
                  className="rounded-md bg-headerPrimary py-2.5 px-8 text-xs font-semibold text-white shadow-sm hover:bg-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 md:text-sm"
                >
                  {t?.('profile.passwordChange.form.submit')}
                </button>
              </div> */}
            </form>
          )}
        </Formik>
      </div>
      <GsmUpdate />
    </div>
  );
};

export default ProfileUpdate;
