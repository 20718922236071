import React from 'react';

const BlurredGptReviewBox = () => {
  return (
    <>
      <div className="h-[300px] max-h-[300px] mt-6 w-full border-2 border-cardBorder rounded-lg px-8 py-4 animate-pulse">
        <div className="w-[100%] bg-gray-300 h-2.5 rounded-full mb-2"></div>
        <div className="w-[60%] bg-gray-300 h-2.5 rounded-full mb-4"></div>
        <div className="w-[100%] bg-gray-300 h-2.5 rounded-full mb-2"></div>
        <div className="w-[50%] bg-gray-300 h-2.5 rounded-full mb-4"></div>
        <div className="w-[100%] bg-gray-300 h-2.5 rounded-full mb-2"></div>
        <div className="w-[20%] bg-gray-300 h-2.5 rounded-full mb-4"></div>
        <div className="w-[100%] bg-gray-300 h-2.5 rounded-full mb-2"></div>
        <div className="w-[20%] bg-gray-300 h-2.5 rounded-full mb-4"></div>
        <div className="w-[100%] bg-gray-300 h-2.5 rounded-full mb-2"></div>
        <div className="w-[80%] bg-gray-300 h-2.5 rounded-full mb-4"></div>
        <div className="w-[100%] bg-gray-300 h-2.5 rounded-full mb-2"></div>
        <div className="w-[100%] bg-gray-300 h-2.5 rounded-full mb-2"></div>
      </div>
    </>
  );
};

export default BlurredGptReviewBox;
