import { Field, FieldArray, Form, Formik } from 'formik';
import { AddCircle, Calculator, DocumentText } from 'iconsax-react';
import { Dispatch, Fragment, SetStateAction, useEffect, useState } from 'react';
import * as Yup from 'yup';

import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { useQuery } from '@tanstack/react-query';

import { useLanguage } from '../../context/LanguageContext';
import { ICountryRoot } from '../../interfaces/etsyToolsInterfaces';
import { Order } from '../../interfaces/shipEntegraInterfaces';
import etsyToolsService from '../../services/etsyToolsService';
import shipentegraService from '../../services/shipentegraService';
import alertNotification from '../../utils/alertNotification';
import { errorElement } from '../../utils/errorElement';
import { isEuCountry } from '../shipEntegraShared/IsEuropeCountry';

interface UpdateOrderModalProps {
    openArrangeOrder: boolean
    setOpenArrangeOrder: Dispatch<SetStateAction<boolean>>
    selectedOrders: Order[]
    getOrders: () => void
}
const UpdateOrderModal: React.FC<UpdateOrderModalProps> = ({ openArrangeOrder, setOpenArrangeOrder, selectedOrders, getOrders }) => {
    const countries = useQuery<ICountryRoot, Error>(['shipEntegraCountries'], {
        queryFn: () => etsyToolsService.getCountries(),
        staleTime: Infinity,
    });

    const initialValues = {
        currency: selectedOrders[0]?.currency || '',
        description: selectedOrders[0]?.content_en || '',
        my_weight: selectedOrders[0]?.my_weight || '',
        ship_to_name: selectedOrders[0]?.ship_to_name || '',
        ship_to_country: selectedOrders[0]?.ship_to_country || '',
        ship_to_state: selectedOrders[0]?.ship_to_state || '',
        ship_to_postal_code: selectedOrders[0]?.ship_to_postal_code || '',
        ship_to_email: selectedOrders[0]?.ship_to_email || '',
        ship_to_phone: selectedOrders[0]?.ship_to_phone || '',
        ship_to_city: selectedOrders[0]?.ship_to_city || '',
        ship_to_street1: selectedOrders[0]?.ship_to_street1 || '',
        ship_to_street2: selectedOrders[0]?.ship_to_street2 || '',
        package_quantity: selectedOrders[0]?.quantity || '',
        my_w: selectedOrders[0]?.my_w || '',
        my_h: selectedOrders[0]?.my_h || '',
        my_l: selectedOrders[0]?.my_l || '',
        shipping_type: selectedOrders[0]?.shipping_type || '',
        reference1: selectedOrders[0]?.reference1 || '',
        ioss_number: selectedOrders[0]?.ioss_number || ''
    };

    const validationSchema = Yup.object().shape({
        currency: Yup.string().required('Bu alan zorunludur.'),
        ship_to_name: Yup.string().required('Bu alan zorunludur.'),
        // ship_to_country: Yup.string().required('Bu alan zorunludur.'),
        ship_to_street1: Yup.string().required('Bu alan zorunludur.'),
    });
    const onSubmit = (values: any) => {
        shipentegraService.updateOrder(selectedOrders[0].orderId, values)
            .then(res => {
                alertNotification('success', 'Siparişiniz Güncellendi')
                setOpenArrangeOrder(false)
                getOrders()
            })
            .catch(err => alertNotification('error', err.response.data.message))
    }
    return (
        <Transition.Root show={openArrangeOrder} as={Fragment}>
            <Dialog as="div" className="relative z-60" onClose={setOpenArrangeOrder}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>
                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full md:max-w-4xl p-6">
                                <div className="absolute right-0 top-0 pr-4 pt-4">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        onClick={() => setOpenArrangeOrder(false)}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className="">
                                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                                        <Dialog.Title as="h3" className="flex flex-row items-center justify-left gap-1 text-base font-semibold leading-6 text-headerPrimary">
                                            <DocumentText color="#1F254B"
                                                variant="Bold"
                                                className="h-5 w-5" />
                                            Düzenle
                                        </Dialog.Title>
                                        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
                                            {
                                                formik => (
                                                    <Form>
                                                        <div className="mt-2 grid grid-cols-12 gap-4 items-center justify-center">
                                                            <div className='col-span-12 lg:col-span-6 flex flex-col items-start justify-center'>
                                                                <label htmlFor="ship_to_name">Alıcı Adı/Şirket Adı</label>
                                                                <input
                                                                    value={formik.values.ship_to_name}
                                                                    onChange={formik.handleChange}
                                                                    id="ship_to_name"
                                                                    name="ship_to_name"
                                                                    className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                                                                    placeholder="Alıcı Adı/Şirket Adı"
                                                                    type="text" />
                                                                {formik.errors.ship_to_name && errorElement(formik.errors.ship_to_name)}
                                                            </div>
                                                            <div className='col-span-12 lg:col-span-6 flex flex-col items-start justify-center'>
                                                                <label htmlFor="ship_to_street1">Adres</label>
                                                                <input
                                                                    value={formik.values.ship_to_street1}
                                                                    onChange={formik.handleChange}
                                                                    id="ship_to_street1"
                                                                    name="ship_to_street1"
                                                                    className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                                                                    placeholder="Adres"
                                                                    type="text" />
                                                                {formik.errors.ship_to_street1 && errorElement(formik.errors.ship_to_street1)}
                                                            </div>
                                                            <div className='col-span-12 lg:col-span-6 flex flex-col items-start justify-center'>
                                                                <label htmlFor="ship_to_street2">Adres 2</label>
                                                                <input
                                                                    value={formik.values.ship_to_street2}
                                                                    onChange={formik.handleChange}
                                                                    id="ship_to_street2"
                                                                    name="ship_to_street2"
                                                                    className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                                                                    placeholder="Adres 2"
                                                                    type="text" />
                                                            </div>
                                                            <div className='col-span-12 lg:col-span-6 flex flex-col items-start justify-center'>
                                                                <label htmlFor="ship_to_city">Şehir</label>
                                                                <input
                                                                    value={formik.values.ship_to_city}
                                                                    onChange={formik.handleChange}
                                                                    id="ship_to_city"
                                                                    name="ship_to_city"
                                                                    className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                                                                    placeholder="Şehir"
                                                                    type="text" />
                                                            </div>
                                                            <div className='col-span-12 lg:col-span-6 flex flex-col items-start justify-center'>
                                                                <label htmlFor="ship_to_state">Eyalet</label>
                                                                <input
                                                                    value={formik.values.ship_to_state}
                                                                    onChange={formik.handleChange}
                                                                    id="ship_to_state"
                                                                    name="ship_to_state"
                                                                    className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                                                                    placeholder="Eyalet"
                                                                    type="text" />
                                                            </div>
                                                            <div className='col-span-12 lg:col-span-6 flex flex-col items-start justify-center'>
                                                                <label htmlFor="ship_to_postal_code">Posta Kodu</label>
                                                                <input
                                                                    value={formik.values.ship_to_postal_code}
                                                                    onChange={formik.handleChange}
                                                                    id="ship_to_postal_code"
                                                                    name="ship_to_postal_code"
                                                                    className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                                                                    placeholder="Posta Kodu"
                                                                    type="text" />
                                                            </div>
                                                            <div className='col-span-12 lg:col-span-6 flex flex-col items-start justify-center'>
                                                                <label htmlFor="ship_to_country">Ülke</label>
                                                                <select
                                                                    value={formik.values.ship_to_country}
                                                                    onChange={formik.handleChange}
                                                                    id="ship_to_country"
                                                                    disabled={true}
                                                                    name="ship_to_country"
                                                                    className="cursor-not-allowed w-full rounded-md border border-cardBorder py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                                                                >
                                                                    <option value="">Seçiniz</option>
                                                                    {countries?.data?.data?.map((country) => {
                                                                        return (
                                                                            <option value={country.countryCode}>{country.name}</option>
                                                                        )
                                                                    })}
                                                                </select>
                                                                {formik.errors.ship_to_country && errorElement(formik.errors.ship_to_country)}
                                                            </div>
                                                            <div className='col-span-12 lg:col-span-6 flex flex-col items-start justify-center'>
                                                                <label htmlFor="ship_to_email">E-Mail</label>
                                                                <input
                                                                    value={formik.values.ship_to_email}
                                                                    onChange={formik.handleChange}
                                                                    id="ship_to_email"
                                                                    name="ship_to_email"
                                                                    className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                                                                    placeholder="E-Mail"
                                                                    type="email" />
                                                            </div>
                                                            <div className='col-span-12 lg:col-span-6 flex flex-col items-start justify-center'>
                                                                <label htmlFor="ship_to_phone">Telefon</label>
                                                                <input
                                                                    value={formik.values.ship_to_phone}
                                                                    onChange={formik.handleChange}
                                                                    id="ship_to_phone"
                                                                    name="ship_to_phone"
                                                                    className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                                                                    placeholder="Telefon"
                                                                    type="tel" />
                                                            </div>
                                                            <div className='col-span-12 lg:col-span-6 flex flex-col items-start justify-center'>
                                                                <label htmlFor="package_quantity">Paket Adedi</label>
                                                                <input
                                                                    value={formik.values.package_quantity}
                                                                    onChange={formik.handleChange}
                                                                    id="package_quantity"
                                                                    name="package_quantity"
                                                                    className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                                                                    placeholder="Paket Adedi"
                                                                    type="number" />
                                                            </div>
                                                            <div className='col-span-12 lg:col-span-6 flex flex-col items-start justify-center'>
                                                                <label htmlFor="description">İngilizce İçerik</label>
                                                                <input
                                                                    value={formik.values.description}
                                                                    onChange={formik.handleChange}
                                                                    id="description"
                                                                    name="description"
                                                                    className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                                                                    placeholder="İngilizce İçerik"
                                                                    type="number" />
                                                            </div>
                                                            <div className='col-span-12 lg:col-span-6 flex flex-col items-start justify-center'>
                                                                <label htmlFor="my_weight">Ağırlık</label>
                                                                <input
                                                                    value={formik.values.my_weight}
                                                                    onChange={formik.handleChange}
                                                                    id="my_weight"
                                                                    name="my_weight"
                                                                    className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                                                                    placeholder="Ağırlık"
                                                                    type="number" />
                                                            </div>
                                                            <div className='col-span-12 lg:col-span-6 flex flex-col items-start justify-center'>
                                                                <label htmlFor="my_w">En</label>
                                                                <input
                                                                    value={formik.values.my_w}
                                                                    onChange={formik.handleChange}
                                                                    id="my_w"
                                                                    name="my_w"
                                                                    className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                                                                    placeholder="En"
                                                                    type="number" />
                                                            </div>
                                                            <div className='col-span-12 lg:col-span-6 flex flex-col items-start justify-center'>
                                                                <label htmlFor="my_l">Uzunluk</label>
                                                                <input
                                                                    value={formik.values.my_l}
                                                                    onChange={formik.handleChange}
                                                                    id="my_l"
                                                                    name="my_l"
                                                                    className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                                                                    placeholder="Uzunluk"
                                                                    type="number" />
                                                            </div>
                                                            <div className='col-span-12 lg:col-span-6 flex flex-col items-start justify-center'>
                                                                <label htmlFor="my_h">Yükseklik</label>
                                                                <input
                                                                    value={formik.values.my_h}
                                                                    onChange={formik.handleChange}
                                                                    id="my_h"
                                                                    name="my_h"
                                                                    className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                                                                    placeholder="Yükseklik"
                                                                    type="number" />
                                                            </div>
                                                            <div className='col-span-12 lg:col-span-6 flex flex-col items-start justify-center'>
                                                                <label htmlFor="reference1">Referans</label>
                                                                <input
                                                                    value={formik.values.reference1}
                                                                    onChange={formik.handleChange}
                                                                    id="reference1"
                                                                    name="reference1"
                                                                    className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                                                                    placeholder="Referans"
                                                                    type="text" />
                                                            </div>
                                                            <div className='col-span-12 lg:col-span-6 flex flex-col items-start justify-center'>
                                                                <label htmlFor="currency">Para Birimi</label>
                                                                <select
                                                                    value={formik.values.currency}
                                                                    onChange={formik.handleChange}
                                                                    id="currency"
                                                                    name="currency"
                                                                    placeholder='Seçiniz'
                                                                    className="w-full rounded-md border border-cardBorder py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                                                                >
                                                                    <option value="USD">USD</option>
                                                                    <option value="EUR">EUR</option>
                                                                    <option value="GBP">GBP</option>
                                                                    <option value="SAR">SAR</option>
                                                                    <option value="AUD">AUD</option>
                                                                    <option value="CAD">CAD</option>
                                                                    <option value="KWD">KWD</option>
                                                                </select>
                                                                {formik.errors.currency && errorElement(formik.errors.currency)}
                                                            </div>
                                                            <div className='col-span-12 lg:col-span-6 flex flex-col items-start justify-center'>
                                                                <label htmlFor="shipping_type">Gönderim Şekli</label>
                                                                <select
                                                                    value={formik.values.shipping_type}
                                                                    onChange={formik.handleChange}
                                                                    id="shipping_type"
                                                                    name="shipping_type"
                                                                    className="w-full rounded-md border border-cardBorder py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                                                                >
                                                                    <option value="">Seçiniz</option>
                                                                    <option value="1">ShipEntegra DDP(Gönderici ödemeli)</option>
                                                                    <option value="2">DDU (alıcı ödemeli)</option>
                                                                    {isEuCountry(selectedOrders[0]?.ship_to_country) &&
                                                                        <option value="3">IOSS</option>
                                                                    }
                                                                    <option value="4">ShipEntegra IOSS</option>
                                                                </select>
                                                            </div>
                                                            {formik.values.shipping_type === '3' &&
                                                                <div className='col-span-12 lg:col-span-6 flex flex-col items-start justify-center'>
                                                                    <label htmlFor="ioss_number">IOSS Nu</label>
                                                                    <input
                                                                        value={formik.values.ioss_number}
                                                                        onChange={formik.handleChange}
                                                                        id="ioss_number"
                                                                        name="ioss_number"
                                                                        className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                                                                        placeholder="IOSS"
                                                                        type="text" />
                                                                </div>}
                                                        </div>
                                                        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                                            <button
                                                                type="submit"
                                                                className="inline-flex w-full min-w-[75px] justify-center rounded-md bg-headerPrimary px-3 py-2 text-sm font-semibold text-white shadow-sm sm:ml-3 sm:w-auto"
                                                            >
                                                                Kaydet
                                                            </button>
                                                            <button
                                                                type="button"
                                                                className="mt-3 inline-flex w-full min-w-[75px] justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                                                onClick={() => setOpenArrangeOrder(false)}
                                                            >
                                                                İptal
                                                            </button>
                                                        </div>
                                                    </Form>)}
                                        </Formik>

                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
export default UpdateOrderModal