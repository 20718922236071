import React from 'react';

const LoadingSkeleton = () => {
  return (
    <>
      <div className="col-span-12 flex flex-col md:flex-row space-x-4 mb-8">
        <div className="h-[440px] w-full lg:w-[25%] bg-lightGray animate-pulse rounded-xl"></div>
        <div className="h-[440px] w-full lg:w-[25%] bg-lightGray animate-pulse rounded-xl"></div>
        <div className="h-[440px] w-full lg:w-[25%] bg-lightGray animate-pulse rounded-xl"></div>
        <div className="h-[440px] w-full lg:w-[25%] bg-lightGray animate-pulse rounded-xl"></div>
      </div>
      <div className="col-span-12 flex flex-col md:flex-row space-x-4">
        <div className="h-[440px] w-full lg:w-[25%] bg-lightGray animate-pulse rounded-xl"></div>
        <div className="h-[440px] w-full lg:w-[25%] bg-lightGray animate-pulse rounded-xl"></div>
        <div className="h-[440px] w-full lg:w-[25%] bg-lightGray animate-pulse rounded-xl"></div>
        <div className="h-[440px] w-full lg:w-[25%] bg-lightGray animate-pulse rounded-xl"></div>
      </div>
    </>
  );
};

export default LoadingSkeleton;
