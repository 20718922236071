import React from 'react';

import DotLoaderSpinner from '../../common/DotLoaderSpinner';
import LoadingComponent from '../../common/LoadingComponent';

// import { IConfirmedOrders } from './ConfirmedShipments';

interface Props {
  loading: boolean;
  data: any;
}

const tableHeader = [
  { name: 'Etiket', column: 'ticket' },
  { name: 'Resim', column: 'imgUrl' },
  { name: 'Alıcı', column: 'ship_to_name' },
  { name: 'Takip Numarası', column: 'se_tracking_number' },
  { name: 'ID', column: 'order_id' },
  { name: 'Kabul Tarihi', column: 'acceptedAt' },
  { name: 'Ülke', column: 'ship_to_country' },
  { name: 'Faturalanan Fiyat($)', column: 'sentegra_price' },
  { name: 'SKU', column: 'sku' },
  // { name: 'Sipariş Tarihi', column: 'seo_created_date' },
];

const ShipmentsTable: React.FC<Props> = ({ loading, data }) => {
  return (
    <div className="flex flex-col flex-shrink-0 bg-white rounded-xl shadow-lg my-8 h-[50vh]">
      {/* <div className="flow-root"> */}
      <div className="relative overflow-x-auto overflow-y-auto">
        <div className="inline-block min-w-full align-middle">
          <div className="border-none rounded-lg">
            <table className="min-w-full table-fixed">
              <thead className="bg-headerPrimary rounded-t-lg">
                {tableHeader.map((item) => {
                  return (
                    <th
                      key={item.name}
                      className="bg-headerPrimary py-6 pl-4 pr-3 text-center text-sm sticky top-0 font-semibold text-white"
                      scope="col"
                    >
                      <p className="text-xs md:text-sm">{item.name}</p>
                    </th>
                  );
                })}
              </thead>

              {loading ? (
                <div className="w-full flex justify-center">
                  <DotLoaderSpinner loading={loading} />
                </div>
              ) : (
                <tbody className="divide-y divide-gray-200 bg-white">
                  {data?.map((order: any) => {
                    return (
                      <tr
                        key={order.shipEntegraOrderId}
                        className="even:bg-[#EDEEF8]"
                      >
                        <td className="px-4 py-4 text-xs md:text-sm text-center text-gray-500">
                          <div className=" flex justify-center items-center space-x-2 h-full">
                            <p className="w-56">{order.orderId}</p>
                          </div>
                        </td>
                        <td className="px-4 py-4 text-xs md:text-sm text-center text-gray-500 ">
                          {order.image_url ? <img
                            src={order.image_url}
                            alt="product_image"
                            className="object-cover aspect-square rounded-lg"
                          /> : '-'}
                        </td>
                        <td className="px-4 py-4 text-xs md:text-sm text-center text-gray-500 ">
                          {order.ship_to_name}
                        </td>
                        <td className="px-4 py-4 text-xs md:text-sm text-center text-gray-500 ">
                          {order.my_tracking_number}
                        </td>
                        <td className="px-4 py-4 text-xs md:text-sm text-center text-gray-500 ">
                          {order.order_id}
                        </td>
                        <td className="px-4 py-4 text-xs md:text-sm text-center text-gray-500 min-w-[100px]">
                          {order.acceptedAt}
                        </td>
                        {/* <td className="px-4 py-4 text-xs md:text-sm text-center text-gray-500 ">
                          <p className="w-20">{order.creationDate}</p>
                        </td> */}

                        <td className="px-4 py-4 text-xs md:text-sm text-center text-gray-500 ">
                          <div>
                            <p className="w-20">{order.ship_to_country}</p>
                          </div>
                        </td>
                        <td className="px-4 py-4 text-xs md:text-sm text-center text-gray-500 ">
                          $ {order.sentegra_price}
                        </td>
                        <td className="px-4 py-4 text-xs md:text-sm text-center text-gray-500 ">
                          {order.sku}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default ShipmentsTable;
