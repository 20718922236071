import React from 'react';
import { Icon } from 'iconsax-react';
import LoadingComponent from '../../common/LoadingComponent';

interface SmallInfoCardsProps {
  spanCount: number;
  infos: {
    icon: Icon;
    title: string;
    value: string | number;
    iconColor: string;
    titleColor: string;
    valueColor: string;
  }[];
  resultLoading: boolean;
}

// function classNames(...classes: String[]) {
//   return classes.filter(Boolean).join(' ');
// }

const SmallInfoCards: React.FC<SmallInfoCardsProps> = ({
  spanCount,
  infos,
  resultLoading,
}) => {
  return (
    <div className="grid grid-cols-5 gap-2 mt-4">
      {infos.map((infoCard, index) => (
        <div
          key={index}
          className={`col-span-${spanCount}  bg-white flex rounded-xl shadow-md`}
        >
          <div className="flex flex-col items-center text-center mx-auto py-3">
            <infoCard.icon
              className="mb-1"
              size="24"
              color={infoCard.iconColor}
            />
            <p style={{ color: infoCard.titleColor }} className="mb-1">
              {infoCard.title}{' '}
            </p>
            <div
              style={{ color: infoCard.valueColor }}
              className="mb-1 font-bold"
            >
              {resultLoading ? <LoadingComponent /> : infoCard.value}{' '}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SmallInfoCards;
