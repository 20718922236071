import React, { Fragment, useState } from 'react';

import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';

const languages = [

    {
        id: 2,
        name: 'USA',
        value: "USA",
        avatar:
            '/assets/flags/united-states.svg',
        disabled: false
    },
    {
        id: 5,
        name: 'GER',
        value: "GER",
        avatar:
            '/assets/flags/ger.png',
        disabled: true
    },
    {
        id: 9,
        name: 'UK',
        value: "UK",
        avatar:
            '/assets/flags/uk.png',
        disabled: true
    },
    {
        id: 4,
        name: 'FR',
        value: "FR",
        avatar:
            '/assets/flags/fr.png',
        disabled: true
    },
    {
        id: 3,
        name: 'CA',
        value: "CA",
        avatar:
            '/assets/flags/ca.png',
        disabled: true
    },
    {
        id: 8,
        name: 'SP',
        value: "SP",
        avatar:
            '/assets/flags/sp.png',
        disabled: true
    },
    {
        id: 6,
        name: 'IT',
        value: "IT",
        avatar:
            '/assets/flags/it.png',
        disabled: true
    },
    {
        id: 1,
        name: 'AU',
        value: "AU",
        avatar:
            '/assets/flags/au.png',
        disabled: true
    },
    {
        id: 7,
        name: 'MX',
        value: "MX",
        avatar:
            '/assets/flags/mx.png',
        disabled: true
    },
]

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
}

export default function Regions() {
    const [selected, setSelected] = useState(languages[0])

    return (
        <Listbox value={selected} onChange={setSelected}>
            {({ open }) => (
                <>
                    <div className="relative mt-2 mr-5 w-full">
                        <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 sm:text-sm sm:leading-6">
                            <span className="flex items-center">
                                <img src={selected.avatar} alt="" className="h-5 w-5 flex-shrink-0 rounded-full" />
                                <span className="ml-3 block truncate">{selected.name}</span>
                            </span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                            </span>
                        </Listbox.Button>

                        <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="absolute z-10 mt-1 w-full rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {languages.map((language) => (
                                    <Listbox.Option
                                        key={language.id}
                                        disabled={language.disabled}
                                        // onClick={() => updateLanguage?.(language.value)}
                                        className={({ active }) =>
                                            classNames(
                                                active ? 'bg-indigo-600 text-white' : 'text-gray-900',
                                                language.disabled ? 'bg-lightGray cursor-not-allowed' : 'cursor-pointer',
                                                'relative cursor-default select-none py-2 pl-3 pr-9'
                                            )
                                        }
                                        value={language}
                                    >
                                        {({ selected, active }) => (
                                            <>
                                                <div className="flex items-center">
                                                    <img src={language.avatar} alt="" className="h-5 w-5 flex-shrink-0 rounded-full" />
                                                    <span
                                                        className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                                                    >
                                                        {language.name}
                                                    </span>
                                                </div>

                                                {selected ? (
                                                    <span
                                                        className={classNames(
                                                            active ? 'text-white' : 'text-indigo-600',
                                                            'absolute inset-y-0 right-0 flex items-center pr-4'
                                                        )}
                                                    >
                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>
            )}
        </Listbox>
    )
}