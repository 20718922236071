import { Formik } from 'formik';
import React from 'react';
import { ToastContainer } from 'react-toastify';
import * as Yup from 'yup';

import { useAuth } from '../../context/AuthContext';
import { useLanguage } from '../../context/LanguageContext';
import producerService from '../../services/producerService';
import alertNotification from '../../utils/alertNotification';
import { errorElement } from '../../utils/errorElement';

export interface ProducerInfos {
  producer: {
    discountRate: string | number;
    location: string;
    logo: string;
    iamges: [url: {}];
    companyName: string;
    phoneNumber: number | string;
    storeName: string;
    producerRating: string | number;
    producerName: string;
    productCount: string | number;
    productRatingCount: string | number;
    productReviewCount: string | number;
    status: 'active' | 'passive' | string;
    userId: number;
  };
}

const UpdateCompany: React.FC<ProducerInfos> = ({ producer }) => {
  const { session } = useAuth();
  const { t } = useLanguage();

  const onSubmit = async (values: any) => {
    // let { phoneNumber, discountRate } = values;
    // if (!phoneNumber.toString().startsWith('0')) {
    //   phoneNumber = '0' + phoneNumber;
    // }
    // if (!phoneNumber.toString().startsWith('9')) {
    //   phoneNumber = '9' + phoneNumber;
    // }
    // const data = { phoneNumber: phoneNumber, discountRate: discountRate };
    // console.log(data);

    await producerService
      .updateProducerInfo(Number(session?.producerId), values)
      .then((res) => {
        // console.log(res);
        alertNotification('success', 'Bilgileriniz başarıyla güncellendi');
      })
      .catch((err) => {
        alertNotification('error', err.response.data.message);
      });
  };

  return (
    <div className="-mt-[30px] flex flex-col w-full flex-shrink-0 bg-white rounded-xl shadow-lg">
      <ToastContainer />
      <div className="border-b-2 border-lightGray p-5 sm:p-7">
        <h3 className="font-medium text-headingPrimary cardHeading">
          Şirket Bilgisi Güncelle
        </h3>
      </div>
      <div className="p-5 sm:p-7">
        <Formik
          initialValues={{
            producerName: producer.producerName ? producer.producerName : '',
            discountRate: producer.discountRate ? producer.discountRate : 0,
          }}
          onSubmit={onSubmit}
          validationSchema={Yup.object({
            discountRate: Yup.number()
              .required(t?.('registerAsSellerModal.discountWarning'))
              .min(50, t?.('registerAsSellerModal.discountRateWarning')),
          })}
        >
          {({ handleSubmit, values, handleChange, errors }) => (
            <form className="flex  flex-col gap-y-3" onSubmit={handleSubmit}>
              <div className="flex justify-between items-center gap-x-3 sm:gap-x-1.5">
                <label className="font-medium text-xs w-2/12 xl:text-sm lg:w-4/12">
                  Mağaza
                </label>
                <div className="flex flex-1 justify-between gap-x-2">
                  <input
                    disabled={true}
                    value={values.producerName}
                    type="text"
                    name="producerName"
                    id="producerName"
                    onChange={handleChange}
                    className="flex w-full rounded-md text-xs bg-backgroundDefault border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-headingPrimary sm:text-sm sm:leading-6 "
                  />
                </div>
              </div>
              {errors.producerName && errorElement(errors.producerName)}

              <div className="flex justify-between items-center gap-x-3 sm:gap-x-1.5">
                <label className="font-medium text-xs w-2/12 xl:text-sm lg:w-4/12">
                  Rexven'e Özel İndirim Oranı
                </label>
                <div className="flex flex-1 justify-between gap-x-2">
                  <input
                    value={values.discountRate}
                    type="number"
                    name="discountRate"
                    id="discountRate"
                    onChange={handleChange}
                    className="flex w-full rounded-md text-xs bg-lightGray border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-headingPrimary sm:text-sm sm:leading-6 "
                  />
                </div>
              </div>
              {errors.discountRate && errorElement(errors.discountRate)}
              <div className="flex  w-full justify-end gap-x-3 ">
                <button
                  disabled={true}
                  className="text-[#939BD2] hover:text-secondary font-semibold text-sm"
                >
                  {t?.('profile.passwordChange.form.discard')}
                </button>
                <button
                  type="submit"
                  className="rounded-md bg-headerPrimary py-2.5 px-8 text-xs font-semibold text-white shadow-sm hover:bg-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 md:text-sm"
                >
                  {t?.('profile.passwordChange.form.submit')}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default UpdateCompany;
