import React, { Fragment, useState } from 'react';

import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/20/solid';

import { useLanguage } from '../../../context/LanguageContext';
import { classNames } from '../../../utils/conditionalClasses';

interface CountrySelectProps {
    label: string
    options: string[]
    selectedCountry: string
    setSelectedCountry: React.Dispatch<React.SetStateAction<string>>;
}
const CountrySelect: React.FC<CountrySelectProps> = ({ label, options, selectedCountry, setSelectedCountry }) => {
    const { t, language } = useLanguage();


    return (
        <>
            <label
                className="w-full py-2 font-medium text-xs min-[1335px]:text-sm"
                htmlFor="countryId"
            >
                {label}
            </label>
            <Listbox
                value={selectedCountry}
                onChange={(country) => {
                    setSelectedCountry(country);
                }}
            >
                {({ open }) => (
                    <>
                        <div className="relative w-full">
                            <Listbox.Button className="relative w-full cursor-default rounded-md bg-white py-2 pl-3 pr-10 text-xs min-[1335px]:text-sm text-left text-gray-900 shadow-sm ring-1 ring-inset ring-cardBorder focus:outline-none ">
                                <span className="block truncate">{selectedCountry}</span>
                                <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                    <ChevronDownIcon
                                        className="h-5 w-5 text-gray-400"
                                        aria-hidden="true"
                                    />
                                </span>
                            </Listbox.Button>

                            <Transition
                                show={open}
                                as={Fragment}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                            >
                                <Listbox.Options className="absolute z-20 border-2 border-cardBorder mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-xs shadow-lg ring-1 ring-black ring-opacity-5 focus:ring-secondary focus:outline-none sm:text-sm">
                                    {options?.map((country: any) => (
                                        <Listbox.Option
                                            key={country}
                                            className={({ active }) =>
                                                classNames(
                                                    active
                                                        ? 'bg-secondary text-white'
                                                        : 'text-gray-900',
                                                    'relative cursor-default select-none py-2 pl-3 pr-9'
                                                )
                                            }
                                            value={country}
                                        >
                                            {({ selected, active }) => (
                                                <>
                                                    <span
                                                        className={classNames(
                                                            selected ? 'font-semibold' : 'font-normal',
                                                            'block truncate'
                                                        )}
                                                    >
                                                        {country}
                                                    </span>

                                                    {selected ? (
                                                        <span
                                                            className={classNames(
                                                                active ? 'text-white' : 'text-indigo-600',
                                                                'absolute inset-y-0 right-0 flex items-center pr-4'
                                                            )}
                                                        >
                                                            <CheckIcon
                                                                className="h-5 w-5"
                                                                aria-hidden="true"
                                                            />
                                                        </span>
                                                    ) : null}
                                                </>
                                            )}
                                        </Listbox.Option>
                                    ))}
                                </Listbox.Options>
                            </Transition>
                        </div>
                    </>
                )}
            </Listbox>
        </>
    )
}

export default CountrySelect