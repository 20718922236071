import { Flash, Shop, Strongbox2, Wallet2, Receipt2, ReceiptDiscount, PercentageSquare, DollarCircle, TruckFast } from 'iconsax-react';
import { seperateNumberWithComma } from '../../utils/numberFormatter';

const nonDividableTitles = ["Kar Marjı", "Kar Oranı"];

export const DummySummaryDatas = [
    {
        iconColor: "#820CF8",
        iconName: Strongbox2,
        titleColor: "#4338CA",
        titleText: "Toplam Ciro",
        amountColor: "#4338CA",
        amountText: seperateNumberWithComma(29467)
    },
    {
        iconColor: "#34D399",
        iconName: Wallet2,
        titleColor: "#047857",
        titleText: "Toplam Kar/Zarar",
        amountColor: "#047857",
        amountText: seperateNumberWithComma(8235)
    },
    {
        iconColor: "#FBBF24",
        iconName: Receipt2,
        titleColor: "#B45309",
        titleText: "Reklam Maliyeti",
        amountColor: "#B45309",
        amountText: seperateNumberWithComma(1100)
    },
    {
        iconColor: "#A78BFA",
        iconName: Flash,
        titleColor: "#6D28D9",
        titleText: "Ürün Maliyeti",
        amountColor: "#6D28D9",
        amountText: seperateNumberWithComma(1820)
    },
    {
        iconColor: "#F87171",
        iconName: Flash,
        titleColor: "#B91C1C",
        titleText: "İade Maliyeti",
        amountColor: "#B91C1C",
        amountText: seperateNumberWithComma(530)
    },
    {
        iconColor: "#FB923C",
        iconName: Shop,
        titleColor: "#C2410C",
        titleText: "Trendyol Kesintileri",
        amountColor: "#C2410C",
        amountText: seperateNumberWithComma(4706)
    },
    {
        iconColor: "#38BDF8",
        iconName: TruckFast,
        titleColor: "#0369A1",
        titleText: "Kargo Maliyeti",
        amountColor: "#0369A1",
        amountText: seperateNumberWithComma(12484)
    },
    {
        iconColor: "#F472B6",
        iconName: ReceiptDiscount,
        titleColor: "#BE205D",
        titleText: "KDV Maliyeti",
        amountColor: "#BE205D",
        amountText: seperateNumberWithComma(3397)
    },
    {
        iconColor: "#2DD4BF",
        iconName: PercentageSquare,
        titleColor: "#0F766E",
        titleText: "Kar Marjı",
        amountColor: "#0F766E",
        amountText: 27
    },
    {
        iconColor: "#3B82F6",
        iconName: DollarCircle,
        titleColor: "#1D4ED8",
        titleText: "Kar Oranı",
        amountColor: "#1D4ED8",
        amountText: 45
    },
]
const calculateAmount = (data: any, factor: number) => {
    if (nonDividableTitles.includes(data.titleText)) return data.amountText;
    if (typeof data.amountText === 'string') {
        return seperateNumberWithComma(Math.round(Number(data.amountText.replace(/,/g, '')) * factor));
    } else {
        return Math.round(data.amountText * factor);
    }
}

export const DailyDummySummaryDatas = DummySummaryDatas.map(data => ({
    ...data,
    amountText: calculateAmount(data, 0.03)
}));

export const WeeklyDummySummaryDatas = DummySummaryDatas.map(data => ({
    ...data,
    amountText: calculateAmount(data, 0.25)
}));

export const QuarterDummySummaryDatas = DummySummaryDatas.map(data => ({
    ...data,
    amountText: calculateAmount(data, 3)
}));

export interface Conditions {
    title: string,
    value: string | number
    titleStyle?: string
}

export const DummyConditions: Conditions[] = [
    {
        title: "Toplam Ciro", value: seperateNumberWithComma(29467), titleStyle: "font-semibold"
    },
    { title: "Trendyol Kesintileri", value: seperateNumberWithComma(4706) },
    { title: " • Trendyol Komisyonu", value: seperateNumberWithComma(4236) },
    { title: " • Hizmet Bedeli", value: seperateNumberWithComma(470) },
    { title: "Kargo Maliyeti", value: seperateNumberWithComma(12484) },
    { title: "Ürün Maliyeti", value: seperateNumberWithComma(1820) },
    { title: "Reklam Maliyeti", value: seperateNumberWithComma(1100) },
    { title: "İade Maliyeti", value: seperateNumberWithComma(530) },
    { title: "KDV Maliyeti", value: seperateNumberWithComma(3397) },
    { title: "Net Kar", value: seperateNumberWithComma(8235), titleStyle: "font-semibold", },
    { title: "Kar Marjı", value: 27, titleStyle: "font-semibold", },
    { title: "Kar Oranı", value: 45, titleStyle: "font-semibold", },
]
const calculateValue = (condition: any, factor: number) => {
    if (nonDividableTitles.includes(condition.title)) return condition.value;
    if (typeof condition.value === 'string') {
        return seperateNumberWithComma(Math.round(Number(condition.value.replace(/,/g, '')) * factor));
    } else {
        return Math.round(condition.value * factor);
    }
}

export const WeeklyDummyConditions = DummyConditions.map(condition => ({
    ...condition,
    value: calculateValue(condition, 0.25)
}));

export const DailyDummyConditions = DummyConditions.map(condition => ({
    ...condition,
    value: calculateValue(condition, 0.3)
}));

export const QuarterDummyConditions = DummyConditions.map(condition => ({
    ...condition,
    value: calculateValue(condition, 3)
}));
export interface ConditionsFull {
    title?: string,
    value?: string | number
    titleStyle?: string
    descriptionTitle?: string,
    descriptionValue?: string,

}

export const DummyConditionsFull: ConditionsFull[] = [
    {
        title: "Toplam Ciro", value: seperateNumberWithComma(29467), titleStyle: "font-semibold"
    },
    { title: "Trendyol Kesintileri", value: seperateNumberWithComma(4706) },
    { title: " • Trendyol Komisyonu", value: seperateNumberWithComma(4236) },
    { title: " • Hizmet Bedeli", value: seperateNumberWithComma(470) },
    { title: "Kargo Maliyeti", value: seperateNumberWithComma(12484) },
    { title: "Ürün Maliyeti", value: seperateNumberWithComma(1820) },
    { title: "Reklam Maliyeti", value: seperateNumberWithComma(1100) },
    { title: "İade Maliyeti", value: seperateNumberWithComma(530) },
    { title: "KDV Maliyeti", value: seperateNumberWithComma(3397) },
    { title: "Net Kar", value: seperateNumberWithComma(8235), titleStyle: "font-semibold", },
    { title: "Kar Marjı", value: 27, titleStyle: "font-semibold", },
    { title: "Kar Oranı", value: 45, titleStyle: "font-semibold", },
    {
        descriptionTitle: "KDV Maliyeti =",
        descriptionValue: "Satıştan oluşan KDV - Ürün maliyetinden oluşan KDV - Kargodan oluşan KDV - Komisyondan oluşan KDV - Hizmet bedelinden oluşan KDV - Reklamdan Olsuşan KDV",
    },
    {
        descriptionTitle: "Satıştan Oluşan KDV =",
        descriptionValue: "Satış Fiyatı / 120 * 20",
    }, {
        descriptionTitle: "Ürün Maliyetinden Oluşan KDV =",
        descriptionValue: "Ürün maliyeti / 120 * 20",
    }, {
        descriptionTitle: "Kargodan Oluşan KDV =",
        descriptionValue: "Kargo fiyatı / 120 * 20",
    }, {
        descriptionTitle: "Komisyondan oluşan KDV =",
        descriptionValue: "Komisyon maliyeti / 120 * 20",
    }, {
        descriptionTitle: "Hizmet bedelinden oluşan KDV =",
        descriptionValue: "Hizmet bedeli / 120 * 20",
    }, {
        descriptionTitle: "Reklamdan oluşan KDV =",
        descriptionValue: "Reklam bedeli / 120 * 20",
    },

]


const multiplyNumericValue = (condition: any, factor: any) => {
    if (condition.value && !nonDividableTitles.includes(condition.title)) {
        return {
            ...condition,
            value: typeof condition.value === 'string'
                ? seperateNumberWithComma(Math.round(Number(condition.value.replace(/,/g, '')) * factor))
                : Math.round(condition.value * factor)
        };
    }
    return condition;
}


export const DailyDummyConditionsFull = DummyConditionsFull.map(condition => multiplyNumericValue(condition, 1 / 30));

export const WeeklyDummyConditionsFull = DummyConditionsFull.map(condition => multiplyNumericValue(condition, 1 / 4));

export const QuarterDummyConditionsFull = DummyConditionsFull.map(condition => multiplyNumericValue(condition, 3));
export interface WeeklyValues {
    week: string;
    total_revenue: string;
}


//generate dummy data for bar chart
const generateWeeks = (numberOfWeeks: number): string[] => {
    const weeks: string[] = [];

    for (let i = 0; i < numberOfWeeks; i++) {
        const date = new Date();
        date.setDate(date.getDate() - i * 7); // One week back
        const formattedDate = date.toLocaleDateString('tr-TR', { day: '2-digit', month: 'long' });
        weeks.push(formattedDate);
    }

    return weeks;
}

const getRandomRevenue = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const DummyWeeklyValues = generateWeeks(12).map((week) => ({
    week,
    total_revenue: getRandomRevenue(5000, 20000).toString(),
}));


export const cardTitles = [
    { title: "Bugün", date: "08 Mart 2023", value: 23 },
    { title: "Son 7 Gün", date: "01 Mart 2023 - 08 Mart 2023", value: 1234 },
    { title: "Son 30 Gün", date: "08 Şubat 2023 - 08 Mart 2023", value: 123 },
    { title: "Son 3 Ay", date: "08 Şubat 2022 - 08 Mart 2023", value: 5674 },

]

export interface DaysType {
    today: string,
    sevenDaysAgo: string,
    thirtyDaysAgo: string,
    ninetyDaysAgo: string
}
export interface DetailsData {
    status: string;
    data: {
        totalRevenue: number;
        productCost: number;
        adCost: number;
        trCut: number;
        shipCost: number;
        returnCost: number;
        totalVat: number;
        profitOrLoss: number;
        profitMargin: number;
        profitRate: number;
    };
}
export interface SummaryItem {
    iconColor: string;
    iconName: any;
    titleColor: string;
    titleText: string;
    amountColor: string;
    amountText: number | string;
};
export interface Data {
    totalRevenue?: number;
    productCost?: number;
    adCost?: number;
    trCut?: number;
    shipCost?: number;
    returnCost?: number;
    totalVat?: number;
    profitOrLoss?: number;
    profitMargin?: number;
    profitRate?: number;
    serviceCost?: number
    [key: string]: number | undefined;
}

export interface RevenueData {
    revenue: number;
    profitOrLoss: number;
    orderCount: number;
    costs: number;
    profitMargin: number;
    returnCount: number;
    saleCount: number
};

export interface FinancialData {
    totalRevenue: RevenueData;
    totalCost: number;
    profitOrLoss: number;
    profitMargin: number;
};

export interface ReportData {
    day: FinancialData;
    week: FinancialData;
    month: FinancialData;
    quarter: FinancialData;
};

export interface DaysDataType {
    loading: boolean;
    data: ReportData;
    error: boolean;
}
