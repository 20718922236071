import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="flex flex-col max-w-[1280px] mx-auto py-10">
      <p className="mb-4 font-medium">
        REXVEN GİZLİLİK VE G&Uuml;VENLİK POLİTİKASI
      </p>
      <p className="mb-4">
        Sitemizde (rexven.com) verilen t&uuml;m servisler Mutlukent Mah. 2041 Sok. No :5/1
        &Ccedil;ankaya/Ankara adresinde kayıtlı
        Rexven Teknoloji A.Ş.&rsquo;ye (&lsquo;Rexven&rsquo; veya
        &lsquo;Şirket&rsquo;) aittir ve Şirket tarafından işletilir.&nbsp;
      </p>
      <p className="mb-4">
        Şirket, &ccedil;eşitli ama&ccedil;larla kişisel veriler toplayabilir.
        Aşağıda ve sitemizde bulunan Kişisel Verilerin Korunmasına İlişkin
        Aydınlatma Metni&rsquo;nde, toplanan kişisel verilerin nasıl ve ne
        şekilde toplandığı, bu verilerin nasıl ve ne şekilde korunduğu
        belirtilmiştir.&nbsp;
      </p>
      <p className="mb-4">
        &Uuml;yelik veya Sitemiz &uuml;zerindeki &ccedil;eşitli form ve
        anketlerin doldurulması suretiyle &uuml;yelerin kendileriyle ilgili bir
        takım kişisel bilgileri (isim-soy isim, firma bilgileri, telefon, adres
        veya e-posta adresleri gibi) Şirketimiz tarafından işin doğası gereği
        toplanmaktadır.&nbsp;
      </p>
      <p className="mb-4">
        Şirketimiz bazı d&ouml;nemlerde m&uuml;şterilerine ve &uuml;yelerine
        kampanya bilgileri, yeni &uuml;r&uuml;nler hakkında bilgiler, promosyon
        teklifleri g&ouml;nderebilir. &Uuml;yelerimiz bu gibi bilgileri alıp
        almama konusunda her t&uuml;rl&uuml; se&ccedil;imi &uuml;ye olurken
        yapabilir, sonrasında &uuml;ye girişi yaptıktan sonra hesap bilgileri
        b&ouml;l&uuml;m&uuml;nden bu se&ccedil;imi değiştirilebilir ya da
        kendisine gelen bilgilendirme iletisindeki linkle bildirim
        yapabilir.&nbsp;
      </p>
      <p className="mb-4">
        Sitemiz &uuml;zerinden veya e posta ile ger&ccedil;ekleştirilen onay
        s&uuml;recinde, &uuml;yelerimiz tarafından mağazamıza elektronik
        ortamdan iletilen kişisel bilgiler, &Uuml;yelerimiz ile yaptığımız
        &ldquo;Kullanıcı S&ouml;zleşmesi&rdquo; ve &ldquo;Aydınlatma
        Metni&rdquo; ile belirlenen ama&ccedil;lar ve kapsam dışında
        &uuml;&ccedil;&uuml;nc&uuml; kişilere a&ccedil;ıklanmayacaktır.
      </p>
      <p className="mb-4">
        Sistemle ilgili sorunların tanımlanması ve verilen hizmet ile ilgili
        &ccedil;ıkabilecek sorunların veya uyuşmazlıkların hızla
        &ccedil;&ouml;z&uuml;lmesi i&ccedil;in, Şirket, &uuml;yelerinin IP
        adresini kaydetmekte ve bunu kullanmaktadır. IP adresleri, kullanıcıları
        genel bir şekilde tanımlamak ve kapsamlı demografik bilgi toplamak
        amacıyla da kullanılabilir.
      </p>
      <p className="mb-4">
        Şirket, &Uuml;yelik S&ouml;zleşmesi ile belirlenen ama&ccedil;lar ve
        kapsam dışında da, talep edilen bilgileri kendisi veya işbirliği
        i&ccedil;inde olduğu kişiler tarafından doğrudan pazarlama yapmak
        amacıyla kullanabilir. &nbsp;Kişisel bilgiler, gerektiğinde kullanıcıyla
        temas kurmak i&ccedil;in de kullanılabilir. Şirket tarafından talep
        edilen bilgiler veya kullanıcı tarafından sağlanan bilgiler veya Site
        &uuml;zerinden yapılan işlemlerle ilgili bilgiler; Şirket ve işbirliği
        i&ccedil;inde olduğu kişiler tarafından, &ldquo;&Uuml;yelik
        S&ouml;zleşmesi&rdquo; ile belirlenen ama&ccedil;lar ve kapsam dışında
        da, &uuml;yelerimizin kimliği ifşa edilmeden &ccedil;eşitli
        istatistiksel değerlendirmeler, veri tabanı oluşturma ve pazar
        araştırmalarında kullanılabilir.
      </p>
      <p className="mb-4">
        Şirket, gizli bilgileri kesinlikle &ouml;zel ve gizli tutmayı, bunu bir
        sır saklama y&uuml;k&uuml;m&uuml; olarak addetmeyi ve gizliliğin
        sağlanması ve s&uuml;rd&uuml;r&uuml;lmesi, gizli bilginin tamamının veya
        herhangi bir kısmının kamu alanına girmesini veya yetkisiz kullanımını
        veya &uuml;&ccedil;&uuml;nc&uuml; bir kişiye ifşasını &ouml;nlemek
        i&ccedil;in gerekli t&uuml;m tedbirleri almayı ve gerekli &ouml;zeni
        g&ouml;stermeyi taahh&uuml;t etmektedir. (bkn. Kişisel verilerin
        Korunmasına İlişkin Aydınlatma Metni)
      </p>
      <p className="mb-4 font-medium">KREDİ KARTI G&Uuml;VENLİĞİ</p>
      <p className="mb-4">
        Şirket, sitemizden alışveriş yapan kredi kartı sahiplerinin
        g&uuml;venliğini ilk planda tutmaktadır. Kredi kartı bilgileriniz
        hi&ccedil;bir şekilde sistemimizde saklanmamaktadır. (&Ouml;deme İyzico
        &ouml;deme altyapısı &uuml;zerinden alınmaktadır. bkn. Mesafeli Satış
        S&ouml;zleşmesi)
      </p>
      <p className="mb-4">
        İşlemler s&uuml;recine girdiğinizde g&uuml;venli bir sitede olduğunuzu
        anlamak i&ccedil;in dikkat etmeniz gereken iki şey vardır. Bunlardan
        biri tarayıcınızın en alt satırında bulunan bir anahtar ya da kilit
        simgesidir. Bu g&uuml;venli bir internet sayfasında olduğunuzu
        g&ouml;sterir ve her t&uuml;rl&uuml; bilgileriniz şifrelenerek korunur.
        Bu bilgiler, ancak satış işlemleri s&uuml;recine bağlı olarak ve
        verdiğiniz talimat istikametinde kullanılır. Alışveriş sırasında
        kullanılan kredi kartı ile ilgili bilgiler sitemizden bağımsız olarak
        128 bit SSL (Secure Sockets Layer) protokol&uuml; ile şifrelenip
        sorgulanmak &uuml;zere ilgili bankaya ulaştırılır. Kartın
        kullanılabilirliği onaylandığı takdirde alışverişe devam edilir. Kartla
        ilgili hi&ccedil;bir bilgi tarafımızdan
        g&ouml;r&uuml;nt&uuml;lenemediğinden ve kaydedilmediğinden,
        &uuml;&ccedil;&uuml;nc&uuml; şahısların herhangi bir koşulda bu
        bilgileri ele ge&ccedil;irmesi engellenmiş olur.
        <br />
        Online olarak kredi kartı ile verilen siparişlerin
        &ouml;deme/fatura/teslimat adresi bilgilerinin g&uuml;venilirliği Şirket
        tarafından Kredi Kartları Dolandırıcılığı&rsquo;na karşı
        denetlenmektedir. Bu y&uuml;zden, alışveriş sitelerimizden ilk defa
        sipariş veren m&uuml;şterilerin siparişlerinin tedarik ve teslimat
        aşamasına gelebilmesi i&ccedil;in &ouml;ncelikle finansal ve
        adres/telefon bilgilerinin doğruluğunun onaylanması gereklidir. Bu
        bilgilerin kontrol&uuml; i&ccedil;in gerekirse kredi kartı sahibi
        m&uuml;şteri ile veya ilgili banka ile irtibata ge&ccedil;ilmektedir.
        <br />
        &Uuml;ye olurken verdiğiniz t&uuml;m bilgilere sadece siz ulaşabilir ve
        siz değiştirebilirsiniz. &Uuml;ye giriş bilgilerinizi g&uuml;venli
        koruduğunuz takdirde başkalarının sizinle ilgili bilgilere ulaşması ve
        bunları değiştirmesi m&uuml;mk&uuml;n değildir. Bu ama&ccedil;la,
        &uuml;yelik işlemleri sırasında 128 bit SSL g&uuml;venlik alanı
        i&ccedil;inde hareket edilir. Bu sistem kırılması m&uuml;mk&uuml;n
        olmayan bir uluslararası bir şifreleme standardıdır.
      </p>
      <p className="mb-4">
        Bilgi hattı veya m&uuml;şteri hizmetleri servisi bulunan ve a&ccedil;ık
        adres ve telefon bilgilerinin belirtildiği İnternet alışveriş siteleri
        g&uuml;n&uuml;m&uuml;zde daha fazla tercih edilmektedir. Bu sayede
        aklınıza takılan b&uuml;t&uuml;n konular hakkında detaylı bilgi
        alabilir, online alışveriş hizmeti sağlayan firmanın g&uuml;venirliği
        konusunda daha sağlıklı bilgi edinebilirsiniz.&nbsp;
      </p>
      <p className="mb-4">
        Not: İnternet alışveriş sitelerinde firmanın a&ccedil;ık adresinin ve
        telefonun yer almasına dikkat edilmesini tavsiye ediyoruz. Alışveriş
        yapacaksanız alışverişinizi yapmadan &uuml;r&uuml;n&uuml; aldığınız
        mağazanın b&uuml;t&uuml;n telefon / adres bilgilerini not edin. Eğer
        g&uuml;venmiyorsanız alışverişten &ouml;nce telefon ederek teyit edin.
        Şirketimize ait t&uuml;m bilgiler sitemizde belirtilmiştir.
      </p>
      <p className="mb-4">
        <strong>
          &Uuml;&Ccedil;&Uuml;NC&Uuml; TARAF WEB SİTELERİ VE UYGULAMALAR
        </strong>
        <br />
        <br />
        Sitemiz, &nbsp;web sitesi d&acirc;hilinde başka sitelere link verebilir.
        Şirket, bu linkler vasıtasıyla erişilen sitelerin gizlilik uygulamaları
        ve i&ccedil;eriklerine y&ouml;nelik herhangi bir sorumluluk
        taşımamaktadır. Şirkete ait sitede yayınlanan reklamlar, reklamcılık
        yapan iş ortaklarımız aracılığı ile kullanıcılarımıza dağıtılır. İş bu
        s&ouml;zleşmedeki Gizlilik Politikası Prensipleri, sadece Sitemizin
        kullanımına ilişkindir, &uuml;&ccedil;&uuml;nc&uuml; taraf web
        sitelerini kapsamaz.&nbsp;
      </p>
      <p className="mb-4">
        <strong>İSTİSNAİ HALLER</strong>
        <br />
        <br />
        Aşağıda belirtilen sınırlı hallerde Şirket, işbu &ldquo;Gizlilik
        Politikası&rdquo; h&uuml;k&uuml;mleri dışında kullanıcılara ait
        bilgileri &uuml;&ccedil;&uuml;nc&uuml; kişilere a&ccedil;ıklayabilir. Bu
        durumlar sınırlı sayıda olmak &uuml;zere;
        <br />
        1.Kanun, Kanun H&uuml;km&uuml;nde Kararname, Y&ouml;netmelik v.b.
        yetkili hukuki otorite tarafından &ccedil;ıkarılan ve
        y&uuml;r&uuml;rl&uuml;l&uuml;kte olan hukuk kurallarının getirdiği
        zorunluluklara uymak;
        <br />
        2.Sitede yer alan kullanıcılarla akdettiği &ldquo;&Uuml;yelik
        S&ouml;zleşmesi&rdquo;&lsquo;nin ve diğer s&ouml;zleşmelerin gereklerini
        yerine getirmek ve bunları uygulamaya koymak amacıyla;
        <br />
        3.Yetkili idari ve adli otorite tarafından usul&uuml;ne g&ouml;re
        y&uuml;r&uuml;t&uuml;len bir araştırma veya soruşturmanın
        y&uuml;r&uuml;t&uuml;m&uuml; amacıyla kullanıcılarla ilgili bilgi talep
        edilmesi;
        <br />
        4.Kullanıcıların hakları veya g&uuml;venliklerini korumak i&ccedil;in
        bilgi vermenin gerekli olduğu hallerdir.&nbsp;
      </p>
      <p className="mb-4">
        <strong> E-POSTA G&Uuml;VENLİĞİ</strong>
        <br />
        Sitemizin M&uuml;şteri Hizmetleri&rsquo;ne, herhangi bir siparişinizle
        ilgili olarak g&ouml;ndereceğiniz e-postalarda, asla kredi kartı
        numaranızı veya şifrelerinizi yazmayınız. E-postalarda yer alan bilgiler
        &uuml;&ccedil;&uuml;nc&uuml; şahıslar tarafından g&ouml;r&uuml;lebilir.
        Firmamız e-postalarınızdan aktarılan bilgilerin g&uuml;venliğini
        hi&ccedil;bir koşulda garanti edemez.
      </p>
      <p className="mb-4">
        <strong>TARAYICI &Ccedil;EREZLERİ&nbsp;</strong>
        <br />
        Şirket, sitemizi ziyaret eden kullanıcılar ve kullanıcıların web
        sitesini kullanımı hakkındaki bilgileri teknik bir iletişim dosyası
        (&Ccedil;erez-Cookie) kullanarak elde edebilir. Bahsi ge&ccedil;en
        teknik iletişim dosyaları, ana bellekte saklanmak &uuml;zere bir
        internet sitesinin kullanıcının tarayıcısına (br/owser) g&ouml;nderdiği
        k&uuml;&ccedil;&uuml;k metin dosyalarıdır. Teknik iletişim dosyası site
        hakkında durum ve tercihleri saklayarak İnternet&rsquo;in kullanımını
        kolaylaştırır.
      </p>
      <p className="mb-4">
        Teknik iletişim dosyası, &nbsp;siteyi ka&ccedil; kişinin ziyaret
        ettiğini, bir kişinin siteyi hangi ama&ccedil;la, ka&ccedil; kez ziyaret
        ettiğini ve ne kadar sitede kaldıkları hakkında istatistiksel bilgileri
        elde etmeye ve kullanıcılar i&ccedil;in &ouml;zel tasarlanmış kullanıcı
        sayfalarından &nbsp;dinamik olarak reklam ve i&ccedil;erik
        &uuml;retilmesine yardımcı olur. Teknik iletişim dosyası, ana bellekte
        veya e-postanızdan veri veya başkaca herhangi bir kişisel bilgi almak
        i&ccedil;in tasarlanmamıştır. Tarayıcıların pek &ccedil;oğu başta teknik
        iletişim dosyasını kabul eder bi&ccedil;imde tasarlanmıştır ancak
        kullanıcılar dilerse teknik iletişim dosyasının gelmemesi veya teknik
        iletişim dosyasının g&ouml;nderildiğinde uyarı verilmesini sağlayacak
        bi&ccedil;imde ayarları değiştirebilirler.
      </p>
      <p className="mb-4">
        Şirket, işbu &ldquo;Gizlilik Politikası&rdquo; h&uuml;k&uuml;mlerini
        dilediği zaman sitede yayınlamak veya kullanıcılara elektronik posta
        g&ouml;ndermek veya sitesinde yayınlamak suretiyle değiştirebilir.
        Gizlilik Politikası h&uuml;k&uuml;mleri değiştiği takdirde, yayınlandığı
        tarihte y&uuml;r&uuml;rl&uuml;k kazanır.
      </p>
      <p className="mb-4">
        Gizlilik politikamız ile ilgili her t&uuml;rl&uuml; soru ve
        &ouml;nerileriniz i&ccedil;in info@rexven.com adresine e mail
        g&ouml;nderebilirsiniz. Şirkete, aşağıdaki iletişim bilgilerinden
        ulaşabilirsiniz.
      </p>
      <p className="mb-4">Firma &Uuml;nvanı: Rexven Teknoloji A.Ş.</p>
      <p className="mb-4">
        Adres: Mutlukent Mah. 2041 Sok. No :5/1
        &Ccedil;ankaya/Ankara
      </p>
      <p className="mb-4">Eposta: info@rexven.com</p>
      <p className="mb-4">Tel: 0850 532 35 76</p>
      <p className="mb-4">
        <br />
      </p>
      <p className="mb-4">
        <br />
      </p>
    </div>
  );
};

export default PrivacyPolicy;
