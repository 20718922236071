import React from 'react';
import { useLanguage } from '../../context/LanguageContext';
import LevelDescripCard from './LevelDescripCard';

const LevelDescriptionCardWrapper: React.FC = () => {
  const { t } = useLanguage();
  const levelDescriptions = [
    {
      id: 1,
      title: t?.('roadmap.level-desc.first.title'),
      content: [
        t?.('roadmap.level-desc.first.first'),
        t?.('roadmap.level-desc.first.second'),
        t?.('roadmap.level-desc.first.third'),
        t?.('roadmap.level-desc.first.fourth'),
        t?.('roadmap.level-desc.first.fifth'),
        t?.('roadmap.level-desc.first.sixth'),
        t?.('roadmap.level-desc.first.seventh'),
        t?.('roadmap.level-desc.first.eighth'),
        t?.('roadmap.level-desc.first.ninth'),
        t?.('roadmap.level-desc.first.tenth'),
        t?.('roadmap.level-desc.first.eleventh'),
        t?.('roadmap.level-desc.first.twelfth'),
        t?.('roadmap.level-desc.first.thirteenth'),
        t?.('roadmap.level-desc.first.fourteenth'),
      ],
      background: '#FFF5E5',
    },
    {
      id: 2,
      title: t?.('roadmap.level-desc.second.title'),
      content: [
        t?.('roadmap.level-desc.second.first'),
        t?.('roadmap.level-desc.second.second'),
        t?.('roadmap.level-desc.second.third'),
        t?.('roadmap.level-desc.second.fourth'),
        t?.('roadmap.level-desc.second.fifth'),
        t?.('roadmap.level-desc.second.sixth'),
        t?.('roadmap.level-desc.second.seventh'),
        t?.('roadmap.level-desc.second.eighth'),
        t?.('roadmap.level-desc.second.ninth'),
        t?.('roadmap.level-desc.second.tenth'),
        t?.('roadmap.level-desc.second.eleventh'),
        t?.('roadmap.level-desc.second.twelfth'),
      ],
      background: '#FFEFE5',
    },
    {
      id: 3,
      title: t?.('roadmap.level-desc.third.title'),
      content: [
        t?.('roadmap.level-desc.third.first'),
        t?.('roadmap.level-desc.third.second'),
        t?.('roadmap.level-desc.third.third'),
        t?.('roadmap.level-desc.third.fourth'),
        t?.('roadmap.level-desc.third.fifth'),
        t?.('roadmap.level-desc.third.sixth'),
        t?.('roadmap.level-desc.third.seventh'),
        t?.('roadmap.level-desc.third.eighth'),
        t?.('roadmap.level-desc.third.ninth'),
        t?.('roadmap.level-desc.third.tenth'),
        t?.('roadmap.level-desc.third.eleventh'),
        t?.('roadmap.level-desc.third.twelfth'),
      ],
      background: '#FCE9E8',
    },
    {
      id: 4,
      title: t?.('roadmap.level-desc.fourth.title'),
      content: [
        t?.('roadmap.level-desc.fourth.first'),
        t?.('roadmap.level-desc.fourth.second'),
        t?.('roadmap.level-desc.fourth.third'),
        t?.('roadmap.level-desc.fourth.fourth'),
        t?.('roadmap.level-desc.fourth.fifth'),
        t?.('roadmap.level-desc.fourth.sixth'),
        t?.('roadmap.level-desc.fourth.seventh'),
        t?.('roadmap.level-desc.fourth.eighth'),
        t?.('roadmap.level-desc.fourth.ninth'),
        t?.('roadmap.level-desc.fourth.tenth'),
      ],
      background: '#FFEBCC',
    },
    {
      id: 5,
      title: t?.('roadmap.level-desc.fifth.title'),
      content: [
        t?.('roadmap.level-desc.fifth.first'),
        t?.('roadmap.level-desc.fifth.second'),
        t?.('roadmap.level-desc.fifth.third'),
        t?.('roadmap.level-desc.fifth.fourth'),
        t?.('roadmap.level-desc.fifth.fifth'),
        t?.('roadmap.level-desc.fifth.sixth'),
        t?.('roadmap.level-desc.fifth.seventh'),
        t?.('roadmap.level-desc.fifth.eighth'),
        t?.('roadmap.level-desc.fifth.ninth'),
        t?.('roadmap.level-desc.fifth.tenth'),
        t?.('roadmap.level-desc.fifth.eleventh'),
        t?.('roadmap.level-desc.fifth.twelfth'),
        t?.('roadmap.level-desc.fifth.thirteenth'),
        t?.('roadmap.level-desc.fifth.fourteenth'),
      ],
      background: '#FFDFCC',
    },
    {
      id: 6,
      title: t?.('roadmap.level-desc.sixth.title'),
      content: [
        t?.('roadmap.level-desc.sixth.first'),
        t?.('roadmap.level-desc.sixth.second'),
        t?.('roadmap.level-desc.sixth.third'),
        t?.('roadmap.level-desc.sixth.fourth'),
        t?.('roadmap.level-desc.sixth.fifth'),
        t?.('roadmap.level-desc.sixth.sixth'),
        t?.('roadmap.level-desc.sixth.seventh'),
        t?.('roadmap.level-desc.sixth.eighth'),
        t?.('roadmap.level-desc.sixth.ninth'),
        t?.('roadmap.level-desc.sixth.tenth'),
        t?.('roadmap.level-desc.sixth.eleventh'),
        t?.('roadmap.level-desc.sixth.twelfth'),
      ],
      background: '#F9D2D2',
    },
  ];
  return (
    <div className="px-1">
      <div
        data-testid="level-description-card-wrapper"
        className="mt-8 grid grid-cols-1 gap-10 overflow-hidden text-center sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3"
      >
        {levelDescriptions.map((descrip) => (
          <LevelDescripCard
            key={descrip.id}
            title={descrip.title}
            content={descrip.content}
            bGroundCol={descrip.background}
          />
        ))}
      </div>
    </div>
  );
};

export default LevelDescriptionCardWrapper;
