import React from 'react';

import AnnouncementsWrapper from './AnnouncementsWrapper';
import CoursesCard from './elements/CoursesCard';
import ProducersCard from './elements/ProducersCard';
import ServicesCard from './elements/ServicesCard';
import ToolsCard from './elements/ToolsCard';

const ToolsAndAnnouncementsWrapper = () => {
    return (
        <div className="grid grid-cols-8 gap-4 overflow-hidden mt-4">
            <div className='col-span-8 xl:col-span-8 grid grid-cols-2 gap-4'>
                <ToolsCard />
                <CoursesCard />
                <ProducersCard />
                <ServicesCard />
            </div>
            {/* <div className='col-span-8 xl:col-span-2 grid grid-rows-2 gap-4'>
                <AnnouncementsWrapper />
            </div> */}
        </div >
    )
}

export default ToolsAndAnnouncementsWrapper