import React from 'react';
export const errorElement = (message: string) => (
  <div className="rounded-md bg-red-50 p-1 my-1">
    <div className="flex">
      <div className="ml-1">
        <h3 className="text-xs text-rexRed">{message}</h3>
      </div>
    </div>
  </div>
);
