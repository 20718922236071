import React, { useRef } from 'react';
import Pagination from '../../common/Pagination';
import { classNames } from '../../../utils/conditionalClasses';
import { IAliexpressTopKeyword } from '../../../interfaces/aliexpressInterfaces';
import LoadingComponent from '../../common/LoadingComponent';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../../context/LanguageContext';

interface IAliexpressTopKeywordssQuery {
  keyword: string;
}

type Props = {
  queryParams: IAliexpressTopKeywordssQuery;
  setQueryParams: React.Dispatch<
    React.SetStateAction<IAliexpressTopKeywordssQuery>
  >;
  totalPageCount: number;
  page: number;
  isLoading: boolean;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  data: IAliexpressTopKeyword[];
  inputPage: string | number;
  setInputPage: React.Dispatch<React.SetStateAction<string | number>>;
  queryString: string;
  setQueryString: React.Dispatch<React.SetStateAction<string>>;
};

const AliexpressTopKeywordsTable = ({
  queryParams,
  setQueryParams,
  setPage,
  page,
  totalPageCount,
  isLoading,
  data,
  inputPage,
  setInputPage,
  queryString,
  setQueryString,
}: Props) => {
  const { language } = useLanguage();
  const tableHeader =
    language === 'tr'
      ? [
          { name: 'Sıra', column: 'index' },
          { name: 'Anahtar Kelime (Eng-Tr)', column: 'keywordTrEng' },
          { name: 'Anahtar Kelime (Rusça)', column: 'keywordRussian' },
          { name: 'Arayan Kullanıcı Sayısı', column: 'searchedUserCount' },
          { name: 'Kategori', column: 'category' },
          { name: 'Ortalama Sipariş Tutarı', column: 'avgOrderValue' },
          { name: 'Görüntülenme/ Arama', column: 'searchToIpv' },
          { name: 'Sepete Ekleme/ Görüntülenme', column: 'ipvToCart' },
          { name: 'Sepete Ekleme/ Arama', column: 'searchToCart' },
          { name: 'Sipariş/ Görüntülenme', column: 'ipvToOrder' },
        ]
      : [
          { name: 'No', column: 'index' },
          { name: 'Keywords (Eng-Tr)', column: 'keywordTrEng' },
          { name: 'Keywords (Russia)', column: 'keywordRussian' },
          { name: 'User Count', column: 'searchedUserCount' },
          { name: 'Category', column: 'category' },
          { name: 'Avg. Order', column: 'avgOrderValue' },
          { name: 'View / Search', column: 'searchToIpv' },
          { name: 'Add to Cart / View', column: 'ipvToCart' },
          { name: 'Add to Card / Search', column: 'searchToCart' },
          { name: 'Order/View', column: 'ipvToOrder' },
        ];

  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    setQueryParams({ keyword: String(inputRef.current?.value) });
    setPage(1);
    setInputPage(1);
    navigate(`?page=1&keyword=${String(inputRef.current?.value)}`);
  };

  return (
    <>
      <div className="flex flex-col flex-shrink-0 bg-white rounded-xl p-5 shadow-lg mt-2 sm:p-4 relative">
        {/* FILTER SECTION */}
        <form
          className="grid gap-8 grid-cols-12 pl-4 items-center pr-0 my-10"
          onSubmit={handleSubmit}
        >
          <div className="col-span-12 text-center lg:col-span-5 lg:pt-[1px] xl:pt-1 lg:text-left">
            <label
              htmlFor="productLink"
              className="text-sm font-medium text-headerPrimary"
            >
              {language === 'tr'
                ? 'Aramak İstediğiniz Kelimeyi Giriniz'
                : 'Enter the word you want to search'}
            </label>
          </div>
          <div className="col-span-12 px-2 lg:col-span-7 relative">
            <div className="relative">
              <input
                ref={inputRef}
                id="default-search"
                className="block w-full p-[0.56rem] pl-4 border-2 text-xs md:text-sm text-gray-900  border-headerPrimary rounded-lg bg-gray-50 "
                placeholder={
                  language === 'tr' ? 'Kelime giriniz...' : 'Enter word...'
                }
              />
              <button
                type="submit"
                className="text-white absolute right-0 bottom-0 bg-headerPrimary rounded-r-md font-medium text-sm px-8 py-2 md:py-2.5"
              >
                {language === 'tr' ? 'Ara' : 'Search'}
              </button>
            </div>
          </div>
        </form>

        <div className="flow-root">
          <div className="relative -mx-4 -my-2 overflow-x-auto overflow-y-auto h-screen scrollbar-hide">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <table className="min-w-full divide-y divide-gray-300 table-fixed">
                <thead className="rounded-xl border-none text-white">
                  <tr>
                    {tableHeader.map((item, index) => {
                      return (
                        <th
                          key={index}
                          className="sticky first:rounded-l-2xl last:rounded-r-2xl top-0 z-10 border-b border-gray-300 text-center bg-headerPrimary py-3.5 pl-4 pr-3 text-sm font-semibold text-white backdrop-blur backdrop-filter "
                        >
                          <p className="text-xs md:text-sm">{item.name}</p>
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                {isLoading ? (
                  <div className="w-full flex justify-center">
                    <LoadingComponent />
                  </div>
                ) : (
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {data?.map((prod: IAliexpressTopKeyword, idx) => {
                      return (
                        <tr
                          key={idx}
                          className={classNames(
                            'even:bg-lightGray border-none'
                          )}
                        >
                          <td className="  text-xs md:text-sm sm:pl-0 border-none">
                            <p className="text-center text-gray">
                              {idx + 50 * (page - 1) + 1}
                            </p>
                          </td>

                          <td className="whitespace-nowrap py-4 text-sm text-center text-gray-500 border-none">
                            {/* https://aliexpress.ru/wholesale?SearchText= */}
                            <a
                              href={`https://aliexpress.ru/wholesale?SearchText=${prod.keywordRussian}`}
                              target="_blank"
                              rel="noreferrer"
                              className="text-center"
                            >
                              {prod.keywordTrEng}
                            </a>
                          </td>

                          <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm min-w-52 text-gray-500 border-none">
                            <a
                              href={`https://aliexpress.ru/wholesale?SearchText=${prod.keywordRussian}`}
                              target="_blank"
                              rel="noreferrer"
                              className="text-center"
                            >
                              {prod.keywordRussian}
                            </a>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm min-w-52 text-gray-500 border-none">
                            <p>{prod.searchedUserCount} </p>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm w-52 text-gray-500 border-none">
                            <p className="px-6">{prod.category}</p>
                          </td>

                          <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 border-none">
                            <p>
                              <span className="mr-1">$</span>
                              {prod.avgOrderValue ? prod.avgOrderValue : '-'}
                            </p>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-center min-w-[100px] text-xs md:text-sm text-gray-500 border-none">
                            <p>{prod.searchToIpv} </p>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 border-none">
                            <p>{prod.ipvToCart} </p>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 border-none">
                            <p>{prod.searchToCart} </p>
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 border-none">
                            <p>{prod.ipvToOrder} </p>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>

        <Pagination
          setPage={setPage}
          page={page}
          inputPage={inputPage}
          setInputPage={setInputPage}
          totalPageCount={totalPageCount!}
          queryString={queryString}
        />
      </div>
    </>
  );
};

export default AliexpressTopKeywordsTable;
