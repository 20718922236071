import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

interface Props {
  setImage: React.Dispatch<any>;
}

const DesignDropZone: React.FC<Props> = ({ setImage }) => {
  const onDrop = useCallback((acceptedFiles: File[]) => {
    console.log(acceptedFiles);
    setImage(
      Object.assign(acceptedFiles[0], {
        preview: URL.createObjectURL(acceptedFiles[0]),
      })
    );
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
    },
    onDrop,
  });
  return (
    <div
      {...getRootProps()}
      className="p-6 border-dashed border-[#2563EB] border-2"
    >
      <input {...getInputProps()} />
      <p className="text-center">
        Yüklemek istediğiniz tasarımı sürükleyip bırakın veya seçmek için
        tıklayın
      </p>
    </div>
  );
};

export default DesignDropZone;
