import React, { useEffect, useState } from 'react';
import NewBanner from '../components/tools/common/NewBanner';
// import ToolSearchBar from '../components/common/ToolSearchBar';
import StoreInfo from '../components/etsyStoreAnalyze/StoreInfo';
import StoreStatisticsWrapper from '../components/etsyStoreAnalyze/StoreStatisticsWrapper';
import etsyToolsService from '../services/etsyToolsService';
import { Box1, Chart1, UserEdit, UserSquare, WalletMoney } from 'iconsax-react';
import alertNotification from '../utils/alertNotification';
import StoreProductsTable from '../components/etsyStoreAnalyze/StoreProductsTable';
import { IEtsyShopProduct } from '../interfaces/etsyToolsInterfaces';
import { useLocation } from 'react-router-dom';
import memberService from '../services/memberService';
import { useLanguage } from '../context/LanguageContext';
import { useAuth } from '../context/AuthContext';
import ErrorFallback from './errors/ErrorFallback';
import { ErrorBoundary } from 'react-error-boundary';

export interface IShop {
  avgSales: string;
  createDate: Date;
  etsyCountry: { name: string };
  reviewCount: number;
  reviewAverage: string;
  shopName: string;
  icon: string;
  etsyTaxonomy: {
    taxonomyName: string;
  };
  etsyProductType: {
    type: string;
  };
}

const EtsyStoreAnalayze = () => {
  const { language } = useLanguage();
  const { session } = useAuth();
  const location = useLocation();
  let tempQuery = location.search.includes('shop')
    ? location.search
    : '?shopId=10204022';
  // console.log({ tempQuery });
  const [query, setQuery] = useState(tempQuery);
  const [isTabActive, setIsTabActive] = React.useState<boolean>(true);

  const [shop, setShop] = useState<IShop | null>(null);
  const [shopProducts, setShopProducts] = useState<IEtsyShopProduct[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [infoCards, setInfoCards] = useState(
    language === 'tr'
      ? [
          {
            title: 'Toplam Satış',
            icon: <Chart1 size={44} color="#0EA5E9" />,
            titleColor: '#0284C7',
            valueColor: '#0284C7',
            value: null,
          },
          {
            title: 'Ort. Günlük Satış',
            icon: <WalletMoney size={44} color="#10B981" />,
            titleColor: '#059669',
            valueColor: '#059669',
            value: null,
          },
          {
            title: 'Ürün Sayısı',
            icon: <Box1 size={44} color="#6366F1" />,
            titleColor: '#4F46E5',
            valueColor: '#4F46E5',
            value: null,
          },
          {
            title: 'Yorum Sayısı / Puanı',
            icon: <UserEdit size={44} color="#EC4899" />,
            titleColor: '#DB2777',
            valueColor: '#DB2777',
            value: null,
          },
          {
            title: 'Mağaza Takipçi Sayısı',
            icon: <UserSquare size={44} color="#F97316" />,
            titleColor: '#EA580C',
            valueColor: '#EA580C',
            value: null,
          },
        ]
      : [
          {
            title: 'Total Sales',
            icon: <Chart1 size={44} color="#0EA5E9" />,
            titleColor: '#0284C7',
            valueColor: '#0284C7',
            value: null,
          },
          {
            title: 'Avg. Daily Sales',
            icon: <WalletMoney size={44} color="#10B981" />,
            titleColor: '#059669',
            valueColor: '#059669',
            value: null,
          },
          {
            title: 'Listing Count',
            icon: <Box1 size={44} color="#6366F1" />,
            titleColor: '#4F46E5',
            valueColor: '#4F46E5',
            value: null,
          },
          {
            title: 'Review Count / Rating',
            icon: <UserEdit size={44} color="#EC4899" />,
            titleColor: '#DB2777',
            valueColor: '#DB2777',
            value: null,
          },
          {
            title: 'Store Followers',
            icon: <UserSquare size={44} color="#F97316" />,
            titleColor: '#EA580C',
            valueColor: '#EA580C',
            value: null,
          },
        ]
  );

  const getStoreAnalysis = async () => {
    setIsLoading(true);
    try {
      const res = await etsyToolsService.getStoreAnalyze(query);
      setShop(res.data.shop);

      let shop = res.data.shop;
      let tempInfo;
      if (shop != null) {
        tempInfo = infoCards.map((item, idx) => {
          if (idx === 0) {
            return { ...item, value: shop.totalSaleCount };
          } else if (idx === 1) {
            return { ...item, value: Number(shop.avgSales).toFixed(0) };
          } else if (idx === 2) {
            return { ...item, value: shop.totalListingCount };
          } else if (idx === 3) {
            return {
              ...item,
              value:
                shop.reviewCount +
                ' / ' +
                Number(shop.reviewAverage).toFixed(1),
            };
          } else {
            return { ...item, value: shop.numFavorers };
          }
        });
        setInfoCards(tempInfo);
      }

      setShopProducts(res.data.shopProducts);
    } catch (error: any) {
      alertNotification('error', error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (location.search.includes('shop')) {
      setQuery(location.search);
      setIsLoading(true);
    }
    getStoreAnalysis();
  }, [location.search, query]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabActive(document.visibilityState === 'visible');
    };

    // Add event listener for visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup function to remove the event listener when the component is unmounted
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    let intervalId: any;
    if (session && isTabActive) {
      intervalId = setInterval(async () => {
        await memberService.userTrackReq(`etsy-store-analyze`, session?.id!);
      }, 60000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [session, isTabActive]);
  return (
    <div>
      <NewBanner
        bgUrl="/images/trendyol-kar-hesaplama-bg.webp"
        toolImageUrl="etsy-store-analyze"
      />
      {shop !== null && <StoreInfo shop={shop} isLoading={isLoading} />}

      {shop !== null && (
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          {' '}
          <StoreStatisticsWrapper infoCards={infoCards} isLoading={isLoading} />
        </ErrorBoundary>
      )}
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <StoreProductsTable
          isLoading={isLoading}
          data={shopProducts}
          setQuery={setQuery}
        />
      </ErrorBoundary>
    </div>
  );
};

export default EtsyStoreAnalayze;
