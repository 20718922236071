import React, { useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import BooksCardsWrapper from '../components/books/BooksCardsWrapper';
import ErrorFallback from './errors/ErrorFallback';
import NumVerifyModal from '../components/numberVerifyModals/NumVerifyModal';
// import SixDigitModal from '../components/numberVerifyModals/SixDigitModal';
import VerifySuccessModal from '../components/numberVerifyModals/VerifySuccessModal';
import SurveyModal from '../components/survey/SurveyModal';
import memberService from '../services/memberService';
import { useAuth } from '../context/AuthContext';

const Books = () => {
  const { session } = useAuth();
  const [isTabActive, setIsTabActive] = React.useState<boolean>(true);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabActive(document.visibilityState === 'visible');
    };

    // Add event listener for visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup function to remove the event listener when the component is unmounted
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    let intervalId: any;
    if (session && isTabActive) {
      intervalId = setInterval(async () => {
        await memberService.userTrackReq('books', session?.id!);
      }, 60000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [session, isTabActive]);
  return (
    <div className="flex w-full flex-shrink-0 flex-col">
      <section className="flex flex-col">
        {/* <SixDigitModal /> */}
        <NumVerifyModal />
        <VerifySuccessModal />
        <SurveyModal />
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <BooksCardsWrapper data-testid="book-list" />
        </ErrorBoundary>
      </section>
    </div>
  );
};

export default Books;
