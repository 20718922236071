import React from 'react';

interface Props {
  children?: React.ReactNode;
}
const PublicLayout: React.FC<Props> = ({ children }) => {
  return (
    <div className="w-full paddingX bg-backgroundDefault min-h-screen flex justify-center items-center">
      {children}
    </div>
  );
};

export default PublicLayout;
