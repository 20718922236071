import React, { useState } from 'react';
import { ArrowDown2, ArrowUp2 } from 'iconsax-react';
import { Conditions, ConditionsFull } from '../DummyDatasAndTypes';

interface DetailsProps {
  card: {
    conditions: Conditions[];
    conditionsFull: ConditionsFull[];
  };
  isLoading: boolean
}
function classNames(...classes: String[]) {
  return classes.filter(Boolean).join(' ');
}
const Details: React.FC<DetailsProps> = ({ card, isLoading }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <div
      className={classNames(
        ' w-full mb-2 md:mb-0 shadow-md rounded-xl p-4 lg:p-2 bg-white rounded-b-xl flex flex-col  items-left'
      )}
    >
      <div className="flex flex-col justify-center overflow-hidden">
        {open
          ? card.conditionsFull.map((item) => {
            return (
              <>
                <div className='flex items-start justify-between space-y-1 lg:text-sm xl:text-auto'>
                  <p
                    className={classNames(
                      item.titleStyle ? item.titleStyle : "", ''
                    )}
                  >
                    {item.title}
                  </p>
                  <p
                    className={classNames(
                      item.titleStyle ? item.titleStyle : "", ''
                    )}
                  >
                    {item.value}{(item.title === 'Kar Marjı' || item.title === 'Kar Oranı') ? ' %' :
                      (item.descriptionTitle === "KDV Maliyeti =" || item.descriptionTitle === "Satıştan Oluşan KDV =" ||
                        item.descriptionTitle === "Ürün Maliyetinden Oluşan KDV =" || item.descriptionTitle === "Kargodan Oluşan KDV =" ||
                        item.descriptionTitle === "Komisyondan oluşan KDV =" || item.descriptionTitle === "Hizmet bedelinden oluşan KDV =" ||
                        item.descriptionTitle === "Reklamdan oluşan KDV =" || item.descriptionTitle === "Satıştan Oluşan KDV =" ? '' : " ₺")}

                  </p>
                </div>
                <div className='lg:text-xs'>
                  <p className={classNames(
                    item.titleStyle ? item.titleStyle : "", 'font-semibold'
                  )} >{item.descriptionTitle}</p>
                  <span className={classNames(
                    'font-thin text-xs italic block '
                  )} >{item.descriptionValue}</span>
                </div>
              </>
            );
          })
          : card.conditions.map((item) => {
            return (
              <div className='flex items-start justify-between space-y-1 lg:text-sm xl:text-auto'>
                <p
                  className={classNames(
                    item.titleStyle ? item.titleStyle : "", ''
                  )}
                >
                  {item.title}
                </p>
                {isLoading ?
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path className="opacity-75 text-[#2D356C]" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  :
                  <p
                    className={classNames(
                      item.titleStyle ? item.titleStyle : "", ''
                    )}
                  >
                    {item.value}{item.title === 'Kar Marjı' || item.title === 'Kar Oranı' ? ' %' : " ₺"}
                  </p>}
              </div>
            );
          })}
        <div className="border-b mt-2 border-opacity-75"></div>
      </div>
      <button
        onClick={handleClick}
        className={classNames(
          'text-black',
          'font-medium flex items-center w-full justify-center text-sm 2xl:text-base'
        )}
      >
        {open ? <ArrowUp2 /> : <ArrowDown2 />}
      </button>
    </div>
  );
};

export default Details;
