import { Formik } from 'formik';
import React, { Fragment, useState } from 'react';
import Select, { SingleValue } from 'react-select';
import * as Yup from 'yup';

import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

import { useLanguage } from '../../context/LanguageContext';
import memberService from '../../services/memberService';
import alertNotification from '../../utils/alertNotification';
import { citiesTurkey, vatCities } from '../../utils/Cities';
import { errorElement } from '../../utils/errorElement';
import { BillingInfo } from './AddresInfoCard';

export interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  billingInfo: BillingInfo;
  getBillingInfo: () => Promise<void>;
}

const AddressChangeForm: React.FC<Props> = ({
  open,
  setOpen,
  billingInfo,
  getBillingInfo,
}) => {
  const { t } = useLanguage();

  const [options, setOptions] = useState(citiesTurkey);
  const [taxOffice, settaxOffice] = useState(vatCities);
  const onSubmit = async (values: BillingInfo) => {
    let temp = { ...values, gsmNumber: `+90 ${values.gsmNumber}` };
    console.log(temp.taxOffice);
    memberService
      .updateBillingAddress(temp)
      .then((res) => {
        // console.log(res);
        alertNotification('success', 'Güncellendi');
        getBillingInfo();
        setOpen(false);
        window.location.reload();
      })
      .catch((err) => {
        // console.log('err', err);
        alertNotification('error', err.response.data.message);
      });
  };

  // const regexName = /^[a-zA-ZŞşÇçıİÜüĞğÖö]*$/;
  const regexName = /^[a-zA-ZŞşÇçıİÜüĞğÖö\s]*$/;
  const regexIdentity = /^[0-9]{11}$/;
  const regexGsm = /^[1-9][0-9\s]*$/;
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform w-full overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-2/3 sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-base md:text-lg font-semibold leading-6 text-headerPrimary"
                    >
                      {t?.('billingInfo.billing.billingForm.formTitle')}
                    </Dialog.Title>
                    <div className="mt-2"></div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6">
                  <Formik
                    initialValues={{
                      title: billingInfo?.title,
                      firstName: billingInfo?.firstName,
                      lastName: billingInfo?.lastName,
                      addressLine1: billingInfo?.addressLine1,
                      addressLine2: billingInfo?.addressLine2,
                      city: billingInfo?.city,
                      country: billingInfo?.country,
                      zipCode: billingInfo?.zipCode,
                      gsmNumber: String(billingInfo?.gsmNumber).replace(
                        /^(\+90)\s*/,
                        ''
                      ),
                      identityNumber: billingInfo?.identityNumber,
                      companyName: billingInfo?.companyName,
                      taxOffice: billingInfo?.taxOffice,
                      vatNumber: billingInfo?.vatNumber,
                      isCorporate: billingInfo?.isCorporate,
                    }}
                    onSubmit={onSubmit}
                    validationSchema={Yup.object({
                      firstName: Yup.string().matches(
                        regexName,
                        t?.('validation.profile.profileUpdate')
                      ),
                      lastName: Yup.string().matches(
                        regexName,
                        t?.('validation.profile.profileUpdate')
                      ),
                      city: Yup.string().matches(
                        regexName,
                        t?.('validation.profile.profileUpdate')
                      ),
                      country: Yup.string().matches(
                        regexName,
                        t?.('validation.profile.profileUpdate')
                      ),
                      identityNumber: Yup.string().matches(
                        regexIdentity,
                        t?.('validation.profile.identity')
                      ),
                      gsmNumber: Yup.string().matches(
                        regexGsm,
                        'Bu alan 0 ile başlayamaz, harf veya özel karakter içeremez'
                      ),
                    })}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      setFieldValue,
                      values,
                      errors,
                    }) => (
                      <form onSubmit={handleSubmit}>
                        <div className="overflow-hidden shadow sm:rounded-md">
                          <div className="bg-white px-4 py-5 sm:p-6">
                            <div className="grid grid-cols-6 gap-6">
                              <div className="col-span-6 ">
                                <label
                                  htmlFor="title"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  {t?.('billingInfo.billing.billingForm.title')}
                                </label>
                                <input
                                  value={values.title}
                                  onChange={handleChange}
                                  type="text"
                                  name="title"
                                  id="title"
                                  className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                              <div className="col-span-6 sm:col-span-3">
                                <label
                                  htmlFor="firstName"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  {t?.('billingInfo.billing.billingForm.name')}
                                </label>
                                <input
                                  type="text"
                                  value={values.firstName}
                                  onChange={handleChange}
                                  name="firstName"
                                  id="firstName"
                                  className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                                {errors.firstName &&
                                  errorElement(errors.firstName)}
                              </div>

                              <div className="col-span-6 sm:col-span-3">
                                <label
                                  htmlFor="lastName"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  {t?.(
                                    'billingInfo.billing.billingForm.surname'
                                  )}
                                </label>
                                <input
                                  value={values.lastName}
                                  onChange={handleChange}
                                  type="text"
                                  name="lastName"
                                  id="lastName"
                                  className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                                {errors.lastName &&
                                  errorElement(errors.lastName)}
                              </div>

                              <div className="col-span-6 sm:col-span-3">
                                <label
                                  htmlFor="addressLine1"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  {t?.(
                                    'billingInfo.billing.billingForm.adres1'
                                  )}
                                </label>
                                <input
                                  value={values.addressLine1}
                                  onChange={handleChange}
                                  type="text"
                                  name="addressLine1"
                                  id="addressLine1"
                                  className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                              <div className="col-span-6 sm:col-span-3">
                                <label
                                  htmlFor="addressLine2"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  {t?.(
                                    'billingInfo.billing.billingForm.adres2'
                                  )}
                                </label>
                                <input
                                  value={
                                    values.addressLine2
                                      ? values.addressLine2
                                      : ''
                                  }
                                  onChange={handleChange}
                                  type="text"
                                  name="addressLine2"
                                  id="addressLine"
                                  className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>

                              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                                <label
                                  htmlFor="city"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  {t?.('billingInfo.billing.billingForm.city')}
                                </label>

                                <Select
                                  options={options}
                                  name="city"
                                  onChange={(
                                    newValue: SingleValue<{
                                      label: string;
                                      value: string;
                                    }>
                                  ) => {
                                    setFieldValue('city', newValue?.value);
                                  }}
                                  className="mt-2 block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>

                              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                                <label
                                  htmlFor="country"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  {t?.(
                                    'billingInfo.billing.billingForm.country'
                                  )}
                                </label>
                                <input
                                  value={values.country}
                                  onChange={handleChange}
                                  type="text"
                                  name="country"
                                  id="country"
                                  className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>

                              <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                                <label
                                  htmlFor="zipCode"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  {t?.(
                                    'billingInfo.billing.billingForm.zipCode'
                                  )}
                                </label>
                                <input
                                  onChange={handleChange}
                                  value={values.zipCode}
                                  type="text"
                                  name="zipCode"
                                  id="zipCode"
                                  className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                              <div className="col-span-6 sm:col-span-2">
                                <label
                                  htmlFor="gsmNumber"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  {t?.('billingInfo.billing.billingForm.phone')}
                                </label>
                                <div className="flex justify-center items-center mt-2 py-1 px-2 w-full rounded-md  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 ">
                                  <p className="text-sm">+90</p>
                                  <input
                                    value={values.gsmNumber}
                                    onChange={handleChange}
                                    type="text"
                                    name="gsmNumber"
                                    placeholder="555 555 55 55"
                                    id="gsmNumber"
                                    className="border-none h-7 w-full leading-3 sm:text-sm focus:ring-0"
                                  />
                                </div>
                                {errors.gsmNumber &&
                                  errorElement(errors.gsmNumber)}
                              </div>

                              <div className="col-span-6 sm:col-span-2">
                                <label
                                  htmlFor="identityNumber"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  {t?.(
                                    'billingInfo.billing.billingForm.identity'
                                  )}
                                </label>
                                <input
                                  onChange={handleChange}
                                  value={values.identityNumber}
                                  type="text"
                                  name="identityNumber"
                                  id="identityNumber"
                                  className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                                {errors.identityNumber &&
                                  errorElement(errors.identityNumber)}
                              </div>
                              <div className="col-span-6 sm:col-span-2">
                                <label
                                  htmlFor="isCorporate"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  Kurumsal Fatura
                                </label>
                                <input
                                  onChange={handleChange}
                                  checked={values.isCorporate}
                                  type="checkbox"
                                  name="isCorporate"
                                  id="isCorporate"
                                  className="mt-3 w-6 h-6 block rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset  sm:text-sm sm:leading-6"
                                />
                              </div>
                              <div className="col-span-6 sm:col-span-2">
                                <label
                                  htmlFor="companyName"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  {t?.(
                                    'billingInfo.billing.billingForm.company'
                                  )}
                                </label>
                                <input
                                  value={values.companyName}
                                  onChange={handleChange}
                                  type="text"
                                  name="companyName"
                                  id="companyName"
                                  className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                              <div className="col-span-6 sm:col-span-2">
                                <label
                                  htmlFor="taxOffice"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  {t?.(
                                    'billingInfo.billing.billingForm.taxOffice'
                                  )}
                                </label>
                                <Select
                                  options={taxOffice}
                                  name="taxOffice"
                                  placeholder="Seçiniz..."
                                  onChange={(
                                    newValue: SingleValue<{
                                      label: string;
                                      value: string;
                                    }>
                                  ) => {
                                    setFieldValue('taxOffice', newValue?.value);
                                  }}
                                  className="mt-2 block w-full rounded-md border-0 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>

                              <div className="col-span-6 sm:col-span-2">
                                <label
                                  htmlFor="vatNumber"
                                  className="block text-sm font-medium leading-6 text-gray-900"
                                >
                                  {t?.(
                                    'billingInfo.billing.billingForm.taxNumber'
                                  )}
                                </label>
                                <input
                                  onChange={handleChange}
                                  value={values.vatNumber}
                                  type="text"
                                  name="vatNumber"
                                  id="vatNumber"
                                  className="mt-2 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="px-4 py-3 text-right sm:px-6">
                            <button
                              type="submit"
                              className="rounded-md bg-headerPrimary py-2.5 px-8 text-xs font-semibold text-white shadow-sm hover:bg-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 md:text-sm"
                            >
                              {t?.('common.buttons.submit')}
                            </button>
                          </div>
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
export default AddressChangeForm;
