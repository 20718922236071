import React from 'react'

const PackageCancelSuccessModal = () => {
    return (
        <div className='py-10'>
            <div className="flex flex-col items-center justify-center text-center space-y-1">
                <div className="font-semibold text-md md:text-xl">
                    <p className="my-5 text-rexRed font-bold text-3xl md:text-4xl">
                        HOŞÇAKALIN
                    </p>
                </div>
            </div>
            <div className="font-semibold text-md md:text-xl text-center mb-2">
                <p className="my-1">Ankete Katıldığın İçin Teşekkür Ederiz.</p>
                <p>Bir Gün Yeniden Karşılaşmak Üzere.</p>
            </div>
            <div className='h-[300px] md:h-[500px] ' >
                <img className='mx-auto h-full object-contain ' src="/images/cancelSubscriptionSuccess.webp" alt="cancel-success" />
            </div>
        </div>

    )
}

export default PackageCancelSuccessModal