import React, { useEffect, useState } from 'react';
import NewBanner from '../components/tools/common/NewBanner';
// import CategorySelection from '../components/serviceProviders/CategorySelection';
import ProvidersCardsWrapper from '../components/serviceProviders/ProvidersCardsWrapper';
// import RegisterAsSellerModal from '../components/productsAndWholeSalers/RegisterAsSellerModal';
import RegisterService from '../components/serviceProviders/RegisterService';
import BuyModal from '../components/tools/etsy/BuyModal';
import { useAuth } from '../context/AuthContext';
import SuggestServiceModal from '../components/shared/SuggestServiceModal';
import ServiceProviderBtn from '../components/serviceProviders/ServiceProviderBtn';
import memberService from '../services/memberService';
import { ToastContainer } from 'react-toastify';
import { useNumberVerification } from '../context/NumberVerificationContext';
import NumVerifyModal from '../components/numberVerifyModals/NumVerifyModal';
// import SixDigitModal from '../components/numberVerifyModals/SixDigitModal';
import VerifySuccessModal from '../components/numberVerifyModals/VerifySuccessModal';
// import SurveyModal from '../components/survey/SurveyModal';
import { useLanguage } from '../context/LanguageContext';
// import memberService from '../services/memberService';
// import alertNotification from '../utils/alertNotification';
export interface ICategory {
  id: number;
  categoryName: string;
}
const ServiceProviderList = () => {
  const [open, setOpen] = useState(false);
  const [openBuy, setOpenBuy] = useState(false);
  const [openSuggest, setOpenSuggest] = useState(false);
  const [isTabActive, setIsTabActive] = React.useState<boolean>(true);

  const { session } = useAuth();
  const { t } = useLanguage();
  const { setNumVerifyModal } = useNumberVerification();

  const handleClick = () => {
    if (!session?.hasPackage) {
      setOpenBuy(true);
    } else {
      if (!session?.isGsmVerified) {
        ///open gsm verification
        setNumVerifyModal?.(true);
      } else {
        setOpen(true);
      }
    }
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabActive(document.visibilityState === 'visible');
    };

    // Add event listener for visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup function to remove the event listener when the component is unmounted
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    let intervalId: any;
    if (session && isTabActive) {
      intervalId = setInterval(async () => {
        await memberService.userTrackReq(`service-providers`, session?.id!);
      }, 60000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [session, isTabActive]);

  return (
    <div className="px-5 flex w-full flex-shrink-0 flex-col">
      <NewBanner
        toolImageUrl="service-providers"
        bgUrl="/images/danismanlik-bg.webp"
      />

      <ServiceProviderBtn
        setOpen={handleClick}
        setOpenSuggest={setOpenSuggest}
      />

      <ProvidersCardsWrapper />
      <BuyModal
        specialString="Hizmet sağlayıcı olarak 42,000 den fazla girişimciye destek vermek için Rexven’e abone olunuz"
        open={openBuy}
        setOpen={setOpenBuy}
      />
      <SuggestServiceModal open={openSuggest} setOpen={setOpenSuggest} />
      <RegisterService open={open} setOpen={setOpen} />
      <ToastContainer />

      <NumVerifyModal />
      {/* <SixDigitModal /> */}
      <VerifySuccessModal />
    </div>
  );
};

export default ServiceProviderList;
