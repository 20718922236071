import React, { lazy, Suspense, useEffect } from 'react';
import ConsultancyCard from '../components/consultancy/ConsultancyCard';
import BannerLoading from '../components/tools/common/BannerLoading';
import memberService from '../services/memberService';
import { useAuth } from '../context/AuthContext';
import { useLanguage } from '../context/LanguageContext';
const NewBanner = lazy(() => import('../components/tools/common/NewBanner'));

const Consultancy = () => {
  const { language } = useLanguage();
  const { session } = useAuth();
  const [isTabActive, setIsTabActive] = React.useState<boolean>(true);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabActive(document.visibilityState === 'visible');
    };

    // Add event listener for visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup function to remove the event listener when the component is unmounted
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
  useEffect(() => {
    let intervalId: any;
    if (session && isTabActive) {
      intervalId = setInterval(async () => {
        await memberService.userTrackReq('consultancy', session?.id!);
      }, 60000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [session, isTabActive]);

  const cards = [
    {
      platform: 'AMAZON',
      price: '$5.000',
      imgUrl: '/images/amazon_1.png',
      conditions:
        language === 'tr'
          ? [
              'E-İhracat destekler görüşmesi',
              'ABD ve İngiltere şirket kuruluşu',
              'Banka hesabı açılması',
              'Amazon hesabı açılması',
              'Marka tescilnin başlatılması',
            ]
          : [
              'USA and UK Company Formation',
              'Opening a Bank Account',
              'Opening an Amazon Account',
              'Initiation of Trademark Registration Process',
              'Analysis of Products',
            ],
      conditionsFull:
        language === 'tr'
          ? [
              'E-İhracat destekler görüşmesi',
              'ABD ve İngiltere şirket kuruluşu',
              'Banka hesabı açılması',
              'Amazon hesabı açılması',
              'Marka tescil sürecinin başlatılması',
              'Ürünlerin analiz edilmesi',
              'Pazar yeri ve ürüne karar verilmesi',
              'Ürün listesinin hazırlanması',
              "Ürünlerin Amazon'a yüklenmesi",
              'Paketleme ve kargolama süreci',
              'A+ Content ve marka sayfası oluşturma',
              'Ürünlerin lansmanının yapılması',
              'Reklamların kurulması',
              'Reklam optimizasyonu',
              'Case açma, marka avantajları,raporlama, soru-cevap',
              '+ 1 yıllık Rexven Ekosistem üyeliği',
              'Süreç oryantasyonu (Danışmanlık sonrası sürecin yönetilebilmesi içindetaylı eğitim)',
            ]
          : [
              'USA and UK Company Formation',
              'Opening a Bank Account',
              'Opening an Amazon Account',
              'Initiation of Trademark Registration Process',
              'Analysis of Products',
              'Deciding on the Marketplace and Product',
              'Preparation of the Product Listing',
              'Uploading Products to Amazon',
              'Packaging and Shipping Process',
              'Creating A+ Content and Brand Page',
              'Launching the Products',
              'Setting Up PPC Campaigns',
              'PPC Optimization',
              'Opening a Case, Brand Advantages, Reporting, Q&A',
              '+1 Year Rexven Ecosystem Membership',
              'Process Orientation (Detailed Training for Managing the Process Post-Consultation)',
            ],
    },
    {
      platform: 'REXVEN',
      imgUrl: '/images/rexven.png',
      conditions:
        language === 'tr'
          ? [
              'ABD ve İngiltere şirket kuruluşu',
              'Banka hesabı açılması',
              'Amazon hesabı açılması',
              'Ürünlerin analiz edilmesi',
              'Markanın açılması',
            ]
          : [
              'USA and UK Company Formation',
              'Opening a Bank Account',
              'Opening an Amazon Account',
              'Analysis of Products',
              'Brand Confirm',
            ],
      conditionsFull:
        language === 'tr'
          ? [
              'ABD ve İngiltere şirket kuruluşu',
              'Banka hesabı açılması',
              'Amazon hesabı açılması',
              'Ürünlerin analiz edilmesi',
              'Markanın açılması',
              'Kategorinin açılması',
              'Ürünlerin tedarik edilmesi',
              'Kargo gönderim süreci',
              'Satış süreci',
              'Stok takibi',
              'Ürünleri Rexven tedarik edecek',
              '+1 yıllık Rexven üyeliği',
            ]
          : [
              'USA and UK Company Formation',
              'Opening a Bank Account',
              'Opening an Amazon Account',
              'Analysis of Products',
              'Brand Confirm',
              'Category Opening',
              'Procurement of Products',
              'Shipping Process',
              'Sales Process',
              'Stock Tracking',
              'All Products Supplied by Rexven',
              '+1 Year Rexven Membership',
            ],
    },
    {
      platform: 'ETSY',
      price: '$2.500',
      imgUrl: '/images/etsy_1.png',
      conditions:
        language === 'tr'
          ? [
              'Başlangıç görüşmesi',
              'Etsy eğitimi',
              'Maliyet-kar analizinin yapılması',
              'Etsy analiz araçlarının kullanımı',
              'Doğru ürün seçiminin yapılması',
            ]
          : [
              'Etsy 1:1 Training',
              'Product Research and Development',
              'Cost and Profit Analysis',
              'Correct Product Selection',
              'Use of Etsy Analysis tools',
            ],
      conditionsFull:
        language === 'tr'
          ? [
              'Başlangıç görüşmesi',
              'Etsy eğitimi',
              'Maliyet ve kar analizinin yapılması',
              'Doğru ürün seçiminin yapılması',
              'Etsy analiz yazılımlarının kullanımı',
              'Ürün fotoğraf çekimi hakkında bilgilendirme',
              'Bilrikte ürün araştırma ve geliştirme',
              'SEO uyumlu listeleme eğitimi',
              'Etsy hesap açılışının yapılması',
              '5 adet ürünün tarafımızca listelenmesi',
              'Mağaza ve satıcı panelinin tanıtımı',
              'Etsy istatiklerinin yorumlanması',
              'Etsy pazarlama araçlarının kullanımı',
              'Pinterest eğitimi',
              'Sipariş sonrası dikkat edilmesi gerekenler',
              'Etsy dijital ürün hakkında bilgilendirme',
              '+1 yıllık Rexven üyeliği',
              'Süreç oryantasyonu (Danışmanlık sonrası sürecin yönetilebilmesi için detaylı eğitim',
            ]
          : [
              'Etsy 1:1 Training',
              'Product Research and Development',
              'Cost and Profit Analysis',
              'Correct Product Selection',
              'Use of Etsy Analysis tools',
              'Information about Product Photography',
              'SEO-Friendly Listing Training',
              'Opening an Etsy Account',
              'Listing of 5 Products by Our Team',
              'Introduction to the Store and Seller Panel',
              'Interpretation of Etsy Statistics',
              'Use of Etsy Marketing Tools',
              'Pinterest Training',
              'Post-Order Procedures',
              'Information about Etsy Digital Products',
              '+1 Year Rexven Membership',
              'Process Orientation (Detailed Training for Managing the Process Post-Consultation)',
            ],
    },
  ];
  return (
    <div className="flex w-full flex-shrink-0 flex-col">
      <Suspense fallback={<BannerLoading />}>
        <NewBanner
          bgUrl={'/images/danismanlik-bg.webp'}
          toolImageUrl="danismanlik"
        />
      </Suspense>

      <div className="flex flex-col md:flex-row  space-y-2 sm:space-y-0 md:space-x-2 lg:space-x-4 xl:space-x-6 justify-center items-start mt-14">
        {cards.map((card, index) => {
          return <ConsultancyCard idx={index} card={card} />;
        })}
      </div>
    </div>
  );
};

export default Consultancy;
