import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useLanguage } from '../../context/LanguageContext';
import { errorElement } from '../../utils/errorElement';
import AuthService from '../../services/authService';
import alertNotification from '../../utils/alertNotification';
import { ToastContainer } from 'react-toastify';

export default function ResetInfoForm() {
  const regex = /^(?=.[A-Za-z])(?=.\d)[A-Za-z\d]{6,}$/;
  const { t } = useLanguage();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const params = useParams();
  const token = params.token;

  const handleResetPassword = (
    password: string,
    passwordConfirm: string,
    firstName: string,
    lastName: string,
    token: string
  ) => {
    setIsLoading(true);
    AuthService.resetPassword(
      { password, passwordConfirm, firstName, lastName },
      token
    )
      .then((res) => {
        navigate('/login');
      })
      .catch((err) => {
        console.log(err);
        alertNotification('error', err?.response?.data?.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="flex h-screen flex-col justify-center py-12 sm:px-6 lg:px-8 bg-lightGray">
        <ToastContainer />
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-7 w-auto"
            src="/assets/logos/rexven-logo.svg"
            alt="Your Company"
          />
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
            {t?.('reset-password.header')}
          </h2>
        </div>
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md p-4">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <div className="mb-3 flex justify-end">
              {/* TODO:if you decide to use translation just comment out these lines */}
              {/* <div className="w-28">
                <LanguageSelect />
              </div> */}
            </div>
            <Formik
              initialValues={{
                password: '',
                passwordConfirm: '',
                firstName: '',
                lastName: '',
              }}
              onSubmit={(values, actions) => {
                handleResetPassword(
                  values.password,
                  values.passwordConfirm,
                  values.firstName,
                  values.lastName,
                  token!
                );
                actions.setSubmitting(false);
              }}
              validateOnChange={false}
              validationSchema={Yup.object({
                firstName: Yup.string().required(t?.('validation.required')),
                lastName: Yup.string().required(t?.('validation.required')),
                password: Yup.string().required(t?.('validation.required')),
                passwordConfirm: Yup.string().required(
                  t?.('validation.required')
                ),
              })}
            >
              {({ handleSubmit, values, handleChange, errors }) => (
                <form onSubmit={handleSubmit} className="space-y-6">
                  <div>
                    <label
                      htmlFor="firstName"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t?.('reset-password.firstName')}
                    </label>
                    <div className="mt-1">
                      <input
                        id="firstName"
                        name="firstName"
                        placeholder={t?.('reset-password.firstName')}
                        value={values.firstName}
                        onChange={handleChange}
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-secondary focus:outline-none focus:ring-secondary sm:text-sm"
                      />
                      {errors.firstName && errorElement(errors.firstName)}
                    </div>
                  </div>{' '}
                  <div>
                    <label
                      htmlFor="lastName"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t?.('reset-password.lastName')}
                    </label>
                    <div className="mt-1">
                      <input
                        id="lastName"
                        name="lastName"
                        placeholder={t?.('reset-password.lastName')}
                        value={values.lastName}
                        onChange={handleChange}
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-secondary focus:outline-none focus:ring-secondary sm:text-sm"
                      />
                      {errors.lastName && errorElement(errors.lastName)}
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t?.('reset-password.newPassword')}
                    </label>
                    <div className="mt-1">
                      <input
                        id="password"
                        name="password"
                        placeholder={t?.('reset-password.newPassword')}
                        type="password"
                        value={values.password}
                        onChange={handleChange}
                        autoComplete="current-password"
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-secondary focus:outline-none focus:ring-secondary sm:text-sm"
                      />
                      {errors.password && errorElement(errors.password)}
                    </div>
                  </div>
                  <div>
                    <label
                      htmlFor="passwordConfirm"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t?.('reset-password.newPasswordConfirm')}
                    </label>
                    <div className="mt-1">
                      <input
                        id="passwordConfirm"
                        name="passwordConfirm"
                        placeholder={t?.('reset-password.newPasswordConfirm')}
                        type="password"
                        value={values.passwordConfirm}
                        onChange={handleChange}
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-secondary focus:outline-none focus:ring-secondary sm:text-sm"
                      />
                      {errors.passwordConfirm &&
                        errorElement(errors.passwordConfirm)}
                    </div>
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="flex w-full justify-center rounded-md border border-transparent bg-secondary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2"
                    >
                      {t?.('common.buttons.resetPassword')}
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
}
