import React, { useEffect } from 'react';

import { useQuery } from '@tanstack/react-query';

import memberService from '../../services/memberService';
import { classNames } from '../../utils/conditionalClasses';

interface Props {
  selectedCategory: number | string;
  setSelectedCategory: (categoryId: number) => void;
}

const CategorySelection: React.FC<Props> = ({
  selectedCategory,
  setSelectedCategory,
}) => {
  const { data } = useQuery<any, Error>(['serviceCategories'], {
    queryFn: () => memberService.getServiceCategories(),
    staleTime: Infinity,
  });
  useEffect(() => {
    console.log('data', data)
  }, [data])

  return (
    <div className="w-full my-10 overflow-hidden">
      <div className="flex items-center justify-between overflow-auto touch-pan-x py-3">
        {data?.data.map((category: any) => (
          <p
            onClick={() => setSelectedCategory(category.id)}
            className={classNames(
              selectedCategory === category.id
                ? 'border-b-2  border-headerPrimary text-headerPrimary'
                : 'text-secondary',
              'font-medium  min-[1306px]:font-medium text-center text-xs xl:text-sm px-3 first:pl-0 last:pr-0  cursor-pointer inline-block hover:text-headerPrimary'
            )}
            key={category.id}
          >
            {category.categoryName}
          </p>
        ))}
      </div>
    </div>
  );
};

export default CategorySelection;
