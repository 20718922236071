import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { seperateNumberWithComma } from '../../../../utils/numberFormatter';
import { DaysDataType, FinancialData } from '../../DummyDatasAndTypes';
import { CardTitle } from './SellerDashboardCardGroups';

interface ProfitMarginCardProps {
    cardTitles: CardTitle[]
    allDayData: DaysDataType
}
const ProfitMarginCard: React.FC<ProfitMarginCardProps> = ({ cardTitles, allDayData }) => {
    const [cardData, setCardData] = useState({} as { [title: string]: FinancialData });

    const getDataForCard = (
        title: string,
        todayData: FinancialData,
        sevenDayData: FinancialData,
        thirtyDayData: FinancialData,
        ninetyDayData: FinancialData
    ): FinancialData => {
        switch (title) {
            case 'Bugün':
                return todayData;
            case 'Son 7 Gün':
                return sevenDayData;
            case 'Son 30 Gün':
                return thirtyDayData;
            case 'Son 90 Gün':
                return ninetyDayData;
            default:
                return {
                    totalRevenue: {
                        revenue: 0,
                        profitOrLoss: 0,
                        orderCount: 0,
                        costs: 0,
                        profitMargin: 0,
                        returnCount: 0,
                        saleCount: 0
                    },
                    totalCost: 0,
                    profitOrLoss: 0,
                    profitMargin: 0
                };
        }
    };




    useEffect(() => {
        let newCardData: { [title: string]: FinancialData } = {};
        cardTitles.forEach((card) => {
            const data = getDataForCard(
                card.title,
                allDayData.data.day,
                allDayData.data.week,
                allDayData.data.month,
                allDayData.data.quarter
            );
            newCardData[card.title] = data;
        });
        setCardData(newCardData);
    }, [allDayData, cardTitles]);
    return (
        <div className='grid grid-cols-12 gap-4 my-8'>
            {cardTitles.map((card, index) => {
                let data = cardData[card.title];
                let profitOrLoss = data?.profitOrLoss ?? 0;
                let profitMargin = data?.profitMargin ?? 0;
                let revenue = data?.totalRevenue?.revenue ?? 0;
                let chartvalue = [profitMargin, 100 - profitMargin]

                let color = '';
                if (profitMargin < 25) {
                    color = '#F87171';
                } else if (profitMargin <= 50) {
                    color = '#60A5FA';
                } else if (profitMargin <= 75) {
                    color = '#A78BFA';
                } else {
                    color = '#34D399';
                }

                const chartOptions = {
                    options: {
                        dataLabels: {
                            enabled: false
                        },
                        tooltip: {
                            enabled: false,
                        },
                        states: {
                            active: {
                                filter: {
                                    type: 'none',
                                }
                            }
                        },
                        colors: [color, `${color}33`],
                        responsive: [
                            {
                                breakpoint: 480,
                                options: {
                                    chart: {
                                        width: 250
                                    },
                                    legend: {
                                        show: false
                                    }
                                }
                            }
                        ],
                        legend: {
                            show: false
                        }
                    }
                };

                return (
                    <div className='col-span-12 lg:col-span-6 xl:col-span-3 bg-lightGray border rounded-md shadow-md p-2 w-full lg:w-auto min-h-[450px] flex flex-col justify-between items-center'>
                        <div className='flex flex-col justify-start w-full'>
                            <p className='font-semibold text-sm'>{card.title}</p>
                            <p className='text-xs font-thin text-cardBorder mb-2'>{card.date}</p>
                        </div>
                        <div className="flex justify-center items-center relative">
                            <ReactApexChart
                                options={chartOptions?.options}
                                series={chartvalue}
                                type="donut"
                                height={225}
                                width={225}
                            />
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    fontSize: '1.5rem',
                                    fontWeight: 'bold',
                                    zIndex: 1,
                                    color: color
                                }}
                            >
                                % {chartvalue[0]}
                            </div>
                        </div>
                        <div className='px-4 text-center min-h-[100px]'>
                            <p>{`${card.title} satışlardan `}
                                <span className='font-semibold'>
                                    {`${seperateNumberWithComma(revenue)} ₺ `}
                                </span>
                                ciro ve {""}
                                <span className='font-semibold'>{seperateNumberWithComma(profitOrLoss)} ₺</span>
                                {""} {profitOrLoss >= 0 ? "kar" : "zarar"} elde edilmiştir.
                            </p>
                            <p>{`Kar Marjınız = `}
                                <span className='font-semibold'>
                                    {seperateNumberWithComma(profitOrLoss)} ₺
                                </span>
                                {" / "}
                                <span className='font-semibold'>{`${seperateNumberWithComma(revenue)} ₺ = % ${seperateNumberWithComma(profitMargin)} `}
                                </span>
                            </p>
                        </div>
                    </div >
                )
            })}
        </div>
    )
}

export default ProfitMarginCard