import { Link1, MouseCircle } from 'iconsax-react';
import React from 'react';
import { Link } from 'react-router-dom';

const Afiliates = () => {
    const AfiliatesCompanies = [
        {
            name: 'Mükellef',
            logo: '/images/afiliates/mukellef.png',
            description: 'Yurt içi ve yurt dışı şirket ve muhasebe işleri',
            advantage: "TR'de 1000 TL'ye, UK'de 100 GBP'ye, US'de 100 USD'ye kadar indirim.",
            link: 'https://register.mukellef.co/?partner=rexven',
            visibleLink: 'mukellef.co'
        },
        {
            name: 'shopify',
            logo: '/images/afiliates/shopify.png',
            description: 'Global e-ticaret web site altyapısı',
            advantage: '1 dolara 3 aylık hesabını açabilirsin.',
            link: 'https://www.shopify.com/free-trial?utm_source=3940132-impact&utm_medium=cpa&utm_campaign=seri&irclickid=QeFTZtwL7xyPToKQ4QxU3w%3AbUkHznYRHeSj23c0&irgwc=1&partner=3940132&affpt=excluded&utm_channel=affiliates',
            visibleLink: 'shopify.com'
        },
        {
            name: 'Etsy Ücretsiz Listeleme',
            logo: '/images/afiliates/etsy.png',
            description: 'El yapımı, hediyelik, dijital ürün pazar yeri',
            advantage: '40 Adet ücretsiz listeleme hakkı',
            link: 'https://etsy.me/4bYZACx',
            visibleLink: 'etsy.me'
        },
        {
            name: 'Ikas',
            logo: '/images/afiliates/Ikas.png',
            description: 'Eticaret web site altyapısı',
            discount: '%15 İndirim',
            // link: 'https://ikas.com/tr?utm_source=yt&utm_medium=mehmettek&utm_id=affiliate',
            link: 'https://get.ikas.com/lvhfgzj8ndxl',
            visibleLink: 'ikas.com'
        },
        {
            name: 'Printify',
            logo: '/images/afiliates/printify.png',
            description: 'Tasarımlarını baskıya dönüştürüp satma platformu',
            advantage: 'Üretim ve baskı derdi olmadan e-ticaret yap',
            link: 'https://try.printify.com/kwqyl93jp8mb',
            visibleLink: 'printify.com'
        },
        {
            name: 'Roketfy',
            logo: '/images/afiliates/roketfy.png',
            description: 'Etsy ürün, mağaza, kelime analizi ve yapay zeka içerik aracı',
            discount: '%30 İndirim',
            code: 'İndirim Kodu: REXVEN30',
            link: 'https://roketfy.com/tr/?ref=rexven',
            visibleLink: 'roketfy.com'
        },
        {
            name: 'helium10',
            logo: '/images/afiliates/helium10.png',
            description: "Amazon ürün ve kelime araştırma aracı",
            discount: '%20 İndirim',
            code: 'İndirim Kodu: REXVEN20',
            link: 'https://bit.ly/3T2o3Af',
            visibleLink: 'helium10.com'
        },
        {
            name: 'EtsyHunt',
            logo: '/images/afiliates/etsyhunt.png',
            description: 'Etsy ürün, mağaza ve kelime analizi aracı',
            link: 'https://etsyhunt.com/?fpr=rexven',
            visibleLink: 'etsyhunt.com'
        },
        {
            name: 'Aliexpress.ru',
            logo: '/images/afiliates/aliexpress.png',
            description: 'Rusya pazar yeri',
            advantage: 'Ücretsiz Entegrasyon desteği ve yardım',
            link: 'https://seller.aliexpress.ru/registration/?channel=tp&partner=rexven',
            visibleLink: 'aliexpress.ru'
        },
        {
            name: 'Ozon',
            logo: '/images/afiliates/ozon.png',
            description: "Rusya pazar yeri",
            advantage: "E-ticaretini Rusya'ya Taşıma Fırsatı",
            link: 'https://seller.ozon.com/tr/?utm_source=rexven',
            visibleLink: 'ozon.com'
        },
        {
            name: 'payoneer',
            logo: '/images/afiliates/payoneer.png',
            description: 'Global, çoklu banka hesabı oluşturma alt yapısı',
            advantage: '$1000 ilk transferinizde $50 kazanabilirsiniz',
            link: 'http://tracking.payoneer.com/SH4Cn',
            visibleLink: 'payoneer.com'
        },
        {
            name: 'quickshipper',
            logo: '/images/afiliates/quickshipper.png',
            description: 'Yurtdışı kargo taşıma firması',
            discount: '%30 İndirim',
            code: 'İndirim Kodu: Rxvn22',
            link: 'https://www.quickshipper.com/',
            visibleLink: 'quickshipper.com'
        },
        {
            name: 'maestrozon',
            logo: '/images/afiliates/maestrozon.png',
            description: 'İleri düzey Amazon reklam ve mağaza yönetimi',
            discount: '%10 İndirim',
            code: 'İndirim Kodu: RXVNAB10',
            link: 'https://maestrozon.com/',
            visibleLink: 'maestrozon.com'
        },
        {
            name: 'worqcompany',
            logo: '/images/afiliates/worqcompany.png',
            description: 'E-ticaret finansman desteği',
            link: 'https://f.worq.app/p/l/?id=8mepBmbM',
            visibleLink: 'worq.app'
        },
        // {
        //     name: 'arspar',
        //     logo: '/images/afiliates/arspar.png',
        //     description: 'Yapay zeka ile ürün görselleri oluşturma',
        //     discount: '%40 İndirim',
        //     code: 'İndirim Kodu: Rexven40',
        //     link: 'https://app.arspar.com/rapid-templates?via=rexven-teknoloji',
        //     visibleLink: 'arspar.com'
        // },
        {
            name: 'orest',
            logo: '/images/afiliates/orest.png',
            description: 'Danışmanlık, Marka Tescili, Belgelendirme',
            discount: '%20 indirim',
            link: 'https://www.orestdanismanlik.com/',
            visibleLink: 'orestdanismanlik.com'
        },
        {
            name: 'sellersprite',
            logo: '/images/afiliates/sellerSprite.webp',
            description: 'Amazon ürün araştırma ve analiz aracı',
            discount: '%35 indirim',
            link: 'https://www.sellersprite.com/affiliate/REXVEN35',
            visibleLink: 'sellersprite.com'
        },
        {
            name: 'Minor Stüdyo',
            logo: '/images/afiliates/minorStudyo.webp',
            description: "Ürün fotoğrafı ve 360° video",
            discount: '%10 indirim',
            code: 'İndirim Kodu: REXVEN10',
            link: 'https://minorstudyo.com?sca_ref=6731059.bOC3TWg4dr&utm_source=affiliate&utm_medium=6731059&utm_campaign=89538',
            visibleLink: 'minorstudyo.com'
        },
        {
            name: 'Sellerboard',
            logo: '/images/afiliates/sellerboard.webp',
            description: "Amazon satıcıları için finansal analiz ve yönetim aracıdır.",
            advantage: 'Rexvenlilere özel 2 ay ücretsiz deneme hakkı',
            link: 'https://sellerboard.com/?p=01775',
            visibleLink: 'sellerboard.com'
        },
    ]
    return (
        <div className='grid grid-cols-12 gap-4 items-center justify-between mt-4'>
            {AfiliatesCompanies.map((company) => {
                return (
                    <div className='bg-[#F9FAFB] min-h-[236px] col-span-12 md:col-span-6 lg:col-span-4 2xl:col-span-3  shadow-lg rounded-3xl p-2 w-full flex flex-col gap-1 items-center justify-between' key={company.name}>
                        <div className='min-h-[50px] flex items-center'>
                            <img src={company.logo} alt={company.name} style={{ width: '100px', height: 'auto' }} />
                        </div>
                        <p className='text-[#374151] text-sm min-h-[30px] text-center'>{company.description}</p>
                        {company.advantage && <p className='text-[#3C4790] text-center font-semibold'>{company.advantage}</p>}
                        {company.discount && <p className='text-[#3C4790] font-bold text-xl'>{company.discount}</p>}                        {company.code && <p className='text-headingPrimary text-xs'>{company.code}</p>}
                        <Link target={'_blank'} to={company.link} className='text-[#0284C7] text-xs relative bottom-0 flex flex-row items-center justify-center gap-1'>
                            <span>{company.visibleLink}</span>
                            <MouseCircle className='w-4 h-4' color="#0284C7" />
                        </Link>
                    </div>
                );
            })}
        </div>
    )
}

export default Afiliates