export const jobs = [
    // Sağlık ve Tıp
    { id: 1, name: 'Sağlık ve Tıp > Doktor' },
    { id: 2, name: 'Sağlık ve Tıp > Hemşire' },
    { id: 3, name: 'Sağlık ve Tıp > Eczacı' },
    { id: 4, name: 'Sağlık ve Tıp > Diş Hekimi' },
    { id: 5, name: 'Sağlık ve Tıp > Veteriner' },
    { id: 6, name: 'Sağlık ve Tıp > Fizyoterapist' },
    { id: 7, name: 'Sağlık ve Tıp > Psikolog' },
    { id: 8, name: 'Sağlık ve Tıp > Radyolog' },
    { id: 9, name: 'Sağlık ve Tıp > Tıbbi Laboratuvar Teknikeri' },
    { id: 10, name: 'Sağlık ve Tıp > Tıbbi Sekreter' },

    // Mühendislik
    { id: 11, name: 'Mühendislik > İnşaat Mühendisi' },
    { id: 12, name: 'Mühendislik > Elektrik Mühendisi' },
    { id: 13, name: 'Mühendislik > Makine Mühendisi' },
    { id: 14, name: 'Mühendislik > Kimya Mühendisi' },
    { id: 15, name: 'Mühendislik > Bilgisayar Mühendisi' },
    { id: 16, name: 'Mühendislik > Endüstri Mühendisi' },
    { id: 17, name: 'Mühendislik > Çevre Mühendisi' },
    { id: 18, name: 'Mühendislik > Yazılım Mühendisi' },
    { id: 19, name: 'Mühendislik > Telekomünikasyon Mühendisi' },
    { id: 20, name: 'Mühendislik > Otomotiv Mühendisi' },

    // Eğitim
    { id: 21, name: 'Eğitim > Öğretmen' },
    { id: 22, name: 'Eğitim > Rehber Öğretmen' },
    { id: 23, name: 'Eğitim > Okul Müdürü' },
    { id: 24, name: 'Eğitim > Üniversite Öğretim Görevlisi' },
    { id: 25, name: 'Eğitim > Okul Psikoloğu' },
    { id: 26, name: 'Eğitim > Eğitim Danışmanı' },
    { id: 27, name: 'Eğitim > Özel Ders Öğretmeni' },
    { id: 28, name: 'Eğitim > Anaokulu Öğretmeni' },
    { id: 29, name: 'Eğitim > Dil Öğretmeni' },
    { id: 30, name: 'Eğitim > Müzik Öğretmeni' },
    { id: 31, name: 'Eğitim > Öğrenci' },

    // İnşaat ve Mimarlık
    { id: 32, name: 'İnşaat ve Mimarlık > İnşaat Mühendisi' },
    { id: 33, name: 'İnşaat ve Mimarlık > Mimari Tasarımcı' },
    { id: 34, name: 'İnşaat ve Mimarlık > İç Mimar' },
    { id: 35, name: 'İnşaat ve Mimarlık > İnşaat İşçisi' },
    { id: 36, name: 'İnşaat ve Mimarlık > Yapı Denetçisi' },
    { id: 37, name: 'İnşaat ve Mimarlık > Elektrikçi' },
    { id: 38, name: 'İnşaat ve Mimarlık > Sıhhi Tesisatçı' },
    { id: 39, name: 'İnşaat ve Mimarlık > İskele Kurulum Uzmanı' },
    { id: 40, name: 'İnşaat ve Mimarlık > Boyacı' },
    { id: 41, name: 'İnşaat ve Mimarlık > Beton Ustası' },
    { id: 42, name: 'İnşaat ve Mimarlık > İşçi' },

    // Hukuk ve Adalet
    { id: 43, name: 'Hukuk ve Adalet > Avukat' },
    { id: 44, name: 'Hukuk ve Adalet > Hakim' },
    { id: 45, name: 'Hukuk ve Adalet > Savcı' },
    { id: 46, name: 'Hukuk ve Adalet > Noter' },
    { id: 47, name: 'Hukuk ve Adalet > Ceza Avukatı' },
    { id: 48, name: 'Hukuk ve Adalet > Ticaret Avukatı' },
    { id: 49, name: 'Hukuk ve Adalet > Aile Hukuku Avukatı' },
    { id: 50, name: 'Hukuk ve Adalet > İcra Avukatı' },
    { id: 51, name: 'Hukuk ve Adalet > İdare Hukuku Avukatı' },
    { id: 52, name: 'Hukuk ve Adalet > İş Hukuku Avukatı' },

    // Finans ve Bankacılık
    { id: 53, name: 'Finans ve Bankacılık > Muhasebeci' },
    { id: 54, name: 'Finans ve Bankacılık > Mali Müşavir' },
    { id: 55, name: 'Finans ve Bankacılık > Vergi Uzmanı' },
    { id: 56, name: 'Finans ve Bankacılık > Bankacı' },
    { id: 57, name: 'Finans ve Bankacılık > Kredi Analisti' },
    { id: 58, name: 'Finans ve Bankacılık > Finansal Analist' },
    { id: 59, name: 'Finans ve Bankacılık > Yatırım Danışmanı' },
    { id: 60, name: 'Finans ve Bankacılık > Sigorta Acentesi' },
    { id: 61, name: 'Finans ve Bankacılık > Borsa Aracısı' },
    { id: 62, name: 'Finans ve Bankacılık > Portföy Yöneticisi' },

    // İnsan Kaynakları
    { id: 63, name: 'İnsan Kaynakları > İnsan Kaynakları Uzmanı' },
    { id: 64, name: 'İnsan Kaynakları > İşe Alım Uzmanı' },
    { id: 65, name: 'İnsan Kaynakları > İşe Gönderme Uzmanı' },
    { id: 66, name: 'İnsan Kaynakları > Performans Yönetimi Uzmanı' },
    { id: 67, name: 'İnsan Kaynakları > Eğitim ve Gelişim Uzmanı' },
    { id: 68, name: 'İnsan Kaynakları > Bordro Uzmanı' },
    { id: 69, name: 'İnsan Kaynakları > İş Sağlığı ve Güvenliği Uzmanı' },
    { id: 70, name: 'İnsan Kaynakları > İşyeri İlişkileri Uzmanı' },
    { id: 71, name: 'İnsan Kaynakları > Yetenek Yönetimi Uzmanı' },
    { id: 72, name: 'İnsan Kaynakları > Kurumsal İletişim Uzmanı' },
    { id: 73, name: 'İnsan Kaynakları > Satın Alma Uzmanı' },

    // Pazarlama ve İletişim
    { id: 74, name: 'Pazarlama ve İletişim > Pazarlama Müdürü' },
    { id: 75, name: 'Pazarlama ve İletişim > Pazarlama Uzmanı' },
    { id: 76, name: 'Pazarlama ve İletişim > Reklamcı' },
    { id: 77, name: 'Pazarlama ve İletişim > Halkla İlişkiler Uzmanı' },
    { id: 78, name: 'Pazarlama ve İletişim > Satış Temsilcisi' },
    { id: 79, name: 'Pazarlama ve İletişim > Marka Yöneticisi' },
    { id: 80, name: 'Pazarlama ve İletişim > Dijital Pazarlama Uzmanı' },
    { id: 81, name: 'Pazarlama ve İletişim > Pazar Araştırmacısı' },
    { id: 82, name: 'Pazarlama ve İletişim > Medya Planlama Uzmanı' },
    { id: 83, name: 'Pazarlama ve İletişim > E-ticaret Uzmanı' },
    { id: 84, name: 'Pazarlama ve İletişim > Mümessil' },
    { id: 85, name: 'Pazarlama ve İletişim > Sosyal Medya Uzmanı' },
    { id: 86, name: 'Pazarlama ve İletişim > SEO Uzmanı' },

    // Bilgi Teknolojileri
    { id: 87, name: 'Bilgi Teknolojileri > Yazılım Geliştirici' },
    { id: 88, name: 'Bilgi Teknolojileri > Sistem Yöneticisi' },
    { id: 89, name: 'Bilgi Teknolojileri > Ağ Mühendisi' },
    { id: 90, name: 'Bilgi Teknolojileri > Veritabanı Yöneticisi' },
    { id: 91, name: 'Bilgi Teknolojileri > Web Tasarımcısı' },
    { id: 92, name: 'Bilgi Teknolojileri > Bilgisayar Teknisyeni' },
    { id: 93, name: 'Bilgi Teknolojileri > Veri Analisti' },
    { id: 94, name: 'Bilgi Teknolojileri > Güvenlik Uzmanı' },
    { id: 95, name: 'Bilgi Teknolojileri > Mobil Uygulama Geliştirici' },
    { id: 96, name: 'Bilgi Teknolojileri > Bilgisayar Programcısı' },

    // Medya ve İletişim
    { id: 97, name: 'Medya ve İletişim > Gazeteci' },
    { id: 98, name: 'Medya ve İletişim > Televizyon Programcısı' },
    { id: 99, name: 'Medya ve İletişim > Yayıncı' },
    { id: 100, name: 'Medya ve İletişim > Medya Planlama Uzmanı' },
    { id: 101, name: 'Medya ve İletişim > Grafik Tasarımcı' },
    { id: 102, name: 'Medya ve İletişim > Kameraman' },
    { id: 103, name: 'Medya ve İletişim > Ses Mühendisi' },
    { id: 104, name: 'Medya ve İletişim > Fotoğrafçı' },
    { id: 105, name: 'Medya ve İletişim > Editör' },
    { id: 106, name: 'Medya ve İletişim > Medya İlişkileri Uzmanı' },

    // Üst Düzey Yöneticiler
    { id: 107, name: 'Üst Düzey Yöneticiler > CEO (Chief Executive Officer)' },
    { id: 108, name: 'Üst Düzey Yöneticiler > CFO (Chief Financial Officer)' },
    { id: 109, name: 'Üst Düzey Yöneticiler > CMO (Chief Marketing Officer)' },
    { id: 110, name: 'Üst Düzey Yöneticiler > CTO (Chief Technology Officer)' },
    { id: 111, name: 'Üst Düzey Yöneticiler > CSO (Chief Security Officer)' },
    { id: 112, name: 'Üst Düzey Yöneticiler > CIO (Chief Information Officer)' },
    { id: 113, name: 'Üst Düzey Yöneticiler > COO (Chief Operating Officer)' },
    { id: 114, name: 'Üst Düzey Yöneticiler > CDO (Chief Data Officer)' },
    { id: 115, name: 'Üst Düzey Yöneticiler > CCO (Chief Communications Officer)' },
    { id: 116, name: 'Üst Düzey Yöneticiler > CHRO (Chief Human Resources Officer)' },

    // Kamu Çalışanları
    { id: 117, name: 'Kamu Çalışanları > Devlet Memuru' },
    { id: 118, name: 'Kamu Çalışanları > Bürokrat' },
    { id: 119, name: 'Kamu Çalışanları > Emniyet Mensubu' },
    { id: 120, name: 'Kamu Çalışanları > Öğretmen' },
    { id: 121, name: 'Kamu Çalışanları > Doktor' },
    { id: 122, name: 'Kamu Çalışanları > Hemşire' },
    { id: 123, name: 'Kamu Çalışanları > Hukukçu' },
    { id: 124, name: 'Kamu Çalışanları > Mühendis' },
    { id: 125, name: 'Kamu Çalışanları > Maliyeci' },
    { id: 126, name: 'Kamu Çalışanları > Çevre Uzmanı' },

    // Proje Yöneticisi
    { id: 127, name: 'Proje Yöneticisi > Proje Yöneticisi' },
    { id: 128, name: 'Proje Yöneticisi > Proje Koordinatörü' },
    { id: 129, name: 'Proje Yöneticisi > Proje Planlama Uzmanı' },
    { id: 130, name: 'Proje Yöneticisi > Proje Analisti' },
    { id: 131, name: 'Proje Yöneticisi > Proje Danışmanı' },
    { id: 132, name: 'Proje Yöneticisi > Program Yöneticisi' },
    { id: 133, name: 'Proje Yöneticisi > Proje Lideri' },

    // Güzellik ve Welness
    { id: 134, name: 'Güzellik ve Welness > Kozmetolog' },
    { id: 135, name: 'Güzellik ve Welness > Makyaj Sanatçısı' },
    { id: 136, name: 'Güzellik ve Welness > Estetisyen' },
    { id: 137, name: 'Güzellik ve Welness > Cilt Bakım Uzmanı' },
    { id: 138, name: 'Güzellik ve Welness > Masör/Masöz' },
    { id: 139, name: 'Güzellik ve Welness > Spa Terapisti' },
    { id: 140, name: 'Güzellik ve Welness > Saç Stilisti/Kuaför' },
    { id: 141, name: 'Güzellik ve Welness > Medikal Estetisyen' },
    { id: 142, name: 'Güzellik ve Welness > Wellness Koçu' },
    { id: 143, name: 'Güzellik ve Welness > Yoga Eğitmeni' },
    { id: 144, name: 'Güzellik ve Welness > Pilates Eğitmeni' },
    { id: 145, name: 'Güzellik ve Welness > Fitness Antrenörü' },
    { id: 146, name: 'Güzellik ve Welness > Beslenme Uzmanı/Diyetisyen' },
    { id: 147, name: 'Güzellik ve Welness > Refleksolog' },
    { id: 148, name: 'Güzellik ve Welness > Aromaterapist' },
    { id: 149, name: 'Güzellik ve Welness > Manikürist/Pedikürist' },
    { id: 150, name: 'Güzellik ve Welness > Waxing Uzmanı' },
    { id: 151, name: 'Güzellik ve Welness > Güzellik Salonu Yöneticisi' },
    { id: 152, name: 'Güzellik ve Welness > Sauna ve Hamam Görevlisi' },
    { id: 153, name: 'Güzellik ve Welness > Göz Bakım Uzmanı' },

    // Spor
    { id: 154, name: 'Spor > Antrenör/Koç' },
    { id: 155, name: 'Spor > Fitness Antrenörü' },
    { id: 156, name: 'Spor > Spor Eğitmeni' },
    { id: 157, name: 'Spor > Spor Bilimcisi' },
    { id: 158, name: 'Spor > Spor Psikoloğu' },
    { id: 159, name: 'Spor > Spor Yöneticisi' },
    { id: 160, name: 'Spor > Spor Gazetecisi/Spor Yazarı' },
    { id: 161, name: 'Spor > Spor Pazarlama Uzmanı' },
    { id: 162, name: 'Spor > Spor Etkinlik Yöneticisi' },
    { id: 163, name: 'Spor > Spor Malzeme Tasarımcısı' },
    { id: 164, name: 'Spor > Spor Hakemi' },
    { id: 165, name: 'Spor > Profesyonel Sporcu' },
    { id: 166, name: 'Spor > Spor Kulübü Yöneticisi' },
    { id: 167, name: 'Spor > Spor Tesis Yöneticisi' },
    { id: 168, name: 'Spor > Spor Etkinliği Koordinatörü' },
    { id: 169, name: 'Spor > Spor Organizatörü' },
    { id: 170, name: 'Spor > Spor Rehabilitasyon Uzmanı' },
    { id: 171, name: 'Spor > Spor Salonu İşletmecisi' },

    // Kişisel Gelişim
    { id: 172, name: 'Kişisel Gelişim > Koç/Yaşam Koçu' },
    { id: 173, name: 'Kişisel Gelişim > NLP Uzmanı' },
    { id: 174, name: 'Kişisel Gelişim > İyi Hissetme Uzmanı' },
    { id: 175, name: 'Kişisel Gelişim > Motivasyonel Konuşmacı' },
    { id: 176, name: 'Kişisel Gelişim > Yoga veya Meditasyon Eğitmeni' },

    // Diğer
    { id: 177, name: 'Diğer > Emekli' },
    { id: 178, name: 'Diğer > İşsiz' },
    { id: 179, name: 'Diğer > Ev Hanımı' },
    { id: 180, name: 'Diğer > Serbest Meslek' },
    { id: 181, name: 'Diğer > Listede Yok' },
];
