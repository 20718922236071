import React from 'react';
import RatingStars from '../../common/RatingStars';

interface ProfitProductInfoProps {
  product: {
    productImg: string;
    productTitle: string;
    brand?: string;
    sellerName: string;
    rating: number;
  };
}

const ProfitProductInfo: React.FC<ProfitProductInfoProps> = ({ product }) => {
  return (
    <div className="flex py-4 items-center gap-1">
      <img
        src={product.productImg}
        width={'20%'}
        className=" rounded-md shadow-lg"
      />
      <div>
        {product.brand && (
          <span className="font-bold text-sm mr-2">{product.brand}</span>
        )}
        <span className="text-sm">
          {product.productTitle.substring(0, 80)}...
        </span>
        <p className="flex gap-2">
          <span className="text-sm uppercase font-bold">
            {product.sellerName}
          </span>
          <RatingStars rating={Number(product?.rating)} shop={false} />
        </p>
      </div>
    </div>
  );
};

export default ProfitProductInfo;
