import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import trendyolToolsService from '../../services/trendyolToolsService';
import LoadingComponent from '../common/LoadingComponent';
import { seperateNumberWithComma } from '../../utils/numberFormatter';
import Pagination from '../common/Pagination';
import memberService from '../../services/memberService';
import { useAuth } from '../../context/AuthContext';
import AliExpressIntegrationModal from '../aliexpress/AliExpressIntegrationModal';
import IntegrationModal from './IntegrationModal';
// import { useLocation, useNavigate } from 'react-router-dom';
import NeedtoBeIntegrated from '../common/NeedtoBeIntegrated';
import ProductCostModal from '../trendyolOrders/ProductCostModal';
// import AdCostModal from '../trendyolOrders/AdCostModal';

const tableHeader = [
  { name: 'Ürün Resmi', column: 'image', isButton: false },
  { name: 'Ürün Başlığı', column: 'title', isButton: false },
  { name: 'SKU Numarası ↑↓', column: 'skuNumber', isButton: true },
  { name: 'Ürün  Fiyatı', column: 'salePrice', isButton: false },
  { name: 'Stok Sayısı', column: 'stock', isButton: false },
  { name: 'Ürün Maliyeti', column: 'productCost', isButton: true },
  { name: 'Reklam Maliyeti', column: 'adCost', isButton: true },
  { name: 'Aliexpress', column: 'aliExpress', isButton: false },
];

export interface ITrendyolProduct {
  status: string;
  data: {
    products: [
      {
        id: number;
        approved: boolean;
        archived: boolean;
        attributes: [
          {
            attributeId: number;
            attributeName: string;
            attributeValue: string;
            attributeValueId: number;
          }
        ];
        barcode: string;
        batchRequestId: string;
        brand: string;
        brandId: string;
        categoryName: string;
        createDateTime: string;
        description: string;
        dimensionalWeight: string;
        gender: null;
        hasActiveCampaign: boolean;
        trId: string;
        lastPriceChangeDate: string;
        lastStockChangeDate: string;
        lastUpdateDate: string;
        listPrice: string;
        locked: boolean;
        onSale: boolean;
        pimCategoryId: string;
        platformListingId: string;
        productCode: string;
        productContentId: string;
        productMainId: string;
        quantity: string;
        salePrice: string;
        stockCode: string;
        stockId: string;
        stockUnitType: string;
        supplierId: string;
        title: string;
        vatRate: string;
        version: string;
        rejected: boolean;
        rejectReasonDetails: 'array';
        blacklisted: boolean;
        images: [
          {
            url: string;
          }
        ];
        reviewCount: string;
        rating: string;
        ratingCount: null;
        isCollected: boolean;
        discountedPrice: string;
        createdAt: string;
        updatedAt: string;
        userId: number;
        producerId: number;
      }
    ];
    totalPageCount: number;
  };
}
const TrendyolProductsTable = () => {
  const { session } = useAuth();
  const [page, setPage] = useState(1);
  const [inputPage, setInputPage] = useState<number | string>(1);
  // const [isIntegrated, setIsIntegrated] = useState(false);
  const [open, setOpen] = useState(false);
  const [openCost, setOpenCost] = useState(false);
  const [openIntegration, setOpenIntegration] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState<number>();
  const [isProductCost, setIsProductCost] = useState(false);
  const [barcode, setBarcode] = useState('');
  const [sort, setSort] = useState('stockCode');
  const [isAsc, setIsAsc] = useState(false);


  const [noProducts, setNoProducts] = useState(false);

  const { isLoading, error, data } = useQuery<ITrendyolProduct, Error>(
    ['trendyolMyProducts', page,sort,isAsc],
    {
      queryFn: () => trendyolToolsService.getMyProducts(page,sort,isAsc),
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      cacheTime: 6000000,
    }
  );

  const onSubmit = async (values: any) => {
    await memberService.integrateAliexpress(values);
    setOpen(false);
  };

  const handleClick = (e: React.SyntheticEvent, id: number) => {
    e.preventDefault();

    if (!session?.isTrendyolIntegrated) {
      setOpen(true);
    } else {
      setOpenIntegration(true);
      setSelectedProductId(id);
    }
  };


  if (!session?.isTrendyolIntegrated) {
    return <NeedtoBeIntegrated />;
  }

  return (
    <div className="flex flex-col flex-shrink-0 bg-white rounded-xl p-5 shadow-lg sm:p-4">
      <div className="flow-root">
        <div className="relative -mx-4 -my-2 overflow-x-auto">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300 table-auto">
              <thead>
                <tr>
                  {tableHeader.map((item, i) => {
                    return (
                      <th
                        key={(i + 1) * 12312}
                        className="border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-sm text-center font-semibold text-gray-900 backdrop-blur backdrop-filter "
                        scope="col"
                      >
                        <p className="text-xs md:text-sm">{item.isButton?<span onClick={()=>{setIsAsc(!isAsc)}}>{item.name}</span>:item.name}</p>
                      </th>
                    );
                  })}
                </tr>
              </thead>

              {isLoading ? (
                <div className="w-full flex justify-center">
                  <LoadingComponent />
                </div>
              ) : (
                <tbody className="divide-y divide-gray-200 bg-white">
                  {data?.data.products.map((product: any, i) => {
                    return (
                      <tr key={(i + 1) * 5000}>
                        <td className="py-4 pl-4 text-xs md:text-sm font-medium text-center text-gray-900 sm:pl-0 w-16">
                          <img
                            className="h-10 mx-auto object-contain"
                            src={
                              product.images.length > 0
                                ? product.images[0].url
                                : ''
                            }
                            alt="product_image_not_found"
                          />
                        </td>

                        <td className="whitespace-nowrap text-center px-3 py-4 text-xs md:text-sm text-gray-500 ">
                          {product.title.substring(0, 50)}...
                        </td>
                        <td className="whitespace-nowrap text-center px-3 py-4 text-xs md:text-sm text-gray-500 ">
                          {product.stockCode ? product.stockCode : '-'}
                        </td>

                        <td className="whitespace-nowrap text-center px-3 py-4 text-xs md:text-sm text-gray-500 ">
                          {seperateNumberWithComma(Number(product.salePrice))}{' '}
                          TL
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          {product.quantity}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          {session?.hasPackage ||
                          session?.role === 'pt04n1dT' ? (
                            <button
                              onClick={() => {
                                setBarcode(product.barcode);
                                setIsProductCost(true);
                                setOpenCost(true);
                              }}
                              className="border-2 border-secondary text-secondary rounded-lg py-1 px-2"
                            >
                              Ürün Maliyeti
                            </button>
                          ) : (
                            <p className="text-primary">
                              Paketiniz bulunmamaktadır
                            </p>
                          )}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          {session?.hasPackage ||
                          session?.role === 'pt04n1dT' ? (
                            <button
                              onClick={() => {
                                setBarcode(product.barcode);
                                setIsProductCost(false);
                                setOpenCost(true);
                              }}
                              className="border-2 border-secondary text-secondary rounded-lg py-1 px-2"
                            >
                              Reklam Maliyeti
                            </button>
                          ) : (
                            <p className="text-primary">
                              Paketiniz bulunmamaktadır
                            </p>
                          )}
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          {session?.isAliExpressIntegrated ||
                          session?.role === 'pt04n1dT' ? (
                            <button
                              disabled={product.aeProcess===1||product.aeProcess===2||product.aeProcess===3}
                              onClick={
                                (e) => handleClick(e, product.id)
                                //burası değişecek
                                // isIntegrated
                                //   ? setOpenIntegration(true)
                                //   : setOpen(true);
                              }
                              className="border-2 border-primary text-primary rounded-lg py-1 px-2"
                            >
                              {(product.aeProcess===0||product.aeProcess===null)&&"AliExpress'e Yükle"}
                              {product.aeProcess===1&&"Yükleniyor..."}
                              {product.aeProcess===2&&"Yüklendi"}
                              {product.aeProcess===3&&"Yüklenemedi..."}
                              
                            </button>
                            
                          ) : (
                            <p className="text-primary">
                              Paketiniz bulunmamaktadır
                            </p>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
      <Pagination
        setPage={setPage}
        page={page}
        inputPage={inputPage}
        setInputPage={setInputPage}
        totalPageCount={data?.data.totalPageCount!}
      />
      <AliExpressIntegrationModal
        open={open}
        setOpen={setOpen}
        onSubmit={onSubmit}
      />
      <ProductCostModal
        open={openCost}
        setOpen={setOpenCost}
        barcode={barcode}
        isProduct={isProductCost}
      />
      {/* <AdCostModal openAd={openAd} setAdOpen={setOpenAd} barcode={'123'} /> */}
      <IntegrationModal
        open={openIntegration}
        setOpen={setOpenIntegration}
        productId={selectedProductId!}
      />
    </div>
  );
};

export default TrendyolProductsTable;
