import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useLanguage } from '../../context/LanguageContext';
import { errorElement } from '../../utils/errorElement';
import AuthService from '../../services/authService';
import { ToastContainer } from 'react-toastify';
import alertNotification from '../../utils/alertNotification';

export default function ForgetPasswordForm() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [emailData, setEmailData] = useState('');
  const { t } = useLanguage();
  const handleForgetPassword = (email: string) => {
    setIsLoading(true);
    AuthService.forgetPassword({ email })
      .then((res: any) => {
        setEmailData(email);
        alertNotification("success", "Şifre yenileme bağlantınız mail adresinize gönderildi.")
      })
      .catch((err: any) => {
        alertNotification('error', err.response.data.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <div className="flex h-screen flex-col justify-center py-12 sm:px-6 lg:px-8 bg-lightGray">
        <ToastContainer />
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-7 w-auto"
            src="/assets/logos/rexven-logo.svg"
            alt="Your Company"
          />
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
            {t?.('forget-password.header')}
          </h2>
        </div>
        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md p-4">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <div className="mb-3 flex justify-end">
              {/* TODO:if you decide to use translation just comment out these lines */}
              {/* <div className="w-28">
                <LanguageSelect />
              </div> */}
            </div>
            <Formik
              initialValues={{
                email: '',
              }}
              onSubmit={(values, actions) => {
                handleForgetPassword(values.email);
                actions.setSubmitting(false);
              }}
              validateOnChange={false}
              validationSchema={Yup.object({
                email: Yup.string()
                  .email('lütfen geçerli bir email adresi giriniz')
                  .required('Bu alan boş bırakılamaz'),
              })}
            >
              {({ handleSubmit, values, handleChange, errors }) => (
                <form onSubmit={handleSubmit} className="space-y-6">
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      {t?.('login.email')}
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="email"
                        placeholder={t?.('login.placeholders.email')}
                        value={values.email}
                        onChange={handleChange}
                        autoComplete="email"
                        className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-secondary focus:outline-none focus:ring-secondary sm:text-sm"
                      />
                      {errors.email && errorElement(errors.email)}
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="flex w-full justify-center rounded-md border border-transparent bg-secondary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2"
                    >
                      {t?.('common.buttons.send-email')}
                    </button>
                  </div>
                </form>
              )}
            </Formik>
            <div className="mt-6">
              <div className="mt-6">
                <Link
                  className="flex w-full justify-center rounded-md border border-transparent bg-primary py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-offset-2"
                  to={'/login'}
                >
                  {' '}
                  {t?.('common.buttons.previous-page')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
