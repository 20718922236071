import React, { useEffect } from 'react';
import { useLanguage } from '../../../../context/LanguageContext';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSearchParams } from 'react-router-dom';

export interface IKeywordFilter {
  minVolume?: number | string;
  maxVolume?: number | string;
  minKeywordCount?: number | string;
  maxKeywordCount?: number | string;
  minTopThreeProductClick?: number | string;
  maxTopThreeProductClick?: number | string;
  minTopThreeProductConversion?: number | string;
  maxTopThreeProductConversion?: number | string;
  keyword?: string;
  minSaleVolume?: number | string;
  maxSaleVolume?: number | string;
  minTotalSaleCount?: number | string;
  maxTotalSaleCount?: number | string;
  minMonthlyRevenue?: number | string;
  maxMonthlyRevenue?: number | string;
  minMonthlySaleCount?: number | string;
  maxMonthlySaleCount?: number | string;
  minGiveawayCount?: number | string;
  maxGiveawayCount?: number | string;
  minPrice?: number | string;
  maxPrice?: number | string;
  minRivalCount?: number | string;
  maxRivalCount?: number | string;
  minProductAge?: number | string;
  maxProductAge?: number | string;
  minSaleReviewRate?: number | string;
  maxSaleReviewRate?: number | string;
}

interface Props {
  setPayload: React.Dispatch<React.SetStateAction<IKeywordFilter>>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  payload: IKeywordFilter;
  setInputPage: React.Dispatch<React.SetStateAction<string | number>>;
  setQueryString: React.Dispatch<React.SetStateAction<string>>;
}
const KeywordForm: React.FC<Props> = ({
  setPayload,
  page,
  payload,
  setPage,
  setInputPage,
  setQueryString,
}) => {
  const { t } = useLanguage();
  const [searchParams, setSearchParams] = useSearchParams();

  const formik = useFormik({
    initialValues: payload
      ? payload
      : {
          minVolume: '',
          maxVolume: '',
          minKeywordCount: '',
          maxKeywordCount: '',
          minTopThreeProductClick: '',
          maxTopThreeProductClick: '',
          minTopThreeProductConversion: '',
          maxTopThreeProductConversion: '',
          keyword: '',
          minSaleVolume: '',
          maxSaleVolume: '',
          minTotalSaleCount: '',
          maxTotalSaleCount: '',
          minMonthlyRevenue: '',
          maxMonthlyRevenue: '',
          minMonthlySaleCount: '',
          maxMonthlySaleCount: '',
          minGiveawayCount: '',
          maxGiveawayCount: '',
          minPrice: '',
          maxPrice: '',
          minRivalCount: '',
          maxRivalCount: '',
          minProductAge: '',
          maxProductAge: '',
          minSaleReviewRate: '',
          maxSaleReviewRate: '',
        },
    onSubmit: (values: IKeywordFilter): void => {
      setPayload(values);
      const params = new URLSearchParams();
      Object.entries(values).forEach(([key, value]) => {
        params.append(key, value);
      });
      // params.append('page', page.toString());
      setPage(1);
      setInputPage(1);
      setSearchParams(params);
      setQueryString(`?${params.toString()}`);
      // setSearchParams({ ...values, page: page.toString() });
    },
    validationSchema: Yup.object().shape({
      minVolume: Yup.number().min(0, t?.('formFilter.noZero')),
      maxVolume: Yup.number().when(
        'minVolume',
        (min: any, schema) =>
          min && schema.min(min, t?.('formFilter.maxVolume'))
      ),
      minSaleVolume: Yup.number().min(0, t?.('formFilter.noZero')),
      maxSaleVolume: Yup.number().when(
        'minSaleVolume',
        (min: any, schema) =>
          min && schema.min(min, t?.('formFilter.maxSaleVolume'))
      ),
      minTotalSaleCount: Yup.number().min(0, t?.('formFilter.noZero')),
      maxTotalSaleCount: Yup.number().when(
        'minTotalSaleCount',
        (min: any, schema) =>
          min && schema.min(min, t?.('formFilter.minTotalSaleCount')) //TODO:buradaki mesajı değiştir
      ),
      minMonthlyRevenue: Yup.number().min(0, t?.('formFilter.noZero')),
      maxMonthlyRevenue: Yup.number().when(
        'minMonthlyRevenue',
        (min: any, schema) =>
          min && schema.min(min, t?.('formFilter.maxMonthlyRevenue')) //TODO:buradaki mesajı değiştir
      ),
      minMonthlySaleCount: Yup.number().min(0, t?.('formFilter.noZero')),
      maxMonthlySaleCount: Yup.number().when(
        'minMonthlySaleCount',
        (min: any, schema) =>
          min && schema.min(min, t?.('formFilter.maxMonthlySaleCount')) //TODO:buradaki mesajı değiştir
      ),
      minKeywordCount: Yup.number().min(0, t?.('formFilter.noZero')),
      maxKeywordCount: Yup.number().when(
        'minKeywordCount',
        (min: any, schema) =>
          min && schema.min(min, t?.('formFilter.minKeywordCount')) //TODO:buradaki mesajı değiştir
      ),
      minGiveawayCount: Yup.number().min(0, t?.('formFilter.noZero')),
      maxGiveawayCount: Yup.number().when(
        'minGiveawayCount',
        (min: any, schema) =>
          min && schema.min(min, t?.('formFilter.maxGiveawayCount')) //TODO:buradaki mesajı değiştir
      ),
      minPrice: Yup.number().min(0, t?.('formFilter.noZero')),
      maxPrice: Yup.number().when(
        'minPrice',
        (min: any, schema) => min && schema.min(min, t?.('formFilter.maxPrice')) //TODO:buradaki mesajı değiştir
      ),
      minRivalCount: Yup.number().min(0, t?.('formFilter.noZero')),
      maxRivalCount: Yup.number().when(
        'minRivalCount',
        (min: any, schema) =>
          min && schema.min(min, t?.('formFilter.maxRivalCount')) //TODO:buradaki mesajı değiştir
      ),
      minProductAge: Yup.number().min(0, t?.('formFilter.noZero')),
      maxProductAge: Yup.number().when(
        'minProductAge',
        (min: any, schema) =>
          min && schema.min(min, t?.('formFilter.maxProductAge')) //TODO:buradaki mesajı değiştir
      ),
      minSaleReviewRate: Yup.number().min(0, t?.('formFilter.noZero')),
      maxSaleReviewRate: Yup.number().when(
        'minSaleReviewRate',
        (min: any, schema) =>
          min && schema.min(min, t?.('formFilter.maxSaleReviewRate')) //TODO:buradaki mesajı değiştir
      ),
      minTopThreeProductClick: Yup.number().min(0, t?.('formFilter.noZero')),
      maxTopThreeProductClick: Yup.number().when(
        'minTopThreeProductClick',
        (min: any, schema) =>
          min && schema.min(min, t?.('formFilter.maxTopThreeProductClick')) //TODO:buradaki mesajı değiştir
      ),
      minTopThreeProductConversion: Yup.number().min(
        0,
        t?.('formFilter.noZero')
      ),
      maxTopThreeProductConversion: Yup.number().when(
        'minTopThreeProductConversion',
        (min: any, schema) =>
          min && schema.min(min, t?.('formFilter.maxTopThreeProductConversion')) //TODO:buradaki mesajı değiştir
      ),
      keyword: Yup.string().required(t?.('validation.required')),
    }),
  });

  useEffect(() => {}, [payload]);

  return (
    <form
      className="w-full py-8 px-4 grid grid-cols-12 gap-4 bg-white shadow-lg rounded-xl"
      onSubmit={formik.handleSubmit}
    >
      {/* SEARCH VOLUME */}
      <div className="col-span-6 md:col-span-3 xl:col-span-4">
        <label
          className="w-full py-2 font-medium text-xs xl:text-sm"
          htmlFor="minVolume"
        >
          Aranma Hacmi
        </label>
        <div className="flex justify-between items-center space-x-2">
          <input
            value={formik.values.minVolume}
            onChange={formik.handleChange}
            id="minVolume"
            name="minVolume"
            type="number"
            className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
            placeholder="Min"
          />
          <p>-</p>
          <input
            value={formik.values.maxVolume}
            onChange={formik.handleChange}
            id="maxVolume"
            name="maxVolume"
            type="number"
            className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
            placeholder="Max"
          />
        </div>
        {/* {formik.errors.saleCountMin &&
              errorElement(formik.errors.saleCountMin!)}
            {formik.errors.saleCountMax &&
              errorElement(formik.errors.saleCountMax!)} */}
      </div>
      {/* ORDER VOLUME */}
      <div className="col-span-6 md:col-span-3 xl:col-span-4">
        <label
          className="w-full py-2 font-medium text-xs xl:text-sm"
          htmlFor="orderVolumeMin"
        >
          Toplam Satış Hacmi
        </label>
        <div className="flex justify-between items-center space-x-2">
          <input
            value={formik.values.minSaleVolume}
            onChange={formik.handleChange}
            id="minSaleVolume"
            name="minSaleVolume"
            type="number"
            className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
            placeholder="Min"
          />
          <p>-</p>
          <input
            value={formik.values.maxSaleVolume}
            onChange={formik.handleChange}
            id="maxSaleVolume"
            name="maxSaleVolume"
            type="number"
            className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
            placeholder="Max"
          />
        </div>
        {/* {formik.errors.saleCountMin &&
              errorElement(formik.errors.saleCountMin!)}
            {formik.errors.saleCountMax &&
              errorElement(formik.errors.saleCountMax!)} */}
      </div>
      {/* SALES COUNT */}
      <div className="col-span-6 md:col-span-3 xl:col-span-4">
        <label
          className="w-full py-2 font-medium text-xs xl:text-sm"
          htmlFor="salesCountMin"
        >
          Toplam Satış Sayısı
        </label>
        <div className="flex justify-between items-center space-x-2">
          <input
            value={formik.values.minTotalSaleCount}
            onChange={formik.handleChange}
            id="minTotalSaleCount"
            name="minTotalSaleCount"
            type="number"
            className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
            placeholder="Min"
          />
          <p>-</p>
          <input
            value={formik.values.maxTotalSaleCount}
            onChange={formik.handleChange}
            id="maxTotalSaleCount"
            name="maxTotalSaleCount"
            type="number"
            className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
            placeholder="Max"
          />
        </div>
        {/* {formik.errors.saleCountMin &&
              errorElement(formik.errors.saleCountMin!)}
            {formik.errors.saleCountMax &&
              errorElement(formik.errors.saleCountMax!)} */}
      </div>
      {/* AVG REVENUE */}
      <div className="col-span-6 md:col-span-3 xl:col-span-4">
        <label
          className="w-full py-2 font-medium text-xs xl:text-sm"
          htmlFor="minMonthlyRevenue"
        >
          Ortalama Aylık Ciro
        </label>
        <div className="flex justify-between items-center space-x-2">
          <input
            value={formik.values.minMonthlyRevenue}
            onChange={formik.handleChange}
            id="minMonthlyRevenue"
            name="minMonthlyRevenue"
            type="number"
            className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
            placeholder="Min"
          />
          <p>-</p>
          <input
            value={formik.values.maxMonthlyRevenue}
            onChange={formik.handleChange}
            id="maxMonthlyRevenue"
            name="maxMonthlyRevenue"
            type="number"
            className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
            placeholder="Max"
          />
        </div>
        {/* {formik.errors.saleCountMin &&
              errorElement(formik.errors.saleCountMin!)}
            {formik.errors.saleCountMax &&
              errorElement(formik.errors.saleCountMax!)} */}
      </div>
      {/* AVG MONTHYLY SALES */}
      <div className="col-span-6 md:col-span-3 xl:col-span-4">
        <label
          className="w-full py-2 font-medium text-xs xl:text-sm"
          htmlFor="minMonthlySaleCount"
        >
          Ortalama Aylık Satış
        </label>
        <div className="flex justify-between items-center space-x-2">
          <input
            value={formik.values.minMonthlySaleCount}
            onChange={formik.handleChange}
            id="minMonthlySaleCount"
            name="minMonthlySaleCount"
            type="number"
            className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
            placeholder="Min"
          />
          <p>-</p>
          <input
            value={formik.values.maxMonthlySaleCount}
            onChange={formik.handleChange}
            id="maxMonthlySaleCount"
            name="maxMonthlySaleCount"
            type="number"
            className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
            placeholder="Max"
          />
        </div>
        {/* {formik.errors.saleCountMin &&
              errorElement(formik.errors.saleCountMin!)}
            {formik.errors.saleCountMax &&
              errorElement(formik.errors.saleCountMax!)} */}
      </div>
      {/* KEYWORD COUNT */}
      <div className="col-span-6 md:col-span-3 xl:col-span-4">
        <label
          className="w-full py-2 font-medium text-xs xl:text-sm"
          htmlFor="minKeywordCount"
        >
          Kelime Sayısı
        </label>
        <div className="flex justify-between items-center space-x-2">
          <input
            value={formik.values.minKeywordCount}
            onChange={formik.handleChange}
            id="minKeywordCount"
            name="minKeywordCount"
            type="number"
            className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
            placeholder="Min"
          />
          <p>-</p>
          <input
            value={formik.values.maxKeywordCount}
            onChange={formik.handleChange}
            id="maxKeywordCount"
            name="maxKeywordCount"
            type="number"
            className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
            placeholder="Max"
          />
        </div>
        {/* {formik.errors.saleCountMin &&
              errorElement(formik.errors.saleCountMin!)}
            {formik.errors.saleCountMax &&
              errorElement(formik.errors.saleCountMax!)} */}
      </div>
      {/* GIVEAWAY COUNT */}
      <div className="col-span-6 md:col-span-3 xl:col-span-4">
        <label
          className="w-full py-2 font-medium text-xs xl:text-sm"
          htmlFor="minGiveawayCount"
        >
          Giveaway Sayısı
        </label>
        <div className="flex justify-between items-center space-x-2">
          <input
            value={formik.values.minGiveawayCount}
            onChange={formik.handleChange}
            id="minGiveawayCount"
            name="minGiveawayCount"
            type="number"
            className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
            placeholder="Min"
          />
          <p>-</p>
          <input
            value={formik.values.maxGiveawayCount}
            onChange={formik.handleChange}
            id="maxGiveawayCount"
            name="maxGiveawayCount"
            type="number"
            className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
            placeholder="Max"
          />
        </div>
        {/* {formik.errors.saleCountMin &&
              errorElement(formik.errors.saleCountMin!)}
            {formik.errors.saleCountMax &&
              errorElement(formik.errors.saleCountMax!)} */}
      </div>
      {/* AVARAGE PRICE */}
      <div className="col-span-6 md:col-span-3 xl:col-span-4">
        <label
          className="w-full py-2 font-medium text-xs xl:text-sm"
          htmlFor="minPrice"
        >
          Ortalama Satış Fiyatı
        </label>
        <div className="flex justify-between items-center space-x-2">
          <input
            value={formik.values.minPrice}
            onChange={formik.handleChange}
            id="minPrice"
            name="minPrice"
            type="number"
            className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
            placeholder="Min"
          />
          <p>-</p>
          <input
            value={formik.values.maxPrice}
            onChange={formik.handleChange}
            id="maxPrice"
            name="maxPrice"
            type="number"
            className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
            placeholder="Max"
          />
        </div>
        {/* {formik.errors.saleCountMin &&
              errorElement(formik.errors.saleCountMin!)}
            {formik.errors.saleCountMax &&
              errorElement(formik.errors.saleCountMax!)} */}
      </div>
      {/* RIVAL COUNT */}
      <div className="col-span-6 md:col-span-3 xl:col-span-4">
        <label
          className="w-full py-2 font-medium text-xs xl:text-sm"
          htmlFor="minRivalCount"
        >
          Rakip Sayısı
        </label>
        <div className="flex justify-between items-center space-x-2">
          <input
            value={formik.values.minRivalCount}
            onChange={formik.handleChange}
            id="minRivalCount"
            name="minRivalCount"
            type="number"
            className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
            placeholder="Min"
          />
          <p>-</p>
          <input
            value={formik.values.maxRivalCount}
            onChange={formik.handleChange}
            id="maxRivalCount"
            name="maxRivalCount"
            type="number"
            className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
            placeholder="Max"
          />
        </div>
        {/* {formik.errors.saleCountMin &&
              errorElement(formik.errors.saleCountMin!)}
            {formik.errors.saleCountMax &&
              errorElement(formik.errors.saleCountMax!)} */}
      </div>
      {/* PRODUCT AGE */}
      <div className="col-span-6 md:col-span-3 xl:col-span-4">
        <label
          className="w-full py-2 font-medium text-xs xl:text-sm"
          htmlFor="minProductAge"
        >
          Ortalama Ürünlerin Yaşı
        </label>
        <div className="flex justify-between items-center space-x-2">
          <input
            value={formik.values.minProductAge}
            onChange={formik.handleChange}
            id="minProductAge"
            name="minProductAge"
            type="number"
            className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
            placeholder="Min"
          />
          <p>-</p>
          <input
            value={formik.values.maxProductAge}
            onChange={formik.handleChange}
            id="maxProductAge"
            name="maxProductAge"
            type="number"
            className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
            placeholder="Max"
          />
        </div>
        {/* {formik.errors.saleCountMin &&
              errorElement(formik.errors.saleCountMin!)}
            {formik.errors.saleCountMax &&
              errorElement(formik.errors.saleCountMax!)} */}
      </div>
      {/* saleReviewRateMin */}
      <div className="col-span-6 md:col-span-3 xl:col-span-4">
        <label
          className="w-full py-2 font-medium text-xs xl:text-sm"
          htmlFor="minSaleReviewRate"
        >
          Satış / Yorum
        </label>
        <div className="flex justify-between items-center space-x-2">
          <input
            value={formik.values.minSaleReviewRate}
            onChange={formik.handleChange}
            id="minSaleReviewRate"
            name="minSaleReviewRate"
            type="number"
            className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
            placeholder="Min"
          />
          <p>-</p>
          <input
            value={formik.values.maxSaleReviewRate}
            onChange={formik.handleChange}
            id="maxSaleReviewRate"
            name="maxSaleReviewRate"
            type="number"
            className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
            placeholder="Max"
          />
        </div>
        {/* {formik.errors.saleCountMin &&
              errorElement(formik.errors.saleCountMin!)}
            {formik.errors.saleCountMax &&
              errorElement(formik.errors.saleCountMax!)} */}
      </div>
      {/* topThreeClickMin */}
      <div className="col-span-6 md:col-span-3 xl:col-span-4">
        <label
          className="w-full py-2 font-medium text-xs xl:text-sm"
          htmlFor="minTopThreeProductClick"
        >
          Top 3 Ürün Tıklama
        </label>
        <div className="flex justify-between items-center space-x-2">
          <input
            value={formik.values.minTopThreeProductClick}
            onChange={formik.handleChange}
            id="minTopThreeProductClick"
            name="minTopThreeProductClick"
            type="number"
            className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
            placeholder="Min"
          />
          <p>-</p>
          <input
            value={formik.values.maxTopThreeProductClick}
            onChange={formik.handleChange}
            id="maxTopThreeProductClick"
            name="maxTopThreeProductClick"
            type="number"
            className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
            placeholder="Max"
          />
        </div>
        {/* {formik.errors.saleCountMin &&
              errorElement(formik.errors.saleCountMin!)}
            {formik.errors.saleCountMax &&
              errorElement(formik.errors.saleCountMax!)} */}
      </div>
      {/* topThreeCpcMin */}
      <div className="col-span-6 md:col-span-3 xl:col-span-4">
        <label
          className="w-full py-2 font-medium text-xs xl:text-sm"
          htmlFor="minTopThreeProductConversion"
        >
          Top 3 Ürün Dönüşüm
        </label>
        <div className="flex justify-between items-center space-x-2">
          <input
            value={formik.values.minTopThreeProductConversion}
            onChange={formik.handleChange}
            id="minTopThreeProductConversion"
            name="minTopThreeProductConversion"
            type="number"
            className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
            placeholder="Min"
          />
          <p>-</p>
          <input
            value={formik.values.maxTopThreeProductConversion}
            onChange={formik.handleChange}
            id="maxTopThreeProductConversion"
            name="maxTopThreeProductConversion"
            type="number"
            className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
            placeholder="Max"
          />
        </div>
        {/* {formik.errors.saleCountMin &&
              errorElement(formik.errors.saleCountMin!)}
            {formik.errors.saleCountMax &&
              errorElement(formik.errors.saleCountMax!)} */}
      </div>
      {/* KEYWORD */}
      <div className="col-span-6 md:col-span-3 xl:col-span-4">
        <label
          className="w-full py-2 font-medium text-xs xl:text-sm"
          htmlFor="keyword"
        >
          Kelime
        </label>
        <input
          value={formik.values.keyword}
          onChange={formik.handleChange}
          id="keyword"
          name="keyword"
          type="text"
          className="w-full rounded-md border border-cardBorder   py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
          placeholder="Keyword"
        />
      </div>
      <div className="col-span-12 xl:col-span-4">
        <div className="flex justify-end space-x-4 items-end h-full">
          <button
            type="button"
            onClick={() => formik.resetForm()}
            className="bg-white w-1/2 border text-xs lg:text-sm border-headerPrimary px-4 py-2 rounded-lg text-headerPrimary"
          >
            Temizle
          </button>
          <button
            type="submit"
            className="bg-headerPrimary text-xs lg:text-sm border border-headerPrimary w-1/2 px-4 py-2 rounded-lg text-white"
          >
            Filtreyi Uygula
          </button>
        </div>
      </div>
    </form>
  );
};

export default KeywordForm;
