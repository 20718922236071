// Google Analytics için bir işlemi tetikleyen yardımcı işlev
export const triggerPurchaseEvent = (event, coupon, item_id, item_name, discount, value, transaction_id) => {
    window.gtag("event", `${event}`, {
        transaction_id: `${transaction_id}`,
        value: `${value}`,
        tax: `${value / 6}`,
        currency: "TRY",
        coupon: `${coupon}`,
        items: [
            {
                item_id: `${item_id}`,
                item_name: `${item_name}`,
                coupon: `${coupon}`,
                discount: `${discount}`,
                price: `${value - value / 6}`,
                quantity: `1`
            }]
    });
};