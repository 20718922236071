import React, { useRef, useState, useEffect } from 'react';
import API_URL from '../../../config';

interface VideoToolsProps {
  videoSrc: string;
}

// /home/shared/7-Software/trendyol-kar-hesaplama.mp4
const VideoTools: React.FC<VideoToolsProps> = ({ videoSrc }) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [video, setVideo] = useState('');

  useEffect(() => {
    const isIphone = navigator.userAgent.match(/iPhone/i) !== null;
    if (isIphone) {
      let videos = videoRef.current!;
      videos.src = `${API_URL}${videoSrc}`;
      videoRef.current?.play();
      setVideo(`${API_URL}${videoSrc}`);
    }
  }, [video]);
  return (
    <div>
      <video
        className="rounded-xl w-full h-auto"
        autoPlay
        muted
        playsInline
        ref={videoRef}
        controls
        controlsList="nodownload"
      >
        <source src={video || `${API_URL}${videoSrc}`} type="video/mp4" />
      </video>
    </div>
  );
};

export default VideoTools;
