import { Dialog, Transition } from '@headlessui/react';
import { MinusIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';
import React, { Fragment, useState } from 'react';
import { errorElement } from '../../utils/errorElement';
import DropImage from '../tools/common/DropImage';
import { useAuth } from '../../context/AuthContext';
import { useQuery } from '@tanstack/react-query';
import { IRootService } from '../../interfaces/serviceProviders';
import memberService from '../../services/memberService';
import axios from 'axios';
import API_URL from '../../config';
import LoadingComponent from '../common/LoadingComponent';
import alertNotification from '../../utils/alertNotification';
import { ToastContainer } from 'react-toastify';

interface ModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const RegisterService: React.FC<ModalProps> = ({ open, setOpen }) => {
  const { session } = useAuth();
  const [isValid, setIsValid] = useState(true);
  const [isUnique, setIsUnique] = useState(true);

  const [priceValid, setPriceValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const [serviceInputs, setServiceInputs] = useState([
    {
      serviceName: { name: 'serviceName1', value: 1, selectedId: 1 },
      price: { name: 'price1', value: '' },
      description: { name: 'description1', value: '' },
    },
  ]);
  const [socialInputs, setSocialInputs] = useState([
    { name: 'social1', value: '' },
  ]);
  const [webUrl, setWebUrl] = useState<string>();
  const [about, setAbout] = useState<string>();
  const [serviceName, setServiceName] = useState<string>();

  const { data } = useQuery<IRootService, Error>(['allServices'], {
    queryFn: () => memberService.getAllServices(),
    staleTime: Infinity,
  });
  const [image, setImage] = useState<any>();
  let http = axios.create({
    headers: {
      'Content-type': 'multipart/form-data',
    },
    baseURL: API_URL,
    withCredentials: true,
  });

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    const payload = new FormData();
    setLoading(true);
    //SERVICES
    let serviceIds = serviceInputs.map((item) => {
      return {
        serviceId: item.serviceName.value,
        serviceFee: item.price.value,
        description: item.description.value,
      };
    });

    //SOCIAL MEDIA LINKS
    let socialMedia = socialInputs.map((item) => item.value);
    payload.append('socialMedia', JSON.stringify(socialMedia));
    payload.append('services', JSON.stringify(serviceIds));
    payload.append('website', String(webUrl));
    payload.append('name', String(serviceName));
    payload.append('description', String(about));
    payload.append('image', image);

    try {
      await http.post('/api/v1/service-providers', payload);

      alertNotification('success', 'Hizmet sağlayıcı talebiniz alınmıştır');
      setOpen(false);
    } catch (error: any) {
      alertNotification('error', error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleClickPlus = (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (
      serviceInputs.length < 6 &&
      serviceInputs[serviceInputs.length - 1].price.value
    ) {
      const size = serviceInputs.length + 1;
      const newObj = {
        serviceName: { name: `serviceName${size}`, value: 1, selectedId: 1 },
        price: { name: `price${size}`, value: '' },
        description: { name: `description${size}`, value: '' },
      };
      setServiceInputs([...serviceInputs, newObj]);
    }
  };
  const handleClickMinus = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (serviceInputs.length > 1) {
      const size = serviceInputs.length - 1;
      setServiceInputs(serviceInputs.filter((_, i) => i !== size));
    }
  };
  const handleClickPlusSocial = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (
      socialInputs.length < 6 &&
      socialInputs[socialInputs.length - 1].value !== ''
    ) {
      const size = socialInputs.length + 1;
      const newObj = { name: `social${size}`, value: '' };
      setSocialInputs([...socialInputs, newObj]);
    }
  };
  const handleClickMinusSocial = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (socialInputs.length > 1) {
      const size = socialInputs.length - 1;
      setSocialInputs(socialInputs.filter((_, i) => i !== size));
    }
  };
  //REGEX
  const urlRegex =
    /^https:\/\/(www\.)?[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\/[a-zA-Z0-9-._~:/?#[\]@!$&'()*+,;=]*)?$/;
  const numberRegex = /^[0-9]+$/;

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { value, name } = e.currentTarget;

    if (name.startsWith('service')) {
      const updatedInputs = serviceInputs.map((input) =>
        input.serviceName.name === name
          ? {
              ...input,
              serviceName: {
                name: name,
                value: Number(value),
                selectedId: Number(value),
              },
            }
          : input
      );
      let temp = updatedInputs.map((item) => item.serviceName.selectedId);
      let setSize = new Set(temp).size;

      if (temp.length !== setSize) {
        setIsUnique(false);
      } else {
        setIsUnique(true);
      }

      setServiceInputs(updatedInputs);
    } else if (name.startsWith('price')) {
      const updatedInputs = serviceInputs.map((input) =>
        input.price.name === name
          ? { ...input, price: { name: name, value: value } }
          : input
      );

      setServiceInputs(updatedInputs);
      if (value && numberRegex.test(value)) {
        setPriceValid(true);
      } else {
        setPriceValid(false);
      }
    } else if (name.startsWith('description')) {
      const updatedInputs = serviceInputs.map((input) =>
        input.description.name === name
          ? { ...input, description: { name: name, value: value } }
          : input
      );

      setServiceInputs(updatedInputs);
    } else if (name.startsWith('social')) {
      const updatedInputs = socialInputs.map((input) => {
        return input.name === name ? { ...input, value: value } : input;
      });

      setSocialInputs(updatedInputs);

      if (value !== '' && !urlRegex.test(value)) {
        setIsValid(false);
      } else {
        setIsValid(true);
      }
    } else if (name === 'webUrl') {
      if (value !== '' && urlRegex.test(value)) {
        setIsValid(true);
      } else {
        setIsValid(false);
      }
      setWebUrl(value);
    } else if (name === 'about') {
      setAbout(value);
    } else if (name === 'providerName') {
      setServiceName(value);
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <ToastContainer />
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative  transform overflow-hidden rounded-lg bg-white  pt-5 pb-4 text-left shadow-xl transition-all w-[95%] sm:my-8 sm:max-w-[500px] ">
                <div className="absolute top-0  right-0  pt-4 pr-4 block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-cardBorder hover:text-gray-500 focus:outline-none"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                <div className="w-full mt-4 px-4">
                  {
                    <div>
                      {/* Registration Form  for Service Providers*/}
                      <form onSubmit={handleSubmit} className="p-2 sm:p-5">
                        <div className="flex items-center justify-start w-full">
                          <div className="flex justify-start w-full items-center space-x-2">
                            <div className="w-1/3">
                              <DropImage setImage={setImage} />
                            </div>
                            <div className="flex flex-col w-2/3 justify-start items-start gap-2 ">
                              <label htmlFor="providerName" className="text-xs">
                                Hizmet Sağlayıcı İsmi
                              </label>
                              <input
                                type="text"
                                name="providerName"
                                id="providerName"
                                value={serviceName}
                                onChange={handleChange}
                                placeholder="Şirket veya kendi isminizi giriniz."
                                className="rounded-md border-2 border-cardBorder w-full text-xs px-1 placeholder:text-[11px]"
                              />
                            </div>
                            {/* <p className="ml-2 text-sm">
                              {session?.firstName} {session?.lastName}{' '}
                            </p> */}
                            {image && (
                              <img
                                src={image?.preview}
                                className="w-24 h-24 rounded-md object-cover object-center hidden sm:block"
                                onLoad={() => {
                                  URL.revokeObjectURL(image.preview);
                                }}
                                alt="preview"
                              />
                            )}
                          </div>
                        </div>
                        <span className="text-xs text-rexRed">
                          Lütfen fotoğraf yükleyiniz
                        </span>
                        <div className="w-full mt-12">
                          <div className="w-full flex justify-between items-center pb-2">
                            <p className="text-xs sm:text-base w-2/3">
                              Verdiğiniz Hizmetleri Seçiniz
                            </p>
                            <p className="text-xs md:text-sm lg:text-base text-start w-1/3 ml-2">
                              Hizmet Bedeli (₺)
                            </p>
                          </div>
                          {serviceInputs?.map((item, idx) => {
                            return (
                              <div
                                key={item.serviceName.name}
                                className="flex flex-col justify-between items-center space-x-2 mb-2"
                              >
                                <div className="flex justify-between items-center space-x-2 mb-2">
                                  <select
                                    className="rounded-md border-2 border-cardBorder text-xs w-2/3"
                                    placeholder="Hizmet kategorisini seçiniz..."
                                    onChange={handleChange}
                                    value={item.serviceName.value}
                                    name={item.serviceName.name}
                                  >
                                    <option value={0}>
                                      Hizmet kategorisi seçiniz...
                                    </option>
                                    {data?.data.map((res) => {
                                      return (
                                        <option
                                          value={Number(res.id)}
                                          key={`service-${res.id}`}
                                        >
                                          {res.serviceName}
                                        </option>
                                      );
                                    })}
                                  </select>

                                  <input
                                    type="text"
                                    name={item.price.name}
                                    id={item.price.name}
                                    className="rounded-md border-2 border-cardBorder text-xs w-1/3"
                                    onChange={handleChange}
                                    value={item.price.value}
                                    placeholder="1200₺"
                                  />
                                </div>
                                <textarea
                                  rows={5}
                                  className="w-full rounded-md border-2  border-cardBorder text-sm md:text-base placeholder:text-sm"
                                  name={item.description.name}
                                  id={item.description.name}
                                  placeholder="Bu hizmeti nasıl verdiğinizi açıklayınız.."
                                  onChange={handleChange}
                                />
                              </div>
                            );
                          })}
                          {!priceValid &&
                            errorElement('Lütfen yalnızca rakam giriniz')}
                          {!isUnique &&
                            errorElement(
                              'Aynı hizmet birden fazla kez seçilemez'
                            )}
                          <div className="w-full flex gap-4 justify-center items-center py-4">
                            <button
                              disabled={!session?.hasPackage}
                              onClick={handleClickPlus}
                              className="w-8 h-8 rounded-md border-2  border-cardBorder flex justify-center items-center"
                            >
                              <PlusIcon height={16} width={16} />
                            </button>
                            <button
                              disabled={!session?.hasPackage}
                              onClick={handleClickMinus}
                              className="w-8 h-8 rounded-md border-2  border-cardBorder flex justify-center items-center"
                            >
                              <MinusIcon height={16} width={16} />
                            </button>
                          </div>
                        </div>
                        <div className="mb-3">
                          <label htmlFor="webUrl" className="block mb-2">
                            Websiteniz
                          </label>
                          <input
                            type="text"
                            name={'webUrl'}
                            id={'webUrl'}
                            value={webUrl}
                            className="rounded-md border-2 border-cardBorder text-xs w-full"
                            onChange={handleChange}
                            placeholder="https://www.rexven.com"
                          />
                          {!isValid &&
                            errorElement('Lütfen belirtilen formatta giriniz!')}
                        </div>
                        <label htmlFor="webUrl" className="block mb-2">
                          Sosyal Medya Hesabı
                        </label>
                        {socialInputs.map((item) => {
                          return (
                            <div key={item.name} className="mb-3">
                              <input
                                type="text"
                                name={item.name}
                                id={item.name}
                                value={item.value}
                                className="rounded-md border-2 border-cardBorder text-xs w-full"
                                onChange={handleChange}
                                placeholder="https://www.instagram.com/rexvencom"
                              />
                            </div>
                          );
                        })}
                        {!isValid &&
                          errorElement('Lütfen belirtilen formatta giriniz!')}
                        <div className="w-full flex gap-4 justify-center items-center py-4">
                          <button
                            disabled={!session?.hasPackage}
                            onClick={handleClickPlusSocial}
                            className="w-8 h-8 rounded-md border-2  border-cardBorder flex justify-center items-center"
                          >
                            <PlusIcon height={16} width={16} />
                          </button>
                          <button
                            disabled={!session?.hasPackage}
                            onClick={handleClickMinusSocial}
                            className="w-8 h-8 rounded-md border-2  border-cardBorder flex justify-center items-center"
                          >
                            <MinusIcon height={16} width={16} />
                          </button>
                        </div>
                        <div>
                          <p>Hakkımda:</p>
                          <textarea
                            name="about"
                            id="about"
                            rows={10}
                            value={about}
                            onChange={handleChange}
                            className="w-full rounded-md border-2  border-cardBorder text-sm"
                            placeholder="Kendinizi detaylı şekilde tanıtınız. Hangi alanlarda uzmansınız? Kaç yıllık tecrübeniz var? Girişimciler neden sizi tercih etmeli?"
                          />
                        </div>

                        <div className="w-full flex justify-center mt-4">
                          {loading ? (
                            <LoadingComponent />
                          ) : (
                            <button
                              disabled={!isValid || !image?.path || !isUnique}
                              type="submit"
                              className="bg-headerPrimary text-white w-4/5 py-2 rounded-lg text-xs sm:text-sm lg:text-base"
                            >
                              Hizmet Sağlayıcı Olarak Başvur
                            </button>
                          )}
                        </div>
                      </form>
                    </div>
                  }
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default RegisterService;
