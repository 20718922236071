import React from 'react';

const SubscriptionPolicy = () => {
  return (
    <div className="flex flex-col max-w-[1280px] mx-auto py-10">
      <p>&Uuml;YELİK S&Ouml;ZLEŞMESİ</p>
      <p>&nbsp;</p>
      <p className="font-medium">1. TARAFLAR</p>
      <p>
        <br />
      </p>
      <p>
        İşbu &Uuml;yelik S&ouml;zleşmesi (&quot;S&ouml;zleşme&quot;),
        www.rexven.com alan adlı internet sitesi ve mobil cihaz uygulamalarının
        (&ldquo;Platform&rdquo;) sahibi olan ve Mutlukent Mah. 2041 Sok. No :5/1
        &Ccedil;ankaya / ANKARA adresinde bulunan Rexven
        Teknoloji A.Ş. (Rexven) ile Platform&rsquo;a işbu
        S&ouml;zleşme&rsquo;deki koşul ve şartları kabul ederek &uuml;ye olan
        kullanıcı (&ldquo;&Uuml;ye&rdquo;, &ldquo;&Uuml;yeler&rdquo;) arasında,
        &Uuml;ye&apos;nin Rexven&rsquo;in sunduğu hizmetlerden yararlanmasına
        ilişkin koşul ve şartların belirlenmesi i&ccedil;in akdedilmiştir.
      </p>
      <p>
        <br />
      </p>
      <p>
        Rexven ve &Uuml;ye işbu S&ouml;zleşme&apos;de ayrı ayrı
        &ldquo;Taraf&rdquo; birlikte &ldquo;Taraflar&rdquo; olarak anılacaktır.
      </p>
      <p>
        <br />
      </p>
      <p className="font-medium">2. TANIMLAR</p>
      <p>
        <br />
      </p>
      <div>
        <table>
          <tbody>
            <tr>
              <td className="border-2 border-lightGray">
                <p>Alıcı/Alıcılar</p>
              </td>
              <td className="border-2 border-lightGray">
                <p>:</p>
              </td>
              <td className="border-2 border-lightGray">
                <p>
                  Platform &uuml;zerinde Rexven tarafından satışa arz edilen
                  &uuml;r&uuml;n ve/veya hizmetleri satın alan veya platformda
                  yer alan &uuml;&ccedil;&uuml;nc&uuml; kişi Hizmet Sağlayıcılar
                  ve Satıcılar ile &lsquo;iletişim kur&rsquo; butonu vasıtasıyla
                  iletişime ge&ccedil;en ger&ccedil;ek veya t&uuml;zel kişi
                  &Uuml;ye&rsquo;yi ifade eder.
                </p>
              </td>
            </tr>
            <tr>
              <td className="border-2 border-lightGray">
                <p>
                  Gizlilik Politikası, Veri Koruma Bildirimi ve Kullanım
                  Koşulları
                </p>
              </td>
              <td className="border-2 border-lightGray">
                <p>:</p>
              </td>
              <td className="border-2 border-lightGray">
                <p>
                  &Uuml;yeler&rsquo;in Platform &uuml;zerinden ilettikleri
                  kişisel verilerin, Rexven tarafından hangi ama&ccedil;larla ve
                  ne şekilde kullanılacağı gibi konular da dahil olmak
                  &uuml;zere Rexven&rsquo;in kişisel verilere ve &ccedil;erez
                  kullanımına ilişkin genel gizlilik politikasını
                  d&uuml;zenleyen ve Platform &uuml;zerinden&nbsp;
                  <a href="https://rexven.com/kisisel-verilerin-korunmasi-aydinlatma-metni/">
                    https://rexven.com/kisisel-verilerin-korunmasi-aydinlatma-metni/
                  </a>{' '}
                  ,{' '}
                  <a href="https://rexven.com/kisisel-verilerin-korunmasi-aydinlatma-metni/">
                    https://rexven.com/kisisel-verilerin-korunmasi-aydinlatma-metni/
                  </a>
                  &nbsp; ,&nbsp;
                  <a href="https://rexven.com/kisisel-verilerin-korunmasi-aydinlatma-metni/">
                    https://rexven.com/kullanim-kosullari/
                  </a>
                  &nbsp; &nbsp;linklerinden erişilebilecek olan metinleri ifade
                  eder.
                </p>
              </td>
            </tr>
            <tr>
              <td className="border-2 border-lightGray">
                <p>Hesabım Sayfası</p>
              </td>
              <td className="border-2 border-lightGray">
                <p>:</p>
              </td>
              <td className="border-2 border-lightGray">
                <p>
                  &Uuml;ye&rsquo;nin Platform&rsquo;da yer alan &ccedil;eşitli
                  hizmetlerden yararlanabilmek i&ccedil;in gerekli işlemleri
                  ger&ccedil;ekleştirdiği, gerekli bilgileri eklediği, sadece
                  ilgili &Uuml;ye tarafından belirlenen kullanıcı adı ve şifre
                  ile erişilebilen &Uuml;ye&rsquo;ye &ouml;zel sayfayı ifade
                  eder.
                </p>
              </td>
            </tr>
            <tr>
              <td className="border-2 border-lightGray">
                <p>Hizmetler</p>
              </td>
              <td>
                <p>:</p>
              </td>
              <td className="border-2 border-lightGray">
                <p>
                  İşbu S&ouml;zleşme uyarınca &Uuml;yeler&rsquo;in işbu
                  S&ouml;zleşme i&ccedil;erisinde tanımlı olan iş ve işlemlerini
                  ger&ccedil;ekleştirmelerini sağlamak amacıyla Rexven
                  tarafından sunulan ve nitelikleri Rexven tarafından belirlenen
                  uygulamaları ifade eder.
                </p>
              </td>
            </tr>
            <tr>
              <td className="border-2 border-lightGray">
                <p>Tedarikçi/Hizmet Sağlayıcı</p>
              </td>
              <td>
                <p>:</p>
              </td>
              <td className="border-2 border-lightGray">
                <p>
                  Rexven Platformunda oluşturduğu hesap &uuml;zerinden
                  yayınladığı listeler aracılığıyla &ccedil;eşitli
                  &uuml;r&uuml;n ve/veya hizmetleri tanıtan ger&ccedil;ek veya
                  t&uuml;zel kişi &Uuml;ye&rsquo;yi ifade eder.
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p>
        <br />
      </p>
      <p className="font-medium">
        3. TARAFLARIN HAK VE Y&Uuml;K&Uuml;ML&Uuml;L&Uuml;KLERİ
      </p>
      <p>
        <br />
      </p>
      <p>
        3.1.&nbsp;&Uuml;yelik stat&uuml;s&uuml;n&uuml;n kazanılabilmesi
        i&ccedil;in, &Uuml;ye olmak isteyen kullanıcının, işbu
        S&ouml;zleşme&apos;yi onaylayarak, Platform&rsquo;da kendisinden talep
        edilen bilgileri doğru, tam ve g&uuml;ncel şekilde doldurması ve
        &uuml;yelik başvurusunun Rexven tarafından onaylanması gerekmektedir.
        &Uuml;ye olmak isteyen kullanıcının 18 (on sekiz) yaşını doldurmuş
        olması, Rexven tarafından belirlenecek ve/veya ilgili mevzuat
        h&uuml;k&uuml;mleri uyarınca gerekli şartları haiz olması gerekmektedir.
        Onay işleminin tamamlanması ve durumun &Uuml;ye&apos;ye bildirilmesi ile
        &Uuml;ye&rsquo;nin &uuml;yelik stat&uuml;s&uuml; başlamakta ve
        b&ouml;ylece &Uuml;ye işbu S&ouml;zleşme&rsquo;de ve Platform&rsquo;da
        &Uuml;yeler i&ccedil;in tanımlanan haklara kavuşmaktadır. İşbu
        S&ouml;zleşme&rsquo;de ve Platform&rsquo;da &Uuml;yeler i&ccedil;in
        tanımlanan y&uuml;k&uuml;ml&uuml;l&uuml;kler ise &Uuml;ye&rsquo;nin işbu
        S&ouml;zleşme&rsquo;yi onaylamasıyla birlikte &Uuml;ye i&ccedil;in
        bağlayıcı ve ge&ccedil;erli olacaktır. Platform&rsquo;da kendisinden
        talep edilen bilgileri doğru, tam ve g&uuml;ncel şekilde sağlamayan
        &Uuml;ye, bu sebeple doğabilecek t&uuml;m zararlardan bizzat sorumlu
        olup; aksine bir durumun tespiti halinde, Rexven, her t&uuml;rl&uuml;
        talep, dava ve takip hakları saklı kalmak &uuml;zere, &Uuml;ye&rsquo;nin
        &uuml;yeliğini kısmen veya tamamen kısıtlama, engelleme, askıya alma
        veya sona erdirme hak ve yetkisine sahip olacaktır. &Uuml;ye,
        bilgilerini Platform vasıtasıyla her zaman g&uuml;ncelleyebilecektir.
      </p>
      <p>
        <br />
      </p>
      <p>
        3.2.&nbsp;&Uuml;yelik hesabı, bu hesabı oluşturan &Uuml;ye&rsquo;ye
        &ouml;zeldir; &Uuml;ye, &uuml;yelik hesabını
        &uuml;&ccedil;&uuml;nc&uuml; bir kişiye kullandırmayacak ve diğer
        &Uuml;yeler&rsquo;in &uuml;yelik hesaplarını kullanmayacaktır.
      </p>
      <p>
        <br />
      </p>
      <p>
        3.3.&nbsp;Rexven; amazon, etsy, trendyol gibi elektronik ticaret
        sitelerinde &uuml;r&uuml;nlerini pazarlamak ve satış yapmak
        &nbsp;isteyen &Uuml;yelere eğitim ve danışmanlık hizmeti vermekte,
        e-ticaret konusunda yazılım ara&ccedil;ları geliştirerek bunların
        kullanımını &Uuml;yelere a&ccedil;maktadır. Ayrıca 5651 sayılı İnternet
        Ortamında Yapılan Yayınların D&uuml;zenlenmesi ve Bu Yayınlar Yoluyla
        İşlenen Su&ccedil;larla M&uuml;cadele Edilmesi Hakkında Kanun uyarınca
        &quot;yer sağlayıcı&quot; sıfatıyla &uuml;r&uuml;nlerini ve/veya
        hizmetlerini tanıtan Tedarikçiler/Hizmet Sağlayıcılar ile
        alıcıları Platform &uuml;zerinden buluşturarak
        Tedarikçiler/Hizmet Sağlayıcılar&rsquo;ın
        Platform&rsquo;da &uuml;r&uuml;nlerinin ve/veya hizmetlerinin
        tanıtımlarını yapmalarına aracılık etmektedir. Rexven, Platform&rsquo;da
        yer alan Tedarikçiler/Hizmet Sağlayıcılar&rsquo;ın
        &uuml;r&uuml;nlerinin/hizmetlerinin yer aldığı listelerdeki
        hi&ccedil;bir g&ouml;rsel, yazılı veya sair nitelikteki i&ccedil;eriğin
        ger&ccedil;ekliğinden, g&uuml;venilirliğinden, doğruluğundan ya da
        hukuka uygunluğundan sorumlu değildir ve Rexven&rsquo;in s&ouml;z konusu
        i&ccedil;erikleri kontrol etme veya Platform&rsquo;da hukuka aykırı bir
        faaliyetin s&ouml;z konusu olup olmadığını araştırma
        y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml; bulunmamaktadır. Şu kadar ki, işbu
        madde kapsamında Rexven&rsquo;in s&ouml;z konusu i&ccedil;erikleri
        kontrol etme veya Platform&rsquo;da hukuka aykırı bir faaliyetin
        s&ouml;z konusu olup olmadığını araştırma
        y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml; bulunmadığı durumlar
        Tedarikçiler/Hizmet Sağlayıcılar&rsquo;ın
        &uuml;r&uuml;nlerinin/hizmetlerinin yer aldığı listelerle sınırlı olup
        Platform&rsquo;da satışa sunduğu kendi &uuml;r&uuml;n ve/veya hizmetler
        i&ccedil;in uygulama alanı bulmayacaktır.
      </p>
      <p>
        <br />
      </p>
      <p>
        3.4.&nbsp;Alıcı, Rexven&rsquo;in bizzat satıcı konumunda olduğu mesafeli
        satış s&ouml;zleşmeleri hari&ccedil; olmak &uuml;zere,
        Tedarikçiler/Hizmet Sağlayıcılar ile akdedilecek mesafeli
        satış s&ouml;zleşmelerinde veya diğer s&ouml;zleşmelerde
        Tedarikçiler/Hizmet Sağlayıcılar ile kendisinin ise taraf
        olduğunu, Rexven&rsquo;in mesafeli satış s&ouml;zleşmelerine veya diğer
        s&ouml;zleşmelere hi&ccedil;bir surette taraf olmadığını,
        y&uuml;r&uuml;rl&uuml;kteki t&uuml;ketici mevzuatı ve sair mevzuat
        kapsamında kendisine karşı tek sorumlunun yalnızca
        Tedarikçiler/Hizmet Sağlayıcılar&rsquo;ın olduğunu ve
        Rexven&rsquo;in herhangi bir sorumluluğu bulunmadığını kabul, beyan ve
        taahh&uuml;t eder. Tedarikçiler/Hizmet Sağlayıcılar, işbu
        maddede belirtilen hususlara ek olarak, Platform&rsquo;da sergiledikleri
        t&uuml;m &uuml;r&uuml;n ve/veya hizmetlerin kalitesinden, mevzuata
        uygunluğundan, zamanında ve ayıpsız şekilde teslim edilmesinden,
        faturalandırılmasından, garanti belgesi de dahil olmak &uuml;zere
        gerekli t&uuml;m belgelerin tesliminden, satış sonrası servislerin ve
        sair hizmetlerin sunulmasından ve sair t&uuml;m
        y&uuml;k&uuml;ml&uuml;l&uuml;klerin yerine getirilmesinden ve de bu
        hususlardaki t&uuml;m talep ve şikayetlerden bizzat sorumludur.
        &Uuml;ye, &uuml;r&uuml;n ve/veya hizmetlere ilişkin olarak, işbu maddede
        sayılanlar da dahil ancak bunlarla sınırlı olmamak &uuml;zere
        hi&ccedil;bir sorumluluğu ve talep ve şikayetlerini Rexven&rsquo;e
        y&ouml;neltemeyeceğini, bu kapsamdaki tek muhatabının ilgili
        &uuml;r&uuml;n ve/veya hizmeti Platformda listeleyen
        Tedarikçiler/Hizmet Sağlayıcılar olacağını kabul, beyan
        ve taahh&uuml;t eder. Ayrıca, &Uuml;ye, Platform, Hizmetler ve/veya
        Platform dahilinde y&uuml;r&uuml;t&uuml;len faaliyetler ile ilgili
        olarak herhangi bir hukuki işlem başlatmak istemesi halinde,
        Platform&rsquo;da yer alanlar da dahil olmak &uuml;zere t&uuml;m gerekli
        yasal y&uuml;k&uuml;ml&uuml;l&uuml;kler ve prosed&uuml;rleri bizzat
        yerine getirmek zorunda olduğunu, bu y&uuml;k&uuml;ml&uuml;l&uuml;kler
        ve prosed&uuml;rlerle ilgili olarak Rexven&rsquo;in herhangi bir
        bilgisinin ve sorumluluğunun bulunmadığını kabul, beyan ve taahh&uuml;t
        eder.
      </p>
      <p>&nbsp;</p>
      <p>
        3.5. Alıcı, Rexven ile arasındaki s&ouml;zleşmesel ilişki kapsamında,
        Tedarikçiler/Hizmet Sağlayıcılar ile Platformda
        listelenen her listede bulunan &lsquo;İletişim Kur&rsquo; butonu
        vasıtasıyla iletişime ge&ccedil;mektedir. Bu buton &uuml;yeyi whatsapp
        online iletişim kanalına y&ouml;nlendirmekte ve &uuml;ye bu kanal
        vasıtasıyla iletişim kurabilmektedir. Alıcı ile
        Tedarikçiler/Hizmet Sağlayıcılar arasında &uuml;r&uuml;n
        ve/veya hizmet satım ilişkisi Platform &uuml;zerinden hi&ccedil;bir
        şekilde ger&ccedil;ekleştirilemez, ger&ccedil;ekleştirilmesini
        sağlayacak bir sistemi yoktur. Bu ilişkide Rexven taraf olmadığı gibi
        &uuml;r&uuml;n ve/veya hizmet bedelini tahsil etme konusunda da yetkili
        değildir.&nbsp;
      </p>
      <p>
        <br />
      </p>
      <p>
        3.6. &Uuml;ye Platform&rsquo;da ger&ccedil;ekleştirdiği her
        t&uuml;rl&uuml; iş ve işlemde, işbu S&ouml;zleşme&apos;nin
        h&uuml;k&uuml;mlerine, Rexven&rsquo;in herhangi bir suretle
        duyurduğu/bildirdiği Platform kurallarına, y&uuml;r&uuml;rl&uuml;kteki
        mevzuata ve ahlak kurallarına uygun olarak hareket edeceğini ve bu iş ve
        işlemlere ilişkin hukuki ve cezai sorumluluğun bizzat kendisine ait
        olduğunu kabul, beyan ve taahh&uuml;t eder.
      </p>
      <p>
        <br />
      </p>
      <p>
        3.7. Rexven, y&uuml;r&uuml;rl&uuml;kteki mevzuat uyarınca, yetkili
        makamların talebi halinde &Uuml;ye&apos;nin bilgilerini s&ouml;z konusu
        makamlarla paylaşabilecektir.
      </p>
      <p>
        <br />
      </p>
      <p>
        3.8. Gerek Platform&rsquo;a kayıt sırasında gerekse de Platform&rsquo;u
        kullanırken &Uuml;ye&rsquo;lerden alınan kişisel veriler, &Uuml;yeler
        (Alıcı ile Tedarikçiler/Hizmet Sağlayıcılar) arasında
        sahtecilik, dolandırıcılık, Platform&rsquo;un k&ouml;t&uuml;ye
        kullanımı, T&uuml;rk Ceza Kanunu anlamında su&ccedil; oluşturabilecek
        konularda &ccedil;ıkan uyuşmazlıklarda, yalnızca talep edilen konu ile
        sınırlı olmak &uuml;zere tarafların yasal haklarını kullanabilmeleri
        amacıyla ve sadece bu kapsam ile sınırlı olmak &uuml;zere uyuşmazlığa
        taraf olabilecek diğer &Uuml;ye ve/veya Satıcılar&apos;a
        iletebilecektir.
      </p>
      <p>
        <br />
      </p>
      <p>
        3.9. &Uuml;ye tarafından Platform&rsquo;a kayıt sırasında &uuml;yelik
        hesabının oluşturulması i&ccedil;in belirlenen kullanıcı adı ve şifre
        bilgileri de dahil olmak &uuml;zere t&uuml;m bilgilere ilişkin t&uuml;m
        sorumluluk &Uuml;ye&rsquo;ye aittir. &Uuml;yelik hesabının şifresinin
        g&uuml;venli bir şekilde belirlenmesi ve belirli aralıklarla
        değiştirilmesi, &uuml;yelik hesabının ve &uuml;yelik hesabının
        oluşturulması i&ccedil;in belirlenen bilgilerin g&uuml;venliğinin
        sağlanması, gizliliğinin korunması ve bu kapsamda gerekli her
        t&uuml;rl&uuml; &ouml;nlemin alınması tamamen &Uuml;ye&apos;nin
        sorumluluğundadır. &Uuml;ye, Rexven&rsquo;in, bu bilgilerin
        &uuml;&ccedil;&uuml;nc&uuml; kişilerce ele ge&ccedil;irilmesinden
        doğabilecek zararlar da dahil olmak &uuml;zere hi&ccedil;bir
        sorumluluğunun bulunmadığını kabul, beyan ve taahh&uuml;t eder. Şifre
        kullanımına ve/veya &uuml;yelik hesabı ve şifresinin g&uuml;venliğine
        ilişkin bir problemin varlığı halinde, Rexven, her t&uuml;rl&uuml;
        talep, dava ve takip hakları saklı kalmak &uuml;zere, &Uuml;ye&rsquo;nin
        &uuml;yeliğini kısmen veya tamamen kısıtlama, engelleme, askıya alma
        veya sona erdirme hak ve yetkisine sahiptir.
      </p>
      <p>
        <br />
      </p>
      <p>
        3.10. &Uuml;ye, kendisine ait kullanıcı adı ve şifre kullanılarak
        ger&ccedil;ekleştirilen iş ve işlemlerin kendisi tarafından
        ger&ccedil;ekleştirilmiş kabul edileceğini, bu iş ve işlemlerden
        kaynaklanan sorumluluğun kendisine ait olduğunu, bu iş ve işlemleri
        kendisinin ger&ccedil;ekleştirmediği yolunda herhangi bir def&apos;i
        ve/veya itiraz ileri s&uuml;remeyeceğini ve/veya bu def&apos;i veya
        itiraza dayanarak y&uuml;k&uuml;ml&uuml;l&uuml;klerini yerine
        getirmekten ka&ccedil;ınamayacağını kabul, beyan ve taahh&uuml;t eder.
      </p>
      <p>
        <br />
      </p>
      <p>
        3.11. &Uuml;ye, Platform&rsquo;u ve Platform&rsquo;daki i&ccedil;erik,
        materyal ve unsurları, işbu S&ouml;zleşme&rsquo;ye, Rexven&rsquo;in
        herhangi bir suretle duyurduğu/bildirdiği Platform kurallarına, ilgili
        mevzuat h&uuml;k&uuml;mlerine ve ahlak kurallarına uygun olarak
        kullanacağını, aykırı kullanımlarından kaynaklanabilecek her
        t&uuml;rl&uuml; hukuki ve cezai sorumluluğun bizzat kendisine ait
        olacağını, Rexven&rsquo;in aykırı bir kullanımı tespit etmesi halinde,
        her t&uuml;rl&uuml; talep, dava ve takip hakları saklı kalmak
        &uuml;zere, &Uuml;ye&rsquo;nin &uuml;yeliğini kısmen veya tamamen
        kısıtlama, engelleme, askıya alma veya sona erdirme hak ve yetkisine
        sahip olacağını kabul, beyan ve taahh&uuml;t eder. &nbsp;
      </p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;</p>
      <p>
        Aşağıda işbu madde uyarınca &ldquo;aykırı kullanım&rdquo; olarak
        nitelendirilebilecek durumlar &ouml;rnekleme yoluyla sayılmıştır:
      </p>
      <p>
        <br />
      </p>
      <ul>
        <li>
          <p>
            Platform&rsquo;daki işlemler ger&ccedil;ekleştirilirken
            Rexven&rsquo;e, Platform&rsquo;a ve/veya
            &uuml;&ccedil;&uuml;nc&uuml; kişilere herhangi bir suretle zarar
            verilmesi;
          </p>
        </li>
        <li>
          <p>
            Platform&rsquo;un herhangi bir kişi adına veri tabanı, kayıt veya
            rehber yaratmak, kontrol etmek, g&uuml;ncellemek ve/veya değiştirmek
            amacıyla kullanılması;
          </p>
        </li>
        <li>
          <p>
            Platform&rsquo;un b&uuml;t&uuml;n&uuml;n&uuml;n veya bir
            b&ouml;l&uuml;m&uuml;n&uuml;n bozma, değiştirme ve/veya ters
            m&uuml;hendislik yapma amacıyla kullanılması;
          </p>
        </li>
        <li>
          <p>
            Yanlış ve/veya bir başkasının bilgileri kullanılarak işlem
            yapılması, yanlış ve/veya yanıltıcı kişisel veriler kullanılmak
            suretiyle ger&ccedil;ek olmayan &Uuml;yelik hesapları oluşturulması
            ve/veya bu hesapların işbu S&ouml;zleşme&rsquo;ye, Rexven&rsquo;in
            herhangi bir suretle duyurduğu/bildirdiği Platform kurallarına,
            y&uuml;r&uuml;rl&uuml;kteki mevzuata ve/veya ahlak kurallarına
            aykırı şekilde kullanılması ve/veya başka bir &Uuml;ye&apos;nin
            hesabının izinsiz kullanılması;
          </p>
        </li>
        <li>
          <p>
            Vir&uuml;s veya Platform&rsquo;a, Platform&rsquo;un veri tabanına,
            Platform&rsquo;da yer alan herhangi bir i&ccedil;eriğe zarar verici
            herhangi başka bir teknoloji yayılması;
          </p>
        </li>
        <li>
          <p>
            Hesap Sayfası&rsquo;na robot veya otomatik giriş y&ouml;ntemleriyle
            girilmesi;
          </p>
        </li>
        <li>
          <p>
            Platform tarafından belirlenmiş olan iletişimler ve teknik sistemler
            &uuml;zerinde makul olmayan veya orantısız derecede b&uuml;y&uuml;k
            y&uuml;klemeler yaratacak ya da teknik işleyişe zarar verecek
            faaliyetlerde bulunulması, Platform&rsquo;un &uuml;zerinde otomatik
            program, robot, web crawler, &ouml;r&uuml;mcek, veri madenciliği
            (data mining) ve veri taraması (data crawling) gibi &quot;screen
            scraping&quot; yazılımları veya sistemleri kullanılması ve/veya bu
            veya benzeri nitelikteki herhangi bir işlemle Platform&rsquo;da yer
            alan herhangi bir i&ccedil;eriğin tamamının veya bir kısmının
            izinsiz kopyalanması, yayınlanması veya kullanılması;
          </p>
        </li>
        <li>
          <p>
            Platform&rsquo;a zarar verecek her t&uuml;rl&uuml; program,
            vir&uuml;s, yazılım, lisanssız &uuml;r&uuml;n, truva atı ve sair
            tehlikelere karşı gerekli koruyucu yazılımların ve lisanslı
            &uuml;r&uuml;nlerin kullanılması da dahil olmak &uuml;zere gerekli
            tedbirlerin alınmaması;
          </p>
        </li>
        <li>
          <p>
            Platform&rsquo;un, &Uuml;yeler&rsquo;in Platform deneyimlerini
            olumsuz y&ouml;nde etkileyecek nitelikte kullanılması ve/veya diğer
            &Uuml;yeler&rsquo;in kişilik ve m&uuml;lkiyet haklarına y&ouml;nelik
            saldırılarda bulunulması.
          </p>
        </li>
      </ul>
      <p>
        <br />
      </p>
      <p>
        3.12.&nbsp;&Uuml;ye, gerek Platform&rsquo;a kayıt sırasında gerekse
        Platform&rsquo;u kullanırken y&uuml;klediği ve sağladığı kişisel bilgi,
        fotoğraf, video, fikir, ifade, yorum, puan, yazışma ve sair t&uuml;m
        i&ccedil;eriklerin (&ldquo;&Uuml;ye İ&ccedil;erikleri&rdquo;)
      </p>
      <ul>
        <li>
          <p>
            İşbu S&ouml;zleşme&rsquo;ye, Rexven&rsquo;in herhangi bir suretle
            duyurduğu/bildirdiği Platform kurallarına, ilgili mevzuat
            h&uuml;k&uuml;mlerine ve ahlak kurallarına uygun olacağını,
          </p>
        </li>
        <li>
          <p>
            Hukuka, ahlaka ve &uuml;&ccedil;&uuml;nc&uuml; kişilerin haklarına
            (fikri ve sınai m&uuml;lkiyet hakları, &ouml;zel hayatın gizliliği
            hakkı ve kişilik hakları dahil ancak bunlarla sınırlı olmamak
            &uuml;zere) aykırı olmayacağını,
          </p>
        </li>
        <li>
          <p>
            Platform dışında yayınlanması da dahil olmak &uuml;zere &Uuml;ye
            İ&ccedil;erikleri&rsquo;ni Platform dışı ama&ccedil;larla ve/veya
            Rexven sistemlerini manip&uuml;le edecek şekilde kullanılmayacağını,
          </p>
        </li>
        <li>
          <p>
            Yalnızca işbu S&ouml;zleşme, Rexven&rsquo;in herhangi bir suretle
            duyurduğu/bildirdiği Platform kuralları ve ilgili mevzuat
            h&uuml;k&uuml;mleri ile izin verilen durumlar i&ccedil;in
            oluşturulacağını, &ouml;rneğin yalnızca ilgili mevzuat uyarınca
            yorum ve puanlama yapma hakkına haiz olduğu &uuml;r&uuml;n ve/veya
            hizmetlere yorum yapacağını,
          </p>
        </li>
        <li>
          <p>
            Konusu su&ccedil; teşkil eden, uluslararası anlaşmaların ihlali
            sonucunu doğuran ya da ihlalini teşvik eden, reklam ve/veya
            pazarlama i&ccedil;erikli, pornografik, &ccedil;ıplaklık
            i&ccedil;eren ya da toplumca genel kabul g&ouml;rm&uuml;ş kurallara,
            fikri ve sınai haklara aykırı, haksız rekabet yaratan ve/veya benzer
            nitelikte olmayacağını ve Rexven&rsquo;in bu t&uuml;r
            i&ccedil;eriklere hi&ccedil;bir durumda izin vermediğini bilir ve
            kabul eder. &Uuml;ye, Rexven&rsquo;in &Uuml;ye
            İ&ccedil;erikleri&rsquo;ne ilişkin herhangi bir kontrol
            y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml;n&uuml;n bulunmadığını, &Uuml;ye
            İ&ccedil;erikleri&rsquo;nden m&uuml;nhasıran kendisinin sorumlu
            olduğunu ve &Uuml;ye İ&ccedil;erikleri dolayısıyla Rexven&rsquo;de,
            Plarform&rsquo;da ve/veya &uuml;&ccedil;&uuml;nc&uuml; kişilerde
            meydana gelecek her t&uuml;rl&uuml; zararı ilk talep &uuml;zerine,
            derhal, nakden ve defaten tazmin etmekle y&uuml;k&uuml;ml&uuml;
            olduğunu kabul eder. Rexven&rsquo;in &Uuml;ye
            İ&ccedil;erikleri&rsquo;ne ilişkin herhangi bir kontrol
            y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml; olduğu anlamına gelmemekle
            birlikte, Rexven, resen veya aldığı bir şik&acirc;yet/bildirim
            &uuml;zerine &Uuml;ye İ&ccedil;erikleri&rsquo;ni kontrol etme ve
            &Uuml;ye İ&ccedil;erikleri&rsquo;ne ilişkin ihlalleri araştırma
            hakkını saklı tutar; Rexven, tamamen kendi takdirinde olmak
            &uuml;zere işbu S&ouml;zleşme&rsquo;ye aykırı olduğuna kanaat
            getirdiği &Uuml;ye İ&ccedil;erikleri&rsquo;ni yayınlamayabilir,
            değiştirebilir, silebilir, &Uuml;ye İ&ccedil;erikleri&rsquo;ne
            erişimi/&Uuml;ye İ&ccedil;erikleri&rsquo;ni
            g&ouml;r&uuml;nt&uuml;lemeyi engelleyebilir. Rexven, bu halde,
            ayrıca, her t&uuml;rl&uuml; talep, dava ve takip hakları saklı
            kalmak &uuml;zere, &Uuml;ye&rsquo;nin &uuml;yeliğini kısmen veya
            tamamen kısıtlama, engelleme, askıya alma veya sona erdirme hak ve
            yetkisine sahiptir.
          </p>
        </li>
      </ul>
      <p>
        <br />
      </p>
      <p>
        3.13.&nbsp;&Uuml;ye, &Uuml;ye İ&ccedil;erikleri&rsquo;ni
        Platform&rsquo;a y&uuml;kleyerek, &Uuml;ye İ&ccedil;erikleri&rsquo;ne
        ilişkin fikri ve sınai m&uuml;lkiyet hakları ve sair hakların
        m&uuml;nhasıran sahibi olduğunu, &Uuml;ye İ&ccedil;erikleri&rsquo;ne
        ilişkin başta 5486 sayılı Fikir ve Sanat Eserleri Kanunu&rsquo;ndan
        kaynaklanan mali hakları [yani; tespit hakkı, işleme hakkı (FSEK m. 21),
        &ccedil;oğaltma hakkı (FSEK m. 22), yayma hakkı (FSEK m. 23), temsil
        hakkı (FSEK m. 24), işaret, ses ve/veya g&ouml;r&uuml;nt&uuml; nakline
        yarayan ara&ccedil;larla umuma iletim hakkı (FSEK m. 25)] ve manevi
        hakları [yani; umuma arz hakkı (FSEK m. 14), adın belirtilmesi hakkı
        (FSEK m. 15), değişiklik yapılmasını &ouml;nleme hakkı (FSEK m. 16),
        tahrif edilmesini/ bozulmasını &ouml;nleme hakkı (FSEK m. 17)] olmak
        &uuml;zere t&uuml;m fikri ve sınai m&uuml;lkiyet hakları ile sair
        haklarına ilişkin olarak, Rexven&rsquo;e, 5486 sayılı Fikir ve Sanat
        Eserleri Kanunu başta olmak &uuml;zere ilgili mevzuat
        h&uuml;k&uuml;mlerinin olanak tanıdığı en geniş &ouml;l&ccedil;&uuml;ler
        i&ccedil;inde, herhangi bir kullanım sınırı olmaksızın, s&uuml;re, sayı
        ve konu itibariyle sınırsız, d&uuml;nya genelinde (uluslararası)
        uygulanabilir, alt lisans verilebilir, geri alınmaz, bedelsiz, kayıtsız
        ve şartsız nitelikte tam ruhsat verdiğini kabul eder.
      </p>
      <p>
        <br />
      </p>
      <p>
        3.14.&nbsp;Rexven, Platform&rsquo;un ve Platform&rsquo;daki erişime,
        kullanıma, indirmeye ve paylaşıma m&uuml;sait i&ccedil;erik, materyal ve
        unsurların zararlı yazılımlardan arındırılmış olması i&ccedil;in mevcut
        imkanlar dahilinde makul tedbirleri almıştır. Ancak, Rexven,
        Platform&rsquo;un ve Platform&rsquo;daki erişime, kullanıma, indirmeye
        ve paylaşıma m&uuml;sait i&ccedil;erik, materyal ve unsurların,
        vir&uuml;slerden, buglardan, truva atlarından, bozuk dosyalardan,
        solucan programlarından, dialer programlarından, spam, spyware ve bunlar
        gibi diğer her t&uuml;rl&uuml; k&ouml;t&uuml; ve zarar verme
        ama&ccedil;lı yazılımlardan (casus yazılım ve reklam yazılımı gibi),
        programlardan, &uuml;r&uuml;nlerden, kodlardan ve/veya materyallerden
        arındırılmış olmasına ilişkin herhangi bir garanti ve taahh&uuml;t
        vermemektedir. &Uuml;ye, bunların oluşmasının ve herhangi bir şekilde
        cihazlarına, cihaz donanım ve yazılımlarına ve/veya diğer ekipman ve
        teknolojilerine bulaşmasının &ouml;nlenmesi i&ccedil;in gerekli koruyucu
        yazılım, donanım ve lisanslı &uuml;r&uuml;nleri kullanmak da dahil olmak
        &uuml;zere gerekli her t&uuml;rl&uuml; tedbiri almakla
        y&uuml;k&uuml;ml&uuml;d&uuml;r. Rexven, bu kapsamda olabilecek arızalar,
        hasarlar, veri yanlışlıkları ve kayıplar da dahil ancak bunlarla sınırlı
        olmamak &uuml;zere &Uuml;ye ve/veya &uuml;&ccedil;&uuml;nc&uuml;
        kişilerin uğrayabileceği zararlardan hi&ccedil;bir şekilde sorumlu
        değildir.
      </p>
      <p>
        <br />
      </p>
      <p>
        3.15.&nbsp;Rexven, Platform&rsquo;un ve Platform&rsquo;daki
        i&ccedil;erik, materyal ve unsurların doğruluğunu, g&uuml;venliğini,
        g&uuml;ncelliğini, mevcudiyetini, s&uuml;rekliliğini, eksiksizliğini,
        amaca uygunluğunu ve işlevselliğini sağlamak adına mevcut imkanlar
        dahilinde makul tedbirleri almıştır ve bunları &ldquo;olduğu
        gibi&rdquo;, &ldquo;mevcut olduğu şekliyle&rdquo; sağlamaktadır; bunlara
        ilişkin herhangi bir garanti ve taahh&uuml;t vermemektedir.
      </p>
      <p>
        <br />
      </p>
      <p>
        3.16.&nbsp;Platform&rsquo;da yer alan linkler, &Uuml;ye&rsquo;yi başka
        web sitelerine/uygulamalara g&ouml;t&uuml;rebilir. Rexven, bu web
        sitelerinin/uygulamaların i&ccedil;eriği, doğruluğu, g&uuml;venilirliği,
        g&uuml;venliği ve/veya işlevselliği ile ilgili olarak herhangi bir
        garanti ve taahh&uuml;t vermemekte olup; bu web siteleri/uygulamalar
        bakımından erişimden, kullanımdan, indirmelerden, paylaşımlardan ve/veya
        değişikliklerden doğabilecek zararlardan hi&ccedil;bir şekilde sorumlu
        değildir.
      </p>
      <p>
        <br />
      </p>
      <p>
        3.17.&nbsp;Rexven, tamamen kendi takdirine olmak &uuml;zere, dilediği
        zaman ve herhangi bir sebep g&ouml;stermeksizin, &uuml;yelik
        başvurularını reddedebilir, &uuml;yelikleri sona erdirebilir veya
        &uuml;yelik başvurusunun kabul edilmesini yahut devam ettirilmesini ya
        da Platform&rsquo;un kullanılmasını veya Hizmetler&rsquo;den
        faydalanılmasını ek şart ve koşullara bağlayabilir. Rexven tarafından
        &Uuml;ye&rsquo;nin &uuml;yelik işlemi sonrası getirilecek ve herhangi
        bir suretle duyurulacak/bildirilecek değişiklikler, ek şart ve koşullar,
        h&uuml;k&uuml;mler, y&ouml;nergeler ve politikalar, bunların
        yayınlanmasının ardından &Uuml;ye i&ccedil;in bağlayıcı olacak ve
        &Uuml;ye bunları Platform&rsquo;u kullanmaya/Hizmetler&rsquo;den
        faydalanmaya devam etmekle kabul etmiş sayılacaktır; ancak ilgili
        mevzuat h&uuml;k&uuml;mlerinin bunların &Uuml;ye tarafından
        a&ccedil;ık&ccedil;a onaylanmasını emretmesi halinde, bunlar &Uuml;ye
        tarafından onaylanmadığı s&uuml;rece, Rexven, &Uuml;ye&rsquo;nin
        &uuml;yeliğini kısmen veya tamamen kısıtlama, engelleme, askıya alma
        veya sona erdirme hak ve yetkisine sahiptir.
      </p>
      <p>
        <br />
      </p>
      <p>
        3.18.&nbsp;Rexven, tamamen kendi takdirinde olmak &uuml;zere
        Platform&rsquo;u ve/veya Hizmetler&rsquo;i, kısmen veya tamamen,
        s&uuml;rekli veya ge&ccedil;ici olarak yayından kaldırabilir,
        değiştirebilir, &uuml;cretli hale getirebilir, g&uuml;ncelleyebilir,
        askıya alabilir, durdurabilir ve/veya Platform &uuml;zerinde ilave
        hizmetler a&ccedil;abilir. Rexven&rsquo;in bu maddede sayılanlara ve
        benzer nitelikteki sair hususlara ilişkin olarak &Uuml;ye&rsquo;ye
        ve/veya &uuml;&ccedil;&uuml;nc&uuml; kişilere karşı hi&ccedil;bir
        sorumluluğu bulunmamaktadır. &nbsp;
      </p>
      <p>
        <br />
      </p>
      <p className="font-medium">4. KİŞİSEL VERİLERİN KORUNMASI</p>
      <p>
        <br />
      </p>
      <p>
        4.1.&nbsp;Rexven, &Uuml;ye&apos;nin Hizmetler&apos;den yararlanabilmek
        i&ccedil;in Platform &uuml;zerinden kendisine sağladığı kişisel
        verilerin 6698 sayılı Kişisel Verilerin Korunması Kanunu da dahil her
        t&uuml;rl&uuml; mevzuata uygun bir şekilde işlenmesine,
        g&uuml;venliğinin sağlanmasına ve korunmasına dikkat etmektedir. Rexven,
        bu kapsamda &Uuml;ye&apos;nin sağladığı kişisel verileri&nbsp;
        <a href="https://community.rexven.com/kisisel-verilerin-korunmasi-aydinlatma-metni/">
          https://rexven.com/kisisel-verilerin-korunmasi-aydinlatma-metni/
        </a>{' '}
        (Kişisel Verilerin Korunmasına İlişkin Aydınlatma Metni),&nbsp;
        <a href="https://community.rexven.com/gizlilik-ve-guvenlik-politikasi/">
          https://rexven.com/gizlilik-ve-guvenlik-politikasi/
        </a>{' '}
        (Gizlilik ve G&uuml;venlik Politikası) ve&nbsp;
        <a href="https://rexven.com/kisisel-verilerin-korunmasi-aydinlatma-metni/">
          https://rexven.com/kullanim-kosullari/
        </a>{' '}
        (Kullanım Koşulları) linklerinde yer alan metinlere uygun olarak
        toplamakta, kullanmakta, aktarmakta ve işleyebilmektedir. Aydınlatma
        Metni, Gizlilik Politikası ve Kullanım Koşullları, işbu
        S&ouml;zleşme&apos;nin ayrılmaz bir par&ccedil;asıdır.&nbsp;
      </p>
      <p>&nbsp;</p>
      <p>
        4.2.&nbsp;&Uuml;ye, Hizmetler&apos;den faydalanarak ve/veya bir hesap
        oluşturarak Aydınlatma Metni ve Gizlilik Politikası&apos;nda
        belirlendiği şekilde kişisel verilerinin toplanmasına, kullanılmasına,
        aktarılmasına ve diğer şekillerde işlenmesine a&ccedil;ık bir şekilde ve
        &ouml;zg&uuml;r iradesiyle rıza g&ouml;stermektedir. Kişisel verinin
        kullanılması ile ilgili koşullar ve bu konudaki haklarınızla ilgili
        olarak daha fazla bilgi i&ccedil;in Aydınlatma Metni ve Gizlilik
        Politikamızı inceleyebilirsiniz ve info@rexven.com elektronik posta
        adresine e-posta g&ouml;ndererek mevzuattan doğan haklarınızı
        kullanabilirsiniz.
      </p>
      <p>&nbsp;</p>
      <p>
        4.3.&nbsp;&Uuml;ye tarafından Platform&rsquo;da beyan edilen ve
        paylaşılmasına rıza g&ouml;sterilen kişisel veriler; işbu S&ouml;zleşme
        ile belirlenen y&uuml;k&uuml;ml&uuml;l&uuml;klerin ifası,
        Platform&rsquo;un işletilmesi i&ccedil;in gereken uygulamaların
        y&uuml;r&uuml;t&uuml;lmesi, &Uuml;ye i&ccedil;in &ccedil;eşitli
        avantajların sağlanıp sunulabilmesi ve &Uuml;ye&rsquo;ye &ouml;zel
        reklam, satış, pazarlama, anket, benzer ama&ccedil;lı her
        t&uuml;rl&uuml; elektronik iletişim, profilleme, istatistiksel
        &ccedil;alışmalar yapılması amacıyla Rexven ya da iş ortakları
        tarafından KVKK ve ilgili mevzuata uygun olarak toplanır, saklanır,
        işlenir, kullanılır ve akdi ilişkilerimize istinaden 3. Kişiler ile
        paylaşılabilir.
      </p>
      <p>&nbsp;</p>
      <p>
        4.4.&nbsp;&Uuml;ye, kişisel verilerinin bilgilerinin Rexven tarafından
        bu şekilde kullanımına ve saklanmasına rıza g&ouml;sterdiğini kabul ve
        beyan eder. Rexven s&ouml;z konusu kişisel verilerin 6698 Sayılı Kişisel
        Verilerin Korunması Kanunu mad. 12 uyarınca g&uuml;venli şekilde
        saklanması, yetkisiz erişimlerin ve hukuka aykırı veri işlemelerin
        &ouml;nlenmesi i&ccedil;in gereken her t&uuml;rl&uuml; tedbiri
        alacaktır. &Uuml;ye kişisel verileri &uuml;zerinde 6698 Sayılı Kişisel
        Verilerin Korunması Kanunu mad. 11 uyarınca sahip olduğu hakları
        kullanma ve bu verilerinde dilediği zaman değişiklik veya
        g&uuml;ncelleme yapma hakkına sahiptir.
      </p>
      <p>
        <br />
      </p>
      <p className="font-medium">5. FİKRİ VE SINAİ M&Uuml;LKİYET HAKLARI</p>
      <p>
        <br />
      </p>
      <p>
        Platform ve Platform&rsquo;a ilişkin her t&uuml;rl&uuml; unsur ve
        i&ccedil;erik (her t&uuml;rl&uuml; ticaret unvanı, eser, telif, buluş,
        patent, marka, tasarım, grafik, logo, diğer tanıtıcı isim ve işaretler,
        alan adı, yazılım, program akışı, algoritma, kaynak ve nesne kodu,
        program ve kullanıcı ara y&uuml;z&uuml;, veri, veri tabanı, kayıt, kod,
        s&uuml;re&ccedil;, fikir, metin, animasyon, klip, m&uuml;zik, video,
        fotoğraf, resim, şekil, &ccedil;izim, g&ouml;r&uuml;nt&uuml;, ticari
        takdim şekli, slogan, ekran, know-how, proje, konsept, program, uygulama
        dahil ancak bunlarla sınırlı olmamak &uuml;zere) ve bunlarla bağlantılı
        t&uuml;m haklar (fikri ve sınai m&uuml;lkiyet ve m&uuml;lkiyet hakları
        dahil ancak bunlarla sınırlı olmamak &uuml;zere) Rexven&rsquo;e aittir.
        &Uuml;yeler&rsquo;in Platform aracılığıyla paylaştığı kişisel bilgi,
        fotoğraf, video, fikir, ifade, yorum ve yazışmalar dahil ancak bunlarla
        sınırlı olmamak &uuml;zere her t&uuml;rl&uuml; i&ccedil;erik,
        Rexven&rsquo;in bunlara ilişkin sahip olduğu kullanma, işleme vb.
        hakları saklı kalmak kaydıyla, bunları Platform aracılığıyla paylaşan
        &Uuml;ye&rsquo;ye aittir. &Uuml;yelik hesabının oluşturulması,
        &Uuml;ye&rsquo;ye Platform&rsquo;a erişim, Platform&rsquo;u kullanma
        ve/veya Hizmetler&rsquo;den faydalanabilme yetkisinin verilmesi ve/veya
        işbu S&ouml;zleşme kapsamındaki herhangi bir h&uuml;k&uuml;m,
        &Uuml;ye&rsquo;ye; Platform&rsquo;a, Platform&rsquo;a ilişkin her
        t&uuml;rl&uuml; unsur ve i&ccedil;eriklere ve/veya diğer &Uuml;ye
        İ&ccedil;erikleri&rsquo;ne ilişkin herhangi bir hak verildiği anlamına
        gelmemektedir. Bu kapsamda, &Uuml;ye&rsquo;nin, Platform&rsquo;u,
        Platforma ait unsur ve i&ccedil;erikleri ve/veya diğer &Uuml;ye
        İ&ccedil;erikleri&rsquo;ni kısmen veya tamamen, doğrudan veya dolaylı
        olarak, Platform&rsquo;un kullanım amacı dışında kullanımı, iktibası,
        kopyalaması, &ccedil;oğaltması, değiştirmesi, tersine m&uuml;hendislik
        yapması, kaynak koda d&ouml;n&uuml;şt&uuml;rmesi, yedeklemesi, iletmesi,
        depolaması, işlemesi, satması, paylaşması, yayması, dağıtması,
        kiralanması, &ouml;d&uuml;n&ccedil; verilmesi, uyarlaması, nakletmesi
        veya başkaca iş, işlem ve tasarrufa konu etmesi ve/veya bunları teşvik
        etmesi ve/veya yapılmasını kolaylaştırması mutlak suretle yasaktır.
        &Uuml;ye, işbu madde kapsamında yer alan yasağı ihlal etmesi halinde
        Rexven&rsquo;de, Platform&rsquo;da ve/veya &uuml;&ccedil;&uuml;nc&uuml;
        kişilerde meydana gelecek her t&uuml;rl&uuml; zararı ilk talep
        &uuml;zerine, derhal, nakden ve defaten tazmin etmekle
        y&uuml;k&uuml;ml&uuml; olduğunu kabul, beyan ve taahh&uuml;t eder.
        Rexven, bu halde, ayrıca, her t&uuml;rl&uuml; talep, dava ve takip
        hakları saklı kalmak &uuml;zere, &Uuml;ye&rsquo;nin &uuml;yeliğini
        kısmen veya tamamen kısıtlama, engelleme, askıya alma veya sona erdirme
        hak ve yetkisine sahiptir.
      </p>
      <p>&nbsp;</p>
      <p className="font-medium">6. S&Ouml;ZLEŞME DEĞİŞİKLİKLERİ VE FERAGAT</p>
      <p>
        <br />
      </p>
      <p>
        6.1.&nbsp;Rexven, tamamen kendi takdirine bağlı olmak &uuml;zere, işbu
        S&ouml;zleşme&apos;yi ve Platform&rsquo;da yer alan her t&uuml;rl&uuml;
        politika ve kuralı, h&uuml;k&uuml;m ve şartı uygun g&ouml;receği
        herhangi bir zamanda, herhangi bir suretle duyurarak/bildirerek tek
        taraflı olarak değiştirebilir. Değişiklikler ve eklemeler, aksi Rexven
        tarafından belirtilmedik&ccedil;e, duyuruldukları/bildirildikleri
        tarihte ge&ccedil;erlilik kazanacak, geri kalan h&uuml;k&uuml;mler aynen
        y&uuml;r&uuml;rl&uuml;kte kalarak h&uuml;k&uuml;m ve sonu&ccedil;larını
        doğurmaya devam edecek ve &Uuml;ye, Platform&rsquo;u
        kullanmaya/Hizmetler&rsquo;den faydalanmaya devam etmekle, bu
        değişiklikleri ve eklemeleri kabul etmiş sayılacaktır. İlgili mevzuat
        h&uuml;k&uuml;mlerinin değişikliklerin &Uuml;ye tarafından
        a&ccedil;ık&ccedil;a onaylanmasını emretmesi ve değişikliklerin &Uuml;ye
        tarafından onaylanmaması halinde Rexven, &Uuml;ye&rsquo;nin
        &uuml;yeliğini kısmen veya tamamen kısıtlayabilir, engelleyebilir,
        askıya alabilir veya sona erdirebilir. &nbsp;
      </p>
      <p>
        <br />
      </p>
      <p>
        6.2.&nbsp;Rexven tarafından imza edilmeyen feragatler ge&ccedil;erli
        değildir. Rexven&rsquo;in, işbu S&ouml;zleşme kapsamındaki herhangi bir
        hakkını, kısmen veya tamamen kullanmaması, &Uuml;ye&rsquo;den, işbu
        S&ouml;zleşme&rsquo;nin herhangi bir h&uuml;k&uuml;m veya şartına
        uymasını talep etmemesi ve/veya &Uuml;ye&rsquo;nin herhangi bir
        ihlalinden feragat etmesi, s&ouml;z konusu h&uuml;k&uuml;m veya şartın
        gereğinin m&uuml;teakiben yerine getirilmesi şartını kısıtlamayacak veya
        izleyen herhangi bir ihlalden feragat olarak yorumlanmayacaktır.&nbsp;
      </p>
      <p>
        <br />
      </p>
      <p className="font-medium">7. M&Uuml;CBİR SEBEPLER</p>
      <p>
        <br />
      </p>
      <p>
        Ayaklanma, ambargo, devlet m&uuml;dahalesi, isyan, işgal, savaş,
        seferberlik, grev, lokavt, iş eylemleri veya boykotlar dahil olmak
        &uuml;zere iş&ccedil;i-işveren anlaşmazlıkları, siber saldırı, iletişim
        sorunları, altyapı ve internet arızaları, sisteme ilişkin iyileştirme
        veya yenileştirme &ccedil;alışmaları ve bu sebeple meydana gelebilecek
        arızalar, elektrik kesintisi, yangın, patlama, fırtına, sel, deprem,
        g&ouml;&ccedil;, salgın veya diğer bir doğal felaket, k&ouml;t&uuml;
        hava koşulları, mevzuat değişiklikleri, yetkili merciler tarafından
        alınan kararlar, veya Rexven&rsquo;in kontrol&uuml; dışında
        ger&ccedil;ekleşen, kusurundan kaynaklanmayan ve makul olarak
        &ouml;ng&ouml;r&uuml;lemeyecek diğer olaylar (M&uuml;cbir Sebep)
        Rexven&apos;in işbu S&ouml;zleşme&rsquo;den doğan
        y&uuml;k&uuml;ml&uuml;l&uuml;klerini ifa etmesini engeller veya
        geciktirirse, Rexven, ifası M&uuml;cbir Sebep sonucunda engellenen veya
        geciken y&uuml;k&uuml;ml&uuml;l&uuml;klerinden dolayı sorumlu tutulamaz
        ve bu durum işbu S&ouml;zleşme&rsquo;nin bir ihlali olarak kabul
        edilemez.
      </p>
      <p>
        <br />
      </p>
      <p className="font-medium">8. MUHTELİF H&Uuml;K&Uuml;MLER</p>
      <p>
        <br />
      </p>
      <p>
        8.1.&nbsp;&Uuml;ye, işbu S&ouml;zleşme&rsquo;den doğabilecek
        ihtilaflarda Rexven&rsquo;in resmi defter ve ticari kayıtları,
        Rexven&rsquo;in veri tabanında, sunucularında tuttuğu e-arşiv kayıtları,
        elektronik bilgiler ve bilgisayar kayıtları dahil olmak &uuml;zere
        Rexven nezdinde bulunan her t&uuml;rl&uuml; kayıt ve belgenin bağlayıcı,
        kesin ve m&uuml;nhasır delil teşkil edeceğini ve işbu maddenin 6100
        sayılı Hukuk Muhakemeleri Kanunu&rsquo;nun 193. maddesi anlamında delil
        s&ouml;zleşmesi niteliğinde olduğunu kabul, beyan ve taahh&uuml;t eder.
      </p>
      <p>&nbsp;</p>
      <p>
        8.2.&nbsp;İşbu S&ouml;zleşme&rsquo;nin yorumlanmasında ve uygulanmasında
        T&uuml;rkiye Cumhuriyeti Hukuku ge&ccedil;erli olacaktır. İşbu
        S&ouml;zleşme&rsquo;den doğabilecek her t&uuml;rl&uuml; ihtilafın
        hallinde Ankara Mahkemeleri ve İcra M&uuml;d&uuml;rl&uuml;kleri
        yetkilidir.
      </p>
      <p>&nbsp;</p>
      <p>
        8.3.&nbsp;Rexven, &Uuml;ye ile &Uuml;ye&apos;nin kaydolurken bildirmiş
        olduğu elektronik posta adresi vasıtasıyla veya telefon numarasına arama
        yapmak ve SMS g&ouml;ndermek suretiyle iletişim kuracaktır. &Uuml;ye,
        elektronik posta adresini ve telefon numarasını g&uuml;ncel tutmakla
        y&uuml;k&uuml;ml&uuml;d&uuml;r.
      </p>
      <p>
        <br />
      </p>
      <p>
        8.4.&nbsp;İşbu S&ouml;zleşme, işbu S&ouml;zleşme konusuna ilişkin olarak
        Taraflar arasındaki anlaşmanın tamamını oluşturmakta olup; işbu
        S&ouml;zleşme&rsquo;nin herhangi bir h&uuml;km&uuml;n&uuml;n veya işbu
        S&ouml;zleşme&rsquo;de yer alan herhangi bir ifadenin
        ge&ccedil;ersizliği, yasaya aykırılığı ve uygulanamaz olması, işbu
        S&ouml;zleşme&rsquo;nin geri kalan h&uuml;k&uuml;mlerinin
        y&uuml;r&uuml;rl&uuml;ğ&uuml;n&uuml; ve ge&ccedil;erliliğini
        etkilemeyecektir.
      </p>
      <p>&nbsp;</p>
      <p>
        8.5.&nbsp;&Uuml;ye, işbu S&ouml;zleşme&rsquo;yi ve/veya işbu
        S&ouml;zleşme&rsquo;den doğan haklarını, menfaatlerini ve/veya
        y&uuml;k&uuml;ml&uuml;l&uuml;klerini &ouml;nceden Rexven&rsquo;in yazılı
        iznini almaksızın, hi&ccedil;bir şekilde &uuml;&ccedil;&uuml;nc&uuml;
        kişilere devir veya temlik edemez. Rexven&rsquo;in &ouml;n yazılı izni
        olmaksızın yapılan temlik veya devir işlemi ge&ccedil;ersiz olacaktır.
        Bununla birlikte Rexven, işbu S&ouml;zleşme&rsquo;yi ve/veya işbu
        S&ouml;zleşme&rsquo;den doğan haklarını, menfaatlerini, alacaklarını
        ve/veya y&uuml;k&uuml;ml&uuml;l&uuml;klerini, &ouml;nceden
        &Uuml;ye&rsquo;nin yazılı iznini almaksızın, h&acirc;kim şirketlerine,
        bağlı şirketlerine, iştiraklerine, iş ortaklarına ve/veya
        &uuml;&ccedil;&uuml;nc&uuml; kişilere devir ve temlik edebilir.
      </p>
      <p>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</p>
      <p>
        8.6.&nbsp;Rexven&rsquo;in işbu S&ouml;zleşme&rsquo;nin h&uuml;k&uuml;m
        ve şartları ve ilgili mevzuat h&uuml;k&uuml;mleri uyarınca sahip olduğu
        her t&uuml;rl&uuml; hak ve yetkisi, birlikte kullanılabilecek
        niteliktedir; diğer bir ifadeyle Rexven&rsquo;in işbu
        S&ouml;zleşme&rsquo;nin h&uuml;k&uuml;m ve şartları ve ilgili mevzuat
        h&uuml;k&uuml;mleri uyarınca sahip olduğu herhangi bir hak veya
        yetkisini kullanması, diğer herhangi bir hak ve/veya yetkisini
        kullanmasına hi&ccedil;bir suretle engel değildir.
      </p>
      <p>
        <br />
      </p>
      <p>
        8 (sekiz) maddeden ibaret işbu S&ouml;zleşme, &Uuml;ye tarafından her
        bir h&uuml;km&uuml; okunarak ve b&uuml;t&uuml;n&uuml;yle anlaşılarak
        elektronik ortamda onaylanmıştır. İşbu S&ouml;zleşme, onaylandığı an
        itibarıyla s&uuml;resiz olarak y&uuml;r&uuml;rl&uuml;ğe girmiştir.
      </p>
      <p>
        <br />
      </p>
      <p>
        <br />
      </p>
    </div>
  );
};

export default SubscriptionPolicy;
