import React from 'react';

const LoadingTable = () => {
  return (
    <div className="grid grid-cols-9 gap-4 w-full justify-around items-center text-primary even:bg-lightGray p-4">
      <TableRow />
      <TableRow />
      <TableRow />
      <TableRow />
      <TableRow />
    </div>
  );
};

const TableRow = () => {
  return (
    <>
      <div className="col-span-1 animate-pulse bg-lightGray w-full h-20 rounded-md"></div>
      <div className="col-span-1 animate-pulse bg-lightGray w-full h-20 rounded-md"></div>
      <div className="col-span-1 animate-pulse bg-lightGray w-full h-20 rounded-md"></div>
      <div className="col-span-1 animate-pulse bg-lightGray w-full h-20 rounded-md"></div>
      <div className="col-span-1 animate-pulse bg-lightGray w-full h-20 rounded-md"></div>
      <div className="col-span-1 animate-pulse bg-lightGray w-full h-20 rounded-md"></div>
      <div className="col-span-1 animate-pulse bg-lightGray w-full h-20 rounded-md"></div>
      <div className="col-span-1 animate-pulse bg-lightGray w-full h-20 rounded-md"></div>
      <div className="col-span-1 animate-pulse bg-lightGray w-full h-20 rounded-md"></div>
    </>
  );
};
export default LoadingTable;
