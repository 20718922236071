import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { LockClosedIcon, PlayIcon } from '@heroicons/react/24/outline';

import { IuserSession, useAuth } from '../../context/AuthContext';
import { useNumberVerification } from '../../context/NumberVerificationContext';
import NotMemberModal from '../shared/NotMemberModal';

interface CourseCardProps {
  courseName: string;
  description: string;
  isFree: boolean;
  hasAccess?: boolean;
  courseImage?: string;
  courseId?: number;
}

function classNames(...classes: String[]) {
  return classes.filter(Boolean).join(' ');
}

const CourseCard: React.FC<CourseCardProps> = ({
  courseName,
  description,
  isFree,
  hasAccess,
  courseImage,
  courseId,
}) => {
  const [notMemmberOpen, setNotMemberOpen] = useState<boolean>(false);
  // const { session } = useAuth();

  const { handleActiveModal } = useNumberVerification();
  const handleNotMember = () => {
    setNotMemberOpen(true);
  };

  if (hasAccess === true || isFree === true) {
    return (
      <Link
        onClick={(event) => handleActiveModal?.(event)}
        to={`/courses/${courseId}/${courseName}`}
      >
        <div className="mt-3 bg-white hover:cursor-pointer">
          <div className="mx-auto max-w-7xl">
            <div className="relative ">
              <div className="absolute  z-[5] top-[45%] left-[46.5%] ">
                <PlayIcon
                  data-testid="play-icon"
                  style={{ background: 'rgba(0,0,0,0.5)' }}
                  className={classNames(
                    ' text-white p-1 rounded border-gray-600 border-1 h-8 w-8'
                  )}
                />
              </div>
              <img
                className={classNames(
                  'aspect-[3/2] w-full rounded-2xl object-fill'
                )}
                src={courseImage}
                alt="CourseImage"
              />
            </div>
            <h3 className="mt-6 h-[40px] max-h-[40px] font-semibold text-left text-headingPrimary cardHeading">
              {courseName}
            </h3>
            <p className="mt-3 h-[80px] max-h-[80px] text-left text-sm text-description">
              {description}
            </p>
            <p className="mt-1 text-left text-sm font-bold text-[#3C4790]">
              Mehmet Tek
            </p>
          </div>
        </div>
      </Link>
    );
  } else {
    return (
      <div
        className="mt-3 bg-white hover:cursor-pointer"
        onClick={handleNotMember}
      >
        <div className="mx-auto max-w-7xl">
          <div className="relative ">
            <div className="absolute  z-[5] top-[45%] left-[46.5%] ">
              <LockClosedIcon
                data-testid="play-icon"
                style={{ background: 'rgba(0,0,0,0.5)' }}
                className={classNames(
                  'text-white p-1 rounded border-gray-600 border-1 h-8 w-8'
                )}
              />
            </div>
            <img
              className={classNames(
                'grayscale -z-10 aspect-[3/2] w-full rounded-2xl object-fill '
              )}
              src={courseImage}
              alt="CourseImage"
            />
          </div>
          <h3 className="mt-6 h-[40px] max-h-[40px] text-lg font-semibold text-left text-[#2D356C]">
            {courseName}
          </h3>
          <p className="mt-3 h-[80px] max-h-[80px] text-left text-sm text-[#070912]">
            {/* {description === '' ? description : 'Lorem Ipsum Dolor'} */}
            {description}
          </p>
          <p className="mt-3 text-left text-sm font-bold text-[#3C4790]">
            Mehmet Tek
          </p>
        </div>
        <NotMemberModal
          content={'common.text.notMemberCourses'}
          notMemmberOpen={notMemmberOpen}
          setNotMemberOpen={setNotMemberOpen}
        />
      </div>
    );
  }
};

export default CourseCard;
