import React from 'react';
import { IAsinData } from '../../../../pages/AmazonForecast';
import { useFormik } from 'formik';
import { IResult } from './ResultSection';

interface Props {
  asin: string;
  setAsin: React.Dispatch<React.SetStateAction<string>>;
  data: IAsinData;
  isLoading: boolean;
  setResult: React.Dispatch<React.SetStateAction<IResult[]>>;
  setTableResult: React.Dispatch<any>;
}
const AsinForm: React.FC<Props> = ({
  asin,
  setAsin,
  data,
  isLoading,
  setResult,
  setTableResult,
}) => {
  const handleSubmit = (values: any) => {
    setAsin(values.asin);
    setResult([
      {
        revenue: 0,
        profit: 0,
        profitMargin: 0,
        investment: 0,
        netInvestment: 0,
        brandValue: 0,
      },
      {
        revenue: 0,
        profit: 0,
        profitMargin: 0,
        investment: 0,
        netInvestment: 0,
        brandValue: 0,
      },
    ]);
    setTableResult([]);
  };
  const formik = useFormik({
    initialValues: {
      asin: asin || '',
    },
    onSubmit: handleSubmit,
  });
  return (
    <div className="flex justify-between flex-col md:flex-row space-y-2 md:space-x-4 mb-4 h-32 bg-white shadow-xl rounded-xl p-4">
      <div className="flex justify-start items-center space-x-4">
        <div>
          {isLoading ? (
            <div className="animate-pulse bg-lightGray w-[60px] rounded-lg h-[60px]"></div>
          ) : (
            <img
              className="w-[60px] h-[60px] object-contain"
              src={`https://m.media-amazon.com/images/I/${data?.data?.image}`}
              alt="product-amazon"
            />
          )}
        </div>
        {isLoading ? (
          <div className="space-y-2">
            <div className="animate-pulse bg-lightGray w-[300px] rounded-lg h-[20px]"></div>
            <div className="animate-pulse bg-lightGray w-[150px] rounded-lg h-[20px]"></div>
          </div>
        ) : (
          <p className="text-xs md:text-sm max-w-[350px]">
            {data?.data?.title}
          </p>
        )}
      </div>
      <form
        className="flex items-center w-full md:w-1/2"
        onSubmit={formik.handleSubmit}
      >
        <input
          type="text"
          name="asin"
          placeholder="B0CQ9FZTZS"
          value={formik.values.asin}
          onChange={formik.handleChange}
          className="border border-gray-300 rounded-l-lg p-2 text-xs lg:text-sm w-full"
          disabled={isLoading}
        />
        <button
          className="rounded-r-lg bg-headerPrimary text-white text-xs lg:text-sm p-2 border border-headerPrimary w-full"
          type="submit"
        >
          Ürünü Getir
        </button>
      </form>
    </div>
  );
};

export default AsinForm;
