import React, { useEffect, useRef, useState } from 'react';
import { useAuth } from '../../../context/AuthContext';
import { ArrowSwapVertical, Link2, Link21 } from 'iconsax-react';
import LoadingComponent from '../../common/LoadingComponent';
import Pagination from '../../common/Pagination';
import {
  ICategory,
  ICountry,
  IProductType,
} from '../../../interfaces/etsyToolsInterfaces';
import { convertDate } from '../../../utils/dateFormatter';
import { seperateNumberWithComma } from '../../../utils/numberFormatter';
import ToolsAbsoluteBuyBox from '../../common/ToolsAbsoluteBuyBox';
import { Link, useNavigate, useNavigation } from 'react-router-dom';
import { useLanguage } from '../../../context/LanguageContext';
interface TableProps {
  tableHeader: { name: string; column: string; isButton: boolean }[];
  isLoading: boolean;
  queryString: string;
  data: {
    id: number;
    icon: string;
    shopName: number;
    etsyCountryId: number;
    createDate: Date;
    categoryId: number;
    productTypeId: number;
    totalSaleCount: number;

    // yesterdaySaleCount: number;
    avgSales: number;
    reviewCount: number;
    reviewAverage: number;
    totalListingCount: number;
    numFavorers: number;
  }[];
  categories: ICategory[];
  productTypes: IProductType[];
  countries: ICountry[];
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  totalPageCount: number;
  setSortQuery: React.Dispatch<
    React.SetStateAction<{
      orderBy: string;
      order: string;
    }>
  >;
  inputPage: string | number;
  setInputPage: React.Dispatch<React.SetStateAction<number | string>>;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
function classNames(...classes: String[]) {
  return classes.filter(Boolean).join(' ');
}

const SellerTable: React.FC<TableProps> = ({
  tableHeader,
  setOpen,
  isLoading,
  data,
  setPage,
  page,
  totalPageCount,
  countries,
  productTypes,
  categories,
  setSortQuery,
  setInputPage,
  inputPage,
  queryString,
}) => {
  const { session } = useAuth();
  const { language } = useLanguage();
  const [fields, setFields] = useState<any>({
    id: 'DESC',
    icon: 'DESC',
    shopName: 'DESC',
    countryId: 'DESC',
    createDate: 'DESC',
    categoryId: 'DESC',
    productTypeId: 'DESC',
    totalSaleCount: 'DESC',
    // dailySales: 'DESC',
    avgSales: 'DESC',
    reviewCount: 'DESC',
    reviewAverage: 'DESC',
    totalListingCount: 'DESC',
    numFavorers: 'DESC',
  });

  return (
    <div className="flex flex-col flex-shrink-0 bg-white rounded-xl p-5 shadow-lg sm:p-4 mt-10 relative">
      {!session?.hasPackage && <ToolsAbsoluteBuyBox />}
      <div className="flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto overflow-y-auto h-[60vh] scrollbar-hide">
          <div className="relative inline-block  min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="relative min-w-full divide-y divide-gray-300">
              <thead>
                <tr>
                  {tableHeader.map((item) => {
                    return (
                      <th
                        key={item.name}
                        className="sticky top-0 z-10 border-b border-gray-300 text-center bg-lightGray  py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900  "
                        scope="col"
                      >
                        {' '}
                        {item.isButton ? (
                          <button
                            className="text-xs md:text-sm flex justify-between items-center"
                            onClick={() => {
                              if (session?.hasPackage) {
                                let order =
                                  fields.hasOwnProperty(
                                    item.column as keyof typeof fields
                                  ) &&
                                  fields[item.column as keyof typeof fields] ===
                                    'DESC'
                                    ? 'ASC'
                                    : 'DESC';
                                setSortQuery({
                                  orderBy: item.column,
                                  order: order,
                                });
                                setFields({
                                  ...fields,
                                  [item.column as keyof typeof fields]: order,
                                });
                              } else {
                                setOpen(true);
                              }
                            }}
                          >
                            {item.name} <ArrowSwapVertical className="" />
                          </button>
                        ) : (
                          <p className="text-xs md:text-sm">{item.name}</p>
                        )}
                      </th>
                    );
                  })}
                </tr>
              </thead>

              {isLoading ? (
                <div className="w-full flex justify-center">
                  <LoadingComponent />
                </div>
              ) : (
                <tbody className="divide-y divide-gray-200 bg-white">
                  {data.map((seller: any, idx) => {
                    return (
                      <tr
                        key={seller.id}
                        className={classNames(
                          !session?.hasPackage && idx > 2
                            ? 'blur-sm'
                            : 'blur-none',
                          'even:bg-lightGray'
                        )}
                      >
                        <td className="py-4 pl-4 text-xs md:text-sm sm:pl-0 w-16">
                          <p className="text-center text-gray">
                            {idx + 100 * (page - 1) + 1}
                          </p>
                        </td>
                        <td className="py-4 pl-4 text-xs md:text-sm font-medium text-center text-gray-900 sm:pl-0 w-16">
                          <a
                            target="_blank"
                            href={`https://www.etsy.com/shop/${seller.shopName}`}
                          >
                            <img
                              className="h-10 object-contain w-full"
                              src={seller.icon}
                            />
                          </a>
                        </td>
                        <td className="px-4 py-4 text-xs md:text-sm text-center text-gray-500  ">
                          <div className="flex justify-center items-center space-x-2">
                            <Link
                              to={`/etsy-store-analyze?shopId=${seller.id}`}
                            >
                              {seller.shopName}
                            </Link>
                            <a
                              target="_blank"
                              href={`https://www.etsy.com/shop/${seller.shopName}`}
                            >
                              <Link2
                                size="24"
                                color="#5501D6"
                                className="cursor-pointer"
                              />
                            </a>
                          </div>
                        </td>
                        <td className="whitespace-nowrap text-center px-3 py-4 text-xs md:text-sm text-gray-500 ">
                          {Number(
                            countries?.find(
                              (item) => item.id === seller.etsyCountryId
                            )?.countryCode.length
                          ) > 0
                            ? countries?.find(
                                (item) => item.id === seller.etsyCountryId
                              )?.countryCode
                            : '-'}
                        </td>
                        <td className="whitespace-nowrap text-center px-3 py-4 text-xs  text-gray-500 ">
                          {convertDate(seller.createDate, language)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs  text-gray-500 ">
                          {Number(
                            categories?.find(
                              (item) => item.id === seller.etsyTaxonomyId
                            )?.taxonomyName.length
                          ) > 0
                            ? categories?.find(
                                (item) => item.id === seller.etsyTaxonomyId
                              )?.taxonomyName
                            : '-'}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          {Number(
                            productTypes?.find(
                              (item) => item.id === seller.etsyProductTypeId
                            )?.type.length
                          ) > 0
                            ? productTypes?.find(
                                (item) => item.id === seller.etsyProductTypeId
                              )?.type
                            : '-'}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          {seperateNumberWithComma(seller.totalSaleCount)}
                        </td>
                        {/* <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          {seperateNumberWithComma(seller.dailySales)}
                        </td> */}
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          {seller.avgSales > 0
                            ? Number(
                                seperateNumberWithComma(seller.avgSales)
                              ).toFixed(0)
                            : Number(
                                seperateNumberWithComma(seller.avgSales)
                              ).toFixed(2)}
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          {seperateNumberWithComma(seller.reviewCount)}
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          {Number(seller.reviewAverage).toFixed(2)}
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500">
                          {seperateNumberWithComma(seller.totalListingCount)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500">
                          {seperateNumberWithComma(seller.numFavorers)}
                        </td>
                      </tr>
                    );
                  })}
                  {!session?.hasPackage &&
                    Array.from({ length: 97 }).map((_, idx) => (
                      <tr
                        key={idx}
                        className={classNames('blur-sm even:bg-lightGray')}
                      >
                        <td className="py-4 pl-4 text-xs md:text-sm sm:pl-0 w-16">
                          <p className="text-center text-gray">{idx + 4}</p>
                        </td>
                        <td className="py-4 pl-4 text-xs md:text-sm font-medium text-center text-gray-900 sm:pl-0 w-16">
                          <img
                            className="h-10 object-contain w-full"
                            src={`https://picsum.photos/${idx}`}
                          />
                        </td>
                        <td className="px-4 py-4 text-xs md:text-sm text-center text-gray-500  ">
                          Heollaaae
                        </td>
                        <td className="whitespace-nowrap text-center px-3 py-4 text-xs md:text-sm text-gray-500 ">
                          Tıasdoanskdo
                        </td>
                        <td className="whitespace-nowrap text-center px-3 py-4 text-xs  text-gray-500 ">
                          1 Ay 4 Gün
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs  text-gray-500 ">
                          Jewelry
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          Fiziksel
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          167253178
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          12313
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          2323
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          4434343
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          333444
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500">
                          756
                        </td>
                      </tr>
                    ))}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
      {session?.hasPackage && (
        <Pagination
          setPage={setPage}
          page={page}
          totalPageCount={totalPageCount!}
          setInputPage={setInputPage}
          inputPage={inputPage}
          queryString={queryString}
        />
      )}
    </div>
  );
};

export default SellerTable;
