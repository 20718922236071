import { AxiosResponse } from 'axios';

import { IOrdersQuery, ShippingData } from '../interfaces/shipEntegraInterfaces';
import httpClient from '../utils/httpClient';

class ShipentegraService {
  responseBody = (response: AxiosResponse) => response.data;

  //check Auth. of User
  public async calculateShipping(payload: { country: string; kgDesi: number }) {
    const { data } = await httpClient.post(
      `/api/v1/ship-entegra/calculateAllPrices`,
      payload
    );
    return data;
  }
  public async getConfirmedShipments(payload: {
    status: number;
    endDate?: any;
    startDate?: any;
    stores?: string;
  }) {
    const { data } = await httpClient.get(
      `/api/v1/ship-entegra/shipments?status=${payload.status ? payload.status : 3
      }&startDate=${payload.startDate ? payload.startDate : ''}&endDate=${payload.endDate ? payload.endDate : ''
      }&stores=${payload.stores ? payload.stores : ''}`
    );
    return data;
  }

  public async getOrdersPageData(
    payload: string) {
    const { data } = await httpClient.get(
      `/api/v1/ship-entegra/orders${payload}`
    );
    return data;
  }
  public async getTotalOrderCount(payload: string) {
    const { data } = await httpClient.get(
      `/api/v1/ship-entegra/orders/count${payload}`
    );
    return data;
  }
  public async createOrder(payload: ShippingData) {
    const { data } = await httpClient.post(
      `/api/v1/ship-entegra/createOrder`,
      payload
    );
    return data;
  }
  public async updateTicket(orderId: any, payload: any) {
    const { data } = await httpClient.post(
      `/api/v1/ship-entegra/label/${orderId}`,
      payload
    );
    return data;
  }
  public async loginWithShipEntegra(payload: { hasAccount: boolean }) {
    const { data } = await httpClient.post(
      `/api/v1/ship-entegra/authenticate`,
      payload
    );
    return data;
  }
  public async getCarriers() {
    const { data } = await httpClient.get(
      `/api/v1/ship-entegra/carriers`
    );
    return data;
  }
  public async getOrderItems(orderId: any) {
    const { data } = await httpClient.get(
      `/api/v1/ship-entegra/order/${orderId}`
    );
    return data;
  }
  public async updateOrder(orderId: any, payload: any) {
    const { data } = await httpClient.patch(
      `/api/v1/ship-entegra/order/${orderId}`,
      payload
    );
    return data;
  }
  public async printLabels(payload: { url: string, orderId: any }[]) {
    const { data } = await httpClient.post(
      `/api/v1/ship-entegra/labels/print`,
      { data: payload }
    );
    return data;
  }
  public async calculate(payload: { country: string; kgDesi: number, seCarrier: string, isAmazonShipment: number }) {
    const { data } = await httpClient.post(
      `/api/v1/ship-entegra/calculatePrice`,
      payload
    );
    return data;
  }
}




export default new ShipentegraService();
