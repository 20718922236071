import { AddCircle, DocumentDownload, SearchNormal } from 'iconsax-react';
import React, { useState } from 'react';
import { DotLoader } from 'react-spinners';

import { useLanguage } from '../../context/LanguageContext';
import shipentegraService from '../../services/shipentegraService';
import alertNotification from '../../utils/alertNotification';
import { override } from '../common/DotLoaderSpinner';

interface ShipEntegraCreateTicketProps {
    setOpenCreateTicket: React.Dispatch<React.SetStateAction<boolean>>
    selectedOrderIds: number[] | undefined
    query: string
    setQuery: React.Dispatch<React.SetStateAction<string>>
    selectedOrderDetails: {
        url: string;
        orderId: number;
    }[]
}
const ShipEntegraCreateTicket: React.FC<ShipEntegraCreateTicketProps> = ({
    setOpenCreateTicket,
    selectedOrderIds,
    query,
    setQuery,
    selectedOrderDetails
}
) => {
    const { t, language } = useLanguage();
    const [loader, setLoader] = useState(false)
    const printLabel = () => {
        const hasInvalidUrl = selectedOrderDetails.some(item => !item.url || item.url === '');

        if (hasInvalidUrl) {
            // If an invalid URL is found, show an alert and stop the function execution
            alertNotification('error', "Etiketlerin yazdırılması için tüm siparişlerin geçerli bir etiketi olmalıdır.");
            return;
        }
        if (selectedOrderDetails.length === 1) {
            window.open(selectedOrderDetails[0].url, '_blank');
            return
        }
        setLoader(false)
        shipentegraService.printLabels(selectedOrderDetails)
            .then((res) => {
                window.open(res.data, '_blank');
            }).catch((err) => {
                alertNotification('err', err.response.data.message)
            }).finally(() => {
                setLoader(false)
            })
    }
    const options = language === 'tr' ?
        ['Etiket İndir', 'Barkod İndir', 'Sipariş Detaylarını İndir', 'SKU Bazlı İndir',] : ['Download Label', 'Download Barcode', 'Download Order Details', 'Download by SKU',]
    return (
        <div className="bg-white rounded-xl my-12 px-4 py-10 flex flex-col md:flex-row items-start md:items-center gap-4 justify-between ">
            <div className=' flex flex-row items-center justify-between w-full 2xl:w-[40%]'>
                <div
                    onClick={() => {
                        if (selectedOrderIds?.length) {
                            setOpenCreateTicket(true)
                        } else {
                            alertNotification("error", 'Lütfen bir sipariş seçiniz')
                        }
                    }}
                    className="cursor-pointer px-4 py-2 text-sm lg:text-base mx-auto w-full font-bold  flex flex-row items-center justify-center bg-headerPrimary rounded-lg text-white"
                >
                    <AddCircle className='w-4 h-4 inline-block mr-2' />
                    {t?.('orders.filters.createTicket')}
                </div>
                <div
                    className="cursor-pointer px-4 py-2 text-sm mx-auto w-full text-headerPrimary font-bold  flex flex-row items-center"
                >
                    <DocumentDownload className='w-8 h-8 inline-block mr-2' />
                    <div
                        onClick={printLabel}
                        className="block w-full rounded-md  py-1.5 py-3 text-gray-900 sm:text-sm sm:leading-6"
                    >
                        {loader ? <DotLoader
                            color={'#1F254B'}
                            cssOverride={override}
                            size={25}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        /> : 'Etiket İndir'}
                    </div>
                </div>
            </div>
            <div className=' w-full md:w-[35%]'>
                <div className="relative">
                    <div className="absolute inset-y-0 right-5 flex items-center pl-3 pointer-events-none">
                        <SearchNormal className='w-4 h-4' />
                    </div>
                    <input
                        className="block w-full p-[0.58rem] pl-5 text-xs md:text-sm text-description border border-headerPrimary rounded-lg "
                        placeholder={'Tabloda Ara'}
                        value={query}
                        onChange={(e) => {
                            setQuery(e.currentTarget.value);
                        }}
                        id="query"
                        name="query"
                        type="text"
                    />
                </div>
            </div>
        </div>

    )
}

export default ShipEntegraCreateTicket