import React from 'react';
import LoadingComponent from '../../common/LoadingComponent';
import { seperateNumberWithComma } from '../../../utils/numberFormatter';
import Pagination from '../../common/Pagination';
import { ToastContainer } from 'react-toastify';
import { IArbitrageProduct } from '../../../interfaces/amazonToolsInterfaces';
import { classNames } from '../../../utils/conditionalClasses';
import { useAuth } from '../../../context/AuthContext';
import ToolsAbsoluteBuyBox from '../../common/ToolsAbsoluteBuyBox';
import { useLanguage } from '../../../context/LanguageContext';

interface ArbitrageProps {
  totalPageCount: number;
  page: number;
  isLoading: boolean;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  inputPage: number | string;
  setInputPage: React.Dispatch<React.SetStateAction<number | string>>;
  data: IArbitrageProduct[];
  queryString: string;
}
const ArbitrageTable: React.FC<ArbitrageProps> = ({
  totalPageCount,
  page,
  setPage,
  isLoading,
  data,
  inputPage,
  setInputPage,
  queryString,
}) => {
  const { session } = useAuth();
  const { t } = useLanguage();
  const tableHeader = [
    { name: t?.('amazon-online-arbitrage.table.no'), column: 'index' },
    { name: t?.('amazon-online-arbitrage.table.image'), column: 'title' },
    { name: t?.('amazon-online-arbitrage.table.title'), column: 'saleCount' },
    { name: t?.('amazon-online-arbitrage.table.asin'), column: 'returnCount' },
    {
      name: t?.('amazon-online-arbitrage.table.canadaPrice'),
      column: 'revenue',
    },
    {
      name: t?.('amazon-online-arbitrage.table.americaPrice'),
      column: 'productCost',
    },
    {
      name: t?.('amazon-online-arbitrage.table.monthlySales'),
      column: 'shippingCost',
    },
    {
      name: t?.('amazon-online-arbitrage.table.monthlyRevenue'),
      column: 'returnCost',
    },
    {
      name: t?.('amazon-online-arbitrage.table.amazonFee'),
      column: 'trendyolFee',
    },
    {
      name: t?.('amazon-online-arbitrage.table.shippingFee'),
      column: 'Reklam Maliyeti',
    },
    { name: t?.('amazon-online-arbitrage.table.netProfit'), column: 'vat' },
    {
      name: t?.('amazon-online-arbitrage.table.profitMargin'),
      column: 'profit',
    },
    {
      name: t?.('amazon-online-arbitrage.table.profitRate'),
      column: 'profitMargin',
    },
    {
      name: t?.('amazon-online-arbitrage.table.rivalCount'),
      column: 'profitRate',
    },
    {
      name: t?.('amazon-online-arbitrage.table.isAmazonSelling'),
      column: 'profitRate',
    },
    {
      name: t?.('amazon-online-arbitrage.table.variation'),
      column: 'variationCount',
    },
    { name: t?.('amazon-online-arbitrage.table.avgBSR'), column: 'avgBsr' },
    { name: t?.('amazon-online-arbitrage.table.category'), column: 'category' },
  ];
  return (
    <div className="flex flex-col flex-shrink-0 bg-white rounded-xl p-5 shadow-lg mt-2 sm:p-4 relative">
      {!session?.hasPackage && <ToolsAbsoluteBuyBox />}
      <div className="flow-root">
        <div className="relative -mx-4 -my-2 overflow-x-auto overflow-y-auto h-[60vh] scrollbar-hide">
          <div className="inline-block min-w-full py-2 align-middle  sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300  table-fixed">
              <thead>
                <tr>
                  {tableHeader.map((item, index) => {
                    return (
                      <th
                        key={index}
                        className="sticky top-0 z-10 border-b border-gray-300 text-center bg-lightGray py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter "
                        // scope="col"
                      >
                        {' '}
                        <p className="text-xs md:text-sm">{item.name}</p>
                      </th>
                    );
                  })}
                </tr>
              </thead>

              {isLoading ? (
                <div className="w-full flex justify-center">
                  <LoadingComponent />
                </div>
              ) : (
                <tbody className="divide-y divide-gray-200 bg-white">
                  {data?.map((prod: any, idx) => {
                    return (
                      <tr
                        key={idx}
                        className={classNames(
                          !session?.hasPackage && idx > 2
                            ? 'blur-sm'
                            : 'blur-none',
                          'even:bg-lightGray'
                        )}
                      >
                        <td className="py-4 pl-4 text-xs md:text-sm sm:pl-0">
                          <p className="text-center text-gray">
                            {idx + 20 * (page - 1) + 1}
                          </p>
                        </td>
                        <td className="py-4 pl-4 text-xs md:text-sm font-medium text-center text-gray-900 sm:pl-0">
                          <a
                            href={`https://www.amazon.ca/dp/${prod.asin}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img
                              className=" h-16 object-contain object-center mx-auto"
                              src={`https://m.media-amazon.com/images/I/${prod.imageUrls[0]}`}
                              alt="imagenotfound"
                            />
                          </a>
                        </td>
                        <td className="py-4 text-xs text-center text-gray-500">
                          <a
                            href={`https://www.amazon.ca/dp/${prod.asin}`}
                            target="_blank"
                            rel="noreferrer"
                            className="text-center"
                          >
                            {prod.title.substring(0, 40)}..
                          </a>
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          <a
                            href={`https://www.amazon.ca/dp/${prod.asin}`}
                            target="_blank"
                            rel="noreferrer"
                            className="text-center"
                          >
                            {prod.asin}
                          </a>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm min-w-52 text-gray-500">
                          <p className="px-6">
                            CA${' '}
                            {prod.toAvgPrice ? (
                              <span className="font-semibold">{`${Number(
                                prod.toAvgPrice
                              ).toFixed(2)}`}</span>
                            ) : (
                              '-'
                            )}
                          </p>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm w-52 text-gray-500 ">
                          CA${' '}
                          {prod.fromAvgPrice ? (
                            <span className="font-semibold">{`${Number(
                              prod.fromAvgPrice
                            ).toFixed(2)}`}</span>
                          ) : (
                            '-'
                          )}
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500">
                          <span className="font-semibold">
                            {prod.saleCount
                              ? seperateNumberWithComma(prod.saleCount)
                              : '-'}
                          </span>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          CA${' '}
                          <span className="font-semibold">
                            {seperateNumberWithComma(
                              Number(prod.monthlyRevenue)
                            )}
                          </span>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center min-w-[100px] text-xs md:text-sm text-gray-500">
                          CA${' '}
                          <span className="font-semibold">
                            {seperateNumberWithComma(prod.amazonCut)}
                          </span>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500">
                          CA${' '}
                          <span className="font-semibold">
                            {seperateNumberWithComma(prod.shipmentCost)}
                          </span>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500">
                          CA${' '}
                          <span className="font-semibold">
                            {seperateNumberWithComma(prod.netProfit)}
                          </span>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500">
                          <span className="font-semibold">
                            %{(Number(prod.profitMargin) * 100).toFixed(0)}
                          </span>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500">
                          <span className="font-semibold">
                            %{(Number(prod.profitRate) * 100).toFixed(0)}
                          </span>
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500">
                          <span className="font-semibold">{prod.rivals}</span>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500">
                          <span className="font-semibold">
                            {prod.isAmazonSeller ? 'VAR' : 'YOK'}
                          </span>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500">
                          <span className="font-semibold">
                            {Number(prod.variationCount)}
                          </span>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500">
                          <span className="font-semibold">
                            {seperateNumberWithComma(prod.avgBsr)}
                          </span>
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500">
                          {prod.categoryName}
                        </td>
                      </tr>
                    );
                  })}
                  {!session?.hasPackage &&
                    Array.from({ length: 97 }).map((_, idx) => (
                      <tr
                        key={idx}
                        className={classNames('blur-sm even:bg-lightGray')}
                      >
                        <td className="py-4 pl-4 text-xs md:text-sm sm:pl-0 w-16">
                          <p className="text-center text-gray">{idx + 4}</p>
                        </td>
                        <td className="py-4 pl-4 text-xs md:text-sm font-medium text-center text-gray-900 sm:pl-0 w-16">
                          <img
                            className="h-10 object-contain w-full"
                            src={`https://picsum.photos/${idx}`}
                          />
                        </td>
                        <td className="px-4 py-4 text-xs md:text-sm text-center text-gray-500  ">
                          Heollaaae
                        </td>
                        <td className="whitespace-nowrap text-center px-3 py-4 text-xs md:text-sm text-gray-500 ">
                          Tıasdoanskdo
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs  text-gray-500 ">
                          Jewelry
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          Fiziksel
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          167253178
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          12313
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          2323
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          4434343
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          333444
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          333444
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          333444
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          333444
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          333444
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          333444
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          333444
                        </td>
                      </tr>
                    ))}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>

      <Pagination
        setPage={setPage}
        page={page}
        inputPage={inputPage}
        setInputPage={setInputPage}
        totalPageCount={totalPageCount!}
        queryString={queryString}
      />

      <ToastContainer />
    </div>
  );
};

export default ArbitrageTable;
