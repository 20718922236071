import { Activity, ArrowSwapVertical } from 'iconsax-react';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import API_URL from '../../config';
import { useAuth } from '../../context/AuthContext';
import { AmazonProduct, orderByTypes } from '../../pages/AmazonProductFinder';
import { classNames } from '../../utils/conditionalClasses';
import { seperateNumberWithComma } from '../../utils/numberFormatter';
import DotLoaderSpinner from '../common/DotLoaderSpinner';
import Pagination from '../common/Pagination';
import ToolsAbsoluteBuyBox from '../common/ToolsAbsoluteBuyBox';

interface AmazonProductFinderTableProps {
  products: AmazonProduct[]
  isLoading: boolean
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  inputPage?: number | string;
  setInputPage?: React.Dispatch<React.SetStateAction<number | string>>;
  totalPageCount: number;
  queryString?: string;
  handleOrderTypeBy: (name: string, orderType: boolean) => void
}
type orderType = 'ASC' | 'DESC'
const AmazonProductFinderTable: React.FC<AmazonProductFinderTableProps> = ({
  products,
  isLoading,
  page,
  setPage,
  totalPageCount,
  inputPage,
  setInputPage,
  queryString,
  handleOrderTypeBy
}) => {
  const [isCheckedList, setIsCheckedList] = useState<boolean[]>(Array(products?.length).fill(false));
  const { session } = useAuth();
  const [tableHeader, setTableHeader] = useState<{
    name: string;
    column: string;
    orderType: boolean;
  }[]>([
    {
      // name: t?.('orders.table.choose'),
      name: 'Ürün',
      column: '',
      orderType: true
    },
    {
      // name: t?.('orders.table.ticket'),
      name: 'Aylık Satış Sayısı',
      column: 'saleCount',
      orderType: true
    },
    {
      // name: t?.('orders.table.product'),
      name: 'Aylık Ciro',
      column: 'revenue',
      orderType: true
    },
    {
      // name: t?.('orders.table.choose'),
      name: 'Ürün Fiyatı',
      column: 'price',
      orderType: true
    },
    {
      // name: t?.('orders.table.id'),
      name: 'Amazon Kesintileri',
      column: 'totalAmazonCut',
      orderType: true
    },
    {
      // name: t?.('orders.table.customs-value'),
      name: 'Ürün Yaşı',
      column: '',
      orderType: true
    },
    {
      // name: t?.('orders.table.buyer'),
      name: 'Rakip Satıcı Sayısı',
      column: 'rivalCount',
      orderType: true
    },
    {
      // name: t?.('orders.table.trackingNu'),
      name: 'Listede Amazon Var mı?',
      column: '',
      orderType: true
    },
    {
      // name: t?.('orders.table.seTagNu'),
      name: 'Yorum Sayısı',
      column: 'reviewCount',
      orderType: true
    },
    {
      // name: t?.('orders.table.carrier'),
      name: 'Kategori & BSR',
      column: 'avgBsr',
      orderType: true
    },
    {
      // name: t?.('orders.table.country'),
      name: 'Varyasyon Sayısı',
      column: 'variationCount',
      orderType: true
    },
    {
      // name: t?.('orders.table.piece'),
      name: 'Ürün Boyutu',
      column: '',
      orderType: true
    },
    {
      // name: t?.('orders.table.piece'),
      name: 'Resim Sayısı',
      column: 'imageCount',
      orderType: true
    },

  ]);
  function calculateElapsedTime(startDate: string, endDate: string) {
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Yılları hesapla
    const years = end.getFullYear() - start.getFullYear();

    // Ayları hesapla
    let months = end.getMonth() - start.getMonth();
    if (months < 0) {
      months += 12;
    }

    // Günleri hesapla
    let days = end.getDate() - start.getDate();
    if (days < 0) {
      const lastDayOfMonth = new Date(end.getFullYear(), end.getMonth(), 0).getDate();
      days += lastDayOfMonth;
      months--;
    }

    // Sonuçları birleştir
    const result = [];
    if (years > 0) {
      result.push(`${years} Yıl`);
    }
    if (months > 0) {
      result.push(`${months} Ay`);
    }
    if (days > 0) {
      result.push(`${days} Gün`);
    }

    return result.join(' ');
  }
  const handleOrderTypeByClick = (columnName: string, currentOrderType: boolean) => {
    handleOrderTypeBy(columnName, currentOrderType)
    const updatedHeaders = tableHeader.map((header) => {
      if (header.column === columnName) {
        return {
          ...header,
          orderType: !currentOrderType,
        };
      } else {
        return {
          ...header,
          orderType: true,
        };
      }
    });

    setTableHeader(updatedHeaders);
  };
  return (
    <div className="flex flex-col flex-shrink-0 bg-white rounded-xl p-5 shadow-lg sm:p-4 mt-10 relative">
      {!session?.hasPackage && <ToolsAbsoluteBuyBox />}
      <div className="flow-root mb-4">
        <div className="relative -mx-4 -my-4 overflow-x-auto overflow-y-auto h-[70vh] scrollbar-hide">
          <div className="inline-block min-w-full align-middle">
            <table className="min-w-full divide-y divide-gray-300 table-auto">
              <thead>
                <tr className=''>
                  {tableHeader.map((item, index) => {
                    return (
                      item.column !== '' ?
                        <th
                          onClick={() => handleOrderTypeByClick(item.column, item.orderType)}
                          key={index}
                          className={classNames(index === 0 ? 'md:left-0 md:z-20' : '', "sticky top-0 z-10 border-b border-gray-300 text-center bg-headerPrimary text-sm font-semibold text-white backdrop-blur backdrop-filter cursor-pointer p-4")}
                          scope="col"
                        >
                          <p className="cursor-pointer text-xs md:text-sm flex flex-row gap-1 items-center justify-center">
                            {item.name}
                            <ArrowSwapVertical className="" />
                          </p>
                        </th>
                        :
                        <th
                          key={index}
                          className={classNames(index === 0 ? 'md:left-0 md:z-20' : '', "sticky top-0 z-10 border-b border-gray-300 text-center bg-headerPrimary text-sm font-semibold text-white backdrop-blur backdrop-filter p-4")}
                          scope="col"
                        >
                          <p className="text-xs md:text-sm flex flex-row gap-1 items-center justify-center">{item.name}</p>
                        </th>

                    );
                  })}
                </tr>
              </thead>
              {isLoading ? (
                <div className="w-full flex justify-center">
                  <DotLoaderSpinner loading={isLoading} />
                </div>
              ) : (
                <tbody className="divide-y divide-gray-200 bg-white" >
                  {products && products
                    .map((product: AmazonProduct, idx: any) => {
                      if (!session?.hasPackage && idx >= 3) return null; // Eğer session package yoksa ve indeks 3 veya daha büyükse, bu ürünü haritamayı atla
                      return (
                        <tr
                          key={idx}
                          className={classNames(
                            !session?.hasPackage && idx > 2 ? 'blur-sm' : 'blur-none',
                            isCheckedList[idx] ? 'bg-[#FFEDD5]' : 'even:bg-lightGray',
                          )}
                        >
                          <td className={classNames(
                            // isCheckedList[idx] ? 'bg-[#FFEDD5]' : 'even:bg-lightGray',
                            "md:sticky left-0 z-10 bg-white even:bg-lightGray text-sm font-medium text-center text-gray-900 sm:pl-0 border-r-4 border-[#B7BCE1] min-w-[400px]")}>
                            <div className='flex flex-row gap-4 py-4 px-4 items-center justify-center'>
                              <a
                                href={`https://www.amazon.com/dp/${product?.asin}`}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  className="w-24 h-24 object-contain object-center mx-auto"
                                  src={`https://m.media-amazon.com/images/I/${product?.images[0]}`}
                                  alt="image"
                                />
                              </a>
                              <div className='text-start text-description flex flex-col gap-1'>
                                <a
                                  href={`https://www.amazon.com/dp/${product?.asin}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <p className='text-md  font-medium w-full'>{product?.title?.slice(0, 65)}...</p>
                                </a>
                                <div className='text-start text-description flex flex-row gap-2 justify-between'>
                                  <a
                                    href={`https://www.amazon.com/dp/${product?.asin}`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <p className='text-md font-normal'>{product?.asin}</p>
                                  </a>
                                  <Link
                                    to={`/amazon-forecast?asin=${product?.asin}`}
                                    className="flex text-md font-normal text-blue-500"
                                    target="_blank"
                                  >
                                    2 Yıllık Forecast{' '}
                                    <Activity
                                      color="#0070f3"
                                      size={20}
                                      className="ml-2"
                                    />
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td className="whitespace-nowrap text-center p-4 text-sm text-description">
                            {product?.saleCount && seperateNumberWithComma(Number(product?.saleCount))}
                          </td>
                          <td className="whitespace-nowrap text-center p-4 text-sm text-description">
                            {product?.revenue ? `$ ${seperateNumberWithComma(Math.trunc(Number(product?.revenue)))}` : '-'}
                          </td>
                          <td className="whitespace-nowrap text-center p-4 text-sm text-description font-bold">
                            {product?.price ? `$${seperateNumberWithComma(Number(Number(product?.price).toFixed(2)))}` : '-'}
                          </td>
                          <td className="whitespace-nowrap text-start p-4 text-sm text-description">
                            <span className='font-semibold '> FBA: </span>{product?.amazonCuts.fbaFee ? `$ ${(Number(product?.amazonCuts.fbaFee)).toFixed(2)}` : '-'} <br />
                            <span className='font-semibold '> Depolama : </span>{product?.amazonCuts.storageFee ? `$ ${(Number(product?.amazonCuts.storageFee)).toFixed(2)}` : '-'} <br />
                            <span className='font-semibold '> Komisyon:  </span>{product?.amazonCuts.referralFee ? `$ ${(Number(product?.amazonCuts.referralFee)).toFixed(2)}` : '-'}
                          </td>
                          <td className="whitespace-nowrap text-center p-4 text-sm text-description">
                            {product?.listedSince ? calculateElapsedTime(product?.listedSince, new Date().toISOString()) : '-'}
                          </td>
                          <td className="whitespace-nowrap text-center p-4 text-sm text-description">
                            {product?.rivalCount && seperateNumberWithComma(product?.rivalCount)}
                          </td>
                          <td className="whitespace-nowrap text-center p-4 text-sm text-description">
                            {product?.isAmazonOnList ? 'Var' : 'Yok'}
                          </td>
                          <td className="whitespace-nowrap text-center p-4 text-sm text-description">
                            {product?.reviewCount && seperateNumberWithComma(product?.reviewCount)}
                            {` (${product?.rating ? `${seperateNumberWithComma(Number(product?.rating))}` : '-'})`}
                          </td>
                          <td className="whitespace-nowrap text-center p-4 text-sm text-description ">
                            {product?.category} <br />
                            {product?.avgBsr && seperateNumberWithComma(Number(product?.avgBsr))}
                          </td>
                          <td className="whitespace-nowrap text-center p-4 text-sm text-description">
                            {product?.variationCount && seperateNumberWithComma(product?.variationCount)}
                          </td>
                          <td className="whitespace-nowrap text-center p-4 text-sm text-description">
                            {product?.sizeTier}
                          </td>
                          <td className="whitespace-nowrap text-center p-4 text-sm text-description">
                            {product?.imageCount && seperateNumberWithComma(Number(product?.imageCount))}
                          </td>
                        </tr>
                      );
                    })}
                  {!session?.hasPackage &&
                    Array.from({ length: 97 }).map((_, idx) => (
                      <tr
                        key={idx}
                        className={classNames('blur-sm even:bg-lightGray')}
                      >

                        <td className="text-xs md:text-sm font-medium text-center text-gray-900 sm:pl-0 border-r-4 border-[#B7BCE1]">
                          <div className='flex flex-row gap-4 p-4 items-center justify-center'>
                            <a
                              href={`#`}
                              rel="noreferrer"
                            >
                              <img
                                className=" h-24 object-contain object-center mx-auto"
                                src={`https://picsum.photos/${idx}`}
                              />
                            </a>
                            <div className='text-start text-description flex flex-col gap-1'>
                              <p className='text-md  font-medium'>premium uyelere ozel bilgiler...</p>
                              <p className='text-md font-normal'>24AD325AS</p>
                              <p className='font-bold'>${Math.floor(Math.random() * 900000) + 100000}</p>
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap text-center px-12 py-4 text-xs  text-gray-500 text-description cursor-pointer">
                          234.123
                        </td>
                        <td className="whitespace-nowrap text-center px-12 py-4 text-xs  text-gray-500 text-description">
                          {Math.floor(Math.random() * 900000) + 100000}
                        </td>
                        <td className="whitespace-nowrap text-center px-12 py-4 text-xs  text-[#0284C7] ">
                          {Math.floor(Math.random() * 900000) + 100000}
                        </td>
                        <td className="whitespace-nowrap text-center px-12 py-4 text-xs  text-description ">
                          {Math.floor(Math.random() * 900000) + 100000}
                        </td>
                        <td className="whitespace-nowrap text-center px-12 py-4 text-xs text-gray-500">
                          Hayir                                                </td>
                        <td className="whitespace-nowrap text-center px-12 py-4 text-xs  text-description ">
                          {Math.floor(Math.random() * 900000) + 100000}
                        </td>
                        <td className="whitespace-nowrap text-center px-12 py-4 text-xs  text-gray-500 ">
                          Home & Kitchen
                        </td>
                        <td className="whitespace-nowrap px-12 py-4 text-center text-xs md:text-sm text-gray-500">
                          23
                        </td>
                        <td className="whitespace-nowrap text-center px-12 py-4 text-xs">
                          Large Standart Size
                        </td>
                        <td className="whitespace-nowrap text-center px-12 py-4 text-xs">
                          4 Months
                        </td>
                      </tr>
                    ))}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
      {session?.hasPackage && (
        <Pagination
          setPage={setPage}
          page={page}
          totalPageCount={totalPageCount!}
          setInputPage={setInputPage}
          inputPage={inputPage}
          queryString={queryString}
        />
      )}
    </div>
  )
}

export default AmazonProductFinderTable