import React, { Fragment, useState } from 'react';

import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon } from '@heroicons/react/24/outline';

import { useAuth } from '../../context/AuthContext';
import { useNumberVerification } from '../../context/NumberVerificationContext';
import memberService from '../../services/memberService';
import Question1 from './elements/Question1';
import Question2 from './elements/Question2';
import Question3 from './elements/Question3';
import Question4 from './elements/Question4';
import Question5 from './elements/Question5';
import Question51 from './elements/Question51';
import Question6 from './elements/Question6';
import SurveySuccessModal from './elements/SurveySuccessModal';

const SurveyModal = () => {
    // const [successModalOpen, setSuccessModalOpen] = useState(false)
    const [question1Modal, setQuestion1Modal] = useState(true)
    const [question2Modal, setQuestion2Modal] = useState(false)
    const [question3Modal, setQuestion3Modal] = useState(false)
    const [question4Modal, setQuestion4Modal] = useState(false)
    const [question51Modal, setQuestion51Modal] = useState(false)
    const [question5Modal, setQuestion5Modal] = useState(false)
    const [question6Modal, setQuestion6Modal] = useState(false)
    const [questionSuccessModal, setQuestionSuccessModal] = useState(false)
    const [answers, setAnswers] = useState<{ [key: number]: string | undefined | string[] | { country: string, state: string } }>({
        1: "",
        2: "",
        3: [""],
        4: [""],
        51: { country: '', state: '' },
        5: [""],
        6: [""]
    })

    const { openSurvey, setOpenSurvey, } = useNumberVerification()
    const { setSession } = useAuth()
    const handleAnswers = (id: number, answer?: string | string[] | { country: string, state: string }) => {
        setAnswers((prevAnswers) => {
            return {
                ...prevAnswers,
                [id]: answer
            }
        })
    }

    const handleForward = (modal: string, answer?: string | string[] | { country: string, state: string }) => {
        switch (modal) {
            case "question1":
                handleAnswers(1, answer)
                setQuestion1Modal(false)
                setQuestion2Modal(true)
                return
            case "question2":
                handleAnswers(2, answer)
                setQuestion2Modal(false)
                setQuestion3Modal(true)
                return
            case "question3":
                handleAnswers(3, answer)
                setQuestion3Modal(false)
                setQuestion4Modal(true)
                return
            case "question4":
                handleAnswers(4, answer)
                setQuestion4Modal(false)
                setQuestion51Modal(true)
                return
            case "question51":
                handleAnswers(51, answer)
                setQuestion51Modal(false)
                setQuestion5Modal(true)
                return
            case "question5":
                handleAnswers(5, answer)
                setQuestion5Modal(false)
                setQuestion6Modal(true)
                return
            case "question6":
                handleAnswers(6, answer)
                sendSurveyAnswers(answers)
                return
        }
    }
    const handleBackward = (modal: string, answer?: string | string[] | { country: string, state: string }) => {
        switch (modal) {
            case "question2":
                handleAnswers(2, answer)
                setQuestion2Modal(false)
                setQuestion1Modal(true)
                return
            case "question3":
                handleAnswers(3, answer)
                setQuestion3Modal(false)
                setQuestion2Modal(true)
                return
            case "question4":
                handleAnswers(4, answer)
                setQuestion4Modal(false)
                setQuestion3Modal(true)
                return
            case "question51":
                handleAnswers(51, answer)
                setQuestion51Modal(false)
                setQuestion4Modal(true)
                return
            case "question5":
                handleAnswers(5, answer)
                setQuestion5Modal(false)
                setQuestion51Modal(true)
                return
            case "question6":
                handleAnswers(6, answer)
                setQuestion6Modal(false)
                setQuestion5Modal(true)
                return
        }
    }

    const sendSurveyAnswers = (answers: { [key: number]: string | undefined | string[] | { country: string, state: string } }) => {
        memberService.sendSurveyAnswers(answers)
            .then((res) => {
                setQuestion6Modal(false)
                setQuestionSuccessModal(true)
                // checkSessionStatus?.()
                setSession?.(res.data)
            })
            .catch((err) => {
                console.log(err);
            })
    }
    const updateSession = () => {
        setOpenSurvey?.(false)
        sendSurveyAnswers(answers)
    }

    let surveyContent;

    if (question1Modal) {
        surveyContent = <Question1 handleForward={handleForward} />
    } else if (question2Modal) {
        surveyContent = <Question2 handleForward={handleForward} handleBackward={handleBackward} />
    } else if (question3Modal) {
        surveyContent = <Question3 handleForward={handleForward} handleBackward={handleBackward} />
    } else if (question4Modal) {
        surveyContent = <Question4 handleForward={handleForward} handleBackward={handleBackward} />
    } else if (question51Modal) {
        surveyContent = <Question51 handleForward={handleForward} handleBackward={handleBackward} />
    } else if (question5Modal) {
        surveyContent = <Question5 handleForward={handleForward} handleBackward={handleBackward} />
    } else if (question6Modal) {
        surveyContent = <Question6 handleForward={handleForward} handleBackward={handleBackward} />
    } else if (questionSuccessModal) {
        surveyContent = <SurveySuccessModal />
    }


    return (
        <Transition.Root show={openSurvey} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={updateSession!}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>
                <div className="fixed inset-0 z-10 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="min-w-[340px] min-h-[490px] relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6 overflow-visible">
                                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                        onClick={updateSession!}
                                    >
                                        <span className="sr-only">Close</span>
                                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className="mx-auto flex w-full max-w-md flex-col space-y-4">
                                    <div className="flex flex-col items-center justify-center text-center space-y-1">
                                        <div className="font-semibold text-md md:text-xl">
                                            <p className="my-1">Rexven'e Katılım Formu</p>
                                        </div>
                                    </div>
                                    {surveyContent}
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>)
}

export default SurveyModal