import { Trash } from 'iconsax-react';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { PlusIcon } from '@heroicons/react/24/outline';
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useAuth } from '../../context/AuthContext';
import { useLanguage } from '../../context/LanguageContext';
import { IPersonalService, IServices } from '../../interfaces/serviceProviders';
import memberService from '../../services/memberService';
import alertNotification from '../../utils/alertNotification';
import AddServiceModal from './AddServiceModal';

interface Props {
  data: IPersonalService;
}
const ServiceSection: React.FC<Props> = ({ data }) => {
  const { session } = useAuth();
  const { t, language } = useLanguage();
  const [services, setServices] = useState<IServices[]>(data?.services);

  const [description, setDescription] = useState(data.description);
  const [providerName, setProviderName] = useState(data?.name);
  const [open, setOpen] = useState(false);
  const [isServiceAdd, setIsServiceAdd] = useState(false);
  const inputRefs = useRef<HTMLInputElement[]>([]);

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.currentTarget.name === 'about') {
      setDescription(e.currentTarget.value);
    } else if (e.currentTarget.name === 'providerName') {
      setProviderName(e.currentTarget.value);
    } else {
      setServices(
        services.map((service) => {
          if (service.serviceId === parseInt(e.currentTarget.name)) {
            service.description = e.currentTarget.value;
          }
          return service;
        })
      );
      setIsServiceAdd(true);
    }
  };
  const handleChangePrice = (e: React.ChangeEvent<HTMLInputElement>) => {
    setServices(
      services.map((service) => {
        if (service.serviceId === parseInt(e.currentTarget.name)) {
          service.serviceFee = e.target.value;
        }
        return service;
      })
    );
    setIsServiceAdd(true);
  };
  const updateServices = async () => {
    const tempServ = services.map((item) => {
      return {
        serviceId: Number(item.serviceId),
        serviceFee: Number(item.serviceFee),
        description: item.description,
      };
    });
    const payload = {
      description: description,
      name: providerName,
      services: tempServ,
    };

    try {
      await memberService.updatePersonalServices(
        data?.serviceProviderId,
        payload
      );
      alertNotification('success', 'Değişiklikler kayıt edildi');
      setIsServiceAdd(false);
    } catch (error) {
      alertNotification('error', 'Bir hata oluştu');
    }
  };

  const deleteService = async (serviceId: number) => {
    try {
      await memberService.deletePersonalService(
        data?.serviceProviderId,
        serviceId
      );
    } catch (error) {
      alertNotification('error', 'Hata oluştu');

      return;
    }
  };
  const queryClient = useQueryClient();

  const updateMutation = useMutation({
    mutationFn: updateServices,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['allServiceProviders'] });
      queryClient.invalidateQueries({ queryKey: ['personalServices'] });
    },
  });
  const mutation = useMutation({
    mutationFn: deleteService,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['personalServices'] });
    },
  });

  const handleDelete = (itemId: number) => {
    mutation.mutate(itemId);
    setServices(services.filter((item) => item.serviceId !== itemId));
  };
  useEffect(() => {
    inputRefs.current = inputRefs.current.slice(0, services?.length);
  }, [services]);

  return (
    <div className='flex flex-col flex-shrink-0 bg-white rounded-xl p-5 shadow-lg sm:p-7 sm:flex-row"'>
      <div className="border-b-2 border-lightGray pb-5 sm:pb-7 ">
        <h3 className="font-medium text-headingPrimary cardHeading">
          {t?.('profile.service.info')}
        </h3>
      </div>
      <div className="flex flex-col jsutify-start gap-2 mt-4">
        <p className="text-headingPrimary font-medium text-sm">
          {t?.('profile.service.name')}
        </p>
        <input
          type="text"
          name="providerName"
          id="providerName"
          value={providerName}
          onChange={handleChange}
          className="w-[300px] rounded-md border-2  border-cardBorder text-sm md:text-base bg-lightGray"
        />
      </div>
      <textarea
        name="about"
        id="about"
        rows={10}
        value={description}
        onChange={handleChange}
        className="w-full rounded-md border-2  border-cardBorder text-sm md:text-base bg-lightGray my-5 sm:my-7"
        placeholder="Kendinizi detaylı şekilde tanıtınız. Hangi alamlarda uzmansınız? Kaç yıllık tecrübeniz var? Girişimciler neden sizi tercih etmeli?"
      />

      <p className="text-headingPrimary font-medium mb-5 sm:mb-7">
        {t?.('profile.service.myServices')}
      </p>
      {services.length > 0 ? (
        services?.map((service, index) => {
          return (
            <div
              className="w-full flex sm:flex-row gap-4 text-xs md:text-sm flex-col justify-start  sm:items-center mb-5"
              key={service.serviceName}
            >
              <div className="flex w-full sm:w-1/2 md:w-1/2  xl:w-1/3 justify-between items-center gap-4">
                <p>
                  <span className="text-headingPrimary font-bold">
                    {index + 1}-
                  </span>
                  {service.serviceName}
                </p>
                <div className="flex justify-start items-center gap-4">
                  <input
                    type="number"
                    ref={(el) =>
                      (inputRefs.current[index] = el as HTMLInputElement)
                    }
                    name={service?.serviceId?.toString()}
                    id={service?.serviceId?.toString()}
                    onChange={handleChangePrice}
                    value={service.serviceFee}
                    className="py-1 px-2 bg-lightGray rounded-md outline-none w-24 text-center text-xs sm:text-sm"
                  />
                  {/* <Edit
                    size="24"
                    color="#2D356C"
                    variant="Outline"
                    className="cursor-pointer"
                    onClick={() => {
                      setDisabled(false);
                      setIsServiceAdd(true);

                      inputRefs.current[index].focus();
                    }}
                  /> */}
                </div>
              </div>
              <div className="flex sm:w-1/2 md:w-1/2 xl:w-2/3 justify-between items-center gap-2">
                <textarea
                  name={service?.serviceId?.toString()}
                  id={service?.serviceId?.toString()}
                  rows={3}
                  value={service.description}
                  onChange={handleChange}
                  className="w-full rounded-md border-2  border-cardBorder text-xs md:text-base bg-lightGray my-5 sm:my-7 overflow-y-auto"
                  placeholder="Kendinizi detaylı şekilde tanıtınız. Hangi alamlarda uzmansınız? Kaç yıllık tecrübeniz var? Girişimciler neden sizi tercih etmeli?"
                />
                <button
                  className="bg-rexRed text-white font-medium flex text-sm justify-center items-center p-2 rounded-md"
                  onClick={() => handleDelete(Number(service?.serviceId))}
                >
                  <Trash size="24" color="white" />
                  <span className="ml-2 text-xs">
                    {t?.('common.buttons.delete')}
                  </span>
                </button>
              </div>
              <hr />
            </div>
          );
        })
      ) : (
        <p>{t?.('profile.service.noService')}</p>
      )}

      <div className="bg-lightGray rounded-full flex justify-center items-center p-2 w-12 h-12 mx-auto my-5 sm:my-7 cursor-pointer">
        <PlusIcon
          height={24}
          width={24}
          color="#2D356C"
          onClick={() => setOpen(true)}
        />
      </div>

      <AddServiceModal
        setIsServiceAdd={setIsServiceAdd}
        open={open}
        setOpen={setOpen}
        setServices={setServices}
        services={services}
      />
      <div className="flex w-full justify-end items-center gap-4 border-b-2 border-lightGray pb-5 sm:pb-7 mt-5 sm:mt-7">
        {isServiceAdd && (
          <p className="text-rexRed text-sm sm:text-base">
            {t?.('profile.service.updateChanges')}
          </p>
        )}
        {!session?.hasPackage && (
          <p className="text-rexRed text-sm sm:text-base">
            Hizmetlerinizi sergilemek için lütfen premium{' '}
            <Link
              to={language === 'tr' ? "https://rexven.com/topluluk-paketleri" : "https://rexven.com/pricing"}
              className="text-secondary underline ">
              üye olunuz.
            </Link>
          </p>
        )}
        <button
          className="bg-headerPrimary text-white font-medium rounded-lg py-2 px-6 text-sm"
          onClick={() => updateMutation.mutate()}
          disabled={!session?.hasPackage}
        >
          {t?.('common.buttons.submit')}
        </button>
      </div>
    </div>
  );
};

export default ServiceSection;
