import React, { useState } from 'react';
import LoadingComponent from '../common/LoadingComponent';
import { seperateNumberWithComma } from '../../utils/numberFormatter';
import Pagination from '../common/Pagination';
import { ToastContainer } from 'react-toastify';
import { ITrendyolOrders } from '../../pages/TrendyolOrderPage';

const tableHeader = [
  { name: 'Ürün Resmi', column: 'productImg', isButton: false },
  { name: 'Ürün Başlığı', column: 'title', isButton: false },
  { name: 'Satış Sayısı', column: 'saleCount', isButton: false },
  { name: 'İade Sayısı', column: 'returnCount', isButton: false },
  { name: 'Toplam Ciro', column: 'revenue', isButton: false },
  { name: 'Ürün Maliyeti', column: 'productCost', isButton: false },
  { name: 'Kargo Maliyeti', column: 'shippingCost', isButton: false },
  { name: 'İade Maliyeti', column: 'returnCost', isButton: false },
  { name: 'Trendyol Kesintileri', column: 'trendyolFee', isButton: false },
  { name: 'Reklam Maliyeti', column: 'Reklam Maliyeti', isButton: false },
  { name: 'KDV', column: 'vat', isButton: false },
  { name: 'Kar/Zarar', column: 'profit', isButton: false },
  { name: 'Kar Marjı', column: 'profitMargin', isButton: false },
  { name: 'Kar Oranı', column: 'profitRate', isButton: false },
];

interface OrderTableProps {
  orders: ITrendyolOrders[];
  totalPageCount: number;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  inputPage: number | string;
  setInputPage: React.Dispatch<React.SetStateAction<number | string>>;
  getTrendyolOrders: () => Promise<void>;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
}
const OrdersTable: React.FC<OrderTableProps> = ({
  orders,
  totalPageCount,
  page,
  setPage,
  inputPage,
  setInputPage,
  getTrendyolOrders,
  isLoading,
  setIsLoading,
}) => {
  // const { session } = useAuth();

  // const [open, setOpen] = useState(false);
  // const [saleCount, setSaleCount] = useState(0);
  // const [openAd, setOpenAd] = useState(false);

  // const [barcode, setBarcode] = useState('');
  // const [hasProductCost, setHasProductCost] = useState(false);

  return (
    <div className="flex flex-col flex-shrink-0 bg-white rounded-xl p-5 shadow-lg mt-2 sm:p-4">
      <div className="flow-root">
        <div className="relative -mx-4 -my-2 overflow-x-auto overflow-y-auto h-[70vh]">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300 table-auto">
              <thead>
                <tr>
                  {tableHeader.map((item) => {
                    return (
                      <th
                        key={item.name}
                        className="border-b sticky top-0 z-10 border-gray-300 bg-white  py-3.5 pl-4 pr-3 text-sm text-center font-semibold text-gray-900 backdrop-blur backdrop-filter "
                        scope="col"
                      >
                        {' '}
                        <p className="text-xs md:text-sm">{item.name}</p>
                      </th>
                    );
                  })}
                </tr>
              </thead>

              {isLoading ? (
                <div className="w-full flex justify-center">
                  <LoadingComponent />
                </div>
              ) : (
                <tbody className="divide-y divide-gray-200 bg-white">
                  {orders && orders.length > 0 ? (
                    orders.map((product: any, idx) => {
                      return (
                        <tr key={product.id}>
                          <td className="flex items-center justify-center py-4">
                            <img
                              className="h-10 w-10 object-contain object-center"
                              src={product.images[0].url}
                            />
                          </td>
                          <td className="px-4 py-4 text-center text-xs md:text-sm  text-gray-500  ">
                            <p>{product.title.substring(0, 20)}...</p>
                          </td>
                          <td className="whitespace-nowrap text-center px-3 py-4 text-xs md:text-sm text-gray-500 ">
                            {product.saleCount}
                          </td>
                          <td className="whitespace-nowrap text-center px-3 py-4 text-xs md:text-sm text-gray-500 ">
                            {product.returnCount}
                          </td>
                          <td className="whitespace-nowrap text-center px-3 py-4 text-xs md:text-sm text-gray-500 ">
                            {seperateNumberWithComma(
                              Number(Number(product.totalRevenue).toFixed(0))
                            )}{' '}
                            ₺
                          </td>
                          <td className="whitespace-nowrap text-center px-3 text-xs md:text-sm text-gray-500 ">
                            {Number(product.productCost).toFixed(0)} ₺
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                            {Number(product.shipCost).toFixed(0)} ₺
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                            {Number(product.returnCost).toFixed(0)} ₺
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                            {Number(product.trCut).toFixed(0)} ₺
                          </td>
                          <td className="whitespace-nowrap text-center px-3 py-4 text-xs md:text-sm text-gray-500 ">
                            {/* <div className="flex">
                              <p className="mr-2"> */}
                            {Number(product.adCost).toFixed(0)} ₺{/* </p> */}
                            {/* <button
                                // disabled={!session?.isSeller}
                                onClick={() => {
                                  setBarcode(product.barcode);
                                  setOpenAd(true);
                                }}
                                className="cursor-pointer"
                              >
                                <Edit size="24" color="#5502D7" />
                              </button> */}
                            {/* </div> */}
                          </td>

                          <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                            {Number(product.totalVat).toFixed(0)} ₺
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                            {Number(product.profitOrLoss).toFixed(0)} ₺
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                            %{Number(product.profitMargin).toFixed(2)}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                            %{Number(product.profitRate).toFixed(2)}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <p className="">Henüz siparişiniz bulunmamaktadır</p>
                  )}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
      {/* PAGINATION KALDIRILACAK */}
      <Pagination
        setPage={setPage}
        page={page}
        inputPage={inputPage}
        setInputPage={setInputPage}
        totalPageCount={totalPageCount!}
      />
      {/* <ProductCostModal
        open={open}
        setOpen={setOpen}
        barcode={barcode}
        getOrders={getTrendyolOrders}
        saleCount={saleCount}
        hasProductCost={hasProductCost}
      /> */}
      {/* <AdCostModal
        openAd={openAd}
        setAdOpen={setOpenAd}
        barcode={barcode}
        getOrders={getTrendyolOrders}
      /> */}
      <ToastContainer />
    </div>
  );
};

export default OrdersTable;
