import React, { useState } from 'react';

import ConfirmedShipments from '../components/shipEntegra/myShipments/ConfirmedShipments';
import LabelledShipments from '../components/shipEntegra/myShipments/LabelledShipments';
import SwitchPageButtons from '../components/shipEntegra/myShipments/SwitchPageButtons';
import ShipEntegraLoginRegister from '../components/shipEntegraShared/ShipEntegraLoginRegister';
import NewBanner from '../components/tools/common/NewBanner';
import { useAuth } from '../context/AuthContext';

const Shipments = () => {
  const [isLabel, setIsLabel] = useState(false);
  const { session } = useAuth()
  let content = <>
    <SwitchPageButtons isLabel={isLabel} setIsLabel={setIsLabel} />
    {isLabel ? <LabelledShipments /> : <ConfirmedShipments />}
  </>
  return (
    <div>
      <NewBanner bgUrl="/images/shipentegra-banner-bg.webp" toolImageUrl="shipments" />
      <ShipEntegraLoginRegister />
      {session?.hasShipEntegraInt ?
        <>
          {content}
        </>
        :
        <div className='blur-sm h-full w-full'>
          {content}
        </div>
      }
    </div>
  );
};

export default Shipments;
