import React, { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLanguage } from '../../context/LanguageContext';

interface SearchProps {
  setProductLink: React.Dispatch<React.SetStateAction<string>>;
  placeholder: string;
}

const SearchField: React.FC<SearchProps> = ({
  placeholder,
  setProductLink,
}) => {
  const { language } = useLanguage();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const navigate = useNavigate();
  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    setProductLink(`?shopUrl=${String(inputRef.current?.value)}`);
    navigate(`?shopUrl=${inputRef.current?.value}`);
  };

  return (
    <div className="grid grid-cols-12 items-center px-0 lg:px-4 py-6">
      <p className="col-span-12 lg:col-span-5 text-sm  mb-2 lg:text-base">
        {language === 'tr'
          ? 'Analiz etmek istediğiniz mağazanın linkini yapıştırınız'
          : 'Paste the link of the store you want to analyze'}
      </p>
      <div className="col-span-12 lg:col-span-7">
        <form className="relative" onSubmit={handleSubmit}>
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <svg
              aria-hidden="true"
              className="w-5 h-5 text-cardBorder"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
          </div>
          <input
            ref={inputRef}
            id="default-search"
            className="block w-full p-[0.58rem] pl-10 text-xs md:text-sm text-gray-900 border border-headerPrimary rounded-lg bg-gray-50"
            placeholder={placeholder.substring(0, 70) + '...'}
          />
          <button
            type="submit"
            className="text-white absolute right-0 bottom-0 bg-headerPrimary rounded-r-md font-medium text-sm px-4 py-2 md:py-2.5"
          >
            {language === 'tr' ? 'Ara' : 'Search'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default SearchField;
