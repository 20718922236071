import { Printer, TruckFast } from 'iconsax-react';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useAuth } from '../../context/AuthContext';
import { Order } from '../../interfaces/shipEntegraInterfaces';
import { classNames } from '../../utils/conditionalClasses';
import DotLoaderSpinner from '../common/DotLoaderSpinner';
import Pagination from '../common/Pagination';
import CreateTicketModal from './CreateTicketModal';
import CreateTicketModalMultiple from './CreateTicketModalMultiple';
import UpdateOrderModal from './UpdateOrderModal';

interface ShipEntegraOrdersTableProps {
  tableHeader: { name: string; column: string; }[];
  isLoading: boolean;
  queryString: string;
  data: Order[];
  inputPage: string | number;
  setInputPage: React.Dispatch<React.SetStateAction<number | string>>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  totalPageCount: number;
  openCreateTicket: boolean
  setOpenCreateTicket: React.Dispatch<React.SetStateAction<boolean>>
  openArrangeOrder: boolean
  setOpenArrangeOrder: React.Dispatch<React.SetStateAction<boolean>>
  selectedOrders: Order[]
  setSelectedOrders: React.Dispatch<React.SetStateAction<Order[]>>
  selectedOrderIds: number[] | undefined
  setSelectedOrderIds: React.Dispatch<React.SetStateAction<number[]>>
  selectedOrderDetails: {
    url: string;
    orderId: number;
  }[]
  setSelectedOrderDetails: React.Dispatch<React.SetStateAction<{
    url: string;
    orderId: number;
  }[]>>
  getOrders: () => void
}

const ShipEntegraOrdersTable: React.FC<ShipEntegraOrdersTableProps> = ({
  tableHeader,
  isLoading,
  data,
  setPage,
  page,
  totalPageCount,
  setInputPage,
  inputPage,
  queryString,
  openCreateTicket,
  setOpenCreateTicket,
  openArrangeOrder,
  setOpenArrangeOrder,
  selectedOrders,
  setSelectedOrders,
  selectedOrderIds,
  setSelectedOrderIds,
  selectedOrderDetails,
  setSelectedOrderDetails,
  getOrders
}) => {
  const { session } = useAuth();
  const handleRowSelectionChange = (order: Order) => {
    if (selectedOrderIds) {
      if (selectedOrderIds.includes(order.orderId)) {
        // orderId'ye göre selectedOrderIds'den çıkar
        setSelectedOrderIds(selectedOrderIds.filter(id => id !== order.orderId));
        // Aynı zamanda sipariş detaylarını selectedOrderDetails'den çıkar
        setSelectedOrderDetails(selectedOrderDetails.filter(item => item.orderId !== order.orderId));
      } else {
        // orderId'ye göre selectedOrderIds'e ekle
        setSelectedOrderIds([...selectedOrderIds, order.orderId]);
        // Aynı zamanda sipariş detaylarını selectedOrderDetails'e ekle
        setSelectedOrderDetails([
          ...selectedOrderDetails,
          // url null ise boş string kullan
          { url: order.shipentegra_label || '', orderId: order.orderId }
        ]);
      }
    }
  };

  const handleOrderSelection = (order: Order) => {
    setSelectedOrders([order]);
    setOpenArrangeOrder(true)
  };

  return (
    <>
      <div className="flex flex-col flex-shrink-0 bg-white rounded-xl p-5 shadow-lg sm:p-4 mt-10 relative">
        <div className="flow-root mb-4">
          <div className="relative -mx-4 -my-4 overflow-x-auto overflow-y-auto h-[70vh] scrollbar-hide">
            <div className="inline-block min-w-full align-middle">
              <table className="min-w-full divide-y divide-gray-300 table-auto">
                <thead>
                  <tr>
                    {tableHeader.map((item) => {
                      return (
                        <th
                          key={item.name}
                          className="sticky top-0 z-10 border-b border-gray-300 text-center bg-headerPrimary py-3.5 pl-4 pr-3 text-sm font-semibold text-white backdrop-blur backdrop-filter "
                          scope="col"
                        >
                          <p className="text-xs md:text-sm">{item.name}</p>
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                {isLoading ? (
                  <div className="w-full flex justify-center">
                    <DotLoaderSpinner loading={isLoading} />
                  </div>
                ) : (
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {data.map((seller: Order, idx) => {
                      return (
                        <tr
                          key={idx}
                          className={classNames(
                            // !session?.hasPackage && idx > 2 ? 'blur-sm' : 'blur-none',
                            selectedOrderIds?.includes(seller.orderId) ? 'bg-[#FFEDD5]' : 'even:bg-lightGray'
                          )}
                        >
                          <td className="py-4 pl-12 text-xs md:text-sm sm:pl-0 w-16 ">
                            <p className="text-center text-gray">
                              <input
                                id="choose"
                                aria-describedby="comments-description"
                                name="comments"
                                type="checkbox"
                                className="h-4 w-4 cursor-pointer rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                checked={selectedOrderIds?.includes(seller.orderId)}
                                onChange={() => handleRowSelectionChange(seller)}

                              />
                            </p>
                          </td>
                          <td className="whitespace-nowrap text-center px-12 py-4 text-xs  text-gray-500 flex flex-col items-start justify-center gap-2">
                            {seller.last_labelled &&
                              <>
                                <div className='rounded-lg bg-[#3C4790] p-2 text-white'>
                                  {seller.last_labelled}
                                </div>
                                <div className='rounded-lg bg-[#A5F3FC] p-2 text-headerPrimary flex flex-row items-center justify-center gap-1'>
                                  <TruckFast className='w-4 h-4' />
                                  {'Express'}
                                </div>
                              </>
                            }
                          </td>
                          <td className="whitespace-nowrap text-center px-12 py-4 text-xs  text-gray-500 ">
                            <p className='text-description'>{seller.name.slice(0, 20)}...</p>
                            {seller.count > 1 && <span className='text-rexRed font-semibold'>Çoklu</span>}
                          </td>
                          <td onClick={
                            () => handleOrderSelection(seller)
                          } className="cursor-pointer whitespace-nowrap text-center px-12 py-4 text-xs  text-[#0284C7] ">
                            <Printer className='w-4 h-4  inline-block mr-1' />
                            {seller.order_id}
                          </td>
                          <td className="whitespace-nowrap text-center px-12 py-4 text-xs  text-description ">
                            {seller.ship_to_name}
                          </td>
                          <td className="whitespace-nowrap text-center px-12 py-4 text-xs text-gray-500">
                            <div className='flex flex-row items-center justify-start gap-1'> {/* text-left sınıfı yerine justify-start kullanıldı */}
                              {/* NOTE: burasi se_courier.img olacak */}
                              {/* <span className='rounded-full text-white bg-[#292D32] p-1 font-semibold'> */}
                              <img className='max-h-[20px]' src={`/images/shipentegra/${seller.se_courier}.png`} alt="" />
                              {/* </span> */}
                              <div className='flex flex-col items-start justify-center gap-1'>
                                <div className='flex flex-row items-between justify-start gap-4'> {/* text-left sınıfı yerine justify-start kullanıldı */}
                                  <span className='text-description font-semibold'>
                                    {seller.se_courier}
                                  </span>
                                  {/* <span className='text-[#3C4790]'>
                                  <Clock className='w-4 h-4  inline-block mr-1' />
                                  Geçmiş
                                </span> */}
                                </div>
                                <span className='text-[#0284C7]'>
                                  {seller.se_tracking_number}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td className="whitespace-nowrap text-center px-12 py-4 text-xs  text-description ">
                            {seller.my_tracking_number}
                          </td>
                          <td className="whitespace-nowrap text-center px-12 py-4 text-xs  text-gray-500 ">
                            {seller.my_courier === 'shipentegra' && <img src="/images/shipentegra.svg" alt="carrier" className="h-full w-full" />}
                            {/* <span className=''>{seller.my_courier}</span> */}
                          </td>
                          <td className="whitespace-nowrap px-12 py-4 text-center text-xs md:text-sm text-gray-500">
                            <span className="flex items-center">
                              {/* <img src={languages[1].avatar} alt="" className="h-5 w-5 flex-shrink-0 rounded-full" /> */}
                              <img className="h-5 w-5 flex-shrink-0 rounded-full" alt="" src={`https://flagsapi.com/${seller.ship_to_country}/flat/64.png`}></img>
                              <span className="ml-1">{seller.ship_to_country}</span>
                            </span>
                          </td>
                          <td className="whitespace-nowrap text-center px-12 py-4 text-xs">
                            <span className={classNames(seller.count > 1 ? 'text-rexRed' : 'text-description', '')}> {seller.count}</span>
                          </td>
                          <td className="whitespace-nowrap text-center px-12 py-4 text-xs">
                            {/* <Edit className='w-5 h-5 text-[#3C4790] inline-block mr-1' /> */}
                            <span className='text-description'>{seller.declared_price}</span>
                          </td>
                          <td className="whitespace-nowrap text-center  text-description px-12 py-4 text-xs">
                            {seller.ship_by_date}
                          </td>
                          {/* <td className="whitespace-nowrap px-12 py-4 text-center text-xs ">
                          <span className="flex flex-col items-center gap-2">
                            <img src={'/assets/logos/etsyE.svg'} alt="" className="h-5 w-5 flex-shrink-0" />
                            <span className="text-description">JewelryUsDesign</span>
                          </span>
                        </td> */}
                          <td className="whitespace-nowrap text-center  text-description px-12 py-4 text-xs">
                            {seller.creationDate}
                          </td>
                          <td className="whitespace-nowrap text-center  text-description px-12 py-4 text-xs">
                            {seller.sku}
                          </td>
                          <td className="whitespace-nowrap text-center  text-description px-12 py-4 text-xs">
                            {seller.my_note !== null ? seller.my_note
                              :
                              // <Edit className='w-5 h-5 text-[#3C4790] inline-block mr-1' />
                              ''
                            }
                          </td>
                          <td className="whitespace-nowrap text-center px-12 py-4 text-xs  text-gray-500 ">
                            {seller.shipment_type === 1 ? 'ShipEntegra DDP' : seller.shipment_type === 2 ? 'DDU' : seller.shipment_type === 3 ? 'IOSS' : seller.shipment_type === 4 ? 'ShipEntegra IOSS' : ''}
                          </td>
                          {/* <td className="whitespace-nowrap px-12 py-4 text-center text-xs md:text-sm text-gray-500">

                        </td> */}
                          {/* <td className="whitespace-nowrap text-center px-12 py-4 text-xs">
                          <Tag className='w-5 h-5 text-[#3C4790] inline-block mr-1' />
                          <span className='text-description'>3</span>
                        </td> */}
                          <td className="py-4 pl-4 text-xs md:text-sm font-medium text-center text-gray-900 sm:pl-0 w-16">
                            {seller.image_url ? <Link
                              target="_blank"
                              to={`${seller.image_url}`}
                            >
                              <img
                                className="h-10 object-contain w-full"
                                src={seller.image_url ? seller.image_url : ''}
                                alt='sellerIcon'
                              />
                            </Link> : '-'}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                )}
              </table>
            </div>
          </div>
        </div>
        {session?.hasShipEntegraInt && (
          <Pagination
            setPage={setPage}
            queryString={queryString}
            page={page}
            totalPageCount={totalPageCount!}
            setInputPage={setInputPage}
            inputPage={inputPage}
          />
        )}
      </div>
      {selectedOrderIds && selectedOrderIds.length > 0 ? (
        selectedOrderIds.length === 1 ? (
          <CreateTicketModal getOrders={getOrders} openCreateTicket={openCreateTicket} setOpenCreateTicket={setOpenCreateTicket} selectedOrderIds={selectedOrderIds} />
        ) : (
          <CreateTicketModalMultiple getOrders={getOrders} openCreateTicket={openCreateTicket} setOpenCreateTicket={setOpenCreateTicket} selectedOrderIds={selectedOrderIds} />
        )
      ) : (
        '' // ya da istediğiniz başka bir durum, örneğin bir mesaj gösterebilirsiniz.
      )}
      <UpdateOrderModal getOrders={getOrders} openArrangeOrder={openArrangeOrder} setOpenArrangeOrder={setOpenArrangeOrder} selectedOrders={selectedOrders} />
    </>
  );
};

export default ShipEntegraOrdersTable;