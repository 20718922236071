import React, { useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import DropDocuments from '../tools/common/UploadMultiDocs';
import axios from 'axios';
import API_URL from '../../config';
import alertNotification from '../../utils/alertNotification';

interface Props {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  orderItemId: any;
  getOrders: () => void;
}
const DocumentUploadModal: React.FC<Props> = ({
  isModalOpen,
  setIsModalOpen,
  orderItemId,
  getOrders,
}) => {
  const [image, setImage] = React.useState<any>(null);

  let http = axios.create({
    headers: {
      'Content-type': 'multipart/form-data',
    },
    baseURL: API_URL,
    withCredentials: true,
  });

  const onSubmit = async () => {
    let formData = new FormData();
    formData.append('image', image);
    try {
      await http.post(
        `/api/v1/marketplace/order/documents/${orderItemId.seller_order_items[0].id}`,
        formData
      );
      alertNotification('success', 'Dökuman eklendi');
      getOrders();
      setIsModalOpen(false);
    } catch (error: any) {
      alertNotification('error', error.response.data.message);
    }
  };
  useEffect(() => {
    // console.log(orderItemId);
  }, [orderItemId]);
  return (
    <Transition show={isModalOpen} as={React.Fragment}>
      <Dialog
        as="div"
        className="relative z-10 "
        onClose={() => setIsModalOpen(false)}
      >
        <Transition.Child
          as={React.Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-2xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <Dialog.Title
                  as="h3"
                  className="text-lg mb-8 font-medium text-center leading-6 text-gray-900"
                >
                  Siparişe ilişkin doküman yükleyin
                </Dialog.Title>
                <div className="flex items-center space-x-4 ">
                  <DropDocuments setImage={setImage} />
                </div>
                <div className="flex justify-end px-8">
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-[#2563EB] px-4 py-2 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                    onClick={onSubmit}
                  >
                    Onayla
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default DocumentUploadModal;
