import React, { useEffect, useRef, useState } from 'react';

import { USState } from '../../../interfaces/shipEntegraInterfaces';
import etsyToolsService from '../../../services/etsyToolsService';
import ShipEntegraCustomSelect from '../../shipentegraCreatePost/elements/ShipEntegraCustomSelect';
import { GermanStates, TurkishCities, USStates } from '../../shipentegraCreatePost/elements/states';

interface QuestionProps {
    handleForward: (question: string, answer: { country: string, state: string }) => void
    handleBackward: (question: string, answer: { country: string, state: string }) => void
}
const Question51: React.FC<QuestionProps> = ({
    handleForward,
    handleBackward
}) => {
    const [states, setStates] = useState<USState[]>(USStates)
    const [selectedState, setSelectedState] = useState<USState>({ name: '', value: '' })
    const [countries, setCountries] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState({
        id: 0,
        name: ''
    })

    const [answer, setAnswer] = useState(
        {
            country: selectedCountry.name,
            state: selectedState.name
        }
    );
    const inputRef = useRef<HTMLInputElement>(null);
    const handleInputChange = () => {
        const inputValue = inputRef.current?.value || "";
        setAnswer((prevAnswer) => ({
            ...prevAnswer,
            state: inputValue
        }));
    };
    const getCountries = () => {
        etsyToolsService.getCountries().then((res) => {
            setCountries(res.data);
        });
    };

    useEffect(() => {
        if (selectedCountry.name === 'United States') {
            setStates(USStates)
        } else if (selectedCountry.name === 'Turkey') {
            setStates(TurkishCities)
        } else if (selectedCountry.name === 'Germany') {
            setStates(GermanStates)
        }
    }, [selectedCountry])
    // Listen for changes in selectedCountry and selectedState
    useEffect(() => {
        // Update the answer state when selectedCountry or selectedState changes
        setAnswer((prevAnswer) => ({
            ...prevAnswer,
            state: selectedState.name
        }));
    }, [selectedState]);
    useEffect(() => {
        // Update the answer state when selectedCountry or selectedState changes
        setAnswer({
            country: selectedCountry.name,
            state: ''
        });
    }, [selectedCountry]);

    // Update session storage when the answer state changes
    useEffect(() => {
        sessionStorage.setItem("answer51", JSON.stringify(answer));
    }, [answer]);

    useEffect(() => {
        getCountries();
    }, []);

    return (
        <>
            <div>
                <div className="flex flex-col space-y-4 min-h-[400px] justify-between">
                    <div className='mt-2'>
                        <label htmlFor="country" className="text-base font-semibold text-gray-900">
                            Yaşadığınız Ülke
                        </label>
                        <div className="w-full mt-2 mb-10">
                            {countries && countries.length > 0 && (
                                <ShipEntegraCustomSelect
                                    id="country"
                                    options={countries}
                                    onSelect={(selectedCountry: { countryId: number, name: string }) => {
                                        console.log(selectedCountry)
                                        setSelectedCountry({
                                            id: selectedCountry.countryId,
                                            name: selectedCountry.name
                                        })
                                    }}
                                />
                            )}
                        </div>
                        <label htmlFor="state" className="block text-sm font-medium text-gray-700">
                            {selectedCountry.name === 'United States' ? 'Eyalet' : 'Şehir'}
                        </label>
                        {selectedCountry.name === 'United States'
                            || selectedCountry.name === 'Turkey'
                            || selectedCountry.name === 'Germany'
                            ?
                            <ShipEntegraCustomSelect
                                id="state"
                                options={states ? states : []}
                                onSelect={(selectedState: any) => {
                                    setSelectedState(selectedState)
                                }}
                            /> :
                            <input
                                ref={inputRef}
                                onChange={handleInputChange}
                                type="state"
                                name="state"
                                id="state"
                                disabled={!selectedCountry.name}
                                className="block w-full rounded-lg border-sm border-cardBorder px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-cardBorder placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cardBorder sm:text-sm sm:leading-6"
                                placeholder={'Şehir'}
                            />
                        }
                    </div>
                    <div className="flex items-center text-[16px] bg justify-between mt-2">
                        <div
                            onClick={() => { handleBackward("question51", answer) }}
                            className=" text-center text-[13px] px-3 h-[50px] max-h-[50px] font-bold  lg:py-1  w-[49%] flex items-center w-full cursor-pointer">Geri</div>
                        <div
                            onClick={() => { handleForward("question51", answer) }}
                            className="hover:cursor-pointer text-center text-[12px] bg-headerPrimary font-bold text-white h-[50px] max-h-[50px]  px-1 py-1  border-2 rounded-lg border-headerPrimary w-[49%] flex items-center justify-center"
                        >
                            İleri
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Question51;

