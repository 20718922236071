import React, { useState } from 'react';
import memberService from '../../services/memberService';
// import ProvidersCard from './ProvidersCard';
import CategorySelection from './CategorySelection';
import ProviderCard from './ProviderCard';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../../pages/errors/ErrorFallback';
import { useQuery } from '@tanstack/react-query';
import { IRootAllServices } from '../../interfaces/serviceProviders';

export interface Provider {
  service_name: string;
  id: number;
  img_path: string;
  order: number;
  min_service_fee: number;
  max_service_fee: number;
  cat_id: number;
}
// const providers = [
//   {
//     service_name: 'Trendyol',
//     id: 3,
//     img_path: '/images/amazon-arbitraj.webp',
//     order: 5,
//     min_service_fee: 1000,
//     max_service_fee: 1500,
//   },
//   {
//     service_name: 'Amazon Hesap Açma',
//     id: 3,
//     img_path: '/images/amazon-arbitraj.webp',
//     order: 5,
//     min_service_fee: 2000,
//     max_service_fee: 5500,
//   },
//   {
//     service_name: 'Etsy Hesap Açma',
//     id: 3,
//     img_path: '/images/amazon-arbitraj.webp',
//     order: 5,
//     min_service_fee: 2000,
//     max_service_fee: 5500,
//   },
//   {
//     service_name: 'Dijital PAzarlama - SEO',
//     id: 3,
//     img_path: '/images/amazon-arbitraj.webp',
//     order: 5,
//     min_service_fee: 1000,
//     max_service_fee: 2500,
//   },
//   {
//     service_name: 'Ürün Fotoğrafı',
//     id: 3,
//     img_path: '/images/amazon-arbitraj.webp',
//     order: 5,
//     min_service_fee: 1000,
//     max_service_fee: 2500,
//   },
//   {
//     service_name: 'Shopify',
//     id: 3,
//     img_path: '/images/amazon-arbitraj.webp',
//     order: 5,
//     min_service_fee: 1000,
//     max_service_fee: 2500,
//   },
// ];

const ProvidersCardsWrapper = () => {
  const [selectedCategory, setSelectedCategory] = useState<number | string>('');

  const { data } = useQuery<IRootAllServices, Error>(
    ['allServiceProviders', selectedCategory],
    {
      queryFn: () => memberService.getServiceProviders(selectedCategory!),
      staleTime: Infinity,
    }
  );
  return (
    <div className="w-full flex flex-col justify-center items-center">
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <CategorySelection
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
      </ErrorBoundary>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <div
          data-testid="service-providers"
          className="grid gap-4 p-1 w-full overflow-hidden text-center grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4  min-[1500px]:grid-cols-6 rounded-2xl"
        >
          {data?.data.map((provider, index) => (
            <ProviderCard provider={provider} key={provider.id} />
          ))}
        </div>
      </ErrorBoundary>
    </div>
  );
};

export default ProvidersCardsWrapper;
