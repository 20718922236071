import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { DocumentDownload } from 'iconsax-react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import amazonToolsService from '../../../../services/amazonToolsService';
import { useQuery } from '@tanstack/react-query';
import LoadingComponent from '../../../common/LoadingComponent';
import { useCallback } from 'react';
import { utils, writeFile } from 'xlsx';

interface Props {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  keyword: string;
}

const RelatedKeywordsModal: React.FC<Props> = ({ open, setOpen, keyword }) => {
  const { data, isLoading } = useQuery<any, Error>(['keywordRivals', keyword], {
    queryFn: () => amazonToolsService.getKeywordRelated(keyword),
    staleTime: Infinity,
    enabled: !!keyword,
  });

  const exportFile = useCallback(() => {
    /* generate worksheet from state */

    const ws = utils.json_to_sheet(data?.data?.keywords);
    /* create workbook and append worksheet */
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, 'Data');

    /* export to XLSX */
    writeFile(wb, 'SheetJSReactAoO.xlsx');
  }, [data?.data?.keywords]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div className="w-full flex justify-end">
                  <button
                    type="button"
                    className="rounded-md bg-white text-headerPrimary hover:text-gray-500 "
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="p-4 flex flex-col w-full">
                  <div className="w-full flex justify-end items-center">
                    <button
                      className="bg-headerPrimary text-white px-4 py-2 rounded-lg border-none flex justify-center items-center space-x-2"
                      onClick={exportFile}
                    >
                      <p>Excel'e İndir</p>
                      <DocumentDownload color="white" className="w-5 h-5" />
                    </button>
                  </div>
                  <div className="flex flex-col mt-4 h-[40vh] -mr-4 overflow-y-auto">
                    <div className="flex justify-between items-center mt-4">
                      <p className="font-medium text-lg text-headerPrimary">
                        Anahtar Kelime
                      </p>
                      <p className="font-medium text-lg text-headerPrimary pr-4">
                        Hacim
                      </p>
                    </div>
                    {isLoading ? (
                      <LoadingComponent />
                    ) : (
                      data?.data?.keywords.map((item: any) => {
                        return (
                          <div className="flex justify-between items-center mt-4">
                            <p className="text-sm">{item.term}</p>
                            <p className="text-sm pr-4">{item.volume}</p>
                          </div>
                        );
                      })
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default RelatedKeywordsModal;
