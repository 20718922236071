import { Message, MessageText1 } from 'iconsax-react';
import React, { Fragment, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/24/outline';

import DotLoaderSpinner from '../components/common/DotLoaderSpinner';
import LoadingComponent from '../components/common/LoadingComponent';
import useMultiplier from '../hooks/useMultiplier';
import { IMarketplaceProducts, ISupplierproductvariant } from '../interfaces/marketplaceInterfaces';
import marketplaceService from '../services/marketplaceService';
import alertNotification from '../utils/alertNotification';
import { classNames } from '../utils/conditionalClasses';

// http://localhost:8000/api/v1/marketplace/cargo-predict?country=US&carrier=shipentegra&weight=1&productId=1
const COUNTRIES = [
  { code: 'US', name: 'Amerika Birleşik Devletleri' },
  { code: 'CA', name: 'Kanada' },
  { code: 'AU', name: 'Avustralya' },
  { code: 'DE', name: 'Almanya' },
  { code: 'FR', name: 'Fransa' },
  { code: 'IT', name: 'İtalya' },
  { code: 'ES', name: 'İspanya' },
  { code: 'NL', name: 'Hollanda' },
  { code: 'AF', name: 'Afganistan' },
  { code: 'AD', name: 'Andora' },
  { code: 'AI', name: 'Angila' },
  { code: 'AO', name: 'Angola' },
  { code: 'AG', name: 'Antigua ve Barbuda' },
  { code: 'AR', name: 'Arjantin' },
  { code: 'AL', name: 'Arnavutluk' },
  { code: 'AW', name: 'Aruba' },
  { code: 'AT', name: 'Avusturya' },
  { code: 'AZ', name: 'Azerbaycan' },
  { code: 'BS', name: 'Bahamalar' },
  { code: 'BH', name: 'Bahreyn' },
  { code: 'BD', name: 'Bangladeş' },
  { code: 'BB', name: 'Barbados' },
  { code: 'BE', name: 'Belçika' },
  { code: 'BZ', name: 'Belize' },
  { code: 'BJ', name: 'Benin' },
  { code: 'BM', name: 'Bermuda' },
  { code: 'BT', name: 'Bhutan' },
  { code: 'AE', name: 'Birleşik Arap Emirlikleri' },
  { code: 'BO', name: 'Bolivya' },
  { code: 'AN', name: 'Bonaire' },
  { code: 'BA', name: 'Bosna-Hersek' },
  { code: 'BW', name: 'Botswana' },
  { code: 'BR', name: 'Brezilya' },
  { code: 'BN', name: 'Brunei' },
  { code: 'BG', name: 'Bulgaristan' },
  { code: 'BF', name: 'Burkina Faso' },
  { code: 'BI', name: 'Burundi' },
  { code: 'CV', name: 'Cape Verde Adaları' },
  { code: 'KY', name: 'Cayman Adaları' },
  { code: 'GI', name: 'Cebelitarık' },
  { code: 'DZ', name: 'Cezayir' },
  { code: 'DJ', name: 'Cibuti' },
  { code: 'CK', name: 'Cook Adaları' },
  { code: 'TD', name: 'Çad' },
  { code: 'CZ', name: 'Çek Cumhuriyeti' },
  { code: 'CN', name: 'Çin Halk Cumhuriyeti' },
  { code: 'DK', name: 'Danimarka' },
  { code: 'TL', name: 'Doğu Timor' },
  { code: 'DO', name: 'Dominik Cumhuriyeti' },
  { code: 'DM', name: 'Dominika' },
  { code: 'EC', name: 'Ekvator' },
  { code: 'GQ', name: 'Ekvator Ginesi' },
  { code: 'SV', name: 'El Salvador' },
  { code: 'ID', name: 'Endonezya' },
  { code: 'ER', name: 'Eritre' },
  { code: 'AM', name: 'Ermenistan' },
  { code: 'EE', name: 'Estonya' },
  { code: 'ET', name: 'Etiyopya' },
  { code: 'FO', name: 'Faroe Adaları' },
  { code: 'MA', name: 'Fas' },
  { code: 'FJ', name: 'Fiji Adası' },
  { code: 'PH', name: 'Filipinler' },
  { code: 'FI', name: 'Finlandiya' },
  { code: 'GF', name: 'Fransız Guyanası' },
  { code: 'PF', name: 'Fransız Polinezyası' },
  { code: 'GA', name: 'Gabon' },
  { code: 'GM', name: 'Gambiya' },
  { code: 'GH', name: 'Gana' },
  { code: 'PS', name: 'Gaza (Batı Şeria)' },
  { code: 'GN', name: 'Gine' },
  { code: 'GW', name: 'Gine-Bissau' },
  { code: 'GD', name: 'Grenada' },
  { code: 'GL', name: 'Grönland' },
  { code: 'GP', name: 'Guadelup' },
  { code: 'GU', name: 'Guam' },
  { code: 'GT', name: 'Guatemala' },
  { code: 'GY', name: 'Guyana' },
  { code: 'ZA', name: 'Güney Afrika' },
  { code: 'GE', name: 'Gürcistan' },
  { code: 'HT', name: 'Haiti' },
  { code: 'HR', name: 'Hırvatistan' },
  { code: 'IN', name: 'Hindistan' },
  { code: 'HN', name: 'Honduras' },
  { code: 'HK', name: 'Hong Kong' },
  { code: 'IQ', name: 'Irak' },
  { code: 'VG', name: 'İngiliz Virgin Adaları' },
  { code: 'GB', name: 'İngiltere' },
  { code: 'IE', name: 'İrlanda Cumhuriyeti' },
  { code: 'IL', name: 'İsrail' },
  { code: 'SE', name: 'İsveç' },
  { code: 'CH', name: 'İsviçre' },
  { code: 'IS', name: 'İzlanda' },
  { code: 'JM', name: 'Jamaika' },
  { code: 'JP', name: 'Japonya' },
  { code: 'KH', name: 'Kamboçya' },
  { code: 'CM', name: 'Kamerun' },
  { code: 'ME', name: 'Karadağ' },
  { code: 'QA', name: 'Katar' },
  { code: 'KZ', name: 'Kazakistan' },
  { code: 'KE', name: 'Kenya' },
  { code: 'CY', name: 'Kıbrıs' },
  { code: 'KG', name: 'Kırgızistan' },
  { code: 'KI', name: 'Kiribati' },
  { code: 'CO', name: 'Kolombiya' },
  { code: 'CG', name: 'Kongo' },
  { code: 'CD', name: 'Kongo Halk Cumhuriyeti' },
  { code: 'KR', name: 'Kore, Güney' },
  { code: 'CR', name: 'Kosta Rika' },
  { code: 'KW', name: 'Kuveyt' },
  { code: 'MP', name: 'Kuzey Mariana Adaları' },
  { code: 'LA', name: 'Laos' },
  { code: 'LS', name: 'Lesotho' },
  { code: 'LV', name: 'Letonya' },
  { code: 'LR', name: 'Liberya' },
  { code: 'LY', name: 'Libya' },
  { code: 'LI', name: 'Liechtenstein' },
  { code: 'LT', name: 'Litvanya' },
  { code: 'LB', name: 'Lübnan' },
  { code: 'LU', name: 'Lüksemburg' },
  { code: 'HU', name: 'Macaristan' },
  { code: 'MO', name: 'Macau' },
  { code: 'MG', name: 'Madagaskar' },
  { code: 'MK', name: 'Makedonya (FYROM)' },
  { code: 'MW', name: 'Malavi' },
  { code: 'MV', name: 'Maldiv Adaları' },
  { code: 'MY', name: 'Malezya' },
  { code: 'ML', name: 'Mali' },
  { code: 'MT', name: 'Malta' },
  { code: 'MH', name: 'Marshall Adaları' },
  { code: 'MQ', name: 'Martinik' },
  { code: 'MU', name: 'Mauritius' },
  { code: 'YT', name: 'Mayotte' },
  { code: 'MX', name: 'Meksika' },
  { code: 'EG', name: 'Mısır' },
  { code: 'FM', name: 'Mikronezya (Mikronezya, Federe Devletleri)' },
  { code: 'MN', name: 'Moğolistan' },
  { code: 'MD', name: 'Moldova' },
  { code: 'MC', name: 'Monako' },
  { code: 'MS', name: 'Montserrat' },
  { code: 'MR', name: 'Moritanya' },
  { code: 'MZ', name: 'Mozambik' },
  { code: 'MM', name: 'Myanmar' },
  { code: 'NA', name: 'Namibya' },
  { code: 'NP', name: 'Nepal' },
  { code: 'KN', name: 'Nevis (St. Kitts)' },
  { code: 'NE', name: 'Nijer' },
  { code: 'NG', name: 'Nijerya' },
  { code: 'NI', name: 'Nikaragua' },
  { code: 'NO', name: 'Norveç' },
  { code: 'CF', name: 'Orta Afrika Cumhuriyeti' },
  { code: 'UZ', name: 'Özbekistan' },
  { code: 'PK', name: 'Pakistan' },
  { code: 'PW', name: 'Palau' },
  { code: 'PA', name: 'Panama' },
  { code: 'PG', name: 'Papua Yeni Gine' },
  { code: 'PY', name: 'Paraguay' },
  { code: 'PE', name: 'Peru' },
  { code: 'PL', name: 'Polonya' },
  { code: 'PT', name: 'Portekiz (Azor ve Madeira hariç)' },
  { code: 'PR', name: 'Porto Riko' },
  { code: 'RE', name: 'Reunion' },
  { code: 'RO', name: 'Romanya' },
  { code: 'RW', name: 'Ruanda' },
  { code: 'WS', name: 'Samoa' },
  { code: 'SM', name: 'San Marino' },
  { code: 'SN', name: 'Senegal' },
  { code: 'SC', name: 'Seysel Adaları' },
  { code: 'RS', name: 'Sırbistan' },
  { code: 'SL', name: 'Sierra Leone' },
  { code: 'SG', name: 'Singapur' },
  { code: 'SK', name: 'Slovak Cumhuriyeti' },
  { code: 'SI', name: 'Slovenya' },
  { code: 'SB', name: 'Solomon Adaları' },
  { code: 'LK', name: 'Sri Lanka' },
  { code: 'BL', name: 'St. Barthelemy' },
  { code: 'LC', name: 'St. Lucia' },
  { code: 'VC', name: 'St. Vincent ve Grenadines' },
  { code: 'SR', name: 'Surinam' },
  { code: 'SA', name: 'Suudi Arabistan' },
  { code: 'SZ', name: 'Swaziland' },
  { code: 'CL', name: 'Şili' },
  { code: 'TJ', name: 'Tacikistan' },
  { code: 'TZ', name: 'Tanzanya' },
  { code: 'TH', name: 'Tayland' },
  { code: 'TW', name: 'Tayvan' },
  { code: 'TG', name: 'Togo' },
  { code: 'TO', name: 'Tonga' },
  { code: 'TT', name: 'Trinidad ve Tobago' },
  { code: 'TN', name: 'Tunus' },
  { code: 'TC', name: 'Turks ve Caicos Adaları' },
  { code: 'TV', name: 'Tuvalu' },
  { code: 'TM', name: 'Türkmenistan' },
  { code: 'UG', name: 'Uganda' },
  { code: 'UA', name: 'Ukrayna' },
  { code: 'OM', name: 'Umman' },
  { code: 'UY', name: 'Uruguay' },
  { code: 'JO', name: 'Ürdün' },
  { code: 'VU', name: 'Vanuatu' },
  { code: 'VE', name: 'Venezuela' },
  { code: 'VN', name: 'Vietnam' },
  { code: 'VI', name: 'Virgin Adaları ABD' },
  { code: 'WF', name: 'Wallis ve Futuna Adaları' },
  { code: 'YE', name: 'Yemen Cumhuriyeti' },
  { code: 'NC', name: 'Yeni Kaledonya' },
  { code: 'NZ', name: 'Yeni Zelanda' },
  { code: 'GR', name: 'Yunanistan' },
  { code: 'ZM', name: 'Zambiya' },
  { code: 'ZW', name: 'Zimbabve' },
];
const MarketplaceProductDetails = () => {
  const multiplier = 1.2
  const [countryCode, setCountryCode] = useState('US');
  const [countryName, setCountryName] = useState(
    COUNTRIES.find((country) => country.code === 'US')?.name || 'Amerika Birleşik Devletleri'
  );
  const [cargoPrice, setCargoPrice] = useState<{
    predictedCargoPrice: number,
    currency: string
  }>()
  const [product, setProduct] = useState<IMarketplaceProducts | undefined>(
    undefined
  );
  const [loading, setLoading] = useState(false);
  const [cargoLoader, setCargoLoader] = useState(false);
  const [selectedVariationId, setSelectedVariationId] = useState('');
  const [selectedVariation, setSelectedVariation] = useState<any>();
  const [quantity, setQuantity] = useState(1);
  const [discountRate, setDiscountRate] = useState(0);
  const [discountedPrice, setDiscountedPrice] = useState<number>(0);
  const [selectedImg, setSelectedImg] = useState<string>()
  const { id } = useParams();
  const getMarketplaceProduct = async () => {
    setLoading(true);
    try {
      const res = await marketplaceService.getMarketplaceProduct(Number(id));
      setSelectedVariation(res.data.data?.supplier_product_variants[0]);
      setProduct(res.data.data);
      setLoading(false);
    } catch (error: any) {
      alertNotification('error', error.response.data.message);
    }
  };
  useEffect(() => {
    getMarketplaceProduct();
  }, [id]);

  useEffect(() => {
    if (product) {
      const rate = Math.min(Math.floor(quantity / 50), 10); // Maksimum %10 indirim
      setDiscountRate(rate);
      const newPrice =
        Number(
          Number(product?.supplier_product_variants[0]?.price) -
          Number(Number(product?.supplier_product_variants[0]?.price) * rate)
        ) / 100;
      setDiscountedPrice(newPrice * quantity);
    }
  }, [quantity, product]);

  const getCargoPrice = () => {
    setCargoLoader(true)
    console.log('selectedVariation', selectedVariation)
    marketplaceService.getCargoPrice(
      {
        country: countryCode,
        carrier: selectedVariation?.carrier,
        weight: +selectedVariation?.weightValue,
        productId: +id!,
        productVariantId: Number(selectedVariation?.id)
      }
    )
      .then((res) => {
        setCargoPrice(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
      .finally(() => {
        setCargoLoader(false)
      })
  }
  useEffect(() => {
    if (selectedVariation) {
      getCargoPrice()
    }
  }, [countryCode, selectedVariation])

  if (!product) {
    return (
      <div>
        <LoadingComponent />
      </div>
    );
  }

  return (
    <div className="flex flex-col xl:flex-row  bg-white rounded-xl p-4 gap-4  justify-center items-start">
      <div className="flex-1 grid grid-cols-1 md:grid-cols-12 item-center justify-center gap-8">
        <div className="col-span-1 md:col-span-12 w-auto max-h-[500px]">
          <img
            className="w-full h-full aspect-square object-cover rounded-xl"
            src={`https://api.rexven.com/images/marketplace/supplier-images/${selectedImg ? selectedImg : product?.images[0]}`}
            alt="product"
          />
        </div>
        <div className="col-span-1 md:col-span-12 grid grid-cols-3 md:grid-cols-12 gap-3 items-center justify-start mt-4 max-w-full overflow-hidden">
          {product?.images?.map((image: string, index) => (
            <img
              key={index}
              onClick={() => setSelectedImg(image)}
              className="aspect-square rounded-lg object-cover w-full h-auto col-span-1 md:col-span-2 cursor-pointer"
              src={`https://api.rexven.com/images/marketplace/supplier-images/${image}`}
              alt=""
            />
          ))}
        </div>
      </div>
      <div className="flex-1 p-4 shadow-md rounded-lg">
        <h2 className="text-lg md:text-2xl font-bold">{product?.title}</h2>
        <p className='text-[18px] text-[#111827] mt-4'>SKU: {selectedVariation?.sku}</p>
        {product?.supplier_product_variants?.length > 1 &&
          <p className='text-[14px] text-[#374151] mt-4'>Varyasyonlar: </p>}
        {product?.supplier_product_variants?.length > 1 && (
          <div className="mb-4 rounded-lg pr-4">
            <label
              htmlFor="variation"
              className="block text-lg font-medium text-gray-700"
            >
              {product?.attributes?.length > 0 && 'Varyasyon Seçiniz'}
            </label>
            <select
              id="variation"
              value={selectedVariationId}
              onChange={(e) => {
                setSelectedVariationId(e.target.value);
                setSelectedVariation(
                  product?.supplier_product_variants?.find(
                    (item) => item.id === e.target.value
                  )
                );
              }}
              className="mt-2 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            >
              {product?.supplier_product_variants?.map((variant) => (
                <option key={variant?.id} value={variant?.id}>
                  {variant?.name}
                </option>
              ))}
            </select>
          </div>
        )}
        {/* Variaton Prices  */}
        <div className="grid grid-cols-12 gap-4 my-4 pr-4 flex items-center">
          <div className="col-span-12 md:col-span-4 flex flex-col items-start md:items-center justify-center">
            <p className="text-xs font-semibold text-[#4B5563]">
              Ürün Fiyatı
            </p>
            <span className="text-xl text-[#4B5563] font-bold">
              {`$ ${(Number(selectedVariation?.price ? selectedVariation?.price : 1) * multiplier).toFixed(2)}`}
            </span>
          </div>
          <div className="col-span-12 md:col-span-4 flex flex-col items-start md:items-center justify-center">
            <p className="text-xs font-semibold text-[#4B5563]">
              Plus Üye Fiyatı
            </p>
            <span className="text-xl text-[#4B5563] font-bold">
              {`$ ${(
                Number(Number(selectedVariation?.price ? selectedVariation?.price : 1).toFixed(2)) * 1.15
              ).toFixed(2)}`}
            </span>
          </div>
          <div className="col-span-12 md:col-span-4 flex flex-col items-start md:items-center justify-center">
            <p className="text-xs font-semibold text-[#4B5563]">
              Premium Üye Fiyatı
            </p>
            <span className="text-xl text-[#4B5563] font-bold">
              {`$ ${(
                Number(Number(selectedVariation?.price ? selectedVariation?.price : 1).toFixed(2)) * 1.1
              ).toFixed(2)}`}

            </span>
          </div>
        </div>
        {/* Cargo select and prices */}
        <div className="col-span-12">
          <label
            className="w-full text-[14px] text-[#374151] mb-4"
            htmlFor="country"
          >
            Gönderilecek Ülke
          </label>

          <Listbox
            value={countryCode}
            onChange={(code) => {
              const selectedCountry = COUNTRIES.find((country) => country.code === code);
              setCountryCode(code);
              setCountryName(selectedCountry ? selectedCountry.name : 'US');
            }}
          >
            {({ open }) => (
              <>
                <div className="relative w-full">
                  <Listbox.Button className="relative w-full cursor-default rounded-md bg-white pl-3 pr-10  pt-2.5 pb-2 text-xs min-[1335px]:text-sm text-left text-gray-900 shadow-sm ring-1 ring-inset ring-cardBorder focus:outline-none ">
                    <span className="block truncate">{countryName}</span>
                    <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                      <ChevronDownIcon
                        className="h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </span>
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-20 border-2 border-cardBorder mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-xs shadow-lg ring-1 ring-black ring-opacity-5 focus:ring-secondary focus:outline-none sm:text-sm">
                      <Listbox.Option
                        key={98989898}
                        className={({ active }) =>
                          classNames(
                            active
                              ? 'bg-secondary text-white'
                              : 'text-gray-900',
                            'relative cursor-default select-none py-2 pl-3 pr-9'
                          )
                        }
                        value={''}
                      >
                        {({ selected, active }) => (
                          <>
                            {selected ? (
                              <span
                                className={classNames(
                                  active ? 'text-white' : 'text-indigo-600',
                                  'absolute inset-y-0 right-0 flex items-center pr-4'
                                )}
                              >
                                <CheckIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                      {COUNTRIES &&
                        COUNTRIES?.map((country: any) => (
                          <Listbox.Option
                            key={country.code}
                            className={({ active }) =>
                              classNames(
                                active
                                  ? 'bg-secondary text-white'
                                  : 'text-gray-900',
                                'relative cursor-default select-none py-2 pl-3 pr-9'
                              )
                            }
                            value={country.code}
                          >
                            {({ selected, active }) => (
                              <>
                                <span
                                  className={classNames(
                                    selected
                                      ? 'font-semibold'
                                      : 'font-normal',
                                    'block truncate'
                                  )}
                                >
                                  {country.name}
                                </span>

                                {selected ? (
                                  <span
                                    className={classNames(
                                      active
                                        ? 'text-white'
                                        : 'text-indigo-600',
                                      'absolute inset-y-0 right-0 flex items-center pr-4'
                                    )}
                                  >
                                    <CheckIcon
                                      className="h-5 w-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div>
        <div className="grid grid-cols-12 gap-4 my-4 pr-4 flex items-center">
          <div className="col-span-12 md:col-span-4 flex flex-col items-start md:items-center justify-center">
            <p className="text-xs font-semibold text-[#4B5563]">
              Kargo Fiyatı
            </p>
            <span className="text-xl text-[#4B5563] font-bold">
              {cargoLoader ? <DotLoaderSpinner size={25} loading={cargoLoader} />
                :
                `$ ${(Number(cargoPrice?.predictedCargoPrice ? cargoPrice?.predictedCargoPrice : 1) * multiplier).toFixed(2)}`
              }
            </span>
          </div>
          <div className="col-span-12 md:col-span-4 flex flex-col items-start md:items-center justify-center">
            <p className="text-xs font-semibold text-[#4B5563]">
              Plus Kargo Fiyatı
            </p>
            <span className="text-xl text-[#4B5563] font-bold">
              {cargoLoader ? <DotLoaderSpinner size={25} loading={cargoLoader} />
                :
                `$ ${(
                  Number(Number(cargoPrice?.predictedCargoPrice ? cargoPrice?.predictedCargoPrice : 1).toFixed(2)) * 1.15
                ).toFixed(2)}`
              }

            </span>
          </div>
          <div className="col-span-12 md:col-span-4 flex flex-col items-start md:items-center justify-center">
            <p className="text-xs font-semibold text-[#4B5563]">
              Premium Kargo Fiyatı
            </p>
            <span className="text-xl text-[#4B5563] font-bold">
              {cargoLoader ? <DotLoaderSpinner size={25} loading={cargoLoader} />
                :
                `$ ${(
                  Number(Number(cargoPrice?.predictedCargoPrice ? cargoPrice?.predictedCargoPrice : 1).toFixed(2)) * 1.1
                ).toFixed(2)}`
              }

            </span>
          </div>
        </div>
        {/* Total Prices */}
        <div className="grid grid-cols-12 gap-4 my-4 pr-4 flex items-center">
          <div className="col-span-12 md:col-span-4 flex flex-col items-center justify-center p-[20px] xl:p-[6px] 2xl:p-[20px]  flex-1 border-2 border-[#4B5563] rounded-lg min-h-[170px] ">
            <p className="text-xs font-semibold text-[#374151] text-center">
              Toplam <br /> Ürün Fiyatı
            </p>
            <span className="text-xl text-[#374151] font-bold">
              {cargoLoader ? <DotLoaderSpinner size={25} loading={cargoLoader} />
                :
                `$  ${(Number(Number(selectedVariation?.price ? selectedVariation?.price : 1) * multiplier)
                  +
                  Number(Number(cargoPrice?.predictedCargoPrice ? cargoPrice?.predictedCargoPrice : 1) * multiplier)).toFixed(2)}`
              }

            </span>
          </div>
          <div className="col-span-12 md:col-span-4 flex flex-col items-center justify-center p-[20px] xl:p-[6px] 2xl:p-[20px]  flex-1 border-2 border-[#1E3A8A]  rounded-lg min-h-[170px] ">
            <p className="text-xs font-semibold text-[#1E3A8A] text-center">
              Toplam <br /> Plus Üye Fiyatı
            </p>
            <span className="text-xl text-[#1E3A8A] font-bold">
              {cargoLoader ? <DotLoaderSpinner size={25} loading={cargoLoader} />
                :
                `$ ${((Number(Number(selectedVariation?.price ? selectedVariation?.price : 1).toFixed(2)) * 1.15)
                  +
                  (Number(Number(cargoPrice?.predictedCargoPrice ? cargoPrice?.predictedCargoPrice : 1).toFixed(2)) * 1.15
                  )).toFixed(2)}`
              }
            </span>
            {!cargoLoader &&
              <span className='text-xs text-[#059669]'>
                Kâr:{`$ ${((Number(Number(selectedVariation?.price ? selectedVariation?.price : 1) * multiplier) + Number(Number(cargoPrice?.predictedCargoPrice ? cargoPrice?.predictedCargoPrice : 1) * multiplier))
                  -
                  ((Number(Number(selectedVariation?.price ? selectedVariation?.price : 1).toFixed(2)) * 1.15)
                    +
                    (Number(Number(cargoPrice?.predictedCargoPrice ? cargoPrice?.predictedCargoPrice : 1).toFixed(2)) * 1.15
                    ))).toFixed(2)
                  }`}
              </span>}
          </div>
          <div className="col-span-12 md:col-span-4 flex flex-col items-center justify-center p-[20px] xl:p-[6px] 2xl:p-[20px]  flex-1 border-2 border-[#2563EB] rounded-lg min-h-[170px] ">
            <p className="text-xs font-semibold text-[#2563EB] text-center">
              Toplam <br /> Premium Üye Fiyatı
            </p>
            <span className="text-xl text-[#2563EB] font-bold">
              {cargoLoader ? <DotLoaderSpinner size={25} loading={cargoLoader} />
                :
                `$ ${((Number(Number(selectedVariation?.price ? selectedVariation?.price : 1).toFixed(2)) * 1.1)
                  +
                  (Number(Number(cargoPrice?.predictedCargoPrice ? cargoPrice?.predictedCargoPrice : 1).toFixed(2)) * 1.1)).toFixed(2)}`
              }
            </span>
            {!cargoLoader &&
              <span className='text-xs text-[#059669]'>
                Kâr: {`$ ${((Number(Number(selectedVariation?.price ? selectedVariation?.price : 1) * multiplier) + Number(Number(cargoPrice?.predictedCargoPrice ? cargoPrice?.predictedCargoPrice : 1) * multiplier))
                  -
                  ((Number(Number(selectedVariation?.price ? selectedVariation?.price : 1).toFixed(2)) * 1.1)
                    +
                    (Number(Number(cargoPrice?.predictedCargoPrice ? cargoPrice?.predictedCargoPrice : 1).toFixed(2)) * 1.1))).toFixed(2)
                  }`}
              </span>}
          </div>
        </div>
        <div className="grid grid-cols-12 gap-4 my-4 pr-4 flex items-center justify-center">
          <div className="col-span-12 md:col-span-8 text-sm">
            Bu ürünü satın almak için Rexven Tedarik Sistemine Katılın
          </div>
          <Link
            type="button"
            className="col-span-12 md:col-span-4 rounded-[12px]  bg-gradient-to-r from-[#4F46E5] to-[#2563EB] text-white py-2 px-8 font-bold text-md text-center"
            to={'https://api.whatsapp.com/send?phone=905352051963&text=Merhaba%20Yüz%20yüze%20Amazon%20ve%20Etsy%20kampı%20hakkında%20bilgi%20almak%20istiyorum'}
            target='_blank'
          >
            İletişim Kur
          </Link>
        </div>
        <div dangerouslySetInnerHTML={{ __html: product?.description }} className="my-4 flex items-center space-x-4">
        </div>
      </div>
    </div>
  );
};

export default MarketplaceProductDetails;
