import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useLanguage } from '../../context/LanguageContext';
import memberService from '../../services/memberService';
import AddressChangeForm from './AddressChangeForm';

export interface BillingInfo {
  title: string;
  addressLine1: string;
  addressLine2?: string | undefined;
  city: string;
  country: string;
  firstName: string;
  lastName: string;
  identityNumber: string;
  gsmNumber?: string;
  taxOffice?: string;
  vatNumber?: string;
  zipCode: string;
  companyName?: string;
  isCorporate?: boolean;
}
interface AddresInfoCardProps {
  setDisablePayment?: Dispatch<SetStateAction<boolean>>;
}
const AddresInfoCard = ({
  setDisablePayment,
}: AddresInfoCardProps | undefined | any) => {
  const { t } = useLanguage();
  const [open, setOpen] = useState(false);

  const [billingInfo, setBillingInfo] = useState<BillingInfo>({
    addressLine1: '',
    addressLine2: undefined,
    city: '',
    companyName: '',
    country: '',
    firstName: '',
    gsmNumber: '',
    identityNumber: '',
    isCorporate: false,
    lastName: '',
    taxOffice: '',
    title: '',
    vatNumber: '',
    zipCode: '',
  });
  const handleOpenModel = () => {
    setOpen(true);
  };

  const getBillingInfo = async () => {
    try {
      const res = await memberService.getBillingAddress();

      if (res.data.length > 0) {
        if (setDisablePayment !== undefined) {
          setDisablePayment(false);
        }
        setBillingInfo(res.data[0]);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getBillingInfo();
  }, []);

  return (
    <div className="col-span-6 lg:col-span-3  bg-white rounded-xl p-4 gap-2 shadow-lg">
      <h2 className="text-headerPrimary font-medium cardHeading">
        {t?.('billingInfo.billing.info')}
      </h2>
      {billingInfo !== undefined ? (
        <div>
          <div className="flex w-full gap-x-3 my-2">
            <span className="text-xs font-medium lg:text-sm">
              {t?.('billingInfo.billing.nameSurname')}{' '}
            </span>
            <p className="text-xs lg:text-sm">
              {billingInfo?.firstName} {billingInfo?.lastName}
            </p>
          </div>
          <div className="flex w-full gap-x-3">
            <p className="text-xs font-medium lg:text-sm">
              {t?.('billingInfo.billing.address')}
            </p>
            <p className="text-xs lg:text-sm" data-testid="hello">
              {billingInfo?.addressLine1}
            </p>
          </div>
        </div>
      ) : (
        <p className="mt-5">Lütfen Fatura Bilgilerini Doldurunuz</p>
      )}

      <div className="flex  w-full justify-end gap-x-3 mt-4">
        <button
          id="updateBtn"
          onClick={handleOpenModel}
          className="rounded-md bg-headerPrimary py-2.5 px-8 text-xs font-semibold text-white shadow-sm hover:bg-secondary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 md:text-sm"
        >
          {t?.('billingInfo.billing.editBtn')}
        </button>
      </div>
      <AddressChangeForm
        open={open}
        setOpen={setOpen}
        billingInfo={billingInfo}
        getBillingInfo={getBillingInfo}
      />
    </div>
  );
};

export default AddresInfoCard;
