import React, { useCallback, useEffect, useState } from 'react';
import TrendyolStackedChart from './TrendyolStackedChart';
import PieChart from '../tools/common/PieChart';
import trendyolToolsService from '../../services/trendyolToolsService';
import { useAuth } from '../../context/AuthContext';
import alertNotification from '../../utils/alertNotification';
import { ToastContainer } from 'react-toastify';

interface ChartValue {
  name: string;
  data: number[];
}
const dummyChartValues = [
  {
    name: 'Sipariş',
    data: [44, 55, 66, 77, 88, 87, 44, 55, 66, 77, 88, 87],
  },
  {
    name: 'İade',
    data: [13, 23, 7, 18, 13, 43, 13, 23, 7, 18, 13, 43],
  },
];
interface ChartProps {
  dateQuery: {
    startDate: string;
    endDate: string;
  };
}
const ChartsWrapper: React.FC<ChartProps> = ({ dateQuery }) => {
  const { session } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [dates, setDates] = useState<string[]>([
    '01 Ağustos',
    '01 Eylül',
    '01 Ekim',
    '01 Kasım',
    '01 Aralık',
    '01 Ocak',
    '01 Şubat',
    '01 Mart',
    '01 Nisan',
    '01 Mayıs',
    '01 Haziran',
    '01 Temmuz',
  ]);
  let temp = session?.isTrendyolIntegrated ? [] : dummyChartValues;
  const [chartValues, setChartValues] = useState<ChartValue[]>(temp);
  const [pieChartValues, setPieChartValues] = useState([
    22, 33, 44, 55, 66, 77, 33, 22, 44, 11,
  ]);
  const [pieChartLabels, setPieChartLabels] = useState([
    'Takı Reçine Kolye',
    'Orijinal Kadın 5 Li Altın',
    'Küp Detaylı Dörtlü',
    'Turquoise Stone Dörtlü',
    'Bereket Bilekliği',
    'Sertifikalı Çakra Bilekliği',
    'Nazar Bilekliği',
    'Aventurin Doğal Taş',
    'Akuamarin Doğal Taş Kolye',
    'Rhinestone Zirkon Taş',
  ]);

  const options = {
    chart: {
      height: 350,
      stacked: true,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: 'bottom',
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 3,

        dataLabels: {
          total: {
            enabled: true,
            style: {
              fontSize: '11px',
              fontWeight: 900,
            },
          },
        },
      },
    },
    xaxis: {
      categories: dates,
      labels: {
        style: {
          fontSize: '11px',
        },
      },
    },
    legend: {
      show: false,
      offsetY: 40,
    },
    fill: {
      opacity: 1,
    },
    colors: ['#3C468F', '#B7BCE1'],
  };
  const pieOptions = {
    labels: pieChartLabels, // Value Titles
    colors: [
      '#E11D48', //var
      '#DC2626', //var
      '#EA580C', //var
      '#D97706', //var
      '#059669',
      '#0D9488',
      '#0891B2',
      '#0284C7',
      '#2563EB',
      '#4F46E5',
    ], // Value Colors
    dataLabels: {
      style: {
        fontSize: '10px',
      },
    },
    legend: { show: false },
    plotOptions: {
      pie: {
        customScale: 1,
        expandOnClick: false,
        donut: {
          size: '40%',
          labels: {
            show: false,
          },
        },
      },
    },
  };

  const getStackedChartValues = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await trendyolToolsService.getTrendyolStackChartData(
        session?.producerId!
      );

      let dates = res.data.map((item: { date: Date }) => {
        return item.date;
      });
      let returns = res.data.map((item: { returnCount: number }) => {
        return item.returnCount;
      });
      let orders = res.data.map(
        (item: { returnCount: number; totalSaleCount: number }) => {
          return item.totalSaleCount - item.returnCount;
        }
      );
      const values = [
        { name: 'Sipariş', data: orders },
        { name: 'İade', data: returns },
      ];
      if (values[0].data.length > 0) {
        setChartValues(values);
        setDates(dates);
      }
    } catch (error: any) {
      alertNotification('error', error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  }, [session?.producerId]);

  const getPieChartValues = useCallback(async () => {
    console.log({ dateQuery });
    try {
      const res = await trendyolToolsService.getTrendyolBestOrders(
        session?.producerId!,
        dateQuery
      );

      let values = res.data.map((item: { saleCount: number }) => {
        return Number(item.saleCount);
      });
      let labels = res.data.map((item: { title: string }) => {
        return String(item.title).substring(0, 30);
      });
      if (values.length > 0) {
        setPieChartValues(values);
        setPieChartLabels(labels);
      }
    } catch (error: any) {
      alertNotification('error', error.response.data.message);
    }
  }, [dateQuery, session?.producerId]);

  useEffect(() => {
    if (session?.producerId) {
      getStackedChartValues();
      getPieChartValues();
    }
  }, [dateQuery]);

  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="xl:col-span-7  lg:col-span-8 md:col-span-7 col-span-12 bg-white rounded-md shadow-md">
        <TrendyolStackedChart
          dates={dates}
          options={options}
          series={isLoading ? [] : chartValues}
        />
      </div>

      <div className="xl:col-span-5 lg:col-span-4 md:col-span-5 col-span-12 bg-white rounded-md shadow-md pt-8">
        <PieChart series={pieChartValues} options={pieOptions} />
      </div>
      <ToastContainer />
    </div>
  );
};

export default ChartsWrapper;
