interface MaxWeights {
    [key: string]: {
        [key: string]: number;
    };
}

const maxWeights: MaxWeights = {
    "shipentegra-worldwide-standard": {
        "CA": 2, "AU": 2, "FR": 2, "DE": 2, "IT": 2, "NL": 2, "ES": 2, "IE": 2,
        "BE": 2, "AT": 2, "NZ": 2, "SG": 2, "SE": 2, "IL": 2, "PT": 2, "DK": 2,
        "PL": 2, "JP": 2, "CZ": 2, "BR": 2, "EE": 2, "FI": 2, "NO": 2, "RO": 2,
        "LV": 2, "LT": 2, "IS": 2, "HU": 2, "GR": 2, "HR": 2, "LU": 2, "SK": 2,
        "SI": 2, "CH": 2, "GB": 2, "IN": 2
    },
    "shipentegra-eco": {
        "US": 0.5
    },
    "shipentegra-amerika-eko-plus": {
        "US": 2
    },
    "shipentegra-ingiltere-eko-plus": {
        "GB": 5,
        "UK": 5
    },

};

export default maxWeights;
