import { AxiosResponse } from 'axios';

import httpClient from '../utils/httpClient';

const products = '/api/v1/products';
class ProductService {
  responseBody = (response: AxiosResponse) => response.data;
  //get producers
  public async getProducts(
    page: number,
    keyword: string,
    orderBy: string,
    order: string,
    categoryId?: number | string,
    location?: string[],
    minPrice?: number,
    maxPrice?: number,
    minRating?: number,
    maxRating?: number,
    minReviewCount?: number,
    maxReviewCount?: number,
  ) {
    const { data } = await httpClient.get(
      `${products}?page=${page}&keyword=${keyword}&orderBy=${orderBy}&order=${order}&categoryId=${categoryId !== 1 ? categoryId : ''}&location=${location}&minPrice=${minPrice ? minPrice : ''}&maxPrice=${maxPrice ? maxPrice : ''}&minRating=${minRating ? minRating : ''}&maxRating=${maxRating ? maxRating : ''}&minReviewCount=${minReviewCount ? minReviewCount : ''}&maxReviewCount=${maxReviewCount ? maxReviewCount : ''}`
    );
    return data;
  }

  public async getProducerProducts(
    id: number,
    page: number,
    queryParams: any
  ) {
    const { data } = await httpClient.get(
      `${products}/producer/${id}?page=${page}&keyword=${queryParams.keyword}&minPrice=${queryParams.minPrice}&maxPrice=${queryParams.maxPrice}&categoryId=${queryParams.categoryId}&minRating=${queryParams.minRating}&maxRating=${queryParams.maxRating}&minReviewCount=${queryParams.minReviewCount}&maxReviewCount=${queryParams.maxReviewCount}`
    );
    return data;
  }
  public async getProducerCheck() {
    const { data } = await httpClient.get(
      `${products}/products/check-producer`
    );
    return data;
  }
}

export default new ProductService();
