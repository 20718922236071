import { SearchNormal1 } from 'iconsax-react';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { ChevronDoubleLeftIcon, ChevronDoubleRightIcon } from '@heroicons/react/24/solid';

import { useLanguage } from '../../context/LanguageContext';
import { useNumberVerification } from '../../context/NumberVerificationContext';
import useMultiplier from '../../hooks/useMultiplier';
import { MarketPlaceProductCard, ProductData } from '../../interfaces/marketplaceInterfaces';
import RatingStars from '../common/RatingStars';

interface ProductCartProps {
  product: ProductData;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setProductId: React.Dispatch<React.SetStateAction<number | null>>;
}

const MarketplaceProductsCart: React.FC<ProductCartProps> = ({
  product,
  setOpen,
  setProductId,
}) => {
  const multiplier = useMultiplier();
  const [currentIndex, setCurrentIndex] = useState(0);

  const prevSlide = () => {
    const isFirstSlide = currentIndex === 0;
    const newIndex = isFirstSlide
      ? product.images.length - 1
      : currentIndex - 1;
    setCurrentIndex(newIndex);
  };

  const nextSlide = () => {
    const isLastSlide = currentIndex === product.images.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };

  return (
    <div className="group relative rounded-xl ">
      <div className="aspect-w-1 aspect-h-1 overflow-hidden rounded-[12px] group-hover:opacity-75 mx-auto h-48 w-auto mb-4">
        <Link
          to={`/marketplace/products/${product?.id}`}
          target="_blank" >
          <img
            onError={(e) => {
              (e.target as HTMLImageElement).src = '/images/notFound.png';
            }}
            src={
              product?.images && product?.images?.length > 0
                ? `https://api.rexven.com/images/marketplace/supplier-images/${product?.images[currentIndex]}`
                // ? `/Rectangle6802.png`
                : '/images/notFound.png'
            }
            alt="product_image"
            className="h-full w-full  object-center rounded-[12px]"
          />
        </Link>
      </div>

      {product?.images?.length > 1 && (
        <>
          <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] left-5 text-xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
            <ChevronDoubleLeftIcon className="h-6 w-6" onClick={prevSlide} />
          </div>
          <div className="hidden group-hover:block absolute top-[50%] -translate-x-0 translate-y-[-50%] right-5 text-xl rounded-full p-2 bg-black/20 text-white cursor-pointer">
            <ChevronDoubleRightIcon className="h-6 w-6" onClick={nextSlide} />
          </div>
        </>
      )}
      <div className="text-left flex flex-col gap-1 items-left justify-center">
        <div className="min-h-[68px] flex items-center text-xs 2xl:text-sm  ">
          <Link
            to={`/marketplace/products/${product?.id}`}
            target="_blank" className="font-semibold">{product.title.length > 60 ? `${product?.title.slice(0, 60)}...` : product.title}
          </Link>
        </div>
        <div className="text-primary justify-center flex flex-col items-start text-sm  ">
          <span className="text-headerPrimary sm:text-lg font-extrabold">
            {`$ ${Number(Number(product?.supplier_product_variants[0]?.price ? product?.supplier_product_variants[0]?.price : 1) * 1.2).toFixed(2)}`}
          </span>
          <div className="text-[#60A5FA] text-xs">
            Premium Özel Fiyat <span className="text-[10px] text-[#60A5FA] text-xs 2xl:text-sm font-bold">{`$ ${(Number(product?.supplier_product_variants[0]?.price ? product?.supplier_product_variants[0]?.price : 1) * 1.1).toFixed(2)}`}</span>
          </div>
        </div>
        <div className="flex flex-col items-left">
          <div className="flex items-center">
            <span className="text-sm"></span>
          </div>
        </div>
        <div className="flex items-center justify-center space-x-4">
          <Link
            to={`/marketplace/products/${product?.id}`}
            target="_blank"
            className="hover:cursor-pointer text-sm 2xl:text-base bg-[#2563EB] font-bold  text-white h-[40px] max-h-[50px] w-full rounded-lg flex items-center justify-center"
          >
            İncele <SearchNormal1 size="24" className="text-white" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MarketplaceProductsCart;
