import { triggerPurchaseEvent } from "./gtagManager";

// İlk çağrıda MutationObserver'ı oluşturmak için kullanılan değişken
let isObserverInitialized = false;

const appendIyzicoScript = (type: string,
    data: string,
    event: string,
    coupon: string,
    item_id: number,
    item_name: string,
    discount: string,
    price: number,
) => {
    let temp: any = document.createElement('div');
    temp.innerHTML = data;

    const script1 = document.createElement('script');
    const script2 = document.createElement('script');

    script1.innerHTML = temp.firstChild.innerHTML;
    script1.async = true;
    document.body.appendChild(script1);

    script2.innerHTML = temp.lastChild.innerHTML;
    script2.async = true;
    document.body.appendChild(script2);

    // Subscription Mode has three Scripts and Loop did not work
    if (type === 'subs') {
        const script3 = document.createElement('script');
        script3.innerHTML = temp.children[1].innerHTML;
        script3.async = true;
        document.body.appendChild(script3);
    }

    // Create the MutationObserver only if not already initialized
    if (!isObserverInitialized) {
        const observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
                // Check if iyz-payment-button is now available
                const iyzButton = document.getElementById("iyz-payment-button");
                if (iyzButton) {
                    // Add the click event listener to the iyz-payment-button
                    iyzButton.addEventListener("click", () => {
                        triggerPurchaseEvent(event, coupon, item_id, item_name, discount, price, 'begin_checkout_transaction');
                    });

                    // Disconnect the observer once the button is found
                    observer.disconnect();
                }
            });
        });

        // Start observing the document for changes
        observer.observe(document, { childList: true, subtree: true });

        // Set the isObserverInitialized flag to true to prevent future initializations
        isObserverInitialized = true;
    }
};

export default appendIyzicoScript;
