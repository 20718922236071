import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';

import { useLanguage } from '../../context/LanguageContext';
import { InputLabel } from '../../pages/AmazonProductFinder';
import { classNames } from '../../utils/conditionalClasses';
import CountrySelect from './elements/CountrySelect';
import Regions from './elements/Regions';

interface AmazonProductFinderFilterProps {
    sizeOptions: string[]
    selectedSize: string
    setSelectedSize: Dispatch<SetStateAction<string>>
    selectFilter: {
        name: string;
        value: boolean;
        id: number
        disabled: boolean
    }[]
    setSelectFilter: Dispatch<SetStateAction<{
        name: string;
        value: boolean;
        id: number
        disabled: boolean
    }[]>>
    inputLabels: InputLabel[]
    setInputLabels: React.Dispatch<React.SetStateAction<InputLabel[]>>
    selectAll: boolean
    setSelectAll: Dispatch<SetStateAction<boolean>>
    isAmazonAdded: boolean | null
    keyword: string;
    setKeyword: Dispatch<SetStateAction<string>>;
    setIsAmazonAdded: Dispatch<SetStateAction<boolean | null>>
    resetFilters: () => void
    handleSubmit: () => void
}

const AmazonProductFinderFilter: React.FC<AmazonProductFinderFilterProps> = ({
    sizeOptions,
    selectedSize,
    setSelectedSize,
    selectFilter,
    setSelectFilter,
    inputLabels,
    selectAll,
    setSelectAll,
    setInputLabels,
    isAmazonAdded,
    keyword,
    setKeyword,
    setIsAmazonAdded,
    resetFilters,
    handleSubmit
}) => {
    const { t, language } = useLanguage();
    const [isCategoryVisible, setIsCategoryVisible] = useState(true);
    const handleInputChange = (e: React.ChangeEvent<HTMLSelectElement | HTMLInputElement>, index: number, type: 'minValue' | 'maxValue') => {
        const value = parseInt(e.target.value, 10);
        const updatedInputLabels = [...inputLabels];
        updatedInputLabels[index][type] = value;

        if (type === 'minValue' && (updatedInputLabels[index].name === 'Ürün Listeleme Tarihi' || updatedInputLabels[index].name === 'Product Age')) {
            if (value === 2024) {
                updatedInputLabels[index].maxValue = 2025;
            } else if (updatedInputLabels[index].maxValue < updatedInputLabels[index].minValue) {
                updatedInputLabels[index].maxValue = 2025
            }
        } else if (type === 'maxValue' && (updatedInputLabels[index].name === 'Ürün Listeleme Tarihi' || updatedInputLabels[index].name === 'Product Age')) {
            if (updatedInputLabels[index].maxValue < updatedInputLabels[index].minValue) {
                updatedInputLabels[index].minValue = 2010
            }
        }

        setInputLabels(updatedInputLabels);
    };

    const handleKeywordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setKeyword(e.target.value);
    };

    return (
        <div className="bg-white rounded-xl mt-12 p-4 flex gap-4 flex-col justify-center items-start">
            <div className='flex flex-row items-center justify-start w-full gap-2'>
                <div className='flex flex-row items-center justify-start w-full gap-2'>
                    <p
                        onClick={() => setIsCategoryVisible(!isCategoryVisible)}
                        className='text-headerPrimary font-semibold cursor-pointer'>
                        Bir veya daha fazla kategori seçiniz
                    </p>
                    <div>
                        {isCategoryVisible ? <ChevronDownIcon
                            onClick={() => setIsCategoryVisible(false)}
                            className="h-5 w-5 text-[#1F254B] cursor-pointer bg-[#EDEEF8] rounded-md"
                            aria-hidden="true"
                        />
                            :
                            <ChevronUpIcon
                                onClick={() => setIsCategoryVisible(true)}
                                className="h-5 w-5 text-[#1F254B] cursor-pointer bg-[#EDEEF8] rounded-md"
                                aria-hidden="true"
                            />
                        }
                    </div>
                </div>
                <div className={classNames(
                    isCategoryVisible ? 'block' : 'hidden',
                    ''
                )}>
                    <Regions />
                </div>
            </div>
            <div
                className={classNames(
                    isCategoryVisible ? 'block' : 'hidden',
                    ''
                )}>
                <div className='grid grid-cols-2 gap-4 w-full justify-start items-start pt-4'>
                    {/* left side checkbox map */}
                    <div className='w-full col-span-2 xl:col-span-1 grid grid-cols-2 gap-4  xl:border-r xl:border-r-headingSecondary'>
                        <label className="flex items-center space-x-2 text-sm cursor-pointer text-description">
                            <input
                                id="selectAll"
                                type="checkbox"
                                className="h-4 w-4 cursor-pointer rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                checked={selectAll}
                                onChange={() => setSelectAll(!selectAll)}
                            />
                            <span>{language === 'tr' ? 'Hepsi' : 'Select All'}</span>
                        </label>
                        {selectFilter.map((item, index) => (
                            <label key={index} className={classNames(item.disabled ? 'opacity-50 cursor-not-allowed' : '', "flex items-center space-x-2 text-sm cursor-pointer text-description")}>
                                <input
                                    id={`choose-${index}`}
                                    disabled={item.disabled}
                                    aria-describedby={`comments-description-${index}`}
                                    name={`comments-${index}`}
                                    type="checkbox"
                                    className={classNames(
                                        item.disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer',
                                        "h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                    )}
                                    checked={item.value && !item.disabled}
                                    onChange={() => {
                                        const updatedSelectFilter = [...selectFilter];
                                        updatedSelectFilter[index].value = !item.value;
                                        setSelectFilter(updatedSelectFilter);
                                    }}
                                />
                                <span className={item.disabled ? 'opacity-50 text-description' : 'text-description'}>{item.name}</span>
                            </label>
                        ))}
                        <br />
                        <div className=" flex justify-start w-full gap-2">
                            <span className='text-rexRed'>*</span>
                            <span className='text-description text-xs opacity-75 text-description'>Tüm kategoriler kısa süre içinde aktif edilecektir.</span>
                        </div>
                    </div>
                    {/* rigth side number inputs map */}
                    <div className='w-full col-span-2 xl:col-span-1 grid grid-cols-2'>
                        {inputLabels.map((item, index) => (
                            <div key={index} className={`col-span-2 lg:col-span-1 flex flex-col items-center mb-2 ${index % 2 !== 1 ? 'lg:mr-6' : 'lg:ml-6'}`}>
                                <label className="w-full py-2 font-medium text-xs min-[1335px]:text-sm text-description" htmlFor={item.name}>
                                    {item.name}
                                </label>
                                <div className="flex w-full justify-between items-center space-x-2">
                                    {item.name === 'Ürün Listeleme Tarihi' || item.name === 'Product Age' ? (
                                        <>
                                            <select
                                                name={`${item.name}-min`}
                                                id={`${item.name}-min`}
                                                onChange={(e) => handleInputChange(e, index, 'minValue')}
                                                value={item.minValue}
                                                className="w-full rounded-md border border-cardBorder py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                                            >
                                                {[...Array(25)].map((_, year) => (
                                                    <option key={year} value={2000 + year}>{2000 + year}</option>
                                                ))}
                                            </select>
                                            <p className='text-description'>-</p>
                                            <select
                                                name={`${item.name}-max`}
                                                id={`${item.name}-max`}
                                                value={item.maxValue}
                                                onChange={(e) => handleInputChange(e, index, 'maxValue')}
                                                className="w-full rounded-md border border-cardBorder py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                                            >
                                                {[...Array(26)].map((_, year) => (
                                                    <option key={year} value={2000 + year}>{2000 + year}</option>
                                                ))}
                                            </select>
                                        </>
                                    ) : (
                                        // Diğer inputlar için mevcut yapıyı koru
                                        <>
                                            <input
                                                name={item.name}
                                                id={`${item.name}-min`}
                                                type="number"
                                                onChange={(e) => handleInputChange(e, index, 'minValue')}
                                                className="w-full rounded-md border border-cardBorder py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                                                placeholder='Min'
                                            />
                                            <p>-</p>
                                            <input
                                                name={item.name}
                                                id={`${item.name}-max`}
                                                type="number"
                                                onChange={(e) => handleInputChange(e, index, 'maxValue')}
                                                className="w-full rounded-md border border-cardBorder py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                                                placeholder='Max'
                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                        ))}

                        <div className={`col-span-2 lg:col-span-1 flex flex-col items-center mb-2 lg:mr-6`}>
                            <CountrySelect label={language === 'tr' ? 'Ürün Boyutları' : 'Product Size'} options={sizeOptions} selectedCountry={selectedSize} setSelectedCountry={setSelectedSize} />
                        </div>
                        <div className={`col-span-2 lg:col-span-1 flex flex-col items-center mb-2 lg:ml-6`}>
                            <span className='w-full py-2 font-medium text-xs min-[1335px]:text-sm text-description'>{'Amazon Listede Var Mı?'}</span>
                            <div className='flex flex-row items-center justify-start h-full w-full gap-4'>
                                <div className='flex flex-row items-center justify-start h-full w-full gap-2'>
                                    <input
                                        name={`amazonAdded`}
                                        type="checkbox"
                                        checked={isAmazonAdded!}
                                        className="w-6 h-6 cursor-pointer rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                        onChange={() => {
                                            setIsAmazonAdded(true);
                                        }}
                                    />
                                    <label htmlFor='amazonAdded' className="flex items-center space-x-2 text-sm  cursor-pointer text-description">
                                        Evet
                                    </label>
                                </div>

                                <div className='flex flex-row items-center justify-start h-full w-full gap-2'>

                                    <input
                                        name={`amazonNotAdded`}
                                        type="checkbox"
                                        checked={!isAmazonAdded! && isAmazonAdded !== null}
                                        className="w-6 h-6 cursor-pointer rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                                        onChange={() => {
                                            setIsAmazonAdded(false);
                                        }}
                                    />
                                    <label htmlFor='amazonNotAdded' className="flex items-center space-x-2 text-sm  cursor-pointer text-description">
                                        Hayır
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div className={`col-span-2 flex flex-col items-center`}>
                            <label
                                className={"w-full py-2 font-medium text-xs min-[1335px]:text-sm text-description"}
                                htmlFor={'keyword'}
                            >
                                {'Kelime'}
                            </label>
                            <input
                                name={'keyword'}
                                id={'keyword'}
                                value={keyword}
                                onChange={handleKeywordChange}
                                type="text"
                                className="w-full rounded-md border border-cardBorder py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white"
                                placeholder="Kelime"
                            />
                        </div>
                    </div>
                </div>
                <div className="mt-4 flex justify-end w-full gap-2">
                    <div
                        onClick={resetFilters}
                        className="text-center cursor-pointer px-4 py-2 text-sm lg:text-base border-2 border-headerPrimary text-headerPrimary font-bold rounded-md  bg-white "
                    >
                        {t?.('orders.filters.clear')}
                    </div>
                    <button
                        onClick={handleSubmit}
                        type="submit"
                        className="px-4 py-2 text-sm lg:text-base border-2 border-headerPrimary text-white font-bold rounded-md  bg-headerPrimary "
                    >
                        {t?.('orders.filters.filter')}
                    </button>
                </div>
            </div>

        </div>
    );
}

export default AmazonProductFinderFilter;
