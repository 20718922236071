import { InfoCircle, Trash } from 'iconsax-react';
import { Dispatch, SetStateAction } from 'react';

import { PlusIcon } from '@heroicons/react/20/solid';

import { useLanguage } from '../../../context/LanguageContext';
import { classNames } from '../../../utils/conditionalClasses';
import { errorElement } from '../../../utils/errorElement';

interface ProductTransactionsTableProps {
    products: {
        id: number;
        quantity: number | null;
        unitPrice: number | null;
        name: string | null;
        sku: string | null;
        gtip: string | null;
        quantityErr: boolean, // required
        unitPriceErr: boolean, // required
        nameErr: boolean,
    }[],
    setProducts: Dispatch<SetStateAction<{
        id: number;
        quantity: number | null;
        unitPrice: number | null;
        name: string | null;
        sku: string | null;
        gtip: string | null;
        quantityErr: boolean, // required
        unitPriceErr: boolean, // required
        nameErr: boolean,
    }[]>>
    description: {
        text: string,
        error: boolean
    }
    setDescription: Dispatch<SetStateAction<{
        text: string,
        error: boolean
    }>>
}
const ProductTransactionsTable: React.FC<ProductTransactionsTableProps> = ({
    products,
    setProducts,
    description,
    setDescription
}) => {
    const { t, language } = useLanguage();
    const tableHeader =
        language === 'tr'
            ? [
                { name: 'Ürün İçeriği', isNeeded: true, type: 'text' },
                { name: 'Adet', isNeeded: true, type: 'number' },
                { name: 'Birim Fiyatı', isNeeded: true, type: 'number' },
                { name: 'SKU Kodu', isNeeded: false, type: 'text' },
                { name: 'Gtip Kodu', isNeeded: false, type: 'number' },
                { name: '#', isNeeded: false, type: '' },
            ]
            : [
                { name: 'Product Content', isNeeded: true, type: 'text' },
                { name: 'Number Of Products', isNeeded: true, type: 'number' },
                { name: 'Unit Price', isNeeded: true, type: 'number' },
                { name: 'SKU Code', isNeeded: false, type: 'text' },
                { name: 'Hs Code', isNeeded: false, type: 'number' },
                { name: '#', isNeeded: false, type: '' },
            ];

    const handleChangeDescription = (e: React.ChangeEvent<HTMLInputElement>) => {
        setDescription({
            text: e.target.value,
            error: false
        });
    };
    const handleDeleteProduct = (id: number) => {
        // İlgili ürünü filtrele ve geri kalan ürünleri ayarla
        setProducts((prevProducts) => prevProducts.filter((product) => product.id !== id));
    };

    const handleAddProduct = () => {
        // Mevcut ürünlerin içindeki id'lerin en büyüğünü bulun
        const maxId = products.reduce((max, product) => Math.max(max, product.id), 0);

        // Yeni bir ürün eklemek için en büyük id'ye bir fazlasını kullanın
        const newProduct = {
            id: maxId + 1,
            quantity: null, // required
            unitPrice: null, // required
            name: null, // required
            sku: null,
            gtip: null,
            quantityErr: false, // required
            unitPriceErr: false, // required
            nameErr: false,
        };

        // Yeni ürünü mevcut ürünler listesine ekleyin
        setProducts((prevProducts) => [...prevProducts, newProduct]);
    };
    const handleChangeProducts = (e: React.ChangeEvent<HTMLInputElement>, id: number, fieldName: string) => {
        const { value } = e.target;
        let newFieldName: string = '';
        let errorKey: string = '';
        if (fieldName === 'Ürün İçeriği' || fieldName === 'Product Content') {
            newFieldName = 'name';
            errorKey = 'nameErr';
        } else if (fieldName === 'Adet' || fieldName === 'Number Of Products') {
            newFieldName = 'quantity';
            errorKey = 'quantityErr';
        } else if (fieldName === 'Birim Fiyatı' || fieldName === 'Unit Price') {
            newFieldName = 'unitPrice';
            errorKey = 'unitPriceErr';
        } else if (fieldName === 'SKU Kodu' || fieldName === 'SKU Code') {
            newFieldName = 'sku';
        } else if (fieldName === 'Gtip Kodu' || fieldName === 'Hs Code') {
            newFieldName = 'gtip';
        }
        if (newFieldName === 'name' || newFieldName === 'quantity' || newFieldName === 'unitPrice') {
            setProducts(prevProducts => prevProducts.map(product =>
                product.id === id ? {
                    ...product,
                    [newFieldName]: value,
                    [errorKey]: value.trim() === '' // Eğer değer boşsa hata true olur
                } : product
            ));
        } else {
            setProducts(prevProducts => prevProducts.map(product =>
                product.id === id ? {
                    ...product,
                    [newFieldName]: value
                } : product
            ));
        }
    };
    return (
        <div className="flow-root mt-8 pb-4">
            <div className="relative -mx-8 -my-2 overflow-x-auto overflow-y-auto scrollbar-hide ">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 shadow-lg">
                    <table className="min-w-full divide-y divide-gray-300 table-fixed">
                        <thead className="rounded-tl-lg rounded-tr-lg border-none text-white">
                            <tr>
                                {tableHeader.map((item, index) => {
                                    return (
                                        <th
                                            key={index}
                                            className="sticky first:rounded-tl-2xl last:rounded-tr-2xl top-0 border-b border-gray-300 text-center bg-headerPrimary py-3.5 pl-4 pr-3 text-sm font-semibold text-white backdrop-blur backdrop-filter "
                                        >
                                            <p className="text-xs md:text-sm">{item.name}
                                                {item.isNeeded && <span className='text-rexRed'>*</span>}
                                                {(item.name === 'Gtip Kodu' || item.name === 'Hs Code') && (
                                                    <InfoCircle className='h-4 w-4 rounded-full ml-1 bg-headerPrimary text-white inline-block' />
                                                )}
                                            </p>
                                        </th>
                                    );
                                })}
                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                            {products.map((product) => (
                                <tr
                                    key={product.id}
                                    className={classNames(
                                        'even:bg-lightGray border-none text-center'
                                    )}
                                >
                                    {tableHeader.filter(item => item.name !== '#').map((item, index) => (
                                        <td key={index} className="whitespace-nowrap pr-2 py-4 text-sm text-center text-gray-500 border-none">
                                            <input
                                                onChange={(e) => handleChangeProducts(e, product.id, item.name)}
                                                type={item.type}
                                                // value={}
                                                name={item.name}
                                                id={item.name}
                                                className="block w-full rounded-lg border-sm border-cardBorder px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-cardBorder placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cardBorder sm:text-sm sm:leading-6"
                                                placeholder={item.name}
                                            />
                                            {product.nameErr === true && (item.name === 'Ürün İçeriği' || item.name === 'Product Content') && (
                                                errorElement('Lütfen değer giriniz.')
                                            )}
                                            {product.unitPriceErr === true && (item.name === 'Birim Fiyatı' || item.name === 'Unit Price') && (
                                                errorElement('Lütfen değer giriniz.')
                                            )}
                                            {product.quantityErr === true && (item.name === 'Adet' || item.name === 'Number Of Products') && (
                                                errorElement('Lütfen değer giriniz.'))}
                                        </td>
                                    ))}
                                    <td className="whitespace-nowrap px-3 py-4 text-center min-w-[100px] text-xs md:text-sm text-gray-500 border-none">
                                        {product.id !== 1 &&
                                            <button
                                                className="bg-rexRed text-white font-medium flex text-sm justify-center items-center p-2 rounded-md"
                                                onClick={() => handleDeleteProduct(product.id)}
                                            >
                                                <Trash size="24" color="white" />
                                                <span className="mx-2 text-xs">
                                                    {t?.('common.buttons.delete')}
                                                </span>
                                            </button>}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <div
                onClick={() => handleAddProduct()}
                className="rounded-md border border-headerPrimary flex justify-center items-center p-2 w-12 h-12 mx-auto my-5 sm:my-7 cursor-pointer">
                <PlusIcon
                    height={24}
                    width={24}
                    color="#2D356C" />
            </div>
            <div className='grid grid-cols-2 gap-4 grid-row items-end justify-start mt-4'>
                <div className='col-span-2'>
                    <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                        {t?.('create-shipment.product-transactions.order-description')}
                        <span className='text-rexRed'>*</span>
                    </label>
                    <div className=' mb-5 sm:mb-7'>
                        <input
                            type="text"
                            name="description"
                            id="description"
                            value={description.text}
                            onChange={handleChangeDescription}
                            className="block w-full rounded-lg border-sm border-cardBorder px-4 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-cardBorder placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-cardBorder sm:text-sm sm:leading-6"
                            placeholder="towel, bracelet, gift card..."
                        />
                        {description.error === true && errorElement('Açıklama Girilmesi Zorunludur.')}
                    </div>
                    {/* <button
                                    type='button'
                                    onClick={handleAddDescripton}
                                    className="rounded-md border border-headerPrimary flex justify-center items-center p-2 mx-auto my-5 sm:my-7 gap-2 cursor-pointer">
                                    {t?.('create-shipment.product-transactions.new-description')}
                                    <PlusIcon
                                        height={24}
                                        width={24}
                                        color="#2D356C" />
                                </button> */}
                </div>
            </div>
            <p className='text-sm text-description'>
                {t?.('create-shipment.product-transactions.note1')}
            </p>
            <p className='text-sm text-description'>
                {t?.('create-shipment.product-transactions.note2')}
            </p>
            <div className="h-0.5 bg-[#DBDEF0] w-2/3 mx-auto mt-16"></div>
        </div>
    )
}

export default ProductTransactionsTable