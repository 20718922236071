import { Icon, MessageText1 } from 'iconsax-react';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import { Bars3BottomLeftIcon } from '@heroicons/react/24/outline';

import { IMAGE_URL } from '../config';
import { useAuth } from '../context/AuthContext';
import { useLanguage } from '../context/LanguageContext';
import memberService from '../services/memberService';
import HeaderDropDown from './HeaderDropDown';
import LanguageSelect from './LanguageSelect';

export interface Navs {
  name: any;
  href: string;
  icon: Icon;
  target?: string
  children?: any[];
  current: boolean;
}
interface PropsState {
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  sidebarOpen: boolean;
  profileNav: Navs[];
}
const Header: React.FC<PropsState> = ({
  setSidebarOpen,
  title,
  sidebarOpen,
  profileNav
}) => {
  const { t } = useLanguage();
  const { session } = useAuth()
  const [unreadCount, setUnreadCount] = useState<number>()
  const getContacts = () => {
    let unread = 0;
    memberService.getContacts()
      .then((res) => {
        res.data.forEach((el: any) => {
          unread += el.unreadCount
        })
        setUnreadCount(unread)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  useEffect(() => {
    const intervalId = setInterval(() => {
      getContacts();
    }, 10000);
    return () => clearInterval(intervalId);
  }, []);
  return (
    <div className="sticky top-0 flex h-16 flex-shrink-0 bg-white shadow z-40">
      <button
        type="button"
        className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 lg:hidden"
        onClick={() => {
          setSidebarOpen(!sidebarOpen);
        }}
      >
        <span className="sr-only">Open sidebar</span>
        <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
      </button>
      <div className="flex flex-1 justify-between paddingX">
        <div className="flex flex-1 items-center">
          <h1 className="text-primary pageHeading font-bold ">{t?.(title)}</h1>
        </div>

        <div className="ml-4 flex items-center gap-4 lg:ml-6">
          {/* TODO:if you decide to use translation just comment out these lines */}
          {/* <div className="flex items-center">
            <LanguageSelect />
          </div> */}
          {unreadCount !== undefined && unreadCount !== null && !isNaN(unreadCount) && unreadCount > 0 && (
            <Link to={'/messages'} className="relative inline-block">
              <MessageText1 id='message' variant='Bold' className='h-8 w-8 text-[#1F254B]' />
              <div className="absolute top-0 right-0 transform translate-x-1/4 -translate-y-1/4 bg-rexRed text-white text-xs px-1 py-0.25 rounded-full z-20 font-">
                {unreadCount}
              </div>
              <Tooltip
                anchorSelect="#message"
                style={{ backgroundColor: "#F3F4F6", color: "#374151", borderRadius: '12px', fontWeight: 600 }}
                content={`${unreadCount} Yeni Mesaj`}
              />
            </Link>
          )}

          {(unreadCount === undefined || unreadCount === null || isNaN(unreadCount) || unreadCount === 0) && (
            <Link to={'/messages'}>
              <MessageText1
                className='h-6 w-6 text-[#1F254B]' />
            </Link>
          )}
          <HeaderDropDown
            profileNav={profileNav}
          />
          {/* <Link to={'/profile'} className="flex items-center justify-end">
            <img
              className="h-8 w-8 rounded-full"
              src={
                session?.profileImage
                  ? `${IMAGE_URL}${session?.profileImage}`
                  : `https://api.dicebear.com/5.x/bottts/svg?seed=${session?.firstName + ' ' + session?.lastName
                  }`
              }
              alt=""
            />
            <div>
              <p className="hidden text-sm sm:text-lg ml-3 md:block">
                {session?.firstName} {session?.lastName}
              </p>
              <p className="hidden text-xs sm:text-md ml-3 md:block">
                {t?.('layout.level')} {session?.level}
              </p>
            </div>
          </Link> */}
        </div>
      </div>
    </div>
  );
};

export default Header;
