import React from 'react';

const SalesPolicy = () => {
  return (
    <div className="flex flex-col max-w-[1280px] mx-auto py-10">
      <p>MESAFELİ SATIŞ S&Ouml;ZLEŞMESİ</p>
      <p>
        <br />
      </p>
      <p>1. TARAFLAR</p>
      <p>
        <br />
      </p>
      <p>
        İşbu S&ouml;zleşme aşağıdaki taraflar arasında aşağıda belirtilen
        h&uuml;k&uuml;m ve şartlar &ccedil;er&ccedil;evesinde imzalanmıştır.
      </p>
      <p>
        <br />
      </p>
      <ol>
        <li>
          <p>
            &lsquo;ALICI&rsquo; ; (s&ouml;zleşmede bundan sonra
            &ldquo;ALICI&rdquo; olarak anılacaktır)
          </p>
        </li>
      </ol>
      <p>
        <br />
      </p>
      <p>AD- SOYAD:</p>
      <p>
        <br />
      </p>
      <p>ADRES:&nbsp;</p>
      <p>
        <br />
      </p>
      <ol>
        <li>
          <p>
            &lsquo;SATICI&rsquo; ; (s&ouml;zleşmede bundan sonra
            &ldquo;SATICI&rdquo; olarak anılacaktır)
          </p>
        </li>
      </ol>
      <p>
        <br />
      </p>
      <p>AD- SOYAD: Rexven Teknoloji A.Ş.</p>
      <p>
        <br />
      </p>
      <p>
        ADRES: Mutlukent Mah. 2041 Sok. No :5/1
        &Ccedil;ankaya/Ankara
      </p>
      <p>
        <br />
      </p>
      <p>E-POSTA: info@rexven.com</p>
      <p>
        <br />
      </p>
      <p>TEL: 0850 532 35 76</p>
      <p>
        <br />
      </p>
      <p>
        İşbu s&ouml;zleşmeyi kabul etmekle ALICI, s&ouml;zleşme konusu siparişi
        onayladığı takdirde sipariş konusu bedeli ve varsa kargo &uuml;creti,
        vergi gibi belirtilen ek &uuml;cretleri &ouml;deme
        y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml; altına gireceğini ve bu konuda
        bilgilendirildiğini peşinen kabul eder.
      </p>
      <p>
        <br />
      </p>
      <p>2. TANIMLAR</p>
      <p>
        <br />
      </p>
      <p>
        İşbu s&ouml;zleşmenin uygulanmasında ve yorumlanmasında aşağıda yazılı
        terimler karşılarındaki yazılı a&ccedil;ıklamaları ifade edeceklerdir.
      </p>
      <p>BAKAN : G&uuml;mr&uuml;k ve Ticaret Bakanı&rsquo;nı,</p>
      <p>BAKANLIK : G&uuml;mr&uuml;k ve Ticaret Bakanlığı&rsquo;nı,</p>
      <p>
        KANUN : 6502 sayılı T&uuml;keticinin Korunması Hakkında Kanun&rsquo;u,
      </p>
      <p>
        Y&Ouml;NETMELİK : Mesafeli S&ouml;zleşmeler Y&ouml;netmeliği&rsquo;ni
        (RG:27.11.2014/29188),
      </p>
      <p>
        HİZMET : Bir &uuml;cret veya menfaat karşılığında yapılan ya da
        yapılması taahh&uuml;t edilen mal sağlama dışındaki her t&uuml;rl&uuml;
        t&uuml;ketici işleminin konusunu,
      </p>
      <p>
        SATICI : Ticari veya mesleki faaliyetleri kapsamında t&uuml;keticiye mal
        sunan veya mal sunan adına veya hesabına hareket eden şirketi,
      </p>
      <p>
        ALICI : Bir mal veya hizmeti ticari veya mesleki olmayan
        ama&ccedil;larla edinen, kullanan veya yararlanan ger&ccedil;ek ya da
        t&uuml;zel kişiyi,
      </p>
      <p>SİTE : SATICI&rsquo;ya ait internet sitesini,</p>
      <p>
        SİPARİŞ VEREN: Bir mal veya hizmeti SATICI&rsquo;ya ait internet sitesi
        &uuml;zerinden talep eden ger&ccedil;ek ya da t&uuml;zel kişiyi,
      </p>
      <p>TARAFLAR : SATICI ve ALICI&rsquo;yı,</p>
      <p>
        S&Ouml;ZLEŞME : SATICI ve ALICI arasında akdedilen işbu s&ouml;zleşmeyi,
      </p>
      <p>
        MAL : Alışverişe konu olan taşınır eşyayı ve elektronik ortamda
        kullanılmak &uuml;zere hazırlanan yazılım, ses, g&ouml;r&uuml;nt&uuml;
        ve benzeri gayri maddi malları ifade eder.
      </p>
      <p>
        <br />
      </p>
      <p>3. KONU</p>
      <p>
        <br />
      </p>
      <p>
        İşbu S&ouml;zleşme, ALICI&rsquo;nın, SATICI&rsquo;ya ait internet sitesi
        &uuml;zerinden elektronik ortamda siparişini verdiği aşağıda nitelikleri
        ve satış fiyatı belirtilen &uuml;r&uuml;n&uuml;n satışı ve teslimi ile
        ilgili olarak 6502 sayılı T&uuml;keticinin Korunması Hakkında Kanun ve
        Mesafeli S&ouml;zleşmelere Dair Y&ouml;netmelik h&uuml;k&uuml;mleri
        gereğince tarafların hak ve y&uuml;k&uuml;ml&uuml;l&uuml;klerini
        d&uuml;zenler.
      </p>
      <p>
        Listelenen ve sitede ilan edilen fiyatlar satış fiyatıdır. İlan edilen
        fiyatlar ve vaatler g&uuml;ncelleme yapılana ve değiştirilene kadar
        ge&ccedil;erlidir. S&uuml;reli olarak ilan edilen fiyatlar ise
        belirtilen s&uuml;re sonuna kadar ge&ccedil;erlidir.
      </p>
      <p>
        <br />
      </p>
      <p>4. SATICI BİLGİLERİ</p>
      <p>
        <br />
      </p>
      <p>Ad - Soyad: Rexven Teknoloji A.Ş.</p>
      <p>
        <br />
      </p>
      <p>E-posta: info@rexven.com</p>
      <p>
        <br />
      </p>
      <p>
        Adres: Mutlukent Mah. 2041 Sok. No :5/1
        &Ccedil;ankaya/Ankara
      </p>
      <p>
        <br />
      </p>
      <p>Tel: 0850 532 35 76</p>
      <p>
        <br />
      </p>
      <p>5. ALICI BİLGİLERİ</p>
      <p>
        <br />
      </p>
      <p>Ad- Soyad:</p>
      <p>
        <br />
      </p>
      <p>Adres:</p>
      <p>
        <br />
      </p>
      <p>Telefon numarası:</p>

      <p>Eposta/kullanıcı adı:&nbsp;</p>
      <p>
        <br />
      </p>
      <p>
        7. S&Ouml;ZLEŞME KONUSU &Uuml;R&Uuml;N/HİZMET, &Ouml;DEME VE TESLİMATA
        İLİŞKİN BİLGİLER
      </p>
      <p>
        <br />
      </p>
      <p>
        7.1. Malın/&Uuml;r&uuml;n&uuml;n/&Uuml;r&uuml;nlerin/Hizmetin temel
        &ouml;zelliklerini (t&uuml;r&uuml;) SATICI&rsquo;ya ait internet
        sitesinde (rexven.com) yayınlanmaktadır. Satıcı tarafından kampanya
        d&uuml;zenlenmiş ise ilgili &uuml;r&uuml;n&uuml;n temel
        &ouml;zelliklerini kampanya s&uuml;resince inceleyebilirsiniz. Kampanya
        tarihine kadar ge&ccedil;erlidir. S&ouml;zleşmeye konu &uuml;r&uuml;n,
        elektronik ticaret konusunda kurulmuş ekosisteme aylık veya yıllık
        abonelik olup, rexven.com web sitesinde yer alan &ccedil;eşitli
        &uuml;r&uuml;nleri/hizmetleri kapsamaktadır. Alıcı &uuml;r&uuml;nlerden
        birini se&ccedil;erek alış-verişini tamamlar. Aylık aboneliklerde 30
        g&uuml;n boyunca, yıllık aboneliklerde 365 g&uuml;n boyunca rexven.com
        web sitesinde yer alan dijital i&ccedil;eriklere erişebilecektir.
        Yapılan &ouml;deme, 30 g&uuml;nl&uuml;k veya 365 g&uuml;nl&uuml;k
        erişimi kapsar. Abonelik sisteminde aboneliğinizi fatura kesim
        tarihinden &ouml;nce iptal etmediğiniz s&uuml;rece, tarafımıza bir
        sonraki faturalama d&ouml;neminin abonelik &uuml;cretini &Ouml;deme
        Y&ouml;ntemi &uuml;zerinden tahsil etme yetkisi vermiş olursunuz. (bkz.
        &quot;Kullanım Koşulları&quot;). &nbsp;
      </p>
      <p>
        <br />
      </p>
      <p>
        7.2. Listelenen ve sitede ilan edilen fiyatlar satış fiyatıdır. İlan
        edilen fiyatlar ve vaatler g&uuml;ncelleme yapılana ve değiştirilene
        kadar ge&ccedil;erlidir. S&uuml;reli olarak ilan edilen fiyatlar ise
        belirtilen s&uuml;re sonuna kadar ge&ccedil;erlidir.
      </p>
      <p>
        <br />
      </p>
      <p>
        7.3. S&ouml;zleşme konusu mal ya da hizmetin t&uuml;m vergiler dahil
        satış fiyatı aşağıda g&ouml;sterilmiştir.
      </p>
      <div>
        <table className="w-full">
          <tr>
            <th className="border-2 border-black">Ürün açıklaması</th>
            <th className="border-2 border-black">Adet</th>
            <th className="border-2 border-black">Birim Fiyatı</th>
            <th className="border-2 border-black">Toplam Tutar (KDV Dahil)</th>
          </tr>

          <tr>
            <td className="border-2 border-black">
              <p className="h-4"> </p>
            </td>
            <td className="border-2 border-black">
              <p className="h-4"> </p>
            </td>
            <td className="border-2 border-black">
              <p className="h-4"> </p>
            </td>
            <td className="border-2 border-black">
              <p className="h-4"> </p>
            </td>
          </tr>
        </table>
      </div>
      <p>
        <br />
      </p>
      <p>
        &Ouml;deme Şekli: Kredi kartı veya banka kartı ile İYZİCO &uuml;zerinden
        yapılan &ouml;deme işlemi.
      </p>
      <p>
        <br />
      </p>
      <p>
        *Bankanız kampanyalar d&uuml;zenleyerek sizin se&ccedil;tiğiniz taksit
        adedinin daha &uuml;st&uuml;nde bir taksit adedi uygulayabilir, taksit
        &ouml;teleme gibi hizmetler sunulabilir. Bu t&uuml;r kampanyalar
        bankanızın inisiyatifindedir ve şirketimizin bilgisi d&acirc;hilinde
        olması durumunda sayfalarımızda kampanyalar hakkında bilgi
        verilmektedir.
      </p>
      <p>
        Kredi kartınızın hesap kesim tarihinden itibaren sipariş toplamı taksit
        adedine b&ouml;l&uuml;nerek kredi kartı &ouml;zetinize bankanız
        tarafından yansıtılacaktır. Banka taksit tutarlarını k&uuml;surat
        farklarını dikkate alarak aylara eşit olarak dağıtmayabilir. Detaylı
        &ouml;deme planınızın oluşturulması bankanız inisiyatifindedir.
      </p>
      <p>
        *Diğer yandan vadeli satışların sadece Bankalara ait kredi kartları ile
        yapılması nedeniyle, T&Uuml;KETİCİ, ilgili faiz oranlarını ve
        temerr&uuml;t faizi ile ilgili bilgileri bankasından ayrıca teyit
        edeceğini, y&uuml;r&uuml;rl&uuml;kte bulunan mevzuat h&uuml;k&uuml;mleri
        gereğince faiz ve temerr&uuml;t faizi ile ilgili h&uuml;k&uuml;mlerin
        Banka ve T&Uuml;KETİCİ arasındaki kredi kartı s&ouml;zleşmesi kapsamında
        uygulanacağını kabul, beyan ve taahh&uuml;t eder.
      </p>
      <p>
        <br />
      </p>
      <p>Sipariş veren kişi:</p>
      <p>
        <br />
      </p>
      <p>Fatura Adresi:</p>
      <p>
        <br />
      </p>
      <p>Sipariş Tarihi:</p>
      <p>
        <br />
      </p>
      <p>
        Teslimat tarihi ve şekli: S&ouml;zleşmeye konu hizmet dijital
        i&ccedil;erik hizmeti olduğu i&ccedil;in, T&Uuml;KETİCİ satın alım
        işlemini yaptığı an hizmetten faydalanmaya başlayabilir.
      </p>
      <p>
        <br />
      </p>
      <p>
        7.4. &nbsp;&Uuml;r&uuml;n internet ortamında yer alan yazılım
        &uuml;zerinde abonelik vb. bir &uuml;r&uuml;n/hizmet olduğu i&ccedil;in
        sevkiyat masrafı, kargo &uuml;creti yoktur.&nbsp;
      </p>
      <p>
        <br />
      </p>
      <p>8. FATURA BİLGİLERİ</p>
      <p>
        <br />
      </p>
      <p>Ad - Soyad:</p>
      <p>
        <br />
      </p>
      <p>Adres:</p>

      <p>Telefon:</p>
      <p>
        <br />
      </p>
      <p>Eposta/kullanıcı adı:</p>
      <p>
        <br />
      </p>
      <p>
        Fatura teslim: Fatura sipariş teslimatı sırasında fatura adresine
        sipariş ile birlikte teslim edilecektir.
      </p>
      <p>
        <br />
      </p>
      <p>9. GENEL H&Uuml;K&Uuml;MLER</p>
      <p>
        <br />
      </p>
      <p>
        9.1. ALICI, SATICI&rsquo;ya ait internet sitesinde s&ouml;zleşme konusu
        &uuml;r&uuml;n&uuml;n temel nitelikleri, satış fiyatı ve &ouml;deme
        şekli ile teslimata ilişkin &ouml;n bilgileri okuyup, bilgi sahibi
        olduğunu, elektronik ortamda gerekli teyidi verdiğini kabul, beyan ve
        taahh&uuml;t eder. ALICI&rsquo;nın; &Ouml;n Bilgilendirmeyi elektronik
        ortamda teyit etmesi, mesafeli satış s&ouml;zleşmesinin kurulmasından
        evvel, SATICI tarafından ALICI&rsquo; ya verilmesi gereken adresi,
        siparişi verilen &uuml;r&uuml;nlere ait temel &ouml;zellikleri,
        &uuml;r&uuml;nlerin vergiler dahil fiyatını, &ouml;deme ve teslimat
        bilgilerini de doğru ve eksiksiz olarak edindiğini kabul, beyan ve
        taahh&uuml;t eder.
      </p>
      <p>
        9.2. S&ouml;zleşme konusu her bir &uuml;r&uuml;n, 30 g&uuml;nl&uuml;k
        yasal s&uuml;reyi aşmamak kaydı ile ALICI&rsquo;nın yerleşim yeri
        uzaklığına bağlı olarak internet sitesindeki &ouml;n bilgiler kısmında
        belirtilen s&uuml;re zarfında ALICI veya ALICI&rsquo;nın g&ouml;sterdiği
        adresteki kişi ve/veya kuruluşa teslim edilir. Bu s&uuml;re
        i&ccedil;inde &uuml;r&uuml;n&uuml;n ALICI&rsquo;ya teslim edilememesi
        durumunda, ALICI&rsquo;nın s&ouml;zleşmeyi feshetme hakkı saklıdır.
      </p>
      <p>
        9.3. SATICI, S&ouml;zleşme konusu &uuml;r&uuml;n&uuml; eksiksiz,
        siparişte belirtilen niteliklere uygun ve varsa garanti belgeleri,
        kullanım kılavuzları işin gereği olan bilgi ve belgeler ile teslim
        etmeyi, her t&uuml;rl&uuml; ayıptan ar&icirc; olarak yasal mevzuat
        gereklerine g&ouml;re sağlam, standartlara uygun bir şekilde işi
        doğruluk ve d&uuml;r&uuml;stl&uuml;k esasları dahilinde ifa etmeyi,
        hizmet kalitesini koruyup y&uuml;kseltmeyi, işin ifası sırasında gerekli
        dikkat ve &ouml;zeni g&ouml;stermeyi, ihtiyat ve &ouml;ng&ouml;r&uuml;
        ile hareket etmeyi kabul, beyan ve taahh&uuml;t eder.
      </p>
      <p>
        9.4. SATICI, s&ouml;zleşmeden doğan ifa
        y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml;n&uuml;n s&uuml;resi dolmadan
        ALICI&rsquo;yı bilgilendirmek ve a&ccedil;ık&ccedil;a onayını almak
        suretiyle eşit kalite ve fiyatta farklı bir &uuml;r&uuml;n tedarik
        edebilir.
      </p>
      <p>
        9.5. SATICI, sipariş konusu &uuml;r&uuml;n veya hizmetin yerine
        getirilmesinin imk&acirc;nsızlaşması halinde s&ouml;zleşme konusu
        y&uuml;k&uuml;ml&uuml;l&uuml;klerini yerine getiremezse, bu durumu,
        &ouml;ğrendiği tarihten itibaren 3 g&uuml;n i&ccedil;inde yazılı olarak
        t&uuml;keticiye bildireceğini, 14 g&uuml;nl&uuml;k s&uuml;re
        i&ccedil;inde toplam bedeli ALICI&rsquo;ya iade edeceğini kabul, beyan
        ve taahh&uuml;t eder.&nbsp;
      </p>
      <p>
        9.6. ALICI, S&ouml;zleşme konusu &uuml;r&uuml;n&uuml;n teslimatı
        i&ccedil;in işbu S&ouml;zleşme&rsquo;yi elektronik ortamda teyit
        edeceğini, herhangi bir nedenle s&ouml;zleşme konusu &uuml;r&uuml;n
        bedelinin &ouml;denmemesi ve/veya banka kayıtlarında iptal edilmesi
        halinde, SATICI&rsquo;nın s&ouml;zleşme konusu &uuml;r&uuml;n&uuml;
        teslim y&uuml;k&uuml;ml&uuml;l&uuml;ğ&uuml;n&uuml;n sona ereceğini
        kabul, beyan ve taahh&uuml;t eder.
      </p>
      <p>
        9.7. ALICI, S&ouml;zleşme konusu &uuml;r&uuml;n&uuml;n ALICI veya
        ALICI&rsquo;nın g&ouml;sterdiği adresteki kişi ve/veya kuruluşa
        tesliminden sonra ALICI&rsquo;ya ait kredi kartının yetkisiz kişilerce
        haksız kullanılması sonucunda s&ouml;zleşme konusu &uuml;r&uuml;n
        bedelinin ilgili banka veya finans kuruluşu tarafından SATICI&rsquo;ya
        &ouml;denmemesi halinde, SATICI &uuml;r&uuml;n&uuml;n/hizmetin
        elektronik ortamda iptalini ger&ccedil;ekleştirebilir. ALICI bu maddeyi
        kabul, beyan ve taahh&uuml;t eder.
      </p>
      <p>
        9.8. SATICI, tarafların iradesi dışında gelişen, &ouml;nceden
        &ouml;ng&ouml;r&uuml;lemeyen ve tarafların bor&ccedil;larını yerine
        getirmesini engelleyici ve/veya geciktirici hallerin oluşması gibi
        m&uuml;cbir sebepler halleri nedeni ile s&ouml;zleşme konusu
        &uuml;r&uuml;n&uuml; s&uuml;resi i&ccedil;inde teslim edemez ise, durumu
        ALICI&rsquo;ya bildireceğini kabul, beyan ve taahh&uuml;t eder. ALICI da
        siparişin iptal edilmesini, s&ouml;zleşme konusu &uuml;r&uuml;n&uuml;n
        varsa emsali ile değiştirilmesini ve/veya teslimat s&uuml;resinin
        engelleyici durumun ortadan kalkmasına kadar ertelenmesini
        SATICI&rsquo;dan talep etme hakkını haizdir. ALICI tarafından siparişin
        iptal edilmesi halinde ALICI&rsquo;nın nakit ile yaptığı
        &ouml;demelerde, &uuml;r&uuml;n tutarı 14 g&uuml;n i&ccedil;inde
        kendisine nakden ve defaten &ouml;denir. ALICI&rsquo;nın kredi kartı ile
        yaptığı &ouml;demelerde ise, &uuml;r&uuml;n tutarı, siparişin ALICI
        tarafından iptal edilmesinden sonra 14 g&uuml;n i&ccedil;erisinde ilgili
        bankaya iade edilir. ALICI, SATICI tarafından kredi kartına iade edilen
        tutarın banka tarafından ALICI hesabına yansıtılmasına ilişkin ortalama
        s&uuml;recin 2 ile 3 haftayı bulabileceğini, bu tutarın bankaya
        iadesinden sonra ALICI&rsquo;nın hesaplarına yansıması halinin tamamen
        banka işlem s&uuml;reci ile ilgili olduğundan, ALICI, olası gecikmeler
        i&ccedil;in SATICI&rsquo;yı sorumlu tutamayacağını kabul, beyan ve
        taahh&uuml;t eder.
      </p>
      <p>
        9.9. SATICININ, ALICI tarafından siteye kayıt formunda belirtilen veya
        daha sonra kendisi tarafından g&uuml;ncellenen adresi, e-posta adresi,
        sabit ve mobil telefon hatları ve diğer iletişim bilgileri
        &uuml;zerinden mektup, e-posta, SMS, telefon g&ouml;r&uuml;şmesi ve
        diğer yollarla iletişim, pazarlama, bildirim ve diğer ama&ccedil;larla
        ALICI&rsquo;ya ulaşma hakkı bulunmaktadır. ALICI, işbu s&ouml;zleşmeyi
        kabul etmekle SATICI&rsquo;nın kendisine y&ouml;nelik yukarıda
        belirtilen iletişim faaliyetlerinde bulunabileceğini kabul ve beyan
        etmektedir.
      </p>
      <p>
        9.10. ALICI ile sipariş esnasında kullanılan kredi kartı hamilinin aynı
        kişi olmaması veya &uuml;r&uuml;n&uuml;n ALICI&rsquo;ya tesliminden
        evvel, siparişte kullanılan kredi kartına ilişkin g&uuml;venlik
        a&ccedil;ığı tespit edilmesi halinde, SATICI, kredi kartı hamiline
        ilişkin kimlik ve iletişim bilgilerini, siparişte kullanılan kredi
        kartının bir &ouml;nceki aya ait ekstresini yahut kart hamilinin
        bankasından kredi kartının kendisine ait olduğuna ilişkin yazıyı ibr/az
        etmesini ALICI&rsquo;dan talep edebilir. ALICI&rsquo;nın talebe konu
        bilgi/belgeleri temin etmesine kadar ge&ccedil;ecek s&uuml;rede sipariş
        dondurulacak olup, mezkur taleplerin 24 saat i&ccedil;erisinde
        karşılanmaması halinde ise SATICI, siparişi iptal etme hakkını haizdir.
      </p>
      <p>
        9.11. ALICI, SATICI&rsquo;ya ait internet sitesine &uuml;ye olurken
        verdiği kişisel ve diğer sair bilgilerin ger&ccedil;eğe uygun olduğunu,
        SATICI&rsquo;nın bu bilgilerin ger&ccedil;eğe aykırılığı nedeniyle
        uğrayacağı t&uuml;m zararları, SATICI&rsquo;nın ilk bildirimi
        &uuml;zerine derhal, nakden ve defaten tazmin edeceğini beyan ve
        taahh&uuml;t eder.
      </p>
      <p>
        9.12. ALICI, SATICI&rsquo;ya ait internet sitesini kullanırken yasal
        mevzuat h&uuml;k&uuml;mlerine riayet etmeyi ve bunları ihlal etmemeyi
        baştan kabul ve taahh&uuml;t eder. Aksi takdirde, doğacak t&uuml;m
        hukuki ve cezai y&uuml;k&uuml;ml&uuml;l&uuml;kler tamamen ve
        m&uuml;nhasıran ALICI&rsquo;yı bağlayacaktır.
      </p>
      <p>
        9.13. ALICI, SATICI&rsquo;ya ait internet sitesini hi&ccedil;bir şekilde
        kamu d&uuml;zenini bozucu, genel ahlaka aykırı, başkalarını rahatsız ve
        taciz edici şekilde, yasalara aykırı bir ama&ccedil; i&ccedil;in,
        başkalarının maddi ve manevi haklarına tecav&uuml;z edecek şekilde
        kullanamaz. Ayrıca, &uuml;ye başkalarının hizmetleri kullanmasını
        &ouml;nleyici veya zorlaştırıcı faaliyet (spam, virus, truva atı, vb.)
        işlemlerde bulunamaz.
      </p>
      <p>
        9.14. SATICI&rsquo;ya ait internet sitesinin &uuml;zerinden,
        SATICI&rsquo;nın kendi kontrol&uuml;nde olmayan ve/veya başkaca
        &uuml;&ccedil;&uuml;nc&uuml; kişilerin sahip olduğu ve/veya işlettiği
        başka web sitelerine ve/veya başka i&ccedil;eriklere link verilebilir.
        Bu linkler ALICI&rsquo;ya y&ouml;nlenme kolaylığı sağlamak amacıyla
        konmuş olup herhangi bir web sitesini veya o siteyi işleten kişiyi
        desteklememekte ve Link verilen web sitesinin i&ccedil;erdiği bilgilere
        y&ouml;nelik herhangi bir garanti niteliği taşımamaktadır.
      </p>
      <p>
        9.16. İşbu s&ouml;zleşme i&ccedil;erisinde sayılan maddelerden bir ya da
        birka&ccedil;ını ihlal eden &uuml;ye işbu ihlal nedeniyle cezai ve
        hukuki olarak şahsen sorumlu olup, SATICI&rsquo;yı bu ihlallerin hukuki
        ve cezai sonu&ccedil;larından ari tutacaktır. Ayrıca; işbu ihlal
        nedeniyle, olayın hukuk alanına intikal ettirilmesi halinde,
        SATICI&rsquo;nın &uuml;yeye karşı &uuml;yelik s&ouml;zleşmesine
        uyulmamasından dolayı tazminat talebinde bulunma hakkı saklıdır.
      </p>
      <p>
        <br />
      </p>
      <p>10. CAYMA HAKKI</p>
      <p>
        <br />
      </p>
      <p>
        T&Uuml;KETİCİ , SATICI ile imzaladığı işbu Mesafeli Satış
        S&ouml;zleşmesi&apos;nden 7 (yedi) g&uuml;n i&ccedil;inde
        herhangi bir gerek&ccedil;e g&ouml;stermeksizin ve cezai şart
        &ouml;demeksizin cayma hakkına sahiptir. Cayma hakkı s&uuml;resi, hizmet
        ifasına ilişkin s&ouml;zleşmelerde s&ouml;zleşmenin kurulduğu g&uuml;n;
        mal teslimine ilişkin s&ouml;zleşmelerde ise T&Uuml;KETİCİ&apos;nin veya
        T&Uuml;KETİCİ tarafından belirlenen &uuml;&ccedil;&uuml;nc&uuml; kişinin
        malı teslim aldığı g&uuml;n başlar. Ancak T&Uuml;KETİCİ,
        s&ouml;zleşmenin kurulmasından malın teslimine kadar olan s&uuml;re
        i&ccedil;inde de cayma hakkını kullanabilir. Cayma hakkı s&uuml;resinin
        belirlenmesinde;
      </p>
      <p>&nbsp;</p>
      <p>
        a) Tek sipariş konusu olup ayrı ayrı teslim edilen mallarda,
        T&Uuml;KETİCİ&apos;nin veya T&Uuml;KETİCİ tarafından belirlenen
        &uuml;&ccedil;&uuml;nc&uuml; kişinin son malı teslim aldığı g&uuml;n,
      </p>
      <p>&nbsp;</p>
      <p>
        b) Birden fazla par&ccedil;adan oluşan mallarda, T&Uuml;KETİCİ&apos;nin
        veya T&Uuml;KETİCİ tarafından belirlenen &uuml;&ccedil;&uuml;nc&uuml;
        kişinin son par&ccedil;ayı teslim aldığı g&uuml;n,
      </p>
      <p>&nbsp;</p>
      <p>
        c) Belirli bir s&uuml;re boyunca malın d&uuml;zenli tesliminin yapıldığı
        s&ouml;zleşmelerde, T&Uuml;KETİCİ&apos;nin veya T&Uuml;KETİCİ tarafından
        belirlenen &uuml;&ccedil;&uuml;nc&uuml; kişinin ilk malı teslim aldığı
        g&uuml;n esas alınır. Cayma bildiriminizi cayma hakkı s&uuml;resi
        dolmadan www.rexven.com &apos;da yer alan&nbsp;
        <a href="mailto:info@rexven.com">info@rexven.com</a> mail adresi
        &uuml;zerinden bizlerle iletişime ge&ccedil;erek yapabilirsiniz.&nbsp;
      </p>
      <p>
        <br />
      </p>
      <p>
        T&uuml;ketici aşağıdaki s&ouml;zleşmelerde cayma hakkını kullanamaz:
      </p>
      <p>&nbsp;</p>
      <p>
        a) Fiyatı finansal piyasalardaki dalgalanmalara bağlı olarak değişen ve
        SATICI veya sağlayıcının kontrol&uuml;nde olmayan mal veya hizmetlere
        ilişkin s&ouml;zleşmeler.
      </p>
      <p>&nbsp;</p>
      <p>
        b) T&uuml;keticinin istekleri veya kişisel ihtiya&ccedil;ları
        doğrultusunda hazırlanan mallara ilişkin s&ouml;zleşmeler.
      </p>
      <p>&nbsp;</p>
      <p>
        c) &Ccedil;abuk bozulabilen veya son kullanma tarihi ge&ccedil;ebilecek
        malların teslimine ilişkin s&ouml;zleşmeler.
      </p>
      <p>&nbsp;</p>
      <p>
        &ccedil;) Tesliminden sonra ambalaj, bant, m&uuml;h&uuml;r, paket gibi
        koruyucu unsurları a&ccedil;ılmış olan mallardan; iadesi sağlık ve
        hijyen a&ccedil;ısından uygun olmayanların teslimine ilişkin
        s&ouml;zleşmeler.
      </p>
      <p>&nbsp;</p>
      <p>
        d) Tesliminden sonra başka &uuml;r&uuml;nlerle karışan ve doğası gereği
        ayrıştırılması m&uuml;mk&uuml;n olmayan mallara ilişkin
        s&ouml;zleşmeler.
      </p>
      <p>&nbsp;</p>
      <p>
        e) Malın tesliminden sonra ambalaj, bant, m&uuml;h&uuml;r, paket gibi
        koruyucu unsurları a&ccedil;ılmış olması halinde maddi ortamda sunulan
        kitap, dijital i&ccedil;erik ve bilgisayar sarf malzemelerine ilişkin
        s&ouml;zleşmeler.
      </p>
      <p>&nbsp;</p>
      <p>
        f) Abonelik s&ouml;zleşmesi kapsamında sağlananlar dışında, gazete ve
        dergi gibi s&uuml;reli yayınların teslimine ilişkin s&ouml;zleşmeler.
      </p>
      <p>&nbsp;</p>
      <p>
        g) Belirli bir tarihte veya d&ouml;nemde yapılması gereken, konaklama,
        eşya taşıma, araba kiralama, yiyecek-i&ccedil;ecek tedariki ve eğlence
        veya dinlenme amacıyla yapılan boş zamanın değerlendirilmesine ilişkin
        s&ouml;zleşmeler.
      </p>
      <p>&nbsp;</p>
      <p>
        ğ) Elektronik ortamda anında ifa edilen hizmetler veya
        T&Uuml;KETİCİ&apos;ye anında teslim edilen gayrimaddi mallara ilişkin
        s&ouml;zleşmeler.
      </p>
      <p>&nbsp;</p>
      <p>
        h) Cayma hakkı s&uuml;resi sona ermeden &ouml;nce,
        T&Uuml;KETİCİ&apos;nin onayı ile ifasına başlanan hizmetlere ilişkin
        s&ouml;zleşmeler.
      </p>
      <p>
        <br />
      </p>
      <p>
        6502 sayılı Kanun ve Mesafeli S&ouml;zleşmeler Y&ouml;netmeliği gereği,
        elektronik ortamda anında ifa edilen hizmetler veya t&uuml;keticiye
        anında teslim edilen gayrimaddi mallar cayma hakkının istisnalarıdır. Bu
        bağlamda, işbu s&ouml;zleşme konusu &uuml;r&uuml;n hakkında mevzuat
        gereği iade veya iptal yapılamamaktadır.
      </p>
      <p>
        <br />
      </p>
      <p>11. TEMERR&Uuml;T HALİ VE HUKUKİ SONU&Ccedil;LARI</p>
      <p>
        ALICI, &ouml;deme işlemlerini kredi kartı ile yaptığı durumda
        temerr&uuml;de d&uuml;şt&uuml;ğ&uuml; takdirde, kart sahibi banka ile
        arasındaki kredi kartı s&ouml;zleşmesi &ccedil;er&ccedil;evesinde faiz
        &ouml;deyeceğini ve bankaya karşı sorumlu olacağını kabul, beyan ve
        taahh&uuml;t eder. Bu durumda ilgili banka hukuki yollara başvurabilir;
        doğacak masrafları ve vek&acirc;let &uuml;cretini ALICI&rsquo;dan talep
        edebilir ve her koşulda ALICI&rsquo;nın borcundan dolayı temerr&uuml;de
        d&uuml;şmesi halinde, ALICI, borcun gecikmeli ifasından dolayı
        SATICI&rsquo;nın uğradığı zarar ve ziyanını &ouml;deyeceğini kabul,
        beyan ve taahh&uuml;t eder.
      </p>
      <p>
        <br />
      </p>
      <p>12. YETKİLİ MAHKEME</p>
      <p>
        12.1. İşbu s&ouml;zleşmeden doğan uyuşmazlıklarda şikayet ve itirazlar,
        ilgili kanunda belirtilen parasal sınırlar d&acirc;hilinde
        t&uuml;keticinin yerleşim yerinin bulunduğu veya t&uuml;ketici işleminin
        yapıldığı yerdeki t&uuml;ketici sorunları hakem heyetine veya
        t&uuml;ketici mahkemesine yapılacaktır.&nbsp;
      </p>
      <p>
        <br />
      </p>
      <p>13. Y&Uuml;R&Uuml;RL&Uuml;K</p>
      <p>
        ALICI, Site &uuml;zerinden verdiği siparişe ait &ouml;demeyi
        ger&ccedil;ekleştirdiğinde işbu s&ouml;zleşmenin t&uuml;m şartlarını
        kabul etmiş sayılır. SATICI, siparişin ger&ccedil;ekleşmesi
        &ouml;ncesinde işbu s&ouml;zleşmenin sitede ALICI tarafından okunup
        kabul edildiğine dair onay alacak şekilde gerekli yazılımsal
        d&uuml;zenlemeleri yapmakla y&uuml;k&uuml;ml&uuml;d&uuml;r.
      </p>
      <p>
        <br />
      </p>
      <p>SATICI: Rexven Teknoloji A.Ş.</p>
      <p>ALICI:</p>
      <p>TARİH:</p>
      <p>
        <br />
      </p>
      <p>
        <br />
      </p>
    </div>
  );
};

export default SalesPolicy;
