import React from 'react';
import { seperateNumberWithComma } from '../../../../utils/numberFormatter';

interface CustomCardChartProps {
    value: number;
    label: string;
    color: string;
    textColor: string;
    fontWeight: string;
    valueColor: string
}

const CustomCardChart: React.FC<CustomCardChartProps> = ({
    value,
    label,
    color,
    textColor,
    fontWeight,
    valueColor
}) => {
    const containerStyle = {
        border: `20px solid ${color}`,
    };

    const labelStyle = {
        color: textColor,
        fontWeight: fontWeight,
        marginBottom: '0',
    };

    const valueStyle = {
        color: valueColor,
        fontWeight: 'bold',
        marginTop: '-1.5rem', // Value'yu yukarı doğru biraz taşı
    };

    return (
        <div>
            <div
                className="flex flex-col items-center w-44 h-44 border-2 rounded-full overflow-hidden"
                style={containerStyle}
            >
                <div className="flex justify-center items-center h-24" style={labelStyle}>
                    <p>{label}</p>
                </div>
                <p className={`${value < 1000 ? 'text-2xl' : value > 1000 ? 'text-xl' : value > 10000 ? 'text-md' : 'text-sm'}`} style={valueStyle}>
                    {seperateNumberWithComma(value)}{label === 'Kar' ? ' ₺' : label === 'Zarar' ? ' ₺' : ''}
                </p>
            </div>
        </div>
    );
};
export default CustomCardChart;

