import React, { useEffect } from 'react';
import TrendyolProductsTable from '../components/trendyolProducts/TrendyolProductsTable';
import memberService from '../services/memberService';
import { useAuth } from '../context/AuthContext';

const TrendyolMyProducts = () => {
  const { session } = useAuth();
  const [isTabActive, setIsTabActive] = React.useState<boolean>(true);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabActive(document.visibilityState === 'visible');
    };

    // Add event listener for visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup function to remove the event listener when the component is unmounted
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    let intervalId: any;
    if (session && isTabActive) {
      intervalId = setInterval(async () => {
        await memberService.userTrackReq(`trendyol-my-products`, session?.id!);
      }, 60000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [session, isTabActive]);
  return (
    <div>
      <TrendyolProductsTable />
    </div>
  );
};

export default TrendyolMyProducts;
