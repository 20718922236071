import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useLocation, useParams } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';

import NumVerifyModal from '../components/numberVerifyModals/NumVerifyModal';
import RegisterService from '../components/serviceProviders/RegisterService';
import ServiceMemberWrapper from '../components/serviceProviders/ServiceMemberWrapper';
import ServiceProviderBtn from '../components/serviceProviders/ServiceProviderBtn';
import SimilarServices from '../components/serviceProviders/SimilarServices';
import TitleBanner from '../components/tools/common/TitleBanner';
import BuyModal from '../components/tools/etsy/BuyModal';
import { useAuth } from '../context/AuthContext';
import { useNumberVerification } from '../context/NumberVerificationContext';
import { IRootAllServices, IServiceDesc } from '../interfaces/serviceProviders';
import memberService from '../services/memberService';
import producerService from '../services/producerService';
import alertNotification from '../utils/alertNotification';
import ErrorFallback from './errors/ErrorFallback';

const ServiceProviderDetails = () => {
  const params = useParams();
  const { id } = params;

  const location = useLocation();
  const { handleActiveModal } = useNumberVerification();
  const { session } = useAuth();
  const [open, setOpen] = useState(false);
  const [openBuy, setOpenBuy] = useState(false);
  const [service, setService] = useState<IServiceDesc>();
  const [isTabActive, setIsTabActive] = React.useState<boolean>(true);
  const handleClick = () => {
    if (!session?.hasPackage) {
      setOpenBuy(true);
    } else {
      if (!session?.isGsmVerified) {
        ///open gsm verification
        handleActiveModal?.();
      } else {
        setOpen(true);
      }
    }
  };

  // const { data } = useQuery<IRootServiceDesc, Error>(['serviceDescription'], {
  //   queryFn: () => memberService.getServiceDescripton(id),
  //   staleTime: 1000,
  // });

  const getServiceDescription = async () => {
    try {
      const res = await memberService.getServiceDescripton(id);

      setService(res.data);
    } catch (error: any) {
      alertNotification('error', error.response.data);
    }
  };

  // const renderDescription = (desc: string) => {
  //   return { __html: desc };
  // };

  const updateService = async () => {
    try {
      await memberService.updateStatistics(id!);
    } catch (error: any) {
      alertNotification('error', error.response.data.message);
    }
  };

  const { data: similarServices } = useQuery<IRootAllServices, Error>(
    ['similarServices', location.state.catId],
    {
      queryFn: () =>
        memberService.getSimilarServiceProviders(4, location.state.catId),
      staleTime: 10000,
    }
  );
  useEffect(() => {
    updateService();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabActive(document.visibilityState === 'visible');
    };

    // Add event listener for visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup function to remove the event listener when the component is unmounted
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    let intervalId: any;
    if (session && isTabActive) {
      intervalId = setInterval(async () => {
        await memberService.userTrackReq(
          `service-provider-details`,
          session?.id!
        );
      }, 60000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [session, isTabActive]);
  useEffect(() => {
    getServiceDescription();
  }, [id]);
  return (
    <div className="w-full">
      <TitleBanner
        title={service?.serviceName!}
        imageUrl="/images/danismanlik-bg.webp"
      />
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <div className="grid grid-cols-12 w-full gap-x-4 mt-16">
          <div className="col-span-12 xl:col-span-10">
            {/* <div
              dangerouslySetInnerHTML={renderDescription(service?.description!)}
              className="w-full border-2 border-cardBorder rounded-xl px-4 py-8"
            ></div> */}
            <p className="text-primary text-3xl font-bold my-10">
              Hizmet Sağlayıcılar
            </p>

            <ServiceMemberWrapper />
          </div>
          <div className="hidden xl:col-span-2 xl:block">
            <ServiceProviderBtn setOpen={handleClick} />

            {similarServices?.data.length ? (
              <SimilarServices data={similarServices} />
            ) : null}
          </div>
        </div>
      </ErrorBoundary>

      <RegisterService open={open} setOpen={setOpen} />
      <BuyModal
        specialString="Hizmet sağlayıcı olarak 25,000 den fazla girişimciye destek vermek için Rexven’e abone olunuz"
        open={openBuy}
        setOpen={setOpenBuy}
      />
      <NumVerifyModal />
    </div>
  );
};

export default ServiceProviderDetails;
