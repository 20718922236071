import React from 'react';

export interface Props {
  bgColor: string;
  headingColor: string;
  textColor: string;
  title: string;
  content: string | React.ReactNode;
}
const PackageCard: React.FC<Props> = ({
  bgColor,
  headingColor,
  textColor,
  title,
  content,
}) => {
  return (
    <div
      className={`col-span-3  text-xs rounded-xl p-4 lg:text-sm`}
      style={{ backgroundColor: bgColor }}
    >
      <h3 className="text-base font-medium" style={{ color: headingColor }}>
        {title}
      </h3>
      <p style={{ color: textColor }}>{content}</p>
    </div>
  );
};

export default PackageCard;
