import React from 'react'
import { SummaryItem } from './DummyDatasAndTypes'

interface SummaryCardsProps {
    summary?: SummaryItem[]
    isLoading: boolean
}
const SummaryCards: React.FC<SummaryCardsProps> = ({ summary, isLoading }) => {

    return (
        <div className='grid gap-4 grid-cols-10 justify-between items-center mt-5'>
            {summary?.map((sum) => {
                return (
                    <div className='shadow-md col-span-10 md:col-span-5 lg:col-span-5 xl:col-span-2 border rounded-md grid gap-2 grid-cols-8 items-center justify center px-2 py-1 min-h-[100px]'>
                        <div className="col-span-3 m-auto" >
                            <sum.iconName className=' w-10 h-10 font-extrabold text-3xl' style={{ color: `${sum.iconColor}` }} />
                        </div>
                        <div className="col-span-5">
                            <div className='font-normal' style={{ color: `${sum.titleColor}` }}>
                                {sum.titleText}
                            </div>
                            {isLoading ?
                                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                    <path className={`opacity-75 text-[${sum.amountColor}]`} fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                </svg> :
                                <div className={`font-medium `} style={{ color: `${sum.amountColor}` }}>
                                    {sum.amountText}{sum.titleText === 'Kar Marjı' || sum.titleText === 'Kar Oranı' ? ' %' : " ₺"}
                                </div>
                            }

                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export default SummaryCards