import React from 'react';
import { ProfitCalc } from '../../../pages/EtsyProfitCalculator';
import { Information } from 'iconsax-react';
import { Tooltip } from 'react-tooltip';
import { useLanguage } from '../../../context/LanguageContext';

interface EstyCostProps {
  profitCalc: ProfitCalc | undefined;
  currency: string;
}
const EtsyCostTable: React.FC<EstyCostProps> = ({ profitCalc, currency }) => {
  const { language } = useLanguage();
  return (
    <>
      <h1 className="w-full border border-[#FF9E66] text-xl font-bold text-[#F45900] text-center rounded-sm bg-[#FFDFCC] p-1 mb-2">
        {language === 'tr' ? 'ETSY KESİNTİSİ' : 'ETSY FEES'}
      </h1>
      {/* Listeleme Ücreti */}
      <div className="flex items-center mb-2">
        <div className="w-[120%] rounded-sm border border-[#FCE3CF] px-2 py-2 shadow-sm text-xs min-[1335px]:text-sm bg-[#FEF1E7] mr-1">
          <div className="flex justify-between">
            <span>
              {language === 'tr' ? 'Listeleme Ücreti' : 'Listing Fee'}{' '}
            </span>
            <Information
              size={20}
              className="cursor-pointer"
              id="listingResultToolTip"
            />
            {language === 'tr' ? (
              <Tooltip anchorSelect="#listingResultToolTip">
                Mağazanıza tanımlı ücretsiz listeme hakkı yok ise $0,20 ya da
                değerindeki kesintidir.
              </Tooltip>
            ) : (
              <Tooltip anchorSelect="#listingResultToolTip">
                If your store does not have the right to my free listing, $0.20
                or is the deduction in value.
              </Tooltip>
            )}
          </div>
        </div>
        <p className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white">
          {currency} {profitCalc ? profitCalc.listingFee : 0}
        </p>
      </div>
      {/* İşlem Ücreti Ürün */}
      <div className="flex items-center mb-2">
        <div className="w-[120%] rounded-sm border border-[#FCE3CF] px-2 py-2 shadow-sm text-xs min-[1335px]:text-sm bg-[#FEF1E7] mr-1">
          <div className="flex justify-between">
            <span>
              {language === 'tr'
                ? 'İşlem Ücreti (Ürün)'
                : 'Transaction Fee (Product)'}{' '}
            </span>
            <Information
              size={20}
              className="cursor-pointer"
              id="islemUcretToolTip"
            />
            {language === 'tr' ? (
              <Tooltip anchorSelect="#islemUcretToolTip">
                İndirimli satış fiyatı üzerinden hesaplanan %6,5 kesintidir.
              </Tooltip>
            ) : (
              <Tooltip anchorSelect="#islemFucretToolTip">
                It is a 6.5% deduction calculated on the discounted sales price.
              </Tooltip>
            )}
          </div>
        </div>

        <p className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white">
          {currency} {profitCalc ? profitCalc.productFee : 0}
        </p>
      </div>
      {/* İşlem Ücreti Kargo */}
      <div className="flex items-center mb-2">
        <div className="w-[120%] rounded-sm border border-[#FCE3CF] px-2 py-2 shadow-sm text-xs min-[1335px]:text-sm bg-[#FEF1E7] mr-1">
          <div className="flex justify-between">
            <span>
              {language === 'tr'
                ? 'İşlem Ücreti (Kargo)'
                : 'Transaction Fee (Shipping)'}{' '}
            </span>
            <Information
              size={20}
              className="cursor-pointer"
              id="shippingFeeResultToolTip"
            />
            {language === 'tr' ? (
              <Tooltip anchorSelect="#shippingFeeResultToolTip">
                Kargo ücreti üzerinden hesaplanan %6,5 kesintidir.sadsa
              </Tooltip>
            ) : (
              <Tooltip anchorSelect="#shippingFeeResultToolTip">
                It is a 6.5% deduction calculated on the shipping fee.sadsa
              </Tooltip>
            )}
          </div>
        </div>
        <p className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white">
          {currency} {profitCalc ? profitCalc.shippingFee : 0}
        </p>
      </div>
      {/* İşlem Ücreti Hediye Paketi */}
      <div className="flex items-center mb-2">
        <div className="w-[120%] rounded-sm border border-[#FCE3CF] px-2 py-2 shadow-sm text-xs min-[1335px]:text-sm bg-[#FEF1E7] mr-1">
          <div className="flex justify-between">
            <span>
              {language === 'tr'
                ? 'İşlem Ücreti (Hediye Paketi)'
                : 'Gift Package Fee'}{' '}
            </span>
            <Information
              size={20}
              className="cursor-pointer"
              id="islemGiftToolTip"
            />
            {language === 'tr' ? (
              <Tooltip anchorSelect="#islemGiftToolTip">
                Hediye Paketi ücreti üzerinden hesaplanan %6,5 kesintidir.
              </Tooltip>
            ) : (
              <Tooltip anchorSelect="#islemGiftToolTip">
                It is a 6.5% deduction calculated on the Gift Package fee.
              </Tooltip>
            )}
          </div>
        </div>

        <p className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white">
          {currency} {profitCalc ? profitCalc.giftBoxFee : 0}
        </p>
      </div>
      {/* Ödeme İşlem Ücreti */}
      <div className="flex items-center mb-2">
        <div className="w-[120%] rounded-sm border border-[#FCE3CF] px-2 py-2 shadow-sm text-xs min-[1335px]:text-sm bg-[#FEF1E7] mr-1">
          <div className="flex justify-between">
            <span>
              {language === 'tr'
                ? 'Ödeme İşlem Ücreti'
                : 'Payment Processing Fee'}{' '}
            </span>
            <Information
              size={20}
              className="cursor-pointer"
              id="ödemeIslemToolTip"
            />
            {language === 'tr' ? (
              <Tooltip anchorSelect="#ödemeIslemToolTip">
                İndirimli "Satış Fiyatı", "Kargo Ücreti", "Hediye Paketi Ücreti"
                ve "Satış Vergisi" toplamı üzerinden <br />
                hesaplanan kesintidir. Bankanın lokasyonuna göre kesinti oranı
                değişir.
              </Tooltip>
            ) : (
              <Tooltip anchorSelect="#ödemeIslemToolTip">
                Discounted "Selling Price", "Shipping Fee",
                <br /> "Gift Package Fee" and "Sales Tax" total <br />
                is the calculated deduction. Deduction <br />
                rate according to the location of the bank It changes.
              </Tooltip>
            )}
          </div>
        </div>
        <p className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white">
          {currency} {profitCalc ? profitCalc.paymentProcessingFee : 0}
        </p>
      </div>
      {/* Yasal İşlem Ücreti */}
      <div className="flex items-center mb-2">
        <div className="w-[120%] rounded-sm border border-[#FCE3CF] px-2 py-2 shadow-sm text-xs min-[1335px]:text-sm bg-[#FEF1E7] mr-1">
          <div className="flex justify-between">
            <span>
              {language === 'tr' ? 'Yasal İşlem Ücreti' : 'Legal Process Fee'}{' '}
            </span>
            <Information
              size={20}
              className="cursor-pointer"
              id="yaslIslemToolTip"
            />
            {language === 'tr' ? (
              <Tooltip anchorSelect="#yaslIslemToolTip">
                İndirimli "Satış Fiyatı", "Kargo Ücreti" ve "Hediye Paketi
                Ücreti" <br />
                toplamı üzerinden hesaplanan kesintidir.
                <br /> Mağaza sahibinin lokasyonuna göre kesinti oranı değişir.
                Mağaza sahibi lokasyonu <br /> İngiltere, İspanya, Fransa,
                İtalya ve Türkiye
                <br /> olan satıcı hesaplarına uygulanır.
              </Tooltip>
            ) : (
              <Tooltip anchorSelect="#yaslIslemToolTip">
                Discounted "Selling Price", "Shipping Fee" and <br /> "Gift
                Package Fee" It is the deduction calculated <br /> on the total.
                The deduction rate varies depending on <br /> the location of
                the store owner.
              </Tooltip>
            )}
          </div>
        </div>

        <p className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white">
          {currency} {profitCalc ? profitCalc.regulatoryOperatingFee : 0}
        </p>
      </div>
      {/* Offsite Ads Ücreti */}
      <div className="flex items-center mb-2">
        <div className="w-[120%] rounded-sm border border-[#FCE3CF] px-2 py-2 shadow-sm text-xs min-[1335px]:text-sm bg-[#FEF1E7] mr-1">
          <div className="flex justify-between">
            <span>
              {language === 'tr' ? 'Offsite Ads Ücreti' : 'Offsite Ads Fees'}{' '}
            </span>
            <Information
              size={20}
              className="cursor-pointer"
              id="offSiteToolTip"
            />
            {language === 'tr' ? (
              <Tooltip anchorSelect="#offSiteToolTip">
                Belirlenen offsite ads oranına göre yapılan kesintidir. <br />
                Kesilebilecek maksimum offsite ads tutarı $100 veya
                değerindedir.
              </Tooltip>
            ) : (
              <Tooltip anchorSelect="#offSiteToolTip">
                It is the deduction made according to the determined offsite ads
                rate. <br />
                The maximum amount of offsite ads that can be cut is $100 or so.
              </Tooltip>
            )}
          </div>
        </div>
        <p className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white">
          {currency} {profitCalc ? profitCalc.offsiteAdsFee : 0}
        </p>
      </div>
      {/* KDV */}
      <div className="flex items-center mb-2">
        <div className="w-[120%] rounded-sm border border-[#FCE3CF] px-2 py-2 shadow-sm text-xs min-[1335px]:text-sm bg-[#FEF1E7] mr-1">
          <div className="flex justify-between">
            <span>{language === 'tr' ? 'KDV' : 'VAT'} </span>
            <Information
              size={20}
              className="cursor-pointer"
              id="kdvResultToolTip"
            />
            {language === 'tr' ? (
              <Tooltip anchorSelect="#kdvResultToolTip">
                Vergi numarası yok ise "Listeleme Ücreti", "İşlem Ücretleri",{' '}
                <br />
                "Ödeme İşlem Ücreti", "Yasal İşlem Ücreti", "Offsite Ads ücreti"{' '}
                <br />
                ve "Etsy Ads ücreti" kesintileri üzerinden mağaza sahibi <br />
                lokasyonuna göre değişen oranda vergi kesilir.
              </Tooltip>
            ) : (
              <Tooltip anchorSelect="#kdvResultToolTip">
                If there is no tax number, "Listing Fee", "Transaction Fees",{' '}
                <br />
                "Payment Processing Fee", "Legal Processing Fee", "Offsite Ads
                fee" <br />
                and store owner via "Etsy Ads fee" deductions Taxes are withheld{' '}
                <br />
                at varying rates depending on location.
              </Tooltip>
            )}
          </div>
        </div>

        <p className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white">
          {currency} {profitCalc ? profitCalc.vat : 0}
        </p>
      </div>
      {/* Para Birimi Dönüşüm Ücreti */}
      <div className="flex items-center mb-2">
        <div className="w-[120%] rounded-sm border border-[#FCE3CF] px-2 py-2 shadow-sm text-xs min-[1335px]:text-sm bg-[#FEF1E7] mr-1">
          <div className="flex justify-between">
            <span>
              {language === 'tr'
                ? 'Para Birimi Dönüşüm Ücr.'
                : 'Currency Conversion Fee'}{' '}
            </span>
            <Information
              size={20}
              className="cursor-pointer"
              id="exchangeFeeResultToolTip"
            />
            {language === 'tr' ? (
              <Tooltip anchorSelect="#exchangeFeeResultToolTip">
                Mağaza para birimi ile banka lokasyonuna ait para biriminin
                farklı <br />
                olması durumunda yapılan kesintidir. <br /> İndirimli "Satış
                Fiyatı", "Kargo Ücreti",
                <br /> "Hediye Paketi Ücreti" ve "Satış Vergisi" toplamının
                %2,5'udur.
              </Tooltip>
            ) : (
              <Tooltip anchorSelect="#exchangeFeeResultToolTip">
                Note that the store currency and the currency <br /> of the bank
                location are different <br />
                This is the deduction made in case of <br />
                Discounted "Sale" Price", "Cargo Fee",
                <br />
                The sum of "Gift Wrapping Fee" and "Sales Tax" It is 2.5%.
              </Tooltip>
            )}
          </div>
        </div>
        <p className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white">
          {currency} {profitCalc ? profitCalc.exchangeFee : 0}
        </p>
      </div>
      {/* Satış Vergisi */}
      <div className="flex items-center mb-2">
        <div className="w-[120%] rounded-sm border border-[#FCE3CF] px-2 py-2 shadow-sm text-xs min-[1335px]:text-sm bg-[#FEF1E7] mr-1">
          <div className="flex justify-between">
            <span>{language === 'tr' ? 'Satış Vergisi' : 'Sales Tax'} </span>
            <Information
              size={20}
              className="cursor-pointer"
              id="salesTaxResultToolTip"
            />
            {language === 'tr' ? (
              <Tooltip anchorSelect="#salesTaxResultToolTip">
                Ödeme esnasında alıcıdan tahsil edilen satış vergisi var ise{' '}
                <br />
                ilgili ülke ya da eyalete geri ödemesinin yapılabilmesi için{' '}
                <br />
                kesilen tutardır.
              </Tooltip>
            ) : (
              <Tooltip anchorSelect="#salesTaxResultToolTip">
                If there is sales tax collected from the buyer during payment{' '}
                <br />
                in order to be refunded to the relevant country or state <br />
                is the deducted amount.
              </Tooltip>
            )}
          </div>
        </div>
        <p className="w-full rounded-sm border border-[#FCE3CF] px-2 py-2 shadow-sm text-xs min-[1335px]:text-sm bg-white">
          {currency} {profitCalc ? profitCalc.salesTax : 0}
        </p>
      </div>
    </>
  );
};

export default EtsyCostTable;
