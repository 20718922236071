import React from 'react';
import { useAuth } from '../../context/AuthContext';
import { classNames } from '../../utils/conditionalClasses';
import { ComponentType } from '../../pages/Payment';
import PaymentRegister from './PaymentRegister';
import PaymentBillingInfo from './PaymentBillingInfo';
import PaymentActions from './PaymnetActions';
import { useLanguage } from '../../context/LanguageContext';

export interface PropsPayment {
  currentComponent: ComponentType;
  setCurrentComponent: React.Dispatch<React.SetStateAction<ComponentType>>;
  setPlanInfo: (paymentInfo: any) => void;
  disablePayment: boolean;
  planInfo: any;
  setDisablePayment: React.Dispatch<React.SetStateAction<boolean>>;
  country: string;
}
const UserInfoWrapper: React.FC<PropsPayment> = ({
  currentComponent,
  setCurrentComponent,
  setPlanInfo,
  disablePayment,
  planInfo,
  setDisablePayment,
  country,
}) => {
  const { t } = useLanguage();
  const { session } = useAuth();
  const handleClick = (component: ComponentType) => {
    setCurrentComponent(component);
  };
  // const [userId, setUserId] = useState<number>();
  return (
    <div className="col-span-12 xl:col-span-6 rounded-xl max-w-[570px] shadow-lg h-full">
      {/* BUTTONS */}
      <div className="flex items-center justify-start h-20 rounded-t-xl bg-lightGray">
        {!session && (
          <button
            className={classNames(
              currentComponent === ComponentType.profile
                ? 'bg-secondary  text-white'
                : 'text-secondary bg-lightGray border-r border-secondary',
              'font-medium px-4 rounded-tl-xl h-full text-sm sm:text-base'
            )}
            onClick={() => handleClick(ComponentType.profile)}
          >
            {t?.('payment.navigation.register')}
          </button>
        )}
        <button
          className={classNames(
            currentComponent === ComponentType.billing
              ? 'bg-secondary text-white'
              : 'text-secondary  border-r border-secondary bg-lightGray',
            session ? 'rounded-tl-xl' : '',
            'font-medium  px-4 h-full text-sm sm:text-base'
          )}
          onClick={() => handleClick(ComponentType.billing)}
        >
          {t?.('payment.navigation.billing')}
        </button>
        <button
          className={classNames(
            currentComponent === ComponentType.payment
              ? 'bg-secondary  text-white'
              : 'text-secondary bg-lightGray',
            'font-medium px-4 h-full text-sm sm:text-base cursor-default'
          )}
        >
          {t?.('payment.navigation.payment')}
        </button>
      </div>
      {currentComponent === ComponentType.profile && (
        <PaymentRegister
          setCurrentComponent={setCurrentComponent}
          currentComponent={currentComponent}
        />
      )}
      {currentComponent === ComponentType.billing && (
        <PaymentBillingInfo
          setCurrentComponent={setCurrentComponent}
          currentComponent={currentComponent}
          country={country}
          setDisablePayment={setDisablePayment}
        />
      )}
      {currentComponent === ComponentType.payment && (
        <PaymentActions
          setPlanInfo={setPlanInfo}
          disablePayment={disablePayment}
          planInfo={planInfo}
          country={country}
        />
      )}
    </div>
  );
};

export default UserInfoWrapper;
