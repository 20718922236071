import { AxiosResponse } from 'axios';
import httpClient from '../utils/httpClient';
import { CostsData } from '../interfaces/trendyolInterfaces';
import AliExpressService from './aliExpressService';

export interface ProfitTrendyol {
  commissionRate: number;
  vatRate: number;
  sellingPrice: number;
  isDiscountPercent: boolean;
  discount: number;
  shippingFee: number;
  productCost: number;
  labourCost: number;
  packingCost: number;
  shippingCost: number;
  advertisingCost: number;
}

class TrendyolToolsService {
  responseBody = (response: AxiosResponse) => response.data;

  // Get productInfo from Url
  public async getProductWithUrl(productUrl: string) {
    const { data } = await httpClient.get(
      `/api/v1/trendyol/profit-calculator?productUrl=${productUrl}`
    );
    return data;
  }

  // Get Categories
  public async getCategories(categoryString: string) {
    const { data } = await httpClient.get(
      `api/v1/trendyol/profit-calculator/categories?category=${categoryString}`
    );
    return data;
  }

  //   Get Calculate Result
  public async getProfitForTrendyol(payload: ProfitTrendyol) {
    const { data } = await httpClient.post(
      `/api/v1/trendyol/profit-calculator`,
      payload
    );
    return data;
  }
  public async getMyProducts(page: number,sort="stockId",isAsc=true) {
    const { data } = await httpClient.get(
      `/api/v1/trendyol/store-products?page=${page}&sort=${sort}&isAsc=${isAsc}`
    );
    console.log(data)
    let processinData = data.data.products.filter((item: any) => item.aeProcess === 1)
    console.log(processinData)
    if(processinData.length > 0){
      console.log("1233")
      await AliExpressService.updateProcesses()
    }
    return data;
  }

  public async getTrendyolStackChartData(producerId: number) {
    const { data } = await httpClient.get(
      `/api/v1/trendyol/distributed-lines?producerId=${producerId}`
    );
    return data;
  }
  public async getTrendyolBestOrders(
    producerId: number,
    dates: { startDate: string; endDate: string }
  ) {
    const { data } = await httpClient.get(
      `/api/v1/trendyol/best-order-lines?producerId=${producerId}&startDate=${dates.startDate}&endDate=${dates.endDate}`
    );
    return data;
  }
  public async getTrendyolOrders(
    producerId: number,
    dates: { startDate: string; endDate: string }
  ) {
    const { data } = await httpClient.get(
      `/api/v1/trendyol/order-lines?producerId=${producerId}&startDate=${dates.startDate}&endDate=${dates.endDate}`
    );
    return data;
  }
  public async updateCosts(barcode: string, producerId: number, payload: any) {
    const { data } = await httpClient.patch(
      `/api/v1/trendyol/cost/${barcode}?producerId=${producerId}`,
      payload
    );
    return data;
  }

  public async getCosts(
    barcode: string,
    payload: { producerId: number; costType: string }
  ) {
    const { data } = await httpClient.get(
      `/api/v1/trendyol/cost/${barcode}?producerId=${payload.producerId}&costType=${payload.costType}`
    );
    return data;
  }

  public async getSellerDashboardChartsData(
    producerId: number,
    isWeekly: boolean
  ) {
    const { data } = await httpClient.get(
      `/api/v1/trendyol/seller-dashboard/chart-data?producerId=${producerId}&isWeekly=${isWeekly}`
    );
    return data;
  }

  public async getSellerDashboardDetails(
    producerId: number,
    startDate: string,
    endDate: string
  ) {
    const { data } = await httpClient.get(
      `/api/v1/trendyol/seller-dashboard/details?producerId=${producerId}&startDate=${startDate}&endDate=${endDate}`
    );
    return data;
  }

  public async getSellerDashboardCardGroupsData(producerId: number) {
    const { data } = await httpClient.get(
      `/api/v1/trendyol/seller-dashboard/card-data?producerId=${producerId}`
    );
    return data;
  }
}

export default new TrendyolToolsService();
