export const USStates = [{
    value: "AL",
    name: "Alabama"
}, {
    value: "AK",
    name: "Alaska"
}, {
    value: "AZ",
    name: "Arizona"
}, {
    value: "AR",
    name: "Arkansas"
}, {
    value: "CA",
    name: "California"
}, {
    value: "CO",
    name: "Colorado"
}, {
    value: "CT",
    name: "Connecticut"
}, {
    value: "DE",
    name: "Delaware"
}, {
    value: "DC",
    name: "District of Columbia"
}, {
    value: "FL",
    name: "Florida"
}, {
    value: "GA",
    name: "Georgia"
}, {
    value: "HI",
    name: "Hawaii"
}, {
    value: "ID",
    name: "Idaho"
}, {
    value: "IL",
    name: "Illinois"
}, {
    value: "IN",
    name: "Indiana"
}, {
    value: "IA",
    name: "Iowa"
}, {
    value: "KS",
    name: "Kansas"
}, {
    value: "KY",
    name: "Kentucky"
}, {
    value: "LA",
    name: "Louisiana"
}, {
    value: "ME",
    name: "Maine"
}, {
    value: "MD",
    name: "Maryland"
}, {
    value: "MA",
    name: "Massachusetts"
}, {
    value: "MI",
    name: "Michigan"
}, {
    value: "MN",
    name: "Minnesota"
}, {
    value: "MS",
    name: "Mississippi"
}, {
    value: "MO",
    name: "Missouri"
}, {
    value: "MT",
    name: "Montana"
}, {
    value: "NE",
    name: "Nebraska"
}, {
    value: "NV",
    name: "Nevada"
}, {
    value: "NH",
    name: "New Hampshire"
}, {
    value: "NJ",
    name: "New Jersey"
}, {
    value: "NM",
    name: "New Mexico"
}, {
    value: "NY",
    name: "New York"
}, {
    value: "NC",
    name: "North Carolina"
}, {
    value: "ND",
    name: "North Dakota"
}, {
    value: "MP",
    name: "Northern Mariana Islands"
}, {
    value: "OH",
    name: "Ohio"
}, {
    value: "OK",
    name: "Oklahoma"
}, {
    value: "OR",
    name: "Oregon"
}, {
    value: "PA",
    name: "Pennsylvania"
}, {
    value: "RI",
    name: "Rhode Island"
}, {
    value: "SC",
    name: "South Carolina"
}, {
    value: "SD",
    name: "South Dakota"
}, {
    value: "TN",
    name: "Tennessee"
}, {
    value: "TX",
    name: "Texas"
}, {
    value: "TT",
    name: "Trust Territories"
}, {
    value: "UT",
    name: "Utah"
}, {
    value: "VT",
    name: "Vermont"
}, {
    value: "VA",
    name: "Virginia"
}, {
    value: "VI",
    name: "Virgin Islands"
}, {
    value: "WA",
    name: "Washington"
}, {
    value: "WV",
    name: "West Virginia"
}, {
    value: "WI",
    name: "Wisconsin"
}, {
    value: "WY",
    name: "Wyoming"
}]
export const TurkishCities = [
    { value: "01", name: "Adana" },
    { value: "02", name: "Adıyaman" },
    { value: "03", name: "Afyonkarahisar" },
    { value: "04", name: "Ağrı" },
    { value: "05", name: "Amasya" },
    { value: "06", name: "Ankara" },
    { value: "07", name: "Antalya" },
    { value: "08", name: "Artvin" },
    { value: "09", name: "Aydın" },
    { value: "10", name: "Balıkesir" },
    { value: "11", name: "Bilecik" },
    { value: "12", name: "Bingöl" },
    { value: "13", name: "Bitlis" },
    { value: "14", name: "Bolu" },
    { value: "15", name: "Burdur" },
    { value: "16", name: "Bursa" },
    { value: "17", name: "Çanakkale" },
    { value: "18", name: "Çankırı" },
    { value: "19", name: "Çorum" },
    { value: "20", name: "Denizli" },
    { value: "21", name: "Diyarbakır" },
    { value: "22", name: "Edirne" },
    { value: "23", name: "Elazığ" },
    { value: "24", name: "Erzincan" },
    { value: "25", name: "Erzurum" },
    { value: "26", name: "Eskişehir" },
    { value: "27", name: "Gaziantep" },
    { value: "28", name: "Giresun" },
    { value: "29", name: "Gümüşhane" },
    { value: "30", name: "Hakkari" },
    { value: "31", name: "Hatay" },
    { value: "32", name: "Isparta" },
    { value: "33", name: "Mersin" },
    { value: "34", name: "İstanbul" },
    { value: "35", name: "İzmir" },
    { value: "36", name: "Kars" },
    { value: "37", name: "Kastamonu" },
    { value: "38", name: "Kayseri" },
    { value: "39", name: "Kırklareli" },
    { value: "40", name: "Kırşehir" },
    { value: "41", name: "Kocaeli" },
    { value: "42", name: "Konya" },
    { value: "43", name: "Kütahya" },
    { value: "44", name: "Malatya" },
    { value: "45", name: "Manisa" },
    { value: "46", name: "Kahramanmaraş" },
    { value: "47", name: "Mardin" },
    { value: "48", name: "Muğla" },
    { value: "49", name: "Muş" },
    { value: "50", name: "Nevşehir" },
    { value: "51", name: "Niğde" },
    { value: "52", name: "Ordu" },
    { value: "53", name: "Rize" },
    { value: "54", name: "Sakarya" },
    { value: "55", name: "Samsun" },
    { value: "56", name: "Siirt" },
    { value: "57", name: "Sinop" },
    { value: "58", name: "Sivas" },
    { value: "59", name: "Tekirdağ" },
    { value: "60", name: "Tokat" },
    { value: "61", name: "Trabzon" },
    { value: "62", name: "Tunceli" },
    { value: "63", name: "Şanlıurfa" },
    { value: "64", name: "Uşak" },
    { value: "65", name: "Van" },
    { value: "66", name: "Yozgat" },
    { value: "67", name: "Zonguldak" },
    { value: "68", name: "Aksaray" },
    { value: "69", name: "Bayburt" },
    { value: "70", name: "Karaman" },
    { value: "71", name: "Kırıkkale" },
    { value: "72", name: "Batman" },
    { value: "73", name: "Şırnak" },
    { value: "74", name: "Bartın" },
    { value: "75", name: "Ardahan" },
    { value: "76", name: "Iğdır" },
    { value: "77", name: "Yalova" },
    { value: "78", name: "Karabük" },
    { value: "79", name: "Kilis" },
    { value: "80", name: "Osmaniye" },
    { value: "81", name: "Düzce" }
];
export const TurkishCitiesForSupliers = [
    { "value": "Adana" },
    { "value": "Adıyaman" },
    { "value": "Afyonkarahisar" },
    { "value": "Ağrı" },
    { "value": "Amasya" },
    { "value": "Ankara" },
    { "value": "Antalya" },
    { "value": "Artvin" },
    { "value": "Aydın" },
    { "value": "Balıkesir" },
    { "value": "Bilecik" },
    { "value": "Bingöl" },
    { "value": "Bitlis" },
    { "value": "Bolu" },
    { "value": "Burdur" },
    { "value": "Bursa" },
    { "value": "Çanakkale" },
    { "value": "Çankırı" },
    { "value": "Çorum" },
    { "value": "Denizli" },
    { "value": "Diyarbakır" },
    { "value": "Edirne" },
    { "value": "Elazığ" },
    { "value": "Erzincan" },
    { "value": "Erzurum" },
    { "value": "Eskişehir" },
    { "value": "Gaziantep" },
    { "value": "Giresun" },
    { "value": "Gümüşhane" },
    { "value": "Hakkari" },
    { "value": "Hatay" },
    { "value": "Isparta" },
    { "value": "Mersin" },
    { "value": "İstanbul" },
    { "value": "İzmir" },
    { "value": "Kars" },
    { "value": "Kastamonu" },
    { "value": "Kayseri" },
    { "value": "Kırklareli" },
    { "value": "Kırşehir" },
    { "value": "Kocaeli" },
    { "value": "Konya" },
    { "value": "Kütahya" },
    { "value": "Malatya" },
    { "value": "Manisa" },
    { "value": "Kahramanmaraş" },
    { "value": "Mardin" },
    { "value": "Muğla" },
    { "value": "Muş" },
    { "value": "Nevşehir" },
    { "value": "Niğde" },
    { "value": "Ordu" },
    { "value": "Rize" },
    { "value": "Sakarya" },
    { "value": "Samsun" },
    { "value": "Siirt" },
    { "value": "Sinop" },
    { "value": "Sivas" },
    { "value": "Tekirdağ" },
    { "value": "Tokat" },
    { "value": "Trabzon" },
    { "value": "Tunceli" },
    { "value": "Şanlıurfa" },
    { "value": "Uşak" },
    { "value": "Van" },
    { "value": "Yozgat" },
    { "value": "Zonguldak" },
    { "value": "Aksaray" },
    { "value": "Bayburt" },
    { "value": "Karaman" },
    { "value": "Kırıkkale" },
    { "value": "Batman" },
    { "value": "Şırnak" },
    { "value": "Bartın" },
    { "value": "Ardahan" },
    { "value": "Iğdır" },
    { "value": "Yalova" },
    { "value": "Karabük" },
    { "value": "Kilis" },
    { "value": "Osmaniye" },
    { "value": "Düzce" }
]

export const GermanStates = [
    { value: "01", name: "Schleswig-Holstein" },
    { value: "02", name: "Hamburg" },
    { value: "03", name: "Lower Saxony" },
    { value: "04", name: "Bremen" },
    { value: "05", name: "North Rhine-Westphalia" },
    { value: "06", name: "Hesse" },
    { value: "07", name: "Rhineland-Palatinate" },
    { value: "08", name: "Baden-Württemberg" },
    { value: "09", name: "Bavaria" },
    { value: "10", name: "Saarland" },
    { value: "11", name: "Berlin" },
    { value: "12", name: "Brandenburg" },
    { value: "13", name: "Mecklenburg-Vorpommern" },
    { value: "14", name: "Saxony" },
    { value: "15", name: "Saxony-Anhalt" },
    { value: "16", name: "Thuringia" },
];
