import React, { useRef, useState } from 'react'
import { CancelReasons } from './PackageInfo';
interface PackageCancelModalQuestionsProps {
    cancelReasons: CancelReasons[]
    cancelModalLoader: boolean
    setOpenPackageCancelModal: React.Dispatch<React.SetStateAction<boolean>>;
    handleCancelPackage: (cancelReasons: number[], userFeedback?: string) => void
    error: string
    setError: React.Dispatch<React.SetStateAction<string>>;
}
const PackageCancelModalQuestions: React.FC<PackageCancelModalQuestionsProps> = ({ cancelModalLoader, cancelReasons, setOpenPackageCancelModal, handleCancelPackage, error, setError }) => {
    const [otherInputValue, setOtherInputValue] = useState('');
    const [answers, setAnswers] = useState<(number)[]>([]);
    const [alert, setAlert] = useState<string>("")
    const inputRef = useRef<HTMLInputElement>(null);

    const handleInputChange = () => {
        const inputValue = inputRef.current?.value || '';
        setOtherInputValue(inputValue);
    };

    const handleCheckboxChange = (answer: number) => {
        const updatedAnswers: number[] = [...answers];
        const answerIndex = updatedAnswers.indexOf(answer);

        if (answerIndex > -1) {
            updatedAnswers.splice(answerIndex, 1);
        } else {
            updatedAnswers.push(answer);
        }

        setAnswers(updatedAnswers);
        setAlert("")
        setError("")
    };

    const handleCancelPackageClick = () => {
        if (answers.length > 0) {
            handleCancelPackage(answers, otherInputValue)
        }
        else if (answers.length === 0) {
            setAlert("Lütfen en az bir sebep seçiniz")
        }
    };

    const isAnswerChecked = (answer: number) => answers.includes(answer);
    return (
        <>
            <div className="flex flex-col items-center justify-center text-center space-y-1">
                <div className="font-semibold text-md md:text-xl">
                    <p className="my-1">Sizleri Daha İyi Anlayabilmek ve Kendimizi Geliştirmek Adına Rexven
                        Aboneliğinizi Neden İptal Etmek İstediğinizi Öğrenmek İstiyoruz</p>
                </div>
            </div>
            <div className='grid gap-4 grid-cols-12 mb-4'>
                <div className='col-span-12 md:col-span-5 '>
                    <div className="flex flex-col space-y-5">
                        <div className="mt-2">
                            <label className="text-base font-semibold text-rexRed">
                                {cancelReasons[0]?.mainReason}
                            </label>
                            <fieldset className="mt-4">
                                <legend className="sr-only">Notification method</legend>
                                <div className="space-y-4">
                                    {cancelReasons.filter((reason) => reason.mainReasonId === 0).map((cancelReason) => (
                                        <div key={cancelReason.id} className="flex items-center">
                                            <input
                                                id={cancelReason.id.toString()}
                                                name="notification-method"
                                                type="checkbox"
                                                checked={isAnswerChecked(cancelReason.id)}
                                                className="h-4 w-4 border-gray-300 text-cardBorder focus:ring-cardBorder"
                                                onChange={() => handleCheckboxChange(cancelReason.id)}
                                            />
                                            <label
                                                htmlFor={cancelReason.id.toString()}
                                                className="ml-3 block text-sm font-small leading-6 text-gray-900"
                                            >
                                                {cancelReason.reason}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </fieldset>
                        </div>
                        <div>
                            <label className="text-base font-semibold text-rexRed">
                                {cancelReasons[5]?.mainReason}
                            </label>
                            <fieldset className="mt-4">
                                <legend className="sr-only">Notification method</legend>
                                <div className="space-y-4">
                                    <>
                                        {cancelReasons.filter((reason) => reason.mainReasonId === 1).map((cancelReason) => (
                                            <div key={cancelReason.id} className="flex items-center">
                                                <input
                                                    id={cancelReason.id.toString()}
                                                    name="notification-method"
                                                    type="checkbox"
                                                    checked={isAnswerChecked(cancelReason.id)}
                                                    className="h-4 w-4 border-gray-300 text-cardBorder focus:ring-cardBorder"
                                                    onChange={() => handleCheckboxChange(cancelReason.id)}
                                                />
                                                <label
                                                    htmlFor={cancelReason.id.toString()}
                                                    className="ml-3 block text-sm font-small leading-6 text-gray-900"
                                                >
                                                    {cancelReason.reason}
                                                </label>
                                            </div>
                                        ))}
                                    </>
                                </div>
                            </fieldset>
                        </div>

                    </div>
                </div>
                <div className='hidden md:block md:col-span-7'>
                    <img className="h-full rounded object-contain " src="/images/cancelSubscription.webp" alt="cancel-package" />
                </div>
            </div>

            {/* Modal Footer Submit Btn Container */}
            <div>
                <div>
                    <label className="text-base font-semibold text-rexRed">
                        Sorunla Alakalı Daha Detaylı Bilgi Verebilir Misiniz?
                    </label>
                    <fieldset className="mt-4">
                        <legend className="sr-only">Notification method</legend>
                        <div className="space-y-4">
                            <div className="flex items-center">
                                <input
                                    ref={inputRef}
                                    value={otherInputValue}
                                    onChange={handleInputChange}
                                    type="text"
                                    name="otherInput"
                                    id="otherInput"
                                    placeholder="Aboneliğimi iptal ettim çünkü ..."
                                    className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6`}
                                />
                            </div>
                        </div>
                    </fieldset>
                </div>
                {alert?.length > 0 &&
                    <div className="flex justify-end mt-2 text-base text-rexRed">
                        {alert}
                    </div>
                }
                {error?.length > 0 &&
                    <div className="flex justify-end mt-2 text-base text-rexRed">
                        {error}
                    </div>
                }
                <div className="flex justify-end mt-2">
                    <div
                        onClick={() => setOpenPackageCancelModal(false)}
                        className="text-center text-[14px] md:text-[16px] px-10 h-[50px] max-h-[50px] font-bold lg:py-1 flex items-center cursor-pointer "
                    >
                        Vazgeç
                    </div>
                    {cancelModalLoader ?
                        <div
                            className="hover:cursor-pointer text-center text-[16px] bg-headerPrimary font-bold text-white h-[50px] max-h-[50px] px-1 py-1 border-2 rounded-lg border-headerPrimary w-[29%] flex items-center justify-center"
                        >
                            <div className="text-center">
                                <div role="status">
                                    <svg aria-hidden="true" className="inline w-5 h-5 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                    </svg>
                                </div>
                            </div>
                        </div>
                        :
                        <div
                            onClick={handleCancelPackageClick}
                            className="hover:cursor-pointer text-center text-[14px] md:text-[16px] bg-headerPrimary font-bold text-white h-[50px] max-h-[50px] p-1 border-2 rounded-lg border-headerPrimary w-[50%] m:w-[29%] flex items-center justify-center"
                        >
                            Aboneliğimi İptal Et
                        </div>
                    }
                </div>

            </div>

        </>

    )
}

export default PackageCancelModalQuestions