import { AxiosResponse } from 'axios';
import { Country } from 'react-phone-number-input';

import {
    IHotProductsQuery, IHotSellersQuery, ITopProductsQuery, ITopSellerQuery
} from '../interfaces/etsyToolsInterfaces';
import httpClient from '../utils/httpClient';

export interface ProfitEtsy {
  shopLocation: string;
  bankLocation: string;
  shopCurrency: string;
  hasTaxNumber: boolean;
  offsiteAds: number;
  hasFreeListing: boolean | string;

  salePrice: number;
  shippingIncome: number;
  giftBoxIncome: number;
  tax: number;
  discountPercentage: number | null;
  discountFixed: number | null;

  productCost: number;
  laborCost: number;
  packageCost: number;
  shippingCost: number;
  addCost: number;
}

class AuthService {
  responseBody = (response: AxiosResponse) => response.data;

  //register user
  public async getProductwithUrl(productUrl: string, shopCurrency: string) {
    const { data } = await httpClient.get(
      `/api/v1/etsy/profit-calculator?cur=${shopCurrency}&url=${productUrl}`
    );
    return data;
  }
  public async getProfitforEtsy(payload: ProfitEtsy) {
    const { data } = await httpClient.post(
      `/api/v1/etsy/profit-calculator`,
      payload
    );
    return data;
  }
  public async getCategories() {
    const { data } = await httpClient.get(`/api/v1/etsy/categories`);

    return data;
  }
  public async getCountries() {
    const compare = (a: any, b: any) => {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    };

    const { data } = await httpClient.get(`/api/v1/etsy/countries`);

    let tempArr = data.data;
    tempArr.sort(compare);

    return { status: data.status, data: tempArr };
  }
  public async getProductTypes() {
    const { data } = await httpClient.get(`/api/v1/etsy/product-types`);
    return data;
  }
  public async getHotSellers(
    page: number,
    orderQuery: { order: string; orderBy: string },
    queryParams?: IHotSellersQuery
  ) {
    const { data } = await httpClient.get(
      `/api/v1/etsy/hot-sellers?countryId=${queryParams?.etsyCountryId}&createDate=${queryParams?.createDate}&taxonomyId=${queryParams?.taxonomyId}&etsyProductTypeId=${queryParams?.etsyProductTypeId}&totalSaleCountMin=${queryParams?.totalSaleCountMin}&totalSaleCountMax=${queryParams?.totalSaleCountMax}&avgSalesMin=${queryParams?.avgSalesMin}&avgSalesMax=${queryParams?.avgSalesMax}&reviewCountMin=${queryParams?.reviewCountMin}&reviewCountMax=${queryParams?.reviewCountMax}&totalListingCountMin=${queryParams?.totalListingCountMin}&totalListingCountMax=${queryParams?.totalListingCountMax}&shopName=${queryParams?.shopName}&orderBy=${orderQuery.orderBy}&order=${orderQuery.order}&page=${page}`
    );
    return data;
  }

  public async getTopSellers(
    page: number,
    orderQuery: { order: string; orderBy: string },
    queryParams?: ITopSellerQuery
  ) {
    const { data } = await httpClient.get(
      `/api/v1/etsy/top-sellers?countryId=${queryParams?.etsyCountryId}&createDate=${queryParams?.createDate}&taxonomyId=${queryParams?.taxonomyId}&etsyProductTypeId=${queryParams?.etsyProductTypeId}&totalSaleCountMin=${queryParams?.totalSaleCountMin}&totalSaleCountMax=${queryParams?.totalSaleCountMax}&avgSalesMin=${queryParams?.avgSalesMin}&avgSalesMax=${queryParams?.avgSalesMax}&reviewCountMin=${queryParams?.reviewCountMin}&reviewCountMax=${queryParams?.reviewCountMax}&totalListingCountMin=${queryParams?.totalListingCountMin}&totalListingCountMax=${queryParams?.totalListingCountMax}&shopName=${queryParams?.shopName}&orderBy=${orderQuery.orderBy}&order=${orderQuery.order}&page=${page}`
    );
    return data;
  }
  public async getHotProducts(
    page: number,
    orderQuery: { order: string; orderBy: string },
    queryParams?: IHotProductsQuery
  ) {
    const { data } = await httpClient.get(
      `/api/v1/etsy/hot-products?countryId=${queryParams?.etsyCountryId}&createDate=${queryParams?.createDate}&taxonomyId=${queryParams?.taxonomyId}&etsyProductTypeId=${queryParams?.etsyProductTypeId}&productCreateDate=${queryParams?.productCreateDate}&saleCountMin=${queryParams?.saleCountMin}&saleCountMax=${queryParams?.saleCountMax}&priceMin=${queryParams?.priceMin}&priceMax=${queryParams?.priceMax}&revenueMin=${queryParams?.revenueMin}&revenueMax=${queryParams?.revenueMax}&reviewCountMin=${queryParams?.reviewCountMin}&reviewCountMax=${queryParams?.reviewCountMax}&viewCountMin=${queryParams?.viewCountMin}&viewCountMax=${queryParams?.viewCountMax}&title=${queryParams?.title}&orderBy=${orderQuery.orderBy}&order=${orderQuery.order}&page=${page}`
    );
    return data;
  }

  public async getTopProducts(
    page: number,
    orderQuery: { order: string; orderBy: string },
    queryParams?: ITopProductsQuery
  ) {
    const { data } = await httpClient.get(
      `/api/v1/etsy/top-products?countryId=${queryParams?.etsyCountryId}&title=${queryParams?.title}&createDate=${queryParams?.createDate}&taxonomyId=${queryParams?.taxonomyId}&etsyProductTypeId=${queryParams?.etsyProductTypeId}&productCreateDate=${queryParams?.productCreateDate}&saleCountMin=${queryParams?.saleCountMin}&saleCountMax=${queryParams?.saleCountMax}&priceMin=${queryParams?.priceMin}&priceMax=${queryParams?.priceMax}&revenueMin=${queryParams?.revenueMin}&revenueMax=${queryParams?.revenueMax}&reviewCountMin=${queryParams?.reviewCountMin}&reviewCountMax=${queryParams?.reviewCountMax}&viewCountMin=${queryParams?.viewCountMin}&viewCountMax=${queryParams?.viewCountMax}&orderBy=${orderQuery.orderBy}&order=${orderQuery.order}&page=${page}`
    );
    return data;
  }
  public async getStoreAnalyze(payload: string | number) {
    const { data } = await httpClient.get(
      `/api/v1/etsy/shop-analysis${payload}`
    );
    return data;
  }
}

export default new AuthService();
