import { AxiosResponse } from 'axios';

import httpClient from '../utils/httpClient';
import { IKeywordFilter } from '../components/tools/amazon/keywordTool/KeywordForm';

class AmazonToolsService {
  responseBody = (response: AxiosResponse) => response.data;

  public async getReviewAnalysis(payload: { productUrl: string }) {
    const { data } = await httpClient.post(
      `/api/v1/amazon/review-analysis`,
      payload
    );
    return data;
  }
  public async getArbitrageProducts(page: number, payload: any) {
    const { data } = await httpClient.get(
      `/api/v1/amazon/arbitrage?page=${page}&to=ca&from=usa&minSaleCount=${payload.minSaleCount}&maxSaleCount=${payload.maxSaleCount}&minPrice=${payload.minPrice}&maxPrice=${payload.maxPrice}&minRevenue=${payload.minRevenue}&maxRevenue=${payload.maxRevenue}&minNetProfit=${payload.minNetProfit}&maxNetProfit=${payload.maxNetProfit}&minProfitMargin=${payload.minProfitMargin}&maxProfitMargin=${payload.maxProfitMargin}&minProfitRate=${payload.minProfitRate}&maxProfitRate=${payload.maxProfitRate}&minRivalCount=${payload.minRivalCount}&maxRivalCount=${payload.maxRivalCount}&minVariationCount=${payload.minVariationCount}&maxVariationCount=${payload.maxVariationCount}`,
      payload
    );
    return data;
  }
  public async getProductSearch(page: number, payload?: any) {
    const { data } = await httpClient.get(
      `/api/v1/amazon/product-search?page=${page}&minSaleCount=${payload.minSaleCount}&maxSaleCount=${payload.maxSaleCount}&minPrice=${payload.minPrice}&maxPrice=${payload.maxPrice}&minRevenue=${payload.minRevenue}&maxRevenue=${payload.maxRevenue}&minRivalCount=${payload.minRivalCount}&maxRivalCount=${payload.maxRivalCount}&minVariationCount=${payload.minVariationCount}&maxVariationCount=${payload.maxVariationCount}&minBsr=${payload.minBsr}&maxBsr=${payload.maxBsr}&minImageCount=${payload.minImageCount}&maxImageCount=${payload.maxImageCount}&minReviewCount=${payload.minReviewCount}&maxReviewCount=${payload.maxReviewCount}&minRating=${payload.minRating}&maxRating=${payload.maxRating}&minAge=${payload.minAge}&maxAge=${payload.maxAge}&categoryIds=${payload.categoryIds}&isAmazonOnList=${payload.isAmazonOnList}&sizeTierCode=${payload.sizeTierCode}&keyword=${payload.keyword}&orderType=${payload.orderType}&orderBy=${payload.orderBy}`
    );
    return data;
  }
  public async getForecastData(asin?: any) {
    const { data } = await httpClient.get(
      `/api/v1/amazon/forecast?asin=${asin}`
    );
    return data;
  }
  public async getKeywords(page: number | string, payload?: IKeywordFilter) {
    const { data } = await httpClient.get(
      `/api/v1/amazon/keywords?domain=usa&page=${page}&minVolume=${payload?.minVolume}&maxVolume=${payload?.maxVolume}&minKeywordCount=${payload?.minKeywordCount}&maxKeywordCount=${payload?.maxKeywordCount}&minTopThreeProductClick=${payload?.minTopThreeProductClick}&maxTopThreeProductClick=${payload?.maxTopThreeProductClick}&minTopThreeProductConversion=${payload?.minTopThreeProductConversion}&maxTopThreeProductConversion=${payload?.maxTopThreeProductConversion}&keyword=${payload?.keyword}&minSaleVolume=${payload?.minSaleVolume}&maxSaleVolume=${payload?.maxSaleVolume}&minTotalSaleCount=${payload?.minTotalSaleCount}&maxTotalSaleCount=${payload?.maxTotalSaleCount}&minMonthlyRevenue=${payload?.minMonthlyRevenue}&maxMonthlyRevenue=${payload?.maxMonthlyRevenue}&minMonthlySaleCount=${payload?.minMonthlySaleCount}&maxMonthlySaleCount=${payload?.maxMonthlySaleCount}&minGiveawayCount=${payload?.minGiveawayCount}&maxGiveawayCount=${payload?.maxGiveawayCount}&minPrice=${payload?.minPrice}&maxPrice=${payload?.maxPrice}&minRivalCount=${payload?.minRivalCount}&maxRivalCount=${payload?.maxRivalCount}&minProductAge=${payload?.minProductAge}&maxProductAge=${payload?.maxProductAge}&minSaleReviewRate=${payload?.minSaleReviewRate}&maxSaleReviewRate=${payload?.maxSaleReviewRate}`
    );
    return data;
  }
  public async getGiveaway(keyword: string) {
    const { data } = await httpClient.get(
      `/api/v1/amazon/keywords/giveaway?domain=usa&keyword=${keyword}`
    );
    return data;
  }
  public async getKeywordHistory(keyword: string) {
    const { data } = await httpClient.get(
      `/api/v1/amazon/keywords/history?domain=usa&keyword=${keyword}`
    );
    return data;
  }
  public async getKeywordRelated(keyword: string) {
    const { data } = await httpClient.get(
      `/api/v1/amazon/keywords/rivals?domain=usa&keyword=${keyword}`
    );
    return data;
  }
}

export default new AmazonToolsService();
