import React, { useState } from 'react';
import { ArrowSwapVertical, Link2 } from 'iconsax-react';
import LoadingComponent from '../common/LoadingComponent';
import Pagination from '../common/Pagination';
import { useAuth } from '../../context/AuthContext';
import {
  ICategory,
  ICountry,
  IEtsyTopProduct,
  IProductType,
} from '../../interfaces/etsyToolsInterfaces';
import { convertDate } from '../../utils/dateFormatter';
import { seperateNumberWithComma } from '../../utils/numberFormatter';
import ToolsAbsoluteBuyBox from '../common/ToolsAbsoluteBuyBox';
import { Link } from 'react-router-dom';
import { useLanguage } from '../../context/LanguageContext';

interface Fields {
  [key: string]: string;
}
function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

function renderTags(tags: any) {
  if (tags.length > 0) {
    return tags.map((tag: any, index: any) => {
      if (index % 5 === 0 && index !== 0) {
        return (
          <React.Fragment key={index}>
            <br />
            <span>{tag}, </span>
          </React.Fragment>
        );
      } else {
        return (
          <span key={index} style={{ color: index % 2 === 0 ? '#FF9E66' : '' }}>
            {tag},
          </span>
        );
      }
    });
  } else {
    return '-';
  }
}

interface EtsyTopProductsTableProps {
  page: number;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  queryString: string;
  isLoading: boolean;
  categories: ICategory[];
  productTypes: IProductType[];
  countries: ICountry[];
  totalPageCount: number;
  setSortQuery: React.Dispatch<
    React.SetStateAction<{
      orderBy: string;
      order: string;
    }>
  >;
  inputPage: string | number;
  setInputPage: React.Dispatch<React.SetStateAction<number | string>>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  data: IEtsyTopProduct[];
}

const EtsyTopProductsTable: React.FC<EtsyTopProductsTableProps> = ({
  page,
  data,
  setOpen,
  setPage,
  isLoading,
  categories,
  productTypes,
  countries,
  setSortQuery,
  totalPageCount,
  setInputPage,
  inputPage,
  queryString,
}) => {
  const { t, language } = useLanguage();
  const { session } = useAuth();

  const [fields, setFields] = useState<Fields>({
    imgUrl: 'DESC',
    title: 'DESC',
    originalCreationTimestamp: 'DESC',
    etsyTaxonomyId: 'DESC',
    etsyProductTypeId: 'DESC',
    totalReviewCount: 'DESC',
    price: 'DESC',
    last30DaysSale: 'DESC',
    last30DaysRevenue: 'DESC',
    totalSale: 'DESC',
    dailySale: 'DESC',
    lastMonthReviewCount: 'DESC',
    view: 'DESC',
    numFavorers: 'DESC',
    createDate: 'DESC',
  });
  const tableHeader = [
    { name: t?.('etsy-top-products.table.no'), column: 'id', isButton: false },
    {
      name: t?.('etsy-top-products.table.product'),
      column: 'imgUrl',
      isButton: false,
    },
    {
      name: t?.('etsy-top-products.table.title'),
      column: 'title',
      isButton: false,
    },
    {
      name: t?.('etsy-top-products.table.storeName'),
      column: 'shopName',
      isButton: false,
    },
    {
      name: t?.('etsy-top-products.table.country'),
      column: 'etsyCountryId',
      isButton: false,
    },
    {
      name: t?.('etsy-top-products.table.storeAge'),
      column: 'createDate',
      isButton: true,
    },
    {
      name: t?.('etsy-top-products.table.productAge'),
      column: 'originalCreationTimestamp',
      isButton: true,
    },
    {
      name: t?.('etsy-top-products.table.category'),
      column: 'etsyTaxonomyId',
      isButton: false,
    },
    {
      name: t?.('etsy-top-products.table.productType'),
      column: 'etsyProductTypeId',
      isButton: false,
    },
    {
      name: t?.('etsy-top-products.table.price'),
      column: 'price',
      isButton: true,
    },
    {
      name: t?.('etsy-top-products.table.last30Sales'),
      column: 'last30DaysSale',
      isButton: true,
    },
    {
      name: t?.('etsy-top-products.table.last30Revenue'),
      column: 'last30DaysRevenue',
      isButton: true,
    },
    {
      name: t?.('etsy-top-products.table.totalSales'),
      column: 'totalSale',
      isButton: true,
    },
    {
      name: t?.('etsy-top-products.table.review'),
      column: 'totalReviewCount',
      isButton: true,
    },
    {
      name: t?.('etsy-top-products.table.last30Review'),
      column: 'lastMonthReviewCount',
      isButton: true,
    },
    {
      name: t?.('etsy-top-products.table.view'),
      column: 'view',
      isButton: true,
    },
    {
      name: t?.('etsy-top-products.table.favorite'),
      column: 'numFavorers',
      isButton: true,
    },
    {
      name: t?.('etsy-top-products.table.tags'),
      column: 'tags',
      isButton: false,
    },
  ];
  return (
    <div className="flex flex-col flex-shrink-0 bg-white rounded-xl p-5 shadow-lg sm:p-4 relative">
      {!session?.hasPackage && <ToolsAbsoluteBuyBox />}
      <div className="flow-root">
        <div className="relative -mx-4 -my-2 overflow-x-auto overflow-y-auto h-[60vh] scrollbar-hide">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300 table-fixed">
              <thead>
                <tr>
                  {tableHeader.map((item, index) => {
                    return (
                      <th
                        key={index}
                        className="sticky top-0 z-10 border-b border-gray-300 text-center w-52 bg-lightGray py-3.5 pl-4 pr-3 text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter "
                        scope="col"
                      >
                        {' '}
                        {item.isButton ? (
                          <button
                            className="text-xs md:text-sm flex justify-between items-center"
                            onClick={() => {
                              if (session?.hasPackage) {
                                let order =
                                  fields.hasOwnProperty(item.column) &&
                                  fields[item.column] === 'DESC'
                                    ? 'ASC'
                                    : 'DESC';
                                setSortQuery({
                                  orderBy: item.column,
                                  order: order,
                                });
                                setFields({ ...fields, [item.column]: order });
                              } else {
                                setOpen(true);
                              }
                            }}
                          >
                            {item.name} <ArrowSwapVertical className="" />
                          </button>
                        ) : (
                          <p className="text-xs md:text-sm">{item.name}</p>
                        )}
                      </th>
                    );
                  })}
                </tr>
              </thead>

              {isLoading ? (
                <div className="w-full flex justify-center">
                  <LoadingComponent />
                </div>
              ) : (
                <tbody className="divide-y divide-gray-200 bg-white">
                  {data?.map((prod: any, idx) => {
                    return (
                      <tr
                        key={idx}
                        className={classNames(
                          !session?.hasPackage && idx > 2
                            ? 'blur-sm'
                            : 'blur-none',
                          'even:bg-lightGray'
                        )}
                      >
                        <td className="py-4 pl-4 text-xs md:text-sm sm:pl-0 w-16">
                          <p className="text-center text-gray">
                            {idx + 100 * (page - 1) + 1}
                          </p>
                        </td>
                        <td className="py-4 pl-4 text-xs md:text-sm font-medium text-center text-gray-900 sm:pl-0 w-16">
                          <a
                            href={`https://www.etsy.com/listing/${prod.id}`}
                            target="_blank"
                          >
                            <img
                              className="h-10 object-contain w-full"
                              src={prod.imgUrl}
                            />
                          </a>
                        </td>
                        <td className="px-4 py-4 text-xs text-center text-gray-500  ">
                          <a
                            href={`https://www.etsy.com/listing/${prod.id}`}
                            target="_blank"
                          >
                            {prod.title.substring(0, 60)}
                          </a>
                        </td>

                        <td className="px-4 py-4 text-xs text-center text-gray-500  ">
                          <div className="flex justify-center items-center space-x-2">
                            <Link
                              to={`/etsy-store-analyze?shopId=${prod.etsyShop.id}`}
                            >
                              {prod.etsyShop.shopName}
                            </Link>
                            <a
                              target="_blank"
                              href={`https://www.etsy.com/shop/${prod.etsyShop.shopName}`}
                            >
                              <Link2
                                size="24"
                                color="#5501D6"
                                className="cursor-pointer"
                              />
                            </a>
                          </div>
                        </td>
                        <td className="whitespace-nowrap text-center px-3 py-4 text-xs md:text-sm text-gray-500 ">
                          {Number(
                            countries?.find(
                              (item) => item.id === prod.etsyShop.etsyCountryId
                            )?.countryCode.length
                          ) > 0
                            ? countries?.find(
                                (item) =>
                                  item.id === prod.etsyShop.etsyCountryId
                              )?.countryCode
                            : '-'}
                        </td>
                        <td className="whitespace-nowrap text-center px-3 py-4 text-xs  text-gray-500 ">
                          {convertDate(prod?.etsyShop.createDate, language)}
                        </td>
                        <td className="whitespace-nowrap text-center px-3 py-4 text-xs  text-gray-500 ">
                          {convertDate(
                            prod?.originalCreationTimestamp,
                            language
                          )}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs  text-gray-500 ">
                          {Number(
                            categories?.find(
                              (item) => item.id === prod.etsyTaxonomyId
                            )?.taxonomyName.length
                          ) > 0
                            ? categories?.find(
                                (item) => item.id === prod.etsyTaxonomyId
                              )?.taxonomyName
                            : '-'}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          {Number(
                            productTypes?.find(
                              (item) => item.id === prod.etsyProductTypeId
                            )?.type.length
                          ) > 0
                            ? productTypes?.find(
                                (item) => item.id === prod.etsyProductTypeId
                              )?.type
                            : '-'}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          ${Number(prod.price).toFixed(2)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          {prod.last30DaysSale
                            ? seperateNumberWithComma(prod.last30DaysSale)
                            : '-'}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          {prod.last30DaysRevenue
                            ? `$ ${seperateNumberWithComma(
                                prod.last30DaysRevenue
                              )}`
                            : '-'}
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500">
                          {prod.totalSale
                            ? seperateNumberWithComma(prod.totalSale)
                            : '-'}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          {seperateNumberWithComma(
                            Number(prod.totalReviewCount)
                          )}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500">
                          {seperateNumberWithComma(prod.lastMonthReviewCount)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500">
                          {seperateNumberWithComma(prod.view)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500">
                          {seperateNumberWithComma(prod.numFavorers)}
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500">
                          {/* {prod.tags[0]} */}
                          {renderTags(prod.tags)}
                        </td>
                      </tr>
                    );
                  })}
                  {!session?.hasPackage &&
                    Array.from({ length: 97 }).map((_, idx) => (
                      <tr
                        key={idx}
                        className={classNames('blur-sm even:bg-lightGray')}
                      >
                        <td className="py-4 pl-4 text-xs md:text-sm sm:pl-0 w-16">
                          <p className="text-center text-gray">{idx + 4}</p>
                        </td>
                        <td className="py-4 pl-4 text-xs md:text-sm font-medium text-center text-gray-900 sm:pl-0 w-16">
                          <img
                            className="h-10 object-contain w-full"
                            src={`https://picsum.photos/${idx}`}
                          />
                        </td>
                        <td className="px-4 py-4 text-xs md:text-sm text-center text-gray-500  ">
                          Heollaaae
                        </td>
                        <td className="whitespace-nowrap text-center px-3 py-4 text-xs md:text-sm text-gray-500 ">
                          Tıasdoanskdo
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs  text-gray-500 ">
                          Jewelry
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          Fiziksel
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          167253178
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          12313
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          2323
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          4434343
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          333444
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          333444
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          333444
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          333444
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          333444
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500 ">
                          333444
                        </td>

                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500">
                          756
                        </td>
                        <td className="whitespace-nowrap px-3 py-4 text-center text-xs md:text-sm text-gray-500">
                          {renderTags([
                            'Bridesmaid',
                            'Hangers',
                            'Personalized',
                            'Hanger',
                            'Wedding',
                            'Bridesmaid',
                            'Hangers',
                            'Personalized',
                            'Hanger',
                            'Wedding',
                            'Bridesmaid',
                            'Hangers',
                            'Personalized',
                            'Hanger',
                            'Wedding',
                          ])}
                        </td>
                      </tr>
                    ))}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
      {session?.hasPackage && (
        <Pagination
          setPage={setPage}
          page={page}
          inputPage={inputPage}
          setInputPage={setInputPage}
          totalPageCount={totalPageCount!}
          queryString={queryString}
        />
      )}
    </div>
  );
};

export default EtsyTopProductsTable;
