import { useFormik } from 'formik';
import { Location } from 'iconsax-react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import { ChevronDoubleUpIcon } from '@heroicons/react/20/solid';
import { useQuery } from '@tanstack/react-query';

import DotLoaderSpinner from '../components/common/DotLoaderSpinner';
import RatingStars from '../components/common/RatingStars';
import NumVerifyModal from '../components/numberVerifyModals/NumVerifyModal';
import VerifySuccessModal from '../components/numberVerifyModals/VerifySuccessModal';
import MessageModal from '../components/productsAndWholeSalers/MessageModal';
import SaleRegister from '../components/productsAndWholeSalers/SaleRegister';
import { Producer } from '../components/productsAndWholeSalers/WholeSalersWrapper';
import { TurkishCities } from '../components/shipentegraCreatePost/elements/states';
import SurveyModal from '../components/survey/SurveyModal';
import { useAuth } from '../context/AuthContext';
import { useLanguage } from '../context/LanguageContext';
import { useNumberVerification } from '../context/NumberVerificationContext';
import memberService from '../services/memberService';
import producerService from '../services/producerService';
import { classNames } from '../utils/conditionalClasses';

interface City {
  value: string;
  name: string;
}

// Varsayılan olarak, tüm şehirlerin değeri false olarak ayarlanacak.
// type CitiesState = { [key in City['value']]?: boolean };

const turkishCities: City[] = TurkishCities;

// Formik initialValues için uygun tipi belirleme
interface FormValues {
  location: any[];
  minProductCount: number | string;
  maxProductCount: number | string;
  minRating: number | string;
  maxRating: number | string;
  minReviewCount: number | string;
  maxReviewCount: number | string;
}

const validationSchema = Yup.object().shape({
  category: Yup.string(),
  minProductCount: Yup.number(),
  maxProductCount: Yup.number(),
  minRating: Yup.number(),
  maxRating: Yup.number(),
  minReviewCount: Yup.number(),
  maxReviewCount: Yup.number(),
});

const Suppliers = () => {
  const { t } = useLanguage();
  const { session } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const { handleActiveModal } = useNumberVerification();
  const [selectedCategory, setSelectedCategory] = useState<number | string>(
    Number(searchParams.get('categoryId')) || ''
  );
  const categories = [
    {
      id: 1,
      categoryName: 'Tüm Kategoriler',
    },
    {
      id: 368,
      categoryName: 'Aksesuar',
    },
    {
      id: 403,
      categoryName: 'Ayakkabı',
    },
    {
      id: 522,
      categoryName: 'Giyim',
    },
    {
      id: 685,
      categoryName: 'Hobi & Eğlence',
    },
    // {
    //   id: 687,
    //   categoryName: 'Kitap',
    // },
    {
      id: 758,
      categoryName: 'Ev & Mobilya',
    },
    {
      id: 790,
      categoryName: 'Otomobil & Motosiklet',
    },
    {
      id: 1070,
      categoryName: 'Kozmetik & Kişisel Bakım',
    },
    {
      id: 1071,
      categoryName: 'Elektronik',
    },
    {
      id: 1216,
      categoryName: 'Kırtasiye & Ofis Malzemeleri',
    },
    {
      id: 1219,
      categoryName: 'Süpermarket',
    },
    {
      id: 2862,
      categoryName: 'Anne & Bebek & Çocuk',
    },
    {
      id: 3186,
      categoryName: 'Spor & Outdoor',
    },
    // {
    //   id: 3981,
    //   categoryName: 'Ek Hizmetler',
    // },
    {
      id: 5558,
      categoryName: 'Bahçe & Elektrikli El Aletleri',
    },
    {
      id: 5559,
      categoryName: 'Banyo Yapı & Hırdavat',
    },
  ];
  const [open, setOpen] = React.useState(false);
  const [producerId, setProducerId] = React.useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState(searchParams.get('keyword') || '');
  const [pageNo, setPageNo] = useState(Number(searchParams.get('page')) || 1);
  const [wholeSalers, setWholeSalers] = useState<Producer[]>([]);
  const [wholeTotalPage, setWholeTotalPage] = useState(1);
  let temp = Object.fromEntries([...searchParams]);

  const [queryParams, setQueryParams] = useState({
    page: temp.page || '1',
    location: temp.location || [],
    minProductCount: temp.minProductCount || '',
    maxProductCount: temp.maxProductCount || '',
    categoryId: temp.categoryId || '',
    minRating: temp.minRating || '',
    keyword: temp.keyword || '',
    maxRating: temp.maxRating || '',
    minReviewCount: temp.minReviewCount || '',
    maxReviewCount: temp.maxReviewCount || '',
  });

  const inputRef = useRef<HTMLInputElement | null>(null);
  const navigate = useNavigate();
  const initialValues = {
    page: searchParams.get('page') || '1',
    location: searchParams.get('location')
      ? [searchParams.get('location')]
      : [],
    minProductCount: searchParams.get('minProductCount') || '',
    maxProductCount: searchParams.get('maxProductCount') || '',
    categoryId: searchParams.get('categoryId') || '',
    minRating: searchParams.get('minRating') || '',
    maxRating: searchParams.get('maxRating') || '',
    minReviewCount: searchParams.get('minReviewCount') || '',
    maxReviewCount: searchParams.get('maxReviewCount') || '',
  };
  const observer = useRef<IntersectionObserver | null>(null);
  const { isLoading, error, data } = useQuery<any, Error>(
    ['suppliers', pageNo, queryParams],
    {
      queryFn: () => producerService.getProducers(pageNo, queryParams),

      staleTime: Infinity,
      refetchOnWindowFocus: false,
      // keepPreviousData: true,
    }
  );

  const lastProductRef = useCallback(
    (node: HTMLElement | null) => {
      console.log({ loading });
      if (loading) return;
      if (observer.current) observer?.current?.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && pageNo < wholeTotalPage) {
          setPageNo((prevPage) => prevPage + 1);
          const params = Object.fromEntries(searchParams.entries());

          params.page = String(pageNo + 1);
          setSearchParams(params);
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, wholeTotalPage, pageNo, searchParams, setSearchParams]
  );
  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    // setSearch(String(inputRef.current?.value));
    const params = Object.fromEntries(searchParams.entries());
    params.keyword = String(inputRef.current?.value);
    params.page = '1';
    setSearchParams(params);
    setQueryParams({
      ...queryParams,
      keyword: String(inputRef.current?.value),
    });
    setWholeSalers([]);
    // navigate(`?page=1&keyword=${inputRef.current?.value}`);
    setPageNo(1);
  };

  const updateProducerStats = (
    producerId: number,
    incrementType: 'view' | 'communication'
  ) => {
    producerService
      .producerStatistics({ producerId, incrementType })
      .then((res) => { })
      .catch((res) => {
        // console.log(res);
      });
  };

  const formik = useFormik<FormValues>({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      setWholeSalers([]);
      const params = {
        page: '1',
        keyword: search,
        categoryId: selectedCategory.toString(),
        location: values.location.join(','),
        minProductCount: values.minProductCount.toString(),
        maxProductCount: values.maxProductCount.toString(),
        minReviewCount: values.minReviewCount.toString(),
        maxReviewCount: values.maxReviewCount.toString(),
        minRating: values.minRating.toString(),
        maxRating: values.maxRating.toString(),
      };
      setSearchParams(params);

      const { page, ...rest } = params;
      setPageNo(1);
      setQueryParams(params);
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
  });

  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    setQueryParams({
      page: temp.minPrice || '1',
      location: temp.location || [],
      minProductCount: temp.minProductCount || '',
      maxProductCount: temp.maxProductCount || '',
      categoryId: temp.categoryId || '',
      minRating: temp.minRating || '',
      keyword: temp.keyword || '',
      maxRating: temp.maxRating || '',
      minReviewCount: temp.minReviewCount || '',
      maxReviewCount: temp.maxReviewCount || '',
    });
  }, [searchParams]);

  useEffect(() => {
    handleActiveModal?.(undefined, true);
  }, []);

  useEffect(() => {
    let intervalId: any;
    if (session) {
      intervalId = setInterval(async () => {
        await memberService.userTrackReq(`suppliers`, session?.id!);
      }, 60000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [session]);

  useEffect(() => {
    if (data) {
      setWholeSalers((prev) => [...prev, ...data.data.producers]);
      setWholeTotalPage(data.data.totalPageCount);
      setLoading(false);
    }
  }, [data]);

  return (
    <>
      <NumVerifyModal />
      <VerifySuccessModal />
      <SurveyModal />
      <div className="flex w-full flex-shrink-0 flex-col">
        <div className="bg-white rounded-2xl p-2 md:p-4">
          {/* NOTE: search Bar*/}
          <div className="grid grid-cols-12 gap-x-4 gap-y-2 lg:gap-y-0 justify-center items-center">
            {/* Logo */}
            <div className="col-span-12 lg:col-span-2">
              <Link
                to={'/'}
                className={classNames(
                  'flex h-16 flex-shrink-0 items-center px-4 justify-between'
                )}
              >
                <img
                  className="h-5 w-auto"
                  src="/assets/logos/rexven-logo.svg"
                  alt="Rexven Company"
                />
              </Link>
            </div>

            {/* Search Bar Field */}
            <div className="col-span-12 lg:col-span-8">
              <form className="" onSubmit={handleSubmit}>
                <label
                  htmlFor="default-search"
                  className="mb-2 text-lg lg:text-sm font-medium text-gray-900 sr-only dark:text-white"
                >
                  Search
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      aria-hidden="true"
                      className="w-5 h-5 text-gray-500 dark:text-gray-400"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      ></path>
                    </svg>
                  </div>
                  <input
                    ref={inputRef}
                    id="default-search"
                    className="block w-full p-[0.58rem] pl-10 text-xs md:text-sm text-gray-900 border border-headerPrimary rounded-lg bg-gray-50 "
                    placeholder={'Tedarikçi Bul'}
                  />
                  <button
                    type="submit"
                    className="text-white absolute right-0 bottom-0 bg-headerPrimary rounded-r-md font-medium text-sm px-4 py-2 md:py-2.5"
                  >
                    {t?.('common.buttons.search')}
                  </button>
                </div>
              </form>
            </div>

            {/* Register Button Field */}
            <div className="col-span-12 lg:col-span-2">
              <SaleRegister />
            </div>
            <NumVerifyModal />
          </div>
          {/* NOTE: categories*/}
          <div className="flow-root">
            <div className="relative -mx-2 -my-2 overflow-x-auto overflow-y-auto scrollbar-hide">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="w-full my-10 overflow-hidden">
                  <div className="flex items-center justify-between overflow-auto touch-pan-x py-3">
                    {categories?.map((category: any) => (
                      <p
                        onClick={() => {
                          setWholeSalers([]);
                          setLoading(true);
                          setPageNo(1);
                          setSelectedCategory(
                            category.id === 1 ? '' : category.id.toString()
                          );

                          setQueryParams({
                            ...queryParams,
                            categoryId:
                              category.id === 1 ? '' : category.id.toString(),
                          });

                          const params = Object.fromEntries(
                            searchParams.entries()
                          );
                          params.categoryId =
                            category.id === 1 ? '' : String(category.id);
                          params.page = '1';
                          setSearchParams(params);
                          // navigate(
                          //   `?${new URLSearchParams({
                          //     ...searchParams,
                          //     categoryId:
                          //       category.id === 1 ? '' : category.id.toString(),
                          //   }).toString()}`
                          // );
                        }}
                        className={classNames(
                          selectedCategory == category.id
                            ? 'border-b-2 font-bold text-md border-headerPrimary text-[#1F2937]'
                            : 'text-[#374151] text-sm xl:text-sm',
                          'whitespace-nowrap font-medium  min-[1306px]:font-medium text-center  px-3 first:pl-0 last:pr-0  cursor-pointer inline-block hover:text-headerPrimary'
                        )}
                        key={category.id}
                      >
                        {category.categoryName}
                      </p>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* side categories and product cards */}
          <div className="grid grid-cols-12 gap-x-4 gap-y-2 lg:gap-y-0 justify-center items-start ">
            {/* side categories*/}
            <div className="col-span-12 min-[450px]:col-span-2 pt-4 min-[450px]:sticky min-[450px]:top-[64px]">
              {' '}
              {/* Örnek: Header yüksekliğine göre ayarlama yapın */}
              <form onSubmit={formik.handleSubmit} className="space-y-4">
                {/* Konum */}
                <div className="">
                  <label
                    htmlFor="location"
                    className="block text-xs md:text-sm font-medium text-gray-700 border-b-2"
                  >
                    Konum
                  </label>
                </div>
                <div className="relative mb-8">
                  <div className="max-h-40 overflow-y-auto scrollbar-thin scrollbar-thumb-gray-300">
                    <div className="flex flex-col gap-1">
                      {turkishCities.map((city) => (
                        <label
                          key={city.value}
                          className="flex items-center space-x-1 md:space-x-2"
                        >
                          <input
                            type="checkbox"
                            name={`cities.${city.value}`}
                            onChange={(e) => {
                              if (e.target.checked) {
                                formik.setFieldValue('location', [
                                  ...formik.values.location,
                                  city.name,
                                ]);
                              } else {
                                typeof formik.values.location === 'object' &&
                                  formik.setFieldValue(
                                    'location',
                                    formik.values.location.filter(
                                      (item) => item !== city.name
                                    )
                                  );
                              }
                            }}
                            checked={formik.values.location.includes(city.name)}
                            className="rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          />
                          <span className="text-xs xl:text-sm font-medium text-gray-700">
                            {city.name}
                          </span>
                        </label>
                      ))}
                    </div>
                  </div>
                </div>

                {/* Ürün Sayısı */}
                <div className="flex flex-col gap-1">
                  <label
                    htmlFor="minProductCount"
                    className="block text-xs font-medium text-gray-700"
                  >
                    Ürün Sayısı
                  </label>
                  <div className="flex">
                    <input
                      id="minProductCount"
                      name="minProductCount"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.minProductCount}
                      placeholder="Min"
                      className="mt-1 block w-full py-0 placeholder:text-xs  rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                    <span className="flex items-center mx-1">-</span>
                    <input
                      id="maxProductCount"
                      name="maxProductCount"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.maxProductCount}
                      placeholder="Max"
                      className="mt-1 block w-full py-0 placeholder:text-xs  rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                </div>
                {/* YORUM SAYISI */}
                <div className="flex flex-col gap-2">
                  <label
                    htmlFor="minReviewCount"
                    className="block text-xs font-medium text-gray-700"
                  >
                    Yorum Sayısı
                  </label>
                  <div className="flex">
                    <input
                      id="minReviewCount"
                      name="minReviewCount"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.minReviewCount}
                      placeholder="Min"
                      className="mt-1 block w-full py-0 placeholder:text-xs  rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                    <span className="flex items-center mx-2">-</span>
                    <input
                      id="maxReviewCount"
                      name="maxReviewCount"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.maxReviewCount}
                      placeholder="Max"
                      className="mt-1 block w-full py-0 placeholder:text-xs  rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                </div>
                {/* RATING  */}
                <div className="flex flex-col gap-2">
                  <label
                    htmlFor="minRating"
                    className="block text-xs font-medium text-gray-700"
                  >
                    Değerlendirme
                  </label>
                  <div className="flex">
                    <input
                      id="minRating"
                      name="minRating"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.minRating}
                      placeholder="Min"
                      className="mt-1 block w-full py-0 placeholder:text-xs  rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                    <span className="flex items-center mx-2">-</span>
                    <input
                      id="maxRating"
                      name="maxRating"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.maxRating}
                      placeholder="Max"
                      className="mt-1 block w-full py-0 placeholder:text-xs  rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                    />
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="hover:cursor-pointer text-center text-[12px] bg-headerPrimary font-bold text-white h-[40px] max-h-[50px] px-3 py-1  border-2 rounded-lg border-headerPrimary w-full flex items-center justify-center"
                  >
                    Uygula
                  </button>
                  <button
                    onClick={() => {
                      formik.resetForm();
                      setSearchParams({
                        page: searchParams.get('page')!,
                        keyword: searchParams.get('keyword')!,
                        categoryId: '',
                        location: [],
                        minProductCount: '',
                        maxProductCount: '',
                        minReviewCount: '',
                        maxReviewCount: '',
                        minRating: '',
                        maxRating: '',
                      });
                    }}
                    className="hover:cursor-pointer text-center text-[12px] bg-white font-bold text-headerPrimary h-[40px] max-h-[50px] w-full px-3 py-1  border-2 rounded-lg border-headerPrimary w-[49%] flex items-center justify-center mt-3"
                  >
                    Temizle
                  </button>
                </div>
              </form>
            </div>
            {/* product cards */}

            <div className="col-span-12 min-[450px]:col-span-10 grid grid-cols-12 gap-6 justify-center items-center pt-4">
              {loading && <DotLoaderSpinner loading={loading} />}
              {wholeSalers?.map((wholeSaler: any, index: number) => (
                <div
                  key={uuidv4()}
                  ref={
                    index === wholeSalers.length - 1
                      ? lastProductRef
                      : undefined
                  }
                  className="col-span-12 md:col-span-6 lg:col-span-4 2xl:col-span-3 "
                >
                  <div className="max-w-sm rounded-lg mx-auto overflow-hidden shadow-lg bg-[#F9FAFB] p-4 min-h-[480px] flex flex-col justify-between">
                    <div className="flex items-center space-x-4">
                      <div className="rounded-full bg-gray-300 w-1/3 flex items-center justify-center mb-2">
                        <img
                          // src={
                          //   wholeSaler.profile_image &&
                          //   wholeSaler.profile_image !== null
                          //     ? `https://d996yidkdlucp.cloudfront.net/${wholeSaler.profile_image}`
                          //     : wholeSaler.images &&
                          //       wholeSaler.images.length > 0
                          //     ? wholeSaler.images[0].url
                          //     : '/images/notFound.png'
                          // }
                          src={
                            wholeSaler.logo &&
                              wholeSaler.logo !== null
                              ? `${wholeSaler.logo}`
                              : wholeSaler.profile_image &&
                                wholeSaler.profile_image !== null
                                ? `https://d996yidkdlucp.cloudfront.net/${wholeSaler.profile_image}`
                                : wholeSaler.images &&
                                  wholeSaler.images.length > 0
                                  ? wholeSaler.images[0].url
                                  : '/images/notFound.png'
                          }
                          alt="producer_image"
                          className="rounded-full w-full aspect-square object-cover object-center"
                        />
                      </div>
                      <div className="flex flex-col gap-1 mb-2 w-2/3">
                        <div className="font-bold text-md">
                          {wholeSaler.producer_name}
                        </div>
                        <div className="flex items-center">
                          <p className="text-xs text-description">
                            Mağaza Puanı:{' '}
                          </p>
                          <RatingStars
                            rating={Number(wholeSaler.producer_rating)}
                            shop={true}
                          />
                          <span className="text-xs font-bold text-[#374151] ml-1">
                            ({wholeSaler.producer_rating})
                          </span>
                        </div>
                        <div className="flex items-center">
                          <Location className="text-rexRed h-4 w-4" />
                          <div className="text-rexRed text-sm">
                            {wholeSaler.location}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="my-4">
                      <p className="font-bold text-sm xl:text-base text-[#374151] mb-2">
                        Satış Yaptığı Kategoriler:
                      </p>
                      <div className="flex flex-wrap gap-2 h-20 overflow-y-auto items-center">
                        {/* Tag list */}
                        {wholeSaler.categories &&
                          wholeSaler?.categories.map((tag: any) => (
                            <span
                              key={tag}
                              className="bg-gray-200 rounded-full px-3 py-1 text-xs text-[#4B5563]"
                            >
                              {tag}
                            </span>
                          ))}
                      </div>
                    </div>

                    <div className="my-4">
                      <p className="font-bold text-sm xl:text-base text-[#374151] mb-2">
                        Ürünler:
                      </p>
                      <div className="flex items-center gap-2">
                        {/* Product images */}
                        {wholeSaler?.products?.length > 0 &&
                          wholeSaler?.products?.map((item: any) => {
                            return (
                              <div
                                key={
                                  item.image !== null
                                    ? item.image.url
                                    : uuidv4()
                                }
                                className="cursor-pointer rounded overflow-hidden w-10 h-10"
                              >
                                <img
                                  src={`${item.image !== null ? item.image.url : ''
                                    }`}
                                  alt="item"
                                  className=" object-cover object-center w-10 h-10"
                                />
                              </div>
                            );
                          })}
                      </div>
                    </div>
                    <div className="flex flex-col space-y-2 mt-2">
                      <Link
                        to={`/manufacturer/${wholeSaler.id}`}
                      // className="text-center text-[13px] px-3 h-[50px] max-h-[50px] font-bold  lg:py-1 border-2 rounded-lg border-headerPrimary w-[49%] flex items-center"
                      >
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            // if (wholeSaler?.id) {
                            //   setProducerId?.(wholeSaler.id);
                            // }
                            // setOpen(true);
                            navigate(
                              `/messages?to=${wholeSaler.producer_user_id
                              }&name=${`${wholeSaler.first_name} ${wholeSaler.last_name}`}&profile=${wholeSaler.profile_image
                              }`
                            );
                          }}
                          className="hover:cursor-pointer text-center text-[12px] bg-headerPrimary font-bold text-white h-[40px] max-h-[50px] w-full px-3 py-1  border-2 rounded-lg border-headerPrimary w-[49%] flex items-center justify-center"
                        >
                          İletişim Kur
                        </button>
                      </Link>
                      <Link
                        to={`/suppliers/${wholeSaler.id}`}
                        target="_blank"
                      // className="text-center text-[13px] px-3 h-[50px] max-h-[50px] font-bold  lg:py-1 border-2 rounded-lg border-headerPrimary w-[49%] flex items-center"
                      >
                        <button
                          onClick={() => {
                            updateProducerStats(wholeSaler.id, 'view');
                          }}
                          className="hover:cursor-pointer text-center text-[12px] bg-white font-bold text-headerPrimary h-[40px] max-h-[50px] w-full px-3 py-1  border-2 rounded-lg border-headerPrimary w-[49%] flex items-center justify-center"
                        >
                          Ürünleri İncele
                        </button>
                      </Link>
                    </div>
                    <p className="text-xs text-[#4B5563] mt-2 min-h-[20px]">
                      {wholeSaler.view_count !== '0' &&
                        wholeSaler.view_count !== undefined &&
                        wholeSaler.view_count !== null && (
                          <>
                            Bu tedarikçinin ürünleri
                            <span className="font-medium">{` ${wholeSaler.view_count} `}</span>
                            kere incelendi
                          </>
                        )}
                      <br />
                      {wholeSaler.view_count !== '0' &&
                        wholeSaler.view_count !== undefined &&
                        wholeSaler.view_count !== null &&
                        wholeSaler.communication_count !== '0' &&
                        wholeSaler.communication_count !== undefined &&
                        wholeSaler.communication_count !== null && (
                          <>
                            <span className="font-medium">{`${wholeSaler.communication_count} `}</span>
                            girişimci iletişim kurdu.
                          </>
                        )}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {pageNo > 1 && (
        <div
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          }}
          className="fixed right-20 bottom-5 mr-4 mb-4 text-2xl rounded-full p-2 bg-black/20 text-white cursor-pointer"
        >
          <ChevronDoubleUpIcon className="h-6 w-6" />
        </div>
      )}
      <MessageModal
        open={open}
        setOpen={setOpen}
        producerId={producerId!}
        updateProducerStats={updateProducerStats}
      />
    </>
  );
};

export default Suppliers;
