import React from 'react';

const RexvenUsage = () => {
  return (
    <div className="flex flex-col max-w-[1280px] mx-auto py-10">
      <p className="font-medium">KULLANIM KOŞULLARI</p>
      <p>
        <br />
      </p>
      <p>
        L&uuml;tfen sitemizi kullanmadan evvel bu &lsquo;Kullanım
        Koşullarını&rsquo; dikkatlice okuyunuz. Bu siteyi kullanan, &uuml;ye
        olan ve alışveriş yapan m&uuml;şterilerimiz aşağıdaki şartları kabul
        etmiş varsayılmaktadır:
      </p>
      <p>
        <br />
      </p>
      <p>
        Sitemizdeki web sayfaları ve ona bağlı t&uuml;m sayfalar
        (&lsquo;site&rsquo;) Mutlukent Mah. 2041 Sok. No :5/1
        &Ccedil;ankaya/Ankara adresindeki Rexven Teknoloji A.Ş.&rsquo;nin
        (Rexven) malıdır ve onun tarafından işletilir. Sizler
        (&lsquo;Kullanıcı&rsquo;) sitede sunulan t&uuml;m hizmetleri kullanırken
        aşağıdaki şartlara tabi olduğunuzu, sitedeki hizmetten yararlanmakla ve
        kullanmaya devam etmekle; bağlı olduğunuz yasalara g&ouml;re
        s&ouml;zleşme imzalama hakkına, yetkisine ve hukuki ehliyetine sahip ve
        18 yaşın &uuml;zerinde olduğunuzu, bu s&ouml;zleşmeyi okuduğunuzu,
        anladığınızı ve s&ouml;zleşmede yazan şartlarla bağlı olduğunuzu kabul
        etmiş sayılırsınız.&nbsp;
      </p>
      <p>
        <br />
      </p>
      <p>
        İşbu s&ouml;zleşme taraflara s&ouml;zleşme konusu site ile ilgili hak ve
        y&uuml;k&uuml;ml&uuml;l&uuml;kler y&uuml;kler ve taraflar işbu
        s&ouml;zleşmeyi kabul ettiklerinde bahsi ge&ccedil;en hak ve
        y&uuml;k&uuml;ml&uuml;l&uuml;kleri eksiksiz, doğru, zamanında, işbu
        s&ouml;zleşmede talep edilen şartlar dahilinde yerine getireceklerini
        beyan ederler.
      </p>
      <p>
        <br />
      </p>
      <p className="font-medium">1. &Uuml;YELİK</p>
      <p>
        <br />
      </p>
      <ol>
        <li>
          <p>
            Rexven &uuml;yeliğiniz, sonlandırılıncaya kadar devam eder.&nbsp;
            <a href="http://www.rexven.com">www.rexven.com</a> internet
            sitesinde sunulan &uuml;r&uuml;nleri/hizmetleri kullanabilmek
            i&ccedil;in internet erişimine ve bize bir &Ouml;deme Y&ouml;ntemi
            sunmalısınız. &ldquo;&Ouml;deme Y&ouml;ntemi&rdquo;, zaman zaman
            g&uuml;ncelleyebileceğiniz ve hesabınız &uuml;zerinden mevcut,
            ge&ccedil;erli, tanınan bir &ouml;deme y&ouml;ntemi anlamına gelir.
            &Uuml;yeliğinizi fatura kesim tarihinden &ouml;nce iptal etmediğiniz
            s&uuml;rece, tarafımıza bir sonraki faturalama d&ouml;neminin
            &uuml;yelik &uuml;cretini &Ouml;deme Y&ouml;ntemi &uuml;zerinden
            tahsil etme yetkisi vermiş olursunuz (bkz. &quot;&Uuml;yelik
            İptali&quot;).
          </p>
        </li>
        <li>
          <p>
            Rexven, &uuml;&ccedil;&uuml;nc&uuml; taraflarca sunulan
            &uuml;r&uuml;nlerden veya hizmetlerden sorumlu tutulamaz. Bazı
            &uuml;yelik planları, kaydolduğunuz sırada veya size sunulan diğer
            iletişimlerde a&ccedil;ıklanacak farklı koşullara ve
            sınırlandırmalara sahip olabilir. Rexven &uuml;yeliğinize dair
            bilgilere, www.rexven.com web sitesini ziyaret ederek sayfanın
            &uuml;st kısmında bulunan &quot;Hesap&quot; bağlantısı aracılığıyla
            erişebilirsiniz.
          </p>
        </li>
        <li>
          <p>
            İndirimli Teklifler: Zaman zaman &ouml;zel promosyon ama&ccedil;lı
            indirimli teklifler, planlar veya &uuml;yelikler
            (&quot;Teklifler&quot;) sunabiliriz. Teklif kriterleri tamamen
            Rexven&rsquo;in takdirine bağlı olarak belirlenir. Kriterlere
            uymadığınızın saptanması h&acirc;linde bir Teklifi iptal etme ve
            hesabınızı askıya alma hakkımız saklıdır. Mevcut veya yakın zamanda
            Rexven &uuml;yeliği olan &uuml;yeler, belirli tanıtım Teklifleri
            i&ccedil;in uygun şartları taşımayabilir. Teklif kriterlerine
            uygunluğun saptanması amacıyla, mevcutta veya yakın zamanda Rexven
            &uuml;yeliği i&ccedil;in kullanılmış cihaz kimliği, &ouml;deme
            y&ouml;ntemi ya da hesap e-posta adresi gibi bilgiler
            kullanılabilir. Uygunluk kriterleri ve diğer sınırlamalar ile
            koşullar, Teklif i&ccedil;in kaydolduğunuzda veya kullanımınıza
            sunulan diğer iletişimlerde a&ccedil;ıklanacaktır.
          </p>
        </li>
      </ol>
      <p>
        <br />
      </p>
      <p className="font-medium">2. FATURALAMA VE &Uuml;YELİK İPTALİ</p>
      <p>
        <br />
      </p>
      <ol>
        <li>
          <p>
            Faturalama D&ouml;nemleri: Rexven&rsquo;in &uuml;yelik &uuml;creti
            ve hizmeti kullanımınıza bağlı ortaya &ccedil;ıkabilecek vergi ve
            olası işlem &uuml;cretleri gibi diğer t&uuml;m masraflar,
            &quot;Hesap&quot; sayfasında belirtilen belli &ouml;deme tarihinde,
            se&ccedil;tiğiniz &Ouml;deme Y&ouml;ntemi &uuml;zerinden tahsil
            edilecektir. Faturalama d&ouml;neminizin uzunluğu, hizmete
            kaydolduğunuzda se&ccedil;tiğiniz abonelik t&uuml;r&uuml;ne bağlı
            olacaktır. &Ouml;deme Y&ouml;nteminizin onaylanamaması, abonelik
            planınızı değiştirmeniz ya da &uuml;cretli &uuml;yeliğinizin
            s&ouml;z konusu aya d&acirc;hil olmayan bir g&uuml;nde başlaması
            gibi durumlarda &ouml;deme tarihinizde değişiklikler yaşanabilir.
            Bir sonraki &ouml;deme tarihinizi g&ouml;rmek i&ccedil;in
            www.rexven.com web sitesini ziyaret edip &quot;Hesap&quot;
            sayfasındaki &quot;&Ouml;deme ge&ccedil;mişi&quot;
            b&ouml;l&uuml;m&uuml;nde g&ouml;rebilirsiniz. &Uuml;yelik veya
            hizmetle ilgili &uuml;cretler
            &ouml;ng&ouml;rd&uuml;ğ&uuml;m&uuml;zde &Ouml;deme Y&ouml;nteminizi,
            hesap a&ccedil;tığınız anda yaklaşık bir aya kadar s&uuml;reyle
            hizmet almanıza izin vermek de dahil olmak &uuml;zere,
            &ccedil;eşitli y&ouml;ntemlerle onaylayabiliriz.&nbsp;
          </p>
        </li>
        <li>
          <p>
            &Ouml;deme Y&ouml;ntemleri: Rexven web sitesindeki &uuml;r&uuml;n ve
            hizmetleri kullanabilmek i&ccedil;in bir &Ouml;deme Y&ouml;ntemi
            sunmalısınız. &Ouml;deme Y&ouml;nteminizin reddedilmesi veya
            abonelik &uuml;cretinizin &ouml;demesi i&ccedil;in artık
            kullanılamaması durumunda, tahsil edilmeyen tutarlardan sorumlu
            olmaya devam edersiniz. &Ouml;deme y&ouml;ntemi ge&ccedil;erlilik
            tarihinin sona ermesi, yeterli bakiye olmaması veya başka
            nedenlerden dolayı &ouml;demenin başarıyla ger&ccedil;ekleşmemesi ve
            hesabınızı iptal etmemeniz halinde, abonelik &uuml;cretini
            ge&ccedil;erli bir &Ouml;deme Y&ouml;nteminden tahsil edinceye kadar
            hizmetimize erişiminizi askıya alabiliriz. Bazı &Ouml;deme
            Y&ouml;ntemlerini kullanmanız h&acirc;linde sağlayıcı kuruluş,
            tarafınızdan &Ouml;deme Y&ouml;nteminizin işlenmesiyle alakalı
            yabancı işlem &uuml;creti gibi &ccedil;eşitli masraflar tahsil
            edebilir. Yerel vergi tutarları kullanılan &Ouml;deme Y&ouml;ntemine
            bağlı olarak değişiklik g&ouml;sterebilir.
          </p>
        </li>
        <li>
          <p>
            &Uuml;yelik İptali: Rexven &uuml;yeliğinizi dilediğiniz zaman iptal
            edebilir ve faturalama d&ouml;neminizin sonuna kadar Rexven
            hizmetinden faydalanmaya devam edebilirsiniz. İlgili yasaların izin
            verdiği &ouml;l&ccedil;&uuml;de &ouml;demelerin iadesi yoktur ve
            tamamlanmamış &uuml;yelik d&ouml;nemleri ya da kullanılmamış Rexven
            i&ccedil;erikleri i&ccedil;in tarafımızca iade ya da kredi
            tanımlaması yapılmamaktadır. &Uuml;yeliğinizi iptal etmek
            i&ccedil;in, &quot;Hesap&quot; sayfasına gidin ve iptal işlemlerine
            y&ouml;nelik talimatları izleyin. &Uuml;yeliğinizi iptal etmeniz
            halinde, hesabınız mevcut faturalama d&ouml;neminizin sonunda
            otomatik olarak kapanacaktır.&nbsp;
          </p>
        </li>
        <li>
          <p>
            &Uuml;cret ve Abonelik Planı Değişiklikleri: Abonelik planımızda ve
            hizmetimizin &uuml;cretinde zaman zaman değişiklik yapabiliriz.
            Ancak yapılan herhangi bir &uuml;cret değişikliği veya abonelik
            planınızdaki değişiklikler, tarafınıza bildirim yapılmasını takiben
            en erken 30 g&uuml;n sonra ge&ccedil;erli olacaktır. &Uuml;cret
            değişikliğini veya abonelik planınızda yapılan değişikliği kabul
            etmek istemiyorsanız değişiklik y&uuml;r&uuml;rl&uuml;ğe girmeden
            &ouml;nce aboneliğinizi iptal edebilirsiniz.
          </p>
        </li>
      </ol>
      <p>
        <br />
      </p>
      <p>
        <br />
      </p>
      <p className="font-medium">3. SORUMLULUKLAR</p>
      <p>
        <br />
      </p>
      <ol>
        <li>
          <p>
            Rexven, bu yasal uyarı sayfasının i&ccedil;eriğini g&uuml;ncelleme
            yetkisini, fiyatlar ve sunulan &uuml;r&uuml;n ve hizmetler
            &uuml;zerinde değişiklik yapma hakkını her zaman saklı tutmaktadır
            ve kullanıcılarına siteye her girişte yasal uyarı sayfasını ziyaret
            etmelerini tavsiye etmektedir.
          </p>
        </li>
        <li>
          <p>
            Rexven işbu site ve site uzantısında mevcut her t&uuml;r hizmet,
            &uuml;r&uuml;n, siteyi kullanma koşulları ile sitede sunulan
            bilgileri &ouml;nceden bir ihtara gerek olmaksızın değiştirme,
            siteyi yeniden organize etme, yayını durdurma hakkını saklı tutar.
            Değişiklikler sitede yayım anında y&uuml;r&uuml;rl&uuml;ğe girer.
            Sitenin kullanımı ya da siteye giriş ile bu değişiklikler de kabul
            edilmiş sayılır. Bu koşullar link verilen diğer web sayfaları
            i&ccedil;in de ge&ccedil;erlidir.
          </p>
        </li>
        <li>
          <p>
            Rexven, &uuml;yenin s&ouml;zleşme konusu hizmetlerden, teknik
            arızalar dışında yararlandırılacağını kabul ve taahh&uuml;t eder.
          </p>
        </li>
        <li>
          <p>
            Bu internet sitesine girilmesi, sitenin ya da sitedeki bilgilerin ve
            diğer verilerin programların vs. kullanılması sebebiyle,
            s&ouml;zleşmenin ihlali, haksız fiil, ya da başkaca sebeplere
            binaen, doğabilecek doğrudan ya da dolaylı oluşabilecek zararlardan
            Rexven Teknoloji A.Ş sorumlu değildir. Rexven, s&ouml;zleşmenin
            ihlali, haksız fiil, ihmal veya diğer sebepler neticesinde; işlemin
            kesintiye uğraması, hata, ihmal, kesinti hususunda herhangi bir
            sorumluluk kabul etmez.
          </p>
        </li>
        <li>
          <p>
            Rexven, s&ouml;zleşmenin ihlali, haksız fiil, ihmal veya diğer
            sebepler neticesinde; işlemin kesintiye uğraması, hata, ihmal,
            kesinti, silinme, kayıp, işlemin veya iletişimin gecikmesi,
            bilgisayar vir&uuml;s&uuml;, iletişim hatası, hırsızlık, imha veya
            izinsiz olarak kayıtlara girilmesi, değiştirilmesi veya kullanılması
            hususunda herhangi bir sorumluluk kabul etmez. Bu internet sitesi
            Rexven&rsquo;in kontrol&uuml; altında olmayan başka internet
            sitelerine bağlantı veya referans i&ccedil;erebilir. Rexven, bu
            sitelerin i&ccedil;erikleri veya i&ccedil;erdikleri diğer
            bağlantılardan sorumlu değildir.&nbsp;
          </p>
        </li>
        <li>
          <p>
            Rexven bu internet sitesinin genel g&ouml;r&uuml;n&uuml;m ve dizaynı
            ile internet sitesindeki t&uuml;m bilgi, resim, Rexven markası ve
            diğer markalar, www.rexven.com alan adı, logo, ikon, demonstratif,
            yazılı, elektronik, grafik veya makinede okunabilir şekilde sunulan
            teknik veriler, bilgisayar yazılımları, uygulanan satış sistemi, iş
            metodu ve iş modeli de dahil t&uuml;m materyallerin
            (&quot;Materyaller&quot;) ve bunlara ilişkin fikri ve sınai
            m&uuml;lkiyet haklarının sahibi veya lisans sahibidir ve yasal
            koruma altındadır. Internet sitesinde bulunan hi&ccedil;bir
            Materyal; &ouml;nceden izin alınmadan ve kaynak g&ouml;sterilmeden,
            kod ve yazılım da dahil olmak &uuml;zere, değiştirilemez,
            kopyalanamaz, &ccedil;oğaltılamaz, başka bir lisana
            &ccedil;evrilemez, yeniden yayımlanamaz, başka bir bilgisayara
            y&uuml;klenemez, postalanamaz, iletilemez, sunulamaz ya da
            dağıtılamaz. Internet sitesinin b&uuml;t&uuml;n&uuml; veya bir kısmı
            başka bir internet sitesinde izinsiz olarak kullanılamaz. Aksine
            hareketler hukuki ve cezai sorumluluğu gerektirir. Rexven&rsquo;in
            burada a&ccedil;ık&ccedil;a belirtilmeyen diğer t&uuml;m hakları
            saklıdır.
          </p>
        </li>
        <li>
          <p>
            Kullanıcı, site i&ccedil;indeki faaliyetlerinde, Rexven discord
            topluluk grubunda, sitenin herhangi bir b&ouml;l&uuml;m&uuml;nde
            veya iletişimlerinde genel ahlaka ve adaba aykırı, kanuna aykırı,
            kişilerin haklarını zedeleyen, yanıltıcı, saldırgan, m&uuml;stehcen,
            pornografik, kişilik haklarını zedeleyen, telif haklarına aykırı,
            yasa dışı faaliyetleri teşvik eden i&ccedil;erikler
            &uuml;retmeyeceğini, paylaşmayacağını kabul eder. Aksi halde
            oluşacak zarardan tamamen kendisi sorumludur ve bu durumda
            &lsquo;Site&rsquo; yetkilileri, bu t&uuml;r hesapları askıya
            alabilir, sona erdirebilir, yasal s&uuml;re&ccedil; başlatma hakkını
            saklı tutar. Bu sebeple yargı mercilerinden etkinlik veya kullanıcı
            hesapları ile ilgili bilgi talepleri gelirse paylaşma hakkını saklı
            tutar.
          </p>
        </li>
        <li>
          <p>
            Sitenin &uuml;yelerinin birbirleri veya &uuml;&ccedil;&uuml;nc&uuml;
            şahıslarla olan ilişkileri kendi sorumluluğundadır.&nbsp;
          </p>
        </li>
      </ol>
      <p>
        <br />
      </p>
      <p className="font-medium">4. FİKRİ M&Uuml;LKİYET HAKLARI</p>
      <p>
        <br />
      </p>
      <ol>
        <li>
          <p>
            İşbu Site&rsquo;de yer alan &uuml;nvan, işletme adı, marka, patent,
            logo, tasarım, bilgi ve y&ouml;ntem gibi tescilli veya tescilsiz
            t&uuml;m fikri m&uuml;lkiyet hakları site işleteni ve sahibi firmaya
            veya belirtilen ilgilisine ait olup, ulusal ve uluslararası hukukun
            koruması altındadır. İşbu Site&rsquo;nin ziyaret edilmesi veya bu
            Site&rsquo;deki hizmetlerden yararlanılması s&ouml;z konusu fikri
            m&uuml;lkiyet hakları konusunda hi&ccedil;bir hak vermez.
          </p>
        </li>
        <li>
          <p>
            Site&rsquo;de yer alan bilgiler hi&ccedil;bir şekilde
            &ccedil;oğaltılamaz, yayınlanamaz, kopyalanamaz, sunulamaz ve/veya
            aktarılamaz. Site&rsquo;nin b&uuml;t&uuml;n&uuml; veya bir kısmı
            diğer bir internet sitesinde izinsiz olarak kullanılamaz.&nbsp;
          </p>
        </li>
      </ol>
      <p>
        <br />
      </p>
      <p>5. GİZLİ BİLGİ</p>
      <p>
        <br />
      </p>
      <ol>
        <li>
          <p>
            Rexven, site &uuml;zerinden kullanıcıların ilettiği kişisel
            bilgileri 3. Kişilere a&ccedil;ıklamayacaktır. Bu kişisel bilgiler;
            kişi adı-soyadı, adresi, telefon numarası, cep telefonu, e-posta
            adresi gibi Kullanıcı&rsquo;yı tanımlamaya y&ouml;nelik her
            t&uuml;rl&uuml; diğer bilgiyi i&ccedil;ermekte olup, kısaca
            &lsquo;Gizli Bilgiler&rsquo; olarak anılacaktır.
          </p>
        </li>
        <li>
          <p>
            Kullanıcı, sadece tanıtım, reklam, kampanya, promosyon, duyuru vb.
            pazarlama faaliyetleri kapsamında kullanılması ile sınırlı olmak
            &uuml;zere, Rexven&rsquo;in &uuml;yenin kendisine ait iletişim,
            portf&ouml;y durumu ve demografik bilgilerini iştirakleri ya da
            bağlı bulunduğu grup şirketleri ile paylaşmasına muvafakat ettiğini
            kabul ve beyan eder. Bu kişisel bilgiler Rexven b&uuml;nyesinde
            m&uuml;şteri profili belirlemek, m&uuml;şteri profiline uygun
            promosyon ve kampanyalar sunmak ve istatistiksel &ccedil;alışmalar
            yapmak amacıyla kullanılabilecektir.
          </p>
        </li>
        <li>
          <p>
            Gizli Bilgiler, ancak resmi makamlarca usul&uuml; dairesinde bu
            bilgilerin talep edilmesi halinde ve y&uuml;r&uuml;rl&uuml;kteki
            emredici mevzuat h&uuml;k&uuml;mleri gereğince resmi makamlara
            a&ccedil;ıklama yapılmasının zorunlu olduğu durumlarda resmi
            makamlara a&ccedil;ıklanabilecektir.
          </p>
        </li>
      </ol>
      <p>
        <br />
      </p>
      <p className="font-medium">6. GARANTİ VERMEME</p>
      <p>
        <br />
      </p>
      <p>
        İŞBU S&Ouml;ZLEŞME MADDESİ UYGULANABİLİR KANUNUN İZİN VERDİĞİ AZAMİ
        &Ouml;L&Ccedil;&Uuml;DE GE&Ccedil;ERLİ OLACAKTIR. REXVEN TARAFINDAN
        SUNULAN HİZMETLER &ldquo;OLDUĞU GİBİ&rdquo; VE &ldquo;M&Uuml;MK&Uuml;N
        OLDUĞU&rdquo; TEMELDE SUNULMAKTA VE PAZARLANABİLİRLİK, BELİRLİ BİR AMACA
        UYGUNLUK VEYA İHLAL ETMEME KONUSUNDA T&Uuml;M ZIMNİ GARANTİLER DE
        D&Acirc;HİL OLMAK &Uuml;ZERE HİZMETLER VEYA UYGULAMA İLE İLGİLİ OLARAK
        (BUNLARDA YER ALAN T&Uuml;M BİLGİLER D&Acirc;HİL) SARİH VEYA ZIMNİ,
        KANUNİ VEYA BAŞKA BİR NİTELİKTE Hİ&Ccedil;BİR GARANTİDE
        BULUNMAMAKTADIR.&nbsp;
      </p>
      <p>
        <br />
      </p>
      <p className="font-medium">7. KAYIT VE G&Uuml;VENLİK</p>
      <p>
        <br />
      </p>
      <p>
        Kullanıcı, doğru, eksiksiz ve g&uuml;ncel kayıt bilgilerini vermek
        zorundadır. Aksi halde bu S&ouml;zleşme ihlal edilmiş sayılacak ve
        Kullanıcı bilgilendirilmeksizin hesap kapatılabilecektir. Kullanıcı,
        site ve &uuml;&ccedil;&uuml;nc&uuml; taraf sitelerdeki şifre ve hesap
        g&uuml;venliğinden kendisi sorumludur. Aksi halde oluşacak veri
        kayıplarından ve g&uuml;venlik ihlallerinden veya donanım ve cihazların
        zarar g&ouml;rmesinden Rexven sorumlu tutulamaz.
      </p>
      <p>
        <br />
      </p>
      <p className="font-medium">8. M&Uuml;CBİR SEBEP</p>
      <p>
        <br />
      </p>
      <p>
        Tarafların kontrol&uuml;nde olmayan; tabii afetler, yangın, patlamalar,
        i&ccedil; savaşlar, savaşlar, ayaklanmalar, halk hareketleri,
        seferberlik ilanı, grev, lokavt ve salgın hastalıklar, altyapı ve
        internet arızaları, elektrik kesintisi gibi sebeplerden (aşağıda
        birlikte &ldquo;M&uuml;cbir Sebep&rdquo; olarak anılacaktır.) dolayı
        s&ouml;zleşmeden doğan y&uuml;k&uuml;ml&uuml;l&uuml;kler taraflarca ifa
        edilemez hale gelirse, taraflar bundan sorumlu değildir. Bu s&uuml;rede
        Taraflar&rsquo;ın işbu S&ouml;zleşme&rsquo;den doğan hak ve
        y&uuml;k&uuml;ml&uuml;l&uuml;kleri askıya alınır.&nbsp;
      </p>
      <p>
        <br />
      </p>
      <p className="font-medium">
        9. S&Ouml;ZLEŞMEDE/KOŞULLARDA YAPILACAK DEĞİŞİKLİKLER&nbsp;
      </p>
      <p>
        <br />
      </p>
      <p>
        Rexven, dilediği zaman sitede sunulan hizmetleri ve işbu s&ouml;zleşme
        şartlarını kısmen veya tamamen değiştirebilir. Değişiklikler sitede
        yayınlandığı tarihten itibaren ge&ccedil;erli olacaktır. Değişiklikleri
        takip etmek Kullanıcı&rsquo;nın sorumluluğundadır. Kullanıcı, sunulan
        hizmetlerden yararlanmaya devam etmekle bu değişiklikleri de kabul etmiş
        sayılır.
      </p>
      <p>
        <br />
      </p>
      <p className="font-medium">10. MUHTELİF H&Uuml;K&Uuml;MLER</p>
      <p>
        <br />
      </p>
      <ol>
        <li>
          <p>
            İşbu S&ouml;zleşme T&uuml;rk hukuku h&uuml;k&uuml;mlerine tabi
            olacaktır ve T&uuml;rk hukukuna g&ouml;re yorumlanacaktır. İşbu
            S&ouml;zleşme&rsquo;den kaynaklanan veya Taraflar arasındaki
            herhangi bir ihtilaf &ouml;ncelikle sulh ile
            &ccedil;&ouml;z&uuml;mlenmeye &ccedil;alışılacaktır. Makul
            s&uuml;rede veya Sulh g&ouml;r&uuml;şmesine başlandığından itibaren
            1 ay i&ccedil;inde bir sulha varılamaması durumunda, bu ihtilaf
            Ankara Mahkemeleri ve İcra Dairelerinde
            &ccedil;&ouml;z&uuml;mlenecektir.
          </p>
        </li>
        <li>
          <p>
            Taraflar arasında işbu s&ouml;zleşme ile ilgili işlemler i&ccedil;in
            &ccedil;ıkabilecek her t&uuml;rl&uuml; uyuşmazlıklarda
            Taraflar&rsquo;ın defter, kayıt ve belgeleri ile ve bilgisayar
            kayıtları ve faks kayıtları 6100 sayılı Hukuk Muhakemeleri Kanunu
            uyarınca delil olarak kabul edilecek olup, kullanıcı bu kayıtlara
            itiraz etmeyeceğini kabul eder.
          </p>
        </li>
        <li>
          <p>
            İşbu S&ouml;zleşme ile ilgili taraflara g&ouml;nderilecek olan
            t&uuml;m bildirimler, Rexven&rsquo;in bilinen e-posta adresi ve
            kullanıcının &uuml;yelik formunda belirttiği e-posta adresi
            vasıtasıyla yapılacaktır. Kullanıcı, &uuml;ye olurken belirttiği
            adresin ge&ccedil;erli tebligat adresi olduğunu, değişmesi durumunda
            5 g&uuml;n i&ccedil;inde yazılı olarak diğer tarafa bildireceğini,
            aksi halde bu adrese yapılacak tebligatların ge&ccedil;erli
            sayılacağını kabul eder.
          </p>
        </li>
        <li>
          <p>
            İşbu s&ouml;zleşme şartlarından biri, kısmen veya tamamen
            ge&ccedil;ersiz hale gelirse, s&ouml;zleşmenin geri kalanı
            ge&ccedil;erliliğini korumaya devam eder.
          </p>
        </li>
        <li>
          <p>
            Hizmetimiz ve &ouml;zellikleri hakkında daha fazla bilgi edinmek
            istiyorsanız ya da hesabınızla ilgili yardıma ihtiyacınız varsa
            l&uuml;tfen rexven.com web sitesi sayfasının en altında yer alan
            Bize Ulaşın b&ouml;l&uuml;m&uuml;ndeki telefon numarasından bize
            ulaşabilirsiniz. Bu Kullanım Koşulları ile M&uuml;şteri Destek
            tarafından verilen veya web sitelerimizin diğer kısımlarında bulunan
            bilgiler arasında herhangi bir uyuşmazlık olması durumunda, bu
            Kullanım Koşulları ge&ccedil;erli olacaktır.
          </p>
        </li>
        <li>
          <p>
            Bu Kullanım Koşullarının herhangi bir h&uuml;km&uuml; veya
            h&uuml;k&uuml;mlerinin ge&ccedil;ersiz, yasalara aykırı veya
            uygulanamaz olması durumunda, kalan h&uuml;k&uuml;mlerin
            ge&ccedil;erliliği, yasallığı ve uygulanabilirliği tam olarak
            y&uuml;r&uuml;rl&uuml;kte kalmaya devam edecektir.
          </p>
        </li>
        <li>
          <p>
            Sizinle olan anlaşmamızı, ilişkili haklarımız ve
            y&uuml;k&uuml;ml&uuml;l&uuml;klerimiz dahil olmak &uuml;zere,
            herhangi bir zamanda bir başka tarafa devredebilir veya temlik
            edebiliriz ve b&ouml;yle bir devir veya temlikle ilişkili olarak
            bizimle iş birliğinde bulunmayı kabul etmiş sayılırsınız.
          </p>
        </li>
        <li>
          <p>
            Hesabınızla ilgili bilgiler (&ouml;deme izinleri, faturalar, şifre
            ya da &Ouml;deme Y&ouml;ntemi değişiklikleri, onay mesajları,
            bildirimler vb.) tarafınıza yalnızca elektronik formatta
            aktarılacaktır. &Ouml;rneğin; kayıt esnasında e-posta adresinize
            g&ouml;nderilen e-postalar aracılığıyla.
          </p>
        </li>
      </ol>
      <p>
        <br />
      </p>
      <p>
        <br />
      </p>
    </div>
  );
};

export default RexvenUsage;
