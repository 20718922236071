import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { ToastContainer } from 'react-toastify';

import { Dialog, Transition } from '@headlessui/react';
import { MinusIcon, PlusIcon, XMarkIcon } from '@heroicons/react/24/outline';

import { useAuth } from '../../context/AuthContext';
import trendyolToolsService from '../../services/trendyolToolsService';
import alertNotification from '../../utils/alertNotification';
import { errorElement } from '../../utils/errorElement';
import LoadingComponent from '../common/LoadingComponent';

interface ProductCostModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  barcode: string;
  // hasProductCost: boolean;
  isProduct: boolean;
}
interface IInput {
  startDate: { name: string; value: string };
  endDate: { name: string; value: string };
  productCost: { name: string; value: string };
}
const ProductCostModal: React.FC<ProductCostModalProps> = ({
  open,
  setOpen,
  barcode,
  // hasProductCost,
  isProduct,
}) => {
  const { session } = useAuth();
  const [inputs, setInputs] = useState<IInput[]>([
    {
      startDate: { name: 'startDate1', value: '' },
      endDate: { name: 'endDate1', value: '' },
      productCost: { name: 'cost1', value: '' },
    },
  ]);
  const [isValid, setIsValid] = useState(true);
  const [loading, setLaoding] = useState(false);
  const [isValidDate, setIsValidDate] = useState(true);
  const [isProperDate, setIsProperDate] = useState(true);

  const handleClickPlus = (e: React.SyntheticEvent) => {
    e.preventDefault();
    if (
      inputs.length < 12 &&
      inputs[inputs.length - 1].productCost.value !== ''
    ) {
      const size = inputs.length + 1;
      const newObj = {
        startDate: { name: `startDate${size}`, value: '' },
        endDate: { name: `endDate${size}`, value: '' },
        productCost: { name: `cost${size}`, value: '' },
      };
      setInputs([...inputs, newObj]);
    }
  };

  const handleClickMinus = (e: React.SyntheticEvent) => {
    e.preventDefault();
    let temp;
    if (inputs.length > 1) {
      temp = inputs;
      temp.pop();

      setInputs([...temp]);
    }
  };

  const checkIfNumber = (input: string): boolean => {
    const regex = /^(?:[0-9.]*)?$/;
    return regex.test(input);
  };

  const validateDates = (date: string) => {
    if (inputs.length <= 1) {
      return;
    } else {
      let temp = [...inputs];
      temp.pop();

      temp?.forEach((item) => {
        if (moment(date).isBetween(item.startDate.value, item.endDate.value)) {
          setIsValidDate(false);
          return;
        } else {
          setIsValidDate(true);
        }
      });
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.currentTarget;

    if (name.startsWith('start')) {
      validateDates(value);
      setInputs((prev) => {
        const updatedInputs = prev.map((input) =>
          input.startDate.name === name
            ? { ...input, startDate: { name: name, value: value } }
            : input
        );
        return updatedInputs;
      });
    } else if (name.startsWith('end')) {
      validateDates(value);
      const updatedInputs = inputs.map((input) =>
        input.endDate.name === name
          ? { ...input, endDate: { name: name, value: value } }
          : input
      );

      setInputs(updatedInputs);
    } else if (name.startsWith('cost')) {
      setIsValid(checkIfNumber(value));
      setInputs((prev) => {
        const updatedInputs = prev.map((input) =>
          input.productCost.name === name
            ? { ...input, productCost: { name: name, value: value } }
            : input
        );
        return updatedInputs;
      });
    }
  };

  const handleOnClose = () => {
    setOpen(false);

    setInputs([
      {
        startDate: { name: 'startDate1', value: '' },
        endDate: { name: 'endDate1', value: '' },
        productCost: { name: 'cost1', value: '' },
      },
    ]);

    setIsValid(true);
  };

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    let temp;
    let isProper = true;
    setLaoding(true);

    inputs.forEach((item) => {
      if (
        moment(item.startDate.value).isAfter(item.endDate.value) ||
        moment(item.startDate.value).isSame(item.endDate.value)
      ) {
        isProper = false;
        console.log('burada', isProper);
        setIsProperDate(false);
        return;
      }
    });
    try {
      if (isProduct) {
        temp = inputs.map((item, idx) => {
          return {
            startDate: item.startDate.value,
            endDate: item.endDate.value,
            productCost: Number(item.productCost.value),
          };
        });
        temp = { productCosts: temp };
      } else {
        temp = inputs.map((item, idx) => {
          return {
            startDate: item.startDate.value,
            endDate: item.endDate.value,
            adCost: Number(item.productCost.value),
          };
        });
        temp = { adCosts: temp };
      }

      if (temp && isProper) {
        await trendyolToolsService.updateCosts(
          barcode,
          session?.producerId!,
          temp
        );
        setInputs([
          {
            startDate: { name: 'startDate1', value: '' },
            endDate: { name: 'endDate1', value: '' },
            productCost: { name: 'cost1', value: '' },
          },
        ]);
        getAllCosts();

        // setOpen(false);
      }
    } catch (error: any) {
      alertNotification('error', error.response.data.message);
    } finally {
      setLaoding(false);
    }
  };

  const getAllCosts = async () => {
    setLaoding(true);
    try {
      const resProd = await trendyolToolsService.getCosts(barcode, {
        producerId: session?.producerId!,
        costType: isProduct ? 'productCost' : 'adCost',
      });

      let tempInputs;
      if (resProd.data && resProd.data.length > 0) {
        if (isProduct) {
          tempInputs = resProd?.data.map(
            (
              item: {
                cost: any;
                startDate: any;
                endDate: any;
                productCost: any;
              },
              idx: number
            ) => {
              return {
                startDate: {
                  name: `startDate${idx + 1}`,
                  value: item.startDate,
                },
                endDate: { name: `endDate${idx + 1}`, value: item.endDate },
                productCost: {
                  name: `cost${idx + 1}`,
                  value: item.cost,
                },
              };
            }
          );
        } else {
          tempInputs = resProd.data.adCosts.map(
            (
              item: {
                cost: any;
                startDate: any;
                endDate: any;
                productCost: any;
              },
              idx: number
            ) => {
              return {
                startDate: {
                  name: `startDate${idx + 1}`,
                  value: item.startDate,
                },
                endDate: { name: `endDate${idx + 1}`, value: item.endDate },
                productCost: {
                  name: `cost${idx + 1}`,
                  value: item.cost,
                },
              };
            }
          );
        }
        setInputs(tempInputs);
      }
      // else {
      //   setInputs([
      //     {
      //       startDate: { name: 'startDate1', value: '' },
      //       endDate: { name: 'endDate1', value: '' },
      //       productCost: { name: 'cost1', value: '' },
      //     },
      //   ]);
      // }
    } catch (error: any) {
      alertNotification('error', error.response.data.message);
    } finally {
      setLaoding(false);
    }
  };

  useEffect(() => {
    if (
      (barcode &&
        session?.isTrendyolIntegrated &&
        !session?.hasPackage &&
        !session?.isTrialEnd) ||
      (session?.hasPackage && barcode && session?.isTrendyolIntegrated)
    ) {
      getAllCosts();
    }
  }, [barcode]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleOnClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-auto rounded-lg bg-white px-2 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full h-[80vh] md:h-auto sm:max-w-2xl sm:p-6">
                <div className="absolute right-0 top-0 hidden pr-4 pt-4 sm:block">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={handleOnClose}
                  >
                    <span className="sr-only">Close</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div>
                  <div className="mt-3 text-center sm:mt-5 px-4">
                    <Dialog.Title
                      as="h3"
                      className="text-lg font-semibold leading-6 text-gray-900"
                    >
                      {isProduct
                        ? 'Ürün Maliyeti Giriniz'
                        : 'Reklam Maliyeti Giriniz'}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-center text-gray-500 mb-2">
                        Aşağıdaki bölümde bu ürünler için{' '}
                        <span className="text-rexRed">ürün başına </span>
                        {!isProduct ? <span>reklam </span> : ''}
                        maliyetlerinizi giriniz. Tarih aralığı girerken daha
                        önce girilen tarih aralığını içermeyen bir tarih aralığı
                        giriniz.
                      </p>
                    </div>
                    {loading ? (
                      <LoadingComponent />
                    ) : (
                      <form
                        className="flex flex-col space-y-2"
                        onSubmit={handleSubmit}
                      >
                        {inputs.map((item, idx) => (
                          <div
                            key={(1 + idx) * 1999}
                            className="flex flex-col md:flex-row justify-center items-center md:space-x-2 border border-primary p-2 rounded-lg bg-lightGray"
                          >
                            <div className="flex flex-col justify-start items-center w-full mb-2 md:mb-0">
                              <label
                                htmlFor="startDate"
                                className="text-sm mb-1"
                              >
                                Başlangıç Tarihi
                              </label>

                              <input
                                type="date"
                                name={item.startDate.name}
                                id={item.startDate.name}
                                className="rounded-md border-secondary text-xs w-full"
                                onChange={handleChange}
                                value={item.startDate.value.split('T')[0]}
                              />
                            </div>
                            <div className="flex flex-col justify-start items-center w-full mb-2 md:mb-0">
                              <label htmlFor="endDate" className="text-sm mb-1">
                                Bitiş Tarihi
                              </label>
                              <input
                                type="date"
                                name={item.endDate.name}
                                className="rounded-md border-secondary text-xs w-full"
                                id={item.endDate.name}
                                onChange={handleChange}
                                value={item.endDate.value.split('T')[0]}
                              />
                            </div>
                            <div className="flex flex-col justify-start items-center w-full mb-2 md:mb-0">
                              <label
                                htmlFor="productCost"
                                className="text-sm mb-1"
                              >
                                Ürün Maliyeti
                              </label>
                              <input
                                className="rounded-md border-secondary text-xs w-full"
                                type="text"
                                name={item.productCost.name}
                                id={item.productCost.name}
                                onChange={handleChange}
                                value={item.productCost.value}
                              />
                            </div>
                          </div>
                        ))}
                        {!isValid &&
                          errorElement(
                            'Bu alan yalnızca rakam ve nokta içerebilir'
                          )}
                        {!isValidDate &&
                          errorElement(
                            'Lütfen aynı tarih aralığında yalnızca 1 ürün maliyeti giriniz.'
                          )}
                        {!isProperDate &&
                          errorElement(
                            'Başlangıç tarihi bitiş tarihinden sonra olamaz'
                          )}
                        <div className="flex w-full justify-center space-x-2 ml-3">
                          <button
                            disabled={
                              (session?.isSeller &&
                                !session?.hasPackage &&
                                session?.isTrialEnd) || //7 gün bitti
                              !session?.isSeller //demo durumu
                            }
                            onClick={handleClickPlus}
                            className="w-8 h-8 rounded-md border-2  border-headingPrimary flex justify-center items-center"
                          >
                            <PlusIcon height={16} width={16} />
                          </button>
                          <button
                            onClick={handleClickMinus}
                            className="w-8 h-8 rounded-md border-2 border-headingPrimary flex justify-center items-center"
                          >
                            <MinusIcon height={16} width={16} />
                          </button>
                        </div>
                        <div className="mt-5 sm:mt-6 w-full flex justify-end">
                          <button
                            type="button"
                            onClick={handleOnClose}
                            className="rounded-md bg-rexRed py-2.5 px-8 text-xs font-semibold text-white shadow-sm hover:bg-secondary md:text-sm mr-2"
                          >
                            Kapat
                          </button>
                          <button
                            disabled={
                              (session?.isSeller &&
                                !session?.hasPackage &&
                                session?.isTrialEnd) || //7 gün bitti
                              !session?.isSeller ||
                              !isValidDate ||
                              !isValid //demo durumu
                            }
                            type="submit"
                            className="rounded-md bg-headerPrimary py-2.5 px-8 text-xs font-semibold text-white shadow-sm hover:bg-secondary md:text-sm"
                          >
                            Kaydet
                          </button>
                        </div>
                      </form>
                    )}
                  </div>
                  <ToastContainer />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ProductCostModal;

{
  /* <div className="flex flex-col md:flex-row justify-center items-center gap-y-2">
<div className="flex flex-col justify-start items-center w-full">
  <label htmlFor="startDate" className="text-sm">
    Başlangıç Tarihi
  </label>
  <DatePicker
    className="rounded-md border-secondary"
    onChange={() => {}}
    locale="tr"
    name="startDate"
    id="startDate"
  />
</div>
<div className="flex flex-col justify-start items-center w-full">
  <label htmlFor="startDate" className="text-sm">
    Bitiş Tarihi
  </label>
  <DatePicker
    className="rounded-md border-secondary"
    onChange={() => {}}
    locale="tr"
    name="endDate"
    id="endDate"
  />
</div>
<div className="flex flex-col justify-start items-center w-full">
  <label htmlFor="startDate" className="text-sm">
    Ürün Maliyeti
  </label>
  <input
    className="rounded-md border-secondary"
    type="text"
    name="productCost"
    id="productCost"
  />
</div>
</div> */
}
