import React from 'react';
import ServiceMemberCard from './ServiceMemberCard';
import memberService from '../../services/memberService';
import { useParams } from 'react-router-dom';
import { IRootServiceMember } from '../../interfaces/serviceProviders';
import { useQuery } from '@tanstack/react-query';
import LoadingComponent from '../common/LoadingComponent';

// const services = [
//   {
//     id: 1,
//     name: 'Mehmet Tek',
//     imgpath: '/images/1-book-amazon-egitimi.webp',
//     status: 'Pending',
//     description:
//       "Yaklaşık 5 yıldır Amazon'da satış yapıyorum. Şu ana kadar 100.000 den fazla kişiye eğitimler verdim. 100'lerce şirkete danışmanlık yaptım.",
//     servicefee: 1200,
//     userid: 1,
//     gsmnumber: '530 339 70 59',
//     ispremium: true,
//     isexpert: true,
//   },
//   {
//     id: 2,
//     name: 'Murtaza Tek',
//     imgpath: '/images/amazon-arbitraj.webp',
//     status: 'Pending',
//     description:
//       "Yaklaşık 5 yıldır Amazon'da satış yapıyorum. Şu ana kadar 100.000 den fazla kişiye eğitimler verdim. 100'lerce şirkete danışmanlık yaptım.",
//     servicefee: 1200,
//     userid: 2,
//     gsmnumber: '530 339 70 59',
//     ispremium: true,
//     isexpert: true,
//   },
//   {
//     id: 1,
//     name: 'Mahmut Tek',
//     imgpath: '/images/amazon-arbitraj.webp',
//     status: 'Pending',
//     description:
//       "Yaklaşık 5 yıldır Amazon'da satış yapıyorum. Şu ana kadar 100.000 den fazla kişiye eğitimler verdim. 100'lerce şirkete danışmanlık yaptım.",
//     servicefee: 1200,
//     userid: 1,
//     gsmnumber: '530 339 70 59',
//     ispremium: true,
//     isexpert: true,
//   },
//   {
//     id: 1,
//     name: 'Muhammet Tek',
//     imgpath: '/images/amazon-arbitraj.webp',
//     status: 'Pending',
//     description:
//       "Yaklaşık 5 yıldır Amazon'da satış yapıyorum. Şu ana kadar 100.000 den fazla kişiye eğitimler verdim. 100'lerce şirkete danışmanlık yaptım.",
//     servicefee: 1200,
//     userid: 1,
//     gsmnumber: '530 339 70 59',
//     ispremium: true,
//     isexpert: true,
//   },
// ];
const ServiceMemberWrapper = () => {
  const params = useParams();

  const { isLoading, error, data } = useQuery<IRootServiceMember, Error>(
    ['serviceMembers', params.id],
    {
      queryFn: () => memberService.getServiceMembers(params.id),
      staleTime: Infinity,
    }
  );
  if (isLoading) {
    return <LoadingComponent />;
  }
  return (
    <div className="flex flex-col space-y-6 justify-center">
      {data?.data.map((item) => {
        return <ServiceMemberCard key={`service-${item.id}`} provider={item} />;
      })}
    </div>
  );
};

export default ServiceMemberWrapper;
