import { Dialog, Transition } from '@headlessui/react';
import React from 'react'
import DotLoaderSpinner from './DotLoaderSpinner';

interface Props{
    text:string
    isModalOpen:boolean
    setIsModalOpen:React.Dispatch<React.SetStateAction<boolean>>
}
const LoadingModal:React.FC<Props> = ({text,isModalOpen,setIsModalOpen}) => {
  return (
    <Transition show={isModalOpen} as={React.Fragment}>
    <Dialog
      as="div"
      className="relative z-10 "
      onClose={() => setIsModalOpen(false)}
    >
      <Transition.Child
        as={React.Fragment}
        enter="ease-out duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="ease-in duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75" />
      </Transition.Child>

      <div className="fixed inset-0 overflow-y-auto">
        <div className="flex min-h-full items-center justify-center p-4 text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
              <Dialog.Title
                as="h3"
                className="text-lg mb-8 font-medium text-center leading-6 text-gray-900"
              >
                Lütfen bekleyiniz
              </Dialog.Title>
              <div className="mt-2 flex flex-col ">
               <p className='text-lg text-headerPrimary font-medium'>
                {text}
               </p>
               <DotLoaderSpinner loading={isModalOpen}/>
              </div>
              
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition>
  )
}

export default LoadingModal