import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { classNames } from '../../utils/conditionalClasses';

interface Props {
  labelCounts: any;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  setInputPage: React.Dispatch<React.SetStateAction<number | string>>;
  setQueryParams: React.Dispatch<any>;
}
const StatusSection: React.FC<Props> = ({
  labelCounts,
  setPage,
  setInputPage,
  setQueryParams,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { session } = useAuth();

  const handleClick = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const newLabel = e.currentTarget?.getAttribute('data-value') as string;
    console.log(newLabel);

    setQueryParams((prev: any) => {
      return {
        ...prev,
        label: newLabel,
      };
    });
    setInputPage(1);
    setPage(1);

    const params = Object.fromEntries(searchParams.entries());

    params.label = newLabel;
    params.page = '1';

    setSearchParams(params);
  };

  return (
    <div className="flex space-x-2 justify-between items-stretch w-full overflow-x-auto px-4">
      <div className="flex-none">
        <button
          className={classNames(
            searchParams.get('label') === '' || !searchParams.get('label')
              ? 'text-white bg-[#2563EB]'
              : 'text-[#2563EB] bg-white',
            'flex space-x-2 justify-center items-center h-full rounded-lg border-2 border-[#2563EB] px-2 p-1 w-[100px]'
          )}
          onClick={handleClick}
          data-value=""
        >
          <p className=" text-sm">Hepsi</p>
        </button>
      </div>

      {!session?.isSupplier && (
        <div className="flex-none">
          <button
            data-value="waitingMatch"
            className={classNames(
              searchParams.get('label') === 'waitingMatch'
                ? 'text-white bg-[#4F46E5]'
                : 'text-[#4F46E5] bg-white',
              'flex space-x-2 justify-center items-center h-full rounded-lg border-2 border-[#2563EB] px-2 p-1 w-[100px]'
            )}
            onClick={handleClick}
          >
            <p className="text-sm">
              Eşleşme Bekliyor <span>({labelCounts?.waitingMatch || 0})</span>
            </p>
          </button>
        </div>
      )}

      {!session?.isSupplier && (
        <div className="flex-none">
          <button
            className={classNames(
              searchParams.get('label') === 'waitingPayment'
                ? 'text-white bg-[#0D9488]'
                : 'text-[#0D9488] bg-white',
              'flex space-x-2 justify-center items-center h-full rounded-lg border-2 border-[#0D9488] px-2 p-1 w-[100px]'
            )}
            data-value="waitingPayment"
            onClick={handleClick}
          >
            <p className=" text-sm">
              Ödeme Bekliyor <span>({labelCounts?.waitingPayment || 0})</span>
            </p>
          </button>
        </div>
      )}
      {/* <div className="flex-none">
        <button className="flex space-x-2 justify-center items-center rounded-lg border border-[#EC4899] px-2 p-1 w-[100px]">
          <Box1 size="24" color="#EC4899" />
          <p className="text-[#EC4899] text-sm">Üretim Bekliyor</p>
        </button>
      </div> */}
      <div className="flex-none">
        <button
          className={classNames(
            searchParams.get('label') === 'waitingProducing'
              ? 'text-white bg-[#EC4899]'
              : 'text-[#EC4899] bg-white',
            'flex space-x-2 justify-center items-center h-full rounded-lg border-2 border-[#EC4899] px-2 p-1 w-[100px]'
          )}
          data-value="waitingProducing"
          onClick={handleClick}
        >
          <p className="text-sm">
            Üretim Bekliyor <span>({labelCounts?.waitingProducing || 0})</span>
          </p>
        </button>
      </div>
      <div className="flex-none">
        <button
          className={classNames(
            searchParams.get('label') === 'producing'
              ? 'text-white bg-[#7C3AED]'
              : 'text-[#7C3AED] bg-white',
            'flex space-x-2 justify-center items-center h-full rounded-lg border-2 border-[#7C3AED] px-2 p-1 w-[100px]'
          )}
          data-value="producing"
          onClick={handleClick}
        >
          <p className=" text-sm">
            Üretiliyor <span>({labelCounts?.producing || 0})</span>
          </p>
        </button>
      </div>

      <div className="flex-none">
        <button
          className={classNames(
            searchParams.get('label') === 'waitingLabel'
              ? 'text-white bg-[#F59E0B]'
              : 'text-[#F59E0B] bg-white',
            'flex space-x-2 justify-center items-center h-full rounded-lg border-2 border-[#F59E0B] px-2 p-1 w-[100px]'
          )}
          data-value="waitingLabel"
          onClick={handleClick}
        >
          <p className="text-sm">
            Etiket Bekliyor <span>({labelCounts?.waitingLabel ?? 0})</span>
          </p>
        </button>
      </div>
      <div className="flex-none">
        <button
          className={classNames(
            searchParams.get('label') === 'ready'
              ? 'text-white bg-[#06B6D4]'
              : 'text-[#06B6D4] bg-white',
            'flex space-x-2 justify-center items-center h-full rounded-lg border-2 border-[#06B6D4] px-2 p-1 w-[100px]'
          )}
          data-value="ready"
          onClick={handleClick}
        >
          <p className=" text-sm">
            Hazır <span>({labelCounts?.ready || 0})</span>
          </p>
        </button>
      </div>
      <div className="flex-none">
        <button
          className={classNames(
            searchParams.get('label') === 'shipped'
              ? 'text-white bg-[#0EA5E9]'
              : 'text-[#0EA5E9] bg-white',
            'flex space-x-2 justify-center items-center h-full rounded-lg border-2 border-[#0EA5E9] px-2 p-1 w-[100px]'
          )}
          data-value="shipped"
          onClick={handleClick}
        >
          <p className="text-sm">
            Kargoya Verildi <span>({labelCounts?.shipped || 0})</span>
          </p>
        </button>
      </div>
      <div className="flex-none">
        <button
          className={classNames(
            searchParams.get('label') === 'delivered'
              ? 'text-white bg-[#059669]'
              : 'text-[#059669] bg-white',
            'flex space-x-2 justify-center items-center h-full rounded-lg border-2 border-[#059669] px-2 p-1 w-[100px]'
          )}
          data-value="delivered"
          onClick={handleClick}
        >
          <p className=" text-sm">
            Teslim Edildi <span>({labelCounts?.delivered || 0})</span>
          </p>
        </button>
      </div>
      <div className="flex-none">
        <button
          className={classNames(
            searchParams.get('label') === 'delivered'
              ? 'text-white bg-red-500'
              : 'text-red-500 bg-white',
            'flex space-x-2 justify-center items-center h-full rounded-lg border-2 border-red-500 px-2 p-1 w-[100px]'
          )}
          data-value="cancelled"
          onClick={handleClick}
        >
          <p className="text-red-500 text-sm">
            İptal Edildi <span>({labelCounts?.delivered || 0})</span>
          </p>
        </button>
      </div>
      {!session?.isSupplier && (
        <div className="flex-none">
          <button
            data-value="hidden"
            className={classNames(
              searchParams.get('label') === 'delivered'
                ? 'text-white bg-[#6B7280]'
                : 'text-[#6B7280] bg-white',
              'flex space-x-2 justify-center items-center h-full rounded-lg border-2 border-[#6B7280] px-2 p-1 w-[100px]'
            )}
            onClick={handleClick}
          >
            <p className="text-sm">
              Gizli <span>({labelCounts?.hidden || 0})</span>
            </p>
          </button>
        </div>
      )}
    </div>
  );
};

export default StatusSection;
