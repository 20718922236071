import React from 'react';
import { classNames } from '../../../utils/conditionalClasses';
import { useLanguage } from '../../../context/LanguageContext';

interface Props {
  isLabel: boolean;
  setIsLabel: React.Dispatch<React.SetStateAction<boolean>>;
}
const SwitchPageButtons: React.FC<Props> = ({ isLabel, setIsLabel }) => {
  return (
    <div className="flex flex-row text-sm mb-8">
      <button
        className={classNames(
          isLabel === false ? 'bg-headerPrimary text-white' : '',
          'px-2 border-2 py-2 border-headerPrimary rounded-l-md'
        )}
        onClick={() => {
          setIsLabel(false);
        }}
      >
        Onaylı Gönderiler
      </button>
      <button
        className={classNames(
          isLabel === true ? 'bg-headerPrimary text-white' : '',
          'px-2 border-2 py-2 border-headerPrimary rounded-r-md'
        )}
        onClick={() => {
          setIsLabel(true);
        }}
      >
        Etiketli Gönderiler
      </button>
    </div>
  );
};

export default SwitchPageButtons;
