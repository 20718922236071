import React, { useState } from 'react';

// import LoadingComponent from '../components/common/LoadingComponent';
import DotLoaderSpinner from '../components/common/DotLoaderSpinner';
import ShippingCalculatorFooter from '../components/shipEntegra/ShippingCalculatorFooter';
import ShippingCalculatorForm from '../components/shipEntegra/ShippingCalculatorForm';
import ShippingInfoCard from '../components/shipEntegra/ShippingInfoCard';
import ShippingOffersTable from '../components/shipEntegra/ShippingOffersTable';
import ShipEntegraLoginRegister from '../components/shipEntegraShared/ShipEntegraLoginRegister';
import NewBanner from '../components/tools/common/NewBanner';
import { useAuth } from '../context/AuthContext';

const ShippingCalculator = () => {
  const { session } = useAuth()
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  let content = <>
    <ShippingCalculatorForm setData={setData} setLoading={setLoading} />
    {loading ? (
      <DotLoaderSpinner loading={loading} />
    ) : data ? (
      <ShippingOffersTable data={data} />
    ) : null}

    <ShippingInfoCard />
    <ShippingCalculatorFooter /></>
  return (
    <div>
      <NewBanner
        bgUrl="/images/shipentegra-banner-bg.webp"
        toolImageUrl="shipping-calculator"
      />
      <ShipEntegraLoginRegister />
      {session?.hasShipEntegraInt ?
        <>
          {content}
        </>
        :
        <div className='blur-sm h-full w-full'>
          {content}
        </div>
      }
    </div>
  );
};

export default ShippingCalculator;
