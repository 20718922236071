import React, { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useSearchParams } from 'react-router-dom';

import LoadingComponent from '../components/common/LoadingComponent';
import Pagination from '../components/common/Pagination';
import ProductNotFound from '../components/common/ProductNotFound';
import SearchBar from '../components/common/SearchBar';
import NumVerifyModal from '../components/numberVerifyModals/NumVerifyModal';
// import SixDigitModal from '../components/numberVerifyModals/SixDigitModal';
import VerifySuccessModal from '../components/numberVerifyModals/VerifySuccessModal';
import PageRadioButtons from '../components/productsAndWholeSalers/PageRadioButtons';
import ProductsWrapper, {
  Products,
} from '../components/productsAndWholeSalers/ProductsWrapper';
import SaleRegister from '../components/productsAndWholeSalers/SaleRegister';
import WholeSalersWrapper, {
  Producer,
} from '../components/productsAndWholeSalers/WholeSalersWrapper';
import SurveyModal from '../components/survey/SurveyModal';
import { useNumberVerification } from '../context/NumberVerificationContext';
import producerService from '../services/producerService';
import productService from '../services/productService';
import alertNotification from '../utils/alertNotification';
import ErrorFallback from './errors/ErrorFallback';
import memberService from '../services/memberService';
import { useAuth } from '../context/AuthContext';
import { useLanguage } from '../context/LanguageContext';
// import MessageModal from '../components/productsAndWholeSalers/MessageModal';

const ProductsAndWholeSalers = () => {
  const { session } = useAuth();
  const { t } = useLanguage();
  const [loading, setLoading] = useState(false);
  const [isProducer, setIsProducer] = useState<boolean>(true);
  const [products, setProducts] = useState<Products[]>([]);
  const [wholeSalers, setWholeSalers] = useState<Producer[]>([]);
  const [keyword, setKeyword] = useState('');
  const [page, setPage] = useState(1);
  const [prTotalPage, setPrTotalPage] = useState(1);
  const [wholeTotalPage, setWholeTotalPage] = useState(1);
  const [orderBy, setOrderBy] = useState('reviewCount');
  const [order, setOrder] = useState('DESC');
  const [inputPage, setInputPage] = useState<number | string>(1);
  const [searchParams, setSearchParams] = useSearchParams();
  const [queryString, setQueryString] = useState('');
  const [isTabActive, setIsTabActive] = React.useState<boolean>(true);

  const { handleActiveModal } = useNumberVerification();

  const getProducts = async () => {
    setLoading(true);
    try {
      const res = await productService.getProducts(
        page,
        keyword,
        orderBy,
        order
      );
      if (keyword) {
        setPage(1);
      }
      setProducts(res.data.products);
      setPrTotalPage(res.data.totalPageCount);
      setLoading(false);
    } catch (error: any) {
      alertNotification('error', error.response.data.message);
    }
  };

  const getWholeSalers = async () => {
    setLoading(true);
    try {
      const res = await producerService.getProducers(page, keyword);

      setWholeSalers(res.data.producers);
      setWholeTotalPage(res.data.totalPageCount);
      setLoading(false);
    } catch (error: any) {
      alertNotification('error', error.response.data.message);
    }
  };

  useEffect(() => {
    if (isProducer === false) {
      getProducts();
    } else {
      getWholeSalers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isProducer, keyword, page, orderBy, order]);

  useEffect(() => {
    let search = Object.fromEntries([...searchParams]);
    if (search.page) {
      setPage(Number(search.page));
      setInputPage(Number(search.page));
    }
    if (search.keyword) {
      setKeyword(search.keyword);
      setQueryString(`keyword=${search.keyword}`);
    }
  }, [searchParams]);

  useEffect(() => {
    handleActiveModal?.(undefined, true);
  }, []);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsTabActive(document.visibilityState === 'visible');
    };

    // Add event listener for visibility change
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup function to remove the event listener when the component is unmounted
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    let intervalId: any;
    if (session && isTabActive) {
      intervalId = setInterval(async () => {
        await memberService.userTrackReq(
          `manufacturers-and-wholesalers`,
          session?.id!
        );
      }, 60000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [session, isTabActive]);
  return (
    <>
      {/* <SixDigitModal /> */}
      <NumVerifyModal />
      <VerifySuccessModal />
      <SurveyModal />
      <div className="flex w-full flex-shrink-0 flex-col">
        <div className="bg-white rounded-2xl p-2 md:p-6">
          <div className="grid grid-cols-8 gap-x-4 gap-y-2 lg:gap-y-0">
            {/* Page Radio Field */}
            <div className="col-span-8 lg:col-span-2">
              <PageRadioButtons
                setInputPage={setInputPage}
                isProducer={isProducer}
                setIsProducer={setIsProducer}
                setPage={setPage}
                setKeyword={setKeyword}
              />
            </div>

            {/* Search Bar Field */}
            <div className="col-span-8 lg:col-span-4">
              <SearchBar
                setKeyword={setKeyword}
                setPage={setPage}
                placeholder={t?.('manufacturers-and-wholesalers.placeholder')}
              />
            </div>

            {/* Register Button Field */}
            <div className="col-span-8 lg:col-span-2">
              <SaleRegister />
            </div>
          </div>

          {/* LOADING */}
          {loading === true && <LoadingComponent />}

          {/* WHOLESALERS */}
          {isProducer === true &&
          loading === false &&
          wholeSalers?.length > 0 ? (
            <>
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <WholeSalersWrapper wholeSalers={wholeSalers} />
              </ErrorBoundary>
            </>
          ) : (
            wholeSalers?.length === 0 &&
            isProducer === true &&
            loading === false && <ProductNotFound />
          )}
          {/* PRODUCTS */}
          {isProducer === false && loading === false && products?.length > 0 ? (
            <>
              <ErrorBoundary FallbackComponent={ErrorFallback}>
                <ProductsWrapper products={products} />
              </ErrorBoundary>
            </>
          ) : (
            products?.length === 0 &&
            isProducer === false &&
            loading === false && <ProductNotFound />
          )}
          {
            <Pagination
              setPage={setPage}
              queryString={queryString}
              page={page}
              inputPage={inputPage}
              setInputPage={setInputPage}
              totalPageCount={
                isProducer === true ? wholeTotalPage : prTotalPage
              }
            />
          }
        </div>
      </div>
    </>
  );
};

export default ProductsAndWholeSalers;
