import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { CheckCircleIcon, UserCircleIcon } from '@heroicons/react/20/solid';

import { useAuth } from '../context/AuthContext';
import memberService from '../services/memberService';
import { formatDateTR } from '../utils/date';

interface Message {
  userId: number;
  message: string;
  createdAt: string;
  receiverId: number;
  isRead: boolean;
}
interface Contacts {
  id: number;
  receiverId: number;
  firstName: string;
  lastName: string;
  unreadCount: number;
}

const Chat: React.FC = () => {
  const [currentMessage, setCurrentMessage] = useState<string>('');
  const [messageList, setMessageList] = useState<Message[]>([]);
  const [activeContact, setActiveContact] = useState<Contacts | null>(null);
  const [contacts, setContacts] = useState<Contacts[]>([]);
  const messageEndRef = useRef<HTMLDivElement | null>(null);
  const { session } = useAuth();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const supplierUserId = Number(searchParams.get('userId'));

  const fetchContacts = async () => {
    try {
      const res = await memberService.getContacts();
      if (supplierUserId) {
        const response = await memberService.getUserById(supplierUserId);
        setActiveContact(response.data);
        setContacts(res.data);
      } else {
        setContacts(res.data);
        if (res.data.length > 0) {
          setActiveContact(res.data[0]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  const markMessagesAsRead = async (contactId: number) => {
    try {
      await memberService.markMessagesAsRead(contactId);
    } catch (error) {
      console.error('Mesajları okundu olarak işaretleme hatası:', error);
    }
  };

  const fetchMessages = async () => {
    if (!activeContact) return;
    try {
      const res = await memberService.getMessages(activeContact.id);
      setMessageList(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const sendMessage = async () => {
    // if (currentMessage.trim() === '') {
    //   return;
    // }
    // if (!activeContact) {
    //   console.log('Aktif bir iletişim kurulamadı.');
    //   return;
    // }
    // try {
    //   const res = await memberService.sendUserMessage(
    //     receiverId: activeContact.id,
    //     {
    //       messageContent: currentMessage,
    //     });
    //   setMessageList([...messageList, res.data]);
    //   setCurrentMessage('');
    //   fetchMessages();
    // } catch (error) {
    //   console.log(error);
    // }
  };

  useEffect(() => {
    fetchContacts();
  }, []);

  useEffect(() => {
    fetchMessages();
  }, [activeContact]);

  useEffect(() => {
    const messagesContainer = messageEndRef.current;
    if (messagesContainer) {
      messagesContainer.scrollTop = messagesContainer.scrollHeight;
    }
  }, [messageList]);

  const fetchOnlyContacts = async () => {
    try {
      const res = await memberService.getContacts();
      setContacts(res.data);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    const interval = setInterval(() => {
      if (activeContact && document.visibilityState === 'visible') {
        fetchMessages();
        markMessagesAsRead(activeContact.id);
        fetchOnlyContacts()
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [activeContact]);

  return (
    <div className="flex flex-col h-screen">
      <div className="flex flex-grow" style={{ height: '80%' }}>
        <div className="chat-sidebar w-1/4 bg-white border border-gray-300 p-4 overflow-auto rounded-xl">
          {contacts.map((contact, index) => (
            <div
              key={index}
              className={`chat-sidebar-contact px-4 py-2 flex items-center text-md 2xl:text-lg cursor-pointer hover:bg-purpleLight hover:rounded-2xl ${activeContact && activeContact.id === contact.id
                ? 'border border-gray-200 bg-purpleLight rounded-2xl shadow-xl '
                : ''
                }`}
              onClick={() => setActiveContact(contact)}
            >
              <UserCircleIcon className="w-6 h-6 2xl:w-10 2xl:h-10 mr-2" /> {contact.firstName}{' '}
              {contact.lastName}{' '}
              {contact.unreadCount > 0 && (
                <span className="ml-2 animate-pulse inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-white bg-rexRed rounded-full">
                  {contact.unreadCount} Mesaj
                </span>
              )}
            </div>
          ))}
        </div>
        <div className="chat-box w-3/4 flex flex-col bg-white border border-gray-300 ml-4 rounded-xl">
          <div className="chat-box-header p-4 border-b border-gray-300">
            <h1 className="text-2xl font-bold">
              {activeContact
                ? activeContact.firstName + ' ' + activeContact.lastName
                : 'Kişi Seçiniz'}
            </h1>
          </div>
          <div
            className="chat-box-messages flex-1 p-4 overflow-auto"
            ref={messageEndRef}
          >
            {messageList.map((messageContent, index) => {
              const isMessageFromCurrentUser =
                session && session.id === messageContent?.userId;
              return (
                <div
                  key={index}
                  className={`message ${isMessageFromCurrentUser ? 'text-right' : 'text-left'
                    }`}
                >
                  <div className="message-content relative text-white bg-purpleMid inline-block p-3 rounded-xl m-1">
                    {messageContent?.message}
                    <div>
                      {messageContent?.isRead ? (
                        <CheckCircleIcon className="w-5 h-5 text-greenMid absolute -bottom-2 -right-2" />
                      ) : (
                        <CheckCircleIcon className="w-5 h-5 text-gray-700 absolute -bottom-2 -right-2" />
                      )}
                    </div>
                  </div>
                  <div className="message-meta text-xs text-gray-400">
                    <p>
                      {isMessageFromCurrentUser
                        ? session.firstName + ' ' + session.lastName
                        : activeContact?.firstName +
                        ' ' +
                        activeContact?.lastName}
                      - {formatDateTR(messageContent?.createdAt)}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="chat-box-input flex items-center p-4 bg-white border-t border-gray-300">
            <textarea
              className="flex-1 resize-none rounded-xl border border-gray-300 p-2"
              rows={1}
              value={currentMessage}
              placeholder="Mesaj yaz ..."
              onChange={(event) => {
                setCurrentMessage(event.target.value);
              }}
              onKeyPress={(event) => {
                if (event.key === 'Enter' && !event.shiftKey) {
                  event.preventDefault();
                  sendMessage();
                }
              }}
            ></textarea>
            <button
              className="ml-4 bg-[#eb6d20] text-white font-bold py-2 px-4 rounded-xl"
              onClick={sendMessage}
            >
              Gönder
            </button>
          </div>
        </div>
      </div>
      <div style={{ height: '20%' }}></div>
    </div>
  );
};

export default Chat;
