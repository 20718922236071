import React from 'react';

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

import { useLanguage } from '../../context/LanguageContext';
import usePaginate from '../../hooks/usePaginate';

export interface PaginationProps {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  inputPage?: number | string;
  setInputPage?: React.Dispatch<React.SetStateAction<number | string>>;
  totalPageCount: number;
  queryString?: string;
}

const Pagination: React.FC<PaginationProps> = ({
  page,
  setPage,
  totalPageCount,
  inputPage,
  setInputPage,
  queryString,
}) => {
  const { t } = useLanguage();
  const { navigate, location } = usePaginate();

  const goToPage = (e: React.SyntheticEvent) => {
    e.preventDefault();
    // if (Number(inputPage) > totalPageCount) return;
    setPage(Number(inputPage));

    if (location.search) {
      navigate(`?page=${inputPage}&` + queryString);
    } else {
      navigate(`${location.pathname}?page=${inputPage}`);
    }
    // navigate(queryString!);

    window.scrollTo(0, 0);
  };

  const handleNext = () => {
    if (page >= totalPageCount) return;
    setPage((prev) => prev + 1);
    setInputPage!(page + 1);

    if (queryString) {
      if (location.search.includes('&')) {
        navigate(`?page=${Number(page + 1)}&` + queryString);
      } else {
        navigate(`${location.pathname}?page=${Number(page) + 1}`);
      }
    } else {
      navigate(`${location.pathname}?page=${Number(page) + 1}`);
    }
    window.scrollTo(0, 0);
  };
  const handlePrev = () => {
    if (page === 1) return;
    setPage((prev) => prev - 1);

    setInputPage!(page - 1);

    if (queryString) {
      if (location.search.includes('')) {
        navigate(`?page=${Number(page - 1)}&` + queryString);
      } else {
        navigate(`${location.pathname}?page=${Number(page) - 1}`);
      }
    } else {
      navigate(`${location.pathname}?page=${Number(page) - 1}`);
    }
    window.scrollTo(0, 0);
  };

  return (
    <div className="flex items-center justify-between mt-4 px-2 sm:px-6">
      <div className="flex flex-1 justify-between sm:hidden">
        <button
          onClick={handlePrev}
          className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-2 sm:px-4 py-1 mr-1 sm:mr:0 sm:py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          {t?.('common.buttons.previous')}
        </button>

        <form
          onSubmit={(e) => goToPage(e)}
          className="isolate inline-flex -space-x-px rounded-md shadow-sm"
        >
          <button
            type="submit"
            className="relative inline-flex text-xs items-center bg-secondary rounded-l-md px-2 py-2 text-white"
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5 text-white "
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
            {t?.('common.buttons.goToPage')}
          </button>
          <input
            onWheel={(e) => {
              e.currentTarget.blur();
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 38 || e.keyCode === 40) {
                // 38 for 'Up' and 40 for 'Down'
                e.preventDefault();
              }
            }}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              const newValue = Number(e.currentTarget.value);
              if (!isNaN(newValue) && newValue <= totalPageCount) {
                setInputPage!(newValue);
              } else {
                setInputPage!(''); // Boş değeri koru
              }
            }}
            type="text"
            placeholder="1"
            name="pageNumber"
            value={inputPage}
            id="pageNumber"
            className="w-10 px-2 py-1  ring-secondary rounded-r-md text-sm font-medium no-outline"
          />
        </form>

        <button
          onClick={handleNext}
          className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-2 sm:px-4 py-1 ml-1 sm:ml:0 sm:py-2  text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          {t?.('common.buttons.next')}
        </button>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-end">
        <form
          onSubmit={(e) => goToPage(e)}
          className="isolate inline-flex -space-x-px rounded-md shadow-sm mr-2"
        >
          <button
            type="submit"
            className="relative inline-flex text-xs items-center bg-secondary rounded-l-md px-2 py-2 text-white"
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5 text-white "
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
              ></path>
            </svg>
            {t?.('common.buttons.goToPage')}
          </button>
          <input
            onWheel={(e) => {
              e.currentTarget.blur();
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 38 || e.keyCode === 40) {
                // 38 for 'Up' and 40 for 'Down'
                e.preventDefault();
              }
            }}
            onChange={(e: React.FormEvent<HTMLInputElement>) => {
              const newValue = Number(e.currentTarget.value);
              if (!isNaN(newValue) && newValue <= totalPageCount) {
                setInputPage!(newValue);
              } else {
                setInputPage!(''); // Boş değeri koru
              }
            }}
            placeholder="1"
            value={inputPage}
            type="text"
            name="pageNumber"
            id="pageNumber"
            className="w-20 px-2 py-1  ring-secondary rounded-r-md"
          />
        </form>
        <div>
          <nav
            className="isolate inline-flex -space-x-px rounded-md shadow-sm"
            aria-label="Pagination"
          >
            <button
              onClick={handlePrev}
              className="relative inline-flex items-center rounded-l-md px-2 py-2 bg-white text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Önceki</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            {/* Current: "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600", Default: "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0" */}
            <p
              aria-current="page"
              className="relative z-10 inline-flex items-center bg-secondary px-4 py-2 text-sm font-semibold text-white focus:z-20 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              {page} / {totalPageCount}
            </p>

            <button
              onClick={handleNext}
              className="relative inline-flex items-center rounded-r-md px-2 py-2 bg-white text-gray-400 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
            >
              <span className="sr-only">Sonraki</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
