import React, { Suspense, useEffect, useState } from 'react';
import BannerLoading from '../components/tools/common/BannerLoading';
import NewBanner from '../components/tools/common/NewBanner';
import KeywordForm, {
  IKeywordFilter,
} from '../components/tools/amazon/keywordTool/KeywordForm';
import KeywordTable from '../components/tools/amazon/keywordTool/KeywordTable';
import GiveawayModal from '../components/tools/amazon/keywordTool/GiveawayModal';
import RelatedKeywordsModal from '../components/tools/amazon/keywordTool/RelatedKeywordsModal';
import SearchVolumeChartModal from '../components/tools/amazon/keywordTool/SearchVolumeChartModal';
import { useQuery } from '@tanstack/react-query';
import amazonToolsService from '../services/amazonToolsService';
import { useLocation, useSearchParams } from 'react-router-dom';
import Pagination from '../components/common/Pagination';

const AmazonKeywordTool = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const [openGiveaway, setOpenGiveaway] = useState(false);
  const [openSearchVolume, setOpenSearchVolume] = useState(false);
  const [openRelated, setOpenRelated] = useState(false);
  const [keyword, setKeyword] = useState('');

  const [payload, setPayload] = useState<IKeywordFilter>(
    Object.fromEntries(searchParams).hasOwnProperty('keyword')
      ? Object.fromEntries(searchParams)
      : {
          minVolume: '',
          maxVolume: '',
          minKeywordCount: '',
          maxKeywordCount: '',
          minTopThreeProductClick: '',
          maxTopThreeProductClick: '',
          minTopThreeProductConversion: '',
          maxTopThreeProductConversion: '',
          keyword: '',
          minSaleVolume: '',
          maxSaleVolume: '',
          minTotalSaleCount: '',
          maxTotalSaleCount: '',
          minMonthlyRevenue: '',
          maxMonthlyRevenue: '',
          minMonthlySaleCount: '',
          maxMonthlySaleCount: '',
          minGiveawayCount: '',
          maxGiveawayCount: '',
          minPrice: '',
          maxPrice: '',
          minRivalCount: '',
          maxRivalCount: '',
          minProductAge: '',
          maxProductAge: '',
          minSaleReviewRate: '',
          maxSaleReviewRate: '',
        }
  );
  const [page, setPage] = useState(Number(searchParams.get('page')) || 1);
  const [inputPage, setInputPage] = useState<number | string>(
    Number(searchParams.get('page')) || 1
  );
  const [queryString, setQueryString] = useState(location.search);

  const { data, isLoading } = useQuery<any, Error>(
    ['keywordTool', page, payload],
    {
      queryFn: () => amazonToolsService.getKeywords(page, payload),
      staleTime: Infinity,
    }
  );

  return (
    <div className="relative">
      <Suspense fallback={<BannerLoading />}>
        <NewBanner
          bgUrl={'/images/amazon-product-finder-bg.webp'}
          toolImageUrl="amazonkeyword"
        />
      </Suspense>
      <KeywordForm
        setPayload={setPayload}
        page={page}
        payload={payload}
        setPage={setPage}
        setInputPage={setInputPage}
        setQueryString={setQueryString}
      />
      <KeywordTable
        data={data?.data}
        isLoading={isLoading}
        setGiveaway={setOpenGiveaway}
        setSearchVolume={setOpenSearchVolume}
        setRelated={setOpenRelated}
        setKeyword={setKeyword}
      />
      <Pagination
        page={page}
        setPage={setPage}
        totalPageCount={5}
        setInputPage={setInputPage}
        inputPage={inputPage}
        queryString={queryString}
      />
      {/* <KeywordPagination page={page} setPage={setPage} totalPageCount={5} /> */}
      <GiveawayModal
        open={openGiveaway}
        setOpen={setOpenGiveaway}
        keyword={keyword || ''}
      />
      <RelatedKeywordsModal
        open={openRelated}
        setOpen={setOpenRelated}
        keyword={keyword || ''}
      />

      <SearchVolumeChartModal
        keyword={keyword || ''}
        open={openSearchVolume}
        setOpen={setOpenSearchVolume}
      />
    </div>
  );
};

export default AmazonKeywordTool;
